import React from "react";

const Colleges = () => {
  return (
    <>
      <main className="bg-[#d9dad9] text-center py-10">
        <h1 className="text-4xl font-bold text-[#68A4A5] mb-6">Colleges</h1>
        <p className="text-lg text-[#555] px-10">
          Discover top colleges and universities to pursue your studies.
        </p>
      </main>
    </>
  );
};

export default Colleges;
