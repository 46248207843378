import React from "react";
import Sidebar from "./sidebar";

const FAQ = (props) => {
  // Create an array of FAQ data
  const faqData = [
    { question: props.FAQquestion1, answer: props.FAQAns1 },
    { question: props.FAQquestion2, answer: props.FAQAns2 },
    { question: props.FAQquestion3, answer: props.FAQAns3 },
    { question: props.FAQquestion4, answer: props.FAQAns4 },
    { question: props.FAQquestion5, answer: props.FAQAns5 },
    { question: props.FAQquestion6, answer: props.FAQAns6 },
    { question: props.FAQquestion7, answer: props.FAQAns7 },
    { question: props.FAQquestion8, answer: props.FAQAns8 },
    { question: props.FAQquestion9, answer: props.FAQAns9 },
    { question: props.FAQquestion10, answer: props.FAQAns10 },
    { question: props.FAQquestion11, answer: props.FAQAns11 },
    { question: props.FAQquestion12, answer: props.FAQAns12 },
  ];

  // Filter out any FAQs that don't have questions
  const filteredFAQs = faqData.filter((faq) => faq.question);

  return (
    <div className="bg-white min-h-screen flex flex-col md:flex-row md:space-x-8">
      {/* FAQ Section */}
      <div className="w-full md:w-2/3 p-4 md:p-8">
        {filteredFAQs.length > 0 ? (
          <ul>
            {filteredFAQs.map((faq, index) => (
              <li
                key={index}
                className="mb-8 p-6 bg-white shadow-md rounded-lg"
              >
                {/* Adjusted heading and paragraph for proper alignment */}
                <h3 className="text-base font-semibold break-words sm:text-lg md:text-xl">
                  {faq.question}
                </h3>
                <p className="text-sm break-words sm:text-base md:text-lg">
                  {faq.answer}
                </p>
              </li>
            ))}
          </ul>
        ) : (
          <div className="p-6 text-gray-600">
            <h3 className="text-lg font-semibold">No FAQs available</h3>
            <p>Please check back later for frequently asked questions.</p>
          </div>
        )}
      </div>

      {/* Sidebar Section - Right on desktop, below FAQ on mobile */}
      <Sidebar
        applynowlink={props.applynowlink}
        brochurelink={props.brochurelink}
        SidebarAdvertiseNameVideos1={props.SidebarAdvertiseNameVideos1}
        SidebarAdvertiseVideosSction1={props.SidebarAdvertiseVideosSction1}
        SidebarAdvertiseVideosSction2={props.SidebarAdvertiseNameVideos2}
        SidebarAdvertiseNameVideos2={props.SidebarAdvertiseVideosSction2}
      />
    </div>
  );
};

export default FAQ;
