import React from 'react'
import ClickCollege from '../ClickCollege'
export default function IIT_Jammu() {
  return (
    <div>
    <ClickCollege 
        LogoLink="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEABAQEBAREBIUFBIZGxgbGSUiHx8iJTgoKygrKDhVNT41NT41VUtbSkVKW0uHal5eaoecg3yDnL2pqb3u4u7///8BEBAQEBEQEhQUEhkbGBsZJSIfHyIlOCgrKCsoOFU1PjU1PjVVS1tKRUpbS4dqXl5qh5yDfIOcvampve7i7v/////CABEIAC4ALgMBIgACEQEDEQH/xAAaAAABBQEAAAAAAAAAAAAAAAAGAQIDBAUA/9oACAEBAAAAADjDfuu5gzWuoTIIX8Qo1OrcsV2rA1JphvdGmK//xAAYAQADAQEAAAAAAAAAAAAAAAABAgMABP/aAAoCAhADEAAAAL8zpG2NYx6GzoD/AP/EAC0QAAICAQIEAwcFAAAAAAAAAAECAwQRABITITFBBRAiFCNhYnGhsiQyUXWC/9oACAEBAAE/AJ54a0TTTOEjXGW+unt8S9FLsKGKRVYE7gUfo+q3jTvBmaECQI7HaeXpfZpGLIrFSpIBIPUebxpKjJIisrDBUjIOr1eGnIiwZ2BSCmc7A3YaeKxXydjKzuxQkdOZbP31WtWa0IQYZQMIJDgL9XbBbSCa9OqtfjLj1BUywXHnbiNWzgyTSPkFGkckkDVqxPejEaV3AUgsy8/VjTRsmDvhAPQyQh0P+lAOvCoYUrJIBBxmXEhi6ec9eKwOaqXXoe476q1xWhCd+rH441JVhkJJTmeTfMP4Yd9VaEFNpTCGAkxyJyBjyN2kqq7W4QpAIJccwdLXo2yZ433hicsj8idcCgyRLxchpGCe8PqbuPtpoPDxHJmbCxvtc8To3TB1EKdfiyJMAA3Ccl8gNn9umt1UXc9iJV3FclgBleo1Sj/Q2f6U/nJqWwadCOcRq4jSMupOPTpYLM9fejRr7JXNkZyecjmbVmGWY3HD4jknecr8Y3AH56tQTGa4Q44ZuPOV+dJhHq3BLall8NTaHhsTTFyeREja/8QAHREBAAIDAAMBAAAAAAAAAAAAAQARAgMSEyJhIf/aAAgBAgEBPwDRzaI2zPSJ6VNmvijq2a8+H4w3YYriHrM0clFT7HHlrFbup4/2u2eIU9p//8QAIREBAAEDBAIDAAAAAAAAAAAAAQACAxIEESExQVJhcYH/2gAIAQMBAT8A1I4dhsj+ynUY1Oa7eDbmWrrdycGkOle5dozp+o2blQVKZJyJKBKAQH4g7nJ4mR6zPY6n/9k=" 
        CollegeName="IIT Jammu " 
        CollegeLocation=""
        collegeRank=""
        rankBasis="NIRF"
        CollegeInfoNewThings=""
        AboutCollege=""
        AboutCollegeMore=""
        Course1=""
        Course1Fee=""
        Course1Eligibility=""
        Course1ApplicationDeadline=""
        Course2=""
        Course2Fee=""
        Course2Eligibility=""
        Course2ApplicationDeadline=""
        Course3=""
        Course3Fee=""
        Course3Eligibility=""
        Course3ApplicationDeadline=""
        Course4=""
        Course4Fee=""
        Course4Eligibility=""
        Course4ApplicationDeadline=""

        DistanceCourseName1=" "
        DistanceCourseFee1=""
        DistanceEligiblity1=""
        DistanceApllicationdate1=""
        DistanceCourseName2=" "
        DistanceCourseFee2=""
        DistanceEligiblity2=""
        DistanceApllicationdate2=""
        DistanceCourseName3=" "
        DistanceCourseFee3=""
        DistanceEligiblity3=""
        DistanceApllicationdate3=""

        Placementyear=""
        PlacementDescriptionaboutHighestPackageAveragePackageTopRecruiters1=""
        PlacementDescriptionaboutHighestPackageAveragePackageTopRecruiters2=""
        Placementhighestpackagelist=""
        PlacementAveragepackage=""
        PlacementTopRecruiters=""

        PlacementCompanyName1=""
        PlacementHighestPackage1=""
        PlacementSector1=""
        PlacementDesignation1=""
        PlacementCompanyName2=""
        PlacementHighestPackage2=""
        PlacementSector2=""
        PlacementDesignation2=""
        PlacementCompanyName3=""
        PlacementHighestPackage3=""
        PlacementSector3=""
        PlacementDesignation3=""
        PlacementCompanyName4=""
        PlacementHighestPackage4=""
        PlacementSector4=""
        PlacementDesignation4=""
        PlacementCompanyName5=""
        PlacementHighestPackage5=""
        PlacementSector5=""
        PlacementDesignation5=""
        PlacementCompanyName6=""
        PlacementHighestPackage6=""
        PlacementSector6=""
        PlacementDesignation6=""

        ReviewLike1=""
        ReviewLike2=""
        ReviewLike3=""
        ReviewLike4=""

        ReviewDilike1=""
        ReviewDilike2=""
        ReviewDilike3=""
        ReviewDilike4=""
        // CutOff
        CutoffTopic=""
        CutoffAboutInfo=""
        CutoffAboutExam = ""
        CutoffAcademicProgramName1 = ""
        CutoffAcademicOpeningranke1 = ""
        cutoffAcademicClossingrank1 = ""
        CutoffAcademicProgramName2 =""
        CutoffAcademicOpeningranke2 =""
        cutoffAcademicClossingrank2 = ""
        CutoffAcademicProgramName3 = ""
        CutoffAcademicOpeningranke3 = ""
        cutoffAcademicClossingrank3 = ""
        CutoffAcademicProgramName4 =""
        CutoffAcademicOpeningranke4 = ""
        cutoffAcademicClossingrank4 = ""
        CutoffExamyear1 = ""
        CutoffExamyear2 = ""

        CutoffCourses1 = ""
        CutoffCourcesRank1 = ""
        CutoffCourses2 =""
        CutoffCourcesRank2 = ""
        CutoffCourses3 =""
        CutoffCourcesRank3 = ""
        CutoffCourses4 =""
        CutoffCourcesRank4 = ""
        CutoffCourses5 = ""
        CutoffCourcesRank5 = ""
        CutoffCoursesName1 = ""
        CutoffCourses1Round1 =""
        CutoffCourses1Round2 = ""
        CutoffCourses1Round3 = ""
        CutoffCourses1Round4 =""
        CutoffCourses1Round5 =""
        CutoffCourses1Round6 =""
        CutoffCoursesName2 = ""
        CutoffCourses2Round1 = ""
        CutoffCourses2Round2 = ""
        CutoffCourses2Round3 = ""
        CutoffCourses2Round4 = ""
        CutoffCourses2Round5 = ""
        CutoffCourses2Round6 = ""
        CutoffCoursesName3 = ""
        CutoffCourses3Round1 = ""
        CutoffCourses3Round2 = ""
        CutoffCourses3Round3 = ""
        CutoffCourses3Round4 = ""
        CutoffCourses3Round5 = ""
        CutoffCourses3Round6 = ""
        

        // Ranking
        RankingYear = ""
        RankingInfo =""
        RankingDescriptionHeader = ""
        RankingWeekRankingName = ""
        RankingWeekDescription1 ="" 
        RankingDescription2 = ""
        RankingWeekStream1 = ""
        Ranking1Week2024 = ""
        Ranking1Week2023 = ""
        Ranking1Week2022 = ""
        RankingWeekStream2 = ""
        Ranking2Week2024 = ""
        Ranking2Week2023 = ""
        Ranking2Week2022 = ""
        RankingNIRFRankingName = ""
        RankingNIRFStream1 = ""
        Ranking1NIRF2024 = ""
        Ranking1NIRF2023 = ""
        Ranking1NIRF2022 = ""
        RankingNIRFStream2 = ""
        Ranking2NIRF2024 = ""
        Ranking2NIRF2023 = ""
        Ranking2NIRF2022 = ""
        

            CollegeFacilitiesInfo=""
            collegeHighlights=""
            CampusFacility1=""
            CampusFacility1PhotoURL=""
            CampusFacility1Paragraph=""
            CampusFacility2=""
            CampusFacility2PhotoURL=""
            CampusFacility2Paragraph=""
            CampusFacility3=""
            CampusFacility3PhotoURL=""
            CampusFacility3Paragraph=""
            CampusFacility4=""
            CampusFacility4PhotoURL=""
            CampusFacility4Paragraph=""
            CampusFacility5=""
            CampusFacility5PhotoURL=""
            CampusFacility5Paragraph=""
            CampusFacility6=""
            CampusFacility6PhotoURL=""
            CampusFacility6Paragraph=""
            CampusFacility7=""
            CampusFacility7PhotoURL=""
            CampusFacility7Paragraph=""
            HostelParagraph=""
            CollegeHostelFacility1=""
            CollegeHostelFacility2=""
            CollegeHostelFacility3=""
            CollegeHostelFacility4=""
            CollegeCautionFees=""
            totalhostelfee=""
            hosteldislikereview1=""
            hosteldislikereview2=""
            hosteldislikereview3=""
            hosteldislikereview4=""
            hostellikereview1=""
            hostellikereview2=""
            hostellikereview3=""
            hostellikereview4=""


            DepartmentName1=""
            DepartmentName1Location=""
            CoursesinDepartment1=""
            DepartmentFees1=""
            Question1=""
            Answer1=""
            Question2=""
            Answer2=""
            Question3=""
            Answer3=""
            Question4=""
            Answer4=""


        
            ScholarshipDescription = " "
            ScholarshipName1 = " "
            ScholarshipEligibilCriteria1 = ""
            ScholarshipAward1 =" " 
            ScholarshipName2 = " "
            ScholarshipEligibilCriteria2 = " "
            ScholarshipAward2 =" " 
            ScholarshipName3 = " "
            ScholarshipEligibilCriteria3 = " "
            ScholarshipAward3 =" " 
            ScholarshipName4 = " "
            ScholarshipEligibilCriteria4 = " "
            ScholarshipAward4 =" " 
            ScholarshipName5 = " "
            ScholarshipEligibilCriteria5 = " "
            ScholarshipAward5 =" " 
            ScholarshipName6 = " "
            ScholarshipEligibilCriteria6 = " "
            ScholarshipAward6 =" " 
            ScholarshipName7 = " "
            ScholarshipEligibilCriteria7 = " "
            ScholarshipAward7 =" " 
            ScholarshipName8 = " "
            ScholarshipEligibilCriteria8 = " "
            ScholarshipAward8 =" " 
            ScholarshipName9 = " "
            ScholarshipEligibilCriteria9 =" "
            ScholarshipAward9 =" " 
            ScholarshipName10 =" " 
            ScholarshipEligibilCriteria10 = " "
            ScholarshipAward10 = " "
        
        />
        </div>
  )
}
