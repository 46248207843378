import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

const generateSlug = (name) => {
  return name.toLowerCase().replace(/[^a-z]/g, ""); // Remove special characters
};

const NewsSection = () => {
  const [articles, setArticles] = useState([]);
  const navigate = useNavigate(); // Use the useNavigate hook for navigation

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch("https://caderaedu.com/api/latestnotifications");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        // Assuming the response data is an array of articles
        setArticles(data);
      } catch (error) {
        console.error("Error fetching articles:", error);
      }
    };

    fetchArticles();
  }, []);

  const handleArticleClick = (name) => {
    const slug = generateSlug(name);
    navigate(`/notifications/${slug}`); // Navigate to the slug URL
  };

  return (
    <div className="sm:hidden block px-4 py-6 bg-white">
      <h3 className="text-2xl font-bold text-black mb-6 text-center">
        Latest Notifications
      </h3>
      <div className="space-y-3">
        {articles.map((article) => (
          <div
            key={article._id} // Use the unique ID from the API response
            className="flex items-center justify-between border-b pb-3"
            onClick={() => handleArticleClick(article.mainheading)} // Handle click on the article
          >
            <div className="flex-1">
              <p className="flex items-center">
                {article.isLive && (
                  <span className="text-red-500 bg-red-100 px-1.5 py-0.5 text-[10px] rounded-full mr-2">
                    LIVE
                  </span>
                )}
                <span className="font-bold text-xs leading-tight">
                  <h1>{article.mainheading}</h1>
                </span>
              </p>
              <p className="text-[11px] text-gray-500 mt-1">
                {article.author} • {article.datetime}
              </p>
            </div>
            <img
              src={article.photo}
              alt={article.mainheading}
              className="w-14 h-10 object-cover rounded-md ml-3"
            />
          </div>
        ))}
      </div>
      <button className="mt-4 bg-teal-500 text-white px-3 py-1.5 rounded-md text-sm">
        <a href="/LatestNotificationPage">View All Updates</a>
      </button>
    </div>
  );
};

export default NewsSection;
