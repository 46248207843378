import React from "react";
import Sidebar from "./sidebar"; // Ensure this import path is correct

const Placement = (props) => {
  // Check if any placement data is available
  const hasPlacementData =
    props.CollegeName &&
    props.Placementyear &&
    (props.PlacementDescriptionaboutHighestPackageAveragePackageTopRecruiters1 ||
      props.PlacementDescriptionaboutHighestPackageAveragePackageTopRecruiters2 ||
      props.Placementhighestpackagelist ||
      props.PlacementAveragepackage ||
      props.PlacementTopRecruiters ||
      props.PlacementCompanyName1 ||
      props.PlacementCompanyName2 ||
      props.PlacementCompanyName3 ||
      props.PlacementCompanyName4 ||
      props.PlacementCompanyName5 ||
      props.PlacementCompanyName6);

  // If no data, return null to avoid rendering
  if (!hasPlacementData) {
    return (
      <div className="placement">
        <h4>This College Does not Have Placement Data</h4>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4 flex flex-col lg:flex-row">
      {/* Main Content */}
      <div className="lg:w-3/4 w-full lg:pr-6 placement-content mb-6 lg:mb-0">
        <div className="bg-white shadow-md p-6 rounded-lg mb-6">
          <h1 className="text-2xl font-bold mb-4">
            {props.CollegeName} Placement {props.Placementyear} Highest Package,
            Average Package, Top Recruiters
          </h1>
          <p className="mb-4">
            {
              props.PlacementDescriptionaboutHighestPackageAveragePackageTopRecruiters1
            }
          </p>
          <p className="mb-4">
            {
              props.PlacementDescriptionaboutHighestPackageAveragePackageTopRecruiters2
            }
          </p>
          <ul className="list-inside mb-4">
            <li>{props.Placementhighestpackagelist}</li>
            <li>{props.PlacementAveragepackage}</li>
            <li>{props.PlacementTopRecruiters}</li>
          </ul>
        </div>

        {/* Placement Table */}
        <div className="bg-white shadow-md p-6 rounded-lg">
          <h2 className="text-xl font-bold mb-4">
            {props.CollegeName} Placement {props.Placementyear} Recruiters and
            Packages List
          </h2>
          <div className="overflow-x-auto">
            {props.PlacementCompanyName1 ||
            props.PlacementCompanyName2 ||
            props.PlacementCompanyName3 ||
            props.PlacementCompanyName4 ||
            props.PlacementCompanyName5 ||
            props.PlacementCompanyName6 ? (
              <table className="table-auto w-full text-left">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="p-2">Company</th>
                    <th className="p-2">Highest Package</th>
                    <th className="p-2">Sector</th>
                    <th className="p-2">Designation</th>
                  </tr>
                </thead>
                <tbody>
                  {props.PlacementCompanyName1 && (
                    <tr className="border-b">
                      <td className="p-2">{props.PlacementCompanyName1}</td>
                      <td className="p-2">{props.PlacementHighestPackage1}</td>
                      <td className="p-2">{props.PlacementSector1}</td>
                      <td className="p-2">{props.PlacementDesignation1}</td>
                    </tr>
                  )}
                  {props.PlacementCompanyName2 && (
                    <tr className="border-b">
                      <td className="p-2">{props.PlacementCompanyName2}</td>
                      <td className="p-2">{props.PlacementHighestPackage2}</td>
                      <td className="p-2">{props.PlacementSector2}</td>
                      <td className="p-2">{props.PlacementDesignation2}</td>
                    </tr>
                  )}
                  {props.PlacementCompanyName3 && (
                    <tr className="border-b">
                      <td className="p-2">{props.PlacementCompanyName3}</td>
                      <td className="p-2">{props.PlacementHighestPackage3}</td>
                      <td className="p-2">{props.PlacementSector3}</td>
                      <td className="p-2">{props.PlacementDesignation3}</td>
                    </tr>
                  )}
                  {props.PlacementCompanyName4 && (
                    <tr className="border-b">
                      <td className="p-2">{props.PlacementCompanyName4}</td>
                      <td className="p-2">{props.PlacementHighestPackage4}</td>
                      <td className="p-2">{props.PlacementSector4}</td>
                      <td className="p-2">{props.PlacementDesignation4}</td>
                    </tr>
                  )}
                  {props.PlacementCompanyName5 && (
                    <tr className="border-b">
                      <td className="p-2">{props.PlacementCompanyName5}</td>
                      <td className="p-2">{props.PlacementHighestPackage5}</td>
                      <td className="p-2">{props.PlacementSector5}</td>
                      <td className="p-2">{props.PlacementDesignation5}</td>
                    </tr>
                  )}
                  {props.PlacementCompanyName6 && (
                    <tr className="border-b">
                      <td className="p-2">{props.PlacementCompanyName6}</td>
                      <td className="p-2">{props.PlacementHighestPackage6}</td>
                      <td className="p-2">{props.PlacementSector6}</td>
                      <td className="p-2">{props.PlacementDesignation6}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            ) : (
              <p>No placement data available. Please check back later.</p>
            )}
          </div>
        </div>
      </div>

      {/* Sidebar */}
      <Sidebar
        applynowlink={props.applynowlink}
        brochurelink={props.brochurelink}
        SidebarAdvertiseNameVideos1={props.SidebarAdvertiseNameVideos1}
        SidebarAdvertiseVideosSction1={props.SidebarAdvertiseVideosSction1}
        SidebarAdvertiseVideosSction2={props.SidebarAdvertiseNameVideos2}
        SidebarAdvertiseNameVideos2={props.SidebarAdvertiseVideosSction2}
      />
    </div>
  );
};

export default Placement;
