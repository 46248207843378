import React, { useState, useEffect } from "react";
import {
  colleges,
  predictor,
  counselling,
  Scholarship,
  Counsellingupdates,
} from "../../images/index";

function App() {
  const [showSignupModal, setShowSignupModal] = useState(false);

  // State for form fields
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    mobileNumber: "",
    city: "",
    courseInterestedIn: "",
  });

  // State for error messages and submission status
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Show modal after a delay of 3 seconds (3000ms)
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSignupModal(true);
    }, 3000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  const handleCloseModal = () => {
    setShowSignupModal(false);
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(""); // Clear any previous errors
    setIsSubmitting(true); // Set submitting state

    // Basic validation (you can enhance it further)
    if (
      !formData.fullName ||
      !formData.email ||
      !formData.mobileNumber ||
      !formData.city ||
      !formData.courseInterestedIn
    ) {
      setErrorMessage("Please fill in all the required fields.");
      setIsSubmitting(false);
      return;
    }

    // Validate if mobile number is exactly 10 digits
    const mobileNumberRegex = /^[0-9]{10}$/;
    if (!mobileNumberRegex.test(formData.mobileNumber)) {
      setErrorMessage("Mobile number must be exactly 10 digits.");
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await fetch("https://caderaedu.com/api/form/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Failed to submit form.");
      }

      const result = await response.json();
      alert("Form submitted successfully!");
      setFormData({
        fullName: "",
        email: "",
        mobileNumber: "",
        city: "",
        courseInterestedIn: "",
      });
      setIsSubmitting(false);
      setShowSignupModal(false); // Close modal on success
    } catch (error) {
      setErrorMessage(error.message);
      setIsSubmitting(false);
    }
  };

  return (
    <div className="App">
      {showSignupModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-6 md:p-8 relative w-full max-w-4xl mx-4 shadow-lg flex flex-col md:flex-row">
            {/* Left Side (Cards Section) - hidden on mobile */}
            <div className="hidden md:block md:w-1/2 bg-gray-100 p-6 rounded-t-lg md:rounded-l-lg md:rounded-t-none mb-4 md:mb-0">
              <h3 className="text-xl font-bold mb-4 text-center">
                How CaderaEdu helps you
              </h3>
              <div className="space-y-4">
                <div className="bg-white p-4 rounded shadow-md flex items-center">
                  <img
                    src={counselling}
                    width={60}
                    height={60}
                    alt="24X7 Dedicated Counsellor"
                    className="mr-4"
                  />
                  <span>24X7 Dedicated Counsellor</span>
                </div>
                <div className="bg-white p-4 rounded shadow-md flex items-center">
                  <img
                    src={Scholarship}
                    width={50}
                    height={50}
                    alt="Categorized Scholarship"
                    className="mr-4"
                  />
                  <span>Categorized Scholarship</span>
                </div>
                <div className="bg-white p-4 rounded shadow-md flex items-center">
                  <img
                    src={colleges}
                    width={50}
                    height={50}
                    alt="Top Ranking Colleges"
                    className="mr-4"
                  />
                  <span>Top Ranking Colleges</span>
                </div>
                <div className="bg-white p-4 rounded shadow-md flex items-center">
                  <img
                    src={Counsellingupdates}
                    width={50}
                    height={50}
                    alt="Counselling Updates"
                    className="mr-4"
                  />
                  <span>Counselling Updates</span>
                </div>
                <div className="bg-white p-4 rounded shadow-md flex items-center">
                  <img
                    src={predictor}
                    width={50}
                    height={50}
                    alt="College Predictors"
                    className="mr-4"
                  />
                  <span>College Predictors</span>
                </div>
              </div>
            </div>

            {/* Right Side (Form Section) */}
            <div className="md:w-1/2 w-full bg-white p-4 md:p-6 rounded-b-lg md:rounded-r-lg md:rounded-b-none relative">
              <span
                className="absolute top-4 right-4 text-2xl cursor-pointer font-bold"
                onClick={handleCloseModal}
              >
                &times;
              </span>
              <h2 className="text-xl font-bold text-teal-600 mb-4">
                Register Now To Apply
              </h2>
              {errorMessage && <p className="text-red-600">{errorMessage}</p>}
              <form className="space-y-3" onSubmit={handleSubmit}>
                <div>
                  <label className="block text-left font-semibold">
                    Full Name *
                  </label>
                  <input
                    type="text"
                    name="fullName"
                    className="w-full border border-gray-300 rounded-md px-4 py-2 mt-1"
                    placeholder="Full Name"
                    value={formData.fullName}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div>
                  <label className="block text-left font-semibold">
                    Email Address *
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="w-full border border-gray-300 rounded-md px-4 py-2 mt-1"
                    placeholder="Email Address"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div>
                  <label className="block text-left font-semibold">
                    Mobile Number *
                  </label>
                  <input
                    type="text"
                    name="mobileNumber"
                    className="w-full border border-gray-300 rounded-md px-4 py-2 mt-1"
                    placeholder="Mobile Number"
                    value={formData.mobileNumber}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div>
                  <label className="block text-left font-semibold">
                    City You Live In *
                  </label>
                  <input
                    type="text"
                    name="city"
                    className="w-full border border-gray-300 rounded-md px-4 py-2 mt-1"
                    placeholder="City"
                    value={formData.city}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div>
                  <label className="block text-left font-semibold">
                    Course Interested In *
                  </label>
                  <input
                    type="text"
                    name="courseInterestedIn"
                    className="w-full border border-gray-300 rounded-md px-4 py-2 mt-1"
                    placeholder="Course"
                    value={formData.courseInterestedIn}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="w-full bg-teal-600 text-white py-2 rounded-md font-semibold hover:bg-teal-700"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </form>
              <p className="mt-4 text-sm">
                Already Registered?{" "}
                <a href="/login" className="text-blue-500 hover:underline">
                  Login
                </a>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
