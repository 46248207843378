[{
    "_id": {
      "$oid": "6733043912f54ba6d9a02ba3"
    },
    "Rank": 1,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ba4"
    },
    "Rank": 3,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ba5"
    },
    "Rank": 5,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ba6"
    },
    "Rank": 6,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ba7"
    },
    "Rank": 7,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ba8"
    },
    "Rank": 8,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ba9"
    },
    "Rank": 9,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02baa"
    },
    "Rank": 10,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bab"
    },
    "Rank": 13,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bac"
    },
    "Rank": 14,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bad"
    },
    "Rank": 15,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bae"
    },
    "Rank": 16,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02baf"
    },
    "Rank": 19,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bb0"
    },
    "Rank": 20,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bb1"
    },
    "Rank": 22,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bb2"
    },
    "Rank": 23,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bb3"
    },
    "Rank": 24,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bb4"
    },
    "Rank": 25,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bb5"
    },
    "Rank": 26,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bb6"
    },
    "Rank": 27,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bb7"
    },
    "Rank": 28,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bb8"
    },
    "Rank": 29,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bb9"
    },
    "Rank": 30,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bba"
    },
    "Rank": 32,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bbb"
    },
    "Rank": 33,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bbc"
    },
    "Rank": 34,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bbd"
    },
    "Rank": 35,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bbe"
    },
    "Rank": 36,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bbf"
    },
    "Rank": 38,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bc0"
    },
    "Rank": 41,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bc1"
    },
    "Rank": 42,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bc2"
    },
    "Rank": 43,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bc3"
    },
    "Rank": 44,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bc4"
    },
    "Rank": 46,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bc5"
    },
    "Rank": 49,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bc6"
    },
    "Rank": 50,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bc7"
    },
    "Rank": 52,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bc8"
    },
    "Rank": 53,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bc9"
    },
    "Rank": 54,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bca"
    },
    "Rank": 55,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bcb"
    },
    "Rank": 56,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bcc"
    },
    "Rank": 57,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bcd"
    },
    "Rank": 58,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bce"
    },
    "Rank": 60,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bcf"
    },
    "Rank": 61,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bd0"
    },
    "Rank": 62,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bd1"
    },
    "Rank": 64,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bd2"
    },
    "Rank": 65,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bd3"
    },
    "Rank": 66,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bd4"
    },
    "Rank": 67,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bd5"
    },
    "Rank": 68,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bd6"
    },
    "Rank": 69,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bd7"
    },
    "Rank": 71,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bd8"
    },
    "Rank": 72,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bd9"
    },
    "Rank": 73,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bda"
    },
    "Rank": 74,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bdb"
    },
    "Rank": 75,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bdc"
    },
    "Rank": 76,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bdd"
    },
    "Rank": 77,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bde"
    },
    "Rank": 78,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bdf"
    },
    "Rank": 79,
    "Allotted Quota": "All India",
    "Allotted Institute": "G B Pant Institute of Post Graduate Medical Education and Research,G B Pant Institute of Post Graduate Medical Education and Research, Delhi (NCT), 110007",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02be0"
    },
    "Rank": 80,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02be1"
    },
    "Rank": 83,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02be2"
    },
    "Rank": 84,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02be3"
    },
    "Rank": 85,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02be4"
    },
    "Rank": 86,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02be5"
    },
    "Rank": 87,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02be6"
    },
    "Rank": 89,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sanjay Gandhi Postgarduate Institute of Medical Sciences,Sanjay Gandhi Postgarduate Institute of Medical Sciences, Uttar Pradesh, 226014",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02be7"
    },
    "Rank": 90,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02be8"
    },
    "Rank": 91,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02be9"
    },
    "Rank": 92,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bea"
    },
    "Rank": 93,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02beb"
    },
    "Rank": 94,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bec"
    },
    "Rank": 95,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bed"
    },
    "Rank": 96,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bee"
    },
    "Rank": 97,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bef"
    },
    "Rank": 98,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bf0"
    },
    "Rank": 99,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bf1"
    },
    "Rank": 100,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bf2"
    },
    "Rank": 102,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bf3"
    },
    "Rank": 103,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bf4"
    },
    "Rank": 104,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bf5"
    },
    "Rank": 105,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bf6"
    },
    "Rank": 106,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bf7"
    },
    "Rank": 107,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bf8"
    },
    "Rank": 109,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bf9"
    },
    "Rank": 110,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bfa"
    },
    "Rank": 111,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bfb"
    },
    "Rank": 112,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bfc"
    },
    "Rank": 113,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bfd"
    },
    "Rank": 114,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bfe"
    },
    "Rank": 115,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02bff"
    },
    "Rank": 116,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c00"
    },
    "Rank": 117,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c01"
    },
    "Rank": 118,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c02"
    },
    "Rank": 119,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c03"
    },
    "Rank": 120,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c04"
    },
    "Rank": 121,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c05"
    },
    "Rank": 122,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c06"
    },
    "Rank": 123,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c07"
    },
    "Rank": 124,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c08"
    },
    "Rank": 126,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c09"
    },
    "Rank": 127,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c0a"
    },
    "Rank": 128,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c0b"
    },
    "Rank": 129,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c0c"
    },
    "Rank": 130,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c0d"
    },
    "Rank": 131,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c0e"
    },
    "Rank": 132,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c0f"
    },
    "Rank": 133,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c10"
    },
    "Rank": 134,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c11"
    },
    "Rank": 135,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c12"
    },
    "Rank": 136,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c13"
    },
    "Rank": 137,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c14"
    },
    "Rank": 138,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c15"
    },
    "Rank": 140,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c16"
    },
    "Rank": 141,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c17"
    },
    "Rank": 142,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c18"
    },
    "Rank": 143,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c19"
    },
    "Rank": 144,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c1a"
    },
    "Rank": 145,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c1b"
    },
    "Rank": 146,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c1c"
    },
    "Rank": 148,
    "Allotted Quota": "All India",
    "Allotted Institute": "G B Pant Institute of Post Graduate Medical Education and Research,G B Pant Institute of Post Graduate Medical Education and Research, Delhi (NCT), 110007",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c1d"
    },
    "Rank": 149,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c1e"
    },
    "Rank": 150,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c1f"
    },
    "Rank": 151,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c20"
    },
    "Rank": 152,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c21"
    },
    "Rank": 153,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c22"
    },
    "Rank": 154,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c23"
    },
    "Rank": 155,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c24"
    },
    "Rank": 157,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c25"
    },
    "Rank": 158,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c26"
    },
    "Rank": 159,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c27"
    },
    "Rank": 160,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c28"
    },
    "Rank": 161,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c29"
    },
    "Rank": 162,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c2a"
    },
    "Rank": 163,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c2b"
    },
    "Rank": 164,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c2c"
    },
    "Rank": 165,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c2d"
    },
    "Rank": 166,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c2e"
    },
    "Rank": 167,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c2f"
    },
    "Rank": 168,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c30"
    },
    "Rank": 169,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c31"
    },
    "Rank": 170,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c32"
    },
    "Rank": 171,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c33"
    },
    "Rank": 172,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c34"
    },
    "Rank": 173,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c35"
    },
    "Rank": 174,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c36"
    },
    "Rank": 175,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c37"
    },
    "Rank": 176,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c38"
    },
    "Rank": 177,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c39"
    },
    "Rank": 178,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c3a"
    },
    "Rank": 179,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c3b"
    },
    "Rank": 181,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c3c"
    },
    "Rank": 182,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c3d"
    },
    "Rank": 183,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c3e"
    },
    "Rank": 184,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c3f"
    },
    "Rank": 185,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c40"
    },
    "Rank": 186,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c41"
    },
    "Rank": 187,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c42"
    },
    "Rank": 188,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c43"
    },
    "Rank": 189,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c44"
    },
    "Rank": 190,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c45"
    },
    "Rank": 192,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c46"
    },
    "Rank": 193,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c47"
    },
    "Rank": 195,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c48"
    },
    "Rank": 196,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c49"
    },
    "Rank": 197,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c4a"
    },
    "Rank": 198,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c4b"
    },
    "Rank": 199,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c4c"
    },
    "Rank": 200,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c4d"
    },
    "Rank": 201,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c4e"
    },
    "Rank": 202,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c4f"
    },
    "Rank": 203,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c50"
    },
    "Rank": 204,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c51"
    },
    "Rank": 206,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c52"
    },
    "Rank": 207,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c53"
    },
    "Rank": 208,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c54"
    },
    "Rank": 209,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c55"
    },
    "Rank": 211,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c56"
    },
    "Rank": 213,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c57"
    },
    "Rank": 214,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c58"
    },
    "Rank": 215,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c59"
    },
    "Rank": 216,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c5a"
    },
    "Rank": 217,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c5b"
    },
    "Rank": 218,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c5c"
    },
    "Rank": 219,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c5d"
    },
    "Rank": 220,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c5e"
    },
    "Rank": 221,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c5f"
    },
    "Rank": 222,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c60"
    },
    "Rank": 223,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c61"
    },
    "Rank": 224,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c62"
    },
    "Rank": 225,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c63"
    },
    "Rank": 226,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c64"
    },
    "Rank": 227,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c65"
    },
    "Rank": 228,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c66"
    },
    "Rank": 229,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c67"
    },
    "Rank": 230,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c68"
    },
    "Rank": 231,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c69"
    },
    "Rank": 232,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c6a"
    },
    "Rank": 233,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c6b"
    },
    "Rank": 235,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c6c"
    },
    "Rank": 236,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c6d"
    },
    "Rank": 238,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c6e"
    },
    "Rank": 239,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c6f"
    },
    "Rank": 240,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c70"
    },
    "Rank": 241,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c71"
    },
    "Rank": 242,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c72"
    },
    "Rank": 243,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c73"
    },
    "Rank": 244,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c74"
    },
    "Rank": 245,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c75"
    },
    "Rank": 246,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c76"
    },
    "Rank": 247,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c77"
    },
    "Rank": 248,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c78"
    },
    "Rank": 249,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c79"
    },
    "Rank": 250,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c7a"
    },
    "Rank": 251,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c7b"
    },
    "Rank": 253,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c7c"
    },
    "Rank": 254,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c7d"
    },
    "Rank": 255,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c7e"
    },
    "Rank": 257,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c7f"
    },
    "Rank": 258,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c80"
    },
    "Rank": 259,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c81"
    },
    "Rank": 260,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c82"
    },
    "Rank": 261,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c83"
    },
    "Rank": 262,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c84"
    },
    "Rank": 263,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c85"
    },
    "Rank": 264,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c86"
    },
    "Rank": 265,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c87"
    },
    "Rank": 266,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c88"
    },
    "Rank": 267,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c89"
    },
    "Rank": 268,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c8a"
    },
    "Rank": 269,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c8b"
    },
    "Rank": 271,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c8c"
    },
    "Rank": 272,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c8d"
    },
    "Rank": 273,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c8e"
    },
    "Rank": 274,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c8f"
    },
    "Rank": 275,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c90"
    },
    "Rank": 276,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c91"
    },
    "Rank": 277,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c92"
    },
    "Rank": 278,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c93"
    },
    "Rank": 279,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c94"
    },
    "Rank": 280,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c95"
    },
    "Rank": 281,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c96"
    },
    "Rank": 283,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c97"
    },
    "Rank": 284,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c98"
    },
    "Rank": 285,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c99"
    },
    "Rank": 286,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c9a"
    },
    "Rank": 287,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c9b"
    },
    "Rank": 288,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c9c"
    },
    "Rank": 289,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c9d"
    },
    "Rank": 290,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c9e"
    },
    "Rank": 291,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02c9f"
    },
    "Rank": 292,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ca0"
    },
    "Rank": 293,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ca1"
    },
    "Rank": 294,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ca2"
    },
    "Rank": 295,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ca3"
    },
    "Rank": 296,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ca4"
    },
    "Rank": 297,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ca5"
    },
    "Rank": 298,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ca6"
    },
    "Rank": 299,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ca7"
    },
    "Rank": 300,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ca8"
    },
    "Rank": 301,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ca9"
    },
    "Rank": 302,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02caa"
    },
    "Rank": 303,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cab"
    },
    "Rank": 304,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cac"
    },
    "Rank": 305,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cad"
    },
    "Rank": 306,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cae"
    },
    "Rank": 308,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02caf"
    },
    "Rank": 309,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cb0"
    },
    "Rank": 310,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cb1"
    },
    "Rank": 311,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cb2"
    },
    "Rank": 312,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cb3"
    },
    "Rank": 313,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cb4"
    },
    "Rank": 314,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cb5"
    },
    "Rank": 316,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cb6"
    },
    "Rank": 317,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cb7"
    },
    "Rank": 318,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cb8"
    },
    "Rank": 319,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cb9"
    },
    "Rank": 320,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cba"
    },
    "Rank": 321,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cbb"
    },
    "Rank": 323,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cbc"
    },
    "Rank": 324,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cbd"
    },
    "Rank": 325,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cbe"
    },
    "Rank": 326,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cbf"
    },
    "Rank": 327,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cc0"
    },
    "Rank": 329,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cc1"
    },
    "Rank": 331,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cc2"
    },
    "Rank": 333,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cc3"
    },
    "Rank": 334,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cc4"
    },
    "Rank": 335,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cc5"
    },
    "Rank": 336,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cc6"
    },
    "Rank": 337,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cc7"
    },
    "Rank": 338,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cc8"
    },
    "Rank": 339,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cc9"
    },
    "Rank": 340,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cca"
    },
    "Rank": 341,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ccb"
    },
    "Rank": 342,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ccc"
    },
    "Rank": 343,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ccd"
    },
    "Rank": 344,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Institute of Medical Sciences (SVIMS), A State University estd. by Govt. of A.P.,Sri Venkateswara Institute of Medical Sciences, SVIMS, Alipiri Road, Tirupati, Andhra Pradesh, 517507",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cce"
    },
    "Rank": 345,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ccf"
    },
    "Rank": 346,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cd0"
    },
    "Rank": 347,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cd1"
    },
    "Rank": 348,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cd2"
    },
    "Rank": 349,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cd3"
    },
    "Rank": 350,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cd4"
    },
    "Rank": 351,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cd5"
    },
    "Rank": 352,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cd6"
    },
    "Rank": 353,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cd7"
    },
    "Rank": 354,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cd8"
    },
    "Rank": 355,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cd9"
    },
    "Rank": 356,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cda"
    },
    "Rank": 357,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cdb"
    },
    "Rank": 358,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cdc"
    },
    "Rank": 359,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cdd"
    },
    "Rank": 360,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cde"
    },
    "Rank": 361,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cdf"
    },
    "Rank": 362,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ce0"
    },
    "Rank": 363,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ce1"
    },
    "Rank": 364,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ce2"
    },
    "Rank": 366,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ce3"
    },
    "Rank": 367,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ce4"
    },
    "Rank": 368,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ce5"
    },
    "Rank": 369,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ce6"
    },
    "Rank": 370,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ce7"
    },
    "Rank": 371,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ce8"
    },
    "Rank": 372,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ce9"
    },
    "Rank": 373,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cea"
    },
    "Rank": 374,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ceb"
    },
    "Rank": 376,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cec"
    },
    "Rank": 377,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ced"
    },
    "Rank": 378,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cee"
    },
    "Rank": 379,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cef"
    },
    "Rank": 380,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cf0"
    },
    "Rank": 381,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cf1"
    },
    "Rank": 382,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cf2"
    },
    "Rank": 383,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cf3"
    },
    "Rank": 384,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cf4"
    },
    "Rank": 385,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cf5"
    },
    "Rank": 386,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cf6"
    },
    "Rank": 387,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cf7"
    },
    "Rank": 388,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cf8"
    },
    "Rank": 389,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cf9"
    },
    "Rank": 390,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cfa"
    },
    "Rank": 391,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cfb"
    },
    "Rank": 392,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cfc"
    },
    "Rank": 393,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cfd"
    },
    "Rank": 394,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cfe"
    },
    "Rank": 395,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02cff"
    },
    "Rank": 396,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d00"
    },
    "Rank": 397,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d01"
    },
    "Rank": 399,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d02"
    },
    "Rank": 400,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d03"
    },
    "Rank": 402,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d04"
    },
    "Rank": 403,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d05"
    },
    "Rank": 404,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d06"
    },
    "Rank": 405,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d07"
    },
    "Rank": 406,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d08"
    },
    "Rank": 407,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d09"
    },
    "Rank": 409,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d0a"
    },
    "Rank": 410,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d0b"
    },
    "Rank": 411,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d0c"
    },
    "Rank": 412,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d0d"
    },
    "Rank": 413,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d0e"
    },
    "Rank": 414,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d0f"
    },
    "Rank": 415,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d10"
    },
    "Rank": 416,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d11"
    },
    "Rank": 417,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d12"
    },
    "Rank": 418,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d13"
    },
    "Rank": 419,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d14"
    },
    "Rank": 420,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d15"
    },
    "Rank": 421,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d16"
    },
    "Rank": 422,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d17"
    },
    "Rank": 424,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d18"
    },
    "Rank": 426,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d19"
    },
    "Rank": 427,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d1a"
    },
    "Rank": 428,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d1b"
    },
    "Rank": 429,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d1c"
    },
    "Rank": 430,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d1d"
    },
    "Rank": 431,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d1e"
    },
    "Rank": 432,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d1f"
    },
    "Rank": 434,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d20"
    },
    "Rank": 435,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d21"
    },
    "Rank": 436,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d22"
    },
    "Rank": 437,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d23"
    },
    "Rank": 438,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d24"
    },
    "Rank": 439,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d25"
    },
    "Rank": 440,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d26"
    },
    "Rank": 441,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d27"
    },
    "Rank": 442,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d28"
    },
    "Rank": 443,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d29"
    },
    "Rank": 444,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d2a"
    },
    "Rank": 445,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d2b"
    },
    "Rank": 446,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Institute of Medical Sciences (SVIMS), A State University estd. by Govt. of A.P.,Sri Venkateswara Institute of Medical Sciences, SVIMS, Alipiri Road, Tirupati, Andhra Pradesh, 517507",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d2c"
    },
    "Rank": 447,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d2d"
    },
    "Rank": 448,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d2e"
    },
    "Rank": 449,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d2f"
    },
    "Rank": 450,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d30"
    },
    "Rank": 451,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d31"
    },
    "Rank": 453,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d32"
    },
    "Rank": 454,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d33"
    },
    "Rank": 455,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d34"
    },
    "Rank": 456,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d35"
    },
    "Rank": 457,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d36"
    },
    "Rank": 458,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d37"
    },
    "Rank": 459,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d38"
    },
    "Rank": 460,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d39"
    },
    "Rank": 461,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d3a"
    },
    "Rank": 462,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d3b"
    },
    "Rank": 463,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d3c"
    },
    "Rank": 464,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d3d"
    },
    "Rank": 465,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d3e"
    },
    "Rank": 466,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d3f"
    },
    "Rank": 468,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d40"
    },
    "Rank": 469,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d41"
    },
    "Rank": 470,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d42"
    },
    "Rank": 471,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d43"
    },
    "Rank": 472,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d44"
    },
    "Rank": 473,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d45"
    },
    "Rank": 474,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d46"
    },
    "Rank": 475,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d47"
    },
    "Rank": 476,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d48"
    },
    "Rank": 477,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d49"
    },
    "Rank": 479,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d4a"
    },
    "Rank": 480,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d4b"
    },
    "Rank": 481,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d4c"
    },
    "Rank": 482,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d4d"
    },
    "Rank": 483,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d4e"
    },
    "Rank": 484,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d4f"
    },
    "Rank": 485,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d50"
    },
    "Rank": 486,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d51"
    },
    "Rank": 487,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d52"
    },
    "Rank": 489,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d53"
    },
    "Rank": 490,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d54"
    },
    "Rank": 492,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d55"
    },
    "Rank": 493,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d56"
    },
    "Rank": 495,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d57"
    },
    "Rank": 496,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kokilaben Dhirubhai Ambani Hospital and Medical Research Institute,,Achyutrao Patwardhan Marg Andheri West Mumbai Maharashtra, Maharashtra, 400053",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d58"
    },
    "Rank": 497,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d59"
    },
    "Rank": 498,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d5a"
    },
    "Rank": 500,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d5b"
    },
    "Rank": 501,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d5c"
    },
    "Rank": 502,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d5d"
    },
    "Rank": 503,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d5e"
    },
    "Rank": 504,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d5f"
    },
    "Rank": 505,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d60"
    },
    "Rank": 506,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d61"
    },
    "Rank": 507,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d62"
    },
    "Rank": 508,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Eastern Indira Gandhi Regional Institute of Health & Medical Sciences, Shillong,North Eastern Indira Gandhi Regional Institute of Health & Medical Sciences, Shillong, Meghalaya, 793018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d63"
    },
    "Rank": 509,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d64"
    },
    "Rank": 510,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d65"
    },
    "Rank": 511,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d66"
    },
    "Rank": 512,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d67"
    },
    "Rank": 513,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d68"
    },
    "Rank": 514,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d69"
    },
    "Rank": 515,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d6a"
    },
    "Rank": 516,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d6b"
    },
    "Rank": 517,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d6c"
    },
    "Rank": 518,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d6d"
    },
    "Rank": 519,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d6e"
    },
    "Rank": 520,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d6f"
    },
    "Rank": 521,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d70"
    },
    "Rank": 522,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d71"
    },
    "Rank": 523,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d72"
    },
    "Rank": 524,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d73"
    },
    "Rank": 525,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Institute of Medical Sciences (SVIMS), A State University estd. by Govt. of A.P.,Sri Venkateswara Institute of Medical Sciences, SVIMS, Alipiri Road, Tirupati, Andhra Pradesh, 517507",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d74"
    },
    "Rank": 527,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d75"
    },
    "Rank": 528,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d76"
    },
    "Rank": 529,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d77"
    },
    "Rank": 530,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kokilaben Dhirubhai Ambani Hospital and Medical Research Institute,,Achyutrao Patwardhan Marg Andheri West Mumbai Maharashtra, Maharashtra, 400053",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d78"
    },
    "Rank": 531,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d79"
    },
    "Rank": 532,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d7a"
    },
    "Rank": 533,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d7b"
    },
    "Rank": 534,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d7c"
    },
    "Rank": 535,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d7d"
    },
    "Rank": 537,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d7e"
    },
    "Rank": 538,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d7f"
    },
    "Rank": 539,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d80"
    },
    "Rank": 540,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d81"
    },
    "Rank": 541,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d82"
    },
    "Rank": 544,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d83"
    },
    "Rank": 545,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d84"
    },
    "Rank": 546,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "P.D. Hinduja National Hospital and Medical Research Centre, ,Veer Savarkar Marg, Mahim, Maharashtra, 400016",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d85"
    },
    "Rank": 547,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d86"
    },
    "Rank": 548,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d87"
    },
    "Rank": 549,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d88"
    },
    "Rank": 550,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d89"
    },
    "Rank": 551,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d8a"
    },
    "Rank": 552,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d8b"
    },
    "Rank": 553,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d8c"
    },
    "Rank": 554,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d8d"
    },
    "Rank": 555,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d8e"
    },
    "Rank": 556,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, ,1,2, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d8f"
    },
    "Rank": 557,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d90"
    },
    "Rank": 558,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d91"
    },
    "Rank": 559,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d92"
    },
    "Rank": 560,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d93"
    },
    "Rank": 562,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sanjay Gandhi Postgarduate Institute of Medical Sciences,Sanjay Gandhi Postgarduate Institute of Medical Sciences, Uttar Pradesh, 226014",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d94"
    },
    "Rank": 563,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d95"
    },
    "Rank": 564,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d96"
    },
    "Rank": 565,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d97"
    },
    "Rank": 566,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d98"
    },
    "Rank": 567,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d99"
    },
    "Rank": 568,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d9a"
    },
    "Rank": 569,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d9b"
    },
    "Rank": 570,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d9c"
    },
    "Rank": 571,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d9d"
    },
    "Rank": 572,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d9e"
    },
    "Rank": 573,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02d9f"
    },
    "Rank": 574,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02da0"
    },
    "Rank": 575,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02da1"
    },
    "Rank": 576,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02da2"
    },
    "Rank": 577,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02da3"
    },
    "Rank": 578,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "P.D. Hinduja National Hospital and Medical Research Centre, ,Veer Savarkar Marg, Mahim, Maharashtra, 400016",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02da4"
    },
    "Rank": 579,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02da5"
    },
    "Rank": 581,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02da6"
    },
    "Rank": 582,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02da7"
    },
    "Rank": 583,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02da8"
    },
    "Rank": 584,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02da9"
    },
    "Rank": 585,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02daa"
    },
    "Rank": 586,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dab"
    },
    "Rank": 587,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dac"
    },
    "Rank": 588,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dad"
    },
    "Rank": 589,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dae"
    },
    "Rank": 591,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02daf"
    },
    "Rank": 592,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02db0"
    },
    "Rank": 594,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02db1"
    },
    "Rank": 596,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02db2"
    },
    "Rank": 597,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02db3"
    },
    "Rank": 598,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02db4"
    },
    "Rank": 600,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02db5"
    },
    "Rank": 601,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02db6"
    },
    "Rank": 602,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02db7"
    },
    "Rank": 603,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02db8"
    },
    "Rank": 604,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02db9"
    },
    "Rank": 605,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dba"
    },
    "Rank": 607,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sanjay Gandhi Postgarduate Institute of Medical Sciences,Sanjay Gandhi Postgarduate Institute of Medical Sciences, Uttar Pradesh, 226014",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dbb"
    },
    "Rank": 608,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dbc"
    },
    "Rank": 609,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dbd"
    },
    "Rank": 610,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dbe"
    },
    "Rank": 611,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dbf"
    },
    "Rank": 612,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dc0"
    },
    "Rank": 614,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dc1"
    },
    "Rank": 615,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dc2"
    },
    "Rank": 616,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dc3"
    },
    "Rank": 617,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dc4"
    },
    "Rank": 618,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dc5"
    },
    "Rank": 619,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dc6"
    },
    "Rank": 621,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dc7"
    },
    "Rank": 623,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dc8"
    },
    "Rank": 624,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dc9"
    },
    "Rank": 625,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dca"
    },
    "Rank": 627,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dcb"
    },
    "Rank": 628,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dcc"
    },
    "Rank": 629,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dcd"
    },
    "Rank": 630,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dce"
    },
    "Rank": 631,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dcf"
    },
    "Rank": 632,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dd0"
    },
    "Rank": 633,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dd1"
    },
    "Rank": 634,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dd2"
    },
    "Rank": 635,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dd3"
    },
    "Rank": 636,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dd4"
    },
    "Rank": 638,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dd5"
    },
    "Rank": 639,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dd6"
    },
    "Rank": 642,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dd7"
    },
    "Rank": 643,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dd8"
    },
    "Rank": 644,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dd9"
    },
    "Rank": 645,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dda"
    },
    "Rank": 647,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ddb"
    },
    "Rank": 648,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ddc"
    },
    "Rank": 649,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ddd"
    },
    "Rank": 650,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dde"
    },
    "Rank": 651,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ddf"
    },
    "Rank": 652,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02de0"
    },
    "Rank": 653,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02de1"
    },
    "Rank": 654,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02de2"
    },
    "Rank": 655,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02de3"
    },
    "Rank": 656,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02de4"
    },
    "Rank": 657,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaslok Hospital and Research Centre, ,15, Dr. G. Deshmukh Marg, Mumbai - 400026, Maharashtra, 400026",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02de5"
    },
    "Rank": 659,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02de6"
    },
    "Rank": 660,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02de7"
    },
    "Rank": 661,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02de8"
    },
    "Rank": 662,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02de9"
    },
    "Rank": 663,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dea"
    },
    "Rank": 664,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02deb"
    },
    "Rank": 665,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dec"
    },
    "Rank": 666,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ded"
    },
    "Rank": 667,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dee"
    },
    "Rank": 668,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02def"
    },
    "Rank": 669,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02df0"
    },
    "Rank": 670,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02df1"
    },
    "Rank": 672,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02df2"
    },
    "Rank": 674,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02df3"
    },
    "Rank": 675,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02df4"
    },
    "Rank": 676,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02df5"
    },
    "Rank": 677,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02df6"
    },
    "Rank": 678,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02df7"
    },
    "Rank": 679,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02df8"
    },
    "Rank": 680,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02df9"
    },
    "Rank": 681,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dfa"
    },
    "Rank": 682,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dfb"
    },
    "Rank": 683,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dfc"
    },
    "Rank": 684,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dfd"
    },
    "Rank": 685,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dfe"
    },
    "Rank": 686,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02dff"
    },
    "Rank": 687,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e00"
    },
    "Rank": 688,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e01"
    },
    "Rank": 689,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e02"
    },
    "Rank": 690,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e03"
    },
    "Rank": 691,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e04"
    },
    "Rank": 693,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e05"
    },
    "Rank": 696,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e06"
    },
    "Rank": 697,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e07"
    },
    "Rank": 698,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e08"
    },
    "Rank": 699,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e09"
    },
    "Rank": 700,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e0a"
    },
    "Rank": 701,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e0b"
    },
    "Rank": 703,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e0c"
    },
    "Rank": 704,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e0d"
    },
    "Rank": 705,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e0e"
    },
    "Rank": 706,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e0f"
    },
    "Rank": 707,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e10"
    },
    "Rank": 708,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e11"
    },
    "Rank": 709,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e12"
    },
    "Rank": 710,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaslok Hospital and Research Centre, ,15, Dr. G. Deshmukh Marg, Mumbai - 400026, Maharashtra, 400026",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e13"
    },
    "Rank": 711,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e14"
    },
    "Rank": 713,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e15"
    },
    "Rank": 716,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e16"
    },
    "Rank": 718,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e17"
    },
    "Rank": 719,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e18"
    },
    "Rank": 720,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e19"
    },
    "Rank": 721,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e1a"
    },
    "Rank": 722,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e1b"
    },
    "Rank": 723,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e1c"
    },
    "Rank": 724,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e1d"
    },
    "Rank": 725,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e1e"
    },
    "Rank": 726,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e1f"
    },
    "Rank": 727,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e20"
    },
    "Rank": 728,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e21"
    },
    "Rank": 729,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lilavati Hospital and Research Centre, ,Lilavati Hospital And Research Centre A-791, Bandra Reclamation, Bandra - West, Mumbai, Maharashtra, 400050",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e22"
    },
    "Rank": 730,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e23"
    },
    "Rank": 731,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Stephen`s Hospital, ,Tis Hazari, Delhi (NCT), 110054",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e24"
    },
    "Rank": 732,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e25"
    },
    "Rank": 733,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e26"
    },
    "Rank": 734,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e27"
    },
    "Rank": 735,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e28"
    },
    "Rank": 736,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e29"
    },
    "Rank": 737,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e2a"
    },
    "Rank": 738,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e2b"
    },
    "Rank": 739,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e2c"
    },
    "Rank": 740,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e2d"
    },
    "Rank": 741,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Mulund - Goregaon Link Rd, Nahur West, Industrial Area, Bhandup West, Mumbai, Maharashtra 400078, Maharashtra, 400078",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e2e"
    },
    "Rank": 743,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e2f"
    },
    "Rank": 745,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e30"
    },
    "Rank": 746,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e31"
    },
    "Rank": 747,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e32"
    },
    "Rank": 748,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e33"
    },
    "Rank": 749,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e34"
    },
    "Rank": 750,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e35"
    },
    "Rank": 752,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e36"
    },
    "Rank": 753,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e37"
    },
    "Rank": 755,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e38"
    },
    "Rank": 756,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e39"
    },
    "Rank": 757,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e3a"
    },
    "Rank": 758,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e3b"
    },
    "Rank": 759,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e3c"
    },
    "Rank": 760,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e3d"
    },
    "Rank": 761,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e3e"
    },
    "Rank": 762,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e3f"
    },
    "Rank": 763,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e40"
    },
    "Rank": 764,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e41"
    },
    "Rank": 765,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e42"
    },
    "Rank": 766,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e43"
    },
    "Rank": 767,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Hurkisondas Nurrotumdas Hospital and Research Centre, ,Prarthana Samaj, Raja Ram Mohan Roy road,\nGirgaon, MUMBAI, Maharashtra, 400004",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e44"
    },
    "Rank": 768,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e45"
    },
    "Rank": 769,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e46"
    },
    "Rank": 770,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e47"
    },
    "Rank": 771,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e48"
    },
    "Rank": 772,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e49"
    },
    "Rank": 774,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e4a"
    },
    "Rank": 776,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e4b"
    },
    "Rank": 777,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e4c"
    },
    "Rank": 778,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e4d"
    },
    "Rank": 779,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e4e"
    },
    "Rank": 780,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e4f"
    },
    "Rank": 781,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e50"
    },
    "Rank": 782,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e51"
    },
    "Rank": 783,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e52"
    },
    "Rank": 784,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e53"
    },
    "Rank": 785,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e54"
    },
    "Rank": 788,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e55"
    },
    "Rank": 789,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e56"
    },
    "Rank": 790,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI INSTITUTE OF CHILD HEALTH, BANGALORE,INDIRA GANDHI INSTITUTE OF CHILD HEALTH, BANGALORE, Karnataka, 560029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e57"
    },
    "Rank": 791,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e58"
    },
    "Rank": 793,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e59"
    },
    "Rank": 794,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e5a"
    },
    "Rank": 795,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e5b"
    },
    "Rank": 797,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e5c"
    },
    "Rank": 798,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e5d"
    },
    "Rank": 799,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e5e"
    },
    "Rank": 800,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e5f"
    },
    "Rank": 802,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e60"
    },
    "Rank": 805,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e61"
    },
    "Rank": 806,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e62"
    },
    "Rank": 808,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e63"
    },
    "Rank": 809,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e64"
    },
    "Rank": 810,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e65"
    },
    "Rank": 811,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e66"
    },
    "Rank": 812,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE - KANNUR,GOVERNMENT MEDICAL COLLEGE\n- KANNUR, Kerala, 670503",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e67"
    },
    "Rank": 813,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e68"
    },
    "Rank": 814,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e69"
    },
    "Rank": 815,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e6a"
    },
    "Rank": 816,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e6b"
    },
    "Rank": 817,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e6c"
    },
    "Rank": 818,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e6d"
    },
    "Rank": 820,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e6e"
    },
    "Rank": 822,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e6f"
    },
    "Rank": 823,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE - KANNUR,GOVERNMENT MEDICAL COLLEGE\n- KANNUR, Kerala, 670503",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e70"
    },
    "Rank": 824,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e71"
    },
    "Rank": 825,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e72"
    },
    "Rank": 826,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e73"
    },
    "Rank": 827,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e74"
    },
    "Rank": 829,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e75"
    },
    "Rank": 830,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e76"
    },
    "Rank": 831,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,HBT Medical College and Dr R N Cooper Municipal General Hospital, Bhaktivedanta Swami Rd, JVPD Schem, Maharashtra, 400056",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e77"
    },
    "Rank": 832,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e78"
    },
    "Rank": 833,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e79"
    },
    "Rank": 834,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e7a"
    },
    "Rank": 835,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e7b"
    },
    "Rank": 836,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e7c"
    },
    "Rank": 837,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e7d"
    },
    "Rank": 838,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e7e"
    },
    "Rank": 839,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e7f"
    },
    "Rank": 840,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e80"
    },
    "Rank": 841,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e81"
    },
    "Rank": 842,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e82"
    },
    "Rank": 843,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e83"
    },
    "Rank": 844,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e84"
    },
    "Rank": 845,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e85"
    },
    "Rank": 846,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,HBT Medical College and Dr R N Cooper Municipal General Hospital, Bhaktivedanta Swami Rd, JVPD Schem, Maharashtra, 400056",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e86"
    },
    "Rank": 847,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e87"
    },
    "Rank": 848,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e88"
    },
    "Rank": 849,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e89"
    },
    "Rank": 850,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e8a"
    },
    "Rank": 851,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e8b"
    },
    "Rank": 852,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE - KANNUR,GOVERNMENT MEDICAL COLLEGE\n- KANNUR, Kerala, 670503",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e8c"
    },
    "Rank": 853,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e8d"
    },
    "Rank": 854,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e8e"
    },
    "Rank": 855,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e8f"
    },
    "Rank": 857,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e90"
    },
    "Rank": 858,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e91"
    },
    "Rank": 859,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "P.D. Hinduja National Hospital and Medical Research Centre, ,Veer Savarkar Marg, Mahim, Maharashtra, 400016",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e92"
    },
    "Rank": 860,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e93"
    },
    "Rank": 861,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e94"
    },
    "Rank": 862,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e95"
    },
    "Rank": 864,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e96"
    },
    "Rank": 865,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e97"
    },
    "Rank": 866,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e98"
    },
    "Rank": 867,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e99"
    },
    "Rank": 868,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e9a"
    },
    "Rank": 870,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e9b"
    },
    "Rank": 871,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e9c"
    },
    "Rank": 872,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kidwai Memorial Institute of Oncolgy,Kidwai Memorial Institute of Oncolgy, Karnataka, 560029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e9d"
    },
    "Rank": 875,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e9e"
    },
    "Rank": 876,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02e9f"
    },
    "Rank": 877,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ea0"
    },
    "Rank": 878,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ea1"
    },
    "Rank": 879,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ea2"
    },
    "Rank": 880,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ea3"
    },
    "Rank": 882,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ea4"
    },
    "Rank": 883,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ea5"
    },
    "Rank": 884,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ea6"
    },
    "Rank": 885,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ea7"
    },
    "Rank": 887,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ea8"
    },
    "Rank": 888,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ea9"
    },
    "Rank": 889,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02eaa"
    },
    "Rank": 890,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02eab"
    },
    "Rank": 891,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02eac"
    },
    "Rank": 892,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ead"
    },
    "Rank": 893,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02eae"
    },
    "Rank": 895,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute of Medical Sciences Srikakulam,Government Medical College Srikakulam\nBalaga Srikakulam Town Srikakulam District, Andhra Pradesh, 532001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02eaf"
    },
    "Rank": 896,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02eb0"
    },
    "Rank": 897,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02eb1"
    },
    "Rank": 899,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02eb2"
    },
    "Rank": 901,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02eb3"
    },
    "Rank": 902,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI INSTITUTE OF CHILD HEALTH, BANGALORE,INDIRA GANDHI INSTITUTE OF CHILD HEALTH, BANGALORE, Karnataka, 560029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02eb4"
    },
    "Rank": 903,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02eb5"
    },
    "Rank": 904,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02eb6"
    },
    "Rank": 905,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02eb7"
    },
    "Rank": 906,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02eb8"
    },
    "Rank": 908,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02eb9"
    },
    "Rank": 910,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02eba"
    },
    "Rank": 911,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ebb"
    },
    "Rank": 912,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Medical Center, ,14 MAJOR ARTERIAL ROAD EW NEWTOWN RAJARHAT KOLKATA 700160, West Bengal, 700160",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ebc"
    },
    "Rank": 913,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ebd"
    },
    "Rank": 914,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ebe"
    },
    "Rank": 915,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ebf"
    },
    "Rank": 916,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ec0"
    },
    "Rank": 917,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ec1"
    },
    "Rank": 918,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ec2"
    },
    "Rank": 920,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ec3"
    },
    "Rank": 921,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ec4"
    },
    "Rank": 922,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ec5"
    },
    "Rank": 923,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ec6"
    },
    "Rank": 925,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ec7"
    },
    "Rank": 926,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ec8"
    },
    "Rank": 927,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ec9"
    },
    "Rank": 928,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02eca"
    },
    "Rank": 929,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ecb"
    },
    "Rank": 930,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ecc"
    },
    "Rank": 931,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Institute of Medical Sciences (SVIMS), A State University estd. by Govt. of A.P.,Sri Venkateswara Institute of Medical Sciences, SVIMS, Alipiri Road, Tirupati, Andhra Pradesh, 517507",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ecd"
    },
    "Rank": 932,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ece"
    },
    "Rank": 933,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI INSTITUTE OF CHILD HEALTH, BANGALORE,INDIRA GANDHI INSTITUTE OF CHILD HEALTH, BANGALORE, Karnataka, 560029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ecf"
    },
    "Rank": 935,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ed0"
    },
    "Rank": 936,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ed1"
    },
    "Rank": 937,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ed2"
    },
    "Rank": 938,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ed3"
    },
    "Rank": 939,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ed4"
    },
    "Rank": 940,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ed5"
    },
    "Rank": 941,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ed6"
    },
    "Rank": 942,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ed7"
    },
    "Rank": 944,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ed8"
    },
    "Rank": 945,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ed9"
    },
    "Rank": 946,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02eda"
    },
    "Rank": 947,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02edb"
    },
    "Rank": 948,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sanjay Gandhi Postgarduate Institute of Medical Sciences,Sanjay Gandhi Postgarduate Institute of Medical Sciences, Uttar Pradesh, 226014",
    "Course": "M.D. IN NUCLEAR MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02edc"
    },
    "Rank": 950,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02edd"
    },
    "Rank": 952,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ede"
    },
    "Rank": 953,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02edf"
    },
    "Rank": 954,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ee0"
    },
    "Rank": 955,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ee1"
    },
    "Rank": 956,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ee2"
    },
    "Rank": 957,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ee3"
    },
    "Rank": 958,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ee4"
    },
    "Rank": 960,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ee5"
    },
    "Rank": 961,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ee6"
    },
    "Rank": 962,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ee7"
    },
    "Rank": 963,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ee8"
    },
    "Rank": 964,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ee9"
    },
    "Rank": 965,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02eea"
    },
    "Rank": 966,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02eeb"
    },
    "Rank": 967,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02eec"
    },
    "Rank": 968,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02eed"
    },
    "Rank": 969,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02eee"
    },
    "Rank": 970,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02eef"
    },
    "Rank": 971,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ef0"
    },
    "Rank": 972,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ef1"
    },
    "Rank": 974,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ef2"
    },
    "Rank": 975,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ef3"
    },
    "Rank": 976,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ef4"
    },
    "Rank": 977,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ef5"
    },
    "Rank": 978,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ef6"
    },
    "Rank": 979,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ef7"
    },
    "Rank": 980,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN,HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN, Karnataka, 573201",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ef8"
    },
    "Rank": 981,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ef9"
    },
    "Rank": 982,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02efa"
    },
    "Rank": 983,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pimpri Chichwad Municipal Corporation Postgraduate Institute Y.C.M.Hospital Pimpri,Pune 18,Dean Office, Y.C.M.Hospital, Sant Tukaramnagar Pimpri, Pune, Maharashtra, 411018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02efb"
    },
    "Rank": 984,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02efc"
    },
    "Rank": 986,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02efd"
    },
    "Rank": 987,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02efe"
    },
    "Rank": 988,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02eff"
    },
    "Rank": 989,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f00"
    },
    "Rank": 990,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f01"
    },
    "Rank": 991,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f02"
    },
    "Rank": 992,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f03"
    },
    "Rank": 993,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f04"
    },
    "Rank": 994,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lilavati Hospital and Research Centre, ,Lilavati Hospital And Research Centre A-791, Bandra Reclamation, Bandra - West, Mumbai, Maharashtra, 400050",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f05"
    },
    "Rank": 995,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f06"
    },
    "Rank": 997,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f07"
    },
    "Rank": 998,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Hurkisondas Nurrotumdas Hospital and Research Centre, ,Prarthana Samaj, Raja Ram Mohan Roy road,\nGirgaon, MUMBAI, Maharashtra, 400004",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f08"
    },
    "Rank": 999,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f09"
    },
    "Rank": 1000,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Batra Hospital and Medical Research Centre, ,1, Tughlakabad institutional Area, Mehrauli Badarpur Road, New Delhi, Delhi (NCT), 110062",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f0a"
    },
    "Rank": 1001,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f0b"
    },
    "Rank": 1002,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f0c"
    },
    "Rank": 1003,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f0d"
    },
    "Rank": 1004,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f0e"
    },
    "Rank": 1005,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f0f"
    },
    "Rank": 1006,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f10"
    },
    "Rank": 1008,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f11"
    },
    "Rank": 1009,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f12"
    },
    "Rank": 1010,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f13"
    },
    "Rank": 1011,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f14"
    },
    "Rank": 1013,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f15"
    },
    "Rank": 1014,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f16"
    },
    "Rank": 1016,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f17"
    },
    "Rank": 1017,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f18"
    },
    "Rank": 1018,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f19"
    },
    "Rank": 1019,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f1a"
    },
    "Rank": 1020,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f1b"
    },
    "Rank": 1021,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f1c"
    },
    "Rank": 1022,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f1d"
    },
    "Rank": 1024,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f1e"
    },
    "Rank": 1025,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f1f"
    },
    "Rank": 1026,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Malabar Institute of Medical Sciences, ,Govindapuram P.O., Mini Bypass Road, Kozhikode, Kerala, 673016",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f20"
    },
    "Rank": 1027,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f21"
    },
    "Rank": 1028,
    "Allotted Quota": "All India",
    "Allotted Institute": "GADAG INSTITUTE OF MEDICAL SCIENCES GADAG,Gadag Institute of Medical Sciences Mallasamudra Gadag-582103, Karnataka, 582103",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f22"
    },
    "Rank": 1029,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f23"
    },
    "Rank": 1031,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f24"
    },
    "Rank": 1032,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f25"
    },
    "Rank": 1033,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f26"
    },
    "Rank": 1034,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f27"
    },
    "Rank": 1036,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f28"
    },
    "Rank": 1037,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f29"
    },
    "Rank": 1039,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f2a"
    },
    "Rank": 1040,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f2b"
    },
    "Rank": 1042,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f2c"
    },
    "Rank": 1043,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Poona Hospital and Research Centre,,27,Sadashiv Peth, Pune -411030, Maharashtra, 411030",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f2d"
    },
    "Rank": 1044,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f2e"
    },
    "Rank": 1045,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f2f"
    },
    "Rank": 1046,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f30"
    },
    "Rank": 1047,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f31"
    },
    "Rank": 1048,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f32"
    },
    "Rank": 1049,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f33"
    },
    "Rank": 1050,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f34"
    },
    "Rank": 1051,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f35"
    },
    "Rank": 1052,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f36"
    },
    "Rank": 1053,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f37"
    },
    "Rank": 1055,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f38"
    },
    "Rank": 1056,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f39"
    },
    "Rank": 1057,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f3a"
    },
    "Rank": 1058,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f3b"
    },
    "Rank": 1059,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Malabar Institute of Medical Sciences, ,Govindapuram P.O., Mini Bypass Road, Kozhikode, Kerala, 673016",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f3c"
    },
    "Rank": 1060,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f3d"
    },
    "Rank": 1062,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f3e"
    },
    "Rank": 1063,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f3f"
    },
    "Rank": 1064,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f40"
    },
    "Rank": 1066,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f41"
    },
    "Rank": 1067,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f42"
    },
    "Rank": 1068,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f43"
    },
    "Rank": 1069,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f44"
    },
    "Rank": 1071,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f45"
    },
    "Rank": 1072,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f46"
    },
    "Rank": 1073,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f47"
    },
    "Rank": 1074,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f48"
    },
    "Rank": 1078,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f49"
    },
    "Rank": 1079,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B. Nanavati Hospital, Vivekanand Rd,Swami Vivekananda Rd, near LIC, LIC Colony, Suresh Colony, Vile Parle West, Mumbai, Maharashtra 4000, Maharashtra, 400056",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f4a"
    },
    "Rank": 1080,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f4b"
    },
    "Rank": 1081,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f4c"
    },
    "Rank": 1082,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f4d"
    },
    "Rank": 1083,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f4e"
    },
    "Rank": 1084,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f4f"
    },
    "Rank": 1085,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f50"
    },
    "Rank": 1086,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f51"
    },
    "Rank": 1087,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f52"
    },
    "Rank": 1088,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f53"
    },
    "Rank": 1089,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f54"
    },
    "Rank": 1090,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI INSTITUTE OF CHILD HEALTH, BANGALORE,INDIRA GANDHI INSTITUTE OF CHILD HEALTH, BANGALORE, Karnataka, 560029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f55"
    },
    "Rank": 1091,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f56"
    },
    "Rank": 1093,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f57"
    },
    "Rank": 1094,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f58"
    },
    "Rank": 1095,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f59"
    },
    "Rank": 1096,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f5a"
    },
    "Rank": 1097,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f5b"
    },
    "Rank": 1098,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f5c"
    },
    "Rank": 1099,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f5d"
    },
    "Rank": 1100,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f5e"
    },
    "Rank": 1101,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f5f"
    },
    "Rank": 1102,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f60"
    },
    "Rank": 1103,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f61"
    },
    "Rank": 1105,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f62"
    },
    "Rank": 1106,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f63"
    },
    "Rank": 1107,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f64"
    },
    "Rank": 1108,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f65"
    },
    "Rank": 1109,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f66"
    },
    "Rank": 1110,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN,HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN, Karnataka, 573201",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f67"
    },
    "Rank": 1111,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f68"
    },
    "Rank": 1112,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f69"
    },
    "Rank": 1113,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f6a"
    },
    "Rank": 1115,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute of Medical Sciences Srikakulam,Government Medical College Srikakulam\nBalaga Srikakulam Town Srikakulam District, Andhra Pradesh, 532001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f6b"
    },
    "Rank": 1116,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f6c"
    },
    "Rank": 1117,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f6d"
    },
    "Rank": 1118,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f6e"
    },
    "Rank": 1119,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f6f"
    },
    "Rank": 1120,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f70"
    },
    "Rank": 1121,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f71"
    },
    "Rank": 1122,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f72"
    },
    "Rank": 1123,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f73"
    },
    "Rank": 1124,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f74"
    },
    "Rank": 1125,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f75"
    },
    "Rank": 1126,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f76"
    },
    "Rank": 1127,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f77"
    },
    "Rank": 1129,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f78"
    },
    "Rank": 1130,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN,HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN, Karnataka, 573201",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f79"
    },
    "Rank": 1131,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f7a"
    },
    "Rank": 1132,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f7b"
    },
    "Rank": 1133,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f7c"
    },
    "Rank": 1134,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f7d"
    },
    "Rank": 1135,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f7e"
    },
    "Rank": 1136,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f7f"
    },
    "Rank": 1137,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f80"
    },
    "Rank": 1138,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f81"
    },
    "Rank": 1139,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f82"
    },
    "Rank": 1140,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f83"
    },
    "Rank": 1141,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f84"
    },
    "Rank": 1142,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f85"
    },
    "Rank": 1143,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f86"
    },
    "Rank": 1144,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f87"
    },
    "Rank": 1145,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f88"
    },
    "Rank": 1149,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f89"
    },
    "Rank": 1150,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f8a"
    },
    "Rank": 1152,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f8b"
    },
    "Rank": 1153,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f8c"
    },
    "Rank": 1154,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f8d"
    },
    "Rank": 1155,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f8e"
    },
    "Rank": 1156,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f8f"
    },
    "Rank": 1157,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f90"
    },
    "Rank": 1158,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f91"
    },
    "Rank": 1159,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f92"
    },
    "Rank": 1160,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f93"
    },
    "Rank": 1161,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f94"
    },
    "Rank": 1162,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f95"
    },
    "Rank": 1163,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f96"
    },
    "Rank": 1165,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f97"
    },
    "Rank": 1166,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f98"
    },
    "Rank": 1167,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f99"
    },
    "Rank": 1168,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f9a"
    },
    "Rank": 1169,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jalgaon,Jilha Peth, Government Hospital Campus, Jalgaon, Maharashtra, 425001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f9b"
    },
    "Rank": 1170,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f9c"
    },
    "Rank": 1171,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f9d"
    },
    "Rank": 1172,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f9e"
    },
    "Rank": 1173,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02f9f"
    },
    "Rank": 1174,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fa0"
    },
    "Rank": 1175,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fa1"
    },
    "Rank": 1176,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fa2"
    },
    "Rank": 1177,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fa3"
    },
    "Rank": 1178,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fa4"
    },
    "Rank": 1179,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fa5"
    },
    "Rank": 1180,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fa6"
    },
    "Rank": 1181,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fa7"
    },
    "Rank": 1183,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fa8"
    },
    "Rank": 1184,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fa9"
    },
    "Rank": 1185,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02faa"
    },
    "Rank": 1186,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fab"
    },
    "Rank": 1187,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fac"
    },
    "Rank": 1190,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fad"
    },
    "Rank": 1191,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fae"
    },
    "Rank": 1192,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02faf"
    },
    "Rank": 1193,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fb0"
    },
    "Rank": 1194,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fb1"
    },
    "Rank": 1195,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fb2"
    },
    "Rank": 1196,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fb3"
    },
    "Rank": 1197,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fb4"
    },
    "Rank": 1198,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fb5"
    },
    "Rank": 1199,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fb6"
    },
    "Rank": 1201,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fb7"
    },
    "Rank": 1202,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B. Nanavati Hospital, Vivekanand Rd,Swami Vivekananda Rd, near LIC, LIC Colony, Suresh Colony, Vile Parle West, Mumbai, Maharashtra 4000, Maharashtra, 400056",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fb8"
    },
    "Rank": 1203,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pimpri Chichwad Municipal Corporation Postgraduate Institute Y.C.M.Hospital Pimpri,Pune 18,Dean Office, Y.C.M.Hospital, Sant Tukaramnagar Pimpri, Pune, Maharashtra, 411018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fb9"
    },
    "Rank": 1205,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fba"
    },
    "Rank": 1206,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fbb"
    },
    "Rank": 1207,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fbc"
    },
    "Rank": 1208,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College,Tumuki, Bihaguri, Tezpur, Sonitpur, Assam, Assam, 784010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fbd"
    },
    "Rank": 1209,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fbe"
    },
    "Rank": 1210,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fbf"
    },
    "Rank": 1211,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fc0"
    },
    "Rank": 1213,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fc1"
    },
    "Rank": 1214,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fc2"
    },
    "Rank": 1215,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fc3"
    },
    "Rank": 1216,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaikh-Ul-Hind Maulana Mahmood Hasan Medical College, Saharanpur,Pilkhani Ambala Road, Uttar Pradesh, 247232",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fc4"
    },
    "Rank": 1217,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fc5"
    },
    "Rank": 1218,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fc6"
    },
    "Rank": 1219,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fc7"
    },
    "Rank": 1220,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fc8"
    },
    "Rank": 1221,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fc9"
    },
    "Rank": 1222,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fca"
    },
    "Rank": 1223,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Institute of Medical Sciences (SVIMS), A State University estd. by Govt. of A.P.,Sri Venkateswara Institute of Medical Sciences, SVIMS, Alipiri Road, Tirupati, Andhra Pradesh, 517507",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fcb"
    },
    "Rank": 1224,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fcc"
    },
    "Rank": 1225,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fcd"
    },
    "Rank": 1226,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fce"
    },
    "Rank": 1227,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fcf"
    },
    "Rank": 1228,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fd0"
    },
    "Rank": 1230,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fd1"
    },
    "Rank": 1232,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fd2"
    },
    "Rank": 1235,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fd3"
    },
    "Rank": 1236,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fd4"
    },
    "Rank": 1237,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE - KANNUR,GOVERNMENT MEDICAL COLLEGE\n- KANNUR, Kerala, 670503",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fd5"
    },
    "Rank": 1238,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fd6"
    },
    "Rank": 1239,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fd7"
    },
    "Rank": 1240,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fd8"
    },
    "Rank": 1243,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fd9"
    },
    "Rank": 1245,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fda"
    },
    "Rank": 1246,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fdb"
    },
    "Rank": 1247,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fdc"
    },
    "Rank": 1248,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fdd"
    },
    "Rank": 1249,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fde"
    },
    "Rank": 1250,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fdf"
    },
    "Rank": 1251,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fe0"
    },
    "Rank": 1252,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fe1"
    },
    "Rank": 1253,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fe2"
    },
    "Rank": 1254,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fe3"
    },
    "Rank": 1256,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fe4"
    },
    "Rank": 1258,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fe5"
    },
    "Rank": 1259,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fe6"
    },
    "Rank": 1260,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fe7"
    },
    "Rank": 1261,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fe8"
    },
    "Rank": 1262,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fe9"
    },
    "Rank": 1263,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fea"
    },
    "Rank": 1264,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02feb"
    },
    "Rank": 1265,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta The Medicity, ,Medanta The Medicity Sector 38 Gurgoan, Haryana, 122001",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fec"
    },
    "Rank": 1266,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster Medcity, Kuttisahib Road,,Aster Medcity Kuttisahib Road\nSouth Chittoor P.O. Cheranalloor\nKochi, Kerala, 682027",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fed"
    },
    "Rank": 1268,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fee"
    },
    "Rank": 1269,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fef"
    },
    "Rank": 1270,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ff0"
    },
    "Rank": 1271,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ff1"
    },
    "Rank": 1272,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ff2"
    },
    "Rank": 1274,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ff3"
    },
    "Rank": 1275,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ff4"
    },
    "Rank": 1277,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ff5"
    },
    "Rank": 1278,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ff6"
    },
    "Rank": 1279,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ff7"
    },
    "Rank": 1280,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ff8"
    },
    "Rank": 1281,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ff9"
    },
    "Rank": 1282,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ffa"
    },
    "Rank": 1283,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ffb"
    },
    "Rank": 1284,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ffc"
    },
    "Rank": 1286,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ffd"
    },
    "Rank": 1287,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02ffe"
    },
    "Rank": 1289,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a02fff"
    },
    "Rank": 1290,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03000"
    },
    "Rank": 1291,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03001"
    },
    "Rank": 1292,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03002"
    },
    "Rank": 1293,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03003"
    },
    "Rank": 1294,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03004"
    },
    "Rank": 1295,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03005"
    },
    "Rank": 1296,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03006"
    },
    "Rank": 1297,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03007"
    },
    "Rank": 1298,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03008"
    },
    "Rank": 1299,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03009"
    },
    "Rank": 1300,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0300a"
    },
    "Rank": 1301,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0300b"
    },
    "Rank": 1302,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Meenakshi Mission Hospital and Research Centre, ,Lake Area, Melur Road, Madurai, Tamil Nadu, 625107",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0300c"
    },
    "Rank": 1303,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0300d"
    },
    "Rank": 1304,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0300e"
    },
    "Rank": 1305,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0300f"
    },
    "Rank": 1306,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03010"
    },
    "Rank": 1307,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03011"
    },
    "Rank": 1308,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03012"
    },
    "Rank": 1310,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03013"
    },
    "Rank": 1311,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03014"
    },
    "Rank": 1313,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03015"
    },
    "Rank": 1314,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03016"
    },
    "Rank": 1315,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03017"
    },
    "Rank": 1316,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03018"
    },
    "Rank": 1317,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03019"
    },
    "Rank": 1319,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0301a"
    },
    "Rank": 1320,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0301b"
    },
    "Rank": 1321,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Meenakshi Mission Hospital and Research Centre, ,Lake Area, Melur Road, Madurai, Tamil Nadu, 625107",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0301c"
    },
    "Rank": 1322,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0301d"
    },
    "Rank": 1323,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0301e"
    },
    "Rank": 1324,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0301f"
    },
    "Rank": 1325,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03020"
    },
    "Rank": 1326,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03021"
    },
    "Rank": 1328,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03022"
    },
    "Rank": 1329,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03023"
    },
    "Rank": 1330,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03024"
    },
    "Rank": 1331,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03025"
    },
    "Rank": 1332,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03026"
    },
    "Rank": 1333,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03027"
    },
    "Rank": 1334,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College, Kolhapur,R.K. Nagar Road, Shenda Park, Kolhapur, Maharashtra, Maharashtra, 416012",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03028"
    },
    "Rank": 1335,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03029"
    },
    "Rank": 1336,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0302a"
    },
    "Rank": 1337,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0302b"
    },
    "Rank": 1338,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0302c"
    },
    "Rank": 1340,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0302d"
    },
    "Rank": 1341,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0302e"
    },
    "Rank": 1342,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0302f"
    },
    "Rank": 1343,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03030"
    },
    "Rank": 1344,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03031"
    },
    "Rank": 1345,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03032"
    },
    "Rank": 1346,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03033"
    },
    "Rank": 1347,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03034"
    },
    "Rank": 1348,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03035"
    },
    "Rank": 1349,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03036"
    },
    "Rank": 1350,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03037"
    },
    "Rank": 1351,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03038"
    },
    "Rank": 1352,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03039"
    },
    "Rank": 1354,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0303a"
    },
    "Rank": 1355,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0303b"
    },
    "Rank": 1356,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0303c"
    },
    "Rank": 1358,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0303d"
    },
    "Rank": 1360,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0303e"
    },
    "Rank": 1361,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0303f"
    },
    "Rank": 1363,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03040"
    },
    "Rank": 1364,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03041"
    },
    "Rank": 1365,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03042"
    },
    "Rank": 1366,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03043"
    },
    "Rank": 1368,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03044"
    },
    "Rank": 1370,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03045"
    },
    "Rank": 1371,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03046"
    },
    "Rank": 1372,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03047"
    },
    "Rank": 1373,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03048"
    },
    "Rank": 1374,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF HUMAN  BEHAVIOUR AND ALLIED SCIENCES,Institute of Human\nBehaviour and Allied Sciences,  Dilshad Garden, New Delhi, Delhi 110095, India, Delhi (NCT), 110095",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03049"
    },
    "Rank": 1376,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0304a"
    },
    "Rank": 1377,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0304b"
    },
    "Rank": 1378,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0304c"
    },
    "Rank": 1380,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0304d"
    },
    "Rank": 1382,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0304e"
    },
    "Rank": 1383,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0304f"
    },
    "Rank": 1384,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03050"
    },
    "Rank": 1386,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03051"
    },
    "Rank": 1387,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03052"
    },
    "Rank": 1388,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03053"
    },
    "Rank": 1389,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sanjay Gandhi Postgarduate Institute of Medical Sciences,Sanjay Gandhi Postgarduate Institute of Medical Sciences, Uttar Pradesh, 226014",
    "Course": "M.D. IN NUCLEAR MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03054"
    },
    "Rank": 1390,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03055"
    },
    "Rank": 1391,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03056"
    },
    "Rank": 1392,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03057"
    },
    "Rank": 1393,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03058"
    },
    "Rank": 1394,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03059"
    },
    "Rank": 1396,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0305a"
    },
    "Rank": 1397,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0305b"
    },
    "Rank": 1398,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0305c"
    },
    "Rank": 1399,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0305d"
    },
    "Rank": 1400,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0305e"
    },
    "Rank": 1401,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0305f"
    },
    "Rank": 1402,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03060"
    },
    "Rank": 1403,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03061"
    },
    "Rank": 1405,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03062"
    },
    "Rank": 1406,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03063"
    },
    "Rank": 1407,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03064"
    },
    "Rank": 1408,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03065"
    },
    "Rank": 1409,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03066"
    },
    "Rank": 1410,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03067"
    },
    "Rank": 1411,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03068"
    },
    "Rank": 1412,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03069"
    },
    "Rank": 1413,
    "Allotted Quota": "All India",
    "Allotted Institute": "Murshidabad Medical College and Hospital,Station Road, Berhampore, Murshidabad, West Bengal, 742101",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0306a"
    },
    "Rank": 1414,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0306b"
    },
    "Rank": 1415,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0306c"
    },
    "Rank": 1417,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0306d"
    },
    "Rank": 1418,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, ,1,2, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0306e"
    },
    "Rank": 1419,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0306f"
    },
    "Rank": 1420,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03070"
    },
    "Rank": 1422,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pimpri Chichwad Municipal Corporation Postgraduate Institute Y.C.M.Hospital Pimpri,Pune 18,Dean Office, Y.C.M.Hospital, Sant Tukaramnagar Pimpri, Pune, Maharashtra, 411018",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03071"
    },
    "Rank": 1423,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03072"
    },
    "Rank": 1424,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03073"
    },
    "Rank": 1425,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03074"
    },
    "Rank": 1426,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03075"
    },
    "Rank": 1427,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03076"
    },
    "Rank": 1428,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03077"
    },
    "Rank": 1429,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03078"
    },
    "Rank": 1430,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03079"
    },
    "Rank": 1432,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0307a"
    },
    "Rank": 1433,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0307b"
    },
    "Rank": 1435,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0307c"
    },
    "Rank": 1437,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0307d"
    },
    "Rank": 1439,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0307e"
    },
    "Rank": 1440,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0307f"
    },
    "Rank": 1441,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03080"
    },
    "Rank": 1443,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03081"
    },
    "Rank": 1444,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03082"
    },
    "Rank": 1445,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03083"
    },
    "Rank": 1447,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. IN NUCLEAR MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03084"
    },
    "Rank": 1449,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03085"
    },
    "Rank": 1450,
    "Allotted Quota": "All India",
    "Allotted Institute": "Murshidabad Medical College and Hospital,Station Road, Berhampore, Murshidabad, West Bengal, 742101",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03086"
    },
    "Rank": 1451,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03087"
    },
    "Rank": 1453,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03088"
    },
    "Rank": 1454,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03089"
    },
    "Rank": 1455,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0308a"
    },
    "Rank": 1456,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0308b"
    },
    "Rank": 1457,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0308c"
    },
    "Rank": 1458,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0308d"
    },
    "Rank": 1459,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0308e"
    },
    "Rank": 1460,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0308f"
    },
    "Rank": 1461,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03090"
    },
    "Rank": 1462,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03091"
    },
    "Rank": 1463,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03092"
    },
    "Rank": 1464,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Eastern Indira Gandhi Regional Institute of Health & Medical Sciences, Shillong,North Eastern Indira Gandhi Regional Institute of Health & Medical Sciences, Shillong, Meghalaya, 793018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03093"
    },
    "Rank": 1465,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03094"
    },
    "Rank": 1466,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Batra Hospital and Medical Research Centre, ,1, Tughlakabad institutional Area, Mehrauli Badarpur Road, New Delhi, Delhi (NCT), 110062",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03095"
    },
    "Rank": 1467,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences, ,academics@kimshospitals.com, Telangana, 500003",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03096"
    },
    "Rank": 1468,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03097"
    },
    "Rank": 1469,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03098"
    },
    "Rank": 1470,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03099"
    },
    "Rank": 1471,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0309a"
    },
    "Rank": 1472,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0309b"
    },
    "Rank": 1473,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0309c"
    },
    "Rank": 1474,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0309d"
    },
    "Rank": 1476,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0309e"
    },
    "Rank": 1477,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0309f"
    },
    "Rank": 1478,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030a0"
    },
    "Rank": 1479,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030a1"
    },
    "Rank": 1480,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030a2"
    },
    "Rank": 1481,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sankara Eye Hospital, ,Varthur Road, Kundalahalli gate, Bangalore 560037, Karnataka, 560037",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030a3"
    },
    "Rank": 1482,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030a4"
    },
    "Rank": 1483,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030a5"
    },
    "Rank": 1484,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030a6"
    },
    "Rank": 1485,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030a7"
    },
    "Rank": 1486,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030a8"
    },
    "Rank": 1487,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030a9"
    },
    "Rank": 1488,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030aa"
    },
    "Rank": 1489,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medical Trust Hospital, ,M.G. Road Pallimukku\nCochin, Kerala, 682016",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030ab"
    },
    "Rank": 1490,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030ac"
    },
    "Rank": 1491,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030ad"
    },
    "Rank": 1493,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030ae"
    },
    "Rank": 1494,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030af"
    },
    "Rank": 1496,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030b0"
    },
    "Rank": 1497,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B L Kapur Memorial Hospital,Pusa Road New Delhi, Delhi (NCT), 110005",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030b1"
    },
    "Rank": 1498,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030b2"
    },
    "Rank": 1499,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030b3"
    },
    "Rank": 1500,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030b4"
    },
    "Rank": 1501,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030b5"
    },
    "Rank": 1503,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030b6"
    },
    "Rank": 1504,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030b7"
    },
    "Rank": 1505,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030b8"
    },
    "Rank": 1506,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indraprastha Apollo Hospital,,Sarita Vihar, New Delhi 110 076, Delhi (NCT), 110076",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030b9"
    },
    "Rank": 1507,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030ba"
    },
    "Rank": 1508,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster Medcity, Kuttisahib Road,,Aster Medcity Kuttisahib Road\nSouth Chittoor P.O. Cheranalloor\nKochi, Kerala, 682027",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030bb"
    },
    "Rank": 1509,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine & Sagore Dutta Hospital,College of Medicine & Sagore Dutta Hospital, West Bengal, 700058",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030bc"
    },
    "Rank": 1510,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030bd"
    },
    "Rank": 1512,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030be"
    },
    "Rank": 1513,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030bf"
    },
    "Rank": 1514,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.I.O.T. Hospital, ,4 112 MOUNT POONAMALLEE ROAD MANAPAKKAM\nCHENNAI 600089, Tamil Nadu, 600089",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030c0"
    },
    "Rank": 1517,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030c1"
    },
    "Rank": 1518,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030c2"
    },
    "Rank": 1519,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030c3"
    },
    "Rank": 1520,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030c4"
    },
    "Rank": 1521,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030c5"
    },
    "Rank": 1522,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030c6"
    },
    "Rank": 1523,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030c7"
    },
    "Rank": 1524,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030c8"
    },
    "Rank": 1527,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030c9"
    },
    "Rank": 1528,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta The Medicity, ,Medanta The Medicity Sector 38 Gurgoan, Haryana, 122001",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030ca"
    },
    "Rank": 1529,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030cb"
    },
    "Rank": 1530,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030cc"
    },
    "Rank": 1531,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030cd"
    },
    "Rank": 1534,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030ce"
    },
    "Rank": 1536,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030cf"
    },
    "Rank": 1537,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030d0"
    },
    "Rank": 1539,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030d1"
    },
    "Rank": 1541,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030d2"
    },
    "Rank": 1542,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030d3"
    },
    "Rank": 1543,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030d4"
    },
    "Rank": 1544,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030d5"
    },
    "Rank": 1545,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030d6"
    },
    "Rank": 1546,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030d7"
    },
    "Rank": 1547,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030d8"
    },
    "Rank": 1549,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences, ,academics@kimshospitals.com, Telangana, 500003",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030d9"
    },
    "Rank": 1551,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030da"
    },
    "Rank": 1552,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030db"
    },
    "Rank": 1553,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030dc"
    },
    "Rank": 1554,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030dd"
    },
    "Rank": 1558,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030de"
    },
    "Rank": 1559,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030df"
    },
    "Rank": 1560,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030e0"
    },
    "Rank": 1561,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030e1"
    },
    "Rank": 1562,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030e2"
    },
    "Rank": 1563,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030e3"
    },
    "Rank": 1564,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030e4"
    },
    "Rank": 1565,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030e5"
    },
    "Rank": 1568,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030e6"
    },
    "Rank": 1569,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030e7"
    },
    "Rank": 1570,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030e8"
    },
    "Rank": 1571,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030e9"
    },
    "Rank": 1572,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030ea"
    },
    "Rank": 1573,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030eb"
    },
    "Rank": 1574,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030ec"
    },
    "Rank": 1575,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030ed"
    },
    "Rank": 1576,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030ee"
    },
    "Rank": 1577,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medical Trust Hospital, ,M.G. Road Pallimukku\nCochin, Kerala, 682016",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030ef"
    },
    "Rank": 1578,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030f0"
    },
    "Rank": 1579,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030f1"
    },
    "Rank": 1580,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030f2"
    },
    "Rank": 1581,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030f3"
    },
    "Rank": 1582,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030f4"
    },
    "Rank": 1583,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030f5"
    },
    "Rank": 1584,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030f6"
    },
    "Rank": 1585,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030f7"
    },
    "Rank": 1587,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030f8"
    },
    "Rank": 1588,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030f9"
    },
    "Rank": 1589,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030fa"
    },
    "Rank": 1590,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030fb"
    },
    "Rank": 1591,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030fc"
    },
    "Rank": 1592,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030fd"
    },
    "Rank": 1593,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute Of Medical Sciences, Kalaburagi,VEERESH NAGAR SEDAM ROAD KALABURAGI, Karnataka, 585105",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030fe"
    },
    "Rank": 1594,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a030ff"
    },
    "Rank": 1596,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03100"
    },
    "Rank": 1597,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03101"
    },
    "Rank": 1598,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Artemis Health Institute, ,Sector 51, Gurugram, Haryana -122001, Haryana, 122001",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03102"
    },
    "Rank": 1600,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03103"
    },
    "Rank": 1602,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03104"
    },
    "Rank": 1603,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03105"
    },
    "Rank": 1604,
    "Allotted Quota": "All India",
    "Allotted Institute": "Calcutta School Of Tropical Medicine,Calcutta School Of Tropical Medicine, West Bengal, 700073",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03106"
    },
    "Rank": 1605,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03107"
    },
    "Rank": 1606,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03108"
    },
    "Rank": 1607,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Breach Candy Hospital, ,60-A, Bhulabhai Desai Road, Mumbai- 400 026., Maharashtra, 400026",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03109"
    },
    "Rank": 1608,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0310a"
    },
    "Rank": 1610,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0310b"
    },
    "Rank": 1612,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0310c"
    },
    "Rank": 1613,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0310d"
    },
    "Rank": 1614,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0310e"
    },
    "Rank": 1615,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0310f"
    },
    "Rank": 1616,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03110"
    },
    "Rank": 1617,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03111"
    },
    "Rank": 1618,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03112"
    },
    "Rank": 1620,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03113"
    },
    "Rank": 1621,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03114"
    },
    "Rank": 1622,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03115"
    },
    "Rank": 1623,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03116"
    },
    "Rank": 1625,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03117"
    },
    "Rank": 1626,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03118"
    },
    "Rank": 1627,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03119"
    },
    "Rank": 1628,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0311a"
    },
    "Rank": 1629,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Artemis Health Institute, ,Sector 51, Gurugram, Haryana -122001, Haryana, 122001",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0311b"
    },
    "Rank": 1630,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0311c"
    },
    "Rank": 1631,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0311d"
    },
    "Rank": 1633,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0311e"
    },
    "Rank": 1634,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0311f"
    },
    "Rank": 1635,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03120"
    },
    "Rank": 1636,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. IN NUCLEAR MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03121"
    },
    "Rank": 1637,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03122"
    },
    "Rank": 1638,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03123"
    },
    "Rank": 1640,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajagiri Hospital,Rajagiri Hospital, Chunagamvely, Aluva 683112, Kerala, 683112",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03124"
    },
    "Rank": 1641,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KIMS Hospital,,#1-112 / 86, Survey No 5 / EE, beside Union Bank, near RTA Office, Kondapur, Hyderabad,Telangana, Telangana, 500084",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03125"
    },
    "Rank": 1642,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03126"
    },
    "Rank": 1643,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03127"
    },
    "Rank": 1644,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Breach Candy Hospital, ,60-A, Bhulabhai Desai Road, Mumbai- 400 026., Maharashtra, 400026",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03128"
    },
    "Rank": 1646,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03129"
    },
    "Rank": 1647,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0312a"
    },
    "Rank": 1648,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0312b"
    },
    "Rank": 1649,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0312c"
    },
    "Rank": 1651,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0312d"
    },
    "Rank": 1653,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0312e"
    },
    "Rank": 1657,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0312f"
    },
    "Rank": 1658,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03130"
    },
    "Rank": 1659,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03131"
    },
    "Rank": 1660,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03132"
    },
    "Rank": 1661,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03133"
    },
    "Rank": 1662,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03134"
    },
    "Rank": 1663,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03135"
    },
    "Rank": 1664,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03136"
    },
    "Rank": 1665,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03137"
    },
    "Rank": 1666,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03138"
    },
    "Rank": 1667,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03139"
    },
    "Rank": 1668,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0313a"
    },
    "Rank": 1669,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0313b"
    },
    "Rank": 1670,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "P.D. Hinduja National Hospital and Medical Research Centre, ,Veer Savarkar Marg, Mahim, Maharashtra, 400016",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0313c"
    },
    "Rank": 1671,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0313d"
    },
    "Rank": 1673,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0313e"
    },
    "Rank": 1674,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0313f"
    },
    "Rank": 1677,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03140"
    },
    "Rank": 1678,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03141"
    },
    "Rank": 1679,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences Koppal, Karnataka,Gangavati Road Kiddidal Gate koppal, Karnataka, 583231",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03142"
    },
    "Rank": 1680,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03143"
    },
    "Rank": 1681,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03144"
    },
    "Rank": 1682,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03145"
    },
    "Rank": 1683,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,HBT Medical College and Dr R N Cooper Municipal General Hospital, Bhaktivedanta Swami Rd, JVPD Schem, Maharashtra, 400056",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03146"
    },
    "Rank": 1684,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03147"
    },
    "Rank": 1686,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03148"
    },
    "Rank": 1687,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03149"
    },
    "Rank": 1688,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0314a"
    },
    "Rank": 1691,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0314b"
    },
    "Rank": 1692,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0314c"
    },
    "Rank": 1693,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Family Hospital,,Okhla Road, New Delhi, Delhi (NCT), 110025",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0314d"
    },
    "Rank": 1694,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0314e"
    },
    "Rank": 1695,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0314f"
    },
    "Rank": 1696,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03150"
    },
    "Rank": 1697,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03151"
    },
    "Rank": 1698,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03152"
    },
    "Rank": 1699,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03153"
    },
    "Rank": 1701,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03154"
    },
    "Rank": 1702,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03155"
    },
    "Rank": 1703,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03156"
    },
    "Rank": 1704,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03157"
    },
    "Rank": 1706,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03158"
    },
    "Rank": 1707,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03159"
    },
    "Rank": 1708,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0315a"
    },
    "Rank": 1709,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0315b"
    },
    "Rank": 1710,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0315c"
    },
    "Rank": 1711,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,HBT Medical College and Dr R N Cooper Municipal General Hospital, Bhaktivedanta Swami Rd, JVPD Schem, Maharashtra, 400056",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0315d"
    },
    "Rank": 1712,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0315e"
    },
    "Rank": 1713,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0315f"
    },
    "Rank": 1714,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03160"
    },
    "Rank": 1715,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03161"
    },
    "Rank": 1716,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03162"
    },
    "Rank": 1717,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03163"
    },
    "Rank": 1719,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03164"
    },
    "Rank": 1720,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03165"
    },
    "Rank": 1721,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Ramakrishna Hospital, ,395, Sarojini Naidu Road, Sidhapudur,\nCoimbatore - 641 044, Tamil Nadu, 641044",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03166"
    },
    "Rank": 1722,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03167"
    },
    "Rank": 1723,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03168"
    },
    "Rank": 1724,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03169"
    },
    "Rank": 1727,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kasturba Hospital, Daryaganj,Delhi,Daryaganj,Delhi",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0316a"
    },
    "Rank": 1728,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0316b"
    },
    "Rank": 1729,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0316c"
    },
    "Rank": 1730,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0316d"
    },
    "Rank": 1731,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0316e"
    },
    "Rank": 1733,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0316f"
    },
    "Rank": 1734,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03170"
    },
    "Rank": 1736,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College and  PGIMSR,ESIC Medical College and  PGIMSR, KK Nagar, Chennai - 600 078, Tamil Nadu, 600078",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03171"
    },
    "Rank": 1737,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03172"
    },
    "Rank": 1738,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03173"
    },
    "Rank": 1739,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03174"
    },
    "Rank": 1740,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College, Kolhapur,R.K. Nagar Road, Shenda Park, Kolhapur, Maharashtra, Maharashtra, 416012",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03175"
    },
    "Rank": 1741,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03176"
    },
    "Rank": 1742,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03177"
    },
    "Rank": 1743,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03178"
    },
    "Rank": 1744,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03179"
    },
    "Rank": 1745,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0317a"
    },
    "Rank": 1747,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0317b"
    },
    "Rank": 1748,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0317c"
    },
    "Rank": 1749,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0317d"
    },
    "Rank": 1750,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0317e"
    },
    "Rank": 1751,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College, Kolhapur,R.K. Nagar Road, Shenda Park, Kolhapur, Maharashtra, Maharashtra, 416012",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0317f"
    },
    "Rank": 1752,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN,HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN, Karnataka, 573201",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03180"
    },
    "Rank": 1753,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital,,FC 50, Max Wali Rd, C and D Block, Shalimar Place Site, Shalimar Bagh, New Delhi, Delhi 110088, Delhi (NCT), 110088",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03181"
    },
    "Rank": 1754,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03182"
    },
    "Rank": 1755,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03183"
    },
    "Rank": 1757,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03184"
    },
    "Rank": 1759,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03185"
    },
    "Rank": 1760,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03186"
    },
    "Rank": 1761,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03187"
    },
    "Rank": 1762,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03188"
    },
    "Rank": 1763,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03189"
    },
    "Rank": 1764,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0318a"
    },
    "Rank": 1765,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0318b"
    },
    "Rank": 1766,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0318c"
    },
    "Rank": 1767,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0318d"
    },
    "Rank": 1768,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0318e"
    },
    "Rank": 1769,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0318f"
    },
    "Rank": 1770,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03190"
    },
    "Rank": 1771,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03191"
    },
    "Rank": 1773,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Hospital, Omandurar,GOVERNMENT MEDICAL COLLEGE, OMANDURAR GOVERNMENT ESTATE, WALAJAH ROAD, NEAR KALAIVANAR ARANGAM, CHEN, Tamil Nadu, 600002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03192"
    },
    "Rank": 1774,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03193"
    },
    "Rank": 1775,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03194"
    },
    "Rank": 1777,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03195"
    },
    "Rank": 1778,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03196"
    },
    "Rank": 1781,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03197"
    },
    "Rank": 1784,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03198"
    },
    "Rank": 1786,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03199"
    },
    "Rank": 1788,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. IN NUCLEAR MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0319a"
    },
    "Rank": 1789,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0319b"
    },
    "Rank": 1790,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0319c"
    },
    "Rank": 1791,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0319d"
    },
    "Rank": 1792,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0319e"
    },
    "Rank": 1794,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0319f"
    },
    "Rank": 1795,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031a0"
    },
    "Rank": 1796,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031a1"
    },
    "Rank": 1797,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031a2"
    },
    "Rank": 1798,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, A Unit of Hometrail Buildtech Pvt Ltd. ,Near Civil Hospital, Phase-6, Mohali, Punjab-160055, Punjab, 160055",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031a3"
    },
    "Rank": 1799,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031a4"
    },
    "Rank": 1801,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031a5"
    },
    "Rank": 1802,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031a6"
    },
    "Rank": 1805,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031a7"
    },
    "Rank": 1806,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031a8"
    },
    "Rank": 1807,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031a9"
    },
    "Rank": 1808,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031aa"
    },
    "Rank": 1809,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031ab"
    },
    "Rank": 1810,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031ac"
    },
    "Rank": 1812,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031ad"
    },
    "Rank": 1813,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031ae"
    },
    "Rank": 1814,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "P.D. Hinduja National Hospital and Medical Research Centre, ,Veer Savarkar Marg, Mahim, Maharashtra, 400016",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031af"
    },
    "Rank": 1815,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.I.O.T. Hospital, ,4 112 MOUNT POONAMALLEE ROAD MANAPAKKAM\nCHENNAI 600089, Tamil Nadu, 600089",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031b0"
    },
    "Rank": 1818,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031b1"
    },
    "Rank": 1819,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Paras Hospital,,C1 Sushant lok sector 43 gurugram, Haryana, 122002",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031b2"
    },
    "Rank": 1820,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031b3"
    },
    "Rank": 1822,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College,Theni ,Government Medical College,Theni, Tamil Nadu, 625512",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031b4"
    },
    "Rank": 1824,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031b5"
    },
    "Rank": 1825,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031b6"
    },
    "Rank": 1827,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031b7"
    },
    "Rank": 1828,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031b8"
    },
    "Rank": 1829,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031b9"
    },
    "Rank": 1830,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031ba"
    },
    "Rank": 1831,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031bb"
    },
    "Rank": 1832,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031bc"
    },
    "Rank": 1836,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031bd"
    },
    "Rank": 1837,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031be"
    },
    "Rank": 1838,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031bf"
    },
    "Rank": 1839,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031c0"
    },
    "Rank": 1840,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,HBT Medical College and Dr R N Cooper Municipal General Hospital, Bhaktivedanta Swami Rd, JVPD Schem, Maharashtra, 400056",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031c1"
    },
    "Rank": 1841,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Paras Hospital,,C1 Sushant lok sector 43 gurugram, Haryana, 122002",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031c2"
    },
    "Rank": 1842,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031c3"
    },
    "Rank": 1843,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031c4"
    },
    "Rank": 1845,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharashtra Post Graduate Institute of Medical Education and Research, Nashik,Maharashtra Post Graduation Institute Of Medical Education and Research Civil Hospital campus Trimba, Maharashtra, 422002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031c5"
    },
    "Rank": 1846,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031c6"
    },
    "Rank": 1848,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031c7"
    },
    "Rank": 1849,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031c8"
    },
    "Rank": 1850,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031c9"
    },
    "Rank": 1851,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sanjay Gandhi Institute of Trauma and Orthopaedics,Sanjay Gandhi Institute of Trauma and Orthopaedics, Karnataka, 560011",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031ca"
    },
    "Rank": 1852,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031cb"
    },
    "Rank": 1853,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pimpri Chichwad Municipal Corporation Postgraduate Institute Y.C.M.Hospital Pimpri,Pune 18,Dean Office, Y.C.M.Hospital, Sant Tukaramnagar Pimpri, Pune, Maharashtra, 411018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031cc"
    },
    "Rank": 1854,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE, PUDUCHERRY,INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE,\nPUDUCHERRY, Puducherry, 605009",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031cd"
    },
    "Rank": 1856,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031ce"
    },
    "Rank": 1857,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031cf"
    },
    "Rank": 1858,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharashtra Post Graduate Institute of Medical Education and Research, Nashik,Maharashtra Post Graduation Institute Of Medical Education and Research Civil Hospital campus Trimba, Maharashtra, 422002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031d0"
    },
    "Rank": 1861,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031d1"
    },
    "Rank": 1863,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031d2"
    },
    "Rank": 1865,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031d3"
    },
    "Rank": 1867,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031d4"
    },
    "Rank": 1868,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031d5"
    },
    "Rank": 1869,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031d6"
    },
    "Rank": 1872,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031d7"
    },
    "Rank": 1873,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031d8"
    },
    "Rank": 1877,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031d9"
    },
    "Rank": 1879,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031da"
    },
    "Rank": 1880,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031db"
    },
    "Rank": 1881,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031dc"
    },
    "Rank": 1882,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031dd"
    },
    "Rank": 1883,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031de"
    },
    "Rank": 1885,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031df"
    },
    "Rank": 1889,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031e0"
    },
    "Rank": 1890,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031e1"
    },
    "Rank": 1891,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031e2"
    },
    "Rank": 1892,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031e3"
    },
    "Rank": 1893,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031e4"
    },
    "Rank": 1894,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031e5"
    },
    "Rank": 1895,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Spirit Hospital, ,Mahakali Caves Road Andheri East Mumbai, Maharashtra, 400093",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031e6"
    },
    "Rank": 1896,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031e7"
    },
    "Rank": 1897,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031e8"
    },
    "Rank": 1898,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031e9"
    },
    "Rank": 1899,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031ea"
    },
    "Rank": 1900,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.E.M. Hospital, ,489, SARDAR MOODLIAR ROAD, RASTA PETH, PUNE 411011, Maharashtra, 411011",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031eb"
    },
    "Rank": 1902,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031ec"
    },
    "Rank": 1905,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031ed"
    },
    "Rank": 1906,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031ee"
    },
    "Rank": 1907,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031ef"
    },
    "Rank": 1908,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031f0"
    },
    "Rank": 1910,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031f1"
    },
    "Rank": 1911,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031f2"
    },
    "Rank": 1912,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031f3"
    },
    "Rank": 1914,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031f4"
    },
    "Rank": 1915,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031f5"
    },
    "Rank": 1916,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031f6"
    },
    "Rank": 1917,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031f7"
    },
    "Rank": 1918,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ananthapuri Hospital and Research Institute, ,Chacka, NH Bypass, Thiruvananthapuram 695024, Kerala, 695024",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031f8"
    },
    "Rank": 1919,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031f9"
    },
    "Rank": 1920,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031fa"
    },
    "Rank": 1921,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindu Rao Hospital, ,SUBZI MANDI, MALKA GANJ, DELHI-110007, Delhi (NCT), 110007",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031fb"
    },
    "Rank": 1922,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031fc"
    },
    "Rank": 1923,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031fd"
    },
    "Rank": 1924,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031fe"
    },
    "Rank": 1925,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a031ff"
    },
    "Rank": 1926,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03200"
    },
    "Rank": 1927,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03201"
    },
    "Rank": 1928,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03202"
    },
    "Rank": 1929,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03203"
    },
    "Rank": 1930,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Institute of Medical Sciences (SVIMS), A State University estd. by Govt. of A.P.,Sri Venkateswara Institute of Medical Sciences, SVIMS, Alipiri Road, Tirupati, Andhra Pradesh, 517507",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03204"
    },
    "Rank": 1931,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharashtra Post Graduate Institute of Medical Education and Research, Nashik,Maharashtra Post Graduation Institute Of Medical Education and Research Civil Hospital campus Trimba, Maharashtra, 422002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03205"
    },
    "Rank": 1933,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03206"
    },
    "Rank": 1935,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03207"
    },
    "Rank": 1938,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ananthapuri Hospital and Research Institute, ,Chacka, NH Bypass, Thiruvananthapuram 695024, Kerala, 695024",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03208"
    },
    "Rank": 1939,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03209"
    },
    "Rank": 1940,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Mulund - Goregaon Link Rd, Nahur West, Industrial Area, Bhandup West, Mumbai, Maharashtra 400078, Maharashtra, 400078",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0320a"
    },
    "Rank": 1941,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0320b"
    },
    "Rank": 1942,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0320c"
    },
    "Rank": 1944,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0320d"
    },
    "Rank": 1946,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0320e"
    },
    "Rank": 1947,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0320f"
    },
    "Rank": 1948,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03210"
    },
    "Rank": 1949,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03211"
    },
    "Rank": 1950,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deen Dayal Upadhyay Hospital, ,DDU HOSPITAL, SHAHEED MANGAL PANDEY MARG, NANAK PURA, HARI NAGAR, NEW DELHI, DELHI-110064, Delhi (NCT), 110064",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03212"
    },
    "Rank": 1951,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03213"
    },
    "Rank": 1952,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03214"
    },
    "Rank": 1954,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03215"
    },
    "Rank": 1955,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03216"
    },
    "Rank": 1956,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03217"
    },
    "Rank": 1957,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03218"
    },
    "Rank": 1958,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03219"
    },
    "Rank": 1959,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0321a"
    },
    "Rank": 1960,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0321b"
    },
    "Rank": 1961,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0321c"
    },
    "Rank": 1962,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0321d"
    },
    "Rank": 1963,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SETH V. C. GANDHI and M. A. VORA MUNICIPAL GENERAL HOSPITAL (RAJAWADI HOSPITAL),\nMaharashtra,hospital.rajawadi@gmail.com, Maharashtra, 400077",
    "Course": "(NBEMS) DERMATOLOGY and VENEREOLOGY and LEPROSY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0321e"
    },
    "Rank": 1964,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0321f"
    },
    "Rank": 1965,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03220"
    },
    "Rank": 1966,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03221"
    },
    "Rank": 1967,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03222"
    },
    "Rank": 1968,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital,,FC 50, Max Wali Rd, C and D Block, Shalimar Place Site, Shalimar Bagh, New Delhi, Delhi 110088, Delhi (NCT), 110088",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03223"
    },
    "Rank": 1969,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03224"
    },
    "Rank": 1972,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03225"
    },
    "Rank": 1975,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03226"
    },
    "Rank": 1976,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03227"
    },
    "Rank": 1977,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03228"
    },
    "Rank": 1978,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03229"
    },
    "Rank": 1979,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0322a"
    },
    "Rank": 1980,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, Shalimar Bagh,A- Block, Shalimar Bagh, Delhi (NCT), 110088",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0322b"
    },
    "Rank": 1981,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0322c"
    },
    "Rank": 1982,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0322d"
    },
    "Rank": 1983,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0322e"
    },
    "Rank": 1984,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0322f"
    },
    "Rank": 1985,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lourdes Hospital, ,Ernakulam, Pachalam, Kochi, Kerala, 682012",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03230"
    },
    "Rank": 1986,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03231"
    },
    "Rank": 1987,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03232"
    },
    "Rank": 1988,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03233"
    },
    "Rank": 1989,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03234"
    },
    "Rank": 1990,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03235"
    },
    "Rank": 1993,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03236"
    },
    "Rank": 1994,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03237"
    },
    "Rank": 1995,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03238"
    },
    "Rank": 1996,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03239"
    },
    "Rank": 1999,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,HBT Medical College and Dr R N Cooper Municipal General Hospital, Bhaktivedanta Swami Rd, JVPD Schem, Maharashtra, 400056",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0323a"
    },
    "Rank": 2001,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0323b"
    },
    "Rank": 2002,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0323c"
    },
    "Rank": 2003,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0323d"
    },
    "Rank": 2004,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0323e"
    },
    "Rank": 2006,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0323f"
    },
    "Rank": 2007,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03240"
    },
    "Rank": 2010,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03241"
    },
    "Rank": 2011,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03242"
    },
    "Rank": 2012,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03243"
    },
    "Rank": 2013,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03244"
    },
    "Rank": 2015,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03245"
    },
    "Rank": 2017,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03246"
    },
    "Rank": 2020,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03247"
    },
    "Rank": 2021,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03248"
    },
    "Rank": 2022,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.I.O.T. Hospital, ,4 112 MOUNT POONAMALLEE ROAD MANAPAKKAM\nCHENNAI 600089, Tamil Nadu, 600089",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03249"
    },
    "Rank": 2023,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0324a"
    },
    "Rank": 2026,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0324b"
    },
    "Rank": 2028,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0324c"
    },
    "Rank": 2029,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaypee Hospital,,sector 128, Noida, Uttar Pradesh, 201304",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0324d"
    },
    "Rank": 2031,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0324e"
    },
    "Rank": 2032,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0324f"
    },
    "Rank": 2033,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03250"
    },
    "Rank": 2035,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kokilaben Dhirubhai Ambani Hospital and Medical Research Institute,,Achyutrao Patwardhan Marg Andheri West Mumbai Maharashtra, Maharashtra, 400053",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03251"
    },
    "Rank": 2037,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03252"
    },
    "Rank": 2038,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03253"
    },
    "Rank": 2040,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03254"
    },
    "Rank": 2042,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03255"
    },
    "Rank": 2043,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03256"
    },
    "Rank": 2044,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03257"
    },
    "Rank": 2045,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03258"
    },
    "Rank": 2047,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03259"
    },
    "Rank": 2048,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0325a"
    },
    "Rank": 2049,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0325b"
    },
    "Rank": 2050,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0325c"
    },
    "Rank": 2052,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VELLORE MEDICAL COLLEGE,GOVERNMENT VELLORE MEDICAL COLLEGE, Tamil Nadu, 632011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0325d"
    },
    "Rank": 2053,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhabha Atomic Research Centre and Hospital, ,Anushaktinagar, Mumbai, Maharashtra, 400094",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0325e"
    },
    "Rank": 2054,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lakeshore Hospital and Res. ,Maradu, Nettoor P O, Kochi, Kerala, 682040",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0325f"
    },
    "Rank": 2055,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03260"
    },
    "Rank": 2056,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jupiter Hospital, ,Eastern Express Highway, Thane West, Maharashtra, 400601",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03261"
    },
    "Rank": 2057,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Hospital, Omandurar,GOVERNMENT MEDICAL COLLEGE, OMANDURAR GOVERNMENT ESTATE, WALAJAH ROAD, NEAR KALAIVANAR ARANGAM, CHEN, Tamil Nadu, 600002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03262"
    },
    "Rank": 2059,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruby Hall Clinic, ,GRANT MEDICAL FOUNDATION,\n40 SASSOON ROAD, PUNE 411001, Maharashtra, 411001",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03263"
    },
    "Rank": 2061,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03264"
    },
    "Rank": 2063,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital International, ,Plot 1A, GIDC Estate, Bhat Gandhinagar, Gujarat, 382428",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03265"
    },
    "Rank": 2066,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03266"
    },
    "Rank": 2067,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03267"
    },
    "Rank": 2068,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03268"
    },
    "Rank": 2070,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03269"
    },
    "Rank": 2071,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0326a"
    },
    "Rank": 2073,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0326b"
    },
    "Rank": 2074,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0326c"
    },
    "Rank": 2076,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0326d"
    },
    "Rank": 2077,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jehangir Hospital, ,32 Sasoon Road Pune, Maharashtra, 411001",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0326e"
    },
    "Rank": 2079,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0326f"
    },
    "Rank": 2080,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03270"
    },
    "Rank": 2081,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03271"
    },
    "Rank": 2082,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03272"
    },
    "Rank": 2084,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03273"
    },
    "Rank": 2085,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03274"
    },
    "Rank": 2086,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03275"
    },
    "Rank": 2087,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03276"
    },
    "Rank": 2088,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03277"
    },
    "Rank": 2089,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Polakulath Narayanan Renai Medicity Multi Super Specialty Hospital,,RENAIMEDICITY PALARIVATTOM KOCHI KERALA, Kerala, 682025",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03278"
    },
    "Rank": 2090,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03279"
    },
    "Rank": 2091,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0327a"
    },
    "Rank": 2093,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0327b"
    },
    "Rank": 2094,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0327c"
    },
    "Rank": 2095,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0327d"
    },
    "Rank": 2097,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0327e"
    },
    "Rank": 2098,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES ADILABAD -504001 TELANGANA STATE, Telangana, 504001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0327f"
    },
    "Rank": 2100,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN,HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN, Karnataka, 573201",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03280"
    },
    "Rank": 2101,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03281"
    },
    "Rank": 2102,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03282"
    },
    "Rank": 2103,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03283"
    },
    "Rank": 2104,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, THOOTHUKUDI,GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, 3rd\nMile,Kamaraj Nagar,Thoothukudi., Tamil Nadu, 628008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03284"
    },
    "Rank": 2105,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03285"
    },
    "Rank": 2106,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03286"
    },
    "Rank": 2107,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03287"
    },
    "Rank": 2108,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03288"
    },
    "Rank": 2109,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03289"
    },
    "Rank": 2110,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0328a"
    },
    "Rank": 2111,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0328b"
    },
    "Rank": 2112,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0328c"
    },
    "Rank": 2114,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0328d"
    },
    "Rank": 2115,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute of Medical Sciences Srikakulam,Government Medical College Srikakulam\nBalaga Srikakulam Town Srikakulam District, Andhra Pradesh, 532001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0328e"
    },
    "Rank": 2116,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0328f"
    },
    "Rank": 2118,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03290"
    },
    "Rank": 2119,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jalgaon,Jilha Peth, Government Hospital Campus, Jalgaon, Maharashtra, 425001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03291"
    },
    "Rank": 2120,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03292"
    },
    "Rank": 2122,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VILLUPURAM MEDICAL COLLEGE, VILLUPURAM,GOVERNMENT VILLUPURAM MEDICAL COLLEGE VILLUPURAM  TRICHY TRUNK ROAD MUNDIYAMPAKKAM  VILLUPURAM DISTR, Tamil Nadu, 605601",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03293"
    },
    "Rank": 2124,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B L Kapur Memorial Hospital,Pusa Road New Delhi, Delhi (NCT), 110005",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03294"
    },
    "Rank": 2125,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03295"
    },
    "Rank": 2126,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Spirit Hospital, ,Mahakali Caves Road Andheri East Mumbai, Maharashtra, 400093",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03296"
    },
    "Rank": 2127,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03297"
    },
    "Rank": 2128,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03298"
    },
    "Rank": 2129,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03299"
    },
    "Rank": 2130,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0329a"
    },
    "Rank": 2131,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0329b"
    },
    "Rank": 2132,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0329c"
    },
    "Rank": 2134,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0329d"
    },
    "Rank": 2135,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0329e"
    },
    "Rank": 2136,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0329f"
    },
    "Rank": 2137,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Balaji Action Medical Institute,,FC34 A4 PASCHIM VIHAR NEW DELHI, Delhi (NCT), 110063",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032a0"
    },
    "Rank": 2139,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032a1"
    },
    "Rank": 2140,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032a2"
    },
    "Rank": 2142,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032a3"
    },
    "Rank": 2143,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032a4"
    },
    "Rank": 2144,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032a5"
    },
    "Rank": 2145,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032a6"
    },
    "Rank": 2146,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032a7"
    },
    "Rank": 2147,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032a8"
    },
    "Rank": 2150,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032a9"
    },
    "Rank": 2153,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032aa"
    },
    "Rank": 2154,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032ab"
    },
    "Rank": 2155,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032ac"
    },
    "Rank": 2158,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032ad"
    },
    "Rank": 2159,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute of Medical Sciences Srikakulam,Government Medical College Srikakulam\nBalaga Srikakulam Town Srikakulam District, Andhra Pradesh, 532001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032ae"
    },
    "Rank": 2160,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032af"
    },
    "Rank": 2162,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032b0"
    },
    "Rank": 2163,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032b1"
    },
    "Rank": 2164,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032b2"
    },
    "Rank": 2165,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032b3"
    },
    "Rank": 2166,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032b4"
    },
    "Rank": 2167,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, (A unit of Balaji Medical and Diagnostic Research Centre)\n,Max Super Specialty Hospital -108A IP Extension Patparganj New Delhi, Delhi (NCT), 110092",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032b5"
    },
    "Rank": 2168,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032b6"
    },
    "Rank": 2169,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032b7"
    },
    "Rank": 2171,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruby Hall Clinic, ,GRANT MEDICAL FOUNDATION,\n40 SASSOON ROAD, PUNE 411001, Maharashtra, 411001",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032b8"
    },
    "Rank": 2172,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032b9"
    },
    "Rank": 2174,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032ba"
    },
    "Rank": 2177,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032bb"
    },
    "Rank": 2178,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Santokbha Durlabhji Memorial Hospital Cum Medical Research Institute,\n,dnb.sdmh@gmail.com, Rajasthan, 302015",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032bc"
    },
    "Rank": 2179,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032bd"
    },
    "Rank": 2181,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jagjivan Ram Railway Hospital,,Maratha Mandir Marg, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032be"
    },
    "Rank": 2182,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032bf"
    },
    "Rank": 2184,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032c0"
    },
    "Rank": 2185,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032c1"
    },
    "Rank": 2186,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032c2"
    },
    "Rank": 2187,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032c3"
    },
    "Rank": 2188,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVERNMENT MEDICAL COLLEGE,KANYAKUMARI GOVERNMENT MEDICAL COLLEGE, Tamil Nadu, 629201",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032c4"
    },
    "Rank": 2190,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032c5"
    },
    "Rank": 2191,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032c6"
    },
    "Rank": 2192,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT DHARMAPURI MEDICAL COLLEGE, DHARMAPURI, TAMIL NADU,GOVERNMENT DHARMAPURI MEDICAL COLLEGE, NETHAJI BYE PASS ROAD, DHARMAPURI, TAMIL NADU, Tamil Nadu, 636701",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032c7"
    },
    "Rank": 2193,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032c8"
    },
    "Rank": 2196,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Hospital, Omandurar,GOVERNMENT MEDICAL COLLEGE, OMANDURAR GOVERNMENT ESTATE, WALAJAH ROAD, NEAR KALAIVANAR ARANGAM, CHEN, Tamil Nadu, 600002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032c9"
    },
    "Rank": 2197,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032ca"
    },
    "Rank": 2198,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032cb"
    },
    "Rank": 2201,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032cc"
    },
    "Rank": 2204,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032cd"
    },
    "Rank": 2205,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032ce"
    },
    "Rank": 2206,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032cf"
    },
    "Rank": 2207,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032d0"
    },
    "Rank": 2209,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032d1"
    },
    "Rank": 2210,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032d2"
    },
    "Rank": 2212,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032d3"
    },
    "Rank": 2213,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, THOOTHUKUDI,GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, 3rd\nMile,Kamaraj Nagar,Thoothukudi., Tamil Nadu, 628008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032d4"
    },
    "Rank": 2214,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032d5"
    },
    "Rank": 2215,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,HBT Medical College and Dr R N Cooper Municipal General Hospital, Bhaktivedanta Swami Rd, JVPD Schem, Maharashtra, 400056",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032d6"
    },
    "Rank": 2216,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032d7"
    },
    "Rank": 2217,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032d8"
    },
    "Rank": 2218,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032d9"
    },
    "Rank": 2219,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032da"
    },
    "Rank": 2221,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032db"
    },
    "Rank": 2222,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032dc"
    },
    "Rank": 2223,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Eastern Indira Gandhi Regional Institute of Health & Medical Sciences, Shillong,North Eastern Indira Gandhi Regional Institute of Health & Medical Sciences, Shillong, Meghalaya, 793018",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032dd"
    },
    "Rank": 2225,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Santokbha Durlabhji Memorial Hospital Cum Medical Research Institute,\n,dnb.sdmh@gmail.com, Rajasthan, 302015",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032de"
    },
    "Rank": 2228,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032df"
    },
    "Rank": 2229,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032e0"
    },
    "Rank": 2230,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Poona Hospital and Research Centre,,27,Sadashiv Peth, Pune -411030, Maharashtra, 411030",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032e1"
    },
    "Rank": 2231,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032e2"
    },
    "Rank": 2233,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032e3"
    },
    "Rank": 2235,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032e4"
    },
    "Rank": 2237,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032e5"
    },
    "Rank": 2238,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032e6"
    },
    "Rank": 2239,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032e7"
    },
    "Rank": 2240,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saifee Hospital, ,postbox no 3771, 15-17 Maharshi Karve Marg, Charni road, Mumbai, Maharashtra, 400004",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032e8"
    },
    "Rank": 2241,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032e9"
    },
    "Rank": 2243,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jupiter Hospital, ,Eastern Express Highway, Thane West, Maharashtra, 400601",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032ea"
    },
    "Rank": 2244,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saifee Hospital, ,postbox no 3771, 15-17 Maharshi Karve Marg, Charni road, Mumbai, Maharashtra, 400004",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032eb"
    },
    "Rank": 2245,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College,Theni ,Government Medical College,Theni, Tamil Nadu, 625512",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032ec"
    },
    "Rank": 2247,
    "Allotted Quota": "All India",
    "Allotted Institute": "Institute of Child Health, Kolkata,Institute of Child Health, Kolkata, West Bengal, 700017",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032ed"
    },
    "Rank": 2248,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032ee"
    },
    "Rank": 2249,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032ef"
    },
    "Rank": 2250,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032f0"
    },
    "Rank": 2251,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032f1"
    },
    "Rank": 2252,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032f2"
    },
    "Rank": 2253,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032f3"
    },
    "Rank": 2254,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032f4"
    },
    "Rank": 2255,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032f5"
    },
    "Rank": 2256,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032f6"
    },
    "Rank": 2257,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032f7"
    },
    "Rank": 2258,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VELLORE MEDICAL COLLEGE,GOVERNMENT VELLORE MEDICAL COLLEGE, Tamil Nadu, 632011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032f8"
    },
    "Rank": 2259,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032f9"
    },
    "Rank": 2260,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032fa"
    },
    "Rank": 2261,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032fb"
    },
    "Rank": 2262,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032fc"
    },
    "Rank": 2263,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "DIP. IN DERM. VENEREOLOGY and LEPROSY/DERMATOLO GY /VENEREOLOGY and DERMATOLOGY/LEPRO SY/VENEREAL DISEASE and LEPROSY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032fd"
    },
    "Rank": 2264,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032fe"
    },
    "Rank": 2265,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a032ff"
    },
    "Rank": 2266,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03300"
    },
    "Rank": 2267,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03301"
    },
    "Rank": 2268,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees' State Insurance Corporation Medical College & Hospital, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD GULBARGA, Karnataka, 585106",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03302"
    },
    "Rank": 2272,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03303"
    },
    "Rank": 2273,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03304"
    },
    "Rank": 2274,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03305"
    },
    "Rank": 2275,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03306"
    },
    "Rank": 2276,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03307"
    },
    "Rank": 2278,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03308"
    },
    "Rank": 2279,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03309"
    },
    "Rank": 2280,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0330a"
    },
    "Rank": 2281,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVERNMENT MEDICAL COLLEGE,KANYAKUMARI GOVERNMENT MEDICAL COLLEGE, Tamil Nadu, 629201",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0330b"
    },
    "Rank": 2282,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0330c"
    },
    "Rank": 2283,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0330d"
    },
    "Rank": 2284,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0330e"
    },
    "Rank": 2286,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0330f"
    },
    "Rank": 2287,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03310"
    },
    "Rank": 2288,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03311"
    },
    "Rank": 2289,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03312"
    },
    "Rank": 2290,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03313"
    },
    "Rank": 2291,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03314"
    },
    "Rank": 2293,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03315"
    },
    "Rank": 2295,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03316"
    },
    "Rank": 2296,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03317"
    },
    "Rank": 2297,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital International, ,Plot 1A, GIDC Estate, Bhat Gandhinagar, Gujarat, 382428",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03318"
    },
    "Rank": 2298,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Memorial Research Institute, ,Fortis memorial research institute, Sector 44, Opposite Huda City Centre Metro station, Gurgaon, Haryana, 122002",
    "Course": "(NBEMS) NUCLEAR MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03319"
    },
    "Rank": 2300,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0331a"
    },
    "Rank": 2301,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0331b"
    },
    "Rank": 2302,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0331c"
    },
    "Rank": 2303,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVERNMENT MEDICAL COLLEGE,KANYAKUMARI GOVERNMENT MEDICAL COLLEGE, Tamil Nadu, 629201",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0331d"
    },
    "Rank": 2305,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0331e"
    },
    "Rank": 2306,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gvernment Medical College,Haldwani,Government Medical College, Haldwani, Uttarakhand, 263139",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0331f"
    },
    "Rank": 2307,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03320"
    },
    "Rank": 2309,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Gleneagles Hospital, ,58 Canal Circular Road Kolkata 700054, West Bengal, 700054",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03321"
    },
    "Rank": 2310,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03322"
    },
    "Rank": 2311,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03323"
    },
    "Rank": 2313,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03324"
    },
    "Rank": 2314,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03325"
    },
    "Rank": 2315,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "G Kuppuswamy Naidu Memorial Hospital, ,Post Box No.6327, Nethaji Road, Pappanaickenpalayam, Coimbatore, Tamil Nadu, 641037",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03326"
    },
    "Rank": 2316,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03327"
    },
    "Rank": 2317,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03328"
    },
    "Rank": 2318,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03329"
    },
    "Rank": 2319,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, THOOTHUKUDI,GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, 3rd\nMile,Kamaraj Nagar,Thoothukudi., Tamil Nadu, 628008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0332a"
    },
    "Rank": 2320,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0332b"
    },
    "Rank": 2321,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) DERMATOLOGY and VENEREOLOGY and LEPROSY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0332c"
    },
    "Rank": 2322,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0332d"
    },
    "Rank": 2323,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0332e"
    },
    "Rank": 2324,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0332f"
    },
    "Rank": 2325,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lourdes Hospital, ,Ernakulam, Pachalam, Kochi, Kerala, 682012",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03330"
    },
    "Rank": 2326,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03331"
    },
    "Rank": 2327,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.I.O.T. Hospital, ,4 112 MOUNT POONAMALLEE ROAD MANAPAKKAM\nCHENNAI 600089, Tamil Nadu, 600089",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03332"
    },
    "Rank": 2328,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03333"
    },
    "Rank": 2329,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03334"
    },
    "Rank": 2330,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,APOLLO HOSPITALS BANGALORE A UNIT OF IMPERIAL HOSPITALS AND RESEARCH CENTRE LIMITED 154/11 Opp IIM B, Karnataka, 560076",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03335"
    },
    "Rank": 2331,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03336"
    },
    "Rank": 2332,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03337"
    },
    "Rank": 2333,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03338"
    },
    "Rank": 2334,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03339"
    },
    "Rank": 2335,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0333a"
    },
    "Rank": 2336,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT DHARMAPURI MEDICAL COLLEGE, DHARMAPURI, TAMIL NADU,GOVERNMENT DHARMAPURI MEDICAL COLLEGE, NETHAJI BYE PASS ROAD, DHARMAPURI, TAMIL NADU, Tamil Nadu, 636701",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0333b"
    },
    "Rank": 2337,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0333c"
    },
    "Rank": 2338,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jehangir Hospital, ,32 Sasoon Road Pune, Maharashtra, 411001",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0333d"
    },
    "Rank": 2339,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0333e"
    },
    "Rank": 2340,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0333f"
    },
    "Rank": 2341,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03340"
    },
    "Rank": 2342,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03341"
    },
    "Rank": 2343,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03342"
    },
    "Rank": 2344,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvarur Govt.Medical College,Thiruvarur Govt.Medical College, Tamil Nadu, 610004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03343"
    },
    "Rank": 2347,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03344"
    },
    "Rank": 2348,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03345"
    },
    "Rank": 2349,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03346"
    },
    "Rank": 2350,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,APOLLO HOSPITALS BANGALORE A UNIT OF IMPERIAL HOSPITALS AND RESEARCH CENTRE LIMITED 154/11 Opp IIM B, Karnataka, 560076",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03347"
    },
    "Rank": 2353,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03348"
    },
    "Rank": 2354,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03349"
    },
    "Rank": 2355,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0334a"
    },
    "Rank": 2356,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0334b"
    },
    "Rank": 2357,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lilavati Hospital and Research Centre, ,Lilavati Hospital And Research Centre A-791, Bandra Reclamation, Bandra - West, Mumbai, Maharashtra, 400050",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0334c"
    },
    "Rank": 2358,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0334d"
    },
    "Rank": 2359,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, Shalimar Bagh,A- Block, Shalimar Bagh, Delhi (NCT), 110088",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0334e"
    },
    "Rank": 2360,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Specialty Hospital, ,NO. 320, PADMA COMPLEX, MOUNT ROAD, CHENNAI, Tamil Nadu, 600035",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0334f"
    },
    "Rank": 2362,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03350"
    },
    "Rank": 2363,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03351"
    },
    "Rank": 2364,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03352"
    },
    "Rank": 2365,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. GERIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03353"
    },
    "Rank": 2366,
    "Allotted Quota": "All India",
    "Allotted Institute": "Radiation Medicine Centre (RMC), Bhabha Atomic Research Centre (BARC),Tata Memorial Hospital Premises, Annexe Bldg, Jerbai Wadia Road, Parel, Mumbai-400012, Maharashtra, 400012",
    "Course": "M.D. IN NUCLEAR MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03354"
    },
    "Rank": 2369,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, THOOTHUKUDI,GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, 3rd\nMile,Kamaraj Nagar,Thoothukudi., Tamil Nadu, 628008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03355"
    },
    "Rank": 2370,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03356"
    },
    "Rank": 2372,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03357"
    },
    "Rank": 2373,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VELLORE MEDICAL COLLEGE,GOVERNMENT VELLORE MEDICAL COLLEGE, Tamil Nadu, 632011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03358"
    },
    "Rank": 2374,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03359"
    },
    "Rank": 2375,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0335a"
    },
    "Rank": 2376,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0335b"
    },
    "Rank": 2377,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0335c"
    },
    "Rank": 2378,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0335d"
    },
    "Rank": 2380,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0335e"
    },
    "Rank": 2382,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0335f"
    },
    "Rank": 2383,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VILLUPURAM MEDICAL COLLEGE, VILLUPURAM,GOVERNMENT VILLUPURAM MEDICAL COLLEGE VILLUPURAM  TRICHY TRUNK ROAD MUNDIYAMPAKKAM  VILLUPURAM DISTR, Tamil Nadu, 605601",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03360"
    },
    "Rank": 2384,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03361"
    },
    "Rank": 2386,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03362"
    },
    "Rank": 2388,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVERNMENT MEDICAL COLLEGE,KANYAKUMARI GOVERNMENT MEDICAL COLLEGE, Tamil Nadu, 629201",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03363"
    },
    "Rank": 2390,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03364"
    },
    "Rank": 2392,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03365"
    },
    "Rank": 2394,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03366"
    },
    "Rank": 2396,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03367"
    },
    "Rank": 2398,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03368"
    },
    "Rank": 2399,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03369"
    },
    "Rank": 2400,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0336a"
    },
    "Rank": 2401,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0336b"
    },
    "Rank": 2402,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0336c"
    },
    "Rank": 2403,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0336d"
    },
    "Rank": 2404,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0336e"
    },
    "Rank": 2405,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0336f"
    },
    "Rank": 2406,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03370"
    },
    "Rank": 2407,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03371"
    },
    "Rank": 2408,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03372"
    },
    "Rank": 2409,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03373"
    },
    "Rank": 2411,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03374"
    },
    "Rank": 2412,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03375"
    },
    "Rank": 2414,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Parsik Hill Road, ,APOLLO HOSPITAL PARSIK HILL ROAD ROAD NO 13 SECTOR 23 CBD BELAPUR NAVI MUMBAI, Maharashtra, 400614",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03376"
    },
    "Rank": 2415,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03377"
    },
    "Rank": 2418,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03378"
    },
    "Rank": 2420,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03379"
    },
    "Rank": 2422,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0337a"
    },
    "Rank": 2424,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0337b"
    },
    "Rank": 2425,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0337c"
    },
    "Rank": 2426,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0337d"
    },
    "Rank": 2427,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0337e"
    },
    "Rank": 2428,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0337f"
    },
    "Rank": 2429,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03380"
    },
    "Rank": 2431,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03381"
    },
    "Rank": 2433,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03382"
    },
    "Rank": 2434,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03383"
    },
    "Rank": 2435,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03384"
    },
    "Rank": 2436,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03385"
    },
    "Rank": 2437,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03386"
    },
    "Rank": 2438,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03387"
    },
    "Rank": 2439,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03388"
    },
    "Rank": 2440,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03389"
    },
    "Rank": 2441,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0338a"
    },
    "Rank": 2442,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0338b"
    },
    "Rank": 2443,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0338c"
    },
    "Rank": 2444,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0338d"
    },
    "Rank": 2445,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0338e"
    },
    "Rank": 2448,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0338f"
    },
    "Rank": 2450,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03390"
    },
    "Rank": 2451,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03391"
    },
    "Rank": 2454,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03392"
    },
    "Rank": 2455,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03393"
    },
    "Rank": 2456,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03394"
    },
    "Rank": 2457,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03395"
    },
    "Rank": 2459,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03396"
    },
    "Rank": 2460,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03397"
    },
    "Rank": 2461,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03398"
    },
    "Rank": 2462,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03399"
    },
    "Rank": 2463,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaslok Hospital and Research Centre, ,15, Dr. G. Deshmukh Marg, Mumbai - 400026, Maharashtra, 400026",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0339a"
    },
    "Rank": 2464,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ERNAKULAM,GOVT MEDICAL COLLEGE, ERNAKULAM,\nKerala, 683503",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0339b"
    },
    "Rank": 2467,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0339c"
    },
    "Rank": 2468,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0339d"
    },
    "Rank": 2470,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0339e"
    },
    "Rank": 2472,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0339f"
    },
    "Rank": 2474,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033a0"
    },
    "Rank": 2475,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033a1"
    },
    "Rank": 2476,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital, ,GMERS Medical College and General hospital,Old TB Campus, gotri road, Vadodara, Gujarat, 390021",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033a2"
    },
    "Rank": 2477,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033a3"
    },
    "Rank": 2478,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033a4"
    },
    "Rank": 2479,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033a5"
    },
    "Rank": 2480,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gvernment Medical College,Haldwani,Government Medical College, Haldwani, Uttarakhand, 263139",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033a6"
    },
    "Rank": 2481,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karnataka,MG Road, Karnataka, 581301",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033a7"
    },
    "Rank": 2482,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aakash Healthcare Super Specialty Hospital, Delhi,HOSPITAL PLOT, ROAD NO- 201,SECTOR 3 DWARKA, Delhi (NCT), 110075",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033a8"
    },
    "Rank": 2483,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033a9"
    },
    "Rank": 2484,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033aa"
    },
    "Rank": 2486,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033ab"
    },
    "Rank": 2487,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033ac"
    },
    "Rank": 2488,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shalby Hospital, ,8TH Floor, shalby  hopsital , opp. karnwati club, sg highway ahmedabad, Gujarat, 380015",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033ad"
    },
    "Rank": 2489,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033ae"
    },
    "Rank": 2490,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI INSTITUTE OF CHILD HEALTH, BANGALORE,INDIRA GANDHI INSTITUTE OF CHILD HEALTH, BANGALORE, Karnataka, 560029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033af"
    },
    "Rank": 2491,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033b0"
    },
    "Rank": 2492,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033b1"
    },
    "Rank": 2493,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033b2"
    },
    "Rank": 2494,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033b3"
    },
    "Rank": 2495,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033b4"
    },
    "Rank": 2496,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033b5"
    },
    "Rank": 2497,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033b6"
    },
    "Rank": 2499,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033b7"
    },
    "Rank": 2504,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033b8"
    },
    "Rank": 2505,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindu Rao Hospital, ,SUBZI MANDI, MALKA GANJ, DELHI-110007, Delhi (NCT), 110007",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033b9"
    },
    "Rank": 2506,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033ba"
    },
    "Rank": 2507,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033bb"
    },
    "Rank": 2508,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033bc"
    },
    "Rank": 2511,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033bd"
    },
    "Rank": 2512,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033be"
    },
    "Rank": 2513,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033bf"
    },
    "Rank": 2514,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital,,DNB office, 3rd Floor Opp Library  Apollo Hospitals, Jubilee Hills, Hyderabad, Telangana, 500033",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033c0"
    },
    "Rank": 2516,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033c1"
    },
    "Rank": 2517,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033c2"
    },
    "Rank": 2518,
    "Allotted Quota": "All India",
    "Allotted Institute": "Radiation Medicine Centre (RMC), Bhabha Atomic Research Centre (BARC),Tata Memorial Hospital Premises, Annexe Bldg, Jerbai Wadia Road, Parel, Mumbai-400012, Maharashtra, 400012",
    "Course": "M.D. IN NUCLEAR MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033c3"
    },
    "Rank": 2520,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033c4"
    },
    "Rank": 2521,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033c5"
    },
    "Rank": 2522,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033c6"
    },
    "Rank": 2525,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033c7"
    },
    "Rank": 2526,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033c8"
    },
    "Rank": 2528,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lilavati Hospital and Research Centre, ,Lilavati Hospital And Research Centre A-791, Bandra Reclamation, Bandra - West, Mumbai, Maharashtra, 400050",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033c9"
    },
    "Rank": 2529,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pushpagiri Institute of Medical Sciences and Research Centre,,Pushpagiri Institute of Medical Sciences and Research centre, Pushp Marg, Tiruvalla, Kerala, 689101",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033ca"
    },
    "Rank": 2530,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033cb"
    },
    "Rank": 2531,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033cc"
    },
    "Rank": 2533,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033cd"
    },
    "Rank": 2534,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES ADILABAD -504001 TELANGANA STATE, Telangana, 504001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033ce"
    },
    "Rank": 2535,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sadhu Vaswani Missions ,7-9 Lane No 1 Koregaon Park Pune 411001, Maharashtra, 411001",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033cf"
    },
    "Rank": 2536,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033d0"
    },
    "Rank": 2538,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033d1"
    },
    "Rank": 2539,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT DHARMAPURI MEDICAL COLLEGE, DHARMAPURI, TAMIL NADU,GOVERNMENT DHARMAPURI MEDICAL COLLEGE, NETHAJI BYE PASS ROAD, DHARMAPURI, TAMIL NADU, Tamil Nadu, 636701",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033d2"
    },
    "Rank": 2540,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033d3"
    },
    "Rank": 2542,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College, Kolhapur,R.K. Nagar Road, Shenda Park, Kolhapur, Maharashtra, Maharashtra, 416012",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033d4"
    },
    "Rank": 2543,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033d5"
    },
    "Rank": 2544,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033d6"
    },
    "Rank": 2545,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asian Institute of Medical Sciences,,Asian Institute of Medical Sciences, Badhkal Flyover Road,Sector-21A, Faridabad, Haryana-121001, Haryana, 121001",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033d7"
    },
    "Rank": 2546,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033d8"
    },
    "Rank": 2547,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033d9"
    },
    "Rank": 2548,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvarur Govt.Medical College,Thiruvarur Govt.Medical College, Tamil Nadu, 610004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033da"
    },
    "Rank": 2551,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033db"
    },
    "Rank": 2552,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033dc"
    },
    "Rank": 2553,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033dd"
    },
    "Rank": 2554,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033de"
    },
    "Rank": 2555,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033df"
    },
    "Rank": 2556,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033e0"
    },
    "Rank": 2557,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033e1"
    },
    "Rank": 2560,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033e2"
    },
    "Rank": 2561,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033e3"
    },
    "Rank": 2562,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033e4"
    },
    "Rank": 2565,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Hospital, ,ALEXANDER ROAD, BESIDE HARIHARA KALABHAVAN, NEAR CLOCK TOWER, SECUNDERBAD, Telangana, 500003",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033e5"
    },
    "Rank": 2566,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dharamshila Narayana Superspeciality Hospital, ,Dharamshila Marg, Vasundhara Enclave, Dharamshila Narayana Superspeciality Hospital, Delhi 110096, Delhi (NCT), 110096",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033e6"
    },
    "Rank": 2567,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033e7"
    },
    "Rank": 2568,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033e8"
    },
    "Rank": 2569,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital,,DNB office, 3rd Floor Opp Library  Apollo Hospitals, Jubilee Hills, Hyderabad, Telangana, 500033",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033e9"
    },
    "Rank": 2571,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033ea"
    },
    "Rank": 2572,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033eb"
    },
    "Rank": 2573,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033ec"
    },
    "Rank": 2574,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033ed"
    },
    "Rank": 2575,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,LOTUS TOWER, G S ROAD, GUWAHATI, Assam, 781005",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033ee"
    },
    "Rank": 2576,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033ef"
    },
    "Rank": 2577,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033f0"
    },
    "Rank": 2579,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Continental Hospital, ,Continental Hospitals Pvt Ltd., Plot No.3, Road No.2, IT & Financial District, Nanakramguda, Gachibo, Telangana, 500032",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033f1"
    },
    "Rank": 2580,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033f2"
    },
    "Rank": 2582,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033f3"
    },
    "Rank": 2583,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033f4"
    },
    "Rank": 2584,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033f5"
    },
    "Rank": 2585,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Thiruvannamalai Medical College, Thiruvannamalai, Tamil Nadu,DISTRICT COLLECTORATE  MASTER PLAN COMPLEX, VENGIKKAL THIRUVANNAMALAI,\nTamil Nadu, 600604",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033f6"
    },
    "Rank": 2586,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033f7"
    },
    "Rank": 2587,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033f8"
    },
    "Rank": 2588,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033f9"
    },
    "Rank": 2590,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033fa"
    },
    "Rank": 2591,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033fb"
    },
    "Rank": 2592,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033fc"
    },
    "Rank": 2593,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Poona Hospital and Research Centre,,27,Sadashiv Peth, Pune -411030, Maharashtra, 411030",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033fd"
    },
    "Rank": 2594,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033fe"
    },
    "Rank": 2596,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a033ff"
    },
    "Rank": 2597,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03400"
    },
    "Rank": 2598,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03401"
    },
    "Rank": 2599,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03402"
    },
    "Rank": 2600,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03403"
    },
    "Rank": 2601,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03404"
    },
    "Rank": 2602,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Gleneagles Hospital, ,58 Canal Circular Road Kolkata 700054, West Bengal, 700054",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03405"
    },
    "Rank": 2603,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03406"
    },
    "Rank": 2604,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03407"
    },
    "Rank": 2605,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03408"
    },
    "Rank": 2606,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03409"
    },
    "Rank": 2607,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Gleneagles Hospital, ,58 Canal Circular Road Kolkata 700054, West Bengal, 700054",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0340a"
    },
    "Rank": 2609,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0340b"
    },
    "Rank": 2610,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0340c"
    },
    "Rank": 2611,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Poona Hospital and Research Centre,,27,Sadashiv Peth, Pune -411030, Maharashtra, 411030",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0340d"
    },
    "Rank": 2612,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0340e"
    },
    "Rank": 2613,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Hospital, ,ALEXANDER ROAD, BESIDE HARIHARA KALABHAVAN, NEAR CLOCK TOWER, SECUNDERBAD, Telangana, 500003",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0340f"
    },
    "Rank": 2614,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03410"
    },
    "Rank": 2616,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03411"
    },
    "Rank": 2617,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03412"
    },
    "Rank": 2618,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03413"
    },
    "Rank": 2619,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03414"
    },
    "Rank": 2620,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03415"
    },
    "Rank": 2621,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03416"
    },
    "Rank": 2622,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03417"
    },
    "Rank": 2623,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03418"
    },
    "Rank": 2625,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03419"
    },
    "Rank": 2626,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0341a"
    },
    "Rank": 2628,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0341b"
    },
    "Rank": 2629,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0341c"
    },
    "Rank": 2631,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0341d"
    },
    "Rank": 2632,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0341e"
    },
    "Rank": 2633,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Sivagangai Medical College and Hospital, Tamil Nadu,GOVT.SIVAGANGAI MEDICAL COLLEGE, MELAVANIYANKUDY, MANAMADURAI ROAD, SIVAGANGAI.,\nTamil Nadu, 630561",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0341f"
    },
    "Rank": 2634,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03420"
    },
    "Rank": 2635,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03421"
    },
    "Rank": 2636,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03422"
    },
    "Rank": 2637,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaypee Hospital,,sector 128, Noida, Uttar Pradesh, 201304",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03423"
    },
    "Rank": 2638,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03424"
    },
    "Rank": 2639,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Sivagangai Medical College and Hospital, Tamil Nadu,GOVT.SIVAGANGAI MEDICAL COLLEGE, MELAVANIYANKUDY, MANAMADURAI ROAD, SIVAGANGAI.,\nTamil Nadu, 630561",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03425"
    },
    "Rank": 2640,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03426"
    },
    "Rank": 2641,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03427"
    },
    "Rank": 2642,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM New Bombay Hospital,,Plot 35, Sector 3, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03428"
    },
    "Rank": 2643,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03429"
    },
    "Rank": 2644,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,APOLLO HOSPITALS BANGALORE A UNIT OF IMPERIAL HOSPITALS AND RESEARCH CENTRE LIMITED 154/11 Opp IIM B, Karnataka, 560076",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0342a"
    },
    "Rank": 2645,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Ramakrishna Hospital, ,395, Sarojini Naidu Road, Sidhapudur,\nCoimbatore - 641 044, Tamil Nadu, 641044",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0342b"
    },
    "Rank": 2646,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0342c"
    },
    "Rank": 2647,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0342d"
    },
    "Rank": 2648,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0342e"
    },
    "Rank": 2649,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "VCSG Govt Institute of Medical Science and Research and HNB Base Teaching Hospital\n, ,Srikot Ganganali Srinagar Garhwal Uttarakhand, Uttarakhand, 246174",
    "Course": "(NBEMS) DERMATOLOGY and VENEREOLOGY and LEPROSY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0342f"
    },
    "Rank": 2650,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03430"
    },
    "Rank": 2651,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medical Trust Hospital, ,M.G. Road Pallimukku\nCochin, Kerala, 682016",
    "Course": "(NBEMS) DERMATOLOGY and VENEREOLOGY and LEPROSY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03431"
    },
    "Rank": 2652,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03432"
    },
    "Rank": 2653,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03433"
    },
    "Rank": 2654,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03434"
    },
    "Rank": 2655,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Balaji Action Medical Institute,,FC34 A4 PASCHIM VIHAR NEW DELHI, Delhi (NCT), 110063",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03435"
    },
    "Rank": 2656,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03436"
    },
    "Rank": 2657,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03437"
    },
    "Rank": 2658,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03438"
    },
    "Rank": 2659,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03439"
    },
    "Rank": 2660,
    "Allotted Quota": "All India",
    "Allotted Institute": "Institute of Child Health, Kolkata,Institute of Child Health, Kolkata, West Bengal, 700017",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0343a"
    },
    "Rank": 2661,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0343b"
    },
    "Rank": 2663,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0343c"
    },
    "Rank": 2664,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "National Institute of Tuberculosis and Respiratory Diseases, (Formerly Known as L.R.S. Instt. of T.B. and Allied Scs),,National Institute of Tuberculosis and Respiratory Diseases (Formerly Known as L.R.S. Instt. of T.B., Delhi (NCT), 110030",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0343d"
    },
    "Rank": 2665,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0343e"
    },
    "Rank": 2666,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0343f"
    },
    "Rank": 2668,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03440"
    },
    "Rank": 2669,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03441"
    },
    "Rank": 2670,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03442"
    },
    "Rank": 2671,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03443"
    },
    "Rank": 2672,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03444"
    },
    "Rank": 2673,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College,Fakhruddin Ali Ahmed Medical College, Assam, 781301",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03445"
    },
    "Rank": 2675,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03446"
    },
    "Rank": 2677,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03447"
    },
    "Rank": 2678,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03448"
    },
    "Rank": 2679,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03449"
    },
    "Rank": 2680,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster CMI Hospital, ,43/2 New Airport Road, NH-7, Kodigehalli, Sahakara Nagar, Hebbal, Bengaluru, Karnataka, 560092",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0344a"
    },
    "Rank": 2681,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Sivagangai Medical College and Hospital, Tamil Nadu,GOVT.SIVAGANGAI MEDICAL COLLEGE, MELAVANIYANKUDY, MANAMADURAI ROAD, SIVAGANGAI.,\nTamil Nadu, 630561",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0344b"
    },
    "Rank": 2682,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kiran Multi Super Speciality Hospital and Research Center, ,Kiran Multi Super Speciality Hospital Centre,\nNear Sumul Dairy, Vastadevdi Road, Surat, Gujarat, 395004",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0344c"
    },
    "Rank": 2683,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0344d"
    },
    "Rank": 2684,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI INSTITUTE OF CHILD HEALTH, BANGALORE,INDIRA GANDHI INSTITUTE OF CHILD HEALTH, BANGALORE, Karnataka, 560029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0344e"
    },
    "Rank": 2685,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0344f"
    },
    "Rank": 2687,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03450"
    },
    "Rank": 2688,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03451"
    },
    "Rank": 2689,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03452"
    },
    "Rank": 2690,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03453"
    },
    "Rank": 2691,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03454"
    },
    "Rank": 2693,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03455"
    },
    "Rank": 2694,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "J.L.N. Main Hospital and Research Centre, ,Bhilai Steel Plant Sector-9, Bhilai, C.G., Chhattisgarh, 490009",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03456"
    },
    "Rank": 2695,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03457"
    },
    "Rank": 2696,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Gleneagles Hospital, ,58 Canal Circular Road Kolkata 700054, West Bengal, 700054",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03458"
    },
    "Rank": 2697,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03459"
    },
    "Rank": 2698,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0345a"
    },
    "Rank": 2699,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0345b"
    },
    "Rank": 2700,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0345c"
    },
    "Rank": 2703,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0345d"
    },
    "Rank": 2704,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0345e"
    },
    "Rank": 2706,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0345f"
    },
    "Rank": 2709,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vivekananda Polyclinic and Institute of Medical Sciences, ,Vivekananda Polyclinic and Institute of Medical Sciences, Vivekananda Puram, Lucknow, Uttar Pradesh, 226007",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03460"
    },
    "Rank": 2710,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03461"
    },
    "Rank": 2711,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Poonam Palaspagar - Academic Coordinator\nFortis Hospital, Bannerghatta Road, Bangalore, Karnataka 5, Karnataka, 560076",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03462"
    },
    "Rank": 2714,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03463"
    },
    "Rank": 2715,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03464"
    },
    "Rank": 2717,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03465"
    },
    "Rank": 2718,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03466"
    },
    "Rank": 2719,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03467"
    },
    "Rank": 2720,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03468"
    },
    "Rank": 2722,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jupiter Lifeline Hospital,,Jupiter Lifeline Hospital, Sr No 142/12, And 143/1/4/5, Near Prathamesh Park, Baner, Pune, Maharasht, Maharashtra, 411045",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03469"
    },
    "Rank": 2723,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0346a"
    },
    "Rank": 2724,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0346b"
    },
    "Rank": 2725,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0346c"
    },
    "Rank": 2727,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0346d"
    },
    "Rank": 2728,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0346e"
    },
    "Rank": 2730,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,APOLLO HOSPITALS BANGALORE A UNIT OF IMPERIAL HOSPITALS AND RESEARCH CENTRE LIMITED 154/11 Opp IIM B, Karnataka, 560076",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0346f"
    },
    "Rank": 2733,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03470"
    },
    "Rank": 2734,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03471"
    },
    "Rank": 2735,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03472"
    },
    "Rank": 2736,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03473"
    },
    "Rank": 2737,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03474"
    },
    "Rank": 2738,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03475"
    },
    "Rank": 2739,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03476"
    },
    "Rank": 2740,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03477"
    },
    "Rank": 2741,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03478"
    },
    "Rank": 2742,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Stephen`s Hospital, ,Tis Hazari, Delhi (NCT), 110054",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03479"
    },
    "Rank": 2743,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0347a"
    },
    "Rank": 2744,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE, PUDUCHERRY,INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE,\nPUDUCHERRY, Puducherry, 605009",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0347b"
    },
    "Rank": 2745,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0347c"
    },
    "Rank": 2746,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0347d"
    },
    "Rank": 2748,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0347e"
    },
    "Rank": 2749,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute Of Medical Sciences, Kalaburagi,VEERESH NAGAR SEDAM ROAD KALABURAGI, Karnataka, 585105",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0347f"
    },
    "Rank": 2750,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03480"
    },
    "Rank": 2751,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,Nalgonda X Roads, Malakpet, Hyderabad, Telangana, 500036",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03481"
    },
    "Rank": 2752,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03482"
    },
    "Rank": 2754,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03483"
    },
    "Rank": 2757,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03484"
    },
    "Rank": 2758,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03485"
    },
    "Rank": 2759,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03486"
    },
    "Rank": 2761,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03487"
    },
    "Rank": 2762,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03488"
    },
    "Rank": 2763,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03489"
    },
    "Rank": 2764,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0348a"
    },
    "Rank": 2765,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0348b"
    },
    "Rank": 2768,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0348c"
    },
    "Rank": 2769,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INSTITUTE OF MEDICAL SCIENCES,RAICHUR INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 584102",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0348d"
    },
    "Rank": 2770,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0348e"
    },
    "Rank": 2771,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0348f"
    },
    "Rank": 2772,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute of Medical Sciences Srikakulam,Government Medical College Srikakulam\nBalaga Srikakulam Town Srikakulam District, Andhra Pradesh, 532001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03490"
    },
    "Rank": 2773,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Baba Saheb Ambedkar Hospital, ,SEC 6 ROHINI DELHI, Delhi (NCT), 110085",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03491"
    },
    "Rank": 2774,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Stephen`s Hospital, ,Tis Hazari, Delhi (NCT), 110054",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03492"
    },
    "Rank": 2776,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03493"
    },
    "Rank": 2777,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03494"
    },
    "Rank": 2778,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03495"
    },
    "Rank": 2780,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Billroth Hospital,,43,Lakshmi Talkies Road, Shenoy Nagar,\nChennai, Tamil Nadu, 600030",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03496"
    },
    "Rank": 2781,
    "Allotted Quota": "All India",
    "Allotted Institute": "Post Graduate Institute of Child Health, Noida.,Post Graduate Institute of Child Health,SECTOR-30, NOIDA, GAUTAM Buddha Nagar, Uttar Pradesh, 201303",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03497"
    },
    "Rank": 2782,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03498"
    },
    "Rank": 2783,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03499"
    },
    "Rank": 2784,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Mulund - Goregaon Link Rd, Nahur West, Industrial Area, Bhandup West, Mumbai, Maharashtra 400078, Maharashtra, 400078",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0349a"
    },
    "Rank": 2785,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vivekananda Polyclinic and Institute of Medical Sciences, ,Vivekananda Polyclinic and Institute of Medical Sciences, Vivekananda Puram, Lucknow, Uttar Pradesh, 226007",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0349b"
    },
    "Rank": 2786,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0349c"
    },
    "Rank": 2788,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0349d"
    },
    "Rank": 2789,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0349e"
    },
    "Rank": 2790,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0349f"
    },
    "Rank": 2791,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034a0"
    },
    "Rank": 2792,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034a1"
    },
    "Rank": 2793,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034a2"
    },
    "Rank": 2794,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034a3"
    },
    "Rank": 2796,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Metro Heart Institute with Multispecialty, ,SECTOR 16A, Faridabad, Haryana, Haryana, 121002",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034a4"
    },
    "Rank": 2797,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034a5"
    },
    "Rank": 2801,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "South Central Railways Hospital, ,SOUTH CENTRAL RAILWAYS CENTRAL HOSPITAL, OFFICE OF THE MEDICAL DIRECTOR, 1ST FLOOR\nLALLAGUDA, METT, Telangana, 500017",
    "Course": "(NBEMS) DERMATOLOGY and VENEREOLOGY and LEPROSY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034a6"
    },
    "Rank": 2802,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bombay Hospital,Ring Road Indore, Madhya Pradesh, 452010",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034a7"
    },
    "Rank": 2803,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034a8"
    },
    "Rank": 2804,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034a9"
    },
    "Rank": 2805,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Stephen`s Hospital, ,Tis Hazari, Delhi (NCT), 110054",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034aa"
    },
    "Rank": 2806,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034ab"
    },
    "Rank": 2807,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034ac"
    },
    "Rank": 2808,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034ad"
    },
    "Rank": 2809,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034ae"
    },
    "Rank": 2810,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034af"
    },
    "Rank": 2811,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Gleneagles Hospital, ,58 Canal Circular Road Kolkata 700054, West Bengal, 700054",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034b0"
    },
    "Rank": 2812,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034b1"
    },
    "Rank": 2813,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Royal Care Super Speciality Hospital, ,1,520 L and T Bypass Road, Neelambur, Coimbatore., Tamil Nadu, 641062",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034b2"
    },
    "Rank": 2814,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034b3"
    },
    "Rank": 2815,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034b4"
    },
    "Rank": 2817,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaslok Hospital and Research Centre, ,15, Dr. G. Deshmukh Marg, Mumbai - 400026, Maharashtra, 400026",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034b5"
    },
    "Rank": 2818,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034b6"
    },
    "Rank": 2819,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034b7"
    },
    "Rank": 2820,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034b8"
    },
    "Rank": 2821,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034b9"
    },
    "Rank": 2823,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034ba"
    },
    "Rank": 2824,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Continental Hospital, ,Continental Hospitals Pvt Ltd., Plot No.3, Road No.2, IT & Financial District, Nanakramguda, Gachibo, Telangana, 500032",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034bb"
    },
    "Rank": 2825,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034bc"
    },
    "Rank": 2826,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034bd"
    },
    "Rank": 2827,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034be"
    },
    "Rank": 2830,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034bf"
    },
    "Rank": 2832,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034c0"
    },
    "Rank": 2833,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ERNAKULAM,GOVT MEDICAL COLLEGE, ERNAKULAM,\nKerala, 683503",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034c1"
    },
    "Rank": 2837,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034c2"
    },
    "Rank": 2838,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034c3"
    },
    "Rank": 2839,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034c4"
    },
    "Rank": 2841,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034c5"
    },
    "Rank": 2842,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034c6"
    },
    "Rank": 2843,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034c7"
    },
    "Rank": 2844,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034c8"
    },
    "Rank": 2845,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gvernment Medical College,Haldwani,Government Medical College, Haldwani, Uttarakhand, 263139",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034c9"
    },
    "Rank": 2847,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034ca"
    },
    "Rank": 2849,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Stephen`s Hospital, ,Tis Hazari, Delhi (NCT), 110054",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034cb"
    },
    "Rank": 2851,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034cc"
    },
    "Rank": 2853,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034cd"
    },
    "Rank": 2854,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034ce"
    },
    "Rank": 2857,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034cf"
    },
    "Rank": 2858,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034d0"
    },
    "Rank": 2859,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034d1"
    },
    "Rank": 2860,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034d2"
    },
    "Rank": 2862,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034d3"
    },
    "Rank": 2863,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI INSTITUTE OF CHILD HEALTH, BANGALORE,INDIRA GANDHI INSTITUTE OF CHILD HEALTH, BANGALORE, Karnataka, 560029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034d4"
    },
    "Rank": 2865,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034d5"
    },
    "Rank": 2866,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034d6"
    },
    "Rank": 2868,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster CMI Hospital, ,43/2 New Airport Road, NH-7, Kodigehalli, Sahakara Nagar, Hebbal, Bengaluru, Karnataka, 560092",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034d7"
    },
    "Rank": 2869,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034d8"
    },
    "Rank": 2870,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034d9"
    },
    "Rank": 2871,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034da"
    },
    "Rank": 2872,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034db"
    },
    "Rank": 2873,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034dc"
    },
    "Rank": 2874,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Columbia Asia Referral Hospital, ,No 26 4 Brigade Gateway, Malleshwaram West, Beside Metro, Bengaluru- 560055, Karnataka, 560055",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034dd"
    },
    "Rank": 2875,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034de"
    },
    "Rank": 2876,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034df"
    },
    "Rank": 2877,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034e0"
    },
    "Rank": 2879,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kokilaben Dhirubhai Ambani Hospital and Medical Research Institute,,Achyutrao Patwardhan Marg Andheri West Mumbai Maharashtra, Maharashtra, 400053",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034e1"
    },
    "Rank": 2881,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034e2"
    },
    "Rank": 2882,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034e3"
    },
    "Rank": 2883,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034e4"
    },
    "Rank": 2884,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034e5"
    },
    "Rank": 2886,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034e6"
    },
    "Rank": 2888,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,RAJBHAVAN ROAD SOMAJIGUDA, Telangana, 500082",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034e7"
    },
    "Rank": 2889,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034e8"
    },
    "Rank": 2891,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034e9"
    },
    "Rank": 2892,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034ea"
    },
    "Rank": 2893,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034eb"
    },
    "Rank": 2895,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034ec"
    },
    "Rank": 2896,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034ed"
    },
    "Rank": 2897,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Royal Care Super Speciality Hospital, ,1,520 L and T Bypass Road, Neelambur, Coimbatore., Tamil Nadu, 641062",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034ee"
    },
    "Rank": 2898,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034ef"
    },
    "Rank": 2899,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034f0"
    },
    "Rank": 2901,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034f1"
    },
    "Rank": 2904,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sanjay Gandhi Postgarduate Institute of Medical Sciences,Sanjay Gandhi Postgarduate Institute of Medical Sciences, Uttar Pradesh, 226014",
    "Course": "M.D. IN NUCLEAR MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034f2"
    },
    "Rank": 2905,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Columbia Asia Referral Hospital, ,No 26 4 Brigade Gateway, Malleshwaram West, Beside Metro, Bengaluru- 560055, Karnataka, 560055",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034f3"
    },
    "Rank": 2906,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "G Kuppuswamy Naidu Memorial Hospital, ,Post Box No.6327, Nethaji Road, Pappanaickenpalayam, Coimbatore, Tamil Nadu, 641037",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034f4"
    },
    "Rank": 2907,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034f5"
    },
    "Rank": 2909,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034f6"
    },
    "Rank": 2910,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034f7"
    },
    "Rank": 2912,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034f8"
    },
    "Rank": 2913,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034f9"
    },
    "Rank": 2914,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034fa"
    },
    "Rank": 2915,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034fb"
    },
    "Rank": 2916,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034fc"
    },
    "Rank": 2917,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034fd"
    },
    "Rank": 2918,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034fe"
    },
    "Rank": 2919,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a034ff"
    },
    "Rank": 2920,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03500"
    },
    "Rank": 2921,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhabha Atomic Research Centre and Hospital, ,Anushaktinagar, Mumbai, Maharashtra, 400094",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03501"
    },
    "Rank": 2923,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03502"
    },
    "Rank": 2924,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03503"
    },
    "Rank": 2925,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03504"
    },
    "Rank": 2926,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03505"
    },
    "Rank": 2928,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03506"
    },
    "Rank": 2932,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital Whitefield,,ITPL Main Rd, KIADB Export Promotion Industrial Area, Krishnarajapura, Bengaluru, Karnataka, 560066",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03507"
    },
    "Rank": 2933,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03508"
    },
    "Rank": 2935,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03509"
    },
    "Rank": 2936,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0350a"
    },
    "Rank": 2937,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0350b"
    },
    "Rank": 2938,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0350c"
    },
    "Rank": 2939,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0350d"
    },
    "Rank": 2940,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0350e"
    },
    "Rank": 2941,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0350f"
    },
    "Rank": 2942,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mata Chanan Devi Hospital,, ,C-1 JANAKPURI, Delhi (NCT), 110058",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03510"
    },
    "Rank": 2943,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03511"
    },
    "Rank": 2944,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03512"
    },
    "Rank": 2945,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03513"
    },
    "Rank": 2946,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03514"
    },
    "Rank": 2947,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03515"
    },
    "Rank": 2948,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03516"
    },
    "Rank": 2949,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03517"
    },
    "Rank": 2951,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03518"
    },
    "Rank": 2953,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03519"
    },
    "Rank": 2954,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0351a"
    },
    "Rank": 2955,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0351b"
    },
    "Rank": 2956,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0351c"
    },
    "Rank": 2957,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0351d"
    },
    "Rank": 2958,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0351e"
    },
    "Rank": 2960,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Wockhardt Hospital, ,1877, Dr. Anand Rao Nair Marg, Mumbai Central, Maharashtra, 400011",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0351f"
    },
    "Rank": 2963,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03520"
    },
    "Rank": 2964,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03521"
    },
    "Rank": 2965,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03522"
    },
    "Rank": 2966,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03523"
    },
    "Rank": 2967,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03524"
    },
    "Rank": 2968,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Parsik Hill Road, ,APOLLO HOSPITAL PARSIK HILL ROAD ROAD NO 13 SECTOR 23 CBD BELAPUR NAVI MUMBAI, Maharashtra, 400614",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03525"
    },
    "Rank": 2969,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03526"
    },
    "Rank": 2970,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03527"
    },
    "Rank": 2971,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03528"
    },
    "Rank": 2972,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03529"
    },
    "Rank": 2973,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0352a"
    },
    "Rank": 2974,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN,HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN, Karnataka, 573201",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0352b"
    },
    "Rank": 2976,
    "Allotted Quota": "All India",
    "Allotted Institute": "Radiation Medicine Centre (RMC), Bhabha Atomic Research Centre (BARC),Tata Memorial Hospital Premises, Annexe Bldg, Jerbai Wadia Road, Parel, Mumbai-400012, Maharashtra, 400012",
    "Course": "M.D. IN NUCLEAR MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0352c"
    },
    "Rank": 2978,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0352d"
    },
    "Rank": 2980,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0352e"
    },
    "Rank": 2981,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0352f"
    },
    "Rank": 2982,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03530"
    },
    "Rank": 2983,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03531"
    },
    "Rank": 2984,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharashtra Post Graduate Institute of Medical Education and Research, Nashik,Maharashtra Post Graduation Institute Of Medical Education and Research Civil Hospital campus Trimba, Maharashtra, 422002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03532"
    },
    "Rank": 2985,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03533"
    },
    "Rank": 2988,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03534"
    },
    "Rank": 2990,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03535"
    },
    "Rank": 2992,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03536"
    },
    "Rank": 2993,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03537"
    },
    "Rank": 2994,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03538"
    },
    "Rank": 2997,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Spirit Hospital, ,Mahakali Caves Road Andheri East Mumbai, Maharashtra, 400093",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03539"
    },
    "Rank": 2998,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0353a"
    },
    "Rank": 3001,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0353b"
    },
    "Rank": 3003,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0353c"
    },
    "Rank": 3004,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0353d"
    },
    "Rank": 3005,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT DHARMAPURI MEDICAL COLLEGE, DHARMAPURI, TAMIL NADU,GOVERNMENT DHARMAPURI MEDICAL COLLEGE, NETHAJI BYE PASS ROAD, DHARMAPURI, TAMIL NADU, Tamil Nadu, 636701",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0353e"
    },
    "Rank": 3007,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College,Theni ,Government Medical College,Theni, Tamil Nadu, 625512",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0353f"
    },
    "Rank": 3011,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03540"
    },
    "Rank": 3013,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03541"
    },
    "Rank": 3014,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03542"
    },
    "Rank": 3015,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B. Nanavati Hospital, Vivekanand Rd,Swami Vivekananda Rd, near LIC, LIC Colony, Suresh Colony, Vile Parle West, Mumbai, Maharashtra 4000, Maharashtra, 400056",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03543"
    },
    "Rank": 3016,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03544"
    },
    "Rank": 3019,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03545"
    },
    "Rank": 3022,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03546"
    },
    "Rank": 3025,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03547"
    },
    "Rank": 3026,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03548"
    },
    "Rank": 3027,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03549"
    },
    "Rank": 3028,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0354a"
    },
    "Rank": 3029,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0354b"
    },
    "Rank": 3030,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute Of Medical Sciences, Kalaburagi,VEERESH NAGAR SEDAM ROAD KALABURAGI, Karnataka, 585105",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0354c"
    },
    "Rank": 3032,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0354d"
    },
    "Rank": 3033,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deen Dayal Upadhyay Hospital, ,DDU HOSPITAL, SHAHEED MANGAL PANDEY MARG, NANAK PURA, HARI NAGAR, NEW DELHI, DELHI-110064, Delhi (NCT), 110064",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0354e"
    },
    "Rank": 3034,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0354f"
    },
    "Rank": 3035,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Spirit Hospital, ,Mahakali Caves Road Andheri East Mumbai, Maharashtra, 400093",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03550"
    },
    "Rank": 3036,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03551"
    },
    "Rank": 3037,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Hospital, Omandurar,GOVERNMENT MEDICAL COLLEGE, OMANDURAR GOVERNMENT ESTATE, WALAJAH ROAD, NEAR KALAIVANAR ARANGAM, CHEN, Tamil Nadu, 600002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03552"
    },
    "Rank": 3038,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VELLORE MEDICAL COLLEGE,GOVERNMENT VELLORE MEDICAL COLLEGE, Tamil Nadu, 632011",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03553"
    },
    "Rank": 3040,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03554"
    },
    "Rank": 3041,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03555"
    },
    "Rank": 3042,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03556"
    },
    "Rank": 3043,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03557"
    },
    "Rank": 3044,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03558"
    },
    "Rank": 3045,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03559"
    },
    "Rank": 3046,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Billroth Hospital,,43,Lakshmi Talkies Road, Shenoy Nagar,\nChennai, Tamil Nadu, 600030",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0355a"
    },
    "Rank": 3047,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0355b"
    },
    "Rank": 3048,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0355c"
    },
    "Rank": 3049,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0355d"
    },
    "Rank": 3050,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0355e"
    },
    "Rank": 3052,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0355f"
    },
    "Rank": 3054,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03560"
    },
    "Rank": 3056,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03561"
    },
    "Rank": 3058,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03562"
    },
    "Rank": 3059,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03563"
    },
    "Rank": 3061,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollomedics Superspeciality Hospital,KBC 31, Sector B, LDA Colony, Kanpur Road, Lucknow, Uttar Pradesh, 226012",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03564"
    },
    "Rank": 3062,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03565"
    },
    "Rank": 3063,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03566"
    },
    "Rank": 3065,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03567"
    },
    "Rank": 3067,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03568"
    },
    "Rank": 3068,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03569"
    },
    "Rank": 3069,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0356a"
    },
    "Rank": 3070,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0356b"
    },
    "Rank": 3071,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0356c"
    },
    "Rank": 3072,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0356d"
    },
    "Rank": 3073,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0356e"
    },
    "Rank": 3076,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0356f"
    },
    "Rank": 3077,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Choithram Hospital and Research Centre,Manika Bagh Road Indore, Madhya Pradesh, 452014",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03570"
    },
    "Rank": 3078,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03571"
    },
    "Rank": 3079,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03572"
    },
    "Rank": 3080,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INSTITUTE OF MEDICAL SCIENCES,RAICHUR INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 584102",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03573"
    },
    "Rank": 3081,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VILLUPURAM MEDICAL COLLEGE, VILLUPURAM,GOVERNMENT VILLUPURAM MEDICAL COLLEGE VILLUPURAM  TRICHY TRUNK ROAD MUNDIYAMPAKKAM  VILLUPURAM DISTR, Tamil Nadu, 605601",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03574"
    },
    "Rank": 3082,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03575"
    },
    "Rank": 3084,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SANKARA NETHRALAYA ,\nTamil Nadu,No 21 Pycrofts Garden Road off. Haddows Road\nChennai, Tamil Nadu, 600006",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03576"
    },
    "Rank": 3085,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03577"
    },
    "Rank": 3088,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03578"
    },
    "Rank": 3090,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03579"
    },
    "Rank": 3091,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0357a"
    },
    "Rank": 3092,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0357b"
    },
    "Rank": 3093,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Human care Medical Charitable Trust,Human Care Medical Charitable Trust. HCMCTManipal Hospital, Sector 6, DWARKA, NEW DELHI, Delhi (NCT), 110075",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0357c"
    },
    "Rank": 3095,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Human care Medical Charitable Trust,Human Care Medical Charitable Trust. HCMCTManipal Hospital, Sector 6, DWARKA, NEW DELHI, Delhi (NCT), 110075",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0357d"
    },
    "Rank": 3097,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asian Institute of Medical Sciences,,Asian Institute of Medical Sciences, Badhkal Flyover Road,Sector-21A, Faridabad, Haryana-121001, Haryana, 121001",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0357e"
    },
    "Rank": 3098,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0357f"
    },
    "Rank": 3099,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03580"
    },
    "Rank": 3100,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03581"
    },
    "Rank": 3101,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03582"
    },
    "Rank": 3103,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03583"
    },
    "Rank": 3105,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Baba Saheb Ambedkar Hospital, ,SEC 6 ROHINI DELHI, Delhi (NCT), 110085",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03584"
    },
    "Rank": 3106,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Santokbha Durlabhji Memorial Hospital Cum Medical Research Institute,\n,dnb.sdmh@gmail.com, Rajasthan, 302015",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03585"
    },
    "Rank": 3107,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03586"
    },
    "Rank": 3109,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03587"
    },
    "Rank": 3110,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF HUMAN  BEHAVIOUR AND ALLIED SCIENCES,Institute of Human\nBehaviour and Allied Sciences,  Dilshad Garden, New Delhi, Delhi 110095, India, Delhi (NCT), 110095",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03588"
    },
    "Rank": 3113,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03589"
    },
    "Rank": 3115,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0358a"
    },
    "Rank": 3116,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES,CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, Chhattisgarh, 495001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0358b"
    },
    "Rank": 3117,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0358c"
    },
    "Rank": 3118,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kanchi Kamakoti Childs Trust Hospital, ,12 A, NAGESWARA ROAD, NUNGAMBAKKAM, CHENNAI., Tamil Nadu, 600034",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0358d"
    },
    "Rank": 3119,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences, ,academics@kimshospitals.com, Telangana, 500003",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0358e"
    },
    "Rank": 3120,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0358f"
    },
    "Rank": 3122,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03590"
    },
    "Rank": 3125,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03591"
    },
    "Rank": 3126,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03592"
    },
    "Rank": 3127,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03593"
    },
    "Rank": 3128,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03594"
    },
    "Rank": 3129,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Northern Railway Central Hospital,, ,Basant Lane New Delhi, Delhi (NCT), 110055",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03595"
    },
    "Rank": 3130,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03596"
    },
    "Rank": 3131,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03597"
    },
    "Rank": 3132,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03598"
    },
    "Rank": 3133,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03599"
    },
    "Rank": 3135,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0359a"
    },
    "Rank": 3142,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sahyadri Super Specialty Hospital,,Plot No.30C, Erandawane, Karve Road, Pune, Maharashtra, 411004",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0359b"
    },
    "Rank": 3143,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "G B Pant Institute of Post Graduate Medical Education and Research,G B Pant Institute of Post Graduate Medical Education and Research, Delhi (NCT), 110007",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0359c"
    },
    "Rank": 3144,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B. C. Roy Post Graduate Institute of Paediatric Sciences,111 Narkeldanga Main Road Kolkata, West Bengal, 700054",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0359d"
    },
    "Rank": 3145,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0359e"
    },
    "Rank": 3146,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0359f"
    },
    "Rank": 3147,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035a0"
    },
    "Rank": 3150,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035a1"
    },
    "Rank": 3152,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Bundelkhand Medical College Sagar M.P.,Govt. Bundelkhand Medical College Sagar M.P., Madhya Pradesh, 470001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035a2"
    },
    "Rank": 3153,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035a3"
    },
    "Rank": 3155,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035a4"
    },
    "Rank": 3156,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035a5"
    },
    "Rank": 3157,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035a6"
    },
    "Rank": 3158,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo BGS Hospital,,Apollo BGS Hospitals, Adhichunchangiri Road, Kuvempunagar, Mysore, Karnataka, 570023",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035a7"
    },
    "Rank": 3159,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035a8"
    },
    "Rank": 3160,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Specialty Hospital, Tamil Nadu,Plot No, 64, Vanagaram-Ambattur Rd, Ayanambakkam, Ambattur Industrial Estate, Chennai, Tamil Nadu 60, Tamil Nadu, 600095",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035a9"
    },
    "Rank": 3162,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035aa"
    },
    "Rank": 3164,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035ab"
    },
    "Rank": 3165,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035ac"
    },
    "Rank": 3167,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035ad"
    },
    "Rank": 3168,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035ae"
    },
    "Rank": 3170,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vadamalayan Hospital,,Registered Office - 15, Jawahar Road, Chokkikulam, Madurai - 625002.\n9A, Vallabhai Road, Chokkikula, Tamil Nadu, 625002",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035af"
    },
    "Rank": 3171,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035b0"
    },
    "Rank": 3172,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, THOOTHUKUDI,GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, 3rd\nMile,Kamaraj Nagar,Thoothukudi., Tamil Nadu, 628008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035b1"
    },
    "Rank": 3173,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035b2"
    },
    "Rank": 3175,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035b3"
    },
    "Rank": 3178,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035b4"
    },
    "Rank": 3179,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sakra World Hospital, (A Unit of Takshasila Hospitals Operating Private Limited)\n,LOVEKESH\\DOT\\PHASU\\AT\\SAKRAWORLDHOSPITAL\\DOT\\COM, Karnataka, 560103",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035b5"
    },
    "Rank": 3180,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, THOOTHUKUDI,GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, 3rd\nMile,Kamaraj Nagar,Thoothukudi., Tamil Nadu, 628008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035b6"
    },
    "Rank": 3181,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035b7"
    },
    "Rank": 3182,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035b8"
    },
    "Rank": 3183,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035b9"
    },
    "Rank": 3184,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035ba"
    },
    "Rank": 3185,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035bb"
    },
    "Rank": 3187,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035bc"
    },
    "Rank": 3189,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035bd"
    },
    "Rank": 3190,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035be"
    },
    "Rank": 3193,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "AIG Hospital,Door No: 1 66 AIG 2 to 5 Mindspace Road  Gachibowli Hyderabad\nTelangana 500032",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035bf"
    },
    "Rank": 3194,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035c0"
    },
    "Rank": 3195,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Datia,Near 29 Batallion, National Highway 44, Dist Datia, Madhya Pradesh Pin - 475661, Madhya Pradesh, 475661",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035c1"
    },
    "Rank": 3196,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035c2"
    },
    "Rank": 3197,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035c3"
    },
    "Rank": 3198,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035c4"
    },
    "Rank": 3199,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035c5"
    },
    "Rank": 3201,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035c6"
    },
    "Rank": 3202,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035c7"
    },
    "Rank": 3203,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Hurkisondas Nurrotumdas Hospital and Research Centre, ,Prarthana Samaj, Raja Ram Mohan Roy road,\nGirgaon, MUMBAI, Maharashtra, 400004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035c8"
    },
    "Rank": 3205,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035c9"
    },
    "Rank": 3206,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035ca"
    },
    "Rank": 3212,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035cb"
    },
    "Rank": 3213,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Malabar Institute of Medical Sciences, ,Govindapuram P.O., Mini Bypass Road, Kozhikode, Kerala, 673016",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035cc"
    },
    "Rank": 3214,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035cd"
    },
    "Rank": 3215,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035ce"
    },
    "Rank": 3217,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035cf"
    },
    "Rank": 3218,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035d0"
    },
    "Rank": 3219,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Medical College and Chhatrapati Shivaji Maharaj Hospital, ,RAJIV GANDHI MEDICAL COLLEGE,THANE BELAPUR ROAD, KALWA, THANE, Maharashtra, 400605",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035d1"
    },
    "Rank": 3221,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035d2"
    },
    "Rank": 3222,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035d3"
    },
    "Rank": 3223,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035d4"
    },
    "Rank": 3224,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035d5"
    },
    "Rank": 3225,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindu Rao Hospital, ,SUBZI MANDI, MALKA GANJ, DELHI-110007, Delhi (NCT), 110007",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035d6"
    },
    "Rank": 3226,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035d7"
    },
    "Rank": 3227,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035d8"
    },
    "Rank": 3228,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035d9"
    },
    "Rank": 3229,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Specialty Hospital, ,NO. 320, PADMA COMPLEX, MOUNT ROAD, CHENNAI, Tamil Nadu, 600035",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035da"
    },
    "Rank": 3232,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Bhausaheb Hire Government Medical College,Sri Bhausaheb Hire Government Medical College,Dhule, Maharashtra, 424311",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035db"
    },
    "Rank": 3233,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035dc"
    },
    "Rank": 3234,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN,HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN, Karnataka, 573201",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035dd"
    },
    "Rank": 3235,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035de"
    },
    "Rank": 3236,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035df"
    },
    "Rank": 3238,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035e0"
    },
    "Rank": 3239,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VELLORE MEDICAL COLLEGE,GOVERNMENT VELLORE MEDICAL COLLEGE, Tamil Nadu, 632011",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035e1"
    },
    "Rank": 3241,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035e2"
    },
    "Rank": 3242,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,SECTOR-6, PANCHKULA, HARYANA-134108, Haryana, 134108",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035e3"
    },
    "Rank": 3243,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035e4"
    },
    "Rank": 3245,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035e5"
    },
    "Rank": 3246,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035e6"
    },
    "Rank": 3247,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035e7"
    },
    "Rank": 3250,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035e8"
    },
    "Rank": 3251,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035e9"
    },
    "Rank": 3253,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035ea"
    },
    "Rank": 3255,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INSTITUTE OF MEDICAL SCIENCES,RAICHUR INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 584102",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035eb"
    },
    "Rank": 3256,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035ec"
    },
    "Rank": 3257,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035ed"
    },
    "Rank": 3258,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035ee"
    },
    "Rank": 3260,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES,CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, Chhattisgarh, 495001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035ef"
    },
    "Rank": 3262,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035f0"
    },
    "Rank": 3263,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035f1"
    },
    "Rank": 3264,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035f2"
    },
    "Rank": 3265,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035f3"
    },
    "Rank": 3266,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BharatRatna Dr. Babasaheb Ambedkar Municipal General Hospital, Kandivali (West), Maharashtra,Parekh Nagar, SV Road, Opp. Kandivali Police station, Kandivali West, Mumbai, Maharashtra, 400067",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035f4"
    },
    "Rank": 3268,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035f5"
    },
    "Rank": 3269,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035f6"
    },
    "Rank": 3270,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035f7"
    },
    "Rank": 3273,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SRM Institutes for Medical Sciences, ,No 1 Jawaharlal Nehru Salai, Vadapalani, Chennai, Tamil Nadu, 600026",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035f8"
    },
    "Rank": 3275,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sarvodaya Hospital and Research Centre, ,YMCA road Sector 08\nFaridabad, Haryana, 121006",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035f9"
    },
    "Rank": 3276,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rabindranath Tagore International Institute of Cardiac Sciences, ,PREMISES NO. 1489 124 MUKUNDAPUR  E M BYPASS  KOLKATA-700099, West Bengal, 700099",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035fa"
    },
    "Rank": 3278,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035fb"
    },
    "Rank": 3279,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035fc"
    },
    "Rank": 3281,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata Shrimati Devendra Kumari Singhdeo Government Medical College, Ambikapur,Kanyaparisar  Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035fd"
    },
    "Rank": 3283,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035fe"
    },
    "Rank": 3284,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a035ff"
    },
    "Rank": 3286,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03600"
    },
    "Rank": 3287,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03601"
    },
    "Rank": 3288,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03602"
    },
    "Rank": 3291,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jalgaon,Jilha Peth, Government Hospital Campus, Jalgaon, Maharashtra, 425001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03603"
    },
    "Rank": 3292,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03604"
    },
    "Rank": 3293,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03605"
    },
    "Rank": 3296,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Meenakshi Mission Hospital and Research Centre, ,Lake Area, Melur Road, Madurai, Tamil Nadu, 625107",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03606"
    },
    "Rank": 3297,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03607"
    },
    "Rank": 3298,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bombay Hospital,Ring Road Indore, Madhya Pradesh, 452010",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03608"
    },
    "Rank": 3300,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03609"
    },
    "Rank": 3302,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR GOVERNMENT MEDICAL COLLEGE,NELLORE,Opp AC Subbareddy Stadium\nDargamitta Nellore, Andhra Pradesh, 524004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0360a"
    },
    "Rank": 3303,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0360b"
    },
    "Rank": 3304,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0360c"
    },
    "Rank": 3305,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0360d"
    },
    "Rank": 3306,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0360e"
    },
    "Rank": 3308,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0360f"
    },
    "Rank": 3309,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lourdes Hospital, ,Ernakulam, Pachalam, Kochi, Kerala, 682012",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03610"
    },
    "Rank": 3311,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03611"
    },
    "Rank": 3312,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Southern Railway Head Quarter Hospital, ,Ayanavaram, Tamil Nadu, 600023",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03612"
    },
    "Rank": 3313,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Meenakshi Mission Hospital and Research Centre, ,Lake Area, Melur Road, Madurai, Tamil Nadu, 625107",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03613"
    },
    "Rank": 3314,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03614"
    },
    "Rank": 3315,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03615"
    },
    "Rank": 3316,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03616"
    },
    "Rank": 3317,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03617"
    },
    "Rank": 3319,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03618"
    },
    "Rank": 3320,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03619"
    },
    "Rank": 3322,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0361a"
    },
    "Rank": 3323,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0361b"
    },
    "Rank": 3325,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0361c"
    },
    "Rank": 3326,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0361d"
    },
    "Rank": 3328,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College and  PGIMSR,ESIC Medical College and  PGIMSR, KK Nagar, Chennai - 600 078, Tamil Nadu, 600078",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0361e"
    },
    "Rank": 3330,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0361f"
    },
    "Rank": 3331,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03620"
    },
    "Rank": 3332,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03621"
    },
    "Rank": 3333,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03622"
    },
    "Rank": 3334,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital Whitefield,,ITPL Main Rd, KIADB Export Promotion Industrial Area, Krishnarajapura, Bengaluru, Karnataka, 560066",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03623"
    },
    "Rank": 3335,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03624"
    },
    "Rank": 3336,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gujarat MRI Centre, ,1ST FLOOR,SAMVED HOSPITAL,STADIUM COMMERCE COLLEGE ROAD,NAVRANGPURA,AHMEDABAD, Gujarat, 380009",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03625"
    },
    "Rank": 3338,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03626"
    },
    "Rank": 3339,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Vasantrao Naik Govt Medical College Yavatmal,Shri Vasantrao Naik Govt Medical College Yavatmal, Maharashtra, 445001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03627"
    },
    "Rank": 3340,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03628"
    },
    "Rank": 3341,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03629"
    },
    "Rank": 3342,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0362a"
    },
    "Rank": 3343,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) NUCLEAR MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0362b"
    },
    "Rank": 3344,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pimpri Chichwad Municipal Corporation Postgraduate Institute Y.C.M.Hospital Pimpri,Pune 18,Dean Office, Y.C.M.Hospital, Sant Tukaramnagar Pimpri, Pune, Maharashtra, 411018",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0362c"
    },
    "Rank": 3345,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SRM Institutes for Medical Sciences, ,No 1 Jawaharlal Nehru Salai, Vadapalani, Chennai, Tamil Nadu, 600026",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0362d"
    },
    "Rank": 3346,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0362e"
    },
    "Rank": 3347,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indraprastha Apollo Hospital,,Sarita Vihar, New Delhi 110 076, Delhi (NCT), 110076",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0362f"
    },
    "Rank": 3348,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "AIG Hospital,Door No: 1 66 AIG 2 to 5 Mindspace Road  Gachibowli Hyderabad\nTelangana 500032",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03630"
    },
    "Rank": 3349,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03631"
    },
    "Rank": 3350,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN,HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN, Karnataka, 573201",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03632"
    },
    "Rank": 3351,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03633"
    },
    "Rank": 3353,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03634"
    },
    "Rank": 3354,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03635"
    },
    "Rank": 3355,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03636"
    },
    "Rank": 3356,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03637"
    },
    "Rank": 3357,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03638"
    },
    "Rank": 3358,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kauvery Hospital, ,No.199, Luz Church road, Mylapore,Chennai-600004, Tamil Nadu, 600004",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03639"
    },
    "Rank": 3359,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0363a"
    },
    "Rank": 3360,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0363b"
    },
    "Rank": 3361,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0363c"
    },
    "Rank": 3362,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0363d"
    },
    "Rank": 3364,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0363e"
    },
    "Rank": 3365,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VILLUPURAM MEDICAL COLLEGE, VILLUPURAM,GOVERNMENT VILLUPURAM MEDICAL COLLEGE VILLUPURAM  TRICHY TRUNK ROAD MUNDIYAMPAKKAM  VILLUPURAM DISTR, Tamil Nadu, 605601",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0363f"
    },
    "Rank": 3366,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03640"
    },
    "Rank": 3368,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03641"
    },
    "Rank": 3369,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Health City, ,Apollo Hospitals, Health City, Chinagadhili, Visakhapatnam, Andhra Pradesh, 530040",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03642"
    },
    "Rank": 3370,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03643"
    },
    "Rank": 3371,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03644"
    },
    "Rank": 3372,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03645"
    },
    "Rank": 3373,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03646"
    },
    "Rank": 3374,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03647"
    },
    "Rank": 3375,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B. C. Roy Post Graduate Institute of Paediatric Sciences,111 Narkeldanga Main Road Kolkata, West Bengal, 700054",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03648"
    },
    "Rank": 3376,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03649"
    },
    "Rank": 3377,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0364a"
    },
    "Rank": 3379,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Hedgewar Rugnalaya, ,Dr Hedgewar Rugnalaya Garkheda Aurangabad, Maharashtra, 431005",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0364b"
    },
    "Rank": 3380,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,RAJBHAVAN ROAD SOMAJIGUDA, Telangana, 500082",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0364c"
    },
    "Rank": 3381,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0364d"
    },
    "Rank": 3382,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0364e"
    },
    "Rank": 3383,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0364f"
    },
    "Rank": 3384,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03650"
    },
    "Rank": 3385,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03651"
    },
    "Rank": 3386,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03652"
    },
    "Rank": 3387,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03653"
    },
    "Rank": 3388,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03654"
    },
    "Rank": 3390,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B. Nanavati Hospital, Vivekanand Rd,Swami Vivekananda Rd, near LIC, LIC Colony, Suresh Colony, Vile Parle West, Mumbai, Maharashtra 4000, Maharashtra, 400056",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03655"
    },
    "Rank": 3392,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03656"
    },
    "Rank": 3398,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03657"
    },
    "Rank": 3399,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lourdes Hospital, ,Ernakulam, Pachalam, Kochi, Kerala, 682012",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03658"
    },
    "Rank": 3400,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03659"
    },
    "Rank": 3401,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0365a"
    },
    "Rank": 3402,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0365b"
    },
    "Rank": 3403,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0365c"
    },
    "Rank": 3404,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0365d"
    },
    "Rank": 3406,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0365e"
    },
    "Rank": 3407,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kalinga Hospital, ,GD-2C, NANDANKANAN ROAD, CHANDRASEKHARPUR, BHUBANESWAR KHORDHA, ODISHA - 751023, Odisha, 751023",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0365f"
    },
    "Rank": 3408,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sanjay Gandhi Institute of Trauma and Orthopaedics,Sanjay Gandhi Institute of Trauma and Orthopaedics, Karnataka, 560011",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03660"
    },
    "Rank": 3409,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03661"
    },
    "Rank": 3411,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03662"
    },
    "Rank": 3412,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03663"
    },
    "Rank": 3413,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03664"
    },
    "Rank": 3414,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03665"
    },
    "Rank": 3415,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03666"
    },
    "Rank": 3416,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03667"
    },
    "Rank": 3417,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03668"
    },
    "Rank": 3418,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03669"
    },
    "Rank": 3419,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0366a"
    },
    "Rank": 3420,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0366b"
    },
    "Rank": 3421,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0366c"
    },
    "Rank": 3422,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College and  PGIMSR,ESIC Medical College and  PGIMSR, KK Nagar, Chennai - 600 078, Tamil Nadu, 600078",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0366d"
    },
    "Rank": 3423,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0366e"
    },
    "Rank": 3424,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0366f"
    },
    "Rank": 3425,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03670"
    },
    "Rank": 3426,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03671"
    },
    "Rank": 3427,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ivy Health and Life Sciences,,Ivy Hospital Mohali, Sector 71 SAS Nagar, Punjab, 160071",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03672"
    },
    "Rank": 3428,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital,,B-22, Sector 62, Noida, Uttar Pradesh, Uttar Pradesh, 201301",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03673"
    },
    "Rank": 3429,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03674"
    },
    "Rank": 3430,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03675"
    },
    "Rank": 3431,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Peerless Hospital and B K Roy Research Centre, ,360, Panchasayar, Kolkata - 700 -094, West Bengal, 700094",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03676"
    },
    "Rank": 3432,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03677"
    },
    "Rank": 3435,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03678"
    },
    "Rank": 3436,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03679"
    },
    "Rank": 3438,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0367a"
    },
    "Rank": 3439,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0367b"
    },
    "Rank": 3443,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0367c"
    },
    "Rank": 3444,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0367d"
    },
    "Rank": 3445,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0367e"
    },
    "Rank": 3446,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0367f"
    },
    "Rank": 3447,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03680"
    },
    "Rank": 3449,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03681"
    },
    "Rank": 3451,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03682"
    },
    "Rank": 3452,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sahyadri Super Specialty Hospital,,Plot No.30C, Erandawane, Karve Road, Pune, Maharashtra, 411004",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03683"
    },
    "Rank": 3454,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03684"
    },
    "Rank": 3455,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03685"
    },
    "Rank": 3456,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03686"
    },
    "Rank": 3458,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03687"
    },
    "Rank": 3459,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03688"
    },
    "Rank": 3461,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03689"
    },
    "Rank": 3462,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gujarat MRI Centre, ,1ST FLOOR,SAMVED HOSPITAL,STADIUM COMMERCE COLLEGE ROAD,NAVRANGPURA,AHMEDABAD, Gujarat, 380009",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0368a"
    },
    "Rank": 3463,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0368b"
    },
    "Rank": 3465,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0368c"
    },
    "Rank": 3466,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0368d"
    },
    "Rank": 3467,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0368e"
    },
    "Rank": 3468,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0368f"
    },
    "Rank": 3470,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03690"
    },
    "Rank": 3471,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03691"
    },
    "Rank": 3472,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Hurkisondas Nurrotumdas Hospital and Research Centre, ,Prarthana Samaj, Raja Ram Mohan Roy road,\nGirgaon, MUMBAI, Maharashtra, 400004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03692"
    },
    "Rank": 3474,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03693"
    },
    "Rank": 3476,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kanchi Kamakoti Childs Trust Hospital, ,12 A, NAGESWARA ROAD, NUNGAMBAKKAM, CHENNAI., Tamil Nadu, 600034",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03694"
    },
    "Rank": 3481,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03695"
    },
    "Rank": 3482,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,Nalgonda X Roads, Malakpet, Hyderabad, Telangana, 500036",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03696"
    },
    "Rank": 3483,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rabindranath Tagore International Institute of Cardiac Sciences, ,PREMISES NO. 1489 124 MUKUNDAPUR  E M BYPASS  KOLKATA-700099, West Bengal, 700099",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03697"
    },
    "Rank": 3485,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03698"
    },
    "Rank": 3486,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03699"
    },
    "Rank": 3487,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0369a"
    },
    "Rank": 3488,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0369b"
    },
    "Rank": 3489,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0369c"
    },
    "Rank": 3490,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0369d"
    },
    "Rank": 3491,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0369e"
    },
    "Rank": 3492,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0369f"
    },
    "Rank": 3493,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036a0"
    },
    "Rank": 3495,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036a1"
    },
    "Rank": 3496,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Care Hospital,,Care Hospitals Road No1 Banjara Hills Hyderabad Telangana, Telangana, 500034",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036a2"
    },
    "Rank": 3497,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036a3"
    },
    "Rank": 3498,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036a4"
    },
    "Rank": 3499,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B. Nanavati Hospital, Vivekanand Rd,Swami Vivekananda Rd, near LIC, LIC Colony, Suresh Colony, Vile Parle West, Mumbai, Maharashtra 4000, Maharashtra, 400056",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036a5"
    },
    "Rank": 3500,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036a6"
    },
    "Rank": 3501,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036a7"
    },
    "Rank": 3504,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vallabhbhai Patel Chest Institute, Delhi,Vallabhbhai Patel Chest Institute, Delhi, Delhi (NCT), 110007",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036a8"
    },
    "Rank": 3505,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036a9"
    },
    "Rank": 3507,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036aa"
    },
    "Rank": 3508,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagwan Mahaveer Jain Hospital, ,MILLERS ROAD, VASANTHNAGAR, BANGALORE, Karnataka, 560052",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036ab"
    },
    "Rank": 3509,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036ac"
    },
    "Rank": 3510,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036ad"
    },
    "Rank": 3511,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036ae"
    },
    "Rank": 3512,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036af"
    },
    "Rank": 3514,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036b0"
    },
    "Rank": 3515,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Care Hospital,,Care Hospitals Road No1 Banjara Hills Hyderabad Telangana, Telangana, 500034",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036b1"
    },
    "Rank": 3517,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036b2"
    },
    "Rank": 3518,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036b3"
    },
    "Rank": 3521,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036b4"
    },
    "Rank": 3523,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Cancer Institute (WIA),\nNo:38, Tamil Nadu,Regional Centre for Cancer Research and Treatment, Cancer Institute (WIA), No.38 Sardar Patel Road,C, Tamil Nadu, 600036",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036b5"
    },
    "Rank": 3526,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sanjay Gandhi Institute of Trauma and Orthopaedics,Sanjay Gandhi Institute of Trauma and Orthopaedics, Karnataka, 560011",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036b6"
    },
    "Rank": 3529,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036b7"
    },
    "Rank": 3530,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036b8"
    },
    "Rank": 3531,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B. Nanavati Hospital, Vivekanand Rd,Swami Vivekananda Rd, near LIC, LIC Colony, Suresh Colony, Vile Parle West, Mumbai, Maharashtra 4000, Maharashtra, 400056",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036b9"
    },
    "Rank": 3532,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036ba"
    },
    "Rank": 3533,
    "Allotted Quota": "All India",
    "Allotted Institute": "Post Graduate Institute of Medical Education & Research and Capital Hospital ( PGIMER & CH), Odisha,Post Graduate Institute of Medical Education & Research and Capital Hospital, Bhubaneswar,Udyan Marg, Odisha, 751001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036bb"
    },
    "Rank": 3536,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036bc"
    },
    "Rank": 3537,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF HUMAN  BEHAVIOUR AND ALLIED SCIENCES,Institute of Human\nBehaviour and Allied Sciences,  Dilshad Garden, New Delhi, Delhi 110095, India, Delhi (NCT), 110095",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036bd"
    },
    "Rank": 3539,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chittaranjan National Cancer Institute, 37- ,37, S P Mukherjee Road, Kolkata-700026- 1st campus, 299 DJ Block A.A.1, Newtown Kolkata- 700156-2nd, West Bengal, 700026",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036be"
    },
    "Rank": 3540,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kasturba Hospital, Daryaganj,Delhi,Daryaganj,Delhi",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036bf"
    },
    "Rank": 3541,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036c0"
    },
    "Rank": 3543,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036c1"
    },
    "Rank": 3544,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036c2"
    },
    "Rank": 3545,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036c3"
    },
    "Rank": 3546,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036c4"
    },
    "Rank": 3549,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036c5"
    },
    "Rank": 3550,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036c6"
    },
    "Rank": 3551,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036c7"
    },
    "Rank": 3552,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036c8"
    },
    "Rank": 3553,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036c9"
    },
    "Rank": 3554,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Hospital, Omandurar,GOVERNMENT MEDICAL COLLEGE, OMANDURAR GOVERNMENT ESTATE, WALAJAH ROAD, NEAR KALAIVANAR ARANGAM, CHEN, Tamil Nadu, 600002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036ca"
    },
    "Rank": 3555,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036cb"
    },
    "Rank": 3556,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036cc"
    },
    "Rank": 3560,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036cd"
    },
    "Rank": 3561,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036ce"
    },
    "Rank": 3567,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036cf"
    },
    "Rank": 3568,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B. C. Roy Post Graduate Institute of Paediatric Sciences,111 Narkeldanga Main Road Kolkata, West Bengal, 700054",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036d0"
    },
    "Rank": 3569,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College,Tumuki, Bihaguri, Tezpur, Sonitpur, Assam, Assam, 784010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036d1"
    },
    "Rank": 3570,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lourdes Hospital, ,Ernakulam, Pachalam, Kochi, Kerala, 682012",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036d2"
    },
    "Rank": 3571,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036d3"
    },
    "Rank": 3573,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036d4"
    },
    "Rank": 3574,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036d5"
    },
    "Rank": 3575,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036d6"
    },
    "Rank": 3576,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036d7"
    },
    "Rank": 3577,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036d8"
    },
    "Rank": 3578,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Shroff`s Charity Eye Hospital,,5027, Kedarnath lane, opposite DAV school, Daryaganj, Delhi 110009, Delhi (NCT), 110009",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036d9"
    },
    "Rank": 3579,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indraprastha Apollo Hospital,,Sarita Vihar, New Delhi 110 076, Delhi (NCT), 110076",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036da"
    },
    "Rank": 3580,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036db"
    },
    "Rank": 3581,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036dc"
    },
    "Rank": 3582,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036dd"
    },
    "Rank": 3583,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ispat General Hospital,,Ispat General Hospital Rourkela Steel Plant SAIL Rourkela 769005, Odisha, 769005",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036de"
    },
    "Rank": 3584,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036df"
    },
    "Rank": 3585,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036e0"
    },
    "Rank": 3586,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036e1"
    },
    "Rank": 3587,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036e2"
    },
    "Rank": 3588,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sparsh Super Specialty Hospital, ,SPARSH SUPER SPECIALITY HOSPITAL, TUMKUR ROAD, YESHWANTHPURA, BANGALORE, Karnataka, 560022",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036e3"
    },
    "Rank": 3589,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036e4"
    },
    "Rank": 3590,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Meenakshi Mission Hospital and Research Centre, ,Lake Area, Melur Road, Madurai, Tamil Nadu, 625107",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036e5"
    },
    "Rank": 3591,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036e6"
    },
    "Rank": 3592,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036e7"
    },
    "Rank": 3593,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036e8"
    },
    "Rank": 3595,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036e9"
    },
    "Rank": 3596,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036ea"
    },
    "Rank": 3600,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036eb"
    },
    "Rank": 3601,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036ec"
    },
    "Rank": 3603,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Valluvanad Hospital Complex Limited,KANNIYAMPURAM POST OTTAPALAM\nPALAKKAD KERALA, Kerala, 679104",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036ed"
    },
    "Rank": 3604,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036ee"
    },
    "Rank": 3605,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036ef"
    },
    "Rank": 3606,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036f0"
    },
    "Rank": 3607,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036f1"
    },
    "Rank": 3608,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Specialty Hospitals, ,IIIM NEHRU NAGAR GHAZIABAD, Uttar Pradesh, 201001",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036f2"
    },
    "Rank": 3609,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036f3"
    },
    "Rank": 3612,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036f4"
    },
    "Rank": 3613,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036f5"
    },
    "Rank": 3615,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036f6"
    },
    "Rank": 3616,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Family Hospital,,Okhla Road, New Delhi, Delhi (NCT), 110025",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036f7"
    },
    "Rank": 3618,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036f8"
    },
    "Rank": 3619,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B. C. Roy Post Graduate Institute of Paediatric Sciences,111 Narkeldanga Main Road Kolkata, West Bengal, 700054",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036f9"
    },
    "Rank": 3620,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036fa"
    },
    "Rank": 3621,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036fb"
    },
    "Rank": 3622,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036fc"
    },
    "Rank": 3623,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036fd"
    },
    "Rank": 3624,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036fe"
    },
    "Rank": 3627,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a036ff"
    },
    "Rank": 3628,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE,ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, Bihar, 823001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03700"
    },
    "Rank": 3629,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03701"
    },
    "Rank": 3630,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03702"
    },
    "Rank": 3632,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03703"
    },
    "Rank": 3634,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03704"
    },
    "Rank": 3635,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03705"
    },
    "Rank": 3640,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bai Jerbai Wadia Hospital for Children, Parel, Mumbai, Maharashtra,Acharya Donde Marg Parel Mumbai, Maharashtra, 400012",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03706"
    },
    "Rank": 3642,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03707"
    },
    "Rank": 3644,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03708"
    },
    "Rank": 3645,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhabha Atomic Research Centre and Hospital, ,Anushaktinagar, Mumbai, Maharashtra, 400094",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03709"
    },
    "Rank": 3649,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0370a"
    },
    "Rank": 3650,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0370b"
    },
    "Rank": 3653,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0370c"
    },
    "Rank": 3654,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Malabar Institute of Medical Sciences, ,Govindapuram P.O., Mini Bypass Road, Kozhikode, Kerala, 673016",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0370d"
    },
    "Rank": 3656,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0370e"
    },
    "Rank": 3657,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0370f"
    },
    "Rank": 3659,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03710"
    },
    "Rank": 3660,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03711"
    },
    "Rank": 3661,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Alexis Multispecialty Hospital,232, Mankapur Square, Koradi road, NAGPUR, Maharashtra, 440030",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03712"
    },
    "Rank": 3665,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "G Kuppuswamy Naidu Memorial Hospital, ,Post Box No.6327, Nethaji Road, Pappanaickenpalayam, Coimbatore, Tamil Nadu, 641037",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03713"
    },
    "Rank": 3667,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bettiah,Government Medical College Bettiah West Champaran Bihar, Bihar, 845438",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03714"
    },
    "Rank": 3668,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03715"
    },
    "Rank": 3669,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03716"
    },
    "Rank": 3671,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03717"
    },
    "Rank": 3675,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03718"
    },
    "Rank": 3677,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03719"
    },
    "Rank": 3678,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0371a"
    },
    "Rank": 3679,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0371b"
    },
    "Rank": 3681,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kanchi Kamakoti Childs Trust Hospital, ,12 A, NAGESWARA ROAD, NUNGAMBAKKAM, CHENNAI., Tamil Nadu, 600034",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0371c"
    },
    "Rank": 3683,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0371d"
    },
    "Rank": 3685,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0371e"
    },
    "Rank": 3686,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE - KANNUR,GOVERNMENT MEDICAL COLLEGE\n- KANNUR, Kerala, 670503",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0371f"
    },
    "Rank": 3688,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruby Hall Clinic, ,GRANT MEDICAL FOUNDATION,\n40 SASSOON ROAD, PUNE 411001, Maharashtra, 411001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03720"
    },
    "Rank": 3690,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Malabar Institute of Medical Sciences, ,Govindapuram P.O., Mini Bypass Road, Kozhikode, Kerala, 673016",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03721"
    },
    "Rank": 3692,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03722"
    },
    "Rank": 3693,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03723"
    },
    "Rank": 3694,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03724"
    },
    "Rank": 3695,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03725"
    },
    "Rank": 3696,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03726"
    },
    "Rank": 3698,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lisie Medical Institution, ,Kochi, Kerala, Kerala, 682018",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03727"
    },
    "Rank": 3699,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kanchi Kamakoti Childs Trust Hospital, ,12 A, NAGESWARA ROAD, NUNGAMBAKKAM, CHENNAI., Tamil Nadu, 600034",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03728"
    },
    "Rank": 3700,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03729"
    },
    "Rank": 3703,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0372a"
    },
    "Rank": 3705,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0372b"
    },
    "Rank": 3706,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Escorts Hospital,,Fortis escorts hospital ,neelam bata road, faridabad, Haryana, 121002",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0372c"
    },
    "Rank": 3707,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0372d"
    },
    "Rank": 3708,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Malabar Institute of Medical Sciences, ,Govindapuram P.O., Mini Bypass Road, Kozhikode, Kerala, 673016",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0372e"
    },
    "Rank": 3709,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yatharth Superspeciality Hospitals,\nUttar Pradesh,HO-01, Sector-01, Greater Noida West, Uttar Pradesh, 201306",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0372f"
    },
    "Rank": 3710,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03730"
    },
    "Rank": 3711,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sarvodaya Multispeciality And Cancer Hospital, ,Delhi Road, Opposite Red Cross Bhawan, Hisar, Haryana, Haryana, 125001",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03731"
    },
    "Rank": 3712,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03732"
    },
    "Rank": 3713,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kanchi Kamakoti Childs Trust Hospital, ,12 A, NAGESWARA ROAD, NUNGAMBAKKAM, CHENNAI., Tamil Nadu, 600034",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03733"
    },
    "Rank": 3714,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lisie Medical Institution, ,Kochi, Kerala, Kerala, 682018",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03734"
    },
    "Rank": 3715,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagwan Mahaveer Jain Hospital, ,MILLERS ROAD, VASANTHNAGAR, BANGALORE, Karnataka, 560052",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03735"
    },
    "Rank": 3716,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03736"
    },
    "Rank": 3718,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Jamshedpur,Mahatma Gandhi Memorial Medical College, Jamshedpur, Jharkhand, 831020",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03737"
    },
    "Rank": 3720,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.G. Hospital and PG Medical Institute ,,No:5, Arts College Road, Coimbatore - 641018, Tamil Nadu, 641018",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03738"
    },
    "Rank": 3722,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03739"
    },
    "Rank": 3723,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0373a"
    },
    "Rank": 3724,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0373b"
    },
    "Rank": 3725,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Gleneagles Hospital, ,58 Canal Circular Road Kolkata 700054, West Bengal, 700054",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0373c"
    },
    "Rank": 3726,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College,Fakhruddin Ali Ahmed Medical College, Assam, 781301",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0373d"
    },
    "Rank": 3727,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0373e"
    },
    "Rank": 3728,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0373f"
    },
    "Rank": 3730,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03740"
    },
    "Rank": 3732,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03741"
    },
    "Rank": 3733,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVERNMENT MEDICAL COLLEGE,KANYAKUMARI GOVERNMENT MEDICAL COLLEGE, Tamil Nadu, 629201",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03742"
    },
    "Rank": 3734,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aadhar Health Institute, (A UNIT OF VLCOM HEALTHCARE P LTD.) ,Aadhar Health Institute, A UNIT OF VLCOM HEALTHCARE P LTD. Tosham Road, Hisar, Haryana- 125001, Haryana, 125001",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03743"
    },
    "Rank": 3735,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03744"
    },
    "Rank": 3736,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,Plotno-251,Sainik school Road,Unit-15 Apollo Hospitals , Bhubaneswar, Odisha, 751005",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03745"
    },
    "Rank": 3739,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Bundelkhand Medical College Sagar M.P.,Govt. Bundelkhand Medical College Sagar M.P., Madhya Pradesh, 470001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03746"
    },
    "Rank": 3740,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03747"
    },
    "Rank": 3741,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kanchi Kamakoti Childs Trust Hospital, ,12 A, NAGESWARA ROAD, NUNGAMBAKKAM, CHENNAI., Tamil Nadu, 600034",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03748"
    },
    "Rank": 3743,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03749"
    },
    "Rank": 3746,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) Plastic and Reconstructive Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0374a"
    },
    "Rank": 3747,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0374b"
    },
    "Rank": 3748,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0374c"
    },
    "Rank": 3749,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0374d"
    },
    "Rank": 3752,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College, ,Office of the Dean, First Floor, Civil Hospital Campus, Sector- 12, Gandhinagar, Gujarat, 382016",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0374e"
    },
    "Rank": 3754,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0374f"
    },
    "Rank": 3757,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bansal Hospital,,C- Sector Shahpura, Bhopal, Madhya Pradesh, 462016",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03750"
    },
    "Rank": 3759,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03751"
    },
    "Rank": 3761,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03752"
    },
    "Rank": 3762,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sadhu Vaswani Missions ,7-9 Lane No 1 Koregaon Park Pune 411001, Maharashtra, 411001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03753"
    },
    "Rank": 3763,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03754"
    },
    "Rank": 3764,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital, ,GMERS Medical College and General hospital,Old TB Campus, gotri road, Vadodara, Gujarat, 390021",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03755"
    },
    "Rank": 3766,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03756"
    },
    "Rank": 3767,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "National Institute of Tuberculosis and Respiratory Diseases, (Formerly Known as L.R.S. Instt. of T.B. and Allied Scs),,National Institute of Tuberculosis and Respiratory Diseases (Formerly Known as L.R.S. Instt. of T.B., Delhi (NCT), 110030",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03757"
    },
    "Rank": 3768,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03758"
    },
    "Rank": 3769,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03759"
    },
    "Rank": 3770,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE,ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, Bihar, 823001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0375a"
    },
    "Rank": 3773,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0375b"
    },
    "Rank": 3774,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jagjivan Ram Railway Hospital,,Maratha Mandir Marg, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0375c"
    },
    "Rank": 3777,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0375d"
    },
    "Rank": 3778,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "G Kuppuswamy Naidu Memorial Hospital, ,Post Box No.6327, Nethaji Road, Pappanaickenpalayam, Coimbatore, Tamil Nadu, 641037",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0375e"
    },
    "Rank": 3779,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0375f"
    },
    "Rank": 3780,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruby Hall Clinic, ,GRANT MEDICAL FOUNDATION,\n40 SASSOON ROAD, PUNE 411001, Maharashtra, 411001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03760"
    },
    "Rank": 3781,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03761"
    },
    "Rank": 3782,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03762"
    },
    "Rank": 3783,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03763"
    },
    "Rank": 3784,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.G. Hospital and PG Medical Institute ,,No:5, Arts College Road, Coimbatore - 641018, Tamil Nadu, 641018",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03764"
    },
    "Rank": 3785,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03765"
    },
    "Rank": 3786,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03766"
    },
    "Rank": 3787,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03767"
    },
    "Rank": 3789,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03768"
    },
    "Rank": 3790,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03769"
    },
    "Rank": 3791,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0376a"
    },
    "Rank": 3792,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0376b"
    },
    "Rank": 3793,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0376c"
    },
    "Rank": 3794,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0376d"
    },
    "Rank": 3795,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0376e"
    },
    "Rank": 3796,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0376f"
    },
    "Rank": 3797,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03770"
    },
    "Rank": 3799,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03771"
    },
    "Rank": 3801,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03772"
    },
    "Rank": 3802,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03773"
    },
    "Rank": 3803,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03774"
    },
    "Rank": 3804,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03775"
    },
    "Rank": 3805,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Baba Saheb Ambedkar Hospital, ,SEC 6 ROHINI DELHI, Delhi (NCT), 110085",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03776"
    },
    "Rank": 3806,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03777"
    },
    "Rank": 3807,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03778"
    },
    "Rank": 3808,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Southern Railway Head Quarter Hospital, ,Ayanavaram, Tamil Nadu, 600023",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03779"
    },
    "Rank": 3809,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindu Rao Hospital, ,SUBZI MANDI, MALKA GANJ, DELHI-110007, Delhi (NCT), 110007",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0377a"
    },
    "Rank": 3810,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0377b"
    },
    "Rank": 3811,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0377c"
    },
    "Rank": 3812,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0377d"
    },
    "Rank": 3813,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0377e"
    },
    "Rank": 3815,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0377f"
    },
    "Rank": 3816,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03780"
    },
    "Rank": 3817,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03781"
    },
    "Rank": 3818,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03782"
    },
    "Rank": 3819,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Health City, ,Apollo Hospitals, Health City, Chinagadhili, Visakhapatnam, Andhra Pradesh, 530040",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03783"
    },
    "Rank": 3820,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03784"
    },
    "Rank": 3822,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03785"
    },
    "Rank": 3824,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03786"
    },
    "Rank": 3825,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "G Kuppuswamy Naidu Memorial Hospital, ,Post Box No.6327, Nethaji Road, Pappanaickenpalayam, Coimbatore, Tamil Nadu, 641037",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03787"
    },
    "Rank": 3826,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03788"
    },
    "Rank": 3827,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03789"
    },
    "Rank": 3828,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0378a"
    },
    "Rank": 3831,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0378b"
    },
    "Rank": 3832,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0378c"
    },
    "Rank": 3835,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gvernment Medical College,Haldwani,Government Medical College, Haldwani, Uttarakhand, 263139",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0378d"
    },
    "Rank": 3837,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Guru Gobind Singh Medical College and Hospital, ,Guru Gobind Singh Medical College Sadiq Road Faridkot Punjab, Punjab, 151203",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0378e"
    },
    "Rank": 3838,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0378f"
    },
    "Rank": 3839,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03790"
    },
    "Rank": 3840,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Datia,Near 29 Batallion, National Highway 44, Dist Datia, Madhya Pradesh Pin - 475661, Madhya Pradesh, 475661",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03791"
    },
    "Rank": 3841,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03792"
    },
    "Rank": 3843,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03793"
    },
    "Rank": 3844,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Care Hospital, ,Care hospitals exhibition road Nampally Hyderabad Telangana, Telangana, 500001",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03794"
    },
    "Rank": 3845,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03795"
    },
    "Rank": 3847,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03796"
    },
    "Rank": 3848,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata Shrimati Devendra Kumari Singhdeo Government Medical College, Ambikapur,Kanyaparisar  Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03797"
    },
    "Rank": 3849,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03798"
    },
    "Rank": 3851,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03799"
    },
    "Rank": 3852,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruby Hall Clinic, ,GRANT MEDICAL FOUNDATION,\n40 SASSOON ROAD, PUNE 411001, Maharashtra, 411001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0379a"
    },
    "Rank": 3853,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "G Kuppuswamy Naidu Memorial Hospital, ,Post Box No.6327, Nethaji Road, Pappanaickenpalayam, Coimbatore, Tamil Nadu, 641037",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0379b"
    },
    "Rank": 3854,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Regency Hospital,,A-2, Sarvodaya Nagar, Kanpur, Uttar Pradesh, 208005",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0379c"
    },
    "Rank": 3855,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Meenakshi Hospital, ,244-2, Trichy Main Road, Near New Bus stand, Thanjavur, Tamil Nadu, 613005",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0379d"
    },
    "Rank": 3856,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0379e"
    },
    "Rank": 3858,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0379f"
    },
    "Rank": 3860,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037a0"
    },
    "Rank": 3861,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037a1"
    },
    "Rank": 3862,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037a2"
    },
    "Rank": 3863,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN,HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN, Karnataka, 573201",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037a3"
    },
    "Rank": 3865,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037a4"
    },
    "Rank": 3867,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037a5"
    },
    "Rank": 3868,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037a6"
    },
    "Rank": 3869,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chacha Nehru Bal Chikitsalaya,Geeta Colony, Delhi (NCT), 110031",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037a7"
    },
    "Rank": 3871,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shree Mahavir Health and Medical Relief Society Hospital, (Smt. R B Shah Mahavir Super Specialty Hospital and Shri B D Mehta Mahavir Heart Institute) ,SHREE MAHAVIR HEALTH CAMPUS, OPP JIVANBHARTI SCHOOL, NANPURA SURAT, Gujarat, 395001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037a8"
    },
    "Rank": 3873,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037a9"
    },
    "Rank": 3874,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037aa"
    },
    "Rank": 3877,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Rela Institute and Medical Centre ,\nTamil Nadu,NO.7, CLC WORKS ROAD, CHROMPET CHENNAI, Tamil Nadu, 600044",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037ab"
    },
    "Rank": 3878,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037ac"
    },
    "Rank": 3880,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Thiruvannamalai Medical College, Thiruvannamalai, Tamil Nadu,DISTRICT COLLECTORATE  MASTER PLAN COMPLEX, VENGIKKAL THIRUVANNAMALAI,\nTamil Nadu, 600604",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037ad"
    },
    "Rank": 3881,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037ae"
    },
    "Rank": 3882,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Gleneagles Hospital, ,58 Canal Circular Road Kolkata 700054, West Bengal, 700054",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037af"
    },
    "Rank": 3884,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037b0"
    },
    "Rank": 3885,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037b1"
    },
    "Rank": 3887,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037b2"
    },
    "Rank": 3888,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037b3"
    },
    "Rank": 3889,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037b4"
    },
    "Rank": 3890,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037b5"
    },
    "Rank": 3891,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037b6"
    },
    "Rank": 3892,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037b7"
    },
    "Rank": 3893,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037b8"
    },
    "Rank": 3894,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037b9"
    },
    "Rank": 3895,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037ba"
    },
    "Rank": 3896,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037bb"
    },
    "Rank": 3897,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaslok Hospital and Research Centre, ,15, Dr. G. Deshmukh Marg, Mumbai - 400026, Maharashtra, 400026",
    "Course": "(NBEMS) NUCLEAR MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037bc"
    },
    "Rank": 3898,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037bd"
    },
    "Rank": 3899,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SUM ULTIMATE MEDICARE, Orissa,PLOT NO- C6 K-8 KALINGA NAGAR GHATIKIA\nBHUBANESWAR ODISHA, Odisha, 751003",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037be"
    },
    "Rank": 3900,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037bf"
    },
    "Rank": 3901,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037c0"
    },
    "Rank": 3902,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037c1"
    },
    "Rank": 3903,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037c2"
    },
    "Rank": 3904,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037c3"
    },
    "Rank": 3906,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037c4"
    },
    "Rank": 3908,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037c5"
    },
    "Rank": 3911,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Care Hospital-,CARE HOSPITAL OLD MUMBAI HIGH WAY NEAR CYBERABAD POLICE COMMISSIONARATE HITECH CITY GACHIBOWLI HYDER, Telangana, 500032",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037c6"
    },
    "Rank": 3912,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037c7"
    },
    "Rank": 3913,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037c8"
    },
    "Rank": 3914,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037c9"
    },
    "Rank": 3915,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037ca"
    },
    "Rank": 3916,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037cb"
    },
    "Rank": 3918,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037cc"
    },
    "Rank": 3919,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Motors Hospital, ,KHARANGAJHAR, TELCO COLONY JAMSHEDPUR, Jharkhand, 831003",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037cd"
    },
    "Rank": 3920,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVERNMENT MEDICAL COLLEGE,KANYAKUMARI GOVERNMENT MEDICAL COLLEGE, Tamil Nadu, 629201",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037ce"
    },
    "Rank": 3921,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kasturba Hospital, Daryaganj,Delhi,Daryaganj,Delhi",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037cf"
    },
    "Rank": 3922,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Family Hospital,,Okhla Road, New Delhi, Delhi (NCT), 110025",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037d0"
    },
    "Rank": 3924,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037d1"
    },
    "Rank": 3925,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037d2"
    },
    "Rank": 3926,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037d3"
    },
    "Rank": 3928,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037d4"
    },
    "Rank": 3930,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037d5"
    },
    "Rank": 3931,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037d6"
    },
    "Rank": 3932,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037d7"
    },
    "Rank": 3933,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037d8"
    },
    "Rank": 3934,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037d9"
    },
    "Rank": 3935,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037da"
    },
    "Rank": 3936,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037db"
    },
    "Rank": 3937,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037dc"
    },
    "Rank": 3938,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037dd"
    },
    "Rank": 3939,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037de"
    },
    "Rank": 3940,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037df"
    },
    "Rank": 3942,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Institute of Medical Sciences (SVIMS), A State University estd. by Govt. of A.P.,Sri Venkateswara Institute of Medical Sciences, SVIMS, Alipiri Road, Tirupati, Andhra Pradesh, 517507",
    "Course": "M.D. IN NUCLEAR MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037e0"
    },
    "Rank": 3943,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037e1"
    },
    "Rank": 3944,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037e2"
    },
    "Rank": 3945,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karnataka,MG Road, Karnataka, 581301",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037e3"
    },
    "Rank": 3948,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037e4"
    },
    "Rank": 3950,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037e5"
    },
    "Rank": 3951,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037e6"
    },
    "Rank": 3952,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037e7"
    },
    "Rank": 3953,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037e8"
    },
    "Rank": 3955,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Care Hospital, ,Care hospitals exhibition road Nampally Hyderabad Telangana, Telangana, 500001",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037e9"
    },
    "Rank": 3957,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Kamakshi Memorial Hospital, (Dr. Kamakshi Institute of Medical Sciences and Research) ,No.1,Radial Road , Pallikaranai, Chennai, Tamil Nadu, 600100",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037ea"
    },
    "Rank": 3958,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kailash Hospital and Heart Institute,\nUttar Pradesh,H-33 SECTOR  27 NOIDA, Uttar Pradesh, 201301",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037eb"
    },
    "Rank": 3959,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037ec"
    },
    "Rank": 3960,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037ed"
    },
    "Rank": 3962,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) NUCLEAR MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037ee"
    },
    "Rank": 3963,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037ef"
    },
    "Rank": 3964,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Omega Hospital,,MLA Colony, Road No. 12, Banjara Hills, Hyderabad, Telangana, 500034",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037f0"
    },
    "Rank": 3969,
    "Allotted Quota": "All India",
    "Allotted Institute": "GADAG INSTITUTE OF MEDICAL SCIENCES GADAG,Gadag Institute of Medical Sciences Mallasamudra Gadag-582103, Karnataka, 582103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037f1"
    },
    "Rank": 3970,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037f2"
    },
    "Rank": 3971,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037f3"
    },
    "Rank": 3972,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037f4"
    },
    "Rank": 3973,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037f5"
    },
    "Rank": 3974,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.J. Somaiya Medical College,,SOMAIYA AYURVIHAR , EASTERN EXPRESS HIGHWAY, SION, MUMBAI 400022, Maharashtra, 400022",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037f6"
    },
    "Rank": 3976,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037f7"
    },
    "Rank": 3979,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037f8"
    },
    "Rank": 3980,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "IQRAA International Hospital and Research Centre, ,IQRAA INTERNATIONAL HOSPITAL AND RESEARCH CENTRE MALAPARAMBA CALICUT, Kerala, 673009",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037f9"
    },
    "Rank": 3981,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indo-American Cancer Institute and Research Centre, ,Road No.14, Banjara Hills, Hyderabad, Telangana, 500034",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037fa"
    },
    "Rank": 3982,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037fb"
    },
    "Rank": 3983,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruby Hall Clinic, ,GRANT MEDICAL FOUNDATION,\n40 SASSOON ROAD, PUNE 411001, Maharashtra, 411001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037fc"
    },
    "Rank": 3985,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037fd"
    },
    "Rank": 3986,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Arvind Eye Hospital, ,Aravind Eye Hospital and Postgraduate Institute of Ophthalmology, Cuddalore Main Road, Thavalakuppam, Puducherry, 605007",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037fe"
    },
    "Rank": 3987,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "P.D. Hinduja National Hospital and Medical Research Centre, ,Veer Savarkar Marg, Mahim, Maharashtra, 400016",
    "Course": "(NBEMS) NUCLEAR MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a037ff"
    },
    "Rank": 3988,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03800"
    },
    "Rank": 3989,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03801"
    },
    "Rank": 3990,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03802"
    },
    "Rank": 3991,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03803"
    },
    "Rank": 3992,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03804"
    },
    "Rank": 3993,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03805"
    },
    "Rank": 3995,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03806"
    },
    "Rank": 3997,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Calcutta Medical Research Institute, ,The Calcutta Medical Research Institute 7 by 2 Diamond Harbour Road Kolkata 700027, West Bengal, 700027",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03807"
    },
    "Rank": 3999,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03808"
    },
    "Rank": 4000,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03809"
    },
    "Rank": 4001,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lisie Medical Institution, ,Kochi, Kerala, Kerala, 682018",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0380a"
    },
    "Rank": 4002,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0380b"
    },
    "Rank": 4003,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0380c"
    },
    "Rank": 4004,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0380d"
    },
    "Rank": 4006,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0380e"
    },
    "Rank": 4007,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Family Hospital,,Okhla Road, New Delhi, Delhi (NCT), 110025",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0380f"
    },
    "Rank": 4008,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03810"
    },
    "Rank": 4009,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sanjay Gandhi Institute of Trauma and Orthopaedics,Sanjay Gandhi Institute of Trauma and Orthopaedics, Karnataka, 560011",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03811"
    },
    "Rank": 4010,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Gleneagles Hospital, ,58 Canal Circular Road Kolkata 700054, West Bengal, 700054",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03812"
    },
    "Rank": 4011,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Village Lingiadih,,Apollo Hospitals Bilaspur Seepat Road  Lingyadih Village  Bilaspur, Chhattisgarh, 495006",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03813"
    },
    "Rank": 4012,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03814"
    },
    "Rank": 4015,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03815"
    },
    "Rank": 4016,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03816"
    },
    "Rank": 4017,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medicover Hospitals, (Formerly MaxCure Hospital) ,Medicover Hospitals , Opposite Cyber gate way , Ibis hotel Lane , hitech city\nMadhapur Hyderabad, Telangana, 500081",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03817"
    },
    "Rank": 4018,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MALABAR INSTITUTE OF MEDICAL SCIENCES Ltd ,Chala East, Chala, Kannur, Kerala, 670621",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03818"
    },
    "Rank": 4019,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03819"
    },
    "Rank": 4022,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0381a"
    },
    "Rank": 4023,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0381b"
    },
    "Rank": 4024,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "DIP.IN MEDICAL RADIO- DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0381c"
    },
    "Rank": 4025,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0381d"
    },
    "Rank": 4027,
    "Allotted Quota": "All India",
    "Allotted Institute": "Post Graduate Institute of Medical Education & Research and Capital Hospital ( PGIMER & CH), Odisha,Post Graduate Institute of Medical Education & Research and Capital Hospital, Bhubaneswar,Udyan Marg, Odisha, 751001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0381e"
    },
    "Rank": 4028,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0381f"
    },
    "Rank": 4029,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03820"
    },
    "Rank": 4030,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03821"
    },
    "Rank": 4031,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.J. Somaiya Medical College,,SOMAIYA AYURVIHAR , EASTERN EXPRESS HIGHWAY, SION, MUMBAI 400022, Maharashtra, 400022",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03822"
    },
    "Rank": 4032,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03823"
    },
    "Rank": 4033,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apple Saraswati Multispecialty Hospital, (Apple Hospitals and Research Institute Ltd.)\n,APPLE SARASWATI MULTISPECIALITY HOSPITAL APPLE HOSPITALS AND RESEARCH INSTITUTE LTD 804 2 805 2 E WA, Maharashtra, 416003",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03824"
    },
    "Rank": 4034,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "CHL Hospital,,L.I.G. SQUARE A.B. ROAD, INDORE, Madhya Pradesh, 452008",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03825"
    },
    "Rank": 4035,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "G Kuppuswamy Naidu Memorial Hospital, ,Post Box No.6327, Nethaji Road, Pappanaickenpalayam, Coimbatore, Tamil Nadu, 641037",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03826"
    },
    "Rank": 4036,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03827"
    },
    "Rank": 4037,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03828"
    },
    "Rank": 4039,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03829"
    },
    "Rank": 4041,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Family Hospital,,Okhla Road, New Delhi, Delhi (NCT), 110025",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0382a"
    },
    "Rank": 4043,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0382b"
    },
    "Rank": 4044,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0382c"
    },
    "Rank": 4045,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0382d"
    },
    "Rank": 4047,
    "Allotted Quota": "All India",
    "Allotted Institute": "Post Graduate Institute of Medical Education & Research and Capital Hospital ( PGIMER & CH), Odisha,Post Graduate Institute of Medical Education & Research and Capital Hospital, Bhubaneswar,Udyan Marg, Odisha, 751001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0382e"
    },
    "Rank": 4050,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0382f"
    },
    "Rank": 4051,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03830"
    },
    "Rank": 4052,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03831"
    },
    "Rank": 4053,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03832"
    },
    "Rank": 4054,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03833"
    },
    "Rank": 4056,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Batra Hospital and Medical Research Centre, ,1, Tughlakabad institutional Area, Mehrauli Badarpur Road, New Delhi, Delhi (NCT), 110062",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03834"
    },
    "Rank": 4057,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03835"
    },
    "Rank": 4058,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03836"
    },
    "Rank": 4059,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lisie Medical Institution, ,Kochi, Kerala, Kerala, 682018",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03837"
    },
    "Rank": 4060,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03838"
    },
    "Rank": 4061,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03839"
    },
    "Rank": 4062,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0383a"
    },
    "Rank": 4063,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0383b"
    },
    "Rank": 4064,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0383c"
    },
    "Rank": 4065,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Satguru Partap Singh Hospital, ,Sherpur Chowk, GT Road, Ludhiana, Punjab-141003, Punjab, 141003",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0383d"
    },
    "Rank": 4066,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital International, ,Plot 1A, GIDC Estate, Bhat Gandhinagar, Gujarat, 382428",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0383e"
    },
    "Rank": 4068,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0383f"
    },
    "Rank": 4070,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Bhausaheb Hire Government Medical College,Sri Bhausaheb Hire Government Medical College,Dhule, Maharashtra, 424311",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03840"
    },
    "Rank": 4071,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03841"
    },
    "Rank": 4073,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03842"
    },
    "Rank": 4074,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03843"
    },
    "Rank": 4075,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03844"
    },
    "Rank": 4076,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03845"
    },
    "Rank": 4078,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03846"
    },
    "Rank": 4081,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03847"
    },
    "Rank": 4082,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03848"
    },
    "Rank": 4083,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College,Fakhruddin Ali Ahmed Medical College, Assam, 781301",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03849"
    },
    "Rank": 4084,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0384a"
    },
    "Rank": 4085,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0384b"
    },
    "Rank": 4088,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0384c"
    },
    "Rank": 4089,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0384d"
    },
    "Rank": 4090,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0384e"
    },
    "Rank": 4091,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0384f"
    },
    "Rank": 4093,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03850"
    },
    "Rank": 4094,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES,CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, Chhattisgarh, 495001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03851"
    },
    "Rank": 4095,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Kasturba Hospital, Daryaganj,Delhi,Daryaganj,Delhi",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03852"
    },
    "Rank": 4096,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03853"
    },
    "Rank": 4098,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03854"
    },
    "Rank": 4100,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "IQRAA International Hospital and Research Centre, ,IQRAA INTERNATIONAL HOSPITAL AND RESEARCH CENTRE MALAPARAMBA CALICUT, Kerala, 673009",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03855"
    },
    "Rank": 4101,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Ramakrishna Hospital, ,395, Sarojini Naidu Road, Sidhapudur,\nCoimbatore - 641 044, Tamil Nadu, 641044",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03856"
    },
    "Rank": 4103,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03857"
    },
    "Rank": 4105,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Kamakshi Memorial Hospital, (Dr. Kamakshi Institute of Medical Sciences and Research) ,No.1,Radial Road , Pallikaranai, Chennai, Tamil Nadu, 600100",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03858"
    },
    "Rank": 4106,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03859"
    },
    "Rank": 4107,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0385a"
    },
    "Rank": 4108,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0385b"
    },
    "Rank": 4109,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0385c"
    },
    "Rank": 4110,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0385d"
    },
    "Rank": 4111,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0385e"
    },
    "Rank": 4112,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0385f"
    },
    "Rank": 4113,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESIC Hospital, Sri Maa Anandmayee Marg,,ESIC Hospital, Sri Maa Anandmayee Marg, Okhla Phase I, New Delhi, Delhi (NCT), 110020",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03860"
    },
    "Rank": 4114,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03861"
    },
    "Rank": 4115,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03862"
    },
    "Rank": 4116,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03863"
    },
    "Rank": 4117,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03864"
    },
    "Rank": 4119,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03865"
    },
    "Rank": 4120,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03866"
    },
    "Rank": 4121,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute Of Medical Sciences, Kalaburagi,VEERESH NAGAR SEDAM ROAD KALABURAGI, Karnataka, 585105",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03867"
    },
    "Rank": 4122,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03868"
    },
    "Rank": 4123,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03869"
    },
    "Rank": 4124,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0386a"
    },
    "Rank": 4125,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0386b"
    },
    "Rank": 4127,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0386c"
    },
    "Rank": 4128,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0386d"
    },
    "Rank": 4129,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0386e"
    },
    "Rank": 4130,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0386f"
    },
    "Rank": 4131,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jehangir Hospital, ,32 Sasoon Road Pune, Maharashtra, 411001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03870"
    },
    "Rank": 4132,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Family Hospital, ,St.Andrews Road, Bandra West , Mumbai - 50, Maharashtra, 400050",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03871"
    },
    "Rank": 4133,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03872"
    },
    "Rank": 4134,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03873"
    },
    "Rank": 4135,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, Uttar Pradesh,H-1, 24,26,27, KAUSHAMBI, NEAR DABUR CHOWK, GHAZIABAD, Uttar Pradesh, 201010",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03874"
    },
    "Rank": 4136,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kingsway Hospitals, Maharashtra,Medisearch Lifesciences Pvt  Ltd 44SPANV Kingsway Rd near Kasturchand Park Nagpur Maharashtra 440001, Maharashtra, 440001",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03875"
    },
    "Rank": 4138,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03876"
    },
    "Rank": 4139,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03877"
    },
    "Rank": 4141,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sagar Hospital, Karnataka,No. 44/54, 30th Cross, Tilak Nagar, Jayanagar Extn., Bangalore, Karnataka-560041",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03878"
    },
    "Rank": 4143,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03879"
    },
    "Rank": 4145,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta The Medicity, ,Medanta The Medicity Sector 38 Gurgoan, Haryana, 122001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0387a"
    },
    "Rank": 4147,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0387b"
    },
    "Rank": 4148,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0387c"
    },
    "Rank": 4149,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Family Hospital, ,St.Andrews Road, Bandra West , Mumbai - 50, Maharashtra, 400050",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0387d"
    },
    "Rank": 4152,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0387e"
    },
    "Rank": 4153,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0387f"
    },
    "Rank": 4155,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asian Institute of Medical Sciences,,p -72,Milapnagar,MIDC,Dombivli E, Maharashtra, 421203",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03880"
    },
    "Rank": 4156,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Batra Hospital and Medical Research Centre, ,1, Tughlakabad institutional Area, Mehrauli Badarpur Road, New Delhi, Delhi (NCT), 110062",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03881"
    },
    "Rank": 4157,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03882"
    },
    "Rank": 4159,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03883"
    },
    "Rank": 4160,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03884"
    },
    "Rank": 4164,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shree Mahavir Health and Medical Relief Society Hospital, (Smt. R B Shah Mahavir Super Specialty Hospital and Shri B D Mehta Mahavir Heart Institute) ,SHREE MAHAVIR HEALTH CAMPUS, OPP JIVANBHARTI SCHOOL, NANPURA SURAT, Gujarat, 395001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03885"
    },
    "Rank": 4166,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03886"
    },
    "Rank": 4167,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03887"
    },
    "Rank": 4168,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03888"
    },
    "Rank": 4169,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03889"
    },
    "Rank": 4171,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0388a"
    },
    "Rank": 4172,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0388b"
    },
    "Rank": 4173,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0388c"
    },
    "Rank": 4175,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0388d"
    },
    "Rank": 4176,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0388e"
    },
    "Rank": 4177,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0388f"
    },
    "Rank": 4178,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03890"
    },
    "Rank": 4179,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03891"
    },
    "Rank": 4180,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03892"
    },
    "Rank": 4181,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03893"
    },
    "Rank": 4182,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS) NUCLEAR MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03894"
    },
    "Rank": 4183,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03895"
    },
    "Rank": 4184,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03896"
    },
    "Rank": 4185,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03897"
    },
    "Rank": 4186,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apple Saraswati Multispecialty Hospital, (Apple Hospitals and Research Institute Ltd.)\n,APPLE SARASWATI MULTISPECIALITY HOSPITAL APPLE HOSPITALS AND RESEARCH INSTITUTE LTD 804 2 805 2 E WA, Maharashtra, 416003",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03898"
    },
    "Rank": 4187,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03899"
    },
    "Rank": 4188,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Smart Super Specialty Hospital, (Formerly Known as Saket City Hospital), ,Mandir Marg, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0389a"
    },
    "Rank": 4189,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0389b"
    },
    "Rank": 4191,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0389c"
    },
    "Rank": 4192,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0389d"
    },
    "Rank": 4193,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Ramesh Cardiac and Multi Speciality Hospital,,Collector office road, Nagarampalem, Guntur , AP, Andhra Pradesh, 522004",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0389e"
    },
    "Rank": 4194,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0389f"
    },
    "Rank": 4195,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038a0"
    },
    "Rank": 4198,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038a1"
    },
    "Rank": 4200,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038a2"
    },
    "Rank": 4201,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo KH Hospital, Tamil Nadu,Apollo KH Hospital, Ranipet - Vellore Bypass Road, Melvisharam, Ranipet District, Tamil Nadu., Tamil Nadu, 632509",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038a3"
    },
    "Rank": 4202,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Southern Railway Head Quarter Hospital, ,Ayanavaram, Tamil Nadu, 600023",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038a4"
    },
    "Rank": 4203,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038a5"
    },
    "Rank": 4204,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038a6"
    },
    "Rank": 4205,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038a7"
    },
    "Rank": 4206,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038a8"
    },
    "Rank": 4207,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038a9"
    },
    "Rank": 4208,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine & Sagore Dutta Hospital,College of Medicine & Sagore Dutta Hospital, West Bengal, 700058",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038aa"
    },
    "Rank": 4209,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038ab"
    },
    "Rank": 4210,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038ac"
    },
    "Rank": 4211,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038ad"
    },
    "Rank": 4212,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038ae"
    },
    "Rank": 4216,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038af"
    },
    "Rank": 4218,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BAPS Pramukh Swami Hospital, ,SHRI PRAMUKH SWAMI MAHARAJ MARG, ADAJAN CHAR RASTA, ADAJAN, SURAT, Gujarat, 395009",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038b0"
    },
    "Rank": 4220,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038b1"
    },
    "Rank": 4221,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038b2"
    },
    "Rank": 4222,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038b3"
    },
    "Rank": 4223,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Stephen`s Hospital, ,Tis Hazari, Delhi (NCT), 110054",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038b4"
    },
    "Rank": 4225,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Batra Hospital and Medical Research Centre, ,1, Tughlakabad institutional Area, Mehrauli Badarpur Road, New Delhi, Delhi (NCT), 110062",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038b5"
    },
    "Rank": 4226,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038b6"
    },
    "Rank": 4228,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038b7"
    },
    "Rank": 4229,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038b8"
    },
    "Rank": 4230,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038b9"
    },
    "Rank": 4233,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038ba"
    },
    "Rank": 4237,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038bb"
    },
    "Rank": 4239,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038bc"
    },
    "Rank": 4242,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038bd"
    },
    "Rank": 4243,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038be"
    },
    "Rank": 4244,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038bf"
    },
    "Rank": 4245,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038c0"
    },
    "Rank": 4246,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Philomena`s Hospital, ,No.4, Campbell Road, Viveknagar P.O. Bangalore - 560047, Karnataka, 560047",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038c1"
    },
    "Rank": 4247,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038c2"
    },
    "Rank": 4248,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038c3"
    },
    "Rank": 4250,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038c4"
    },
    "Rank": 4251,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Naruvi Hospitals ,\nTamil Nadu,Naruvi Hospitals, A Unit of SANCO Foundation, T.S.No.72 1B2, Collector Office Road, Chennai- Bengalu, Tamil Nadu, 632004",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038c5"
    },
    "Rank": 4252,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038c6"
    },
    "Rank": 4254,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038c7"
    },
    "Rank": 4255,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038c8"
    },
    "Rank": 4256,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038c9"
    },
    "Rank": 4258,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038ca"
    },
    "Rank": 4259,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038cb"
    },
    "Rank": 4260,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038cc"
    },
    "Rank": 4261,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038cd"
    },
    "Rank": 4262,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038ce"
    },
    "Rank": 4263,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038cf"
    },
    "Rank": 4264,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038d0"
    },
    "Rank": 4265,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038d1"
    },
    "Rank": 4266,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sagar Hospital, Karnataka,No. 44/54, 30th Cross, Tilak Nagar, Jayanagar Extn., Bangalore, Karnataka-560041",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038d2"
    },
    "Rank": 4267,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038d3"
    },
    "Rank": 4268,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038d4"
    },
    "Rank": 4270,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038d5"
    },
    "Rank": 4271,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038d6"
    },
    "Rank": 4272,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038d7"
    },
    "Rank": 4273,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KMC Specialty Hospital, ,No-6, Royal Road, Cantonment, Trichy-620001, Tamil Nadu, 620001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038d8"
    },
    "Rank": 4274,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038d9"
    },
    "Rank": 4275,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE - KANNUR,GOVERNMENT MEDICAL COLLEGE\n- KANNUR, Kerala, 670503",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038da"
    },
    "Rank": 4276,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038db"
    },
    "Rank": 4278,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038dc"
    },
    "Rank": 4279,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038dd"
    },
    "Rank": 4280,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038de"
    },
    "Rank": 4282,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038df"
    },
    "Rank": 4283,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038e0"
    },
    "Rank": 4284,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038e1"
    },
    "Rank": 4285,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038e2"
    },
    "Rank": 4286,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038e3"
    },
    "Rank": 4290,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038e4"
    },
    "Rank": 4291,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038e5"
    },
    "Rank": 4292,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S. K. S. Hospital, ,NO.23,SKS Hospital Road, Alagapuram,Salem, Tamil Nadu, 636004",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038e6"
    },
    "Rank": 4293,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038e7"
    },
    "Rank": 4296,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Royal Care Super Speciality Hospital, ,1,520 L and T Bypass Road, Neelambur, Coimbatore., Tamil Nadu, 641062",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038e8"
    },
    "Rank": 4297,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038e9"
    },
    "Rank": 4300,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agarsen Hospital, ,., Delhi (NCT), 110026",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038ea"
    },
    "Rank": 4301,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038eb"
    },
    "Rank": 4302,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038ec"
    },
    "Rank": 4305,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038ed"
    },
    "Rank": 4306,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038ee"
    },
    "Rank": 4307,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038ef"
    },
    "Rank": 4308,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038f0"
    },
    "Rank": 4309,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences Koppal, Karnataka,Gangavati Road Kiddidal Gate koppal, Karnataka, 583231",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038f1"
    },
    "Rank": 4310,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038f2"
    },
    "Rank": 4312,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038f3"
    },
    "Rank": 4314,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038f4"
    },
    "Rank": 4315,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medicover Hospitals, (Formerly MaxCure Hospital) ,Medicover Hospitals , Opposite Cyber gate way , Ibis hotel Lane , hitech city\nMadhapur Hyderabad, Telangana, 500081",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038f5"
    },
    "Rank": 4316,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kamineni Hospital ,Boggulkunta King Koti Hyderabad, Telangana, 500001",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038f6"
    },
    "Rank": 4317,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038f7"
    },
    "Rank": 4318,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine & Sagore Dutta Hospital,College of Medicine & Sagore Dutta Hospital, West Bengal, 700058",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038f8"
    },
    "Rank": 4319,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038f9"
    },
    "Rank": 4320,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038fa"
    },
    "Rank": 4321,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038fb"
    },
    "Rank": 4322,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rukmani Birla Hospital , (A unit of The Calcutta Medical Research Institute) ,Gopalpura Bypass Road, Near Triveni Flyover, Jaipur, Rajasthan, 302018",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038fc"
    },
    "Rank": 4323,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038fd"
    },
    "Rank": 4324,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Cancer Institute and Research Centre, ,Sector-5, Rohini, Delhi-110085, Delhi (NCT), 110085",
    "Course": "(NBEMS) NUCLEAR MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038fe"
    },
    "Rank": 4328,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,HBT Medical College and Dr R N Cooper Municipal General Hospital, Bhaktivedanta Swami Rd, JVPD Schem, Maharashtra, 400056",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a038ff"
    },
    "Rank": 4329,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03900"
    },
    "Rank": 4331,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Ramesh Cardiac and Multi Speciality Hospital,,Collector office road, Nagarampalem, Guntur , AP, Andhra Pradesh, 522004",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03901"
    },
    "Rank": 4332,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "IQRAA International Hospital and Research Centre, ,IQRAA INTERNATIONAL HOSPITAL AND RESEARCH CENTRE MALAPARAMBA CALICUT, Kerala, 673009",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03902"
    },
    "Rank": 4333,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College,Tumuki, Bihaguri, Tezpur, Sonitpur, Assam, Assam, 784010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03903"
    },
    "Rank": 4335,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03904"
    },
    "Rank": 4336,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03905"
    },
    "Rank": 4337,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03906"
    },
    "Rank": 4340,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03907"
    },
    "Rank": 4341,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Government Medical College, Vidisha,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA MP, Madhya Pradesh, 464001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03908"
    },
    "Rank": 4342,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03909"
    },
    "Rank": 4343,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0390a"
    },
    "Rank": 4344,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0390b"
    },
    "Rank": 4349,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0390c"
    },
    "Rank": 4350,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0390d"
    },
    "Rank": 4351,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0390e"
    },
    "Rank": 4352,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deen Dayal Upadhyay Hospital, ,DDU HOSPITAL, SHAHEED MANGAL PANDEY MARG, NANAK PURA, HARI NAGAR, NEW DELHI, DELHI-110064, Delhi (NCT), 110064",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0390f"
    },
    "Rank": 4353,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03910"
    },
    "Rank": 4355,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indo-American Cancer Institute and Research Centre, ,Road No.14, Banjara Hills, Hyderabad, Telangana, 500034",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03911"
    },
    "Rank": 4356,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03912"
    },
    "Rank": 4358,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03913"
    },
    "Rank": 4359,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences, ,academics@kimshospitals.com, Telangana, 500003",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03914"
    },
    "Rank": 4360,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mehta Multispecialty Hospital, (Formerly Known as Dr. Mehta`s Hospital) ,No.2, McNichols Road, 3 rd Lane, Chetpet, Chennai, Tamil Nadu, 600031",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03915"
    },
    "Rank": 4361,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03916"
    },
    "Rank": 4362,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03917"
    },
    "Rank": 4363,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03918"
    },
    "Rank": 4364,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences Ltd, ,Dargamitta, Nellore, Andhra Pradesh, 524003",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03919"
    },
    "Rank": 4366,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0391a"
    },
    "Rank": 4367,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0391b"
    },
    "Rank": 4369,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0391c"
    },
    "Rank": 4371,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0391d"
    },
    "Rank": 4372,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0391e"
    },
    "Rank": 4373,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Specialty Hospital, ,Lake View Road, KK. Nagar, Madurai -625020, Tamil Nadu, 625020",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0391f"
    },
    "Rank": 4374,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03920"
    },
    "Rank": 4375,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Advanced Medical Research Institute (AMRI) Hospital,,AMRI Hospitals, Bhubaneswar Plot no-1, Besides Satya Sai Enclave, Khandagiri, Bhubaneswar, Odisha, 751030",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03921"
    },
    "Rank": 4376,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03922"
    },
    "Rank": 4379,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College, Kolhapur,R.K. Nagar Road, Shenda Park, Kolhapur, Maharashtra, Maharashtra, 416012",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03923"
    },
    "Rank": 4380,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03924"
    },
    "Rank": 4381,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03925"
    },
    "Rank": 4382,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Santokbha Durlabhji Memorial Hospital Cum Medical Research Institute,\n,dnb.sdmh@gmail.com, Rajasthan, 302015",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03926"
    },
    "Rank": 4383,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03927"
    },
    "Rank": 4384,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03928"
    },
    "Rank": 4385,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital,,DNB office, 3rd Floor Opp Library  Apollo Hospitals, Jubilee Hills, Hyderabad, Telangana, 500033",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03929"
    },
    "Rank": 4386,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0392a"
    },
    "Rank": 4387,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pimpri Chichwad Municipal Corporation Postgraduate Institute Y.C.M.Hospital Pimpri,Pune 18,Dean Office, Y.C.M.Hospital, Sant Tukaramnagar Pimpri, Pune, Maharashtra, 411018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0392b"
    },
    "Rank": 4388,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0392c"
    },
    "Rank": 4389,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0392d"
    },
    "Rank": 4390,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0392e"
    },
    "Rank": 4391,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0392f"
    },
    "Rank": 4392,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03930"
    },
    "Rank": 4394,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03931"
    },
    "Rank": 4395,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03932"
    },
    "Rank": 4397,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03933"
    },
    "Rank": 4398,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03934"
    },
    "Rank": 4399,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03935"
    },
    "Rank": 4400,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03936"
    },
    "Rank": 4401,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,HBT Medical College and Dr R N Cooper Municipal General Hospital, Bhaktivedanta Swami Rd, JVPD Schem, Maharashtra, 400056",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03937"
    },
    "Rank": 4402,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03938"
    },
    "Rank": 4403,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asian Institute of Medical Sciences,,p -72,Milapnagar,MIDC,Dombivli E, Maharashtra, 421203",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03939"
    },
    "Rank": 4406,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0393a"
    },
    "Rank": 4409,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute of Medical Sciences Srikakulam,Government Medical College Srikakulam\nBalaga Srikakulam Town Srikakulam District, Andhra Pradesh, 532001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0393b"
    },
    "Rank": 4410,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agrasen Medical College,,NH- 9, VPO - Agroha, District Hisar, Haryana, 125047",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0393c"
    },
    "Rank": 4411,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0393d"
    },
    "Rank": 4412,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0393e"
    },
    "Rank": 4413,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0393f"
    },
    "Rank": 4414,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03940"
    },
    "Rank": 4415,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03941"
    },
    "Rank": 4416,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03942"
    },
    "Rank": 4418,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03943"
    },
    "Rank": 4419,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03944"
    },
    "Rank": 4424,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03945"
    },
    "Rank": 4425,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nizam`s Institute of Medical Sciences, ,NIZAMS INSTITUTE OF MEDICAL SCIENCES PANJAGUTTA HYDERABAD, Telangana, 500082",
    "Course": "(NBEMS) NUCLEAR MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03946"
    },
    "Rank": 4426,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03947"
    },
    "Rank": 4428,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03948"
    },
    "Rank": 4429,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03949"
    },
    "Rank": 4432,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0394a"
    },
    "Rank": 4433,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Hospital, Omandurar,GOVERNMENT MEDICAL COLLEGE, OMANDURAR GOVERNMENT ESTATE, WALAJAH ROAD, NEAR KALAIVANAR ARANGAM, CHEN, Tamil Nadu, 600002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0394b"
    },
    "Rank": 4436,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0394c"
    },
    "Rank": 4437,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0394d"
    },
    "Rank": 4440,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0394e"
    },
    "Rank": 4441,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Malabar Institute of Medical Sciences, ,Govindapuram P.O., Mini Bypass Road, Kozhikode, Kerala, 673016",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0394f"
    },
    "Rank": 4442,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03950"
    },
    "Rank": 4443,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03951"
    },
    "Rank": 4444,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta The Medicity, ,Medanta The Medicity Sector 38 Gurgoan, Haryana, 122001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03952"
    },
    "Rank": 4445,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03953"
    },
    "Rank": 4447,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03954"
    },
    "Rank": 4448,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03955"
    },
    "Rank": 4449,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES ADILABAD -504001 TELANGANA STATE, Telangana, 504001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03956"
    },
    "Rank": 4450,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03957"
    },
    "Rank": 4451,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital,,DNB office, 3rd Floor Opp Library  Apollo Hospitals, Jubilee Hills, Hyderabad, Telangana, 500033",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03958"
    },
    "Rank": 4452,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indo-American Cancer Institute and Research Centre, ,Road No.14, Banjara Hills, Hyderabad, Telangana, 500034",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03959"
    },
    "Rank": 4454,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0395a"
    },
    "Rank": 4455,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhabha Atomic Research Centre and Hospital, ,Anushaktinagar, Mumbai, Maharashtra, 400094",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0395b"
    },
    "Rank": 4456,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0395c"
    },
    "Rank": 4457,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Batra Hospital and Medical Research Centre, ,1, Tughlakabad institutional Area, Mehrauli Badarpur Road, New Delhi, Delhi (NCT), 110062",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0395d"
    },
    "Rank": 4458,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0395e"
    },
    "Rank": 4462,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0395f"
    },
    "Rank": 4465,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03960"
    },
    "Rank": 4466,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03961"
    },
    "Rank": 4467,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03962"
    },
    "Rank": 4468,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sai Sudha Hospital,,# 21-1-24, Jawahar Street, Salipeta, Kakinada, Andhra Pradesh, 533001",
    "Course": "(NBEMS) DERMATOLOGY and VENEREOLOGY and LEPROSY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03963"
    },
    "Rank": 4469,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03964"
    },
    "Rank": 4470,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03965"
    },
    "Rank": 4471,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03966"
    },
    "Rank": 4472,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "AIG Hospital,Door No: 1 66 AIG 2 to 5 Mindspace Road  Gachibowli Hyderabad\nTelangana 500032",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03967"
    },
    "Rank": 4474,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03968"
    },
    "Rank": 4475,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03969"
    },
    "Rank": 4477,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0396a"
    },
    "Rank": 4479,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0396b"
    },
    "Rank": 4480,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0396c"
    },
    "Rank": 4481,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0396d"
    },
    "Rank": 4483,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0396e"
    },
    "Rank": 4484,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0396f"
    },
    "Rank": 4485,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03970"
    },
    "Rank": 4486,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03971"
    },
    "Rank": 4489,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03972"
    },
    "Rank": 4490,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Ramakrishna Hospital, ,395, Sarojini Naidu Road, Sidhapudur,\nCoimbatore - 641 044, Tamil Nadu, 641044",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03973"
    },
    "Rank": 4493,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhabha Atomic Research Centre and Hospital, ,Anushaktinagar, Mumbai, Maharashtra, 400094",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03974"
    },
    "Rank": 4494,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03975"
    },
    "Rank": 4495,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03976"
    },
    "Rank": 4496,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03977"
    },
    "Rank": 4497,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03978"
    },
    "Rank": 4498,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agrasen Medical College,,NH- 9, VPO - Agroha, District Hisar, Haryana, 125047",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03979"
    },
    "Rank": 4499,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "South Central Railways Hospital, ,SOUTH CENTRAL RAILWAYS CENTRAL HOSPITAL, OFFICE OF THE MEDICAL DIRECTOR, 1ST FLOOR\nLALLAGUDA, METT, Telangana, 500017",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0397a"
    },
    "Rank": 4500,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0397b"
    },
    "Rank": 4501,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0397c"
    },
    "Rank": 4502,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0397d"
    },
    "Rank": 4505,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0397e"
    },
    "Rank": 4506,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Eastern Indira Gandhi Regional Institute of Health & Medical Sciences, Shillong,North Eastern Indira Gandhi Regional Institute of Health & Medical Sciences, Shillong, Meghalaya, 793018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0397f"
    },
    "Rank": 4507,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03980"
    },
    "Rank": 4509,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03981"
    },
    "Rank": 4510,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vishesh Jupiter Hospital,Scheme No 94, Ring Road, near Teen Imli Chauraha, Sector 1, Indore, Madhya Pradesh 452020, Madhya Pradesh, 452020",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03982"
    },
    "Rank": 4514,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College, ,Office of the Dean, First Floor, Civil Hospital Campus, Sector- 12, Gandhinagar, Gujarat, 382016",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03983"
    },
    "Rank": 4515,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Northern Railway Central Hospital,, ,Basant Lane New Delhi, Delhi (NCT), 110055",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03984"
    },
    "Rank": 4516,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03985"
    },
    "Rank": 4518,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03986"
    },
    "Rank": 4521,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.J. Somaiya Medical College,,SOMAIYA AYURVIHAR , EASTERN EXPRESS HIGHWAY, SION, MUMBAI 400022, Maharashtra, 400022",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03987"
    },
    "Rank": 4522,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03988"
    },
    "Rank": 4523,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital , ,GMERS MEDICAL COLLEGE , Hadiyol-Gadhoda Road, Near-Government Polytechnic, Hiimmatnagar, Gujarat, 383001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03989"
    },
    "Rank": 4525,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0398a"
    },
    "Rank": 4526,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0398b"
    },
    "Rank": 4528,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mohandai Oswal Hospital, ,Mohandai Oswal Hospital, Sherpur Byepass, G T Road, Ludhiana-141009, Punjab, 141009",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0398c"
    },
    "Rank": 4529,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0398d"
    },
    "Rank": 4531,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0398e"
    },
    "Rank": 4532,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0398f"
    },
    "Rank": 4533,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03990"
    },
    "Rank": 4534,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03991"
    },
    "Rank": 4535,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03992"
    },
    "Rank": 4536,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lalitha Super Specialty Hospital, ,KOTHAPET GUNTUR, Andhra Pradesh, 522001",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03993"
    },
    "Rank": 4538,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Malabar Institute of Medical Sciences, ,Govindapuram P.O., Mini Bypass Road, Kozhikode, Kerala, 673016",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03994"
    },
    "Rank": 4539,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03995"
    },
    "Rank": 4540,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Calcutta Medical Research Institute, ,The Calcutta Medical Research Institute 7 by 2 Diamond Harbour Road Kolkata 700027, West Bengal, 700027",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03996"
    },
    "Rank": 4541,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Arvind Eye Hospital, ,Aravind Eye Hospital and Postgraduate Institute of Ophthalmology, Cuddalore Main Road, Thavalakuppam, Puducherry, 605007",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03997"
    },
    "Rank": 4542,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03998"
    },
    "Rank": 4543,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "National Institute of Tuberculosis and Respiratory Diseases, (Formerly Known as L.R.S. Instt. of T.B. and Allied Scs),,National Institute of Tuberculosis and Respiratory Diseases (Formerly Known as L.R.S. Instt. of T.B., Delhi (NCT), 110030",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03999"
    },
    "Rank": 4545,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0399a"
    },
    "Rank": 4547,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0399b"
    },
    "Rank": 4548,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0399c"
    },
    "Rank": 4549,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0399d"
    },
    "Rank": 4550,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0399e"
    },
    "Rank": 4551,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0399f"
    },
    "Rank": 4552,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039a0"
    },
    "Rank": 4554,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039a1"
    },
    "Rank": 4555,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039a2"
    },
    "Rank": 4556,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039a3"
    },
    "Rank": 4557,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039a4"
    },
    "Rank": 4558,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039a5"
    },
    "Rank": 4559,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039a6"
    },
    "Rank": 4560,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039a7"
    },
    "Rank": 4561,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039a8"
    },
    "Rank": 4563,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039a9"
    },
    "Rank": 4564,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039aa"
    },
    "Rank": 4565,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039ab"
    },
    "Rank": 4566,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "DIP.IN MEDICAL RADIO- DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039ac"
    },
    "Rank": 4567,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039ad"
    },
    "Rank": 4568,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agrasen Medical College,,NH- 9, VPO - Agroha, District Hisar, Haryana, 125047",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039ae"
    },
    "Rank": 4569,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Martha`s Hospital , ,No.5, Nrupathunga Road, Bengaluru, Karnataka, 560001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039af"
    },
    "Rank": 4570,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039b0"
    },
    "Rank": 4571,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Santokbha Durlabhji Memorial Hospital Cum Medical Research Institute,\n,dnb.sdmh@gmail.com, Rajasthan, 302015",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039b1"
    },
    "Rank": 4573,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039b2"
    },
    "Rank": 4574,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039b3"
    },
    "Rank": 4575,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039b4"
    },
    "Rank": 4576,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039b5"
    },
    "Rank": 4577,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039b6"
    },
    "Rank": 4578,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039b7"
    },
    "Rank": 4579,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039b8"
    },
    "Rank": 4580,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039b9"
    },
    "Rank": 4581,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039ba"
    },
    "Rank": 4582,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Parsik Hill Road, ,APOLLO HOSPITAL PARSIK HILL ROAD ROAD NO 13 SECTOR 23 CBD BELAPUR NAVI MUMBAI, Maharashtra, 400614",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039bb"
    },
    "Rank": 4583,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039bc"
    },
    "Rank": 4586,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039bd"
    },
    "Rank": 4587,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039be"
    },
    "Rank": 4588,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chandan Hospital, ,Vijayant Khand, Gomti Nagar, Lucknow, Uttar Pradesh, 226010",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039bf"
    },
    "Rank": 4590,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Advanced Medical Research Institute (AMRI) Hospital,,AMRI Hospitals, Bhubaneswar Plot no-1, Besides Satya Sai Enclave, Khandagiri, Bhubaneswar, Odisha, 751030",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039c0"
    },
    "Rank": 4591,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039c1"
    },
    "Rank": 4592,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039c2"
    },
    "Rank": 4593,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039c3"
    },
    "Rank": 4594,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039c4"
    },
    "Rank": 4596,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039c5"
    },
    "Rank": 4597,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039c6"
    },
    "Rank": 4599,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039c7"
    },
    "Rank": 4600,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039c8"
    },
    "Rank": 4601,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Santosh Hospital, ,No.6/1, Promenade Road, Bangalore.560 005, Karnataka, 560005",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039c9"
    },
    "Rank": 4602,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039ca"
    },
    "Rank": 4603,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039cb"
    },
    "Rank": 4604,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039cc"
    },
    "Rank": 4605,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhaktivedanta Hospital and Research Institute, ,Bhaktivedanta Hospital and Research Institute,Srishti Complex, Bhaktivedanta Swami Marg, Mira Road E, Maharashtra, 401107",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039cd"
    },
    "Rank": 4606,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sanjay Gandhi Institute of Trauma and Orthopaedics,Sanjay Gandhi Institute of Trauma and Orthopaedics, Karnataka, 560011",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039ce"
    },
    "Rank": 4610,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039cf"
    },
    "Rank": 4611,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jehangir Hospital, ,32 Sasoon Road Pune, Maharashtra, 411001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039d0"
    },
    "Rank": 4612,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039d1"
    },
    "Rank": 4613,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VELLORE MEDICAL COLLEGE,GOVERNMENT VELLORE MEDICAL COLLEGE, Tamil Nadu, 632011",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039d2"
    },
    "Rank": 4615,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Breach Candy Hospital, ,60-A, Bhulabhai Desai Road, Mumbai- 400 026., Maharashtra, 400026",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039d3"
    },
    "Rank": 4616,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039d4"
    },
    "Rank": 4617,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039d5"
    },
    "Rank": 4618,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039d6"
    },
    "Rank": 4619,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039d7"
    },
    "Rank": 4620,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Institute of Medical Sciences (SVIMS), A State University estd. by Govt. of A.P.,Sri Venkateswara Institute of Medical Sciences, SVIMS, Alipiri Road, Tirupati, Andhra Pradesh, 517507",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039d8"
    },
    "Rank": 4621,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039d9"
    },
    "Rank": 4622,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039da"
    },
    "Rank": 4623,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039db"
    },
    "Rank": 4624,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039dc"
    },
    "Rank": 4626,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039dd"
    },
    "Rank": 4627,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Vasantrao Naik Govt Medical College Yavatmal,Shri Vasantrao Naik Govt Medical College Yavatmal, Maharashtra, 445001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039de"
    },
    "Rank": 4629,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039df"
    },
    "Rank": 4630,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039e0"
    },
    "Rank": 4631,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039e1"
    },
    "Rank": 4632,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039e2"
    },
    "Rank": 4633,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Ramakrishna Hospital, ,395, Sarojini Naidu Road, Sidhapudur,\nCoimbatore - 641 044, Tamil Nadu, 641044",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039e3"
    },
    "Rank": 4634,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039e4"
    },
    "Rank": 4635,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039e5"
    },
    "Rank": 4638,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039e6"
    },
    "Rank": 4639,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agarsen Hospital, ,., Delhi (NCT), 110026",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039e7"
    },
    "Rank": 4640,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039e8"
    },
    "Rank": 4642,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039e9"
    },
    "Rank": 4643,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039ea"
    },
    "Rank": 4645,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039eb"
    },
    "Rank": 4646,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039ec"
    },
    "Rank": 4649,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039ed"
    },
    "Rank": 4651,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039ee"
    },
    "Rank": 4652,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039ef"
    },
    "Rank": 4653,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Vasantrao Naik Govt Medical College Yavatmal,Shri Vasantrao Naik Govt Medical College Yavatmal, Maharashtra, 445001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039f0"
    },
    "Rank": 4655,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Arvind Eye Hospital , ,Aravind Eye Hospital & Postgraduate Institute of Ophthalmology, Avinashi Road, Civil Aerodrome Post,, Tamil Nadu, 641014",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039f1"
    },
    "Rank": 4656,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039f2"
    },
    "Rank": 4658,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039f3"
    },
    "Rank": 4659,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Paras HMRI Hospital, ,Paras HMRI Hospital\nNH 30 Bailey Road Raja Bazaar Patna 800014 Bihar, Bihar, 800014",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039f4"
    },
    "Rank": 4660,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039f5"
    },
    "Rank": 4663,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039f6"
    },
    "Rank": 4664,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039f7"
    },
    "Rank": 4665,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039f8"
    },
    "Rank": 4666,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039f9"
    },
    "Rank": 4667,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039fa"
    },
    "Rank": 4668,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kailash hospital neuro institute,\nUttar Pradesh,KAILASH HOSPITAL & NEURO INSTITUTE NH-1  SECTOR 71 NOIDA UP, Uttar Pradesh, 201309",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039fb"
    },
    "Rank": 4669,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Malabar Institute of Medical Sciences, ,Govindapuram P.O., Mini Bypass Road, Kozhikode, Kerala, 673016",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039fc"
    },
    "Rank": 4670,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039fd"
    },
    "Rank": 4671,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039fe"
    },
    "Rank": 4672,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a039ff"
    },
    "Rank": 4674,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kongunad Hospitals Private Limited, ,116,117 11th Street, Tatabad,Opposite to east power house, Tamil Nadu, 641012",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a00"
    },
    "Rank": 4675,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vivekananda Polyclinic and Institute of Medical Sciences, ,Vivekananda Polyclinic and Institute of Medical Sciences, Vivekananda Puram, Lucknow, Uttar Pradesh, 226007",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a01"
    },
    "Rank": 4676,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gvernment Medical College,Haldwani,Government Medical College, Haldwani, Uttarakhand, 263139",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a02"
    },
    "Rank": 4677,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a03"
    },
    "Rank": 4679,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a04"
    },
    "Rank": 4681,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a05"
    },
    "Rank": 4682,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a06"
    },
    "Rank": 4685,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a07"
    },
    "Rank": 4686,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a08"
    },
    "Rank": 4688,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a09"
    },
    "Rank": 4689,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a0a"
    },
    "Rank": 4690,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a0b"
    },
    "Rank": 4691,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a0c"
    },
    "Rank": 4692,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a0d"
    },
    "Rank": 4693,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a0e"
    },
    "Rank": 4694,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. Medical College and hospital, ,Govt Medical College  Akola  Collector Office Road Akola Maharshtra, Maharashtra, 444001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a0f"
    },
    "Rank": 4695,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VELLORE MEDICAL COLLEGE,GOVERNMENT VELLORE MEDICAL COLLEGE, Tamil Nadu, 632011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a10"
    },
    "Rank": 4696,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a11"
    },
    "Rank": 4697,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a12"
    },
    "Rank": 4698,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,Plotno-251,Sainik school Road,Unit-15 Apollo Hospitals , Bhubaneswar, Odisha, 751005",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a13"
    },
    "Rank": 4701,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KIMS Saveera Hospital,1-1348 OPPOSITE SAKSHI OFFICE RUDRAMPET ANANTAPURAM 515004, Andhra Pradesh, 515004",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a14"
    },
    "Rank": 4703,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a15"
    },
    "Rank": 4704,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a16"
    },
    "Rank": 4705,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a17"
    },
    "Rank": 4706,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a18"
    },
    "Rank": 4708,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agarsen Hospital, ,., Delhi (NCT), 110026",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a19"
    },
    "Rank": 4709,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a1a"
    },
    "Rank": 4710,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a1b"
    },
    "Rank": 4711,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a1c"
    },
    "Rank": 4712,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a1d"
    },
    "Rank": 4713,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a1e"
    },
    "Rank": 4714,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT DHARMAPURI MEDICAL COLLEGE, DHARMAPURI, TAMIL NADU,GOVERNMENT DHARMAPURI MEDICAL COLLEGE, NETHAJI BYE PASS ROAD, DHARMAPURI, TAMIL NADU, Tamil Nadu, 636701",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a1f"
    },
    "Rank": 4716,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a20"
    },
    "Rank": 4717,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Philomena`s Hospital, ,No.4, Campbell Road, Viveknagar P.O. Bangalore - 560047, Karnataka, 560047",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a21"
    },
    "Rank": 4718,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "P.D. Hinduja National Hospital and Medical Research Centre, ,Veer Savarkar Marg, Mahim, Maharashtra, 400016",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a22"
    },
    "Rank": 4719,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a23"
    },
    "Rank": 4720,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Kasturba Hospital, Daryaganj,Delhi,Daryaganj,Delhi",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a24"
    },
    "Rank": 4721,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a25"
    },
    "Rank": 4722,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a26"
    },
    "Rank": 4723,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kalpana Chawla Govt Medical College, ,Model Town, Karnal, Haryana, 132001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a27"
    },
    "Rank": 4724,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a28"
    },
    "Rank": 4725,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College,Theni ,Government Medical College,Theni, Tamil Nadu, 625512",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a29"
    },
    "Rank": 4727,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a2a"
    },
    "Rank": 4728,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a2b"
    },
    "Rank": 4730,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a2c"
    },
    "Rank": 4731,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a2d"
    },
    "Rank": 4732,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a2e"
    },
    "Rank": 4733,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a2f"
    },
    "Rank": 4734,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a30"
    },
    "Rank": 4739,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a31"
    },
    "Rank": 4741,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Royal Care Super Speciality Hospital, ,1,520 L and T Bypass Road, Neelambur, Coimbatore., Tamil Nadu, 641062",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a32"
    },
    "Rank": 4743,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a33"
    },
    "Rank": 4745,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a34"
    },
    "Rank": 4746,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a35"
    },
    "Rank": 4747,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "DIP.IN MEDICAL RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a36"
    },
    "Rank": 4749,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a37"
    },
    "Rank": 4750,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jupiter Hospital, ,Eastern Express Highway, Thane West, Maharashtra, 400601",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a38"
    },
    "Rank": 4751,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital,\n,paddoc road, near majewadi gate, junagadh, Gujarat, 362001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a39"
    },
    "Rank": 4752,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a3a"
    },
    "Rank": 4753,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a3b"
    },
    "Rank": 4754,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a3c"
    },
    "Rank": 4757,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a3d"
    },
    "Rank": 4758,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Breach Candy Hospital, ,60-A, Bhulabhai Desai Road, Mumbai- 400 026., Maharashtra, 400026",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a3e"
    },
    "Rank": 4760,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a3f"
    },
    "Rank": 4761,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a40"
    },
    "Rank": 4762,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Stephen`s Hospital, ,Tis Hazari, Delhi (NCT), 110054",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a41"
    },
    "Rank": 4763,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a42"
    },
    "Rank": 4764,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a43"
    },
    "Rank": 4765,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a44"
    },
    "Rank": 4768,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a45"
    },
    "Rank": 4769,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a46"
    },
    "Rank": 4771,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a47"
    },
    "Rank": 4772,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster CMI Hospital, ,43/2 New Airport Road, NH-7, Kodigehalli, Sahakara Nagar, Hebbal, Bengaluru, Karnataka, 560092",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a48"
    },
    "Rank": 4773,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "AIG Hospital,Door No: 1 66 AIG 2 to 5 Mindspace Road  Gachibowli Hyderabad\nTelangana 500032",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a49"
    },
    "Rank": 4774,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a4a"
    },
    "Rank": 4775,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a4b"
    },
    "Rank": 4779,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a4c"
    },
    "Rank": 4780,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a4d"
    },
    "Rank": 4781,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a4e"
    },
    "Rank": 4782,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a4f"
    },
    "Rank": 4783,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a50"
    },
    "Rank": 4784,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a51"
    },
    "Rank": 4786,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a52"
    },
    "Rank": 4787,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Mission Hospital, West Bengal\n,The Mission Hospital, 219 P, Immon Kalyan Sarani, Sector-2C, Bidhannagar, Durgapur, Dist- Paschim Ba, West Bengal, 713212",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a53"
    },
    "Rank": 4788,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a54"
    },
    "Rank": 4790,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Gujarat Research and Medical Institute (Rajasthan Hospitals), ,Camp Road, Shahibaug, Ahmedabad, Gujarat, 380004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a55"
    },
    "Rank": 4791,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a56"
    },
    "Rank": 4792,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a57"
    },
    "Rank": 4795,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a58"
    },
    "Rank": 4796,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Care Hospital,,Care Hospitals Road No1 Banjara Hills Hyderabad Telangana, Telangana, 500034",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a59"
    },
    "Rank": 4798,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a5a"
    },
    "Rank": 4803,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a5b"
    },
    "Rank": 4804,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a5c"
    },
    "Rank": 4805,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a5d"
    },
    "Rank": 4806,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a5e"
    },
    "Rank": 4807,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a5f"
    },
    "Rank": 4809,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a60"
    },
    "Rank": 4810,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a61"
    },
    "Rank": 4811,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Shankara Cancer Hospital and Research centre, ,1st Cross, Shankarapuram, Basavanagudi, Bengaluru, Karnataka, 560004",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a62"
    },
    "Rank": 4813,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a63"
    },
    "Rank": 4816,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a64"
    },
    "Rank": 4817,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a65"
    },
    "Rank": 4818,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "G Kuppuswamy Naidu Memorial Hospital, ,Post Box No.6327, Nethaji Road, Pappanaickenpalayam, Coimbatore, Tamil Nadu, 641037",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a66"
    },
    "Rank": 4819,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Philomena`s Hospital, ,No.4, Campbell Road, Viveknagar P.O. Bangalore - 560047, Karnataka, 560047",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a67"
    },
    "Rank": 4820,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a68"
    },
    "Rank": 4821,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a69"
    },
    "Rank": 4822,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a6a"
    },
    "Rank": 4824,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a6b"
    },
    "Rank": 4827,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mehta Multispecialty Hospital, (Formerly Known as Dr. Mehta`s Hospital) ,No.2, McNichols Road, 3 rd Lane, Chetpet, Chennai, Tamil Nadu, 600031",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a6c"
    },
    "Rank": 4828,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a6d"
    },
    "Rank": 4829,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT DHARMAPURI MEDICAL COLLEGE, DHARMAPURI, TAMIL NADU,GOVERNMENT DHARMAPURI MEDICAL COLLEGE, NETHAJI BYE PASS ROAD, DHARMAPURI, TAMIL NADU, Tamil Nadu, 636701",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a6e"
    },
    "Rank": 4830,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a6f"
    },
    "Rank": 4831,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a70"
    },
    "Rank": 4832,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a71"
    },
    "Rank": 4835,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shrikrishna Medical College & Hospital, Muzaffarpur,Shrikrishna Medical College & Hospital, Muzaffarpur, Bihar, 842004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a72"
    },
    "Rank": 4836,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a73"
    },
    "Rank": 4838,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sadhu Vaswani Missions ,7-9 Lane No 1 Koregaon Park Pune 411001, Maharashtra, 411001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a74"
    },
    "Rank": 4839,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a75"
    },
    "Rank": 4841,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a76"
    },
    "Rank": 4842,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Kamakshi Memorial Hospital, (Dr. Kamakshi Institute of Medical Sciences and Research) ,No.1,Radial Road , Pallikaranai, Chennai, Tamil Nadu, 600100",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a77"
    },
    "Rank": 4844,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a78"
    },
    "Rank": 4845,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chandan Hospital, ,Vijayant Khand, Gomti Nagar, Lucknow, Uttar Pradesh, 226010",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a79"
    },
    "Rank": 4846,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a7a"
    },
    "Rank": 4847,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a7b"
    },
    "Rank": 4848,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a7c"
    },
    "Rank": 4849,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a7d"
    },
    "Rank": 4850,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a7e"
    },
    "Rank": 4851,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a7f"
    },
    "Rank": 4852,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a80"
    },
    "Rank": 4853,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a81"
    },
    "Rank": 4854,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Vallabhbhai Patel Chest Institute, Delhi,Vallabhbhai Patel Chest Institute, Delhi, Delhi (NCT), 110007",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a82"
    },
    "Rank": 4855,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a83"
    },
    "Rank": 4856,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a84"
    },
    "Rank": 4857,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a85"
    },
    "Rank": 4858,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a86"
    },
    "Rank": 4859,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a87"
    },
    "Rank": 4860,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a88"
    },
    "Rank": 4861,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a89"
    },
    "Rank": 4862,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a8a"
    },
    "Rank": 4864,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a8b"
    },
    "Rank": 4865,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a8c"
    },
    "Rank": 4866,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a8d"
    },
    "Rank": 4867,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a8e"
    },
    "Rank": 4868,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a8f"
    },
    "Rank": 4869,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a90"
    },
    "Rank": 4871,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a91"
    },
    "Rank": 4873,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaikh-Ul-Hind Maulana Mahmood Hasan Medical College, Saharanpur,Pilkhani Ambala Road, Uttar Pradesh, 247232",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a92"
    },
    "Rank": 4874,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a93"
    },
    "Rank": 4877,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a94"
    },
    "Rank": 4878,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a95"
    },
    "Rank": 4879,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a96"
    },
    "Rank": 4881,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a97"
    },
    "Rank": 4882,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a98"
    },
    "Rank": 4883,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a99"
    },
    "Rank": 4884,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a9a"
    },
    "Rank": 4887,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Healthworld Hospital, ,Healthworld Hospitals [A Unit of Parashmani Medical Centre Pvt.Ltd.], C-49,Commercial Area, Opp. ESI, West Bengal, 713216",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a9b"
    },
    "Rank": 4888,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kongunad Hospitals Private Limited, ,116,117 11th Street, Tatabad,Opposite to east power house, Tamil Nadu, 641012",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a9c"
    },
    "Rank": 4890,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a9d"
    },
    "Rank": 4891,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indira Gandhi Govt. General Hospital and PG Institute, No. 1, ,No.1, Victor Simonel street, Puducherry -1., Puducherry, 605001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a9e"
    },
    "Rank": 4892,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03a9f"
    },
    "Rank": 4893,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03aa0"
    },
    "Rank": 4894,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Malabar Hospitals Pvt. Ltd., Kerala,Karuvambrum PO, Manjeri,\nMalappuram, Kerala, Kerala, 676123",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03aa1"
    },
    "Rank": 4895,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03aa2"
    },
    "Rank": 4898,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03aa3"
    },
    "Rank": 4899,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03aa4"
    },
    "Rank": 4900,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS) DERMATOLOGY and VENEREOLOGY and LEPROSY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03aa5"
    },
    "Rank": 4901,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03aa6"
    },
    "Rank": 4907,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03aa7"
    },
    "Rank": 4908,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03aa8"
    },
    "Rank": 4910,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03aa9"
    },
    "Rank": 4911,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03aaa"
    },
    "Rank": 4912,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, THOOTHUKUDI,GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, 3rd\nMile,Kamaraj Nagar,Thoothukudi., Tamil Nadu, 628008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03aab"
    },
    "Rank": 4914,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03aac"
    },
    "Rank": 4916,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ananthapuri Hospital and Research Institute, ,Chacka, NH Bypass, Thiruvananthapuram 695024, Kerala, 695024",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03aad"
    },
    "Rank": 4917,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03aae"
    },
    "Rank": 4919,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03aaf"
    },
    "Rank": 4920,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Little Flower Hospital and Research Centre,,Post Box 23, Angamaly, Ernakulam, Kerala, Kerala, 683572",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ab0"
    },
    "Rank": 4923,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ab1"
    },
    "Rank": 4924,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ab2"
    },
    "Rank": 4925,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aditya Birla Memorial Hospital, Maharashtra,Aditya Birla Hospital Marg, P.O.- Chinchwad, Pune, Maharashtra, 411033",
    "Course": "(NBEMS) NUCLEAR MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ab3"
    },
    "Rank": 4926,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ab4"
    },
    "Rank": 4929,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pimpri Chichwad Municipal Corporation Postgraduate Institute Y.C.M.Hospital Pimpri,Pune 18,Dean Office, Y.C.M.Hospital, Sant Tukaramnagar Pimpri, Pune, Maharashtra, 411018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ab5"
    },
    "Rank": 4930,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ab6"
    },
    "Rank": 4931,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ab7"
    },
    "Rank": 4933,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ab8"
    },
    "Rank": 4934,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ab9"
    },
    "Rank": 4936,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03aba"
    },
    "Rank": 4937,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ramkrishna Care Hospital, ,AUROBINDO ENCLAVE, PACHPEDI NAKA,DAHAMTARI ROAD, RAIPUR, Chhattisgarh, 492001",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03abb"
    },
    "Rank": 4938,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03abc"
    },
    "Rank": 4939,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03abd"
    },
    "Rank": 4940,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "AIG Hospital,Door No: 1 66 AIG 2 to 5 Mindspace Road  Gachibowli Hyderabad\nTelangana 500032",
    "Course": "(NBEMS) NUCLEAR MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03abe"
    },
    "Rank": 4941,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03abf"
    },
    "Rank": 4942,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Gujarat Research and Medical Institute (Rajasthan Hospitals), ,Camp Road, Shahibaug, Ahmedabad, Gujarat, 380004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ac0"
    },
    "Rank": 4944,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES,CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, Chhattisgarh, 495001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ac1"
    },
    "Rank": 4945,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Heritage Hospital Pvt. Ltd.,\nUttar Pradesh,Madhav Market Lanka Near BHU Gate Varanasi, Uttar Pradesh, 221005",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ac2"
    },
    "Rank": 4946,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indo-American Cancer Institute and Research Centre, ,Road No.14, Banjara Hills, Hyderabad, Telangana, 500034",
    "Course": "(NBEMS) NUCLEAR MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ac3"
    },
    "Rank": 4947,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ac4"
    },
    "Rank": 4948,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Bundelkhand Medical College Sagar M.P.,Govt. Bundelkhand Medical College Sagar M.P., Madhya Pradesh, 470001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ac5"
    },
    "Rank": 4949,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Naruvi Hospitals ,\nTamil Nadu,Naruvi Hospitals, A Unit of SANCO Foundation, T.S.No.72 1B2, Collector Office Road, Chennai- Bengalu, Tamil Nadu, 632004",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ac6"
    },
    "Rank": 4950,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ac7"
    },
    "Rank": 4951,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ac8"
    },
    "Rank": 4952,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Jamshedpur,Mahatma Gandhi Memorial Medical College, Jamshedpur, Jharkhand, 831020",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ac9"
    },
    "Rank": 4953,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03aca"
    },
    "Rank": 4954,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03acb"
    },
    "Rank": 4956,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03acc"
    },
    "Rank": 4957,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ramkrishna Care Hospital, ,AUROBINDO ENCLAVE, PACHPEDI NAKA,DAHAMTARI ROAD, RAIPUR, Chhattisgarh, 492001",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03acd"
    },
    "Rank": 4958,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gvernment Medical College,Haldwani,Government Medical College, Haldwani, Uttarakhand, 263139",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ace"
    },
    "Rank": 4959,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03acf"
    },
    "Rank": 4960,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ad0"
    },
    "Rank": 4963,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ad1"
    },
    "Rank": 4964,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ad2"
    },
    "Rank": 4965,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "DIP.IN MEDICAL RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ad3"
    },
    "Rank": 4966,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ad4"
    },
    "Rank": 4969,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ad5"
    },
    "Rank": 4970,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ad6"
    },
    "Rank": 4971,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ad7"
    },
    "Rank": 4973,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaipur Golden Hospital, ,2 INSTITUTIONAL AREA, SECTOR-3, ROHINI, Delhi (NCT), 110085",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ad8"
    },
    "Rank": 4974,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ad9"
    },
    "Rank": 4975,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ada"
    },
    "Rank": 4976,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03adb"
    },
    "Rank": 4977,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03adc"
    },
    "Rank": 4978,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Little Flower Hospital and Research Centre,,Post Box 23, Angamaly, Ernakulam, Kerala, Kerala, 683572",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03add"
    },
    "Rank": 4979,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ade"
    },
    "Rank": 4980,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03adf"
    },
    "Rank": 4981,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ae0"
    },
    "Rank": 4983,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ae1"
    },
    "Rank": 4984,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ae2"
    },
    "Rank": 4985,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ae3"
    },
    "Rank": 4986,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Mulund - Goregaon Link Rd, Nahur West, Industrial Area, Bhandup West, Mumbai, Maharashtra 400078, Maharashtra, 400078",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ae4"
    },
    "Rank": 4987,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ae5"
    },
    "Rank": 4989,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ae6"
    },
    "Rank": 4990,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ae7"
    },
    "Rank": 4992,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SunShine Hospital, ,1-7-201 TO 205\nPG Road, Paradise, Secunderabad, Telangana, 500003",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ae8"
    },
    "Rank": 4993,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute Of Medical Sciences, Kalaburagi,VEERESH NAGAR SEDAM ROAD KALABURAGI, Karnataka, 585105",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ae9"
    },
    "Rank": 4994,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, (A unit of Balaji Medical and Diagnostic Research Centre)\n,Max Super Specialty Hospital -108A IP Extension Patparganj New Delhi, Delhi (NCT), 110092",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03aea"
    },
    "Rank": 4997,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03aeb"
    },
    "Rank": 4998,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03aec"
    },
    "Rank": 5000,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03aed"
    },
    "Rank": 5001,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03aee"
    },
    "Rank": 5004,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03aef"
    },
    "Rank": 5005,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03af0"
    },
    "Rank": 5008,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03af1"
    },
    "Rank": 5011,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03af2"
    },
    "Rank": 5012,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03af3"
    },
    "Rank": 5013,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03af4"
    },
    "Rank": 5014,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03af5"
    },
    "Rank": 5016,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Darbhanga Medical College Hospital,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "(NBEMS) DERMATOLOGY and VENEREOLOGY and LEPROSY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03af6"
    },
    "Rank": 5017,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03af7"
    },
    "Rank": 5018,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03af8"
    },
    "Rank": 5019,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03af9"
    },
    "Rank": 5020,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03afa"
    },
    "Rank": 5021,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03afb"
    },
    "Rank": 5022,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03afc"
    },
    "Rank": 5023,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medical Trust Hospital, ,M.G. Road Pallimukku\nCochin, Kerala, 682016",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03afd"
    },
    "Rank": 5025,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03afe"
    },
    "Rank": 5027,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03aff"
    },
    "Rank": 5028,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b00"
    },
    "Rank": 5029,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b01"
    },
    "Rank": 5031,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b02"
    },
    "Rank": 5032,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b03"
    },
    "Rank": 5034,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Little Flower Hospital and Research Centre,,Post Box 23, Angamaly, Ernakulam, Kerala, Kerala, 683572",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b04"
    },
    "Rank": 5035,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vishesh Jupiter Hospital,Scheme No 94, Ring Road, near Teen Imli Chauraha, Sector 1, Indore, Madhya Pradesh 452020, Madhya Pradesh, 452020",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b05"
    },
    "Rank": 5036,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b06"
    },
    "Rank": 5037,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b07"
    },
    "Rank": 5038,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b08"
    },
    "Rank": 5039,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b09"
    },
    "Rank": 5040,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Rangarajan Memorial Hospital, (Sundaram Medical Foundation) ,Sundaram Medical Foundation, Shanthi Colony, 4th Avenue, Anna Nagar, Chennai, Tamil Nadu, 600040",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b0a"
    },
    "Rank": 5041,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b0b"
    },
    "Rank": 5042,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Children`s Hospital, ,Rainbow Childrens Hospital, Road No.2, Banjara Hills, Hyderabad, Telangana, 500034",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b0c"
    },
    "Rank": 5044,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b0d"
    },
    "Rank": 5045,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Revathi Medical Center Tirupur (I) Pvt Ltd ,\nTamil Nadu,No: 10, KUMAR NAGAR WEST, VALAYANKADU ROAD, AVINASHI ROAD, TIRUPUR, Tamil Nadu, 641603",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b0e"
    },
    "Rank": 5047,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b0f"
    },
    "Rank": 5049,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b10"
    },
    "Rank": 5050,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ananthapuri Hospital and Research Institute, ,Chacka, NH Bypass, Thiruvananthapuram 695024, Kerala, 695024",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b11"
    },
    "Rank": 5052,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b12"
    },
    "Rank": 5053,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b13"
    },
    "Rank": 5056,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "IQRAA International Hospital and Research Centre, ,IQRAA INTERNATIONAL HOSPITAL AND RESEARCH CENTRE MALAPARAMBA CALICUT, Kerala, 673009",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b14"
    },
    "Rank": 5057,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "AMRI Hospitals,\nWest Bengal,AMRI Hospitals Mukundapur 230 Barakhola Lane Behind Metro Cash and Carry on EM Bypass Kolkata, West Bengal, 700099",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b15"
    },
    "Rank": 5058,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b16"
    },
    "Rank": 5059,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agrasen Medical College,,NH- 9, VPO - Agroha, District Hisar, Haryana, 125047",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b17"
    },
    "Rank": 5060,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kozhikode District Co-Operative Hospital,The Kozhikode District Co-operative Hospital, Eranhipalam, Kozhikode, 673006, Kerala, 673006",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b18"
    },
    "Rank": 5061,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b19"
    },
    "Rank": 5062,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b1a"
    },
    "Rank": 5063,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b1b"
    },
    "Rank": 5064,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b1c"
    },
    "Rank": 5065,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "National Institute of Tuberculosis and Respiratory Diseases, (Formerly Known as L.R.S. Instt. of T.B. and Allied Scs),,National Institute of Tuberculosis and Respiratory Diseases (Formerly Known as L.R.S. Instt. of T.B., Delhi (NCT), 110030",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b1d"
    },
    "Rank": 5066,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b1e"
    },
    "Rank": 5067,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b1f"
    },
    "Rank": 5071,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES,CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, Chhattisgarh, 495001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b20"
    },
    "Rank": 5073,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b21"
    },
    "Rank": 5074,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b22"
    },
    "Rank": 5075,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b23"
    },
    "Rank": 5076,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b24"
    },
    "Rank": 5077,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B L Kapur Memorial Hospital,Pusa Road New Delhi, Delhi (NCT), 110005",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b25"
    },
    "Rank": 5079,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE - KANNUR,GOVERNMENT MEDICAL COLLEGE\n- KANNUR, Kerala, 670503",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b26"
    },
    "Rank": 5080,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b27"
    },
    "Rank": 5081,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Philomena`s Hospital, ,No.4, Campbell Road, Viveknagar P.O. Bangalore - 560047, Karnataka, 560047",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b28"
    },
    "Rank": 5082,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Parsik Hill Road, ,APOLLO HOSPITAL PARSIK HILL ROAD ROAD NO 13 SECTOR 23 CBD BELAPUR NAVI MUMBAI, Maharashtra, 400614",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b29"
    },
    "Rank": 5083,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b2a"
    },
    "Rank": 5084,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b2b"
    },
    "Rank": 5086,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b2c"
    },
    "Rank": 5087,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b2d"
    },
    "Rank": 5089,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College,Theni ,Government Medical College,Theni, Tamil Nadu, 625512",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b2e"
    },
    "Rank": 5090,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b2f"
    },
    "Rank": 5091,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b30"
    },
    "Rank": 5093,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. Medical College and hospital, ,Govt Medical College  Akola  Collector Office Road Akola Maharshtra, Maharashtra, 444001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b31"
    },
    "Rank": 5094,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b32"
    },
    "Rank": 5095,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b33"
    },
    "Rank": 5096,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b34"
    },
    "Rank": 5097,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "National Institute of Tuberculosis and Respiratory Diseases, (Formerly Known as L.R.S. Instt. of T.B. and Allied Scs),,National Institute of Tuberculosis and Respiratory Diseases (Formerly Known as L.R.S. Instt. of T.B., Delhi (NCT), 110030",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b35"
    },
    "Rank": 5098,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b36"
    },
    "Rank": 5100,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b37"
    },
    "Rank": 5101,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b38"
    },
    "Rank": 5104,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b39"
    },
    "Rank": 5105,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Arvind Eye Hospital , ,Aravind Eye Hospital & Postgraduate Institute of Ophthalmology, Avinashi Road, Civil Aerodrome Post,, Tamil Nadu, 641014",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b3a"
    },
    "Rank": 5106,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b3b"
    },
    "Rank": 5108,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b3c"
    },
    "Rank": 5110,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b3d"
    },
    "Rank": 5111,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b3e"
    },
    "Rank": 5112,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b3f"
    },
    "Rank": 5114,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b40"
    },
    "Rank": 5116,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b41"
    },
    "Rank": 5117,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b42"
    },
    "Rank": 5118,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b43"
    },
    "Rank": 5119,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Little Flower Hospital and Research Centre,,Post Box 23, Angamaly, Ernakulam, Kerala, Kerala, 683572",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b44"
    },
    "Rank": 5121,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b45"
    },
    "Rank": 5122,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b46"
    },
    "Rank": 5123,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b47"
    },
    "Rank": 5124,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b48"
    },
    "Rank": 5125,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b49"
    },
    "Rank": 5127,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Stephen`s Hospital, ,Tis Hazari, Delhi (NCT), 110054",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b4a"
    },
    "Rank": 5128,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b4b"
    },
    "Rank": 5129,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b4c"
    },
    "Rank": 5130,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bombay Hospital,Ring Road Indore, Madhya Pradesh, 452010",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b4d"
    },
    "Rank": 5132,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b4e"
    },
    "Rank": 5133,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b4f"
    },
    "Rank": 5134,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b50"
    },
    "Rank": 5135,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b51"
    },
    "Rank": 5136,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b52"
    },
    "Rank": 5139,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agrasen Medical College,,NH- 9, VPO - Agroha, District Hisar, Haryana, 125047",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b53"
    },
    "Rank": 5140,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b54"
    },
    "Rank": 5141,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b55"
    },
    "Rank": 5142,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agarsen Hospital, ,., Delhi (NCT), 110026",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b56"
    },
    "Rank": 5144,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mehta Multispecialty Hospital, (Formerly Known as Dr. Mehta`s Hospital) ,No.2, McNichols Road, 3 rd Lane, Chetpet, Chennai, Tamil Nadu, 600031",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b57"
    },
    "Rank": 5146,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "South Central Railways Hospital, ,SOUTH CENTRAL RAILWAYS CENTRAL HOSPITAL, OFFICE OF THE MEDICAL DIRECTOR, 1ST FLOOR\nLALLAGUDA, METT, Telangana, 500017",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b58"
    },
    "Rank": 5149,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b59"
    },
    "Rank": 5150,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b5a"
    },
    "Rank": 5151,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rabindranath Tagore International Institute of Cardiac Sciences, ,PREMISES NO. 1489 124 MUKUNDAPUR  E M BYPASS  KOLKATA-700099, West Bengal, 700099",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b5b"
    },
    "Rank": 5153,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b5c"
    },
    "Rank": 5154,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b5d"
    },
    "Rank": 5155,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b5e"
    },
    "Rank": 5156,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b5f"
    },
    "Rank": 5157,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b60"
    },
    "Rank": 5158,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College,Theni ,Government Medical College,Theni, Tamil Nadu, 625512",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b61"
    },
    "Rank": 5160,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b62"
    },
    "Rank": 5164,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b63"
    },
    "Rank": 5165,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VILLUPURAM MEDICAL COLLEGE, VILLUPURAM,GOVERNMENT VILLUPURAM MEDICAL COLLEGE VILLUPURAM  TRICHY TRUNK ROAD MUNDIYAMPAKKAM  VILLUPURAM DISTR, Tamil Nadu, 605601",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b64"
    },
    "Rank": 5166,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b65"
    },
    "Rank": 5168,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lourdes Hospital, ,Ernakulam, Pachalam, Kochi, Kerala, 682012",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b66"
    },
    "Rank": 5169,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b67"
    },
    "Rank": 5170,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b68"
    },
    "Rank": 5174,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b69"
    },
    "Rank": 5175,
    "Allotted Quota": "All India",
    "Allotted Institute": "Post Graduate Institute of Medical Education & Research and Capital Hospital ( PGIMER & CH), Odisha,Post Graduate Institute of Medical Education & Research and Capital Hospital, Bhubaneswar,Udyan Marg, Odisha, 751001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b6a"
    },
    "Rank": 5176,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b6b"
    },
    "Rank": 5177,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sanjay Gandhi Memorial Hospital, , ,Sanjay Gandhi Memorial Hospital\nS-Block, Near Bus Terminal, Mangolpuri, North West, Delhi - 110083, Delhi (NCT), 110083",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b6c"
    },
    "Rank": 5178,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b6d"
    },
    "Rank": 5179,
    "Allotted Quota": "All India",
    "Allotted Institute": "GADAG INSTITUTE OF MEDICAL SCIENCES GADAG,Gadag Institute of Medical Sciences Mallasamudra Gadag-582103, Karnataka, 582103",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b6e"
    },
    "Rank": 5180,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b6f"
    },
    "Rank": 5182,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b70"
    },
    "Rank": 5184,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Philomena`s Hospital, ,No.4, Campbell Road, Viveknagar P.O. Bangalore - 560047, Karnataka, 560047",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b71"
    },
    "Rank": 5185,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pimpri Chichwad Municipal Corporation Postgraduate Institute Y.C.M.Hospital Pimpri,Pune 18,Dean Office, Y.C.M.Hospital, Sant Tukaramnagar Pimpri, Pune, Maharashtra, 411018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b72"
    },
    "Rank": 5186,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b73"
    },
    "Rank": 5187,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital,,GOVERNMENT HEADQUARTERS HOSPITAL AND DNB POST GRADUATE TEACHING INSTITUTE,EVN ROAD, ERODE, Tamil Nadu, 638009",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b74"
    },
    "Rank": 5188,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Sathya Sai Instt. of Higher Medical Sciences, ,EPIP AREA, WHITEFIELD, BANGALORE, Karnataka, 560066",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b75"
    },
    "Rank": 5189,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b76"
    },
    "Rank": 5190,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mehta Multispecialty Hospital, (Formerly Known as Dr. Mehta`s Hospital) ,No.2, McNichols Road, 3 rd Lane, Chetpet, Chennai, Tamil Nadu, 600031",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b77"
    },
    "Rank": 5191,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b78"
    },
    "Rank": 5192,
    "Allotted Quota": "All India",
    "Allotted Institute": "Institute of Child Health, Kolkata,Institute of Child Health, Kolkata, West Bengal, 700017",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b79"
    },
    "Rank": 5193,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b7a"
    },
    "Rank": 5194,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b7b"
    },
    "Rank": 5195,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b7c"
    },
    "Rank": 5196,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b7d"
    },
    "Rank": 5197,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b7e"
    },
    "Rank": 5198,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Martha`s Hospital , ,No.5, Nrupathunga Road, Bengaluru, Karnataka, 560001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b7f"
    },
    "Rank": 5199,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b80"
    },
    "Rank": 5200,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College, Kolhapur,R.K. Nagar Road, Shenda Park, Kolhapur, Maharashtra, Maharashtra, 416012",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b81"
    },
    "Rank": 5201,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b82"
    },
    "Rank": 5202,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K B Bhabha Municipal General Hospital, ,R K PATKAR ROAD ,BANDRA WEST\n,MUMBAI, Maharashtra, 400050",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b83"
    },
    "Rank": 5203,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sadhu Vaswani Missions ,7-9 Lane No 1 Koregaon Park Pune 411001, Maharashtra, 411001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b84"
    },
    "Rank": 5204,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jupiter Hospital, ,Eastern Express Highway, Thane West, Maharashtra, 400601",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b85"
    },
    "Rank": 5207,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b86"
    },
    "Rank": 5208,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b87"
    },
    "Rank": 5210,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b88"
    },
    "Rank": 5213,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b89"
    },
    "Rank": 5216,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Masonic Medical Centre for Children, Tamil Nadu,232 RACE COURSE,COIMBATORE, Tamil Nadu, 641018",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b8a"
    },
    "Rank": 5219,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b8b"
    },
    "Rank": 5220,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b8c"
    },
    "Rank": 5222,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b8d"
    },
    "Rank": 5223,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lilavati Hospital and Research Centre, ,Lilavati Hospital And Research Centre A-791, Bandra Reclamation, Bandra - West, Mumbai, Maharashtra, 400050",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b8e"
    },
    "Rank": 5224,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b8f"
    },
    "Rank": 5225,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b90"
    },
    "Rank": 5228,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b91"
    },
    "Rank": 5229,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b92"
    },
    "Rank": 5230,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b93"
    },
    "Rank": 5231,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SunShine Hospital, ,1-7-201 TO 205\nPG Road, Paradise, Secunderabad, Telangana, 500003",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b94"
    },
    "Rank": 5233,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Martha`s Hospital , ,No.5, Nrupathunga Road, Bengaluru, Karnataka, 560001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b95"
    },
    "Rank": 5234,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Institute of Medical Sciences (SVIMS), A State University estd. by Govt. of A.P.,Sri Venkateswara Institute of Medical Sciences, SVIMS, Alipiri Road, Tirupati, Andhra Pradesh, 517507",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b96"
    },
    "Rank": 5235,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b97"
    },
    "Rank": 5237,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medical Trust Hospital, ,M.G. Road Pallimukku\nCochin, Kerala, 682016",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b98"
    },
    "Rank": 5238,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b99"
    },
    "Rank": 5239,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b9a"
    },
    "Rank": 5242,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "AIG Hospital,Door No: 1 66 AIG 2 to 5 Mindspace Road  Gachibowli Hyderabad\nTelangana 500032",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b9b"
    },
    "Rank": 5243,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b9c"
    },
    "Rank": 5244,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b9d"
    },
    "Rank": 5245,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B L Kapur Memorial Hospital,Pusa Road New Delhi, Delhi (NCT), 110005",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b9e"
    },
    "Rank": 5246,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03b9f"
    },
    "Rank": 5248,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ba0"
    },
    "Rank": 5250,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ba1"
    },
    "Rank": 5251,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ba2"
    },
    "Rank": 5252,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata Shrimati Devendra Kumari Singhdeo Government Medical College, Ambikapur,Kanyaparisar  Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ba3"
    },
    "Rank": 5253,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ba4"
    },
    "Rank": 5257,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ba5"
    },
    "Rank": 5258,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ba6"
    },
    "Rank": 5259,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ba7"
    },
    "Rank": 5262,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ba8"
    },
    "Rank": 5263,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ba9"
    },
    "Rank": 5265,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ananthapuri Hospital and Research Institute, ,Chacka, NH Bypass, Thiruvananthapuram 695024, Kerala, 695024",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03baa"
    },
    "Rank": 5266,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bab"
    },
    "Rank": 5270,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bac"
    },
    "Rank": 5271,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bad"
    },
    "Rank": 5273,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bae"
    },
    "Rank": 5276,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Vasantrao Naik Govt Medical College Yavatmal,Shri Vasantrao Naik Govt Medical College Yavatmal, Maharashtra, 445001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03baf"
    },
    "Rank": 5277,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Wockhardt Hospital, ,1877, Dr. Anand Rao Nair Marg, Mumbai Central, Maharashtra, 400011",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bb0"
    },
    "Rank": 5278,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bb1"
    },
    "Rank": 5279,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bb2"
    },
    "Rank": 5280,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bb3"
    },
    "Rank": 5281,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College,Fakhruddin Ali Ahmed Medical College, Assam, 781301",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bb4"
    },
    "Rank": 5283,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bb5"
    },
    "Rank": 5285,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bb6"
    },
    "Rank": 5287,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, (A unit of Balaji Medical and Diagnostic Research Centre)\n,Max Super Specialty Hospital -108A IP Extension Patparganj New Delhi, Delhi (NCT), 110092",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bb7"
    },
    "Rank": 5288,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bb8"
    },
    "Rank": 5289,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bb9"
    },
    "Rank": 5290,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bba"
    },
    "Rank": 5292,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bbb"
    },
    "Rank": 5293,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College,Theni ,Government Medical College,Theni, Tamil Nadu, 625512",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bbc"
    },
    "Rank": 5295,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Institute of Medical Sciences, ,Greater Noida, Kasna, Uttar Pradesh, 201310",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bbd"
    },
    "Rank": 5297,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bombay Hospital,Ring Road Indore, Madhya Pradesh, 452010",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bbe"
    },
    "Rank": 5299,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government General Hospital, ,Government General Hospital Thiruvananthapuram, Vanchiyoor PO Thiruvananthapuram Kerala, Kerala, 695035",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bbf"
    },
    "Rank": 5302,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees' State Insurance Corporation Medical College & Hospital, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD GULBARGA, Karnataka, 585106",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bc0"
    },
    "Rank": 5303,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bc1"
    },
    "Rank": 5304,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bc2"
    },
    "Rank": 5305,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bc3"
    },
    "Rank": 5306,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bc4"
    },
    "Rank": 5307,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bc5"
    },
    "Rank": 5308,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bc6"
    },
    "Rank": 5310,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bc7"
    },
    "Rank": 5311,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bc8"
    },
    "Rank": 5313,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bc9"
    },
    "Rank": 5314,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bca"
    },
    "Rank": 5315,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bcb"
    },
    "Rank": 5317,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bcc"
    },
    "Rank": 5319,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bcd"
    },
    "Rank": 5320,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bce"
    },
    "Rank": 5322,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bcf"
    },
    "Rank": 5324,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bd0"
    },
    "Rank": 5327,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bd1"
    },
    "Rank": 5328,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bd2"
    },
    "Rank": 5329,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saifee Hospital, ,postbox no 3771, 15-17 Maharshi Karve Marg, Charni road, Mumbai, Maharashtra, 400004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bd3"
    },
    "Rank": 5330,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bd4"
    },
    "Rank": 5332,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bd5"
    },
    "Rank": 5333,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS MEDICAL COLLEGE, NEAR GUJARAT HIGH COURT, S.G HIGH WAY, SOLA, AHMEDABAD, Gujarat, 380060",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bd6"
    },
    "Rank": 5335,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bd7"
    },
    "Rank": 5337,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bd8"
    },
    "Rank": 5338,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bd9"
    },
    "Rank": 5339,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bda"
    },
    "Rank": 5340,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bdb"
    },
    "Rank": 5341,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bdc"
    },
    "Rank": 5343,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bdd"
    },
    "Rank": 5344,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bde"
    },
    "Rank": 5347,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bdf"
    },
    "Rank": 5348,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03be0"
    },
    "Rank": 5350,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03be1"
    },
    "Rank": 5352,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN,HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN, Karnataka, 573201",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03be2"
    },
    "Rank": 5353,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03be3"
    },
    "Rank": 5355,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03be4"
    },
    "Rank": 5356,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03be5"
    },
    "Rank": 5357,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BAPS Pramukh Swami Hospital, ,SHRI PRAMUKH SWAMI MAHARAJ MARG, ADAJAN CHAR RASTA, ADAJAN, SURAT, Gujarat, 395009",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03be6"
    },
    "Rank": 5358,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03be7"
    },
    "Rank": 5360,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharashtra Post Graduate Institute of Medical Education and Research, Nashik,Maharashtra Post Graduation Institute Of Medical Education and Research Civil Hospital campus Trimba, Maharashtra, 422002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03be8"
    },
    "Rank": 5362,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Institute of Medical Sciences, ,Greater Noida, Kasna, Uttar Pradesh, 201310",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03be9"
    },
    "Rank": 5363,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KIMS Hospital,,#1-112 / 86, Survey No 5 / EE, beside Union Bank, near RTA Office, Kondapur, Hyderabad,Telangana, Telangana, 500084",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bea"
    },
    "Rank": 5364,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03beb"
    },
    "Rank": 5365,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Christian Fellowship Hospital, ,Dindigul Palani Main Road Oddanchatram\nDindigul District, Tamil Nadu, 624619",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bec"
    },
    "Rank": 5367,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bed"
    },
    "Rank": 5368,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lilavati Hospital and Research Centre, ,Lilavati Hospital And Research Centre A-791, Bandra Reclamation, Bandra - West, Mumbai, Maharashtra, 400050",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bee"
    },
    "Rank": 5369,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Children`s Hospital, ,Rainbow Childrens Hospital, Road No.2, Banjara Hills, Hyderabad, Telangana, 500034",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bef"
    },
    "Rank": 5370,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "HCG Hospital,,\n1, Gujarat,1, Maharashtra Society, Mithakhali Six Road, Ellisbridge, Ahmedabad, Gujarat 380006, Gujarat, 380006",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bf0"
    },
    "Rank": 5371,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bf1"
    },
    "Rank": 5373,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bf2"
    },
    "Rank": 5374,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Martha`s Hospital , ,No.5, Nrupathunga Road, Bengaluru, Karnataka, 560001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bf3"
    },
    "Rank": 5376,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bf4"
    },
    "Rank": 5378,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bf5"
    },
    "Rank": 5379,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bf6"
    },
    "Rank": 5381,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bf7"
    },
    "Rank": 5382,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bf8"
    },
    "Rank": 5383,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bf9"
    },
    "Rank": 5384,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital , ,GMERS MEDICAL COLLEGE , Hadiyol-Gadhoda Road, Near-Government Polytechnic, Hiimmatnagar, Gujarat, 383001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bfa"
    },
    "Rank": 5386,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaipur Golden Hospital, ,2 INSTITUTIONAL AREA, SECTOR-3, ROHINI, Delhi (NCT), 110085",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bfb"
    },
    "Rank": 5389,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bfc"
    },
    "Rank": 5392,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bfd"
    },
    "Rank": 5393,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bfe"
    },
    "Rank": 5395,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03bff"
    },
    "Rank": 5396,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c00"
    },
    "Rank": 5398,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c01"
    },
    "Rank": 5399,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c02"
    },
    "Rank": 5400,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c03"
    },
    "Rank": 5402,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c04"
    },
    "Rank": 5404,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c05"
    },
    "Rank": 5407,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c06"
    },
    "Rank": 5408,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sadhu Vaswani Missions ,7-9 Lane No 1 Koregaon Park Pune 411001, Maharashtra, 411001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c07"
    },
    "Rank": 5409,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c08"
    },
    "Rank": 5411,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c09"
    },
    "Rank": 5412,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Northern Railway Central Hospital,, ,Basant Lane New Delhi, Delhi (NCT), 110055",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c0a"
    },
    "Rank": 5414,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BharatRatna Dr. Babasaheb Ambedkar Municipal General Hospital, Kandivali (West), Maharashtra,Parekh Nagar, SV Road, Opp. Kandivali Police station, Kandivali West, Mumbai, Maharashtra, 400067",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c0b"
    },
    "Rank": 5415,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c0c"
    },
    "Rank": 5420,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "HEALTH CITY HOSPITAL , Assam\n,UDAY NAGAR KOINADHARA KHANAPARA GUWAHATI KAMRUP M, Assam, 781022",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c0d"
    },
    "Rank": 5422,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c0e"
    },
    "Rank": 5423,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c0f"
    },
    "Rank": 5424,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c10"
    },
    "Rank": 5425,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c11"
    },
    "Rank": 5426,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c12"
    },
    "Rank": 5428,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c13"
    },
    "Rank": 5429,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c14"
    },
    "Rank": 5430,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c15"
    },
    "Rank": 5434,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c16"
    },
    "Rank": 5435,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c17"
    },
    "Rank": 5440,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c18"
    },
    "Rank": 5441,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) Plastic and Reconstructive Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c19"
    },
    "Rank": 5442,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, THOOTHUKUDI,GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, 3rd\nMile,Kamaraj Nagar,Thoothukudi., Tamil Nadu, 628008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c1a"
    },
    "Rank": 5444,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c1b"
    },
    "Rank": 5445,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c1c"
    },
    "Rank": 5447,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c1d"
    },
    "Rank": 5448,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BharatRatna Dr. Babasaheb Ambedkar Municipal General Hospital, Kandivali (West), Maharashtra,Parekh Nagar, SV Road, Opp. Kandivali Police station, Kandivali West, Mumbai, Maharashtra, 400067",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c1e"
    },
    "Rank": 5449,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c1f"
    },
    "Rank": 5450,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Cosmopolitan Hospital,,COSMOPOLITAN HOSPITALS Pvt.Ltd, Murinjapalam, Pattom P O, Thiruvananthapuram, Kerala, 695004",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c20"
    },
    "Rank": 5451,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c21"
    },
    "Rank": 5452,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sahyadri Super Specialty Hospital,,Plot No.30C, Erandawane, Karve Road, Pune, Maharashtra, 411004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c22"
    },
    "Rank": 5453,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c23"
    },
    "Rank": 5454,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c24"
    },
    "Rank": 5457,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c25"
    },
    "Rank": 5458,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c26"
    },
    "Rank": 5459,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c27"
    },
    "Rank": 5461,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c28"
    },
    "Rank": 5462,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c29"
    },
    "Rank": 5463,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c2a"
    },
    "Rank": 5465,
    "Allotted Quota": "All India",
    "Allotted Institute": "SDS Tuberculosis Research Centre and Rajiv Gandhi Institute of Chest Diseases ,SDS Tuberculosis Research Centre and Rajiv Gandhi Institute of Chest Diseases, Karnataka, 560029",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c2b"
    },
    "Rank": 5469,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c2c"
    },
    "Rank": 5470,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College,Theni ,Government Medical College,Theni, Tamil Nadu, 625512",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c2d"
    },
    "Rank": 5471,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharashtra Post Graduate Institute of Medical Education and Research, Nashik,Maharashtra Post Graduation Institute Of Medical Education and Research Civil Hospital campus Trimba, Maharashtra, 422002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c2e"
    },
    "Rank": 5472,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MAR SLEEVA MEDICITY PALAI, Kerala,Kezhuvankulam P.O., Cherpunkal, Kottayam, Kerala, 686584",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c2f"
    },
    "Rank": 5473,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhaktivedanta Hospital and Research Institute, ,Bhaktivedanta Hospital and Research Institute,Srishti Complex, Bhaktivedanta Swami Marg, Mira Road E, Maharashtra, 401107",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c30"
    },
    "Rank": 5474,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c31"
    },
    "Rank": 5475,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Care Hospital-,CARE HOSPITAL OLD MUMBAI HIGH WAY NEAR CYBERABAD POLICE COMMISSIONARATE HITECH CITY GACHIBOWLI HYDER, Telangana, 500032",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c32"
    },
    "Rank": 5476,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c33"
    },
    "Rank": 5477,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kozhikode District Co-Operative Hospital,The Kozhikode District Co-operative Hospital, Eranhipalam, Kozhikode, 673006, Kerala, 673006",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c34"
    },
    "Rank": 5479,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c35"
    },
    "Rank": 5482,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mata Chanan Devi Hospital,, ,C-1 JANAKPURI, Delhi (NCT), 110058",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c36"
    },
    "Rank": 5483,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE,ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, Bihar, 823001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c37"
    },
    "Rank": 5484,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c38"
    },
    "Rank": 5486,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c39"
    },
    "Rank": 5488,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c3a"
    },
    "Rank": 5489,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c3b"
    },
    "Rank": 5490,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c3c"
    },
    "Rank": 5491,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c3d"
    },
    "Rank": 5493,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "South Western Railway,Room No 22 Central Hospital South Western Railway Gadag, road Hubballi, Karnataka, 580020",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c3e"
    },
    "Rank": 5494,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c3f"
    },
    "Rank": 5495,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c40"
    },
    "Rank": 5496,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c41"
    },
    "Rank": 5497,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c42"
    },
    "Rank": 5498,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c43"
    },
    "Rank": 5499,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c44"
    },
    "Rank": 5500,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c45"
    },
    "Rank": 5501,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c46"
    },
    "Rank": 5502,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c47"
    },
    "Rank": 5503,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Poonam Palaspagar - Academic Coordinator\nFortis Hospital, Bannerghatta Road, Bangalore, Karnataka 5, Karnataka, 560076",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c48"
    },
    "Rank": 5505,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kauvery Hospital, ,No.199, Luz Church road, Mylapore,Chennai-600004, Tamil Nadu, 600004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c49"
    },
    "Rank": 5506,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c4a"
    },
    "Rank": 5507,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c4b"
    },
    "Rank": 5508,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Christian Fellowship Hospital, ,Dindigul Palani Main Road Oddanchatram\nDindigul District, Tamil Nadu, 624619",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c4c"
    },
    "Rank": 5510,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c4d"
    },
    "Rank": 5513,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c4e"
    },
    "Rank": 5514,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, ,1,2, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c4f"
    },
    "Rank": 5515,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c50"
    },
    "Rank": 5517,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c51"
    },
    "Rank": 5518,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c52"
    },
    "Rank": 5522,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c53"
    },
    "Rank": 5523,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c54"
    },
    "Rank": 5524,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine & Sagore Dutta Hospital,College of Medicine & Sagore Dutta Hospital, West Bengal, 700058",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c55"
    },
    "Rank": 5525,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c56"
    },
    "Rank": 5526,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "HEALTH CITY HOSPITAL , Assam\n,UDAY NAGAR KOINADHARA KHANAPARA GUWAHATI KAMRUP M, Assam, 781022",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c57"
    },
    "Rank": 5528,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shaheed Hasan Khan Mewati Government Medical College , ,Shaheed Hasan Khan Mewati Government Medical College Nalhar Nuh, Haryana, 122107",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c58"
    },
    "Rank": 5530,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "DIP. IN DERM. VENEREOLOGY and LEPROSY/DERMATOLO GY /VENEREOLOGY and DERMATOLOGY/LEPRO SY/VENEREAL DISEASE and LEPROSY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c59"
    },
    "Rank": 5531,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Star Hospital,,Star Hospitals, 8-2-596/5, Road. No. 10,\nBanjara Hills, Hyderabad, Telangana, 500034",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c5a"
    },
    "Rank": 5532,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c5b"
    },
    "Rank": 5534,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c5c"
    },
    "Rank": 5535,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c5d"
    },
    "Rank": 5536,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c5e"
    },
    "Rank": 5537,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c5f"
    },
    "Rank": 5538,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c60"
    },
    "Rank": 5539,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VILLUPURAM MEDICAL COLLEGE, VILLUPURAM,GOVERNMENT VILLUPURAM MEDICAL COLLEGE VILLUPURAM  TRICHY TRUNK ROAD MUNDIYAMPAKKAM  VILLUPURAM DISTR, Tamil Nadu, 605601",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c61"
    },
    "Rank": 5540,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences Koppal, Karnataka,Gangavati Road Kiddidal Gate koppal, Karnataka, 583231",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c62"
    },
    "Rank": 5542,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. GERIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c63"
    },
    "Rank": 5543,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c64"
    },
    "Rank": 5544,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, THOOTHUKUDI,GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, 3rd\nMile,Kamaraj Nagar,Thoothukudi., Tamil Nadu, 628008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c65"
    },
    "Rank": 5545,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c66"
    },
    "Rank": 5546,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c67"
    },
    "Rank": 5548,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c68"
    },
    "Rank": 5549,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Vasantrao Naik Govt Medical College Yavatmal,Shri Vasantrao Naik Govt Medical College Yavatmal, Maharashtra, 445001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c69"
    },
    "Rank": 5550,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c6a"
    },
    "Rank": 5551,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c6b"
    },
    "Rank": 5552,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c6c"
    },
    "Rank": 5553,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Stephen`s Hospital, ,Tis Hazari, Delhi (NCT), 110054",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c6d"
    },
    "Rank": 5555,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Shroff`s Charity Eye Hospital,,5027, Kedarnath lane, opposite DAV school, Daryaganj, Delhi 110009, Delhi (NCT), 110009",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c6e"
    },
    "Rank": 5556,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c6f"
    },
    "Rank": 5558,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c70"
    },
    "Rank": 5561,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jupiter Lifeline Hospital,,Jupiter Lifeline Hospital, Sr No 142/12, And 143/1/4/5, Near Prathamesh Park, Baner, Pune, Maharasht, Maharashtra, 411045",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c71"
    },
    "Rank": 5563,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c72"
    },
    "Rank": 5564,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c73"
    },
    "Rank": 5565,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Medical College and Chhatrapati Shivaji Maharaj Hospital, ,RAJIV GANDHI MEDICAL COLLEGE,THANE BELAPUR ROAD, KALWA, THANE, Maharashtra, 400605",
    "Course": "(NBEMS) DERMATOLOGY and VENEREOLOGY and LEPROSY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c74"
    },
    "Rank": 5566,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c75"
    },
    "Rank": 5567,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c76"
    },
    "Rank": 5569,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c77"
    },
    "Rank": 5571,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c78"
    },
    "Rank": 5573,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Rangarajan Memorial Hospital, (Sundaram Medical Foundation) ,Sundaram Medical Foundation, Shanthi Colony, 4th Avenue, Anna Nagar, Chennai, Tamil Nadu, 600040",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c79"
    },
    "Rank": 5577,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Thiruvannamalai Medical College, Thiruvannamalai, Tamil Nadu,DISTRICT COLLECTORATE  MASTER PLAN COMPLEX, VENGIKKAL THIRUVANNAMALAI,\nTamil Nadu, 600604",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c7a"
    },
    "Rank": 5578,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Christian Fellowship Hospital, ,Dindigul Palani Main Road Oddanchatram\nDindigul District, Tamil Nadu, 624619",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c7b"
    },
    "Rank": 5579,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c7c"
    },
    "Rank": 5581,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS MEDICAL COLLEGE, NEAR GUJARAT HIGH COURT, S.G HIGH WAY, SOLA, AHMEDABAD, Gujarat, 380060",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c7d"
    },
    "Rank": 5582,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c7e"
    },
    "Rank": 5583,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, ,1,2, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c7f"
    },
    "Rank": 5584,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chittaranjan Seva Sadan Hospital,37, S.P. MUKHERJEE ROAD, KOLKATA, West Bengal, 700026",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c80"
    },
    "Rank": 5585,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c81"
    },
    "Rank": 5586,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c82"
    },
    "Rank": 5589,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deen Dayal Upadhyay Hospital, ,DDU HOSPITAL, SHAHEED MANGAL PANDEY MARG, NANAK PURA, HARI NAGAR, NEW DELHI, DELHI-110064, Delhi (NCT), 110064",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c83"
    },
    "Rank": 5590,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c84"
    },
    "Rank": 5593,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c85"
    },
    "Rank": 5594,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Mulund - Goregaon Link Rd, Nahur West, Industrial Area, Bhandup West, Mumbai, Maharashtra 400078, Maharashtra, 400078",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c86"
    },
    "Rank": 5595,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c87"
    },
    "Rank": 5596,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhaktivedanta Hospital and Research Institute, ,Bhaktivedanta Hospital and Research Institute,Srishti Complex, Bhaktivedanta Swami Marg, Mira Road E, Maharashtra, 401107",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c88"
    },
    "Rank": 5597,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c89"
    },
    "Rank": 5598,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saifee Hospital, ,postbox no 3771, 15-17 Maharshi Karve Marg, Charni road, Mumbai, Maharashtra, 400004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c8a"
    },
    "Rank": 5599,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Thiruvannamalai Medical College, Thiruvannamalai, Tamil Nadu,DISTRICT COLLECTORATE  MASTER PLAN COMPLEX, VENGIKKAL THIRUVANNAMALAI,\nTamil Nadu, 600604",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c8b"
    },
    "Rank": 5600,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c8c"
    },
    "Rank": 5601,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c8d"
    },
    "Rank": 5602,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Balaji Action Medical Institute,,FC34 A4 PASCHIM VIHAR NEW DELHI, Delhi (NCT), 110063",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c8e"
    },
    "Rank": 5603,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, ,1,2, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c8f"
    },
    "Rank": 5604,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c90"
    },
    "Rank": 5605,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c91"
    },
    "Rank": 5606,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c92"
    },
    "Rank": 5607,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Memorial Research Institute, ,Fortis memorial research institute, Sector 44, Opposite Huda City Centre Metro station, Gurgaon, Haryana, 122002",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c93"
    },
    "Rank": 5608,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c94"
    },
    "Rank": 5611,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Metas Adventist Hospital, ,PB NO. 24, OPP KP COMMERCE COLLEGE, ATHWALINES,\nGujarat, 395001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c95"
    },
    "Rank": 5612,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c96"
    },
    "Rank": 5613,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c97"
    },
    "Rank": 5614,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c98"
    },
    "Rank": 5617,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c99"
    },
    "Rank": 5619,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c9a"
    },
    "Rank": 5620,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT DHARMAPURI MEDICAL COLLEGE, DHARMAPURI, TAMIL NADU,GOVERNMENT DHARMAPURI MEDICAL COLLEGE, NETHAJI BYE PASS ROAD, DHARMAPURI, TAMIL NADU, Tamil Nadu, 636701",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c9b"
    },
    "Rank": 5622,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c9c"
    },
    "Rank": 5624,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c9d"
    },
    "Rank": 5625,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "P.D. Hinduja National Hospital and Medical Research Centre, ,Veer Savarkar Marg, Mahim, Maharashtra, 400016",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c9e"
    },
    "Rank": 5626,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03c9f"
    },
    "Rank": 5630,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ca0"
    },
    "Rank": 5631,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ca1"
    },
    "Rank": 5632,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ca2"
    },
    "Rank": 5633,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ca3"
    },
    "Rank": 5635,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ca4"
    },
    "Rank": 5636,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital,Ms. Hilda Sujatha Pr no 3-5-836, to 838 , Near Old MLA quarters, Hyderguda, Hyderabad, Telangana, 500029",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ca5"
    },
    "Rank": 5637,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ca6"
    },
    "Rank": 5639,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ca7"
    },
    "Rank": 5641,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Noble Hospital, ,Magarpatta City Road, Hadpsar, Pune, Maharashtra, 411028",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ca8"
    },
    "Rank": 5647,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ca9"
    },
    "Rank": 5648,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03caa"
    },
    "Rank": 5649,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cab"
    },
    "Rank": 5650,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ispat General Hospital,,Ispat General Hospital Rourkela Steel Plant SAIL Rourkela 769005, Odisha, 769005",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cac"
    },
    "Rank": 5652,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cad"
    },
    "Rank": 5653,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. James Hospital, ,Old Highway, CHALAKUDY, Kerala, 680307",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cae"
    },
    "Rank": 5654,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03caf"
    },
    "Rank": 5655,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cb0"
    },
    "Rank": 5657,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cb1"
    },
    "Rank": 5658,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cb2"
    },
    "Rank": 5660,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "G Kuppuswamy Naidu Memorial Hospital, ,Post Box No.6327, Nethaji Road, Pappanaickenpalayam, Coimbatore, Tamil Nadu, 641037",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cb3"
    },
    "Rank": 5661,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cb4"
    },
    "Rank": 5662,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cb5"
    },
    "Rank": 5663,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cb6"
    },
    "Rank": 5664,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cb7"
    },
    "Rank": 5667,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cb8"
    },
    "Rank": 5668,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cb9"
    },
    "Rank": 5670,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Wockhardt Hospital, ,1877, Dr. Anand Rao Nair Marg, Mumbai Central, Maharashtra, 400011",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cba"
    },
    "Rank": 5671,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. L H Hiranandani Hospital, ,HILL SIDE AVENUE, HIRANANDANI GARDENS, POWAI-400076, Maharashtra, 400076",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cbb"
    },
    "Rank": 5672,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cbc"
    },
    "Rank": 5673,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cbd"
    },
    "Rank": 5674,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cbe"
    },
    "Rank": 5675,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cbf"
    },
    "Rank": 5676,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cc0"
    },
    "Rank": 5677,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharashtra Post Graduate Institute of Medical Education and Research, Nashik,Maharashtra Post Graduation Institute Of Medical Education and Research Civil Hospital campus Trimba, Maharashtra, 422002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cc1"
    },
    "Rank": 5678,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Meenakshi Mission Hospital and Research Centre, ,Lake Area, Melur Road, Madurai, Tamil Nadu, 625107",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cc2"
    },
    "Rank": 5679,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cc3"
    },
    "Rank": 5680,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. L H Hiranandani Hospital, ,HILL SIDE AVENUE, HIRANANDANI GARDENS, POWAI-400076, Maharashtra, 400076",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cc4"
    },
    "Rank": 5681,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cc5"
    },
    "Rank": 5683,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cc6"
    },
    "Rank": 5684,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "DIP.IN MEDICAL RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cc7"
    },
    "Rank": 5685,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagwan Mahaveer Jain Hospital, ,MILLERS ROAD, VASANTHNAGAR, BANGALORE, Karnataka, 560052",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cc8"
    },
    "Rank": 5686,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Erode Medical College- Formerly IRT-Perundurai Medical College,GOVERNMENT ERODE MEDICAL COLLEGE AND  HOSPITAL PERUNDURAI SANATORIUM POST ERODE  DISTRICT, Tamil Nadu, 638053",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cc9"
    },
    "Rank": 5687,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cca"
    },
    "Rank": 5688,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharashtra Post Graduate Institute of Medical Education and Research, Nashik,Maharashtra Post Graduation Institute Of Medical Education and Research Civil Hospital campus Trimba, Maharashtra, 422002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ccb"
    },
    "Rank": 5689,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ccc"
    },
    "Rank": 5690,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ccd"
    },
    "Rank": 5691,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kauvery Hospital, ,No.199, Luz Church road, Mylapore,Chennai-600004, Tamil Nadu, 600004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cce"
    },
    "Rank": 5692,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ccf"
    },
    "Rank": 5696,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cd0"
    },
    "Rank": 5697,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cd1"
    },
    "Rank": 5698,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deen Dayal Upadhyay Hospital, ,DDU HOSPITAL, SHAHEED MANGAL PANDEY MARG, NANAK PURA, HARI NAGAR, NEW DELHI, DELHI-110064, Delhi (NCT), 110064",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cd2"
    },
    "Rank": 5699,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR GOVERNMENT MEDICAL COLLEGE,NELLORE,Opp AC Subbareddy Stadium\nDargamitta Nellore, Andhra Pradesh, 524004",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cd3"
    },
    "Rank": 5700,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cd4"
    },
    "Rank": 5701,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital , ,District Hospital Palakkad, Court Road,\nPalakkad Post, Palakkad, Kerala, 678001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cd5"
    },
    "Rank": 5702,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saifee Hospital, ,postbox no 3771, 15-17 Maharshi Karve Marg, Charni road, Mumbai, Maharashtra, 400004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cd6"
    },
    "Rank": 5703,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cd7"
    },
    "Rank": 5704,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.G. Hospital and PG Medical Institute ,,No:5, Arts College Road, Coimbatore - 641018, Tamil Nadu, 641018",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cd8"
    },
    "Rank": 5705,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Swami Dayanand Hospital, ,Dilshad Garden, Shahdara, Delhi, Delhi (NCT), 110095",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cd9"
    },
    "Rank": 5706,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cda"
    },
    "Rank": 5708,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cdb"
    },
    "Rank": 5709,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cdc"
    },
    "Rank": 5710,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cdd"
    },
    "Rank": 5712,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lourdes Hospital, ,Ernakulam, Pachalam, Kochi, Kerala, 682012",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cde"
    },
    "Rank": 5713,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cdf"
    },
    "Rank": 5715,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ce0"
    },
    "Rank": 5716,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ce1"
    },
    "Rank": 5717,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences Koppal, Karnataka,Gangavati Road Kiddidal Gate koppal, Karnataka, 583231",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ce2"
    },
    "Rank": 5718,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Balaji Action Medical Institute,,FC34 A4 PASCHIM VIHAR NEW DELHI, Delhi (NCT), 110063",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ce3"
    },
    "Rank": 5719,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ce4"
    },
    "Rank": 5720,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ce5"
    },
    "Rank": 5723,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "P.D. Hinduja National Hospital and Medical Research Centre, ,Veer Savarkar Marg, Mahim, Maharashtra, 400016",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ce6"
    },
    "Rank": 5724,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ce7"
    },
    "Rank": 5726,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ce8"
    },
    "Rank": 5727,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ce9"
    },
    "Rank": 5728,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cea"
    },
    "Rank": 5729,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yatharth Superspeciality Hospitals,\nUttar Pradesh,HO-01, Sector-01, Greater Noida West, Uttar Pradesh, 201306",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ceb"
    },
    "Rank": 5732,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Balaji Action Medical Institute,,FC34 A4 PASCHIM VIHAR NEW DELHI, Delhi (NCT), 110063",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cec"
    },
    "Rank": 5733,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ced"
    },
    "Rank": 5734,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cee"
    },
    "Rank": 5735,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cef"
    },
    "Rank": 5736,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cf0"
    },
    "Rank": 5737,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cf1"
    },
    "Rank": 5738,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Telangana,Adjacent Keyes High School,  St Johns Road,Secunderabad, Telangana 500003, Telangana, 500003",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cf2"
    },
    "Rank": 5739,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cf3"
    },
    "Rank": 5740,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cf4"
    },
    "Rank": 5741,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cf5"
    },
    "Rank": 5743,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cf6"
    },
    "Rank": 5744,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cf7"
    },
    "Rank": 5746,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cf8"
    },
    "Rank": 5748,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cf9"
    },
    "Rank": 5749,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cfa"
    },
    "Rank": 5750,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Ram Manohar Lohia Institute of Medical Sciences, Vibhuti khand, ,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Vibhuti Khand, Gomti Nagar, Lucknow, Uttar Pradesh, 226010",
    "Course": "(NBEMS) NUCLEAR MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cfb"
    },
    "Rank": 5751,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Specialty Hospital, ,Lake View Road, KK. Nagar, Madurai -625020, Tamil Nadu, 625020",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cfc"
    },
    "Rank": 5752,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kalpana Chawla Govt Medical College, ,Model Town, Karnal, Haryana, 132001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cfd"
    },
    "Rank": 5753,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cfe"
    },
    "Rank": 5756,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Caritas Hospital, ,Thellakom P.O, Kerala, 686630",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03cff"
    },
    "Rank": 5757,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d00"
    },
    "Rank": 5758,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KIMS Al Shifa Hospital, ,KIMS Al Shifa Healthcare Pvt  Ltd.\nPB No 26 .\nOotty Road.\nPerinthalmanna, Malappuram district, Ke, Kerala, 679322",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d01"
    },
    "Rank": 5759,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d02"
    },
    "Rank": 5760,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d03"
    },
    "Rank": 5762,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d04"
    },
    "Rank": 5763,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d05"
    },
    "Rank": 5764,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d06"
    },
    "Rank": 5765,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d07"
    },
    "Rank": 5767,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d08"
    },
    "Rank": 5768,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d09"
    },
    "Rank": 5769,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d0a"
    },
    "Rank": 5770,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d0b"
    },
    "Rank": 5771,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d0c"
    },
    "Rank": 5772,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvarur Govt.Medical College,Thiruvarur Govt.Medical College, Tamil Nadu, 610004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d0d"
    },
    "Rank": 5774,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d0e"
    },
    "Rank": 5775,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d0f"
    },
    "Rank": 5776,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saifee Hospital, ,postbox no 3771, 15-17 Maharshi Karve Marg, Charni road, Mumbai, Maharashtra, 400004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d10"
    },
    "Rank": 5777,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d11"
    },
    "Rank": 5778,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d12"
    },
    "Rank": 5781,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. R. D. B. P. Jaipuria Hospital, (Attached with RUHS College of Medical Sciences),,GOVTRDBPJAIPURIAHOSPITALJAIPUR@GMAIL.COM, Rajasthan, 302018",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d13"
    },
    "Rank": 5783,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d14"
    },
    "Rank": 5785,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Thiruvannamalai Medical College, Thiruvannamalai, Tamil Nadu,DISTRICT COLLECTORATE  MASTER PLAN COMPLEX, VENGIKKAL THIRUVANNAMALAI,\nTamil Nadu, 600604",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d15"
    },
    "Rank": 5788,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d16"
    },
    "Rank": 5789,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d17"
    },
    "Rank": 5790,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "PBMA`S H V Desai Eye Hospital, ,93, Tarwade Vasti, Mohammadwadi, Pune, Maharashtra, 411060",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d18"
    },
    "Rank": 5791,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mata Chanan Devi Hospital,, ,C-1 JANAKPURI, Delhi (NCT), 110058",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d19"
    },
    "Rank": 5792,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d1a"
    },
    "Rank": 5793,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d1b"
    },
    "Rank": 5796,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lalitha Super Specialty Hospital, ,KOTHAPET GUNTUR, Andhra Pradesh, 522001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d1c"
    },
    "Rank": 5797,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d1d"
    },
    "Rank": 5799,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. TMA Pai Hospital, Udupi, Karnataka-576101,Opposite Old Taluk office, Court Road, Udupi, Karnataka, 576101",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d1e"
    },
    "Rank": 5801,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Gondia,Nehru Chowk, KTS General Hospital Campus, Govt. Medical College, Gondia. 441601. Maharashtra., Maharashtra, 441601",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d1f"
    },
    "Rank": 5804,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Sivagangai Medical College and Hospital, Tamil Nadu,GOVT.SIVAGANGAI MEDICAL COLLEGE, MELAVANIYANKUDY, MANAMADURAI ROAD, SIVAGANGAI.,\nTamil Nadu, 630561",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d20"
    },
    "Rank": 5807,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d21"
    },
    "Rank": 5808,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d22"
    },
    "Rank": 5809,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d23"
    },
    "Rank": 5810,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d24"
    },
    "Rank": 5811,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d25"
    },
    "Rank": 5812,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d26"
    },
    "Rank": 5813,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d27"
    },
    "Rank": 5814,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d28"
    },
    "Rank": 5817,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d29"
    },
    "Rank": 5818,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d2a"
    },
    "Rank": 5821,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Noble Hospital, ,Magarpatta City Road, Hadpsar, Pune, Maharashtra, 411028",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d2b"
    },
    "Rank": 5822,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d2c"
    },
    "Rank": 5823,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d2d"
    },
    "Rank": 5824,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d2e"
    },
    "Rank": 5827,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medical Trust Hospital, ,M.G. Road Pallimukku\nCochin, Kerala, 682016",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d2f"
    },
    "Rank": 5828,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d30"
    },
    "Rank": 5829,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d31"
    },
    "Rank": 5830,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d32"
    },
    "Rank": 5831,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d33"
    },
    "Rank": 5832,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d34"
    },
    "Rank": 5833,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "IQRAA International Hospital and Research Centre, ,IQRAA INTERNATIONAL HOSPITAL AND RESEARCH CENTRE MALAPARAMBA CALICUT, Kerala, 673009",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d35"
    },
    "Rank": 5834,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Sivagangai Medical College and Hospital, Tamil Nadu,GOVT.SIVAGANGAI MEDICAL COLLEGE, MELAVANIYANKUDY, MANAMADURAI ROAD, SIVAGANGAI.,\nTamil Nadu, 630561",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d36"
    },
    "Rank": 5835,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d37"
    },
    "Rank": 5836,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d38"
    },
    "Rank": 5842,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d39"
    },
    "Rank": 5843,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d3a"
    },
    "Rank": 5847,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d3b"
    },
    "Rank": 5848,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d3c"
    },
    "Rank": 5849,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d3d"
    },
    "Rank": 5850,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d3e"
    },
    "Rank": 5851,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d3f"
    },
    "Rank": 5852,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvarur Govt.Medical College,Thiruvarur Govt.Medical College, Tamil Nadu, 610004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d40"
    },
    "Rank": 5853,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital, ,GMERS Medical College and General hospital,Old TB Campus, gotri road, Vadodara, Gujarat, 390021",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d41"
    },
    "Rank": 5854,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d42"
    },
    "Rank": 5855,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Noble Hospital, ,Magarpatta City Road, Hadpsar, Pune, Maharashtra, 411028",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d43"
    },
    "Rank": 5856,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d44"
    },
    "Rank": 5857,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Noble Hospital, ,Magarpatta City Road, Hadpsar, Pune, Maharashtra, 411028",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d45"
    },
    "Rank": 5861,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d46"
    },
    "Rank": 5862,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d47"
    },
    "Rank": 5863,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Hedgewar Rugnalaya, ,Dr Hedgewar Rugnalaya Garkheda Aurangabad, Maharashtra, 431005",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d48"
    },
    "Rank": 5869,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d49"
    },
    "Rank": 5870,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d4a"
    },
    "Rank": 5871,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Hedgewar Rugnalaya, ,Dr Hedgewar Rugnalaya Garkheda Aurangabad, Maharashtra, 431005",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d4b"
    },
    "Rank": 5873,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d4c"
    },
    "Rank": 5874,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d4d"
    },
    "Rank": 5877,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Baba Saheb Ambedkar Hospital, ,SEC 6 ROHINI DELHI, Delhi (NCT), 110085",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d4e"
    },
    "Rank": 5878,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d4f"
    },
    "Rank": 5879,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d50"
    },
    "Rank": 5880,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster Medcity, Kuttisahib Road,,Aster Medcity Kuttisahib Road\nSouth Chittoor P.O. Cheranalloor\nKochi, Kerala, 682027",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d51"
    },
    "Rank": 5881,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d52"
    },
    "Rank": 5882,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d53"
    },
    "Rank": 5883,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d54"
    },
    "Rank": 5884,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sarvodaya Hospital and Research Centre, ,YMCA road Sector 08\nFaridabad, Haryana, 121006",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d55"
    },
    "Rank": 5885,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d56"
    },
    "Rank": 5887,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "College of Medicine and JNM Hospital,,Kalyani, Silpanchal, West Bengal, 741235",
    "Course": "(NBEMS) DERMATOLOGY and VENEREOLOGY and LEPROSY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d57"
    },
    "Rank": 5889,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d58"
    },
    "Rank": 5890,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College and  PGIMSR,ESIC Medical College and  PGIMSR, KK Nagar, Chennai - 600 078, Tamil Nadu, 600078",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d59"
    },
    "Rank": 5891,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d5a"
    },
    "Rank": 5893,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d5b"
    },
    "Rank": 5894,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kongunad Hospitals Private Limited, ,116,117 11th Street, Tatabad,Opposite to east power house, Tamil Nadu, 641012",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d5c"
    },
    "Rank": 5896,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SAL Hospital and Medical Institute,,NEAR TAKSHASHILA",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d5d"
    },
    "Rank": 5897,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Hurkisondas Nurrotumdas Hospital and Research Centre, ,Prarthana Samaj, Raja Ram Mohan Roy road,\nGirgaon, MUMBAI, Maharashtra, 400004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d5e"
    },
    "Rank": 5899,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Arvind Eye Hospital , ,Aravind Eye Hospital & Postgraduate Institute of Ophthalmology, Avinashi Road, Civil Aerodrome Post,, Tamil Nadu, 641014",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d5f"
    },
    "Rank": 5900,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d60"
    },
    "Rank": 5901,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d61"
    },
    "Rank": 5902,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d62"
    },
    "Rank": 5903,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d63"
    },
    "Rank": 5905,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K C General Hospital, ,No. 89, 5th Cross, Malleshwaram, Bangalore, Karnataka, 560003",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d64"
    },
    "Rank": 5906,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d65"
    },
    "Rank": 5907,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d66"
    },
    "Rank": 5908,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d67"
    },
    "Rank": 5909,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d68"
    },
    "Rank": 5911,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INSTITUTE OF MEDICAL SCIENCES,RAICHUR INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 584102",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d69"
    },
    "Rank": 5912,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d6a"
    },
    "Rank": 5915,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d6b"
    },
    "Rank": 5918,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College, Kolhapur,R.K. Nagar Road, Shenda Park, Kolhapur, Maharashtra, Maharashtra, 416012",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d6c"
    },
    "Rank": 5920,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d6d"
    },
    "Rank": 5921,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nizam`s Institute of Medical Sciences, ,NIZAMS INSTITUTE OF MEDICAL SCIENCES PANJAGUTTA HYDERABAD, Telangana, 500082",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d6e"
    },
    "Rank": 5922,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d6f"
    },
    "Rank": 5923,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d70"
    },
    "Rank": 5924,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d71"
    },
    "Rank": 5925,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVERNMENT MEDICAL COLLEGE,KANYAKUMARI GOVERNMENT MEDICAL COLLEGE, Tamil Nadu, 629201",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d72"
    },
    "Rank": 5926,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d73"
    },
    "Rank": 5928,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d74"
    },
    "Rank": 5929,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kokilaben Dhirubhai Ambani Hospital and Medical Research Institute,,Achyutrao Patwardhan Marg Andheri West Mumbai Maharashtra, Maharashtra, 400053",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d75"
    },
    "Rank": 5931,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d76"
    },
    "Rank": 5932,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d77"
    },
    "Rank": 5934,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhabha Atomic Research Centre and Hospital, ,Anushaktinagar, Mumbai, Maharashtra, 400094",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d78"
    },
    "Rank": 5935,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d79"
    },
    "Rank": 5936,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d7a"
    },
    "Rank": 5939,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d7b"
    },
    "Rank": 5940,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d7c"
    },
    "Rank": 5941,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d7d"
    },
    "Rank": 5942,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chittaranjan Seva Sadan Hospital,37, S.P. MUKHERJEE ROAD, KOLKATA, West Bengal, 700026",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d7e"
    },
    "Rank": 5943,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d7f"
    },
    "Rank": 5946,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d80"
    },
    "Rank": 5947,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d81"
    },
    "Rank": 5948,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d82"
    },
    "Rank": 5950,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Cross Hospital, ,Holy Cross Hospital, Kottiyam P O, Kollam District, Kerala, 691571",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d83"
    },
    "Rank": 5951,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d84"
    },
    "Rank": 5953,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bethany Hospital, ,Pokhran road No. 2, Thane West, Maharashtra, 400610",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d85"
    },
    "Rank": 5955,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gvernment Medical College,Haldwani,Government Medical College, Haldwani, Uttarakhand, 263139",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d86"
    },
    "Rank": 5958,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Caritas Hospital, ,Thellakom P.O, Kerala, 686630",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d87"
    },
    "Rank": 5959,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d88"
    },
    "Rank": 5960,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d89"
    },
    "Rank": 5961,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d8a"
    },
    "Rank": 5962,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d8b"
    },
    "Rank": 5964,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agrasen Medical College,,NH- 9, VPO - Agroha, District Hisar, Haryana, 125047",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d8c"
    },
    "Rank": 5966,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d8d"
    },
    "Rank": 5967,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pimpri Chichwad Municipal Corporation Postgraduate Institute Y.C.M.Hospital Pimpri,Pune 18,Dean Office, Y.C.M.Hospital, Sant Tukaramnagar Pimpri, Pune, Maharashtra, 411018",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d8e"
    },
    "Rank": 5970,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d8f"
    },
    "Rank": 5971,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d90"
    },
    "Rank": 5973,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d91"
    },
    "Rank": 5974,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d92"
    },
    "Rank": 5975,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d93"
    },
    "Rank": 5976,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "B.R. Singh Hospital and Centre for Medical Education and Research,,B.R.Singh Hospital Eastern Railway, Sealdah, Kolkata, West Bengal, 700014",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d94"
    },
    "Rank": 5977,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vijaya Hospital, ,New No 434, Old No 180, NSK Salai, Vadapalani, Chennai - 600026, Tamil Nadu, 600026",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d95"
    },
    "Rank": 5978,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d96"
    },
    "Rank": 5979,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Metas Adventist Hospital, ,PB NO. 24, OPP KP COMMERCE COLLEGE, ATHWALINES,\nGujarat, 395001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d97"
    },
    "Rank": 5980,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jalgaon,Jilha Peth, Government Hospital Campus, Jalgaon, Maharashtra, 425001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d98"
    },
    "Rank": 5981,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d99"
    },
    "Rank": 5982,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d9a"
    },
    "Rank": 5983,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d9b"
    },
    "Rank": 5985,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d9c"
    },
    "Rank": 5986,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM New Bombay Hospital,,Plot 35, Sector 3, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d9d"
    },
    "Rank": 5987,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d9e"
    },
    "Rank": 5990,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03d9f"
    },
    "Rank": 5991,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VILLUPURAM MEDICAL COLLEGE, VILLUPURAM,GOVERNMENT VILLUPURAM MEDICAL COLLEGE VILLUPURAM  TRICHY TRUNK ROAD MUNDIYAMPAKKAM  VILLUPURAM DISTR, Tamil Nadu, 605601",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03da0"
    },
    "Rank": 5992,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster Medcity, Kuttisahib Road,,Aster Medcity Kuttisahib Road\nSouth Chittoor P.O. Cheranalloor\nKochi, Kerala, 682027",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03da1"
    },
    "Rank": 5993,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Noble Hospital, ,Magarpatta City Road, Hadpsar, Pune, Maharashtra, 411028",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03da2"
    },
    "Rank": 5994,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03da3"
    },
    "Rank": 5995,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03da4"
    },
    "Rank": 5996,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03da5"
    },
    "Rank": 5997,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03da6"
    },
    "Rank": 5998,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Medical College and Chhatrapati Shivaji Maharaj Hospital, ,RAJIV GANDHI MEDICAL COLLEGE,THANE BELAPUR ROAD, KALWA, THANE, Maharashtra, 400605",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03da7"
    },
    "Rank": 6001,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03da8"
    },
    "Rank": 6002,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03da9"
    },
    "Rank": 6004,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03daa"
    },
    "Rank": 6006,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dab"
    },
    "Rank": 6008,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vadamalayan Hospital,,Registered Office - 15, Jawahar Road, Chokkikulam, Madurai - 625002.\n9A, Vallabhai Road, Chokkikula, Tamil Nadu, 625002",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dac"
    },
    "Rank": 6009,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Medical College and Chhatrapati Shivaji Maharaj Hospital, ,RAJIV GANDHI MEDICAL COLLEGE,THANE BELAPUR ROAD, KALWA, THANE, Maharashtra, 400605",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dad"
    },
    "Rank": 6010,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dae"
    },
    "Rank": 6011,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03daf"
    },
    "Rank": 6013,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03db0"
    },
    "Rank": 6014,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03db1"
    },
    "Rank": 6015,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sagar Hospital, Karnataka,No. 44/54, 30th Cross, Tilak Nagar, Jayanagar Extn., Bangalore, Karnataka-560041",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03db2"
    },
    "Rank": 6019,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03db3"
    },
    "Rank": 6021,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03db4"
    },
    "Rank": 6023,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03db5"
    },
    "Rank": 6024,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "HCG Hospital,,\n1, Gujarat,1, Maharashtra Society, Mithakhali Six Road, Ellisbridge, Ahmedabad, Gujarat 380006, Gujarat, 380006",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03db6"
    },
    "Rank": 6026,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03db7"
    },
    "Rank": 6027,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vijaya Hospital, ,New No 434, Old No 180, NSK Salai, Vadapalani, Chennai - 600026, Tamil Nadu, 600026",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03db8"
    },
    "Rank": 6030,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03db9"
    },
    "Rank": 6031,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dba"
    },
    "Rank": 6032,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dbb"
    },
    "Rank": 6034,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajagiri Hospital,Rajagiri Hospital, Chunagamvely, Aluva 683112, Kerala, 683112",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dbc"
    },
    "Rank": 6035,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dbd"
    },
    "Rank": 6037,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dbe"
    },
    "Rank": 6038,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dbf"
    },
    "Rank": 6040,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dc0"
    },
    "Rank": 6041,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rabindranath Tagore International Institute of Cardiac Sciences, ,PREMISES NO. 1489 124 MUKUNDAPUR  E M BYPASS  KOLKATA-700099, West Bengal, 700099",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dc1"
    },
    "Rank": 6042,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dc2"
    },
    "Rank": 6043,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Family Hospital,,Okhla Road, New Delhi, Delhi (NCT), 110025",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dc3"
    },
    "Rank": 6044,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dc4"
    },
    "Rank": 6047,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,APOLLO HOSPITALS BANGALORE A UNIT OF IMPERIAL HOSPITALS AND RESEARCH CENTRE LIMITED 154/11 Opp IIM B, Karnataka, 560076",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dc5"
    },
    "Rank": 6048,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dc6"
    },
    "Rank": 6049,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dc7"
    },
    "Rank": 6050,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Superspecialty Hospital,,Junction of 2nd Hoogly Bridge & Andul Road, 120/1 Andul Road, West Bengal, 711103",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dc8"
    },
    "Rank": 6051,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "N S Memorial Institute of Medical Sciences ,N S Memorial Institute of Medical Sciences Palathara, Thattamala, Kollam, Kerala, 691020",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dc9"
    },
    "Rank": 6052,
    "Allotted Quota": "All India",
    "Allotted Institute": "Post Graduate Institute of Medical Education & Research and Capital Hospital ( PGIMER & CH), Odisha,Post Graduate Institute of Medical Education & Research and Capital Hospital, Bhubaneswar,Udyan Marg, Odisha, 751001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dca"
    },
    "Rank": 6053,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajagiri Hospital,Rajagiri Hospital, Chunagamvely, Aluva 683112, Kerala, 683112",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dcb"
    },
    "Rank": 6054,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dcc"
    },
    "Rank": 6055,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dcd"
    },
    "Rank": 6056,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dce"
    },
    "Rank": 6057,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubnagar,Government Medical College, Yedira, Mahabubnagar, Telangana state, Telangana, 509002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dcf"
    },
    "Rank": 6058,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dd0"
    },
    "Rank": 6059,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dd1"
    },
    "Rank": 6061,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dd2"
    },
    "Rank": 6062,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medical Trust Hospital, ,M.G. Road Pallimukku\nCochin, Kerala, 682016",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dd3"
    },
    "Rank": 6064,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indira Gandhi Medical College and Research Institute, Pondicherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dd4"
    },
    "Rank": 6067,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dd5"
    },
    "Rank": 6068,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dd6"
    },
    "Rank": 6069,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS) Plastic and Reconstructive Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dd7"
    },
    "Rank": 6072,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dd8"
    },
    "Rank": 6075,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dd9"
    },
    "Rank": 6076,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dda"
    },
    "Rank": 6077,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ddb"
    },
    "Rank": 6079,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ddc"
    },
    "Rank": 6082,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vijaya Hospital, ,New No 434, Old No 180, NSK Salai, Vadapalani, Chennai - 600026, Tamil Nadu, 600026",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ddd"
    },
    "Rank": 6083,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dde"
    },
    "Rank": 6084,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ddf"
    },
    "Rank": 6085,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Noble Hospital, ,Magarpatta City Road, Hadpsar, Pune, Maharashtra, 411028",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03de0"
    },
    "Rank": 6088,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03de1"
    },
    "Rank": 6089,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03de2"
    },
    "Rank": 6091,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Artemis Health Institute, ,Sector 51, Gurugram, Haryana -122001, Haryana, 122001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03de3"
    },
    "Rank": 6092,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03de4"
    },
    "Rank": 6093,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03de5"
    },
    "Rank": 6094,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03de6"
    },
    "Rank": 6095,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indraprastha Apollo Hospital,,Sarita Vihar, New Delhi 110 076, Delhi (NCT), 110076",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03de7"
    },
    "Rank": 6097,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03de8"
    },
    "Rank": 6098,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03de9"
    },
    "Rank": 6099,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dea"
    },
    "Rank": 6100,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03deb"
    },
    "Rank": 6101,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dec"
    },
    "Rank": 6103,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vijaya Hospital, ,New No 434, Old No 180, NSK Salai, Vadapalani, Chennai - 600026, Tamil Nadu, 600026",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ded"
    },
    "Rank": 6105,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dee"
    },
    "Rank": 6106,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03def"
    },
    "Rank": 6107,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K B Bhabha Municipal General Hospital, ,R K PATKAR ROAD ,BANDRA WEST\n,MUMBAI, Maharashtra, 400050",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03df0"
    },
    "Rank": 6110,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03df1"
    },
    "Rank": 6111,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03df2"
    },
    "Rank": 6113,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03df3"
    },
    "Rank": 6114,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03df4"
    },
    "Rank": 6115,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03df5"
    },
    "Rank": 6116,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03df6"
    },
    "Rank": 6118,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KIMS Al Shifa Hospital, ,KIMS Al Shifa Healthcare Pvt  Ltd.\nPB No 26 .\nOotty Road.\nPerinthalmanna, Malappuram district, Ke, Kerala, 679322",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03df7"
    },
    "Rank": 6120,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03df8"
    },
    "Rank": 6121,
    "Allotted Quota": "All India",
    "Allotted Institute": "NSC Government Medical College, Khandwa (MP),LUV KUSH NAGAR,MUNDI NAGAR, KHANDWA, Madhya Pradesh, 450001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03df9"
    },
    "Rank": 6123,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dfa"
    },
    "Rank": 6127,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dfb"
    },
    "Rank": 6129,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chacha Nehru Bal Chikitsalaya,Geeta Colony, Delhi (NCT), 110031",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dfc"
    },
    "Rank": 6131,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dfd"
    },
    "Rank": 6132,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dfe"
    },
    "Rank": 6133,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03dff"
    },
    "Rank": 6134,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College, ,Office of the Dean, First Floor, Civil Hospital Campus, Sector- 12, Gandhinagar, Gujarat, 382016",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e00"
    },
    "Rank": 6136,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e01"
    },
    "Rank": 6137,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e02"
    },
    "Rank": 6139,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e03"
    },
    "Rank": 6140,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e04"
    },
    "Rank": 6141,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Cross Hospital, ,Holy Cross Hospital, Kottiyam P O, Kollam District, Kerala, 691571",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e05"
    },
    "Rank": 6142,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Hedgewar Rugnalaya, ,Dr Hedgewar Rugnalaya Garkheda Aurangabad, Maharashtra, 431005",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e06"
    },
    "Rank": 6144,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e07"
    },
    "Rank": 6147,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Karnataka,Kodagu Institute of Medical Sciences Madikeri Kodagu, Karnataka, 571201",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e08"
    },
    "Rank": 6148,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Datia,Near 29 Batallion, National Highway 44, Dist Datia, Madhya Pradesh Pin - 475661, Madhya Pradesh, 475661",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e09"
    },
    "Rank": 6149,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e0a"
    },
    "Rank": 6150,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Family Hospital,,Okhla Road, New Delhi, Delhi (NCT), 110025",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e0b"
    },
    "Rank": 6151,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e0c"
    },
    "Rank": 6153,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Kasturba Hospital, Daryaganj,Delhi,Daryaganj,Delhi",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e0d"
    },
    "Rank": 6154,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e0e"
    },
    "Rank": 6156,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC MEDICAL COLLEGE, JOKA,ESI-PGIMSR & ESIC MEDICAL COLLEGE, JOKA, KOLKATA, WEST BENGAL, West Bengal, 700104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e0f"
    },
    "Rank": 6157,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e10"
    },
    "Rank": 6158,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e11"
    },
    "Rank": 6160,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bethany Hospital, ,Pokhran road No. 2, Thane West, Maharashtra, 400610",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e12"
    },
    "Rank": 6161,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e13"
    },
    "Rank": 6162,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e14"
    },
    "Rank": 6163,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster CMI Hospital, ,43/2 New Airport Road, NH-7, Kodigehalli, Sahakara Nagar, Hebbal, Bengaluru, Karnataka, 560092",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e15"
    },
    "Rank": 6165,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e16"
    },
    "Rank": 6166,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SETH V. C. GANDHI and M. A. VORA MUNICIPAL GENERAL HOSPITAL (RAJAWADI HOSPITAL),\nMaharashtra,hospital.rajawadi@gmail.com, Maharashtra, 400077",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e17"
    },
    "Rank": 6167,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital, ,GMERS Medical College and General hospital,Old TB Campus, gotri road, Vadodara, Gujarat, 390021",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e18"
    },
    "Rank": 6168,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e19"
    },
    "Rank": 6169,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e1a"
    },
    "Rank": 6170,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharashtra Post Graduate Institute of Medical Education and Research, Nashik,Maharashtra Post Graduation Institute Of Medical Education and Research Civil Hospital campus Trimba, Maharashtra, 422002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e1b"
    },
    "Rank": 6171,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e1c"
    },
    "Rank": 6172,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital,,Malsi, Mussoorie Diversion Road, Uttarakhand, 248001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e1d"
    },
    "Rank": 6173,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e1e"
    },
    "Rank": 6174,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S. K. S. Hospital, ,NO.23,SKS Hospital Road, Alagapuram,Salem, Tamil Nadu, 636004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e1f"
    },
    "Rank": 6176,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Radhakrishnan Govt. Medical College , ,Dr. Radhakrishnan Govt. Medical College Hamirpur HP, Himachal Pradesh, 177001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e20"
    },
    "Rank": 6177,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e21"
    },
    "Rank": 6178,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Datia,Near 29 Batallion, National Highway 44, Dist Datia, Madhya Pradesh Pin - 475661, Madhya Pradesh, 475661",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e22"
    },
    "Rank": 6179,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Bhausaheb Hire Government Medical College,Sri Bhausaheb Hire Government Medical College,Dhule, Maharashtra, 424311",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e23"
    },
    "Rank": 6181,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e24"
    },
    "Rank": 6182,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e25"
    },
    "Rank": 6183,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e26"
    },
    "Rank": 6187,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e27"
    },
    "Rank": 6188,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e28"
    },
    "Rank": 6191,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e29"
    },
    "Rank": 6193,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e2a"
    },
    "Rank": 6195,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e2b"
    },
    "Rank": 6196,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e2c"
    },
    "Rank": 6197,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "DIP.IN MEDICAL RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e2d"
    },
    "Rank": 6198,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KIMS Al Shifa Hospital, ,KIMS Al Shifa Healthcare Pvt  Ltd.\nPB No 26 .\nOotty Road.\nPerinthalmanna, Malappuram district, Ke, Kerala, 679322",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e2e"
    },
    "Rank": 6200,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e2f"
    },
    "Rank": 6201,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e30"
    },
    "Rank": 6202,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e31"
    },
    "Rank": 6203,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e32"
    },
    "Rank": 6204,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e33"
    },
    "Rank": 6206,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e34"
    },
    "Rank": 6207,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e35"
    },
    "Rank": 6208,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e36"
    },
    "Rank": 6210,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Little Flower Hospital and Research Centre,,Post Box 23, Angamaly, Ernakulam, Kerala, Kerala, 683572",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e37"
    },
    "Rank": 6211,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharashtra Post Graduate Institute of Medical Education and Research, Nashik,Maharashtra Post Graduation Institute Of Medical Education and Research Civil Hospital campus Trimba, Maharashtra, 422002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e38"
    },
    "Rank": 6214,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e39"
    },
    "Rank": 6217,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e3a"
    },
    "Rank": 6218,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e3b"
    },
    "Rank": 6219,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e3c"
    },
    "Rank": 6221,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e3d"
    },
    "Rank": 6223,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e3e"
    },
    "Rank": 6225,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e3f"
    },
    "Rank": 6226,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e40"
    },
    "Rank": 6227,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e41"
    },
    "Rank": 6228,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e42"
    },
    "Rank": 6230,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e43"
    },
    "Rank": 6231,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e44"
    },
    "Rank": 6233,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e45"
    },
    "Rank": 6234,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Surya Children`s Medicare, (Formerly Surya Children`s Hospital) ,S. V. Road, Santacruz West Mumbai, Maharashtra, 400054",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e46"
    },
    "Rank": 6240,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e47"
    },
    "Rank": 6241,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e48"
    },
    "Rank": 6242,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e49"
    },
    "Rank": 6243,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e4a"
    },
    "Rank": 6244,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e4b"
    },
    "Rank": 6245,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e4c"
    },
    "Rank": 6246,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "HINDU MISSION HOSPITAL,\n,103 G S T Road Tambaram West Chennai, Tamil Nadu, 600045",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e4d"
    },
    "Rank": 6249,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e4e"
    },
    "Rank": 6251,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e4f"
    },
    "Rank": 6253,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e50"
    },
    "Rank": 6254,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e51"
    },
    "Rank": 6255,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e52"
    },
    "Rank": 6256,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Metro Heart Institute with Multispecialty, ,SECTOR 16A, Faridabad, Haryana, Haryana, 121002",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e53"
    },
    "Rank": 6257,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e54"
    },
    "Rank": 6258,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Zen Multispeciality Hospital, Maharashtra,425, 10th Road, Chembur, Mumbai, Maharashtra, 400071",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e55"
    },
    "Rank": 6259,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e56"
    },
    "Rank": 6260,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e57"
    },
    "Rank": 6261,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e58"
    },
    "Rank": 6262,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e59"
    },
    "Rank": 6263,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital, ,NO 1 RAMAMOORTHY ROAD VIRUDHUNAGAR, Tamil Nadu, 626001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e5a"
    },
    "Rank": 6264,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e5b"
    },
    "Rank": 6265,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e5c"
    },
    "Rank": 6266,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM New Bombay Hospital,,Plot 35, Sector 3, Vashi, Navi Mumbai, Maharashtra, 400703",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e5d"
    },
    "Rank": 6268,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e5e"
    },
    "Rank": 6269,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e5f"
    },
    "Rank": 6272,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e60"
    },
    "Rank": 6273,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e61"
    },
    "Rank": 6274,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e62"
    },
    "Rank": 6276,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e63"
    },
    "Rank": 6277,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e64"
    },
    "Rank": 6278,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aakash Healthcare Super Specialty Hospital, Delhi,HOSPITAL PLOT, ROAD NO- 201,SECTOR 3 DWARKA, Delhi (NCT), 110075",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e65"
    },
    "Rank": 6279,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e66"
    },
    "Rank": 6281,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e67"
    },
    "Rank": 6282,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e68"
    },
    "Rank": 6283,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster CMI Hospital, ,43/2 New Airport Road, NH-7, Kodigehalli, Sahakara Nagar, Hebbal, Bengaluru, Karnataka, 560092",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e69"
    },
    "Rank": 6284,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e6a"
    },
    "Rank": 6285,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Bhausaheb Hire Government Medical College,Sri Bhausaheb Hire Government Medical College,Dhule, Maharashtra, 424311",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e6b"
    },
    "Rank": 6287,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e6c"
    },
    "Rank": 6288,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e6d"
    },
    "Rank": 6289,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e6e"
    },
    "Rank": 6291,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e6f"
    },
    "Rank": 6292,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e70"
    },
    "Rank": 6293,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e71"
    },
    "Rank": 6295,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e72"
    },
    "Rank": 6296,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e73"
    },
    "Rank": 6298,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e74"
    },
    "Rank": 6299,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e75"
    },
    "Rank": 6300,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e76"
    },
    "Rank": 6301,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lilavati Hospital and Research Centre, ,Lilavati Hospital And Research Centre A-791, Bandra Reclamation, Bandra - West, Mumbai, Maharashtra, 400050",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e77"
    },
    "Rank": 6302,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Continental Hospital, ,Continental Hospitals Pvt Ltd., Plot No.3, Road No.2, IT & Financial District, Nanakramguda, Gachibo, Telangana, 500032",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e78"
    },
    "Rank": 6304,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e79"
    },
    "Rank": 6306,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e7a"
    },
    "Rank": 6308,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jupiter Lifeline Hospital,,Jupiter Lifeline Hospital, Sr No 142/12, And 143/1/4/5, Near Prathamesh Park, Baner, Pune, Maharasht, Maharashtra, 411045",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e7b"
    },
    "Rank": 6309,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Haria L G Rotary Hospital, ,PLOT NO.363\\1 & 364 HOUSING SECTOR GIDC VAPI GUJARAT 396195, Gujarat, 396195",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e7c"
    },
    "Rank": 6310,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Artemis Health Institute, ,Sector 51, Gurugram, Haryana -122001, Haryana, 122001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e7d"
    },
    "Rank": 6311,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e7e"
    },
    "Rank": 6313,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e7f"
    },
    "Rank": 6315,
    "Allotted Quota": "All India",
    "Allotted Institute": "Post Graduate Institute of Medical Education & Research and Capital Hospital ( PGIMER & CH), Odisha,Post Graduate Institute of Medical Education & Research and Capital Hospital, Bhubaneswar,Udyan Marg, Odisha, 751001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e80"
    },
    "Rank": 6316,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e81"
    },
    "Rank": 6318,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e82"
    },
    "Rank": 6320,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e83"
    },
    "Rank": 6321,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e84"
    },
    "Rank": 6322,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e85"
    },
    "Rank": 6323,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e86"
    },
    "Rank": 6324,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e87"
    },
    "Rank": 6326,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e88"
    },
    "Rank": 6327,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e89"
    },
    "Rank": 6328,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e8a"
    },
    "Rank": 6330,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e8b"
    },
    "Rank": 6331,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Daya General Hospital,Daya General Hospital Limited Shornur Road NEar Viyyur bridge\nThiruvambady PO Thrissur, Kerala, 680022",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e8c"
    },
    "Rank": 6332,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e8d"
    },
    "Rank": 6333,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e8e"
    },
    "Rank": 6335,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e8f"
    },
    "Rank": 6337,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e90"
    },
    "Rank": 6338,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e91"
    },
    "Rank": 6341,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e92"
    },
    "Rank": 6342,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e93"
    },
    "Rank": 6343,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e94"
    },
    "Rank": 6346,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e95"
    },
    "Rank": 6347,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital, ,NO 1 RAMAMOORTHY ROAD VIRUDHUNAGAR, Tamil Nadu, 626001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e96"
    },
    "Rank": 6348,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. GERIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e97"
    },
    "Rank": 6349,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e98"
    },
    "Rank": 6350,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e99"
    },
    "Rank": 6351,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Choithram Hospital and Research Centre,Manika Bagh Road Indore, Madhya Pradesh, 452014",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e9a"
    },
    "Rank": 6352,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e9b"
    },
    "Rank": 6353,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e9c"
    },
    "Rank": 6354,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e9d"
    },
    "Rank": 6355,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e9e"
    },
    "Rank": 6356,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03e9f"
    },
    "Rank": 6357,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ea0"
    },
    "Rank": 6358,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ea1"
    },
    "Rank": 6359,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ea2"
    },
    "Rank": 6361,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ea3"
    },
    "Rank": 6362,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ea4"
    },
    "Rank": 6363,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ea5"
    },
    "Rank": 6366,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ea6"
    },
    "Rank": 6368,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS Medical College Valsad,Nanakwada Halar Road,Valsad ,Gujarat, Gujarat, 396001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ea7"
    },
    "Rank": 6369,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ea8"
    },
    "Rank": 6370,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tirath Ram Shah Hospital,,2A, RBL Isher Das Sawhney Marg, Rajpur Road,, Delhi (NCT), 110054",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ea9"
    },
    "Rank": 6371,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sakra World Hospital, (A Unit of Takshasila Hospitals Operating Private Limited)\n,LOVEKESH\\DOT\\PHASU\\AT\\SAKRAWORLDHOSPITAL\\DOT\\COM, Karnataka, 560103",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03eaa"
    },
    "Rank": 6373,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03eab"
    },
    "Rank": 6374,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03eac"
    },
    "Rank": 6375,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ead"
    },
    "Rank": 6376,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03eae"
    },
    "Rank": 6377,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03eaf"
    },
    "Rank": 6379,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03eb0"
    },
    "Rank": 6380,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03eb1"
    },
    "Rank": 6381,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03eb2"
    },
    "Rank": 6384,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03eb3"
    },
    "Rank": 6385,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03eb4"
    },
    "Rank": 6386,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ramkrishna Care Hospital, ,AUROBINDO ENCLAVE, PACHPEDI NAKA,DAHAMTARI ROAD, RAIPUR, Chhattisgarh, 492001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03eb5"
    },
    "Rank": 6387,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03eb6"
    },
    "Rank": 6388,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03eb7"
    },
    "Rank": 6391,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03eb8"
    },
    "Rank": 6392,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ernakulam Medical Centre,,ERNAKULAM MEDICAL CENTRE NH BYPASS, KOCHI, KERALA, INDIA - 682028, Kerala, 682028",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03eb9"
    },
    "Rank": 6394,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03eba"
    },
    "Rank": 6397,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ebb"
    },
    "Rank": 6399,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ebc"
    },
    "Rank": 6401,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ebd"
    },
    "Rank": 6403,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ebe"
    },
    "Rank": 6405,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Southern Railway Head Quarter Hospital, ,Ayanavaram, Tamil Nadu, 600023",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ebf"
    },
    "Rank": 6406,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ec0"
    },
    "Rank": 6409,
    "Allotted Quota": "All India",
    "Allotted Institute": "Post Graduate Institute of Medical Education & Research and Capital Hospital ( PGIMER & CH), Odisha,Post Graduate Institute of Medical Education & Research and Capital Hospital, Bhubaneswar,Udyan Marg, Odisha, 751001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ec1"
    },
    "Rank": 6411,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Meenakshi Mission Hospital and Research Centre, ,Lake Area, Melur Road, Madurai, Tamil Nadu, 625107",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ec2"
    },
    "Rank": 6412,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ec3"
    },
    "Rank": 6413,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ec4"
    },
    "Rank": 6414,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ec5"
    },
    "Rank": 6415,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ec6"
    },
    "Rank": 6419,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ec7"
    },
    "Rank": 6420,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ec8"
    },
    "Rank": 6421,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital,\n,paddoc road, near majewadi gate, junagadh, Gujarat, 362001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ec9"
    },
    "Rank": 6422,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03eca"
    },
    "Rank": 6424,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ecb"
    },
    "Rank": 6425,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ecc"
    },
    "Rank": 6426,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ecd"
    },
    "Rank": 6427,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ece"
    },
    "Rank": 6428,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ecf"
    },
    "Rank": 6429,
    "Allotted Quota": "All India",
    "Allotted Institute": "Calcutta School Of Tropical Medicine,Calcutta School Of Tropical Medicine, West Bengal, 700073",
    "Course": "M.D. (TROPICAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ed0"
    },
    "Rank": 6430,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ed1"
    },
    "Rank": 6431,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ed2"
    },
    "Rank": 6432,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ed3"
    },
    "Rank": 6433,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lilavati Hospital and Research Centre, ,Lilavati Hospital And Research Centre A-791, Bandra Reclamation, Bandra - West, Mumbai, Maharashtra, 400050",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ed4"
    },
    "Rank": 6434,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ed5"
    },
    "Rank": 6435,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital,Ms. Hilda Sujatha Pr no 3-5-836, to 838 , Near Old MLA quarters, Hyderguda, Hyderabad, Telangana, 500029",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ed6"
    },
    "Rank": 6436,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ed7"
    },
    "Rank": 6440,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ed8"
    },
    "Rank": 6441,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ed9"
    },
    "Rank": 6443,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03eda"
    },
    "Rank": 6445,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03edb"
    },
    "Rank": 6447,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03edc"
    },
    "Rank": 6449,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Rangarajan Memorial Hospital, (Sundaram Medical Foundation) ,Sundaram Medical Foundation, Shanthi Colony, 4th Avenue, Anna Nagar, Chennai, Tamil Nadu, 600040",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03edd"
    },
    "Rank": 6451,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ede"
    },
    "Rank": 6453,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Bhausaheb Hire Government Medical College,Sri Bhausaheb Hire Government Medical College,Dhule, Maharashtra, 424311",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03edf"
    },
    "Rank": 6455,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Adlux Hospital ( A Unit of Adlux Medicity and Convention Centre Private Limited), Kerala\n,Apollo Adlux Hospital (A Unit of Adlux medicity and Convention Centre Private limited), Adlux juncti, Kerala, 683576",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ee0"
    },
    "Rank": 6456,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ee1"
    },
    "Rank": 6457,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ee2"
    },
    "Rank": 6458,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ee3"
    },
    "Rank": 6459,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Cosmopolitan Hospital,,COSMOPOLITAN HOSPITALS Pvt.Ltd, Murinjapalam, Pattom P O, Thiruvananthapuram, Kerala, 695004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ee4"
    },
    "Rank": 6460,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ee5"
    },
    "Rank": 6461,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,LOTUS TOWER, G S ROAD, GUWAHATI, Assam, 781005",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ee6"
    },
    "Rank": 6462,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ee7"
    },
    "Rank": 6463,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagwan Mahaveer Jain Hospital, ,MILLERS ROAD, VASANTHNAGAR, BANGALORE, Karnataka, 560052",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ee8"
    },
    "Rank": 6464,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ee9"
    },
    "Rank": 6465,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03eea"
    },
    "Rank": 6466,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03eeb"
    },
    "Rank": 6467,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03eec"
    },
    "Rank": 6468,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03eed"
    },
    "Rank": 6470,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03eee"
    },
    "Rank": 6471,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03eef"
    },
    "Rank": 6473,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ef0"
    },
    "Rank": 6474,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ef1"
    },
    "Rank": 6475,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mumbai Port Trust Hospital, ,L.M. Nadkarni Marg, Wadala, Mumbai, Maharashtra, 400037",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ef2"
    },
    "Rank": 6476,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ef3"
    },
    "Rank": 6477,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ef4"
    },
    "Rank": 6481,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ef5"
    },
    "Rank": 6482,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ef6"
    },
    "Rank": 6483,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ef7"
    },
    "Rank": 6485,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ef8"
    },
    "Rank": 6488,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ef9"
    },
    "Rank": 6490,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College and  PGIMSR,ESIC Medical College and  PGIMSR, KK Nagar, Chennai - 600 078, Tamil Nadu, 600078",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03efa"
    },
    "Rank": 6491,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Continental Hospital, ,Continental Hospitals Pvt Ltd., Plot No.3, Road No.2, IT & Financial District, Nanakramguda, Gachibo, Telangana, 500032",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03efb"
    },
    "Rank": 6494,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03efc"
    },
    "Rank": 6498,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03efd"
    },
    "Rank": 6500,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03efe"
    },
    "Rank": 6501,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03eff"
    },
    "Rank": 6502,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f00"
    },
    "Rank": 6503,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f01"
    },
    "Rank": 6507,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC MEDICAL COLLEGE, JOKA,ESI-PGIMSR & ESIC MEDICAL COLLEGE, JOKA, KOLKATA, WEST BENGAL, West Bengal, 700104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f02"
    },
    "Rank": 6508,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f03"
    },
    "Rank": 6509,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f04"
    },
    "Rank": 6510,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f05"
    },
    "Rank": 6511,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f06"
    },
    "Rank": 6513,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S. K. S. Hospital, ,NO.23,SKS Hospital Road, Alagapuram,Salem, Tamil Nadu, 636004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f07"
    },
    "Rank": 6515,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Cosmopolitan Hospital,,COSMOPOLITAN HOSPITALS Pvt.Ltd, Murinjapalam, Pattom P O, Thiruvananthapuram, Kerala, 695004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f08"
    },
    "Rank": 6517,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VELLORE MEDICAL COLLEGE,GOVERNMENT VELLORE MEDICAL COLLEGE, Tamil Nadu, 632011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f09"
    },
    "Rank": 6518,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f0a"
    },
    "Rank": 6520,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f0b"
    },
    "Rank": 6521,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f0c"
    },
    "Rank": 6523,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f0d"
    },
    "Rank": 6525,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VELLORE MEDICAL COLLEGE,GOVERNMENT VELLORE MEDICAL COLLEGE, Tamil Nadu, 632011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f0e"
    },
    "Rank": 6526,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f0f"
    },
    "Rank": 6527,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f10"
    },
    "Rank": 6529,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f11"
    },
    "Rank": 6530,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f12"
    },
    "Rank": 6531,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f13"
    },
    "Rank": 6532,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f14"
    },
    "Rank": 6534,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f15"
    },
    "Rank": 6535,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Escorts Hospital, ,214, Jawahar Lal Nehru Marg, Malviya Nagar, Jaipur, Rajasthan, 302017",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f16"
    },
    "Rank": 6536,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f17"
    },
    "Rank": 6537,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f18"
    },
    "Rank": 6539,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,SECTOR-6, PANCHKULA, HARYANA-134108, Haryana, 134108",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f19"
    },
    "Rank": 6540,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESIC Model Hospital and Occupational Disease Centre,,ESIC MODEL HOSPITAL AND ODC NANDA NAGAR INDORE, Madhya Pradesh, 452011",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f1a"
    },
    "Rank": 6541,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Doon Medical College, Dehradun,Govt. Doon Medical College Dehrakhas, Patel Nagar Dehradun 248001, Uttarakhand, 248001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f1b"
    },
    "Rank": 6542,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Poonam Palaspagar - Academic Coordinator\nFortis Hospital, Bannerghatta Road, Bangalore, Karnataka 5, Karnataka, 560076",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f1c"
    },
    "Rank": 6543,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, (Formerly Columbia Asia Hospital),Survey No. 10P and 12P, Whitefield Main Rd, Varthur Kodi, Ramagondanahalli, Bengaluru, Karnataka, Karnataka, 560066",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f1d"
    },
    "Rank": 6544,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f1e"
    },
    "Rank": 6546,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital Whitefield,,ITPL Main Rd, KIADB Export Promotion Industrial Area, Krishnarajapura, Bengaluru, Karnataka, 560066",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f1f"
    },
    "Rank": 6549,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saideep Healthcare and Research Pvt Ltd,YASHWANT COLONY, VIRAJ ESTATE, TARAKPUR, AHMEDNAGAR, Maharashtra, 414001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f20"
    },
    "Rank": 6550,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f21"
    },
    "Rank": 6552,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f22"
    },
    "Rank": 6553,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aditya Birla Memorial Hospital, Maharashtra,Aditya Birla Hospital Marg, P.O.- Chinchwad, Pune, Maharashtra, 411033",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f23"
    },
    "Rank": 6555,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Caritas Hospital, ,Thellakom P.O, Kerala, 686630",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f24"
    },
    "Rank": 6557,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f25"
    },
    "Rank": 6561,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta The Medicity, ,Medanta The Medicity Sector 38 Gurgoan, Haryana, 122001",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f26"
    },
    "Rank": 6562,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f27"
    },
    "Rank": 6563,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Care Hospital, ,Plot No.- 324 p Prachi Enclave, Chandrasekharpur,\nBhubaneswar, Odisha, 751016",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f28"
    },
    "Rank": 6565,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f29"
    },
    "Rank": 6567,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f2a"
    },
    "Rank": 6569,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f2b"
    },
    "Rank": 6570,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f2c"
    },
    "Rank": 6572,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f2d"
    },
    "Rank": 6576,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f2e"
    },
    "Rank": 6577,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f2f"
    },
    "Rank": 6578,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f30"
    },
    "Rank": 6580,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agrasen Medical College,,NH- 9, VPO - Agroha, District Hisar, Haryana, 125047",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f31"
    },
    "Rank": 6581,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f32"
    },
    "Rank": 6582,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f33"
    },
    "Rank": 6587,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Raiganj Government Medical college and Hospital, West Bengal,DR B C ROY SARANI. RAIGANJ, UTTAR DINAJPUR,PIN 733130, West Bengal, 733130",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f34"
    },
    "Rank": 6588,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f35"
    },
    "Rank": 6591,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f36"
    },
    "Rank": 6592,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f37"
    },
    "Rank": 6594,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f38"
    },
    "Rank": 6595,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f39"
    },
    "Rank": 6596,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f3a"
    },
    "Rank": 6597,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f3b"
    },
    "Rank": 6598,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f3c"
    },
    "Rank": 6599,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f3d"
    },
    "Rank": 6600,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f3e"
    },
    "Rank": 6601,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. R N Cooper Municipal General Hospital, (Associated with H B T (Hinduhridayasamrat Balasaheb Thackeray) medical College) ,HBTMC AND DR.R.N.COOPER HOSPITAL\nU 15 BHAKTI VEDANTA ROAD JVPD SCHEME VILE PARLE WEST MUMBAI,\nMaharashtra, 400056",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f3f"
    },
    "Rank": 6602,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tirath Ram Shah Hospital,,2A, RBL Isher Das Sawhney Marg, Rajpur Road,, Delhi (NCT), 110054",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f40"
    },
    "Rank": 6604,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jalgaon,Jilha Peth, Government Hospital Campus, Jalgaon, Maharashtra, 425001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f41"
    },
    "Rank": 6605,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruby General Hospital, ,576 Anandapur, Kasba Golpark, E. M. Bypass, Kolkata 700107, West Bengal, 700107",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f42"
    },
    "Rank": 6606,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,APOLLO HOSPITALS BANGALORE A UNIT OF IMPERIAL HOSPITALS AND RESEARCH CENTRE LIMITED 154/11 Opp IIM B, Karnataka, 560076",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f43"
    },
    "Rank": 6607,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f44"
    },
    "Rank": 6610,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f45"
    },
    "Rank": 6611,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindu Rao Hospital, ,SUBZI MANDI, MALKA GANJ, DELHI-110007, Delhi (NCT), 110007",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f46"
    },
    "Rank": 6612,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f47"
    },
    "Rank": 6613,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital Whitefield,,ITPL Main Rd, KIADB Export Promotion Industrial Area, Krishnarajapura, Bengaluru, Karnataka, 560066",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f48"
    },
    "Rank": 6614,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f49"
    },
    "Rank": 6615,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f4a"
    },
    "Rank": 6616,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sanjay Gandhi Postgarduate Institute of Medical Sciences,Sanjay Gandhi Postgarduate Institute of Medical Sciences, Uttar Pradesh, 226014",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f4b"
    },
    "Rank": 6617,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Doon Medical College, Dehradun,Govt. Doon Medical College Dehrakhas, Patel Nagar Dehradun 248001, Uttarakhand, 248001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f4c"
    },
    "Rank": 6618,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f4d"
    },
    "Rank": 6619,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f4e"
    },
    "Rank": 6620,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f4f"
    },
    "Rank": 6622,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f50"
    },
    "Rank": 6626,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f51"
    },
    "Rank": 6628,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Government Medical College, Vidisha,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA MP, Madhya Pradesh, 464001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f52"
    },
    "Rank": 6629,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f53"
    },
    "Rank": 6631,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Gleneagles Hospital, ,58 Canal Circular Road Kolkata 700054, West Bengal, 700054",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f54"
    },
    "Rank": 6633,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f55"
    },
    "Rank": 6635,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f56"
    },
    "Rank": 6636,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f57"
    },
    "Rank": 6639,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aditya Birla Memorial Hospital, Maharashtra,Aditya Birla Hospital Marg, P.O.- Chinchwad, Pune, Maharashtra, 411033",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f58"
    },
    "Rank": 6641,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kaveri Medical Centre and Hospital,,No-1, KC Road, Tennur, Trichy-620017, Tamil Nadu, 620017",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f59"
    },
    "Rank": 6642,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f5a"
    },
    "Rank": 6643,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.E.M. Hospital, ,489, SARDAR MOODLIAR ROAD, RASTA PETH, PUNE 411011, Maharashtra, 411011",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f5b"
    },
    "Rank": 6644,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "South Western Railway,Room No 22 Central Hospital South Western Railway Gadag, road Hubballi, Karnataka, 580020",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f5c"
    },
    "Rank": 6645,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f5d"
    },
    "Rank": 6647,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pimpri Chichwad Municipal Corporation Postgraduate Institute Y.C.M.Hospital Pimpri,Pune 18,Dean Office, Y.C.M.Hospital, Sant Tukaramnagar Pimpri, Pune, Maharashtra, 411018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f5e"
    },
    "Rank": 6648,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f5f"
    },
    "Rank": 6650,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharashtra Post Graduate Institute of Medical Education and Research, Nashik,Maharashtra Post Graduation Institute Of Medical Education and Research Civil Hospital campus Trimba, Maharashtra, 422002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f60"
    },
    "Rank": 6651,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f61"
    },
    "Rank": 6653,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f62"
    },
    "Rank": 6655,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f63"
    },
    "Rank": 6656,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f64"
    },
    "Rank": 6657,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f65"
    },
    "Rank": 6658,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f66"
    },
    "Rank": 6660,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f67"
    },
    "Rank": 6661,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INSTITUTE OF MEDICAL SCIENCES,RAICHUR INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 584102",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f68"
    },
    "Rank": 6662,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS MEDICAL COLLEGE, NEAR GUJARAT HIGH COURT, S.G HIGH WAY, SOLA, AHMEDABAD, Gujarat, 380060",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f69"
    },
    "Rank": 6664,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f6a"
    },
    "Rank": 6665,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f6b"
    },
    "Rank": 6666,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f6c"
    },
    "Rank": 6667,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Childrens Hospital, S,KR Puram, Survey No. 8/5, Outer Ring Rd, Doddanekundi, Marathahalli, Bengaluru, Karnataka 560037, Karnataka, 560037",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f6d"
    },
    "Rank": 6668,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karnataka,MG Road, Karnataka, 581301",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f6e"
    },
    "Rank": 6670,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saideep Healthcare and Research Pvt Ltd,YASHWANT COLONY, VIRAJ ESTATE, TARAKPUR, AHMEDNAGAR, Maharashtra, 414001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f6f"
    },
    "Rank": 6673,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f70"
    },
    "Rank": 6674,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f71"
    },
    "Rank": 6675,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Daya General Hospital,Daya General Hospital Limited Shornur Road NEar Viyyur bridge\nThiruvambady PO Thrissur, Kerala, 680022",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f72"
    },
    "Rank": 6676,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f73"
    },
    "Rank": 6677,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f74"
    },
    "Rank": 6678,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f75"
    },
    "Rank": 6679,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Park Hospital, NEW DELHI,12 MEERA ENCLAVE CHOWKHANDI NEAR KESHOPUR BUS DEPOT OUTER RING ROAD NEW DELHI 110018, Delhi (NCT), 110018",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f76"
    },
    "Rank": 6682,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f77"
    },
    "Rank": 6684,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Vasantrao Naik Govt Medical College Yavatmal,Shri Vasantrao Naik Govt Medical College Yavatmal, Maharashtra, 445001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f78"
    },
    "Rank": 6686,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f79"
    },
    "Rank": 6689,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f7a"
    },
    "Rank": 6691,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bokaro General Hospital, ,SECTOR - 4 BOKARO STEEL CITY\nDIST - BOKARO, Jharkhand, 827004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f7b"
    },
    "Rank": 6692,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f7c"
    },
    "Rank": 6694,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f7d"
    },
    "Rank": 6695,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asian Institute of Medical Sciences,,Asian Institute of Medical Sciences, Badhkal Flyover Road,Sector-21A, Faridabad, Haryana-121001, Haryana, 121001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f7e"
    },
    "Rank": 6696,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f7f"
    },
    "Rank": 6697,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f80"
    },
    "Rank": 6699,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f81"
    },
    "Rank": 6700,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f82"
    },
    "Rank": 6701,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f83"
    },
    "Rank": 6702,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f84"
    },
    "Rank": 6703,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f85"
    },
    "Rank": 6704,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f86"
    },
    "Rank": 6705,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apex Hospital, ,SP 4 AND 6 MALVIYA INDUSTRIAL AREA MALVIYA NAGAR , JAIPUR,\nRajasthan, 302017",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f87"
    },
    "Rank": 6706,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Datia,Near 29 Batallion, National Highway 44, Dist Datia, Madhya Pradesh Pin - 475661, Madhya Pradesh, 475661",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f88"
    },
    "Rank": 6707,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indira Gandhi Govt. General Hospital and PG Institute, No. 1, ,No.1, Victor Simonel street, Puducherry -1., Puducherry, 605001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f89"
    },
    "Rank": 6708,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f8a"
    },
    "Rank": 6710,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sarvodaya Hospital and Research Centre, ,YMCA road Sector 08\nFaridabad, Haryana, 121006",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f8b"
    },
    "Rank": 6711,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhaktivedanta Hospital and Research Institute, ,Bhaktivedanta Hospital and Research Institute,Srishti Complex, Bhaktivedanta Swami Marg, Mira Road E, Maharashtra, 401107",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f8c"
    },
    "Rank": 6712,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f8d"
    },
    "Rank": 6713,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bettiah,Government Medical College Bettiah West Champaran Bihar, Bihar, 845438",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f8e"
    },
    "Rank": 6716,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f8f"
    },
    "Rank": 6719,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f90"
    },
    "Rank": 6720,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Valluvanad Hospital Complex Limited,KANNIYAMPURAM POST OTTAPALAM\nPALAKKAD KERALA, Kerala, 679104",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f91"
    },
    "Rank": 6721,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f92"
    },
    "Rank": 6723,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f93"
    },
    "Rank": 6726,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Vinoba Bhave Civil Hospital,,S R Delkar Marg, Saily Road, Silvassa, Dadra And Nagar Haveli, 396230",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f94"
    },
    "Rank": 6727,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Star Hospital,,Star Hospitals, 8-2-596/5, Road. No. 10,\nBanjara Hills, Hyderabad, Telangana, 500034",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f95"
    },
    "Rank": 6728,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f96"
    },
    "Rank": 6729,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f97"
    },
    "Rank": 6730,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f98"
    },
    "Rank": 6732,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f99"
    },
    "Rank": 6733,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESIC Model Hospital, ,LAXMI NAGAR AJMER ROAD JAIPUR, Rajasthan, 302006",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f9a"
    },
    "Rank": 6734,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f9b"
    },
    "Rank": 6735,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f9c"
    },
    "Rank": 6737,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f9d"
    },
    "Rank": 6739,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f9e"
    },
    "Rank": 6740,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03f9f"
    },
    "Rank": 6742,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fa0"
    },
    "Rank": 6743,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fa1"
    },
    "Rank": 6749,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fa2"
    },
    "Rank": 6752,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fa3"
    },
    "Rank": 6753,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fa4"
    },
    "Rank": 6755,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fa5"
    },
    "Rank": 6756,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fa6"
    },
    "Rank": 6757,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fa7"
    },
    "Rank": 6758,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fa8"
    },
    "Rank": 6759,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fa9"
    },
    "Rank": 6761,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03faa"
    },
    "Rank": 6762,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chamarajanagar Institute of Medical Sciences, Karnataka,CHAMARAJANAGAR INSTITUTE OF MEDICAL SCIENCES, SY. NO 124, YEDAPURA VILLAGE KASABA HOBLI, CHAMARAJANA, Karnataka, 571313",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fab"
    },
    "Rank": 6763,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fac"
    },
    "Rank": 6764,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asian Institute of Medical Sciences,,Asian Institute of Medical Sciences, Badhkal Flyover Road,Sector-21A, Faridabad, Haryana-121001, Haryana, 121001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fad"
    },
    "Rank": 6767,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. GERIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fae"
    },
    "Rank": 6768,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03faf"
    },
    "Rank": 6769,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Hospital, Omandurar,GOVERNMENT MEDICAL COLLEGE, OMANDURAR GOVERNMENT ESTATE, WALAJAH ROAD, NEAR KALAIVANAR ARANGAM, CHEN, Tamil Nadu, 600002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fb0"
    },
    "Rank": 6772,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fb1"
    },
    "Rank": 6774,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fb2"
    },
    "Rank": 6775,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VILLUPURAM MEDICAL COLLEGE, VILLUPURAM,GOVERNMENT VILLUPURAM MEDICAL COLLEGE VILLUPURAM  TRICHY TRUNK ROAD MUNDIYAMPAKKAM  VILLUPURAM DISTR, Tamil Nadu, 605601",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fb3"
    },
    "Rank": 6777,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fb4"
    },
    "Rank": 6778,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fb5"
    },
    "Rank": 6779,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollomedics Superspeciality Hospital,KBC 31, Sector B, LDA Colony, Kanpur Road, Lucknow, Uttar Pradesh, 226012",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fb6"
    },
    "Rank": 6780,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fb7"
    },
    "Rank": 6781,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fb8"
    },
    "Rank": 6782,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fb9"
    },
    "Rank": 6785,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Siddipet,SURVEY NO 54  GOVERNMENT MEDICAL COLLEGE  ENSANPALLY  SIDDIPET   502114, Telangana, 502114",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fba"
    },
    "Rank": 6786,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Noble Hospital, ,Magarpatta City Road, Hadpsar, Pune, Maharashtra, 411028",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fbb"
    },
    "Rank": 6787,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fbc"
    },
    "Rank": 6789,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital, ,GMERS Medical College and General hospital,Old TB Campus, gotri road, Vadodara, Gujarat, 390021",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fbd"
    },
    "Rank": 6790,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kongunad Hospitals Private Limited, ,116,117 11th Street, Tatabad,Opposite to east power house, Tamil Nadu, 641012",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fbe"
    },
    "Rank": 6792,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fbf"
    },
    "Rank": 6793,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fc0"
    },
    "Rank": 6794,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fc1"
    },
    "Rank": 6795,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fc2"
    },
    "Rank": 6796,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Haria L G Rotary Hospital, ,PLOT NO.363\\1 & 364 HOUSING SECTOR GIDC VAPI GUJARAT 396195, Gujarat, 396195",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fc3"
    },
    "Rank": 6798,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fc4"
    },
    "Rank": 6802,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fc5"
    },
    "Rank": 6807,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fc6"
    },
    "Rank": 6808,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Baba Saheb Ambedkar Hospital, ,SEC 6 ROHINI DELHI, Delhi (NCT), 110085",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fc7"
    },
    "Rank": 6810,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fc8"
    },
    "Rank": 6811,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fc9"
    },
    "Rank": 6812,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fca"
    },
    "Rank": 6814,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fcb"
    },
    "Rank": 6817,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fcc"
    },
    "Rank": 6818,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fcd"
    },
    "Rank": 6819,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fce"
    },
    "Rank": 6821,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fcf"
    },
    "Rank": 6823,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Martha`s Hospital , ,No.5, Nrupathunga Road, Bengaluru, Karnataka, 560001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fd0"
    },
    "Rank": 6824,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fd1"
    },
    "Rank": 6826,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fd2"
    },
    "Rank": 6827,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College, Kolhapur,R.K. Nagar Road, Shenda Park, Kolhapur, Maharashtra, Maharashtra, 416012",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fd3"
    },
    "Rank": 6828,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fd4"
    },
    "Rank": 6829,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Eastern Indira Gandhi Regional Institute of Health & Medical Sciences, Shillong,North Eastern Indira Gandhi Regional Institute of Health & Medical Sciences, Shillong, Meghalaya, 793018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fd5"
    },
    "Rank": 6833,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fd6"
    },
    "Rank": 6834,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,APOLLO HOSPITALS BANGALORE A UNIT OF IMPERIAL HOSPITALS AND RESEARCH CENTRE LIMITED 154/11 Opp IIM B, Karnataka, 560076",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fd7"
    },
    "Rank": 6835,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fd8"
    },
    "Rank": 6837,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fd9"
    },
    "Rank": 6838,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fda"
    },
    "Rank": 6839,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fdb"
    },
    "Rank": 6840,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fdc"
    },
    "Rank": 6841,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fdd"
    },
    "Rank": 6842,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fde"
    },
    "Rank": 6844,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fdf"
    },
    "Rank": 6845,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindu Rao Hospital, ,SUBZI MANDI, MALKA GANJ, DELHI-110007, Delhi (NCT), 110007",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fe0"
    },
    "Rank": 6846,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fe1"
    },
    "Rank": 6848,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fe2"
    },
    "Rank": 6849,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute of Medical Sciences Srikakulam,Government Medical College Srikakulam\nBalaga Srikakulam Town Srikakulam District, Andhra Pradesh, 532001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fe3"
    },
    "Rank": 6850,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jupiter Hospital, ,Eastern Express Highway, Thane West, Maharashtra, 400601",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fe4"
    },
    "Rank": 6851,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fe5"
    },
    "Rank": 6852,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fe6"
    },
    "Rank": 6853,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ozone Multispeciality hospital and Critical care center, Maharashtra,Holycross convent Road, Kedia Plots, Akola, Maharashtra, 444005",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fe7"
    },
    "Rank": 6858,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fe8"
    },
    "Rank": 6859,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fe9"
    },
    "Rank": 6861,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fea"
    },
    "Rank": 6865,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03feb"
    },
    "Rank": 6869,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kalpana Chawla Govt Medical College, ,Model Town, Karnal, Haryana, 132001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fec"
    },
    "Rank": 6870,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fed"
    },
    "Rank": 6871,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "VishwaRaj Hospital,VishwaRaj Hospital, Pune Solapur Road , Near Loni Railway Station, Loni Kalbhor , Pune., Maharashtra, 412201",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fee"
    },
    "Rank": 6872,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Doon Medical College, Dehradun,Govt. Doon Medical College Dehrakhas, Patel Nagar Dehradun 248001, Uttarakhand, 248001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fef"
    },
    "Rank": 6873,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute of Medical Sciences Srikakulam,Government Medical College Srikakulam\nBalaga Srikakulam Town Srikakulam District, Andhra Pradesh, 532001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ff0"
    },
    "Rank": 6874,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "VishwaRaj Hospital,VishwaRaj Hospital, Pune Solapur Road , Near Loni Railway Station, Loni Kalbhor , Pune., Maharashtra, 412201",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ff1"
    },
    "Rank": 6876,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ff2"
    },
    "Rank": 6877,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ff3"
    },
    "Rank": 6879,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "J.L.N. Main Hospital and Research Centre, ,Bhilai Steel Plant Sector-9, Bhilai, C.G., Chhattisgarh, 490009",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ff4"
    },
    "Rank": 6882,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ff5"
    },
    "Rank": 6883,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ff6"
    },
    "Rank": 6886,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ff7"
    },
    "Rank": 6887,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Gleneagles Hospital, ,58 Canal Circular Road Kolkata 700054, West Bengal, 700054",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ff8"
    },
    "Rank": 6888,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ff9"
    },
    "Rank": 6889,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ffa"
    },
    "Rank": 6890,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ffb"
    },
    "Rank": 6892,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ffc"
    },
    "Rank": 6893,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sheth Vadilal Sarabhai General Hospital and Sheth Chinai Prasuti Hospital, Gujarat,SHETH VADILAL SARABHAI GENERAL HOSPITAL AND SHETH CHINAI PRASUTI HOSPITAL ELLISBRIDGE AHMEDABAD, Gujarat, 380006",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ffd"
    },
    "Rank": 6894,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03ffe"
    },
    "Rank": 6895,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a03fff"
    },
    "Rank": 6896,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04000"
    },
    "Rank": 6899,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04001"
    },
    "Rank": 6900,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Christian Fellowship Hospital, ,Dindigul Palani Main Road Oddanchatram\nDindigul District, Tamil Nadu, 624619",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04002"
    },
    "Rank": 6902,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04003"
    },
    "Rank": 6903,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Eastern Indira Gandhi Regional Institute of Health & Medical Sciences, Shillong,North Eastern Indira Gandhi Regional Institute of Health & Medical Sciences, Shillong, Meghalaya, 793018",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04004"
    },
    "Rank": 6904,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04005"
    },
    "Rank": 6905,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04006"
    },
    "Rank": 6906,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital,,Sector 5, Vidhyadhar Nagar, Jaipur, Rajasthan, 302013",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04007"
    },
    "Rank": 6907,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo BGS Hospital,,Apollo BGS Hospitals, Adhichunchangiri Road, Kuvempunagar, Mysore, Karnataka, 570023",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04008"
    },
    "Rank": 6908,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04009"
    },
    "Rank": 6911,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0400a"
    },
    "Rank": 6912,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0400b"
    },
    "Rank": 6913,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0400c"
    },
    "Rank": 6914,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0400d"
    },
    "Rank": 6917,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0400e"
    },
    "Rank": 6918,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0400f"
    },
    "Rank": 6920,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04010"
    },
    "Rank": 6921,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04011"
    },
    "Rank": 6923,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04012"
    },
    "Rank": 6925,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04013"
    },
    "Rank": 6926,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04014"
    },
    "Rank": 6927,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04015"
    },
    "Rank": 6928,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04016"
    },
    "Rank": 6931,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04017"
    },
    "Rank": 6932,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04018"
    },
    "Rank": 6934,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04019"
    },
    "Rank": 6935,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0401a"
    },
    "Rank": 6937,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0401b"
    },
    "Rank": 6938,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0401c"
    },
    "Rank": 6939,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0401d"
    },
    "Rank": 6942,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0401e"
    },
    "Rank": 6943,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0401f"
    },
    "Rank": 6946,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Thiruvannamalai Medical College, Thiruvannamalai, Tamil Nadu,DISTRICT COLLECTORATE  MASTER PLAN COMPLEX, VENGIKKAL THIRUVANNAMALAI,\nTamil Nadu, 600604",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04020"
    },
    "Rank": 6947,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04021"
    },
    "Rank": 6948,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Metro Heart Institute with Multispecialty, ,SECTOR 16A, Faridabad, Haryana, Haryana, 121002",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04022"
    },
    "Rank": 6949,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04023"
    },
    "Rank": 6950,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04024"
    },
    "Rank": 6951,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04025"
    },
    "Rank": 6953,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "HINDU MISSION HOSPITAL,\n,103 G S T Road Tambaram West Chennai, Tamil Nadu, 600045",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04026"
    },
    "Rank": 6954,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04027"
    },
    "Rank": 6955,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04028"
    },
    "Rank": 6956,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04029"
    },
    "Rank": 6957,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0402a"
    },
    "Rank": 6962,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Arvind Eye Hospital, ,Aravind Eye Hospital and Postgraduate Institute of Ophthalmology, Cuddalore Main Road, Thavalakuppam, Puducherry, 605007",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0402b"
    },
    "Rank": 6965,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0402c"
    },
    "Rank": 6966,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0402d"
    },
    "Rank": 6968,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0402e"
    },
    "Rank": 6969,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0402f"
    },
    "Rank": 6970,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Malabar Hospitals Pvt. Ltd., Kerala,Karuvambrum PO, Manjeri,\nMalappuram, Kerala, Kerala, 676123",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04030"
    },
    "Rank": 6971,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kamalnayan Bajaj Hospital (Marathwada Medical and Research Institutes), ,Kamalnayan Bajaj Hospital, Gut no.  43, Satara Parisar, Beed bypass road, Auranagabad., Maharashtra, 431010",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04031"
    },
    "Rank": 6972,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04032"
    },
    "Rank": 6973,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04033"
    },
    "Rank": 6974,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04034"
    },
    "Rank": 6975,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KIMS Al Shifa Hospital, ,KIMS Al Shifa Healthcare Pvt  Ltd.\nPB No 26 .\nOotty Road.\nPerinthalmanna, Malappuram district, Ke, Kerala, 679322",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04035"
    },
    "Rank": 6976,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04036"
    },
    "Rank": 6978,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04037"
    },
    "Rank": 6979,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Care Hospital,,Care Hospitals Road No1 Banjara Hills Hyderabad Telangana, Telangana, 500034",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04038"
    },
    "Rank": 6980,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Alexis Multispecialty Hospital,232, Mankapur Square, Koradi road, NAGPUR, Maharashtra, 440030",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04039"
    },
    "Rank": 6981,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0403a"
    },
    "Rank": 6982,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0403b"
    },
    "Rank": 6983,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0403c"
    },
    "Rank": 6984,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Polakulath Narayanan Renai Medicity Multi Super Specialty Hospital,,RENAIMEDICITY PALARIVATTOM KOCHI KERALA, Kerala, 682025",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0403d"
    },
    "Rank": 6986,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0403e"
    },
    "Rank": 6988,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shree Markendey Solapur Sahakari Rugnalya and Research Centre, ,Final Plot No.19, Pachha Peth,Solapur, Maharashtra, 413005",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0403f"
    },
    "Rank": 6989,
    "Allotted Quota": "All India",
    "Allotted Institute": "GADAG INSTITUTE OF MEDICAL SCIENCES GADAG,Gadag Institute of Medical Sciences Mallasamudra Gadag-582103, Karnataka, 582103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04040"
    },
    "Rank": 6990,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mata Chanan Devi Hospital,, ,C-1 JANAKPURI, Delhi (NCT), 110058",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04041"
    },
    "Rank": 6991,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Dada Dev Matri Avum Shishu Chikitsalaya, ,Dabri, New Delhi-110045, Delhi (NCT), 110045",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04042"
    },
    "Rank": 6992,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Escorts Hospital, ,214, Jawahar Lal Nehru Marg, Malviya Nagar, Jaipur, Rajasthan, 302017",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04043"
    },
    "Rank": 6994,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Government Medical College, Vidisha,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA MP, Madhya Pradesh, 464001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04044"
    },
    "Rank": 6995,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04045"
    },
    "Rank": 6996,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Arvind Eye Hospital , ,Aravind Eye Hospital & Postgraduate Institute of Ophthalmology, Avinashi Road, Civil Aerodrome Post,, Tamil Nadu, 641014",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04046"
    },
    "Rank": 6997,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04047"
    },
    "Rank": 6998,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04048"
    },
    "Rank": 7000,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Bundelkhand Medical College Sagar M.P.,Govt. Bundelkhand Medical College Sagar M.P., Madhya Pradesh, 470001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04049"
    },
    "Rank": 7002,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0404a"
    },
    "Rank": 7003,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "National Institute of Tuberculosis and Respiratory Diseases, (Formerly Known as L.R.S. Instt. of T.B. and Allied Scs),,National Institute of Tuberculosis and Respiratory Diseases (Formerly Known as L.R.S. Instt. of T.B., Delhi (NCT), 110030",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0404b"
    },
    "Rank": 7005,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0404c"
    },
    "Rank": 7006,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SANKARA NETHRALAYA ,\nTamil Nadu,No 21 Pycrofts Garden Road off. Haddows Road\nChennai, Tamil Nadu, 600006",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0404d"
    },
    "Rank": 7008,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0404e"
    },
    "Rank": 7009,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0404f"
    },
    "Rank": 7010,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04050"
    },
    "Rank": 7011,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04051"
    },
    "Rank": 7013,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine and JNM Hospital, WBUHS,Block A, Post Office Kalyani, Near Silpanchal Station,\nBlock A2, District Nadia, West Bengal, West Bengal, 741235",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04052"
    },
    "Rank": 7014,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asian Institute of Medical Sciences,,p -72,Milapnagar,MIDC,Dombivli E, Maharashtra, 421203",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04053"
    },
    "Rank": 7015,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04054"
    },
    "Rank": 7019,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04055"
    },
    "Rank": 7021,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04056"
    },
    "Rank": 7022,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindu Rao Hospital, ,SUBZI MANDI, MALKA GANJ, DELHI-110007, Delhi (NCT), 110007",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04057"
    },
    "Rank": 7023,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Bhausaheb Hire Government Medical College,Sri Bhausaheb Hire Government Medical College,Dhule, Maharashtra, 424311",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04058"
    },
    "Rank": 7024,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04059"
    },
    "Rank": 7026,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "N S Memorial Institute of Medical Sciences ,N S Memorial Institute of Medical Sciences Palathara, Thattamala, Kollam, Kerala, 691020",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0405a"
    },
    "Rank": 7027,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE, PUDUCHERRY,INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE,\nPUDUCHERRY, Puducherry, 605009",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0405b"
    },
    "Rank": 7028,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0405c"
    },
    "Rank": 7029,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0405d"
    },
    "Rank": 7030,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "HCG Manavata Cancer Centre, A Unit of HCG Manavata Oncology LLP,,Near Mylan Circle, Mumbai Naka, Nashik-422002, Maharashtra, 422002",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0405e"
    },
    "Rank": 7032,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Alexis Multispecialty Hospital,232, Mankapur Square, Koradi road, NAGPUR, Maharashtra, 440030",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0405f"
    },
    "Rank": 7033,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04060"
    },
    "Rank": 7035,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04061"
    },
    "Rank": 7036,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04062"
    },
    "Rank": 7037,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04063"
    },
    "Rank": 7038,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04064"
    },
    "Rank": 7039,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04065"
    },
    "Rank": 7040,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04066"
    },
    "Rank": 7043,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04067"
    },
    "Rank": 7044,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04068"
    },
    "Rank": 7045,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sakra World Hospital, (A Unit of Takshasila Hospitals Operating Private Limited)\n,LOVEKESH\\DOT\\PHASU\\AT\\SAKRAWORLDHOSPITAL\\DOT\\COM, Karnataka, 560103",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04069"
    },
    "Rank": 7046,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0406a"
    },
    "Rank": 7047,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0406b"
    },
    "Rank": 7050,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0406c"
    },
    "Rank": 7053,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VELLORE MEDICAL COLLEGE,GOVERNMENT VELLORE MEDICAL COLLEGE, Tamil Nadu, 632011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0406d"
    },
    "Rank": 7054,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute Of Medical Sciences, Kalaburagi,VEERESH NAGAR SEDAM ROAD KALABURAGI, Karnataka, 585105",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0406e"
    },
    "Rank": 7056,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0406f"
    },
    "Rank": 7057,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04070"
    },
    "Rank": 7058,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04071"
    },
    "Rank": 7060,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Choithram Hospital and Research Centre,Manika Bagh Road Indore, Madhya Pradesh, 452014",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04072"
    },
    "Rank": 7062,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04073"
    },
    "Rank": 7063,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04074"
    },
    "Rank": 7066,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Sivagangai Medical College and Hospital, Tamil Nadu,GOVT.SIVAGANGAI MEDICAL COLLEGE, MELAVANIYANKUDY, MANAMADURAI ROAD, SIVAGANGAI.,\nTamil Nadu, 630561",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04075"
    },
    "Rank": 7068,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SAL Hospital and Medical Institute,,NEAR TAKSHASHILA",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04076"
    },
    "Rank": 7070,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MAR SLEEVA MEDICITY PALAI, Kerala,Kezhuvankulam P.O., Cherpunkal, Kottayam, Kerala, 686584",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04077"
    },
    "Rank": 7071,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nice Hospital For Women, Newborn and Children, ,10-2-247 and 248, Shanti Nagar, Masab Tank, Hyderabad, Telangana 500057, India, Telangana, 500057",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04078"
    },
    "Rank": 7072,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04079"
    },
    "Rank": 7074,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College and  PGIMSR,ESIC Medical College and  PGIMSR, KK Nagar, Chennai - 600 078, Tamil Nadu, 600078",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0407a"
    },
    "Rank": 7076,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0407b"
    },
    "Rank": 7077,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0407c"
    },
    "Rank": 7079,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital, ,GMERS Medical College and General hospital,Old TB Campus, gotri road, Vadodara, Gujarat, 390021",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0407d"
    },
    "Rank": 7082,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0407e"
    },
    "Rank": 7083,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Doon Medical College, Dehradun,Govt. Doon Medical College Dehrakhas, Patel Nagar Dehradun 248001, Uttarakhand, 248001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0407f"
    },
    "Rank": 7084,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04080"
    },
    "Rank": 7085,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04081"
    },
    "Rank": 7086,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04082"
    },
    "Rank": 7087,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04083"
    },
    "Rank": 7090,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04084"
    },
    "Rank": 7091,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04085"
    },
    "Rank": 7093,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Health City, ,Apollo Hospitals, Health City, Chinagadhili, Visakhapatnam, Andhra Pradesh, 530040",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04086"
    },
    "Rank": 7094,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04087"
    },
    "Rank": 7095,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gvernment Medical College,Haldwani,Government Medical College, Haldwani, Uttarakhand, 263139",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04088"
    },
    "Rank": 7097,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "VCSG Govt Institute of Medical Science and Research and HNB Base Teaching Hospital\n, ,Srikot Ganganali Srinagar Garhwal Uttarakhand, Uttarakhand, 246174",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04089"
    },
    "Rank": 7098,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0408a"
    },
    "Rank": 7099,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0408b"
    },
    "Rank": 7100,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0408c"
    },
    "Rank": 7101,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0408d"
    },
    "Rank": 7102,
    "Allotted Quota": "All India",
    "Allotted Institute": "Institute of Child Health, Kolkata,Institute of Child Health, Kolkata, West Bengal, 700017",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0408e"
    },
    "Rank": 7103,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0408f"
    },
    "Rank": 7104,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04090"
    },
    "Rank": 7107,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04091"
    },
    "Rank": 7109,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04092"
    },
    "Rank": 7110,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Theresa`s Hospital,,7-1-645/A ERRAGADA SANATHNAGAAR HYDERABAD,\nTelangana, 500018",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04093"
    },
    "Rank": 7111,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04094"
    },
    "Rank": 7112,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Theresa`s Hospital,,7-1-645/A ERRAGADA SANATHNAGAAR HYDERABAD,\nTelangana, 500018",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04095"
    },
    "Rank": 7113,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04096"
    },
    "Rank": 7115,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04097"
    },
    "Rank": 7118,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mother Hospital, ,P.O PULLAZHI, OLARI,THRISSUR-680012, Kerala, 680012",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04098"
    },
    "Rank": 7119,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04099"
    },
    "Rank": 7121,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Zen Multispeciality Hospital, Maharashtra,425, 10th Road, Chembur, Mumbai, Maharashtra, 400071",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0409a"
    },
    "Rank": 7122,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asian Institute of Medical Sciences,,p -72,Milapnagar,MIDC,Dombivli E, Maharashtra, 421203",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0409b"
    },
    "Rank": 7126,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medica Superspecialty Hospital, ,127 Mukundapur, E.M. Byapss, Kolkata, West Bengal, 700099",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0409c"
    },
    "Rank": 7127,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0409d"
    },
    "Rank": 7129,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kamalnayan Bajaj Hospital (Marathwada Medical and Research Institutes), ,Kamalnayan Bajaj Hospital, Gut no.  43, Satara Parisar, Beed bypass road, Auranagabad., Maharashtra, 431010",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0409e"
    },
    "Rank": 7130,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0409f"
    },
    "Rank": 7131,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Vasantrao Naik Govt Medical College Yavatmal,Shri Vasantrao Naik Govt Medical College Yavatmal, Maharashtra, 445001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040a0"
    },
    "Rank": 7132,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ayushman Hospital and Health Services, Dwarka,  Delhi,Ayushman Hospital and Health Services, Sector-10 Dwarka, New Delhi-110075, Delhi (NCT), 110075",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040a1"
    },
    "Rank": 7133,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040a2"
    },
    "Rank": 7134,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040a3"
    },
    "Rank": 7135,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040a4"
    },
    "Rank": 7136,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040a5"
    },
    "Rank": 7137,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040a6"
    },
    "Rank": 7138,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040a7"
    },
    "Rank": 7139,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040a8"
    },
    "Rank": 7141,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040a9"
    },
    "Rank": 7143,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040aa"
    },
    "Rank": 7144,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kingsway Hospitals, Maharashtra,Medisearch Lifesciences Pvt  Ltd 44SPANV Kingsway Rd near Kasturchand Park Nagpur Maharashtra 440001, Maharashtra, 440001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040ab"
    },
    "Rank": 7145,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040ac"
    },
    "Rank": 7148,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Hospital, Omandurar,GOVERNMENT MEDICAL COLLEGE, OMANDURAR GOVERNMENT ESTATE, WALAJAH ROAD, NEAR KALAIVANAR ARANGAM, CHEN, Tamil Nadu, 600002",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040ad"
    },
    "Rank": 7150,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vivekananda Polyclinic and Institute of Medical Sciences, ,Vivekananda Polyclinic and Institute of Medical Sciences, Vivekananda Puram, Lucknow, Uttar Pradesh, 226007",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040ae"
    },
    "Rank": 7152,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040af"
    },
    "Rank": 7153,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bokaro General Hospital, ,SECTOR - 4 BOKARO STEEL CITY\nDIST - BOKARO, Jharkhand, 827004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040b0"
    },
    "Rank": 7154,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.G. Hospital and PG Medical Institute ,,No:5, Arts College Road, Coimbatore - 641018, Tamil Nadu, 641018",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040b1"
    },
    "Rank": 7157,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Martha`s Hospital , ,No.5, Nrupathunga Road, Bengaluru, Karnataka, 560001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040b2"
    },
    "Rank": 7158,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kalra Hospital and Shri Ram Cardiothoracic and Neuro-Sciences Centre, ,,A-4,5 & 6, Kirti Nagar, New Delhi -110015, Delhi (NCT), 110015",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040b3"
    },
    "Rank": 7159,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Memorial Research Institute, ,Fortis memorial research institute, Sector 44, Opposite Huda City Centre Metro station, Gurgaon, Haryana, 122002",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040b4"
    },
    "Rank": 7160,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040b5"
    },
    "Rank": 7161,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, (Formerly- Pushpanjali Crosslay Hospital) ,W-3, Sector-1, Vaishali, Ghaziabad, Uttar Pradesh, 201012",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040b6"
    },
    "Rank": 7162,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040b7"
    },
    "Rank": 7163,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040b8"
    },
    "Rank": 7164,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040b9"
    },
    "Rank": 7166,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040ba"
    },
    "Rank": 7169,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Arvind Eye Hospital, ,Aravind Eye Hospital and Postgraduate Institute of Ophthalmology, Cuddalore Main Road, Thavalakuppam, Puducherry, 605007",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040bb"
    },
    "Rank": 7170,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Vasantrao Naik Govt Medical College Yavatmal,Shri Vasantrao Naik Govt Medical College Yavatmal, Maharashtra, 445001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040bc"
    },
    "Rank": 7171,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040bd"
    },
    "Rank": 7172,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040be"
    },
    "Rank": 7174,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040bf"
    },
    "Rank": 7177,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Telangana,Adjacent Keyes High School,  St Johns Road,Secunderabad, Telangana 500003, Telangana, 500003",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040c0"
    },
    "Rank": 7178,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040c1"
    },
    "Rank": 7179,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040c2"
    },
    "Rank": 7180,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040c3"
    },
    "Rank": 7181,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040c4"
    },
    "Rank": 7185,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040c5"
    },
    "Rank": 7186,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Siddipet,SURVEY NO 54  GOVERNMENT MEDICAL COLLEGE  ENSANPALLY  SIDDIPET   502114, Telangana, 502114",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040c6"
    },
    "Rank": 7187,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040c7"
    },
    "Rank": 7189,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040c8"
    },
    "Rank": 7193,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040c9"
    },
    "Rank": 7196,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SK HOSPITAL, Kerala,skhospitalsyahoo.co.in, Kerala, 695006",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040ca"
    },
    "Rank": 7197,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040cb"
    },
    "Rank": 7198,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040cc"
    },
    "Rank": 7199,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KIMS Al Shifa Hospital, ,KIMS Al Shifa Healthcare Pvt  Ltd.\nPB No 26 .\nOotty Road.\nPerinthalmanna, Malappuram district, Ke, Kerala, 679322",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040cd"
    },
    "Rank": 7201,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College,Fakhruddin Ali Ahmed Medical College, Assam, 781301",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040ce"
    },
    "Rank": 7202,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040cf"
    },
    "Rank": 7204,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, (Formerly- Pushpanjali Crosslay Hospital) ,W-3, Sector-1, Vaishali, Ghaziabad, Uttar Pradesh, 201012",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040d0"
    },
    "Rank": 7208,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040d1"
    },
    "Rank": 7210,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aravind Eye Hospital, ,Aravind Eye Hospital\nPoonamallee High Rd, opposite Saveetha Dental College, Noombal, Chennai, Tamil Nadu, 600077",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040d2"
    },
    "Rank": 7212,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medica Superspecialty Hospital, ,127 Mukundapur, E.M. Byapss, Kolkata, West Bengal, 700099",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040d3"
    },
    "Rank": 7213,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhailal Amin General Hospital, Vadodara, Gujarat,Bhailal Amin General Hospital, Bhailal Amin Marg,\nGorwa, Vadodara, Gujarat, 390003",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040d4"
    },
    "Rank": 7214,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS Medical College Valsad,Nanakwada Halar Road,Valsad ,Gujarat, Gujarat, 396001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040d5"
    },
    "Rank": 7216,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kaveri Medical Centre and Hospital,,No-1, KC Road, Tennur, Trichy-620017, Tamil Nadu, 620017",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040d6"
    },
    "Rank": 7222,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Coimbatore childtrust hospitals pvt ltd, Tamil Nadu,111 Nanjappa Nagar Trichy Main Road\nSinganallur Coimbatore, Tamil Nadu, 641005",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040d7"
    },
    "Rank": 7223,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040d8"
    },
    "Rank": 7224,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040d9"
    },
    "Rank": 7228,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Rela Institute and Medical Centre ,\nTamil Nadu,NO.7, CLC WORKS ROAD, CHROMPET CHENNAI, Tamil Nadu, 600044",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040da"
    },
    "Rank": 7229,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040db"
    },
    "Rank": 7230,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040dc"
    },
    "Rank": 7231,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sagar Hospital, Karnataka,No. 44/54, 30th Cross, Tilak Nagar, Jayanagar Extn., Bangalore, Karnataka-560041",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040dd"
    },
    "Rank": 7232,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS MEDICAL COLLEGE, NEAR GUJARAT HIGH COURT, S.G HIGH WAY, SOLA, AHMEDABAD, Gujarat, 380060",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040de"
    },
    "Rank": 7233,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aakash Healthcare Super Specialty Hospital, Delhi,HOSPITAL PLOT, ROAD NO- 201,SECTOR 3 DWARKA, Delhi (NCT), 110075",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040df"
    },
    "Rank": 7235,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040e0"
    },
    "Rank": 7236,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040e1"
    },
    "Rank": 7237,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040e2"
    },
    "Rank": 7239,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040e3"
    },
    "Rank": 7241,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Autonomous State Medical College and Allied Pandit Ram Prasad Bismil Memorial Hospital,\n,ASMC, JIGNERA, kanth road, shahjahanpur, Uttar Pradesh, 242001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040e4"
    },
    "Rank": 7243,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040e5"
    },
    "Rank": 7245,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Kasturba Hospital, Daryaganj,Delhi,Daryaganj,Delhi",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040e6"
    },
    "Rank": 7246,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040e7"
    },
    "Rank": 7248,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040e8"
    },
    "Rank": 7250,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040e9"
    },
    "Rank": 7251,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040ea"
    },
    "Rank": 7252,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040eb"
    },
    "Rank": 7253,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,Plotno-251,Sainik school Road,Unit-15 Apollo Hospitals , Bhubaneswar, Odisha, 751005",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040ec"
    },
    "Rank": 7256,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040ed"
    },
    "Rank": 7257,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040ee"
    },
    "Rank": 7258,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Christian Fellowship Hospital, ,Dindigul Palani Main Road Oddanchatram\nDindigul District, Tamil Nadu, 624619",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040ef"
    },
    "Rank": 7263,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital,,B-22, Sector 62, Noida, Uttar Pradesh, Uttar Pradesh, 201301",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040f0"
    },
    "Rank": 7264,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Sivagangai Medical College and Hospital, Tamil Nadu,GOVT.SIVAGANGAI MEDICAL COLLEGE, MELAVANIYANKUDY, MANAMADURAI ROAD, SIVAGANGAI.,\nTamil Nadu, 630561",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040f1"
    },
    "Rank": 7265,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040f2"
    },
    "Rank": 7266,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bansal Hospital,,C- Sector Shahpura, Bhopal, Madhya Pradesh, 462016",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040f3"
    },
    "Rank": 7267,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040f4"
    },
    "Rank": 7268,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040f5"
    },
    "Rank": 7270,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040f6"
    },
    "Rank": 7271,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040f7"
    },
    "Rank": 7274,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040f8"
    },
    "Rank": 7275,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tiruvalla Medical Mission, ,Tiruvalla Medical Mission Post Box 74\nThiruvalla, Kerala, 689101",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040f9"
    },
    "Rank": 7276,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kamalnayan Bajaj Hospital (Marathwada Medical and Research Institutes), ,Kamalnayan Bajaj Hospital, Gut no.  43, Satara Parisar, Beed bypass road, Auranagabad., Maharashtra, 431010",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040fa"
    },
    "Rank": 7277,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040fb"
    },
    "Rank": 7278,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040fc"
    },
    "Rank": 7281,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040fd"
    },
    "Rank": 7282,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Philomena`s Hospital, ,No.4, Campbell Road, Viveknagar P.O. Bangalore - 560047, Karnataka, 560047",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040fe"
    },
    "Rank": 7286,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a040ff"
    },
    "Rank": 7287,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,HBT Medical College and Dr R N Cooper Municipal General Hospital, Bhaktivedanta Swami Rd, JVPD Schem, Maharashtra, 400056",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04100"
    },
    "Rank": 7288,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04101"
    },
    "Rank": 7289,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04102"
    },
    "Rank": 7290,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04103"
    },
    "Rank": 7292,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04104"
    },
    "Rank": 7293,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04105"
    },
    "Rank": 7297,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04106"
    },
    "Rank": 7300,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. GERIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04107"
    },
    "Rank": 7301,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04108"
    },
    "Rank": 7302,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04109"
    },
    "Rank": 7303,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0410a"
    },
    "Rank": 7304,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0410b"
    },
    "Rank": 7307,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M R Bangur Hospital, ,241&249 Desh Pran Sashmal Road kolkata, West Bengal, 700033",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0410c"
    },
    "Rank": 7308,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0410d"
    },
    "Rank": 7309,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0410e"
    },
    "Rank": 7311,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0410f"
    },
    "Rank": 7312,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04110"
    },
    "Rank": 7313,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04111"
    },
    "Rank": 7314,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chittaranjan Seva Sadan Hospital,37, S.P. MUKHERJEE ROAD, KOLKATA, West Bengal, 700026",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04112"
    },
    "Rank": 7315,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Institute of Medical Sciences (SVIMS), A State University estd. by Govt. of A.P.,Sri Venkateswara Institute of Medical Sciences, SVIMS, Alipiri Road, Tirupati, Andhra Pradesh, 517507",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04113"
    },
    "Rank": 7316,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Santokbha Durlabhji Memorial Hospital Cum Medical Research Institute,\n,dnb.sdmh@gmail.com, Rajasthan, 302015",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04114"
    },
    "Rank": 7317,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mata Chanan Devi Hospital,, ,C-1 JANAKPURI, Delhi (NCT), 110058",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04115"
    },
    "Rank": 7318,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04116"
    },
    "Rank": 7320,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,APOLLO HOSPITALS BANGALORE A UNIT OF IMPERIAL HOSPITALS AND RESEARCH CENTRE LIMITED 154/11 Opp IIM B, Karnataka, 560076",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04117"
    },
    "Rank": 7322,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Baba Saheb Ambedkar Hospital, ,SEC 6 ROHINI DELHI, Delhi (NCT), 110085",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04118"
    },
    "Rank": 7323,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04119"
    },
    "Rank": 7324,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0411a"
    },
    "Rank": 7325,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0411b"
    },
    "Rank": 7326,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Metro Hospital and Heart Institute, GURUGRAM, Haryana,H-BLOCK, PALAM VIHAR,GURUGRAM. HARYANA\nHaryana 122017, Haryana, 122017",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0411c"
    },
    "Rank": 7327,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Balaji Institute of Medical Sceince,,MOWA, RAIPUR, Chhattisgarh, 492001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0411d"
    },
    "Rank": 7328,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0411e"
    },
    "Rank": 7329,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0411f"
    },
    "Rank": 7330,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04120"
    },
    "Rank": 7332,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Paras Hospital,,C1 Sushant lok sector 43 gurugram, Haryana, 122002",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04121"
    },
    "Rank": 7335,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04122"
    },
    "Rank": 7336,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04123"
    },
    "Rank": 7339,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04124"
    },
    "Rank": 7341,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital, ,GMERS Medical College and General hospital,Old TB Campus, gotri road, Vadodara, Gujarat, 390021",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04125"
    },
    "Rank": 7342,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE, PUDUCHERRY,INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE,\nPUDUCHERRY, Puducherry, 605009",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04126"
    },
    "Rank": 7343,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04127"
    },
    "Rank": 7346,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04128"
    },
    "Rank": 7347,
    "Allotted Quota": "All India",
    "Allotted Institute": "NSC Government Medical College, Khandwa (MP),LUV KUSH NAGAR,MUNDI NAGAR, KHANDWA, Madhya Pradesh, 450001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04129"
    },
    "Rank": 7348,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute of Medical Sciences Srikakulam,Government Medical College Srikakulam\nBalaga Srikakulam Town Srikakulam District, Andhra Pradesh, 532001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0412a"
    },
    "Rank": 7352,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0412b"
    },
    "Rank": 7353,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0412c"
    },
    "Rank": 7354,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0412d"
    },
    "Rank": 7356,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0412e"
    },
    "Rank": 7358,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0412f"
    },
    "Rank": 7359,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04130"
    },
    "Rank": 7362,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sanjay Gandhi Memorial Hospital, , ,Sanjay Gandhi Memorial Hospital\nS-Block, Near Bus Terminal, Mangolpuri, North West, Delhi - 110083, Delhi (NCT), 110083",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04131"
    },
    "Rank": 7363,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04132"
    },
    "Rank": 7366,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04133"
    },
    "Rank": 7371,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04134"
    },
    "Rank": 7372,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04135"
    },
    "Rank": 7373,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04136"
    },
    "Rank": 7374,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04137"
    },
    "Rank": 7375,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04138"
    },
    "Rank": 7376,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04139"
    },
    "Rank": 7377,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0413a"
    },
    "Rank": 7378,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0413b"
    },
    "Rank": 7379,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0413c"
    },
    "Rank": 7380,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0413d"
    },
    "Rank": 7381,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0413e"
    },
    "Rank": 7383,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sankara Eye Hospital, ,Varthur Road, Kundalahalli gate, Bangalore 560037, Karnataka, 560037",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0413f"
    },
    "Rank": 7386,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Jamshedpur,Mahatma Gandhi Memorial Medical College, Jamshedpur, Jharkhand, 831020",
    "Course": "DIP.IN MEDICAL RADIO- DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04140"
    },
    "Rank": 7388,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Swami Dayanand Hospital, ,Dilshad Garden, Shahdara, Delhi, Delhi (NCT), 110095",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04141"
    },
    "Rank": 7389,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo BGS Hospital,,Apollo BGS Hospitals, Adhichunchangiri Road, Kuvempunagar, Mysore, Karnataka, 570023",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04142"
    },
    "Rank": 7390,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04143"
    },
    "Rank": 7391,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04144"
    },
    "Rank": 7393,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04145"
    },
    "Rank": 7396,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04146"
    },
    "Rank": 7397,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apple Saraswati Multispecialty Hospital, (Apple Hospitals and Research Institute Ltd.)\n,APPLE SARASWATI MULTISPECIALITY HOSPITAL APPLE HOSPITALS AND RESEARCH INSTITUTE LTD 804 2 805 2 E WA, Maharashtra, 416003",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04147"
    },
    "Rank": 7399,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "DIP.IN MEDICAL RADIO- DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04148"
    },
    "Rank": 7400,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04149"
    },
    "Rank": 7401,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESI Hospital, ,IG ESI HOSPITAL JHILMIL DELHI - 95, Delhi (NCT), 110095",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0414a"
    },
    "Rank": 7404,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0414b"
    },
    "Rank": 7405,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0414c"
    },
    "Rank": 7406,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Human care Medical Charitable Trust,Human Care Medical Charitable Trust. HCMCTManipal Hospital, Sector 6, DWARKA, NEW DELHI, Delhi (NCT), 110075",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0414d"
    },
    "Rank": 7407,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Lal Bahadur Shastri Government Medical College and Hospital , ,VPO- Bhangrotu, MC- Ner Chowk, District- Mandi, Himachal Pradesh, 175021",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0414e"
    },
    "Rank": 7409,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0414f"
    },
    "Rank": 7410,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04150"
    },
    "Rank": 7411,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Motors Hospital, ,KHARANGAJHAR, TELCO COLONY JAMSHEDPUR, Jharkhand, 831003",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04151"
    },
    "Rank": 7412,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04152"
    },
    "Rank": 7416,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kalra Hospital and Shri Ram Cardiothoracic and Neuro-Sciences Centre, ,,A-4,5 & 6, Kirti Nagar, New Delhi -110015, Delhi (NCT), 110015",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04153"
    },
    "Rank": 7418,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04154"
    },
    "Rank": 7419,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medicover Hospitals, (Formerly MaxCure Hospital) ,Medicover Hospitals , Opposite Cyber gate way , Ibis hotel Lane , hitech city\nMadhapur Hyderabad, Telangana, 500081",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04155"
    },
    "Rank": 7420,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04156"
    },
    "Rank": 7421,
    "Allotted Quota": "All India",
    "Allotted Institute": "Institute of Child Health, Kolkata,Institute of Child Health, Kolkata, West Bengal, 700017",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04157"
    },
    "Rank": 7422,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jalgaon,Jilha Peth, Government Hospital Campus, Jalgaon, Maharashtra, 425001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04158"
    },
    "Rank": 7424,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04159"
    },
    "Rank": 7426,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ashwani Sahakari Rugnalaya,\n,Survey No 7107/1 Tank plot No 180,North Sadar Bazar Solapur, Maharashtra, 413003",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0415a"
    },
    "Rank": 7428,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lisie Medical Institution, ,Kochi, Kerala, Kerala, 682018",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0415b"
    },
    "Rank": 7430,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0415c"
    },
    "Rank": 7432,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Motors Hospital, ,KHARANGAJHAR, TELCO COLONY JAMSHEDPUR, Jharkhand, 831003",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0415d"
    },
    "Rank": 7434,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0415e"
    },
    "Rank": 7436,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0415f"
    },
    "Rank": 7439,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04160"
    },
    "Rank": 7440,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaiprakash Hospital and Research Centre Pvt. Ltd, Odisha,,JAIPRAKASH HOSPITAL AND RESEARCH CENTER PVT. LTD., DANDIAPALI, ROURKELA, Odisha, 769004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04161"
    },
    "Rank": 7441,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04162"
    },
    "Rank": 7443,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lalitha Super Specialty Hospital, ,KOTHAPET GUNTUR, Andhra Pradesh, 522001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04163"
    },
    "Rank": 7444,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. R N Cooper Municipal General Hospital, (Associated with H B T (Hinduhridayasamrat Balasaheb Thackeray) medical College) ,HBTMC AND DR.R.N.COOPER HOSPITAL\nU 15 BHAKTI VEDANTA ROAD JVPD SCHEME VILE PARLE WEST MUMBAI,\nMaharashtra, 400056",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04164"
    },
    "Rank": 7447,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04165"
    },
    "Rank": 7448,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04166"
    },
    "Rank": 7449,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04167"
    },
    "Rank": 7451,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04168"
    },
    "Rank": 7454,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Gondia,Nehru Chowk, KTS General Hospital Campus, Govt. Medical College, Gondia. 441601. Maharashtra., Maharashtra, 441601",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04169"
    },
    "Rank": 7455,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0416a"
    },
    "Rank": 7458,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS MEDICAL COLLEGE, NEAR GUJARAT HIGH COURT, S.G HIGH WAY, SOLA, AHMEDABAD, Gujarat, 380060",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0416b"
    },
    "Rank": 7459,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital Whitefield,,ITPL Main Rd, KIADB Export Promotion Industrial Area, Krishnarajapura, Bengaluru, Karnataka, 560066",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0416c"
    },
    "Rank": 7460,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute Of Medical Sciences, Kalaburagi,VEERESH NAGAR SEDAM ROAD KALABURAGI, Karnataka, 585105",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0416d"
    },
    "Rank": 7461,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0416e"
    },
    "Rank": 7462,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0416f"
    },
    "Rank": 7463,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ozone Multispeciality hospital and Critical care center, Maharashtra,Holycross convent Road, Kedia Plots, Akola, Maharashtra, 444005",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04170"
    },
    "Rank": 7464,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04171"
    },
    "Rank": 7467,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Central Hospital, ,CENTRAL HOSPITAL, NORTH WESTERN RAILWAY, GANPATI NAGAR, HASANPURA ROAD, JAIPUR, Rajasthan, 302006",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04172"
    },
    "Rank": 7469,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04173"
    },
    "Rank": 7470,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Alexis Multispecialty Hospital,232, Mankapur Square, Koradi road, NAGPUR, Maharashtra, 440030",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04174"
    },
    "Rank": 7474,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Mundian Kalan, Chandigarh Road, Ludhiana-141015, Punjab, 141015",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04175"
    },
    "Rank": 7478,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04176"
    },
    "Rank": 7481,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04177"
    },
    "Rank": 7483,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lalitha Super Specialty Hospital, ,KOTHAPET GUNTUR, Andhra Pradesh, 522001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04178"
    },
    "Rank": 7484,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04179"
    },
    "Rank": 7485,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharashtra Post Graduate Institute of Medical Education and Research, Nashik,Maharashtra Post Graduation Institute Of Medical Education and Research Civil Hospital campus Trimba, Maharashtra, 422002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0417a"
    },
    "Rank": 7486,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Valluvanad Hospital Complex Limited,KANNIYAMPURAM POST OTTAPALAM\nPALAKKAD KERALA, Kerala, 679104",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0417b"
    },
    "Rank": 7487,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0417c"
    },
    "Rank": 7488,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0417d"
    },
    "Rank": 7489,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0417e"
    },
    "Rank": 7490,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0417f"
    },
    "Rank": 7491,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04180"
    },
    "Rank": 7492,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04181"
    },
    "Rank": 7493,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04182"
    },
    "Rank": 7495,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apple Saraswati Multispecialty Hospital, (Apple Hospitals and Research Institute Ltd.)\n,APPLE SARASWATI MULTISPECIALITY HOSPITAL APPLE HOSPITALS AND RESEARCH INSTITUTE LTD 804 2 805 2 E WA, Maharashtra, 416003",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04183"
    },
    "Rank": 7496,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04184"
    },
    "Rank": 7497,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04185"
    },
    "Rank": 7499,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04186"
    },
    "Rank": 7501,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04187"
    },
    "Rank": 7503,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS MEDICAL COLLEGE, NEAR GUJARAT HIGH COURT, S.G HIGH WAY, SOLA, AHMEDABAD, Gujarat, 380060",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04188"
    },
    "Rank": 7504,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04189"
    },
    "Rank": 7505,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0418a"
    },
    "Rank": 7507,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0418b"
    },
    "Rank": 7508,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "CMC Hospital,DABRA CHOWK , DELHI ROAD, HISAR, Haryana, 125001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0418c"
    },
    "Rank": 7509,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharashtra Post Graduate Institute of Medical Education and Research, Nashik,Maharashtra Post Graduation Institute Of Medical Education and Research Civil Hospital campus Trimba, Maharashtra, 422002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0418d"
    },
    "Rank": 7510,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Children`s Hospital, ,Rainbow Childrens Hospital, Road No.2, Banjara Hills, Hyderabad, Telangana, 500034",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0418e"
    },
    "Rank": 7511,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0418f"
    },
    "Rank": 7512,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04190"
    },
    "Rank": 7513,
    "Allotted Quota": "All India",
    "Allotted Institute": "MALDA MEDICAL COLLEGE,MALDA MEDICAL COLLEGE, West Bengal, 732101",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04191"
    },
    "Rank": 7515,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04192"
    },
    "Rank": 7516,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government General Hospital, ,Hospital Rd, Marine Drive, Ernakulam, Kerala 682011, Kerala, 682011",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04193"
    },
    "Rank": 7517,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04194"
    },
    "Rank": 7518,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04195"
    },
    "Rank": 7520,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04196"
    },
    "Rank": 7521,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04197"
    },
    "Rank": 7522,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,Government Medical College, Near RTC Bus stand, Gollaguda, Nalgonda Telangana - 508001, Telangana, 508001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04198"
    },
    "Rank": 7523,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04199"
    },
    "Rank": 7524,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0419a"
    },
    "Rank": 7528,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0419b"
    },
    "Rank": 7529,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0419c"
    },
    "Rank": 7530,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0419d"
    },
    "Rank": 7531,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES ADILABAD -504001 TELANGANA STATE, Telangana, 504001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0419e"
    },
    "Rank": 7532,
    "Allotted Quota": "All India",
    "Allotted Institute": "Institute of Child Health, Kolkata,Institute of Child Health, Kolkata, West Bengal, 700017",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0419f"
    },
    "Rank": 7533,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,HBT Medical College and Dr R N Cooper Municipal General Hospital, Bhaktivedanta Swami Rd, JVPD Schem, Maharashtra, 400056",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041a0"
    },
    "Rank": 7534,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041a1"
    },
    "Rank": 7536,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VILLUPURAM MEDICAL COLLEGE, VILLUPURAM,GOVERNMENT VILLUPURAM MEDICAL COLLEGE VILLUPURAM  TRICHY TRUNK ROAD MUNDIYAMPAKKAM  VILLUPURAM DISTR, Tamil Nadu, 605601",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041a2"
    },
    "Rank": 7539,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041a3"
    },
    "Rank": 7541,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041a4"
    },
    "Rank": 7542,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041a5"
    },
    "Rank": 7543,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041a6"
    },
    "Rank": 7544,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041a7"
    },
    "Rank": 7547,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041a8"
    },
    "Rank": 7548,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041a9"
    },
    "Rank": 7550,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041aa"
    },
    "Rank": 7552,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041ab"
    },
    "Rank": 7554,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chinmaya Mission Hospital,,Chinmaya Mission Hospital, 80 Feet CMH Road, Indiranagar, Bangalore, Karnataka, 560038",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041ac"
    },
    "Rank": 7555,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. Medical College (Maharani Hospital), Jagdalpur, Chhattisgarh,Late Baliram Kashyap memorial Govt. Medical College, Dimrapal, Jagdalpur Dist-Bastar, Chhattisgarh, 494001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041ad"
    },
    "Rank": 7556,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollomedics Superspeciality Hospital,KBC 31, Sector B, LDA Colony, Kanpur Road, Lucknow, Uttar Pradesh, 226012",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041ae"
    },
    "Rank": 7557,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kingsway Hospitals, Maharashtra,Medisearch Lifesciences Pvt  Ltd 44SPANV Kingsway Rd near Kasturchand Park Nagpur Maharashtra 440001, Maharashtra, 440001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041af"
    },
    "Rank": 7558,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041b0"
    },
    "Rank": 7559,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041b1"
    },
    "Rank": 7560,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine and JNM Hospital, WBUHS,Block A, Post Office Kalyani, Near Silpanchal Station,\nBlock A2, District Nadia, West Bengal, West Bengal, 741235",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041b2"
    },
    "Rank": 7561,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, THOOTHUKUDI,GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, 3rd\nMile,Kamaraj Nagar,Thoothukudi., Tamil Nadu, 628008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041b3"
    },
    "Rank": 7562,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041b4"
    },
    "Rank": 7563,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041b5"
    },
    "Rank": 7564,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041b6"
    },
    "Rank": 7565,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041b7"
    },
    "Rank": 7566,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dharwad Institute of Mental Health and Neurosciences (DIMHANS),Dharwad Institute of Mental Health and Neurosciences, Belagavi Road, Dharwad, Karnataka, 580008",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041b8"
    },
    "Rank": 7569,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Children`s Hospital, ,Rainbow Childrens Hospital, Road No.2, Banjara Hills, Hyderabad, Telangana, 500034",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041b9"
    },
    "Rank": 7571,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Alexis Multispecialty Hospital,232, Mankapur Square, Koradi road, NAGPUR, Maharashtra, 440030",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041ba"
    },
    "Rank": 7572,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041bb"
    },
    "Rank": 7573,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041bc"
    },
    "Rank": 7574,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvarur Govt.Medical College,Thiruvarur Govt.Medical College, Tamil Nadu, 610004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041bd"
    },
    "Rank": 7580,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041be"
    },
    "Rank": 7581,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041bf"
    },
    "Rank": 7582,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Medical College and Chhatrapati Shivaji Maharaj Hospital, ,RAJIV GANDHI MEDICAL COLLEGE,THANE BELAPUR ROAD, KALWA, THANE, Maharashtra, 400605",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041c0"
    },
    "Rank": 7583,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041c1"
    },
    "Rank": 7585,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041c2"
    },
    "Rank": 7586,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041c3"
    },
    "Rank": 7588,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041c4"
    },
    "Rank": 7589,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041c5"
    },
    "Rank": 7592,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041c6"
    },
    "Rank": 7593,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "B.R. Singh Hospital and Centre for Medical Education and Research,,B.R.Singh Hospital Eastern Railway, Sealdah, Kolkata, West Bengal, 700014",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041c7"
    },
    "Rank": 7594,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lisie Medical Institution, ,Kochi, Kerala, Kerala, 682018",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041c8"
    },
    "Rank": 7595,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041c9"
    },
    "Rank": 7596,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College, Kolhapur,R.K. Nagar Road, Shenda Park, Kolhapur, Maharashtra, Maharashtra, 416012",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041ca"
    },
    "Rank": 7597,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041cb"
    },
    "Rank": 7600,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041cc"
    },
    "Rank": 7602,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041cd"
    },
    "Rank": 7604,
    "Allotted Quota": "All India",
    "Allotted Institute": "MALDA MEDICAL COLLEGE,MALDA MEDICAL COLLEGE, West Bengal, 732101",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041ce"
    },
    "Rank": 7605,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041cf"
    },
    "Rank": 7607,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "C.S.I. Kalyani General Hospital,, ,15, DR RADHAKRISHNAN SALAI, MYLAPORE, CHENNAI, Tamil Nadu, 600004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041d0"
    },
    "Rank": 7608,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041d1"
    },
    "Rank": 7610,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041d2"
    },
    "Rank": 7611,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041d3"
    },
    "Rank": 7613,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041d4"
    },
    "Rank": 7614,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. GERIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041d5"
    },
    "Rank": 7615,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041d6"
    },
    "Rank": 7616,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041d7"
    },
    "Rank": 7618,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041d8"
    },
    "Rank": 7622,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041d9"
    },
    "Rank": 7626,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "AUTONOMOUS STATE MEDICAL COLLEGE, ,RAJARSHI DASHRATH AUTONOMOUS STATE MEDICAL COLLEGE VILL- GANJA, POST- DABHA SEMAR, DISTRICT- AYODHYA, Uttar Pradesh, 224133",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041da"
    },
    "Rank": 7628,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041db"
    },
    "Rank": 7629,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041dc"
    },
    "Rank": 7630,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041dd"
    },
    "Rank": 7631,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041de"
    },
    "Rank": 7633,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences Koppal, Karnataka,Gangavati Road Kiddidal Gate koppal, Karnataka, 583231",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041df"
    },
    "Rank": 7634,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041e0"
    },
    "Rank": 7635,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041e1"
    },
    "Rank": 7636,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041e2"
    },
    "Rank": 7638,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.J. Somaiya Medical College,,SOMAIYA AYURVIHAR , EASTERN EXPRESS HIGHWAY, SION, MUMBAI 400022, Maharashtra, 400022",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041e3"
    },
    "Rank": 7642,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE,ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, Bihar, 823001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041e4"
    },
    "Rank": 7644,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S. K. S. Hospital, ,NO.23,SKS Hospital Road, Alagapuram,Salem, Tamil Nadu, 636004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041e5"
    },
    "Rank": 7645,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Valluvanad Hospital Complex Limited,KANNIYAMPURAM POST OTTAPALAM\nPALAKKAD KERALA, Kerala, 679104",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041e6"
    },
    "Rank": 7647,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041e7"
    },
    "Rank": 7648,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College and  PGIMSR,ESIC Medical College and  PGIMSR, KK Nagar, Chennai - 600 078, Tamil Nadu, 600078",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041e8"
    },
    "Rank": 7649,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Rela Institute and Medical Centre ,\nTamil Nadu,NO.7, CLC WORKS ROAD, CHROMPET CHENNAI, Tamil Nadu, 600044",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041e9"
    },
    "Rank": 7650,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, (Formerly- Pushpanjali Crosslay Hospital) ,W-3, Sector-1, Vaishali, Ghaziabad, Uttar Pradesh, 201012",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041ea"
    },
    "Rank": 7651,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. GERIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041eb"
    },
    "Rank": 7652,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041ec"
    },
    "Rank": 7655,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jalgaon,Jilha Peth, Government Hospital Campus, Jalgaon, Maharashtra, 425001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041ed"
    },
    "Rank": 7658,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041ee"
    },
    "Rank": 7663,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041ef"
    },
    "Rank": 7664,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041f0"
    },
    "Rank": 7666,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041f1"
    },
    "Rank": 7667,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041f2"
    },
    "Rank": 7670,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "C.S.I. Holdsworth Memorial Hospital, ,P.B.No. 38, Sawday Road, Mandi Mohalla, Mysuru, Karnataka, 570001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041f3"
    },
    "Rank": 7671,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041f4"
    },
    "Rank": 7673,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SANKARA NETHRALAYA ,\nTamil Nadu,No 21 Pycrofts Garden Road off. Haddows Road\nChennai, Tamil Nadu, 600006",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041f5"
    },
    "Rank": 7674,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apex Hospital, ,SP 4 AND 6 MALVIYA INDUSTRIAL AREA MALVIYA NAGAR , JAIPUR,\nRajasthan, 302017",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041f6"
    },
    "Rank": 7676,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041f7"
    },
    "Rank": 7677,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041f8"
    },
    "Rank": 7678,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041f9"
    },
    "Rank": 7679,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vivekananda Polyclinic and Institute of Medical Sciences, ,Vivekananda Polyclinic and Institute of Medical Sciences, Vivekananda Puram, Lucknow, Uttar Pradesh, 226007",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041fa"
    },
    "Rank": 7682,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041fb"
    },
    "Rank": 7683,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Health City, ,Apollo Hospitals, Health City, Chinagadhili, Visakhapatnam, Andhra Pradesh, 530040",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041fc"
    },
    "Rank": 7685,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041fd"
    },
    "Rank": 7687,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041fe"
    },
    "Rank": 7689,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a041ff"
    },
    "Rank": 7690,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Seven Hills Hospital, ,11-4-4/A, Rockdale Layout, Visakhapatnam, Andhra Pradesh, 530002",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04200"
    },
    "Rank": 7692,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04201"
    },
    "Rank": 7693,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04202"
    },
    "Rank": 7696,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chandan Hospital, ,Vijayant Khand, Gomti Nagar, Lucknow, Uttar Pradesh, 226010",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04203"
    },
    "Rank": 7697,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04204"
    },
    "Rank": 7698,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04205"
    },
    "Rank": 7699,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04206"
    },
    "Rank": 7701,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04207"
    },
    "Rank": 7702,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04208"
    },
    "Rank": 7703,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04209"
    },
    "Rank": 7704,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0420a"
    },
    "Rank": 7705,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Philomena`s Hospital, ,No.4, Campbell Road, Viveknagar P.O. Bangalore - 560047, Karnataka, 560047",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0420b"
    },
    "Rank": 7706,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0420c"
    },
    "Rank": 7710,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0420d"
    },
    "Rank": 7712,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0420e"
    },
    "Rank": 7713,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "C.S.I. Holdsworth Memorial Hospital, ,P.B.No. 38, Sawday Road, Mandi Mohalla, Mysuru, Karnataka, 570001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0420f"
    },
    "Rank": 7714,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04210"
    },
    "Rank": 7715,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Paras HMRI Hospital, ,Paras HMRI Hospital\nNH 30 Bailey Road Raja Bazaar Patna 800014 Bihar, Bihar, 800014",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04211"
    },
    "Rank": 7716,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Calcutta Medical Research Institute, ,The Calcutta Medical Research Institute 7 by 2 Diamond Harbour Road Kolkata 700027, West Bengal, 700027",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04212"
    },
    "Rank": 7717,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04213"
    },
    "Rank": 7718,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College, ,Office of the Dean, First Floor, Civil Hospital Campus, Sector- 12, Gandhinagar, Gujarat, 382016",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04214"
    },
    "Rank": 7719,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04215"
    },
    "Rank": 7721,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04216"
    },
    "Rank": 7722,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04217"
    },
    "Rank": 7723,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Stephen`s Hospital, ,Tis Hazari, Delhi (NCT), 110054",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04218"
    },
    "Rank": 7725,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lalitha Super Specialty Hospital, ,KOTHAPET GUNTUR, Andhra Pradesh, 522001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04219"
    },
    "Rank": 7729,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bokaro General Hospital, ,SECTOR - 4 BOKARO STEEL CITY\nDIST - BOKARO, Jharkhand, 827004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0421a"
    },
    "Rank": 7730,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deen Dayal Upadhyay Hospital, ,DDU HOSPITAL, SHAHEED MANGAL PANDEY MARG, NANAK PURA, HARI NAGAR, NEW DELHI, DELHI-110064, Delhi (NCT), 110064",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0421b"
    },
    "Rank": 7732,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0421c"
    },
    "Rank": 7733,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubnagar,Government Medical College, Yedira, Mahabubnagar, Telangana state, Telangana, 509002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0421d"
    },
    "Rank": 7738,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0421e"
    },
    "Rank": 7739,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Sector-62, Phase VIII, Mohali- 160062, Punjab, 160062",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0421f"
    },
    "Rank": 7740,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04220"
    },
    "Rank": 7742,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04221"
    },
    "Rank": 7743,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Arvind Eye Hospital , ,Aravind Eye Hospital & Postgraduate Institute of Ophthalmology, Avinashi Road, Civil Aerodrome Post,, Tamil Nadu, 641014",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04222"
    },
    "Rank": 7745,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04223"
    },
    "Rank": 7746,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindu Rao Hospital, ,SUBZI MANDI, MALKA GANJ, DELHI-110007, Delhi (NCT), 110007",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04224"
    },
    "Rank": 7749,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04225"
    },
    "Rank": 7751,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04226"
    },
    "Rank": 7752,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. James Hospital, ,Old Highway, CHALAKUDY, Kerala, 680307",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04227"
    },
    "Rank": 7757,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04228"
    },
    "Rank": 7758,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04229"
    },
    "Rank": 7759,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0422a"
    },
    "Rank": 7760,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0422b"
    },
    "Rank": 7761,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0422c"
    },
    "Rank": 7764,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0422d"
    },
    "Rank": 7765,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lalitha Super Specialty Hospital, ,KOTHAPET GUNTUR, Andhra Pradesh, 522001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0422e"
    },
    "Rank": 7767,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0422f"
    },
    "Rank": 7768,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04230"
    },
    "Rank": 7770,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04231"
    },
    "Rank": 7773,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04232"
    },
    "Rank": 7774,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04233"
    },
    "Rank": 7778,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. TMA Pai Hospital, Udupi, Karnataka-576101,Opposite Old Taluk office, Court Road, Udupi, Karnataka, 576101",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04234"
    },
    "Rank": 7779,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gujarat Institute of Mental Health Hospital, ,Hospital for Mental Health, Outside Delhi Gate,\nShahibug, Ahmedabad, Gujarat, 380004",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04235"
    },
    "Rank": 7781,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04236"
    },
    "Rank": 7782,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chinmaya Mission Hospital,,Chinmaya Mission Hospital, 80 Feet CMH Road, Indiranagar, Bangalore, Karnataka, 560038",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04237"
    },
    "Rank": 7784,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Head Quarters Hospital,,H NO 3 3 141 MNCL X ROADS KARIMNAGAR KARIMNAGAR DISTRICT, Telangana, 505001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04238"
    },
    "Rank": 7785,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04239"
    },
    "Rank": 7786,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College, Kolhapur,R.K. Nagar Road, Shenda Park, Kolhapur, Maharashtra, Maharashtra, 416012",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0423a"
    },
    "Rank": 7787,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0423b"
    },
    "Rank": 7789,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0423c"
    },
    "Rank": 7790,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government General Hospital, ,Government General Hospital Thiruvananthapuram, Vanchiyoor PO Thiruvananthapuram Kerala, Kerala, 695035",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0423d"
    },
    "Rank": 7791,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Motors Hospital, ,KHARANGAJHAR, TELCO COLONY JAMSHEDPUR, Jharkhand, 831003",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0423e"
    },
    "Rank": 7794,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gvernment Medical College,Haldwani,Government Medical College, Haldwani, Uttarakhand, 263139",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0423f"
    },
    "Rank": 7795,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04240"
    },
    "Rank": 7798,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04241"
    },
    "Rank": 7799,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ramkrishna Care Hospital, ,AUROBINDO ENCLAVE, PACHPEDI NAKA,DAHAMTARI ROAD, RAIPUR, Chhattisgarh, 492001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04242"
    },
    "Rank": 7800,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College and  PGIMSR,ESIC Medical College and  PGIMSR, KK Nagar, Chennai - 600 078, Tamil Nadu, 600078",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04243"
    },
    "Rank": 7801,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04244"
    },
    "Rank": 7802,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindustan Aeronautics Hospital, ,SURANJANDAS ROAD ,VIMANAPURA POST BANGALORE -560017, Karnataka, 560017",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04245"
    },
    "Rank": 7803,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government Women and Children Hospital, (Formerly Indira Gandhi.\nGeneral Hospital) ,100 feet road, Ellaipillaichavady, Puducherry, 605005",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04246"
    },
    "Rank": 7804,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Venkateshwar Hospital, ,Sector- 18 A, Dwarka, New Delhi, Delhi (NCT), 110075",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04247"
    },
    "Rank": 7805,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "DIPLOMA IN CHILD HEALTH/ PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04248"
    },
    "Rank": 7806,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04249"
    },
    "Rank": 7808,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0424a"
    },
    "Rank": 7809,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Erode Medical College- Formerly IRT-Perundurai Medical College,GOVERNMENT ERODE MEDICAL COLLEGE AND  HOSPITAL PERUNDURAI SANATORIUM POST ERODE  DISTRICT, Tamil Nadu, 638053",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0424b"
    },
    "Rank": 7810,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0424c"
    },
    "Rank": 7811,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0424d"
    },
    "Rank": 7816,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0424e"
    },
    "Rank": 7817,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0424f"
    },
    "Rank": 7818,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04250"
    },
    "Rank": 7821,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04251"
    },
    "Rank": 7822,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Specialty Hospitals, ,IIIM NEHRU NAGAR GHAZIABAD, Uttar Pradesh, 201001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04252"
    },
    "Rank": 7823,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Doon Medical College, Dehradun,Govt. Doon Medical College Dehrakhas, Patel Nagar Dehradun 248001, Uttarakhand, 248001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04253"
    },
    "Rank": 7824,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04254"
    },
    "Rank": 7825,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Malabar Institute of Medical Sciences, ,Govindapuram P.O., Mini Bypass Road, Kozhikode, Kerala, 673016",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04255"
    },
    "Rank": 7826,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04256"
    },
    "Rank": 7827,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharishi Vashistha Autonomous State Medical College and Opec Hospital,\n,Rampur Tahsil Sadar Basti Basti, Uttar Pradesh, 272124",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04257"
    },
    "Rank": 7830,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B. C. Roy Post Graduate Institute of Paediatric Sciences,111 Narkeldanga Main Road Kolkata, West Bengal, 700054",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04258"
    },
    "Rank": 7831,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04259"
    },
    "Rank": 7836,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0425a"
    },
    "Rank": 7838,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Rela Institute and Medical Centre ,\nTamil Nadu,NO.7, CLC WORKS ROAD, CHROMPET CHENNAI, Tamil Nadu, 600044",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0425b"
    },
    "Rank": 7839,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mother Hospital, ,P.O PULLAZHI, OLARI,THRISSUR-680012, Kerala, 680012",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0425c"
    },
    "Rank": 7842,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0425d"
    },
    "Rank": 7846,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Santokbha Durlabhji Memorial Hospital Cum Medical Research Institute,\n,dnb.sdmh@gmail.com, Rajasthan, 302015",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0425e"
    },
    "Rank": 7848,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sparsh Super Specialty Hospital, ,SPARSH SUPER SPECIALITY HOSPITAL, TUMKUR ROAD, YESHWANTHPURA, BANGALORE, Karnataka, 560022",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0425f"
    },
    "Rank": 7851,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04260"
    },
    "Rank": 7852,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Specialty Hospitals, ,IIIM NEHRU NAGAR GHAZIABAD, Uttar Pradesh, 201001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04261"
    },
    "Rank": 7853,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04262"
    },
    "Rank": 7854,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04263"
    },
    "Rank": 7856,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04264"
    },
    "Rank": 7858,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04265"
    },
    "Rank": 7859,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04266"
    },
    "Rank": 7860,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Durgapur Steel Plant Hospital, ,J M Sengupta Road, B Zone,  Durgapur, Dist- Paschim Bardhaman, West Bengal, 713205",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04267"
    },
    "Rank": 7861,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Vallabhbhai Patel Chest Institute, Delhi,Vallabhbhai Patel Chest Institute, Delhi, Delhi (NCT), 110007",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04268"
    },
    "Rank": 7862,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04269"
    },
    "Rank": 7863,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Cosmopolitan Hospital,,COSMOPOLITAN HOSPITALS Pvt.Ltd, Murinjapalam, Pattom P O, Thiruvananthapuram, Kerala, 695004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0426a"
    },
    "Rank": 7864,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "PBMA`S H V Desai Eye Hospital, ,93, Tarwade Vasti, Mohammadwadi, Pune, Maharashtra, 411060",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0426b"
    },
    "Rank": 7865,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0426c"
    },
    "Rank": 7867,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Hospital, ,ALEXANDER ROAD, BESIDE HARIHARA KALABHAVAN, NEAR CLOCK TOWER, SECUNDERBAD, Telangana, 500003",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0426d"
    },
    "Rank": 7868,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0426e"
    },
    "Rank": 7870,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0426f"
    },
    "Rank": 7871,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Park Hospital, NEW DELHI,12 MEERA ENCLAVE CHOWKHANDI NEAR KESHOPUR BUS DEPOT OUTER RING ROAD NEW DELHI 110018, Delhi (NCT), 110018",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04270"
    },
    "Rank": 7875,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04271"
    },
    "Rank": 7876,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04272"
    },
    "Rank": 7877,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BHEL Kasturba Hospital,,KASTURBA HOSPITAL, BHEL, HABIBGANJ, BHOPAL,\nMadhya Pradesh, 462024",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04273"
    },
    "Rank": 7878,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04274"
    },
    "Rank": 7880,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pimpri Chichwad Municipal Corporation Postgraduate Institute Y.C.M.Hospital Pimpri,Pune 18,Dean Office, Y.C.M.Hospital, Sant Tukaramnagar Pimpri, Pune, Maharashtra, 411018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04275"
    },
    "Rank": 7881,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruby General Hospital, ,576 Anandapur, Kasba Golpark, E. M. Bypass, Kolkata 700107, West Bengal, 700107",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04276"
    },
    "Rank": 7882,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04277"
    },
    "Rank": 7885,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04278"
    },
    "Rank": 7886,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04279"
    },
    "Rank": 7889,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0427a"
    },
    "Rank": 7890,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0427b"
    },
    "Rank": 7891,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0427c"
    },
    "Rank": 7892,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apple Saraswati Multispecialty Hospital, (Apple Hospitals and Research Institute Ltd.)\n,APPLE SARASWATI MULTISPECIALITY HOSPITAL APPLE HOSPITALS AND RESEARCH INSTITUTE LTD 804 2 805 2 E WA, Maharashtra, 416003",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0427d"
    },
    "Rank": 7894,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0427e"
    },
    "Rank": 7895,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0427f"
    },
    "Rank": 7896,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04280"
    },
    "Rank": 7897,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04281"
    },
    "Rank": 7899,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04282"
    },
    "Rank": 7902,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04283"
    },
    "Rank": 7904,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04284"
    },
    "Rank": 7905,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04285"
    },
    "Rank": 7906,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04286"
    },
    "Rank": 7908,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04287"
    },
    "Rank": 7911,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Choithram Hospital and Research Centre,Manika Bagh Road Indore, Madhya Pradesh, 452014",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04288"
    },
    "Rank": 7912,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04289"
    },
    "Rank": 7915,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medicover Hospitals, (Formerly MaxCure Hospital) ,Medicover Hospitals , Opposite Cyber gate way , Ibis hotel Lane , hitech city\nMadhapur Hyderabad, Telangana, 500081",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0428a"
    },
    "Rank": 7918,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, THOOTHUKUDI,GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, 3rd\nMile,Kamaraj Nagar,Thoothukudi., Tamil Nadu, 628008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0428b"
    },
    "Rank": 7920,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0428c"
    },
    "Rank": 7921,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) Paediatric Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0428d"
    },
    "Rank": 7924,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0428e"
    },
    "Rank": 7926,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0428f"
    },
    "Rank": 7929,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VILLUPURAM MEDICAL COLLEGE, VILLUPURAM,GOVERNMENT VILLUPURAM MEDICAL COLLEGE VILLUPURAM  TRICHY TRUNK ROAD MUNDIYAMPAKKAM  VILLUPURAM DISTR, Tamil Nadu, 605601",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04290"
    },
    "Rank": 7933,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04291"
    },
    "Rank": 7935,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B L Kapur Memorial Hospital,Pusa Road New Delhi, Delhi (NCT), 110005",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04292"
    },
    "Rank": 7937,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Seven Hills Hospital, ,11-4-4/A, Rockdale Layout, Visakhapatnam, Andhra Pradesh, 530002",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04293"
    },
    "Rank": 7938,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04294"
    },
    "Rank": 7939,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04295"
    },
    "Rank": 7940,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VELLORE MEDICAL COLLEGE,GOVERNMENT VELLORE MEDICAL COLLEGE, Tamil Nadu, 632011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04296"
    },
    "Rank": 7942,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04297"
    },
    "Rank": 7943,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04298"
    },
    "Rank": 7944,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VILLUPURAM MEDICAL COLLEGE, VILLUPURAM,GOVERNMENT VILLUPURAM MEDICAL COLLEGE VILLUPURAM  TRICHY TRUNK ROAD MUNDIYAMPAKKAM  VILLUPURAM DISTR, Tamil Nadu, 605601",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04299"
    },
    "Rank": 7948,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College,Theni ,Government Medical College,Theni, Tamil Nadu, 625512",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0429a"
    },
    "Rank": 7952,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0429b"
    },
    "Rank": 7955,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0429c"
    },
    "Rank": 7956,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, Uttar Pradesh,H-1, 24,26,27, KAUSHAMBI, NEAR DABUR CHOWK, GHAZIABAD, Uttar Pradesh, 201010",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0429d"
    },
    "Rank": 7958,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0429e"
    },
    "Rank": 7959,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Gondia,Nehru Chowk, KTS General Hospital Campus, Govt. Medical College, Gondia. 441601. Maharashtra., Maharashtra, 441601",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0429f"
    },
    "Rank": 7962,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042a0"
    },
    "Rank": 7963,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042a1"
    },
    "Rank": 7967,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jagjivan Ram Railway Hospital,,Maratha Mandir Marg, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042a2"
    },
    "Rank": 7971,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042a3"
    },
    "Rank": 7972,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "YATHARTH SUPER SPECIALTY HOSPITALS,\nUttar Pradesh,Sector omega 01-, Greater noida, Uttar Pradesh, 201308",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042a4"
    },
    "Rank": 7975,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042a5"
    },
    "Rank": 7976,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital , ,GMERS MEDICAL COLLEGE , Hadiyol-Gadhoda Road, Near-Government Polytechnic, Hiimmatnagar, Gujarat, 383001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042a6"
    },
    "Rank": 7978,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042a7"
    },
    "Rank": 7979,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042a8"
    },
    "Rank": 7982,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Bundelkhand Medical College Sagar M.P.,Govt. Bundelkhand Medical College Sagar M.P., Madhya Pradesh, 470001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042a9"
    },
    "Rank": 7983,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042aa"
    },
    "Rank": 7984,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sparsh Super Specialty Hospital, ,SPARSH SUPER SPECIALITY HOSPITAL, TUMKUR ROAD, YESHWANTHPURA, BANGALORE, Karnataka, 560022",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042ab"
    },
    "Rank": 7986,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042ac"
    },
    "Rank": 7991,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Lal Bahadur Shastri Government Medical College and Hospital , ,VPO- Bhangrotu, MC- Ner Chowk, District- Mandi, Himachal Pradesh, 175021",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042ad"
    },
    "Rank": 7992,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Jamshedpur,Mahatma Gandhi Memorial Medical College, Jamshedpur, Jharkhand, 831020",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042ae"
    },
    "Rank": 7993,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042af"
    },
    "Rank": 7994,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042b0"
    },
    "Rank": 7995,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Arvind Eye Hospital , ,Aravind Eye Hospital & Postgraduate Institute of Ophthalmology, Avinashi Road, Civil Aerodrome Post,, Tamil Nadu, 641014",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042b1"
    },
    "Rank": 7996,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042b2"
    },
    "Rank": 7997,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow hospital for women and children, ,Survey No. 141, Door No: 1-2-20, Opp.\nChermas, Hydernagar, Hyderabad, Telangana-500072",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042b3"
    },
    "Rank": 7998,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042b4"
    },
    "Rank": 7999,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sanjay Gandhi Postgarduate Institute of Medical Sciences,Sanjay Gandhi Postgarduate Institute of Medical Sciences, Uttar Pradesh, 226014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042b5"
    },
    "Rank": 8000,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042b6"
    },
    "Rank": 8001,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042b7"
    },
    "Rank": 8002,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042b8"
    },
    "Rank": 8003,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042b9"
    },
    "Rank": 8004,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042ba"
    },
    "Rank": 8005,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medical Trust Hospital, ,M.G. Road Pallimukku\nCochin, Kerala, 682016",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042bb"
    },
    "Rank": 8007,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042bc"
    },
    "Rank": 8008,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042bd"
    },
    "Rank": 8009,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "C.S.I. Kalyani General Hospital,, ,15, DR RADHAKRISHNAN SALAI, MYLAPORE, CHENNAI, Tamil Nadu, 600004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042be"
    },
    "Rank": 8011,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, Uttar Pradesh,H-1, 24,26,27, KAUSHAMBI, NEAR DABUR CHOWK, GHAZIABAD, Uttar Pradesh, 201010",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042bf"
    },
    "Rank": 8013,
    "Allotted Quota": "All India",
    "Allotted Institute": "MALDA MEDICAL COLLEGE,MALDA MEDICAL COLLEGE, West Bengal, 732101",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042c0"
    },
    "Rank": 8016,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042c1"
    },
    "Rank": 8017,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042c2"
    },
    "Rank": 8019,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042c3"
    },
    "Rank": 8022,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042c4"
    },
    "Rank": 8025,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deen Dayal Upadhyay Hospital, ,DDU HOSPITAL, SHAHEED MANGAL PANDEY MARG, NANAK PURA, HARI NAGAR, NEW DELHI, DELHI-110064, Delhi (NCT), 110064",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042c5"
    },
    "Rank": 8027,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "South Central Railways Hospital, ,SOUTH CENTRAL RAILWAYS CENTRAL HOSPITAL, OFFICE OF THE MEDICAL DIRECTOR, 1ST FLOOR\nLALLAGUDA, METT, Telangana, 500017",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042c6"
    },
    "Rank": 8030,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042c7"
    },
    "Rank": 8032,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kingsway Hospitals, Maharashtra,Medisearch Lifesciences Pvt  Ltd 44SPANV Kingsway Rd near Kasturchand Park Nagpur Maharashtra 440001, Maharashtra, 440001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042c8"
    },
    "Rank": 8033,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042c9"
    },
    "Rank": 8035,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042ca"
    },
    "Rank": 8038,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042cb"
    },
    "Rank": 8039,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042cc"
    },
    "Rank": 8041,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042cd"
    },
    "Rank": 8042,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042ce"
    },
    "Rank": 8043,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042cf"
    },
    "Rank": 8047,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042d0"
    },
    "Rank": 8050,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Ramesh Cardiac and Multi Speciality Hospital,,Collector office road, Nagarampalem, Guntur , AP, Andhra Pradesh, 522004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042d1"
    },
    "Rank": 8051,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahavir Hospital and Research Centre, ,10 1 1 Bhagwan Mahavir Marg A C Guards Hyderabad 500004, Telangana, 500004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042d2"
    },
    "Rank": 8054,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042d3"
    },
    "Rank": 8055,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042d4"
    },
    "Rank": 8060,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Park Hospital, Haryana,near Siwah village GT road\nPanipat, Haryana, 132103",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042d5"
    },
    "Rank": 8062,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042d6"
    },
    "Rank": 8064,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajagiri Hospital,Rajagiri Hospital, Chunagamvely, Aluva 683112, Kerala, 683112",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042d7"
    },
    "Rank": 8065,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aravind Eye Hospital, ,Aravind Eye Hospital\nPoonamallee High Rd, opposite Saveetha Dental College, Noombal, Chennai, Tamil Nadu, 600077",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042d8"
    },
    "Rank": 8067,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042d9"
    },
    "Rank": 8068,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deen Dayal Upadhyay Hospital, ,DDU HOSPITAL, SHAHEED MANGAL PANDEY MARG, NANAK PURA, HARI NAGAR, NEW DELHI, DELHI-110064, Delhi (NCT), 110064",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042da"
    },
    "Rank": 8071,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B. C. Roy Post Graduate Institute of Paediatric Sciences,111 Narkeldanga Main Road Kolkata, West Bengal, 700054",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042db"
    },
    "Rank": 8072,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042dc"
    },
    "Rank": 8073,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042dd"
    },
    "Rank": 8075,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042de"
    },
    "Rank": 8076,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042df"
    },
    "Rank": 8077,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaikh-Ul-Hind Maulana Mahmood Hasan Medical College, Saharanpur,Pilkhani Ambala Road, Uttar Pradesh, 247232",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042e0"
    },
    "Rank": 8079,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042e1"
    },
    "Rank": 8083,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL CANCER CENTRE,REGIONAL CANCER CENTRE, Kerala, 695011",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042e2"
    },
    "Rank": 8084,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Hospital, Omandurar,GOVERNMENT MEDICAL COLLEGE, OMANDURAR GOVERNMENT ESTATE, WALAJAH ROAD, NEAR KALAIVANAR ARANGAM, CHEN, Tamil Nadu, 600002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042e3"
    },
    "Rank": 8085,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. Medical College (Maharani Hospital), Jagdalpur, Chhattisgarh,Late Baliram Kashyap memorial Govt. Medical College, Dimrapal, Jagdalpur Dist-Bastar, Chhattisgarh, 494001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042e4"
    },
    "Rank": 8086,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital , ,GMERS MEDICAL COLLEGE , Hadiyol-Gadhoda Road, Near-Government Polytechnic, Hiimmatnagar, Gujarat, 383001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042e5"
    },
    "Rank": 8087,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042e6"
    },
    "Rank": 8088,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042e7"
    },
    "Rank": 8089,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042e8"
    },
    "Rank": 8091,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042e9"
    },
    "Rank": 8097,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042ea"
    },
    "Rank": 8102,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042eb"
    },
    "Rank": 8104,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042ec"
    },
    "Rank": 8105,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042ed"
    },
    "Rank": 8106,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042ee"
    },
    "Rank": 8107,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042ef"
    },
    "Rank": 8108,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042f0"
    },
    "Rank": 8110,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042f1"
    },
    "Rank": 8111,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042f2"
    },
    "Rank": 8112,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Autonomous State Medical college Society and S.N.M District Hospital, ,DAULATTAPUR JALESAR ROAD NARKHI FIROZABAD, Uttar Pradesh, 283203",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042f3"
    },
    "Rank": 8113,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Daya General Hospital,Daya General Hospital Limited Shornur Road NEar Viyyur bridge\nThiruvambady PO Thrissur, Kerala, 680022",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042f4"
    },
    "Rank": 8116,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042f5"
    },
    "Rank": 8118,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042f6"
    },
    "Rank": 8119,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Baba Saheb Ambedkar Hospital, ,SEC 6 ROHINI DELHI, Delhi (NCT), 110085",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042f7"
    },
    "Rank": 8120,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajkiya Medical College, Jalaun,kalpi Road orai Jalaun, Uttar Pradesh, 285001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042f8"
    },
    "Rank": 8121,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042f9"
    },
    "Rank": 8122,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042fa"
    },
    "Rank": 8123,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahavir Hospital and Research Centre, ,10 1 1 Bhagwan Mahavir Marg A C Guards Hyderabad 500004, Telangana, 500004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042fb"
    },
    "Rank": 8125,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042fc"
    },
    "Rank": 8127,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042fd"
    },
    "Rank": 8128,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042fe"
    },
    "Rank": 8129,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a042ff"
    },
    "Rank": 8130,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Princess Durru Shehvar Children`s and General Hospital, ,22 3 660A PURANI HAVELI HYDERABAD, Telangana, 500002",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04300"
    },
    "Rank": 8132,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manik Hospital and Research Center, ,Plot No. 6 and 7, Shivneri Nagar, Near Jawahar Nagar Police Station, Garkheda Parisar, Aurangabad, M, Maharashtra, 431005",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04301"
    },
    "Rank": 8133,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04302"
    },
    "Rank": 8134,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04303"
    },
    "Rank": 8135,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04304"
    },
    "Rank": 8136,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04305"
    },
    "Rank": 8137,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04306"
    },
    "Rank": 8139,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindu Rao Hospital, ,SUBZI MANDI, MALKA GANJ, DELHI-110007, Delhi (NCT), 110007",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04307"
    },
    "Rank": 8141,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GG Hospital, Murinjapalam Junction, ,Murinjapalam Junction, Medical College Post, Trivandrum-695011, Kerala, 695011",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04308"
    },
    "Rank": 8142,
    "Allotted Quota": "All India",
    "Allotted Institute": "Calcutta School Of Tropical Medicine,Calcutta School Of Tropical Medicine, West Bengal, 700073",
    "Course": "M.D. (TROPICAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04309"
    },
    "Rank": 8143,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S. G. L. Super Specialty Charitable Hospital, ,GARHA ROAD, JALANDHAR, Punjab, 144022",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0430a"
    },
    "Rank": 8146,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0430b"
    },
    "Rank": 8147,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medica Superspecialty Hospital, ,127 Mukundapur, E.M. Byapss, Kolkata, West Bengal, 700099",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0430c"
    },
    "Rank": 8149,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0430d"
    },
    "Rank": 8150,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0430e"
    },
    "Rank": 8152,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0430f"
    },
    "Rank": 8156,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Escorts Hospital,,Fortis escorts hospital ,neelam bata road, faridabad, Haryana, 121002",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04310"
    },
    "Rank": 8157,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04311"
    },
    "Rank": 8158,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04312"
    },
    "Rank": 8160,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VILLUPURAM MEDICAL COLLEGE, VILLUPURAM,GOVERNMENT VILLUPURAM MEDICAL COLLEGE VILLUPURAM  TRICHY TRUNK ROAD MUNDIYAMPAKKAM  VILLUPURAM DISTR, Tamil Nadu, 605601",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04313"
    },
    "Rank": 8161,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04314"
    },
    "Rank": 8165,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04315"
    },
    "Rank": 8169,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Jamshedpur,Mahatma Gandhi Memorial Medical College, Jamshedpur, Jharkhand, 831020",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04316"
    },
    "Rank": 8170,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Muthoot Health Care, ,Muthoot Healthcare, College Road, Kozhencherry, Pathanamthitta District, Kerala, 689641",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04317"
    },
    "Rank": 8175,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagwan Mahavir Cancer Hospital and Research Centre, ,Managed by K G Kothari Memorial Trust\nJawahar Lal Nehru Marg, Jaipur 302 017, Rajasthan, 302017",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04318"
    },
    "Rank": 8176,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04319"
    },
    "Rank": 8179,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shree Markendey Solapur Sahakari Rugnalya and Research Centre, ,Final Plot No.19, Pachha Peth,Solapur, Maharashtra, 413005",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0431a"
    },
    "Rank": 8181,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "OZONE Institute of Medical Sciences,Telangana,OZONE INSTITUTE OF MEDICAL SCIENCES PVT LTD, GREEN HILLS COLONY, ROAD NO 4, KOTHAPET, HYDERABAD, TEL, Telangana, 500035",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0431b"
    },
    "Rank": 8182,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees' State Insurance Corporation Medical College & Hospital, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD GULBARGA, Karnataka, 585106",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0431c"
    },
    "Rank": 8183,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GG Hospital, Murinjapalam Junction, ,Murinjapalam Junction, Medical College Post, Trivandrum-695011, Kerala, 695011",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0431d"
    },
    "Rank": 8184,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0431e"
    },
    "Rank": 8185,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Escorts Hospital,,Fortis escorts hospital ,neelam bata road, faridabad, Haryana, 121002",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0431f"
    },
    "Rank": 8187,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04320"
    },
    "Rank": 8188,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04321"
    },
    "Rank": 8189,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sparsh Hospital, Karnataka,Ideal Homes HBCS Layout, 8, 4th Cross Rd, Javarandoddi, Rajarajeshwari Nagar, Bengaluru, Karnataka, 560098",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04322"
    },
    "Rank": 8190,
    "Allotted Quota": "All India",
    "Allotted Institute": "GADAG INSTITUTE OF MEDICAL SCIENCES GADAG,Gadag Institute of Medical Sciences Mallasamudra Gadag-582103, Karnataka, 582103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04323"
    },
    "Rank": 8191,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04324"
    },
    "Rank": 8192,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Fatima Hospital, ,35-C, MAHANAGAR, LUCKNOW, UP, Uttar Pradesh, 226006",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04325"
    },
    "Rank": 8193,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Escorts Hospital, ,214, Jawahar Lal Nehru Marg, Malviya Nagar, Jaipur, Rajasthan, 302017",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04326"
    },
    "Rank": 8194,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bansal Hospital,,C- Sector Shahpura, Bhopal, Madhya Pradesh, 462016",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04327"
    },
    "Rank": 8196,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Eternal Heart Care Centre and Research Institute, ,3A, Jagatpura Road, Near Jawahar Circle, Malviya Nagar, Jaipur, Rajasthan, 302017",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04328"
    },
    "Rank": 8198,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fernandez Hospital, ,4-1-1230, Bogulkunta, Hyderabad, Telangana-500001, Telangana, 500001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04329"
    },
    "Rank": 8199,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0432a"
    },
    "Rank": 8200,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0432b"
    },
    "Rank": 8201,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deen Dayal Upadhyay Hospital, ,DDU HOSPITAL, SHAHEED MANGAL PANDEY MARG, NANAK PURA, HARI NAGAR, NEW DELHI, DELHI-110064, Delhi (NCT), 110064",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0432c"
    },
    "Rank": 8202,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0432d"
    },
    "Rank": 8204,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0432e"
    },
    "Rank": 8205,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0432f"
    },
    "Rank": 8207,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04330"
    },
    "Rank": 8209,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04331"
    },
    "Rank": 8210,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "HCG Manavata Cancer Centre, A Unit of HCG Manavata Oncology LLP,,Near Mylan Circle, Mumbai Naka, Nashik-422002, Maharashtra, 422002",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04332"
    },
    "Rank": 8211,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04333"
    },
    "Rank": 8212,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04334"
    },
    "Rank": 8215,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04335"
    },
    "Rank": 8219,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shroff Eye Centre,,A-9, Kailash Colony, New Delhi, Delhi (NCT), 110048",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04336"
    },
    "Rank": 8222,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Batra Hospital and Medical Research Centre, ,1, Tughlakabad institutional Area, Mehrauli Badarpur Road, New Delhi, Delhi (NCT), 110062",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04337"
    },
    "Rank": 8226,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04338"
    },
    "Rank": 8229,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Metro Hospital and Heart Institute, GURUGRAM, Haryana,H-BLOCK, PALAM VIHAR,GURUGRAM. HARYANA\nHaryana 122017, Haryana, 122017",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04339"
    },
    "Rank": 8231,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Baba Saheb Ambedkar Hospital, ,SEC 6 ROHINI DELHI, Delhi (NCT), 110085",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0433a"
    },
    "Rank": 8233,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0433b"
    },
    "Rank": 8234,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0433c"
    },
    "Rank": 8235,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0433d"
    },
    "Rank": 8236,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0433e"
    },
    "Rank": 8237,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0433f"
    },
    "Rank": 8239,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04340"
    },
    "Rank": 8241,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04341"
    },
    "Rank": 8242,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04342"
    },
    "Rank": 8243,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Bhausaheb Hire Government Medical College,Sri Bhausaheb Hire Government Medical College,Dhule, Maharashtra, 424311",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04343"
    },
    "Rank": 8244,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04344"
    },
    "Rank": 8246,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04345"
    },
    "Rank": 8247,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04346"
    },
    "Rank": 8248,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Alliance Multispeciality Hospital LLP, Maharashtra,14412, Behind Suraj Gas Godown, Chandur Road, Ichalkaranji 416 115, Maharashtra, 416115",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04347"
    },
    "Rank": 8251,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Alliance Multispeciality Hospital LLP, Maharashtra,14412, Behind Suraj Gas Godown, Chandur Road, Ichalkaranji 416 115, Maharashtra, 416115",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04348"
    },
    "Rank": 8252,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Sector-62, Phase VIII, Mohali- 160062, Punjab, 160062",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04349"
    },
    "Rank": 8253,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0434a"
    },
    "Rank": 8255,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Childrens Hospital,,, Tamil Nadu, 600015",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0434b"
    },
    "Rank": 8256,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chinmaya Mission Hospital,,Chinmaya Mission Hospital, 80 Feet CMH Road, Indiranagar, Bangalore, Karnataka, 560038",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0434c"
    },
    "Rank": 8257,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0434d"
    },
    "Rank": 8259,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0434e"
    },
    "Rank": 8261,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0434f"
    },
    "Rank": 8263,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Satguru Partap Singh Hospital, ,Sherpur Chowk, GT Road, Ludhiana, Punjab-141003, Punjab, 141003",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04350"
    },
    "Rank": 8265,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04351"
    },
    "Rank": 8267,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K B Bhabha Municipal General Hospital, ,R K PATKAR ROAD ,BANDRA WEST\n,MUMBAI, Maharashtra, 400050",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04352"
    },
    "Rank": 8268,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04353"
    },
    "Rank": 8269,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Eternal Heart Care Centre and Research Institute, ,3A, Jagatpura Road, Near Jawahar Circle, Malviya Nagar, Jaipur, Rajasthan, 302017",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04354"
    },
    "Rank": 8270,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Childrens Hospital,,, Tamil Nadu, 600015",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04355"
    },
    "Rank": 8271,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04356"
    },
    "Rank": 8274,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Hospital, ,Government Medical College and Associated Hospital, Kheora, Rajouri, Jammu and Kashmir-185131, Jammu And Kashmir, 185131",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04357"
    },
    "Rank": 8275,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04358"
    },
    "Rank": 8276,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVERNMENT MEDICAL COLLEGE,KANYAKUMARI GOVERNMENT MEDICAL COLLEGE, Tamil Nadu, 629201",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04359"
    },
    "Rank": 8280,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0435a"
    },
    "Rank": 8281,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE,ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, Bihar, 823001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0435b"
    },
    "Rank": 8282,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0435c"
    },
    "Rank": 8283,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0435d"
    },
    "Rank": 8284,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "National Institute of Tuberculosis and Respiratory Diseases, (Formerly Known as L.R.S. Instt. of T.B. and Allied Scs),,National Institute of Tuberculosis and Respiratory Diseases (Formerly Known as L.R.S. Instt. of T.B., Delhi (NCT), 110030",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0435e"
    },
    "Rank": 8285,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0435f"
    },
    "Rank": 8286,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chittaranjan Seva Sadan Hospital,37, S.P. MUKHERJEE ROAD, KOLKATA, West Bengal, 700026",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04360"
    },
    "Rank": 8288,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04361"
    },
    "Rank": 8289,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College, ,Office of the Dean, First Floor, Civil Hospital Campus, Sector- 12, Gandhinagar, Gujarat, 382016",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04362"
    },
    "Rank": 8291,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04363"
    },
    "Rank": 8294,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College,Theni ,Government Medical College,Theni, Tamil Nadu, 625512",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04364"
    },
    "Rank": 8296,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04365"
    },
    "Rank": 8297,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04366"
    },
    "Rank": 8298,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04367"
    },
    "Rank": 8299,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deen Dayal Upadhyay Hospital, ,DDU HOSPITAL, SHAHEED MANGAL PANDEY MARG, NANAK PURA, HARI NAGAR, NEW DELHI, DELHI-110064, Delhi (NCT), 110064",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04368"
    },
    "Rank": 8300,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Paras Hospital,,C1 Sushant lok sector 43 gurugram, Haryana, 122002",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04369"
    },
    "Rank": 8301,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0436a"
    },
    "Rank": 8302,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0436b"
    },
    "Rank": 8303,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shifaa Hospital, ,NO 332, QUEENS ROAD, SHIVAJINAGAR, Karnataka, 560052",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0436c"
    },
    "Rank": 8304,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0436d"
    },
    "Rank": 8305,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0436e"
    },
    "Rank": 8307,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0436f"
    },
    "Rank": 8310,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04370"
    },
    "Rank": 8312,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Daya General Hospital,Daya General Hospital Limited Shornur Road NEar Viyyur bridge\nThiruvambady PO Thrissur, Kerala, 680022",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04371"
    },
    "Rank": 8313,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, THOOTHUKUDI,GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, 3rd\nMile,Kamaraj Nagar,Thoothukudi., Tamil Nadu, 628008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04372"
    },
    "Rank": 8314,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04373"
    },
    "Rank": 8316,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04374"
    },
    "Rank": 8318,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04375"
    },
    "Rank": 8320,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04376"
    },
    "Rank": 8322,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04377"
    },
    "Rank": 8323,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04378"
    },
    "Rank": 8325,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute Of Medical Sciences, Kalaburagi,VEERESH NAGAR SEDAM ROAD KALABURAGI, Karnataka, 585105",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04379"
    },
    "Rank": 8329,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0437a"
    },
    "Rank": 8331,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.G.M. Muthoot Hospital, ,MGM Muthoot Medical Centre, Ring Road, Pathanamthitta, Kerala, 689645",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0437b"
    },
    "Rank": 8333,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Muthoot Health Care, ,Muthoot Healthcare, College Road, Kozhencherry, Pathanamthitta District, Kerala, 689641",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0437c"
    },
    "Rank": 8334,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Bundelkhand Medical College Sagar M.P.,Govt. Bundelkhand Medical College Sagar M.P., Madhya Pradesh, 470001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0437d"
    },
    "Rank": 8335,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0437e"
    },
    "Rank": 8336,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0437f"
    },
    "Rank": 8338,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04380"
    },
    "Rank": 8339,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ananthapuri Hospital and Research Institute, ,Chacka, NH Bypass, Thiruvananthapuram 695024, Kerala, 695024",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04381"
    },
    "Rank": 8340,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT DHARMAPURI MEDICAL COLLEGE, DHARMAPURI, TAMIL NADU,GOVERNMENT DHARMAPURI MEDICAL COLLEGE, NETHAJI BYE PASS ROAD, DHARMAPURI, TAMIL NADU, Tamil Nadu, 636701",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04382"
    },
    "Rank": 8346,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04383"
    },
    "Rank": 8347,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04384"
    },
    "Rank": 8348,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Sivagangai Medical College and Hospital, Tamil Nadu,GOVT.SIVAGANGAI MEDICAL COLLEGE, MELAVANIYANKUDY, MANAMADURAI ROAD, SIVAGANGAI.,\nTamil Nadu, 630561",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04385"
    },
    "Rank": 8350,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04386"
    },
    "Rank": 8351,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04387"
    },
    "Rank": 8352,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04388"
    },
    "Rank": 8353,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindustan Aeronautics Hospital, ,SURANJANDAS ROAD ,VIMANAPURA POST BANGALORE -560017, Karnataka, 560017",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04389"
    },
    "Rank": 8354,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0438a"
    },
    "Rank": 8355,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0438b"
    },
    "Rank": 8356,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0438c"
    },
    "Rank": 8358,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0438d"
    },
    "Rank": 8359,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0438e"
    },
    "Rank": 8360,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0438f"
    },
    "Rank": 8362,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster Medcity, Kuttisahib Road,,Aster Medcity Kuttisahib Road\nSouth Chittoor P.O. Cheranalloor\nKochi, Kerala, 682027",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04390"
    },
    "Rank": 8363,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04391"
    },
    "Rank": 8364,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04392"
    },
    "Rank": 8366,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04393"
    },
    "Rank": 8367,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04394"
    },
    "Rank": 8368,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Moolchand Khairatiram Hospital and Ayurvedic Research Institute,,,Lajpat Nagar-III, New Delhi-110024, Delhi (NCT), 110024",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04395"
    },
    "Rank": 8370,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04396"
    },
    "Rank": 8371,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "G Kuppuswamy Naidu Memorial Hospital, ,Post Box No.6327, Nethaji Road, Pappanaickenpalayam, Coimbatore, Tamil Nadu, 641037",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04397"
    },
    "Rank": 8372,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04398"
    },
    "Rank": 8374,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04399"
    },
    "Rank": 8375,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chinmaya Mission Hospital,,Chinmaya Mission Hospital, 80 Feet CMH Road, Indiranagar, Bangalore, Karnataka, 560038",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0439a"
    },
    "Rank": 8376,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0439b"
    },
    "Rank": 8378,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0439c"
    },
    "Rank": 8380,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster Medcity, Kuttisahib Road,,Aster Medcity Kuttisahib Road\nSouth Chittoor P.O. Cheranalloor\nKochi, Kerala, 682027",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0439d"
    },
    "Rank": 8384,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agarsen Hospital, ,., Delhi (NCT), 110026",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0439e"
    },
    "Rank": 8386,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vallabhbhai Patel Chest Institute, Delhi,Vallabhbhai Patel Chest Institute, Delhi, Delhi (NCT), 110007",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0439f"
    },
    "Rank": 8387,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ayushman Hospital and Health Services, Dwarka,  Delhi,Ayushman Hospital and Health Services, Sector-10 Dwarka, New Delhi-110075, Delhi (NCT), 110075",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043a0"
    },
    "Rank": 8388,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043a1"
    },
    "Rank": 8390,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043a2"
    },
    "Rank": 8391,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043a3"
    },
    "Rank": 8392,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agrasen Medical College,,NH- 9, VPO - Agroha, District Hisar, Haryana, 125047",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043a4"
    },
    "Rank": 8393,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043a5"
    },
    "Rank": 8394,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043a6"
    },
    "Rank": 8395,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indraprastha Apollo Hospital,,Sarita Vihar, New Delhi 110 076, Delhi (NCT), 110076",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043a7"
    },
    "Rank": 8399,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043a8"
    },
    "Rank": 8403,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jupiter Hospital, ,Eastern Express Highway, Thane West, Maharashtra, 400601",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043a9"
    },
    "Rank": 8404,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ozone Multispeciality hospital and Critical care center, Maharashtra,Holycross convent Road, Kedia Plots, Akola, Maharashtra, 444005",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043aa"
    },
    "Rank": 8405,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043ab"
    },
    "Rank": 8406,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043ac"
    },
    "Rank": 8407,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043ad"
    },
    "Rank": 8410,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indraprastha Apollo Hospital,,Sarita Vihar, New Delhi 110 076, Delhi (NCT), 110076",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043ae"
    },
    "Rank": 8412,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043af"
    },
    "Rank": 8413,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043b0"
    },
    "Rank": 8414,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043b1"
    },
    "Rank": 8416,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Moolchand Khairatiram Hospital and Ayurvedic Research Institute,,,Lajpat Nagar-III, New Delhi-110024, Delhi (NCT), 110024",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043b2"
    },
    "Rank": 8417,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagwan Mahavir Cancer Hospital and Research Centre, ,Managed by K G Kothari Memorial Trust\nJawahar Lal Nehru Marg, Jaipur 302 017, Rajasthan, 302017",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043b3"
    },
    "Rank": 8418,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bettiah,Government Medical College Bettiah West Champaran Bihar, Bihar, 845438",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043b4"
    },
    "Rank": 8420,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043b5"
    },
    "Rank": 8421,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043b6"
    },
    "Rank": 8422,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043b7"
    },
    "Rank": 8423,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043b8"
    },
    "Rank": 8424,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Ramesh Cardiac and Multi Speciality Hospital,,Collector office road, Nagarampalem, Guntur , AP, Andhra Pradesh, 522004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043b9"
    },
    "Rank": 8425,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043ba"
    },
    "Rank": 8427,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043bb"
    },
    "Rank": 8430,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043bc"
    },
    "Rank": 8433,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.G.M. Muthoot Hospital, ,MGM Muthoot Medical Centre, Ring Road, Pathanamthitta, Kerala, 689645",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043bd"
    },
    "Rank": 8434,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ananthapuri Hospital and Research Institute, ,Chacka, NH Bypass, Thiruvananthapuram 695024, Kerala, 695024",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043be"
    },
    "Rank": 8436,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043bf"
    },
    "Rank": 8437,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043c0"
    },
    "Rank": 8440,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043c1"
    },
    "Rank": 8441,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Human care Medical Charitable Trust,Human Care Medical Charitable Trust. HCMCTManipal Hospital, Sector 6, DWARKA, NEW DELHI, Delhi (NCT), 110075",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043c2"
    },
    "Rank": 8442,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043c3"
    },
    "Rank": 8444,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043c4"
    },
    "Rank": 8445,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Satguru Partap Singh Hospital, ,Sherpur Chowk, GT Road, Ludhiana, Punjab-141003, Punjab, 141003",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043c5"
    },
    "Rank": 8447,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043c6"
    },
    "Rank": 8449,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043c7"
    },
    "Rank": 8452,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043c8"
    },
    "Rank": 8453,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.G. Patel Children Hospital and Jayodia Research Institute,, ,Medical Care Centre Trust\nK.G. Patel Children Hospital,\nJalaram Marg, Karelibaug, Vadodara, Gujarat, 390018",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043c9"
    },
    "Rank": 8454,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043ca"
    },
    "Rank": 8455,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043cb"
    },
    "Rank": 8457,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043cc"
    },
    "Rank": 8460,
    "Allotted Quota": "All India",
    "Allotted Institute": "GADAG INSTITUTE OF MEDICAL SCIENCES GADAG,Gadag Institute of Medical Sciences Mallasamudra Gadag-582103, Karnataka, 582103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043cd"
    },
    "Rank": 8461,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043ce"
    },
    "Rank": 8462,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Durgabai Deshmukh Hospital, ,OSMANIA UNIVERSITY ROAD, VIDYANAGAR, HYDERABAD, Telangana, 500044",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043cf"
    },
    "Rank": 8464,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043d0"
    },
    "Rank": 8465,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043d1"
    },
    "Rank": 8471,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043d2"
    },
    "Rank": 8472,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043d3"
    },
    "Rank": 8474,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.G.M. Muthoot Hospital, ,MGM Muthoot Medical Centre, Ring Road, Pathanamthitta, Kerala, 689645",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043d4"
    },
    "Rank": 8476,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043d5"
    },
    "Rank": 8477,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043d6"
    },
    "Rank": 8478,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043d7"
    },
    "Rank": 8479,
    "Allotted Quota": "All India",
    "Allotted Institute": "Institute of Child Health, Kolkata,Institute of Child Health, Kolkata, West Bengal, 700017",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043d8"
    },
    "Rank": 8482,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043d9"
    },
    "Rank": 8483,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shifa Hospitals,No.82, Kailasapuram Middle Street, Tirunelveli Junction,\nTirunelveli, Tamilnadu, Tamil Nadu, 627001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043da"
    },
    "Rank": 8485,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI INSTITUTE OF CHILD HEALTH, BANGALORE,INDIRA GANDHI INSTITUTE OF CHILD HEALTH, BANGALORE, Karnataka, 560029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043db"
    },
    "Rank": 8486,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043dc"
    },
    "Rank": 8489,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043dd"
    },
    "Rank": 8492,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043de"
    },
    "Rank": 8493,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Peerless Hospital and B K Roy Research Centre, ,360, Panchasayar, Kolkata - 700 -094, West Bengal, 700094",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043df"
    },
    "Rank": 8494,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043e0"
    },
    "Rank": 8496,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Mundian Kalan, Chandigarh Road, Ludhiana-141015, Punjab, 141015",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043e1"
    },
    "Rank": 8498,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043e2"
    },
    "Rank": 8499,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043e3"
    },
    "Rank": 8500,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043e4"
    },
    "Rank": 8501,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043e5"
    },
    "Rank": 8502,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043e6"
    },
    "Rank": 8505,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043e7"
    },
    "Rank": 8506,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Venkateshwar Hospital, ,Sector- 18 A, Dwarka, New Delhi, Delhi (NCT), 110075",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043e8"
    },
    "Rank": 8507,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "INSTITUTE OF HUMAN  BEHAVIOUR AND ALLIED SCIENCES,Institute of Human\nBehaviour and Allied Sciences,  Dilshad Garden, New Delhi, Delhi 110095, India, Delhi (NCT), 110095",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043e9"
    },
    "Rank": 8509,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043ea"
    },
    "Rank": 8510,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043eb"
    },
    "Rank": 8512,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bansal Hospital,,C- Sector Shahpura, Bhopal, Madhya Pradesh, 462016",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043ec"
    },
    "Rank": 8515,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043ed"
    },
    "Rank": 8517,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043ee"
    },
    "Rank": 8519,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Human care Medical Charitable Trust,Human Care Medical Charitable Trust. HCMCTManipal Hospital, Sector 6, DWARKA, NEW DELHI, Delhi (NCT), 110075",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043ef"
    },
    "Rank": 8522,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043f0"
    },
    "Rank": 8524,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MMI Narayana Multispecialty Hospital,,Dhamtari Road, Lalpur, Raipur, Chhattisgarh, 492001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043f1"
    },
    "Rank": 8525,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bansal Hospital,,C- Sector Shahpura, Bhopal, Madhya Pradesh, 462016",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043f2"
    },
    "Rank": 8527,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043f3"
    },
    "Rank": 8528,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043f4"
    },
    "Rank": 8529,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. Medical College (Maharani Hospital), Jagdalpur, Chhattisgarh,Late Baliram Kashyap memorial Govt. Medical College, Dimrapal, Jagdalpur Dist-Bastar, Chhattisgarh, 494001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043f5"
    },
    "Rank": 8530,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,HBT Medical College and Dr R N Cooper Municipal General Hospital, Bhaktivedanta Swami Rd, JVPD Schem, Maharashtra, 400056",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043f6"
    },
    "Rank": 8531,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Family Hospital,,Okhla Road, New Delhi, Delhi (NCT), 110025",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043f7"
    },
    "Rank": 8532,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bharath Hospital, Kerala,AZAD LANE, KOTTAYAM, KERALA 686001, Kerala, 686001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043f8"
    },
    "Rank": 8533,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Medical College and Chhatrapati Shivaji Maharaj Hospital, ,RAJIV GANDHI MEDICAL COLLEGE,THANE BELAPUR ROAD, KALWA, THANE, Maharashtra, 400605",
    "Course": "(NBEMS) RADIO- DIAGNOSIS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043f9"
    },
    "Rank": 8534,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ST. JOSEPH HOSPITAL ,\nTamil Nadu,15,Trichy Road, Dindigul - 624001., Tamil Nadu, 624001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043fa"
    },
    "Rank": 8535,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Poonam Palaspagar - Academic Coordinator\nFortis Hospital, Bannerghatta Road, Bangalore, Karnataka 5, Karnataka, 560076",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043fb"
    },
    "Rank": 8536,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043fc"
    },
    "Rank": 8537,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043fd"
    },
    "Rank": 8538,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT DHARMAPURI MEDICAL COLLEGE, DHARMAPURI, TAMIL NADU,GOVERNMENT DHARMAPURI MEDICAL COLLEGE, NETHAJI BYE PASS ROAD, DHARMAPURI, TAMIL NADU, Tamil Nadu, 636701",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043fe"
    },
    "Rank": 8539,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dharwad,dsdharwad at gmail dot com, Karnataka, 580001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a043ff"
    },
    "Rank": 8540,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVERNMENT MEDICAL COLLEGE,KANYAKUMARI GOVERNMENT MEDICAL COLLEGE, Tamil Nadu, 629201",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04400"
    },
    "Rank": 8541,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04401"
    },
    "Rank": 8544,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04402"
    },
    "Rank": 8545,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Sathya Sai Sarla Memorial Hospital, Karnataka,Sathya Sai Grama Muddenahalli\nChikkaballapura, Karnataka, 562101",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04403"
    },
    "Rank": 8546,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04404"
    },
    "Rank": 8549,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04405"
    },
    "Rank": 8555,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Datia,Near 29 Batallion, National Highway 44, Dist Datia, Madhya Pradesh Pin - 475661, Madhya Pradesh, 475661",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04406"
    },
    "Rank": 8558,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04407"
    },
    "Rank": 8559,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04408"
    },
    "Rank": 8563,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04409"
    },
    "Rank": 8565,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0440a"
    },
    "Rank": 8566,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Thiruvannamalai Medical College, Thiruvannamalai, Tamil Nadu,DISTRICT COLLECTORATE  MASTER PLAN COMPLEX, VENGIKKAL THIRUVANNAMALAI,\nTamil Nadu, 600604",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0440b"
    },
    "Rank": 8571,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0440c"
    },
    "Rank": 8572,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0440d"
    },
    "Rank": 8573,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VELLORE MEDICAL COLLEGE,GOVERNMENT VELLORE MEDICAL COLLEGE, Tamil Nadu, 632011",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0440e"
    },
    "Rank": 8574,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESIC Model Hospital, ,A-3, TULSI MARG, SECTOR-24, NOIDA, Uttar Pradesh, 201301",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0440f"
    },
    "Rank": 8575,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04410"
    },
    "Rank": 8576,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute Of Medical Sciences, Kalaburagi,VEERESH NAGAR SEDAM ROAD KALABURAGI, Karnataka, 585105",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04411"
    },
    "Rank": 8577,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04412"
    },
    "Rank": 8578,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yatharth Superspeciality Hospitals,\nUttar Pradesh,HO-01, Sector-01, Greater Noida West, Uttar Pradesh, 201306",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04413"
    },
    "Rank": 8579,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04414"
    },
    "Rank": 8580,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Adarsha Hospital, ,Near KSRTC Bus Stand, Udupi Taluk and District, Karnataka, Karnataka, 576101",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04415"
    },
    "Rank": 8581,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ananthapuri Hospital and Research Institute, ,Chacka, NH Bypass, Thiruvananthapuram 695024, Kerala, 695024",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04416"
    },
    "Rank": 8582,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04417"
    },
    "Rank": 8584,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mallige Medical Centre, ,No 31 32 Crescent Road Bangalore 560001, Karnataka, 560001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04418"
    },
    "Rank": 8585,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees' State Insurance Corporation Medical College & Hospital, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD GULBARGA, Karnataka, 585106",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04419"
    },
    "Rank": 8586,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0441a"
    },
    "Rank": 8587,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0441b"
    },
    "Rank": 8588,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0441c"
    },
    "Rank": 8589,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0441d"
    },
    "Rank": 8590,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0441e"
    },
    "Rank": 8592,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvarur Govt.Medical College,Thiruvarur Govt.Medical College, Tamil Nadu, 610004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0441f"
    },
    "Rank": 8593,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04420"
    },
    "Rank": 8594,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04421"
    },
    "Rank": 8595,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhabha Atomic Research Centre and Hospital, ,Anushaktinagar, Mumbai, Maharashtra, 400094",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04422"
    },
    "Rank": 8598,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04423"
    },
    "Rank": 8599,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "OZONE Institute of Medical Sciences,Telangana,OZONE INSTITUTE OF MEDICAL SCIENCES PVT LTD, GREEN HILLS COLONY, ROAD NO 4, KOTHAPET, HYDERABAD, TEL, Telangana, 500035",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04424"
    },
    "Rank": 8600,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04425"
    },
    "Rank": 8601,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. GERIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04426"
    },
    "Rank": 8602,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Jeyasekharan Hospital and Nursing Home, ,Dr.Jeyasekharan Hospital and Nursing Home,\nK.P.Road, Nagercoil, Kanyakumari District, Tamil Nadu, 629003",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04427"
    },
    "Rank": 8603,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chamarajanagar Institute of Medical Sciences, Karnataka,CHAMARAJANAGAR INSTITUTE OF MEDICAL SCIENCES, SY. NO 124, YEDAPURA VILLAGE KASABA HOBLI, CHAMARAJANA, Karnataka, 571313",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04428"
    },
    "Rank": 8604,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04429"
    },
    "Rank": 8606,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0442a"
    },
    "Rank": 8608,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0442b"
    },
    "Rank": 8609,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0442c"
    },
    "Rank": 8613,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0442d"
    },
    "Rank": 8614,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Healthway Hospital, Goa\n,plot no 132, Ella, Tiswadi, Old Goa, Goa, 403402",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0442e"
    },
    "Rank": 8616,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0442f"
    },
    "Rank": 8618,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04430"
    },
    "Rank": 8619,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04431"
    },
    "Rank": 8620,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04432"
    },
    "Rank": 8621,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04433"
    },
    "Rank": 8622,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04434"
    },
    "Rank": 8623,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Homi Bhabha Cancer Hospital, Uttar Pradesh,Homi Bhabha Cancer Hospital , Old Loco Colony, Shivpurwa, Lahartara, Varanasi-221002, Uttar Pradesh, Uttar Pradesh, 221002",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04435"
    },
    "Rank": 8624,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04436"
    },
    "Rank": 8630,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04437"
    },
    "Rank": 8631,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVERNMENT MEDICAL COLLEGE,KANYAKUMARI GOVERNMENT MEDICAL COLLEGE, Tamil Nadu, 629201",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04438"
    },
    "Rank": 8632,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04439"
    },
    "Rank": 8633,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0443a"
    },
    "Rank": 8635,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Polakulath Narayanan Renai Medicity Multi Super Specialty Hospital,,RENAIMEDICITY PALARIVATTOM KOCHI KERALA, Kerala, 682025",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0443b"
    },
    "Rank": 8636,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,RAJBHAVAN ROAD SOMAJIGUDA, Telangana, 500082",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0443c"
    },
    "Rank": 8637,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES ADILABAD -504001 TELANGANA STATE, Telangana, 504001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0443d"
    },
    "Rank": 8638,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0443e"
    },
    "Rank": 8642,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0443f"
    },
    "Rank": 8643,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04440"
    },
    "Rank": 8646,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04441"
    },
    "Rank": 8647,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04442"
    },
    "Rank": 8648,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Revathi Medical Center Tirupur (I) Pvt Ltd ,\nTamil Nadu,No: 10, KUMAR NAGAR WEST, VALAYANKADU ROAD, AVINASHI ROAD, TIRUPUR, Tamil Nadu, 641603",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04443"
    },
    "Rank": 8649,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04444"
    },
    "Rank": 8650,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04445"
    },
    "Rank": 8654,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04446"
    },
    "Rank": 8658,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. R N Cooper Municipal General Hospital, (Associated with H B T (Hinduhridayasamrat Balasaheb Thackeray) medical College) ,HBTMC AND DR.R.N.COOPER HOSPITAL\nU 15 BHAKTI VEDANTA ROAD JVPD SCHEME VILE PARLE WEST MUMBAI,\nMaharashtra, 400056",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04447"
    },
    "Rank": 8660,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04448"
    },
    "Rank": 8663,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04449"
    },
    "Rank": 8664,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0444a"
    },
    "Rank": 8665,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Jamshedpur,Mahatma Gandhi Memorial Medical College, Jamshedpur, Jharkhand, 831020",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0444b"
    },
    "Rank": 8666,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0444c"
    },
    "Rank": 8667,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Hurkisondas Nurrotumdas Hospital and Research Centre, ,Prarthana Samaj, Raja Ram Mohan Roy road,\nGirgaon, MUMBAI, Maharashtra, 400004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0444d"
    },
    "Rank": 8669,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS MEDICAL COLLEGE, NEAR GUJARAT HIGH COURT, S.G HIGH WAY, SOLA, AHMEDABAD, Gujarat, 380060",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0444e"
    },
    "Rank": 8670,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0444f"
    },
    "Rank": 8672,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04450"
    },
    "Rank": 8673,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04451"
    },
    "Rank": 8674,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Hospital,,JANGLAT MANDI ANANTNAG, JAMMU AND KASHMIR 192101, Jammu And Kashmir, 192101",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04452"
    },
    "Rank": 8675,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04453"
    },
    "Rank": 8679,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Care Hospital, ,Plot No.- 324 p Prachi Enclave, Chandrasekharpur,\nBhubaneswar, Odisha, 751016",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04454"
    },
    "Rank": 8680,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04455"
    },
    "Rank": 8681,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04456"
    },
    "Rank": 8682,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04457"
    },
    "Rank": 8684,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVERNMENT MEDICAL COLLEGE,KANYAKUMARI GOVERNMENT MEDICAL COLLEGE, Tamil Nadu, 629201",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04458"
    },
    "Rank": 8685,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04459"
    },
    "Rank": 8686,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0445a"
    },
    "Rank": 8687,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0445b"
    },
    "Rank": 8689,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Laddhad Hospital,,WANKHEDE LAY OUT BULDANA, Maharashtra, 443001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0445c"
    },
    "Rank": 8690,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0445d"
    },
    "Rank": 8692,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0445e"
    },
    "Rank": 8693,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0445f"
    },
    "Rank": 8698,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College,Tumuki, Bihaguri, Tezpur, Sonitpur, Assam, Assam, 784010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04460"
    },
    "Rank": 8699,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04461"
    },
    "Rank": 8702,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04462"
    },
    "Rank": 8704,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04463"
    },
    "Rank": 8716,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04464"
    },
    "Rank": 8722,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Calcutta Medical Research Institute, ,The Calcutta Medical Research Institute 7 by 2 Diamond Harbour Road Kolkata 700027, West Bengal, 700027",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04465"
    },
    "Rank": 8724,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04466"
    },
    "Rank": 8725,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Cosmopolitan Hospital,,COSMOPOLITAN HOSPITALS Pvt.Ltd, Murinjapalam, Pattom P O, Thiruvananthapuram, Kerala, 695004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04467"
    },
    "Rank": 8726,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04468"
    },
    "Rank": 8727,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04469"
    },
    "Rank": 8729,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lokopriya Gopinath Bordoloi Regional Institute of Mental Health,LGBRIMH, Tezpur, District Sonitpur,, Assam, 784001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0446a"
    },
    "Rank": 8730,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jupiter Hospital, ,Eastern Express Highway, Thane West, Maharashtra, 400601",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0446b"
    },
    "Rank": 8734,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0446c"
    },
    "Rank": 8736,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0446d"
    },
    "Rank": 8737,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvarur Govt.Medical College,Thiruvarur Govt.Medical College, Tamil Nadu, 610004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0446e"
    },
    "Rank": 8739,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0446f"
    },
    "Rank": 8740,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04470"
    },
    "Rank": 8741,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B. Nanavati Hospital, Vivekanand Rd,Swami Vivekananda Rd, near LIC, LIC Colony, Suresh Colony, Vile Parle West, Mumbai, Maharashtra 4000, Maharashtra, 400056",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04471"
    },
    "Rank": 8742,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04472"
    },
    "Rank": 8743,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) Plastic and Reconstructive Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04473"
    },
    "Rank": 8745,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Motors Hospital, ,KHARANGAJHAR, TELCO COLONY JAMSHEDPUR, Jharkhand, 831003",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04474"
    },
    "Rank": 8746,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04475"
    },
    "Rank": 8747,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Surya Children`s Medicare, (Formerly Surya Children`s Hospital) ,S. V. Road, Santacruz West Mumbai, Maharashtra, 400054",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04476"
    },
    "Rank": 8748,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04477"
    },
    "Rank": 8749,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04478"
    },
    "Rank": 8750,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Rela Institute and Medical Centre ,\nTamil Nadu,NO.7, CLC WORKS ROAD, CHROMPET CHENNAI, Tamil Nadu, 600044",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04479"
    },
    "Rank": 8751,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0447a"
    },
    "Rank": 8752,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0447b"
    },
    "Rank": 8753,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0447c"
    },
    "Rank": 8755,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agrasen Medical College,,NH- 9, VPO - Agroha, District Hisar, Haryana, 125047",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0447d"
    },
    "Rank": 8759,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0447e"
    },
    "Rank": 8761,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT DHARMAPURI MEDICAL COLLEGE, DHARMAPURI, TAMIL NADU,GOVERNMENT DHARMAPURI MEDICAL COLLEGE, NETHAJI BYE PASS ROAD, DHARMAPURI, TAMIL NADU, Tamil Nadu, 636701",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0447f"
    },
    "Rank": 8762,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bharath Hospital, Kerala,AZAD LANE, KOTTAYAM, KERALA 686001, Kerala, 686001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04480"
    },
    "Rank": 8765,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chandan Hospital, ,Vijayant Khand, Gomti Nagar, Lucknow, Uttar Pradesh, 226010",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04481"
    },
    "Rank": 8768,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pushpagiri Institute of Medical Sciences and Research Centre,,Pushpagiri Institute of Medical Sciences and Research centre, Pushp Marg, Tiruvalla, Kerala, 689101",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04482"
    },
    "Rank": 8772,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04483"
    },
    "Rank": 8773,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "IQRAA International Hospital and Research Centre, ,IQRAA INTERNATIONAL HOSPITAL AND RESEARCH CENTRE MALAPARAMBA CALICUT, Kerala, 673009",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04484"
    },
    "Rank": 8774,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04485"
    },
    "Rank": 8775,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "North 24 Parganas District Hospital, ,BANAMALIPUR BARASAT WEST BENGAL, West Bengal, 700124",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04486"
    },
    "Rank": 8777,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04487"
    },
    "Rank": 8778,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04488"
    },
    "Rank": 8781,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04489"
    },
    "Rank": 8783,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Southern Railway Head Quarter Hospital, ,Ayanavaram, Tamil Nadu, 600023",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0448a"
    },
    "Rank": 8785,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BharatRatna Dr. Babasaheb Ambedkar Municipal General Hospital, Kandivali (West), Maharashtra,Parekh Nagar, SV Road, Opp. Kandivali Police station, Kandivali West, Mumbai, Maharashtra, 400067",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0448b"
    },
    "Rank": 8786,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0448c"
    },
    "Rank": 8787,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0448d"
    },
    "Rank": 8788,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0448e"
    },
    "Rank": 8790,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. GERIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0448f"
    },
    "Rank": 8792,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Stephen`s Hospital, ,Tis Hazari, Delhi (NCT), 110054",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04490"
    },
    "Rank": 8794,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04491"
    },
    "Rank": 8795,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVERNMENT MEDICAL COLLEGE,KANYAKUMARI GOVERNMENT MEDICAL COLLEGE, Tamil Nadu, 629201",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04492"
    },
    "Rank": 8796,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) Plastic and Reconstructive Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04493"
    },
    "Rank": 8799,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04494"
    },
    "Rank": 8801,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rio Childrens Hospital Pvt Ltd, Madurai, Tamil Nadu,No 40 4C 2B1 Tuticorin Ring Road Masthanpatti Madurai, Tamil Nadu, 625020",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04495"
    },
    "Rank": 8803,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. IN NUCLEAR MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04496"
    },
    "Rank": 8804,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04497"
    },
    "Rank": 8807,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04498"
    },
    "Rank": 8808,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04499"
    },
    "Rank": 8810,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0449a"
    },
    "Rank": 8813,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0449b"
    },
    "Rank": 8814,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital, ,Nellikuppam Main Road, Cuddalore, Tamil Nadu, 607001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0449c"
    },
    "Rank": 8817,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0449d"
    },
    "Rank": 8820,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Doon Medical College, Dehradun,Govt. Doon Medical College Dehrakhas, Patel Nagar Dehradun 248001, Uttarakhand, 248001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0449e"
    },
    "Rank": 8822,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shifaa Hospital, ,NO 332, QUEENS ROAD, SHIVAJINAGAR, Karnataka, 560052",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0449f"
    },
    "Rank": 8824,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE - KANNUR,GOVERNMENT MEDICAL COLLEGE\n- KANNUR, Kerala, 670503",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044a0"
    },
    "Rank": 8827,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044a1"
    },
    "Rank": 8828,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Diamond Harbour Government Medical College And Hospital, ,Harindanga, New Town, Word No- 4, P.O and P.S- Diamond Harbour, Dist- South 24 Parganas, West Bengal, West Bengal, 743331",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044a2"
    },
    "Rank": 8829,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College, Kolhapur,R.K. Nagar Road, Shenda Park, Kolhapur, Maharashtra, Maharashtra, 416012",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044a3"
    },
    "Rank": 8830,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044a4"
    },
    "Rank": 8831,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044a5"
    },
    "Rank": 8832,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044a6"
    },
    "Rank": 8833,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044a7"
    },
    "Rank": 8834,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN,HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN, Karnataka, 573201",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044a8"
    },
    "Rank": 8835,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044a9"
    },
    "Rank": 8836,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044aa"
    },
    "Rank": 8839,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044ab"
    },
    "Rank": 8843,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044ac"
    },
    "Rank": 8844,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Batra Hospital and Medical Research Centre, ,1, Tughlakabad institutional Area, Mehrauli Badarpur Road, New Delhi, Delhi (NCT), 110062",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044ad"
    },
    "Rank": 8845,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B. Nanavati Hospital, Vivekanand Rd,Swami Vivekananda Rd, near LIC, LIC Colony, Suresh Colony, Vile Parle West, Mumbai, Maharashtra 4000, Maharashtra, 400056",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044ae"
    },
    "Rank": 8847,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044af"
    },
    "Rank": 8853,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044b0"
    },
    "Rank": 8854,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. Medical College (Maharani Hospital), Jagdalpur, Chhattisgarh,Late Baliram Kashyap memorial Govt. Medical College, Dimrapal, Jagdalpur Dist-Bastar, Chhattisgarh, 494001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044b1"
    },
    "Rank": 8856,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Laddhad Hospital,,WANKHEDE LAY OUT BULDANA, Maharashtra, 443001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044b2"
    },
    "Rank": 8857,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044b3"
    },
    "Rank": 8860,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044b4"
    },
    "Rank": 8862,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Datia,Near 29 Batallion, National Highway 44, Dist Datia, Madhya Pradesh Pin - 475661, Madhya Pradesh, 475661",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044b5"
    },
    "Rank": 8864,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044b6"
    },
    "Rank": 8866,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044b7"
    },
    "Rank": 8874,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044b8"
    },
    "Rank": 8878,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044b9"
    },
    "Rank": 8879,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Muthoot Health Care, ,Muthoot Healthcare, College Road, Kozhencherry, Pathanamthitta District, Kerala, 689641",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044ba"
    },
    "Rank": 8880,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044bb"
    },
    "Rank": 8881,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044bc"
    },
    "Rank": 8882,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044bd"
    },
    "Rank": 8886,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ernakulam Medical Centre,,ERNAKULAM MEDICAL CENTRE NH BYPASS, KOCHI, KERALA, INDIA - 682028, Kerala, 682028",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044be"
    },
    "Rank": 8887,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044bf"
    },
    "Rank": 8888,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044c0"
    },
    "Rank": 8889,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT DHARMAPURI MEDICAL COLLEGE, DHARMAPURI, TAMIL NADU,GOVERNMENT DHARMAPURI MEDICAL COLLEGE, NETHAJI BYE PASS ROAD, DHARMAPURI, TAMIL NADU, Tamil Nadu, 636701",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044c1"
    },
    "Rank": 8890,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KAMAKSHI HOSPITAL, Karnataka,KAMAKSHI HOSPITAL KAMAKSHI HOSPITAL ROAD\nKUVEMPU NAGAR\nMYSORE, Karnataka, 570009",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044c2"
    },
    "Rank": 8892,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044c3"
    },
    "Rank": 8893,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B. Nanavati Hospital, Vivekanand Rd,Swami Vivekananda Rd, near LIC, LIC Colony, Suresh Colony, Vile Parle West, Mumbai, Maharashtra 4000, Maharashtra, 400056",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044c4"
    },
    "Rank": 8895,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044c5"
    },
    "Rank": 8896,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sparsh Hospital, Karnataka,Ideal Homes HBCS Layout, 8, 4th Cross Rd, Javarandoddi, Rajarajeshwari Nagar, Bengaluru, Karnataka, 560098",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044c6"
    },
    "Rank": 8897,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaiprakash Hospital and Research Centre Pvt. Ltd, Odisha,,JAIPRAKASH HOSPITAL AND RESEARCH CENTER PVT. LTD., DANDIAPALI, ROURKELA, Odisha, 769004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044c7"
    },
    "Rank": 8898,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Doon Medical College, Dehradun,Govt. Doon Medical College Dehrakhas, Patel Nagar Dehradun 248001, Uttarakhand, 248001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044c8"
    },
    "Rank": 8899,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044c9"
    },
    "Rank": 8901,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044ca"
    },
    "Rank": 8902,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044cb"
    },
    "Rank": 8903,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kauvery Hospital, ,No.199, Luz Church road, Mylapore,Chennai-600004, Tamil Nadu, 600004",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044cc"
    },
    "Rank": 8905,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044cd"
    },
    "Rank": 8910,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044ce"
    },
    "Rank": 8912,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044cf"
    },
    "Rank": 8913,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "P.D. Hinduja National Hospital and Medical Research Centre, ,Veer Savarkar Marg, Mahim, Maharashtra, 400016",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044d0"
    },
    "Rank": 8914,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044d1"
    },
    "Rank": 8915,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Peerless Hospital and B K Roy Research Centre, ,360, Panchasayar, Kolkata - 700 -094, West Bengal, 700094",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044d2"
    },
    "Rank": 8916,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044d3"
    },
    "Rank": 8917,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Central Hospital, ,Central Hospital, P.O- Jagjiwan Nagar, Dist- Dhanbad, Pin-826003, Jharkhand, 826003",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044d4"
    },
    "Rank": 8919,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044d5"
    },
    "Rank": 8920,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044d6"
    },
    "Rank": 8921,
    "Allotted Quota": "All India",
    "Allotted Institute": "GADAG INSTITUTE OF MEDICAL SCIENCES GADAG,Gadag Institute of Medical Sciences Mallasamudra Gadag-582103, Karnataka, 582103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044d7"
    },
    "Rank": 8922,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044d8"
    },
    "Rank": 8923,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deen Dayal Upadhyay Hospital, ,DDU HOSPITAL, SHAHEED MANGAL PANDEY MARG, NANAK PURA, HARI NAGAR, NEW DELHI, DELHI-110064, Delhi (NCT), 110064",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044d9"
    },
    "Rank": 8924,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044da"
    },
    "Rank": 8928,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gvernment Medical College,Haldwani,Government Medical College, Haldwani, Uttarakhand, 263139",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044db"
    },
    "Rank": 8929,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sanjay Gandhi Memorial Hospital, , ,Sanjay Gandhi Memorial Hospital\nS-Block, Near Bus Terminal, Mangolpuri, North West, Delhi - 110083, Delhi (NCT), 110083",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044dc"
    },
    "Rank": 8931,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044dd"
    },
    "Rank": 8932,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044de"
    },
    "Rank": 8933,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044df"
    },
    "Rank": 8935,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhavsinhji and Maharani Rupaliba General Hospital, ,Near S. T. Bus Stand, Opp.\nBhutnath Temple, Hospital Road, Porbandar, Gujarat, 360575",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044e0"
    },
    "Rank": 8937,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044e1"
    },
    "Rank": 8940,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044e2"
    },
    "Rank": 8942,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044e3"
    },
    "Rank": 8943,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Stephen`s Hospital, ,Tis Hazari, Delhi (NCT), 110054",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044e4"
    },
    "Rank": 8944,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tricolour Hospital, ,Dr. Vikram Sarabhai Road, near Genda Circle, Vadodara, Gujarat, 390007",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044e5"
    },
    "Rank": 8945,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Jamshedpur,Mahatma Gandhi Memorial Medical College, Jamshedpur, Jharkhand, 831020",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044e6"
    },
    "Rank": 8946,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044e7"
    },
    "Rank": 8947,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Southern Railway Head Quarter Hospital, ,Ayanavaram, Tamil Nadu, 600023",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044e8"
    },
    "Rank": 8948,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044e9"
    },
    "Rank": 8951,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KMC Specialty Hospital, ,No-6, Royal Road, Cantonment, Trichy-620001, Tamil Nadu, 620001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044ea"
    },
    "Rank": 8952,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044eb"
    },
    "Rank": 8955,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SK HOSPITAL, Kerala,skhospitalsyahoo.co.in, Kerala, 695006",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044ec"
    },
    "Rank": 8956,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044ed"
    },
    "Rank": 8957,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044ee"
    },
    "Rank": 8958,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster CMI Hospital, ,43/2 New Airport Road, NH-7, Kodigehalli, Sahakara Nagar, Hebbal, Bengaluru, Karnataka, 560092",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044ef"
    },
    "Rank": 8959,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044f0"
    },
    "Rank": 8960,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044f1"
    },
    "Rank": 8961,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "YATHARTH SUPER SPECIALTY HOSPITALS,\nUttar Pradesh,Sector omega 01-, Greater noida, Uttar Pradesh, 201308",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044f2"
    },
    "Rank": 8962,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044f3"
    },
    "Rank": 8963,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044f4"
    },
    "Rank": 8964,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044f5"
    },
    "Rank": 8965,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "G Kuppuswamy Naidu Memorial Hospital, ,Post Box No.6327, Nethaji Road, Pappanaickenpalayam, Coimbatore, Tamil Nadu, 641037",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044f6"
    },
    "Rank": 8966,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS MEDICAL COLLEGE, NEAR GUJARAT HIGH COURT, S.G HIGH WAY, SOLA, AHMEDABAD, Gujarat, 380060",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044f7"
    },
    "Rank": 8969,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tirumala Hospital, ,PSR COMPLEX, NEAR RTC COMPLEX, VIZIANAGARAM, Andhra Pradesh, 535003",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044f8"
    },
    "Rank": 8970,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S. G. L. Super Specialty Charitable Hospital, ,GARHA ROAD, JALANDHAR, Punjab, 144022",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044f9"
    },
    "Rank": 8971,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gvernment Medical College,Haldwani,Government Medical College, Haldwani, Uttarakhand, 263139",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044fa"
    },
    "Rank": 8972,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvarur Govt.Medical College,Thiruvarur Govt.Medical College, Tamil Nadu, 610004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044fb"
    },
    "Rank": 8975,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044fc"
    },
    "Rank": 8976,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajagiri Hospital,Rajagiri Hospital, Chunagamvely, Aluva 683112, Kerala, 683112",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044fd"
    },
    "Rank": 8978,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044fe"
    },
    "Rank": 8980,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a044ff"
    },
    "Rank": 8982,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindu Rao Hospital, ,SUBZI MANDI, MALKA GANJ, DELHI-110007, Delhi (NCT), 110007",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04500"
    },
    "Rank": 8983,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL CANCER CENTRE,REGIONAL CANCER CENTRE, Kerala, 695011",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04501"
    },
    "Rank": 8984,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rukmani Birla Hospital , (A unit of The Calcutta Medical Research Institute) ,Gopalpura Bypass Road, Near Triveni Flyover, Jaipur, Rajasthan, 302018",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04502"
    },
    "Rank": 8986,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04503"
    },
    "Rank": 8988,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agrasen Medical College,,NH- 9, VPO - Agroha, District Hisar, Haryana, 125047",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04504"
    },
    "Rank": 8991,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04505"
    },
    "Rank": 8993,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04506"
    },
    "Rank": 8997,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Jeyasekharan Hospital and Nursing Home, ,Dr.Jeyasekharan Hospital and Nursing Home,\nK.P.Road, Nagercoil, Kanyakumari District, Tamil Nadu, 629003",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04507"
    },
    "Rank": 9008,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mallige Medical Centre, ,No 31 32 Crescent Road Bangalore 560001, Karnataka, 560001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04508"
    },
    "Rank": 9010,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04509"
    },
    "Rank": 9011,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Adlux Hospital ( A Unit of Adlux Medicity and Convention Centre Private Limited), Kerala\n,Apollo Adlux Hospital (A Unit of Adlux medicity and Convention Centre Private limited), Adlux juncti, Kerala, 683576",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0450a"
    },
    "Rank": 9012,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0450b"
    },
    "Rank": 9013,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Central Hospital, ,Central Hospital, P.O- Jagjiwan Nagar, Dist- Dhanbad, Pin-826003, Jharkhand, 826003",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0450c"
    },
    "Rank": 9015,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0450d"
    },
    "Rank": 9021,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sahyadri Narayana Multispecialty Hospital, Harakere,,NT ROAD, HARAKERE , SHIMOGA, Karnataka, 577202",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0450e"
    },
    "Rank": 9022,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0450f"
    },
    "Rank": 9023,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04510"
    },
    "Rank": 9024,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Gondia,Nehru Chowk, KTS General Hospital Campus, Govt. Medical College, Gondia. 441601. Maharashtra., Maharashtra, 441601",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04511"
    },
    "Rank": 9026,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindu Rao Hospital, ,SUBZI MANDI, MALKA GANJ, DELHI-110007, Delhi (NCT), 110007",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04512"
    },
    "Rank": 9027,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Multispeciality Hospital, West Bengal,Narayana Multispeciality Hospital 78, Jessore Road ( South),\nKolkata, West Bengal, West Bengal, 700127",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04513"
    },
    "Rank": 9029,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Revathi Medical Center Tirupur (I) Pvt Ltd ,\nTamil Nadu,No: 10, KUMAR NAGAR WEST, VALAYANKADU ROAD, AVINASHI ROAD, TIRUPUR, Tamil Nadu, 641603",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04514"
    },
    "Rank": 9030,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sacred Heart Hospital, ,Sacred Heart Hospital Maqsudan\nJalandhar Punjab, Punjab, 144008",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04515"
    },
    "Rank": 9031,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04516"
    },
    "Rank": 9032,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04517"
    },
    "Rank": 9034,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04518"
    },
    "Rank": 9035,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "IQRAA International Hospital and Research Centre, ,IQRAA INTERNATIONAL HOSPITAL AND RESEARCH CENTRE MALAPARAMBA CALICUT, Kerala, 673009",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04519"
    },
    "Rank": 9036,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0451a"
    },
    "Rank": 9038,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Smart Super Specialty Hospital, (Formerly Known as Saket City Hospital), ,Mandir Marg, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0451b"
    },
    "Rank": 9040,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Park Hospital, Haryana,near Siwah village GT road\nPanipat, Haryana, 132103",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0451c"
    },
    "Rank": 9041,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0451d"
    },
    "Rank": 9042,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0451e"
    },
    "Rank": 9043,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Institute of Medical Sciences (SVIMS), A State University estd. by Govt. of A.P.,Sri Venkateswara Institute of Medical Sciences, SVIMS, Alipiri Road, Tirupati, Andhra Pradesh, 517507",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0451f"
    },
    "Rank": 9045,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04520"
    },
    "Rank": 9047,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04521"
    },
    "Rank": 9048,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medical Trust Hospital, ,M.G. Road Pallimukku\nCochin, Kerala, 682016",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04522"
    },
    "Rank": 9049,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04523"
    },
    "Rank": 9050,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04524"
    },
    "Rank": 9053,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "P.D. Hinduja National Hospital and Medical Research Centre, ,Veer Savarkar Marg, Mahim, Maharashtra, 400016",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04525"
    },
    "Rank": 9055,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "IQRAA International Hospital and Research Centre, ,IQRAA INTERNATIONAL HOSPITAL AND RESEARCH CENTRE MALAPARAMBA CALICUT, Kerala, 673009",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04526"
    },
    "Rank": 9058,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "College of Medicine and JNM Hospital,,Kalyani, Silpanchal, West Bengal, 741235",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04527"
    },
    "Rank": 9059,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04528"
    },
    "Rank": 9061,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lilavati Hospital and Research Centre, ,Lilavati Hospital And Research Centre A-791, Bandra Reclamation, Bandra - West, Mumbai, Maharashtra, 400050",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04529"
    },
    "Rank": 9063,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0452a"
    },
    "Rank": 9064,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0452b"
    },
    "Rank": 9066,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVERNMENT MEDICAL COLLEGE,KANYAKUMARI GOVERNMENT MEDICAL COLLEGE, Tamil Nadu, 629201",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0452c"
    },
    "Rank": 9067,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0452d"
    },
    "Rank": 9069,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "P.L. Sharma District Hospital,,Ahmad Road, Near Ghantaghar, Meerut City, Uttar Pradesh, 250002",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0452e"
    },
    "Rank": 9072,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "College of Medicine and JNM Hospital,,Kalyani, Silpanchal, West Bengal, 741235",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0452f"
    },
    "Rank": 9073,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04530"
    },
    "Rank": 9074,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04531"
    },
    "Rank": 9075,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04532"
    },
    "Rank": 9076,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pimpri Chichwad Municipal Corporation Postgraduate Institute Y.C.M.Hospital Pimpri,Pune 18,Dean Office, Y.C.M.Hospital, Sant Tukaramnagar Pimpri, Pune, Maharashtra, 411018",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04533"
    },
    "Rank": 9077,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04534"
    },
    "Rank": 9078,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04535"
    },
    "Rank": 9081,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04536"
    },
    "Rank": 9082,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Childrens Hospital, ,178/1 and  178/2, Bannerghatta Main Rd, opposite Janardhan Towers, Dollars Colony, Phase 4, Bilekaha, Karnataka, 560076",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04537"
    },
    "Rank": 9083,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Swami Dayanand Hospital, ,Dilshad Garden, Shahdara, Delhi, Delhi (NCT), 110095",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04538"
    },
    "Rank": 9086,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Caritas Hospital, ,Thellakom P.O, Kerala, 686630",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04539"
    },
    "Rank": 9088,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0453a"
    },
    "Rank": 9091,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0453b"
    },
    "Rank": 9092,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sukhda Multi Specialty Hospital, Haryana,SUKHDA MULTISPECIALITY HOSPITAL,DELHI ROAD, OPPOSITE GREEN BELT, HISAR - 125001 , HARYANA,\nHaryana, 125001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0453c"
    },
    "Rank": 9093,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0453d"
    },
    "Rank": 9097,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE, PUDUCHERRY,INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE,\nPUDUCHERRY, Puducherry, 605009",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0453e"
    },
    "Rank": 9098,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0453f"
    },
    "Rank": 9099,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04540"
    },
    "Rank": 9101,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04541"
    },
    "Rank": 9102,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04542"
    },
    "Rank": 9104,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aditya Diagnostics and Hospital,,Bordoloi Avenue, Dibrugarh-786005, Assam, 786005",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04543"
    },
    "Rank": 9105,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,Government Medical College, Near RTC Bus stand, Gollaguda, Nalgonda Telangana - 508001, Telangana, 508001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04544"
    },
    "Rank": 9106,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04545"
    },
    "Rank": 9107,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Sivagangai Medical College and Hospital, Tamil Nadu,GOVT.SIVAGANGAI MEDICAL COLLEGE, MELAVANIYANKUDY, MANAMADURAI ROAD, SIVAGANGAI.,\nTamil Nadu, 630561",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04546"
    },
    "Rank": 9108,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04547"
    },
    "Rank": 9109,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES ADILABAD -504001 TELANGANA STATE, Telangana, 504001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04548"
    },
    "Rank": 9111,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04549"
    },
    "Rank": 9113,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0454a"
    },
    "Rank": 9114,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0454b"
    },
    "Rank": 9115,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indraprastha Apollo Hospital,,Sarita Vihar, New Delhi 110 076, Delhi (NCT), 110076",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0454c"
    },
    "Rank": 9117,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0454d"
    },
    "Rank": 9119,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BharatRatna Dr. Babasaheb Ambedkar Municipal General Hospital, Kandivali (West), Maharashtra,Parekh Nagar, SV Road, Opp. Kandivali Police station, Kandivali West, Mumbai, Maharashtra, 400067",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0454e"
    },
    "Rank": 9122,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0454f"
    },
    "Rank": 9124,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Satguru Partap Singh Hospital, ,Sherpur Chowk, GT Road, Ludhiana, Punjab-141003, Punjab, 141003",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04550"
    },
    "Rank": 9125,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04551"
    },
    "Rank": 9128,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences, ,academics@kimshospitals.com, Telangana, 500003",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04552"
    },
    "Rank": 9129,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.G. Patel Children Hospital and Jayodia Research Institute,, ,Medical Care Centre Trust\nK.G. Patel Children Hospital,\nJalaram Marg, Karelibaug, Vadodara, Gujarat, 390018",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04553"
    },
    "Rank": 9130,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04554"
    },
    "Rank": 9132,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tirumala Hospital, ,PSR COMPLEX, NEAR RTC COMPLEX, VIZIANAGARAM, Andhra Pradesh, 535003",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04555"
    },
    "Rank": 9134,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04556"
    },
    "Rank": 9135,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04557"
    },
    "Rank": 9136,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04558"
    },
    "Rank": 9138,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04559"
    },
    "Rank": 9139,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0455a"
    },
    "Rank": 9140,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Advanced Medicare and Research Institute (AMRI),,p-4&5, CIT scheme, Block-A, Gariahat Road, Kolkata-700029, West Bengal, West Bengal, 700029",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0455b"
    },
    "Rank": 9141,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0455c"
    },
    "Rank": 9143,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sagar Hospital - ,Shavige Malleshwara Hills, Kumaraswamy Layout, Bangalore, Karnataka, 560078",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0455d"
    },
    "Rank": 9146,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0455e"
    },
    "Rank": 9148,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0455f"
    },
    "Rank": 9149,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04560"
    },
    "Rank": 9154,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Rangarajan Memorial Hospital, (Sundaram Medical Foundation) ,Sundaram Medical Foundation, Shanthi Colony, 4th Avenue, Anna Nagar, Chennai, Tamil Nadu, 600040",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04561"
    },
    "Rank": 9155,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04562"
    },
    "Rank": 9157,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04563"
    },
    "Rank": 9160,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kailash Hospital and Heart Institute,\nUttar Pradesh,H-33 SECTOR  27 NOIDA, Uttar Pradesh, 201301",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04564"
    },
    "Rank": 9161,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo BGS Hospital,,Apollo BGS Hospitals, Adhichunchangiri Road, Kuvempunagar, Mysore, Karnataka, 570023",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04565"
    },
    "Rank": 9163,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04566"
    },
    "Rank": 9164,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04567"
    },
    "Rank": 9165,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04568"
    },
    "Rank": 9168,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04569"
    },
    "Rank": 9169,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0456a"
    },
    "Rank": 9172,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0456b"
    },
    "Rank": 9173,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0456c"
    },
    "Rank": 9174,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0456d"
    },
    "Rank": 9176,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Arvind Eye Hospital and PG Instt.of Ophthalmology, ,No.1, S.N.High Road, Tirunelveli, Tamil Nadu, 627001",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0456e"
    },
    "Rank": 9177,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0456f"
    },
    "Rank": 9181,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04570"
    },
    "Rank": 9182,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nazareth Hospital, Laitumukhrah, ,Laitumkhrah Shillong, East Khasi Hills district, Meghalaya, 793003",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04571"
    },
    "Rank": 9183,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04572"
    },
    "Rank": 9184,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04573"
    },
    "Rank": 9185,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04574"
    },
    "Rank": 9186,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES ADILABAD -504001 TELANGANA STATE, Telangana, 504001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04575"
    },
    "Rank": 9190,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04576"
    },
    "Rank": 9192,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaikh-Ul-Hind Maulana Mahmood Hasan Medical College, Saharanpur,Pilkhani Ambala Road, Uttar Pradesh, 247232",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04577"
    },
    "Rank": 9195,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04578"
    },
    "Rank": 9196,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04579"
    },
    "Rank": 9197,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0457a"
    },
    "Rank": 9198,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0457b"
    },
    "Rank": 9199,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0457c"
    },
    "Rank": 9201,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0457d"
    },
    "Rank": 9202,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0457e"
    },
    "Rank": 9203,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Arvind Eye Hospital, ,Aravind Eye Hospital and Postgraduate Institute of Ophthalmology, Cuddalore Main Road, Thavalakuppam, Puducherry, 605007",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0457f"
    },
    "Rank": 9204,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SANKARA NETHRALAYA ,\nTamil Nadu,No 21 Pycrofts Garden Road off. Haddows Road\nChennai, Tamil Nadu, 600006",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04580"
    },
    "Rank": 9205,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04581"
    },
    "Rank": 9206,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04582"
    },
    "Rank": 9209,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04583"
    },
    "Rank": 9211,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04584"
    },
    "Rank": 9212,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04585"
    },
    "Rank": 9213,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04586"
    },
    "Rank": 9214,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Ophthalmology,RIO Prev Known as Nehru Institute of Ophthalmology and Research Centre Civil Lines Sitapur, Uttar Pradesh, 261001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04587"
    },
    "Rank": 9216,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04588"
    },
    "Rank": 9217,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kothari Medical and Research Institute, ,NH-15, Jaisalmer road, Bikaner, Rajasthan, 334004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04589"
    },
    "Rank": 9218,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College, ,Office of the Dean, First Floor, Civil Hospital Campus, Sector- 12, Gandhinagar, Gujarat, 382016",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0458a"
    },
    "Rank": 9220,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0458b"
    },
    "Rank": 9221,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0458c"
    },
    "Rank": 9222,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Calcutta Medical Research Institute, ,The Calcutta Medical Research Institute 7 by 2 Diamond Harbour Road Kolkata 700027, West Bengal, 700027",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0458d"
    },
    "Rank": 9224,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Advanced Medicare and Research Institute (AMRI),,p-4&5, CIT scheme, Block-A, Gariahat Road, Kolkata-700029, West Bengal, West Bengal, 700029",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0458e"
    },
    "Rank": 9226,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0458f"
    },
    "Rank": 9227,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04590"
    },
    "Rank": 9229,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04591"
    },
    "Rank": 9230,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,4th T Block, Beside Rajiv Gandhi University of Health Sciences Jayanagar,, Karnataka, 560041",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04592"
    },
    "Rank": 9232,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indraprastha Apollo Hospital,,Sarita Vihar, New Delhi 110 076, Delhi (NCT), 110076",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04593"
    },
    "Rank": 9233,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Erode Medical College- Formerly IRT-Perundurai Medical College,GOVERNMENT ERODE MEDICAL COLLEGE AND  HOSPITAL PERUNDURAI SANATORIUM POST ERODE  DISTRICT, Tamil Nadu, 638053",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04594"
    },
    "Rank": 9234,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "CHL Hospital,,L.I.G. SQUARE A.B. ROAD, INDORE, Madhya Pradesh, 452008",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04595"
    },
    "Rank": 9235,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruby Hall Clinic, ,GRANT MEDICAL FOUNDATION,\n40 SASSOON ROAD, PUNE 411001, Maharashtra, 411001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04596"
    },
    "Rank": 9238,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04597"
    },
    "Rank": 9240,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College,Theni ,Government Medical College,Theni, Tamil Nadu, 625512",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04598"
    },
    "Rank": 9241,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ozone Multispeciality hospital and Critical care center, Maharashtra,Holycross convent Road, Kedia Plots, Akola, Maharashtra, 444005",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04599"
    },
    "Rank": 9242,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Childrens Hospital, S,KR Puram, Survey No. 8/5, Outer Ring Rd, Doddanekundi, Marathahalli, Bengaluru, Karnataka 560037, Karnataka, 560037",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0459a"
    },
    "Rank": 9243,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0459b"
    },
    "Rank": 9244,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0459c"
    },
    "Rank": 9245,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Super Speciality Hospital, Delhi,Rajiv Gandhi Super Speciality Hospital,Tahirpur, Delhi, Delhi (NCT), 110093",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0459d"
    },
    "Rank": 9247,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0459e"
    },
    "Rank": 9248,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahavir Jaipuriya Rajasthan Hospital, Rajasthan\n,Opposite Jaipuriya Hospital, Hospital Road, Milap Nagar, JLN Marg, Jaipur, Rajasthan, 302018",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0459f"
    },
    "Rank": 9250,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Sathya Sai Sarla Memorial Hospital, Karnataka,Sathya Sai Grama Muddenahalli\nChikkaballapura, Karnataka, 562101",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045a0"
    },
    "Rank": 9251,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gvernment Medical College,Haldwani,Government Medical College, Haldwani, Uttarakhand, 263139",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045a1"
    },
    "Rank": 9252,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rabindranath Tagore International Institute of Cardiac Sciences, ,PREMISES NO. 1489 124 MUKUNDAPUR  E M BYPASS  KOLKATA-700099, West Bengal, 700099",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045a2"
    },
    "Rank": 9253,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deen Dayal Upadhyay Hospital, ,DDU HOSPITAL, SHAHEED MANGAL PANDEY MARG, NANAK PURA, HARI NAGAR, NEW DELHI, DELHI-110064, Delhi (NCT), 110064",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045a3"
    },
    "Rank": 9254,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045a4"
    },
    "Rank": 9257,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Metro Hospital and Cancer Research Centre,,KUCHAINI PARISAR, DAMOH NAKA, JABALPUR, Madhya Pradesh, 482002",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045a5"
    },
    "Rank": 9258,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Christian Fellowship Hospital, ,Dindigul Palani Main Road Oddanchatram\nDindigul District, Tamil Nadu, 624619",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045a6"
    },
    "Rank": 9259,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VELLORE MEDICAL COLLEGE,GOVERNMENT VELLORE MEDICAL COLLEGE, Tamil Nadu, 632011",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045a7"
    },
    "Rank": 9260,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S. G. L. Super Specialty Charitable Hospital, ,GARHA ROAD, JALANDHAR, Punjab, 144022",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045a8"
    },
    "Rank": 9261,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045a9"
    },
    "Rank": 9263,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045aa"
    },
    "Rank": 9265,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045ab"
    },
    "Rank": 9266,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045ac"
    },
    "Rank": 9268,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045ad"
    },
    "Rank": 9270,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster Medcity, Kuttisahib Road,,Aster Medcity Kuttisahib Road\nSouth Chittoor P.O. Cheranalloor\nKochi, Kerala, 682027",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045ae"
    },
    "Rank": 9274,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045af"
    },
    "Rank": 9278,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Family Hospital,,Okhla Road, New Delhi, Delhi (NCT), 110025",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045b0"
    },
    "Rank": 9286,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Hurkisondas Nurrotumdas Hospital and Research Centre, ,Prarthana Samaj, Raja Ram Mohan Roy road,\nGirgaon, MUMBAI, Maharashtra, 400004",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045b1"
    },
    "Rank": 9287,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lourdes Hospital, ,Ernakulam, Pachalam, Kochi, Kerala, 682012",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045b2"
    },
    "Rank": 9288,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045b3"
    },
    "Rank": 9290,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045b4"
    },
    "Rank": 9291,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Valluvanad Hospital Complex Limited,KANNIYAMPURAM POST OTTAPALAM\nPALAKKAD KERALA, Kerala, 679104",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045b5"
    },
    "Rank": 9292,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045b6"
    },
    "Rank": 9294,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045b7"
    },
    "Rank": 9295,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045b8"
    },
    "Rank": 9296,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Medical College and Chhatrapati Shivaji Maharaj Hospital, ,RAJIV GANDHI MEDICAL COLLEGE,THANE BELAPUR ROAD, KALWA, THANE, Maharashtra, 400605",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045b9"
    },
    "Rank": 9299,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045ba"
    },
    "Rank": 9300,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045bb"
    },
    "Rank": 9302,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Santokbha Durlabhji Memorial Hospital Cum Medical Research Institute,\n,dnb.sdmh@gmail.com, Rajasthan, 302015",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045bc"
    },
    "Rank": 9303,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045bd"
    },
    "Rank": 9304,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045be"
    },
    "Rank": 9305,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045bf"
    },
    "Rank": 9306,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045c0"
    },
    "Rank": 9310,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045c1"
    },
    "Rank": 9312,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045c2"
    },
    "Rank": 9313,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045c3"
    },
    "Rank": 9314,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045c4"
    },
    "Rank": 9316,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045c5"
    },
    "Rank": 9322,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Stephen`s Hospital, ,Tis Hazari, Delhi (NCT), 110054",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045c6"
    },
    "Rank": 9324,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KMC Specialty Hospital, ,No-6, Royal Road, Cantonment, Trichy-620001, Tamil Nadu, 620001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045c7"
    },
    "Rank": 9325,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fernandez Hospital, ,4-1-1230, Bogulkunta, Hyderabad, Telangana-500001, Telangana, 500001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045c8"
    },
    "Rank": 9326,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata Shrimati Devendra Kumari Singhdeo Government Medical College, Ambikapur,Kanyaparisar  Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045c9"
    },
    "Rank": 9327,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045ca"
    },
    "Rank": 9328,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Siddipet,SURVEY NO 54  GOVERNMENT MEDICAL COLLEGE  ENSANPALLY  SIDDIPET   502114, Telangana, 502114",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045cb"
    },
    "Rank": 9329,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045cc"
    },
    "Rank": 9331,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "PRS Hospital,,PRS HOSPITAL PVT LTD KILLIPALAM THIRUVANATHAPURAM\nKERALA. <academics@prshospital.com>, Kerala, 695002",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045cd"
    },
    "Rank": 9334,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045ce"
    },
    "Rank": 9336,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045cf"
    },
    "Rank": 9337,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rohini Medicare, (Dr. J Sudhakar Reddy) ,2-5-742 Subedari Hanamkonda Warangal, Telangana, 506001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045d0"
    },
    "Rank": 9338,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Superspecialty Hospital,,Junction of 2nd Hoogly Bridge & Andul Road, 120/1 Andul Road, West Bengal, 711103",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045d1"
    },
    "Rank": 9340,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC MEDICAL COLLEGE, JOKA,ESI-PGIMSR & ESIC MEDICAL COLLEGE, JOKA, KOLKATA, WEST BENGAL, West Bengal, 700104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045d2"
    },
    "Rank": 9341,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045d3"
    },
    "Rank": 9343,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045d4"
    },
    "Rank": 9344,
    "Allotted Quota": "All India",
    "Allotted Institute": "Murshidabad Medical College and Hospital,Station Road, Berhampore, Murshidabad, West Bengal, 742101",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045d5"
    },
    "Rank": 9347,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045d6"
    },
    "Rank": 9348,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045d7"
    },
    "Rank": 9349,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045d8"
    },
    "Rank": 9350,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fernandez Hospital, ,4-1-1230, Bogulkunta, Hyderabad, Telangana-500001, Telangana, 500001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045d9"
    },
    "Rank": 9352,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rukmani Birla Hospital , (A unit of The Calcutta Medical Research Institute) ,Gopalpura Bypass Road, Near Triveni Flyover, Jaipur, Rajasthan, 302018",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045da"
    },
    "Rank": 9353,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045db"
    },
    "Rank": 9354,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045dc"
    },
    "Rank": 9355,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045dd"
    },
    "Rank": 9356,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Childrens Hospital, S,KR Puram, Survey No. 8/5, Outer Ring Rd, Doddanekundi, Marathahalli, Bengaluru, Karnataka 560037, Karnataka, 560037",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045de"
    },
    "Rank": 9358,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045df"
    },
    "Rank": 9359,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045e0"
    },
    "Rank": 9360,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sukhda Multi Specialty Hospital, Haryana,SUKHDA MULTISPECIALITY HOSPITAL,DELHI ROAD, OPPOSITE GREEN BELT, HISAR - 125001 , HARYANA,\nHaryana, 125001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045e1"
    },
    "Rank": 9367,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045e2"
    },
    "Rank": 9368,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045e3"
    },
    "Rank": 9369,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvarur Govt.Medical College,Thiruvarur Govt.Medical College, Tamil Nadu, 610004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045e4"
    },
    "Rank": 9370,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045e5"
    },
    "Rank": 9371,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "QRG Medicare, ,Plot No. 1, Sector-16, Faridabad, Haryana, Haryana, 121002",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045e6"
    },
    "Rank": 9376,
    "Allotted Quota": "All India",
    "Allotted Institute": "BHOPAL MEMORIAL HOSPITAL AND RESEARCH CENTRE, BHOPAL,Raisen By pass\nRoad, Bhopal- 462038 (M.P.), Madhya Pradesh, 462038",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045e7"
    },
    "Rank": 9378,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "QRG Medicare, ,Plot No. 1, Sector-16, Faridabad, Haryana, Haryana, 121002",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045e8"
    },
    "Rank": 9379,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045e9"
    },
    "Rank": 9380,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaikh-Ul-Hind Maulana Mahmood Hasan Medical College, Saharanpur,Pilkhani Ambala Road, Uttar Pradesh, 247232",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045ea"
    },
    "Rank": 9381,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045eb"
    },
    "Rank": 9382,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KIMS Al Shifa Hospital, ,KIMS Al Shifa Healthcare Pvt  Ltd.\nPB No 26 .\nOotty Road.\nPerinthalmanna, Malappuram district, Ke, Kerala, 679322",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045ec"
    },
    "Rank": 9388,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chamarajanagar Institute of Medical Sciences, Karnataka,CHAMARAJANAGAR INSTITUTE OF MEDICAL SCIENCES, SY. NO 124, YEDAPURA VILLAGE KASABA HOBLI, CHAMARAJANA, Karnataka, 571313",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045ed"
    },
    "Rank": 9389,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "YATHARTH SUPER SPECIALTY HOSPITALS,\nUttar Pradesh,Sector omega 01-, Greater noida, Uttar Pradesh, 201308",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045ee"
    },
    "Rank": 9392,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES ADILABAD -504001 TELANGANA STATE, Telangana, 504001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045ef"
    },
    "Rank": 9393,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045f0"
    },
    "Rank": 9394,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045f1"
    },
    "Rank": 9397,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045f2"
    },
    "Rank": 9398,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045f3"
    },
    "Rank": 9399,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045f4"
    },
    "Rank": 9400,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045f5"
    },
    "Rank": 9401,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045f6"
    },
    "Rank": 9406,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Ramachandra Childrens and Dental Hospital,,SRI RAMACHANDRA CHILDRENS AND DENTAL HOSPITAL, D.NO 13-7-1, 6 LANE, GUNTURUVARITHOTA, OLD CLUB\nROAD,, Andhra Pradesh, 522001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045f7"
    },
    "Rank": 9409,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045f8"
    },
    "Rank": 9410,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045f9"
    },
    "Rank": 9412,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045fa"
    },
    "Rank": 9416,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agrasen Medical College,,NH- 9, VPO - Agroha, District Hisar, Haryana, 125047",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045fb"
    },
    "Rank": 9418,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045fc"
    },
    "Rank": 9419,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sanjay Gandhi Postgarduate Institute of Medical Sciences,Sanjay Gandhi Postgarduate Institute of Medical Sciences, Uttar Pradesh, 226014",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045fd"
    },
    "Rank": 9421,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045fe"
    },
    "Rank": 9423,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Stephen`s Hospital, ,Tis Hazari, Delhi (NCT), 110054",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a045ff"
    },
    "Rank": 9424,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04600"
    },
    "Rank": 9425,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Healthway Hospital, Goa\n,plot no 132, Ella, Tiswadi, Old Goa, Goa, 403402",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04601"
    },
    "Rank": 9427,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Hospital, ,Government Medical College and Associated Hospital, Kheora, Rajouri, Jammu and Kashmir-185131, Jammu And Kashmir, 185131",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04602"
    },
    "Rank": 9429,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04603"
    },
    "Rank": 9430,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tricolour Hospital, ,Dr. Vikram Sarabhai Road, near Genda Circle, Vadodara, Gujarat, 390007",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04604"
    },
    "Rank": 9432,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pimpri Chichwad Municipal Corporation Postgraduate Institute Y.C.M.Hospital Pimpri,Pune 18,Dean Office, Y.C.M.Hospital, Sant Tukaramnagar Pimpri, Pune, Maharashtra, 411018",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04605"
    },
    "Rank": 9433,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04606"
    },
    "Rank": 9435,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04607"
    },
    "Rank": 9436,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04608"
    },
    "Rank": 9437,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04609"
    },
    "Rank": 9438,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0460a"
    },
    "Rank": 9440,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lokopriya Gopinath Bordoloi Regional Institute of Mental Health,LGBRIMH, Tezpur, District Sonitpur,, Assam, 784001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0460b"
    },
    "Rank": 9443,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0460c"
    },
    "Rank": 9446,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0460d"
    },
    "Rank": 9447,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0460e"
    },
    "Rank": 9449,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0460f"
    },
    "Rank": 9451,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rangadore Memorial Hospital,,1ST CROSS, SHANKARAPURAM, BANGALORE, Karnataka, 560004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04610"
    },
    "Rank": 9452,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kailash Hospital and Heart Institute,\nUttar Pradesh,H-33 SECTOR  27 NOIDA, Uttar Pradesh, 201301",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04611"
    },
    "Rank": 9453,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES,CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, Chhattisgarh, 495001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04612"
    },
    "Rank": 9454,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04613"
    },
    "Rank": 9455,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ST. JOSEPH HOSPITAL ,\nTamil Nadu,15,Trichy Road, Dindigul - 624001., Tamil Nadu, 624001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04614"
    },
    "Rank": 9457,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SRCC Children`s Hoapital,,1 A Haji Ali Park, K Khadye Marg, Near MCGN Pump Station,\nMahalaxmi, Mumbai, Maharashtra, 400034",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04615"
    },
    "Rank": 9458,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04616"
    },
    "Rank": 9460,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sagar Hospital - ,Shavige Malleshwara Hills, Kumaraswamy Layout, Bangalore, Karnataka, 560078",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04617"
    },
    "Rank": 9461,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04618"
    },
    "Rank": 9463,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karnataka,MG Road, Karnataka, 581301",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04619"
    },
    "Rank": 9464,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaikh-Ul-Hind Maulana Mahmood Hasan Medical College, Saharanpur,Pilkhani Ambala Road, Uttar Pradesh, 247232",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0461a"
    },
    "Rank": 9465,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0461b"
    },
    "Rank": 9467,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0461c"
    },
    "Rank": 9468,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SRCC Children`s Hoapital,,1 A Haji Ali Park, K Khadye Marg, Near MCGN Pump Station,\nMahalaxmi, Mumbai, Maharashtra, 400034",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0461d"
    },
    "Rank": 9469,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0461e"
    },
    "Rank": 9470,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0461f"
    },
    "Rank": 9473,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04620"
    },
    "Rank": 9474,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04621"
    },
    "Rank": 9476,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Hospital, Manjeri,Government Medical College, Manjeri, Malappuram, Kerala, Kerala, 676121",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04622"
    },
    "Rank": 9477,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04623"
    },
    "Rank": 9478,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VELLORE MEDICAL COLLEGE,GOVERNMENT VELLORE MEDICAL COLLEGE, Tamil Nadu, 632011",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04624"
    },
    "Rank": 9479,
    "Allotted Quota": "All India",
    "Allotted Institute": "Homi Bhabha Cancer Hospital, Varanasi,Homi Bhabha Cancer Hospital , Old Loco Colony, Shivpurwa, Lahartara, Varanasi-221002, Uttar Pradesh, Uttar Pradesh, 221002",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04625"
    },
    "Rank": 9480,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,APOLLO HOSPITALS BANGALORE A UNIT OF IMPERIAL HOSPITALS AND RESEARCH CENTRE LIMITED 154/11 Opp IIM B, Karnataka, 560076",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04626"
    },
    "Rank": 9481,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04627"
    },
    "Rank": 9482,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shifa Hospitals,No.82, Kailasapuram Middle Street, Tirunelveli Junction,\nTirunelveli, Tamilnadu, Tamil Nadu, 627001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04628"
    },
    "Rank": 9484,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chittaranjan National Cancer Institute, 37- ,37, S P Mukherjee Road, Kolkata-700026- 1st campus, 299 DJ Block A.A.1, Newtown Kolkata- 700156-2nd, West Bengal, 700026",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04629"
    },
    "Rank": 9485,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0462a"
    },
    "Rank": 9486,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0462b"
    },
    "Rank": 9487,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0462c"
    },
    "Rank": 9488,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0462d"
    },
    "Rank": 9489,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0462e"
    },
    "Rank": 9490,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0462f"
    },
    "Rank": 9493,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College,Theni ,Government Medical College,Theni, Tamil Nadu, 625512",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04630"
    },
    "Rank": 9494,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04631"
    },
    "Rank": 9495,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Meenakshi Mission Hospital and Research Centre, ,Lake Area, Melur Road, Madurai, Tamil Nadu, 625107",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04632"
    },
    "Rank": 9497,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Specialty Hospital, ,Lake View Road, KK. Nagar, Madurai -625020, Tamil Nadu, 625020",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04633"
    },
    "Rank": 9498,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04634"
    },
    "Rank": 9500,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04635"
    },
    "Rank": 9501,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Village Lingiadih,,Apollo Hospitals Bilaspur Seepat Road  Lingyadih Village  Bilaspur, Chhattisgarh, 495006",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04636"
    },
    "Rank": 9504,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04637"
    },
    "Rank": 9505,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bettiah,Government Medical College Bettiah West Champaran Bihar, Bihar, 845438",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04638"
    },
    "Rank": 9506,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04639"
    },
    "Rank": 9508,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Adarsha Hospital, ,Near KSRTC Bus Stand, Udupi Taluk and District, Karnataka, Karnataka, 576101",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0463a"
    },
    "Rank": 9509,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pushpagiri Institute of Medical Sciences and Research Centre,,Pushpagiri Institute of Medical Sciences and Research centre, Pushp Marg, Tiruvalla, Kerala, 689101",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0463b"
    },
    "Rank": 9510,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagwan Mahaveer Jain Hospital, ,MILLERS ROAD, VASANTHNAGAR, BANGALORE, Karnataka, 560052",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0463c"
    },
    "Rank": 9519,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0463d"
    },
    "Rank": 9520,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deben Mahato (Sadar) Hospital,,Main Road Near SP office PO Purulia Main PS Purulia Town Dist Purulia West Bengal, West Bengal, 723101",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0463e"
    },
    "Rank": 9522,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0463f"
    },
    "Rank": 9523,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04640"
    },
    "Rank": 9524,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04641"
    },
    "Rank": 9527,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04642"
    },
    "Rank": 9528,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04643"
    },
    "Rank": 9529,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04644"
    },
    "Rank": 9530,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04645"
    },
    "Rank": 9533,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04646"
    },
    "Rank": 9534,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04647"
    },
    "Rank": 9535,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04648"
    },
    "Rank": 9536,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04649"
    },
    "Rank": 9537,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0464a"
    },
    "Rank": 9539,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "CMC Hospital,DABRA CHOWK , DELHI ROAD, HISAR, Haryana, 125001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0464b"
    },
    "Rank": 9540,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "DIP.IN GYNAE. and OBST.",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0464c"
    },
    "Rank": 9541,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0464d"
    },
    "Rank": 9545,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jamnabai General Hospital, ,Mandvi-Panigate Road, Mandvi, Opp. SBI Main Branch, Vadodara., Gujarat, 390017",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0464e"
    },
    "Rank": 9547,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0464f"
    },
    "Rank": 9548,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04650"
    },
    "Rank": 9550,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS Medical College Valsad,Nanakwada Halar Road,Valsad ,Gujarat, Gujarat, 396001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04651"
    },
    "Rank": 9551,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital, ,GMERS Medical College and General hospital,Old TB Campus, gotri road, Vadodara, Gujarat, 390021",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04652"
    },
    "Rank": 9553,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agrasen Medical College,,NH- 9, VPO - Agroha, District Hisar, Haryana, 125047",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04653"
    },
    "Rank": 9554,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04654"
    },
    "Rank": 9555,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Santosh Hospital, ,No.6/1, Promenade Road, Bangalore.560 005, Karnataka, 560005",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04655"
    },
    "Rank": 9556,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04656"
    },
    "Rank": 9557,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04657"
    },
    "Rank": 9558,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04658"
    },
    "Rank": 9560,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04659"
    },
    "Rank": 9561,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mata Chanan Devi Hospital,, ,C-1 JANAKPURI, Delhi (NCT), 110058",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0465a"
    },
    "Rank": 9562,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0465b"
    },
    "Rank": 9563,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0465c"
    },
    "Rank": 9564,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0465d"
    },
    "Rank": 9565,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0465e"
    },
    "Rank": 9567,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0465f"
    },
    "Rank": 9568,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04660"
    },
    "Rank": 9571,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Voluntary Health Services and Medical Centre, ,RAJIV GANDHI SALAI, TARAMANI, CHENNAI, Tamil Nadu, 600113",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04661"
    },
    "Rank": 9573,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04662"
    },
    "Rank": 9574,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04663"
    },
    "Rank": 9575,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Artemis Health Institute, ,Sector 51, Gurugram, Haryana -122001, Haryana, 122001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04664"
    },
    "Rank": 9577,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M R Bangur Hospital, ,241&249 Desh Pran Sashmal Road kolkata, West Bengal, 700033",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04665"
    },
    "Rank": 9578,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04666"
    },
    "Rank": 9579,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04667"
    },
    "Rank": 9580,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04668"
    },
    "Rank": 9581,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04669"
    },
    "Rank": 9582,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0466a"
    },
    "Rank": 9583,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0466b"
    },
    "Rank": 9584,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0466c"
    },
    "Rank": 9587,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Hospital, ,ALEXANDER ROAD, BESIDE HARIHARA KALABHAVAN, NEAR CLOCK TOWER, SECUNDERBAD, Telangana, 500003",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0466d"
    },
    "Rank": 9588,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0466e"
    },
    "Rank": 9589,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Village Lingiadih,,Apollo Hospitals Bilaspur Seepat Road  Lingyadih Village  Bilaspur, Chhattisgarh, 495006",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0466f"
    },
    "Rank": 9590,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04670"
    },
    "Rank": 9591,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04671"
    },
    "Rank": 9592,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04672"
    },
    "Rank": 9596,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Swami Dayanand Hospital, ,Dilshad Garden, Shahdara, Delhi, Delhi (NCT), 110095",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04673"
    },
    "Rank": 9598,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04674"
    },
    "Rank": 9599,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kanchi Kamakoti Childs Trust Hospital, ,12 A, NAGESWARA ROAD, NUNGAMBAKKAM, CHENNAI., Tamil Nadu, 600034",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04675"
    },
    "Rank": 9600,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04676"
    },
    "Rank": 9602,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04677"
    },
    "Rank": 9603,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04678"
    },
    "Rank": 9604,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Princess Durru Shehvar Children`s and General Hospital, ,22 3 660A PURANI HAVELI HYDERABAD, Telangana, 500002",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04679"
    },
    "Rank": 9606,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sankara Eye Hospital, ,Varthur Road, Kundalahalli gate, Bangalore 560037, Karnataka, 560037",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0467a"
    },
    "Rank": 9607,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital, ,Nellikuppam Main Road, Cuddalore, Tamil Nadu, 607001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0467b"
    },
    "Rank": 9609,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KMC Hospital and Research Centre, ,KMC Hospital and Research Centre 187, Baghpat Road, Meerut, Uttar Pradesh, 250002",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0467c"
    },
    "Rank": 9613,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow hospital for women and children, ,Survey No. 141, Door No: 1-2-20, Opp.\nChermas, Hydernagar, Hyderabad, Telangana-500072",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0467d"
    },
    "Rank": 9614,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster Medcity, Kuttisahib Road,,Aster Medcity Kuttisahib Road\nSouth Chittoor P.O. Cheranalloor\nKochi, Kerala, 682027",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0467e"
    },
    "Rank": 9616,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0467f"
    },
    "Rank": 9618,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Stephen`s Hospital, ,Tis Hazari, Delhi (NCT), 110054",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04680"
    },
    "Rank": 9620,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04681"
    },
    "Rank": 9623,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04682"
    },
    "Rank": 9625,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04683"
    },
    "Rank": 9626,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04684"
    },
    "Rank": 9628,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04685"
    },
    "Rank": 9629,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Isabels Hospital, ,49, Oliver Road, Mylapore, Chennai., Tamil Nadu, 600004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04686"
    },
    "Rank": 9630,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE, PUDUCHERRY,INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE,\nPUDUCHERRY, Puducherry, 605009",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04687"
    },
    "Rank": 9631,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Moulana Hospital, Kerala,OOTY ROAD PERINTHALAMANNA. MALAPPURAM, Kerala, 679322",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04688"
    },
    "Rank": 9634,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04689"
    },
    "Rank": 9636,
    "Allotted Quota": "All India",
    "Allotted Institute": "Murshidabad Medical College and Hospital,Station Road, Berhampore, Murshidabad, West Bengal, 742101",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0468a"
    },
    "Rank": 9637,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Metro Hospital and Cancer Research Centre,,KUCHAINI PARISAR, DAMOH NAKA, JABALPUR, Madhya Pradesh, 482002",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0468b"
    },
    "Rank": 9638,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sundram Arulrhaj Hospital, ,145-5B, JEYARAJ ROAD ,TUTICORIN, Tamil Nadu, 628002",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0468c"
    },
    "Rank": 9639,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SANKARA NETHRALAYA ,\nTamil Nadu,No 21 Pycrofts Garden Road off. Haddows Road\nChennai, Tamil Nadu, 600006",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0468d"
    },
    "Rank": 9642,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital,\n,DISTRICT HOSPITAL KULGAM NEAR FRUIT MANDI, Jammu And Kashmir, 192231",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0468e"
    },
    "Rank": 9644,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Southern Railway Head Quarter Hospital, ,Ayanavaram, Tamil Nadu, 600023",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0468f"
    },
    "Rank": 9646,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04690"
    },
    "Rank": 9647,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04691"
    },
    "Rank": 9652,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Voluntary Health Services and Medical Centre, ,RAJIV GANDHI SALAI, TARAMANI, CHENNAI, Tamil Nadu, 600113",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04692"
    },
    "Rank": 9653,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04693"
    },
    "Rank": 9654,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04694"
    },
    "Rank": 9656,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04695"
    },
    "Rank": 9657,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04696"
    },
    "Rank": 9658,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04697"
    },
    "Rank": 9659,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KIMS Hospital,,#1-112 / 86, Survey No 5 / EE, beside Union Bank, near RTA Office, Kondapur, Hyderabad,Telangana, Telangana, 500084",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04698"
    },
    "Rank": 9660,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04699"
    },
    "Rank": 9661,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0469a"
    },
    "Rank": 9663,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Northern Railway Central Hospital,, ,Basant Lane New Delhi, Delhi (NCT), 110055",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0469b"
    },
    "Rank": 9665,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mohandai Oswal Hospital, ,Mohandai Oswal Hospital, Sherpur Byepass, G T Road, Ludhiana-141009, Punjab, 141009",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0469c"
    },
    "Rank": 9666,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS Medical College Valsad,Nanakwada Halar Road,Valsad ,Gujarat, Gujarat, 396001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0469d"
    },
    "Rank": 9668,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Bhausaheb Hire Government Medical College,Sri Bhausaheb Hire Government Medical College,Dhule, Maharashtra, 424311",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0469e"
    },
    "Rank": 9669,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital,\n,OPPOSITE MARALU SIDDESHWARA TEMPLE MG ROAD CROSS CHIKKABALLAPUR-562101, Karnataka, 562101",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0469f"
    },
    "Rank": 9671,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046a0"
    },
    "Rank": 9677,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "NEO Hospital,\nUttar Pradesh,D 170 A Sector 50 Noida, Uttar Pradesh, 201301",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046a1"
    },
    "Rank": 9678,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pushpagiri Institute of Medical Sciences and Research Centre,,Pushpagiri Institute of Medical Sciences and Research centre, Pushp Marg, Tiruvalla, Kerala, 689101",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046a2"
    },
    "Rank": 9679,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046a3"
    },
    "Rank": 9682,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046a4"
    },
    "Rank": 9684,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046a5"
    },
    "Rank": 9685,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MMI Narayana Multispecialty Hospital,,Dhamtari Road, Lalpur, Raipur, Chhattisgarh, 492001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046a6"
    },
    "Rank": 9687,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Santokbha Durlabhji Memorial Hospital Cum Medical Research Institute,\n,dnb.sdmh@gmail.com, Rajasthan, 302015",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046a7"
    },
    "Rank": 9689,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mumbai Port Trust Hospital, ,L.M. Nadkarni Marg, Wadala, Mumbai, Maharashtra, 400037",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046a8"
    },
    "Rank": 9690,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vijaya Hospital, ,New No 434, Old No 180, NSK Salai, Vadapalani, Chennai - 600026, Tamil Nadu, 600026",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046a9"
    },
    "Rank": 9691,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046aa"
    },
    "Rank": 9692,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046ab"
    },
    "Rank": 9693,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046ac"
    },
    "Rank": 9695,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahavir Jaipuriya Rajasthan Hospital, Rajasthan\n,Opposite Jaipuriya Hospital, Hospital Road, Milap Nagar, JLN Marg, Jaipur, Rajasthan, 302018",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046ad"
    },
    "Rank": 9696,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046ae"
    },
    "Rank": 9698,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046af"
    },
    "Rank": 9699,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046b0"
    },
    "Rank": 9700,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046b1"
    },
    "Rank": 9701,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046b2"
    },
    "Rank": 9704,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046b3"
    },
    "Rank": 9705,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046b4"
    },
    "Rank": 9706,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046b5"
    },
    "Rank": 9710,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046b6"
    },
    "Rank": 9711,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Escorts Heart Institute, ,Fortis Escorts Heart Institute and Research Centre, Okhla Road, Delhi (NCT), 110025",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046b7"
    },
    "Rank": 9713,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046b8"
    },
    "Rank": 9716,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,10 Biplabi Haren Ghosh Sarani Howrah, West Bengal, 711101",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046b9"
    },
    "Rank": 9717,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Siddipet,SURVEY NO 54  GOVERNMENT MEDICAL COLLEGE  ENSANPALLY  SIDDIPET   502114, Telangana, 502114",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046ba"
    },
    "Rank": 9719,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046bb"
    },
    "Rank": 9725,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046bc"
    },
    "Rank": 9726,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046bd"
    },
    "Rank": 9728,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046be"
    },
    "Rank": 9730,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046bf"
    },
    "Rank": 9731,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046c0"
    },
    "Rank": 9732,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Dada Dev Matri Avum Shishu Chikitsalaya, ,Dabri, New Delhi-110045, Delhi (NCT), 110045",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046c1"
    },
    "Rank": 9733,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital Whitefield,,ITPL Main Rd, KIADB Export Promotion Industrial Area, Krishnarajapura, Bengaluru, Karnataka, 560066",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046c2"
    },
    "Rank": 9735,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046c3"
    },
    "Rank": 9736,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046c4"
    },
    "Rank": 9738,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kauvery Hospital,9-50, Trichy Main Road, Seelanaickenpatti, Salem, Tamil Nadu, 636201",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046c5"
    },
    "Rank": 9740,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046c6"
    },
    "Rank": 9744,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046c7"
    },
    "Rank": 9745,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046c8"
    },
    "Rank": 9746,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046c9"
    },
    "Rank": 9747,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046ca"
    },
    "Rank": 9748,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT DHARMAPURI MEDICAL COLLEGE, DHARMAPURI, TAMIL NADU,GOVERNMENT DHARMAPURI MEDICAL COLLEGE, NETHAJI BYE PASS ROAD, DHARMAPURI, TAMIL NADU, Tamil Nadu, 636701",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046cb"
    },
    "Rank": 9749,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Childrens Hospital, (A unit of Rainbow Childrens Medicare Pvt. Ltd.) (Formerly Rainbow Institute of Medical Sciences ,Opp. NTR University of Health Sciences, Currency Nagar. Vijayawada - 520008, Andhra Pradesh, 520008",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046cc"
    },
    "Rank": 9750,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046cd"
    },
    "Rank": 9751,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN,HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN, Karnataka, 573201",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046ce"
    },
    "Rank": 9752,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046cf"
    },
    "Rank": 9755,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046d0"
    },
    "Rank": 9756,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S. G. L. Super Specialty Charitable Hospital, ,GARHA ROAD, JALANDHAR, Punjab, 144022",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046d1"
    },
    "Rank": 9760,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046d2"
    },
    "Rank": 9762,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046d3"
    },
    "Rank": 9764,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "AIG Hospital,Door No: 1 66 AIG 2 to 5 Mindspace Road  Gachibowli Hyderabad\nTelangana 500032",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046d4"
    },
    "Rank": 9767,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sacred Heart Hospital, ,Sacred Heart Hospital Maqsudan\nJalandhar Punjab, Punjab, 144008",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046d5"
    },
    "Rank": 9768,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shrikrishna Medical College & Hospital, Muzaffarpur,Shrikrishna Medical College & Hospital, Muzaffarpur, Bihar, 842004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046d6"
    },
    "Rank": 9770,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046d7"
    },
    "Rank": 9772,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046d8"
    },
    "Rank": 9773,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046d9"
    },
    "Rank": 9775,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046da"
    },
    "Rank": 9778,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046db"
    },
    "Rank": 9781,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046dc"
    },
    "Rank": 9785,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046dd"
    },
    "Rank": 9786,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Parsik Hill Road, ,APOLLO HOSPITAL PARSIK HILL ROAD ROAD NO 13 SECTOR 23 CBD BELAPUR NAVI MUMBAI, Maharashtra, 400614",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046de"
    },
    "Rank": 9788,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aditya Diagnostics and Hospital,,Bordoloi Avenue, Dibrugarh-786005, Assam, 786005",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046df"
    },
    "Rank": 9790,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046e0"
    },
    "Rank": 9792,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046e1"
    },
    "Rank": 9793,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046e2"
    },
    "Rank": 9794,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046e3"
    },
    "Rank": 9795,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster CMI Hospital, ,43/2 New Airport Road, NH-7, Kodigehalli, Sahakara Nagar, Hebbal, Bengaluru, Karnataka, 560092",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046e4"
    },
    "Rank": 9796,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chandulal Chandrakar Memorial Hospital, ,chandulalchandrakarhospital@gmail.com, Chhattisgarh, 490020",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046e5"
    },
    "Rank": 9798,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Stephen`s Hospital, ,Tis Hazari, Delhi (NCT), 110054",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046e6"
    },
    "Rank": 9799,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046e7"
    },
    "Rank": 9800,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046e8"
    },
    "Rank": 9802,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046e9"
    },
    "Rank": 9804,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046ea"
    },
    "Rank": 9807,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Cauvery Heart and Multispeciality Hospital, ,BANNUR ROAD, NEAR TERESIAN CIRCLE,MYSORE, Karnataka, 570029",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046eb"
    },
    "Rank": 9809,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN,HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN, Karnataka, 573201",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046ec"
    },
    "Rank": 9810,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES ADILABAD -504001 TELANGANA STATE, Telangana, 504001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046ed"
    },
    "Rank": 9811,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046ee"
    },
    "Rank": 9813,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046ef"
    },
    "Rank": 9815,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046f0"
    },
    "Rank": 9819,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046f1"
    },
    "Rank": 9820,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046f2"
    },
    "Rank": 9822,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Arvind Eye Hospital, ,Aravind Eye Hospital and Postgraduate Institute of Ophthalmology, Cuddalore Main Road, Thavalakuppam, Puducherry, 605007",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046f3"
    },
    "Rank": 9823,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rangadore Memorial Hospital,,1ST CROSS, SHANKARAPURAM, BANGALORE, Karnataka, 560004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046f4"
    },
    "Rank": 9824,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Meenakshi Mission Hospital and Research Centre, ,Lake Area, Melur Road, Madurai, Tamil Nadu, 625107",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046f5"
    },
    "Rank": 9826,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046f6"
    },
    "Rank": 9829,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046f7"
    },
    "Rank": 9833,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaslok Hospital and Research Centre, ,15, Dr. G. Deshmukh Marg, Mumbai - 400026, Maharashtra, 400026",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046f8"
    },
    "Rank": 9834,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. R. D. B. P. Jaipuria Hospital, (Attached with RUHS College of Medical Sciences),,GOVTRDBPJAIPURIAHOSPITALJAIPUR@GMAIL.COM, Rajasthan, 302018",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046f9"
    },
    "Rank": 9837,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sundram Arulrhaj Hospital, ,145-5B, JEYARAJ ROAD ,TUTICORIN, Tamil Nadu, 628002",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046fa"
    },
    "Rank": 9841,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046fb"
    },
    "Rank": 9843,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Peerless Hospital and B K Roy Research Centre, ,360, Panchasayar, Kolkata - 700 -094, West Bengal, 700094",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046fc"
    },
    "Rank": 9844,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046fd"
    },
    "Rank": 9845,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046fe"
    },
    "Rank": 9846,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a046ff"
    },
    "Rank": 9847,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04700"
    },
    "Rank": 9848,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04701"
    },
    "Rank": 9852,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lotus Children`s Hospital,,6-2-29,Lakdikapul, Hyderabad - 500004, Telangana, 500004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04702"
    },
    "Rank": 9854,
    "Allotted Quota": "All India",
    "Allotted Institute": "All India Institute of Physical Medicine and Rehabilitation,All India Institute of Physical Medicine and Rehabilitation, Maharashtra, 400034",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04703"
    },
    "Rank": 9858,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04704"
    },
    "Rank": 9859,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Government Medical College, Vidisha,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA MP, Madhya Pradesh, 464001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04705"
    },
    "Rank": 9863,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Fatima Hospital, ,35-C, MAHANAGAR, LUCKNOW, UP, Uttar Pradesh, 226006",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04706"
    },
    "Rank": 9864,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lourdes Hospital, ,Ernakulam, Pachalam, Kochi, Kerala, 682012",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04707"
    },
    "Rank": 9866,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04708"
    },
    "Rank": 9870,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mother Hospital, ,P.O PULLAZHI, OLARI,THRISSUR-680012, Kerala, 680012",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04709"
    },
    "Rank": 9876,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lourdes Hospital, ,Ernakulam, Pachalam, Kochi, Kerala, 682012",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0470a"
    },
    "Rank": 9878,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Hospital, Manjeri,Government Medical College, Manjeri, Malappuram, Kerala, Kerala, 676121",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0470b"
    },
    "Rank": 9879,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0470c"
    },
    "Rank": 9880,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0470d"
    },
    "Rank": 9884,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Batra Hospital and Medical Research Centre, ,1, Tughlakabad institutional Area, Mehrauli Badarpur Road, New Delhi, Delhi (NCT), 110062",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0470e"
    },
    "Rank": 9886,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0470f"
    },
    "Rank": 9887,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SUSHRUT INSTITUTE OF PLASTIC SURGERY PRIVATE LIMITED,\nUttar Pradesh,29 Shahmeena Road Lucknow, Uttar Pradesh, 226003",
    "Course": "(NBEMS) Plastic and Reconstructive Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04710"
    },
    "Rank": 9888,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vijaya Hospital, ,New No 434, Old No 180, NSK Salai, Vadapalani, Chennai - 600026, Tamil Nadu, 600026",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04711"
    },
    "Rank": 9889,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04712"
    },
    "Rank": 9893,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04713"
    },
    "Rank": 9894,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04714"
    },
    "Rank": 9895,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sahyadri Super Specialty Hospital,,Plot No.30C, Erandawane, Karve Road, Pune, Maharashtra, 411004",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04715"
    },
    "Rank": 9896,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04716"
    },
    "Rank": 9899,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04717"
    },
    "Rank": 9903,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rampurhat Govt. Medical College and Hospital,\n,RAMPURHAT GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, P.O. RAMPURHAT, P.S. RAMPURHAT, DIST. BIRBHUM, WB, West Bengal, 731224",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04718"
    },
    "Rank": 9904,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K B Bhabha Municipal General Hospital, ,R K PATKAR ROAD ,BANDRA WEST\n,MUMBAI, Maharashtra, 400050",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04719"
    },
    "Rank": 9905,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0471a"
    },
    "Rank": 9906,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "NEO Hospital,\nUttar Pradesh,D 170 A Sector 50 Noida, Uttar Pradesh, 201301",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0471b"
    },
    "Rank": 9907,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0471c"
    },
    "Rank": 9909,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0471d"
    },
    "Rank": 9910,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0471e"
    },
    "Rank": 9911,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Martha`s Hospital , ,No.5, Nrupathunga Road, Bengaluru, Karnataka, 560001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0471f"
    },
    "Rank": 9912,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Ophthalmology,RIO Prev Known as Nehru Institute of Ophthalmology and Research Centre Civil Lines Sitapur, Uttar Pradesh, 261001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04720"
    },
    "Rank": 9914,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04721"
    },
    "Rank": 9915,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04722"
    },
    "Rank": 9916,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04723"
    },
    "Rank": 9917,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS MEDICAL COLLEGE, NEAR GUJARAT HIGH COURT, S.G HIGH WAY, SOLA, AHMEDABAD, Gujarat, 380060",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04724"
    },
    "Rank": 9918,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04725"
    },
    "Rank": 9923,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04726"
    },
    "Rank": 9924,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Associated Hospital Government Medical College, ,Government Medical College Chack Sajjan Kathua, Jammu And Kashmir, 184101",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04727"
    },
    "Rank": 9926,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College,Theni ,Government Medical College,Theni, Tamil Nadu, 625512",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04728"
    },
    "Rank": 9928,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Hospital, ,ALEXANDER ROAD, BESIDE HARIHARA KALABHAVAN, NEAR CLOCK TOWER, SECUNDERBAD, Telangana, 500003",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04729"
    },
    "Rank": 9929,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kannur Medical College Hospital, ,Anjarakandy Integrated Campus Anjarakandy  PO\nKannur, Kerala, 670612",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0472a"
    },
    "Rank": 9930,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0472b"
    },
    "Rank": 9931,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Daya General Hospital,Daya General Hospital Limited Shornur Road NEar Viyyur bridge\nThiruvambady PO Thrissur, Kerala, 680022",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0472c"
    },
    "Rank": 9933,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0472d"
    },
    "Rank": 9935,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0472e"
    },
    "Rank": 9938,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.E.M. Hospital, ,489, SARDAR MOODLIAR ROAD, RASTA PETH, PUNE 411011,\nMaharashtra, 411011",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0472f"
    },
    "Rank": 9940,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04730"
    },
    "Rank": 9941,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "AIG Hospital,Door No: 1 66 AIG 2 to 5 Mindspace Road  Gachibowli Hyderabad\nTelangana 500032",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04731"
    },
    "Rank": 9942,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. R. D. B. P. Jaipuria Hospital, (Attached with RUHS College of Medical Sciences),,GOVTRDBPJAIPURIAHOSPITALJAIPUR@GMAIL.COM, Rajasthan, 302018",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04732"
    },
    "Rank": 9943,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "C.S.I. Holdsworth Memorial Hospital, ,P.B.No. 38, Sawday Road, Mandi Mohalla, Mysuru, Karnataka, 570001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04733"
    },
    "Rank": 9944,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04734"
    },
    "Rank": 9945,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Diamond Harbour Government Medical College And Hospital, ,Harindanga, New Town, Word No- 4, P.O and P.S- Diamond Harbour, Dist- South 24 Parganas, West Bengal, West Bengal, 743331",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04735"
    },
    "Rank": 9947,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESIC Hospital, Sri Maa Anandmayee Marg,,ESIC Hospital, Sri Maa Anandmayee Marg, Okhla Phase I, New Delhi, Delhi (NCT), 110020",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04736"
    },
    "Rank": 9949,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04737"
    },
    "Rank": 9950,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rural Development Trust Hospital, ,Kadiri Road,Bathalapalli, Anantapur District Andhra Pradesh, Andhra Pradesh, 515661",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04738"
    },
    "Rank": 9952,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04739"
    },
    "Rank": 9954,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0473a"
    },
    "Rank": 9956,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0473b"
    },
    "Rank": 9958,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0473c"
    },
    "Rank": 9960,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0473d"
    },
    "Rank": 9965,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0473e"
    },
    "Rank": 9968,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0473f"
    },
    "Rank": 9969,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sacred Heart Hospital, ,Sacred Heart Hospital Maqsudan\nJalandhar Punjab, Punjab, 144008",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04740"
    },
    "Rank": 9972,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04741"
    },
    "Rank": 9975,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Arihant Hospital and Research Centre, Madhya Pradesh,A-283, Gumasta Nagar, Near Shiv Mandir, Indore, Madhya Pradesh, 452009",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04742"
    },
    "Rank": 9979,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04743"
    },
    "Rank": 9980,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhavsinhji and Maharani Rupaliba General Hospital, ,Near S. T. Bus Stand, Opp.\nBhutnath Temple, Hospital Road, Porbandar, Gujarat, 360575",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04744"
    },
    "Rank": 9981,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "JLNM Hospital, ,JLNM Hospital Rainawari Srinagar, Jammu And Kashmir, 190003",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04745"
    },
    "Rank": 9983,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE - KANNUR,GOVERNMENT MEDICAL COLLEGE\n- KANNUR, Kerala, 670503",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04746"
    },
    "Rank": 9985,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Autonomous State Medical college Society and S.N.M District Hospital, ,DAULATTAPUR JALESAR ROAD NARKHI FIROZABAD, Uttar Pradesh, 283203",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04747"
    },
    "Rank": 9986,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04748"
    },
    "Rank": 9987,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04749"
    },
    "Rank": 9988,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital International, ,Plot 1A, GIDC Estate, Bhat Gandhinagar, Gujarat, 382428",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0474a"
    },
    "Rank": 9989,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0474b"
    },
    "Rank": 9990,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0474c"
    },
    "Rank": 9991,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0474d"
    },
    "Rank": 9992,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Paramitha Childrens Hospital,,11-13-728 5, greenhills colony, kothapet main road, hyderabad, Telangana-500074, india, Telangana, 500074",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0474e"
    },
    "Rank": 9993,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0474f"
    },
    "Rank": 9995,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tagore Hospital and Heart Care Center Pvt. Ltd., ,BANDA BAHADUR NAGAR, MAHAVIR MARG, JALANDHAR CITY 144001, PUNJAB, INDIA, Punjab, 144001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04750"
    },
    "Rank": 9996,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KMC Hospital and Research Centre, ,KMC Hospital and Research Centre 187, Baghpat Road, Meerut, Uttar Pradesh, 250002",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04751"
    },
    "Rank": 10001,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04752"
    },
    "Rank": 10003,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04753"
    },
    "Rank": 10004,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04754"
    },
    "Rank": 10005,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04755"
    },
    "Rank": 10006,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04756"
    },
    "Rank": 10007,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04757"
    },
    "Rank": 10008,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04758"
    },
    "Rank": 10009,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04759"
    },
    "Rank": 10011,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0475a"
    },
    "Rank": 10012,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tagore Hospital and Heart Care Center Pvt. Ltd., ,BANDA BAHADUR NAGAR, MAHAVIR MARG, JALANDHAR CITY 144001, PUNJAB, INDIA, Punjab, 144001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0475b"
    },
    "Rank": 10013,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0475c"
    },
    "Rank": 10014,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0475d"
    },
    "Rank": 10015,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Hospital, ,ALEXANDER ROAD, BESIDE HARIHARA KALABHAVAN, NEAR CLOCK TOWER, SECUNDERBAD, Telangana, 500003",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0475e"
    },
    "Rank": 10020,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0475f"
    },
    "Rank": 10022,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mohandai Oswal Hospital, ,Mohandai Oswal Hospital, Sherpur Byepass, G T Road, Ludhiana-141009, Punjab, 141009",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04760"
    },
    "Rank": 10023,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indraprastha Apollo Hospital,,Sarita Vihar, New Delhi 110 076, Delhi (NCT), 110076",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04761"
    },
    "Rank": 10024,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE - KANNUR,GOVERNMENT MEDICAL COLLEGE\n- KANNUR, Kerala, 670503",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04762"
    },
    "Rank": 10027,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04763"
    },
    "Rank": 10028,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "CITY HOSPITAL, Moglaha, ,City Super Speciality Hospital Pvt. Ltd., Moglaha, Medical College Road, Gorakhpur, Uttar Pradesh 27, Uttar Pradesh, 273013",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04764"
    },
    "Rank": 10030,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VILLUPURAM MEDICAL COLLEGE, VILLUPURAM,GOVERNMENT VILLUPURAM MEDICAL COLLEGE VILLUPURAM  TRICHY TRUNK ROAD MUNDIYAMPAKKAM  VILLUPURAM DISTR, Tamil Nadu, 605601",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04765"
    },
    "Rank": 10031,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04766"
    },
    "Rank": 10032,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04767"
    },
    "Rank": 10033,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04768"
    },
    "Rank": 10035,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KAMAKSHI HOSPITAL, Karnataka,KAMAKSHI HOSPITAL KAMAKSHI HOSPITAL ROAD\nKUVEMPU NAGAR\nMYSORE, Karnataka, 570009",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04769"
    },
    "Rank": 10038,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) Paediatric Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0476a"
    },
    "Rank": 10040,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sahyadri Super Specialty Hospital,,Plot No.30C, Erandawane, Karve Road, Pune, Maharashtra, 411004",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0476b"
    },
    "Rank": 10044,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0476c"
    },
    "Rank": 10045,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0476d"
    },
    "Rank": 10047,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN,HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN, Karnataka, 573201",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0476e"
    },
    "Rank": 10048,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0476f"
    },
    "Rank": 10050,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tej Bahadur Sapru Hospital, ,Stanley Road opp Erny memorial school Beli Goan Prayagraj up pin- 211002, Uttar Pradesh, 211002",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04770"
    },
    "Rank": 10053,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Noble Hospital, ,Magarpatta City Road, Hadpsar, Pune, Maharashtra, 411028",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04771"
    },
    "Rank": 10054,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04772"
    },
    "Rank": 10055,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04773"
    },
    "Rank": 10059,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04774"
    },
    "Rank": 10063,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04775"
    },
    "Rank": 10064,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04776"
    },
    "Rank": 10065,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04777"
    },
    "Rank": 10074,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mother Hospital, ,P.O PULLAZHI, OLARI,THRISSUR-680012, Kerala, 680012",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04778"
    },
    "Rank": 10075,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04779"
    },
    "Rank": 10077,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0477a"
    },
    "Rank": 10078,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindu Rao Hospital, ,SUBZI MANDI, MALKA GANJ, DELHI-110007, Delhi (NCT), 110007",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0477b"
    },
    "Rank": 10079,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, ,1,2, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0477c"
    },
    "Rank": 10080,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Head Quarters Hospital,,H NO 3 3 141 MNCL X ROADS KARIMNAGAR KARIMNAGAR DISTRICT, Telangana, 505001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0477d"
    },
    "Rank": 10083,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Childrens Hospital, ,178/1 and  178/2, Bannerghatta Main Rd, opposite Janardhan Towers, Dollars Colony, Phase 4, Bilekaha, Karnataka, 560076",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0477e"
    },
    "Rank": 10084,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0477f"
    },
    "Rank": 10085,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04780"
    },
    "Rank": 10086,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04781"
    },
    "Rank": 10087,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ahalia Hospital ,\n(Women and Childrens Hospital - Maternal and Child Care Centre) ,Ahalia Campus Kozhippara Post Palakkad Kerala 678 557, Kerala, 678557",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04782"
    },
    "Rank": 10088,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, ,1,2, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04783"
    },
    "Rank": 10089,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04784"
    },
    "Rank": 10093,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04785"
    },
    "Rank": 10094,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04786"
    },
    "Rank": 10095,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04787"
    },
    "Rank": 10099,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04788"
    },
    "Rank": 10100,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04789"
    },
    "Rank": 10101,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0478a"
    },
    "Rank": 10102,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0478b"
    },
    "Rank": 10108,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0478c"
    },
    "Rank": 10109,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0478d"
    },
    "Rank": 10110,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SunShine Hospital, ,1-7-201 TO 205\nPG Road, Paradise, Secunderabad, Telangana, 500003",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0478e"
    },
    "Rank": 10114,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0478f"
    },
    "Rank": 10115,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04790"
    },
    "Rank": 10116,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04791"
    },
    "Rank": 10117,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Woodlands Multispeciality Hospital Limited,\n,8-5 ALIPORE ROAD KOLKATA, West Bengal, 700027",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04792"
    },
    "Rank": 10118,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04793"
    },
    "Rank": 10119,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mohandai Oswal Hospital, ,Mohandai Oswal Hospital, Sherpur Byepass, G T Road, Ludhiana-141009, Punjab, 141009",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04794"
    },
    "Rank": 10124,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04795"
    },
    "Rank": 10125,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04796"
    },
    "Rank": 10134,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "DIPLOMA IN ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04797"
    },
    "Rank": 10136,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04798"
    },
    "Rank": 10139,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.E.M. Hospital, ,489, SARDAR MOODLIAR ROAD, RASTA PETH, PUNE 411011,\nMaharashtra, 411011",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04799"
    },
    "Rank": 10141,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0479a"
    },
    "Rank": 10144,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shree Narayana Hospital, ,Near Ganj Mandi, Behind Sector 5, Devendra Nagar, Pandri, Raipur, Chhattisgarh, 492001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0479b"
    },
    "Rank": 10145,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MAXCARE Hospital, ,6-1-236, Near Kuda Office Line Sai Nagar, Opposite Ashoka Hotel, Hanamkonda, Telangana 506001, Telangana, 506001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0479c"
    },
    "Rank": 10146,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0479d"
    },
    "Rank": 10147,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0479e"
    },
    "Rank": 10150,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pushpanjali Hospital and Research Centre, Uttar Pradesh,Pushpanjali Hospital and Research Centre Pvt Ltd Pushpanjali Palace, Delhi Gate, Agra, Uttar Pradesh, 282002",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0479f"
    },
    "Rank": 10152,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047a0"
    },
    "Rank": 10153,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047a1"
    },
    "Rank": 10155,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047a2"
    },
    "Rank": 10156,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047a3"
    },
    "Rank": 10157,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047a4"
    },
    "Rank": 10158,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047a5"
    },
    "Rank": 10160,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chandulal Chandrakar Memorial Hospital, ,chandulalchandrakarhospital@gmail.com, Chhattisgarh, 490020",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047a6"
    },
    "Rank": 10161,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047a7"
    },
    "Rank": 10163,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindu Rao Hospital, ,SUBZI MANDI, MALKA GANJ, DELHI-110007, Delhi (NCT), 110007",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047a8"
    },
    "Rank": 10164,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047a9"
    },
    "Rank": 10165,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata Shrimati Devendra Kumari Singhdeo Government Medical College, Ambikapur,Kanyaparisar  Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047aa"
    },
    "Rank": 10166,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047ab"
    },
    "Rank": 10167,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047ac"
    },
    "Rank": 10168,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047ad"
    },
    "Rank": 10169,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047ae"
    },
    "Rank": 10172,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047af"
    },
    "Rank": 10173,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047b0"
    },
    "Rank": 10174,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "AIG Hospital,Door No: 1 66 AIG 2 to 5 Mindspace Road  Gachibowli Hyderabad\nTelangana 500032",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047b1"
    },
    "Rank": 10176,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vijaya Hospital, ,New No 434, Old No 180, NSK Salai, Vadapalani, Chennai - 600026, Tamil Nadu, 600026",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047b2"
    },
    "Rank": 10177,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Northern Railway Central Hospital,, ,Basant Lane New Delhi, Delhi (NCT), 110055",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047b3"
    },
    "Rank": 10178,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047b4"
    },
    "Rank": 10179,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047b5"
    },
    "Rank": 10184,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047b6"
    },
    "Rank": 10187,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047b7"
    },
    "Rank": 10188,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College,Tumuki, Bihaguri, Tezpur, Sonitpur, Assam, Assam, 784010",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047b8"
    },
    "Rank": 10190,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047b9"
    },
    "Rank": 10191,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Punjab Institute of Medical Scineces ,Punjab Institute of Medical Sciences Garha Road Jalandhar, Punjab, 144006",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047ba"
    },
    "Rank": 10192,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047bb"
    },
    "Rank": 10193,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta The Medicity, ,Medanta The Medicity Sector 38 Gurgoan, Haryana, 122001",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047bc"
    },
    "Rank": 10195,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047bd"
    },
    "Rank": 10196,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ispat General Hospital,,Ispat General Hospital Rourkela Steel Plant SAIL Rourkela 769005, Odisha, 769005",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047be"
    },
    "Rank": 10198,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indraprastha Apollo Hospital,,Sarita Vihar, New Delhi 110 076, Delhi (NCT), 110076",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047bf"
    },
    "Rank": 10199,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indiana hospital and Heart Institute, ,Mahaveer Cirlce, Pumpwell, Mangalore, Karnataka, 575002",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047c0"
    },
    "Rank": 10201,
    "Allotted Quota": "All India",
    "Allotted Institute": "G B Pant Institute of Post Graduate Medical Education and Research,G B Pant Institute of Post Graduate Medical Education and Research, Delhi (NCT), 110007",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047c1"
    },
    "Rank": 10203,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Mulund - Goregaon Link Rd, Nahur West, Industrial Area, Bhandup West, Mumbai, Maharashtra 400078, Maharashtra, 400078",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047c2"
    },
    "Rank": 10205,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047c3"
    },
    "Rank": 10208,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047c4"
    },
    "Rank": 10216,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047c5"
    },
    "Rank": 10218,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Childrens Hospital and BirthRight, Telangana,Hno 3 7 222 223 sy no 51 to 54 opposit kharkana police station kaskhana main road kakaguda hyderabad, Telangana, 500009",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047c6"
    },
    "Rank": 10220,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047c7"
    },
    "Rank": 10221,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047c8"
    },
    "Rank": 10223,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047c9"
    },
    "Rank": 10225,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047ca"
    },
    "Rank": 10226,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047cb"
    },
    "Rank": 10227,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Kamakshi Memorial Hospital, (Dr. Kamakshi Institute of Medical Sciences and Research) ,No.1,Radial Road , Pallikaranai, Chennai, Tamil Nadu, 600100",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047cc"
    },
    "Rank": 10228,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047cd"
    },
    "Rank": 10229,
    "Allotted Quota": "All India",
    "Allotted Institute": "Homi Bhabha Cancer Hospital, Varanasi,Homi Bhabha Cancer Hospital , Old Loco Colony, Shivpurwa, Lahartara, Varanasi-221002, Uttar Pradesh, Uttar Pradesh, 221002",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047ce"
    },
    "Rank": 10231,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Government Medical College, Vidisha,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA MP, Madhya Pradesh, 464001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047cf"
    },
    "Rank": 10234,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047d0"
    },
    "Rank": 10237,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047d1"
    },
    "Rank": 10239,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047d2"
    },
    "Rank": 10244,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Jammu and Kashmir\n,DUDERHAMA GANDERBAL, Jammu And Kashmir, 191201",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047d3"
    },
    "Rank": 10245,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047d4"
    },
    "Rank": 10247,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047d5"
    },
    "Rank": 10250,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SLG Hospitals,Sree Lakshmi Gayatri Hospitals Pvt Ltd, SLG Circle Nizampet Road, Bachupally, Medchal Malkajgiri Dis, Telangana, 500090",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047d6"
    },
    "Rank": 10251,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047d7"
    },
    "Rank": 10255,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College,Tumuki, Bihaguri, Tezpur, Sonitpur, Assam, Assam, 784010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047d8"
    },
    "Rank": 10256,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047d9"
    },
    "Rank": 10258,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047da"
    },
    "Rank": 10260,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047db"
    },
    "Rank": 10261,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047dc"
    },
    "Rank": 10264,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047dd"
    },
    "Rank": 10265,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lourdes Hospital, ,Ernakulam, Pachalam, Kochi, Kerala, 682012",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047de"
    },
    "Rank": 10266,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medical Trust Hospital, ,M.G. Road Pallimukku\nCochin, Kerala, 682016",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047df"
    },
    "Rank": 10268,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047e0"
    },
    "Rank": 10269,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nirmal Hospital,,NEAR KADIWALA SCHOOL, RING ROAD, SURAT, Gujarat, 395002",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047e1"
    },
    "Rank": 10270,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047e2"
    },
    "Rank": 10273,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047e3"
    },
    "Rank": 10275,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nirmal Hospital,,NEAR KADIWALA SCHOOL, RING ROAD, SURAT, Gujarat, 395002",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047e4"
    },
    "Rank": 10277,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ursala Horsman Memorial Hospital, ,Bada Chauraha, Civil Lines,Kanpur Nagar, Uttar Pradesh, 208001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047e5"
    },
    "Rank": 10278,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dharwad,dsdharwad at gmail dot com, Karnataka, 580001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047e6"
    },
    "Rank": 10281,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BIG Apollo Spectra Hospitals,Shitla Mandir Road, Agamkuan, Patna, Bihar, 800007",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047e7"
    },
    "Rank": 10282,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047e8"
    },
    "Rank": 10284,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.J. Somaiya Medical College,,SOMAIYA AYURVIHAR , EASTERN EXPRESS HIGHWAY, SION, MUMBAI 400022, Maharashtra, 400022",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047e9"
    },
    "Rank": 10285,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047ea"
    },
    "Rank": 10286,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Babasaheb Ambedkar Central Railway Hospital, ,Bharatratna Dr. Babasaheb Ambedkar Memorial Hospital, Central Railway, Dr. Babasaheb Ambedkar Road,, Maharashtra, 400027",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047eb"
    },
    "Rank": 10288,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047ec"
    },
    "Rank": 10291,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047ed"
    },
    "Rank": 10292,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047ee"
    },
    "Rank": 10294,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047ef"
    },
    "Rank": 10295,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047f0"
    },
    "Rank": 10297,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital, ,Nellikuppam Main Road, Cuddalore, Tamil Nadu, 607001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047f1"
    },
    "Rank": 10298,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047f2"
    },
    "Rank": 10300,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Medical College and Chhatrapati Shivaji Maharaj Hospital, ,RAJIV GANDHI MEDICAL COLLEGE,THANE BELAPUR ROAD, KALWA, THANE, Maharashtra, 400605",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047f3"
    },
    "Rank": 10301,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047f4"
    },
    "Rank": 10303,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047f5"
    },
    "Rank": 10304,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047f6"
    },
    "Rank": 10305,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047f7"
    },
    "Rank": 10306,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047f8"
    },
    "Rank": 10307,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047f9"
    },
    "Rank": 10308,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047fa"
    },
    "Rank": 10309,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC MEDICAL COLLEGE, JOKA,ESI-PGIMSR & ESIC MEDICAL COLLEGE, JOKA, KOLKATA, WEST BENGAL, West Bengal, 700104",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047fb"
    },
    "Rank": 10310,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES ADILABAD -504001 TELANGANA STATE, Telangana, 504001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047fc"
    },
    "Rank": 10311,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruby Hall Clinic, ,GRANT MEDICAL FOUNDATION,\n40 SASSOON ROAD, PUNE 411001, Maharashtra, 411001",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047fd"
    },
    "Rank": 10312,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047fe"
    },
    "Rank": 10313,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a047ff"
    },
    "Rank": 10315,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04800"
    },
    "Rank": 10316,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC MEDICAL COLLEGE, JOKA,ESI-PGIMSR & ESIC MEDICAL COLLEGE, JOKA, KOLKATA, WEST BENGAL, West Bengal, 700104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04801"
    },
    "Rank": 10317,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04802"
    },
    "Rank": 10321,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04803"
    },
    "Rank": 10322,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, West Bengal,730, Em By pass Road, Anandapur, Kolkata, West Bengal, 700107",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04804"
    },
    "Rank": 10323,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04805"
    },
    "Rank": 10324,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04806"
    },
    "Rank": 10327,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04807"
    },
    "Rank": 10329,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kanchi Kamakoti Childs Trust Hospital, ,12 A, NAGESWARA ROAD, NUNGAMBAKKAM, CHENNAI., Tamil Nadu, 600034",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04808"
    },
    "Rank": 10331,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Metro Heart Institute, Uttar Pradesh,L 94 sector 11 Noida Uttar pradesh, Uttar Pradesh, 201301",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04809"
    },
    "Rank": 10332,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0480a"
    },
    "Rank": 10333,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital International, ,Plot 1A, GIDC Estate, Bhat Gandhinagar, Gujarat, 382428",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0480b"
    },
    "Rank": 10335,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "C.S.I. Kalyani General Hospital,, ,15, DR RADHAKRISHNAN SALAI, MYLAPORE, CHENNAI, Tamil Nadu, 600004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0480c"
    },
    "Rank": 10337,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0480d"
    },
    "Rank": 10339,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Malabar Institute of Medical Sciences, ,Govindapuram P.O., Mini Bypass Road, Kozhikode, Kerala, 673016",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0480e"
    },
    "Rank": 10340,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0480f"
    },
    "Rank": 10341,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04810"
    },
    "Rank": 10342,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04811"
    },
    "Rank": 10344,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04812"
    },
    "Rank": 10345,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04813"
    },
    "Rank": 10347,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MAXCARE Hospital, ,6-1-236, Near Kuda Office Line Sai Nagar, Opposite Ashoka Hotel, Hanamkonda, Telangana 506001, Telangana, 506001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04814"
    },
    "Rank": 10349,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Cosmopolitan Hospital,,COSMOPOLITAN HOSPITALS Pvt.Ltd, Murinjapalam, Pattom P O, Thiruvananthapuram, Kerala, 695004",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04815"
    },
    "Rank": 10352,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S. K. S. Hospital, ,NO.23,SKS Hospital Road, Alagapuram,Salem, Tamil Nadu, 636004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04816"
    },
    "Rank": 10353,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College,Theni ,Government Medical College,Theni, Tamil Nadu, 625512",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04817"
    },
    "Rank": 10354,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Durgapur Steel Plant Hospital, ,J M Sengupta Road, B Zone,  Durgapur, Dist- Paschim Bardhaman, West Bengal, 713205",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04818"
    },
    "Rank": 10356,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04819"
    },
    "Rank": 10359,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0481a"
    },
    "Rank": 10360,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BHEL Kasturba Hospital,,KASTURBA HOSPITAL, BHEL, HABIBGANJ, BHOPAL,\nMadhya Pradesh, 462024",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0481b"
    },
    "Rank": 10361,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences, ,academics@kimshospitals.com, Telangana, 500003",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0481c"
    },
    "Rank": 10363,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Moulana Hospital, Kerala,OOTY ROAD PERINTHALAMANNA. MALAPPURAM, Kerala, 679322",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0481d"
    },
    "Rank": 10366,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B. Nanavati Hospital, Vivekanand Rd,Swami Vivekananda Rd, near LIC, LIC Colony, Suresh Colony, Vile Parle West, Mumbai, Maharashtra 4000, Maharashtra, 400056",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0481e"
    },
    "Rank": 10367,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0481f"
    },
    "Rank": 10368,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04820"
    },
    "Rank": 10369,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04821"
    },
    "Rank": 10370,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04822"
    },
    "Rank": 10374,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04823"
    },
    "Rank": 10377,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04824"
    },
    "Rank": 10379,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04825"
    },
    "Rank": 10385,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04826"
    },
    "Rank": 10387,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Bundelkhand Medical College Sagar M.P.,Govt. Bundelkhand Medical College Sagar M.P., Madhya Pradesh, 470001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04827"
    },
    "Rank": 10388,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences, ,academics@kimshospitals.com, Telangana, 500003",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04828"
    },
    "Rank": 10390,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jubilee Mission Hospital, ,Bishop Alappatt Road, Jubilee Mission P.O. Thrissur, Kerala, 680005",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04829"
    },
    "Rank": 10392,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0482a"
    },
    "Rank": 10394,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, Sector B, ,SECTOR-B1, VASANT KUNJ NEW DELHI, Delhi (NCT), 110070",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0482b"
    },
    "Rank": 10395,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0482c"
    },
    "Rank": 10396,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ispat General Hospital,,Ispat General Hospital Rourkela Steel Plant SAIL Rourkela 769005, Odisha, 769005",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0482d"
    },
    "Rank": 10397,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0482e"
    },
    "Rank": 10398,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bombay Hospital and Institute of Medical Sciences, ,12, New Marine Lines, Mumbai, Maharashtra, 400020",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0482f"
    },
    "Rank": 10399,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Caritas Hospital, ,Thellakom P.O, Kerala, 686630",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04830"
    },
    "Rank": 10400,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Kamakshi Memorial Hospital, (Dr. Kamakshi Institute of Medical Sciences and Research) ,No.1,Radial Road , Pallikaranai, Chennai, Tamil Nadu, 600100",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04831"
    },
    "Rank": 10403,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaikh-Ul-Hind Maulana Mahmood Hasan Medical College, Saharanpur,Pilkhani Ambala Road, Uttar Pradesh, 247232",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04832"
    },
    "Rank": 10404,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04833"
    },
    "Rank": 10407,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Kamakshi Memorial Hospital, (Dr. Kamakshi Institute of Medical Sciences and Research) ,No.1,Radial Road , Pallikaranai, Chennai, Tamil Nadu, 600100",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04834"
    },
    "Rank": 10411,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04835"
    },
    "Rank": 10412,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04836"
    },
    "Rank": 10413,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04837"
    },
    "Rank": 10416,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital,,B-22, Sector 62, Noida, Uttar Pradesh, Uttar Pradesh, 201301",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04838"
    },
    "Rank": 10418,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04839"
    },
    "Rank": 10419,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0483a"
    },
    "Rank": 10420,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0483b"
    },
    "Rank": 10421,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0483c"
    },
    "Rank": 10424,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0483d"
    },
    "Rank": 10426,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indus International Hospital, ,CHANDIGARH-AMBALA ROAD NH-22, DERABASSI, Punjab, 140507",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0483e"
    },
    "Rank": 10427,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rohini Medicare, (Dr. J Sudhakar Reddy) ,2-5-742 Subedari Hanamkonda Warangal, Telangana, 506001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0483f"
    },
    "Rank": 10428,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sheth Vadilal Sarabhai General Hospital and Sheth Chinai Prasuti Hospital, Gujarat,SHETH VADILAL SARABHAI GENERAL HOSPITAL AND SHETH CHINAI PRASUTI HOSPITAL ELLISBRIDGE AHMEDABAD, Gujarat, 380006",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04840"
    },
    "Rank": 10429,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04841"
    },
    "Rank": 10431,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital , ,GMERS MEDICAL COLLEGE , Hadiyol-Gadhoda Road, Near-Government Polytechnic, Hiimmatnagar, Gujarat, 383001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04842"
    },
    "Rank": 10432,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04843"
    },
    "Rank": 10433,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04844"
    },
    "Rank": 10436,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04845"
    },
    "Rank": 10437,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04846"
    },
    "Rank": 10438,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kokilaben Dhirubhai Ambani Hospital and Medical Research Institute,,Achyutrao Patwardhan Marg Andheri West Mumbai Maharashtra, Maharashtra, 400053",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04847"
    },
    "Rank": 10440,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04848"
    },
    "Rank": 10441,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04849"
    },
    "Rank": 10444,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0484a"
    },
    "Rank": 10448,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0484b"
    },
    "Rank": 10451,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kasturba Hospital, Daryaganj,Delhi,Daryaganj,Delhi",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0484c"
    },
    "Rank": 10453,
    "Allotted Quota": "All India",
    "Allotted Institute": "BHOPAL MEMORIAL HOSPITAL AND RESEARCH CENTRE, BHOPAL,Raisen By pass\nRoad, Bhopal- 462038 (M.P.), Madhya Pradesh, 462038",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0484d"
    },
    "Rank": 10454,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sakra World Hospital, (A Unit of Takshasila Hospitals Operating Private Limited)\n,LOVEKESH\\DOT\\PHASU\\AT\\SAKRAWORLDHOSPITAL\\DOT\\COM, Karnataka, 560103",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0484e"
    },
    "Rank": 10456,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0484f"
    },
    "Rank": 10458,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04850"
    },
    "Rank": 10459,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ZOI HOSPITALS, Telangana,Pillar No:145, zoi Hospitals, attapur, Telangana, 500045",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04851"
    },
    "Rank": 10461,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04852"
    },
    "Rank": 10463,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04853"
    },
    "Rank": 10464,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04854"
    },
    "Rank": 10466,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04855"
    },
    "Rank": 10468,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jehangir Hospital, ,32 Sasoon Road Pune, Maharashtra, 411001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04856"
    },
    "Rank": 10469,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04857"
    },
    "Rank": 10470,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04858"
    },
    "Rank": 10471,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04859"
    },
    "Rank": 10472,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0485a"
    },
    "Rank": 10473,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0485b"
    },
    "Rank": 10474,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0485c"
    },
    "Rank": 10477,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0485d"
    },
    "Rank": 10478,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bombay Hospital and Institute of Medical Sciences, ,12, New Marine Lines, Mumbai, Maharashtra, 400020",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0485e"
    },
    "Rank": 10480,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0485f"
    },
    "Rank": 10481,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04860"
    },
    "Rank": 10482,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04861"
    },
    "Rank": 10483,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04862"
    },
    "Rank": 10484,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04863"
    },
    "Rank": 10485,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jehangir Hospital, ,32 Sasoon Road Pune, Maharashtra, 411001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04864"
    },
    "Rank": 10488,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04865"
    },
    "Rank": 10490,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04866"
    },
    "Rank": 10491,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04867"
    },
    "Rank": 10492,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Parsik Hill Road, ,APOLLO HOSPITAL PARSIK HILL ROAD ROAD NO 13 SECTOR 23 CBD BELAPUR NAVI MUMBAI, Maharashtra, 400614",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04868"
    },
    "Rank": 10495,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04869"
    },
    "Rank": 10496,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Madras Medical Mission Hospital, ,NO-4A, DR J J NAGAR, MOGAPPAIR, CHENNAI, Tamil Nadu, 600037",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0486a"
    },
    "Rank": 10498,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0486b"
    },
    "Rank": 10499,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indira Gandhi Govt. General Hospital and PG Institute, No. 1, ,No.1, Victor Simonel street, Puducherry -1., Puducherry, 605001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0486c"
    },
    "Rank": 10501,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Madhukar Rainbow Childrens Hospital,,FC-29, Plot No.5, Geetanjali, Near Malviya Nagar Metro station gate no.1, Delhi (NCT), 110017",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0486d"
    },
    "Rank": 10505,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0486e"
    },
    "Rank": 10506,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0486f"
    },
    "Rank": 10507,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharashtra Post Graduate Institute of Medical Education and Research, Nashik,Maharashtra Post Graduation Institute Of Medical Education and Research Civil Hospital campus Trimba, Maharashtra, 422002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04870"
    },
    "Rank": 10508,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04871"
    },
    "Rank": 10509,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04872"
    },
    "Rank": 10510,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04873"
    },
    "Rank": 10512,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04874"
    },
    "Rank": 10513,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Comtrust Eye Hospital, ,Mini Bypass Road, Puthiyara, Kozhikode., Kerala, 673004",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04875"
    },
    "Rank": 10514,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04876"
    },
    "Rank": 10515,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aravind Eye Hospital and Postgraduate Institute of Ophthalmology, ,371, Periyakulam Road, Theni, Tamil Nadu, 625531",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04877"
    },
    "Rank": 10516,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04878"
    },
    "Rank": 10517,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04879"
    },
    "Rank": 10518,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0487a"
    },
    "Rank": 10520,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0487b"
    },
    "Rank": 10522,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0487c"
    },
    "Rank": 10524,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0487d"
    },
    "Rank": 10525,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0487e"
    },
    "Rank": 10526,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0487f"
    },
    "Rank": 10530,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04880"
    },
    "Rank": 10532,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04881"
    },
    "Rank": 10533,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Santokbha Durlabhji Memorial Hospital Cum Medical Research Institute,\n,dnb.sdmh@gmail.com, Rajasthan, 302015",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04882"
    },
    "Rank": 10534,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04883"
    },
    "Rank": 10536,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04884"
    },
    "Rank": 10537,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Hurkisondas Nurrotumdas Hospital and Research Centre, ,Prarthana Samaj, Raja Ram Mohan Roy road,\nGirgaon, MUMBAI, Maharashtra, 400004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04885"
    },
    "Rank": 10539,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "South Eastern Railways Central Hospital, ,CENTRAL HOSPITAL SOUTH EASTERN RAILWAY\n11 GARDEN REACH ROAD\nKOLKATA, West Bengal, 700043",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04886"
    },
    "Rank": 10541,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04887"
    },
    "Rank": 10543,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04888"
    },
    "Rank": 10546,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04889"
    },
    "Rank": 10556,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "C.S.I. Kalyani General Hospital,, ,15, DR RADHAKRISHNAN SALAI, MYLAPORE, CHENNAI, Tamil Nadu, 600004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0488a"
    },
    "Rank": 10557,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0488b"
    },
    "Rank": 10558,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0488c"
    },
    "Rank": 10560,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ananthapuri Hospital and Research Institute, ,Chacka, NH Bypass, Thiruvananthapuram 695024, Kerala, 695024",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0488d"
    },
    "Rank": 10561,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0488e"
    },
    "Rank": 10563,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MENTAL HEALTH AND HOSPITAL, AGRA,INSTITUTE OF MENTAL HEALTH AND HOSPITAL, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0488f"
    },
    "Rank": 10565,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04890"
    },
    "Rank": 10568,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04891"
    },
    "Rank": 10569,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES,CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, Chhattisgarh, 495001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04892"
    },
    "Rank": 10570,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04893"
    },
    "Rank": 10571,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aditya Diagnostics and Hospital,,Bordoloi Avenue, Dibrugarh-786005, Assam, 786005",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04894"
    },
    "Rank": 10573,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04895"
    },
    "Rank": 10574,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04896"
    },
    "Rank": 10576,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04897"
    },
    "Rank": 10580,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jupiter Hospital, ,Eastern Express Highway, Thane West, Maharashtra, 400601",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04898"
    },
    "Rank": 10582,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Princess Durru Shehvar Children`s and General Hospital, ,22 3 660A PURANI HAVELI HYDERABAD, Telangana, 500002",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04899"
    },
    "Rank": 10583,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0489a"
    },
    "Rank": 10584,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences, ,academics@kimshospitals.com, Telangana, 500003",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0489b"
    },
    "Rank": 10587,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Delhi Heart and Lung Institute,\n,3-MM-II PANCHKUIAN RAOD NEW DELHI, Delhi (NCT), 110055",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0489c"
    },
    "Rank": 10588,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital International, ,Plot 1A, GIDC Estate, Bhat Gandhinagar, Gujarat, 382428",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0489d"
    },
    "Rank": 10589,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruby Hall Clinic, ,GRANT MEDICAL FOUNDATION,\n40 SASSOON ROAD, PUNE 411001, Maharashtra, 411001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0489e"
    },
    "Rank": 10596,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rangadore Memorial Hospital,,1ST CROSS, SHANKARAPURAM, BANGALORE, Karnataka, 560004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0489f"
    },
    "Rank": 10597,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048a0"
    },
    "Rank": 10599,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048a1"
    },
    "Rank": 10607,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048a2"
    },
    "Rank": 10609,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "NIMS Medicity, Trivandrum,\nKerala,Aralumoodu, Neyyattinkara, Trivandrum 695123, Kerala, 695123",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048a3"
    },
    "Rank": 10610,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048a4"
    },
    "Rank": 10611,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,RAJBHAVAN ROAD SOMAJIGUDA, Telangana, 500082",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048a5"
    },
    "Rank": 10616,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048a6"
    },
    "Rank": 10617,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048a7"
    },
    "Rank": 10620,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital,,DNB office, 3rd Floor Opp Library  Apollo Hospitals, Jubilee Hills, Hyderabad, Telangana, 500033",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048a8"
    },
    "Rank": 10621,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sagar Hospital, Karnataka,No. 44/54, 30th Cross, Tilak Nagar, Jayanagar Extn., Bangalore, Karnataka-560041",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048a9"
    },
    "Rank": 10622,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048aa"
    },
    "Rank": 10623,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048ab"
    },
    "Rank": 10624,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048ac"
    },
    "Rank": 10626,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B L Kapur Memorial Hospital,Pusa Road New Delhi, Delhi (NCT), 110005",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048ad"
    },
    "Rank": 10628,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ZOI HOSPITALS, Telangana,Pillar No:145, zoi Hospitals, attapur, Telangana, 500045",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048ae"
    },
    "Rank": 10631,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Head Quarters Hospital,,District Hospital, Sangareddy Telangana State, Telangana, 502001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048af"
    },
    "Rank": 10635,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048b0"
    },
    "Rank": 10638,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048b1"
    },
    "Rank": 10639,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Childrens Hospital and BirthRight, Telangana,Hno 3 7 222 223 sy no 51 to 54 opposit kharkana police station kaskhana main road kakaguda hyderabad, Telangana, 500009",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048b2"
    },
    "Rank": 10641,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048b3"
    },
    "Rank": 10644,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048b4"
    },
    "Rank": 10645,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048b5"
    },
    "Rank": 10647,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048b6"
    },
    "Rank": 10649,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048b7"
    },
    "Rank": 10651,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048b8"
    },
    "Rank": 10654,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048b9"
    },
    "Rank": 10655,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Maternity Home and Pediatric Centre, Tamil Nadu,North highground road, Palayamkottai, Tirunelveli 627002, Tamil Nadu, 627002",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048ba"
    },
    "Rank": 10656,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048bb"
    },
    "Rank": 10658,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bokaro General Hospital, ,SECTOR - 4 BOKARO STEEL CITY\nDIST - BOKARO, Jharkhand, 827004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048bc"
    },
    "Rank": 10663,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,LOTUS TOWER, G S ROAD, GUWAHATI, Assam, 781005",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048bd"
    },
    "Rank": 10664,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048be"
    },
    "Rank": 10667,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048bf"
    },
    "Rank": 10669,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Babasaheb Ambedkar Central Railway Hospital, ,Bharatratna Dr. Babasaheb Ambedkar Memorial Hospital, Central Railway, Dr. Babasaheb Ambedkar Road,, Maharashtra, 400027",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048c0"
    },
    "Rank": 10670,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048c1"
    },
    "Rank": 10671,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, (A unit of Balaji Medical and Diagnostic Research Centre)\n,Max Super Specialty Hospital -108A IP Extension Patparganj New Delhi, Delhi (NCT), 110092",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048c2"
    },
    "Rank": 10673,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "CITY HOSPITAL, Moglaha, ,City Super Speciality Hospital Pvt. Ltd., Moglaha, Medical College Road, Gorakhpur, Uttar Pradesh 27, Uttar Pradesh, 273013",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048c3"
    },
    "Rank": 10677,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048c4"
    },
    "Rank": 10679,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048c5"
    },
    "Rank": 10680,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048c6"
    },
    "Rank": 10681,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048c7"
    },
    "Rank": 10682,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048c8"
    },
    "Rank": 10683,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048c9"
    },
    "Rank": 10685,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE,ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, Bihar, 823001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048ca"
    },
    "Rank": 10686,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048cb"
    },
    "Rank": 10687,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kozhikode District Co-Operative Hospital,The Kozhikode District Co-operative Hospital, Eranhipalam, Kozhikode, 673006, Kerala, 673006",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048cc"
    },
    "Rank": 10688,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nirmal Hospital,,NEAR KADIWALA SCHOOL, RING ROAD, SURAT, Gujarat, 395002",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048cd"
    },
    "Rank": 10689,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048ce"
    },
    "Rank": 10690,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KIMS Hospital,,#1-112 / 86, Survey No 5 / EE, beside Union Bank, near RTA Office, Kondapur, Hyderabad,Telangana, Telangana, 500084",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048cf"
    },
    "Rank": 10692,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048d0"
    },
    "Rank": 10698,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048d1"
    },
    "Rank": 10700,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Institute of Medical Sciences, ,Greater Noida, Kasna, Uttar Pradesh, 201310",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048d2"
    },
    "Rank": 10702,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048d3"
    },
    "Rank": 10703,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048d4"
    },
    "Rank": 10704,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048d5"
    },
    "Rank": 10705,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM Health Care Private Limited, Tamil Nadu,New No 72, Old No 54, Nelson Manickam Road, Aminjikarai, Chennai – 600029 Tamilnadu State, Tamil Nadu, 600029",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048d6"
    },
    "Rank": 10707,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048d7"
    },
    "Rank": 10709,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College,Tumuki, Bihaguri, Tezpur, Sonitpur, Assam, Assam, 784010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048d8"
    },
    "Rank": 10710,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048d9"
    },
    "Rank": 10711,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Thiruvannamalai Medical College, Thiruvannamalai, Tamil Nadu,DISTRICT COLLECTORATE  MASTER PLAN COMPLEX, VENGIKKAL THIRUVANNAMALAI,\nTamil Nadu, 600604",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048da"
    },
    "Rank": 10712,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Christian Fellowship Hospital, ,Dindigul Palani Main Road Oddanchatram\nDindigul District, Tamil Nadu, 624619",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048db"
    },
    "Rank": 10714,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048dc"
    },
    "Rank": 10719,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048dd"
    },
    "Rank": 10721,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048de"
    },
    "Rank": 10722,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kauvery Hospital,9-50, Trichy Main Road, Seelanaickenpatti, Salem, Tamil Nadu, 636201",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048df"
    },
    "Rank": 10723,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048e0"
    },
    "Rank": 10724,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asian Institute of Medical Sciences,,Asian Institute of Medical Sciences, Badhkal Flyover Road,Sector-21A, Faridabad, Haryana-121001, Haryana, 121001",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048e1"
    },
    "Rank": 10725,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048e2"
    },
    "Rank": 10726,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048e3"
    },
    "Rank": 10727,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Hospital, Omandurar,GOVERNMENT MEDICAL COLLEGE, OMANDURAR GOVERNMENT ESTATE, WALAJAH ROAD, NEAR KALAIVANAR ARANGAM, CHEN, Tamil Nadu, 600002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048e4"
    },
    "Rank": 10730,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048e5"
    },
    "Rank": 10733,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K S HOSPITAL, Karnataka,9-13-1270, M B DIWATAR NAGAR, HOSPET ROAD,\nKOPPAL, Karnataka, 583231",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048e6"
    },
    "Rank": 10735,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048e7"
    },
    "Rank": 10736,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048e8"
    },
    "Rank": 10739,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048e9"
    },
    "Rank": 10740,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VELLORE MEDICAL COLLEGE,GOVERNMENT VELLORE MEDICAL COLLEGE, Tamil Nadu, 632011",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048ea"
    },
    "Rank": 10743,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM Health Care Private Limited, Tamil Nadu,New No 72, Old No 54, Nelson Manickam Road, Aminjikarai, Chennai – 600029 Tamilnadu State, Tamil Nadu, 600029",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048eb"
    },
    "Rank": 10745,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gopala Gowda Shanthaveri Memorial Hospital, Karnataka,T Narasipura Road, Nazarbad Mysuru, Karnataka, 570010",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048ec"
    },
    "Rank": 10746,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048ed"
    },
    "Rank": 10748,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048ee"
    },
    "Rank": 10749,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048ef"
    },
    "Rank": 10754,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sanjay Gandhi Postgarduate Institute of Medical Sciences,Sanjay Gandhi Postgarduate Institute of Medical Sciences, Uttar Pradesh, 226014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048f0"
    },
    "Rank": 10755,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District hospital chitradurga, B D road beside DHO office Chitradurga, Karnataka, 577501",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048f1"
    },
    "Rank": 10756,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048f2"
    },
    "Rank": 10759,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048f3"
    },
    "Rank": 10760,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048f4"
    },
    "Rank": 10761,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KAUVERY HOSPITAL, Karnataka,no.92, 1 A B, Konappana Agrahara, Electronic City, Bangalore, Karnataka, 560100",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048f5"
    },
    "Rank": 10762,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Choithram Hospital and Research Centre,Manika Bagh Road Indore, Madhya Pradesh, 452014",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048f6"
    },
    "Rank": 10767,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048f7"
    },
    "Rank": 10768,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048f8"
    },
    "Rank": 10769,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048f9"
    },
    "Rank": 10771,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048fa"
    },
    "Rank": 10772,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pushpanjali Hospital and Research Centre, Uttar Pradesh,Pushpanjali Hospital and Research Centre Pvt Ltd Pushpanjali Palace, Delhi Gate, Agra, Uttar Pradesh, 282002",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048fb"
    },
    "Rank": 10774,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048fc"
    },
    "Rank": 10775,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048fd"
    },
    "Rank": 10776,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048fe"
    },
    "Rank": 10777,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a048ff"
    },
    "Rank": 10778,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharashtra Post Graduate Institute of Medical Education and Research, Nashik,Maharashtra Post Graduation Institute Of Medical Education and Research Civil Hospital campus Trimba, Maharashtra, 422002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04900"
    },
    "Rank": 10782,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04901"
    },
    "Rank": 10783,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04902"
    },
    "Rank": 10785,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04903"
    },
    "Rank": 10787,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04904"
    },
    "Rank": 10788,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Multispeciality Hospital, West Bengal,Narayana Multispeciality Hospital 78, Jessore Road ( South),\nKolkata, West Bengal, West Bengal, 700127",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04905"
    },
    "Rank": 10790,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital, B.H.Road, Tumakuru-572101, Karnataka, 572101",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04906"
    },
    "Rank": 10791,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04907"
    },
    "Rank": 10792,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04908"
    },
    "Rank": 10793,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Head Quarters Hospital,,District Hospital, Sangareddy Telangana State, Telangana, 502001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04909"
    },
    "Rank": 10794,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0490a"
    },
    "Rank": 10796,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0490b"
    },
    "Rank": 10798,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0490c"
    },
    "Rank": 10799,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0490d"
    },
    "Rank": 10804,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0490e"
    },
    "Rank": 10805,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0490f"
    },
    "Rank": 10806,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04910"
    },
    "Rank": 10807,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aravind Eye Hospital, ,Aravind Eye Hospital\nPoonamallee High Rd, opposite Saveetha Dental College, Noombal, Chennai, Tamil Nadu, 600077",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04911"
    },
    "Rank": 10809,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruby Hall Clinic, ,GRANT MEDICAL FOUNDATION,\n40 SASSOON ROAD, PUNE 411001, Maharashtra, 411001",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04912"
    },
    "Rank": 10810,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital\nNext to Sainik School second gate Athani Road, Vijayapura Karnataka 586103, Karnataka, 586103",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04913"
    },
    "Rank": 10813,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04914"
    },
    "Rank": 10814,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04915"
    },
    "Rank": 10815,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04916"
    },
    "Rank": 10816,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04917"
    },
    "Rank": 10820,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS MEDICAL COLLEGE, NEAR GUJARAT HIGH COURT, S.G HIGH WAY, SOLA, AHMEDABAD, Gujarat, 380060",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04918"
    },
    "Rank": 10825,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04919"
    },
    "Rank": 10828,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agarsen Hospital, ,., Delhi (NCT), 110026",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0491a"
    },
    "Rank": 10829,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital,,FC 50, Max Wali Rd, C and D Block, Shalimar Place Site, Shalimar Bagh, New Delhi, Delhi 110088, Delhi (NCT), 110088",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0491b"
    },
    "Rank": 10831,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "NEMCARE Hospital,,G.S.Road, Bhangagarh, Guwahati, Assam, 781005",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0491c"
    },
    "Rank": 10832,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC MEDICAL COLLEGE, JOKA,ESI-PGIMSR & ESIC MEDICAL COLLEGE, JOKA, KOLKATA, WEST BENGAL, West Bengal, 700104",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0491d"
    },
    "Rank": 10833,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Balaji Action Medical Institute,,FC34 A4 PASCHIM VIHAR NEW DELHI, Delhi (NCT), 110063",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0491e"
    },
    "Rank": 10834,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Ramakrishna Hospital, ,395, Sarojini Naidu Road, Sidhapudur,\nCoimbatore - 641 044, Tamil Nadu, 641044",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0491f"
    },
    "Rank": 10836,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04920"
    },
    "Rank": 10838,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MVR Cancer Centre and Research Institute, Kerala,MVR Cancer Centre and Research Institute, CP7-504 VELLALASSERI\nREC -NIT VIA CALICUT,\nKERALA, Kerala, 673601",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04921"
    },
    "Rank": 10839,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS) DERMATOLOGY and VENEREOLOGY and LEPROSY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04922"
    },
    "Rank": 10840,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata Shrimati Devendra Kumari Singhdeo Government Medical College, Ambikapur,Kanyaparisar  Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04923"
    },
    "Rank": 10841,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,Nalgonda X Roads, Malakpet, Hyderabad, Telangana, 500036",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04924"
    },
    "Rank": 10842,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04925"
    },
    "Rank": 10845,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Hospital,,JANGLAT MANDI ANANTNAG, JAMMU AND KASHMIR 192101, Jammu And Kashmir, 192101",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04926"
    },
    "Rank": 10846,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04927"
    },
    "Rank": 10847,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K S HOSPITAL, Karnataka,9-13-1270, M B DIWATAR NAGAR, HOSPET ROAD,\nKOPPAL, Karnataka, 583231",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04928"
    },
    "Rank": 10850,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04929"
    },
    "Rank": 10851,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0492a"
    },
    "Rank": 10852,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Emergency Medical Care (EMC) Hospital,\n,Back Side DC Residence Lane, Green Avenue, Amritsar, Punjab, 143001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0492b"
    },
    "Rank": 10853,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0492c"
    },
    "Rank": 10856,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0492d"
    },
    "Rank": 10857,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0492e"
    },
    "Rank": 10860,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Baba Saheb Ambedkar Hospital, ,SEC 6 ROHINI DELHI, Delhi (NCT), 110085",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0492f"
    },
    "Rank": 10861,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04930"
    },
    "Rank": 10862,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Santokbha Durlabhji Memorial Hospital Cum Medical Research Institute,\n,dnb.sdmh@gmail.com, Rajasthan, 302015",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04931"
    },
    "Rank": 10863,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Paramitha Childrens Hospital,,11-13-728 5, greenhills colony, kothapet main road, hyderabad, Telangana-500074, india, Telangana, 500074",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04932"
    },
    "Rank": 10864,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04933"
    },
    "Rank": 10867,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04934"
    },
    "Rank": 10868,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04935"
    },
    "Rank": 10869,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Super Speciality Hospital, Delhi,Rajiv Gandhi Super Speciality Hospital,Tahirpur, Delhi, Delhi (NCT), 110093",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04936"
    },
    "Rank": 10870,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04937"
    },
    "Rank": 10871,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aravind Eye Hospital, ,Aravind Eye Hospital\nPoonamallee High Rd, opposite Saveetha Dental College, Noombal, Chennai, Tamil Nadu, 600077",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04938"
    },
    "Rank": 10873,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04939"
    },
    "Rank": 10874,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K B Bhabha Municipal General Hospital, ,R K PATKAR ROAD ,BANDRA WEST\n,MUMBAI, Maharashtra, 400050",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0493a"
    },
    "Rank": 10876,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Childrens Hospital, (A unit of Rainbow Childrens Medicare Pvt. Ltd.) (Formerly Rainbow Institute of Medical Sciences ,Opp. NTR University of Health Sciences, Currency Nagar. Vijayawada - 520008, Andhra Pradesh, 520008",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0493b"
    },
    "Rank": 10877,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0493c"
    },
    "Rank": 10878,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0493d"
    },
    "Rank": 10880,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0493e"
    },
    "Rank": 10883,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0493f"
    },
    "Rank": 10885,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Capital Hospital, Orissa,director.chb@gmail.com, Odisha, 751001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04940"
    },
    "Rank": 10888,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollomedics Superspeciality Hospital,KBC 31, Sector B, LDA Colony, Kanpur Road, Lucknow, Uttar Pradesh, 226012",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04941"
    },
    "Rank": 10890,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "IQRAA International Hospital and Research Centre, ,IQRAA INTERNATIONAL HOSPITAL AND RESEARCH CENTRE MALAPARAMBA CALICUT, Kerala, 673009",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04942"
    },
    "Rank": 10891,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "P.D. Hinduja National Hospital and Medical Research Centre, ,Veer Savarkar Marg, Mahim, Maharashtra, 400016",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04943"
    },
    "Rank": 10893,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Martha`s Hospital , ,No.5, Nrupathunga Road, Bengaluru, Karnataka, 560001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04944"
    },
    "Rank": 10895,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04945"
    },
    "Rank": 10897,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04946"
    },
    "Rank": 10899,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gwalior Mansik Arogyashala, Madhya Pradesh,Gwalior Mansik Arogyashala Jail Rd, Gwalior, Madhya Pradesh 474012, Madhya Pradesh, 474012",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04947"
    },
    "Rank": 10900,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Princess Durru Shehvar Children`s and General Hospital, ,22 3 660A PURANI HAVELI HYDERABAD, Telangana, 500002",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04948"
    },
    "Rank": 10901,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04949"
    },
    "Rank": 10902,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "PRASHANTH HOSPITALS ,\nTamil Nadu,No 36  36 A Velachery Main road Velachery\nChennai 600042, Tamil Nadu, 600042",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0494a"
    },
    "Rank": 10903,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. R N Cooper Municipal General Hospital, (Associated with H B T (Hinduhridayasamrat Balasaheb Thackeray) medical College) ,HBTMC AND DR.R.N.COOPER HOSPITAL\nU 15 BHAKTI VEDANTA ROAD JVPD SCHEME VILE PARLE WEST MUMBAI,\nMaharashtra, 400056",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0494b"
    },
    "Rank": 10904,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jehangir Hospital, ,32 Sasoon Road Pune, Maharashtra, 411001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0494c"
    },
    "Rank": 10905,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0494d"
    },
    "Rank": 10909,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0494e"
    },
    "Rank": 10910,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Escorts Hospital, ,214, Jawahar Lal Nehru Marg, Malviya Nagar, Jaipur, Rajasthan, 302017",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0494f"
    },
    "Rank": 10912,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Metro Heart Institute, Uttar Pradesh,L 94 sector 11 Noida Uttar pradesh, Uttar Pradesh, 201301",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04950"
    },
    "Rank": 10915,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04951"
    },
    "Rank": 10916,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04952"
    },
    "Rank": 10917,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fernandez Hospital, ,4-1-1230, Bogulkunta, Hyderabad, Telangana-500001, Telangana, 500001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04953"
    },
    "Rank": 10919,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04954"
    },
    "Rank": 10920,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04955"
    },
    "Rank": 10921,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Madhukar Rainbow Childrens Hospital,,FC-29, Plot No.5, Geetanjali, Near Malviya Nagar Metro station gate no.1, Delhi (NCT), 110017",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04956"
    },
    "Rank": 10922,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Western India Instt. of Neurosciences, ,183 1, B, Nagala Park, Kolhapur, Maharashtra, 416002",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04957"
    },
    "Rank": 10923,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04958"
    },
    "Rank": 10924,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04959"
    },
    "Rank": 10925,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0495a"
    },
    "Rank": 10927,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Government Medical College, Vidisha,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA MP, Madhya Pradesh, 464001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0495b"
    },
    "Rank": 10928,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital,\n,DISTRICT SUREGEON DISTRICT HOSPITAL SETOR-11 NAVANAGAR BAGALKOTE\nKARNATAKA, Karnataka, 587103",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0495c"
    },
    "Rank": 10931,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0495d"
    },
    "Rank": 10934,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0495e"
    },
    "Rank": 10936,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0495f"
    },
    "Rank": 10937,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Hospital,,JANGLAT MANDI ANANTNAG, JAMMU AND KASHMIR 192101, Jammu And Kashmir, 192101",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04960"
    },
    "Rank": 10938,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04961"
    },
    "Rank": 10940,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04962"
    },
    "Rank": 10941,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04963"
    },
    "Rank": 10943,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04964"
    },
    "Rank": 10944,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B L Kapur Memorial Hospital,Pusa Road New Delhi, Delhi (NCT), 110005",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04965"
    },
    "Rank": 10945,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lalitha Super Specialty Hospital, ,KOTHAPET GUNTUR, Andhra Pradesh, 522001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04966"
    },
    "Rank": 10946,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04967"
    },
    "Rank": 10947,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04968"
    },
    "Rank": 10949,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Spirit Hospital, ,Mahakali Caves Road Andheri East Mumbai, Maharashtra, 400093",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04969"
    },
    "Rank": 10951,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0496a"
    },
    "Rank": 10952,
    "Allotted Quota": "All India",
    "Allotted Institute": "Calcutta School Of Tropical Medicine,Calcutta School Of Tropical Medicine, West Bengal, 700073",
    "Course": "M.D. (TROPICAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0496b"
    },
    "Rank": 10954,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0496c"
    },
    "Rank": 10956,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0496d"
    },
    "Rank": 10957,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0496e"
    },
    "Rank": 10960,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Balaji Action Medical Institute,,FC34 A4 PASCHIM VIHAR NEW DELHI, Delhi (NCT), 110063",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0496f"
    },
    "Rank": 10961,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04970"
    },
    "Rank": 10965,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04971"
    },
    "Rank": 10969,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KIMS Al Shifa Hospital, ,KIMS Al Shifa Healthcare Pvt  Ltd.\nPB No 26 .\nOotty Road.\nPerinthalmanna, Malappuram district, Ke, Kerala, 679322",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04972"
    },
    "Rank": 10971,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04973"
    },
    "Rank": 10972,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04974"
    },
    "Rank": 10973,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lourdes Hospital, ,Ernakulam, Pachalam, Kochi, Kerala, 682012",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04975"
    },
    "Rank": 10974,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaipur Golden Hospital, ,2 INSTITUTIONAL AREA, SECTOR-3, ROHINI, Delhi (NCT), 110085",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04976"
    },
    "Rank": 10976,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. GERIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04977"
    },
    "Rank": 10982,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, (Formerly- Pushpanjali Crosslay Hospital) ,W-3, Sector-1, Vaishali, Ghaziabad, Uttar Pradesh, 201012",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04978"
    },
    "Rank": 10984,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04979"
    },
    "Rank": 10987,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Autonomous State Medical College and Allied Pandit Ram Prasad Bismil Memorial Hospital,\n,ASMC, JIGNERA, kanth road, shahjahanpur, Uttar Pradesh, 242001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0497a"
    },
    "Rank": 10989,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MAXCARE Hospital, ,6-1-236, Near Kuda Office Line Sai Nagar, Opposite Ashoka Hotel, Hanamkonda, Telangana 506001, Telangana, 506001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0497b"
    },
    "Rank": 10992,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0497c"
    },
    "Rank": 10993,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College,Fakhruddin Ali Ahmed Medical College, Assam, 781301",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0497d"
    },
    "Rank": 10995,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0497e"
    },
    "Rank": 10996,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Cauvery Heart and Multispeciality Hospital, ,BANNUR ROAD, NEAR TERESIAN CIRCLE,MYSORE, Karnataka, 570029",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0497f"
    },
    "Rank": 10997,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04980"
    },
    "Rank": 11000,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04981"
    },
    "Rank": 11002,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agarsen Hospital, ,., Delhi (NCT), 110026",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04982"
    },
    "Rank": 11003,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Motors Hospital, ,KHARANGAJHAR, TELCO COLONY JAMSHEDPUR, Jharkhand, 831003",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04983"
    },
    "Rank": 11006,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04984"
    },
    "Rank": 11007,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04985"
    },
    "Rank": 11008,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04986"
    },
    "Rank": 11009,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College and  PGIMSR,ESIC Medical College and  PGIMSR, KK Nagar, Chennai - 600 078, Tamil Nadu, 600078",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04987"
    },
    "Rank": 11011,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04988"
    },
    "Rank": 11013,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04989"
    },
    "Rank": 11015,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0498a"
    },
    "Rank": 11016,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College,Theni ,Government Medical College,Theni, Tamil Nadu, 625512",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0498b"
    },
    "Rank": 11019,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0498c"
    },
    "Rank": 11022,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Spirit Hospital, ,Mahakali Caves Road Andheri East Mumbai, Maharashtra, 400093",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0498d"
    },
    "Rank": 11023,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0498e"
    },
    "Rank": 11025,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0498f"
    },
    "Rank": 11027,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04990"
    },
    "Rank": 11028,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VELLORE MEDICAL COLLEGE,GOVERNMENT VELLORE MEDICAL COLLEGE, Tamil Nadu, 632011",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04991"
    },
    "Rank": 11029,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04992"
    },
    "Rank": 11030,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. L H Hiranandani Hospital, ,HILL SIDE AVENUE, HIRANANDANI GARDENS, POWAI-400076, Maharashtra, 400076",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04993"
    },
    "Rank": 11031,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rangadore Memorial Hospital,,1ST CROSS, SHANKARAPURAM, BANGALORE, Karnataka, 560004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04994"
    },
    "Rank": 11032,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Guru Gobind Singh Govt. Hospital, Delhi,F-Block, Raghubir Nagar, New Delhi, Delhi (NCT), 110027",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04995"
    },
    "Rank": 11033,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04996"
    },
    "Rank": 11036,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MVR Cancer Centre and Research Institute, Kerala,MVR Cancer Centre and Research Institute, CP7-504 VELLALASSERI\nREC -NIT VIA CALICUT,\nKERALA, Kerala, 673601",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04997"
    },
    "Rank": 11038,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04998"
    },
    "Rank": 11039,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04999"
    },
    "Rank": 11040,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, (Formerly- Pushpanjali Crosslay Hospital) ,W-3, Sector-1, Vaishali, Ghaziabad, Uttar Pradesh, 201012",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0499a"
    },
    "Rank": 11041,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVERNMENT MEDICAL COLLEGE,KANYAKUMARI GOVERNMENT MEDICAL COLLEGE, Tamil Nadu, 629201",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0499b"
    },
    "Rank": 11042,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0499c"
    },
    "Rank": 11046,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BIG Apollo Spectra Hospitals,Shitla Mandir Road, Agamkuan, Patna, Bihar, 800007",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0499d"
    },
    "Rank": 11047,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0499e"
    },
    "Rank": 11049,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0499f"
    },
    "Rank": 11052,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049a0"
    },
    "Rank": 11055,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District hospital chitradurga, B D road beside DHO office Chitradurga, Karnataka, 577501",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049a1"
    },
    "Rank": 11059,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049a2"
    },
    "Rank": 11060,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049a3"
    },
    "Rank": 11062,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Ramesh Cardiac and Multi Speciality Hospital,,Collector office road, Nagarampalem, Guntur , AP, Andhra Pradesh, 522004",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049a4"
    },
    "Rank": 11063,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049a5"
    },
    "Rank": 11064,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049a6"
    },
    "Rank": 11067,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049a7"
    },
    "Rank": 11068,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kidwai Memorial Institute of Oncolgy,Kidwai Memorial Institute of Oncolgy, Karnataka, 560029",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049a8"
    },
    "Rank": 11069,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049a9"
    },
    "Rank": 11070,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE,ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, Bihar, 823001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049aa"
    },
    "Rank": 11072,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049ab"
    },
    "Rank": 11073,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Memorial Research Institute, ,Fortis memorial research institute, Sector 44, Opposite Huda City Centre Metro station, Gurgaon, Haryana, 122002",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049ac"
    },
    "Rank": 11074,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SunShine Hospital, ,1-7-201 TO 205\nPG Road, Paradise, Secunderabad, Telangana, 500003",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049ad"
    },
    "Rank": 11075,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Coochbehar Govt Medical College and Hospital, West Bengal,Vivekananda Street, Pilkhana, Cooch Behar, WB-736101, West Bengal, 736101",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049ae"
    },
    "Rank": 11076,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agarsen Hospital, ,., Delhi (NCT), 110026",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049af"
    },
    "Rank": 11077,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hayat Hospital, ,kahilipara Road, Odalbakra, Lalganesh,Guwahati, Assam, 781034",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049b0"
    },
    "Rank": 11078,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049b1"
    },
    "Rank": 11080,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049b2"
    },
    "Rank": 11084,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049b3"
    },
    "Rank": 11085,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049b4"
    },
    "Rank": 11086,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049b5"
    },
    "Rank": 11087,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049b6"
    },
    "Rank": 11088,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049b7"
    },
    "Rank": 11089,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049b8"
    },
    "Rank": 11092,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indian Spinal Injuries Centre,,SECTOR-C, VASANT KUNJ, NEW DELHI-110070, Delhi (NCT), 110070",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049b9"
    },
    "Rank": 11095,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049ba"
    },
    "Rank": 11097,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049bb"
    },
    "Rank": 11100,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049bc"
    },
    "Rank": 11101,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vijay Marie Hospital and Educational Society, ,10-1-673 1 Veernagar Khairatabad, Telangana, 500004",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049bd"
    },
    "Rank": 11104,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chittaranjan Seva Sadan Hospital,37, S.P. MUKHERJEE ROAD, KOLKATA, West Bengal, 700026",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049be"
    },
    "Rank": 11105,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Krishna Medical College and Hospital, ,Uma Nagar Muzaffarpur Bihar 842004, Bihar, 842004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049bf"
    },
    "Rank": 11106,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049c0"
    },
    "Rank": 11107,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049c1"
    },
    "Rank": 11108,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049c2"
    },
    "Rank": 11109,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049c3"
    },
    "Rank": 11111,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Balaji Action Medical Institute,,FC34 A4 PASCHIM VIHAR NEW DELHI, Delhi (NCT), 110063",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049c4"
    },
    "Rank": 11113,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049c5"
    },
    "Rank": 11115,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049c6"
    },
    "Rank": 11116,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049c7"
    },
    "Rank": 11117,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049c8"
    },
    "Rank": 11118,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049c9"
    },
    "Rank": 11119,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital International, ,Plot 1A, GIDC Estate, Bhat Gandhinagar, Gujarat, 382428",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049ca"
    },
    "Rank": 11120,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049cb"
    },
    "Rank": 11125,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KIMS Hospital , ,KIMS Hospitals, 69/213-k-9, Joharapuram Road, Joharapuram, Kurnool, Andhra Pradesh, 518002",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049cc"
    },
    "Rank": 11127,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049cd"
    },
    "Rank": 11133,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049ce"
    },
    "Rank": 11134,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049cf"
    },
    "Rank": 11135,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gopala Gowda Shanthaveri Memorial Hospital, Karnataka,T Narasipura Road, Nazarbad Mysuru, Karnataka, 570010",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049d0"
    },
    "Rank": 11136,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Siddipet,SURVEY NO 54  GOVERNMENT MEDICAL COLLEGE  ENSANPALLY  SIDDIPET   502114, Telangana, 502114",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049d1"
    },
    "Rank": 11137,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049d2"
    },
    "Rank": 11138,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "PRASHANTH HOSPITALS ,\nTamil Nadu,No 36  36 A Velachery Main road Velachery\nChennai 600042, Tamil Nadu, 600042",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049d3"
    },
    "Rank": 11139,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049d4"
    },
    "Rank": 11141,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Homi Bhabha Cancer Hospital, Uttar Pradesh,Homi Bhabha Cancer Hospital , Old Loco Colony, Shivpurwa, Lahartara, Varanasi-221002, Uttar Pradesh, Uttar Pradesh, 221002",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049d5"
    },
    "Rank": 11142,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kurji Holy Family Hospital, ,Kurji Holy Family Hospital\nP.O - Sadaquat Ashram, Bihar, 800010",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049d6"
    },
    "Rank": 11145,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Ramakrishna Hospital, ,395, Sarojini Naidu Road, Sidhapudur,\nCoimbatore - 641 044, Tamil Nadu, 641044",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049d7"
    },
    "Rank": 11147,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Motors Hospital, ,KHARANGAJHAR, TELCO COLONY JAMSHEDPUR, Jharkhand, 831003",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049d8"
    },
    "Rank": 11148,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049d9"
    },
    "Rank": 11149,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049da"
    },
    "Rank": 11150,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049db"
    },
    "Rank": 11151,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Cancer Institute and Research Centre, ,Sector-5, Rohini, Delhi-110085, Delhi (NCT), 110085",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049dc"
    },
    "Rank": 11152,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Medical College and Chhatrapati Shivaji Maharaj Hospital, ,RAJIV GANDHI MEDICAL COLLEGE,THANE BELAPUR ROAD, KALWA, THANE, Maharashtra, 400605",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049dd"
    },
    "Rank": 11154,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Artemis Health Institute, ,Sector 51, Gurugram, Haryana -122001, Haryana, 122001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049de"
    },
    "Rank": 11156,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lourdes Hospital, ,Ernakulam, Pachalam, Kochi, Kerala, 682012",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049df"
    },
    "Rank": 11157,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049e0"
    },
    "Rank": 11159,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049e1"
    },
    "Rank": 11160,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049e2"
    },
    "Rank": 11161,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Santokbha Durlabhji Memorial Hospital Cum Medical Research Institute,\n,dnb.sdmh@gmail.com, Rajasthan, 302015",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049e3"
    },
    "Rank": 11162,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Human care Medical Charitable Trust,Human Care Medical Charitable Trust. HCMCTManipal Hospital, Sector 6, DWARKA, NEW DELHI, Delhi (NCT), 110075",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049e4"
    },
    "Rank": 11165,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fernandez Hospital, ,4-1-1230, Bogulkunta, Hyderabad, Telangana-500001, Telangana, 500001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049e5"
    },
    "Rank": 11171,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049e6"
    },
    "Rank": 11172,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049e7"
    },
    "Rank": 11173,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049e8"
    },
    "Rank": 11174,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049e9"
    },
    "Rank": 11175,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SLG Hospitals,Sree Lakshmi Gayatri Hospitals Pvt Ltd, SLG Circle Nizampet Road, Bachupally, Medchal Malkajgiri Dis, Telangana, 500090",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049ea"
    },
    "Rank": 11176,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049eb"
    },
    "Rank": 11179,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College,Theni ,Government Medical College,Theni, Tamil Nadu, 625512",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049ec"
    },
    "Rank": 11183,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SETH V. C. GANDHI and M. A. VORA MUNICIPAL GENERAL HOSPITAL (RAJAWADI HOSPITAL),\nMaharashtra,hospital.rajawadi@gmail.com, Maharashtra, 400077",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049ed"
    },
    "Rank": 11188,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "FIMS HOSPITAL(FRANK INSTITUTE OF MEDICAL SCIENCES), Haryana,Sonipat-\nBahalgarh Road, Sonipat-NCR, Sonipat, Haryana, 131001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049ee"
    },
    "Rank": 11189,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rabindranath Tagore International Institute of Cardiac Sciences, ,PREMISES NO. 1489 124 MUKUNDAPUR  E M BYPASS  KOLKATA-700099, West Bengal, 700099",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049ef"
    },
    "Rank": 11191,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Central Hospital, Gandhinagar, ,CENTRAL HOSPITAL, GANDHINAGAR, GANDHINAGAR COLONY,\nKANKE ROAD,\nRANCHI, Jharkhand, 834008",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049f0"
    },
    "Rank": 11194,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GURUKRIPA HOSPITALS ,JYOTI NAGAR PIPRALI ROAD SIKAR RAJASTHAN,\nRajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049f1"
    },
    "Rank": 11195,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049f2"
    },
    "Rank": 11197,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049f3"
    },
    "Rank": 11198,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Radhakrishnan Govt. Medical College , ,Dr. Radhakrishnan Govt. Medical College Hamirpur HP, Himachal Pradesh, 177001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049f4"
    },
    "Rank": 11199,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049f5"
    },
    "Rank": 11202,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Balaji Action Medical Institute,,FC34 A4 PASCHIM VIHAR NEW DELHI, Delhi (NCT), 110063",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049f6"
    },
    "Rank": 11203,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049f7"
    },
    "Rank": 11206,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049f8"
    },
    "Rank": 11208,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Metrounited Healthcare LLP, Karnataka,Shivamurthy Circle Savalanga Road\nShivamogga, Karnataka, 577201",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049f9"
    },
    "Rank": 11210,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049fa"
    },
    "Rank": 11211,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indus International Hospital, ,CHANDIGARH-AMBALA ROAD NH-22, DERABASSI, Punjab, 140507",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049fb"
    },
    "Rank": 11212,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049fc"
    },
    "Rank": 11215,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049fd"
    },
    "Rank": 11216,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049fe"
    },
    "Rank": 11217,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVERNMENT MEDICAL COLLEGE,KANYAKUMARI GOVERNMENT MEDICAL COLLEGE, Tamil Nadu, 629201",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a049ff"
    },
    "Rank": 11221,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Rangarajan Memorial Hospital, (Sundaram Medical Foundation) ,Sundaram Medical Foundation, Shanthi Colony, 4th Avenue, Anna Nagar, Chennai, Tamil Nadu, 600040",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a00"
    },
    "Rank": 11222,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a01"
    },
    "Rank": 11224,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VELLORE MEDICAL COLLEGE,GOVERNMENT VELLORE MEDICAL COLLEGE, Tamil Nadu, 632011",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a02"
    },
    "Rank": 11225,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rural Development Trust Hospital, ,Kadiri Road,Bathalapalli, Anantapur District Andhra Pradesh, Andhra Pradesh, 515661",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a03"
    },
    "Rank": 11226,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Down Town Hospital, G S Road, ,G S Road, Dispur, Guwahati-781006, Assam, 781006",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a04"
    },
    "Rank": 11229,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a05"
    },
    "Rank": 11231,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Ophthalmology,RIO Prev Known as Nehru Institute of Ophthalmology and Research Centre Civil Lines Sitapur, Uttar Pradesh, 261001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a06"
    },
    "Rank": 11234,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a07"
    },
    "Rank": 11236,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shree Narayana Hospital, ,Near Ganj Mandi, Behind Sector 5, Devendra Nagar, Pandri, Raipur, Chhattisgarh, 492001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a08"
    },
    "Rank": 11238,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a09"
    },
    "Rank": 11239,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital\nNext to Sainik School second gate Athani Road, Vijayapura Karnataka 586103, Karnataka, 586103",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a0a"
    },
    "Rank": 11243,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SANKARA NETHRALAYA ,\nTamil Nadu,No 21 Pycrofts Garden Road off. Haddows Road\nChennai, Tamil Nadu, 600006",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a0b"
    },
    "Rank": 11244,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a0c"
    },
    "Rank": 11245,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a0d"
    },
    "Rank": 11246,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaipur Golden Hospital, ,2 INSTITUTIONAL AREA, SECTOR-3, ROHINI, Delhi (NCT), 110085",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a0e"
    },
    "Rank": 11247,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a0f"
    },
    "Rank": 11248,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a10"
    },
    "Rank": 11250,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a11"
    },
    "Rank": 11251,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a12"
    },
    "Rank": 11253,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a13"
    },
    "Rank": 11256,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Human care Medical Charitable Trust,Human Care Medical Charitable Trust. HCMCTManipal Hospital, Sector 6, DWARKA, NEW DELHI, Delhi (NCT), 110075",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a14"
    },
    "Rank": 11258,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a15"
    },
    "Rank": 11265,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a16"
    },
    "Rank": 11266,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a17"
    },
    "Rank": 11267,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a18"
    },
    "Rank": 11268,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a19"
    },
    "Rank": 11272,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sheth Vadilal Sarabhai General Hospital and Sheth Chinai Prasuti Hospital, Gujarat,SHETH VADILAL SARABHAI GENERAL HOSPITAL AND SHETH CHINAI PRASUTI HOSPITAL ELLISBRIDGE AHMEDABAD, Gujarat, 380006",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a1a"
    },
    "Rank": 11278,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Jamshedpur,Mahatma Gandhi Memorial Medical College, Jamshedpur, Jharkhand, 831020",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a1b"
    },
    "Rank": 11279,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a1c"
    },
    "Rank": 11280,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a1d"
    },
    "Rank": 11283,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a1e"
    },
    "Rank": 11285,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Amandeep Medicity, Mall Road, Amritsar, Punjab,Mall Rd, near Novelty Chownk, Maharaja Ranjit Singh Nagar, Joshi Colony, Amritsar, Punjab 143001, Punjab, 143001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a1f"
    },
    "Rank": 11287,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a20"
    },
    "Rank": 11289,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jalgaon,Jilha Peth, Government Hospital Campus, Jalgaon, Maharashtra, 425001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a21"
    },
    "Rank": 11290,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Hospital For Women and Children, Telangana,Saraswathi Nagar Colony, Mansoorabad Village, LB Nagar, Telangana, 500070",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a22"
    },
    "Rank": 11293,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asian Institute of Medical Sciences,,Asian Institute of Medical Sciences, Badhkal Flyover Road,Sector-21A, Faridabad, Haryana-121001, Haryana, 121001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a23"
    },
    "Rank": 11294,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, Sector B, ,SECTOR-B1, VASANT KUNJ NEW DELHI, Delhi (NCT), 110070",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a24"
    },
    "Rank": 11295,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Hospital For Women and Children, Telangana,Saraswathi Nagar Colony, Mansoorabad Village, LB Nagar, Telangana, 500070",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a25"
    },
    "Rank": 11298,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a26"
    },
    "Rank": 11300,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a27"
    },
    "Rank": 11307,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a28"
    },
    "Rank": 11308,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shija Hospitals and Research Institute, ,SHIJA HOSPITALS AND RESEARCH INSTITUTE, LANGOL, LAMPHELPAT, IMPHAL WEST, MANIPUR 795004, Manipur, 795004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a29"
    },
    "Rank": 11309,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Martha`s Hospital , ,No.5, Nrupathunga Road, Bengaluru, Karnataka, 560001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a2a"
    },
    "Rank": 11310,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Gujarat Research and Medical Institute (Rajasthan Hospitals), ,Camp Road, Shahibaug, Ahmedabad, Gujarat, 380004",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a2b"
    },
    "Rank": 11311,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a2c"
    },
    "Rank": 11315,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a2d"
    },
    "Rank": 11316,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a2e"
    },
    "Rank": 11318,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SETH V. C. GANDHI and M. A. VORA MUNICIPAL GENERAL HOSPITAL (RAJAWADI HOSPITAL),\nMaharashtra,hospital.rajawadi@gmail.com, Maharashtra, 400077",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a2f"
    },
    "Rank": 11320,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a30"
    },
    "Rank": 11321,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a31"
    },
    "Rank": 11322,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a32"
    },
    "Rank": 11325,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindu Rao Hospital, ,SUBZI MANDI, MALKA GANJ, DELHI-110007, Delhi (NCT), 110007",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a33"
    },
    "Rank": 11330,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Childrens Hospital, (A unit of Rainbow Childrens Medicare Pvt. Ltd.) (Formerly Rainbow Institute of Medical Sciences ,Opp. NTR University of Health Sciences, Currency Nagar. Vijayawada - 520008, Andhra Pradesh, 520008",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a34"
    },
    "Rank": 11333,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a35"
    },
    "Rank": 11335,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Associated Hospital Government Medical College, ,Government Medical College Chack Sajjan Kathua, Jammu And Kashmir, 184101",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a36"
    },
    "Rank": 11336,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a37"
    },
    "Rank": 11339,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vijay Marie Hospital and Educational Society, ,10-1-673 1 Veernagar Khairatabad, Telangana, 500004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a38"
    },
    "Rank": 11340,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a39"
    },
    "Rank": 11342,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indo American Hospital, ,Institute of Brain & Spine,Akkarappadam P O, Chemmanakary, Vaikom, Kottayam, Kerala, 686143",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a3a"
    },
    "Rank": 11343,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVERNMENT MEDICAL COLLEGE,KANYAKUMARI GOVERNMENT MEDICAL COLLEGE, Tamil Nadu, 629201",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a3b"
    },
    "Rank": 11345,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, Sector B, ,SECTOR-B1, VASANT KUNJ NEW DELHI, Delhi (NCT), 110070",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a3c"
    },
    "Rank": 11346,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a3d"
    },
    "Rank": 11347,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES,CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, Chhattisgarh, 495001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a3e"
    },
    "Rank": 11348,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kauvery Hospital Medical Services Pvt Ltd,\n,vijay@kauveryhealthcare.com,\nNo 35,Shanthi nagar, Opposite to CSI church,Hosur 635109,Tamil Nadu,",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a3f"
    },
    "Rank": 11350,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VILLUPURAM MEDICAL COLLEGE, VILLUPURAM,GOVERNMENT VILLUPURAM MEDICAL COLLEGE VILLUPURAM  TRICHY TRUNK ROAD MUNDIYAMPAKKAM  VILLUPURAM DISTR, Tamil Nadu, 605601",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a40"
    },
    "Rank": 11352,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a41"
    },
    "Rank": 11354,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sheth Vadilal Sarabhai General Hospital and Sheth Chinai Prasuti Hospital, Gujarat,SHETH VADILAL SARABHAI GENERAL HOSPITAL AND SHETH CHINAI PRASUTI HOSPITAL ELLISBRIDGE AHMEDABAD, Gujarat, 380006",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a42"
    },
    "Rank": 11355,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a43"
    },
    "Rank": 11357,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a44"
    },
    "Rank": 11358,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "C.S.I. Kalyani General Hospital,, ,15, DR RADHAKRISHNAN SALAI, MYLAPORE, CHENNAI, Tamil Nadu, 600004",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a45"
    },
    "Rank": 11359,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lotus Children`s Hospital,,6-2-29,Lakdikapul, Hyderabad - 500004, Telangana, 500004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a46"
    },
    "Rank": 11361,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine & Sagore Dutta Hospital,College of Medicine & Sagore Dutta Hospital, West Bengal, 700058",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a47"
    },
    "Rank": 11363,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Arvind Eye Hospital, ,Aravind Eye Hospital and Postgraduate Institute of Ophthalmology, Cuddalore Main Road, Thavalakuppam, Puducherry, 605007",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a48"
    },
    "Rank": 11366,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "PRANAYAM LUNG AND HEART INSTITUTE, Gujarat,LAKULESH PARK SOCIETY NEAR SADHU VASWANI SCHOOL, NEW VIP ROAD, Gujarat, 390022",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a49"
    },
    "Rank": 11369,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Down Town Hospital, G S Road, ,G S Road, Dispur, Guwahati-781006, Assam, 781006",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a4a"
    },
    "Rank": 11370,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a4b"
    },
    "Rank": 11374,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ANKURA HOSPITAL FOR WOMEN AND CHILDREN(A UNIT OF ANKURA TIRUPATI HOSPITALS PVT LTD), Andhra Pradesh,ANKURA HOSPITAL FOR WOMEN AND CHILDREN RENIGUNTA ROAD TIRUPATHI, Andhra Pradesh, 517501",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a4c"
    },
    "Rank": 11375,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a4d"
    },
    "Rank": 11376,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a4e"
    },
    "Rank": 11379,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a4f"
    },
    "Rank": 11380,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a50"
    },
    "Rank": 11381,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vidyasagar Institute of Mental Health and Neuro Sciences,, ,NO.1, INSTITUTIONAL AREA, NEHRU NAGAR, NEW DELHI, Delhi (NCT), 110065",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a51"
    },
    "Rank": 11382,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a52"
    },
    "Rank": 11383,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a53"
    },
    "Rank": 11386,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a54"
    },
    "Rank": 11387,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chittaranjan Seva Sadan Hospital,37, S.P. MUKHERJEE ROAD, KOLKATA, West Bengal, 700026",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a55"
    },
    "Rank": 11389,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a56"
    },
    "Rank": 11390,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.J. Somaiya Medical College,,SOMAIYA AYURVIHAR , EASTERN EXPRESS HIGHWAY, SION, MUMBAI 400022, Maharashtra, 400022",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a57"
    },
    "Rank": 11391,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a58"
    },
    "Rank": 11394,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Christian Fellowship Hospital, ,Dindigul Palani Main Road Oddanchatram\nDindigul District, Tamil Nadu, 624619",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a59"
    },
    "Rank": 11395,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a5a"
    },
    "Rank": 11398,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kozhikode District Co-Operative Hospital,The Kozhikode District Co-operative Hospital, Eranhipalam, Kozhikode, 673006, Kerala, 673006",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a5b"
    },
    "Rank": 11399,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a5c"
    },
    "Rank": 11400,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a5d"
    },
    "Rank": 11403,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a5e"
    },
    "Rank": 11407,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a5f"
    },
    "Rank": 11409,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a60"
    },
    "Rank": 11410,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "J.L.N. Main Hospital and Research Centre, ,Bhilai Steel Plant Sector-9, Bhilai, C.G., Chhattisgarh, 490009",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a61"
    },
    "Rank": 11411,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a62"
    },
    "Rank": 11416,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kauvery Hospital Medical Services Pvt Ltd,\n,vijay@kauveryhealthcare.com,\nNo 35,Shanthi nagar, Opposite to CSI church,Hosur 635109,Tamil Nadu,",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a63"
    },
    "Rank": 11419,
    "Allotted Quota": "All India",
    "Allotted Institute": "Post Graduate Institute of Medical Education & Research and Capital Hospital ( PGIMER & CH), Odisha,Post Graduate Institute of Medical Education & Research and Capital Hospital, Bhubaneswar,Udyan Marg, Odisha, 751001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a64"
    },
    "Rank": 11421,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Eastern Indira Gandhi Regional Institute of Health & Medical Sciences, Shillong,North Eastern Indira Gandhi Regional Institute of Health & Medical Sciences, Shillong, Meghalaya, 793018",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a65"
    },
    "Rank": 11423,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a66"
    },
    "Rank": 11425,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Ramakrishna Hospital, ,395, Sarojini Naidu Road, Sidhapudur,\nCoimbatore - 641 044, Tamil Nadu, 641044",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a67"
    },
    "Rank": 11426,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a68"
    },
    "Rank": 11427,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "C.S.I. Kalyani General Hospital,, ,15, DR RADHAKRISHNAN SALAI, MYLAPORE, CHENNAI, Tamil Nadu, 600004",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a69"
    },
    "Rank": 11428,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a6a"
    },
    "Rank": 11430,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a6b"
    },
    "Rank": 11431,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a6c"
    },
    "Rank": 11432,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a6d"
    },
    "Rank": 11433,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a6e"
    },
    "Rank": 11434,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a6f"
    },
    "Rank": 11439,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a70"
    },
    "Rank": 11441,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Comtrust Eye Hospital, ,Mini Bypass Road, Puthiyara, Kozhikode., Kerala, 673004",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a71"
    },
    "Rank": 11442,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a72"
    },
    "Rank": 11443,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a73"
    },
    "Rank": 11446,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rabindranath Tagore International Institute of Cardiac Sciences, ,PREMISES NO. 1489 124 MUKUNDAPUR  E M BYPASS  KOLKATA-700099, West Bengal, 700099",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a74"
    },
    "Rank": 11448,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fernandez Hospital, Telangana,No.8-2-698, Rd Number 12, Bhola Nagar, Banjara Hills, Hyderabad, Telangana Telangana , Telangana-500, Telangana, 500034",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a75"
    },
    "Rank": 11450,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sarji Hospital, ,RMR RAOD PARK EXTENSION sHIVAMOGGA-577201, Karnataka, 577201",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a76"
    },
    "Rank": 11451,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a77"
    },
    "Rank": 11452,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GURUKRIPA HOSPITALS ,JYOTI NAGAR PIPRALI ROAD SIKAR RAJASTHAN,\nRajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a78"
    },
    "Rank": 11455,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a79"
    },
    "Rank": 11456,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES ADILABAD -504001 TELANGANA STATE, Telangana, 504001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a7a"
    },
    "Rank": 11457,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a7b"
    },
    "Rank": 11463,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Bhausaheb Hire Government Medical College,Sri Bhausaheb Hire Government Medical College,Dhule, Maharashtra, 424311",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a7c"
    },
    "Rank": 11468,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a7d"
    },
    "Rank": 11470,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a7e"
    },
    "Rank": 11471,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a7f"
    },
    "Rank": 11472,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Bundelkhand Medical College Sagar M.P.,Govt. Bundelkhand Medical College Sagar M.P., Madhya Pradesh, 470001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a80"
    },
    "Rank": 11473,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a81"
    },
    "Rank": 11474,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vijay Marie Hospital and Educational Society, ,10-1-673 1 Veernagar Khairatabad, Telangana, 500004",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a82"
    },
    "Rank": 11476,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a83"
    },
    "Rank": 11477,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a84"
    },
    "Rank": 11479,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a85"
    },
    "Rank": 11481,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sadhu Vaswani Missions ,7-9 Lane No 1 Koregaon Park Pune 411001, Maharashtra, 411001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a86"
    },
    "Rank": 11483,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a87"
    },
    "Rank": 11484,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apex Hospital,,N7 2A 5D BLW Hydel Road Varanasi, Uttar Pradesh, 221004",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a88"
    },
    "Rank": 11485,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a89"
    },
    "Rank": 11488,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karnataka,MG Road, Karnataka, 581301",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a8a"
    },
    "Rank": 11489,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a8b"
    },
    "Rank": 11493,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a8c"
    },
    "Rank": 11494,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a8d"
    },
    "Rank": 11495,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kasturba Medical College Hospital (KMC Hospital),,KMC Hospital, Dr B R Ambedkar Circle, Mangalore, Karnataka, 575001",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a8e"
    },
    "Rank": 11497,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lotus Children`s Hospital,,6-2-29,Lakdikapul, Hyderabad - 500004, Telangana, 500004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a8f"
    },
    "Rank": 11498,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a90"
    },
    "Rank": 11501,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Balaji Action Medical Institute,,FC34 A4 PASCHIM VIHAR NEW DELHI, Delhi (NCT), 110063",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a91"
    },
    "Rank": 11502,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a92"
    },
    "Rank": 11503,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Northern Railway Central Hospital,, ,Basant Lane New Delhi, Delhi (NCT), 110055",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a93"
    },
    "Rank": 11504,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deen Dayal Upadhyay Hospital, ,DDU HOSPITAL, SHAHEED MANGAL PANDEY MARG, NANAK PURA, HARI NAGAR, NEW DELHI, DELHI-110064, Delhi (NCT), 110064",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a94"
    },
    "Rank": 11506,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital,\n,DISTRICT SUREGEON DISTRICT HOSPITAL SETOR-11 NAVANAGAR BAGALKOTE\nKARNATAKA, Karnataka, 587103",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a95"
    },
    "Rank": 11508,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. L H Hiranandani Hospital, ,HILL SIDE AVENUE, HIRANANDANI GARDENS, POWAI-400076, Maharashtra, 400076",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a96"
    },
    "Rank": 11509,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a97"
    },
    "Rank": 11510,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine & Sagore Dutta Hospital,College of Medicine & Sagore Dutta Hospital, West Bengal, 700058",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a98"
    },
    "Rank": 11511,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sarji Hospital, ,RMR RAOD PARK EXTENSION sHIVAMOGGA-577201, Karnataka, 577201",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a99"
    },
    "Rank": 11512,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a9a"
    },
    "Rank": 11513,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, THOOTHUKUDI,GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, 3rd\nMile,Kamaraj Nagar,Thoothukudi., Tamil Nadu, 628008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a9b"
    },
    "Rank": 11514,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a9c"
    },
    "Rank": 11516,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jai Prakash District Government Hospital, ,Jai Prakash District Goverment Hospital, 1250, Link Road No.2 Tulsi Nagar, Shivaji Nagar, Bhopal,, Madhya Pradesh, 462001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a9d"
    },
    "Rank": 11517,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District hospital chitradurga, B D road beside DHO office Chitradurga, Karnataka, 577501",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a9e"
    },
    "Rank": 11519,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04a9f"
    },
    "Rank": 11522,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhaktivedanta Hospital and Research Institute, ,Bhaktivedanta Hospital and Research Institute,Srishti Complex, Bhaktivedanta Swami Marg, Mira Road E, Maharashtra, 401107",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04aa0"
    },
    "Rank": 11523,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04aa1"
    },
    "Rank": 11524,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ganga Medical Centre and Hospital,,313 Mettupalayam Road Coimbatore 641043, Tamil Nadu, 641043",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04aa2"
    },
    "Rank": 11525,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Down Town Hospital, G S Road, ,G S Road, Dispur, Guwahati-781006, Assam, 781006",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04aa3"
    },
    "Rank": 11527,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04aa4"
    },
    "Rank": 11529,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster Aadhar Hospital, ,RS NO 628, B WARD NEAR KMT WORKSHOP, SHASTRINAGAR, 416012. KOLHAPUR, Maharashtra, 416012",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04aa5"
    },
    "Rank": 11530,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04aa6"
    },
    "Rank": 11532,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04aa7"
    },
    "Rank": 11533,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04aa8"
    },
    "Rank": 11535,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04aa9"
    },
    "Rank": 11537,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ankura Hospital for Women and Children, ,Plot no 55-56, KPHB 7th Phase, JNTU-Hi Tech City road, Hyderabad, Telangana 500085, Telangana, 500085",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04aaa"
    },
    "Rank": 11539,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KAUVERY HOSPITAL, Karnataka,no.92, 1 A B, Konappana Agrahara, Electronic City, Bangalore, Karnataka, 560100",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04aab"
    },
    "Rank": 11540,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04aac"
    },
    "Rank": 11541,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04aad"
    },
    "Rank": 11542,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apex Hospital,,N7 2A 5D BLW Hydel Road Varanasi, Uttar Pradesh, 221004",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04aae"
    },
    "Rank": 11543,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04aaf"
    },
    "Rank": 11544,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (PALLIATIVE MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ab0"
    },
    "Rank": 11548,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ab1"
    },
    "Rank": 11549,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Children`s Hospital, ,Rainbow Childrens Hospital, Road No.2, Banjara Hills, Hyderabad, Telangana, 500034",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ab2"
    },
    "Rank": 11550,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "NEOTIA GETWEL HEALTHCARE CENTRE,\n,Uttorayon, Behind City Centre, Matigara, Siliguri, West Bengal, 734010",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ab3"
    },
    "Rank": 11551,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ab4"
    },
    "Rank": 11553,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "J.L.N. Main Hospital and Research Centre, ,Bhilai Steel Plant Sector-9, Bhilai, C.G., Chhattisgarh, 490009",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ab5"
    },
    "Rank": 11556,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Popular Medicare,POPULAR MEDICARE LTD N 10 60 A 2 BLW ROAD KAKARAMATTA VARANASI, Uttar Pradesh, 221106",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ab6"
    },
    "Rank": 11561,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Bhausaheb Hire Government Medical College,Sri Bhausaheb Hire Government Medical College,Dhule, Maharashtra, 424311",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ab7"
    },
    "Rank": 11562,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ab8"
    },
    "Rank": 11566,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ab9"
    },
    "Rank": 11568,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04aba"
    },
    "Rank": 11569,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04abb"
    },
    "Rank": 11570,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "B.R. Singh Hospital and Centre for Medical Education and Research,,B.R.Singh Hospital Eastern Railway, Sealdah, Kolkata, West Bengal, 700014",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04abc"
    },
    "Rank": 11571,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Artemis Health Institute, ,Sector 51, Gurugram, Haryana -122001, Haryana, 122001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04abd"
    },
    "Rank": 11572,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indian Spinal Injuries Centre,,SECTOR-C, VASANT KUNJ, NEW DELHI-110070, Delhi (NCT), 110070",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04abe"
    },
    "Rank": 11573,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04abf"
    },
    "Rank": 11574,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ac0"
    },
    "Rank": 11575,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rural Development Trust Hospital, ,Kadiri Road,Bathalapalli, Anantapur District Andhra Pradesh, Andhra Pradesh, 515661",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ac1"
    },
    "Rank": 11576,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ac2"
    },
    "Rank": 11580,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ac3"
    },
    "Rank": 11581,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ac4"
    },
    "Rank": 11582,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ac5"
    },
    "Rank": 11584,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ac6"
    },
    "Rank": 11587,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ac7"
    },
    "Rank": 11591,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Radhakrishnan Govt. Medical College , ,Dr. Radhakrishnan Govt. Medical College Hamirpur HP, Himachal Pradesh, 177001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ac8"
    },
    "Rank": 11593,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ankura Hospital for Women and Children, ,Plot no 55-56, KPHB 7th Phase, JNTU-Hi Tech City road, Hyderabad, Telangana 500085, Telangana, 500085",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ac9"
    },
    "Rank": 11594,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE - KANNUR,GOVERNMENT MEDICAL COLLEGE\n- KANNUR, Kerala, 670503",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04aca"
    },
    "Rank": 11595,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VILLUPURAM MEDICAL COLLEGE, VILLUPURAM,GOVERNMENT VILLUPURAM MEDICAL COLLEGE VILLUPURAM  TRICHY TRUNK ROAD MUNDIYAMPAKKAM  VILLUPURAM DISTR, Tamil Nadu, 605601",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04acb"
    },
    "Rank": 11599,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04acc"
    },
    "Rank": 11600,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INSTITUTE OF MEDICAL SCIENCES,RAICHUR INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 584102",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04acd"
    },
    "Rank": 11608,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Healthworld Hospital, ,Healthworld Hospitals [A Unit of Parashmani Medical Centre Pvt.Ltd.], C-49,Commercial Area, Opp. ESI, West Bengal, 713216",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ace"
    },
    "Rank": 11611,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04acf"
    },
    "Rank": 11613,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fernandez Hospital, ,4-1-1230, Bogulkunta, Hyderabad, Telangana-500001, Telangana, 500001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ad0"
    },
    "Rank": 11614,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ad1"
    },
    "Rank": 11615,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sanjay Gandhi Memorial Hospital, , ,Sanjay Gandhi Memorial Hospital\nS-Block, Near Bus Terminal, Mangolpuri, North West, Delhi - 110083, Delhi (NCT), 110083",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ad2"
    },
    "Rank": 11617,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ad3"
    },
    "Rank": 11619,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ad4"
    },
    "Rank": 11620,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences, ,academics@kimshospitals.com, Telangana, 500003",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ad5"
    },
    "Rank": 11623,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ad6"
    },
    "Rank": 11624,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Gleneagles Hospital, ,58 Canal Circular Road Kolkata 700054, West Bengal, 700054",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ad7"
    },
    "Rank": 11626,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Comtrust Eye Hospital, ,Mini Bypass Road, Puthiyara, Kozhikode., Kerala, 673004",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ad8"
    },
    "Rank": 11631,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asian Institute of Medical Sciences,,Asian Institute of Medical Sciences, Badhkal Flyover Road,Sector-21A, Faridabad, Haryana-121001, Haryana, 121001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ad9"
    },
    "Rank": 11633,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ada"
    },
    "Rank": 11635,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04adb"
    },
    "Rank": 11636,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dharwad,dsdharwad at gmail dot com, Karnataka, 580001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04adc"
    },
    "Rank": 11637,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Metrounited Healthcare LLP, Karnataka,Shivamurthy Circle Savalanga Road\nShivamogga, Karnataka, 577201",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04add"
    },
    "Rank": 11638,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Malabar Institute of Medical Sciences, ,Govindapuram P.O., Mini Bypass Road, Kozhikode, Kerala, 673016",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ade"
    },
    "Rank": 11640,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sree Gokulam Medical College Research Foundation, ,Venjaramoodu. P.O Thiruvananthapuram, Kerala, 695607",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04adf"
    },
    "Rank": 11642,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ae0"
    },
    "Rank": 11646,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ae1"
    },
    "Rank": 11647,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SETH V. C. GANDHI and M. A. VORA MUNICIPAL GENERAL HOSPITAL (RAJAWADI HOSPITAL),\nMaharashtra,hospital.rajawadi@gmail.com, Maharashtra, 400077",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ae2"
    },
    "Rank": 11648,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ae3"
    },
    "Rank": 11649,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ae4"
    },
    "Rank": 11650,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jalgaon,Jilha Peth, Government Hospital Campus, Jalgaon, Maharashtra, 425001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ae5"
    },
    "Rank": 11653,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Down Town Hospital, G S Road, ,G S Road, Dispur, Guwahati-781006, Assam, 781006",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ae6"
    },
    "Rank": 11654,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ae7"
    },
    "Rank": 11656,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kamla Nehru Memorial Hospital, 1, ,1, HASHIMPUR ROAD, PRAYAGRAJ, Uttar Pradesh, 211002",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ae8"
    },
    "Rank": 11658,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ae9"
    },
    "Rank": 11659,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Kamakshi Memorial Hospital, (Dr. Kamakshi Institute of Medical Sciences and Research) ,No.1,Radial Road , Pallikaranai, Chennai, Tamil Nadu, 600100",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04aea"
    },
    "Rank": 11661,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04aeb"
    },
    "Rank": 11662,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVERNMENT MEDICAL COLLEGE,KANYAKUMARI GOVERNMENT MEDICAL COLLEGE, Tamil Nadu, 629201",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04aec"
    },
    "Rank": 11663,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04aed"
    },
    "Rank": 11665,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04aee"
    },
    "Rank": 11667,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04aef"
    },
    "Rank": 11669,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Edappal Hospital, ,Edappal Hospitals Pvt LKtd, Pattambi Road, Edappal, Malappuram, Kerala, 679576",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04af0"
    },
    "Rank": 11670,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Homi Bhabha Cancer Hospital, Uttar Pradesh,Homi Bhabha Cancer Hospital , Old Loco Colony, Shivpurwa, Lahartara, Varanasi-221002, Uttar Pradesh, Uttar Pradesh, 221002",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04af1"
    },
    "Rank": 11672,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SNR District Hospital,,SNR DISTRICT HOSPITAL, KOLAR KARNATAKA-563101,\nKarnataka, 563101",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04af2"
    },
    "Rank": 11673,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "J.L.N. Main Hospital and Research Centre, ,Bhilai Steel Plant Sector-9, Bhilai, C.G., Chhattisgarh, 490009",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04af3"
    },
    "Rank": 11674,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kokilaben Dhirubhai Ambani Hospital and Medical Research Institute,,Achyutrao Patwardhan Marg Andheri West Mumbai Maharashtra, Maharashtra, 400053",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04af4"
    },
    "Rank": 11677,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04af5"
    },
    "Rank": 11679,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Durgabai Deshmukh Hospital, ,OSMANIA UNIVERSITY ROAD, VIDYANAGAR, HYDERABAD, Telangana, 500044",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04af6"
    },
    "Rank": 11683,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04af7"
    },
    "Rank": 11686,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04af8"
    },
    "Rank": 11689,
    "Allotted Quota": "All India",
    "Allotted Institute": "Post Graduate Institute of Medical Education & Research and Capital Hospital ( PGIMER & CH), Odisha,Post Graduate Institute of Medical Education & Research and Capital Hospital, Bhubaneswar,Udyan Marg, Odisha, 751001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04af9"
    },
    "Rank": 11690,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04afa"
    },
    "Rank": 11692,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04afb"
    },
    "Rank": 11694,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04afc"
    },
    "Rank": 11696,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Unique Hospital Multispeciality and Research Institute, Surat,\nGujarat,Unique Hospital Multispeciality and research Institute Run by Surat Life care pvt ltd,\nNear Kiran, Gujarat, 395017",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04afd"
    },
    "Rank": 11698,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04afe"
    },
    "Rank": 11701,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04aff"
    },
    "Rank": 11702,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Family Hospital,,Okhla Road, New Delhi, Delhi (NCT), 110025",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b00"
    },
    "Rank": 11705,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Batra Hospital and Medical Research Centre, ,1, Tughlakabad institutional Area, Mehrauli Badarpur Road, New Delhi, Delhi (NCT), 110062",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b01"
    },
    "Rank": 11706,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b02"
    },
    "Rank": 11711,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Woodlands Multispeciality Hospital Limited,\n,8-5 ALIPORE ROAD KOLKATA, West Bengal, 700027",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b03"
    },
    "Rank": 11712,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "NEMCARE Hospital,,G.S.Road, Bhangagarh, Guwahati, Assam, 781005",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b04"
    },
    "Rank": 11713,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b05"
    },
    "Rank": 11716,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b06"
    },
    "Rank": 11717,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhaktivedanta Hospital and Research Institute, ,Bhaktivedanta Hospital and Research Institute,Srishti Complex, Bhaktivedanta Swami Marg, Mira Road E, Maharashtra, 401107",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b07"
    },
    "Rank": 11718,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Institute of Mental Health, Govt. Mental Hospital, ,Circular Road, Amritsar, Punjab, 143001",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b08"
    },
    "Rank": 11719,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, ,1,2, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b09"
    },
    "Rank": 11726,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Baba Saheb Ambedkar Hospital, ,SEC 6 ROHINI DELHI, Delhi (NCT), 110085",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b0a"
    },
    "Rank": 11727,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b0b"
    },
    "Rank": 11728,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b0c"
    },
    "Rank": 11736,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b0d"
    },
    "Rank": 11737,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "HEALTH CITY HOSPITAL , Assam\n,UDAY NAGAR KOINADHARA KHANAPARA GUWAHATI KAMRUP M, Assam, 781022",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b0e"
    },
    "Rank": 11740,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aakash Healthcare Super Specialty Hospital, Delhi,HOSPITAL PLOT, ROAD NO- 201,SECTOR 3 DWARKA, Delhi (NCT), 110075",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b0f"
    },
    "Rank": 11742,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b10"
    },
    "Rank": 11743,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital,,DNB office, 3rd Floor Opp Library  Apollo Hospitals, Jubilee Hills, Hyderabad, Telangana, 500033",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b11"
    },
    "Rank": 11744,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Venkateshwar Hospital, ,Sector- 18 A, Dwarka, New Delhi, Delhi (NCT), 110075",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b12"
    },
    "Rank": 11746,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b13"
    },
    "Rank": 11751,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lakeshore Hospital and Res. ,Maradu, Nettoor P O, Kochi, Kerala, 682040",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b14"
    },
    "Rank": 11752,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b15"
    },
    "Rank": 11755,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b16"
    },
    "Rank": 11756,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b17"
    },
    "Rank": 11757,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Gleneagles Hospital, ,58 Canal Circular Road Kolkata 700054, West Bengal, 700054",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b18"
    },
    "Rank": 11758,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "B.R. Singh Hospital and Centre for Medical Education and Research,,B.R.Singh Hospital Eastern Railway, Sealdah, Kolkata, West Bengal, 700014",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b19"
    },
    "Rank": 11759,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, THOOTHUKUDI,GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, 3rd\nMile,Kamaraj Nagar,Thoothukudi., Tamil Nadu, 628008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b1a"
    },
    "Rank": 11760,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "C.S.I. Holdsworth Memorial Hospital, ,P.B.No. 38, Sawday Road, Mandi Mohalla, Mysuru, Karnataka, 570001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b1b"
    },
    "Rank": 11762,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lotus Children`s Hospital,,6-2-29,Lakdikapul, Hyderabad - 500004, Telangana, 500004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b1c"
    },
    "Rank": 11765,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Arvind Eye Hospital and PG Instt.of Ophthalmology, ,No.1, S.N.High Road, Tirunelveli, Tamil Nadu, 627001",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b1d"
    },
    "Rank": 11766,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b1e"
    },
    "Rank": 11767,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Hospital For Women and Children, Telangana,Saraswathi Nagar Colony, Mansoorabad Village, LB Nagar, Telangana, 500070",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b1f"
    },
    "Rank": 11768,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Family Hospital,,Okhla Road, New Delhi, Delhi (NCT), 110025",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b20"
    },
    "Rank": 11769,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b21"
    },
    "Rank": 11770,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Vasantrao Naik Govt Medical College Yavatmal,Shri Vasantrao Naik Govt Medical College Yavatmal, Maharashtra, 445001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b22"
    },
    "Rank": 11771,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b23"
    },
    "Rank": 11773,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b24"
    },
    "Rank": 11774,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b25"
    },
    "Rank": 11775,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b26"
    },
    "Rank": 11777,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital,,GOVERNMENT HEADQUARTERS HOSPITAL AND DNB POST GRADUATE TEACHING INSTITUTE,EVN ROAD, ERODE, Tamil Nadu, 638009",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b27"
    },
    "Rank": 11778,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Noble Hospital, ,Magarpatta City Road, Hadpsar, Pune, Maharashtra, 411028",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b28"
    },
    "Rank": 11779,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "DIP.IN GYNAE. and OBST.",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b29"
    },
    "Rank": 11780,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MAXCARE Hospital, ,6-1-236, Near Kuda Office Line Sai Nagar, Opposite Ashoka Hotel, Hanamkonda, Telangana 506001, Telangana, 506001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b2a"
    },
    "Rank": 11781,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sahyadri Super Specialty Hospital,,Plot No.30C, Erandawane, Karve Road, Pune, Maharashtra, 411004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b2b"
    },
    "Rank": 11784,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ankura hospital for Women and Children , Telangana ,Plot No. 10,11,12,\nSurvey nos:9/1/J, Opp Metro Pillar No.1643, Saroornagar Stadium Line, LB Nagar, , Telangana, 500035",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b2c"
    },
    "Rank": 11786,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b2d"
    },
    "Rank": 11789,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Healthworld Hospital, ,Healthworld Hospitals [A Unit of Parashmani Medical Centre Pvt.Ltd.], C-49,Commercial Area, Opp. ESI, West Bengal, 713216",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b2e"
    },
    "Rank": 11790,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b2f"
    },
    "Rank": 11793,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b30"
    },
    "Rank": 11794,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b31"
    },
    "Rank": 11799,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b32"
    },
    "Rank": 11802,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b33"
    },
    "Rank": 11803,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b34"
    },
    "Rank": 11804,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ganga Medical Centre and Hospital,,313 Mettupalayam Road Coimbatore 641043, Tamil Nadu, 641043",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b35"
    },
    "Rank": 11805,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Central Hospital, ,CENTRAL HOSPITAL NF RAILWAY\nMALIGAON GUWAHATI, Assam, 781011",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b36"
    },
    "Rank": 11806,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "NEOTIA GETWEL HEALTHCARE CENTRE,\n,Uttorayon, Behind City Centre, Matigara, Siliguri, West Bengal, 734010",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b37"
    },
    "Rank": 11807,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, THOOTHUKUDI,GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, 3rd\nMile,Kamaraj Nagar,Thoothukudi., Tamil Nadu, 628008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b38"
    },
    "Rank": 11809,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. Sports Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b39"
    },
    "Rank": 11810,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b3a"
    },
    "Rank": 11812,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Balaji Action Medical Institute,,FC34 A4 PASCHIM VIHAR NEW DELHI, Delhi (NCT), 110063",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b3b"
    },
    "Rank": 11813,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "PARAMITHA CHILDRENS HOSPITAL ,\nTelangana,PLOT NO 37, NCL ENCLAVE, PET BASHEERABAD, KOMPALLY, MEDCHAL, 500067, Telangana, 500067",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b3c"
    },
    "Rank": 11817,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b3d"
    },
    "Rank": 11818,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b3e"
    },
    "Rank": 11819,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Escorts Hospital,,Fortis escorts hospital ,neelam bata road, faridabad, Haryana, 121002",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b3f"
    },
    "Rank": 11820,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b40"
    },
    "Rank": 11821,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT DHARMAPURI MEDICAL COLLEGE, DHARMAPURI, TAMIL NADU,GOVERNMENT DHARMAPURI MEDICAL COLLEGE, NETHAJI BYE PASS ROAD, DHARMAPURI, TAMIL NADU, Tamil Nadu, 636701",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b41"
    },
    "Rank": 11822,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aravind Eye Hospital, ,Aravind Eye Hospital\nPoonamallee High Rd, opposite Saveetha Dental College, Noombal, Chennai, Tamil Nadu, 600077",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b42"
    },
    "Rank": 11823,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b43"
    },
    "Rank": 11824,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pragna Childrens Hospital, ,6 3 347 22 B 1 DWARAKAPURI SAIBABA TEMPLE LANE PANJAGUTTA HYDERABAD, Telangana, 500082",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b44"
    },
    "Rank": 11825,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b45"
    },
    "Rank": 11826,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deen Dayal Upadhyay Hospital, ,DDU HOSPITAL, SHAHEED MANGAL PANDEY MARG, NANAK PURA, HARI NAGAR, NEW DELHI, DELHI-110064, Delhi (NCT), 110064",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b46"
    },
    "Rank": 11828,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sakra World Hospital, (A Unit of Takshasila Hospitals Operating Private Limited)\n,LOVEKESH\\DOT\\PHASU\\AT\\SAKRAWORLDHOSPITAL\\DOT\\COM, Karnataka, 560103",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b47"
    },
    "Rank": 11831,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KIMS Hospital , ,KIMS Hospitals, 69/213-k-9, Joharapuram Road, Joharapuram, Kurnool, Andhra Pradesh, 518002",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b48"
    },
    "Rank": 11832,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b49"
    },
    "Rank": 11834,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medical Trust Hospital, ,M.G. Road Pallimukku\nCochin, Kerala, 682016",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b4a"
    },
    "Rank": 11836,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b4b"
    },
    "Rank": 11838,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b4c"
    },
    "Rank": 11839,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital, ,GMERS Medical College and General hospital,Old TB Campus, gotri road, Vadodara, Gujarat, 390021",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b4d"
    },
    "Rank": 11842,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "HEALTH CITY HOSPITAL , Assam\n,UDAY NAGAR KOINADHARA KHANAPARA GUWAHATI KAMRUP M, Assam, 781022",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b4e"
    },
    "Rank": 11845,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vivekananda Polyclinic and Institute of Medical Sciences, ,Vivekananda Polyclinic and Institute of Medical Sciences, Vivekananda Puram, Lucknow, Uttar Pradesh, 226007",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b4f"
    },
    "Rank": 11846,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Surya Mother and Child Superspeciality Hospital, ,Surya Mother and Child Super Speciality Hospital     Sr. No 8 Devi Yash , Near Octroi Post Wakad, Pu, Maharashtra, 411057",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b50"
    },
    "Rank": 11852,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b51"
    },
    "Rank": 11854,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Gondia,Nehru Chowk, KTS General Hospital Campus, Govt. Medical College, Gondia. 441601. Maharashtra., Maharashtra, 441601",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b52"
    },
    "Rank": 11856,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b53"
    },
    "Rank": 11860,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b54"
    },
    "Rank": 11861,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b55"
    },
    "Rank": 11862,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b56"
    },
    "Rank": 11864,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. L H Hiranandani Hospital, ,HILL SIDE AVENUE, HIRANANDANI GARDENS, POWAI-400076, Maharashtra, 400076",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b57"
    },
    "Rank": 11867,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sahyadri Super Speciality Hospital, Maharashtra,Survey No.-185A Shastri Nagar Near MSEB office Yerawada Nagar Road, Pune-411006, Maharashtra, 411006",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b58"
    },
    "Rank": 11869,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE,ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, Bihar, 823001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b59"
    },
    "Rank": 11870,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Kamakshi Memorial Hospital, (Dr. Kamakshi Institute of Medical Sciences and Research) ,No.1,Radial Road , Pallikaranai, Chennai, Tamil Nadu, 600100",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b5a"
    },
    "Rank": 11871,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sankara Nethralaya, (A Unit of Medical Research Foundation) ,147\\1 MUKUNDAPUR E M BYPASS KOLKATA 700099, West Bengal, 700099",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b5b"
    },
    "Rank": 11875,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b5c"
    },
    "Rank": 11876,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b5d"
    },
    "Rank": 11877,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aravind Eye Hospital,,64 Sankari Main Road, Nethimedu, Salem, Tamil Nadu, 636002",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b5e"
    },
    "Rank": 11878,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b5f"
    },
    "Rank": 11879,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b60"
    },
    "Rank": 11881,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b61"
    },
    "Rank": 11882,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b62"
    },
    "Rank": 11883,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S. K. S. Hospital, ,NO.23,SKS Hospital Road, Alagapuram,Salem, Tamil Nadu, 636004",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b63"
    },
    "Rank": 11884,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b64"
    },
    "Rank": 11885,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pushpawati Singhania Hospital, ,Pushpawati Singhania Hospital & Research Institute Press Enclave Marg, Sheikh Sarai, Phase-2, New D, Delhi (NCT), 110017",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b65"
    },
    "Rank": 11889,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Venkateshwar Hospital, ,Sector- 18 A, Dwarka, New Delhi, Delhi (NCT), 110075",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b66"
    },
    "Rank": 11891,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b67"
    },
    "Rank": 11894,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b68"
    },
    "Rank": 11895,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Smart Super Specialty Hospital, (Formerly Known as Saket City Hospital), ,Mandir Marg, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b69"
    },
    "Rank": 11898,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b6a"
    },
    "Rank": 11900,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b6b"
    },
    "Rank": 11902,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rural Development Trust Hospital, ,Kadiri Road,Bathalapalli, Anantapur District Andhra Pradesh, Andhra Pradesh, 515661",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b6c"
    },
    "Rank": 11904,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Spirit Hospital, ,Mahakali Caves Road Andheri East Mumbai, Maharashtra, 400093",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b6d"
    },
    "Rank": 11906,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b6e"
    },
    "Rank": 11907,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Jamshedpur,Mahatma Gandhi Memorial Medical College, Jamshedpur, Jharkhand, 831020",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b6f"
    },
    "Rank": 11912,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kalpana Chawla Govt Medical College, ,Model Town, Karnal, Haryana, 132001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b70"
    },
    "Rank": 11914,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr Shyama Prasad Mukherjee (Civil) Hospital, ,Park Road, Hazratganj, Lucknow, Uttar Pradesh, 226001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b71"
    },
    "Rank": 11915,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b72"
    },
    "Rank": 11916,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b73"
    },
    "Rank": 11918,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. Medical College (Maharani Hospital), Jagdalpur, Chhattisgarh,Late Baliram Kashyap memorial Govt. Medical College, Dimrapal, Jagdalpur Dist-Bastar, Chhattisgarh, 494001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b74"
    },
    "Rank": 11920,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VELLORE MEDICAL COLLEGE,GOVERNMENT VELLORE MEDICAL COLLEGE, Tamil Nadu, 632011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b75"
    },
    "Rank": 11921,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Associated Hospital Govt. Medical College, ,Makeshift Campus, Near Bus Stand, Doda City, Doda, Jammu and Kashmir, Jammu And Kashmir, 182202",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b76"
    },
    "Rank": 11922,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b77"
    },
    "Rank": 11923,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b78"
    },
    "Rank": 11928,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b79"
    },
    "Rank": 11929,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b7a"
    },
    "Rank": 11937,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bharat Ratna Late Shri Atal Bihari Vajpayee Memorial Medical College, Chhattisgarh,BHARAT RATNA LATE SHRI ATAL BIHARI VAJPAYEE MEMEORIAL MEDICAL COLLEGE, RAJNANDGAON. CG, Chhattisgarh, 491441",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b7b"
    },
    "Rank": 11938,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "J.L.N. Main Hospital and Research Centre, ,Bhilai Steel Plant Sector-9, Bhilai, C.G., Chhattisgarh, 490009",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b7c"
    },
    "Rank": 11940,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b7d"
    },
    "Rank": 11942,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b7e"
    },
    "Rank": 11943,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b7f"
    },
    "Rank": 11944,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Homi Bhabha Cancer Hospital, Uttar Pradesh,Homi Bhabha Cancer Hospital , Old Loco Colony, Shivpurwa, Lahartara, Varanasi-221002, Uttar Pradesh, Uttar Pradesh, 221002",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b80"
    },
    "Rank": 11945,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College,Tumuki, Bihaguri, Tezpur, Sonitpur, Assam, Assam, 784010",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b81"
    },
    "Rank": 11947,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b82"
    },
    "Rank": 11948,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b83"
    },
    "Rank": 11949,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital Faridkot,\nCivil Hospital Faridkot, Punjab,Railway Road Opposite to DC Office, Punjab, 151203",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b84"
    },
    "Rank": 11950,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,Nalgonda X Roads, Malakpet, Hyderabad, Telangana, 500036",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b85"
    },
    "Rank": 11951,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b86"
    },
    "Rank": 11954,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Caritas Hospital, ,Thellakom P.O, Kerala, 686630",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b87"
    },
    "Rank": 11955,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.J. Somaiya Medical College,,SOMAIYA AYURVIHAR , EASTERN EXPRESS HIGHWAY, SION, MUMBAI 400022, Maharashtra, 400022",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b88"
    },
    "Rank": 11956,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "TIMES HOSPITAL, Assam,Old N.T. Road, Deurigaon Opposite NRL Petrol Pump, Mission Chariali, Ketekibari, Tezpur, Assam 78400, Assam, 784001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b89"
    },
    "Rank": 11962,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b8a"
    },
    "Rank": 11964,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b8b"
    },
    "Rank": 11966,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b8c"
    },
    "Rank": 11967,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b8d"
    },
    "Rank": 11969,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b8e"
    },
    "Rank": 11971,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b8f"
    },
    "Rank": 11972,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b90"
    },
    "Rank": 11973,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M R Bangur Hospital, ,241&249 Desh Pran Sashmal Road kolkata, West Bengal, 700033",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b91"
    },
    "Rank": 11974,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asian Institute of Medical Sciences,,Asian Institute of Medical Sciences, Badhkal Flyover Road,Sector-21A, Faridabad, Haryana-121001, Haryana, 121001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b92"
    },
    "Rank": 11976,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vivekananda Polyclinic and Institute of Medical Sciences, ,Vivekananda Polyclinic and Institute of Medical Sciences, Vivekananda Puram, Lucknow, Uttar Pradesh, 226007",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b93"
    },
    "Rank": 11980,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mothers Care Children Hospital and Research Centre, ,SAGOLBAND MOIRANG LEIRAK, IMPHAL WEST, Manipur, 795001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b94"
    },
    "Rank": 11981,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Motors Hospital, ,KHARANGAJHAR, TELCO COLONY JAMSHEDPUR, Jharkhand, 831003",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b95"
    },
    "Rank": 11982,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b96"
    },
    "Rank": 11984,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b97"
    },
    "Rank": 11985,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Gleneagles Hospital, ,58 Canal Circular Road Kolkata 700054, West Bengal, 700054",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b98"
    },
    "Rank": 11986,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Eastern Indira Gandhi Regional Institute of Health & Medical Sciences, Shillong,North Eastern Indira Gandhi Regional Institute of Health & Medical Sciences, Shillong, Meghalaya, 793018",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b99"
    },
    "Rank": 11987,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaypee Hospital,,sector 128, Noida, Uttar Pradesh, 201304",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b9a"
    },
    "Rank": 11989,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b9b"
    },
    "Rank": 11990,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhaktivedanta Hospital and Research Institute, ,Bhaktivedanta Hospital and Research Institute,Srishti Complex, Bhaktivedanta Swami Marg, Mira Road E, Maharashtra, 401107",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b9c"
    },
    "Rank": 11991,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b9d"
    },
    "Rank": 11993,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b9e"
    },
    "Rank": 11994,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SETH V. C. GANDHI and M. A. VORA MUNICIPAL GENERAL HOSPITAL (RAJAWADI HOSPITAL),\nMaharashtra,hospital.rajawadi@gmail.com, Maharashtra, 400077",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04b9f"
    },
    "Rank": 11996,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences, ,academics@kimshospitals.com, Telangana, 500003",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ba0"
    },
    "Rank": 11997,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deen Dayal Upadhyay Hospital, ,DDU HOSPITAL, SHAHEED MANGAL PANDEY MARG, NANAK PURA, HARI NAGAR, NEW DELHI, DELHI-110064, Delhi (NCT), 110064",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ba1"
    },
    "Rank": 11998,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ba2"
    },
    "Rank": 11999,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ba3"
    },
    "Rank": 12000,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ba4"
    },
    "Rank": 12001,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ba5"
    },
    "Rank": 12002,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ba6"
    },
    "Rank": 12003,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ba7"
    },
    "Rank": 12005,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dharwad,dsdharwad at gmail dot com, Karnataka, 580001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ba8"
    },
    "Rank": 12006,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Head Quarters Hospital,,District Hospital, Sangareddy Telangana State, Telangana, 502001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ba9"
    },
    "Rank": 12008,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04baa"
    },
    "Rank": 12009,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bab"
    },
    "Rank": 12011,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bac"
    },
    "Rank": 12012,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Arvind Eye Hospital and PG Instt.of Ophthalmology, ,No.1, S.N.High Road, Tirunelveli, Tamil Nadu, 627001",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bad"
    },
    "Rank": 12013,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bae"
    },
    "Rank": 12014,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jehangir Hospital, ,32 Sasoon Road Pune, Maharashtra, 411001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04baf"
    },
    "Rank": 12015,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bb0"
    },
    "Rank": 12017,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bb1"
    },
    "Rank": 12018,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kokilaben Dhirubhai Ambani Hospital and Medical Research Institute,,Achyutrao Patwardhan Marg Andheri West Mumbai Maharashtra, Maharashtra, 400053",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bb2"
    },
    "Rank": 12020,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bb3"
    },
    "Rank": 12021,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jupiter Lifeline Hospital,,Jupiter Lifeline Hospital, Sr No 142/12, And 143/1/4/5, Near Prathamesh Park, Baner, Pune, Maharasht, Maharashtra, 411045",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bb4"
    },
    "Rank": 12025,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta The Medicity, ,Medanta The Medicity Sector 38 Gurgoan, Haryana, 122001",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bb5"
    },
    "Rank": 12026,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jupiter Lifeline Hospital,,Jupiter Lifeline Hospital, Sr No 142/12, And 143/1/4/5, Near Prathamesh Park, Baner, Pune, Maharasht, Maharashtra, 411045",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bb6"
    },
    "Rank": 12027,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bb7"
    },
    "Rank": 12030,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bb8"
    },
    "Rank": 12031,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Isabels Hospital, ,49, Oliver Road, Mylapore, Chennai., Tamil Nadu, 600004",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bb9"
    },
    "Rank": 12032,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VILLUPURAM MEDICAL COLLEGE, VILLUPURAM,GOVERNMENT VILLUPURAM MEDICAL COLLEGE VILLUPURAM  TRICHY TRUNK ROAD MUNDIYAMPAKKAM  VILLUPURAM DISTR, Tamil Nadu, 605601",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bba"
    },
    "Rank": 12037,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bbb"
    },
    "Rank": 12038,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bbc"
    },
    "Rank": 12040,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bbd"
    },
    "Rank": 12041,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bbe"
    },
    "Rank": 12042,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bbf"
    },
    "Rank": 12043,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bokaro General Hospital, ,SECTOR - 4 BOKARO STEEL CITY\nDIST - BOKARO, Jharkhand, 827004",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bc0"
    },
    "Rank": 12044,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bc1"
    },
    "Rank": 12047,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bc2"
    },
    "Rank": 12048,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital,,NO.5 RAILWAY ROAD, KANCHIPURAM., Tamil Nadu, 631501",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bc3"
    },
    "Rank": 12050,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bc4"
    },
    "Rank": 12051,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bc5"
    },
    "Rank": 12052,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bc6"
    },
    "Rank": 12053,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bc7"
    },
    "Rank": 12057,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees' State Insurance Corporation Medical College & Hospital, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD GULBARGA, Karnataka, 585106",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bc8"
    },
    "Rank": 12058,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bc9"
    },
    "Rank": 12059,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bca"
    },
    "Rank": 12060,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rural Development Trust Hospital, ,Kadiri Road,Bathalapalli, Anantapur District Andhra Pradesh, Andhra Pradesh, 515661",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bcb"
    },
    "Rank": 12063,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Punjab Institute of Medical Scineces ,Punjab Institute of Medical Sciences Garha Road Jalandhar, Punjab, 144006",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bcc"
    },
    "Rank": 12064,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bcd"
    },
    "Rank": 12066,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.E.M. Hospital, ,489, SARDAR MOODLIAR ROAD, RASTA PETH, PUNE 411011,\nMaharashtra, 411011",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bce"
    },
    "Rank": 12069,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bcf"
    },
    "Rank": 12070,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Calcutta Medical Research Institute, ,The Calcutta Medical Research Institute 7 by 2 Diamond Harbour Road Kolkata 700027, West Bengal, 700027",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bd0"
    },
    "Rank": 12072,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aarthi Hospital, Tamil Nadu,DOOR NO 60 SANTHAIPETTAI ROAD KOVILPATTI KOVILPATTI TALUK THOOTHUKUDI DISTRICT, Tamil Nadu, 628502",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bd1"
    },
    "Rank": 12074,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bd2"
    },
    "Rank": 12076,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bd3"
    },
    "Rank": 12079,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bd4"
    },
    "Rank": 12083,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bd5"
    },
    "Rank": 12085,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bd6"
    },
    "Rank": 12086,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kasturba Hospital, Daryaganj,Delhi,Daryaganj,Delhi",
    "Course": "DIP.IN GYNAE. and OBST.",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bd7"
    },
    "Rank": 12088,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Little Flower Hospital and Research Centre,,Post Box 23, Angamaly, Ernakulam, Kerala, Kerala, 683572",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bd8"
    },
    "Rank": 12090,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ramkrishna Care Hospital, ,AUROBINDO ENCLAVE, PACHPEDI NAKA,DAHAMTARI ROAD, RAIPUR, Chhattisgarh, 492001",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bd9"
    },
    "Rank": 12094,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lala Harbhagwan Das Memorial and Dr Prem Hospital, ,BISHAN SWAROOP COLONY, OPP. BUS STAND, Haryana, 132103",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bda"
    },
    "Rank": 12098,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BharatRatna Dr. Babasaheb Ambedkar Municipal General Hospital, Kandivali (West), Maharashtra,Parekh Nagar, SV Road, Opp. Kandivali Police station, Kandivali West, Mumbai, Maharashtra, 400067",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bdb"
    },
    "Rank": 12100,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bdc"
    },
    "Rank": 12106,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bdd"
    },
    "Rank": 12108,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bde"
    },
    "Rank": 12109,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bdf"
    },
    "Rank": 12111,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College, ,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04be0"
    },
    "Rank": 12114,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04be1"
    },
    "Rank": 12116,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Edappal Hospital, ,Edappal Hospitals Pvt LKtd, Pattambi Road, Edappal, Malappuram, Kerala, 679576",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04be2"
    },
    "Rank": 12117,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Malabar Institute of Medical Sciences, ,Govindapuram P.O., Mini Bypass Road, Kozhikode, Kerala, 673016",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04be3"
    },
    "Rank": 12118,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,HBT Medical College and Dr R N Cooper Municipal General Hospital, Bhaktivedanta Swami Rd, JVPD Schem, Maharashtra, 400056",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04be4"
    },
    "Rank": 12119,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04be5"
    },
    "Rank": 12121,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04be6"
    },
    "Rank": 12123,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Vasantrao Naik Govt Medical College Yavatmal,Shri Vasantrao Naik Govt Medical College Yavatmal, Maharashtra, 445001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04be7"
    },
    "Rank": 12124,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04be8"
    },
    "Rank": 12127,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES,CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, Chhattisgarh, 495001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04be9"
    },
    "Rank": 12132,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Christian Fellowship Hospital, ,Dindigul Palani Main Road Oddanchatram\nDindigul District, Tamil Nadu, 624619",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bea"
    },
    "Rank": 12133,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ranchi Institute of Nuero - Psychiatry and Allied Sciences,,RANCHI INSTITUTE of NEURO-PSYCHIATRY and ALLIED SCIENCES, KANKE, RANCHI, Jharkhand, 834006",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04beb"
    },
    "Rank": 12137,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bec"
    },
    "Rank": 12138,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bed"
    },
    "Rank": 12141,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bee"
    },
    "Rank": 12143,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bef"
    },
    "Rank": 12148,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bf0"
    },
    "Rank": 12150,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,4th T Block, Beside Rajiv Gandhi University of Health Sciences Jayanagar,, Karnataka, 560041",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bf1"
    },
    "Rank": 12151,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bf2"
    },
    "Rank": 12153,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K C General Hospital, ,No. 89, 5th Cross, Malleshwaram, Bangalore, Karnataka, 560003",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bf3"
    },
    "Rank": 12155,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bf4"
    },
    "Rank": 12156,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sant Parmanand Hospital, ,18 Sham Nath Marg Civil Lines, Delhi (NCT), 110054",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bf5"
    },
    "Rank": 12157,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lisie Medical Institution, ,Kochi, Kerala, Kerala, 682018",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bf6"
    },
    "Rank": 12158,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "JOSHI HOSPITAL,\nPunjab,JOSHI HOSPITAL KAPURTHALA CHOWK JALANDHAR PUNJAB 144001,\nPunjab, 144001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bf7"
    },
    "Rank": 12161,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bf8"
    },
    "Rank": 12162,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bf9"
    },
    "Rank": 12163,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rani Hospital, ,INFRONT OF GOVERNOR HOUSE BOOTY ROAD RANCHI, Jharkhand, 834001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bfa"
    },
    "Rank": 12164,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bfb"
    },
    "Rank": 12165,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bfc"
    },
    "Rank": 12167,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agarsen Hospital, ,., Delhi (NCT), 110026",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bfd"
    },
    "Rank": 12168,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sarvodaya Hospital and Research Centre, ,YMCA road Sector 08\nFaridabad, Haryana, 121006",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bfe"
    },
    "Rank": 12169,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Head Quarters Hospital,,H NO 3 3 141 MNCL X ROADS KARIMNAGAR KARIMNAGAR DISTRICT, Telangana, 505001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04bff"
    },
    "Rank": 12174,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c00"
    },
    "Rank": 12175,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c01"
    },
    "Rank": 12176,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c02"
    },
    "Rank": 12177,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. L H Hiranandani Hospital, ,HILL SIDE AVENUE, HIRANANDANI GARDENS, POWAI-400076, Maharashtra, 400076",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c03"
    },
    "Rank": 12178,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "AIG Hospital,Door No: 1 66 AIG 2 to 5 Mindspace Road  Gachibowli Hyderabad\nTelangana 500032",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c04"
    },
    "Rank": 12179,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c05"
    },
    "Rank": 12180,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jehangir Hospital, ,32 Sasoon Road Pune, Maharashtra, 411001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c06"
    },
    "Rank": 12181,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, THOOTHUKUDI,GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, 3rd\nMile,Kamaraj Nagar,Thoothukudi., Tamil Nadu, 628008",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c07"
    },
    "Rank": 12183,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c08"
    },
    "Rank": 12184,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c09"
    },
    "Rank": 12185,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c0a"
    },
    "Rank": 12187,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital, ,GOVERNMENT MEDICAL COLLEGE HOSPITAL THIRCHI ROAD, DINDIGUL -624 001, TAMIL NADU., Tamil Nadu, 624001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c0b"
    },
    "Rank": 12192,
    "Allotted Quota": "All India",
    "Allotted Institute": "Homi Bhabha Cancer Hospital, Varanasi,Homi Bhabha Cancer Hospital , Old Loco Colony, Shivpurwa, Lahartara, Varanasi-221002, Uttar Pradesh, Uttar Pradesh, 221002",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c0c"
    },
    "Rank": 12193,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sahyadri Super Speciality Hospital, Maharashtra,Survey No.-185A Shastri Nagar Near MSEB office Yerawada Nagar Road, Pune-411006, Maharashtra, 411006",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c0d"
    },
    "Rank": 12201,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c0e"
    },
    "Rank": 12202,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MALABAR INSTITUTE OF MEDICAL SCIENCES Ltd ,Chala East, Chala, Kannur, Kerala, 670621",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c0f"
    },
    "Rank": 12203,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE - KANNUR,GOVERNMENT MEDICAL COLLEGE\n- KANNUR, Kerala, 670503",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c10"
    },
    "Rank": 12206,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Parsik Hill Road, ,APOLLO HOSPITAL PARSIK HILL ROAD ROAD NO 13 SECTOR 23 CBD BELAPUR NAVI MUMBAI, Maharashtra, 400614",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c11"
    },
    "Rank": 12207,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c12"
    },
    "Rank": 12209,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c13"
    },
    "Rank": 12210,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c14"
    },
    "Rank": 12211,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College,Tumuki, Bihaguri, Tezpur, Sonitpur, Assam, Assam, 784010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c15"
    },
    "Rank": 12213,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c16"
    },
    "Rank": 12215,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K C General Hospital, ,No. 89, 5th Cross, Malleshwaram, Bangalore, Karnataka, 560003",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c17"
    },
    "Rank": 12217,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c18"
    },
    "Rank": 12218,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c19"
    },
    "Rank": 12219,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Civil Hospital, ,Medical Superintendent Office, Civil Hospital, Dawrpui, Aizawl, Mizoram, 796001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c1a"
    },
    "Rank": 12221,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c1b"
    },
    "Rank": 12227,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Escorts Hospital,,Fortis escorts hospital ,neelam bata road, faridabad, Haryana, 121002",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c1c"
    },
    "Rank": 12228,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VILLUPURAM MEDICAL COLLEGE, VILLUPURAM,GOVERNMENT VILLUPURAM MEDICAL COLLEGE VILLUPURAM  TRICHY TRUNK ROAD MUNDIYAMPAKKAM  VILLUPURAM DISTR, Tamil Nadu, 605601",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c1d"
    },
    "Rank": 12229,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c1e"
    },
    "Rank": 12231,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "JLNM Hospital, ,JLNM Hospital Rainawari Srinagar, Jammu And Kashmir, 190003",
    "Course": "(NBEMS) DERMATOLOGY and VENEREOLOGY and LEPROSY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c1f"
    },
    "Rank": 12232,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Rangarajan Memorial Hospital, (Sundaram Medical Foundation) ,Sundaram Medical Foundation, Shanthi Colony, 4th Avenue, Anna Nagar, Chennai, Tamil Nadu, 600040",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c20"
    },
    "Rank": 12235,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Thiruvannamalai Medical College, Thiruvannamalai, Tamil Nadu,DISTRICT COLLECTORATE  MASTER PLAN COMPLEX, VENGIKKAL THIRUVANNAMALAI,\nTamil Nadu, 600604",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c21"
    },
    "Rank": 12236,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c22"
    },
    "Rank": 12237,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sree Gokulam Medical College Research Foundation, ,Venjaramoodu. P.O Thiruvananthapuram, Kerala, 695607",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c23"
    },
    "Rank": 12238,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Choithram Hospital and Research Centre,Manika Bagh Road Indore, Madhya Pradesh, 452014",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c24"
    },
    "Rank": 12241,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c25"
    },
    "Rank": 12242,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c26"
    },
    "Rank": 12243,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c27"
    },
    "Rank": 12246,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c28"
    },
    "Rank": 12250,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Thiruvannamalai Medical College, Thiruvannamalai, Tamil Nadu,DISTRICT COLLECTORATE  MASTER PLAN COMPLEX, VENGIKKAL THIRUVANNAMALAI,\nTamil Nadu, 600604",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c29"
    },
    "Rank": 12252,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c2a"
    },
    "Rank": 12255,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sankara Eye Hospital, ,No.1, Third Cross Street, Sri Sankara Nagar, Pammal, Chennai, Tamil Nadu, 600075",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c2b"
    },
    "Rank": 12256,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c2c"
    },
    "Rank": 12257,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kanchi Kamakoti Childs Trust Hospital, ,12 A, NAGESWARA ROAD, NUNGAMBAKKAM, CHENNAI., Tamil Nadu, 600034",
    "Course": "(NBEMS) Paediatric Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c2d"
    },
    "Rank": 12258,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c2e"
    },
    "Rank": 12261,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c2f"
    },
    "Rank": 12262,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital,,GMERS Medical College and Hospital Campus, Dharpur, Patan-Unjha State Highway, Patan (North Gujarat,, Gujarat, 384265",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c30"
    },
    "Rank": 12263,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c31"
    },
    "Rank": 12266,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c32"
    },
    "Rank": 12269,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c33"
    },
    "Rank": 12270,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Hospital,,JANGLAT MANDI ANANTNAG, JAMMU AND KASHMIR 192101, Jammu And Kashmir, 192101",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c34"
    },
    "Rank": 12274,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Autonomous State Medical college Society and S.N.M District Hospital, ,DAULATTAPUR JALESAR ROAD NARKHI FIROZABAD, Uttar Pradesh, 283203",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c35"
    },
    "Rank": 12275,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sarvodaya Hospital and Research Centre, ,YMCA road Sector 08\nFaridabad, Haryana, 121006",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c36"
    },
    "Rank": 12276,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c37"
    },
    "Rank": 12277,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c38"
    },
    "Rank": 12281,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shaheed Hasan Khan Mewati Government Medical College , ,Shaheed Hasan Khan Mewati Government Medical College Nalhar Nuh, Haryana, 122107",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c39"
    },
    "Rank": 12282,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES ADILABAD -504001 TELANGANA STATE, Telangana, 504001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c3a"
    },
    "Rank": 12287,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kokilaben Dhirubhai Ambani Hospital and Medical Research Institute,,Achyutrao Patwardhan Marg Andheri West Mumbai Maharashtra, Maharashtra, 400053",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c3b"
    },
    "Rank": 12288,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ganga Medical Centre and Hospital,,313 Mettupalayam Road Coimbatore 641043, Tamil Nadu, 641043",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c3c"
    },
    "Rank": 12293,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c3d"
    },
    "Rank": 12294,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,RAJBHAVAN ROAD SOMAJIGUDA, Telangana, 500082",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c3e"
    },
    "Rank": 12295,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ANKURA MEDICAL AND RESEARCH CENTRE PVT.LTD. ,\nTelangana,Plot No. 1-18, Raghavendra Nagar, Peerzadiguda, Narapally, Uppal, Hyderabad, Telangana, 500039",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c3f"
    },
    "Rank": 12297,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Head Quarters Hospital,,H NO 3 3 141 MNCL X ROADS KARIMNAGAR KARIMNAGAR DISTRICT, Telangana, 505001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c40"
    },
    "Rank": 12299,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c41"
    },
    "Rank": 12300,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c42"
    },
    "Rank": 12302,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jan Swasthya Sahyog,,,Village and PO Ganiyari - 495112, Dist. Bilaspur,\nChhattisgarh, Chhattisgarh, 495112",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c43"
    },
    "Rank": 12304,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dharwad,dsdharwad at gmail dot com, Karnataka, 580001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c44"
    },
    "Rank": 12306,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c45"
    },
    "Rank": 12307,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c46"
    },
    "Rank": 12308,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c47"
    },
    "Rank": 12312,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c48"
    },
    "Rank": 12313,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c49"
    },
    "Rank": 12319,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c4a"
    },
    "Rank": 12320,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c4b"
    },
    "Rank": 12321,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c4c"
    },
    "Rank": 12328,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c4d"
    },
    "Rank": 12331,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kasturba Medical College Hospital (KMC Hospital),,KMC Hospital, Dr B R Ambedkar Circle, Mangalore, Karnataka, 575001",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c4e"
    },
    "Rank": 12332,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c4f"
    },
    "Rank": 12333,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,5 D L Roy Road, Krishnanagar, Nadia, West Bengal, 741101",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c50"
    },
    "Rank": 12336,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sree Gokulam Medical College Research Foundation, ,Venjaramoodu. P.O Thiruvananthapuram, Kerala, 695607",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c51"
    },
    "Rank": 12341,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c52"
    },
    "Rank": 12342,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c53"
    },
    "Rank": 12344,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KIMS Hospital , ,KIMS Hospitals, 69/213-k-9, Joharapuram Road, Joharapuram, Kurnool, Andhra Pradesh, 518002",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c54"
    },
    "Rank": 12345,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c55"
    },
    "Rank": 12346,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pimpri Chichwad Municipal Corporation Postgraduate Institute Y.C.M.Hospital Pimpri,Pune 18,Dean Office, Y.C.M.Hospital, Sant Tukaramnagar Pimpri, Pune, Maharashtra, 411018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c56"
    },
    "Rank": 12348,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c57"
    },
    "Rank": 12349,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c58"
    },
    "Rank": 12350,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Thiruvannamalai Medical College, Thiruvannamalai, Tamil Nadu,DISTRICT COLLECTORATE  MASTER PLAN COMPLEX, VENGIKKAL THIRUVANNAMALAI,\nTamil Nadu, 600604",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c59"
    },
    "Rank": 12354,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c5a"
    },
    "Rank": 12356,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saifee Hospital, ,postbox no 3771, 15-17 Maharshi Karve Marg, Charni road, Mumbai, Maharashtra, 400004",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c5b"
    },
    "Rank": 12357,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c5c"
    },
    "Rank": 12358,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c5d"
    },
    "Rank": 12359,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c5e"
    },
    "Rank": 12361,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Krishna Medical College and Hospital, ,Uma Nagar Muzaffarpur Bihar 842004, Bihar, 842004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c5f"
    },
    "Rank": 12362,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c60"
    },
    "Rank": 12363,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences, ,academics@kimshospitals.com, Telangana, 500003",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c61"
    },
    "Rank": 12364,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c62"
    },
    "Rank": 12366,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c63"
    },
    "Rank": 12369,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c64"
    },
    "Rank": 12370,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Gleneagles Hospital, ,58 Canal Circular Road Kolkata 700054, West Bengal, 700054",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c65"
    },
    "Rank": 12377,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital, B.H.Road, Tumakuru-572101, Karnataka, 572101",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c66"
    },
    "Rank": 12379,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Institute of Medical Sciences (SVIMS), A State University estd. by Govt. of A.P.,Sri Venkateswara Institute of Medical Sciences, SVIMS, Alipiri Road, Tirupati, Andhra Pradesh, 517507",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c67"
    },
    "Rank": 12382,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Bidaris Ashwini Hospital,,ASHWINI INSTITUTE OF CHILD HEALTH & RESEARCH CENTER BLDE ROAD VIJAYAPURA BIJAPUR, Karnataka, 586103",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c68"
    },
    "Rank": 12385,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lisie Medical Institution, ,Kochi, Kerala, Kerala, 682018",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c69"
    },
    "Rank": 12387,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c6a"
    },
    "Rank": 12388,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine and JNM Hospital, WBUHS,Block A, Post Office Kalyani, Near Silpanchal Station,\nBlock A2, District Nadia, West Bengal, West Bengal, 741235",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c6b"
    },
    "Rank": 12389,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c6c"
    },
    "Rank": 12390,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Muslim Maternity and Childrens Hospital, ,Muslim Maternity and Childrens Hospital, H.no-16-6-326, Osmanpura, Chaderghat, Hyderabad, Telangana, 500024",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c6d"
    },
    "Rank": 12391,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Muslim Maternity and Childrens Hospital, ,Muslim Maternity and Childrens Hospital, H.no-16-6-326, Osmanpura, Chaderghat, Hyderabad, Telangana, 500024",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c6e"
    },
    "Rank": 12394,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jagjivan Ram Railway Hospital,,Maratha Mandir Marg, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c6f"
    },
    "Rank": 12397,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Sivagangai Medical College and Hospital, Tamil Nadu,GOVT.SIVAGANGAI MEDICAL COLLEGE, MELAVANIYANKUDY, MANAMADURAI ROAD, SIVAGANGAI.,\nTamil Nadu, 630561",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c70"
    },
    "Rank": 12399,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c71"
    },
    "Rank": 12400,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c72"
    },
    "Rank": 12402,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c73"
    },
    "Rank": 12403,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c74"
    },
    "Rank": 12404,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "National Institute of Tuberculosis and Respiratory Diseases, (Formerly Known as L.R.S. Instt. of T.B. and Allied Scs),,National Institute of Tuberculosis and Respiratory Diseases (Formerly Known as L.R.S. Instt. of T.B., Delhi (NCT), 110030",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c75"
    },
    "Rank": 12405,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VELLORE MEDICAL COLLEGE,GOVERNMENT VELLORE MEDICAL COLLEGE, Tamil Nadu, 632011",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c76"
    },
    "Rank": 12407,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c77"
    },
    "Rank": 12413,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bokaro General Hospital, ,SECTOR - 4 BOKARO STEEL CITY\nDIST - BOKARO, Jharkhand, 827004",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c78"
    },
    "Rank": 12414,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c79"
    },
    "Rank": 12416,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College,Theni ,Government Medical College,Theni, Tamil Nadu, 625512",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c7a"
    },
    "Rank": 12417,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c7b"
    },
    "Rank": 12418,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Prashanthi Hospital, # ,7-3-94, Machilibazar, hanamkonda, Warangal, Telangana, 506011",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c7c"
    },
    "Rank": 12419,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c7d"
    },
    "Rank": 12423,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Caritas Hospital, ,Thellakom P.O, Kerala, 686630",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c7e"
    },
    "Rank": 12424,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c7f"
    },
    "Rank": 12429,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c80"
    },
    "Rank": 12430,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Arvind Eye Hospital and PG Instt.of Ophthalmology, ,No.1, S.N.High Road, Tirunelveli, Tamil Nadu, 627001",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c81"
    },
    "Rank": 12436,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c82"
    },
    "Rank": 12437,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sankara Eye Hospital, (formerly known as Shri Kanchi Kamakoti Medical Trust Hospital),,No.16-A, Sankara Eye Hospital Street, Sivanandapuram, Sathy Road, Saravanampatti, Coimbatore, Tamil Nadu, 641035",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c83"
    },
    "Rank": 12440,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c84"
    },
    "Rank": 12443,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaslok Hospital and Research Centre, ,15, Dr. G. Deshmukh Marg, Mumbai - 400026, Maharashtra, 400026",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c85"
    },
    "Rank": 12444,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c86"
    },
    "Rank": 12445,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c87"
    },
    "Rank": 12449,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c88"
    },
    "Rank": 12451,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c89"
    },
    "Rank": 12453,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shroff Eye Centre,,A-9, Kailash Colony, New Delhi, Delhi (NCT), 110048",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c8a"
    },
    "Rank": 12454,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Medical College and Chhatrapati Shivaji Maharaj Hospital, ,RAJIV GANDHI MEDICAL COLLEGE,THANE BELAPUR ROAD, KALWA, THANE, Maharashtra, 400605",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c8b"
    },
    "Rank": 12457,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "V Y Institute of Medical Science, ,Near Kamal Vihar, Dhamtari Road, Raipur, Chhattisgarh, 492001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c8c"
    },
    "Rank": 12458,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c8d"
    },
    "Rank": 12459,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c8e"
    },
    "Rank": 12460,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c8f"
    },
    "Rank": 12461,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Christian Institute of Health Sciences and Research, ,4th Mile, Central Jail Road, Dimapur, Nagaland, 797115",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c90"
    },
    "Rank": 12462,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c91"
    },
    "Rank": 12464,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Meenakshi Mission Hospital and Research Centre, ,Lake Area, Melur Road, Madurai, Tamil Nadu, 625107",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c92"
    },
    "Rank": 12467,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Parsik Hill Road, ,APOLLO HOSPITAL PARSIK HILL ROAD ROAD NO 13 SECTOR 23 CBD BELAPUR NAVI MUMBAI, Maharashtra, 400614",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c93"
    },
    "Rank": 12469,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c94"
    },
    "Rank": 12471,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c95"
    },
    "Rank": 12472,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Andhra Hospital (Vijayawada), ,CVR Complex, Governorpet, Vijayawada-520002, Krishna District, Andhra Pradesh, 520002",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c96"
    },
    "Rank": 12473,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SNR District Hospital,,SNR DISTRICT HOSPITAL, KOLAR KARNATAKA-563101,\nKarnataka, 563101",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c97"
    },
    "Rank": 12476,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Choithram Hospital and Research Centre,Manika Bagh Road Indore, Madhya Pradesh, 452014",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c98"
    },
    "Rank": 12477,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c99"
    },
    "Rank": 12478,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c9a"
    },
    "Rank": 12479,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c9b"
    },
    "Rank": 12481,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c9c"
    },
    "Rank": 12486,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Renee Hospital, Karimnagar, Telangana,H. No 3-7-1 and 3-7-02, Indira Nagar, Karimnagar-505001, Telangana, Telangana, 505001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c9d"
    },
    "Rank": 12487,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c9e"
    },
    "Rank": 12489,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04c9f"
    },
    "Rank": 12493,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "PARAMITHA CHILDRENS HOSPITAL ,\nTelangana,PLOT NO 37, NCL ENCLAVE, PET BASHEERABAD, KOMPALLY, MEDCHAL, 500067, Telangana, 500067",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ca0"
    },
    "Rank": 12494,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvarur Govt.Medical College,Thiruvarur Govt.Medical College, Tamil Nadu, 610004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ca1"
    },
    "Rank": 12495,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "QRG Medicare, ,Plot No. 1, Sector-16, Faridabad, Haryana, Haryana, 121002",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ca2"
    },
    "Rank": 12501,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ca3"
    },
    "Rank": 12502,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ca4"
    },
    "Rank": 12503,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pimpri Chichwad Municipal Corporation Postgraduate Institute Y.C.M.Hospital Pimpri,Pune 18,Dean Office, Y.C.M.Hospital, Sant Tukaramnagar Pimpri, Pune, Maharashtra, 411018",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ca5"
    },
    "Rank": 12505,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ca6"
    },
    "Rank": 12508,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ca7"
    },
    "Rank": 12513,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ca8"
    },
    "Rank": 12514,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ca9"
    },
    "Rank": 12515,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04caa"
    },
    "Rank": 12518,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College,Fakhruddin Ali Ahmed Medical College, Assam, 781301",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cab"
    },
    "Rank": 12519,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cac"
    },
    "Rank": 12520,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cad"
    },
    "Rank": 12521,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cae"
    },
    "Rank": 12522,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lokopriya Gopinath Bordoloi Regional Institute of Mental Health,LGBRIMH, Tezpur, District Sonitpur,, Assam, 784001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04caf"
    },
    "Rank": 12523,
    "Allotted Quota": "All India",
    "Allotted Institute": "Acharya Harihar Post Graduate Institute of Cancer, Cuttack,Cuttack Odisha, Odisha, 753007",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cb0"
    },
    "Rank": 12524,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cb1"
    },
    "Rank": 12526,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cb2"
    },
    "Rank": 12528,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Specialty Hospitals, ,IIIM NEHRU NAGAR GHAZIABAD, Uttar Pradesh, 201001",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cb3"
    },
    "Rank": 12529,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "DIPLOMA IN CHILD HEALTH/ PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cb4"
    },
    "Rank": 12530,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cb5"
    },
    "Rank": 12532,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cb6"
    },
    "Rank": 12535,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sher-I-Kashmir Institute of Medical Sciences, Jammu and Kashmir,SKIMS SOURA SRINAGAR JAMMU & KASHMIR, Jammu And Kashmir, 190011",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cb7"
    },
    "Rank": 12536,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cb8"
    },
    "Rank": 12537,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cb9"
    },
    "Rank": 12539,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Sathya Sai Sarla Memorial Hospital, Karnataka,Sathya Sai Grama Muddenahalli\nChikkaballapura, Karnataka, 562101",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cba"
    },
    "Rank": 12541,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cbb"
    },
    "Rank": 12542,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Institute of Medical Sciences (SVIMS), A State University estd. by Govt. of A.P.,Sri Venkateswara Institute of Medical Sciences, SVIMS, Alipiri Road, Tirupati, Andhra Pradesh, 517507",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cbc"
    },
    "Rank": 12543,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cbd"
    },
    "Rank": 12548,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute of Medical Sciences Srikakulam,Government Medical College Srikakulam\nBalaga Srikakulam Town Srikakulam District, Andhra Pradesh, 532001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cbe"
    },
    "Rank": 12549,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cbf"
    },
    "Rank": 12550,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Muslim Maternity and Childrens Hospital, ,Muslim Maternity and Childrens Hospital, H.no-16-6-326, Osmanpura, Chaderghat, Hyderabad, Telangana, 500024",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cc0"
    },
    "Rank": 12553,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cc1"
    },
    "Rank": 12559,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cc2"
    },
    "Rank": 12560,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cc3"
    },
    "Rank": 12561,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cc4"
    },
    "Rank": 12562,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aarthi Hospital, Tamil Nadu,DOOR NO 60 SANTHAIPETTAI ROAD KOVILPATTI KOVILPATTI TALUK THOOTHUKUDI DISTRICT, Tamil Nadu, 628502",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cc5"
    },
    "Rank": 12564,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Civil Hospital, ,Medical Superintendent Office, Civil Hospital, Dawrpui, Aizawl, Mizoram, 796001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cc6"
    },
    "Rank": 12568,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cc7"
    },
    "Rank": 12572,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gheesibai Memorial Mittal Hospital and Research Centre,,Gheesi Bai Memorial Mittal Hospital and Research Centre, Pushkar Road, Ajmer, Rajasthan, 305004",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cc8"
    },
    "Rank": 12574,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cc9"
    },
    "Rank": 12575,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital , Jammu and Kashmir\n,Court road pulwama, Jammu And Kashmir, 192301",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cca"
    },
    "Rank": 12577,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ccb"
    },
    "Rank": 12583,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ccc"
    },
    "Rank": 12584,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ccd"
    },
    "Rank": 12585,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shija Hospitals and Research Institute, ,SHIJA HOSPITALS AND RESEARCH INSTITUTE, LANGOL, LAMPHELPAT, IMPHAL WEST, MANIPUR 795004, Manipur, 795004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cce"
    },
    "Rank": 12589,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ccf"
    },
    "Rank": 12590,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cd0"
    },
    "Rank": 12591,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cd1"
    },
    "Rank": 12593,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cd2"
    },
    "Rank": 12595,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Christian Institute of Health Sciences and Research, ,4th Mile, Central Jail Road, Dimapur, Nagaland, 797115",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cd3"
    },
    "Rank": 12597,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "J.L.N. Main Hospital and Research Centre, ,Bhilai Steel Plant Sector-9, Bhilai, C.G., Chhattisgarh, 490009",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cd4"
    },
    "Rank": 12599,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Meenakshi Mission Hospital and Research Centre, ,Lake Area, Melur Road, Madurai, Tamil Nadu, 625107",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cd5"
    },
    "Rank": 12601,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aravind Eye Hospital,,64 Sankari Main Road, Nethimedu, Salem, Tamil Nadu, 636002",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cd6"
    },
    "Rank": 12604,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cd7"
    },
    "Rank": 12606,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cd8"
    },
    "Rank": 12609,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cd9"
    },
    "Rank": 12611,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESIC Model Hospital, ,A-3, TULSI MARG, SECTOR-24, NOIDA, Uttar Pradesh, 201301",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cda"
    },
    "Rank": 12612,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cdb"
    },
    "Rank": 12613,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. R N Cooper Municipal General Hospital, (Associated with H B T (Hinduhridayasamrat Balasaheb Thackeray) medical College) ,HBTMC AND DR.R.N.COOPER HOSPITAL\nU 15 BHAKTI VEDANTA ROAD JVPD SCHEME VILE PARLE WEST MUMBAI,\nMaharashtra, 400056",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cdc"
    },
    "Rank": 12614,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Jeyasekharan Hospital and Nursing Home, ,Dr.Jeyasekharan Hospital and Nursing Home,\nK.P.Road, Nagercoil, Kanyakumari District, Tamil Nadu, 629003",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cdd"
    },
    "Rank": 12617,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cde"
    },
    "Rank": 12618,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Lal Bahadur Shastri Government Medical College and Hospital , ,VPO- Bhangrotu, MC- Ner Chowk, District- Mandi, Himachal Pradesh, 175021",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cdf"
    },
    "Rank": 12619,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ce0"
    },
    "Rank": 12620,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ce1"
    },
    "Rank": 12621,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,DISTRICT HOSPITAL NEAR SANGAM CIRCLE DR RAJ KUMAR ROAD BALLARI-583101 KARNATAKA, Karnataka, 583101",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ce2"
    },
    "Rank": 12622,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT DHARMAPURI MEDICAL COLLEGE, DHARMAPURI, TAMIL NADU,GOVERNMENT DHARMAPURI MEDICAL COLLEGE, NETHAJI BYE PASS ROAD, DHARMAPURI, TAMIL NADU, Tamil Nadu, 636701",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ce3"
    },
    "Rank": 12623,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, West Bengal,730, Em By pass Road, Anandapur, Kolkata, West Bengal, 700107",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ce4"
    },
    "Rank": 12624,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine & Sagore Dutta Hospital,College of Medicine & Sagore Dutta Hospital, West Bengal, 700058",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ce5"
    },
    "Rank": 12625,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ce6"
    },
    "Rank": 12628,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ce7"
    },
    "Rank": 12629,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mata Chanan Devi Hospital,, ,C-1 JANAKPURI, Delhi (NCT), 110058",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ce8"
    },
    "Rank": 12631,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ce9"
    },
    "Rank": 12632,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cea"
    },
    "Rank": 12633,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ceb"
    },
    "Rank": 12634,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Associated Hospital Government Medical College, ,Government Medical College Chack Sajjan Kathua, Jammu And Kashmir, 184101",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cec"
    },
    "Rank": 12636,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kokilaben Dhirubhai Ambani Hospital and Medical Research Institute,,Achyutrao Patwardhan Marg Andheri West Mumbai Maharashtra, Maharashtra, 400053",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ced"
    },
    "Rank": 12638,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvarur Govt.Medical College,Thiruvarur Govt.Medical College, Tamil Nadu, 610004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cee"
    },
    "Rank": 12639,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cef"
    },
    "Rank": 12641,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cf0"
    },
    "Rank": 12644,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cf1"
    },
    "Rank": 12647,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cf2"
    },
    "Rank": 12649,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cf3"
    },
    "Rank": 12650,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaipur Golden Hospital, ,2 INSTITUTIONAL AREA, SECTOR-3, ROHINI, Delhi (NCT), 110085",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cf4"
    },
    "Rank": 12654,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "B.R. Singh Hospital and Centre for Medical Education and Research,,B.R.Singh Hospital Eastern Railway, Sealdah, Kolkata, West Bengal, 700014",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cf5"
    },
    "Rank": 12655,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cf6"
    },
    "Rank": 12662,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cf7"
    },
    "Rank": 12667,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sarvodaya Hospital and Research Centre, ,YMCA road Sector 08\nFaridabad, Haryana, 121006",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cf8"
    },
    "Rank": 12669,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Meenakshi Mission Hospital and Research Centre, ,Lake Area, Melur Road, Madurai, Tamil Nadu, 625107",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cf9"
    },
    "Rank": 12670,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cfa"
    },
    "Rank": 12672,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medical Trust Hospital, ,M.G. Road Pallimukku\nCochin, Kerala, 682016",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cfb"
    },
    "Rank": 12675,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cfc"
    },
    "Rank": 12676,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cfd"
    },
    "Rank": 12678,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cfe"
    },
    "Rank": 12683,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04cff"
    },
    "Rank": 12684,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Polakulath Narayanan Renai Medicity Multi Super Specialty Hospital,,RENAIMEDICITY PALARIVATTOM KOCHI KERALA, Kerala, 682025",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d00"
    },
    "Rank": 12689,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Escorts Hospital,,Fortis escorts hospital ,neelam bata road, faridabad, Haryana, 121002",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d01"
    },
    "Rank": 12690,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d02"
    },
    "Rank": 12691,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SBLS Civil Hospital, ,NEAR JYOTI CHOWK JAIL ROAD JALANDHAR, Punjab, 144001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d03"
    },
    "Rank": 12692,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d04"
    },
    "Rank": 12693,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d05"
    },
    "Rank": 12694,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital, ,GMERS Medical College and General hospital,Old TB Campus, gotri road, Vadodara, Gujarat, 390021",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d06"
    },
    "Rank": 12697,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d07"
    },
    "Rank": 12698,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d08"
    },
    "Rank": 12699,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d09"
    },
    "Rank": 12702,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d0a"
    },
    "Rank": 12704,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d0b"
    },
    "Rank": 12705,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d0c"
    },
    "Rank": 12708,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d0d"
    },
    "Rank": 12711,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE,ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, Bihar, 823001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d0e"
    },
    "Rank": 12712,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d0f"
    },
    "Rank": 12713,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Hospital,,Government Hospital Gandhi Nagar Jammu, Jammu And Kashmir, 180004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d10"
    },
    "Rank": 12714,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Ganapati Nethralaya, ,Near Janata high school, Jalna, Maharashtra, 431203",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d11"
    },
    "Rank": 12717,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d12"
    },
    "Rank": 12718,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Raja Sukhdev Singh DH Poonch, Jammu and Kashmir,RAJA SUKDEV SINGH DISTRIC HOSPITAL POONCH, Jammu And Kashmir, 185101",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d13"
    },
    "Rank": 12719,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d14"
    },
    "Rank": 12720,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Bidaris Ashwini Hospital,,ASHWINI INSTITUTE OF CHILD HEALTH & RESEARCH CENTER BLDE ROAD VIJAYAPURA BIJAPUR, Karnataka, 586103",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d15"
    },
    "Rank": 12721,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d16"
    },
    "Rank": 12723,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lisie Medical Institution, ,Kochi, Kerala, Kerala, 682018",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d17"
    },
    "Rank": 12724,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nazareth Hospital, Laitumukhrah, ,Laitumkhrah Shillong, East Khasi Hills district, Meghalaya, 793003",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d18"
    },
    "Rank": 12725,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajkiya Medical College, Jalaun,kalpi Road orai Jalaun, Uttar Pradesh, 285001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d19"
    },
    "Rank": 12726,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d1a"
    },
    "Rank": 12727,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College, ,Office of the Dean, First Floor, Civil Hospital Campus, Sector- 12, Gandhinagar, Gujarat, 382016",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d1b"
    },
    "Rank": 12730,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d1c"
    },
    "Rank": 12731,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Swami Dayanand Hospital, ,Dilshad Garden, Shahdara, Delhi, Delhi (NCT), 110095",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d1d"
    },
    "Rank": 12733,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chandan Hospital, ,Vijayant Khand, Gomti Nagar, Lucknow, Uttar Pradesh, 226010",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d1e"
    },
    "Rank": 12734,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Arvind Eye Hospital and PG Instt.of Ophthalmology, ,No.1, S.N.High Road, Tirunelveli, Tamil Nadu, 627001",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d1f"
    },
    "Rank": 12737,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d20"
    },
    "Rank": 12739,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital, ,GMERS Medical College and General hospital,Old TB Campus, gotri road, Vadodara, Gujarat, 390021",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d21"
    },
    "Rank": 12741,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d22"
    },
    "Rank": 12744,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d23"
    },
    "Rank": 12754,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Siddh Multi Specialty Hospital,SIDDH MULTISPECIALTY HOSPITAL  HARTHALA INDUSTRIAL ESTATE KANTH ROAD MORADABAD, Uttar Pradesh, 244001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d24"
    },
    "Rank": 12755,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d25"
    },
    "Rank": 12756,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d26"
    },
    "Rank": 12757,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "DEEP NURSING HOME AND CHILDREN HOSPITAL, Punjab,481, Model Town Road,\nPritam Nagar, Model Town, Ludhiana, Punjab 141002, Punjab, 141002",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d27"
    },
    "Rank": 12758,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Swami Dayanand Hospital, ,Dilshad Garden, Shahdara, Delhi, Delhi (NCT), 110095",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d28"
    },
    "Rank": 12765,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d29"
    },
    "Rank": 12767,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d2a"
    },
    "Rank": 12768,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d2b"
    },
    "Rank": 12769,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Batra Hospital and Medical Research Centre, ,1, Tughlakabad institutional Area, Mehrauli Badarpur Road, New Delhi, Delhi (NCT), 110062",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d2c"
    },
    "Rank": 12772,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aarthi Hospital, Tamil Nadu,DOOR NO 60 SANTHAIPETTAI ROAD KOVILPATTI KOVILPATTI TALUK THOOTHUKUDI DISTRICT, Tamil Nadu, 628502",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d2d"
    },
    "Rank": 12773,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d2e"
    },
    "Rank": 12774,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d2f"
    },
    "Rank": 12779,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Peerless Hospital and B K Roy Research Centre, ,360, Panchasayar, Kolkata - 700 -094, West Bengal, 700094",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d30"
    },
    "Rank": 12780,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d31"
    },
    "Rank": 12786,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d32"
    },
    "Rank": 12790,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,RAJBHAVAN ROAD SOMAJIGUDA, Telangana, 500082",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d33"
    },
    "Rank": 12791,
    "Allotted Quota": "All India",
    "Allotted Institute": "GADAG INSTITUTE OF MEDICAL SCIENCES GADAG,Gadag Institute of Medical Sciences Mallasamudra Gadag-582103, Karnataka, 582103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d34"
    },
    "Rank": 12792,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d35"
    },
    "Rank": 12795,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d36"
    },
    "Rank": 12796,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d37"
    },
    "Rank": 12797,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Daya General Hospital,Daya General Hospital Limited Shornur Road NEar Viyyur bridge\nThiruvambady PO Thrissur, Kerala, 680022",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d38"
    },
    "Rank": 12806,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d39"
    },
    "Rank": 12813,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d3a"
    },
    "Rank": 12819,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d3b"
    },
    "Rank": 12821,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d3c"
    },
    "Rank": 12823,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d3d"
    },
    "Rank": 12824,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d3e"
    },
    "Rank": 12830,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PALLIATIVE MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d3f"
    },
    "Rank": 12833,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital,\n,paddoc road, near majewadi gate, junagadh, Gujarat, 362001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d40"
    },
    "Rank": 12836,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d41"
    },
    "Rank": 12837,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Bidaris Ashwini Hospital,,ASHWINI INSTITUTE OF CHILD HEALTH & RESEARCH CENTER BLDE ROAD VIJAYAPURA BIJAPUR, Karnataka, 586103",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d42"
    },
    "Rank": 12838,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "IQRAA International Hospital and Research Centre, ,IQRAA INTERNATIONAL HOSPITAL AND RESEARCH CENTRE MALAPARAMBA CALICUT, Kerala, 673009",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d43"
    },
    "Rank": 12840,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Punjab Institute of Medical Scineces ,Punjab Institute of Medical Sciences Garha Road Jalandhar, Punjab, 144006",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d44"
    },
    "Rank": 12841,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d45"
    },
    "Rank": 12842,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d46"
    },
    "Rank": 12843,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aarthi Hospital, Tamil Nadu,DOOR NO 60 SANTHAIPETTAI ROAD KOVILPATTI KOVILPATTI TALUK THOOTHUKUDI DISTRICT, Tamil Nadu, 628502",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d47"
    },
    "Rank": 12846,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Andhra Hospital (Vijayawada), ,CVR Complex, Governorpet, Vijayawada-520002, Krishna District, Andhra Pradesh, 520002",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d48"
    },
    "Rank": 12853,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS MEDICAL COLLEGE, NEAR GUJARAT HIGH COURT, S.G HIGH WAY, SOLA, AHMEDABAD, Gujarat, 380060",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d49"
    },
    "Rank": 12854,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d4a"
    },
    "Rank": 12855,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d4b"
    },
    "Rank": 12857,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d4c"
    },
    "Rank": 12858,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d4d"
    },
    "Rank": 12860,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d4e"
    },
    "Rank": 12861,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d4f"
    },
    "Rank": 12863,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d50"
    },
    "Rank": 12865,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Princess Durru Shehvar Children`s and General Hospital, ,22 3 660A PURANI HAVELI HYDERABAD, Telangana, 500002",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d51"
    },
    "Rank": 12866,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sankara Eye Hospital, (formerly known as Shri Kanchi Kamakoti Medical Trust Hospital),,No.16-A, Sankara Eye Hospital Street, Sivanandapuram, Sathy Road, Saravanampatti, Coimbatore, Tamil Nadu, 641035",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d52"
    },
    "Rank": 12867,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESIC Model Hospital, ,LAXMI NAGAR AJMER ROAD JAIPUR, Rajasthan, 302006",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d53"
    },
    "Rank": 12870,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d54"
    },
    "Rank": 12871,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. TMA Pai Hospital, Udupi, Karnataka-576101,Opposite Old Taluk office, Court Road, Udupi, Karnataka, 576101",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d55"
    },
    "Rank": 12872,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,SECTOR-6, PANCHKULA, HARYANA-134108, Haryana, 134108",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d56"
    },
    "Rank": 12873,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chittaranjan Seva Sadan Hospital,37, S.P. MUKHERJEE ROAD, KOLKATA, West Bengal, 700026",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d57"
    },
    "Rank": 12874,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d58"
    },
    "Rank": 12877,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asian Institute of Medical Sciences,,Asian Institute of Medical Sciences, Badhkal Flyover Road,Sector-21A, Faridabad, Haryana-121001, Haryana, 121001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d59"
    },
    "Rank": 12881,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Specialty Hospitals, ,IIIM NEHRU NAGAR GHAZIABAD, Uttar Pradesh, 201001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d5a"
    },
    "Rank": 12882,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d5b"
    },
    "Rank": 12883,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d5c"
    },
    "Rank": 12887,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d5d"
    },
    "Rank": 12890,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chaithanya Eye Hospital, ,Kesavadasapuram, Thiruvananthapuram Kerala-695004, Kerala, 695004",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d5e"
    },
    "Rank": 12892,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ispat General Hospital,,Ispat General Hospital Rourkela Steel Plant SAIL Rourkela 769005, Odisha, 769005",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d5f"
    },
    "Rank": 12898,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d60"
    },
    "Rank": 12899,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d61"
    },
    "Rank": 12900,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mental Health Centre, ,Mental Health Centre Peroorkada  Thiruvanthapuram Kerala, Kerala, 695005",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d62"
    },
    "Rank": 12904,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d63"
    },
    "Rank": 12906,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d64"
    },
    "Rank": 12907,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K B Bhabha Municipal General Hospital, ,R K PATKAR ROAD ,BANDRA WEST\n,MUMBAI, Maharashtra, 400050",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d65"
    },
    "Rank": 12910,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaiprakash Hospital and Research Centre Pvt. Ltd, Odisha,,JAIPRAKASH HOSPITAL AND RESEARCH CENTER PVT. LTD., DANDIAPALI, ROURKELA, Odisha, 769004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d66"
    },
    "Rank": 12917,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d67"
    },
    "Rank": 12918,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d68"
    },
    "Rank": 12919,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nazareth Hospital, Laitumukhrah, ,Laitumkhrah Shillong, East Khasi Hills district, Meghalaya, 793003",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d69"
    },
    "Rank": 12920,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Imambara District Hospital,,AKHANBAZAR ROAD, CHINSURA, HOOGHLY, West Bengal, 712101",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d6a"
    },
    "Rank": 12921,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "J.L.N. Main Hospital and Research Centre, ,Bhilai Steel Plant Sector-9, Bhilai, C.G., Chhattisgarh, 490009",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d6b"
    },
    "Rank": 12923,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d6c"
    },
    "Rank": 12924,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d6d"
    },
    "Rank": 12925,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d6e"
    },
    "Rank": 12927,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Venkatapadma Hospital,,Venkatapadma hospital, near 3 lamp junction, Door No 15-1-45, Vizianagaram, Andhra Pradesh, 535002",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d6f"
    },
    "Rank": 12928,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d70"
    },
    "Rank": 12929,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Omni RK Super Specialty Hospital, ,Waltair Main Rd, opp. Lions Club Of, Ram Nagar, Visakhapatnam, Andhra Pradesh 530002, Andhra Pradesh, 530002",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d71"
    },
    "Rank": 12930,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d72"
    },
    "Rank": 12931,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ashwini Hospital, Cuttack Orissa,SECTOR 1 CDA CUTTACK, Odisha, 753014",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d73"
    },
    "Rank": 12932,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,DISTRICT HOSPITAL NEAR SANGAM CIRCLE DR RAJ KUMAR ROAD BALLARI-583101 KARNATAKA, Karnataka, 583101",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d74"
    },
    "Rank": 12933,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d75"
    },
    "Rank": 12937,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d76"
    },
    "Rank": 12939,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d77"
    },
    "Rank": 12940,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d78"
    },
    "Rank": 12942,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Salem Polyclinic ,\nTamil Nadu,No.250, Omalur main road, Salem-636007, Tamil Nadu, 636007",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d79"
    },
    "Rank": 12943,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d7a"
    },
    "Rank": 12945,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Metro Heart Institute with Multispecialty, ,SECTOR 16A, Faridabad, Haryana, Haryana, 121002",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d7b"
    },
    "Rank": 12948,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d7c"
    },
    "Rank": 12950,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d7d"
    },
    "Rank": 12951,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d7e"
    },
    "Rank": 12952,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, Uttar Pradesh,H-1, 24,26,27, KAUSHAMBI, NEAR DABUR CHOWK, GHAZIABAD, Uttar Pradesh, 201010",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d7f"
    },
    "Rank": 12955,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Princess Durru Shehvar Children`s and General Hospital, ,22 3 660A PURANI HAVELI HYDERABAD, Telangana, 500002",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d80"
    },
    "Rank": 12959,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d81"
    },
    "Rank": 12960,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d82"
    },
    "Rank": 12963,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d83"
    },
    "Rank": 12965,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "P.D. Hinduja National Hospital and Medical Research Centre, ,Veer Savarkar Marg, Mahim, Maharashtra, 400016",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d84"
    },
    "Rank": 12966,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d85"
    },
    "Rank": 12967,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Associated Hospital Government Medical College, ,Kanth Bagh Baramulla, Jammu And Kashmir, 193103",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d86"
    },
    "Rank": 12968,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d87"
    },
    "Rank": 12973,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. James Hospital, ,Old Highway, CHALAKUDY, Kerala, 680307",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d88"
    },
    "Rank": 12974,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d89"
    },
    "Rank": 12976,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d8a"
    },
    "Rank": 12977,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d8b"
    },
    "Rank": 12978,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahavir Jaipuriya Rajasthan Hospital, Rajasthan\n,Opposite Jaipuriya Hospital, Hospital Road, Milap Nagar, JLN Marg, Jaipur, Rajasthan, 302018",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d8c"
    },
    "Rank": 12984,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d8d"
    },
    "Rank": 12985,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d8e"
    },
    "Rank": 12986,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d8f"
    },
    "Rank": 12988,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Hurkisondas Nurrotumdas Hospital and Research Centre, ,Prarthana Samaj, Raja Ram Mohan Roy road,\nGirgaon, MUMBAI, Maharashtra, 400004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d90"
    },
    "Rank": 12989,
    "Allotted Quota": "All India",
    "Allotted Institute": "LATE SHRI LAKHI RAM AGRAWAL MEMORIAL GOVT.MEDICAL COLLEGE,LATE SHRI LAKHI RAM AGRAWAL MEMORIAL GOVT.MEDICAL COLLEGE, Chhattisgarh, 496001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d91"
    },
    "Rank": 12990,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rich Hospital, Andhra Pradesh,POGATHOTA,NELLORE,ANDHRAPRADESH, Andhra Pradesh, 524001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d92"
    },
    "Rank": 12991,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d93"
    },
    "Rank": 12994,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d94"
    },
    "Rank": 12995,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Omni RK Super Specialty Hospital, ,Waltair Main Rd, opp. Lions Club Of, Ram Nagar, Visakhapatnam, Andhra Pradesh 530002, Andhra Pradesh, 530002",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d95"
    },
    "Rank": 12996,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d96"
    },
    "Rank": 12999,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gheesibai Memorial Mittal Hospital and Research Centre,,Gheesi Bai Memorial Mittal Hospital and Research Centre, Pushkar Road, Ajmer, Rajasthan, 305004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d97"
    },
    "Rank": 13000,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d98"
    },
    "Rank": 13003,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d99"
    },
    "Rank": 13004,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d9a"
    },
    "Rank": 13005,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d9b"
    },
    "Rank": 13006,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Vinoba Bhave Civil Hospital,,S R Delkar Marg, Saily Road, Silvassa, Dadra And Nagar Haveli, 396230",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d9c"
    },
    "Rank": 13007,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d9d"
    },
    "Rank": 13008,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "DIP.IN GYNAE. and OBST.",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d9e"
    },
    "Rank": 13012,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04d9f"
    },
    "Rank": 13014,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04da0"
    },
    "Rank": 13015,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Care Hospital,,Care Hospitals Road No1 Banjara Hills Hyderabad Telangana, Telangana, 500034",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04da1"
    },
    "Rank": 13016,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ekta Institute of Child Health, ,Near Ram Mandir. Shantinagar, Raipur, Chhattisgarh, 492001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04da2"
    },
    "Rank": 13017,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kurji Holy Family Hospital, ,Kurji Holy Family Hospital\nP.O - Sadaquat Ashram, Bihar, 800010",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04da3"
    },
    "Rank": 13022,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, Shalimar Bagh,A- Block, Shalimar Bagh, Delhi (NCT), 110088",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04da4"
    },
    "Rank": 13026,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04da5"
    },
    "Rank": 13030,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04da6"
    },
    "Rank": 13035,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04da7"
    },
    "Rank": 13037,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04da8"
    },
    "Rank": 13039,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04da9"
    },
    "Rank": 13044,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04daa"
    },
    "Rank": 13054,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dab"
    },
    "Rank": 13055,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Masonic Medical Centre for Children, Tamil Nadu,232 RACE COURSE,COIMBATORE, Tamil Nadu, 641018",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dac"
    },
    "Rank": 13057,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dad"
    },
    "Rank": 13058,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aarthi Hospital, Tamil Nadu,DOOR NO 60 SANTHAIPETTAI ROAD KOVILPATTI KOVILPATTI TALUK THOOTHUKUDI DISTRICT, Tamil Nadu, 628502",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dae"
    },
    "Rank": 13059,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,DISTRICT HOSPITAL NEAR SANGAM CIRCLE DR RAJ KUMAR ROAD BALLARI-583101 KARNATAKA, Karnataka, 583101",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04daf"
    },
    "Rank": 13060,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04db0"
    },
    "Rank": 13061,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kasturba Medical College Hospital (KMC Hospital),,KMC Hospital, Dr B R Ambedkar Circle, Mangalore, Karnataka, 575001",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04db1"
    },
    "Rank": 13063,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT DHARMAPURI MEDICAL COLLEGE, DHARMAPURI, TAMIL NADU,GOVERNMENT DHARMAPURI MEDICAL COLLEGE, NETHAJI BYE PASS ROAD, DHARMAPURI, TAMIL NADU, Tamil Nadu, 636701",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04db2"
    },
    "Rank": 13064,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04db3"
    },
    "Rank": 13069,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04db4"
    },
    "Rank": 13073,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mata Chanan Devi Hospital,, ,C-1 JANAKPURI, Delhi (NCT), 110058",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04db5"
    },
    "Rank": 13074,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04db6"
    },
    "Rank": 13076,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04db7"
    },
    "Rank": 13078,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Philomena`s Hospital, ,No.4, Campbell Road, Viveknagar P.O. Bangalore - 560047, Karnataka, 560047",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04db8"
    },
    "Rank": 13080,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04db9"
    },
    "Rank": 13081,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dba"
    },
    "Rank": 13084,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dbb"
    },
    "Rank": 13086,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dbc"
    },
    "Rank": 13090,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital,,FC 50, Max Wali Rd, C and D Block, Shalimar Place Site, Shalimar Bagh, New Delhi, Delhi 110088, Delhi (NCT), 110088",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dbd"
    },
    "Rank": 13092,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sankara Eye Hospital, (formerly known as Shri Kanchi Kamakoti Medical Trust Hospital),,No.16-A, Sankara Eye Hospital Street, Sivanandapuram, Sathy Road, Saravanampatti, Coimbatore, Tamil Nadu, 641035",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dbe"
    },
    "Rank": 13094,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Little Flower Hospital and Research Centre,,Post Box 23, Angamaly, Ernakulam, Kerala, Kerala, 683572",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dbf"
    },
    "Rank": 13095,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KIMS Al Shifa Hospital, ,KIMS Al Shifa Healthcare Pvt  Ltd.\nPB No 26 .\nOotty Road.\nPerinthalmanna, Malappuram district, Ke, Kerala, 679322",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dc0"
    },
    "Rank": 13096,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Associated Hospital Government Medical College, ,Kanth Bagh Baramulla, Jammu And Kashmir, 193103",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dc1"
    },
    "Rank": 13105,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dc2"
    },
    "Rank": 13106,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "QRG Medicare, ,Plot No. 1, Sector-16, Faridabad, Haryana, Haryana, 121002",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dc3"
    },
    "Rank": 13112,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KMC Specialty Hospital, ,No-6, Royal Road, Cantonment, Trichy-620001, Tamil Nadu, 620001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dc4"
    },
    "Rank": 13116,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ERNAKULAM,GOVT MEDICAL COLLEGE, ERNAKULAM,\nKerala, 683503",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dc5"
    },
    "Rank": 13119,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dc6"
    },
    "Rank": 13120,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Motors Hospital, ,KHARANGAJHAR, TELCO COLONY JAMSHEDPUR, Jharkhand, 831003",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dc7"
    },
    "Rank": 13122,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shaheed Hasan Khan Mewati Government Medical College , ,Shaheed Hasan Khan Mewati Government Medical College Nalhar Nuh, Haryana, 122107",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dc8"
    },
    "Rank": 13123,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Radhakrishnan Govt. Medical College , ,Dr. Radhakrishnan Govt. Medical College Hamirpur HP, Himachal Pradesh, 177001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dc9"
    },
    "Rank": 13125,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Regency Hospital,,A-2, Sarvodaya Nagar, Kanpur, Uttar Pradesh, 208005",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dca"
    },
    "Rank": 13126,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "J.L.N. Main Hospital and Research Centre, ,Bhilai Steel Plant Sector-9, Bhilai, C.G., Chhattisgarh, 490009",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dcb"
    },
    "Rank": 13127,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dcc"
    },
    "Rank": 13130,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dcd"
    },
    "Rank": 13131,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asansol District Hospital, ,S. B. GORAI ROAD, P.O.- ASANSOL, DIST.- PASCHIM BARDHAMAN, West Bengal, 713301",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dce"
    },
    "Rank": 13133,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Ramesh Cardiac and Multi Speciality Hospital,,Collector office road, Nagarampalem, Guntur , AP, Andhra Pradesh, 522004",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dcf"
    },
    "Rank": 13134,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Thiruvannamalai Medical College, Thiruvannamalai, Tamil Nadu,DISTRICT COLLECTORATE  MASTER PLAN COMPLEX, VENGIKKAL THIRUVANNAMALAI,\nTamil Nadu, 600604",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dd0"
    },
    "Rank": 13135,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asansol District Hospital, ,S. B. GORAI ROAD, P.O.- ASANSOL, DIST.- PASCHIM BARDHAMAN, West Bengal, 713301",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dd1"
    },
    "Rank": 13136,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dd2"
    },
    "Rank": 13140,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dd3"
    },
    "Rank": 13141,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INSTITUTE OF MEDICAL SCIENCES,RAICHUR INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 584102",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dd4"
    },
    "Rank": 13142,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shanti Hospital, ,166A\\1A, Near Old IB, Extension Area, Bagalkot, Karnataka, 587101",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dd5"
    },
    "Rank": 13145,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dd6"
    },
    "Rank": 13149,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SETH V. C. GANDHI and M. A. VORA MUNICIPAL GENERAL HOSPITAL (RAJAWADI HOSPITAL),\nMaharashtra,hospital.rajawadi@gmail.com, Maharashtra, 400077",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dd7"
    },
    "Rank": 13151,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dd8"
    },
    "Rank": 13153,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital,,DNB office, 3rd Floor Opp Library  Apollo Hospitals, Jubilee Hills, Hyderabad, Telangana, 500033",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dd9"
    },
    "Rank": 13157,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dda"
    },
    "Rank": 13158,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ddb"
    },
    "Rank": 13159,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Bundelkhand Medical College Sagar M.P.,Govt. Bundelkhand Medical College Sagar M.P., Madhya Pradesh, 470001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ddc"
    },
    "Rank": 13162,
    "Allotted Quota": "All India",
    "Allotted Institute": "Homi Bhabha Cancer Hospital, Varanasi,Homi Bhabha Cancer Hospital , Old Loco Colony, Shivpurwa, Lahartara, Varanasi-221002, Uttar Pradesh, Uttar Pradesh, 221002",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ddd"
    },
    "Rank": 13164,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ganga Medical Centre and Hospital,,313 Mettupalayam Road Coimbatore 641043, Tamil Nadu, 641043",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dde"
    },
    "Rank": 13165,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ddf"
    },
    "Rank": 13169,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Arvind Eye Hospital and PG Instt.of Ophthalmology, ,No.1, S.N.High Road, Tirunelveli, Tamil Nadu, 627001",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04de0"
    },
    "Rank": 13172,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Philomena`s Hospital, ,No.4, Campbell Road, Viveknagar P.O. Bangalore - 560047, Karnataka, 560047",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04de1"
    },
    "Rank": 13173,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04de2"
    },
    "Rank": 13174,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04de3"
    },
    "Rank": 13176,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04de4"
    },
    "Rank": 13182,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04de5"
    },
    "Rank": 13185,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04de6"
    },
    "Rank": 13186,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04de7"
    },
    "Rank": 13190,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04de8"
    },
    "Rank": 13194,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04de9"
    },
    "Rank": 13196,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dea"
    },
    "Rank": 13197,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "North 24 Parganas District Hospital, ,BANAMALIPUR BARASAT WEST BENGAL, West Bengal, 700124",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04deb"
    },
    "Rank": 13198,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dec"
    },
    "Rank": 13199,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ded"
    },
    "Rank": 13200,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Malabar Institute of Medical Sciences, ,Govindapuram P.O., Mini Bypass Road, Kozhikode, Kerala, 673016",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dee"
    },
    "Rank": 13201,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Renee Hospital, Karimnagar, Telangana,H. No 3-7-1 and 3-7-02, Indira Nagar, Karimnagar-505001, Telangana, Telangana, 505001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04def"
    },
    "Rank": 13202,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shaheed Hasan Khan Mewati Government Medical College , ,Shaheed Hasan Khan Mewati Government Medical College Nalhar Nuh, Haryana, 122107",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04df0"
    },
    "Rank": 13203,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Headquarters Hospital, ,Udumalai Road, Pollachi, Tamil Nadu, Tamil Nadu, 642001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04df1"
    },
    "Rank": 13204,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04df2"
    },
    "Rank": 13205,
    "Allotted Quota": "All India",
    "Allotted Institute": "Murshidabad Medical College and Hospital,Station Road, Berhampore, Murshidabad, West Bengal, 742101",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04df3"
    },
    "Rank": 13206,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04df4"
    },
    "Rank": 13207,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04df5"
    },
    "Rank": 13208,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04df6"
    },
    "Rank": 13209,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04df7"
    },
    "Rank": 13212,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04df8"
    },
    "Rank": 13214,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Arvind Eye Hospital and PG Instt.of Ophthalmology, ,No.1, S.N.High Road, Tirunelveli, Tamil Nadu, 627001",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04df9"
    },
    "Rank": 13215,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dfa"
    },
    "Rank": 13216,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dfb"
    },
    "Rank": 13220,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dfc"
    },
    "Rank": 13222,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dfd"
    },
    "Rank": 13227,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dfe"
    },
    "Rank": 13229,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04dff"
    },
    "Rank": 13230,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Family Hospital, ,St.Andrews Road, Bandra West , Mumbai - 50, Maharashtra, 400050",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e00"
    },
    "Rank": 13231,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kurji Holy Family Hospital, ,Kurji Holy Family Hospital\nP.O - Sadaquat Ashram, Bihar, 800010",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e01"
    },
    "Rank": 13235,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e02"
    },
    "Rank": 13237,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e03"
    },
    "Rank": 13242,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e04"
    },
    "Rank": 13243,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e05"
    },
    "Rank": 13244,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e06"
    },
    "Rank": 13246,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e07"
    },
    "Rank": 13248,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e08"
    },
    "Rank": 13250,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital , ,GMERS MEDICAL COLLEGE , Hadiyol-Gadhoda Road, Near-Government Polytechnic, Hiimmatnagar, Gujarat, 383001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e09"
    },
    "Rank": 13255,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. B. C. Roy Post Graduate Institute of Paediatric Sciences,111 Narkeldanga Main Road Kolkata, West Bengal, 700054",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e0a"
    },
    "Rank": 13258,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Trustwell Hospitals Pvt. Ltd, ,NO 5 Chandrika Tower JC Road\nBangalore, Karnataka, 560002",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e0b"
    },
    "Rank": 13261,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences Koppal, Karnataka,Gangavati Road Kiddidal Gate koppal, Karnataka, 583231",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e0c"
    },
    "Rank": 13263,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "National Heart Institute, ,49-50, Community Centre, East of Kailash, Delhi (NCT), 110065",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e0d"
    },
    "Rank": 13264,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Popular Medicare,POPULAR MEDICARE LTD N 10 60 A 2 BLW ROAD KAKARAMATTA VARANASI, Uttar Pradesh, 221106",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e0e"
    },
    "Rank": 13266,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e0f"
    },
    "Rank": 13267,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruby Hall Clinic, ,GRANT MEDICAL FOUNDATION,\n40 SASSOON ROAD, PUNE 411001, Maharashtra, 411001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e10"
    },
    "Rank": 13269,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lourdes Hospital, ,Ernakulam, Pachalam, Kochi, Kerala, 682012",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e11"
    },
    "Rank": 13273,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollomedics Superspeciality Hospital,KBC 31, Sector B, LDA Colony, Kanpur Road, Lucknow, Uttar Pradesh, 226012",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e12"
    },
    "Rank": 13274,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta Hospital,\nUttar Pradesh,Medanta hospital, Sec A pocket 1, Amar Shaheed Path Sushant Golf City Lucknow, Uttar Pradesh, 226030",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e13"
    },
    "Rank": 13277,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e14"
    },
    "Rank": 13278,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e15"
    },
    "Rank": 13279,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Hospital, ,ALEXANDER ROAD, BESIDE HARIHARA KALABHAVAN, NEAR CLOCK TOWER, SECUNDERBAD, Telangana, 500003",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e16"
    },
    "Rank": 13284,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e17"
    },
    "Rank": 13287,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Datia,Near 29 Batallion, National Highway 44, Dist Datia, Madhya Pradesh Pin - 475661, Madhya Pradesh, 475661",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e18"
    },
    "Rank": 13289,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e19"
    },
    "Rank": 13290,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e1a"
    },
    "Rank": 13294,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e1b"
    },
    "Rank": 13295,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, Uttar Pradesh,H-1, 24,26,27, KAUSHAMBI, NEAR DABUR CHOWK, GHAZIABAD, Uttar Pradesh, 201010",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e1c"
    },
    "Rank": 13297,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e1d"
    },
    "Rank": 13301,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Calcutta Medical Research Institute, ,The Calcutta Medical Research Institute 7 by 2 Diamond Harbour Road Kolkata 700027, West Bengal, 700027",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e1e"
    },
    "Rank": 13306,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jagannath Gupta Institute of Medical Sciences and Hospital, ,BUITA, K P MONDAL ROAD, BUDGE BUDGE, SOUTH 24 PARGANAS, KOLKATA, West Bengal, 700137",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e1f"
    },
    "Rank": 13312,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Specialty Hospitals, ,IIIM NEHRU NAGAR GHAZIABAD, Uttar Pradesh, 201001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e20"
    },
    "Rank": 13315,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e21"
    },
    "Rank": 13317,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e22"
    },
    "Rank": 13318,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e23"
    },
    "Rank": 13319,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e24"
    },
    "Rank": 13320,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Civil Hospital GURDASPUR, Punjab,smodhgsp@gmail.com, Punjab, 143521",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e25"
    },
    "Rank": 13324,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Specialty Hospitals, ,IIIM NEHRU NAGAR GHAZIABAD, Uttar Pradesh, 201001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e26"
    },
    "Rank": 13326,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e27"
    },
    "Rank": 13327,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e28"
    },
    "Rank": 13329,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Ramakrishna Hospital, ,395, Sarojini Naidu Road, Sidhapudur,\nCoimbatore - 641 044, Tamil Nadu, 641044",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e29"
    },
    "Rank": 13330,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Meenakshi Mission Hospital and Research Centre, ,Lake Area, Melur Road, Madurai, Tamil Nadu, 625107",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e2a"
    },
    "Rank": 13332,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e2b"
    },
    "Rank": 13333,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS Medical College Valsad,Nanakwada Halar Road,Valsad ,Gujarat, Gujarat, 396001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e2c"
    },
    "Rank": 13334,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e2d"
    },
    "Rank": 13338,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e2e"
    },
    "Rank": 13340,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo First Med Hospital,154, Poonamallee High Road, Kilapuk, Chennai, Tamil Nadu, 600010",
    "Course": "(NBEMS) Plastic and Reconstructive Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e2f"
    },
    "Rank": 13341,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata Shrimati Devendra Kumari Singhdeo Government Medical College, Ambikapur,Kanyaparisar  Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e30"
    },
    "Rank": 13343,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dharwad,dsdharwad at gmail dot com, Karnataka, 580001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e31"
    },
    "Rank": 13346,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e32"
    },
    "Rank": 13347,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e33"
    },
    "Rank": 13350,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e34"
    },
    "Rank": 13351,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agarsen Hospital, ,., Delhi (NCT), 110026",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e35"
    },
    "Rank": 13353,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kalpana Chawla Govt Medical College, ,Model Town, Karnal, Haryana, 132001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e36"
    },
    "Rank": 13356,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e37"
    },
    "Rank": 13358,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e38"
    },
    "Rank": 13360,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e39"
    },
    "Rank": 13361,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "DIP.IN MEDICAL RADIO- DIAGNOSIS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e3a"
    },
    "Rank": 13362,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "NEOTIA GETWEL HEALTHCARE CENTRE,\n,Uttorayon, Behind City Centre, Matigara, Siliguri, West Bengal, 734010",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e3b"
    },
    "Rank": 13363,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e3c"
    },
    "Rank": 13364,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e3d"
    },
    "Rank": 13365,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shanti Hospital, ,166A\\1A, Near Old IB, Extension Area, Bagalkot, Karnataka, 587101",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e3e"
    },
    "Rank": 13372,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e3f"
    },
    "Rank": 13377,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e40"
    },
    "Rank": 13379,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VELLORE MEDICAL COLLEGE,GOVERNMENT VELLORE MEDICAL COLLEGE, Tamil Nadu, 632011",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e41"
    },
    "Rank": 13381,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e42"
    },
    "Rank": 13387,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e43"
    },
    "Rank": 13388,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rangadore Memorial Hospital,,1ST CROSS, SHANKARAPURAM, BANGALORE, Karnataka, 560004",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e44"
    },
    "Rank": 13391,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e45"
    },
    "Rank": 13393,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e46"
    },
    "Rank": 13395,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Agarwal`s Eye Hospital and Research Centre, ,222, TTK Road, Alwarpet, Chennai, Tamil Nadu, 600018",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e47"
    },
    "Rank": 13396,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e48"
    },
    "Rank": 13398,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indraprastha Apollo Hospital,,Sarita Vihar, New Delhi 110 076, Delhi (NCT), 110076",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e49"
    },
    "Rank": 13401,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VILLUPURAM MEDICAL COLLEGE, VILLUPURAM,GOVERNMENT VILLUPURAM MEDICAL COLLEGE VILLUPURAM  TRICHY TRUNK ROAD MUNDIYAMPAKKAM  VILLUPURAM DISTR, Tamil Nadu, 605601",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e4a"
    },
    "Rank": 13409,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Siddipet,SURVEY NO 54  GOVERNMENT MEDICAL COLLEGE  ENSANPALLY  SIDDIPET   502114, Telangana, 502114",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e4b"
    },
    "Rank": 13410,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,5 D L Roy Road, Krishnanagar, Nadia, West Bengal, 741101",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e4c"
    },
    "Rank": 13418,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e4d"
    },
    "Rank": 13421,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tirath Ram Shah Hospital,,2A, RBL Isher Das Sawhney Marg, Rajpur Road,, Delhi (NCT), 110054",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e4e"
    },
    "Rank": 13423,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e4f"
    },
    "Rank": 13425,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital, ,GMERS Medical College and General hospital,Old TB Campus, gotri road, Vadodara, Gujarat, 390021",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e50"
    },
    "Rank": 13429,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e51"
    },
    "Rank": 13430,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e52"
    },
    "Rank": 13431,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e53"
    },
    "Rank": 13432,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e54"
    },
    "Rank": 13438,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e55"
    },
    "Rank": 13440,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e56"
    },
    "Rank": 13442,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e57"
    },
    "Rank": 13447,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. James Hospital, ,Old Highway, CHALAKUDY, Kerala, 680307",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e58"
    },
    "Rank": 13449,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Motors Hospital, ,KHARANGAJHAR, TELCO COLONY JAMSHEDPUR, Jharkhand, 831003",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e59"
    },
    "Rank": 13450,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e5a"
    },
    "Rank": 13451,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e5b"
    },
    "Rank": 13455,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e5c"
    },
    "Rank": 13459,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e5d"
    },
    "Rank": 13461,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e5e"
    },
    "Rank": 13463,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e5f"
    },
    "Rank": 13465,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aarthi Hospital, Tamil Nadu,DOOR NO 60 SANTHAIPETTAI ROAD KOVILPATTI KOVILPATTI TALUK THOOTHUKUDI DISTRICT, Tamil Nadu, 628502",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e60"
    },
    "Rank": 13466,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bokaro General Hospital, ,SECTOR - 4 BOKARO STEEL CITY\nDIST - BOKARO, Jharkhand, 827004",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e61"
    },
    "Rank": 13468,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Little Flower Hospital and Research Centre,,Post Box 23, Angamaly, Ernakulam, Kerala, Kerala, 683572",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e62"
    },
    "Rank": 13470,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e63"
    },
    "Rank": 13471,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Bundelkhand Medical College Sagar M.P.,Govt. Bundelkhand Medical College Sagar M.P., Madhya Pradesh, 470001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e64"
    },
    "Rank": 13472,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tirath Ram Shah Hospital,,2A, RBL Isher Das Sawhney Marg, Rajpur Road,, Delhi (NCT), 110054",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e65"
    },
    "Rank": 13473,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Childrens Hospital, S,KR Puram, Survey No. 8/5, Outer Ring Rd, Doddanekundi, Marathahalli, Bengaluru, Karnataka 560037, Karnataka, 560037",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e66"
    },
    "Rank": 13474,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e67"
    },
    "Rank": 13477,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kokilaben Dhirubhai Ambani Hospital and Medical Research Institute,,Achyutrao Patwardhan Marg Andheri West Mumbai Maharashtra, Maharashtra, 400053",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e68"
    },
    "Rank": 13478,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e69"
    },
    "Rank": 13479,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e6a"
    },
    "Rank": 13480,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Theresa`s Hospital,,7-1-645/A ERRAGADA SANATHNAGAAR HYDERABAD,\nTelangana, 500018",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e6b"
    },
    "Rank": 13481,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e6c"
    },
    "Rank": 13484,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e6d"
    },
    "Rank": 13486,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KIMS Hospital,,#1-112 / 86, Survey No 5 / EE, beside Union Bank, near RTA Office, Kondapur, Hyderabad,Telangana, Telangana, 500084",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e6e"
    },
    "Rank": 13487,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e6f"
    },
    "Rank": 13488,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "DIPLOMA IN CHILD HEALTH/ PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e70"
    },
    "Rank": 13489,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rani Hospital, ,INFRONT OF GOVERNOR HOUSE BOOTY ROAD RANCHI, Jharkhand, 834001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e71"
    },
    "Rank": 13493,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S. K. S. Hospital, ,NO.23,SKS Hospital Road, Alagapuram,Salem, Tamil Nadu, 636004",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e72"
    },
    "Rank": 13494,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e73"
    },
    "Rank": 13495,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.I.O.T. Hospital, ,4 112 MOUNT POONAMALLEE ROAD MANAPAKKAM\nCHENNAI 600089, Tamil Nadu, 600089",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e74"
    },
    "Rank": 13496,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Autonomous State Medical College,\nUttar Pradesh,Autonomous State Medical College, Gaura Danda, Sitapur Road, Hardoi, Uttar Pradesh, 241001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e75"
    },
    "Rank": 13497,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shaheed Hasan Khan Mewati Government Medical College , ,Shaheed Hasan Khan Mewati Government Medical College Nalhar Nuh, Haryana, 122107",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e76"
    },
    "Rank": 13498,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e77"
    },
    "Rank": 13500,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Parsik Hill Road, ,APOLLO HOSPITAL PARSIK HILL ROAD ROAD NO 13 SECTOR 23 CBD BELAPUR NAVI MUMBAI, Maharashtra, 400614",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e78"
    },
    "Rank": 13505,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e79"
    },
    "Rank": 13507,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e7a"
    },
    "Rank": 13509,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e7b"
    },
    "Rank": 13511,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deen Dayal Upadhyay Hospital, ,DDU HOSPITAL, SHAHEED MANGAL PANDEY MARG, NANAK PURA, HARI NAGAR, NEW DELHI, DELHI-110064, Delhi (NCT), 110064",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e7c"
    },
    "Rank": 13512,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e7d"
    },
    "Rank": 13513,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Regency Hospital,,A-2, Sarvodaya Nagar, Kanpur, Uttar Pradesh, 208005",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e7e"
    },
    "Rank": 13514,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e7f"
    },
    "Rank": 13515,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e80"
    },
    "Rank": 13518,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e81"
    },
    "Rank": 13520,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Daya General Hospital,Daya General Hospital Limited Shornur Road NEar Viyyur bridge\nThiruvambady PO Thrissur, Kerala, 680022",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e82"
    },
    "Rank": 13522,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e83"
    },
    "Rank": 13523,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e84"
    },
    "Rank": 13525,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e85"
    },
    "Rank": 13526,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e86"
    },
    "Rank": 13527,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yatharth Superspeciality Hospitals,\nUttar Pradesh,HO-01, Sector-01, Greater Noida West, Uttar Pradesh, 201306",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e87"
    },
    "Rank": 13530,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. Medical College and hospital, ,Govt Medical College  Akola  Collector Office Road Akola Maharshtra, Maharashtra, 444001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e88"
    },
    "Rank": 13531,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e89"
    },
    "Rank": 13534,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e8a"
    },
    "Rank": 13537,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Amar Hospital, ,#8, Bank Colony, Income Tax Office Road, Patiala, Punjab, 147001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e8b"
    },
    "Rank": 13539,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e8c"
    },
    "Rank": 13545,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Polakulath Narayanan Renai Medicity Multi Super Specialty Hospital,,RENAIMEDICITY PALARIVATTOM KOCHI KERALA, Kerala, 682025",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e8d"
    },
    "Rank": 13551,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e8e"
    },
    "Rank": 13552,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lourdes Hospital, ,Ernakulam, Pachalam, Kochi, Kerala, 682012",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e8f"
    },
    "Rank": 13555,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e90"
    },
    "Rank": 13556,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Almadeena Institute of Medical Science, ,ALMAS HOSPITAL, CHANGUVETTY, KOTTAKKAL, MALAPPURAM DISTRICT, Kerala, 676503",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e91"
    },
    "Rank": 13558,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e92"
    },
    "Rank": 13560,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagirathi Neotia Woman and Child Care Centre, West Bengal,BHAGIRATHI NEOTIA WOMAN AND CHILD CARE CENTRE, NEW TOWN PREMISES NO.-27-0327, STREET NO.-327, DG BLO, West Bengal, 700156",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e93"
    },
    "Rank": 13562,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "G Kuppuswamy Naidu Memorial Hospital, ,Post Box No.6327, Nethaji Road, Pappanaickenpalayam, Coimbatore, Tamil Nadu, 641037",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e94"
    },
    "Rank": 13565,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gvernment Medical College,Haldwani,Government Medical College, Haldwani, Uttarakhand, 263139",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e95"
    },
    "Rank": 13566,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e96"
    },
    "Rank": 13569,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e97"
    },
    "Rank": 13571,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e98"
    },
    "Rank": 13576,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e99"
    },
    "Rank": 13577,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e9a"
    },
    "Rank": 13578,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Balaji Action Medical Institute,,FC34 A4 PASCHIM VIHAR NEW DELHI, Delhi (NCT), 110063",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e9b"
    },
    "Rank": 13581,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e9c"
    },
    "Rank": 13582,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "FIMS HOSPITAL(FRANK INSTITUTE OF MEDICAL SCIENCES), Haryana,Sonipat-\nBahalgarh Road, Sonipat-NCR, Sonipat, Haryana, 131001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e9d"
    },
    "Rank": 13585,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fehmicare Hospital, ,8-3-22937, TAHIR VILLA, YOUSUFGUDA HYDERABAD, Telangana, 500045",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e9e"
    },
    "Rank": 13586,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04e9f"
    },
    "Rank": 13588,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aravind Eye Hospital,,64 Sankari Main Road, Nethimedu, Salem, Tamil Nadu, 636002",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ea0"
    },
    "Rank": 13593,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ea1"
    },
    "Rank": 13596,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES,CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, Chhattisgarh, 495001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ea2"
    },
    "Rank": 13597,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Smart Super Specialty Hospital, (Formerly Known as Saket City Hospital), ,Mandir Marg, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ea3"
    },
    "Rank": 13599,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ea4"
    },
    "Rank": 13601,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ea5"
    },
    "Rank": 13603,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ea6"
    },
    "Rank": 13607,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Hospital,,JANGLAT MANDI ANANTNAG, JAMMU AND KASHMIR 192101, Jammu And Kashmir, 192101",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ea7"
    },
    "Rank": 13608,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pimpri Chichwad Municipal Corporation Postgraduate Institute Y.C.M.Hospital Pimpri,Pune 18,Dean Office, Y.C.M.Hospital, Sant Tukaramnagar Pimpri, Pune, Maharashtra, 411018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ea8"
    },
    "Rank": 13609,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ea9"
    },
    "Rank": 13611,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04eaa"
    },
    "Rank": 13614,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Siddh Multi Specialty Hospital,SIDDH MULTISPECIALTY HOSPITAL  HARTHALA INDUSTRIAL ESTATE KANTH ROAD MORADABAD, Uttar Pradesh, 244001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04eab"
    },
    "Rank": 13615,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "DIPLOMA IN CHILD HEALTH/ PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04eac"
    },
    "Rank": 13616,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indira Gandhi Govt. General Hospital and PG Institute, No. 1, ,No.1, Victor Simonel street, Puducherry -1., Puducherry, 605001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ead"
    },
    "Rank": 13618,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04eae"
    },
    "Rank": 13619,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Almadeena Institute of Medical Science, ,ALMAS HOSPITAL, CHANGUVETTY, KOTTAKKAL, MALAPPURAM DISTRICT, Kerala, 676503",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04eaf"
    },
    "Rank": 13622,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lala Harbhagwan Das Memorial and Dr Prem Hospital, ,BISHAN SWAROOP COLONY, OPP. BUS STAND, Haryana, 132103",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04eb0"
    },
    "Rank": 13626,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04eb1"
    },
    "Rank": 13628,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chinmaya Mission Hospital,,Chinmaya Mission Hospital, 80 Feet CMH Road, Indiranagar, Bangalore, Karnataka, 560038",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04eb2"
    },
    "Rank": 13630,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Hospital,377, MAIN ROAD, SATTUR, VIRUDHUNAGAR, Tamil Nadu, 626203",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04eb3"
    },
    "Rank": 13634,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04eb4"
    },
    "Rank": 13635,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sant Parmanand Hospital, ,18 Sham Nath Marg Civil Lines, Delhi (NCT), 110054",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04eb5"
    },
    "Rank": 13636,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04eb6"
    },
    "Rank": 13637,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04eb7"
    },
    "Rank": 13638,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sanjay Gandhi Memorial Hospital, , ,Sanjay Gandhi Memorial Hospital\nS-Block, Near Bus Terminal, Mangolpuri, North West, Delhi - 110083, Delhi (NCT), 110083",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04eb8"
    },
    "Rank": 13640,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BGS Global Hospital, ,BGS Global Hospital, Uttarahalli Road, Kengeri Bangalore, Karnataka, 560060",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04eb9"
    },
    "Rank": 13641,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04eba"
    },
    "Rank": 13643,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ebb"
    },
    "Rank": 13645,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ebc"
    },
    "Rank": 13646,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ebd"
    },
    "Rank": 13647,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ebe"
    },
    "Rank": 13649,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Head Quarters Hospital, Khammam District, Telangana,District Head Quarters Hospital, Khammam District, Telangana, Telangana, 507002",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ebf"
    },
    "Rank": 13655,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ec0"
    },
    "Rank": 13657,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tiruvalla Medical Mission, ,Tiruvalla Medical Mission Post Box 74\nThiruvalla, Kerala, 689101",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ec1"
    },
    "Rank": 13658,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ec2"
    },
    "Rank": 13660,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "P.D. Hinduja National Hospital and Medical Research Centre, ,Veer Savarkar Marg, Mahim, Maharashtra, 400016",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ec3"
    },
    "Rank": 13661,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ec4"
    },
    "Rank": 13663,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ec5"
    },
    "Rank": 13664,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ekta Institute of Child Health, ,Near Ram Mandir. Shantinagar, Raipur, Chhattisgarh, 492001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ec6"
    },
    "Rank": 13669,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster Aadhar Hospital, ,RS NO 628, B WARD NEAR KMT WORKSHOP, SHASTRINAGAR, 416012. KOLHAPUR, Maharashtra, 416012",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ec7"
    },
    "Rank": 13672,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ec8"
    },
    "Rank": 13673,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ec9"
    },
    "Rank": 13676,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B L Kapur Memorial Hospital,Pusa Road New Delhi, Delhi (NCT), 110005",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04eca"
    },
    "Rank": 13679,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ecb"
    },
    "Rank": 13680,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Batra Hospital and Medical Research Centre, ,1, Tughlakabad institutional Area, Mehrauli Badarpur Road, New Delhi, Delhi (NCT), 110062",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ecc"
    },
    "Rank": 13683,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ecd"
    },
    "Rank": 13685,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ece"
    },
    "Rank": 13686,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ecf"
    },
    "Rank": 13688,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jalgaon,Jilha Peth, Government Hospital Campus, Jalgaon, Maharashtra, 425001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ed0"
    },
    "Rank": 13691,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.M. Joshi Eye Hospital, ,Gokul Road, Hosur, Hubli, Karnataka, 580021",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ed1"
    },
    "Rank": 13692,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ed2"
    },
    "Rank": 13696,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ed3"
    },
    "Rank": 13699,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "NEOTIA GETWEL HEALTHCARE CENTRE,\n,Uttorayon, Behind City Centre, Matigara, Siliguri, West Bengal, 734010",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ed4"
    },
    "Rank": 13700,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ed5"
    },
    "Rank": 13701,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Hospital, ,Government Medical College and Associated Hospital, Kheora, Rajouri, Jammu and Kashmir-185131, Jammu And Kashmir, 185131",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ed6"
    },
    "Rank": 13705,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Hospital,377, MAIN ROAD, SATTUR, VIRUDHUNAGAR, Tamil Nadu, 626203",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ed7"
    },
    "Rank": 13709,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Little Flower Hospital and Research Centre,,Post Box 23, Angamaly, Ernakulam, Kerala, Kerala, 683572",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ed8"
    },
    "Rank": 13710,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ed9"
    },
    "Rank": 13712,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta The Medicity, ,Medanta The Medicity Sector 38 Gurgoan, Haryana, 122001",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04eda"
    },
    "Rank": 13713,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04edb"
    },
    "Rank": 13715,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhattar Hospital (Balgopal Children Hospital and Research Institute), ,BYRON BAZAR, OPP ASHIRWAD BHAVAN, RAIPUR, Chhattisgarh, 492001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04edc"
    },
    "Rank": 13716,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Parsik Hill Road, ,APOLLO HOSPITAL PARSIK HILL ROAD ROAD NO 13 SECTOR 23 CBD BELAPUR NAVI MUMBAI, Maharashtra, 400614",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04edd"
    },
    "Rank": 13717,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ede"
    },
    "Rank": 13722,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Spirit Hospital, ,Mahakali Caves Road Andheri East Mumbai, Maharashtra, 400093",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04edf"
    },
    "Rank": 13723,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Shishu Bhawan Hospital,,EIDGAH ROAD MADHYA NAGARI BILASPUR, Chhattisgarh, 495001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ee0"
    },
    "Rank": 13725,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "DIP.IN GYNAE. and OBST.",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ee1"
    },
    "Rank": 13727,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ee2"
    },
    "Rank": 13728,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ee3"
    },
    "Rank": 13731,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Valluvanad Hospital Complex Limited,KANNIYAMPURAM POST OTTAPALAM\nPALAKKAD KERALA, Kerala, 679104",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ee4"
    },
    "Rank": 13732,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ee5"
    },
    "Rank": 13733,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ee6"
    },
    "Rank": 13737,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ee7"
    },
    "Rank": 13738,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lisie Medical Institution, ,Kochi, Kerala, Kerala, 682018",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ee8"
    },
    "Rank": 13739,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ee9"
    },
    "Rank": 13740,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04eea"
    },
    "Rank": 13741,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Diamond Harbour Government Medical College And Hospital, ,Harindanga, New Town, Word No- 4, P.O and P.S- Diamond Harbour, Dist- South 24 Parganas, West Bengal, West Bengal, 743331",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04eeb"
    },
    "Rank": 13744,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04eec"
    },
    "Rank": 13746,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04eed"
    },
    "Rank": 13748,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indraprastha Apollo Hospital,,Sarita Vihar, New Delhi 110 076, Delhi (NCT), 110076",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04eee"
    },
    "Rank": 13749,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04eef"
    },
    "Rank": 13751,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Venkateshwar Hospital, ,Sector- 18 A, Dwarka, New Delhi, Delhi (NCT), 110075",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ef0"
    },
    "Rank": 13752,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ef1"
    },
    "Rank": 13754,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,APOLLO HOSPITALS BANGALORE A UNIT OF IMPERIAL HOSPITALS AND RESEARCH CENTRE LIMITED 154/11 Opp IIM B, Karnataka, 560076",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ef2"
    },
    "Rank": 13755,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SUSHRUT INSTITUTE OF PLASTIC SURGERY PRIVATE LIMITED,\nUttar Pradesh,29 Shahmeena Road Lucknow, Uttar Pradesh, 226003",
    "Course": "(NBEMS) Plastic and Reconstructive Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ef3"
    },
    "Rank": 13756,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ef4"
    },
    "Rank": 13757,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Venkateshwar Hospital, ,Sector- 18 A, Dwarka, New Delhi, Delhi (NCT), 110075",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ef5"
    },
    "Rank": 13760,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asian Institute of Medical Sciences,,p -72,Milapnagar,MIDC,Dombivli E, Maharashtra, 421203",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ef6"
    },
    "Rank": 13762,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ef7"
    },
    "Rank": 13763,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Bundelkhand Medical College Sagar M.P.,Govt. Bundelkhand Medical College Sagar M.P., Madhya Pradesh, 470001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ef8"
    },
    "Rank": 13769,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ef9"
    },
    "Rank": 13771,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04efa"
    },
    "Rank": 13772,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04efb"
    },
    "Rank": 13773,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "P.D. Hinduja National Hospital and Medical Research Centre, ,Veer Savarkar Marg, Mahim, Maharashtra, 400016",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04efc"
    },
    "Rank": 13774,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S. K. S. Hospital, ,NO.23,SKS Hospital Road, Alagapuram,Salem, Tamil Nadu, 636004",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04efd"
    },
    "Rank": 13775,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04efe"
    },
    "Rank": 13777,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Healthworld Hospital, ,Healthworld Hospitals [A Unit of Parashmani Medical Centre Pvt.Ltd.], C-49,Commercial Area, Opp. ESI, West Bengal, 713216",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04eff"
    },
    "Rank": 13778,
    "Allotted Quota": "All India",
    "Allotted Institute": "HOMI BHABHA CANCER HOSPITAL, SANGRUR,Civil Hospital Campus Sangrur City, Punjab, 148001",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f00"
    },
    "Rank": 13780,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f01"
    },
    "Rank": 13782,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f02"
    },
    "Rank": 13784,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sheth Vadilal Sarabhai General Hospital and Sheth Chinai Prasuti Hospital, Gujarat,SHETH VADILAL SARABHAI GENERAL HOSPITAL AND SHETH CHINAI PRASUTI HOSPITAL ELLISBRIDGE AHMEDABAD, Gujarat, 380006",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f03"
    },
    "Rank": 13785,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f04"
    },
    "Rank": 13786,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rani Hospital, ,INFRONT OF GOVERNOR HOUSE BOOTY ROAD RANCHI, Jharkhand, 834001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f05"
    },
    "Rank": 13789,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f06"
    },
    "Rank": 13790,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f07"
    },
    "Rank": 13791,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f08"
    },
    "Rank": 13792,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhattar Hospital (Balgopal Children Hospital and Research Institute), ,BYRON BAZAR, OPP ASHIRWAD BHAVAN, RAIPUR, Chhattisgarh, 492001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f09"
    },
    "Rank": 13793,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f0a"
    },
    "Rank": 13798,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tejasvini Hospital and SSIOT, ,Tejasvini Hospital and SSIOT Kadri Temple Road\nMangalore, Karnataka, 575003",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f0b"
    },
    "Rank": 13799,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ganga Medical Centre and Hospital,,313 Mettupalayam Road Coimbatore 641043, Tamil Nadu, 641043",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f0c"
    },
    "Rank": 13801,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "DIPLOMA IN CHILD HEALTH/ PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f0d"
    },
    "Rank": 13807,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f0e"
    },
    "Rank": 13813,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Hospital,377, MAIN ROAD, SATTUR, VIRUDHUNAGAR, Tamil Nadu, 626203",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f0f"
    },
    "Rank": 13817,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f10"
    },
    "Rank": 13818,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government General Hospital, ,Hospital Rd, Marine Drive, Ernakulam, Kerala 682011, Kerala, 682011",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f11"
    },
    "Rank": 13820,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Adlux Hospital ( A Unit of Adlux Medicity and Convention Centre Private Limited), Kerala\n,Apollo Adlux Hospital (A Unit of Adlux medicity and Convention Centre Private limited), Adlux juncti, Kerala, 683576",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f12"
    },
    "Rank": 13821,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f13"
    },
    "Rank": 13822,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f14"
    },
    "Rank": 13823,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College, ,Office of the Dean, First Floor, Civil Hospital Campus, Sector- 12, Gandhinagar, Gujarat, 382016",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f15"
    },
    "Rank": 13825,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Western India Instt. of Neurosciences, ,183 1, B, Nagala Park, Kolhapur, Maharashtra, 416002",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f16"
    },
    "Rank": 13829,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Malabar Institute of Medical Sciences, ,Govindapuram P.O., Mini Bypass Road, Kozhikode, Kerala, 673016",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f17"
    },
    "Rank": 13834,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Karnataka,Kodagu Institute of Medical Sciences Madikeri Kodagu, Karnataka, 571201",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f18"
    },
    "Rank": 13839,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. Medical College (Maharani Hospital), Jagdalpur, Chhattisgarh,Late Baliram Kashyap memorial Govt. Medical College, Dimrapal, Jagdalpur Dist-Bastar, Chhattisgarh, 494001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f19"
    },
    "Rank": 13840,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f1a"
    },
    "Rank": 13841,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f1b"
    },
    "Rank": 13842,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ganga Medical Centre and Hospital,,313 Mettupalayam Road Coimbatore 641043, Tamil Nadu, 641043",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f1c"
    },
    "Rank": 13844,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f1d"
    },
    "Rank": 13845,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f1e"
    },
    "Rank": 13846,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f1f"
    },
    "Rank": 13849,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sankara Eye Hospital, ,No.1, Third Cross Street, Sri Sankara Nagar, Pammal, Chennai, Tamil Nadu, 600075",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f20"
    },
    "Rank": 13850,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vadamalayan Hospital,,Registered Office - 15, Jawahar Road, Chokkikulam, Madurai - 625002.\n9A, Vallabhai Road, Chokkikula, Tamil Nadu, 625002",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f21"
    },
    "Rank": 13852,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Batra Hospital and Medical Research Centre, ,1, Tughlakabad institutional Area, Mehrauli Badarpur Road, New Delhi, Delhi (NCT), 110062",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f22"
    },
    "Rank": 13853,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f23"
    },
    "Rank": 13854,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pratiksha Hospital, Assam,Barbari, VIP Road, Hengrabari, Guwahati, Assam, 781036",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f24"
    },
    "Rank": 13858,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K C General Hospital, ,No. 89, 5th Cross, Malleshwaram, Bangalore, Karnataka, 560003",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f25"
    },
    "Rank": 13859,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f26"
    },
    "Rank": 13860,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "P.D. Hinduja National Hospital and Medical Research Centre, ,Veer Savarkar Marg, Mahim, Maharashtra, 400016",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f27"
    },
    "Rank": 13861,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Meenakshi Mission Hospital and Research Centre, ,Lake Area, Melur Road, Madurai, Tamil Nadu, 625107",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f28"
    },
    "Rank": 13864,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f29"
    },
    "Rank": 13867,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f2a"
    },
    "Rank": 13868,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharashtra Post Graduate Institute of Medical Education and Research, Nashik,Maharashtra Post Graduation Institute Of Medical Education and Research Civil Hospital campus Trimba, Maharashtra, 422002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f2b"
    },
    "Rank": 13870,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "J.L.N. Main Hospital and Research Centre, ,Bhilai Steel Plant Sector-9, Bhilai, C.G., Chhattisgarh, 490009",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f2c"
    },
    "Rank": 13873,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f2d"
    },
    "Rank": 13874,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f2e"
    },
    "Rank": 13875,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "DIP.IN GYNAE. and OBST.",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f2f"
    },
    "Rank": 13876,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f30"
    },
    "Rank": 13878,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Parsik Hill Road, ,APOLLO HOSPITAL PARSIK HILL ROAD ROAD NO 13 SECTOR 23 CBD BELAPUR NAVI MUMBAI, Maharashtra, 400614",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f31"
    },
    "Rank": 13879,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f32"
    },
    "Rank": 13884,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f33"
    },
    "Rank": 13885,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f34"
    },
    "Rank": 13888,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Mission Hospital, West Bengal\n,The Mission Hospital, 219 P, Immon Kalyan Sarani, Sector-2C, Bidhannagar, Durgapur, Dist- Paschim Ba, West Bengal, 713212",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f35"
    },
    "Rank": 13889,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Cosmopolitan Hospital,,COSMOPOLITAN HOSPITALS Pvt.Ltd, Murinjapalam, Pattom P O, Thiruvananthapuram, Kerala, 695004",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f36"
    },
    "Rank": 13890,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharashtra Post Graduate Institute of Medical Education and Research, Nashik,Maharashtra Post Graduation Institute Of Medical Education and Research Civil Hospital campus Trimba, Maharashtra, 422002",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f37"
    },
    "Rank": 13894,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f38"
    },
    "Rank": 13897,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f39"
    },
    "Rank": 13899,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f3a"
    },
    "Rank": 13900,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f3b"
    },
    "Rank": 13901,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "North 24 Parganas District Hospital, ,BANAMALIPUR BARASAT WEST BENGAL, West Bengal, 700124",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f3c"
    },
    "Rank": 13902,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f3d"
    },
    "Rank": 13904,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f3e"
    },
    "Rank": 13906,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f3f"
    },
    "Rank": 13907,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f40"
    },
    "Rank": 13908,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rani Hospital, ,INFRONT OF GOVERNOR HOUSE BOOTY ROAD RANCHI, Jharkhand, 834001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f41"
    },
    "Rank": 13910,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sree Uthradom Tirunal Hospital,,Pattom, Trivandrum, Kerala, 695004",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f42"
    },
    "Rank": 13911,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvarur Govt.Medical College,Thiruvarur Govt.Medical College, Tamil Nadu, 610004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f43"
    },
    "Rank": 13914,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f44"
    },
    "Rank": 13916,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f45"
    },
    "Rank": 13921,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f46"
    },
    "Rank": 13923,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vivekanandha Medical Care Hospital , ,Sf. No. 20-1 Elayampalayam village, Tiruchengode taluk\nNamakkal, Tamil Nadu, 637205",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f47"
    },
    "Rank": 13930,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESIC MEDICAL COLLEGE AND HOSPITAL, Bihar,ESIC Medical College and Hospital,Ara Danapur Road, Sikandarpur, Bihta, Patna, Bihar, 801103",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f48"
    },
    "Rank": 13932,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chinmaya Mission Hospital,,Chinmaya Mission Hospital, 80 Feet CMH Road, Indiranagar, Bangalore, Karnataka, 560038",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f49"
    },
    "Rank": 13933,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f4a"
    },
    "Rank": 13934,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f4b"
    },
    "Rank": 13940,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f4c"
    },
    "Rank": 13942,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Poonam Palaspagar - Academic Coordinator\nFortis Hospital, Bannerghatta Road, Bangalore, Karnataka 5, Karnataka, 560076",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f4d"
    },
    "Rank": 13948,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Hospital,377, MAIN ROAD, SATTUR, VIRUDHUNAGAR, Tamil Nadu, 626203",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f4e"
    },
    "Rank": 13949,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f4f"
    },
    "Rank": 13952,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f50"
    },
    "Rank": 13954,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f51"
    },
    "Rank": 13957,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f52"
    },
    "Rank": 13959,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f53"
    },
    "Rank": 13960,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f54"
    },
    "Rank": 13961,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f55"
    },
    "Rank": 13964,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f56"
    },
    "Rank": 13965,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Fatima Hospital, ,35-C, MAHANAGAR, LUCKNOW, UP, Uttar Pradesh, 226006",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f57"
    },
    "Rank": 13967,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f58"
    },
    "Rank": 13970,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Sathya Sai Institute of Higher Medical Sciences, ,PRASANTHIGRAM , PUTTAPARTHI\nANANTAPUR, Andhra Pradesh, 515134",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f59"
    },
    "Rank": 13971,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f5a"
    },
    "Rank": 13973,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f5b"
    },
    "Rank": 13974,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f5c"
    },
    "Rank": 13975,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f5d"
    },
    "Rank": 13976,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f5e"
    },
    "Rank": 13978,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f5f"
    },
    "Rank": 13980,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr M K Shah Medical College And SMS Multispeciality Hospital,,Near Tapovan circle,\nVisat Gandhinagar Highway chandkheda ahmedabad, Gujarat, 382424",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f60"
    },
    "Rank": 13981,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f61"
    },
    "Rank": 13983,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f62"
    },
    "Rank": 13984,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f63"
    },
    "Rank": 13993,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Doon Medical College, Dehradun,Govt. Doon Medical College Dehrakhas, Patel Nagar Dehradun 248001, Uttarakhand, 248001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f64"
    },
    "Rank": 13994,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES,CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, Chhattisgarh, 495001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f65"
    },
    "Rank": 13995,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rex Hospital, ,43, R.R.Layout, Behind Shanmuga Theatre, Poomarket Bus Stop, Mettupalayam Road, Coimbatore - 641002, Tamil Nadu, 641002",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f66"
    },
    "Rank": 13997,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Netralaya, Narayana Health City Narayana Hrudayalaya Campus ,258 A, Narayana Health City, Bommasandra, Hosur Road, Anekal Taluk, Bangalore, Karnataka, 560099",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f67"
    },
    "Rank": 14001,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ahalia Foundation Eye Hospital, ,Ahalia Campus Kozhippara Post Palakkad Kerala 678557, Kerala, 678557",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f68"
    },
    "Rank": 14003,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "DIPLOMA IN CHILD HEALTH/ PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f69"
    },
    "Rank": 14005,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College,Tumuki, Bihaguri, Tezpur, Sonitpur, Assam, Assam, 784010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f6a"
    },
    "Rank": 14007,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f6b"
    },
    "Rank": 14008,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f6c"
    },
    "Rank": 14013,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f6d"
    },
    "Rank": 14015,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kaveri Medical Centre and Hospital,,No-1, KC Road, Tennur, Trichy-620017, Tamil Nadu, 620017",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f6e"
    },
    "Rank": 14016,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f6f"
    },
    "Rank": 14017,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Agarwal`s Eye Hospital and Research Centre, ,222, TTK Road, Alwarpet, Chennai, Tamil Nadu, 600018",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f70"
    },
    "Rank": 14018,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f71"
    },
    "Rank": 14020,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "V Y Institute of Medical Science, ,Near Kamal Vihar, Dhamtari Road, Raipur, Chhattisgarh, 492001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f72"
    },
    "Rank": 14021,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Khan Bahadur Bhabha Hospital, Maharashtra,Khan Bahadur Bhabha Hospital Belgrami road kurla west, Maharashtra, 400070",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f73"
    },
    "Rank": 14022,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Care Hospital,,Care Hospitals Road No1 Banjara Hills Hyderabad Telangana, Telangana, 500034",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f74"
    },
    "Rank": 14026,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District General Hospital, Ramachandraraopeta, Eluru, West Godavari District, Andhra Prad, Andhra Pradesh, 534006,Government District General Hospital, Ramachandraraopeta, Eluru, West Godavari District, Andhra Prad, Andhra Pradesh, 534006",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f75"
    },
    "Rank": 14029,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f76"
    },
    "Rank": 14032,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f77"
    },
    "Rank": 14034,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f78"
    },
    "Rank": 14035,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f79"
    },
    "Rank": 14036,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f7a"
    },
    "Rank": 14038,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f7b"
    },
    "Rank": 14042,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Ophthalmology,RIO Prev Known as Nehru Institute of Ophthalmology and Research Centre Civil Lines Sitapur, Uttar Pradesh, 261001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f7c"
    },
    "Rank": 14044,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f7d"
    },
    "Rank": 14045,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saifee Hospital, ,postbox no 3771, 15-17 Maharshi Karve Marg, Charni road, Mumbai, Maharashtra, 400004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f7e"
    },
    "Rank": 14046,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f7f"
    },
    "Rank": 14047,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f80"
    },
    "Rank": 14049,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f81"
    },
    "Rank": 14050,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f82"
    },
    "Rank": 14058,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Care Hospital,,Care Hospitals Road No1 Banjara Hills Hyderabad Telangana, Telangana, 500034",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f83"
    },
    "Rank": 14061,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f84"
    },
    "Rank": 14066,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f85"
    },
    "Rank": 14067,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "JLNM Hospital, ,JLNM Hospital Rainawari Srinagar, Jammu And Kashmir, 190003",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f86"
    },
    "Rank": 14068,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f87"
    },
    "Rank": 14069,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f88"
    },
    "Rank": 14071,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f89"
    },
    "Rank": 14072,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN,HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN, Karnataka, 573201",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f8a"
    },
    "Rank": 14074,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f8b"
    },
    "Rank": 14075,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Healthworld Hospital, ,Healthworld Hospitals [A Unit of Parashmani Medical Centre Pvt.Ltd.], C-49,Commercial Area, Opp. ESI, West Bengal, 713216",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f8c"
    },
    "Rank": 14078,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f8d"
    },
    "Rank": 14080,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f8e"
    },
    "Rank": 14087,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Superspeciality Hospital, Assam,Near Tolaram Bafna Medical Complex Amingaon Guwahati, Assam, 781031",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f8f"
    },
    "Rank": 14088,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f90"
    },
    "Rank": 14089,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Venkateswara Aravind Eye Hospital, Andhra Pradesh,No 588 A, Alipiri to zoo park road, beside NCC campus, tirupati, Andhra Pradesh, 517505",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f91"
    },
    "Rank": 14090,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f92"
    },
    "Rank": 14092,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f93"
    },
    "Rank": 14094,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Cross Hospital, ,Holy Cross Hospital, Kottiyam P O, Kollam District, Kerala, 691571",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f94"
    },
    "Rank": 14096,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indraprastha Apollo Hospital,,Sarita Vihar, New Delhi 110 076, Delhi (NCT), 110076",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f95"
    },
    "Rank": 14097,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Gleneagles Hospital, ,58 Canal Circular Road Kolkata 700054, West Bengal, 700054",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f96"
    },
    "Rank": 14098,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nazareth Hospital, Laitumukhrah, ,Laitumkhrah Shillong, East Khasi Hills district, Meghalaya, 793003",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f97"
    },
    "Rank": 14099,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.M. Joshi Eye Hospital, ,Gokul Road, Hosur, Hubli, Karnataka, 580021",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f98"
    },
    "Rank": 14100,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f99"
    },
    "Rank": 14104,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f9a"
    },
    "Rank": 14106,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mata Kaushalya Governement Hospital, ,NEAR DISTRICT COURT LAHORI GATE PATIALA, Punjab, 147001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f9b"
    },
    "Rank": 14108,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f9c"
    },
    "Rank": 14112,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f9d"
    },
    "Rank": 14114,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "DIP.IN GYNAE. and OBST.",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f9e"
    },
    "Rank": 14117,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04f9f"
    },
    "Rank": 14118,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital, ,Nellikuppam Main Road, Cuddalore, Tamil Nadu, 607001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fa0"
    },
    "Rank": 14119,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kongunad Hospitals Private Limited, ,116,117 11th Street, Tatabad,Opposite to east power house, Tamil Nadu, 641012",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fa1"
    },
    "Rank": 14121,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Gondia,Nehru Chowk, KTS General Hospital Campus, Govt. Medical College, Gondia. 441601. Maharashtra., Maharashtra, 441601",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fa2"
    },
    "Rank": 14123,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.J. Somaiya Medical College,,SOMAIYA AYURVIHAR , EASTERN EXPRESS HIGHWAY, SION, MUMBAI 400022, Maharashtra, 400022",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fa3"
    },
    "Rank": 14125,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "NEOTIA GETWEL HEALTHCARE CENTRE,\n,Uttorayon, Behind City Centre, Matigara, Siliguri, West Bengal, 734010",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fa4"
    },
    "Rank": 14130,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fa5"
    },
    "Rank": 14132,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fa6"
    },
    "Rank": 14135,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fa7"
    },
    "Rank": 14137,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fa8"
    },
    "Rank": 14144,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajagiri Hospital,Rajagiri Hospital, Chunagamvely, Aluva 683112, Kerala, 683112",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fa9"
    },
    "Rank": 14145,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shifaa Hospital, ,NO 332, QUEENS ROAD, SHIVAJINAGAR, Karnataka, 560052",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04faa"
    },
    "Rank": 14146,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fab"
    },
    "Rank": 14147,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fac"
    },
    "Rank": 14148,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Gleneagles Hospital, ,58 Canal Circular Road Kolkata 700054, West Bengal, 700054",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fad"
    },
    "Rank": 14150,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Martha`s Hospital , ,No.5, Nrupathunga Road, Bengaluru, Karnataka, 560001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fae"
    },
    "Rank": 14151,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "J.L.N. Main Hospital and Research Centre, ,Bhilai Steel Plant Sector-9, Bhilai, C.G., Chhattisgarh, 490009",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04faf"
    },
    "Rank": 14155,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indraprastha Apollo Hospital,,Sarita Vihar, New Delhi 110 076, Delhi (NCT), 110076",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fb0"
    },
    "Rank": 14158,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fb1"
    },
    "Rank": 14159,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Masina Hospital, ,Sant Savta Marg Byculla East Mumbai, Maharashtra, 400027",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fb2"
    },
    "Rank": 14160,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fb3"
    },
    "Rank": 14164,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fb4"
    },
    "Rank": 14167,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kasturba Hospital, Daryaganj,Delhi,Daryaganj,Delhi",
    "Course": "DIP.IN GYNAE. and OBST.",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fb5"
    },
    "Rank": 14168,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fb6"
    },
    "Rank": 14169,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Diamond Harbour Government Medical College And Hospital, ,Harindanga, New Town, Word No- 4, P.O and P.S- Diamond Harbour, Dist- South 24 Parganas, West Bengal, West Bengal, 743331",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fb7"
    },
    "Rank": 14170,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fb8"
    },
    "Rank": 14171,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital,,FC 50, Max Wali Rd, C and D Block, Shalimar Place Site, Shalimar Bagh, New Delhi, Delhi 110088, Delhi (NCT), 110088",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fb9"
    },
    "Rank": 14172,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mehta Multispecialty Hospital, (Formerly Known as Dr. Mehta`s Hospital) ,No.2, McNichols Road, 3 rd Lane, Chetpet, Chennai, Tamil Nadu, 600031",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fba"
    },
    "Rank": 14175,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lourdes Hospital, ,Ernakulam, Pachalam, Kochi, Kerala, 682012",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fbb"
    },
    "Rank": 14176,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "JOSHI HOSPITAL,\nPunjab,JOSHI HOSPITAL KAPURTHALA CHOWK JALANDHAR PUNJAB 144001,\nPunjab, 144001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fbc"
    },
    "Rank": 14179,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo BGS Hospital,,Apollo BGS Hospitals, Adhichunchangiri Road, Kuvempunagar, Mysore, Karnataka, 570023",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fbd"
    },
    "Rank": 14183,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "P.D. Hinduja National Hospital and Medical Research Centre, ,Veer Savarkar Marg, Mahim, Maharashtra, 400016",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fbe"
    },
    "Rank": 14184,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "DIPLOMA IN ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fbf"
    },
    "Rank": 14188,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fc0"
    },
    "Rank": 14189,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fc1"
    },
    "Rank": 14193,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fc2"
    },
    "Rank": 14194,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fc3"
    },
    "Rank": 14198,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Christian Institute of Health Sciences and Research, ,4th Mile, Central Jail Road, Dimapur, Nagaland, 797115",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fc4"
    },
    "Rank": 14199,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fc5"
    },
    "Rank": 14200,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,Nalgonda X Roads, Malakpet, Hyderabad, Telangana, 500036",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fc6"
    },
    "Rank": 14201,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. R. D. B. P. Jaipuria Hospital, (Attached with RUHS College of Medical Sciences),,GOVTRDBPJAIPURIAHOSPITALJAIPUR@GMAIL.COM, Rajasthan, 302018",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fc7"
    },
    "Rank": 14204,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fc8"
    },
    "Rank": 14206,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sadguru Nethra Chikitsalaya, ,Shri Sadguru Seva Sangh Trust Jankikund Chitrakoot\nDistrict Satna, Madhya Pradesh, 210204",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fc9"
    },
    "Rank": 14207,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sadguru Nethra Chikitsalaya, ,Shri Sadguru Seva Sangh Trust Jankikund Chitrakoot\nDistrict Satna, Madhya Pradesh, 210204",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fca"
    },
    "Rank": 14213,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lilavati Hospital and Research Centre, ,Lilavati Hospital And Research Centre A-791, Bandra Reclamation, Bandra - West, Mumbai, Maharashtra, 400050",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fcb"
    },
    "Rank": 14214,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fcc"
    },
    "Rank": 14217,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fcd"
    },
    "Rank": 14218,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bharat Ratna Late Shri Atal Bihari Vajpayee Memorial Medical College, Chhattisgarh,BHARAT RATNA LATE SHRI ATAL BIHARI VAJPAYEE MEMEORIAL MEDICAL COLLEGE, RAJNANDGAON. CG, Chhattisgarh, 491441",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fce"
    },
    "Rank": 14219,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Netralaya, Narayana Health City Narayana Hrudayalaya Campus ,258 A, Narayana Health City, Bommasandra, Hosur Road, Anekal Taluk, Bangalore, Karnataka, 560099",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fcf"
    },
    "Rank": 14220,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fd0"
    },
    "Rank": 14224,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaiprakash Hospital and Research Centre Pvt. Ltd, Odisha,,JAIPRAKASH HOSPITAL AND RESEARCH CENTER PVT. LTD., DANDIAPALI, ROURKELA, Odisha, 769004",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fd1"
    },
    "Rank": 14227,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fd2"
    },
    "Rank": 14228,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sadguru Nethra Chikitsalaya, ,Shri Sadguru Seva Sangh Trust Jankikund Chitrakoot\nDistrict Satna, Madhya Pradesh, 210204",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fd3"
    },
    "Rank": 14232,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fd4"
    },
    "Rank": 14235,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fd5"
    },
    "Rank": 14236,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Institute of Medical Sciences (SVIMS), A State University estd. by Govt. of A.P.,Sri Venkateswara Institute of Medical Sciences, SVIMS, Alipiri Road, Tirupati, Andhra Pradesh, 517507",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fd6"
    },
    "Rank": 14237,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fd7"
    },
    "Rank": 14241,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Swami Dayanand Hospital, ,Dilshad Garden, Shahdara, Delhi, Delhi (NCT), 110095",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fd8"
    },
    "Rank": 14243,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,SECTOR-6, PANCHKULA, HARYANA-134108, Haryana, 134108",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fd9"
    },
    "Rank": 14244,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital International, ,Plot 1A, GIDC Estate, Bhat Gandhinagar, Gujarat, 382428",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fda"
    },
    "Rank": 14245,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Institute of Mental Health, Govt. Mental Hospital, ,Circular Road, Amritsar, Punjab, 143001",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fdb"
    },
    "Rank": 14246,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fdc"
    },
    "Rank": 14247,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fdd"
    },
    "Rank": 14248,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fde"
    },
    "Rank": 14249,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College,Fakhruddin Ali Ahmed Medical College, Assam, 781301",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fdf"
    },
    "Rank": 14251,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ford Hospital and Research Centre Pvt Ltd, Bihar,NH-30, New Bypass Khemnichak, Ramakrishna Nagar, Patna, Bihar, 800027",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fe0"
    },
    "Rank": 14253,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nazareth Hospital, Laitumukhrah, ,Laitumkhrah Shillong, East Khasi Hills district, Meghalaya, 793003",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fe1"
    },
    "Rank": 14254,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fe2"
    },
    "Rank": 14255,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fe3"
    },
    "Rank": 14256,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, ,1,2, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fe4"
    },
    "Rank": 14257,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fe5"
    },
    "Rank": 14258,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fe6"
    },
    "Rank": 14259,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fe7"
    },
    "Rank": 14261,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Martha`s Hospital , ,No.5, Nrupathunga Road, Bengaluru, Karnataka, 560001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fe8"
    },
    "Rank": 14262,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fe9"
    },
    "Rank": 14267,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fea"
    },
    "Rank": 14269,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04feb"
    },
    "Rank": 14273,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fec"
    },
    "Rank": 14280,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo BGS Hospital,,Apollo BGS Hospitals, Adhichunchangiri Road, Kuvempunagar, Mysore, Karnataka, 570023",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fed"
    },
    "Rank": 14283,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fee"
    },
    "Rank": 14284,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fef"
    },
    "Rank": 14285,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ff0"
    },
    "Rank": 14287,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mothers Care Children Hospital and Research Centre, ,SAGOLBAND MOIRANG LEIRAK, IMPHAL WEST, Manipur, 795001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ff1"
    },
    "Rank": 14291,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ff2"
    },
    "Rank": 14292,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jupiter Hospital, ,Eastern Express Highway, Thane West, Maharashtra, 400601",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ff3"
    },
    "Rank": 14293,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ff4"
    },
    "Rank": 14296,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ff5"
    },
    "Rank": 14297,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ff6"
    },
    "Rank": 14299,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ff7"
    },
    "Rank": 14300,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ff8"
    },
    "Rank": 14301,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Zen Multispeciality Hospital, Maharashtra,425, 10th Road, Chembur, Mumbai, Maharashtra, 400071",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ff9"
    },
    "Rank": 14304,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ffa"
    },
    "Rank": 14305,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "HOSMAT Hospital, ,NO.45, MAGRATH ROAD, OFF RICHMOND ROAD, BANGALORE,\nKarnataka, 560025",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ffb"
    },
    "Rank": 14308,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ffc"
    },
    "Rank": 14310,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaiprakash Hospital and Research Centre Pvt. Ltd, Odisha,,JAIPRAKASH HOSPITAL AND RESEARCH CENTER PVT. LTD., DANDIAPALI, ROURKELA, Odisha, 769004",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ffd"
    },
    "Rank": 14317,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04ffe"
    },
    "Rank": 14319,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a04fff"
    },
    "Rank": 14321,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05000"
    },
    "Rank": 14322,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05001"
    },
    "Rank": 14324,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05002"
    },
    "Rank": 14326,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05003"
    },
    "Rank": 14327,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05004"
    },
    "Rank": 14328,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05005"
    },
    "Rank": 14329,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05006"
    },
    "Rank": 14330,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05007"
    },
    "Rank": 14331,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05008"
    },
    "Rank": 14335,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05009"
    },
    "Rank": 14337,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vadamalayan Hospital,,Registered Office - 15, Jawahar Road, Chokkikulam, Madurai - 625002.\n9A, Vallabhai Road, Chokkikula, Tamil Nadu, 625002",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0500a"
    },
    "Rank": 14338,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0500b"
    },
    "Rank": 14341,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0500c"
    },
    "Rank": 14343,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kidwai Memorial Institute of Oncolgy,Kidwai Memorial Institute of Oncolgy, Karnataka, 560029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0500d"
    },
    "Rank": 14344,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR GOVERNMENT MEDICAL COLLEGE,NELLORE,Opp AC Subbareddy Stadium\nDargamitta Nellore, Andhra Pradesh, 524004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0500e"
    },
    "Rank": 14345,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0500f"
    },
    "Rank": 14349,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05010"
    },
    "Rank": 14354,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Polakulath Narayanan Renai Medicity Multi Super Specialty Hospital,,RENAIMEDICITY PALARIVATTOM KOCHI KERALA, Kerala, 682025",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05011"
    },
    "Rank": 14357,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Alakh Nayan Mandir Insttitute of Ophthalmology, ,Ashok Nagar, Udaipur - 313001, Rajasthan, 313001",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05012"
    },
    "Rank": 14360,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharashtra Post Graduate Institute of Medical Education and Research, Nashik,Maharashtra Post Graduation Institute Of Medical Education and Research Civil Hospital campus Trimba, Maharashtra, 422002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05013"
    },
    "Rank": 14361,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05014"
    },
    "Rank": 14362,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lourdes Hospital, ,Ernakulam, Pachalam, Kochi, Kerala, 682012",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05015"
    },
    "Rank": 14366,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medical Trust Hospital, ,M.G. Road Pallimukku\nCochin, Kerala, 682016",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05016"
    },
    "Rank": 14371,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05017"
    },
    "Rank": 14373,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lisie Medical Institution, ,Kochi, Kerala, Kerala, 682018",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05018"
    },
    "Rank": 14376,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Ganapati Nethralaya, ,Near Janata high school, Jalna, Maharashtra, 431203",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05019"
    },
    "Rank": 14379,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Civil Hospital GURDASPUR, Punjab,smodhgsp@gmail.com, Punjab, 143521",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0501a"
    },
    "Rank": 14380,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0501b"
    },
    "Rank": 14389,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College,Fakhruddin Ali Ahmed Medical College, Assam, 781301",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0501c"
    },
    "Rank": 14392,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0501d"
    },
    "Rank": 14393,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Batra Hospital and Medical Research Centre, ,1, Tughlakabad institutional Area, Mehrauli Badarpur Road, New Delhi, Delhi (NCT), 110062",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0501e"
    },
    "Rank": 14396,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tejasvini Hospital and SSIOT, ,Tejasvini Hospital and SSIOT Kadri Temple Road\nMangalore, Karnataka, 575003",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0501f"
    },
    "Rank": 14398,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05020"
    },
    "Rank": 14399,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaypee Hospital,,sector 128, Noida, Uttar Pradesh, 201304",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05021"
    },
    "Rank": 14400,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sahyadri Super Specialty Hospital,,Plot No.30C, Erandawane, Karve Road, Pune, Maharashtra, 411004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05022"
    },
    "Rank": 14401,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05023"
    },
    "Rank": 14406,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bokaro General Hospital, ,SECTOR - 4 BOKARO STEEL CITY\nDIST - BOKARO, Jharkhand, 827004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05024"
    },
    "Rank": 14407,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ford Hospital and Research Centre Pvt Ltd, Bihar,NH-30, New Bypass Khemnichak, Ramakrishna Nagar, Patna, Bihar, 800027",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05025"
    },
    "Rank": 14408,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.S. ( Traumatology and Surgery )",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05026"
    },
    "Rank": 14409,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital International, ,Plot 1A, GIDC Estate, Bhat Gandhinagar, Gujarat, 382428",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05027"
    },
    "Rank": 14411,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05028"
    },
    "Rank": 14412,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05029"
    },
    "Rank": 14413,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0502a"
    },
    "Rank": 14414,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0502b"
    },
    "Rank": 14418,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0502c"
    },
    "Rank": 14419,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kurji Holy Family Hospital, ,Kurji Holy Family Hospital\nP.O - Sadaquat Ashram, Bihar, 800010",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0502d"
    },
    "Rank": 14420,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Credence Hospital, ,Near Ulloor Bridge Ulloor Medical College P O Trivandrum, Kerala, 695011",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0502e"
    },
    "Rank": 14421,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "EMS Memorial Co-operative Hospital and Research Centre, ,Panambi, Perintalmanna, Malappuram District, Kerala, 679322",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0502f"
    },
    "Rank": 14424,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital,,GMERS Medical College and Hospital Campus, Dharpur, Patan-Unjha State Highway, Patan (North Gujarat,, Gujarat, 384265",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05030"
    },
    "Rank": 14425,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "PRS Hospital,,PRS HOSPITAL PVT LTD KILLIPALAM THIRUVANATHAPURAM\nKERALA. <academics@prshospital.com>, Kerala, 695002",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05031"
    },
    "Rank": 14427,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kokilaben Dhirubhai Ambani Hospital and Medical Research Institute,,Achyutrao Patwardhan Marg Andheri West Mumbai Maharashtra, Maharashtra, 400053",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05032"
    },
    "Rank": 14428,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Associated Hospital Govt. Medical College, ,Makeshift Campus, Near Bus Stand, Doda City, Doda, Jammu and Kashmir, Jammu And Kashmir, 182202",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05033"
    },
    "Rank": 14429,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05034"
    },
    "Rank": 14431,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Babasaheb Ambedkar Central Railway Hospital, ,Bharatratna Dr. Babasaheb Ambedkar Memorial Hospital, Central Railway, Dr. Babasaheb Ambedkar Road,, Maharashtra, 400027",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05035"
    },
    "Rank": 14432,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05036"
    },
    "Rank": 14433,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,OFFICE OF SUPERINTENDENT 9TH FLOOR SSH PO JALPAIGURI,\nWest Bengal, 735101",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05037"
    },
    "Rank": 14434,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ernakulam Medical Centre,,ERNAKULAM MEDICAL CENTRE NH BYPASS, KOCHI, KERALA, INDIA - 682028, Kerala, 682028",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05038"
    },
    "Rank": 14437,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05039"
    },
    "Rank": 14439,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0503a"
    },
    "Rank": 14441,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.I.O.T. Hospital, ,4 112 MOUNT POONAMALLEE ROAD MANAPAKKAM\nCHENNAI 600089, Tamil Nadu, 600089",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0503b"
    },
    "Rank": 14447,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Hospital, Omandurar,GOVERNMENT MEDICAL COLLEGE, OMANDURAR GOVERNMENT ESTATE, WALAJAH ROAD, NEAR KALAIVANAR ARANGAM, CHEN, Tamil Nadu, 600002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0503c"
    },
    "Rank": 14450,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Christian Fellowship Hospital, ,Dindigul Palani Main Road Oddanchatram\nDindigul District, Tamil Nadu, 624619",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0503d"
    },
    "Rank": 14455,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0503e"
    },
    "Rank": 14457,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lilavati Hospital and Research Centre, ,Lilavati Hospital And Research Centre A-791, Bandra Reclamation, Bandra - West, Mumbai, Maharashtra, 400050",
    "Course": "(NBEMS) Paediatric Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0503f"
    },
    "Rank": 14458,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05040"
    },
    "Rank": 14459,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES ADILABAD -504001 TELANGANA STATE, Telangana, 504001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05041"
    },
    "Rank": 14460,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Batra Hospital and Medical Research Centre, ,1, Tughlakabad institutional Area, Mehrauli Badarpur Road, New Delhi, Delhi (NCT), 110062",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05042"
    },
    "Rank": 14461,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government DB General Medical Hosptal,Churu, Nayabass, Churu",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05043"
    },
    "Rank": 14467,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05044"
    },
    "Rank": 14469,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Civil Hospital Shillong, Meghalaya,Civil Hospital Shillong Laban Shillong East Khasi Hills Meghalaya 793001, Meghalaya, 793001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05045"
    },
    "Rank": 14471,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05046"
    },
    "Rank": 14472,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College,Tumuki, Bihaguri, Tezpur, Sonitpur, Assam, Assam, 784010",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05047"
    },
    "Rank": 14473,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05048"
    },
    "Rank": 14474,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05049"
    },
    "Rank": 14476,
    "Allotted Quota": "All India",
    "Allotted Institute": "NSC Government Medical College, Khandwa (MP),LUV KUSH NAGAR,MUNDI NAGAR, KHANDWA, Madhya Pradesh, 450001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0504a"
    },
    "Rank": 14478,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0504b"
    },
    "Rank": 14479,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0504c"
    },
    "Rank": 14481,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District General Hospital,,Central Jail Road, Rajamahendravaram, East Godavari District, Andhra Pradesh, 533105",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0504d"
    },
    "Rank": 14486,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "P.D. Hinduja National Hospital and Medical Research Centre, ,Veer Savarkar Marg, Mahim, Maharashtra, 400016",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0504e"
    },
    "Rank": 14488,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0504f"
    },
    "Rank": 14489,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05050"
    },
    "Rank": 14491,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05051"
    },
    "Rank": 14493,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05052"
    },
    "Rank": 14498,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05053"
    },
    "Rank": 14499,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Life line Super Specialty Hospital, ,14th Mile, Melood P.O Adoor , Pathanamthitta Dist, Kerala, 691554",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05054"
    },
    "Rank": 14502,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05055"
    },
    "Rank": 14507,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05056"
    },
    "Rank": 14510,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05057"
    },
    "Rank": 14511,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kongunad Hospitals Private Limited, ,116,117 11th Street, Tatabad,Opposite to east power house, Tamil Nadu, 641012",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05058"
    },
    "Rank": 14512,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05059"
    },
    "Rank": 14513,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0505a"
    },
    "Rank": 14515,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0505b"
    },
    "Rank": 14516,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0505c"
    },
    "Rank": 14517,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GG Hospital, Murinjapalam Junction, ,Murinjapalam Junction, Medical College Post, Trivandrum-695011, Kerala, 695011",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0505d"
    },
    "Rank": 14524,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0505e"
    },
    "Rank": 14527,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0505f"
    },
    "Rank": 14528,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vadamalayan Hospital,,Registered Office - 15, Jawahar Road, Chokkikulam, Madurai - 625002.\n9A, Vallabhai Road, Chokkikula, Tamil Nadu, 625002",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05060"
    },
    "Rank": 14530,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "G Kuppuswamy Naidu Memorial Hospital, ,Post Box No.6327, Nethaji Road, Pappanaickenpalayam, Coimbatore, Tamil Nadu, 641037",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05061"
    },
    "Rank": 14533,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05062"
    },
    "Rank": 14534,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05063"
    },
    "Rank": 14536,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05064"
    },
    "Rank": 14537,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05065"
    },
    "Rank": 14538,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,RAJBHAVAN ROAD SOMAJIGUDA, Telangana, 500082",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05066"
    },
    "Rank": 14545,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital,,FC 50, Max Wali Rd, C and D Block, Shalimar Place Site, Shalimar Bagh, New Delhi, Delhi 110088, Delhi (NCT), 110088",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05067"
    },
    "Rank": 14547,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05068"
    },
    "Rank": 14548,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital , ,GMERS MEDICAL COLLEGE , Hadiyol-Gadhoda Road, Near-Government Polytechnic, Hiimmatnagar, Gujarat, 383001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05069"
    },
    "Rank": 14549,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0506a"
    },
    "Rank": 14551,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0506b"
    },
    "Rank": 14552,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aakash Healthcare Super Specialty Hospital, Delhi,HOSPITAL PLOT, ROAD NO- 201,SECTOR 3 DWARKA, Delhi (NCT), 110075",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0506c"
    },
    "Rank": 14553,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0506d"
    },
    "Rank": 14556,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0506e"
    },
    "Rank": 14557,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kidwai Memorial Institute of Oncolgy,Kidwai Memorial Institute of Oncolgy, Karnataka, 560029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0506f"
    },
    "Rank": 14558,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05070"
    },
    "Rank": 14559,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nazareth Hospital, Laitumukhrah, ,Laitumkhrah Shillong, East Khasi Hills district, Meghalaya, 793003",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05071"
    },
    "Rank": 14561,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hayat Hospital, ,kahilipara Road, Odalbakra, Lalganesh,Guwahati, Assam, 781034",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05072"
    },
    "Rank": 14563,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05073"
    },
    "Rank": 14565,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05074"
    },
    "Rank": 14567,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bokaro General Hospital, ,SECTOR - 4 BOKARO STEEL CITY\nDIST - BOKARO, Jharkhand, 827004",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05075"
    },
    "Rank": 14572,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kailash Hospital and Heart Institute,\nUttar Pradesh,H-33 SECTOR  27 NOIDA, Uttar Pradesh, 201301",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05076"
    },
    "Rank": 14573,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05077"
    },
    "Rank": 14579,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05078"
    },
    "Rank": 14580,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05079"
    },
    "Rank": 14581,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rabindranath Tagore International Institute of Cardiac Sciences, ,PREMISES NO. 1489 124 MUKUNDAPUR  E M BYPASS  KOLKATA-700099, West Bengal, 700099",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0507a"
    },
    "Rank": 14585,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0507b"
    },
    "Rank": 14586,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KD Hospital,KD Hospital, Vaishnodevi circle, S. G Road, Ahmedabad\nGujarat- 382421., Gujarat, 382421",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0507c"
    },
    "Rank": 14587,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0507d"
    },
    "Rank": 14588,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,DISTRICT HOSPITAL NEAR SANGAM CIRCLE DR RAJ KUMAR ROAD BALLARI-583101 KARNATAKA, Karnataka, 583101",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0507e"
    },
    "Rank": 14589,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Udhampur ,\nJammu and Kashmir,Sallian Talab Dhar Road Udhampur, Jammu And Kashmir, 182101",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0507f"
    },
    "Rank": 14590,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lilavati Hospital and Research Centre, ,Lilavati Hospital And Research Centre A-791, Bandra Reclamation, Bandra - West, Mumbai, Maharashtra, 400050",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05080"
    },
    "Rank": 14591,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05081"
    },
    "Rank": 14593,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05082"
    },
    "Rank": 14598,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05083"
    },
    "Rank": 14600,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS Medical College Valsad,Nanakwada Halar Road,Valsad ,Gujarat, Gujarat, 396001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05084"
    },
    "Rank": 14602,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05085"
    },
    "Rank": 14603,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05086"
    },
    "Rank": 14604,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ICare Eye Hospital and Post Graduate Institute,,ICARE Eye Hospital and Post Graduate Institute, E-3A, Sector-26, Noida, Uttar Pradesh-201301, Uttar Pradesh, 201301",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05087"
    },
    "Rank": 14606,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05088"
    },
    "Rank": 14608,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "DIP.IN MEDICAL RADIO- DIAGNOSIS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05089"
    },
    "Rank": 14611,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Fatima Hospital, ,35-C, MAHANAGAR, LUCKNOW, UP, Uttar Pradesh, 226006",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0508a"
    },
    "Rank": 14614,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0508b"
    },
    "Rank": 14615,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agrasen Medical College,,NH- 9, VPO - Agroha, District Hisar, Haryana, 125047",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0508c"
    },
    "Rank": 14618,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saifee Hospital, ,postbox no 3771, 15-17 Maharshi Karve Marg, Charni road, Mumbai, Maharashtra, 400004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0508d"
    },
    "Rank": 14619,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0508e"
    },
    "Rank": 14621,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0508f"
    },
    "Rank": 14625,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05090"
    },
    "Rank": 14626,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, ,1,2, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05091"
    },
    "Rank": 14627,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05092"
    },
    "Rank": 14628,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Santokbha Durlabhji Memorial Hospital Cum Medical Research Institute,\n,dnb.sdmh@gmail.com, Rajasthan, 302015",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05093"
    },
    "Rank": 14629,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05094"
    },
    "Rank": 14631,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kaveri Medical Centre and Hospital,,No-1, KC Road, Tennur, Trichy-620017, Tamil Nadu, 620017",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05095"
    },
    "Rank": 14632,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata Shrimati Devendra Kumari Singhdeo Government Medical College, Ambikapur,Kanyaparisar  Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05096"
    },
    "Rank": 14635,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05097"
    },
    "Rank": 14636,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05098"
    },
    "Rank": 14639,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "G Kuppuswamy Naidu Memorial Hospital, ,Post Box No.6327, Nethaji Road, Pappanaickenpalayam, Coimbatore, Tamil Nadu, 641037",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05099"
    },
    "Rank": 14641,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0509a"
    },
    "Rank": 14644,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0509b"
    },
    "Rank": 14647,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0509c"
    },
    "Rank": 14649,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Hurkisondas Nurrotumdas Hospital and Research Centre, ,Prarthana Samaj, Raja Ram Mohan Roy road,\nGirgaon, MUMBAI, Maharashtra, 400004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0509d"
    },
    "Rank": 14651,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0509e"
    },
    "Rank": 14653,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0509f"
    },
    "Rank": 14656,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital\nNext to Sainik School second gate Athani Road, Vijayapura Karnataka 586103, Karnataka, 586103",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050a0"
    },
    "Rank": 14659,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050a1"
    },
    "Rank": 14661,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, (A unit of Balaji Medical and Diagnostic Research Centre)\n,Max Super Specialty Hospital -108A IP Extension Patparganj New Delhi, Delhi (NCT), 110092",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050a2"
    },
    "Rank": 14665,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Superspeciality Hospital, Assam,Near Tolaram Bafna Medical Complex Amingaon Guwahati, Assam, 781031",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050a3"
    },
    "Rank": 14672,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050a4"
    },
    "Rank": 14674,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050a5"
    },
    "Rank": 14676,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050a6"
    },
    "Rank": 14683,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050a7"
    },
    "Rank": 14685,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050a8"
    },
    "Rank": 14689,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asha Hospital, ,?Asha Hospital 8-2-316/12/A, Rd #14,\nBanjara Hills, Hyderabad, Telangana State - 500 034\nOff: 04, Telangana, 500034",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050a9"
    },
    "Rank": 14690,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050aa"
    },
    "Rank": 14692,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050ab"
    },
    "Rank": 14694,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Madras Medical Mission Hospital, ,NO-4A, DR J J NAGAR, MOGAPPAIR, CHENNAI, Tamil Nadu, 600037",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050ac"
    },
    "Rank": 14695,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "DIPLOMA IN CHILD HEALTH/ PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050ad"
    },
    "Rank": 14698,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050ae"
    },
    "Rank": 14701,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deben Mahato (Sadar) Hospital,,Main Road Near SP office PO Purulia Main PS Purulia Town Dist Purulia West Bengal, West Bengal, 723101",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050af"
    },
    "Rank": 14703,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050b0"
    },
    "Rank": 14704,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute of Medical Sciences Srikakulam,Government Medical College Srikakulam\nBalaga Srikakulam Town Srikakulam District, Andhra Pradesh, 532001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050b1"
    },
    "Rank": 14708,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050b2"
    },
    "Rank": 14710,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050b3"
    },
    "Rank": 14711,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050b4"
    },
    "Rank": 14712,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.G. Hospital and PG Medical Institute ,,No:5, Arts College Road, Coimbatore - 641018, Tamil Nadu, 641018",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050b5"
    },
    "Rank": 14714,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050b6"
    },
    "Rank": 14715,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050b7"
    },
    "Rank": 14716,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050b8"
    },
    "Rank": 14717,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050b9"
    },
    "Rank": 14718,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,Nalgonda X Roads, Malakpet, Hyderabad, Telangana, 500036",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050ba"
    },
    "Rank": 14720,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Village Lingiadih,,Apollo Hospitals Bilaspur Seepat Road  Lingyadih Village  Bilaspur, Chhattisgarh, 495006",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050bb"
    },
    "Rank": 14721,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050bc"
    },
    "Rank": 14722,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050bd"
    },
    "Rank": 14724,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050be"
    },
    "Rank": 14725,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050bf"
    },
    "Rank": 14726,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050c0"
    },
    "Rank": 14727,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rabindranath Tagore International Institute of Cardiac Sciences, ,PREMISES NO. 1489 124 MUKUNDAPUR  E M BYPASS  KOLKATA-700099, West Bengal, 700099",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050c1"
    },
    "Rank": 14730,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050c2"
    },
    "Rank": 14731,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050c3"
    },
    "Rank": 14734,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B L Kapur Memorial Hospital,Pusa Road New Delhi, Delhi (NCT), 110005",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050c4"
    },
    "Rank": 14737,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050c5"
    },
    "Rank": 14738,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050c6"
    },
    "Rank": 14739,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050c7"
    },
    "Rank": 14748,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.I.O.T. Hospital, ,4 112 MOUNT POONAMALLEE ROAD MANAPAKKAM\nCHENNAI 600089, Tamil Nadu, 600089",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050c8"
    },
    "Rank": 14749,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.E.M. Hospital, ,489, SARDAR MOODLIAR ROAD, RASTA PETH, PUNE 411011,\nMaharashtra, 411011",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050c9"
    },
    "Rank": 14751,
    "Allotted Quota": "All India",
    "Allotted Institute": "BHOPAL MEMORIAL HOSPITAL AND RESEARCH CENTRE, BHOPAL,Raisen By pass\nRoad, Bhopal- 462038 (M.P.), Madhya Pradesh, 462038",
    "Course": "M.Ch. (Neuro Surgery)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050ca"
    },
    "Rank": 14753,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050cb"
    },
    "Rank": 14755,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital, B.H.Road, Tumakuru-572101, Karnataka, 572101",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050cc"
    },
    "Rank": 14759,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050cd"
    },
    "Rank": 14760,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kidwai Memorial Institute of Oncolgy,Kidwai Memorial Institute of Oncolgy, Karnataka, 560029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050ce"
    },
    "Rank": 14761,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Dada Dev Matri Avum Shishu Chikitsalaya, ,Dabri, New Delhi-110045, Delhi (NCT), 110045",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050cf"
    },
    "Rank": 14766,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050d0"
    },
    "Rank": 14768,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050d1"
    },
    "Rank": 14770,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asansol District Hospital, ,S. B. GORAI ROAD, P.O.- ASANSOL, DIST.- PASCHIM BARDHAMAN, West Bengal, 713301",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050d2"
    },
    "Rank": 14774,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050d3"
    },
    "Rank": 14778,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050d4"
    },
    "Rank": 14782,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050d5"
    },
    "Rank": 14783,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050d6"
    },
    "Rank": 14784,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050d7"
    },
    "Rank": 14785,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050d8"
    },
    "Rank": 14788,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050d9"
    },
    "Rank": 14797,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050da"
    },
    "Rank": 14798,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "QRG Medicare, ,Plot No. 1, Sector-16, Faridabad, Haryana, Haryana, 121002",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050db"
    },
    "Rank": 14800,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050dc"
    },
    "Rank": 14801,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050dd"
    },
    "Rank": 14804,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050de"
    },
    "Rank": 14806,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050df"
    },
    "Rank": 14812,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050e0"
    },
    "Rank": 14813,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B.R. Ambedkar State Institute of Medical Sciences, Punjab,aimsestablishment@gmail.com, Punjab, 160055",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050e1"
    },
    "Rank": 14814,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "PRS Hospital,,PRS HOSPITAL PVT LTD KILLIPALAM THIRUVANATHAPURAM\nKERALA. <academics@prshospital.com>, Kerala, 695002",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050e2"
    },
    "Rank": 14817,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050e3"
    },
    "Rank": 14820,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hayat Hospital, ,kahilipara Road, Odalbakra, Lalganesh,Guwahati, Assam, 781034",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050e4"
    },
    "Rank": 14821,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shanmuga Hospital and Salem Cancer Institute, ,24/51 SARADA COLLEGE ROAD\n,SHANMUGA HOSPITAL, SALEM TAMILNADU 636007, Tamil Nadu, 636007",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050e5"
    },
    "Rank": 14833,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KD Hospital,KD Hospital, Vaishnodevi circle, S. G Road, Ahmedabad\nGujarat- 382421., Gujarat, 382421",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050e6"
    },
    "Rank": 14835,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Prashanthi Hospital, # ,7-3-94, Machilibazar, hanamkonda, Warangal, Telangana, 506011",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050e7"
    },
    "Rank": 14837,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "FAKIR MOHAN MEDICAL COLLEGE AND HOSPITAL ,\nOrissa,AT-Remuna Post-Remuna Dist-Balasore, Odisha, 756019",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050e8"
    },
    "Rank": 14841,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital International, ,Plot 1A, GIDC Estate, Bhat Gandhinagar, Gujarat, 382428",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050e9"
    },
    "Rank": 14844,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050ea"
    },
    "Rank": 14846,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Gokulam Hospital,,3 60 MEYYANUR ROAD FIVE ROADS\nSALEM - 636004, Tamil Nadu, 636004",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050eb"
    },
    "Rank": 14850,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "RENOVA NEELIMA HOSPITAL,Renova Neelima Hospital H.No  7 2 1735 A\nOpp Voltas CZECH Colony, Sanathnagar, Hyderabad, Telangana, 500018",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050ec"
    },
    "Rank": 14851,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050ed"
    },
    "Rank": 14852,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050ee"
    },
    "Rank": 14854,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050ef"
    },
    "Rank": 14861,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR GOVERNMENT MEDICAL COLLEGE,NELLORE,Opp AC Subbareddy Stadium\nDargamitta Nellore, Andhra Pradesh, 524004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050f0"
    },
    "Rank": 14862,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lal Bahadur Shastri Hosptial, ,LBSH MAYUR VIHAR PHASE 2 KHICHRIPUR 110091, Delhi (NCT), 110091",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050f1"
    },
    "Rank": 14864,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Metas Adventist Hospital, ,PB NO. 24, OPP KP COMMERCE COLLEGE, ATHWALINES,\nGujarat, 395001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050f2"
    },
    "Rank": 14869,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, THOOTHUKUDI,GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, 3rd\nMile,Kamaraj Nagar,Thoothukudi., Tamil Nadu, 628008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050f3"
    },
    "Rank": 14870,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050f4"
    },
    "Rank": 14872,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050f5"
    },
    "Rank": 14875,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo BGS Hospital,,Apollo BGS Hospitals, Adhichunchangiri Road, Kuvempunagar, Mysore, Karnataka, 570023",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050f6"
    },
    "Rank": 14878,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050f7"
    },
    "Rank": 14879,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050f8"
    },
    "Rank": 14882,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050f9"
    },
    "Rank": 14883,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050fa"
    },
    "Rank": 14884,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050fb"
    },
    "Rank": 14885,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050fc"
    },
    "Rank": 14886,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050fd"
    },
    "Rank": 14890,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster Medcity, Kuttisahib Road,,Aster Medcity Kuttisahib Road\nSouth Chittoor P.O. Cheranalloor\nKochi, Kerala, 682027",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050fe"
    },
    "Rank": 14891,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lakshmi Hospital, ,DIWANS ROAD, ERNAKULAM, KOCHI-682016, Kerala, 682016",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a050ff"
    },
    "Rank": 14893,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05100"
    },
    "Rank": 14894,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shrimad Rajchandra Hospital And Research Centre, Gujarat,Shrimad Rajchandra Hospital And Research Centre, Dharampur Bypass Road, At. Sherimal Post. Bilpudi, , Gujarat, 396050",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05101"
    },
    "Rank": 14896,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "DIPLOMA IN CHILD HEALTH/ PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05102"
    },
    "Rank": 14898,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K B Bhabha Municipal General Hospital, ,R K PATKAR ROAD ,BANDRA WEST\n,MUMBAI, Maharashtra, 400050",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05103"
    },
    "Rank": 14900,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05104"
    },
    "Rank": 14902,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05105"
    },
    "Rank": 14903,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jubilee Mission Hospital, ,Bishop Alappatt Road, Jubilee Mission P.O. Thrissur, Kerala, 680005",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05106"
    },
    "Rank": 14904,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05107"
    },
    "Rank": 14905,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05108"
    },
    "Rank": 14906,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Drashti Nethralaya, ,DRASHTI NETRALAYA CHAKALIA ROAD\nDAHOD-389151, Gujarat, 389151",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05109"
    },
    "Rank": 14909,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0510a"
    },
    "Rank": 14912,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mydhili Hospital, ,ICS Road Gudur SPSR Nellore District, Andhra Pradesh, 524101",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0510b"
    },
    "Rank": 14913,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Centre for Sight, Delhi,Centre For Sight, Plot No. 9, Sector 9, Dwarka, Delhi, 110075, Delhi (NCT), 110075",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0510c"
    },
    "Rank": 14919,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kanchi Kamakoti Childs Trust Hospital, ,12 A, NAGESWARA ROAD, NUNGAMBAKKAM, CHENNAI., Tamil Nadu, 600034",
    "Course": "(NBEMS) Paediatric Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0510d"
    },
    "Rank": 14921,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0510e"
    },
    "Rank": 14929,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0510f"
    },
    "Rank": 14931,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05110"
    },
    "Rank": 14934,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05111"
    },
    "Rank": 14936,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jehangir Hospital, ,32 Sasoon Road Pune, Maharashtra, 411001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05112"
    },
    "Rank": 14938,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05113"
    },
    "Rank": 14940,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lilavati Hospital and Research Centre, ,Lilavati Hospital And Research Centre A-791, Bandra Reclamation, Bandra - West, Mumbai, Maharashtra, 400050",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05114"
    },
    "Rank": 14942,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tomo Riba Institute of Health and Medical Sciences,,TRIHMS. Old Assembly Block. Near Rajiv Gandhi Stadium. Naharlagun-791110. Arunachal Pradesh, Arunachal Pradesh, 791110",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05115"
    },
    "Rank": 14943,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05116"
    },
    "Rank": 14945,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "DIPLOMA IN DIABETOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05117"
    },
    "Rank": 14948,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Santosh Hospital, ,No.6/1, Promenade Road, Bangalore.560 005, Karnataka, 560005",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05118"
    },
    "Rank": 14949,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ispat General Hospital,,Ispat General Hospital Rourkela Steel Plant SAIL Rourkela 769005, Odisha, 769005",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05119"
    },
    "Rank": 14952,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0511a"
    },
    "Rank": 14954,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0511b"
    },
    "Rank": 14963,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0511c"
    },
    "Rank": 14964,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM Eye Institute,5TH MILE, VIDHAN SABHA ROAD, RAIPUR, Chhattisgarh, 493111",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0511d"
    },
    "Rank": 14965,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Siliguri District Hospital,,Kachari Road, Siliguri, Dist- Darjeeling, West Bengal, West Bengal, 734001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0511e"
    },
    "Rank": 14966,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0511f"
    },
    "Rank": 14968,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05120"
    },
    "Rank": 14978,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Veerangana Awanti Bai Women Hospital, ,sic.vabwhlko@gmail.com, Uttar Pradesh, 226018",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05121"
    },
    "Rank": 14979,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05122"
    },
    "Rank": 14982,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Martha`s Hospital , ,No.5, Nrupathunga Road, Bengaluru, Karnataka, 560001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05123"
    },
    "Rank": 14984,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05124"
    },
    "Rank": 14988,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Giridhar Eye Institute , ,Ponneth Temple Road Kadavanthra\nCochin, Kerala, 682020",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05125"
    },
    "Rank": 14990,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05126"
    },
    "Rank": 14993,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District Hospital,,POOLBAGH ROAD,NEAR FRUIT MARKET,ANAKAPALLI,VISAKHAPATNAM,ANDHRAPRADESH., Andhra Pradesh, 531001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05127"
    },
    "Rank": 14994,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Babasaheb Ambedkar Central Railway Hospital, ,Bharatratna Dr. Babasaheb Ambedkar Memorial Hospital, Central Railway, Dr. Babasaheb Ambedkar Road,, Maharashtra, 400027",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05128"
    },
    "Rank": 14995,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05129"
    },
    "Rank": 15001,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0512a"
    },
    "Rank": 15003,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "HOSMAT Hospital, ,NO.45, MAGRATH ROAD, OFF RICHMOND ROAD, BANGALORE,\nKarnataka, 560025",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0512b"
    },
    "Rank": 15004,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0512c"
    },
    "Rank": 15005,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0512d"
    },
    "Rank": 15006,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0512e"
    },
    "Rank": 15007,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Punjab Institute of Medical Scineces ,Punjab Institute of Medical Sciences Garha Road Jalandhar, Punjab, 144006",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0512f"
    },
    "Rank": 15011,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05130"
    },
    "Rank": 15019,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05131"
    },
    "Rank": 15020,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Bhausaheb Hire Government Medical College,Sri Bhausaheb Hire Government Medical College,Dhule, Maharashtra, 424311",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05132"
    },
    "Rank": 15022,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05133"
    },
    "Rank": 15024,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Hurkisondas Nurrotumdas Hospital and Research Centre, ,Prarthana Samaj, Raja Ram Mohan Roy road,\nGirgaon, MUMBAI, Maharashtra, 400004",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05134"
    },
    "Rank": 15026,
    "Allotted Quota": "All India",
    "Allotted Institute": "GADAG INSTITUTE OF MEDICAL SCIENCES GADAG,Gadag Institute of Medical Sciences Mallasamudra Gadag-582103, Karnataka, 582103",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05135"
    },
    "Rank": 15028,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05136"
    },
    "Rank": 15029,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05137"
    },
    "Rank": 15031,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05138"
    },
    "Rank": 15032,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute of Medical Sciences Srikakulam,Government Medical College Srikakulam\nBalaga Srikakulam Town Srikakulam District, Andhra Pradesh, 532001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05139"
    },
    "Rank": 15035,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0513a"
    },
    "Rank": 15039,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "VASUNDHARA HOSPITAL,\nRajasthan,Sector-11, Nandanwan, Chopasni Housing Board, Jodhpur, Rajasthan, 342008",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0513b"
    },
    "Rank": 15040,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0513c"
    },
    "Rank": 15043,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0513d"
    },
    "Rank": 15046,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jehangir Hospital, ,32 Sasoon Road Pune, Maharashtra, 411001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0513e"
    },
    "Rank": 15048,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Alchemist Hospital, Haryana,Alchemist Hospital Sector 21, Panchkula, Haryana, 134112",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0513f"
    },
    "Rank": 15050,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS) Plastic and Reconstructive Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05140"
    },
    "Rank": 15052,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM Hospital and Research Centre Pvt Ltd.,\nBihar,Jagat Narayan Lal Road, East Lohanipur, Kadamkuan, Patna 800003, Bihar, 800003",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05141"
    },
    "Rank": 15054,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05142"
    },
    "Rank": 15060,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05143"
    },
    "Rank": 15061,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "AMAR HOSPITAL, Karnataka\n,Amar Hospital, Julai Nagar, Gangavathi, Karnataka, 583227",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05144"
    },
    "Rank": 15062,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,SECTOR-6, PANCHKULA, HARYANA-134108, Haryana, 134108",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05145"
    },
    "Rank": 15063,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05146"
    },
    "Rank": 15065,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Imambara District Hospital,,AKHANBAZAR ROAD, CHINSURA, HOOGHLY, West Bengal, 712101",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05147"
    },
    "Rank": 15066,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhabha Atomic Research Centre and Hospital, ,Anushaktinagar, Mumbai, Maharashtra, 400094",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05148"
    },
    "Rank": 15068,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05149"
    },
    "Rank": 15069,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sanjay Gandhi Postgarduate Institute of Medical Sciences,Sanjay Gandhi Postgarduate Institute of Medical Sciences, Uttar Pradesh, 226014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0514a"
    },
    "Rank": 15071,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0514b"
    },
    "Rank": 15073,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Northern Railway Central Hospital,, ,Basant Lane New Delhi, Delhi (NCT), 110055",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0514c"
    },
    "Rank": 15081,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0514d"
    },
    "Rank": 15083,
    "Allotted Quota": "All India",
    "Allotted Institute": "LATE SHRI LAKHI RAM AGRAWAL MEMORIAL GOVT.MEDICAL COLLEGE,LATE SHRI LAKHI RAM AGRAWAL MEMORIAL GOVT.MEDICAL COLLEGE, Chhattisgarh, 496001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0514e"
    },
    "Rank": 15084,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0514f"
    },
    "Rank": 15085,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05150"
    },
    "Rank": 15089,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Autonomous State Medical College and Allied Pandit Ram Prasad Bismil Memorial Hospital,\n,ASMC, JIGNERA, kanth road, shahjahanpur, Uttar Pradesh, 242001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05151"
    },
    "Rank": 15090,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05152"
    },
    "Rank": 15091,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District hospital chitradurga, B D road beside DHO office Chitradurga, Karnataka, 577501",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05153"
    },
    "Rank": 15092,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B. Nanavati Hospital, Vivekanand Rd,Swami Vivekananda Rd, near LIC, LIC Colony, Suresh Colony, Vile Parle West, Mumbai, Maharashtra 4000, Maharashtra, 400056",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05154"
    },
    "Rank": 15093,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hannah Joseph Hospital ,\nTamil Nadu,R.S. No 115-3B2 and 116-1A2, Madurai-Tuticorin Ring Road,\nChinthamani, Madurai 625009\nTamilna, Tamil Nadu, 625009",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05155"
    },
    "Rank": 15094,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Down Town Hospital, G S Road, ,G S Road, Dispur, Guwahati-781006, Assam, 781006",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05156"
    },
    "Rank": 15096,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05157"
    },
    "Rank": 15097,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05158"
    },
    "Rank": 15100,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kalpana Chawla Govt Medical College, ,Model Town, Karnal, Haryana, 132001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05159"
    },
    "Rank": 15101,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0515a"
    },
    "Rank": 15104,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shrimad Rajchandra Hospital And Research Centre, Gujarat,Shrimad Rajchandra Hospital And Research Centre, Dharampur Bypass Road, At. Sherimal Post. Bilpudi, , Gujarat, 396050",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0515b"
    },
    "Rank": 15105,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0515c"
    },
    "Rank": 15108,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL CANCER CENTRE,REGIONAL CANCER CENTRE, Kerala, 695011",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0515d"
    },
    "Rank": 15109,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Kamakshi Memorial Hospital, (Dr. Kamakshi Institute of Medical Sciences and Research) ,No.1,Radial Road , Pallikaranai, Chennai, Tamil Nadu, 600100",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0515e"
    },
    "Rank": 15110,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0515f"
    },
    "Rank": 15112,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05160"
    },
    "Rank": 15114,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05161"
    },
    "Rank": 15117,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05162"
    },
    "Rank": 15120,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05163"
    },
    "Rank": 15122,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Mulund - Goregaon Link Rd, Nahur West, Industrial Area, Bhandup West, Mumbai, Maharashtra 400078, Maharashtra, 400078",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05164"
    },
    "Rank": 15123,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05165"
    },
    "Rank": 15125,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05166"
    },
    "Rank": 15131,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05167"
    },
    "Rank": 15132,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Siddipet,SURVEY NO 54  GOVERNMENT MEDICAL COLLEGE  ENSANPALLY  SIDDIPET   502114, Telangana, 502114",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05168"
    },
    "Rank": 15133,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital , ,GMERS MEDICAL COLLEGE , Hadiyol-Gadhoda Road, Near-Government Polytechnic, Hiimmatnagar, Gujarat, 383001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05169"
    },
    "Rank": 15135,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Zen Multispeciality Hospital, Maharashtra,425, 10th Road, Chembur, Mumbai, Maharashtra, 400071",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0516a"
    },
    "Rank": 15136,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0516b"
    },
    "Rank": 15140,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0516c"
    },
    "Rank": 15141,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Life line Super Specialty Hospital, ,14th Mile, Melood P.O Adoor , Pathanamthitta Dist, Kerala, 691554",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0516d"
    },
    "Rank": 15144,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0516e"
    },
    "Rank": 15147,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0516f"
    },
    "Rank": 15148,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government Women and Children Hospital, (Formerly Indira Gandhi.\nGeneral Hospital) ,100 feet road, Ellaipillaichavady, Puducherry, 605005",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05170"
    },
    "Rank": 15151,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05171"
    },
    "Rank": 15153,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,Nalgonda X Roads, Malakpet, Hyderabad, Telangana, 500036",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05172"
    },
    "Rank": 15156,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05173"
    },
    "Rank": 15157,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05174"
    },
    "Rank": 15158,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05175"
    },
    "Rank": 15160,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05176"
    },
    "Rank": 15161,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05177"
    },
    "Rank": 15162,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Netralaya, Narayana Health City Narayana Hrudayalaya Campus ,258 A, Narayana Health City, Bommasandra, Hosur Road, Anekal Taluk, Bangalore, Karnataka, 560099",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05178"
    },
    "Rank": 15163,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B. Nanavati Hospital, Vivekanand Rd,Swami Vivekananda Rd, near LIC, LIC Colony, Suresh Colony, Vile Parle West, Mumbai, Maharashtra 4000, Maharashtra, 400056",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05179"
    },
    "Rank": 15164,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Christian Fellowship Hospital, ,Dindigul Palani Main Road Oddanchatram\nDindigul District, Tamil Nadu, 624619",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0517a"
    },
    "Rank": 15165,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0517b"
    },
    "Rank": 15167,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0517c"
    },
    "Rank": 15168,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0517d"
    },
    "Rank": 15173,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0517e"
    },
    "Rank": 15177,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0517f"
    },
    "Rank": 15179,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bai Jerbai Wadia Hospital for Children, Parel, Mumbai, Maharashtra,Acharya Donde Marg Parel Mumbai, Maharashtra, 400012",
    "Course": "(NBEMS) Paediatric Surgery (Direct 6 Years Course)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05180"
    },
    "Rank": 15182,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05181"
    },
    "Rank": 15183,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05182"
    },
    "Rank": 15184,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Laxmi Narasimha Hospital, Telangana,H NO 2 2 316 BESIDE PUSHPANJALI FUNCTION HALL NAIM NAGAR HANAMKONDA, Telangana, 506001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05183"
    },
    "Rank": 15189,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dharwad Institute of Mental Health and Neurosciences (DIMHANS),Dharwad Institute of Mental Health and Neurosciences, Belagavi Road, Dharwad, Karnataka, 580008",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05184"
    },
    "Rank": 15190,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05185"
    },
    "Rank": 15198,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, Karnataka,General Hospital Doddaballapur, Near TB Circle, Chikkaballapur Main Road, Doddaballapur Taluk, Benga, Karnataka, 561203",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05186"
    },
    "Rank": 15200,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE - KANNUR,GOVERNMENT MEDICAL COLLEGE\n- KANNUR, Kerala, 670503",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05187"
    },
    "Rank": 15208,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kokilaben Dhirubhai Ambani Hospital and Medical Research Institute,,Achyutrao Patwardhan Marg Andheri West Mumbai Maharashtra, Maharashtra, 400053",
    "Course": "(NBEMS) PHYSICAL\nMED. and REHABILITATION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05188"
    },
    "Rank": 15209,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05189"
    },
    "Rank": 15210,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0518a"
    },
    "Rank": 15214,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0518b"
    },
    "Rank": 15220,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jagannath Gupta Institute of Medical Sciences and Hospital, ,BUITA, K P MONDAL ROAD, BUDGE BUDGE, SOUTH 24 PARGANAS, KOLKATA, West Bengal, 700137",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0518c"
    },
    "Rank": 15224,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0518d"
    },
    "Rank": 15227,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0518e"
    },
    "Rank": 15229,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College,Fakhruddin Ali Ahmed Medical College, Assam, 781301",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0518f"
    },
    "Rank": 15232,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05190"
    },
    "Rank": 15233,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asha Hospital, ,?Asha Hospital 8-2-316/12/A, Rd #14,\nBanjara Hills, Hyderabad, Telangana State - 500 034\nOff: 04, Telangana, 500034",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05191"
    },
    "Rank": 15237,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05192"
    },
    "Rank": 15239,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr Shyama Prasad Mukherjee (Civil) Hospital, ,Park Road, Hazratganj, Lucknow, Uttar Pradesh, 226001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05193"
    },
    "Rank": 15241,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "JBMM District Hospital , ,JBMM DISTRICT HOSPITAL RAM BAGH PHATAK NANDAN CINEMA\nCHOWK AMRITSAR AMRITSAR AMRITSAR IN\n143001, Punjab, 143001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05194"
    },
    "Rank": 15243,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Gregorios Medical Mission Hospital, Kerala,St. Gregorios Medical Mission Hospital, Parumala, Pathanamthitta Kerala, Kerala, 689626",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05195"
    },
    "Rank": 15245,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indira Gandhi Govt. General Hospital and PG Institute, No. 1, ,No.1, Victor Simonel street, Puducherry -1., Puducherry, 605001",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05196"
    },
    "Rank": 15247,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ashwini Trauma Centre, ,plot no.1-COM-14-Sector-1 CDA BIDANASHI Cuttack 753014, Odisha, 753014",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05197"
    },
    "Rank": 15248,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05198"
    },
    "Rank": 15250,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05199"
    },
    "Rank": 15255,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0519a"
    },
    "Rank": 15256,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0519b"
    },
    "Rank": 15257,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0519c"
    },
    "Rank": 15258,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0519d"
    },
    "Rank": 15259,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0519e"
    },
    "Rank": 15260,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0519f"
    },
    "Rank": 15262,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051a0"
    },
    "Rank": 15266,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital,,DNB office, 3rd Floor Opp Library  Apollo Hospitals, Jubilee Hills, Hyderabad, Telangana, 500033",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051a1"
    },
    "Rank": 15268,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051a2"
    },
    "Rank": 15270,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051a3"
    },
    "Rank": 15271,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051a4"
    },
    "Rank": 15274,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051a5"
    },
    "Rank": 15275,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT DHARMAPURI MEDICAL COLLEGE, DHARMAPURI, TAMIL NADU,GOVERNMENT DHARMAPURI MEDICAL COLLEGE, NETHAJI BYE PASS ROAD, DHARMAPURI, TAMIL NADU, Tamil Nadu, 636701",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051a6"
    },
    "Rank": 15276,
    "Allotted Quota": "All India",
    "Allotted Institute": "Late Baliram Kashyap Memorial Govt Medical Dimrapal,Late Baliram Kashyap Memorial Govt Medical Dimrapal, Chhattisgarh, 494001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051a7"
    },
    "Rank": 15279,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051a8"
    },
    "Rank": 15281,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES ADILABAD -504001 TELANGANA STATE, Telangana, 504001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051a9"
    },
    "Rank": 15282,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051aa"
    },
    "Rank": 15284,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051ab"
    },
    "Rank": 15285,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shalby Hospital, ,8TH Floor, shalby  hopsital , opp. karnwati club, sg highway ahmedabad, Gujarat, 380015",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051ac"
    },
    "Rank": 15289,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051ad"
    },
    "Rank": 15291,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital,,DNB office, 3rd Floor Opp Library  Apollo Hospitals, Jubilee Hills, Hyderabad, Telangana, 500033",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051ae"
    },
    "Rank": 15292,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mata Chanan Devi Hospital,, ,C-1 JANAKPURI, Delhi (NCT), 110058",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051af"
    },
    "Rank": 15297,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vivekananda Polyclinic and Institute of Medical Sciences, ,Vivekananda Polyclinic and Institute of Medical Sciences, Vivekananda Puram, Lucknow, Uttar Pradesh, 226007",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051b0"
    },
    "Rank": 15298,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051b1"
    },
    "Rank": 15302,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051b2"
    },
    "Rank": 15303,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Paediatric Surgery Centre and P.G. Institute, Maharashtra,8750 Vishrambag Sangli, Maharashtra, 416415",
    "Course": "(NBEMS) Paediatric Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051b3"
    },
    "Rank": 15304,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nazareth Hospital, Laitumukhrah, ,Laitumkhrah Shillong, East Khasi Hills district, Meghalaya, 793003",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051b4"
    },
    "Rank": 15308,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sanjay Gandhi Postgarduate Institute of Medical Sciences,Sanjay Gandhi Postgarduate Institute of Medical Sciences, Uttar Pradesh, 226014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051b5"
    },
    "Rank": 15311,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051b6"
    },
    "Rank": 15313,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KMC Specialty Hospital, ,No-6, Royal Road, Cantonment, Trichy-620001, Tamil Nadu, 620001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051b7"
    },
    "Rank": 15314,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051b8"
    },
    "Rank": 15315,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asian Institute of Medical Sciences,,Asian Institute of Medical Sciences, Badhkal Flyover Road,Sector-21A, Faridabad, Haryana-121001, Haryana, 121001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051b9"
    },
    "Rank": 15317,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Stephen`s Hospital, ,Tis Hazari, Delhi (NCT), 110054",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051ba"
    },
    "Rank": 15318,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051bb"
    },
    "Rank": 15320,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051bc"
    },
    "Rank": 15322,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051bd"
    },
    "Rank": 15323,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051be"
    },
    "Rank": 15324,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Lal Bahadur Shastri Government Medical College and Hospital , ,VPO- Bhangrotu, MC- Ner Chowk, District- Mandi, Himachal Pradesh, 175021",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051bf"
    },
    "Rank": 15326,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Eye Foundation,,582A D B Road, R S Puram, Coimbatore-641002, Tamil Nadu, 641002",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051c0"
    },
    "Rank": 15329,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051c1"
    },
    "Rank": 15333,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051c2"
    },
    "Rank": 15334,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051c3"
    },
    "Rank": 15335,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051c4"
    },
    "Rank": 15336,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051c5"
    },
    "Rank": 15337,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051c6"
    },
    "Rank": 15340,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051c7"
    },
    "Rank": 15341,
    "Allotted Quota": "All India",
    "Allotted Institute": "GADAG INSTITUTE OF MEDICAL SCIENCES GADAG,Gadag Institute of Medical Sciences Mallasamudra Gadag-582103, Karnataka, 582103",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051c8"
    },
    "Rank": 15342,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051c9"
    },
    "Rank": 15346,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Employee State Insurance Corporation Model Hospital,,Bharat Nagar, Ludhiana, Punjab, 141001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051ca"
    },
    "Rank": 15347,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051cb"
    },
    "Rank": 15348,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bai Jerbai Wadia Hospital for Children, Parel, Mumbai, Maharashtra,Acharya Donde Marg Parel Mumbai, Maharashtra, 400012",
    "Course": "(NBEMS) Paediatric Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051cc"
    },
    "Rank": 15349,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Guru Gobind Singh Govt. Hospital, Delhi,F-Block, Raghubir Nagar, New Delhi, Delhi (NCT), 110027",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051cd"
    },
    "Rank": 15356,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rampurhat Govt. Medical College and Hospital,\n,RAMPURHAT GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, P.O. RAMPURHAT, P.S. RAMPURHAT, DIST. BIRBHUM, WB, West Bengal, 731224",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051ce"
    },
    "Rank": 15359,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Coochbehar Govt Medical College and Hospital, West Bengal,Vivekananda Street, Pilkhana, Cooch Behar, WB-736101, West Bengal, 736101",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051cf"
    },
    "Rank": 15360,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jalgaon,Jilha Peth, Government Hospital Campus, Jalgaon, Maharashtra, 425001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051d0"
    },
    "Rank": 15361,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Arasan Eye Hospital, ,44,ANNAMALAI LAYOUT, OPP TO ROYAL THEATER,NEAR BUS STAND, ERODE - 638011, Tamil Nadu, 638011",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051d1"
    },
    "Rank": 15362,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asha Hospital, ,?Asha Hospital 8-2-316/12/A, Rd #14,\nBanjara Hills, Hyderabad, Telangana State - 500 034\nOff: 04, Telangana, 500034",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051d2"
    },
    "Rank": 15367,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Hurkisondas Nurrotumdas Hospital and Research Centre, ,Prarthana Samaj, Raja Ram Mohan Roy road,\nGirgaon, MUMBAI, Maharashtra, 400004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051d3"
    },
    "Rank": 15368,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051d4"
    },
    "Rank": 15373,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051d5"
    },
    "Rank": 15374,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051d6"
    },
    "Rank": 15378,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051d7"
    },
    "Rank": 15379,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051d8"
    },
    "Rank": 15380,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jehangir Hospital, ,32 Sasoon Road Pune, Maharashtra, 411001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051d9"
    },
    "Rank": 15382,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051da"
    },
    "Rank": 15385,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051db"
    },
    "Rank": 15386,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Little Flower Hospital and Research Centre,,Post Box 23, Angamaly, Ernakulam, Kerala, Kerala, 683572",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051dc"
    },
    "Rank": 15387,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kokilaben Dhirubhai Ambani Hospital and Medical Research Institute,,Achyutrao Patwardhan Marg Andheri West Mumbai Maharashtra, Maharashtra, 400053",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051dd"
    },
    "Rank": 15388,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051de"
    },
    "Rank": 15389,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051df"
    },
    "Rank": 15392,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Laxmi Charitable Trust Eye Hospital, ,Vasant Krupa Building, Hamid Mulla Road, Panvel, Maharashtra, 410206",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051e0"
    },
    "Rank": 15394,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051e1"
    },
    "Rank": 15396,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sarji Hospital, ,RMR RAOD PARK EXTENSION sHIVAMOGGA-577201, Karnataka, 577201",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051e2"
    },
    "Rank": 15399,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051e3"
    },
    "Rank": 15401,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ICare Eye Hospital and Post Graduate Institute,,ICARE Eye Hospital and Post Graduate Institute, E-3A, Sector-26, Noida, Uttar Pradesh-201301, Uttar Pradesh, 201301",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051e4"
    },
    "Rank": 15402,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051e5"
    },
    "Rank": 15403,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shalby Hospital, ,8TH Floor, shalby  hopsital , opp. karnwati club, sg highway ahmedabad, Gujarat, 380015",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051e6"
    },
    "Rank": 15405,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051e7"
    },
    "Rank": 15406,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051e8"
    },
    "Rank": 15408,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kokilaben Dhirubhai Ambani Hospital and Medical Research Institute,,Achyutrao Patwardhan Marg Andheri West Mumbai Maharashtra, Maharashtra, 400053",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051e9"
    },
    "Rank": 15414,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051ea"
    },
    "Rank": 15416,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051eb"
    },
    "Rank": 15420,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaipur Golden Hospital, ,2 INSTITUTIONAL AREA, SECTOR-3, ROHINI, Delhi (NCT), 110085",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051ec"
    },
    "Rank": 15422,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051ed"
    },
    "Rank": 15423,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051ee"
    },
    "Rank": 15424,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051ef"
    },
    "Rank": 15429,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital,,B-22, Sector 62, Noida, Uttar Pradesh, Uttar Pradesh, 201301",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051f0"
    },
    "Rank": 15431,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051f1"
    },
    "Rank": 15433,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College,Fakhruddin Ali Ahmed Medical College, Assam, 781301",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051f2"
    },
    "Rank": 15438,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indiana hospital and Heart Institute, ,Mahaveer Cirlce, Pumpwell, Mangalore, Karnataka, 575002",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051f3"
    },
    "Rank": 15440,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "National Heart Institute, ,49-50, Community Centre, East of Kailash, Delhi (NCT), 110065",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051f4"
    },
    "Rank": 15441,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL CANCER CENTRE,REGIONAL CANCER CENTRE, Kerala, 695011",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051f5"
    },
    "Rank": 15443,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051f6"
    },
    "Rank": 15449,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051f7"
    },
    "Rank": 15450,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mata Chanan Devi Hospital,, ,C-1 JANAKPURI, Delhi (NCT), 110058",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051f8"
    },
    "Rank": 15451,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051f9"
    },
    "Rank": 15454,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Civil Hospital Shillong, Meghalaya,Civil Hospital Shillong Laban Shillong East Khasi Hills Meghalaya 793001, Meghalaya, 793001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051fa"
    },
    "Rank": 15455,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BharatRatna Dr. Babasaheb Ambedkar Municipal General Hospital, Kandivali (West), Maharashtra,Parekh Nagar, SV Road, Opp. Kandivali Police station, Kandivali West, Mumbai, Maharashtra, 400067",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051fb"
    },
    "Rank": 15456,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051fc"
    },
    "Rank": 15458,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051fd"
    },
    "Rank": 15460,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051fe"
    },
    "Rank": 15462,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KAMAKSHI HOSPITAL, Karnataka,KAMAKSHI HOSPITAL KAMAKSHI HOSPITAL ROAD\nKUVEMPU NAGAR\nMYSORE, Karnataka, 570009",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a051ff"
    },
    "Rank": 15464,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sankara Eye Hospital , Vijaywada-,Guntur-Vijayawada Express Highway, Pedakakani, Guntur, Andhra Pradesh, 522509",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05200"
    },
    "Rank": 15467,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sikkim-Manipal Institute of Medical Sciences, ,5TH MILE , TADONG , GANGTOK, Sikkim, 737102",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05201"
    },
    "Rank": 15469,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, ,1,2, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05202"
    },
    "Rank": 15472,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences, ,academics@kimshospitals.com, Telangana, 500003",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05203"
    },
    "Rank": 15474,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05204"
    },
    "Rank": 15475,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tejasvini Hospital and SSIOT, ,Tejasvini Hospital and SSIOT Kadri Temple Road\nMangalore, Karnataka, 575003",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05205"
    },
    "Rank": 15477,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.E.M. Hospital, ,489, SARDAR MOODLIAR ROAD, RASTA PETH, PUNE 411011,\nMaharashtra, 411011",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05206"
    },
    "Rank": 15478,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05207"
    },
    "Rank": 15483,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Ophthalmology,RIO Prev Known as Nehru Institute of Ophthalmology and Research Centre Civil Lines Sitapur, Uttar Pradesh, 261001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05208"
    },
    "Rank": 15484,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital , ,District Hospital Palakkad, Court Road,\nPalakkad Post, Palakkad, Kerala, 678001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05209"
    },
    "Rank": 15485,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0520a"
    },
    "Rank": 15486,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rural Development Trust Hospital, ,Kadiri Road,Bathalapalli, Anantapur District Andhra Pradesh, Andhra Pradesh, 515661",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0520b"
    },
    "Rank": 15487,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Laxmi Narasimha Hospital, Telangana,H NO 2 2 316 BESIDE PUSHPANJALI FUNCTION HALL NAIM NAGAR HANAMKONDA, Telangana, 506001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0520c"
    },
    "Rank": 15488,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Khan Bahadur Bhabha Hospital, Maharashtra,Khan Bahadur Bhabha Hospital Belgrami road kurla west, Maharashtra, 400070",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0520d"
    },
    "Rank": 15489,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bokaro General Hospital, ,SECTOR - 4 BOKARO STEEL CITY\nDIST - BOKARO, Jharkhand, 827004",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0520e"
    },
    "Rank": 15490,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0520f"
    },
    "Rank": 15491,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05210"
    },
    "Rank": 15499,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05211"
    },
    "Rank": 15500,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05212"
    },
    "Rank": 15501,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05213"
    },
    "Rank": 15502,
    "Allotted Quota": "All India",
    "Allotted Institute": "HOMI BHABHA CANCER HOSPITAL AND RESEARCH CENTRE,AGANAMPUDI VILLAGE\nGAJUWAKA MANDAL\nVISAKHAPATNAM, Andhra Pradesh, 530053",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05214"
    },
    "Rank": 15503,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05215"
    },
    "Rank": 15506,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sub Division Hospital, ,Opp Town Police Station Gangavathi Dist Koppal, Karnataka, 583227",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05216"
    },
    "Rank": 15511,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05217"
    },
    "Rank": 15513,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "DIPLOMA IN CHILD HEALTH/ PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05218"
    },
    "Rank": 15516,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sankara Eye Hospital , Vijaywada-,Guntur-Vijayawada Express Highway, Pedakakani, Guntur, Andhra Pradesh, 522509",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05219"
    },
    "Rank": 15517,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0521a"
    },
    "Rank": 15520,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0521b"
    },
    "Rank": 15521,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0521c"
    },
    "Rank": 15522,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Martha`s Hospital , ,No.5, Nrupathunga Road, Bengaluru, Karnataka, 560001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0521d"
    },
    "Rank": 15526,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0521e"
    },
    "Rank": 15527,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0521f"
    },
    "Rank": 15528,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05220"
    },
    "Rank": 15529,
    "Allotted Quota": "All India",
    "Allotted Institute": "HOMI BHABHA CANCER HOSPITAL, SANGRUR,Civil Hospital Campus Sangrur City, Punjab, 148001",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05221"
    },
    "Rank": 15533,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05222"
    },
    "Rank": 15534,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05223"
    },
    "Rank": 15535,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05224"
    },
    "Rank": 15539,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05225"
    },
    "Rank": 15541,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaslok Hospital and Research Centre, ,15, Dr. G. Deshmukh Marg, Mumbai - 400026, Maharashtra, 400026",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05226"
    },
    "Rank": 15543,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05227"
    },
    "Rank": 15551,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05228"
    },
    "Rank": 15553,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05229"
    },
    "Rank": 15554,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0522a"
    },
    "Rank": 15563,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Lal Bahadur Shastri Government Medical College and Hospital , ,VPO- Bhangrotu, MC- Ner Chowk, District- Mandi, Himachal Pradesh, 175021",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0522b"
    },
    "Rank": 15567,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0522c"
    },
    "Rank": 15568,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Hurkisondas Nurrotumdas Hospital and Research Centre, ,Prarthana Samaj, Raja Ram Mohan Roy road,\nGirgaon, MUMBAI, Maharashtra, 400004",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0522d"
    },
    "Rank": 15569,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jagannath Gupta Institute of Medical Sciences and Hospital, ,BUITA, K P MONDAL ROAD, BUDGE BUDGE, SOUTH 24 PARGANAS, KOLKATA, West Bengal, 700137",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0522e"
    },
    "Rank": 15572,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0522f"
    },
    "Rank": 15577,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05230"
    },
    "Rank": 15581,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05231"
    },
    "Rank": 15583,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05232"
    },
    "Rank": 15585,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05233"
    },
    "Rank": 15586,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05234"
    },
    "Rank": 15587,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Noble Hospital, ,Magarpatta City Road, Hadpsar, Pune, Maharashtra, 411028",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05235"
    },
    "Rank": 15588,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05236"
    },
    "Rank": 15592,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Spirit Hospital, ,Mahakali Caves Road Andheri East Mumbai, Maharashtra, 400093",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05237"
    },
    "Rank": 15593,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.M. Joshi Eye Hospital, ,Gokul Road, Hosur, Hubli, Karnataka, 580021",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05238"
    },
    "Rank": 15596,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05239"
    },
    "Rank": 15597,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0523a"
    },
    "Rank": 15600,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Institute of Medical Sciences (SVIMS), A State University estd. by Govt. of A.P.,Sri Venkateswara Institute of Medical Sciences, SVIMS, Alipiri Road, Tirupati, Andhra Pradesh, 517507",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0523b"
    },
    "Rank": 15602,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mata Chanan Devi Hospital,, ,C-1 JANAKPURI, Delhi (NCT), 110058",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0523c"
    },
    "Rank": 15610,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.G. Hospital and PG Medical Institute ,,No:5, Arts College Road, Coimbatore - 641018, Tamil Nadu, 641018",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0523d"
    },
    "Rank": 15611,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta The Medicity, ,Medanta The Medicity Sector 38 Gurgoan, Haryana, 122001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0523e"
    },
    "Rank": 15612,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0523f"
    },
    "Rank": 15613,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MENTAL HEALTH AND HOSPITAL, AGRA,INSTITUTE OF MENTAL HEALTH AND HOSPITAL, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05240"
    },
    "Rank": 15614,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE - KANNUR,GOVERNMENT MEDICAL COLLEGE\n- KANNUR, Kerala, 670503",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05241"
    },
    "Rank": 15616,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05242"
    },
    "Rank": 15618,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05243"
    },
    "Rank": 15620,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05244"
    },
    "Rank": 15621,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SRM Institutes for Medical Sciences, ,No 1 Jawaharlal Nehru Salai, Vadapalani, Chennai, Tamil Nadu, 600026",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05245"
    },
    "Rank": 15622,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharashtra Post Graduate Institute of Medical Education and Research, Nashik,Maharashtra Post Graduation Institute Of Medical Education and Research Civil Hospital campus Trimba, Maharashtra, 422002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05246"
    },
    "Rank": 15623,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Daga Memorial Women Government Hospital Nagpur, ,Ganjakhet gandhibagh nagpur, Maharashtra, 440008",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05247"
    },
    "Rank": 15624,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Specialty Hospital, ,Lake View Road, KK. Nagar, Madurai -625020, Tamil Nadu, 625020",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05248"
    },
    "Rank": 15625,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR GOVERNMENT MEDICAL COLLEGE,NELLORE,Opp AC Subbareddy Stadium\nDargamitta Nellore, Andhra Pradesh, 524004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05249"
    },
    "Rank": 15627,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0524a"
    },
    "Rank": 15628,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0524b"
    },
    "Rank": 15630,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0524c"
    },
    "Rank": 15631,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0524d"
    },
    "Rank": 15634,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Agarwal`s Eye Hospital and Research Centre, ,222, TTK Road, Alwarpet, Chennai, Tamil Nadu, 600018",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0524e"
    },
    "Rank": 15640,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "HCG Cancer Centre,, ,Sola Science City Road, Near Sola Bridge, S.G. Highway, Ahmedabad, Gujarat, 380060",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0524f"
    },
    "Rank": 15641,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05250"
    },
    "Rank": 15645,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Specialty Hospitals, ,IIIM NEHRU NAGAR GHAZIABAD, Uttar Pradesh, 201001",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05251"
    },
    "Rank": 15646,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05252"
    },
    "Rank": 15647,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "DIPLOMA IN CHILD HEALTH/ PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05253"
    },
    "Rank": 15649,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05254"
    },
    "Rank": 15650,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medical Trust Hospital, ,M.G. Road Pallimukku\nCochin, Kerala, 682016",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05255"
    },
    "Rank": 15652,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. L H Hiranandani Hospital, ,HILL SIDE AVENUE, HIRANANDANI GARDENS, POWAI-400076, Maharashtra, 400076",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05256"
    },
    "Rank": 15653,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05257"
    },
    "Rank": 15655,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Daya General Hospital,Daya General Hospital Limited Shornur Road NEar Viyyur bridge\nThiruvambady PO Thrissur, Kerala, 680022",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05258"
    },
    "Rank": 15656,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Spirit Hospital, ,Mahakali Caves Road Andheri East Mumbai, Maharashtra, 400093",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05259"
    },
    "Rank": 15657,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0525a"
    },
    "Rank": 15666,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Little Flower Hospital and Research Centre,,Post Box 23, Angamaly, Ernakulam, Kerala, Kerala, 683572",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0525b"
    },
    "Rank": 15667,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0525c"
    },
    "Rank": 15669,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0525d"
    },
    "Rank": 15671,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0525e"
    },
    "Rank": 15683,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees' State Insurance Corporation Medical College & Hospital, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD GULBARGA, Karnataka, 585106",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0525f"
    },
    "Rank": 15684,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05260"
    },
    "Rank": 15688,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05261"
    },
    "Rank": 15690,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05262"
    },
    "Rank": 15694,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05263"
    },
    "Rank": 15696,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05264"
    },
    "Rank": 15698,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05265"
    },
    "Rank": 15699,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05266"
    },
    "Rank": 15700,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05267"
    },
    "Rank": 15703,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nethradhama Super Specialty Eye Hospital, ,NO 256-14 KANAKAPURA MAIN ROAD 7TH BLOCK JAYANAGAR BANGALORE 560 070, Karnataka, 560070",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05268"
    },
    "Rank": 15707,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Cross Hospital, ,Holy Cross Hospital, Kottiyam P O, Kollam District, Kerala, 691571",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05269"
    },
    "Rank": 15712,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "DIP.IN GYNAE. and OBST.",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0526a"
    },
    "Rank": 15714,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Theresa`s Hospital,,7-1-645/A ERRAGADA SANATHNAGAAR HYDERABAD,\nTelangana, 500018",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0526b"
    },
    "Rank": 15716,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0526c"
    },
    "Rank": 15717,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaipur Golden Hospital, ,2 INSTITUTIONAL AREA, SECTOR-3, ROHINI, Delhi (NCT), 110085",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0526d"
    },
    "Rank": 15720,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0526e"
    },
    "Rank": 15721,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Meenakshi Mission Hospital and Research Centre, ,Lake Area, Melur Road, Madurai, Tamil Nadu, 625107",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0526f"
    },
    "Rank": 15723,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05270"
    },
    "Rank": 15724,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Breach Candy Hospital, ,60-A, Bhulabhai Desai Road, Mumbai- 400 026., Maharashtra, 400026",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05271"
    },
    "Rank": 15726,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05272"
    },
    "Rank": 15727,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College and  PGIMSR,ESIC Medical College and  PGIMSR, KK Nagar, Chennai - 600 078, Tamil Nadu, 600078",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05273"
    },
    "Rank": 15729,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05274"
    },
    "Rank": 15731,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Devadoss Multi Speciality Hospital, ,,DOOR NO 75/1, SURVEYOR COLONY, ALAGAR KOVIL MAIN ROAD, MADURAI, Tamil Nadu, 625007",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05275"
    },
    "Rank": 15733,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05276"
    },
    "Rank": 15735,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.E.M. Hospital, ,489, SARDAR MOODLIAR ROAD, RASTA PETH, PUNE 411011,\nMaharashtra, 411011",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05277"
    },
    "Rank": 15736,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,10 Biplabi Haren Ghosh Sarani Howrah, West Bengal, 711101",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05278"
    },
    "Rank": 15739,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute of Medical Sciences Srikakulam,Government Medical College Srikakulam\nBalaga Srikakulam Town Srikakulam District, Andhra Pradesh, 532001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05279"
    },
    "Rank": 15741,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagwan Mahaveer Jain Hospital, ,MILLERS ROAD, VASANTHNAGAR, BANGALORE, Karnataka, 560052",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0527a"
    },
    "Rank": 15744,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0527b"
    },
    "Rank": 15745,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0527c"
    },
    "Rank": 15746,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0527d"
    },
    "Rank": 15747,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute of Medical Sciences Srikakulam,Government Medical College Srikakulam\nBalaga Srikakulam Town Srikakulam District, Andhra Pradesh, 532001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0527e"
    },
    "Rank": 15750,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sankara Eye Hospital , Vijaywada-,Guntur-Vijayawada Express Highway, Pedakakani, Guntur, Andhra Pradesh, 522509",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0527f"
    },
    "Rank": 15751,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Rangarajan Memorial Hospital, (Sundaram Medical Foundation) ,Sundaram Medical Foundation, Shanthi Colony, 4th Avenue, Anna Nagar, Chennai, Tamil Nadu, 600040",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05280"
    },
    "Rank": 15752,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Suri Sadar Hospital, ,Laldighipara Suri Birbhum, West Bengal, 731101",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05281"
    },
    "Rank": 15758,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05282"
    },
    "Rank": 15760,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Malabar Institute of Medical Sciences, ,Govindapuram P.O., Mini Bypass Road, Kozhikode, Kerala, 673016",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05283"
    },
    "Rank": 15761,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05284"
    },
    "Rank": 15763,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. R N Cooper Municipal General Hospital, (Associated with H B T (Hinduhridayasamrat Balasaheb Thackeray) medical College) ,HBTMC AND DR.R.N.COOPER HOSPITAL\nU 15 BHAKTI VEDANTA ROAD JVPD SCHEME VILE PARLE WEST MUMBAI,\nMaharashtra, 400056",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05285"
    },
    "Rank": 15764,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05286"
    },
    "Rank": 15765,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences, ,academics@kimshospitals.com, Telangana, 500003",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05287"
    },
    "Rank": 15768,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Gujarat Research and Medical Institute (Rajasthan Hospitals), ,Camp Road, Shahibaug, Ahmedabad, Gujarat, 380004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05288"
    },
    "Rank": 15769,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Agarwal`s Eye Hospital and Research Centre, ,222, TTK Road, Alwarpet, Chennai, Tamil Nadu, 600018",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05289"
    },
    "Rank": 15771,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0528a"
    },
    "Rank": 15773,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Balaji Institute of Surgery, Research and Rehabilitation for the Disabled Hospital (BIRRD), ,BIRRD Trust Hospital, SVIMS Campus, T.T. Devasthanams, Tirupati, Andhra Pradesh, 517501",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0528b"
    },
    "Rank": 15777,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0528c"
    },
    "Rank": 15780,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhabha Atomic Research Centre and Hospital, ,Anushaktinagar, Mumbai, Maharashtra, 400094",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0528d"
    },
    "Rank": 15782,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Siddipet,SURVEY NO 54  GOVERNMENT MEDICAL COLLEGE  ENSANPALLY  SIDDIPET   502114, Telangana, 502114",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0528e"
    },
    "Rank": 15784,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0528f"
    },
    "Rank": 15785,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05290"
    },
    "Rank": 15786,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Stephen`s Hospital, ,Tis Hazari, Delhi (NCT), 110054",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05291"
    },
    "Rank": 15787,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Royal Care Super Speciality Hospital, ,1,520 L and T Bypass Road, Neelambur, Coimbatore., Tamil Nadu, 641062",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05292"
    },
    "Rank": 15788,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05293"
    },
    "Rank": 15789,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05294"
    },
    "Rank": 15793,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05295"
    },
    "Rank": 15794,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Netralaya, Narayana Health City Narayana Hrudayalaya Campus ,258 A, Narayana Health City, Bommasandra, Hosur Road, Anekal Taluk, Bangalore, Karnataka, 560099",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05296"
    },
    "Rank": 15796,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhabha Atomic Research Centre and Hospital, ,Anushaktinagar, Mumbai, Maharashtra, 400094",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05297"
    },
    "Rank": 15797,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05298"
    },
    "Rank": 15801,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lisie Medical Institution, ,Kochi, Kerala, Kerala, 682018",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05299"
    },
    "Rank": 15805,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0529a"
    },
    "Rank": 15806,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhaktivedanta Hospital and Research Institute, ,Bhaktivedanta Hospital and Research Institute,Srishti Complex, Bhaktivedanta Swami Marg, Mira Road E, Maharashtra, 401107",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0529b"
    },
    "Rank": 15807,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital, ,Nellikuppam Main Road, Cuddalore, Tamil Nadu, 607001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0529c"
    },
    "Rank": 15809,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0529d"
    },
    "Rank": 15811,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0529e"
    },
    "Rank": 15813,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0529f"
    },
    "Rank": 15815,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052a0"
    },
    "Rank": 15816,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr M K Shah Medical College And SMS Multispeciality Hospital,,Near Tapovan circle,\nVisat Gandhinagar Highway chandkheda ahmedabad, Gujarat, 382424",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052a1"
    },
    "Rank": 15818,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052a2"
    },
    "Rank": 15821,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karnataka,MG Road, Karnataka, 581301",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052a3"
    },
    "Rank": 15824,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052a4"
    },
    "Rank": 15825,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052a5"
    },
    "Rank": 15828,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Healthworld Hospital, ,Healthworld Hospitals [A Unit of Parashmani Medical Centre Pvt.Ltd.], C-49,Commercial Area, Opp. ESI, West Bengal, 713216",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052a6"
    },
    "Rank": 15841,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052a7"
    },
    "Rank": 15844,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052a8"
    },
    "Rank": 15848,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kingsway Hospitals, Maharashtra,Medisearch Lifesciences Pvt  Ltd 44SPANV Kingsway Rd near Kasturchand Park Nagpur Maharashtra 440001, Maharashtra, 440001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052a9"
    },
    "Rank": 15856,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052aa"
    },
    "Rank": 15861,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052ab"
    },
    "Rank": 15865,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.G. Hospital and PG Medical Institute ,,No:5, Arts College Road, Coimbatore - 641018, Tamil Nadu, 641018",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052ac"
    },
    "Rank": 15867,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indira Gandhi Govt. General Hospital and PG Institute, No. 1, ,No.1, Victor Simonel street, Puducherry -1., Puducherry, 605001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052ad"
    },
    "Rank": 15870,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052ae"
    },
    "Rank": 15871,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052af"
    },
    "Rank": 15875,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052b0"
    },
    "Rank": 15877,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052b1"
    },
    "Rank": 15879,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. R N Cooper Municipal General Hospital, (Associated with H B T (Hinduhridayasamrat Balasaheb Thackeray) medical College) ,HBTMC AND DR.R.N.COOPER HOSPITAL\nU 15 BHAKTI VEDANTA ROAD JVPD SCHEME VILE PARLE WEST MUMBAI,\nMaharashtra, 400056",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052b2"
    },
    "Rank": 15881,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052b3"
    },
    "Rank": 15886,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GENERAL HOSPITAL MORBI,GENERAL HOSPITAL MORBI, GANDHI CHOKE NEAR TOWN HALL MORBI, Gujarat, 363641",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052b4"
    },
    "Rank": 15888,
    "Allotted Quota": "All India",
    "Allotted Institute": "BHOPAL MEMORIAL HOSPITAL AND RESEARCH CENTRE, BHOPAL,Raisen By pass\nRoad, Bhopal- 462038 (M.P.), Madhya Pradesh, 462038",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052b5"
    },
    "Rank": 15894,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Healthworld Hospital, ,Healthworld Hospitals [A Unit of Parashmani Medical Centre Pvt.Ltd.], C-49,Commercial Area, Opp. ESI, West Bengal, 713216",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052b6"
    },
    "Rank": 15895,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sankara Eye Hospital , Vijaywada-,Guntur-Vijayawada Express Highway, Pedakakani, Guntur, Andhra Pradesh, 522509",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052b7"
    },
    "Rank": 15896,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052b8"
    },
    "Rank": 15897,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Jamshedpur,Mahatma Gandhi Memorial Medical College, Jamshedpur, Jharkhand, 831020",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052b9"
    },
    "Rank": 15904,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Paras HMRI Hospital, ,Paras HMRI Hospital\nNH 30 Bailey Road Raja Bazaar Patna 800014 Bihar, Bihar, 800014",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052ba"
    },
    "Rank": 15906,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaipur Golden Hospital, ,2 INSTITUTIONAL AREA, SECTOR-3, ROHINI, Delhi (NCT), 110085",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052bb"
    },
    "Rank": 15908,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shalby Hospital, ,8TH Floor, shalby  hopsital , opp. karnwati club, sg highway ahmedabad, Gujarat, 380015",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052bc"
    },
    "Rank": 15909,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052bd"
    },
    "Rank": 15911,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052be"
    },
    "Rank": 15912,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chirayu Hospital, ,HATHOJ, KALWAR ROAD, JAIPUR, Rajasthan, 302012",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052bf"
    },
    "Rank": 15914,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, THOOTHUKUDI,GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, 3rd\nMile,Kamaraj Nagar,Thoothukudi., Tamil Nadu, 628008",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052c0"
    },
    "Rank": 15916,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052c1"
    },
    "Rank": 15917,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052c2"
    },
    "Rank": 15918,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052c3"
    },
    "Rank": 15921,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shalby Hospital, ,8TH Floor, shalby  hopsital , opp. karnwati club, sg highway ahmedabad, Gujarat, 380015",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052c4"
    },
    "Rank": 15923,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052c5"
    },
    "Rank": 15925,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Associated Hospital Govt. Medical College, ,Makeshift Campus, Near Bus Stand, Doda City, Doda, Jammu and Kashmir, Jammu And Kashmir, 182202",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052c6"
    },
    "Rank": 15927,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Balaji Institute of Surgery, Research and Rehabilitation for the Disabled Hospital (BIRRD), ,BIRRD Trust Hospital, SVIMS Campus, T.T. Devasthanams, Tirupati, Andhra Pradesh, 517501",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052c7"
    },
    "Rank": 15932,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Specialty Hospitals, ,IIIM NEHRU NAGAR GHAZIABAD, Uttar Pradesh, 201001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052c8"
    },
    "Rank": 15934,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta The Medicity, ,Medanta The Medicity Sector 38 Gurgoan, Haryana, 122001",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052c9"
    },
    "Rank": 15944,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Doon Medical College, Dehradun,Govt. Doon Medical College Dehrakhas, Patel Nagar Dehradun 248001, Uttarakhand, 248001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052ca"
    },
    "Rank": 15949,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Meenakshi Mission Hospital and Research Centre, ,Lake Area, Melur Road, Madurai, Tamil Nadu, 625107",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052cb"
    },
    "Rank": 15950,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052cc"
    },
    "Rank": 15951,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052cd"
    },
    "Rank": 15954,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. L H Hiranandani Hospital, ,HILL SIDE AVENUE, HIRANANDANI GARDENS, POWAI-400076, Maharashtra, 400076",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052ce"
    },
    "Rank": 15956,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Peerless Hospital and B K Roy Research Centre, ,360, Panchasayar, Kolkata - 700 -094, West Bengal, 700094",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052cf"
    },
    "Rank": 15958,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SETH V. C. GANDHI and M. A. VORA MUNICIPAL GENERAL HOSPITAL (RAJAWADI HOSPITAL),\nMaharashtra,hospital.rajawadi@gmail.com, Maharashtra, 400077",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052d0"
    },
    "Rank": 15961,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052d1"
    },
    "Rank": 15962,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN,HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN, Karnataka, 573201",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052d2"
    },
    "Rank": 15964,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Meenakshi Mission Hospital and Research Centre, ,Lake Area, Melur Road, Madurai, Tamil Nadu, 625107",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052d3"
    },
    "Rank": 15966,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052d4"
    },
    "Rank": 15970,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital,,FC 50, Max Wali Rd, C and D Block, Shalimar Place Site, Shalimar Bagh, New Delhi, Delhi 110088, Delhi (NCT), 110088",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052d5"
    },
    "Rank": 15971,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindu Rao Hospital, ,SUBZI MANDI, MALKA GANJ, DELHI-110007, Delhi (NCT), 110007",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052d6"
    },
    "Rank": 15973,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Gleneagles Hospital, ,58 Canal Circular Road Kolkata 700054, West Bengal, 700054",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052d7"
    },
    "Rank": 15974,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052d8"
    },
    "Rank": 15975,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Giridhar Eye Institute , ,Ponneth Temple Road Kadavanthra\nCochin, Kerala, 682020",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052d9"
    },
    "Rank": 15976,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052da"
    },
    "Rank": 15977,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052db"
    },
    "Rank": 15978,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sankara Nethralaya, (A Unit of Medical Research Foundation) ,147\\1 MUKUNDAPUR E M BYPASS KOLKATA 700099, West Bengal, 700099",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052dc"
    },
    "Rank": 15979,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Babasaheb Ambedkar Central Railway Hospital, ,Bharatratna Dr. Babasaheb Ambedkar Memorial Hospital, Central Railway, Dr. Babasaheb Ambedkar Road,, Maharashtra, 400027",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052dd"
    },
    "Rank": 15980,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Sathya Sai Institute of Higher Medical Sciences, ,PRASANTHIGRAM , PUTTAPARTHI\nANANTAPUR, Andhra Pradesh, 515134",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052de"
    },
    "Rank": 15982,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052df"
    },
    "Rank": 15985,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052e0"
    },
    "Rank": 15989,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BharatRatna Dr. Babasaheb Ambedkar Municipal General Hospital, Kandivali (West), Maharashtra,Parekh Nagar, SV Road, Opp. Kandivali Police station, Kandivali West, Mumbai, Maharashtra, 400067",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052e1"
    },
    "Rank": 15995,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Prabha Eye Clinic And Research Centre, ,No. 504, 40th cross, 8th Block, Jayanagar, Bangalore 560070, Karnataka, 560070",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052e2"
    },
    "Rank": 15996,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052e3"
    },
    "Rank": 15997,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asha Hospital, ,?Asha Hospital 8-2-316/12/A, Rd #14,\nBanjara Hills, Hyderabad, Telangana State - 500 034\nOff: 04, Telangana, 500034",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052e4"
    },
    "Rank": 15999,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052e5"
    },
    "Rank": 16004,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052e6"
    },
    "Rank": 16005,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052e7"
    },
    "Rank": 16012,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052e8"
    },
    "Rank": 16013,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052e9"
    },
    "Rank": 16015,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC MEDICAL COLLEGE, JOKA,ESI-PGIMSR & ESIC MEDICAL COLLEGE, JOKA, KOLKATA, WEST BENGAL, West Bengal, 700104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052ea"
    },
    "Rank": 16016,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052eb"
    },
    "Rank": 16019,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Motors Hospital, ,KHARANGAJHAR, TELCO COLONY JAMSHEDPUR, Jharkhand, 831003",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052ec"
    },
    "Rank": 16020,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052ed"
    },
    "Rank": 16022,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Advanced Medical Research Institute (AMRI) Hospital,,AMRI Hospitals, Bhubaneswar Plot no-1, Besides Satya Sai Enclave, Khandagiri, Bhubaneswar, Odisha, 751030",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052ee"
    },
    "Rank": 16024,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052ef"
    },
    "Rank": 16025,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Centre for Sight, ,B5-24, Safdarjung Enclave, New Delhi-110029, Delhi (NCT), 110029",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052f0"
    },
    "Rank": 16026,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "J.L.N. Main Hospital and Research Centre, ,Bhilai Steel Plant Sector-9, Bhilai, C.G., Chhattisgarh, 490009",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052f1"
    },
    "Rank": 16029,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052f2"
    },
    "Rank": 16030,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "IQRAA International Hospital and Research Centre, ,IQRAA INTERNATIONAL HOSPITAL AND RESEARCH CENTRE MALAPARAMBA CALICUT, Kerala, 673009",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052f3"
    },
    "Rank": 16031,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Hurkisondas Nurrotumdas Hospital and Research Centre, ,Prarthana Samaj, Raja Ram Mohan Roy road,\nGirgaon, MUMBAI, Maharashtra, 400004",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052f4"
    },
    "Rank": 16032,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052f5"
    },
    "Rank": 16033,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052f6"
    },
    "Rank": 16034,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052f7"
    },
    "Rank": 16035,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kidwai Memorial Institute of Oncolgy,Kidwai Memorial Institute of Oncolgy, Karnataka, 560029",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052f8"
    },
    "Rank": 16036,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052f9"
    },
    "Rank": 16037,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rabindranath Tagore International Institute of Cardiac Sciences, ,PREMISES NO. 1489 124 MUKUNDAPUR  E M BYPASS  KOLKATA-700099, West Bengal, 700099",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052fa"
    },
    "Rank": 16040,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,OFFICE OF SUPERINTENDENT 9TH FLOOR SSH PO JALPAIGURI,\nWest Bengal, 735101",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052fb"
    },
    "Rank": 16041,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052fc"
    },
    "Rank": 16042,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052fd"
    },
    "Rank": 16043,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052fe"
    },
    "Rank": 16046,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Agarwal`s Eye Hospital and Research Centre, ,222, TTK Road, Alwarpet, Chennai, Tamil Nadu, 600018",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a052ff"
    },
    "Rank": 16047,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05300"
    },
    "Rank": 16049,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05301"
    },
    "Rank": 16050,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "DIP. IN T.B. and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05302"
    },
    "Rank": 16052,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05303"
    },
    "Rank": 16053,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bombay Hospital,Ring Road Indore, Madhya Pradesh, 452010",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05304"
    },
    "Rank": 16054,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05305"
    },
    "Rank": 16055,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05306"
    },
    "Rank": 16058,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05307"
    },
    "Rank": 16059,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05308"
    },
    "Rank": 16065,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute of Medical Sciences Srikakulam,Government Medical College Srikakulam\nBalaga Srikakulam Town Srikakulam District, Andhra Pradesh, 532001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05309"
    },
    "Rank": 16066,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Swami Dayanand Hospital, ,Dilshad Garden, Shahdara, Delhi, Delhi (NCT), 110095",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0530a"
    },
    "Rank": 16068,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0530b"
    },
    "Rank": 16074,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chamarajanagar Institute of Medical Sciences, Karnataka,CHAMARAJANAGAR INSTITUTE OF MEDICAL SCIENCES, SY. NO 124, YEDAPURA VILLAGE KASABA HOBLI, CHAMARAJANA, Karnataka, 571313",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0530c"
    },
    "Rank": 16075,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0530d"
    },
    "Rank": 16078,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0530e"
    },
    "Rank": 16079,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SRM Institutes for Medical Sciences, ,No 1 Jawaharlal Nehru Salai, Vadapalani, Chennai, Tamil Nadu, 600026",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0530f"
    },
    "Rank": 16080,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, (Formerly- Pushpanjali Crosslay Hospital) ,W-3, Sector-1, Vaishali, Ghaziabad, Uttar Pradesh, 201012",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05310"
    },
    "Rank": 16082,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05311"
    },
    "Rank": 16084,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Motors Hospital, ,KHARANGAJHAR, TELCO COLONY JAMSHEDPUR, Jharkhand, 831003",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05312"
    },
    "Rank": 16085,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Human care Medical Charitable Trust,Human Care Medical Charitable Trust. HCMCTManipal Hospital, Sector 6, DWARKA, NEW DELHI, Delhi (NCT), 110075",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05313"
    },
    "Rank": 16087,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Mulund - Goregaon Link Rd, Nahur West, Industrial Area, Bhandup West, Mumbai, Maharashtra 400078, Maharashtra, 400078",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05314"
    },
    "Rank": 16089,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05315"
    },
    "Rank": 16093,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05316"
    },
    "Rank": 16095,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "AUTONOMOUS STATE MEDICAL COLLEGE, ,RAJARSHI DASHRATH AUTONOMOUS STATE MEDICAL COLLEGE VILL- GANJA, POST- DABHA SEMAR, DISTRICT- AYODHYA, Uttar Pradesh, 224133",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05317"
    },
    "Rank": 16096,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05318"
    },
    "Rank": 16097,
    "Allotted Quota": "All India",
    "Allotted Institute": "BHOPAL MEMORIAL HOSPITAL AND RESEARCH CENTRE, BHOPAL,Raisen By pass\nRoad, Bhopal- 462038 (M.P.), Madhya Pradesh, 462038",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05319"
    },
    "Rank": 16098,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kidwai Memorial Institute of Oncolgy,Kidwai Memorial Institute of Oncolgy, Karnataka, 560029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0531a"
    },
    "Rank": 16105,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0531b"
    },
    "Rank": 16112,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0531c"
    },
    "Rank": 16117,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0531d"
    },
    "Rank": 16119,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Siddipet,SURVEY NO 54  GOVERNMENT MEDICAL COLLEGE  ENSANPALLY  SIDDIPET   502114, Telangana, 502114",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0531e"
    },
    "Rank": 16122,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESIC MEDICAL COLLEGE AND HOSPITAL, Bihar,ESIC Medical College and Hospital,Ara Danapur Road, Sikandarpur, Bihta, Patna, Bihar, 801103",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0531f"
    },
    "Rank": 16123,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "DIPLOMA IN CHILD HEALTH/ PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05320"
    },
    "Rank": 16125,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05321"
    },
    "Rank": 16129,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05322"
    },
    "Rank": 16130,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.G. Hospital and PG Medical Institute ,,No:5, Arts College Road, Coimbatore - 641018, Tamil Nadu, 641018",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05323"
    },
    "Rank": 16132,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "DIP.IN GYNAE. and OBST.",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05324"
    },
    "Rank": 16136,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05325"
    },
    "Rank": 16138,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Royal Care Super Speciality Hospital, ,1,520 L and T Bypass Road, Neelambur, Coimbatore., Tamil Nadu, 641062",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05326"
    },
    "Rank": 16143,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sankara Eye Hospital, ,Village- Panau Purwa, Post- Tatiyaganj, Dist.- Kanpur Nagar, Uttar Pradesh, 209217",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05327"
    },
    "Rank": 16145,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05328"
    },
    "Rank": 16147,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Preethi Hospital, ,50, Melur Main Road, Uthangudi, Madurai - 625107, Tamil Nadu, 625107",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05329"
    },
    "Rank": 16150,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0532a"
    },
    "Rank": 16154,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Centre for Sight, Delhi,Centre For Sight, Plot No. 9, Sector 9, Dwarka, Delhi, 110075, Delhi (NCT), 110075",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0532b"
    },
    "Rank": 16158,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "G Kuppuswamy Naidu Memorial Hospital, ,Post Box No.6327, Nethaji Road, Pappanaickenpalayam, Coimbatore, Tamil Nadu, 641037",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0532c"
    },
    "Rank": 16159,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0532d"
    },
    "Rank": 16164,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Southern Railway Head Quarter Hospital, ,Ayanavaram, Tamil Nadu, 600023",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0532e"
    },
    "Rank": 16165,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0532f"
    },
    "Rank": 16166,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05330"
    },
    "Rank": 16171,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05331"
    },
    "Rank": 16173,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN,HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN, Karnataka, 573201",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05332"
    },
    "Rank": 16174,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05333"
    },
    "Rank": 16175,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05334"
    },
    "Rank": 16178,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vivekananda Polyclinic and Institute of Medical Sciences, ,Vivekananda Polyclinic and Institute of Medical Sciences, Vivekananda Puram, Lucknow, Uttar Pradesh, 226007",
    "Course": "(NBEMS) Plastic and Reconstructive Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05335"
    },
    "Rank": 16188,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05336"
    },
    "Rank": 16189,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jehangir Hospital, ,32 Sasoon Road Pune, Maharashtra, 411001",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05337"
    },
    "Rank": 16191,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05338"
    },
    "Rank": 16192,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital,,DNB office, 3rd Floor Opp Library  Apollo Hospitals, Jubilee Hills, Hyderabad, Telangana, 500033",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05339"
    },
    "Rank": 16194,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0533a"
    },
    "Rank": 16199,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saifee Hospital, ,postbox no 3771, 15-17 Maharshi Karve Marg, Charni road, Mumbai, Maharashtra, 400004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0533b"
    },
    "Rank": 16200,
    "Allotted Quota": "All India",
    "Allotted Institute": "HOMI BHABHA CANCER HOSPITAL AND RESEARCH CENTRE,AGANAMPUDI VILLAGE\nGAJUWAKA MANDAL\nVISAKHAPATNAM, Andhra Pradesh, 530053",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0533c"
    },
    "Rank": 16201,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0533d"
    },
    "Rank": 16203,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College, Kolhapur,R.K. Nagar Road, Shenda Park, Kolhapur, Maharashtra, Maharashtra, 416012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0533e"
    },
    "Rank": 16214,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0533f"
    },
    "Rank": 16215,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, Shalimar Bagh,A- Block, Shalimar Bagh, Delhi (NCT), 110088",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05340"
    },
    "Rank": 16217,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Balaji Institute of Surgery, Research and Rehabilitation for the Disabled Hospital (BIRRD), ,BIRRD Trust Hospital, SVIMS Campus, T.T. Devasthanams, Tirupati, Andhra Pradesh, 517501",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05341"
    },
    "Rank": 16219,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.B.Borooah Cancer Institute,Dr.B.Borooah Cancer Institute, Assam, 781016",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05342"
    },
    "Rank": 16220,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Martha`s Hospital , ,No.5, Nrupathunga Road, Bengaluru, Karnataka, 560001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05343"
    },
    "Rank": 16222,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chaithanya Eye Hospital, ,Kesavadasapuram, Thiruvananthapuram Kerala-695004, Kerala, 695004",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05344"
    },
    "Rank": 16226,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05345"
    },
    "Rank": 16228,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05346"
    },
    "Rank": 16230,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05347"
    },
    "Rank": 16237,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05348"
    },
    "Rank": 16241,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "HOSMAT Hospital, ,NO.45, MAGRATH ROAD, OFF RICHMOND ROAD, BANGALORE,\nKarnataka, 560025",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05349"
    },
    "Rank": 16243,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0534a"
    },
    "Rank": 16244,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Siddipet,SURVEY NO 54  GOVERNMENT MEDICAL COLLEGE  ENSANPALLY  SIDDIPET   502114, Telangana, 502114",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0534b"
    },
    "Rank": 16245,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0534c"
    },
    "Rank": 16247,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Civil Hospital, ,Medical Superintendent Office, Civil Hospital, Dawrpui, Aizawl, Mizoram, 796001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0534d"
    },
    "Rank": 16251,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital,,DNB office, 3rd Floor Opp Library  Apollo Hospitals, Jubilee Hills, Hyderabad, Telangana, 500033",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0534e"
    },
    "Rank": 16252,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0534f"
    },
    "Rank": 16253,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rural Development Trust Hospital, ,Kadiri Road,Bathalapalli, Anantapur District Andhra Pradesh, Andhra Pradesh, 515661",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05350"
    },
    "Rank": 16255,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05351"
    },
    "Rank": 16256,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05352"
    },
    "Rank": 16257,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05353"
    },
    "Rank": 16258,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESI Hospital, ,IG ESI HOSPITAL JHILMIL DELHI - 95, Delhi (NCT), 110095",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05354"
    },
    "Rank": 16260,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05355"
    },
    "Rank": 16263,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05356"
    },
    "Rank": 16266,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agarsen Hospital, ,., Delhi (NCT), 110026",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05357"
    },
    "Rank": 16269,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05358"
    },
    "Rank": 16270,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Hurkisondas Nurrotumdas Hospital and Research Centre, ,Prarthana Samaj, Raja Ram Mohan Roy road,\nGirgaon, MUMBAI, Maharashtra, 400004",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05359"
    },
    "Rank": 16279,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Stephen`s Hospital, ,Tis Hazari, Delhi (NCT), 110054",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0535a"
    },
    "Rank": 16280,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0535b"
    },
    "Rank": 16282,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indira Gandhi Medical College and Research Institute, Pondicherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0535c"
    },
    "Rank": 16283,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "VCSG Govt Institute of Medical Science and Research and HNB Base Teaching Hospital\n, ,Srikot Ganganali Srinagar Garhwal Uttarakhand, Uttarakhand, 246174",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0535d"
    },
    "Rank": 16286,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saifee Hospital, ,postbox no 3771, 15-17 Maharshi Karve Marg, Charni road, Mumbai, Maharashtra, 400004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0535e"
    },
    "Rank": 16288,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Breach Candy Hospital, ,60-A, Bhulabhai Desai Road, Mumbai- 400 026., Maharashtra, 400026",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0535f"
    },
    "Rank": 16289,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05360"
    },
    "Rank": 16290,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05361"
    },
    "Rank": 16294,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05362"
    },
    "Rank": 16299,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05363"
    },
    "Rank": 16305,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05364"
    },
    "Rank": 16306,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05365"
    },
    "Rank": 16308,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05366"
    },
    "Rank": 16309,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Philomena`s Hospital, ,No.4, Campbell Road, Viveknagar P.O. Bangalore - 560047, Karnataka, 560047",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05367"
    },
    "Rank": 16310,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05368"
    },
    "Rank": 16311,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05369"
    },
    "Rank": 16312,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0536a"
    },
    "Rank": 16317,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0536b"
    },
    "Rank": 16319,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0536c"
    },
    "Rank": 16320,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0536d"
    },
    "Rank": 16321,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0536e"
    },
    "Rank": 16323,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Narayani Hospital and Res. Centre, ,Sripuram Thirumalaikodi Vellore, Tamil Nadu, 632055",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0536f"
    },
    "Rank": 16324,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deen Dayal Upadhyay Hospital, ,DDU HOSPITAL, SHAHEED MANGAL PANDEY MARG, NANAK PURA, HARI NAGAR, NEW DELHI, DELHI-110064, Delhi (NCT), 110064",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05370"
    },
    "Rank": 16325,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05371"
    },
    "Rank": 16326,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,LOTUS TOWER, G S ROAD, GUWAHATI, Assam, 781005",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05372"
    },
    "Rank": 16328,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05373"
    },
    "Rank": 16330,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government Women and Children Hospital, (Formerly Indira Gandhi.\nGeneral Hospital) ,100 feet road, Ellaipillaichavady, Puducherry, 605005",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05374"
    },
    "Rank": 16332,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05375"
    },
    "Rank": 16334,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05376"
    },
    "Rank": 16335,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05377"
    },
    "Rank": 16340,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "HCG Cancer Centre,\nHoly cross road, Maharashtra,Holy Cross Road IC Colony Off Borival-Dahisar link road Borivali West Mumbai Maharashtra, Maharashtra, 400092",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05378"
    },
    "Rank": 16341,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE,ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, Bihar, 823001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05379"
    },
    "Rank": 16342,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medical Trust Hospital, ,M.G. Road Pallimukku\nCochin, Kerala, 682016",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0537a"
    },
    "Rank": 16343,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital,,FC 50, Max Wali Rd, C and D Block, Shalimar Place Site, Shalimar Bagh, New Delhi, Delhi 110088, Delhi (NCT), 110088",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0537b"
    },
    "Rank": 16347,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESIC Model Hospital and Occupational Disease Centre,,ESIC MODEL HOSPITAL AND ODC NANDA NAGAR INDORE, Madhya Pradesh, 452011",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0537c"
    },
    "Rank": 16352,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0537d"
    },
    "Rank": 16353,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS Medical College Valsad,Nanakwada Halar Road,Valsad ,Gujarat, Gujarat, 396001",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0537e"
    },
    "Rank": 16356,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Rangarajan Memorial Hospital, (Sundaram Medical Foundation) ,Sundaram Medical Foundation, Shanthi Colony, 4th Avenue, Anna Nagar, Chennai, Tamil Nadu, 600040",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0537f"
    },
    "Rank": 16357,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SRCC Children`s Hoapital,,1 A Haji Ali Park, K Khadye Marg, Near MCGN Pump Station,\nMahalaxmi, Mumbai, Maharashtra, 400034",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05380"
    },
    "Rank": 16360,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dhanwantri Hospital and Research Centre,67/56-A, Near Mandara BUs stand, new sanganer road, mansarovar, jaipur-302020, Rajasthan, 302020",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05381"
    },
    "Rank": 16361,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05382"
    },
    "Rank": 16363,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05383"
    },
    "Rank": 16366,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05384"
    },
    "Rank": 16367,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05385"
    },
    "Rank": 16368,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05386"
    },
    "Rank": 16370,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05387"
    },
    "Rank": 16377,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05388"
    },
    "Rank": 16383,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05389"
    },
    "Rank": 16385,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Sankaradeva Nethralaya,,96, Basistha Road, Guwahati, Assam, 781028",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0538a"
    },
    "Rank": 16392,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0538b"
    },
    "Rank": 16394,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sadhu Vaswani Missions ,7-9 Lane No 1 Koregaon Park Pune 411001, Maharashtra, 411001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0538c"
    },
    "Rank": 16399,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Motors Hospital, ,KHARANGAJHAR, TELCO COLONY JAMSHEDPUR, Jharkhand, 831003",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0538d"
    },
    "Rank": 16401,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Artemis Health Institute, ,Sector 51, Gurugram, Haryana -122001, Haryana, 122001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0538e"
    },
    "Rank": 16402,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaiprakash Hospital and Research Centre Pvt. Ltd, Odisha,,JAIPRAKASH HOSPITAL AND RESEARCH CENTER PVT. LTD., DANDIAPALI, ROURKELA, Odisha, 769004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0538f"
    },
    "Rank": 16404,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05390"
    },
    "Rank": 16408,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medical Trust Hospital, ,M.G. Road Pallimukku\nCochin, Kerala, 682016",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05391"
    },
    "Rank": 16416,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chaithanya Eye Hospital, ,Kesavadasapuram, Thiruvananthapuram Kerala-695004, Kerala, 695004",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05392"
    },
    "Rank": 16417,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sparsh Super Specialty Hospital, ,SPARSH SUPER SPECIALITY HOSPITAL, TUMKUR ROAD, YESHWANTHPURA, BANGALORE, Karnataka, 560022",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05393"
    },
    "Rank": 16420,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05394"
    },
    "Rank": 16421,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05395"
    },
    "Rank": 16422,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital , ,GMERS MEDICAL COLLEGE , Hadiyol-Gadhoda Road, Near-Government Polytechnic, Hiimmatnagar, Gujarat, 383001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05396"
    },
    "Rank": 16425,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05397"
    },
    "Rank": 16426,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagwan Mahaveer Jain Hospital, ,MILLERS ROAD, VASANTHNAGAR, BANGALORE, Karnataka, 560052",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05398"
    },
    "Rank": 16428,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05399"
    },
    "Rank": 16429,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0539a"
    },
    "Rank": 16435,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster CMI Hospital, ,43/2 New Airport Road, NH-7, Kodigehalli, Sahakara Nagar, Hebbal, Bengaluru, Karnataka, 560092",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0539b"
    },
    "Rank": 16438,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "J.L.N. Main Hospital and Research Centre, ,Bhilai Steel Plant Sector-9, Bhilai, C.G., Chhattisgarh, 490009",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0539c"
    },
    "Rank": 16439,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "National Institute Of Ophthalmology, ,1187.30 ShivajiNagar, off Ghole Road, Pune, Maharashtra, 411005",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0539d"
    },
    "Rank": 16440,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0539e"
    },
    "Rank": 16441,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0539f"
    },
    "Rank": 16442,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vijaya Hospital, ,New No 434, Old No 180, NSK Salai, Vadapalani, Chennai - 600026, Tamil Nadu, 600026",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053a0"
    },
    "Rank": 16443,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053a1"
    },
    "Rank": 16444,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053a2"
    },
    "Rank": 16445,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sagar Hospital, Karnataka,No. 44/54, 30th Cross, Tilak Nagar, Jayanagar Extn., Bangalore, Karnataka-560041",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053a3"
    },
    "Rank": 16453,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Meenakshi Mission Hospital and Research Centre, ,Lake Area, Melur Road, Madurai, Tamil Nadu, 625107",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053a4"
    },
    "Rank": 16460,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vivekananda Polyclinic and Institute of Medical Sciences, ,Vivekananda Polyclinic and Institute of Medical Sciences, Vivekananda Puram, Lucknow, Uttar Pradesh, 226007",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053a5"
    },
    "Rank": 16463,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053a6"
    },
    "Rank": 16468,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Motors Hospital, ,KHARANGAJHAR, TELCO COLONY JAMSHEDPUR, Jharkhand, 831003",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053a7"
    },
    "Rank": 16471,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Stephen`s Hospital, ,Tis Hazari, Delhi (NCT), 110054",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053a8"
    },
    "Rank": 16475,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053a9"
    },
    "Rank": 16476,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Balaji Institute of Medical Sceince,,MOWA, RAIPUR, Chhattisgarh, 492001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053aa"
    },
    "Rank": 16479,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Hospital, ,ALEXANDER ROAD, BESIDE HARIHARA KALABHAVAN, NEAR CLOCK TOWER, SECUNDERBAD, Telangana, 500003",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053ab"
    },
    "Rank": 16480,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Choithram Netralaya,Choithram Netralaya Plot No 54-56 Shree Ram Talawali Dhar road, Indore PIN 453001, Madhya Pradesh, Madhya Pradesh, 453001",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053ac"
    },
    "Rank": 16481,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jehangir Hospital, ,32 Sasoon Road Pune, Maharashtra, 411001",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053ad"
    },
    "Rank": 16485,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aadhar Health Institute, (A UNIT OF VLCOM HEALTHCARE P LTD.) ,Aadhar Health Institute, A UNIT OF VLCOM HEALTHCARE P LTD. Tosham Road, Hisar, Haryana- 125001, Haryana, 125001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053ae"
    },
    "Rank": 16486,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053af"
    },
    "Rank": 16487,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053b0"
    },
    "Rank": 16488,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Balaji Action Medical Institute,,FC34 A4 PASCHIM VIHAR NEW DELHI, Delhi (NCT), 110063",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053b1"
    },
    "Rank": 16490,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053b2"
    },
    "Rank": 16492,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053b3"
    },
    "Rank": 16494,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jehangir Hospital, ,32 Sasoon Road Pune, Maharashtra, 411001",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053b4"
    },
    "Rank": 16498,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053b5"
    },
    "Rank": 16500,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053b6"
    },
    "Rank": 16502,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053b7"
    },
    "Rank": 16503,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053b8"
    },
    "Rank": 16506,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053b9"
    },
    "Rank": 16507,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053ba"
    },
    "Rank": 16508,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medical Trust Hospital, ,M.G. Road Pallimukku\nCochin, Kerala, 682016",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053bb"
    },
    "Rank": 16509,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053bc"
    },
    "Rank": 16510,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Hurkisondas Nurrotumdas Hospital and Research Centre, ,Prarthana Samaj, Raja Ram Mohan Roy road,\nGirgaon, MUMBAI, Maharashtra, 400004",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053bd"
    },
    "Rank": 16511,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053be"
    },
    "Rank": 16515,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Government Medical College, Vidisha,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA MP, Madhya Pradesh, 464001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053bf"
    },
    "Rank": 16519,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053c0"
    },
    "Rank": 16520,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053c1"
    },
    "Rank": 16521,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053c2"
    },
    "Rank": 16522,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Human care Medical Charitable Trust,Human Care Medical Charitable Trust. HCMCTManipal Hospital, Sector 6, DWARKA, NEW DELHI, Delhi (NCT), 110075",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053c3"
    },
    "Rank": 16530,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences, ,academics@kimshospitals.com, Telangana, 500003",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053c4"
    },
    "Rank": 16534,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asian Institute of Medical Sciences,,Asian Institute of Medical Sciences, Badhkal Flyover Road,Sector-21A, Faridabad, Haryana-121001, Haryana, 121001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053c5"
    },
    "Rank": 16535,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053c6"
    },
    "Rank": 16537,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "EMS Memorial Co-operative Hospital and Research Centre, ,Panambi, Perintalmanna, Malappuram District, Kerala, 679322",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053c7"
    },
    "Rank": 16539,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053c8"
    },
    "Rank": 16540,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Malabar Institute of Medical Sciences, ,Govindapuram P.O., Mini Bypass Road, Kozhikode, Kerala, 673016",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053c9"
    },
    "Rank": 16543,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Venkateswara Aravind Eye Hospital, Andhra Pradesh,No 588 A, Alipiri to zoo park road, beside NCC campus, tirupati, Andhra Pradesh, 517505",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053ca"
    },
    "Rank": 16545,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "EMS Memorial Co-operative Hospital and Research Centre, ,Panambi, Perintalmanna, Malappuram District, Kerala, 679322",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053cb"
    },
    "Rank": 16546,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, (Formerly- Pushpanjali Crosslay Hospital) ,W-3, Sector-1, Vaishali, Ghaziabad, Uttar Pradesh, 201012",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053cc"
    },
    "Rank": 16549,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053cd"
    },
    "Rank": 16558,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053ce"
    },
    "Rank": 16560,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053cf"
    },
    "Rank": 16562,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053d0"
    },
    "Rank": 16564,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053d1"
    },
    "Rank": 16566,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053d2"
    },
    "Rank": 16567,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053d3"
    },
    "Rank": 16570,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster CMI Hospital, ,43/2 New Airport Road, NH-7, Kodigehalli, Sahakara Nagar, Hebbal, Bengaluru, Karnataka, 560092",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053d4"
    },
    "Rank": 16571,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nethradhama Super Specialty Eye Hospital, ,NO 256-14 KANAKAPURA MAIN ROAD 7TH BLOCK JAYANAGAR BANGALORE 560 070, Karnataka, 560070",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053d5"
    },
    "Rank": 16572,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Hrudayalaya Hospital (Formerly Sparsh Hospital),,mahadevan.s@narayanahealth.org, Karnataka, 560099",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053d6"
    },
    "Rank": 16577,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees' State Insurance Corporation Medical College & Hospital, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD GULBARGA, Karnataka, 585106",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053d7"
    },
    "Rank": 16583,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053d8"
    },
    "Rank": 16584,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053d9"
    },
    "Rank": 16586,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sadguru Nethra Chikitsalaya, ,Shri Sadguru Seva Sangh Trust Jankikund Chitrakoot\nDistrict Satna, Madhya Pradesh, 210204",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053da"
    },
    "Rank": 16587,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Smart Super Specialty Hospital, (Formerly Known as Saket City Hospital), ,Mandir Marg, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053db"
    },
    "Rank": 16592,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medical Trust Hospital, ,M.G. Road Pallimukku\nCochin, Kerala, 682016",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053dc"
    },
    "Rank": 16593,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Almadeena Institute of Medical Science, ,ALMAS HOSPITAL, CHANGUVETTY, KOTTAKKAL, MALAPPURAM DISTRICT, Kerala, 676503",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053dd"
    },
    "Rank": 16594,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053de"
    },
    "Rank": 16599,
    "Allotted Quota": "All India",
    "Allotted Institute": "Homi Bhabha Cancer Hospital, Varanasi,Homi Bhabha Cancer Hospital , Old Loco Colony, Shivpurwa, Lahartara, Varanasi-221002, Uttar Pradesh, Uttar Pradesh, 221002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053df"
    },
    "Rank": 16600,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharashtra Post Graduate Institute of Medical Education and Research, Nashik,Maharashtra Post Graduation Institute Of Medical Education and Research Civil Hospital campus Trimba, Maharashtra, 422002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053e0"
    },
    "Rank": 16608,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053e1"
    },
    "Rank": 16611,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053e2"
    },
    "Rank": 16617,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Christian Fellowship Hospital, ,Dindigul Palani Main Road Oddanchatram\nDindigul District, Tamil Nadu, 624619",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053e3"
    },
    "Rank": 16620,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053e4"
    },
    "Rank": 16622,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053e5"
    },
    "Rank": 16623,
    "Allotted Quota": "All India",
    "Allotted Institute": "Homi Bhabha Cancer Hospital, Varanasi,Homi Bhabha Cancer Hospital , Old Loco Colony, Shivpurwa, Lahartara, Varanasi-221002, Uttar Pradesh, Uttar Pradesh, 221002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053e6"
    },
    "Rank": 16626,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kovai Medical Centre, ,Kovai Medical Center and Hospital, 99, Avanashi Road, Coimbatore - 641014, Tamil Nadu, 641014",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053e7"
    },
    "Rank": 16627,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Christian Fellowship Hospital, ,Dindigul Palani Main Road Oddanchatram\nDindigul District, Tamil Nadu, 624619",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053e8"
    },
    "Rank": 16629,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053e9"
    },
    "Rank": 16630,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053ea"
    },
    "Rank": 16631,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gvernment Medical College,Haldwani,Government Medical College, Haldwani, Uttarakhand, 263139",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053eb"
    },
    "Rank": 16632,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053ec"
    },
    "Rank": 16633,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053ed"
    },
    "Rank": 16634,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053ee"
    },
    "Rank": 16635,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053ef"
    },
    "Rank": 16638,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital, B.H.Road, Tumakuru-572101, Karnataka, 572101",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053f0"
    },
    "Rank": 16639,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agarsen Hospital, ,., Delhi (NCT), 110026",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053f1"
    },
    "Rank": 16641,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Choithram Hospital and Research Centre,Manika Bagh Road Indore, Madhya Pradesh, 452014",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053f2"
    },
    "Rank": 16643,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Philomena`s Hospital, ,No.4, Campbell Road, Viveknagar P.O. Bangalore - 560047, Karnataka, 560047",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053f3"
    },
    "Rank": 16644,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053f4"
    },
    "Rank": 16645,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053f5"
    },
    "Rank": 16652,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lotus Eye Hospital, ,Lotus Eye Hospital and Institute Civil Aerodrome Post\nAvinashi Road Coimbatore, Tamil Nadu, 641014",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053f6"
    },
    "Rank": 16659,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lalitha Super Specialty Hospital, ,KOTHAPET GUNTUR, Andhra Pradesh, 522001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053f7"
    },
    "Rank": 16661,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS Medical College Valsad,Nanakwada Halar Road,Valsad ,Gujarat, Gujarat, 396001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053f8"
    },
    "Rank": 16662,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053f9"
    },
    "Rank": 16667,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053fa"
    },
    "Rank": 16669,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Eye Foundation,,582A D B Road, R S Puram, Coimbatore-641002, Tamil Nadu, 641002",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053fb"
    },
    "Rank": 16676,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053fc"
    },
    "Rank": 16677,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Artemis Health Institute, ,Sector 51, Gurugram, Haryana -122001, Haryana, 122001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053fd"
    },
    "Rank": 16681,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "INSTITUTE OF HUMAN  BEHAVIOUR AND ALLIED SCIENCES,Institute of Human\nBehaviour and Allied Sciences,  Dilshad Garden, New Delhi, Delhi 110095, India, Delhi (NCT), 110095",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053fe"
    },
    "Rank": 16682,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INSTITUTE OF MEDICAL SCIENCES,RAICHUR INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 584102",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a053ff"
    },
    "Rank": 16684,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital\nNext to Sainik School second gate Athani Road, Vijayapura Karnataka 586103, Karnataka, 586103",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05400"
    },
    "Rank": 16685,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05401"
    },
    "Rank": 16686,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05402"
    },
    "Rank": 16688,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sarvodaya Hospital and Research Centre, ,YMCA road Sector 08\nFaridabad, Haryana, 121006",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05403"
    },
    "Rank": 16690,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "AMAR HOSPITAL, Karnataka\n,Amar Hospital, Julai Nagar, Gangavathi, Karnataka, 583227",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05404"
    },
    "Rank": 16691,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05405"
    },
    "Rank": 16692,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Multispecialty Hospital, ,Narayana Multispecialty Hospital, Jaipur Sec-28,Kumbha Marg, Pratap Nagar Sanganer 302033",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05406"
    },
    "Rank": 16693,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Balaji Action Medical Institute,,FC34 A4 PASCHIM VIHAR NEW DELHI, Delhi (NCT), 110063",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05407"
    },
    "Rank": 16698,
    "Allotted Quota": "All India",
    "Allotted Institute": "BHOPAL MEMORIAL HOSPITAL AND RESEARCH CENTRE, BHOPAL,Raisen By pass\nRoad, Bhopal- 462038 (M.P.), Madhya Pradesh, 462038",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05408"
    },
    "Rank": 16700,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05409"
    },
    "Rank": 16702,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0540a"
    },
    "Rank": 16704,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Anand Hospital and Eye Centre, ,21 BHARAT MATA LANE JAMNA LAL BAJAJ MARG C SCHEME JAIPUR EXT. SER. AT 406 SURESH MARG NEAR BAGADIA B,\nRajasthan, 302001",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0540b"
    },
    "Rank": 16706,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0540c"
    },
    "Rank": 16707,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0540d"
    },
    "Rank": 16715,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0540e"
    },
    "Rank": 16719,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0540f"
    },
    "Rank": 16720,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05410"
    },
    "Rank": 16722,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05411"
    },
    "Rank": 16723,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medical Trust Hospital, ,M.G. Road Pallimukku\nCochin, Kerala, 682016",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05412"
    },
    "Rank": 16724,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05413"
    },
    "Rank": 16725,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.I.O.T. Hospital, ,4 112 MOUNT POONAMALLEE ROAD MANAPAKKAM\nCHENNAI 600089, Tamil Nadu, 600089",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05414"
    },
    "Rank": 16726,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta The Medicity, ,Medanta The Medicity Sector 38 Gurgoan, Haryana, 122001",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05415"
    },
    "Rank": 16729,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Bundelkhand Medical College Sagar M.P.,Govt. Bundelkhand Medical College Sagar M.P., Madhya Pradesh, 470001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05416"
    },
    "Rank": 16732,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05417"
    },
    "Rank": 16733,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "G Kuppuswamy Naidu Memorial Hospital, ,Post Box No.6327, Nethaji Road, Pappanaickenpalayam, Coimbatore, Tamil Nadu, 641037",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05418"
    },
    "Rank": 16736,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Choithram Hospital and Research Centre,Manika Bagh Road Indore, Madhya Pradesh, 452014",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05419"
    },
    "Rank": 16737,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0541a"
    },
    "Rank": 16738,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College,Tumuki, Bihaguri, Tezpur, Sonitpur, Assam, Assam, 784010",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0541b"
    },
    "Rank": 16740,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0541c"
    },
    "Rank": 16742,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0541d"
    },
    "Rank": 16744,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0541e"
    },
    "Rank": 16748,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Sankaradeva Nethralaya,,96, Basistha Road, Guwahati, Assam, 781028",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0541f"
    },
    "Rank": 16750,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tirumala Hospital, ,PSR COMPLEX, NEAR RTC COMPLEX, VIZIANAGARAM, Andhra Pradesh, 535003",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05420"
    },
    "Rank": 16754,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05421"
    },
    "Rank": 16759,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "DIPLOMA IN CHILD HEALTH/ PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05422"
    },
    "Rank": 16764,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Goverment District Hospital Bundi, ,IN FRONT OF COLLECTORATE BUNDI, Rajasthan, 323001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05423"
    },
    "Rank": 16765,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05424"
    },
    "Rank": 16767,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhabha Atomic Research Centre and Hospital, ,Anushaktinagar, Mumbai, Maharashtra, 400094",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05425"
    },
    "Rank": 16769,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sadguru Nethra Chikitsalaya, ,Shri Sadguru Seva Sangh Trust Jankikund Chitrakoot\nDistrict Satna, Madhya Pradesh, 210204",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05426"
    },
    "Rank": 16770,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05427"
    },
    "Rank": 16771,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05428"
    },
    "Rank": 16774,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ispat General Hospital,,Ispat General Hospital Rourkela Steel Plant SAIL Rourkela 769005, Odisha, 769005",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05429"
    },
    "Rank": 16775,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lal Bahadur Shastri Hosptial, ,LBSH MAYUR VIHAR PHASE 2 KHICHRIPUR 110091, Delhi (NCT), 110091",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0542a"
    },
    "Rank": 16776,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaikh-Ul-Hind Maulana Mahmood Hasan Medical College, Saharanpur,Pilkhani Ambala Road, Uttar Pradesh, 247232",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0542b"
    },
    "Rank": 16777,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajagiri Hospital,Rajagiri Hospital, Chunagamvely, Aluva 683112, Kerala, 683112",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0542c"
    },
    "Rank": 16778,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Choithram Hospital and Research Centre,Manika Bagh Road Indore, Madhya Pradesh, 452014",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0542d"
    },
    "Rank": 16782,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Daga Memorial Women Government Hospital Nagpur, ,Ganjakhet gandhibagh nagpur, Maharashtra, 440008",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0542e"
    },
    "Rank": 16784,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "V Y Institute of Medical Science, ,Near Kamal Vihar, Dhamtari Road, Raipur, Chhattisgarh, 492001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0542f"
    },
    "Rank": 16787,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05430"
    },
    "Rank": 16788,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,Nalgonda X Roads, Malakpet, Hyderabad, Telangana, 500036",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05431"
    },
    "Rank": 16789,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dharwad,dsdharwad at gmail dot com, Karnataka, 580001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05432"
    },
    "Rank": 16795,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "HOSMAT Hospital, ,NO.45, MAGRATH ROAD, OFF RICHMOND ROAD, BANGALORE,\nKarnataka, 560025",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05433"
    },
    "Rank": 16801,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05434"
    },
    "Rank": 16805,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ispat General Hospital,,Ispat General Hospital Rourkela Steel Plant SAIL Rourkela 769005, Odisha, 769005",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05435"
    },
    "Rank": 16808,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rabindranath Tagore International Institute of Cardiac Sciences, ,PREMISES NO. 1489 124 MUKUNDAPUR  E M BYPASS  KOLKATA-700099, West Bengal, 700099",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05436"
    },
    "Rank": 16811,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Medical College and Chhatrapati Shivaji Maharaj Hospital, ,RAJIV GANDHI MEDICAL COLLEGE,THANE BELAPUR ROAD, KALWA, THANE, Maharashtra, 400605",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05437"
    },
    "Rank": 16813,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05438"
    },
    "Rank": 16816,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Stephen`s Hospital, ,Tis Hazari, Delhi (NCT), 110054",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05439"
    },
    "Rank": 16817,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Babasaheb Ambedkar Central Railway Hospital, ,Bharatratna Dr. Babasaheb Ambedkar Memorial Hospital, Central Railway, Dr. Babasaheb Ambedkar Road,, Maharashtra, 400027",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0543a"
    },
    "Rank": 16821,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ashwini Hospital, Cuttack Orissa,SECTOR 1 CDA CUTTACK, Odisha, 753014",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0543b"
    },
    "Rank": 16825,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0543c"
    },
    "Rank": 16828,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deen Dayal Upadhyay Hospital, ,DDU HOSPITAL, SHAHEED MANGAL PANDEY MARG, NANAK PURA, HARI NAGAR, NEW DELHI, DELHI-110064, Delhi (NCT), 110064",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0543d"
    },
    "Rank": 16831,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0543e"
    },
    "Rank": 16833,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0543f"
    },
    "Rank": 16834,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05440"
    },
    "Rank": 16835,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government Women and Children Hospital, (Formerly Indira Gandhi.\nGeneral Hospital) ,100 feet road, Ellaipillaichavady, Puducherry, 605005",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05441"
    },
    "Rank": 16838,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05442"
    },
    "Rank": 16840,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05443"
    },
    "Rank": 16841,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05444"
    },
    "Rank": 16842,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Balaji Action Medical Institute,,FC34 A4 PASCHIM VIHAR NEW DELHI, Delhi (NCT), 110063",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05445"
    },
    "Rank": 16847,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nethradhama Super Specialty Eye Hospital, ,NO 256-14 KANAKAPURA MAIN ROAD 7TH BLOCK JAYANAGAR BANGALORE 560 070, Karnataka, 560070",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05446"
    },
    "Rank": 16857,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Mulund - Goregaon Link Rd, Nahur West, Industrial Area, Bhandup West, Mumbai, Maharashtra 400078, Maharashtra, 400078",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05447"
    },
    "Rank": 16858,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College, ,Office of the Dean, First Floor, Civil Hospital Campus, Sector- 12, Gandhinagar, Gujarat, 382016",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05448"
    },
    "Rank": 16859,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05449"
    },
    "Rank": 16860,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Artemis Health Institute, ,Sector 51, Gurugram, Haryana -122001, Haryana, 122001",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0544a"
    },
    "Rank": 16863,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, (A unit of Balaji Medical and Diagnostic Research Centre)\n,Max Super Specialty Hospital -108A IP Extension Patparganj New Delhi, Delhi (NCT), 110092",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0544b"
    },
    "Rank": 16868,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0544c"
    },
    "Rank": 16869,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sankar Foundation Eye Hospital,,,9 - 50  Sai Madhava Nagar  Naiduthota Vepagunta   Visakhapatnam, Andhra Pradesh, 530047",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0544d"
    },
    "Rank": 16872,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0544e"
    },
    "Rank": 16874,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0544f"
    },
    "Rank": 16877,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05450"
    },
    "Rank": 16879,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mohan Eye Institute,,11 B, GANGA RAM HOSPITAL MARG, NEW DELHI, Delhi (NCT), 110060",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05451"
    },
    "Rank": 16881,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sakra World Hospital, (A Unit of Takshasila Hospitals Operating Private Limited)\n,LOVEKESH\\DOT\\PHASU\\AT\\SAKRAWORLDHOSPITAL\\DOT\\COM, Karnataka, 560103",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05452"
    },
    "Rank": 16883,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Signature Hospital,Sector-37D, Dwarka Expressway, Gurgaon, Haryana, Haryana, 122001",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05453"
    },
    "Rank": 16885,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. IN NUCLEAR MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05454"
    },
    "Rank": 16890,
    "Allotted Quota": "All India",
    "Allotted Institute": "Smt. G. R. Doshi and Smt. K. M. Mehta Institute of Kidney Diseases & Research Centre Dr. H. L. Trivedi Institute of Transplantation Sciences (IKDRC-ITS),Opp. Trauma Centre, Civil Hospital Campus, Asarwa, Ahmedabad, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05455"
    },
    "Rank": 16891,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "DIP.IN GYNAE. and OBST.",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05456"
    },
    "Rank": 16897,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05457"
    },
    "Rank": 16900,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05458"
    },
    "Rank": 16901,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05459"
    },
    "Rank": 16906,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0545a"
    },
    "Rank": 16908,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0545b"
    },
    "Rank": 16911,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0545c"
    },
    "Rank": 16912,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Choithram Hospital and Research Centre,Manika Bagh Road Indore, Madhya Pradesh, 452014",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0545d"
    },
    "Rank": 16919,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District General Hospital,,Central Jail Road, Rajamahendravaram, East Godavari District, Andhra Pradesh, 533105",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0545e"
    },
    "Rank": 16924,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.I.O.T. Hospital, ,4 112 MOUNT POONAMALLEE ROAD MANAPAKKAM\nCHENNAI 600089, Tamil Nadu, 600089",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0545f"
    },
    "Rank": 16925,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05460"
    },
    "Rank": 16931,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Agarwal`s Eye Hospital and Research Centre, ,222, TTK Road, Alwarpet, Chennai, Tamil Nadu, 600018",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05461"
    },
    "Rank": 16941,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Specialty Hospital, ,Lake View Road, KK. Nagar, Madurai -625020, Tamil Nadu, 625020",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05462"
    },
    "Rank": 16950,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05463"
    },
    "Rank": 16952,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05464"
    },
    "Rank": 16953,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05465"
    },
    "Rank": 16956,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05466"
    },
    "Rank": 16957,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05467"
    },
    "Rank": 16958,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05468"
    },
    "Rank": 16959,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lourdes Hospital, ,Ernakulam, Pachalam, Kochi, Kerala, 682012",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05469"
    },
    "Rank": 16961,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0546a"
    },
    "Rank": 16963,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aditya Birla Memorial Hospital, Maharashtra,Aditya Birla Hospital Marg, P.O.- Chinchwad, Pune, Maharashtra, 411033",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0546b"
    },
    "Rank": 16964,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0546c"
    },
    "Rank": 16967,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Jeyasekharan Hospital and Nursing Home, ,Dr.Jeyasekharan Hospital and Nursing Home,\nK.P.Road, Nagercoil, Kanyakumari District, Tamil Nadu, 629003",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0546d"
    },
    "Rank": 16968,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mata Chanan Devi Hospital,, ,C-1 JANAKPURI, Delhi (NCT), 110058",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0546e"
    },
    "Rank": 16970,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0546f"
    },
    "Rank": 16975,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05470"
    },
    "Rank": 16979,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05471"
    },
    "Rank": 16981,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaipur Golden Hospital, ,2 INSTITUTIONAL AREA, SECTOR-3, ROHINI, Delhi (NCT), 110085",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05472"
    },
    "Rank": 16987,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chaithanya Eye Hospital, ,Kesavadasapuram, Thiruvananthapuram Kerala-695004, Kerala, 695004",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05473"
    },
    "Rank": 16988,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ahalia Hospital ,\n(Women and Childrens Hospital - Maternal and Child Care Centre) ,Ahalia Campus Kozhippara Post Palakkad Kerala 678 557, Kerala, 678557",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05474"
    },
    "Rank": 16993,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Village Lingiadih,,Apollo Hospitals Bilaspur Seepat Road  Lingyadih Village  Bilaspur, Chhattisgarh, 495006",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05475"
    },
    "Rank": 16996,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aadhar Health Institute, (A UNIT OF VLCOM HEALTHCARE P LTD.) ,Aadhar Health Institute, A UNIT OF VLCOM HEALTHCARE P LTD. Tosham Road, Hisar, Haryana- 125001, Haryana, 125001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05476"
    },
    "Rank": 17000,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Adarsha Hospital, ,Near KSRTC Bus Stand, Udupi Taluk and District, Karnataka, Karnataka, 576101",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05477"
    },
    "Rank": 17002,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05478"
    },
    "Rank": 17008,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05479"
    },
    "Rank": 17010,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0547a"
    },
    "Rank": 17011,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Life line Super Specialty Hospital, ,14th Mile, Melood P.O Adoor , Pathanamthitta Dist, Kerala, 691554",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0547b"
    },
    "Rank": 17012,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Gleneagles Hospital, ,58 Canal Circular Road Kolkata 700054, West Bengal, 700054",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0547c"
    },
    "Rank": 17015,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0547d"
    },
    "Rank": 17019,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0547e"
    },
    "Rank": 17021,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Paras HMRI Hospital, ,Paras HMRI Hospital\nNH 30 Bailey Road Raja Bazaar Patna 800014 Bihar, Bihar, 800014",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0547f"
    },
    "Rank": 17022,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05480"
    },
    "Rank": 17025,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05481"
    },
    "Rank": 17030,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hannah Joseph Hospital ,\nTamil Nadu,R.S. No 115-3B2 and 116-1A2, Madurai-Tuticorin Ring Road,\nChinthamani, Madurai 625009\nTamilna, Tamil Nadu, 625009",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05482"
    },
    "Rank": 17042,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Anup Institute of Orthopaedics and Rehabilitation,,G 75-77, P.C. Colony Kankarbagh , Patna, Bihar, 800020",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05483"
    },
    "Rank": 17043,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05484"
    },
    "Rank": 17048,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster Medcity, Kuttisahib Road,,Aster Medcity Kuttisahib Road\nSouth Chittoor P.O. Cheranalloor\nKochi, Kerala, 682027",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05485"
    },
    "Rank": 17050,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Venkateshwar Hospital, ,Sector- 18 A, Dwarka, New Delhi, Delhi (NCT), 110075",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05486"
    },
    "Rank": 17052,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,GENERAL HOSPITAL VYARA OPP DAXINAPATH VIDHAYALAYA VYARA DIST-TAPI, Gujarat, 394650",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05487"
    },
    "Rank": 17054,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05488"
    },
    "Rank": 17055,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Artemis Health Institute, ,Sector 51, Gurugram, Haryana -122001, Haryana, 122001",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05489"
    },
    "Rank": 17056,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0548a"
    },
    "Rank": 17062,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, (A unit of Balaji Medical and Diagnostic Research Centre)\n,Max Super Specialty Hospital -108A IP Extension Patparganj New Delhi, Delhi (NCT), 110092",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0548b"
    },
    "Rank": 17066,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0548c"
    },
    "Rank": 17068,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0548d"
    },
    "Rank": 17069,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital,,GMERS Medical College and Hospital Campus, Dharpur, Patan-Unjha State Highway, Patan (North Gujarat,, Gujarat, 384265",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0548e"
    },
    "Rank": 17071,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital - Sub District Hospital, Gujarat,Opp Nagar Palika Deesa banaskantha, Gujarat, 385535",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0548f"
    },
    "Rank": 17074,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05490"
    },
    "Rank": 17075,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. Sports Medicine",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05491"
    },
    "Rank": 17076,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K B Bhabha Municipal General Hospital, ,R K PATKAR ROAD ,BANDRA WEST\n,MUMBAI, Maharashtra, 400050",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05492"
    },
    "Rank": 17083,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05493"
    },
    "Rank": 17084,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,Nalgonda X Roads, Malakpet, Hyderabad, Telangana, 500036",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05494"
    },
    "Rank": 17088,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital, ,GMERS Medical College and General hospital,Old TB Campus, gotri road, Vadodara, Gujarat, 390021",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05495"
    },
    "Rank": 17089,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05496"
    },
    "Rank": 17092,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05497"
    },
    "Rank": 17093,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "DIPLOMA IN ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05498"
    },
    "Rank": 17096,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Stephen`s Hospital, ,Tis Hazari, Delhi (NCT), 110054",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05499"
    },
    "Rank": 17099,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ahalia Foundation Eye Hospital, ,Ahalia Campus Kozhippara Post Palakkad Kerala 678557, Kerala, 678557",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0549a"
    },
    "Rank": 17100,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pushpawati Singhania Hospital, ,Pushpawati Singhania Hospital & Research Institute Press Enclave Marg, Sheikh Sarai, Phase-2, New D, Delhi (NCT), 110017",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0549b"
    },
    "Rank": 17103,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0549c"
    },
    "Rank": 17104,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0549d"
    },
    "Rank": 17105,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0549e"
    },
    "Rank": 17107,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Moolchand Khairatiram Hospital and Ayurvedic Research Institute,,,Lajpat Nagar-III, New Delhi-110024, Delhi (NCT), 110024",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0549f"
    },
    "Rank": 17108,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vallabhbhai Patel Chest Institute, Delhi,Vallabhbhai Patel Chest Institute, Delhi, Delhi (NCT), 110007",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054a0"
    },
    "Rank": 17110,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054a1"
    },
    "Rank": 17111,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital Whitefield,,ITPL Main Rd, KIADB Export Promotion Industrial Area, Krishnarajapura, Bengaluru, Karnataka, 560066",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054a2"
    },
    "Rank": 17112,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Bhausaheb Hire Government Medical College,Sri Bhausaheb Hire Government Medical College,Dhule, Maharashtra, 424311",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054a3"
    },
    "Rank": 17115,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054a4"
    },
    "Rank": 17119,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deen Dayal Upadhyay Hospital, ,DDU HOSPITAL, SHAHEED MANGAL PANDEY MARG, NANAK PURA, HARI NAGAR, NEW DELHI, DELHI-110064, Delhi (NCT), 110064",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054a5"
    },
    "Rank": 17124,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054a6"
    },
    "Rank": 17125,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medical Trust Hospital, ,M.G. Road Pallimukku\nCochin, Kerala, 682016",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054a7"
    },
    "Rank": 17126,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054a8"
    },
    "Rank": 17127,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054a9"
    },
    "Rank": 17128,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054aa"
    },
    "Rank": 17130,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Balaji Action Medical Institute,,FC34 A4 PASCHIM VIHAR NEW DELHI, Delhi (NCT), 110063",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054ab"
    },
    "Rank": 17131,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054ac"
    },
    "Rank": 17132,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054ad"
    },
    "Rank": 17133,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS Medical College Valsad,Nanakwada Halar Road,Valsad ,Gujarat, Gujarat, 396001",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054ae"
    },
    "Rank": 17135,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054af"
    },
    "Rank": 17136,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Hrudayalaya Hospital (Formerly Sparsh Hospital),,mahadevan.s@narayanahealth.org, Karnataka, 560099",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054b0"
    },
    "Rank": 17137,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054b1"
    },
    "Rank": 17140,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054b2"
    },
    "Rank": 17141,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jupiter Lifeline Hospital,,Jupiter Lifeline Hospital, Sr No 142/12, And 143/1/4/5, Near Prathamesh Park, Baner, Pune, Maharasht, Maharashtra, 411045",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054b3"
    },
    "Rank": 17142,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054b4"
    },
    "Rank": 17146,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "National Institute Of Ophthalmology, ,1187.30 ShivajiNagar, off Ghole Road, Pune, Maharashtra, 411005",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054b5"
    },
    "Rank": 17149,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Parsik Hill Road, ,APOLLO HOSPITAL PARSIK HILL ROAD ROAD NO 13 SECTOR 23 CBD BELAPUR NAVI MUMBAI, Maharashtra, 400614",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054b6"
    },
    "Rank": 17150,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054b7"
    },
    "Rank": 17151,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054b8"
    },
    "Rank": 17162,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054b9"
    },
    "Rank": 17163,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054ba"
    },
    "Rank": 17165,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054bb"
    },
    "Rank": 17166,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054bc"
    },
    "Rank": 17167,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054bd"
    },
    "Rank": 17172,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054be"
    },
    "Rank": 17174,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Government Medical College, Vidisha,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA MP, Madhya Pradesh, 464001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054bf"
    },
    "Rank": 17177,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054c0"
    },
    "Rank": 17178,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054c1"
    },
    "Rank": 17180,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ganga Medical Centre and Hospital,,313 Mettupalayam Road Coimbatore 641043, Tamil Nadu, 641043",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054c2"
    },
    "Rank": 17181,
    "Allotted Quota": "All India",
    "Allotted Institute": "GADAG INSTITUTE OF MEDICAL SCIENCES GADAG,Gadag Institute of Medical Sciences Mallasamudra Gadag-582103, Karnataka, 582103",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054c3"
    },
    "Rank": 17182,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054c4"
    },
    "Rank": 17184,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054c5"
    },
    "Rank": 17186,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Karnataka,Kodagu Institute of Medical Sciences Madikeri Kodagu, Karnataka, 571201",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054c6"
    },
    "Rank": 17187,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054c7"
    },
    "Rank": 17189,
    "Allotted Quota": "All India",
    "Allotted Institute": "MALDA MEDICAL COLLEGE,MALDA MEDICAL COLLEGE, West Bengal, 732101",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054c8"
    },
    "Rank": 17190,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,HBT Medical College and Dr R N Cooper Municipal General Hospital, Bhaktivedanta Swami Rd, JVPD Schem, Maharashtra, 400056",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054c9"
    },
    "Rank": 17191,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Jamshedpur,Mahatma Gandhi Memorial Medical College, Jamshedpur, Jharkhand, 831020",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054ca"
    },
    "Rank": 17193,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Hospital, ,ALEXANDER ROAD, BESIDE HARIHARA KALABHAVAN, NEAR CLOCK TOWER, SECUNDERBAD, Telangana, 500003",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054cb"
    },
    "Rank": 17194,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Vinoba Bhave Civil Hospital,,S R Delkar Marg, Saily Road, Silvassa, Dadra And Nagar Haveli, 396230",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054cc"
    },
    "Rank": 17195,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054cd"
    },
    "Rank": 17196,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Eye Foundation,,582A D B Road, R S Puram, Coimbatore-641002, Tamil Nadu, 641002",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054ce"
    },
    "Rank": 17199,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054cf"
    },
    "Rank": 17208,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054d0"
    },
    "Rank": 17210,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054d1"
    },
    "Rank": 17211,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vijaya Hospital, ,New No 434, Old No 180, NSK Salai, Vadapalani, Chennai - 600026, Tamil Nadu, 600026",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054d2"
    },
    "Rank": 17212,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054d3"
    },
    "Rank": 17215,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054d4"
    },
    "Rank": 17216,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Meenakshi Mission Hospital and Research Centre, ,Lake Area, Melur Road, Madurai, Tamil Nadu, 625107",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054d5"
    },
    "Rank": 17218,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Parsik Hill Road, ,APOLLO HOSPITAL PARSIK HILL ROAD ROAD NO 13 SECTOR 23 CBD BELAPUR NAVI MUMBAI, Maharashtra, 400614",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054d6"
    },
    "Rank": 17221,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054d7"
    },
    "Rank": 17223,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rabindranath Tagore International Institute of Cardiac Sciences, ,PREMISES NO. 1489 124 MUKUNDAPUR  E M BYPASS  KOLKATA-700099, West Bengal, 700099",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054d8"
    },
    "Rank": 17225,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054d9"
    },
    "Rank": 17226,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054da"
    },
    "Rank": 17227,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054db"
    },
    "Rank": 17228,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Eye Foundation,,582A D B Road, R S Puram, Coimbatore-641002, Tamil Nadu, 641002",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054dc"
    },
    "Rank": 17230,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM Health Care Private Limited, Tamil Nadu,New No 72, Old No 54, Nelson Manickam Road, Aminjikarai, Chennai – 600029 Tamilnadu State, Tamil Nadu, 600029",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054dd"
    },
    "Rank": 17233,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054de"
    },
    "Rank": 17234,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054df"
    },
    "Rank": 17235,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vision Eye Centre, ,19 , SIRI FORT ROAD , NEW DELHI, Delhi (NCT), 110049",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054e0"
    },
    "Rank": 17238,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Sector-62, Phase VIII, Mohali- 160062, Punjab, 160062",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054e1"
    },
    "Rank": 17243,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Malabar Institute of Medical Sciences, ,Govindapuram P.O., Mini Bypass Road, Kozhikode, Kerala, 673016",
    "Course": "(NBEMS) Plastic and Reconstructive Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054e2"
    },
    "Rank": 17245,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Vinoba Bhave Civil Hospital,,S R Delkar Marg, Saily Road, Silvassa, Dadra And Nagar Haveli, 396230",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054e3"
    },
    "Rank": 17251,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Artemis Health Institute, ,Sector 51, Gurugram, Haryana -122001, Haryana, 122001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054e4"
    },
    "Rank": 17253,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore West Lions Superspecialty Eye Hospital, ,J C road Bangalore -560002, Karnataka, 560002",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054e5"
    },
    "Rank": 17255,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.J. Somaiya Medical College,,SOMAIYA AYURVIHAR , EASTERN EXPRESS HIGHWAY, SION, MUMBAI 400022, Maharashtra, 400022",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054e6"
    },
    "Rank": 17258,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindu Rao Hospital, ,SUBZI MANDI, MALKA GANJ, DELHI-110007, Delhi (NCT), 110007",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054e7"
    },
    "Rank": 17259,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054e8"
    },
    "Rank": 17260,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.G. Hospital and PG Medical Institute ,,No:5, Arts College Road, Coimbatore - 641018, Tamil Nadu, 641018",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054e9"
    },
    "Rank": 17264,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vivekananda Polyclinic and Institute of Medical Sciences, ,Vivekananda Polyclinic and Institute of Medical Sciences, Vivekananda Puram, Lucknow, Uttar Pradesh, 226007",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054ea"
    },
    "Rank": 17268,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Stephen`s Hospital, ,Tis Hazari, Delhi (NCT), 110054",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054eb"
    },
    "Rank": 17269,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054ec"
    },
    "Rank": 17271,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Sector-62, Phase VIII, Mohali- 160062, Punjab, 160062",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054ed"
    },
    "Rank": 17274,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054ee"
    },
    "Rank": 17280,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahathma Eye Hospital,\n,No. 6, Seshapuram, Tennur, Tiruchirappalli - 620017, Tamilnadu, Tamil Nadu, 620017",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054ef"
    },
    "Rank": 17281,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital, ,Nellikuppam Main Road, Cuddalore, Tamil Nadu, 607001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054f0"
    },
    "Rank": 17282,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054f1"
    },
    "Rank": 17283,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054f2"
    },
    "Rank": 17286,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054f3"
    },
    "Rank": 17287,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054f4"
    },
    "Rank": 17293,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054f5"
    },
    "Rank": 17297,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shaheed Hasan Khan Mewati Government Medical College , ,Shaheed Hasan Khan Mewati Government Medical College Nalhar Nuh, Haryana, 122107",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054f6"
    },
    "Rank": 17298,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Life line Super Specialty Hospital, ,14th Mile, Melood P.O Adoor , Pathanamthitta Dist, Kerala, 691554",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054f7"
    },
    "Rank": 17301,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Cancer Institute and Research Centre, ,Sector-5, Rohini, Delhi-110085, Delhi (NCT), 110085",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054f8"
    },
    "Rank": 17303,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital,,GMERS Medical College and Hospital Campus, Dharpur, Patan-Unjha State Highway, Patan (North Gujarat,, Gujarat, 384265",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054f9"
    },
    "Rank": 17304,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054fa"
    },
    "Rank": 17305,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054fb"
    },
    "Rank": 17306,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Hospital, ,ALEXANDER ROAD, BESIDE HARIHARA KALABHAVAN, NEAR CLOCK TOWER, SECUNDERBAD, Telangana, 500003",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054fc"
    },
    "Rank": 17307,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054fd"
    },
    "Rank": 17308,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Medical Center, ,14 MAJOR ARTERIAL ROAD EW NEWTOWN RAJARHAT KOLKATA 700160, West Bengal, 700160",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054fe"
    },
    "Rank": 17311,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Malabar Institute of Medical Sciences, ,Govindapuram P.O., Mini Bypass Road, Kozhikode, Kerala, 673016",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a054ff"
    },
    "Rank": 17313,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Babasaheb Ambedkar Central Railway Hospital, ,Bharatratna Dr. Babasaheb Ambedkar Memorial Hospital, Central Railway, Dr. Babasaheb Ambedkar Road,, Maharashtra, 400027",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05500"
    },
    "Rank": 17314,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Babasaheb Ambedkar Central Railway Hospital, ,Bharatratna Dr. Babasaheb Ambedkar Memorial Hospital, Central Railway, Dr. Babasaheb Ambedkar Road,, Maharashtra, 400027",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05501"
    },
    "Rank": 17316,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Devadoss Multi Speciality Hospital, ,,DOOR NO 75/1, SURVEYOR COLONY, ALAGAR KOVIL MAIN ROAD, MADURAI, Tamil Nadu, 625007",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05502"
    },
    "Rank": 17319,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05503"
    },
    "Rank": 17321,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05504"
    },
    "Rank": 17323,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05505"
    },
    "Rank": 17325,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05506"
    },
    "Rank": 17326,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05507"
    },
    "Rank": 17327,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ganga Medical Centre and Hospital,,313 Mettupalayam Road Coimbatore 641043, Tamil Nadu, 641043",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05508"
    },
    "Rank": 17331,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jalgaon,Jilha Peth, Government Hospital Campus, Jalgaon, Maharashtra, 425001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05509"
    },
    "Rank": 17332,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharashtra Post Graduate Institute of Medical Education and Research, Nashik,Maharashtra Post Graduation Institute Of Medical Education and Research Civil Hospital campus Trimba, Maharashtra, 422002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0550a"
    },
    "Rank": 17333,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nazareth Hospital, Laitumukhrah, ,Laitumkhrah Shillong, East Khasi Hills district, Meghalaya, 793003",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0550b"
    },
    "Rank": 17338,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vishnuprasad Nandrai Desai Municipal General Hospital, Maharashtra,11 TH GOLIBAR ROAD TPS,SANTACRUZ EAST, Maharashtra, 400055",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0550c"
    },
    "Rank": 17339,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Swami Dayanand Hospital, ,Dilshad Garden, Shahdara, Delhi, Delhi (NCT), 110095",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0550d"
    },
    "Rank": 17342,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0550e"
    },
    "Rank": 17347,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0550f"
    },
    "Rank": 17349,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05510"
    },
    "Rank": 17350,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05511"
    },
    "Rank": 17353,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05512"
    },
    "Rank": 17359,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05513"
    },
    "Rank": 17362,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital Whitefield,,ITPL Main Rd, KIADB Export Promotion Industrial Area, Krishnarajapura, Bengaluru, Karnataka, 560066",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05514"
    },
    "Rank": 17363,
    "Allotted Quota": "All India",
    "Allotted Institute": "The National Institute of Health and Family Welfare,The National Institute of Health and Family Welfare, Delhi (NCT), 110067",
    "Course": "M.D. (COMMUNITY HEALTH  and ADMN.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05515"
    },
    "Rank": 17370,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05516"
    },
    "Rank": 17375,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster Medcity, Kuttisahib Road,,Aster Medcity Kuttisahib Road\nSouth Chittoor P.O. Cheranalloor\nKochi, Kerala, 682027",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05517"
    },
    "Rank": 17377,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05518"
    },
    "Rank": 17378,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Datia,Near 29 Batallion, National Highway 44, Dist Datia, Madhya Pradesh Pin - 475661, Madhya Pradesh, 475661",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05519"
    },
    "Rank": 17380,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.B.Borooah Cancer Institute,Dr.B.Borooah Cancer Institute, Assam, 781016",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0551a"
    },
    "Rank": 17381,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Chandra Sekara Hospital, Tamil Nadu,M-1, M-2, H-23, Avalapalli Housing Board, Bagalur Road, Hosur - 635109, Krishnagiri Dist, Tamil Nadu, Tamil Nadu, 635109",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0551b"
    },
    "Rank": 17383,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0551c"
    },
    "Rank": 17385,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lourdes Hospital, ,Ernakulam, Pachalam, Kochi, Kerala, 682012",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0551d"
    },
    "Rank": 17386,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Specialty Hospital, ,NO. 320, PADMA COMPLEX, MOUNT ROAD, CHENNAI, Tamil Nadu, 600035",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0551e"
    },
    "Rank": 17387,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0551f"
    },
    "Rank": 17392,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05520"
    },
    "Rank": 17394,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Voluntary Health Services and Medical Centre, ,RAJIV GANDHI SALAI, TARAMANI, CHENNAI, Tamil Nadu, 600113",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05521"
    },
    "Rank": 17395,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05522"
    },
    "Rank": 17396,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karnataka,MG Road, Karnataka, 581301",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05523"
    },
    "Rank": 17397,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sankara Eye Hospital, ,Village- Panau Purwa, Post- Tatiyaganj, Dist.- Kanpur Nagar, Uttar Pradesh, 209217",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05524"
    },
    "Rank": 17400,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Escorts Heart Institute, ,Fortis Escorts Heart Institute and Research Centre, Okhla Road, Delhi (NCT), 110025",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05525"
    },
    "Rank": 17401,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajan Eye Care Hospital,,No.5, Vidyodaya second cross street,T.Nagar, Chennai- 600017\nTamil Nadu,\nIndia, Tamil Nadu, 600017",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05526"
    },
    "Rank": 17403,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05527"
    },
    "Rank": 17404,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05528"
    },
    "Rank": 17406,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05529"
    },
    "Rank": 17407,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaiprakash Hospital and Research Centre Pvt. Ltd, Odisha,,JAIPRAKASH HOSPITAL AND RESEARCH CENTER PVT. LTD., DANDIAPALI, ROURKELA, Odisha, 769004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0552a"
    },
    "Rank": 17410,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0552b"
    },
    "Rank": 17413,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0552c"
    },
    "Rank": 17416,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0552d"
    },
    "Rank": 17417,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhabha Atomic Research Centre and Hospital, ,Anushaktinagar, Mumbai, Maharashtra, 400094",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0552e"
    },
    "Rank": 17418,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0552f"
    },
    "Rank": 17419,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05530"
    },
    "Rank": 17421,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05531"
    },
    "Rank": 17424,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE, PUDUCHERRY,INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE,\nPUDUCHERRY, Puducherry, 605009",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05532"
    },
    "Rank": 17429,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lisie Medical Institution, ,Kochi, Kerala, Kerala, 682018",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05533"
    },
    "Rank": 17430,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,Nalgonda X Roads, Malakpet, Hyderabad, Telangana, 500036",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05534"
    },
    "Rank": 17431,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05535"
    },
    "Rank": 17432,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05536"
    },
    "Rank": 17433,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05537"
    },
    "Rank": 17435,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "EMS Memorial Co-operative Hospital and Research Centre, ,Panambi, Perintalmanna, Malappuram District, Kerala, 679322",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05538"
    },
    "Rank": 17437,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05539"
    },
    "Rank": 17438,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0553a"
    },
    "Rank": 17440,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0553b"
    },
    "Rank": 17441,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajagiri Hospital,Rajagiri Hospital, Chunagamvely, Aluva 683112, Kerala, 683112",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0553c"
    },
    "Rank": 17444,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jalgaon,Jilha Peth, Government Hospital Campus, Jalgaon, Maharashtra, 425001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0553d"
    },
    "Rank": 17446,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0553e"
    },
    "Rank": 17448,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0553f"
    },
    "Rank": 17449,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05540"
    },
    "Rank": 17455,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Agarwals Eye Hospital, ,10, South Bypass Road, Vannarpettai,\nTirunelveli, Tamil Nadu, 627003",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05541"
    },
    "Rank": 17456,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Meenakshi Mission Hospital and Research Centre, ,Lake Area, Melur Road, Madurai, Tamil Nadu, 625107",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05542"
    },
    "Rank": 17461,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05543"
    },
    "Rank": 17462,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05544"
    },
    "Rank": 17464,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05545"
    },
    "Rank": 17467,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05546"
    },
    "Rank": 17468,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, West Bengal,730, Em By pass Road, Anandapur, Kolkata, West Bengal, 700107",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05547"
    },
    "Rank": 17469,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05548"
    },
    "Rank": 17471,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05549"
    },
    "Rank": 17472,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0554a"
    },
    "Rank": 17473,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0554b"
    },
    "Rank": 17476,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medical Trust Hospital, ,M.G. Road Pallimukku\nCochin, Kerala, 682016",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0554c"
    },
    "Rank": 17478,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Hedgewar Rugnalaya, ,Dr Hedgewar Rugnalaya Garkheda Aurangabad, Maharashtra, 431005",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0554d"
    },
    "Rank": 17480,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jan Swasthya Sahyog,,,Village and PO Ganiyari - 495112, Dist. Bilaspur,\nChhattisgarh, Chhattisgarh, 495112",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0554e"
    },
    "Rank": 17481,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND ESIC HOSPITAL, COIMBATORE,KAMARAJAR ROAD, VARATHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0554f"
    },
    "Rank": 17482,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05550"
    },
    "Rank": 17484,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05551"
    },
    "Rank": 17495,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05552"
    },
    "Rank": 17496,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rabindranath Tagore International Institute of Cardiac Sciences, ,PREMISES NO. 1489 124 MUKUNDAPUR  E M BYPASS  KOLKATA-700099, West Bengal, 700099",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05553"
    },
    "Rank": 17501,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05554"
    },
    "Rank": 17503,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05555"
    },
    "Rank": 17505,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05556"
    },
    "Rank": 17506,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lakeshore Hospital and Res. ,Maradu, Nettoor P O, Kochi, Kerala, 682040",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05557"
    },
    "Rank": 17507,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05558"
    },
    "Rank": 17509,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,Nalgonda X Roads, Malakpet, Hyderabad, Telangana, 500036",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05559"
    },
    "Rank": 17510,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sarvodaya Hospital and Research Centre, ,YMCA road Sector 08\nFaridabad, Haryana, 121006",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0555a"
    },
    "Rank": 17511,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF HUMAN  BEHAVIOUR AND ALLIED SCIENCES,Institute of Human\nBehaviour and Allied Sciences,  Dilshad Garden, New Delhi, Delhi 110095, India, Delhi (NCT), 110095",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0555b"
    },
    "Rank": 17513,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0555c"
    },
    "Rank": 17519,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruby General Hospital, ,576 Anandapur, Kasba Golpark, E. M. Bypass, Kolkata 700107, West Bengal, 700107",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0555d"
    },
    "Rank": 17524,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital,,B-22, Sector 62, Noida, Uttar Pradesh, Uttar Pradesh, 201301",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0555e"
    },
    "Rank": 17526,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0555f"
    },
    "Rank": 17527,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05560"
    },
    "Rank": 17528,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute Of Medical Sciences, Kalaburagi,VEERESH NAGAR SEDAM ROAD KALABURAGI, Karnataka, 585105",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05561"
    },
    "Rank": 17530,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jehangir Hospital, ,32 Sasoon Road Pune, Maharashtra, 411001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05562"
    },
    "Rank": 17531,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05563"
    },
    "Rank": 17533,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Paras HMRI Hospital, ,Paras HMRI Hospital\nNH 30 Bailey Road Raja Bazaar Patna 800014 Bihar, Bihar, 800014",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05564"
    },
    "Rank": 17535,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05565"
    },
    "Rank": 17542,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05566"
    },
    "Rank": 17543,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05567"
    },
    "Rank": 17545,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05568"
    },
    "Rank": 17551,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "DIP. IN T.B. and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05569"
    },
    "Rank": 17557,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0556a"
    },
    "Rank": 17561,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "DIPLOMA IN CHILD HEALTH/ PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0556b"
    },
    "Rank": 17568,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0556c"
    },
    "Rank": 17571,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0556d"
    },
    "Rank": 17573,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0556e"
    },
    "Rank": 17574,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Philomena`s Hospital, ,No.4, Campbell Road, Viveknagar P.O. Bangalore - 560047, Karnataka, 560047",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0556f"
    },
    "Rank": 17576,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "G Kuppuswamy Naidu Memorial Hospital, ,Post Box No.6327, Nethaji Road, Pappanaickenpalayam, Coimbatore, Tamil Nadu, 641037",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05570"
    },
    "Rank": 17577,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, (Formerly- Pushpanjali Crosslay Hospital) ,W-3, Sector-1, Vaishali, Ghaziabad, Uttar Pradesh, 201012",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05571"
    },
    "Rank": 17581,
    "Allotted Quota": "All India",
    "Allotted Institute": "GADAG INSTITUTE OF MEDICAL SCIENCES GADAG,Gadag Institute of Medical Sciences Mallasamudra Gadag-582103, Karnataka, 582103",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05572"
    },
    "Rank": 17582,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05573"
    },
    "Rank": 17583,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05574"
    },
    "Rank": 17584,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05575"
    },
    "Rank": 17587,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital,\n,OPPOSITE MARALU SIDDESHWARA TEMPLE MG ROAD CROSS CHIKKABALLAPUR-562101, Karnataka, 562101",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05576"
    },
    "Rank": 17589,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05577"
    },
    "Rank": 17590,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "G Kuppuswamy Naidu Memorial Hospital, ,Post Box No.6327, Nethaji Road, Pappanaickenpalayam, Coimbatore, Tamil Nadu, 641037",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05578"
    },
    "Rank": 17591,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Isabels Hospital, ,49, Oliver Road, Mylapore, Chennai., Tamil Nadu, 600004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05579"
    },
    "Rank": 17594,
    "Allotted Quota": "All India",
    "Allotted Institute": "BHOPAL MEMORIAL HOSPITAL AND RESEARCH CENTRE, BHOPAL,Raisen By pass\nRoad, Bhopal- 462038 (M.P.), Madhya Pradesh, 462038",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0557a"
    },
    "Rank": 17595,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0557b"
    },
    "Rank": 17598,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0557c"
    },
    "Rank": 17599,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "G Kuppuswamy Naidu Memorial Hospital, ,Post Box No.6327, Nethaji Road, Pappanaickenpalayam, Coimbatore, Tamil Nadu, 641037",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0557d"
    },
    "Rank": 17601,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0557e"
    },
    "Rank": 17604,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore West Lions Superspecialty Eye Hospital, ,J C road Bangalore -560002, Karnataka, 560002",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0557f"
    },
    "Rank": 17606,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05580"
    },
    "Rank": 17609,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Agarwals Eye Hospital, ,10, South Bypass Road, Vannarpettai,\nTirunelveli, Tamil Nadu, 627003",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05581"
    },
    "Rank": 17611,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05582"
    },
    "Rank": 17612,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05583"
    },
    "Rank": 17613,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05584"
    },
    "Rank": 17614,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Multispecialty Hospital, ,Narayana Multispecialty Hospital, Jaipur Sec-28,Kumbha Marg, Pratap Nagar Sanganer 302033",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05585"
    },
    "Rank": 17618,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05586"
    },
    "Rank": 17620,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SETH V. C. GANDHI and M. A. VORA MUNICIPAL GENERAL HOSPITAL (RAJAWADI HOSPITAL),\nMaharashtra,hospital.rajawadi@gmail.com, Maharashtra, 400077",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05587"
    },
    "Rank": 17622,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences Ltd, ,Dargamitta, Nellore, Andhra Pradesh, 524003",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05588"
    },
    "Rank": 17628,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mythri Hospital , Telangana,H.no 13-6-434/C/148/1 to 4\nSBRK COMPLEX RING ROAD MEHDIPATNAM\nHYDERABAD, Telangana, 500008",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05589"
    },
    "Rank": 17630,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lisie Medical Institution, ,Kochi, Kerala, Kerala, 682018",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0558a"
    },
    "Rank": 17631,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta Hospital,\nUttar Pradesh,Medanta hospital, Sec A pocket 1, Amar Shaheed Path Sushant Golf City Lucknow, Uttar Pradesh, 226030",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0558b"
    },
    "Rank": 17632,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0558c"
    },
    "Rank": 17634,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0558d"
    },
    "Rank": 17637,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0558e"
    },
    "Rank": 17640,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saifee Hospital, ,postbox no 3771, 15-17 Maharshi Karve Marg, Charni road, Mumbai, Maharashtra, 400004",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0558f"
    },
    "Rank": 17641,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Escorts Hospital,,Fortis escorts hospital ,neelam bata road, faridabad, Haryana, 121002",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05590"
    },
    "Rank": 17642,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lions Club Sadhu Ram Eye Hospital, ,1-2-8, GAGANMAHAL ROAD DOMALGUDA HYDERABAD PIN 500029 TELANGANA STATE, Andhra Pradesh, 500029",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05591"
    },
    "Rank": 17645,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05592"
    },
    "Rank": 17646,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster CMI Hospital, ,43/2 New Airport Road, NH-7, Kodigehalli, Sahakara Nagar, Hebbal, Bengaluru, Karnataka, 560092",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05593"
    },
    "Rank": 17647,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05594"
    },
    "Rank": 17648,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,LOTUS TOWER, G S ROAD, GUWAHATI, Assam, 781005",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05595"
    },
    "Rank": 17649,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Ramakrishna Hospital, ,395, Sarojini Naidu Road, Sidhapudur,\nCoimbatore - 641 044, Tamil Nadu, 641044",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05596"
    },
    "Rank": 17653,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05597"
    },
    "Rank": 17655,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05598"
    },
    "Rank": 17657,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.I.O.T. Hospital, ,4 112 MOUNT POONAMALLEE ROAD MANAPAKKAM\nCHENNAI 600089, Tamil Nadu, 600089",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05599"
    },
    "Rank": 17658,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "South Central Railways Hospital, ,SOUTH CENTRAL RAILWAYS CENTRAL HOSPITAL, OFFICE OF THE MEDICAL DIRECTOR, 1ST FLOOR\nLALLAGUDA, METT, Telangana, 500017",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0559a"
    },
    "Rank": 17661,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "INDLAS Vijayawada Institute of Mental Health and Neuro Sciences (INDLAS VIMHANS Hospital), ,29 7 32 V V RAO STREET DEVI OIL MILL ROAD\nSURYARAOPETA VIJAYAWADA 520002, Andhra Pradesh, 520002",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0559b"
    },
    "Rank": 17662,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lakeshore Hospital and Res. ,Maradu, Nettoor P O, Kochi, Kerala, 682040",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0559c"
    },
    "Rank": 17664,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lokopriya Gopinath Bordoloi Regional Institute of Mental Health,LGBRIMH, Tezpur, District Sonitpur,, Assam, 784001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0559d"
    },
    "Rank": 17667,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. R N Cooper Municipal General Hospital, (Associated with H B T (Hinduhridayasamrat Balasaheb Thackeray) medical College) ,HBTMC AND DR.R.N.COOPER HOSPITAL\nU 15 BHAKTI VEDANTA ROAD JVPD SCHEME VILE PARLE WEST MUMBAI,\nMaharashtra, 400056",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0559e"
    },
    "Rank": 17668,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Cosmopolitan Hospital,,COSMOPOLITAN HOSPITALS Pvt.Ltd, Murinjapalam, Pattom P O, Thiruvananthapuram, Kerala, 695004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0559f"
    },
    "Rank": 17670,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055a0"
    },
    "Rank": 17672,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055a1"
    },
    "Rank": 17683,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055a2"
    },
    "Rank": 17685,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Baba Saheb Ambedkar Hospital, ,SEC 6 ROHINI DELHI, Delhi (NCT), 110085",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055a3"
    },
    "Rank": 17686,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055a4"
    },
    "Rank": 17687,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Noble Hospital, ,Magarpatta City Road, Hadpsar, Pune, Maharashtra, 411028",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055a5"
    },
    "Rank": 17689,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055a6"
    },
    "Rank": 17691,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055a7"
    },
    "Rank": 17695,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saifee Hospital, ,postbox no 3771, 15-17 Maharshi Karve Marg, Charni road, Mumbai, Maharashtra, 400004",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055a8"
    },
    "Rank": 17699,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055a9"
    },
    "Rank": 17701,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055aa"
    },
    "Rank": 17702,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055ab"
    },
    "Rank": 17705,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055ac"
    },
    "Rank": 17706,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Udhampur ,\nJammu and Kashmir,Sallian Talab Dhar Road Udhampur, Jammu And Kashmir, 182101",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055ad"
    },
    "Rank": 17707,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr Shyama Prasad Mukherjee (Civil) Hospital, ,Park Road, Hazratganj, Lucknow, Uttar Pradesh, 226001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055ae"
    },
    "Rank": 17709,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055af"
    },
    "Rank": 17711,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "DIPLOMA IN ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055b0"
    },
    "Rank": 17717,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM Health Care Private Limited, Tamil Nadu,New No 72, Old No 54, Nelson Manickam Road, Aminjikarai, Chennai – 600029 Tamilnadu State, Tamil Nadu, 600029",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055b1"
    },
    "Rank": 17722,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055b2"
    },
    "Rank": 17723,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital,,DNB office, 3rd Floor Opp Library  Apollo Hospitals, Jubilee Hills, Hyderabad, Telangana, 500033",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055b3"
    },
    "Rank": 17724,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sankar Foundation Eye Hospital,,,9 - 50  Sai Madhava Nagar  Naiduthota Vepagunta   Visakhapatnam, Andhra Pradesh, 530047",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055b4"
    },
    "Rank": 17726,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lakeshore Hospital and Res. ,Maradu, Nettoor P O, Kochi, Kerala, 682040",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055b5"
    },
    "Rank": 17727,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055b6"
    },
    "Rank": 17728,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kokilaben Dhirubhai Ambani Hospital and Medical Research Institute,,Achyutrao Patwardhan Marg Andheri West Mumbai Maharashtra, Maharashtra, 400053",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055b7"
    },
    "Rank": 17730,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055b8"
    },
    "Rank": 17732,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055b9"
    },
    "Rank": 17733,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tirumala Hospital, ,PSR COMPLEX, NEAR RTC COMPLEX, VIZIANAGARAM, Andhra Pradesh, 535003",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055ba"
    },
    "Rank": 17737,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055bb"
    },
    "Rank": 17738,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,RAJBHAVAN ROAD SOMAJIGUDA, Telangana, 500082",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055bc"
    },
    "Rank": 17741,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Vasantrao Naik Govt Medical College Yavatmal,Shri Vasantrao Naik Govt Medical College Yavatmal, Maharashtra, 445001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055bd"
    },
    "Rank": 17744,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "North 24 Parganas District Hospital, ,BANAMALIPUR BARASAT WEST BENGAL, West Bengal, 700124",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055be"
    },
    "Rank": 17745,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Haria L G Rotary Hospital, ,PLOT NO.363\\1 & 364 HOUSING SECTOR GIDC VAPI GUJARAT 396195, Gujarat, 396195",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055bf"
    },
    "Rank": 17746,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055c0"
    },
    "Rank": 17747,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "EMS Memorial Co-operative Hospital and Research Centre, ,Panambi, Perintalmanna, Malappuram District, Kerala, 679322",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055c1"
    },
    "Rank": 17748,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.G. Hospital and PG Medical Institute ,,No:5, Arts College Road, Coimbatore - 641018, Tamil Nadu, 641018",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055c2"
    },
    "Rank": 17750,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sadhu Vaswani Missions ,7-9 Lane No 1 Koregaon Park Pune 411001, Maharashtra, 411001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055c3"
    },
    "Rank": 17751,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055c4"
    },
    "Rank": 17752,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055c5"
    },
    "Rank": 17755,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.J. Somaiya Medical College,,SOMAIYA AYURVIHAR , EASTERN EXPRESS HIGHWAY, SION, MUMBAI 400022, Maharashtra, 400022",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055c6"
    },
    "Rank": 17760,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, (Formerly- Pushpanjali Crosslay Hospital) ,W-3, Sector-1, Vaishali, Ghaziabad, Uttar Pradesh, 201012",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055c7"
    },
    "Rank": 17762,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055c8"
    },
    "Rank": 17763,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055c9"
    },
    "Rank": 17764,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhaktivedanta Hospital and Research Institute, ,Bhaktivedanta Hospital and Research Institute,Srishti Complex, Bhaktivedanta Swami Marg, Mira Road E, Maharashtra, 401107",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055ca"
    },
    "Rank": 17765,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital - Sub District Hospital, Gujarat,Opp Nagar Palika Deesa banaskantha, Gujarat, 385535",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055cb"
    },
    "Rank": 17766,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055cc"
    },
    "Rank": 17767,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055cd"
    },
    "Rank": 17768,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sarvodaya Hospital and Research Centre, ,YMCA road Sector 08\nFaridabad, Haryana, 121006",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055ce"
    },
    "Rank": 17770,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESI Hospital, ,IG ESI HOSPITAL JHILMIL DELHI - 95, Delhi (NCT), 110095",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055cf"
    },
    "Rank": 17772,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055d0"
    },
    "Rank": 17775,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055d1"
    },
    "Rank": 17778,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Artemis Health Institute, ,Sector 51, Gurugram, Haryana -122001, Haryana, 122001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055d2"
    },
    "Rank": 17779,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055d3"
    },
    "Rank": 17780,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055d4"
    },
    "Rank": 17783,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apex Hospital,,N7 2A 5D BLW Hydel Road Varanasi, Uttar Pradesh, 221004",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055d5"
    },
    "Rank": 17784,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Adarsha Hospital, ,Near KSRTC Bus Stand, Udupi Taluk and District, Karnataka, Karnataka, 576101",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055d6"
    },
    "Rank": 17786,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aadhar Health Institute, (A UNIT OF VLCOM HEALTHCARE P LTD.) ,Aadhar Health Institute, A UNIT OF VLCOM HEALTHCARE P LTD. Tosham Road, Hisar, Haryana- 125001, Haryana, 125001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055d7"
    },
    "Rank": 17788,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nandadeep Eye Hospital,OPPOSITE TO PATIDAR BHAVAN, MADHAV NAGAR ROAD,\nSANGLI, Maharashtra, 416416",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055d8"
    },
    "Rank": 17791,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Escorts Hospital,,Fortis escorts hospital ,neelam bata road, faridabad, Haryana, 121002",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055d9"
    },
    "Rank": 17792,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Specialty Hospitals, ,IIIM NEHRU NAGAR GHAZIABAD, Uttar Pradesh, 201001",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055da"
    },
    "Rank": 17793,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055db"
    },
    "Rank": 17795,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055dc"
    },
    "Rank": 17796,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC MEDICAL COLLEGE, JOKA,ESI-PGIMSR & ESIC MEDICAL COLLEGE, JOKA, KOLKATA, WEST BENGAL, West Bengal, 700104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055dd"
    },
    "Rank": 17800,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dharwad,dsdharwad at gmail dot com, Karnataka, 580001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055de"
    },
    "Rank": 17802,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055df"
    },
    "Rank": 17804,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "C.S.I. Holdsworth Memorial Hospital, ,P.B.No. 38, Sawday Road, Mandi Mohalla, Mysuru, Karnataka, 570001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055e0"
    },
    "Rank": 17805,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055e1"
    },
    "Rank": 17807,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jupiter Lifeline Hospital,,Jupiter Lifeline Hospital, Sr No 142/12, And 143/1/4/5, Near Prathamesh Park, Baner, Pune, Maharasht, Maharashtra, 411045",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055e2"
    },
    "Rank": 17808,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055e3"
    },
    "Rank": 17812,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055e4"
    },
    "Rank": 17815,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055e5"
    },
    "Rank": 17816,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055e6"
    },
    "Rank": 17820,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vivekananda Polyclinic and Institute of Medical Sciences, ,Vivekananda Polyclinic and Institute of Medical Sciences, Vivekananda Puram, Lucknow, Uttar Pradesh, 226007",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055e7"
    },
    "Rank": 17825,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Ramakrishna Hospital, ,395, Sarojini Naidu Road, Sidhapudur,\nCoimbatore - 641 044, Tamil Nadu, 641044",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055e8"
    },
    "Rank": 17826,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,10 Biplabi Haren Ghosh Sarani Howrah, West Bengal, 711101",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055e9"
    },
    "Rank": 17827,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,APOLLO HOSPITALS BANGALORE A UNIT OF IMPERIAL HOSPITALS AND RESEARCH CENTRE LIMITED 154/11 Opp IIM B, Karnataka, 560076",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055ea"
    },
    "Rank": 17832,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055eb"
    },
    "Rank": 17835,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital, ,New Road, Kovilpatti, Thoothukudi District, Tamil Nadu, 628501",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055ec"
    },
    "Rank": 17838,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055ed"
    },
    "Rank": 17839,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055ee"
    },
    "Rank": 17841,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055ef"
    },
    "Rank": 17844,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharashtra Post Graduate Institute of Medical Education and Research, Nashik,Maharashtra Post Graduation Institute Of Medical Education and Research Civil Hospital campus Trimba, Maharashtra, 422002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055f0"
    },
    "Rank": 17845,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital , ,GOVT HEAD QUARTERS HOSPITAL, THURAIYUR MAIN ROAD,PERAMBALUR, TAMILNADU, Tamil Nadu, 621212",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055f1"
    },
    "Rank": 17847,
    "Allotted Quota": "All India",
    "Allotted Institute": "GADAG INSTITUTE OF MEDICAL SCIENCES GADAG,Gadag Institute of Medical Sciences Mallasamudra Gadag-582103, Karnataka, 582103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055f2"
    },
    "Rank": 17848,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sewa Sadan Eye Hospital,B-Ward Behind Civil Hospital Sant Hirdaram Nagar\nBhopal., Madhya Pradesh, 462030",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055f3"
    },
    "Rank": 17849,
    "Allotted Quota": "All India",
    "Allotted Institute": "Smt. G. R. Doshi and Smt. K. M. Mehta Institute of Kidney Diseases & Research Centre Dr. H. L. Trivedi Institute of Transplantation Sciences (IKDRC-ITS),Opp. Trauma Centre, Civil Hospital Campus, Asarwa, Ahmedabad, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055f4"
    },
    "Rank": 17851,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055f5"
    },
    "Rank": 17854,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055f6"
    },
    "Rank": 17857,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055f7"
    },
    "Rank": 17859,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Hedgewar Rugnalaya, ,Dr Hedgewar Rugnalaya Garkheda Aurangabad, Maharashtra, 431005",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055f8"
    },
    "Rank": 17860,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055f9"
    },
    "Rank": 17861,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055fa"
    },
    "Rank": 17866,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Sathya Sai Institute of Higher Medical Sciences, ,PRASANTHIGRAM , PUTTAPARTHI\nANANTAPUR, Andhra Pradesh, 515134",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055fb"
    },
    "Rank": 17868,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055fc"
    },
    "Rank": 17870,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "C.S.I. Holdsworth Memorial Hospital, ,P.B.No. 38, Sawday Road, Mandi Mohalla, Mysuru, Karnataka, 570001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055fd"
    },
    "Rank": 17873,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055fe"
    },
    "Rank": 17877,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a055ff"
    },
    "Rank": 17879,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lions Club Sadhu Ram Eye Hospital, ,1-2-8, GAGANMAHAL ROAD DOMALGUDA HYDERABAD PIN 500029 TELANGANA STATE, Andhra Pradesh, 500029",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05600"
    },
    "Rank": 17880,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lotus Eye Hospital, ,Lotus Eye Hospital and Institute Civil Aerodrome Post\nAvinashi Road Coimbatore, Tamil Nadu, 641014",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05601"
    },
    "Rank": 17884,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05602"
    },
    "Rank": 17885,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05603"
    },
    "Rank": 17886,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government General Hospital, ,Government General Hospital Thiruvananthapuram, Vanchiyoor PO Thiruvananthapuram Kerala, Kerala, 695035",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05604"
    },
    "Rank": 17887,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05605"
    },
    "Rank": 17888,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital,,Sector 5, Vidhyadhar Nagar, Jaipur, Rajasthan, 302013",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05606"
    },
    "Rank": 17893,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Babasaheb Ambedkar Central Railway Hospital, ,Bharatratna Dr. Babasaheb Ambedkar Memorial Hospital, Central Railway, Dr. Babasaheb Ambedkar Road,, Maharashtra, 400027",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05607"
    },
    "Rank": 17897,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05608"
    },
    "Rank": 17901,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05609"
    },
    "Rank": 17903,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Mulund - Goregaon Link Rd, Nahur West, Industrial Area, Bhandup West, Mumbai, Maharashtra 400078, Maharashtra, 400078",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0560a"
    },
    "Rank": 17910,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0560b"
    },
    "Rank": 17911,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital , ,District Hospital Palakkad, Court Road,\nPalakkad Post, Palakkad, Kerala, 678001",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0560c"
    },
    "Rank": 17914,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0560d"
    },
    "Rank": 17916,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollomedics Superspeciality Hospital,KBC 31, Sector B, LDA Colony, Kanpur Road, Lucknow, Uttar Pradesh, 226012",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0560e"
    },
    "Rank": 17917,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0560f"
    },
    "Rank": 17919,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "INDLAS Vijayawada Institute of Mental Health and Neuro Sciences (INDLAS VIMHANS Hospital), ,29 7 32 V V RAO STREET DEVI OIL MILL ROAD\nSURYARAOPETA VIJAYAWADA 520002, Andhra Pradesh, 520002",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05610"
    },
    "Rank": 17921,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ramkrishna Care Hospital, ,AUROBINDO ENCLAVE, PACHPEDI NAKA,DAHAMTARI ROAD, RAIPUR, Chhattisgarh, 492001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05611"
    },
    "Rank": 17923,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05612"
    },
    "Rank": 17924,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Delhi Hospital And Nursing Home, Haryana,METRO PILLAR NO. 812 NEAR SHYAMJI COMPLEX BAHADURGARH DIST JHAJJAR-124507, Haryana, 124507",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05613"
    },
    "Rank": 17925,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05614"
    },
    "Rank": 17926,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Ramakrishna Hospital, ,395, Sarojini Naidu Road, Sidhapudur,\nCoimbatore - 641 044, Tamil Nadu, 641044",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05615"
    },
    "Rank": 17927,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aadhar Health Institute, (A UNIT OF VLCOM HEALTHCARE P LTD.) ,Aadhar Health Institute, A UNIT OF VLCOM HEALTHCARE P LTD. Tosham Road, Hisar, Haryana- 125001, Haryana, 125001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05616"
    },
    "Rank": 17928,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05617"
    },
    "Rank": 17929,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05618"
    },
    "Rank": 17930,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05619"
    },
    "Rank": 17931,
    "Allotted Quota": "All India",
    "Allotted Institute": "BHOPAL MEMORIAL HOSPITAL AND RESEARCH CENTRE, BHOPAL,Raisen By pass\nRoad, Bhopal- 462038 (M.P.), Madhya Pradesh, 462038",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0561a"
    },
    "Rank": 17932,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0561b"
    },
    "Rank": 17933,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KMC Specialty Hospital, ,No-6, Royal Road, Cantonment, Trichy-620001, Tamil Nadu, 620001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0561c"
    },
    "Rank": 17935,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agrasen Medical College,,NH- 9, VPO - Agroha, District Hisar, Haryana, 125047",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0561d"
    },
    "Rank": 17936,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0561e"
    },
    "Rank": 17939,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0561f"
    },
    "Rank": 17940,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College, Kolhapur,R.K. Nagar Road, Shenda Park, Kolhapur, Maharashtra, Maharashtra, 416012",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05620"
    },
    "Rank": 17941,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05621"
    },
    "Rank": 17942,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05622"
    },
    "Rank": 17943,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05623"
    },
    "Rank": 17944,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Malabar Institute of Medical Sciences, ,Govindapuram P.O., Mini Bypass Road, Kozhikode, Kerala, 673016",
    "Course": "(NBEMS) Paediatric Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05624"
    },
    "Rank": 17946,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Andhra Pradesh,District Hospital Machilipatnam House No  30-21-12-M Block No 30 Revenue ward no 30 Locality  Machil, Andhra Pradesh, 521001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05625"
    },
    "Rank": 17947,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Khan Bahadur Bhabha Hospital, Maharashtra,Khan Bahadur Bhabha Hospital Belgrami road kurla west, Maharashtra, 400070",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05626"
    },
    "Rank": 17952,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05627"
    },
    "Rank": 17954,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05628"
    },
    "Rank": 17958,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahatme Eye Bank and Eye Hospital, ,2163-C, Chintaman Nagar, Near Rajiv Nagar, Somalwada,, Maharashtra, 440025",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05629"
    },
    "Rank": 17959,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College,Fakhruddin Ali Ahmed Medical College, Assam, 781301",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0562a"
    },
    "Rank": 17960,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0562b"
    },
    "Rank": 17961,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital Whitefield,,ITPL Main Rd, KIADB Export Promotion Industrial Area, Krishnarajapura, Bengaluru, Karnataka, 560066",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0562c"
    },
    "Rank": 17962,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. Sports Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0562d"
    },
    "Rank": 17964,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0562e"
    },
    "Rank": 17965,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0562f"
    },
    "Rank": 17967,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Noble Hospital, ,Magarpatta City Road, Hadpsar, Pune, Maharashtra, 411028",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05630"
    },
    "Rank": 17969,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05631"
    },
    "Rank": 17970,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital , ,GMERS MEDICAL COLLEGE , Hadiyol-Gadhoda Road, Near-Government Polytechnic, Hiimmatnagar, Gujarat, 383001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05632"
    },
    "Rank": 17972,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05633"
    },
    "Rank": 17975,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05634"
    },
    "Rank": 17977,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05635"
    },
    "Rank": 17978,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05636"
    },
    "Rank": 17979,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ananthapuri Hospital and Research Institute, ,Chacka, NH Bypass, Thiruvananthapuram 695024, Kerala, 695024",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05637"
    },
    "Rank": 17981,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05638"
    },
    "Rank": 17985,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05639"
    },
    "Rank": 17986,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (PALLIATIVE MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0563a"
    },
    "Rank": 17991,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ganga Medical Centre and Hospital,,313 Mettupalayam Road Coimbatore 641043, Tamil Nadu, 641043",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0563b"
    },
    "Rank": 17992,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0563c"
    },
    "Rank": 17994,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0563d"
    },
    "Rank": 17995,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0563e"
    },
    "Rank": 17997,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollomedics Superspeciality Hospital,KBC 31, Sector B, LDA Colony, Kanpur Road, Lucknow, Uttar Pradesh, 226012",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0563f"
    },
    "Rank": 17998,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05640"
    },
    "Rank": 18003,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Susrut Eye Foundation, West Bengal,HB 36 A 1 Salt Lake City Sector 3 Kolkata 700106, West Bengal, 700106",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05641"
    },
    "Rank": 18004,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kidwai Memorial Institute of Oncolgy,Kidwai Memorial Institute of Oncolgy, Karnataka, 560029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05642"
    },
    "Rank": 18006,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05643"
    },
    "Rank": 18007,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Narayani Hospital and Res. Centre, ,Sripuram Thirumalaikodi Vellore, Tamil Nadu, 632055",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05644"
    },
    "Rank": 18011,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dharwad,dsdharwad at gmail dot com, Karnataka, 580001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05645"
    },
    "Rank": 18014,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05646"
    },
    "Rank": 18015,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chaithanya Eye Hospital, ,Kesavadasapuram, Thiruvananthapuram Kerala-695004, Kerala, 695004",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05647"
    },
    "Rank": 18020,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05648"
    },
    "Rank": 18021,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Vinoba Bhave Civil Hospital,,S R Delkar Marg, Saily Road, Silvassa, Dadra And Nagar Haveli, 396230",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05649"
    },
    "Rank": 18025,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College, Kolhapur,R.K. Nagar Road, Shenda Park, Kolhapur, Maharashtra, Maharashtra, 416012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0564a"
    },
    "Rank": 18026,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0564b"
    },
    "Rank": 18028,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0564c"
    },
    "Rank": 18029,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0564d"
    },
    "Rank": 18030,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0564e"
    },
    "Rank": 18031,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0564f"
    },
    "Rank": 18032,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital,,GMERS Medical College and Hospital Campus, Dharpur, Patan-Unjha State Highway, Patan (North Gujarat,, Gujarat, 384265",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05650"
    },
    "Rank": 18038,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Peerless Hospital and B K Roy Research Centre, ,360, Panchasayar, Kolkata - 700 -094, West Bengal, 700094",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05651"
    },
    "Rank": 18040,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05652"
    },
    "Rank": 18045,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05653"
    },
    "Rank": 18047,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruban Memorial Hospital, Bihar,19 Patliputra Colony Near Patliputra Golambar Patna, Bihar, 800013",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05654"
    },
    "Rank": 18051,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05655"
    },
    "Rank": 18057,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05656"
    },
    "Rank": 18060,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05657"
    },
    "Rank": 18062,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ursala Horsman Memorial Hospital, ,Bada Chauraha, Civil Lines,Kanpur Nagar, Uttar Pradesh, 208001",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05658"
    },
    "Rank": 18064,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Gokulam Hospital,,3 60 MEYYANUR ROAD FIVE ROADS\nSALEM - 636004, Tamil Nadu, 636004",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05659"
    },
    "Rank": 18065,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0565a"
    },
    "Rank": 18067,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Daga Memorial Women Government Hospital Nagpur, ,Ganjakhet gandhibagh nagpur, Maharashtra, 440008",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0565b"
    },
    "Rank": 18068,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0565c"
    },
    "Rank": 18069,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0565d"
    },
    "Rank": 18073,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Village Lingiadih,,Apollo Hospitals Bilaspur Seepat Road  Lingyadih Village  Bilaspur, Chhattisgarh, 495006",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0565e"
    },
    "Rank": 18075,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0565f"
    },
    "Rank": 18076,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government Women and Children Hospital, (Formerly Indira Gandhi.\nGeneral Hospital) ,100 feet road, Ellaipillaichavady, Puducherry, 605005",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05660"
    },
    "Rank": 18077,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Central Hospital, ,Central Hospital, P.O- Jagjiwan Nagar, Dist- Dhanbad, Pin-826003, Jharkhand, 826003",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05661"
    },
    "Rank": 18082,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM Health Care Private Limited, Tamil Nadu,New No 72, Old No 54, Nelson Manickam Road, Aminjikarai, Chennai – 600029 Tamilnadu State, Tamil Nadu, 600029",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05662"
    },
    "Rank": 18083,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sankar Foundation Eye Hospital,,,9 - 50  Sai Madhava Nagar  Naiduthota Vepagunta   Visakhapatnam, Andhra Pradesh, 530047",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05663"
    },
    "Rank": 18084,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital, ,Kharvad medan Sipor road Vadnagar, Gujarat, 384355",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05664"
    },
    "Rank": 18090,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05665"
    },
    "Rank": 18091,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05666"
    },
    "Rank": 18093,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Signature Hospital,Sector-37D, Dwarka Expressway, Gurgaon, Haryana, Haryana, 122001",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05667"
    },
    "Rank": 18094,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ramkrishna Care Hospital, ,AUROBINDO ENCLAVE, PACHPEDI NAKA,DAHAMTARI ROAD, RAIPUR, Chhattisgarh, 492001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05668"
    },
    "Rank": 18099,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lisie Medical Institution, ,Kochi, Kerala, Kerala, 682018",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05669"
    },
    "Rank": 18103,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Surya Children`s Medicare, (Formerly Surya Children`s Hospital) ,S. V. Road, Santacruz West Mumbai, Maharashtra, 400054",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0566a"
    },
    "Rank": 18106,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.I.O.T. Hospital, ,4 112 MOUNT POONAMALLEE ROAD MANAPAKKAM\nCHENNAI 600089, Tamil Nadu, 600089",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0566b"
    },
    "Rank": 18107,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kingsway Hospitals, Maharashtra,Medisearch Lifesciences Pvt  Ltd 44SPANV Kingsway Rd near Kasturchand Park Nagpur Maharashtra 440001, Maharashtra, 440001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0566c"
    },
    "Rank": 18109,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0566d"
    },
    "Rank": 18116,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0566e"
    },
    "Rank": 18119,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0566f"
    },
    "Rank": 18120,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05670"
    },
    "Rank": 18121,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05671"
    },
    "Rank": 18128,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05672"
    },
    "Rank": 18131,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05673"
    },
    "Rank": 18132,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05674"
    },
    "Rank": 18139,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Sathya Sai Institute of Higher Medical Sciences, ,PRASANTHIGRAM , PUTTAPARTHI\nANANTAPUR, Andhra Pradesh, 515134",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05675"
    },
    "Rank": 18140,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sreenethra Eye Care, Kerala,sreenethra01@gmail.com, Kerala, 695034",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05676"
    },
    "Rank": 18141,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05677"
    },
    "Rank": 18142,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05678"
    },
    "Rank": 18145,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sparsh Super Specialty Hospital, ,SPARSH SUPER SPECIALITY HOSPITAL, TUMKUR ROAD, YESHWANTHPURA, BANGALORE, Karnataka, 560022",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05679"
    },
    "Rank": 18147,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0567a"
    },
    "Rank": 18152,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0567b"
    },
    "Rank": 18160,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0567c"
    },
    "Rank": 18162,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0567d"
    },
    "Rank": 18165,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Venkateshwar Hospital, ,Sector- 18 A, Dwarka, New Delhi, Delhi (NCT), 110075",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0567e"
    },
    "Rank": 18167,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0567f"
    },
    "Rank": 18170,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sanjay Gandhi Memorial Hospital, , ,Sanjay Gandhi Memorial Hospital\nS-Block, Near Bus Terminal, Mangolpuri, North West, Delhi - 110083, Delhi (NCT), 110083",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05680"
    },
    "Rank": 18172,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Preethi Hospital, ,50, Melur Main Road, Uthangudi, Madurai - 625107, Tamil Nadu, 625107",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05681"
    },
    "Rank": 18175,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05682"
    },
    "Rank": 18178,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Babasaheb Ambedkar Central Railway Hospital, ,Bharatratna Dr. Babasaheb Ambedkar Memorial Hospital, Central Railway, Dr. Babasaheb Ambedkar Road,, Maharashtra, 400027",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05683"
    },
    "Rank": 18182,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Calcutta Medical Research Institute, ,The Calcutta Medical Research Institute 7 by 2 Diamond Harbour Road Kolkata 700027, West Bengal, 700027",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05684"
    },
    "Rank": 18185,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05685"
    },
    "Rank": 18187,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "JLNM Hospital, ,JLNM Hospital Rainawari Srinagar, Jammu And Kashmir, 190003",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05686"
    },
    "Rank": 18190,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05687"
    },
    "Rank": 18191,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajan Eye Care Hospital,,No.5, Vidyodaya second cross street,T.Nagar, Chennai- 600017\nTamil Nadu,\nIndia, Tamil Nadu, 600017",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05688"
    },
    "Rank": 18192,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster CMI Hospital, ,43/2 New Airport Road, NH-7, Kodigehalli, Sahakara Nagar, Hebbal, Bengaluru, Karnataka, 560092",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05689"
    },
    "Rank": 18199,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0568a"
    },
    "Rank": 18200,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0568b"
    },
    "Rank": 18202,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0568c"
    },
    "Rank": 18208,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0568d"
    },
    "Rank": 18209,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0568e"
    },
    "Rank": 18212,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "VCSG Govt Institute of Medical Science and Research and HNB Base Teaching Hospital\n, ,Srikot Ganganali Srinagar Garhwal Uttarakhand, Uttarakhand, 246174",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0568f"
    },
    "Rank": 18215,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vivekananda Polyclinic and Institute of Medical Sciences, ,Vivekananda Polyclinic and Institute of Medical Sciences, Vivekananda Puram, Lucknow, Uttar Pradesh, 226007",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05690"
    },
    "Rank": 18216,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Bhausaheb Hire Government Medical College,Sri Bhausaheb Hire Government Medical College,Dhule, Maharashtra, 424311",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05691"
    },
    "Rank": 18218,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Anand Hospital and Eye Centre, ,21 BHARAT MATA LANE JAMNA LAL BAJAJ MARG C SCHEME JAIPUR EXT. SER. AT 406 SURESH MARG NEAR BAGADIA B,\nRajasthan, 302001",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05692"
    },
    "Rank": 18224,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jehangir Hospital, ,32 Sasoon Road Pune, Maharashtra, 411001",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05693"
    },
    "Rank": 18225,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05694"
    },
    "Rank": 18226,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chinmaya Mission Hospital,,Chinmaya Mission Hospital, 80 Feet CMH Road, Indiranagar, Bangalore, Karnataka, 560038",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05695"
    },
    "Rank": 18227,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "DIP.IN GYNAE. and OBST.",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05696"
    },
    "Rank": 18228,
    "Allotted Quota": "All India",
    "Allotted Institute": "BHOPAL MEMORIAL HOSPITAL AND RESEARCH CENTRE, BHOPAL,Raisen By pass\nRoad, Bhopal- 462038 (M.P.), Madhya Pradesh, 462038",
    "Course": "M.Ch. (Neuro Surgery)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05697"
    },
    "Rank": 18229,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05698"
    },
    "Rank": 18230,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05699"
    },
    "Rank": 18231,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BGS Global Hospital, ,BGS Global Hospital, Uttarahalli Road, Kengeri Bangalore, Karnataka, 560060",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0569a"
    },
    "Rank": 18233,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kozhikode District Co-Operative Hospital,The Kozhikode District Co-operative Hospital, Eranhipalam, Kozhikode, 673006, Kerala, 673006",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0569b"
    },
    "Rank": 18235,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0569c"
    },
    "Rank": 18236,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Jammu and Kashmir\n,DUDERHAMA GANDERBAL, Jammu And Kashmir, 191201",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0569d"
    },
    "Rank": 18237,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Sector-62, Phase VIII, Mohali- 160062, Punjab, 160062",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0569e"
    },
    "Rank": 18240,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0569f"
    },
    "Rank": 18241,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM Health Care Private Limited, Tamil Nadu,New No 72, Old No 54, Nelson Manickam Road, Aminjikarai, Chennai – 600029 Tamilnadu State, Tamil Nadu, 600029",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056a0"
    },
    "Rank": 18243,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056a1"
    },
    "Rank": 18246,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056a2"
    },
    "Rank": 18249,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Anand Charitable Trusts Anand Hospital, Maharashtra,Anand Hospital, Phulewadi Road, Vaijapur Dist. Aurangabad, Maharashtra, 423701",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056a3"
    },
    "Rank": 18258,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056a4"
    },
    "Rank": 18259,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056a5"
    },
    "Rank": 18260,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Jamshedpur,Mahatma Gandhi Memorial Medical College, Jamshedpur, Jharkhand, 831020",
    "Course": "DIP.IN GYNAE. and OBST.",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056a6"
    },
    "Rank": 18263,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shree Markendey Solapur Sahakari Rugnalya and Research Centre, ,Final Plot No.19, Pachha Peth,Solapur, Maharashtra, 413005",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056a7"
    },
    "Rank": 18264,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Paediatric Surgery Centre and P.G. Institute, Maharashtra,8750 Vishrambag Sangli, Maharashtra, 416415",
    "Course": "(NBEMS) Paediatric Surgery (Direct 6 Years Course)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056a8"
    },
    "Rank": 18266,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, ,1,2, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056a9"
    },
    "Rank": 18267,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kamalnayan Bajaj Hospital (Marathwada Medical and Research Institutes), ,Kamalnayan Bajaj Hospital, Gut no.  43, Satara Parisar, Beed bypass road, Auranagabad., Maharashtra, 431010",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056aa"
    },
    "Rank": 18270,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jehangir Hospital, ,32 Sasoon Road Pune, Maharashtra, 411001",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056ab"
    },
    "Rank": 18273,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Amandeep Hospital, ,Amandeep Hospital GT road Model Town Amritsar, Punjab, 143001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056ac"
    },
    "Rank": 18278,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056ad"
    },
    "Rank": 18279,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagwan Mahaveer Jain Hospital, ,MILLERS ROAD, VASANTHNAGAR, BANGALORE, Karnataka, 560052",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056ae"
    },
    "Rank": 18281,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Stephen`s Hospital, ,Tis Hazari, Delhi (NCT), 110054",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056af"
    },
    "Rank": 18284,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056b0"
    },
    "Rank": 18285,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta Hospital,\nUttar Pradesh,Medanta hospital, Sec A pocket 1, Amar Shaheed Path Sushant Golf City Lucknow, Uttar Pradesh, 226030",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056b1"
    },
    "Rank": 18291,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056b2"
    },
    "Rank": 18293,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sarvodaya Hospital and Research Centre, ,YMCA road Sector 08\nFaridabad, Haryana, 121006",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056b3"
    },
    "Rank": 18296,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Siliguri Greater Lions Eye Hospital,\n,2ND MILE, SEVOKE ROQAD, BEHIND VISHAL CINEMA, SILIGURI, DIST.JALPIAGURI, West Bengal, 734002",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056b4"
    },
    "Rank": 18297,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056b5"
    },
    "Rank": 18299,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056b6"
    },
    "Rank": 18300,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster CMI Hospital, ,43/2 New Airport Road, NH-7, Kodigehalli, Sahakara Nagar, Hebbal, Bengaluru, Karnataka, 560092",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056b7"
    },
    "Rank": 18301,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shanmuga Hospital and Salem Cancer Institute, ,24/51 SARADA COLLEGE ROAD\n,SHANMUGA HOSPITAL, SALEM TAMILNADU 636007, Tamil Nadu, 636007",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056b8"
    },
    "Rank": 18302,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056b9"
    },
    "Rank": 18304,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056ba"
    },
    "Rank": 18307,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Citizen Specialty Hospital,,D.No.-1-100-1-CCH, Nallagandla, Serilingampally, Hyderabad, Ranga Reddy District, Telangana, 500019",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056bb"
    },
    "Rank": 18308,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagwan Mahaveer Jain Hospital, ,MILLERS ROAD, VASANTHNAGAR, BANGALORE, Karnataka, 560052",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056bc"
    },
    "Rank": 18309,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056bd"
    },
    "Rank": 18311,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Peerless Hospital and B K Roy Research Centre, ,360, Panchasayar, Kolkata - 700 -094, West Bengal, 700094",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056be"
    },
    "Rank": 18314,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056bf"
    },
    "Rank": 18315,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhaktivedanta Hospital and Research Institute, ,Bhaktivedanta Hospital and Research Institute,Srishti Complex, Bhaktivedanta Swami Marg, Mira Road E, Maharashtra, 401107",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056c0"
    },
    "Rank": 18318,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056c1"
    },
    "Rank": 18319,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lilavati Hospital and Research Centre, ,Lilavati Hospital And Research Centre A-791, Bandra Reclamation, Bandra - West, Mumbai, Maharashtra, 400050",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056c2"
    },
    "Rank": 18320,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056c3"
    },
    "Rank": 18321,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056c4"
    },
    "Rank": 18322,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056c5"
    },
    "Rank": 18324,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056c6"
    },
    "Rank": 18326,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Porwal Hospital, ,bakliwalravi@gmail.com, Rajasthan, 311001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056c7"
    },
    "Rank": 18327,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056c8"
    },
    "Rank": 18329,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Ramakrishna Hospital, ,395, Sarojini Naidu Road, Sidhapudur,\nCoimbatore - 641 044, Tamil Nadu, 641044",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056c9"
    },
    "Rank": 18330,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056ca"
    },
    "Rank": 18335,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056cb"
    },
    "Rank": 18339,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Calcutta Medical Research Institute, ,The Calcutta Medical Research Institute 7 by 2 Diamond Harbour Road Kolkata 700027, West Bengal, 700027",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056cc"
    },
    "Rank": 18340,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chaithanya Eye Hospital, ,Kesavadasapuram, Thiruvananthapuram Kerala-695004, Kerala, 695004",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056cd"
    },
    "Rank": 18345,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deen Dayal Upadhyay Hospital, ,DDU HOSPITAL, SHAHEED MANGAL PANDEY MARG, NANAK PURA, HARI NAGAR, NEW DELHI, DELHI-110064, Delhi (NCT), 110064",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056ce"
    },
    "Rank": 18346,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Artemis Health Institute, ,Sector 51, Gurugram, Haryana -122001, Haryana, 122001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056cf"
    },
    "Rank": 18354,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056d0"
    },
    "Rank": 18355,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vardhman Trauma and LaproscopyCentre Pvt. Ltd., Uttar Pradesh\n,3RD KM. JANSATH ROAD, MUZAFFARNAGAR, Uttar Pradesh, 251001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056d1"
    },
    "Rank": 18356,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056d2"
    },
    "Rank": 18357,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056d3"
    },
    "Rank": 18359,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056d4"
    },
    "Rank": 18362,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056d5"
    },
    "Rank": 18363,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056d6"
    },
    "Rank": 18366,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Agarwal`s Eye Hospital, ,BBC TOWER, LINK ROAD SQUARE,MADHUPATNA,CUTTACK,ORISSA 753010, Odisha, 753010",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056d7"
    },
    "Rank": 18368,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056d8"
    },
    "Rank": 18369,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo BGS Hospital,,Apollo BGS Hospitals, Adhichunchangiri Road, Kuvempunagar, Mysore, Karnataka, 570023",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056d9"
    },
    "Rank": 18372,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "G Kuppuswamy Naidu Memorial Hospital, ,Post Box No.6327, Nethaji Road, Pappanaickenpalayam, Coimbatore, Tamil Nadu, 641037",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056da"
    },
    "Rank": 18375,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharashtra Post Graduate Institute of Medical Education and Research, Nashik,Maharashtra Post Graduation Institute Of Medical Education and Research Civil Hospital campus Trimba, Maharashtra, 422002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056db"
    },
    "Rank": 18377,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056dc"
    },
    "Rank": 18378,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ernakulam Medical Centre,,ERNAKULAM MEDICAL CENTRE NH BYPASS, KOCHI, KERALA, INDIA - 682028, Kerala, 682028",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056dd"
    },
    "Rank": 18380,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056de"
    },
    "Rank": 18381,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aditya Birla Memorial Hospital, Maharashtra,Aditya Birla Hospital Marg, P.O.- Chinchwad, Pune, Maharashtra, 411033",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056df"
    },
    "Rank": 18384,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kokilaben Dhirubhai Ambani Hospital and Medical Research Institute,,Achyutrao Patwardhan Marg Andheri West Mumbai Maharashtra, Maharashtra, 400053",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056e0"
    },
    "Rank": 18385,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056e1"
    },
    "Rank": 18386,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Doon Medical College, Dehradun,Govt. Doon Medical College Dehrakhas, Patel Nagar Dehradun 248001, Uttarakhand, 248001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056e2"
    },
    "Rank": 18389,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056e3"
    },
    "Rank": 18397,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital , ,GMERS MEDICAL COLLEGE , Hadiyol-Gadhoda Road, Near-Government Polytechnic, Hiimmatnagar, Gujarat, 383001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056e4"
    },
    "Rank": 18400,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056e5"
    },
    "Rank": 18401,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Escorts Heart Institute, ,Fortis Escorts Heart Institute and Research Centre, Okhla Road, Delhi (NCT), 110025",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056e6"
    },
    "Rank": 18402,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sree Uthradom Tirunal Hospital,,Pattom, Trivandrum, Kerala, 695004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056e7"
    },
    "Rank": 18403,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Stephen`s Hospital, ,Tis Hazari, Delhi (NCT), 110054",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056e8"
    },
    "Rank": 18404,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lotus Hospitals and Research Centre, 90, ,90, Thayumanava Sundaram Street, Poondurai Main Road, Kollampalayam, Erode, Tamil Nadu, 638002",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056e9"
    },
    "Rank": 18405,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Stephen`s Hospital, ,Tis Hazari, Delhi (NCT), 110054",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056ea"
    },
    "Rank": 18406,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College and  PGIMSR,ESIC Medical College and  PGIMSR, KK Nagar, Chennai - 600 078, Tamil Nadu, 600078",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056eb"
    },
    "Rank": 18408,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Preethi Hospital, ,50, Melur Main Road, Uthangudi, Madurai - 625107, Tamil Nadu, 625107",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056ec"
    },
    "Rank": 18409,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "QRG Medicare, ,Plot No. 1, Sector-16, Faridabad, Haryana, Haryana, 121002",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056ed"
    },
    "Rank": 18410,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056ee"
    },
    "Rank": 18411,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056ef"
    },
    "Rank": 18413,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056f0"
    },
    "Rank": 18415,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056f1"
    },
    "Rank": 18417,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Jeyasekharan Hospital and Nursing Home, ,Dr.Jeyasekharan Hospital and Nursing Home,\nK.P.Road, Nagercoil, Kanyakumari District, Tamil Nadu, 629003",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056f2"
    },
    "Rank": 18422,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056f3"
    },
    "Rank": 18424,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Ramakrishna Hospital, ,395, Sarojini Naidu Road, Sidhapudur,\nCoimbatore - 641 044, Tamil Nadu, 641044",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056f4"
    },
    "Rank": 18425,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medical Trust Hospital, ,M.G. Road Pallimukku\nCochin, Kerala, 682016",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056f5"
    },
    "Rank": 18429,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056f6"
    },
    "Rank": 18430,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056f7"
    },
    "Rank": 18431,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056f8"
    },
    "Rank": 18434,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056f9"
    },
    "Rank": 18437,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indraprastha Apollo Hospital,,Sarita Vihar, New Delhi 110 076, Delhi (NCT), 110076",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056fa"
    },
    "Rank": 18439,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056fb"
    },
    "Rank": 18441,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056fc"
    },
    "Rank": 18444,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital, B.H.Road, Tumakuru-572101, Karnataka, 572101",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056fd"
    },
    "Rank": 18447,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056fe"
    },
    "Rank": 18450,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a056ff"
    },
    "Rank": 18453,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05700"
    },
    "Rank": 18456,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indira Gandhi Medical College and Research Institute, Pondicherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05701"
    },
    "Rank": 18460,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05702"
    },
    "Rank": 18463,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05703"
    },
    "Rank": 18464,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05704"
    },
    "Rank": 18468,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ramkrishna Care Hospital, ,AUROBINDO ENCLAVE, PACHPEDI NAKA,DAHAMTARI ROAD, RAIPUR, Chhattisgarh, 492001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05705"
    },
    "Rank": 18471,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Medical College and Chhatrapati Shivaji Maharaj Hospital, ,RAJIV GANDHI MEDICAL COLLEGE,THANE BELAPUR ROAD, KALWA, THANE, Maharashtra, 400605",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05706"
    },
    "Rank": 18473,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05707"
    },
    "Rank": 18474,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gwalior Mansik Arogyashala, Madhya Pradesh,Gwalior Mansik Arogyashala Jail Rd, Gwalior, Madhya Pradesh 474012, Madhya Pradesh, 474012",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05708"
    },
    "Rank": 18476,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Adlux Hospital ( A Unit of Adlux Medicity and Convention Centre Private Limited), Kerala\n,Apollo Adlux Hospital (A Unit of Adlux medicity and Convention Centre Private limited), Adlux juncti, Kerala, 683576",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05709"
    },
    "Rank": 18480,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital, ,NO 1 RAMAMOORTHY ROAD VIRUDHUNAGAR, Tamil Nadu, 626001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0570a"
    },
    "Rank": 18481,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences Ltd, ,Dargamitta, Nellore, Andhra Pradesh, 524003",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0570b"
    },
    "Rank": 18483,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0570c"
    },
    "Rank": 18484,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo KH Hospital, Tamil Nadu,Apollo KH Hospital, Ranipet - Vellore Bypass Road, Melvisharam, Ranipet District, Tamil Nadu., Tamil Nadu, 632509",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0570d"
    },
    "Rank": 18486,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Andhra Hospital (Vijayawada), ,CVR Complex, Governorpet, Vijayawada-520002, Krishna District, Andhra Pradesh, 520002",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0570e"
    },
    "Rank": 18488,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Peerless Hospital and B K Roy Research Centre, ,360, Panchasayar, Kolkata - 700 -094, West Bengal, 700094",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0570f"
    },
    "Rank": 18491,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Smart Super Specialty Hospital, (Formerly Known as Saket City Hospital), ,Mandir Marg, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05710"
    },
    "Rank": 18492,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Siddipet,SURVEY NO 54  GOVERNMENT MEDICAL COLLEGE  ENSANPALLY  SIDDIPET   502114, Telangana, 502114",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05711"
    },
    "Rank": 18493,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital,,DNB office, 3rd Floor Opp Library  Apollo Hospitals, Jubilee Hills, Hyderabad, Telangana, 500033",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05712"
    },
    "Rank": 18495,
    "Allotted Quota": "All India",
    "Allotted Institute": "SDS Tuberculosis Research Centre and Rajiv Gandhi Institute of Chest Diseases ,SDS Tuberculosis Research Centre and Rajiv Gandhi Institute of Chest Diseases, Karnataka, 560029",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05713"
    },
    "Rank": 18500,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05714"
    },
    "Rank": 18501,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Escorts Hospital,,Fortis escorts hospital ,neelam bata road, faridabad, Haryana, 121002",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05715"
    },
    "Rank": 18502,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05716"
    },
    "Rank": 18507,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05717"
    },
    "Rank": 18508,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B L Kapur Memorial Hospital,Pusa Road New Delhi, Delhi (NCT), 110005",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05718"
    },
    "Rank": 18510,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05719"
    },
    "Rank": 18512,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0571a"
    },
    "Rank": 18514,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0571b"
    },
    "Rank": 18522,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0571c"
    },
    "Rank": 18529,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0571d"
    },
    "Rank": 18531,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0571e"
    },
    "Rank": 18532,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees' State Insurance Corporation Medical College & Hospital, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD GULBARGA, Karnataka, 585106",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0571f"
    },
    "Rank": 18533,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sikkim-Manipal Institute of Medical Sciences, ,5TH MILE , TADONG , GANGTOK, Sikkim, 737102",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05720"
    },
    "Rank": 18534,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05721"
    },
    "Rank": 18538,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05722"
    },
    "Rank": 18539,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05723"
    },
    "Rank": 18543,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Coimbatore childtrust hospitals pvt ltd, Tamil Nadu,111 Nanjappa Nagar Trichy Main Road\nSinganallur Coimbatore, Tamil Nadu, 641005",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05724"
    },
    "Rank": 18544,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05725"
    },
    "Rank": 18546,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05726"
    },
    "Rank": 18547,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MANASA NURSING HOME, Karnataka,JPN Road , 1st Cross, Shivamogga, Karnataka, 577201",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05727"
    },
    "Rank": 18549,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05728"
    },
    "Rank": 18551,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05729"
    },
    "Rank": 18552,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0572a"
    },
    "Rank": 18554,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0572b"
    },
    "Rank": 18555,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0572c"
    },
    "Rank": 18559,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0572d"
    },
    "Rank": 18571,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,APOLLO HOSPITALS BANGALORE A UNIT OF IMPERIAL HOSPITALS AND RESEARCH CENTRE LIMITED 154/11 Opp IIM B, Karnataka, 560076",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0572e"
    },
    "Rank": 18573,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0572f"
    },
    "Rank": 18574,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05730"
    },
    "Rank": 18575,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05731"
    },
    "Rank": 18577,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05732"
    },
    "Rank": 18578,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apex Hospital, ,SP 4 AND 6 MALVIYA INDUSTRIAL AREA MALVIYA NAGAR , JAIPUR,\nRajasthan, 302017",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05733"
    },
    "Rank": 18579,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05734"
    },
    "Rank": 18583,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05735"
    },
    "Rank": 18588,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indraprastha Apollo Hospital,,Sarita Vihar, New Delhi 110 076, Delhi (NCT), 110076",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05736"
    },
    "Rank": 18589,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05737"
    },
    "Rank": 18591,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05738"
    },
    "Rank": 18592,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indo-American Cancer Institute and Research Centre, ,Road No.14, Banjara Hills, Hyderabad, Telangana, 500034",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05739"
    },
    "Rank": 18593,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0573a"
    },
    "Rank": 18594,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0573b"
    },
    "Rank": 18597,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jehangir Hospital, ,32 Sasoon Road Pune, Maharashtra, 411001",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0573c"
    },
    "Rank": 18598,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindu Rao Hospital, ,SUBZI MANDI, MALKA GANJ, DELHI-110007, Delhi (NCT), 110007",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0573d"
    },
    "Rank": 18601,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0573e"
    },
    "Rank": 18603,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0573f"
    },
    "Rank": 18605,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05740"
    },
    "Rank": 18608,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Caritas Hospital, ,Thellakom P.O, Kerala, 686630",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05741"
    },
    "Rank": 18612,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS Medical College Valsad,Nanakwada Halar Road,Valsad ,Gujarat, Gujarat, 396001",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05742"
    },
    "Rank": 18619,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05743"
    },
    "Rank": 18621,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,Nalgonda X Roads, Malakpet, Hyderabad, Telangana, 500036",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05744"
    },
    "Rank": 18625,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "QRG Medicare, ,Plot No. 1, Sector-16, Faridabad, Haryana, Haryana, 121002",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05745"
    },
    "Rank": 18631,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Tony`s Super Speciality Eye Institute,,High Road, Aluva, Ernakulam, Kerala, 683101",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05746"
    },
    "Rank": 18633,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05747"
    },
    "Rank": 18636,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Associated Hospital Govt. Medical College, ,Makeshift Campus, Near Bus Stand, Doda City, Doda, Jammu and Kashmir, Jammu And Kashmir, 182202",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05748"
    },
    "Rank": 18637,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Vasantrao Naik Govt Medical College Yavatmal,Shri Vasantrao Naik Govt Medical College Yavatmal, Maharashtra, 445001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05749"
    },
    "Rank": 18639,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shija Hospitals and Research Institute, ,SHIJA HOSPITALS AND RESEARCH INSTITUTE, LANGOL, LAMPHELPAT, IMPHAL WEST, MANIPUR 795004, Manipur, 795004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0574a"
    },
    "Rank": 18644,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0574b"
    },
    "Rank": 18645,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0574c"
    },
    "Rank": 18652,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0574d"
    },
    "Rank": 18653,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0574e"
    },
    "Rank": 18654,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sikkim-Manipal Institute of Medical Sciences, ,5TH MILE , TADONG , GANGTOK, Sikkim, 737102",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0574f"
    },
    "Rank": 18655,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KIMS Saveera Hospital,1-1348 OPPOSITE SAKSHI OFFICE RUDRAMPET ANANTAPURAM 515004, Andhra Pradesh, 515004",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05750"
    },
    "Rank": 18656,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "HINDU MISSION HOSPITAL,\n,103 G S T Road Tambaram West Chennai, Tamil Nadu, 600045",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05751"
    },
    "Rank": 18657,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sanjay Gandhi Postgarduate Institute of Medical Sciences,Sanjay Gandhi Postgarduate Institute of Medical Sciences, Uttar Pradesh, 226014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05752"
    },
    "Rank": 18658,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kongunad Hospitals Private Limited, ,116,117 11th Street, Tatabad,Opposite to east power house, Tamil Nadu, 641012",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05753"
    },
    "Rank": 18659,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05754"
    },
    "Rank": 18661,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05755"
    },
    "Rank": 18663,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05756"
    },
    "Rank": 18664,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05757"
    },
    "Rank": 18665,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Bharat Ratna Late Shri Atal Bihari Vajpayee Memorial Medical College, Chhattisgarh ,BHARAT RATNA LATE SHRI ATAL BIHARI VAJPAYEE MEMORIAL MEDICAL COLLEGE,\nPENDRI, RAJNANDGAON, CHHATTIS, Chhattisgarh, 491441",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05758"
    },
    "Rank": 18666,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05759"
    },
    "Rank": 18668,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0575a"
    },
    "Rank": 18674,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0575b"
    },
    "Rank": 18676,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0575c"
    },
    "Rank": 18678,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Sathya Sai Instt. of Higher Medical Sciences, ,EPIP AREA, WHITEFIELD, BANGALORE, Karnataka, 560066",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0575d"
    },
    "Rank": 18679,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0575e"
    },
    "Rank": 18681,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "IQRAA International Hospital and Research Centre, ,IQRAA INTERNATIONAL HOSPITAL AND RESEARCH CENTRE MALAPARAMBA CALICUT, Kerala, 673009",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0575f"
    },
    "Rank": 18683,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.G. Hospital and PG Medical Institute ,,No:5, Arts College Road, Coimbatore - 641018, Tamil Nadu, 641018",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05760"
    },
    "Rank": 18684,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05761"
    },
    "Rank": 18685,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05762"
    },
    "Rank": 18687,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05763"
    },
    "Rank": 18689,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pawana Hospital,Pawana Hospital, Gat No-167, Somatane Phata, Tal- Maval. Pune, Maharashtra, 410506",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05764"
    },
    "Rank": 18690,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05765"
    },
    "Rank": 18691,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05766"
    },
    "Rank": 18695,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05767"
    },
    "Rank": 18697,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05768"
    },
    "Rank": 18698,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Quality Care India Limited (Care Hospital), (Formerly Visakha Hospital and Diagnostics (Care Hospital)) ,10-5-11\\5 A S Raja Complex\nRamnagar Waltair Main Road Visakhapatnam, Andhra Pradesh, 530002",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05769"
    },
    "Rank": 18699,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lakeshore Hospital and Res. ,Maradu, Nettoor P O, Kochi, Kerala, 682040",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0576a"
    },
    "Rank": 18702,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital,,DNB office, 3rd Floor Opp Library  Apollo Hospitals, Jubilee Hills, Hyderabad, Telangana, 500033",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0576b"
    },
    "Rank": 18705,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0576c"
    },
    "Rank": 18710,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Suraj Eye Institute, ,559, New Colony, Maharashtra, 440001",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0576d"
    },
    "Rank": 18711,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0576e"
    },
    "Rank": 18712,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SETH V. C. GANDHI and M. A. VORA MUNICIPAL GENERAL HOSPITAL (RAJAWADI HOSPITAL),\nMaharashtra,hospital.rajawadi@gmail.com, Maharashtra, 400077",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0576f"
    },
    "Rank": 18716,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05770"
    },
    "Rank": 18719,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05771"
    },
    "Rank": 18721,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS Medical College Valsad,Nanakwada Halar Road,Valsad ,Gujarat, Gujarat, 396001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05772"
    },
    "Rank": 18722,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "INSTITUTE OF HUMAN  BEHAVIOUR AND ALLIED SCIENCES,Institute of Human\nBehaviour and Allied Sciences,  Dilshad Garden, New Delhi, Delhi 110095, India, Delhi (NCT), 110095",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05773"
    },
    "Rank": 18730,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asansol District Hospital, ,S. B. GORAI ROAD, P.O.- ASANSOL, DIST.- PASCHIM BARDHAMAN, West Bengal, 713301",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05774"
    },
    "Rank": 18731,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aditya Birla Memorial Hospital, Maharashtra,Aditya Birla Hospital Marg, P.O.- Chinchwad, Pune, Maharashtra, 411033",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05775"
    },
    "Rank": 18734,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05776"
    },
    "Rank": 18737,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05777"
    },
    "Rank": 18739,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05778"
    },
    "Rank": 18740,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05779"
    },
    "Rank": 18741,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0577a"
    },
    "Rank": 18743,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Ramakrishna Hospital, ,395, Sarojini Naidu Road, Sidhapudur,\nCoimbatore - 641 044, Tamil Nadu, 641044",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0577b"
    },
    "Rank": 18745,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "South Central Railways Hospital, ,SOUTH CENTRAL RAILWAYS CENTRAL HOSPITAL, OFFICE OF THE MEDICAL DIRECTOR, 1ST FLOOR\nLALLAGUDA, METT, Telangana, 500017",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0577c"
    },
    "Rank": 18748,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0577d"
    },
    "Rank": 18752,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Spirit Hospital, ,Mahakali Caves Road Andheri East Mumbai, Maharashtra, 400093",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0577e"
    },
    "Rank": 18755,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0577f"
    },
    "Rank": 18757,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Rangarajan Memorial Hospital, (Sundaram Medical Foundation) ,Sundaram Medical Foundation, Shanthi Colony, 4th Avenue, Anna Nagar, Chennai, Tamil Nadu, 600040",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05780"
    },
    "Rank": 18759,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05781"
    },
    "Rank": 18763,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05782"
    },
    "Rank": 18765,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital,\n,paddoc road, near majewadi gate, junagadh, Gujarat, 362001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05783"
    },
    "Rank": 18769,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, ,1,2, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05784"
    },
    "Rank": 18773,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medica Superspecialty Hospital, ,127 Mukundapur, E.M. Byapss, Kolkata, West Bengal, 700099",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05785"
    },
    "Rank": 18775,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05786"
    },
    "Rank": 18776,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "DIP.IN GYNAE. and OBST.",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05787"
    },
    "Rank": 18777,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kasturba Hospital, Daryaganj,Delhi,Daryaganj,Delhi",
    "Course": "DIPLOMA IN CHILD HEALTH/ PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05788"
    },
    "Rank": 18778,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05789"
    },
    "Rank": 18779,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Aurobindo Medical Research Centre, ,Dhamtari Road,Pachpedi naka,Lalpur,Raipur, Chhattisgarh, 492001",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0578a"
    },
    "Rank": 18780,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.E.M. Hospital, ,489, SARDAR MOODLIAR ROAD, RASTA PETH, PUNE 411011,\nMaharashtra, 411011",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0578b"
    },
    "Rank": 18781,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0578c"
    },
    "Rank": 18784,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vivekanandha Medical Care Hospital , ,Sf. No. 20-1 Elayampalayam village, Tiruchengode taluk\nNamakkal, Tamil Nadu, 637205",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0578d"
    },
    "Rank": 18790,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government Women and Children Hospital, (Formerly Indira Gandhi.\nGeneral Hospital) ,100 feet road, Ellaipillaichavady, Puducherry, 605005",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0578e"
    },
    "Rank": 18794,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Paras HMRI Hospital, ,Paras HMRI Hospital\nNH 30 Bailey Road Raja Bazaar Patna 800014 Bihar, Bihar, 800014",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0578f"
    },
    "Rank": 18795,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medicover Hospitals, (Formerly MaxCure Hospital) ,Medicover Hospitals , Opposite Cyber gate way , Ibis hotel Lane , hitech city\nMadhapur Hyderabad, Telangana, 500081",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05790"
    },
    "Rank": 18797,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05791"
    },
    "Rank": 18799,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05792"
    },
    "Rank": 18801,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kongunad Hospitals Private Limited, ,116,117 11th Street, Tatabad,Opposite to east power house, Tamil Nadu, 641012",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05793"
    },
    "Rank": 18802,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05794"
    },
    "Rank": 18804,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05795"
    },
    "Rank": 18806,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Bundelkhand Medical College Sagar M.P.,Govt. Bundelkhand Medical College Sagar M.P., Madhya Pradesh, 470001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05796"
    },
    "Rank": 18809,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05797"
    },
    "Rank": 18810,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05798"
    },
    "Rank": 18812,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhabha Atomic Research Centre and Hospital, ,Anushaktinagar, Mumbai, Maharashtra, 400094",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05799"
    },
    "Rank": 18813,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0579a"
    },
    "Rank": 18814,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SBLS Civil Hospital, ,NEAR JYOTI CHOWK JAIL ROAD JALANDHAR, Punjab, 144001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0579b"
    },
    "Rank": 18819,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0579c"
    },
    "Rank": 18820,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0579d"
    },
    "Rank": 18821,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yatharth Superspeciality Hospitals,\nUttar Pradesh,HO-01, Sector-01, Greater Noida West, Uttar Pradesh, 201306",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0579e"
    },
    "Rank": 18823,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0579f"
    },
    "Rank": 18825,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057a0"
    },
    "Rank": 18827,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Wockhardt Hospital, ,1877, Dr. Anand Rao Nair Marg, Mumbai Central, Maharashtra, 400011",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057a1"
    },
    "Rank": 18834,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057a2"
    },
    "Rank": 18836,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ananthapuri Hospital and Research Institute, ,Chacka, NH Bypass, Thiruvananthapuram 695024, Kerala, 695024",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057a3"
    },
    "Rank": 18837,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057a4"
    },
    "Rank": 18838,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057a5"
    },
    "Rank": 18841,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057a6"
    },
    "Rank": 18844,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Vasantrao Naik Govt Medical College Yavatmal,Shri Vasantrao Naik Govt Medical College Yavatmal, Maharashtra, 445001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057a7"
    },
    "Rank": 18846,
    "Allotted Quota": "All India",
    "Allotted Institute": "GADAG INSTITUTE OF MEDICAL SCIENCES GADAG,Gadag Institute of Medical Sciences Mallasamudra Gadag-582103, Karnataka, 582103",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057a8"
    },
    "Rank": 18847,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057a9"
    },
    "Rank": 18852,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057aa"
    },
    "Rank": 18854,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057ab"
    },
    "Rank": 18855,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Andhra Hospital (Vijayawada), ,CVR Complex, Governorpet, Vijayawada-520002, Krishna District, Andhra Pradesh, 520002",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057ac"
    },
    "Rank": 18858,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057ad"
    },
    "Rank": 18860,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057ae"
    },
    "Rank": 18861,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057af"
    },
    "Rank": 18862,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057b0"
    },
    "Rank": 18863,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057b1"
    },
    "Rank": 18865,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057b2"
    },
    "Rank": 18866,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057b3"
    },
    "Rank": 18874,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057b4"
    },
    "Rank": 18875,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indraprastha Apollo Hospital,,Sarita Vihar, New Delhi 110 076, Delhi (NCT), 110076",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057b5"
    },
    "Rank": 18876,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Voluntary Health Services and Medical Centre, ,RAJIV GANDHI SALAI, TARAMANI, CHENNAI, Tamil Nadu, 600113",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057b6"
    },
    "Rank": 18877,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057b7"
    },
    "Rank": 18880,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yatharth Superspeciality Hospitals,\nUttar Pradesh,HO-01, Sector-01, Greater Noida West, Uttar Pradesh, 201306",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057b8"
    },
    "Rank": 18881,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057b9"
    },
    "Rank": 18882,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057ba"
    },
    "Rank": 18883,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ganga Medical Centre and Hospital,,313 Mettupalayam Road Coimbatore 641043, Tamil Nadu, 641043",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057bb"
    },
    "Rank": 18886,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057bc"
    },
    "Rank": 18897,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057bd"
    },
    "Rank": 18901,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.G. Hospital and PG Medical Institute ,,No:5, Arts College Road, Coimbatore - 641018, Tamil Nadu, 641018",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057be"
    },
    "Rank": 18903,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Muthoot Health Care, ,Muthoot Healthcare, College Road, Kozhencherry, Pathanamthitta District, Kerala, 689641",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057bf"
    },
    "Rank": 18907,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057c0"
    },
    "Rank": 18908,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057c1"
    },
    "Rank": 18911,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057c2"
    },
    "Rank": 18915,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Kamakshi Memorial Hospital, (Dr. Kamakshi Institute of Medical Sciences and Research) ,No.1,Radial Road , Pallikaranai, Chennai, Tamil Nadu, 600100",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057c3"
    },
    "Rank": 18916,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District General Hospital, ,Government District General Hospital, Noonepalle, Nandyal - 518502, Andhra Pradesh, 518502",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057c4"
    },
    "Rank": 18919,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Madras Medical Mission Hospital, ,NO-4A, DR J J NAGAR, MOGAPPAIR, CHENNAI, Tamil Nadu, 600037",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057c5"
    },
    "Rank": 18922,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057c6"
    },
    "Rank": 18923,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057c7"
    },
    "Rank": 18937,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Amandeep Hospital, ,Amandeep Hospital GT road Model Town Amritsar, Punjab, 143001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057c8"
    },
    "Rank": 18939,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MALANKARA MEDICAL MISSION HOSPITAL,THRISSUR, Kerala,MOSC MEDICAL MISSION HOSPITAL, KUNNAMKULAM, THRISSUR, Kerala, 680503",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057c9"
    },
    "Rank": 18942,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057ca"
    },
    "Rank": 18943,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057cb"
    },
    "Rank": 18945,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "P.D. Hinduja National Hospital and Medical Research Centre, ,Veer Savarkar Marg, Mahim, Maharashtra, 400016",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057cc"
    },
    "Rank": 18948,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pushpagiri Eye Institute,Plot No 241 Uma Plaza Door No 10 – 2- 342 Road No 9 West Marredpally\nSecunderabad 500026, Telangana, 500026",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057cd"
    },
    "Rank": 18949,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indo-American Cancer Institute and Research Centre, ,Road No.14, Banjara Hills, Hyderabad, Telangana, 500034",
    "Course": "(NBEMS) Palliative Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057ce"
    },
    "Rank": 18951,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057cf"
    },
    "Rank": 18952,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asian Heart Institute and Research Centre, ,G N Block, Bandra Kurla Complex Bandra E Mumbai, Maharashtra, 400051",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057d0"
    },
    "Rank": 18953,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM Health Care Private Limited, Tamil Nadu,New No 72, Old No 54, Nelson Manickam Road, Aminjikarai, Chennai – 600029 Tamilnadu State, Tamil Nadu, 600029",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057d1"
    },
    "Rank": 18956,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057d2"
    },
    "Rank": 18957,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.G.M. Muthoot Hospital, ,MGM Muthoot Medical Centre, Ring Road, Pathanamthitta, Kerala, 689645",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057d3"
    },
    "Rank": 18959,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mythri Hospital , Telangana,H.no 13-6-434/C/148/1 to 4\nSBRK COMPLEX RING ROAD MEHDIPATNAM\nHYDERABAD, Telangana, 500008",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057d4"
    },
    "Rank": 18960,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057d5"
    },
    "Rank": 18966,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jehangir Hospital, ,32 Sasoon Road Pune, Maharashtra, 411001",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057d6"
    },
    "Rank": 18972,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESIC Model Hospital, ,A-3, TULSI MARG, SECTOR-24, NOIDA, Uttar Pradesh, 201301",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057d7"
    },
    "Rank": 18973,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057d8"
    },
    "Rank": 18974,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital,,B-22, Sector 62, Noida, Uttar Pradesh, Uttar Pradesh, 201301",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057d9"
    },
    "Rank": 18976,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital, ,Nellikuppam Main Road, Cuddalore, Tamil Nadu, 607001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057da"
    },
    "Rank": 18977,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057db"
    },
    "Rank": 18979,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Balaji Institute of Neuro Sciences and Trauma, ,HOSUR UNKAL ROAD,VIDYANAGAR,HUBLI, Karnataka, 580021",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057dc"
    },
    "Rank": 18982,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057dd"
    },
    "Rank": 18983,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lalitha Super Specialty Hospital, ,KOTHAPET GUNTUR, Andhra Pradesh, 522001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057de"
    },
    "Rank": 18984,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Preethi Hospital, ,50, Melur Main Road, Uthangudi, Madurai - 625107, Tamil Nadu, 625107",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057df"
    },
    "Rank": 18987,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tirumala Hospital, ,PSR COMPLEX, NEAR RTC COMPLEX, VIZIANAGARAM, Andhra Pradesh, 535003",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057e0"
    },
    "Rank": 18989,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057e1"
    },
    "Rank": 18990,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Multispecialty Hospital, ,Narayana Multispecialty Hospital, Jaipur Sec-28,Kumbha Marg, Pratap Nagar Sanganer 302033",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057e2"
    },
    "Rank": 18994,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jupiter Hospital, ,Eastern Express Highway, Thane West, Maharashtra, 400601",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057e3"
    },
    "Rank": 18995,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057e4"
    },
    "Rank": 19001,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Hospital, ,ALEXANDER ROAD, BESIDE HARIHARA KALABHAVAN, NEAR CLOCK TOWER, SECUNDERBAD, Telangana, 500003",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057e5"
    },
    "Rank": 19003,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "PRS Hospital,,PRS HOSPITAL PVT LTD KILLIPALAM THIRUVANATHAPURAM\nKERALA. <academics@prshospital.com>, Kerala, 695002",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057e6"
    },
    "Rank": 19005,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057e7"
    },
    "Rank": 19006,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057e8"
    },
    "Rank": 19014,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057e9"
    },
    "Rank": 19018,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "DIPLOMA IN CHILD HEALTH/ PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057ea"
    },
    "Rank": 19020,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057eb"
    },
    "Rank": 19021,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Alakh Nayan Mandir Insttitute of Ophthalmology, ,Ashok Nagar, Udaipur - 313001, Rajasthan, 313001",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057ec"
    },
    "Rank": 19023,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lilavati Hospital and Research Centre, ,Lilavati Hospital And Research Centre A-791, Bandra Reclamation, Bandra - West, Mumbai, Maharashtra, 400050",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057ed"
    },
    "Rank": 19027,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Khan Bahadur Bhabha Hospital, Maharashtra,Khan Bahadur Bhabha Hospital Belgrami road kurla west, Maharashtra, 400070",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057ee"
    },
    "Rank": 19028,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Balrampur Hospital, ,Gola Ganj, lucknow, Uttar Pradesh, 226018",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057ef"
    },
    "Rank": 19032,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057f0"
    },
    "Rank": 19033,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057f1"
    },
    "Rank": 19035,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lakeshore Hospital and Res. ,Maradu, Nettoor P O, Kochi, Kerala, 682040",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057f2"
    },
    "Rank": 19036,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057f3"
    },
    "Rank": 19037,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057f4"
    },
    "Rank": 19043,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057f5"
    },
    "Rank": 19046,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057f6"
    },
    "Rank": 19048,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tirumala Hospital, ,PSR COMPLEX, NEAR RTC COMPLEX, VIZIANAGARAM, Andhra Pradesh, 535003",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057f7"
    },
    "Rank": 19051,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruby General Hospital, ,576 Anandapur, Kasba Golpark, E. M. Bypass, Kolkata 700107, West Bengal, 700107",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057f8"
    },
    "Rank": 19052,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057f9"
    },
    "Rank": 19053,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057fa"
    },
    "Rank": 19054,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "P.D. Hinduja National Hospital and Medical Research Centre, ,Veer Savarkar Marg, Mahim, Maharashtra, 400016",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057fb"
    },
    "Rank": 19058,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057fc"
    },
    "Rank": 19059,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057fd"
    },
    "Rank": 19060,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057fe"
    },
    "Rank": 19062,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a057ff"
    },
    "Rank": 19063,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indira Gandhi Medical College and Research Institute, Pondicherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05800"
    },
    "Rank": 19066,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05801"
    },
    "Rank": 19074,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05802"
    },
    "Rank": 19079,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05803"
    },
    "Rank": 19080,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05804"
    },
    "Rank": 19082,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "PRS Hospital,,PRS HOSPITAL PVT LTD KILLIPALAM THIRUVANATHAPURAM\nKERALA. <academics@prshospital.com>, Kerala, 695002",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05805"
    },
    "Rank": 19085,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asian Institute of Medical Sciences,,Asian Institute of Medical Sciences, Badhkal Flyover Road,Sector-21A, Faridabad, Haryana-121001, Haryana, 121001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05806"
    },
    "Rank": 19086,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shanmuga Hospital and Salem Cancer Institute, ,24/51 SARADA COLLEGE ROAD\n,SHANMUGA HOSPITAL, SALEM TAMILNADU 636007, Tamil Nadu, 636007",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05807"
    },
    "Rank": 19087,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05808"
    },
    "Rank": 19090,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kongunad Hospitals Private Limited, ,116,117 11th Street, Tatabad,Opposite to east power house, Tamil Nadu, 641012",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05809"
    },
    "Rank": 19092,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0580a"
    },
    "Rank": 19095,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0580b"
    },
    "Rank": 19098,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0580c"
    },
    "Rank": 19099,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0580d"
    },
    "Rank": 19105,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Metro Heart Institute with Multispecialty, ,SECTOR 16A, Faridabad, Haryana, Haryana, 121002",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0580e"
    },
    "Rank": 19106,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0580f"
    },
    "Rank": 19107,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05810"
    },
    "Rank": 19111,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Sector-62, Phase VIII, Mohali- 160062, Punjab, 160062",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05811"
    },
    "Rank": 19113,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05812"
    },
    "Rank": 19116,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05813"
    },
    "Rank": 19117,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rotary Eye Institute, ,Dudhia Talao, Opp Fire Station, Navsari - 396445, Gujarat, 396445",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05814"
    },
    "Rank": 19129,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saraswathy Hospital, Kerala,Saraswathy Hospital, Parassala, Thiruvananthapuram, Kerala, 695502",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05815"
    },
    "Rank": 19132,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05816"
    },
    "Rank": 19133,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05817"
    },
    "Rank": 19137,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05818"
    },
    "Rank": 19138,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Voluntary Health Services and Medical Centre, ,RAJIV GANDHI SALAI, TARAMANI, CHENNAI, Tamil Nadu, 600113",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05819"
    },
    "Rank": 19139,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0581a"
    },
    "Rank": 19141,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0581b"
    },
    "Rank": 19142,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0581c"
    },
    "Rank": 19146,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apex Hospital, ,SP 4 AND 6 MALVIYA INDUSTRIAL AREA MALVIYA NAGAR , JAIPUR,\nRajasthan, 302017",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0581d"
    },
    "Rank": 19147,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0581e"
    },
    "Rank": 19148,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Specialty Hospital, ,Lake View Road, KK. Nagar, Madurai -625020, Tamil Nadu, 625020",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0581f"
    },
    "Rank": 19151,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05820"
    },
    "Rank": 19157,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Medical Center, ,14 MAJOR ARTERIAL ROAD EW NEWTOWN RAJARHAT KOLKATA 700160, West Bengal, 700160",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05821"
    },
    "Rank": 19159,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rabindranath Tagore International Institute of Cardiac Sciences, ,PREMISES NO. 1489 124 MUKUNDAPUR  E M BYPASS  KOLKATA-700099, West Bengal, 700099",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05822"
    },
    "Rank": 19167,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Charak Hospital and Research Center, Uttar Pradesh,Hardoi Road, Dubagga, Lucknow, Uttar Pradesh, 226003",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05823"
    },
    "Rank": 19168,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05824"
    },
    "Rank": 19170,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indiana hospital and Heart Institute, ,Mahaveer Cirlce, Pumpwell, Mangalore, Karnataka, 575002",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05825"
    },
    "Rank": 19171,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05826"
    },
    "Rank": 19173,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05827"
    },
    "Rank": 19174,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital,,B-22, Sector 62, Noida, Uttar Pradesh, Uttar Pradesh, 201301",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05828"
    },
    "Rank": 19176,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pimpri Chichwad Municipal Corporation Postgraduate Institute Y.C.M.Hospital Pimpri,Pune 18,Dean Office, Y.C.M.Hospital, Sant Tukaramnagar Pimpri, Pune, Maharashtra, 411018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05829"
    },
    "Rank": 19179,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Memorial Research Institute, ,Fortis memorial research institute, Sector 44, Opposite Huda City Centre Metro station, Gurgaon, Haryana, 122002",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0582a"
    },
    "Rank": 19180,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yenepoya Specialty Hospital, (A unit of Yenepoya Institute of Medical Sciences and Research Pvt Ltd) ,Yenepoya Specialty Hospital, Opp Bishops House, Kodialbail, Mangalore 575003, Karnataka, 575003",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0582b"
    },
    "Rank": 19181,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SRM Institutes for Medical Sciences, ,No 1 Jawaharlal Nehru Salai, Vadapalani, Chennai, Tamil Nadu, 600026",
    "Course": "(NBEMS) Plastic and Reconstructive Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0582c"
    },
    "Rank": 19183,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, A Unit of Hometrail Buildtech Pvt Ltd. ,Near Civil Hospital, Phase-6, Mohali, Punjab-160055, Punjab, 160055",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0582d"
    },
    "Rank": 19184,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0582e"
    },
    "Rank": 19185,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0582f"
    },
    "Rank": 19187,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Isabels Hospital, ,49, Oliver Road, Mylapore, Chennai., Tamil Nadu, 600004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05830"
    },
    "Rank": 19190,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05831"
    },
    "Rank": 19191,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster CMI Hospital, ,43/2 New Airport Road, NH-7, Kodigehalli, Sahakara Nagar, Hebbal, Bengaluru, Karnataka, 560092",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05832"
    },
    "Rank": 19195,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05833"
    },
    "Rank": 19196,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05834"
    },
    "Rank": 19200,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Care Hospital,,Care Hospitals Road No1 Banjara Hills Hyderabad Telangana, Telangana, 500034",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05835"
    },
    "Rank": 19206,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Gondia,Nehru Chowk, KTS General Hospital Campus, Govt. Medical College, Gondia. 441601. Maharashtra., Maharashtra, 441601",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05836"
    },
    "Rank": 19209,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ratan Jyoti Netralaya Opthalmic Institute and Research Centre, ,18 Vikas Nagar Near Sai Baba Mandir Fort Road Gwalior, Madhya Pradesh, 474002",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05837"
    },
    "Rank": 19212,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05838"
    },
    "Rank": 19216,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05839"
    },
    "Rank": 19218,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0583a"
    },
    "Rank": 19223,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aditya Birla Memorial Hospital, Maharashtra,Aditya Birla Hospital Marg, P.O.- Chinchwad, Pune, Maharashtra, 411033",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0583b"
    },
    "Rank": 19225,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Adlux Hospital ( A Unit of Adlux Medicity and Convention Centre Private Limited), Kerala\n,Apollo Adlux Hospital (A Unit of Adlux medicity and Convention Centre Private limited), Adlux juncti, Kerala, 683576",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0583c"
    },
    "Rank": 19227,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0583d"
    },
    "Rank": 19228,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0583e"
    },
    "Rank": 19230,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0583f"
    },
    "Rank": 19231,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05840"
    },
    "Rank": 19234,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05841"
    },
    "Rank": 19237,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Life line Super Specialty Hospital, ,14th Mile, Melood P.O Adoor , Pathanamthitta Dist, Kerala, 691554",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05842"
    },
    "Rank": 19244,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05843"
    },
    "Rank": 19247,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agrasen Medical College,,NH- 9, VPO - Agroha, District Hisar, Haryana, 125047",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05844"
    },
    "Rank": 19248,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Sathya Sai Institute of Higher Medical Sciences, ,PRASANTHIGRAM , PUTTAPARTHI\nANANTAPUR, Andhra Pradesh, 515134",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05845"
    },
    "Rank": 19249,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nasa Brain And Spine Centre,\nPunjab,128 GURUNANAK PURA KAPURTHALA ROAD JALANDHAR, Punjab, 144008",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05846"
    },
    "Rank": 19250,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Rangarajan Memorial Hospital, (Sundaram Medical Foundation) ,Sundaram Medical Foundation, Shanthi Colony, 4th Avenue, Anna Nagar, Chennai, Tamil Nadu, 600040",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05847"
    },
    "Rank": 19261,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05848"
    },
    "Rank": 19262,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05849"
    },
    "Rank": 19267,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shanmuga Hospital and Salem Cancer Institute, ,24/51 SARADA COLLEGE ROAD\n,SHANMUGA HOSPITAL, SALEM TAMILNADU 636007, Tamil Nadu, 636007",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0584a"
    },
    "Rank": 19269,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Sector-62, Phase VIII, Mohali- 160062, Punjab, 160062",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0584b"
    },
    "Rank": 19270,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0584c"
    },
    "Rank": 19272,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0584d"
    },
    "Rank": 19273,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medicover Hospitals, (Formerly MaxCure Hospital) ,Medicover Hospitals , Opposite Cyber gate way , Ibis hotel Lane , hitech city\nMadhapur Hyderabad, Telangana, 500081",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0584e"
    },
    "Rank": 19277,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0584f"
    },
    "Rank": 19278,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ganga Medical Centre and Hospital,,313 Mettupalayam Road Coimbatore 641043, Tamil Nadu, 641043",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05850"
    },
    "Rank": 19279,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05851"
    },
    "Rank": 19285,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur and Associated Group of Hospitals Shri Haridev Joshi Hospital,,Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur, Rajasthan, 314001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05852"
    },
    "Rank": 19287,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05853"
    },
    "Rank": 19293,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Swami Dayanand Hospital, ,Dilshad Garden, Shahdara, Delhi, Delhi (NCT), 110095",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05854"
    },
    "Rank": 19294,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ashwini Trauma Centre, ,plot no.1-COM-14-Sector-1 CDA BIDANASHI Cuttack 753014, Odisha, 753014",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05855"
    },
    "Rank": 19295,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05856"
    },
    "Rank": 19297,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital,,DNB office, 3rd Floor Opp Library  Apollo Hospitals, Jubilee Hills, Hyderabad, Telangana, 500033",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05857"
    },
    "Rank": 19299,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Paras HMRI Hospital, ,Paras HMRI Hospital\nNH 30 Bailey Road Raja Bazaar Patna 800014 Bihar, Bihar, 800014",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05858"
    },
    "Rank": 19300,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05859"
    },
    "Rank": 19302,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0585a"
    },
    "Rank": 19306,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0585b"
    },
    "Rank": 19309,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Swami Dayanand Hospital, ,Dilshad Garden, Shahdara, Delhi, Delhi (NCT), 110095",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0585c"
    },
    "Rank": 19313,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. Sports Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0585d"
    },
    "Rank": 19314,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0585e"
    },
    "Rank": 19316,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0585f"
    },
    "Rank": 19317,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Susrut Eye Foundation, West Bengal,HB 36 A 1 Salt Lake City Sector 3 Kolkata 700106, West Bengal, 700106",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05860"
    },
    "Rank": 19319,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05861"
    },
    "Rank": 19320,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05862"
    },
    "Rank": 19322,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sarakshi Netralaya, ,PLOT  NO 19 RAJIV NAGAR WARDHA ROAD NAGPUR,\nMaharashtra, 440025",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05863"
    },
    "Rank": 19325,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.G. Hospital and PG Medical Institute ,,No:5, Arts College Road, Coimbatore - 641018, Tamil Nadu, 641018",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05864"
    },
    "Rank": 19326,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ANURADHA SUPERSPECIALITY EYE HOSPITAL LLP, Maharashtra,9652-53-71, 100\nFT Road, Gandhi Colony, Ganesh Mandir Bylane, Vishrambhag, Sangli, Maharashtra, 416415",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05865"
    },
    "Rank": 19327,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05866"
    },
    "Rank": 19330,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05867"
    },
    "Rank": 19332,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05868"
    },
    "Rank": 19333,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05869"
    },
    "Rank": 19335,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0586a"
    },
    "Rank": 19338,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0586b"
    },
    "Rank": 19339,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0586c"
    },
    "Rank": 19343,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0586d"
    },
    "Rank": 19344,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ganga Medical Centre and Hospital,,313 Mettupalayam Road Coimbatore 641043, Tamil Nadu, 641043",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0586e"
    },
    "Rank": 19349,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0586f"
    },
    "Rank": 19350,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05870"
    },
    "Rank": 19353,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05871"
    },
    "Rank": 19354,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kokilaben Dhirubhai Ambani Hospital and Medical Research Institute,,Achyutrao Patwardhan Marg Andheri West Mumbai Maharashtra, Maharashtra, 400053",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05872"
    },
    "Rank": 19361,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Southern Railway Head Quarter Hospital, ,Ayanavaram, Tamil Nadu, 600023",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05873"
    },
    "Rank": 19362,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05874"
    },
    "Rank": 19365,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05875"
    },
    "Rank": 19366,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05876"
    },
    "Rank": 19369,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindusthan Hospital,Tamil Nadu,522/3, 523/3, Navaindia road, Udaiyampalayam, Coimbatore 641028, Tamil Nadu, 641028",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05877"
    },
    "Rank": 19371,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS Medical College Valsad,Nanakwada Halar Road,Valsad ,Gujarat, Gujarat, 396001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05878"
    },
    "Rank": 19376,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sagar Hospital, Karnataka,No. 44/54, 30th Cross, Tilak Nagar, Jayanagar Extn., Bangalore, Karnataka-560041",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05879"
    },
    "Rank": 19379,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Vinoba Bhave Civil Hospital,,S R Delkar Marg, Saily Road, Silvassa, Dadra And Nagar Haveli, 396230",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0587a"
    },
    "Rank": 19381,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0587b"
    },
    "Rank": 19383,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0587c"
    },
    "Rank": 19384,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Jammu and Kashmir\n,DUDERHAMA GANDERBAL, Jammu And Kashmir, 191201",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0587d"
    },
    "Rank": 19386,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0587e"
    },
    "Rank": 19391,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Thind Eye Hospital , ,701 Mall Road , Model Town , Jalandhar, Punjab, 144003",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0587f"
    },
    "Rank": 19394,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05880"
    },
    "Rank": 19395,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC MEDICAL COLLEGE, JOKA,ESI-PGIMSR & ESIC MEDICAL COLLEGE, JOKA, KOLKATA, WEST BENGAL, West Bengal, 700104",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05881"
    },
    "Rank": 19402,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sahyadri Narayana Multispecialty Hospital, Harakere,,NT ROAD, HARAKERE , SHIMOGA, Karnataka, 577202",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05882"
    },
    "Rank": 19404,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.I.O.T. Hospital, ,4 112 MOUNT POONAMALLEE ROAD MANAPAKKAM\nCHENNAI 600089, Tamil Nadu, 600089",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05883"
    },
    "Rank": 19409,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05884"
    },
    "Rank": 19410,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05885"
    },
    "Rank": 19411,
    "Allotted Quota": "All India",
    "Allotted Institute": "Smt. G. R. Doshi and Smt. K. M. Mehta Institute of Kidney Diseases & Research Centre Dr. H. L. Trivedi Institute of Transplantation Sciences (IKDRC-ITS),Opp. Trauma Centre, Civil Hospital Campus, Asarwa, Ahmedabad, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05886"
    },
    "Rank": 19413,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Chandra Sekara Hospital, Tamil Nadu,M-1, M-2, H-23, Avalapalli Housing Board, Bagalur Road, Hosur - 635109, Krishnagiri Dist, Tamil Nadu, Tamil Nadu, 635109",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05887"
    },
    "Rank": 19414,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Khan Bahadur Bhabha Hospital, Maharashtra,Khan Bahadur Bhabha Hospital Belgrami road kurla west, Maharashtra, 400070",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05888"
    },
    "Rank": 19416,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05889"
    },
    "Rank": 19417,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0588a"
    },
    "Rank": 19429,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KIMS Saveera Hospital,1-1348 OPPOSITE SAKSHI OFFICE RUDRAMPET ANANTAPURAM 515004, Andhra Pradesh, 515004",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0588b"
    },
    "Rank": 19430,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0588c"
    },
    "Rank": 19432,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0588d"
    },
    "Rank": 19435,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0588e"
    },
    "Rank": 19436,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhabha Atomic Research Centre and Hospital, ,Anushaktinagar, Mumbai, Maharashtra, 400094",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0588f"
    },
    "Rank": 19437,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Imambara District Hospital,,AKHANBAZAR ROAD, CHINSURA, HOOGHLY, West Bengal, 712101",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05890"
    },
    "Rank": 19440,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05891"
    },
    "Rank": 19447,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "HINDU MISSION HOSPITAL,\n,103 G S T Road Tambaram West Chennai, Tamil Nadu, 600045",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05892"
    },
    "Rank": 19452,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05893"
    },
    "Rank": 19453,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05894"
    },
    "Rank": 19454,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lal Bahadur Shastri Hosptial, ,LBSH MAYUR VIHAR PHASE 2 KHICHRIPUR 110091, Delhi (NCT), 110091",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05895"
    },
    "Rank": 19457,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05896"
    },
    "Rank": 19460,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Calcutta Medical Research Institute, ,The Calcutta Medical Research Institute 7 by 2 Diamond Harbour Road Kolkata 700027, West Bengal, 700027",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05897"
    },
    "Rank": 19461,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Specialty Hospitals, ,IIIM NEHRU NAGAR GHAZIABAD, Uttar Pradesh, 201001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05898"
    },
    "Rank": 19462,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05899"
    },
    "Rank": 19467,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Spirit Hospital, ,Mahakali Caves Road Andheri East Mumbai, Maharashtra, 400093",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0589a"
    },
    "Rank": 19471,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saket Hospital,10, Meera Marg, Agarwal Farm, Masarover, Jaipur, Rajasthan, 302020",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0589b"
    },
    "Rank": 19472,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0589c"
    },
    "Rank": 19473,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahavir Hospital Manish IVF Center, Gujarat,Dr Manish R Pandya Lati plot , Opp.\nDistrict Library, Near C J hospital Surendranagar, Gujarat, 363001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0589d"
    },
    "Rank": 19474,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindu Rao Hospital, ,SUBZI MANDI, MALKA GANJ, DELHI-110007, Delhi (NCT), 110007",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0589e"
    },
    "Rank": 19479,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MANASA NURSING HOME, Karnataka,JPN Road , 1st Cross, Shivamogga, Karnataka, 577201",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0589f"
    },
    "Rank": 19480,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaiprakash Hospital and Research Centre Pvt. Ltd, Odisha,,JAIPRAKASH HOSPITAL AND RESEARCH CENTER PVT. LTD., DANDIAPALI, ROURKELA, Odisha, 769004",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058a0"
    },
    "Rank": 19483,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shija Hospitals and Research Institute, ,SHIJA HOSPITALS AND RESEARCH INSTITUTE, LANGOL, LAMPHELPAT, IMPHAL WEST, MANIPUR 795004, Manipur, 795004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058a1"
    },
    "Rank": 19489,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058a2"
    },
    "Rank": 19491,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Kamakshi Memorial Hospital, (Dr. Kamakshi Institute of Medical Sciences and Research) ,No.1,Radial Road , Pallikaranai, Chennai, Tamil Nadu, 600100",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058a3"
    },
    "Rank": 19494,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058a4"
    },
    "Rank": 19495,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058a5"
    },
    "Rank": 19499,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058a6"
    },
    "Rank": 19501,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Daga Memorial Women Government Hospital Nagpur, ,Ganjakhet gandhibagh nagpur, Maharashtra, 440008",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058a7"
    },
    "Rank": 19504,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PALLIATIVE MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058a8"
    },
    "Rank": 19505,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058a9"
    },
    "Rank": 19507,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Surya Children`s Medicare, (Formerly Surya Children`s Hospital) ,S. V. Road, Santacruz West Mumbai, Maharashtra, 400054",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058aa"
    },
    "Rank": 19508,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058ab"
    },
    "Rank": 19509,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B. Nanavati Hospital, Vivekanand Rd,Swami Vivekananda Rd, near LIC, LIC Colony, Suresh Colony, Vile Parle West, Mumbai, Maharashtra 4000, Maharashtra, 400056",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058ac"
    },
    "Rank": 19511,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058ad"
    },
    "Rank": 19515,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058ae"
    },
    "Rank": 19518,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KIMS Al Shifa Hospital, ,KIMS Al Shifa Healthcare Pvt  Ltd.\nPB No 26 .\nOotty Road.\nPerinthalmanna, Malappuram district, Ke, Kerala, 679322",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058af"
    },
    "Rank": 19520,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,RAJBHAVAN ROAD SOMAJIGUDA, Telangana, 500082",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058b0"
    },
    "Rank": 19522,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, (Formerly- Pushpanjali Crosslay Hospital) ,W-3, Sector-1, Vaishali, Ghaziabad, Uttar Pradesh, 201012",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058b1"
    },
    "Rank": 19523,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "B.M. Gupta Hospital,  New Delhi,H-9 to H-15, Uttam Nagar Near Arya Samaj Road, Delhi (NCT), 110059",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058b2"
    },
    "Rank": 19524,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058b3"
    },
    "Rank": 19526,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058b4"
    },
    "Rank": 19531,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Paras HMRI Hospital, ,Paras HMRI Hospital\nNH 30 Bailey Road Raja Bazaar Patna 800014 Bihar, Bihar, 800014",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058b5"
    },
    "Rank": 19532,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Head Quarters Hospital, Khammam District, Telangana,District Head Quarters Hospital, Khammam District, Telangana, Telangana, 507002",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058b6"
    },
    "Rank": 19536,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058b7"
    },
    "Rank": 19539,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058b8"
    },
    "Rank": 19540,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. Medical College (Maharani Hospital), Jagdalpur, Chhattisgarh,Late Baliram Kashyap memorial Govt. Medical College, Dimrapal, Jagdalpur Dist-Bastar, Chhattisgarh, 494001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058b9"
    },
    "Rank": 19545,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SRM Institutes for Medical Sciences, ,No 1 Jawaharlal Nehru Salai, Vadapalani, Chennai, Tamil Nadu, 600026",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058ba"
    },
    "Rank": 19546,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058bb"
    },
    "Rank": 19547,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chamarajanagar Institute of Medical Sciences, Karnataka,CHAMARAJANAGAR INSTITUTE OF MEDICAL SCIENCES, SY. NO 124, YEDAPURA VILLAGE KASABA HOBLI, CHAMARAJANA, Karnataka, 571313",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058bc"
    },
    "Rank": 19548,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medical Trust Hospital, ,M.G. Road Pallimukku\nCochin, Kerala, 682016",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058bd"
    },
    "Rank": 19552,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saraswathy Hospital, Kerala,Saraswathy Hospital, Parassala, Thiruvananthapuram, Kerala, 695502",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058be"
    },
    "Rank": 19555,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058bf"
    },
    "Rank": 19559,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058c0"
    },
    "Rank": 19560,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lakeshore Hospital and Res. ,Maradu, Nettoor P O, Kochi, Kerala, 682040",
    "Course": "(NBEMS) Neuro Surgery (Direct 6 Years Course)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058c1"
    },
    "Rank": 19561,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058c2"
    },
    "Rank": 19563,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058c3"
    },
    "Rank": 19564,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058c4"
    },
    "Rank": 19568,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Advanced Medical Research Institute (AMRI) Hospital,,AMRI Hospitals, Bhubaneswar Plot no-1, Besides Satya Sai Enclave, Khandagiri, Bhubaneswar, Odisha, 751030",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058c5"
    },
    "Rank": 19572,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058c6"
    },
    "Rank": 19574,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058c7"
    },
    "Rank": 19584,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS Medical College Valsad,Nanakwada Halar Road,Valsad ,Gujarat, Gujarat, 396001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058c8"
    },
    "Rank": 19588,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058c9"
    },
    "Rank": 19591,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Specialty Hospital, ,Lake View Road, KK. Nagar, Madurai -625020, Tamil Nadu, 625020",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058ca"
    },
    "Rank": 19592,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "NEOTIA GETWEL HEALTHCARE CENTRE,\n,Uttorayon, Behind City Centre, Matigara, Siliguri, West Bengal, 734010",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058cb"
    },
    "Rank": 19594,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058cc"
    },
    "Rank": 19596,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K K Hospital,\nUttar Pradesh,87 88 Nabiullah Road River Bank Colony Lucknow, Uttar Pradesh, 226018",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058cd"
    },
    "Rank": 19597,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058ce"
    },
    "Rank": 19598,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058cf"
    },
    "Rank": 19599,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Almadeena Institute of Medical Science, ,ALMAS HOSPITAL, CHANGUVETTY, KOTTAKKAL, MALAPPURAM DISTRICT, Kerala, 676503",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058d0"
    },
    "Rank": 19603,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Jamshedpur,Mahatma Gandhi Memorial Medical College, Jamshedpur, Jharkhand, 831020",
    "Course": "DIPLOMA IN CHILD HEALTH/ PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058d1"
    },
    "Rank": 19604,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Surya Children`s Medicare, (Formerly Surya Children`s Hospital) ,S. V. Road, Santacruz West Mumbai, Maharashtra, 400054",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058d2"
    },
    "Rank": 19609,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVERNMENT MEDICAL COLLEGE,KANYAKUMARI GOVERNMENT MEDICAL COLLEGE, Tamil Nadu, 629201",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058d3"
    },
    "Rank": 19616,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058d4"
    },
    "Rank": 19617,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sahyadri Narayana Multispecialty Hospital, Harakere,,NT ROAD, HARAKERE , SHIMOGA, Karnataka, 577202",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058d5"
    },
    "Rank": 19618,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058d6"
    },
    "Rank": 19619,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058d7"
    },
    "Rank": 19624,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058d8"
    },
    "Rank": 19626,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.E.M. Hospital, ,489, SARDAR MOODLIAR ROAD, RASTA PETH, PUNE 411011,\nMaharashtra, 411011",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058d9"
    },
    "Rank": 19627,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "B.R. Singh Hospital and Centre for Medical Education and Research,,B.R.Singh Hospital Eastern Railway, Sealdah, Kolkata, West Bengal, 700014",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058da"
    },
    "Rank": 19628,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shree Narayana Hospital, ,Near Ganj Mandi, Behind Sector 5, Devendra Nagar, Pandri, Raipur, Chhattisgarh, 492001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058db"
    },
    "Rank": 19630,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058dc"
    },
    "Rank": 19631,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058dd"
    },
    "Rank": 19632,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VELLORE MEDICAL COLLEGE,GOVERNMENT VELLORE MEDICAL COLLEGE, Tamil Nadu, 632011",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058de"
    },
    "Rank": 19635,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058df"
    },
    "Rank": 19638,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058e0"
    },
    "Rank": 19642,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Specialty Hospital, ,Lake View Road, KK. Nagar, Madurai -625020, Tamil Nadu, 625020",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058e1"
    },
    "Rank": 19644,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058e2"
    },
    "Rank": 19647,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Star Hospital,,Star Hospitals, 8-2-596/5, Road. No. 10,\nBanjara Hills, Hyderabad, Telangana, 500034",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058e3"
    },
    "Rank": 19648,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058e4"
    },
    "Rank": 19651,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bombay Hospital and Institute of Medical Sciences, ,12, New Marine Lines, Mumbai, Maharashtra, 400020",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058e5"
    },
    "Rank": 19653,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agrasen Medical College,,NH- 9, VPO - Agroha, District Hisar, Haryana, 125047",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058e6"
    },
    "Rank": 19654,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Kamakshi Memorial Hospital, (Dr. Kamakshi Institute of Medical Sciences and Research) ,No.1,Radial Road , Pallikaranai, Chennai, Tamil Nadu, 600100",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058e7"
    },
    "Rank": 19657,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gujarat Institute of Mental Health Hospital, ,Hospital for Mental Health, Outside Delhi Gate,\nShahibug, Ahmedabad, Gujarat, 380004",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058e8"
    },
    "Rank": 19660,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital,,DNB office, 3rd Floor Opp Library  Apollo Hospitals, Jubilee Hills, Hyderabad, Telangana, 500033",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058e9"
    },
    "Rank": 19665,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Andhra Hospital (Vijayawada), ,CVR Complex, Governorpet, Vijayawada-520002, Krishna District, Andhra Pradesh, 520002",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058ea"
    },
    "Rank": 19666,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government Women and Children Hospital, (Formerly Indira Gandhi.\nGeneral Hospital) ,100 feet road, Ellaipillaichavady, Puducherry, 605005",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058eb"
    },
    "Rank": 19668,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058ec"
    },
    "Rank": 19669,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058ed"
    },
    "Rank": 19676,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Sathya Sai Sarla Memorial Hospital, Karnataka,Sathya Sai Grama Muddenahalli\nChikkaballapura, Karnataka, 562101",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058ee"
    },
    "Rank": 19678,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058ef"
    },
    "Rank": 19682,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058f0"
    },
    "Rank": 19683,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058f1"
    },
    "Rank": 19687,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Surya Children`s Medicare, (Formerly Surya Children`s Hospital) ,S. V. Road, Santacruz West Mumbai, Maharashtra, 400054",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058f2"
    },
    "Rank": 19688,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "J.P.M. Rotary Eye Hospital and Research Institute,,CDA, Sector -6 , Abhinav Bidanasi, Markat Nagar Cuttack, Odisha, 753014",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058f3"
    },
    "Rank": 19690,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058f4"
    },
    "Rank": 19692,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058f5"
    },
    "Rank": 19693,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Hospital, Omandurar,GOVERNMENT MEDICAL COLLEGE, OMANDURAR GOVERNMENT ESTATE, WALAJAH ROAD, NEAR KALAIVANAR ARANGAM, CHEN, Tamil Nadu, 600002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058f6"
    },
    "Rank": 19694,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital,,GMERS Medical College and Hospital Campus, Dharpur, Patan-Unjha State Highway, Patan (North Gujarat,, Gujarat, 384265",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058f7"
    },
    "Rank": 19696,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058f8"
    },
    "Rank": 19697,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058f9"
    },
    "Rank": 19700,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Theresa`s Hospital,,7-1-645/A ERRAGADA SANATHNAGAAR HYDERABAD,\nTelangana, 500018",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058fa"
    },
    "Rank": 19704,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058fb"
    },
    "Rank": 19710,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058fc"
    },
    "Rank": 19711,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058fd"
    },
    "Rank": 19714,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058fe"
    },
    "Rank": 19718,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Care Hospital-,CARE HOSPITAL OLD MUMBAI HIGH WAY NEAR CYBERABAD POLICE COMMISSIONARATE HITECH CITY GACHIBOWLI HYDER, Telangana, 500032",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a058ff"
    },
    "Rank": 19722,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05900"
    },
    "Rank": 19725,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "PRASHANTH HOSPITALS ,\nTamil Nadu,No 36  36 A Velachery Main road Velachery\nChennai 600042, Tamil Nadu, 600042",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05901"
    },
    "Rank": 19728,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05902"
    },
    "Rank": 19730,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Almadeena Institute of Medical Science, ,ALMAS HOSPITAL, CHANGUVETTY, KOTTAKKAL, MALAPPURAM DISTRICT, Kerala, 676503",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05903"
    },
    "Rank": 19731,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05904"
    },
    "Rank": 19735,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05905"
    },
    "Rank": 19739,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05906"
    },
    "Rank": 19740,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Preethi Hospital, ,50, Melur Main Road, Uthangudi, Madurai - 625107, Tamil Nadu, 625107",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05907"
    },
    "Rank": 19744,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05908"
    },
    "Rank": 19747,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05909"
    },
    "Rank": 19750,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0590a"
    },
    "Rank": 19751,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0590b"
    },
    "Rank": 19754,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0590c"
    },
    "Rank": 19757,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0590d"
    },
    "Rank": 19760,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0590e"
    },
    "Rank": 19761,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0590f"
    },
    "Rank": 19766,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rotary Eye Institute, ,Dudhia Talao, Opp Fire Station, Navsari - 396445, Gujarat, 396445",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05910"
    },
    "Rank": 19773,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Virendra Laser, Phaco Surgery Center - Eye Hospital ,,Vision Tower, Gandhi Nagar, Tonk Road , Near Toyota Motor Showroom, jaipur, Rajasthan, 302015",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05911"
    },
    "Rank": 19775,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05912"
    },
    "Rank": 19778,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "PRASHANTH HOSPITALS ,\nTamil Nadu,No 36  36 A Velachery Main road Velachery\nChennai 600042, Tamil Nadu, 600042",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05913"
    },
    "Rank": 19782,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Philomena`s Hospital, ,No.4, Campbell Road, Viveknagar P.O. Bangalore - 560047, Karnataka, 560047",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05914"
    },
    "Rank": 19783,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05915"
    },
    "Rank": 19789,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shaheed Hasan Khan Mewati Government Medical College , ,Shaheed Hasan Khan Mewati Government Medical College Nalhar Nuh, Haryana, 122107",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05916"
    },
    "Rank": 19790,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05917"
    },
    "Rank": 19791,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05918"
    },
    "Rank": 19792,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaiprakash Hospital and Research Centre Pvt. Ltd, Odisha,,JAIPRAKASH HOSPITAL AND RESEARCH CENTER PVT. LTD., DANDIAPALI, ROURKELA, Odisha, 769004",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05919"
    },
    "Rank": 19793,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0591a"
    },
    "Rank": 19794,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Satguru Partap Singh Hospital, ,Sherpur Chowk, GT Road, Ludhiana, Punjab-141003, Punjab, 141003",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0591b"
    },
    "Rank": 19797,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rukmani Birla Hospital , (A unit of The Calcutta Medical Research Institute) ,Gopalpura Bypass Road, Near Triveni Flyover, Jaipur, Rajasthan, 302018",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0591c"
    },
    "Rank": 19803,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0591d"
    },
    "Rank": 19804,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. James Hospital, ,Old Highway, CHALAKUDY, Kerala, 680307",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0591e"
    },
    "Rank": 19808,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0591f"
    },
    "Rank": 19809,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Care Hospital,,Care Hospitals Road No1 Banjara Hills Hyderabad Telangana, Telangana, 500034",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05920"
    },
    "Rank": 19811,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "DIP. IN T.B. and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05921"
    },
    "Rank": 19814,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital, ,NO 1 RAMAMOORTHY ROAD VIRUDHUNAGAR, Tamil Nadu, 626001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05922"
    },
    "Rank": 19818,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05923"
    },
    "Rank": 19822,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05924"
    },
    "Rank": 19823,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asian Heart Institute and Research Centre, ,G N Block, Bandra Kurla Complex Bandra E Mumbai, Maharashtra, 400051",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05925"
    },
    "Rank": 19825,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta The Medicity, ,Medanta The Medicity Sector 38 Gurgoan, Haryana, 122001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05926"
    },
    "Rank": 19827,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05927"
    },
    "Rank": 19836,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05928"
    },
    "Rank": 19838,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05929"
    },
    "Rank": 19841,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0592a"
    },
    "Rank": 19844,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0592b"
    },
    "Rank": 19848,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "DIPLOMA IN ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0592c"
    },
    "Rank": 19849,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0592d"
    },
    "Rank": 19850,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0592e"
    },
    "Rank": 19852,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences, ,academics@kimshospitals.com, Telangana, 500003",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0592f"
    },
    "Rank": 19853,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Bundelkhand Medical College Sagar M.P.,Govt. Bundelkhand Medical College Sagar M.P., Madhya Pradesh, 470001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05930"
    },
    "Rank": 19854,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster Medcity, Kuttisahib Road,,Aster Medcity Kuttisahib Road\nSouth Chittoor P.O. Cheranalloor\nKochi, Kerala, 682027",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05931"
    },
    "Rank": 19856,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05932"
    },
    "Rank": 19861,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05933"
    },
    "Rank": 19862,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05934"
    },
    "Rank": 19863,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05935"
    },
    "Rank": 19865,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Vasantrao Naik Govt Medical College Yavatmal,Shri Vasantrao Naik Govt Medical College Yavatmal, Maharashtra, 445001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05936"
    },
    "Rank": 19866,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05937"
    },
    "Rank": 19868,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05938"
    },
    "Rank": 19876,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05939"
    },
    "Rank": 19879,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Sector-62, Phase VIII, Mohali- 160062, Punjab, 160062",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0593a"
    },
    "Rank": 19881,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KIMS Al Shifa Hospital, ,KIMS Al Shifa Healthcare Pvt  Ltd.\nPB No 26 .\nOotty Road.\nPerinthalmanna, Malappuram district, Ke, Kerala, 679322",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0593b"
    },
    "Rank": 19883,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.G.M. Muthoot Hospital, ,MGM Muthoot Medical Centre, Ring Road, Pathanamthitta, Kerala, 689645",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0593c"
    },
    "Rank": 19884,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE,ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, Bihar, 823001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0593d"
    },
    "Rank": 19887,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0593e"
    },
    "Rank": 19888,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Satguru Partap Singh Hospital, ,Sherpur Chowk, GT Road, Ludhiana, Punjab-141003, Punjab, 141003",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0593f"
    },
    "Rank": 19890,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, (Formerly- Pushpanjali Crosslay Hospital) ,W-3, Sector-1, Vaishali, Ghaziabad, Uttar Pradesh, 201012",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05940"
    },
    "Rank": 19891,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05941"
    },
    "Rank": 19894,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pandit Madan Mohan Malaviya Shatabdi Hospital, Maharashtra,Pandit  Madan Mohan Malaviya  Shatabdi Hospital\nWaman Tukaram Patil Marg Govandi Mumbai 400088, Maharashtra, 400088",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05942"
    },
    "Rank": 19897,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05943"
    },
    "Rank": 19898,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Jamshedpur,Mahatma Gandhi Memorial Medical College, Jamshedpur, Jharkhand, 831020",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05944"
    },
    "Rank": 19899,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05945"
    },
    "Rank": 19900,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05946"
    },
    "Rank": 19901,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College,Tumuki, Bihaguri, Tezpur, Sonitpur, Assam, Assam, 784010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05947"
    },
    "Rank": 19905,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SRM Institutes for Medical Sciences, ,No 1 Jawaharlal Nehru Salai, Vadapalani, Chennai, Tamil Nadu, 600026",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05948"
    },
    "Rank": 19906,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,RAJBHAVAN ROAD SOMAJIGUDA, Telangana, 500082",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05949"
    },
    "Rank": 19907,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0594a"
    },
    "Rank": 19911,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0594b"
    },
    "Rank": 19913,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0594c"
    },
    "Rank": 19917,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0594d"
    },
    "Rank": 19919,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vishnuprasad Nandrai Desai Municipal General Hospital, Maharashtra,11 TH GOLIBAR ROAD TPS,SANTACRUZ EAST, Maharashtra, 400055",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0594e"
    },
    "Rank": 19924,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0594f"
    },
    "Rank": 19932,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESIC Hospital, Sri Maa Anandmayee Marg,,ESIC Hospital, Sri Maa Anandmayee Marg, Okhla Phase I, New Delhi, Delhi (NCT), 110020",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05950"
    },
    "Rank": 19935,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "YATHARTH SUPER SPECIALTY HOSPITALS,\nUttar Pradesh,Sector omega 01-, Greater noida, Uttar Pradesh, 201308",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05951"
    },
    "Rank": 19937,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. James Hospital, ,Old Highway, CHALAKUDY, Kerala, 680307",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05952"
    },
    "Rank": 19940,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05953"
    },
    "Rank": 19941,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05954"
    },
    "Rank": 19942,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05955"
    },
    "Rank": 19943,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05956"
    },
    "Rank": 19944,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Sathya Sai Sarla Memorial Hospital, Karnataka,Sathya Sai Grama Muddenahalli\nChikkaballapura, Karnataka, 562101",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05957"
    },
    "Rank": 19945,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05958"
    },
    "Rank": 19949,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.Ch. (Neuro Surgery)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05959"
    },
    "Rank": 19950,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital,,GOVERNMENT HEADQUARTERS HOSPITAL AND DNB POST GRADUATE TEACHING INSTITUTE,EVN ROAD, ERODE, Tamil Nadu, 638009",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0595a"
    },
    "Rank": 19952,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine & Sagore Dutta Hospital,College of Medicine & Sagore Dutta Hospital, West Bengal, 700058",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0595b"
    },
    "Rank": 19953,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0595c"
    },
    "Rank": 19954,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shija Hospitals and Research Institute, ,SHIJA HOSPITALS AND RESEARCH INSTITUTE, LANGOL, LAMPHELPAT, IMPHAL WEST, MANIPUR 795004, Manipur, 795004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0595d"
    },
    "Rank": 19955,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Little Flower Hospital and Research Centre,,Post Box 23, Angamaly, Ernakulam, Kerala, Kerala, 683572",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0595e"
    },
    "Rank": 19956,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shija Hospitals and Research Institute, ,SHIJA HOSPITALS AND RESEARCH INSTITUTE, LANGOL, LAMPHELPAT, IMPHAL WEST, MANIPUR 795004, Manipur, 795004",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0595f"
    },
    "Rank": 19957,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05960"
    },
    "Rank": 19958,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bokaro General Hospital, ,SECTOR - 4 BOKARO STEEL CITY\nDIST - BOKARO, Jharkhand, 827004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05961"
    },
    "Rank": 19959,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05962"
    },
    "Rank": 19960,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "YATHARTH SUPER SPECIALTY HOSPITALS,\nUttar Pradesh,Sector omega 01-, Greater noida, Uttar Pradesh, 201308",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05963"
    },
    "Rank": 19964,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05964"
    },
    "Rank": 19965,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sanjay Gandhi Postgarduate Institute of Medical Sciences,Sanjay Gandhi Postgarduate Institute of Medical Sciences, Uttar Pradesh, 226014",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05965"
    },
    "Rank": 19972,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05966"
    },
    "Rank": 19973,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05967"
    },
    "Rank": 19976,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agrasen Medical College,,NH- 9, VPO - Agroha, District Hisar, Haryana, 125047",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05968"
    },
    "Rank": 19981,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05969"
    },
    "Rank": 19987,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Vinoba Bhave Civil Hospital,,S R Delkar Marg, Saily Road, Silvassa, Dadra And Nagar Haveli, 396230",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0596a"
    },
    "Rank": 19990,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC MEDICAL COLLEGE, JOKA,ESI-PGIMSR & ESIC MEDICAL COLLEGE, JOKA, KOLKATA, WEST BENGAL, West Bengal, 700104",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0596b"
    },
    "Rank": 19991,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Almadeena Institute of Medical Science, ,ALMAS HOSPITAL, CHANGUVETTY, KOTTAKKAL, MALAPPURAM DISTRICT, Kerala, 676503",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0596c"
    },
    "Rank": 19993,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0596d"
    },
    "Rank": 19995,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Almadeena Institute of Medical Science, ,ALMAS HOSPITAL, CHANGUVETTY, KOTTAKKAL, MALAPPURAM DISTRICT, Kerala, 676503",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0596e"
    },
    "Rank": 19996,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Hospital,,JANGLAT MANDI ANANTNAG, JAMMU AND KASHMIR 192101, Jammu And Kashmir, 192101",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0596f"
    },
    "Rank": 19999,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05970"
    },
    "Rank": 20003,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05971"
    },
    "Rank": 20006,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Daga Memorial Women Government Hospital Nagpur, ,Ganjakhet gandhibagh nagpur, Maharashtra, 440008",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05972"
    },
    "Rank": 20007,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Vinoba Bhave Civil Hospital,,S R Delkar Marg, Saily Road, Silvassa, Dadra And Nagar Haveli, 396230",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05973"
    },
    "Rank": 20008,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.E.M. Hospital, ,489, SARDAR MOODLIAR ROAD, RASTA PETH, PUNE 411011,\nMaharashtra, 411011",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05974"
    },
    "Rank": 20010,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jehangir Hospital, ,32 Sasoon Road Pune, Maharashtra, 411001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05975"
    },
    "Rank": 20011,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine & Sagore Dutta Hospital,College of Medicine & Sagore Dutta Hospital, West Bengal, 700058",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05976"
    },
    "Rank": 20012,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Sankaradeva Nethralaya,,96, Basistha Road, Guwahati, Assam, 781028",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05977"
    },
    "Rank": 20015,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05978"
    },
    "Rank": 20016,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences, ,academics@kimshospitals.com, Telangana, 500003",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05979"
    },
    "Rank": 20017,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0597a"
    },
    "Rank": 20018,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Hospital, ,ALEXANDER ROAD, BESIDE HARIHARA KALABHAVAN, NEAR CLOCK TOWER, SECUNDERBAD, Telangana, 500003",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0597b"
    },
    "Rank": 20019,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Sankaradeva Nethralaya,,96, Basistha Road, Guwahati, Assam, 781028",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0597c"
    },
    "Rank": 20024,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0597d"
    },
    "Rank": 20025,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kauvery Hospital, ,No.199, Luz Church road, Mylapore,Chennai-600004, Tamil Nadu, 600004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0597e"
    },
    "Rank": 20026,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indraprastha Apollo Hospital,,Sarita Vihar, New Delhi 110 076, Delhi (NCT), 110076",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0597f"
    },
    "Rank": 20028,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Agarwal`s Eye Hospital, ,BBC TOWER, LINK ROAD SQUARE,MADHUPATNA,CUTTACK,ORISSA 753010, Odisha, 753010",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05980"
    },
    "Rank": 20029,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVERNMENT MEDICAL COLLEGE,KANYAKUMARI GOVERNMENT MEDICAL COLLEGE, Tamil Nadu, 629201",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05981"
    },
    "Rank": 20030,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Caritas Hospital, ,Thellakom P.O, Kerala, 686630",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05982"
    },
    "Rank": 20031,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05983"
    },
    "Rank": 20032,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05984"
    },
    "Rank": 20037,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHAS NATIONAL INSTITUTE OF SPORTS, PATIALA,NETAJI SUBHAS NATIONAL INSTITUTE OF SPORTS, OLD MOTIBAGH, PATIALA, Punjab, 147001",
    "Course": "DIP. IN SPORTS MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05985"
    },
    "Rank": 20038,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05986"
    },
    "Rank": 20040,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05987"
    },
    "Rank": 20041,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05988"
    },
    "Rank": 20042,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05989"
    },
    "Rank": 20043,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0598a"
    },
    "Rank": 20045,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0598b"
    },
    "Rank": 20047,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahavir Hospital Manish IVF Center, Gujarat,Dr Manish R Pandya Lati plot , Opp.\nDistrict Library, Near C J hospital Surendranagar, Gujarat, 363001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0598c"
    },
    "Rank": 20050,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indira Gandhi Medical College and Research Institute, Pondicherry,Vazhudavour Road, Kadirkamam, Puducherry, 605009",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0598d"
    },
    "Rank": 20055,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kokilaben Dhirubhai Ambani Hospital and Medical Research Institute,,Achyutrao Patwardhan Marg Andheri West Mumbai Maharashtra, Maharashtra, 400053",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0598e"
    },
    "Rank": 20056,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0598f"
    },
    "Rank": 20062,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "EMS Memorial Co-operative Hospital and Research Centre, ,Panambi, Perintalmanna, Malappuram District, Kerala, 679322",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05990"
    },
    "Rank": 20064,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05991"
    },
    "Rank": 20068,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Gleneagles Hospital, ,58 Canal Circular Road Kolkata 700054, West Bengal, 700054",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05992"
    },
    "Rank": 20070,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05993"
    },
    "Rank": 20073,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05994"
    },
    "Rank": 20075,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Advanced Medical Research Institute (AMRI) Hospital,,AMRI Hospitals, Bhubaneswar Plot no-1, Besides Satya Sai Enclave, Khandagiri, Bhubaneswar, Odisha, 751030",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05995"
    },
    "Rank": 20076,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, ,1,2, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05996"
    },
    "Rank": 20079,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agrasen Medical College,,NH- 9, VPO - Agroha, District Hisar, Haryana, 125047",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05997"
    },
    "Rank": 20090,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05998"
    },
    "Rank": 20094,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Rangarajan Memorial Hospital, (Sundaram Medical Foundation) ,Sundaram Medical Foundation, Shanthi Colony, 4th Avenue, Anna Nagar, Chennai, Tamil Nadu, 600040",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05999"
    },
    "Rank": 20097,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0599a"
    },
    "Rank": 20098,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0599b"
    },
    "Rank": 20101,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0599c"
    },
    "Rank": 20103,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0599d"
    },
    "Rank": 20105,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0599e"
    },
    "Rank": 20106,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BGS Global Hospital, ,BGS Global Hospital, Uttarahalli Road, Kengeri Bangalore, Karnataka, 560060",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0599f"
    },
    "Rank": 20111,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059a0"
    },
    "Rank": 20114,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaypee Hospital,,sector 128, Noida, Uttar Pradesh, 201304",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059a1"
    },
    "Rank": 20116,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Doon Medical College, Dehradun,Govt. Doon Medical College Dehrakhas, Patel Nagar Dehradun 248001, Uttarakhand, 248001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059a2"
    },
    "Rank": 20118,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Philomena`s Hospital, ,No.4, Campbell Road, Viveknagar P.O. Bangalore - 560047, Karnataka, 560047",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059a3"
    },
    "Rank": 20119,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059a4"
    },
    "Rank": 20120,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Daya General Hospital,Daya General Hospital Limited Shornur Road NEar Viyyur bridge\nThiruvambady PO Thrissur, Kerala, 680022",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059a5"
    },
    "Rank": 20121,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059a6"
    },
    "Rank": 20122,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059a7"
    },
    "Rank": 20124,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059a8"
    },
    "Rank": 20125,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059a9"
    },
    "Rank": 20126,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Siddipet,SURVEY NO 54  GOVERNMENT MEDICAL COLLEGE  ENSANPALLY  SIDDIPET   502114, Telangana, 502114",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059aa"
    },
    "Rank": 20130,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital,,Sector 5, Vidhyadhar Nagar, Jaipur, Rajasthan, 302013",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059ab"
    },
    "Rank": 20131,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059ac"
    },
    "Rank": 20142,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059ad"
    },
    "Rank": 20143,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerala Institute of Medical Sciences, ,PB NO.1, ANAYARA PO, TRIVANDRUM, Kerala, 695024",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059ae"
    },
    "Rank": 20148,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College,Tumuki, Bihaguri, Tezpur, Sonitpur, Assam, Assam, 784010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059af"
    },
    "Rank": 20153,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sreenethra Eye Care, Kerala,sreenethra01@gmail.com, Kerala, 695034",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059b0"
    },
    "Rank": 20155,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059b1"
    },
    "Rank": 20156,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pushpanjali Hospital and Research Centre, Uttar Pradesh,Pushpanjali Hospital and Research Centre Pvt Ltd Pushpanjali Palace, Delhi Gate, Agra, Uttar Pradesh, 282002",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059b2"
    },
    "Rank": 20159,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medica Superspecialty Hospital, ,127 Mukundapur, E.M. Byapss, Kolkata, West Bengal, 700099",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059b3"
    },
    "Rank": 20161,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Institute of Child Health, ,11, Dr Biresh Guha Street, Kolkata, West Bengal, 700017",
    "Course": "(NBEMS) Paediatric Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059b4"
    },
    "Rank": 20166,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.G.M. Muthoot Hospital, ,MGM Muthoot Medical Centre, Ring Road, Pathanamthitta, Kerala, 689645",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059b5"
    },
    "Rank": 20169,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Little Flower Hospital and Research Centre,,Post Box 23, Angamaly, Ernakulam, Kerala, Kerala, 683572",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059b6"
    },
    "Rank": 20173,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Family Hospital, ,St.Andrews Road, Bandra West , Mumbai - 50, Maharashtra, 400050",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059b7"
    },
    "Rank": 20174,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Amandeep Hospital, ,Amandeep Hospital GT road Model Town Amritsar, Punjab, 143001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059b8"
    },
    "Rank": 20176,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059b9"
    },
    "Rank": 20184,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nazareth Hospital, Laitumukhrah, ,Laitumkhrah Shillong, East Khasi Hills district, Meghalaya, 793003",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059ba"
    },
    "Rank": 20186,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059bb"
    },
    "Rank": 20189,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mohandai Oswal Hospital, ,Mohandai Oswal Hospital, Sherpur Byepass, G T Road, Ludhiana-141009, Punjab, 141009",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059bc"
    },
    "Rank": 20194,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059bd"
    },
    "Rank": 20197,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059be"
    },
    "Rank": 20200,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059bf"
    },
    "Rank": 20201,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059c0"
    },
    "Rank": 20206,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollomedics Superspeciality Hospital,KBC 31, Sector B, LDA Colony, Kanpur Road, Lucknow, Uttar Pradesh, 226012",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059c1"
    },
    "Rank": 20208,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059c2"
    },
    "Rank": 20213,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059c3"
    },
    "Rank": 20217,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059c4"
    },
    "Rank": 20218,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chittaranjan Seva Sadan College of Obstetrics, ,37, S P MUKHERJEE ROAD KOLKATA, West Bengal, 700026",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059c5"
    },
    "Rank": 20221,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mohandai Oswal Hospital, ,Mohandai Oswal Hospital, Sherpur Byepass, G T Road, Ludhiana-141009, Punjab, 141009",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059c6"
    },
    "Rank": 20222,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (Hospital Administration)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059c7"
    },
    "Rank": 20223,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Sector-62, Phase VIII, Mohali- 160062, Punjab, 160062",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059c8"
    },
    "Rank": 20224,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Princess Durru Shehvar Children`s and General Hospital, ,22 3 660A PURANI HAVELI HYDERABAD, Telangana, 500002",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059c9"
    },
    "Rank": 20226,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059ca"
    },
    "Rank": 20244,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059cb"
    },
    "Rank": 20245,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shija Hospitals and Research Institute, ,SHIJA HOSPITALS AND RESEARCH INSTITUTE, LANGOL, LAMPHELPAT, IMPHAL WEST, MANIPUR 795004, Manipur, 795004",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059cc"
    },
    "Rank": 20247,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059cd"
    },
    "Rank": 20249,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059ce"
    },
    "Rank": 20250,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Gleneagles Hospital, ,58 Canal Circular Road Kolkata 700054, West Bengal, 700054",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059cf"
    },
    "Rank": 20251,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Theresa`s Hospital,,7-1-645/A ERRAGADA SANATHNAGAAR HYDERABAD,\nTelangana, 500018",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059d0"
    },
    "Rank": 20253,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059d1"
    },
    "Rank": 20255,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Mission Hospital, West Bengal\n,The Mission Hospital, 219 P, Immon Kalyan Sarani, Sector-2C, Bidhannagar, Durgapur, Dist- Paschim Ba, West Bengal, 713212",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059d2"
    },
    "Rank": 20259,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lala Harbhagwan Das Memorial and Dr Prem Hospital, ,BISHAN SWAROOP COLONY, OPP. BUS STAND, Haryana, 132103",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059d3"
    },
    "Rank": 20262,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059d4"
    },
    "Rank": 20263,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lal Bahadur Shastri Hosptial, ,LBSH MAYUR VIHAR PHASE 2 KHICHRIPUR 110091, Delhi (NCT), 110091",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059d5"
    },
    "Rank": 20264,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Continental Hospital, ,Continental Hospitals Pvt Ltd., Plot No.3, Road No.2, IT & Financial District, Nanakramguda, Gachibo, Telangana, 500032",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059d6"
    },
    "Rank": 20265,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059d7"
    },
    "Rank": 20271,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059d8"
    },
    "Rank": 20274,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medica Superspecialty Hospital, ,127 Mukundapur, E.M. Byapss, Kolkata, West Bengal, 700099",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059d9"
    },
    "Rank": 20276,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lala Harbhagwan Das Memorial and Dr Prem Hospital, ,BISHAN SWAROOP COLONY, OPP. BUS STAND, Haryana, 132103",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059da"
    },
    "Rank": 20280,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Family Hospital, ,St.Andrews Road, Bandra West , Mumbai - 50, Maharashtra, 400050",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059db"
    },
    "Rank": 20283,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Doon Medical College, Dehradun,Govt. Doon Medical College Dehrakhas, Patel Nagar Dehradun 248001, Uttarakhand, 248001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059dc"
    },
    "Rank": 20294,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059dd"
    },
    "Rank": 20295,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SARDAR VALLABH BHAI PATEL DISTRICT HOSPITAL , Madhya Pradesh,HOSPITAL CHOWK, DISTRICT HOSPITAL SATNA, Madhya Pradesh, 485001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059de"
    },
    "Rank": 20296,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "DIP.IN GYNAE. and OBST.",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059df"
    },
    "Rank": 20297,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059e0"
    },
    "Rank": 20300,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059e1"
    },
    "Rank": 20301,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059e2"
    },
    "Rank": 20306,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059e3"
    },
    "Rank": 20311,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aditya Birla Memorial Hospital, Maharashtra,Aditya Birla Hospital Marg, P.O.- Chinchwad, Pune, Maharashtra, 411033",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059e4"
    },
    "Rank": 20312,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College,Tumuki, Bihaguri, Tezpur, Sonitpur, Assam, Assam, 784010",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059e5"
    },
    "Rank": 20313,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059e6"
    },
    "Rank": 20320,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chittaranjan Seva Sadan Hospital,37, S.P. MUKHERJEE ROAD, KOLKATA, West Bengal, 700026",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059e7"
    },
    "Rank": 20321,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059e8"
    },
    "Rank": 20322,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESI Hospital, ,IG ESI HOSPITAL JHILMIL DELHI - 95, Delhi (NCT), 110095",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059e9"
    },
    "Rank": 20324,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059ea"
    },
    "Rank": 20326,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059eb"
    },
    "Rank": 20327,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Mulund - Goregaon Link Rd, Nahur West, Industrial Area, Bhandup West, Mumbai, Maharashtra 400078, Maharashtra, 400078",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059ec"
    },
    "Rank": 20329,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059ed"
    },
    "Rank": 20330,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059ee"
    },
    "Rank": 20332,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059ef"
    },
    "Rank": 20336,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rabindranath Tagore International Institute of Cardiac Sciences, ,PREMISES NO. 1489 124 MUKUNDAPUR  E M BYPASS  KOLKATA-700099, West Bengal, 700099",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059f0"
    },
    "Rank": 20337,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059f1"
    },
    "Rank": 20340,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Healthworld Hospital, ,Healthworld Hospitals [A Unit of Parashmani Medical Centre Pvt.Ltd.], C-49,Commercial Area, Opp. ESI, West Bengal, 713216",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059f2"
    },
    "Rank": 20341,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059f3"
    },
    "Rank": 20342,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Village Lingiadih,,Apollo Hospitals Bilaspur Seepat Road  Lingyadih Village  Bilaspur, Chhattisgarh, 495006",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059f4"
    },
    "Rank": 20344,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Prabha Eye Clinic And Research Centre, ,No. 504, 40th cross, 8th Block, Jayanagar, Bangalore 560070, Karnataka, 560070",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059f5"
    },
    "Rank": 20345,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059f6"
    },
    "Rank": 20346,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BIG Apollo Spectra Hospitals,Shitla Mandir Road, Agamkuan, Patna, Bihar, 800007",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059f7"
    },
    "Rank": 20347,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Kamakshi Memorial Hospital, (Dr. Kamakshi Institute of Medical Sciences and Research) ,No.1,Radial Road , Pallikaranai, Chennai, Tamil Nadu, 600100",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059f8"
    },
    "Rank": 20349,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishnakumar Orthopedic Hospital, ,15 82 E Overbridge Parvathipuram\nNagercoil\nKanyakumari District, Tamil Nadu, 629003",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059f9"
    },
    "Rank": 20350,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Head Quarters Hospital,,H NO 3 3 141 MNCL X ROADS KARIMNAGAR KARIMNAGAR DISTRICT, Telangana, 505001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059fa"
    },
    "Rank": 20352,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059fb"
    },
    "Rank": 20355,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "NEOTIA GETWEL HEALTHCARE CENTRE,\n,Uttorayon, Behind City Centre, Matigara, Siliguri, West Bengal, 734010",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059fc"
    },
    "Rank": 20357,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059fd"
    },
    "Rank": 20365,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059fe"
    },
    "Rank": 20375,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "G Kuppuswamy Naidu Memorial Hospital, ,Post Box No.6327, Nethaji Road, Pappanaickenpalayam, Coimbatore, Tamil Nadu, 641037",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a059ff"
    },
    "Rank": 20381,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a00"
    },
    "Rank": 20383,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a01"
    },
    "Rank": 20384,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Peerless Hospital and B K Roy Research Centre, ,360, Panchasayar, Kolkata - 700 -094, West Bengal, 700094",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a02"
    },
    "Rank": 20385,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a03"
    },
    "Rank": 20391,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Almadeena Institute of Medical Science, ,ALMAS HOSPITAL, CHANGUVETTY, KOTTAKKAL, MALAPPURAM DISTRICT, Kerala, 676503",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a04"
    },
    "Rank": 20392,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a05"
    },
    "Rank": 20394,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Spirit Hospital, ,Mahakali Caves Road Andheri East Mumbai, Maharashtra, 400093",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a06"
    },
    "Rank": 20399,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Artemis Health Institute, ,Sector 51, Gurugram, Haryana -122001, Haryana, 122001",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a07"
    },
    "Rank": 20403,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K C General Hospital, ,No. 89, 5th Cross, Malleshwaram, Bangalore, Karnataka, 560003",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a08"
    },
    "Rank": 20405,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a09"
    },
    "Rank": 20409,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a0a"
    },
    "Rank": 20412,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.I.O.T. Hospital, ,4 112 MOUNT POONAMALLEE ROAD MANAPAKKAM\nCHENNAI 600089, Tamil Nadu, 600089",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a0b"
    },
    "Rank": 20413,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "HEALTH CITY HOSPITAL , Assam\n,UDAY NAGAR KOINADHARA KHANAPARA GUWAHATI KAMRUP M, Assam, 781022",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a0c"
    },
    "Rank": 20416,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a0d"
    },
    "Rank": 20417,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a0e"
    },
    "Rank": 20419,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KIMS Al Shifa Hospital, ,KIMS Al Shifa Healthcare Pvt  Ltd.\nPB No 26 .\nOotty Road.\nPerinthalmanna, Malappuram district, Ke, Kerala, 679322",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a0f"
    },
    "Rank": 20420,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a10"
    },
    "Rank": 20425,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Datia,Near 29 Batallion, National Highway 44, Dist Datia, Madhya Pradesh Pin - 475661, Madhya Pradesh, 475661",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a11"
    },
    "Rank": 20429,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a12"
    },
    "Rank": 20431,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a13"
    },
    "Rank": 20433,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a14"
    },
    "Rank": 20435,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a15"
    },
    "Rank": 20446,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Central Super Speciality Hospital,\n,mdcsshecr@gmail.com, Bihar, 800001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a16"
    },
    "Rank": 20448,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Life line Super Specialty Hospital, ,14th Mile, Melood P.O Adoor , Pathanamthitta Dist, Kerala, 691554",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a17"
    },
    "Rank": 20453,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "DIP.IN GYNAE. and OBST.",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a18"
    },
    "Rank": 20457,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vadamalayan Hospital,,Registered Office - 15, Jawahar Road, Chokkikulam, Madurai - 625002.\n9A, Vallabhai Road, Chokkikula, Tamil Nadu, 625002",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a19"
    },
    "Rank": 20459,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ernakulam Medical Centre,,ERNAKULAM MEDICAL CENTRE NH BYPASS, KOCHI, KERALA, INDIA - 682028, Kerala, 682028",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a1a"
    },
    "Rank": 20460,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Breach Candy Hospital, ,60-A, Bhulabhai Desai Road, Mumbai- 400 026., Maharashtra, 400026",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a1b"
    },
    "Rank": 20461,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a1c"
    },
    "Rank": 20466,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Polakulath Narayanan Renai Medicity Multi Super Specialty Hospital,,RENAIMEDICITY PALARIVATTOM KOCHI KERALA, Kerala, 682025",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a1d"
    },
    "Rank": 20467,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a1e"
    },
    "Rank": 20469,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital,,DNB office, 3rd Floor Opp Library  Apollo Hospitals, Jubilee Hills, Hyderabad, Telangana, 500033",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a1f"
    },
    "Rank": 20475,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Healthworld Hospital, ,Healthworld Hospitals [A Unit of Parashmani Medical Centre Pvt.Ltd.], C-49,Commercial Area, Opp. ESI, West Bengal, 713216",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a20"
    },
    "Rank": 20479,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District Hospital,,POOLBAGH ROAD,NEAR FRUIT MARKET,ANAKAPALLI,VISAKHAPATNAM,ANDHRAPRADESH., Andhra Pradesh, 531001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a21"
    },
    "Rank": 20481,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Voluntary Health Services and Medical Centre, ,RAJIV GANDHI SALAI, TARAMANI, CHENNAI, Tamil Nadu, 600113",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a22"
    },
    "Rank": 20482,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Kamakshi Memorial Hospital, (Dr. Kamakshi Institute of Medical Sciences and Research) ,No.1,Radial Road , Pallikaranai, Chennai, Tamil Nadu, 600100",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a23"
    },
    "Rank": 20488,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apple Saraswati Multispecialty Hospital, (Apple Hospitals and Research Institute Ltd.)\n,APPLE SARASWATI MULTISPECIALITY HOSPITAL APPLE HOSPITALS AND RESEARCH INSTITUTE LTD 804 2 805 2 E WA, Maharashtra, 416003",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a24"
    },
    "Rank": 20490,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Memorial Research Institute, ,Fortis memorial research institute, Sector 44, Opposite Huda City Centre Metro station, Gurgaon, Haryana, 122002",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a25"
    },
    "Rank": 20498,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a26"
    },
    "Rank": 20499,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a27"
    },
    "Rank": 20501,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a28"
    },
    "Rank": 20505,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a29"
    },
    "Rank": 20507,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a2a"
    },
    "Rank": 20513,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Baba Saheb Ambedkar Hospital, ,SEC 6 ROHINI DELHI, Delhi (NCT), 110085",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a2b"
    },
    "Rank": 20524,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a2c"
    },
    "Rank": 20525,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agrasen Medical College,,NH- 9, VPO - Agroha, District Hisar, Haryana, 125047",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a2d"
    },
    "Rank": 20530,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a2e"
    },
    "Rank": 20531,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MAR SLEEVA MEDICITY PALAI, Kerala,Kezhuvankulam P.O., Cherpunkal, Kottayam, Kerala, 686584",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a2f"
    },
    "Rank": 20532,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a30"
    },
    "Rank": 20537,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Raja Sukhdev Singh DH Poonch, Jammu and Kashmir,RAJA SUKDEV SINGH DISTRIC HOSPITAL POONCH, Jammu And Kashmir, 185101",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a31"
    },
    "Rank": 20540,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District General Hospital, ,Government District General Hospital, Noonepalle, Nandyal - 518502, Andhra Pradesh, 518502",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a32"
    },
    "Rank": 20543,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a33"
    },
    "Rank": 20546,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indraprastha Apollo Hospital,,Sarita Vihar, New Delhi 110 076, Delhi (NCT), 110076",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a34"
    },
    "Rank": 20552,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a35"
    },
    "Rank": 20555,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a36"
    },
    "Rank": 20559,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a37"
    },
    "Rank": 20561,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a38"
    },
    "Rank": 20562,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a39"
    },
    "Rank": 20565,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a3a"
    },
    "Rank": 20568,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a3b"
    },
    "Rank": 20569,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a3c"
    },
    "Rank": 20577,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Poonam Palaspagar - Academic Coordinator\nFortis Hospital, Bannerghatta Road, Bangalore, Karnataka 5, Karnataka, 560076",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a3d"
    },
    "Rank": 20578,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Theresa`s Hospital,,7-1-645/A ERRAGADA SANATHNAGAAR HYDERABAD,\nTelangana, 500018",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a3e"
    },
    "Rank": 20585,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KMC Specialty Hospital, ,No-6, Royal Road, Cantonment, Trichy-620001, Tamil Nadu, 620001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a3f"
    },
    "Rank": 20586,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Capital Hospital, Orissa,director.chb@gmail.com, Odisha, 751001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a40"
    },
    "Rank": 20587,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Divine Heart and Multispecialty Hospital,\nUttar Pradesh,Viraj Khand 5 Institutional Area Gomti Nagar Lucknow, Uttar Pradesh, 226010",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a41"
    },
    "Rank": 20591,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a42"
    },
    "Rank": 20595,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a43"
    },
    "Rank": 20597,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "B.R. Singh Hospital and Centre for Medical Education and Research,,B.R.Singh Hospital Eastern Railway, Sealdah, Kolkata, West Bengal, 700014",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a44"
    },
    "Rank": 20598,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.E.M. Hospital, ,489, SARDAR MOODLIAR ROAD, RASTA PETH, PUNE 411011,\nMaharashtra, 411011",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a45"
    },
    "Rank": 20603,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,APOLLO HOSPITALS BANGALORE A UNIT OF IMPERIAL HOSPITALS AND RESEARCH CENTRE LIMITED 154/11 Opp IIM B, Karnataka, 560076",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a46"
    },
    "Rank": 20607,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a47"
    },
    "Rank": 20611,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a48"
    },
    "Rank": 20614,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaipur Golden Hospital, ,2 INSTITUTIONAL AREA, SECTOR-3, ROHINI, Delhi (NCT), 110085",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a49"
    },
    "Rank": 20616,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, A Unit of Hometrail Buildtech Pvt Ltd. ,Near Civil Hospital, Phase-6, Mohali, Punjab-160055, Punjab, 160055",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a4a"
    },
    "Rank": 20617,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Meenakshi Mission Hospital and Research Centre, ,Lake Area, Melur Road, Madurai, Tamil Nadu, 625107",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a4b"
    },
    "Rank": 20620,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital Whitefield,,ITPL Main Rd, KIADB Export Promotion Industrial Area, Krishnarajapura, Bengaluru, Karnataka, 560066",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a4c"
    },
    "Rank": 20624,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "NEMCARE Hospital,,G.S.Road, Bhangagarh, Guwahati, Assam, 781005",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a4d"
    },
    "Rank": 20625,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a4e"
    },
    "Rank": 20626,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a4f"
    },
    "Rank": 20631,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a50"
    },
    "Rank": 20632,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a51"
    },
    "Rank": 20635,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a52"
    },
    "Rank": 20644,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jeevan Rekha Superspecialty Hospital, Rajasthan\n,S24, Central Spine  Mahal Yojna, Jagatpura, Jaipur 302 025, Rajasthan, 302025",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a53"
    },
    "Rank": 20648,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a54"
    },
    "Rank": 20649,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MMI Narayana Multispecialty Hospital,,Dhamtari Road, Lalpur, Raipur, Chhattisgarh, 492001",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a55"
    },
    "Rank": 20656,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pandit Madan Mohan Malaviya Shatabdi Hospital, Maharashtra,Pandit  Madan Mohan Malaviya  Shatabdi Hospital\nWaman Tukaram Patil Marg Govandi Mumbai 400088, Maharashtra, 400088",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a56"
    },
    "Rank": 20658,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollomedics Superspeciality Hospital,KBC 31, Sector B, LDA Colony, Kanpur Road, Lucknow, Uttar Pradesh, 226012",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a57"
    },
    "Rank": 20659,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Mission Hospital, West Bengal\n,The Mission Hospital, 219 P, Immon Kalyan Sarani, Sector-2C, Bidhannagar, Durgapur, Dist- Paschim Ba, West Bengal, 713212",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a58"
    },
    "Rank": 20660,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Almadeena Institute of Medical Science, ,ALMAS HOSPITAL, CHANGUVETTY, KOTTAKKAL, MALAPPURAM DISTRICT, Kerala, 676503",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a59"
    },
    "Rank": 20662,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ashwini Hospital, Cuttack Orissa,SECTOR 1 CDA CUTTACK, Odisha, 753014",
    "Course": "(NBEMS) Emergency Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a5a"
    },
    "Rank": 20664,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agrasen Medical College,,NH- 9, VPO - Agroha, District Hisar, Haryana, 125047",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a5b"
    },
    "Rank": 20670,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a5c"
    },
    "Rank": 20672,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,Plotno-251,Sainik school Road,Unit-15 Apollo Hospitals , Bhubaneswar, Odisha, 751005",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a5d"
    },
    "Rank": 20675,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shija Hospitals and Research Institute, ,SHIJA HOSPITALS AND RESEARCH INSTITUTE, LANGOL, LAMPHELPAT, IMPHAL WEST, MANIPUR 795004, Manipur, 795004",
    "Course": "(NBEMS) Plastic and Reconstructive Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a5e"
    },
    "Rank": 20677,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Healthworld Hospital, ,Healthworld Hospitals [A Unit of Parashmani Medical Centre Pvt.Ltd.], C-49,Commercial Area, Opp. ESI, West Bengal, 713216",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a5f"
    },
    "Rank": 20680,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a60"
    },
    "Rank": 20688,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,Veer Chandra Singh Garhwali Govt. Institute of Medical Science and Research, Srinagar Garhwal, Uttarakhand, 246174",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a61"
    },
    "Rank": 20695,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a62"
    },
    "Rank": 20699,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a63"
    },
    "Rank": 20700,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a64"
    },
    "Rank": 20702,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a65"
    },
    "Rank": 20709,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a66"
    },
    "Rank": 20716,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta The Medicity, ,Medanta The Medicity Sector 38 Gurgoan, Haryana, 122001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a67"
    },
    "Rank": 20717,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,HBT Medical College and Dr R N Cooper Municipal General Hospital, Bhaktivedanta Swami Rd, JVPD Schem, Maharashtra, 400056",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a68"
    },
    "Rank": 20718,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Surya Children`s Medicare, (Formerly Surya Children`s Hospital) ,S. V. Road, Santacruz West Mumbai, Maharashtra, 400054",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a69"
    },
    "Rank": 20725,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a6a"
    },
    "Rank": 20728,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a6b"
    },
    "Rank": 20729,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, ,1,2, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a6c"
    },
    "Rank": 20730,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a6d"
    },
    "Rank": 20734,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a6e"
    },
    "Rank": 20736,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BIG Apollo Spectra Hospitals,Shitla Mandir Road, Agamkuan, Patna, Bihar, 800007",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a6f"
    },
    "Rank": 20737,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a70"
    },
    "Rank": 20746,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SRI SANKARA NETRA CHIKITSALAYA, Andhra Pradesh,SRI SANKARA NETRA CHIKITSALAYA, 23A-6-6 ,NEAR VENKATESWARASWAMY TEMPLE,RR PETA,ELURU,WESTGODAVARI DI, Andhra Pradesh, 534002",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a71"
    },
    "Rank": 20747,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Specialty Hospitals, ,IIIM NEHRU NAGAR GHAZIABAD, Uttar Pradesh, 201001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a72"
    },
    "Rank": 20749,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a73"
    },
    "Rank": 20751,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Babasaheb Ambedkar Central Railway Hospital, ,Bharatratna Dr. Babasaheb Ambedkar Memorial Hospital, Central Railway, Dr. Babasaheb Ambedkar Road,, Maharashtra, 400027",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a74"
    },
    "Rank": 20754,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ashoka Medicover Hospital, ,Ashoka Medicover Hospitals, Indira Nagar, Opposite to WNS, Wadala Nashik, Maharashtra, 422009",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a75"
    },
    "Rank": 20757,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Thind Eye Hospital , ,701 Mall Road , Model Town , Jalandhar, Punjab, 144003",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a76"
    },
    "Rank": 20760,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagwan Mahavir Cancer Hospital and Research Centre, ,Managed by K G Kothari Memorial Trust\nJawahar Lal Nehru Marg, Jaipur 302 017, Rajasthan, 302017",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a77"
    },
    "Rank": 20761,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a78"
    },
    "Rank": 20769,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Hospital,,JANGLAT MANDI ANANTNAG, JAMMU AND KASHMIR 192101, Jammu And Kashmir, 192101",
    "Course": "(NBEMS) DERMATOLOGY and VENEREOLOGY and LEPROSY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a79"
    },
    "Rank": 20773,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "FAKIR MOHAN MEDICAL COLLEGE AND HOSPITAL ,\nOrissa,AT-Remuna Post-Remuna Dist-Balasore, Odisha, 756019",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a7a"
    },
    "Rank": 20776,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaikh-Ul-Hind Maulana Mahmood Hasan Medical College, Saharanpur,Pilkhani Ambala Road, Uttar Pradesh, 247232",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a7b"
    },
    "Rank": 20777,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gaba Hospital, Haryana,Bye Pass Road, Yamuna Nagar Haryana 135001, Haryana, 135001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a7c"
    },
    "Rank": 20778,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kauvery Hospital, ,No.199, Luz Church road, Mylapore,Chennai-600004, Tamil Nadu, 600004",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a7d"
    },
    "Rank": 20783,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Headquarters Hospital, ,Udumalai Road, Pollachi, Tamil Nadu, Tamil Nadu, 642001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a7e"
    },
    "Rank": 20784,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bejan Singh Eye Hospital, ,2/1 313C , M.S.ROAD, VETTOORNIMADAM, NAGERCOIL, Tamil Nadu, 629003",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a7f"
    },
    "Rank": 20785,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "CHL Hospital,,L.I.G. SQUARE A.B. ROAD, INDORE, Madhya Pradesh, 452008",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a80"
    },
    "Rank": 20788,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a81"
    },
    "Rank": 20790,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a82"
    },
    "Rank": 20793,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medivision Eye and Health Care Centre, ,10-3-304/1 to 4, Indira Sadan,Humayun Nagar, Near NMDC, Hyderabad., Telangana, 500028",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a83"
    },
    "Rank": 20795,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Diamond Harbour Government Medical College And Hospital, ,Harindanga, New Town, Word No- 4, P.O and P.S- Diamond Harbour, Dist- South 24 Parganas, West Bengal, West Bengal, 743331",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a84"
    },
    "Rank": 20797,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta The Medicity, ,Medanta The Medicity Sector 38 Gurgoan, Haryana, 122001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a85"
    },
    "Rank": 20798,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,RAJBHAVAN ROAD SOMAJIGUDA, Telangana, 500082",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a86"
    },
    "Rank": 20801,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Meenakshi Mission Hospital and Research Centre, ,Lake Area, Melur Road, Madurai, Tamil Nadu, 625107",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a87"
    },
    "Rank": 20806,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a88"
    },
    "Rank": 20807,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a89"
    },
    "Rank": 20808,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Womens Center by Motherhood,\n,NO 146B Mettupalayam Road Coimbatore, Tamil Nadu, 641043",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a8a"
    },
    "Rank": 20810,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Global Hospital Institute of Ophthalmology, ,GLOBAL HOSPITAL INSTITUTE OF OPHTHALMOLOGY TALHATI POST SHANTIVAN ABU ROAD SIROHI  RAJASTHAN\n307510, Rajasthan, 307510",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a8b"
    },
    "Rank": 20815,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a8c"
    },
    "Rank": 20817,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Cosmopolitan Hospital,,COSMOPOLITAN HOSPITALS Pvt.Ltd, Murinjapalam, Pattom P O, Thiruvananthapuram, Kerala, 695004",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a8d"
    },
    "Rank": 20818,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Amar Hospital, ,#8, Bank Colony, Income Tax Office Road, Patiala, Punjab, 147001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a8e"
    },
    "Rank": 20819,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a8f"
    },
    "Rank": 20821,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aakash Healthcare Super Specialty Hospital, Delhi,HOSPITAL PLOT, ROAD NO- 201,SECTOR 3 DWARKA, Delhi (NCT), 110075",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a90"
    },
    "Rank": 20823,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a91"
    },
    "Rank": 20826,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. R N Cooper Municipal General Hospital, (Associated with H B T (Hinduhridayasamrat Balasaheb Thackeray) medical College) ,HBTMC AND DR.R.N.COOPER HOSPITAL\nU 15 BHAKTI VEDANTA ROAD JVPD SCHEME VILE PARLE WEST MUMBAI,\nMaharashtra, 400056",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a92"
    },
    "Rank": 20832,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT DHARMAPURI MEDICAL COLLEGE, DHARMAPURI, TAMIL NADU,GOVERNMENT DHARMAPURI MEDICAL COLLEGE, NETHAJI BYE PASS ROAD, DHARMAPURI, TAMIL NADU, Tamil Nadu, 636701",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a93"
    },
    "Rank": 20833,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a94"
    },
    "Rank": 20838,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta Hospital,\nUttar Pradesh,Medanta hospital, Sec A pocket 1, Amar Shaheed Path Sushant Golf City Lucknow, Uttar Pradesh, 226030",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a95"
    },
    "Rank": 20842,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a96"
    },
    "Rank": 20843,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a97"
    },
    "Rank": 20844,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a98"
    },
    "Rank": 20845,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Care Hospital,,Care Hospitals Road No1 Banjara Hills Hyderabad Telangana, Telangana, 500034",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a99"
    },
    "Rank": 20856,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a9a"
    },
    "Rank": 20857,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a9b"
    },
    "Rank": 20863,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "DIP. IN T.B. and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a9c"
    },
    "Rank": 20865,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a9d"
    },
    "Rank": 20867,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "YATHARTH SUPER SPECIALTY HOSPITALS,\nUttar Pradesh,Sector omega 01-, Greater noida, Uttar Pradesh, 201308",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a9e"
    },
    "Rank": 20868,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apex Hospital,\nUttar Pradesh,Delhi Road, opposite hotel park square, Moradabad, Uttar Pradesh, 244001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05a9f"
    },
    "Rank": 20871,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05aa0"
    },
    "Rank": 20873,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05aa1"
    },
    "Rank": 20875,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05aa2"
    },
    "Rank": 20876,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05aa3"
    },
    "Rank": 20877,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05aa4"
    },
    "Rank": 20878,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05aa5"
    },
    "Rank": 20884,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05aa6"
    },
    "Rank": 20886,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indus International Hospital, ,CHANDIGARH-AMBALA ROAD NH-22, DERABASSI, Punjab, 140507",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05aa7"
    },
    "Rank": 20887,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05aa8"
    },
    "Rank": 20888,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SRM Institutes for Medical Sciences, ,No 1 Jawaharlal Nehru Salai, Vadapalani, Chennai, Tamil Nadu, 600026",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05aa9"
    },
    "Rank": 20890,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apex Hospital,,N7 2A 5D BLW Hydel Road Varanasi, Uttar Pradesh, 221004",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05aaa"
    },
    "Rank": 20891,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Amar Hospital, ,#8, Bank Colony, Income Tax Office Road, Patiala, Punjab, 147001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05aab"
    },
    "Rank": 20893,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rabindranath Tagore International Institute of Cardiac Sciences, ,PREMISES NO. 1489 124 MUKUNDAPUR  E M BYPASS  KOLKATA-700099, West Bengal, 700099",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05aac"
    },
    "Rank": 20896,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Visakha Eye Hospital , ,Managed By Visakha Nethra Jyothi Pvt Ltd 8-1-64 Pedda Waltari Visakhapatnam, Andhra Pradesh, 530017",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05aad"
    },
    "Rank": 20900,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital, ,Kharvad medan Sipor road Vadnagar, Gujarat, 384355",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05aae"
    },
    "Rank": 20902,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gvernment Medical College,Haldwani,Government Medical College, Haldwani, Uttarakhand, 263139",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05aaf"
    },
    "Rank": 20904,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ab0"
    },
    "Rank": 20909,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ab1"
    },
    "Rank": 20910,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ab2"
    },
    "Rank": 20913,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Meenakshi Hospital, ,244-2, Trichy Main Road, Near New Bus stand, Thanjavur, Tamil Nadu, 613005",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ab3"
    },
    "Rank": 20915,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES,CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, Chhattisgarh, 495001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ab4"
    },
    "Rank": 20917,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ab5"
    },
    "Rank": 20918,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ab6"
    },
    "Rank": 20919,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ab7"
    },
    "Rank": 20922,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kauvery Hospital, ,No.199, Luz Church road, Mylapore,Chennai-600004, Tamil Nadu, 600004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ab8"
    },
    "Rank": 20924,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ab9"
    },
    "Rank": 20929,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05aba"
    },
    "Rank": 20935,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05abb"
    },
    "Rank": 20937,
    "Allotted Quota": "All India",
    "Allotted Institute": "All  India Institute of  Hygiene and Public Health,110, C. R  Avenue, Kolkata, West Bengal, 700073",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05abc"
    },
    "Rank": 20939,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05abd"
    },
    "Rank": 20941,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Specialty Hospitals, ,IIIM NEHRU NAGAR GHAZIABAD, Uttar Pradesh, 201001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05abe"
    },
    "Rank": 20944,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05abf"
    },
    "Rank": 20946,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SP FORT HOSPITAL, Kerala\n,FORT, ANAKOTTIL LANE, THIRUVANANTHAPURAM, Kerala, 695023",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ac0"
    },
    "Rank": 20947,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ac1"
    },
    "Rank": 20948,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Surya Mother and Child Care Jaipur Pvt Ltd.,\n,B-7, Sawai Ram SIngh Road, Opp SMS Hospital, Jaipur, Rajasthan, 302001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ac2"
    },
    "Rank": 20949,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Daya General Hospital,Daya General Hospital Limited Shornur Road NEar Viyyur bridge\nThiruvambady PO Thrissur, Kerala, 680022",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ac3"
    },
    "Rank": 20950,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ac4"
    },
    "Rank": 20953,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ac5"
    },
    "Rank": 20954,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ac6"
    },
    "Rank": 20955,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Madhukar Rainbow Childrens Hospital,,FC-29, Plot No.5, Geetanjali, Near Malviya Nagar Metro station gate no.1, Delhi (NCT), 110017",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ac7"
    },
    "Rank": 20957,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ac8"
    },
    "Rank": 20962,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ac9"
    },
    "Rank": 20964,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Raos ENT Super Speciality Hospitals, Hyderabad , Telangana,PLOT NO -29,KPHB COLONY BEHIND TEMPLE BUS STOP ,HYDERABAD,TELANGANA, Telangana, 500072",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05aca"
    },
    "Rank": 20969,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05acb"
    },
    "Rank": 20970,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Allopathic Medical College, Uttar Pradesh,RANI DURGAVATI MEDICAL COLLEGE NARAINI ROAD BANDA, Uttar Pradesh, 210001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05acc"
    },
    "Rank": 20971,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Civil Hospital, ,NIT, Opp. MCF Faridabad Haryana 121001, Haryana, 121001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05acd"
    },
    "Rank": 20973,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ace"
    },
    "Rank": 20975,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KIMS Hospital , ,KIMS Hospitals, 69/213-k-9, Joharapuram Road, Joharapuram, Kurnool, Andhra Pradesh, 518002",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05acf"
    },
    "Rank": 20976,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,APOLLO HOSPITALS BANGALORE A UNIT OF IMPERIAL HOSPITALS AND RESEARCH CENTRE LIMITED 154/11 Opp IIM B, Karnataka, 560076",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ad0"
    },
    "Rank": 20981,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College,Tumuki, Bihaguri, Tezpur, Sonitpur, Assam, Assam, 784010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ad1"
    },
    "Rank": 20982,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Care Hospital,,Care Hospitals Road No1 Banjara Hills Hyderabad Telangana, Telangana, 500034",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ad2"
    },
    "Rank": 20985,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "B.M. Gupta Hospital,  New Delhi,H-9 to H-15, Uttam Nagar Near Arya Samaj Road, Delhi (NCT), 110059",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ad3"
    },
    "Rank": 20986,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Poonam Palaspagar - Academic Coordinator\nFortis Hospital, Bannerghatta Road, Bangalore, Karnataka 5, Karnataka, 560076",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ad4"
    },
    "Rank": 20988,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ad5"
    },
    "Rank": 20990,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ad6"
    },
    "Rank": 20995,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ad7"
    },
    "Rank": 20998,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agrasen Medical College,,NH- 9, VPO - Agroha, District Hisar, Haryana, 125047",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ad8"
    },
    "Rank": 21000,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ad9"
    },
    "Rank": 21001,
    "Allotted Quota": "All India",
    "Allotted Institute": "The National Institute of Health and Family Welfare,The National Institute of Health and Family Welfare, Delhi (NCT), 110067",
    "Course": "M.D. (COMMUNITY HEALTH  and ADMN.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ada"
    },
    "Rank": 21003,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05adb"
    },
    "Rank": 21005,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute of Medical Sciences Srikakulam,Government Medical College Srikakulam\nBalaga Srikakulam Town Srikakulam District, Andhra Pradesh, 532001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05adc"
    },
    "Rank": 21006,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.G.M. Muthoot Hospital, ,MGM Muthoot Medical Centre, Ring Road, Pathanamthitta, Kerala, 689645",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05add"
    },
    "Rank": 21009,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "J.P.M. Rotary Eye Hospital and Research Institute,,CDA, Sector -6 , Abhinav Bidanasi, Markat Nagar Cuttack, Odisha, 753014",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ade"
    },
    "Rank": 21012,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indus International Hospital, ,CHANDIGARH-AMBALA ROAD NH-22, DERABASSI, Punjab, 140507",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05adf"
    },
    "Rank": 21013,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ae0"
    },
    "Rank": 21026,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Cosmopolitan Hospital,,COSMOPOLITAN HOSPITALS Pvt.Ltd, Murinjapalam, Pattom P O, Thiruvananthapuram, Kerala, 695004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ae1"
    },
    "Rank": 21028,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ae2"
    },
    "Rank": 21035,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S. K. S. Hospital, ,NO.23,SKS Hospital Road, Alagapuram,Salem, Tamil Nadu, 636004",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ae3"
    },
    "Rank": 21036,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MMI Narayana Multispecialty Hospital,,Dhamtari Road, Lalpur, Raipur, Chhattisgarh, 492001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ae4"
    },
    "Rank": 21039,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Starcare Hospital Kozhikode Private Limited, Starcare Hospital,\n,STARCARE HOSPITAL, NELLIKKODE POST, THONDAYAD, KOZHIKODE, Kerala, 673017",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ae5"
    },
    "Rank": 21040,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta The Medicity, ,Medanta The Medicity Sector 38 Gurgoan, Haryana, 122001",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ae6"
    },
    "Rank": 21042,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, ,1,2, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ae7"
    },
    "Rank": 21043,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Madhukar Rainbow Childrens Hospital,,FC-29, Plot No.5, Geetanjali, Near Malviya Nagar Metro station gate no.1, Delhi (NCT), 110017",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ae8"
    },
    "Rank": 21044,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ae9"
    },
    "Rank": 21046,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05aea"
    },
    "Rank": 21047,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05aeb"
    },
    "Rank": 21048,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Breach Candy Hospital, ,60-A, Bhulabhai Desai Road, Mumbai- 400 026., Maharashtra, 400026",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05aec"
    },
    "Rank": 21049,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05aed"
    },
    "Rank": 21051,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05aee"
    },
    "Rank": 21053,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05aef"
    },
    "Rank": 21054,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kauvery Hospital, ,No.199, Luz Church road, Mylapore,Chennai-600004, Tamil Nadu, 600004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05af0"
    },
    "Rank": 21056,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05af1"
    },
    "Rank": 21058,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mehta Multispecialty Hospital, (Formerly Known as Dr. Mehta`s Hospital) ,No.2, McNichols Road, 3 rd Lane, Chetpet, Chennai, Tamil Nadu, 600031",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05af2"
    },
    "Rank": 21059,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05af3"
    },
    "Rank": 21060,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Life line Super Specialty Hospital, ,14th Mile, Melood P.O Adoor , Pathanamthitta Dist, Kerala, 691554",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05af4"
    },
    "Rank": 21066,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aditya Birla Memorial Hospital, Maharashtra,Aditya Birla Hospital Marg, P.O.- Chinchwad, Pune, Maharashtra, 411033",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05af5"
    },
    "Rank": 21070,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05af6"
    },
    "Rank": 21071,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agrasen Medical College,,NH- 9, VPO - Agroha, District Hisar, Haryana, 125047",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05af7"
    },
    "Rank": 21073,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SunShine Hospital, ,1-7-201 TO 205\nPG Road, Paradise, Secunderabad, Telangana, 500003",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05af8"
    },
    "Rank": 21078,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Divine Heart and Multispecialty Hospital,\nUttar Pradesh,Viraj Khand 5 Institutional Area Gomti Nagar Lucknow, Uttar Pradesh, 226010",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05af9"
    },
    "Rank": 21079,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaslok Hospital and Research Centre, ,15, Dr. G. Deshmukh Marg, Mumbai - 400026, Maharashtra, 400026",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05afa"
    },
    "Rank": 21081,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05afb"
    },
    "Rank": 21086,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05afc"
    },
    "Rank": 21088,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Felix Hospital,\n,NH-01 SECTOR 137 NOIDA EXPRESSWAY UP, Uttar Pradesh, 201305",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05afd"
    },
    "Rank": 21089,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05afe"
    },
    "Rank": 21091,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05aff"
    },
    "Rank": 21096,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Autonomous State Medical College and Allied Pandit Ram Prasad Bismil Memorial Hospital,\n,ASMC, JIGNERA, kanth road, shahjahanpur, Uttar Pradesh, 242001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b00"
    },
    "Rank": 21100,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b01"
    },
    "Rank": 21101,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b02"
    },
    "Rank": 21103,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indo-American Cancer Institute and Research Centre, ,Road No.14, Banjara Hills, Hyderabad, Telangana, 500034",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b03"
    },
    "Rank": 21105,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Global Hospital Institute of Ophthalmology, ,GLOBAL HOSPITAL INSTITUTE OF OPHTHALMOLOGY TALHATI POST SHANTIVAN ABU ROAD SIROHI  RAJASTHAN\n307510, Rajasthan, 307510",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b04"
    },
    "Rank": 21106,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College, Kolhapur,R.K. Nagar Road, Shenda Park, Kolhapur, Maharashtra, Maharashtra, 416012",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b05"
    },
    "Rank": 21109,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b06"
    },
    "Rank": 21110,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b07"
    },
    "Rank": 21123,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manik Hospital, Aurangabad Maharashtra,\nMaharashtra,Manik Hospital P 148 Opp Kamgar Kalyan bhawan near Motadevi mandir Bajaj nagar MIDC Waluj Aurangabad, Maharashtra, 431136",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b08"
    },
    "Rank": 21126,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Preethi Hospital, ,50, Melur Main Road, Uthangudi, Madurai - 625107, Tamil Nadu, 625107",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b09"
    },
    "Rank": 21131,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b0a"
    },
    "Rank": 21132,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VELLORE MEDICAL COLLEGE,GOVERNMENT VELLORE MEDICAL COLLEGE, Tamil Nadu, 632011",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b0b"
    },
    "Rank": 21135,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b0c"
    },
    "Rank": 21138,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Spirit Hospital, ,Mahakali Caves Road Andheri East Mumbai, Maharashtra, 400093",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b0d"
    },
    "Rank": 21140,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SRM Institutes for Medical Sciences, ,No 1 Jawaharlal Nehru Salai, Vadapalani, Chennai, Tamil Nadu, 600026",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b0e"
    },
    "Rank": 21142,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaiprakash Hospital and Research Centre Pvt. Ltd, Odisha,,JAIPRAKASH HOSPITAL AND RESEARCH CENTER PVT. LTD., DANDIAPALI, ROURKELA, Odisha, 769004",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b0f"
    },
    "Rank": 21146,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Kamakshi Memorial Hospital, (Dr. Kamakshi Institute of Medical Sciences and Research) ,No.1,Radial Road , Pallikaranai, Chennai, Tamil Nadu, 600100",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b10"
    },
    "Rank": 21153,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo BGS Hospital,,Apollo BGS Hospitals, Adhichunchangiri Road, Kuvempunagar, Mysore, Karnataka, 570023",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b11"
    },
    "Rank": 21156,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shija Hospitals and Research Institute, ,SHIJA HOSPITALS AND RESEARCH INSTITUTE, LANGOL, LAMPHELPAT, IMPHAL WEST, MANIPUR 795004, Manipur, 795004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b12"
    },
    "Rank": 21158,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Virk Hospital Private Limited, Karnal , Haryana,363-364 Randhir Lane, Karnal, Haryana, 132001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b13"
    },
    "Rank": 21161,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Rela Institute and Medical Centre ,\nTamil Nadu,NO.7, CLC WORKS ROAD, CHROMPET CHENNAI, Tamil Nadu, 600044",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b14"
    },
    "Rank": 21164,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Doon Medical College, Dehradun,Govt. Doon Medical College Dehrakhas, Patel Nagar Dehradun 248001, Uttarakhand, 248001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b15"
    },
    "Rank": 21165,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vadamalayan Hospital,,Registered Office - 15, Jawahar Road, Chokkikulam, Madurai - 625002.\n9A, Vallabhai Road, Chokkikula, Tamil Nadu, 625002",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b16"
    },
    "Rank": 21170,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,LOTUS TOWER, G S ROAD, GUWAHATI, Assam, 781005",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b17"
    },
    "Rank": 21173,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b18"
    },
    "Rank": 21174,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sahai Hospital and Research Centre, ,a-73 ,Bhabha Marg, Moti Doongri, Tilak Nagar, Jaipur 302004, Rajasthan, 302004",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b19"
    },
    "Rank": 21175,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Breach Candy Hospital, ,60-A, Bhulabhai Desai Road, Mumbai- 400 026., Maharashtra, 400026",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b1a"
    },
    "Rank": 21184,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b1b"
    },
    "Rank": 21192,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b1c"
    },
    "Rank": 21196,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K C General Hospital, ,No. 89, 5th Cross, Malleshwaram, Bangalore, Karnataka, 560003",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b1d"
    },
    "Rank": 21199,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, (Formerly- Pushpanjali Crosslay Hospital) ,W-3, Sector-1, Vaishali, Ghaziabad, Uttar Pradesh, 201012",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b1e"
    },
    "Rank": 21200,
    "Allotted Quota": "All India",
    "Allotted Institute": "All  India Institute of  Hygiene and Public Health,110, C. R  Avenue, Kolkata, West Bengal, 700073",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b1f"
    },
    "Rank": 21201,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Meenakshi Hospital, ,244-2, Trichy Main Road, Near New Bus stand, Thanjavur, Tamil Nadu, 613005",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b20"
    },
    "Rank": 21202,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b21"
    },
    "Rank": 21204,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b22"
    },
    "Rank": 21206,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b23"
    },
    "Rank": 21207,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KMC Specialty Hospital, ,No-6, Royal Road, Cantonment, Trichy-620001, Tamil Nadu, 620001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b24"
    },
    "Rank": 21208,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b25"
    },
    "Rank": 21209,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b26"
    },
    "Rank": 21210,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b27"
    },
    "Rank": 21211,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Gleneagles Hospital, ,58 Canal Circular Road Kolkata 700054, West Bengal, 700054",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b28"
    },
    "Rank": 21217,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b29"
    },
    "Rank": 21218,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b2a"
    },
    "Rank": 21221,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta The Medicity, ,Medanta The Medicity Sector 38 Gurgoan, Haryana, 122001",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b2b"
    },
    "Rank": 21223,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "C.S.I. Kalyani General Hospital,, ,15, DR RADHAKRISHNAN SALAI, MYLAPORE, CHENNAI, Tamil Nadu, 600004",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b2c"
    },
    "Rank": 21224,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Healthworld Hospital, ,Healthworld Hospitals [A Unit of Parashmani Medical Centre Pvt.Ltd.], C-49,Commercial Area, Opp. ESI, West Bengal, 713216",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b2d"
    },
    "Rank": 21227,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital,,DNB office, 3rd Floor Opp Library  Apollo Hospitals, Jubilee Hills, Hyderabad, Telangana, 500033",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b2e"
    },
    "Rank": 21228,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b2f"
    },
    "Rank": 21229,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,APOLLO HOSPITALS BANGALORE A UNIT OF IMPERIAL HOSPITALS AND RESEARCH CENTRE LIMITED 154/11 Opp IIM B, Karnataka, 560076",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b30"
    },
    "Rank": 21230,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b31"
    },
    "Rank": 21231,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b32"
    },
    "Rank": 21232,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College,Tumuki, Bihaguri, Tezpur, Sonitpur, Assam, Assam, 784010",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b33"
    },
    "Rank": 21233,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b34"
    },
    "Rank": 21236,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b35"
    },
    "Rank": 21240,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b36"
    },
    "Rank": 21241,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Sivagangai Medical College and Hospital, Tamil Nadu,GOVT.SIVAGANGAI MEDICAL COLLEGE, MELAVANIYANKUDY, MANAMADURAI ROAD, SIVAGANGAI.,\nTamil Nadu, 630561",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b37"
    },
    "Rank": 21247,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. L H Hiranandani Hospital, ,HILL SIDE AVENUE, HIRANANDANI GARDENS, POWAI-400076, Maharashtra, 400076",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b38"
    },
    "Rank": 21248,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b39"
    },
    "Rank": 21250,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Action Cancer hospital, ,H 2 FC-34 A-4, PASCHIM VIHAR NEW DELHI, Delhi (NCT), 110063",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b3a"
    },
    "Rank": 21254,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b3b"
    },
    "Rank": 21259,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b3c"
    },
    "Rank": 21260,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kalpana Chawla Govt Medical College, ,Model Town, Karnal, Haryana, 132001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b3d"
    },
    "Rank": 21262,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b3e"
    },
    "Rank": 21263,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "DIP.IN GYNAE. and OBST.",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b3f"
    },
    "Rank": 21264,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "National Institute Of Ophthalmology, ,1187.30 ShivajiNagar, off Ghole Road, Pune, Maharashtra, 411005",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b40"
    },
    "Rank": 21265,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Northern Railway Central Hospital,, ,Basant Lane New Delhi, Delhi (NCT), 110055",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b41"
    },
    "Rank": 21267,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruban Memorial Hospital, Bihar,19 Patliputra Colony Near Patliputra Golambar Patna, Bihar, 800013",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b42"
    },
    "Rank": 21269,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SNR District Hospital,,SNR DISTRICT HOSPITAL, KOLAR KARNATAKA-563101,\nKarnataka, 563101",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b43"
    },
    "Rank": 21271,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b44"
    },
    "Rank": 21272,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b45"
    },
    "Rank": 21279,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b46"
    },
    "Rank": 21284,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b47"
    },
    "Rank": 21289,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b48"
    },
    "Rank": 21290,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b49"
    },
    "Rank": 21291,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,LOTUS TOWER, G S ROAD, GUWAHATI, Assam, 781005",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b4a"
    },
    "Rank": 21292,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b4b"
    },
    "Rank": 21293,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESI Hospital, ,IG ESI HOSPITAL JHILMIL DELHI - 95, Delhi (NCT), 110095",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b4c"
    },
    "Rank": 21294,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b4d"
    },
    "Rank": 21297,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b4e"
    },
    "Rank": 21299,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Adlux Hospital ( A Unit of Adlux Medicity and Convention Centre Private Limited), Kerala\n,Apollo Adlux Hospital (A Unit of Adlux medicity and Convention Centre Private limited), Adlux juncti, Kerala, 683576",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b4f"
    },
    "Rank": 21304,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b50"
    },
    "Rank": 21305,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Johal Multispeciality Hospital,Hoshiarpur Road Rama Mandi Jalandhar Punjab 144005, Punjab, 144005",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b51"
    },
    "Rank": 21307,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Divisional Railway Hospital,,Near Railway Gate Valdhuni Kalyan west dist Thane, Maharashtra, 421301",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b52"
    },
    "Rank": 21309,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b53"
    },
    "Rank": 21310,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b54"
    },
    "Rank": 21311,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Care Hospital, ,Care hospitals exhibition road Nampally Hyderabad Telangana, Telangana, 500001",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b55"
    },
    "Rank": 21316,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b56"
    },
    "Rank": 21319,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Cosmopolitan Hospital,,COSMOPOLITAN HOSPITALS Pvt.Ltd, Murinjapalam, Pattom P O, Thiruvananthapuram, Kerala, 695004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b57"
    },
    "Rank": 21320,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,Plotno-251,Sainik school Road,Unit-15 Apollo Hospitals , Bhubaneswar, Odisha, 751005",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b58"
    },
    "Rank": 21322,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Central Super Speciality Hospital,\n,mdcsshecr@gmail.com, Bihar, 800001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b59"
    },
    "Rank": 21324,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Visakha Eye Hospital , ,Managed By Visakha Nethra Jyothi Pvt Ltd 8-1-64 Pedda Waltari Visakhapatnam, Andhra Pradesh, 530017",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b5a"
    },
    "Rank": 21325,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bokaro General Hospital, ,SECTOR - 4 BOKARO STEEL CITY\nDIST - BOKARO, Jharkhand, 827004",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b5b"
    },
    "Rank": 21328,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Ramesh Cardiac and Multi Speciality Hospital,,Collector office road, Nagarampalem, Guntur , AP, Andhra Pradesh, 522004",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b5c"
    },
    "Rank": 21329,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gangasheel Advanced Medical Research Institute, Uttar Pradesh,C-17, Deen Dayal Puram, Bareilly - 243122, Uttar Pradesh, 243122",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b5d"
    },
    "Rank": 21330,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yatharth Superspeciality Hospitals,\nUttar Pradesh,HO-01, Sector-01, Greater Noida West, Uttar Pradesh, 201306",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b5e"
    },
    "Rank": 21332,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,Plotno-251,Sainik school Road,Unit-15 Apollo Hospitals , Bhubaneswar, Odisha, 751005",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b5f"
    },
    "Rank": 21334,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b60"
    },
    "Rank": 21336,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kokilaben Dhirubhai Ambani Hospital and Medical Research Institute,,Achyutrao Patwardhan Marg Andheri West Mumbai Maharashtra, Maharashtra, 400053",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b61"
    },
    "Rank": 21337,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Kiran Institute of Ophthalmology, ,Srikiran Institute of Ophthalmology, D.No, 6-227, Penumarthy Road, Thimmapuram Village, Kakinada Rur, Andhra Pradesh, 533005",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b62"
    },
    "Rank": 21341,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) Hospital Administration",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b63"
    },
    "Rank": 21342,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b64"
    },
    "Rank": 21343,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b65"
    },
    "Rank": 21344,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b66"
    },
    "Rank": 21347,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Muthoot Health Care, ,Muthoot Healthcare, College Road, Kozhencherry, Pathanamthitta District, Kerala, 689641",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b67"
    },
    "Rank": 21348,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Khan Bahadur Bhabha Hospital, Maharashtra,Khan Bahadur Bhabha Hospital Belgrami road kurla west, Maharashtra, 400070",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b68"
    },
    "Rank": 21349,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b69"
    },
    "Rank": 21350,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b6a"
    },
    "Rank": 21352,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b6b"
    },
    "Rank": 21354,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaslok Hospital and Research Centre, ,15, Dr. G. Deshmukh Marg, Mumbai - 400026, Maharashtra, 400026",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b6c"
    },
    "Rank": 21355,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b6d"
    },
    "Rank": 21358,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b6e"
    },
    "Rank": 21359,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b6f"
    },
    "Rank": 21360,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital,\n,General Hospital\nPavagada Tumakuru District Karnataka 561202, Karnataka, 561202",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b70"
    },
    "Rank": 21361,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b71"
    },
    "Rank": 21363,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Johal Multispeciality Hospital,Hoshiarpur Road Rama Mandi Jalandhar Punjab 144005, Punjab, 144005",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b72"
    },
    "Rank": 21366,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b73"
    },
    "Rank": 21368,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b74"
    },
    "Rank": 21369,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Bangur Hospital, Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan , Rajasthan-306401,Government Medical College and Bangur Hospital Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan Ra, Rajasthan, 306401",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b75"
    },
    "Rank": 21371,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b76"
    },
    "Rank": 21374,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b77"
    },
    "Rank": 21379,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b78"
    },
    "Rank": 21382,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b79"
    },
    "Rank": 21385,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b7a"
    },
    "Rank": 21386,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b7b"
    },
    "Rank": 21388,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Kamakshi Memorial Hospital, (Dr. Kamakshi Institute of Medical Sciences and Research) ,No.1,Radial Road , Pallikaranai, Chennai, Tamil Nadu, 600100",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b7c"
    },
    "Rank": 21390,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b7d"
    },
    "Rank": 21391,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital , ,GMERS MEDICAL COLLEGE , Hadiyol-Gadhoda Road, Near-Government Polytechnic, Hiimmatnagar, Gujarat, 383001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b7e"
    },
    "Rank": 21392,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b7f"
    },
    "Rank": 21393,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b80"
    },
    "Rank": 21396,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.G. Hospital and PG Medical Institute ,,No:5, Arts College Road, Coimbatore - 641018, Tamil Nadu, 641018",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b81"
    },
    "Rank": 21397,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medica Superspecialty Hospital, ,127 Mukundapur, E.M. Byapss, Kolkata, West Bengal, 700099",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b82"
    },
    "Rank": 21398,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b83"
    },
    "Rank": 21401,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "N S Memorial Institute of Medical Sciences ,N S Memorial Institute of Medical Sciences Palathara, Thattamala, Kollam, Kerala, 691020",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b84"
    },
    "Rank": 21402,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Ramesh Cardiac and Multi Speciality Hospital,,Collector office road, Nagarampalem, Guntur , AP, Andhra Pradesh, 522004",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b85"
    },
    "Rank": 21405,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Polakulath Narayanan Renai Medicity Multi Super Specialty Hospital,,RENAIMEDICITY PALARIVATTOM KOCHI KERALA, Kerala, 682025",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b86"
    },
    "Rank": 21407,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b87"
    },
    "Rank": 21410,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b88"
    },
    "Rank": 21413,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b89"
    },
    "Rank": 21415,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b8a"
    },
    "Rank": 21426,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Hospital, ,ALEXANDER ROAD, BESIDE HARIHARA KALABHAVAN, NEAR CLOCK TOWER, SECUNDERBAD, Telangana, 500003",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b8b"
    },
    "Rank": 21431,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b8c"
    },
    "Rank": 21436,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, ,1,2, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b8d"
    },
    "Rank": 21440,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b8e"
    },
    "Rank": 21443,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b8f"
    },
    "Rank": 21446,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b90"
    },
    "Rank": 21448,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b91"
    },
    "Rank": 21449,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Martha`s Hospital , ,No.5, Nrupathunga Road, Bengaluru, Karnataka, 560001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b92"
    },
    "Rank": 21451,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b93"
    },
    "Rank": 21452,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sadhu Vaswani Missions ,7-9 Lane No 1 Koregaon Park Pune 411001, Maharashtra, 411001",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b94"
    },
    "Rank": 21453,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b95"
    },
    "Rank": 21454,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Health City, ,Apollo Hospitals, Health City, Chinagadhili, Visakhapatnam, Andhra Pradesh, 530040",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b96"
    },
    "Rank": 21455,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asian Institute of Medical Sciences,,Asian Institute of Medical Sciences, Badhkal Flyover Road,Sector-21A, Faridabad, Haryana-121001, Haryana, 121001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b97"
    },
    "Rank": 21456,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b98"
    },
    "Rank": 21460,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Surya Children`s Medicare, (Formerly Surya Children`s Hospital) ,S. V. Road, Santacruz West Mumbai, Maharashtra, 400054",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b99"
    },
    "Rank": 21463,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b9a"
    },
    "Rank": 21469,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vijaya Hospital, ,New No 434, Old No 180, NSK Salai, Vadapalani, Chennai - 600026, Tamil Nadu, 600026",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b9b"
    },
    "Rank": 21470,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC MEDICAL COLLEGE, JOKA,ESI-PGIMSR & ESIC MEDICAL COLLEGE, JOKA, KOLKATA, WEST BENGAL, West Bengal, 700104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b9c"
    },
    "Rank": 21471,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jagjivan Ram Railway Hospital,,Maratha Mandir Marg, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b9d"
    },
    "Rank": 21472,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Jeyasekharan Hospital and Nursing Home, ,Dr.Jeyasekharan Hospital and Nursing Home,\nK.P.Road, Nagercoil, Kanyakumari District, Tamil Nadu, 629003",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b9e"
    },
    "Rank": 21474,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05b9f"
    },
    "Rank": 21475,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ba0"
    },
    "Rank": 21476,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ba1"
    },
    "Rank": 21479,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Sector-62, Phase VIII, Mohali- 160062, Punjab, 160062",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ba2"
    },
    "Rank": 21489,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ba3"
    },
    "Rank": 21490,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Down Town Hospital, G S Road, ,G S Road, Dispur, Guwahati-781006, Assam, 781006",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ba4"
    },
    "Rank": 21491,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Head Quarters Hospital,,District Hospital, Sangareddy Telangana State, Telangana, 502001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ba5"
    },
    "Rank": 21493,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ba6"
    },
    "Rank": 21496,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Batra Hospital and Medical Research Centre, ,1, Tughlakabad institutional Area, Mehrauli Badarpur Road, New Delhi, Delhi (NCT), 110062",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ba7"
    },
    "Rank": 21500,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rabindranath Tagore International Institute of Cardiac Sciences, ,PREMISES NO. 1489 124 MUKUNDAPUR  E M BYPASS  KOLKATA-700099, West Bengal, 700099",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ba8"
    },
    "Rank": 21502,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ba9"
    },
    "Rank": 21506,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "YATHARTH SUPER SPECIALTY HOSPITALS,\nUttar Pradesh,Sector omega 01-, Greater noida, Uttar Pradesh, 201308",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05baa"
    },
    "Rank": 21507,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences, ,academics@kimshospitals.com, Telangana, 500003",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bab"
    },
    "Rank": 21511,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S.K.Hospital, ,SUPERINTENDENT RNO 36  GOVT SK HOSPITAL  SILVER JUBLI ROAD  SIKAR  RAJ, Rajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bac"
    },
    "Rank": 21512,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KMC Specialty Hospital, ,No-6, Royal Road, Cantonment, Trichy-620001, Tamil Nadu, 620001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bad"
    },
    "Rank": 21514,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHAS NATIONAL INSTITUTE OF SPORTS, PATIALA,NETAJI SUBHAS NATIONAL INSTITUTE OF SPORTS, OLD MOTIBAGH, PATIALA, Punjab, 147001",
    "Course": "DIP. IN SPORTS MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bae"
    },
    "Rank": 21516,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05baf"
    },
    "Rank": 21519,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SunShine Hospital, ,1-7-201 TO 205\nPG Road, Paradise, Secunderabad, Telangana, 500003",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bb0"
    },
    "Rank": 21522,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bb1"
    },
    "Rank": 21527,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bb2"
    },
    "Rank": 21530,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaipur Golden Hospital, ,2 INSTITUTIONAL AREA, SECTOR-3, ROHINI, Delhi (NCT), 110085",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bb3"
    },
    "Rank": 21531,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bb4"
    },
    "Rank": 21534,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata Shrimati Devendra Kumari Singhdeo Government Medical College, Ambikapur,Kanyaparisar  Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bb5"
    },
    "Rank": 21536,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital, ,NO 1 RAMAMOORTHY ROAD VIRUDHUNAGAR, Tamil Nadu, 626001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bb6"
    },
    "Rank": 21539,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Childrens Hospital and BirthRight, Telangana,Hno 3 7 222 223 sy no 51 to 54 opposit kharkana police station kaskhana main road kakaguda hyderabad, Telangana, 500009",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bb7"
    },
    "Rank": 21540,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bb8"
    },
    "Rank": 21541,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bb9"
    },
    "Rank": 21542,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medicover Hospitals, (Formerly MaxCure Hospital) ,Medicover Hospitals , Opposite Cyber gate way , Ibis hotel Lane , hitech city\nMadhapur Hyderabad, Telangana, 500081",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bba"
    },
    "Rank": 21551,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine and JNM Hospital, WBUHS,Block A, Post Office Kalyani, Near Silpanchal Station,\nBlock A2, District Nadia, West Bengal, West Bengal, 741235",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bbb"
    },
    "Rank": 21552,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bbc"
    },
    "Rank": 21558,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gunam Super Speciality Hospital,No 35, Tank Street, Opp To Govt Hospital, Denkanikottai Road, Hosur, Krishnagiri Dist., Tamil Nadu, 635109",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bbd"
    },
    "Rank": 21559,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Muthoot Health Care, ,Muthoot Healthcare, College Road, Kozhencherry, Pathanamthitta District, Kerala, 689641",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bbe"
    },
    "Rank": 21561,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "VCSG Govt Institute of Medical Science and Research and HNB Base Teaching Hospital\n, ,Srikot Ganganali Srinagar Garhwal Uttarakhand, Uttarakhand, 246174",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bbf"
    },
    "Rank": 21562,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bc0"
    },
    "Rank": 21563,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Star Hospital,,Star Hospitals, 8-2-596/5, Road. No. 10,\nBanjara Hills, Hyderabad, Telangana, 500034",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bc1"
    },
    "Rank": 21564,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bc2"
    },
    "Rank": 21569,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences, ,academics@kimshospitals.com, Telangana, 500003",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bc3"
    },
    "Rank": 21577,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees' State Insurance Corporation Medical College & Hospital, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD GULBARGA, Karnataka, 585106",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bc4"
    },
    "Rank": 21578,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "DIP. IN T.B. and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bc5"
    },
    "Rank": 21580,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "AUTONOMOUS STATE MEDICAL COLLEGE, ,RAJARSHI DASHRATH AUTONOMOUS STATE MEDICAL COLLEGE VILL- GANJA, POST- DABHA SEMAR, DISTRICT- AYODHYA, Uttar Pradesh, 224133",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bc6"
    },
    "Rank": 21582,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Anand Charitable Trusts Anand Hospital, Maharashtra,Anand Hospital, Phulewadi Road, Vaijapur Dist. Aurangabad, Maharashtra, 423701",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bc7"
    },
    "Rank": 21583,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bc8"
    },
    "Rank": 21584,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, ,1,2, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bc9"
    },
    "Rank": 21585,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences, ,academics@kimshospitals.com, Telangana, 500003",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bca"
    },
    "Rank": 21586,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bcb"
    },
    "Rank": 21588,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bcc"
    },
    "Rank": 21590,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Daga Memorial Women Government Hospital Nagpur, ,Ganjakhet gandhibagh nagpur, Maharashtra, 440008",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bcd"
    },
    "Rank": 21594,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Martha`s Hospital , ,No.5, Nrupathunga Road, Bengaluru, Karnataka, 560001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bce"
    },
    "Rank": 21597,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine & Sagore Dutta Hospital,College of Medicine & Sagore Dutta Hospital, West Bengal, 700058",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bcf"
    },
    "Rank": 21598,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Hurkisondas Nurrotumdas Hospital and Research Centre, ,Prarthana Samaj, Raja Ram Mohan Roy road,\nGirgaon, MUMBAI, Maharashtra, 400004",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bd0"
    },
    "Rank": 21599,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bd1"
    },
    "Rank": 21604,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,SECTOR-6, PANCHKULA, HARYANA-134108, Haryana, 134108",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bd2"
    },
    "Rank": 21613,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bd3"
    },
    "Rank": 21614,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, THOOTHUKUDI,GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, 3rd\nMile,Kamaraj Nagar,Thoothukudi., Tamil Nadu, 628008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bd4"
    },
    "Rank": 21615,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bd5"
    },
    "Rank": 21617,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT DHARMAPURI MEDICAL COLLEGE, DHARMAPURI, TAMIL NADU,GOVERNMENT DHARMAPURI MEDICAL COLLEGE, NETHAJI BYE PASS ROAD, DHARMAPURI, TAMIL NADU, Tamil Nadu, 636701",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bd6"
    },
    "Rank": 21619,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Advanced Medical Research Institute (AMRI) Hospital,,AMRI Hospitals, Bhubaneswar Plot no-1, Besides Satya Sai Enclave, Khandagiri, Bhubaneswar, Odisha, 751030",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bd7"
    },
    "Rank": 21623,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Smart Super Specialty Hospital, (Formerly Known as Saket City Hospital), ,Mandir Marg, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bd8"
    },
    "Rank": 21624,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.G. Hospital and PG Medical Institute ,,No:5, Arts College Road, Coimbatore - 641018, Tamil Nadu, 641018",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bd9"
    },
    "Rank": 21625,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Escorts Hospital, ,214, Jawahar Lal Nehru Marg, Malviya Nagar, Jaipur, Rajasthan, 302017",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bda"
    },
    "Rank": 21627,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, A Unit of Hometrail Buildtech Pvt Ltd. ,Near Civil Hospital, Phase-6, Mohali, Punjab-160055, Punjab, 160055",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bdb"
    },
    "Rank": 21629,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bdc"
    },
    "Rank": 21630,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine & Sagore Dutta Hospital,College of Medicine & Sagore Dutta Hospital, West Bengal, 700058",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bdd"
    },
    "Rank": 21631,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bde"
    },
    "Rank": 21632,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chandra Mother and Eye Care Pvt Ltd,\nUttar Pradesh,Chandra Tower TC-13 V-III Vibhuti Khand gomti Nagar Lucknow, Uttar Pradesh, 226010",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bdf"
    },
    "Rank": 21637,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05be0"
    },
    "Rank": 21644,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05be1"
    },
    "Rank": 21648,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05be2"
    },
    "Rank": 21649,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lilavati Hospital and Research Centre, ,Lilavati Hospital And Research Centre A-791, Bandra Reclamation, Bandra - West, Mumbai, Maharashtra, 400050",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05be3"
    },
    "Rank": 21650,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jagjivan Ram Railway Hospital,,Maratha Mandir Marg, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05be4"
    },
    "Rank": 21653,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital International, ,Plot 1A, GIDC Estate, Bhat Gandhinagar, Gujarat, 382428",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05be5"
    },
    "Rank": 21654,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05be6"
    },
    "Rank": 21657,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,Nalgonda X Roads, Malakpet, Hyderabad, Telangana, 500036",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05be7"
    },
    "Rank": 21658,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05be8"
    },
    "Rank": 21660,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05be9"
    },
    "Rank": 21664,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.G. Hospital and PG Medical Institute ,,No:5, Arts College Road, Coimbatore - 641018, Tamil Nadu, 641018",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bea"
    },
    "Rank": 21666,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Hospital,,Government Hospital Gandhi Nagar Jammu, Jammu And Kashmir, 180004",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05beb"
    },
    "Rank": 21667,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shaheed Hasan Khan Mewati Government Medical College , ,Shaheed Hasan Khan Mewati Government Medical College Nalhar Nuh, Haryana, 122107",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bec"
    },
    "Rank": 21668,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bed"
    },
    "Rank": 21670,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Muthoot Health Care, ,Muthoot Healthcare, College Road, Kozhencherry, Pathanamthitta District, Kerala, 689641",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bee"
    },
    "Rank": 21671,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bef"
    },
    "Rank": 21673,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gvernment Medical College,Haldwani,Government Medical College, Haldwani, Uttarakhand, 263139",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bf0"
    },
    "Rank": 21676,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bf1"
    },
    "Rank": 21680,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medivision Eye and Health Care Centre, ,10-3-304/1 to 4, Indira Sadan,Humayun Nagar, Near NMDC, Hyderabad., Telangana, 500028",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bf2"
    },
    "Rank": 21681,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "NEMCARE Hospital,,G.S.Road, Bhangagarh, Guwahati, Assam, 781005",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bf3"
    },
    "Rank": 21686,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Medical College and Chhatrapati Shivaji Maharaj Hospital, ,RAJIV GANDHI MEDICAL COLLEGE,THANE BELAPUR ROAD, KALWA, THANE, Maharashtra, 400605",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bf4"
    },
    "Rank": 21687,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bf5"
    },
    "Rank": 21690,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bf6"
    },
    "Rank": 21691,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dhir Hospital,\n,DHIR HOSPITAL MEHAM GATE BHIWANI HARYANA 127021, Haryana, 127021",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bf7"
    },
    "Rank": 21698,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bf8"
    },
    "Rank": 21699,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bf9"
    },
    "Rank": 21705,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Mission Hospital, West Bengal\n,The Mission Hospital, 219 P, Immon Kalyan Sarani, Sector-2C, Bidhannagar, Durgapur, Dist- Paschim Ba, West Bengal, 713212",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bfa"
    },
    "Rank": 21710,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaipur Golden Hospital, ,2 INSTITUTIONAL AREA, SECTOR-3, ROHINI, Delhi (NCT), 110085",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bfb"
    },
    "Rank": 21712,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bfc"
    },
    "Rank": 21713,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Hospital, ,ALEXANDER ROAD, BESIDE HARIHARA KALABHAVAN, NEAR CLOCK TOWER, SECUNDERBAD, Telangana, 500003",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bfd"
    },
    "Rank": 21714,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bfe"
    },
    "Rank": 21716,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. L H Hiranandani Hospital, ,HILL SIDE AVENUE, HIRANANDANI GARDENS, POWAI-400076, Maharashtra, 400076",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05bff"
    },
    "Rank": 21717,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c00"
    },
    "Rank": 21723,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tirumala Hospital, ,PSR COMPLEX, NEAR RTC COMPLEX, VIZIANAGARAM, Andhra Pradesh, 535003",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c01"
    },
    "Rank": 21725,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GURUKRIPA HOSPITALS ,JYOTI NAGAR PIPRALI ROAD SIKAR RAJASTHAN,\nRajasthan, 332001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c02"
    },
    "Rank": 21727,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "People Tree Hospital, ,No.02, Tumkur Rd, Next to Yeshwanthpur Metro Stop, Goraguntepalya, Yeswanthpur, Bengaluru, Karnataka, Karnataka, 560022",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c03"
    },
    "Rank": 21733,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sagar Hospital - ,Shavige Malleshwara Hills, Kumaraswamy Layout, Bangalore, Karnataka, 560078",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c04"
    },
    "Rank": 21734,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c05"
    },
    "Rank": 21739,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,APOLLO HOSPITALS BANGALORE A UNIT OF IMPERIAL HOSPITALS AND RESEARCH CENTRE LIMITED 154/11 Opp IIM B, Karnataka, 560076",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c06"
    },
    "Rank": 21741,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "JLNM Hospital, ,JLNM Hospital Rainawari Srinagar, Jammu And Kashmir, 190003",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c07"
    },
    "Rank": 21744,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,APOLLO HOSPITALS BANGALORE A UNIT OF IMPERIAL HOSPITALS AND RESEARCH CENTRE LIMITED 154/11 Opp IIM B, Karnataka, 560076",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c08"
    },
    "Rank": 21747,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c09"
    },
    "Rank": 21751,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c0a"
    },
    "Rank": 21752,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c0b"
    },
    "Rank": 21756,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. L H Hiranandani Hospital, ,HILL SIDE AVENUE, HIRANANDANI GARDENS, POWAI-400076, Maharashtra, 400076",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c0c"
    },
    "Rank": 21758,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "AIG Hospital,Door No: 1 66 AIG 2 to 5 Mindspace Road  Gachibowli Hyderabad\nTelangana 500032",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c0d"
    },
    "Rank": 21759,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sarji Hospital, ,RMR RAOD PARK EXTENSION sHIVAMOGGA-577201, Karnataka, 577201",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c0e"
    },
    "Rank": 21760,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Rela Institute and Medical Centre ,\nTamil Nadu,NO.7, CLC WORKS ROAD, CHROMPET CHENNAI, Tamil Nadu, 600044",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c0f"
    },
    "Rank": 21762,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS Medical College Valsad,Nanakwada Halar Road,Valsad ,Gujarat, Gujarat, 396001",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c10"
    },
    "Rank": 21763,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pandit Madan Mohan Malaviya Shatabdi Hospital, Maharashtra,Pandit  Madan Mohan Malaviya  Shatabdi Hospital\nWaman Tukaram Patil Marg Govandi Mumbai 400088, Maharashtra, 400088",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c11"
    },
    "Rank": 21765,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Ramesh Cardiac and Multi Speciality Hospital,,Collector office road, Nagarampalem, Guntur , AP, Andhra Pradesh, 522004",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c12"
    },
    "Rank": 21767,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c13"
    },
    "Rank": 21768,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Smart Super Specialty Hospital, (Formerly Known as Saket City Hospital), ,Mandir Marg, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c14"
    },
    "Rank": 21770,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Sathya Sai Institute of Higher Medical Sciences, ,PRASANTHIGRAM , PUTTAPARTHI\nANANTAPUR, Andhra Pradesh, 515134",
    "Course": "(NBEMS) Cardio Vascular and Thoracic Surgery (Direct 6 Years Course)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c15"
    },
    "Rank": 21779,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c16"
    },
    "Rank": 21782,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c17"
    },
    "Rank": 21785,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rangadore Memorial Hospital,,1ST CROSS, SHANKARAPURAM, BANGALORE, Karnataka, 560004",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c18"
    },
    "Rank": 21786,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, ,1,2, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c19"
    },
    "Rank": 21787,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c1a"
    },
    "Rank": 21788,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c1b"
    },
    "Rank": 21793,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Kamakshi Memorial Hospital, (Dr. Kamakshi Institute of Medical Sciences and Research) ,No.1,Radial Road , Pallikaranai, Chennai, Tamil Nadu, 600100",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c1c"
    },
    "Rank": 21794,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c1d"
    },
    "Rank": 21799,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gunam Super Speciality Hospital,No 35, Tank Street, Opp To Govt Hospital, Denkanikottai Road, Hosur, Krishnagiri Dist., Tamil Nadu, 635109",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c1e"
    },
    "Rank": 21800,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c1f"
    },
    "Rank": 21802,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c20"
    },
    "Rank": 21803,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Raos ENT Super Speciality Hospitals, Hyderabad , Telangana,PLOT NO -29,KPHB COLONY BEHIND TEMPLE BUS STOP ,HYDERABAD,TELANGANA, Telangana, 500072",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c21"
    },
    "Rank": 21804,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lilavati Hospital and Research Centre, ,Lilavati Hospital And Research Centre A-791, Bandra Reclamation, Bandra - West, Mumbai, Maharashtra, 400050",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c22"
    },
    "Rank": 21805,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,RAJBHAVAN ROAD SOMAJIGUDA, Telangana, 500082",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c23"
    },
    "Rank": 21807,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bettiah,Government Medical College Bettiah West Champaran Bihar, Bihar, 845438",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c24"
    },
    "Rank": 21808,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo BGS Hospital,,Apollo BGS Hospitals, Adhichunchangiri Road, Kuvempunagar, Mysore, Karnataka, 570023",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c25"
    },
    "Rank": 21816,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "PRS Hospital,,PRS HOSPITAL PVT LTD KILLIPALAM THIRUVANATHAPURAM\nKERALA. <academics@prshospital.com>, Kerala, 695002",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c26"
    },
    "Rank": 21818,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c27"
    },
    "Rank": 21821,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c28"
    },
    "Rank": 21823,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Goutami Eye Institute,1, RV Nagar, Korukonda Road, Rajahmundry, East Godavari District, Andhra Pradesh, Andhra Pradesh, 533105",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c29"
    },
    "Rank": 21825,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c2a"
    },
    "Rank": 21826,
    "Allotted Quota": "All India",
    "Allotted Institute": "GADAG INSTITUTE OF MEDICAL SCIENCES GADAG,Gadag Institute of Medical Sciences Mallasamudra Gadag-582103, Karnataka, 582103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c2b"
    },
    "Rank": 21827,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College,Fakhruddin Ali Ahmed Medical College, Assam, 781301",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c2c"
    },
    "Rank": 21831,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences, ,academics@kimshospitals.com, Telangana, 500003",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c2d"
    },
    "Rank": 21834,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ashoka Medicover Hospital, ,Ashoka Medicover Hospitals, Indira Nagar, Opposite to WNS, Wadala Nashik, Maharashtra, 422009",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c2e"
    },
    "Rank": 21838,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c2f"
    },
    "Rank": 21857,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Philomena`s Hospital, ,No.4, Campbell Road, Viveknagar P.O. Bangalore - 560047, Karnataka, 560047",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c30"
    },
    "Rank": 21860,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c31"
    },
    "Rank": 21861,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c32"
    },
    "Rank": 21862,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c33"
    },
    "Rank": 21864,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c34"
    },
    "Rank": 21867,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kiran Multi Super Speciality Hospital and Research Center, ,Kiran Multi Super Speciality Hospital Centre,\nNear Sumul Dairy, Vastadevdi Road, Surat, Gujarat, 395004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c35"
    },
    "Rank": 21872,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "DIP.IN GYNAE. and OBST.",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c36"
    },
    "Rank": 21875,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishnakumar Orthopedic Hospital, ,15 82 E Overbridge Parvathipuram\nNagercoil\nKanyakumari District, Tamil Nadu, 629003",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c37"
    },
    "Rank": 21877,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata Shrimati Devendra Kumari Singhdeo Government Medical College, Ambikapur,Kanyaparisar  Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c38"
    },
    "Rank": 21879,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B L Kapur Memorial Hospital,Pusa Road New Delhi, Delhi (NCT), 110005",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c39"
    },
    "Rank": 21884,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c3a"
    },
    "Rank": 21886,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c3b"
    },
    "Rank": 21890,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c3c"
    },
    "Rank": 21891,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c3d"
    },
    "Rank": 21893,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c3e"
    },
    "Rank": 21898,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c3f"
    },
    "Rank": 21902,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Santokbha Durlabhji Memorial Hospital Cum Medical Research Institute,\n,dnb.sdmh@gmail.com, Rajasthan, 302015",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c40"
    },
    "Rank": 21908,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c41"
    },
    "Rank": 21909,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c42"
    },
    "Rank": 21912,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c43"
    },
    "Rank": 21914,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KIMS Hospital,,#1-112 / 86, Survey No 5 / EE, beside Union Bank, near RTA Office, Kondapur, Hyderabad,Telangana, Telangana, 500084",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c44"
    },
    "Rank": 21917,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manik Hospital, Aurangabad Maharashtra,\nMaharashtra,Manik Hospital P 148 Opp Kamgar Kalyan bhawan near Motadevi mandir Bajaj nagar MIDC Waluj Aurangabad, Maharashtra, 431136",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c45"
    },
    "Rank": 21925,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c46"
    },
    "Rank": 21926,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c47"
    },
    "Rank": 21931,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Poonam Palaspagar - Academic Coordinator\nFortis Hospital, Bannerghatta Road, Bangalore, Karnataka 5, Karnataka, 560076",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c48"
    },
    "Rank": 21933,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Udai Omni Hospital, ,#5-9-92/A/1, Telangana, 500001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c49"
    },
    "Rank": 21935,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Surya Mother and Child Superspeciality Hospital, ,Surya Mother and Child Super Speciality Hospital     Sr. No 8 Devi Yash , Near Octroi Post Wakad, Pu, Maharashtra, 411057",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c4a"
    },
    "Rank": 21937,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c4b"
    },
    "Rank": 21941,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lilavati Hospital and Research Centre, ,Lilavati Hospital And Research Centre A-791, Bandra Reclamation, Bandra - West, Mumbai, Maharashtra, 400050",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c4c"
    },
    "Rank": 21942,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c4d"
    },
    "Rank": 21944,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c4e"
    },
    "Rank": 21946,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c4f"
    },
    "Rank": 21948,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VILLUPURAM MEDICAL COLLEGE, VILLUPURAM,GOVERNMENT VILLUPURAM MEDICAL COLLEGE VILLUPURAM  TRICHY TRUNK ROAD MUNDIYAMPAKKAM  VILLUPURAM DISTR, Tamil Nadu, 605601",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c50"
    },
    "Rank": 21953,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c51"
    },
    "Rank": 21954,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Parsik Hill Road, ,APOLLO HOSPITAL PARSIK HILL ROAD ROAD NO 13 SECTOR 23 CBD BELAPUR NAVI MUMBAI, Maharashtra, 400614",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c52"
    },
    "Rank": 21958,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c53"
    },
    "Rank": 21960,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c54"
    },
    "Rank": 21974,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Continental Hospital, ,Continental Hospitals Pvt Ltd., Plot No.3, Road No.2, IT & Financial District, Nanakramguda, Gachibo, Telangana, 500032",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c55"
    },
    "Rank": 21986,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sanjay Gandhi Postgarduate Institute of Medical Sciences,Sanjay Gandhi Postgarduate Institute of Medical Sciences, Uttar Pradesh, 226014",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c56"
    },
    "Rank": 21988,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c57"
    },
    "Rank": 21990,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. R. D. B. P. Jaipuria Hospital, (Attached with RUHS College of Medical Sciences),,GOVTRDBPJAIPURIAHOSPITALJAIPUR@GMAIL.COM, Rajasthan, 302018",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c58"
    },
    "Rank": 21991,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Hospital, ,ALEXANDER ROAD, BESIDE HARIHARA KALABHAVAN, NEAR CLOCK TOWER, SECUNDERBAD, Telangana, 500003",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c59"
    },
    "Rank": 21993,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Christian Institute of Health Sciences and Research, ,4th Mile, Central Jail Road, Dimapur, Nagaland, 797115",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c5a"
    },
    "Rank": 21998,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c5b"
    },
    "Rank": 22001,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Christian Institute of Health Sciences and Research, ,4th Mile, Central Jail Road, Dimapur, Nagaland, 797115",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c5c"
    },
    "Rank": 22002,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine and JNM Hospital, WBUHS,Block A, Post Office Kalyani, Near Silpanchal Station,\nBlock A2, District Nadia, West Bengal, West Bengal, 741235",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c5d"
    },
    "Rank": 22003,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shija Hospitals and Research Institute, ,SHIJA HOSPITALS AND RESEARCH INSTITUTE, LANGOL, LAMPHELPAT, IMPHAL WEST, MANIPUR 795004, Manipur, 795004",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c5e"
    },
    "Rank": 22009,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,Nalgonda X Roads, Malakpet, Hyderabad, Telangana, 500036",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c5f"
    },
    "Rank": 22014,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Madhukar Rainbow Childrens Hospital,,FC-29, Plot No.5, Geetanjali, Near Malviya Nagar Metro station gate no.1, Delhi (NCT), 110017",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c60"
    },
    "Rank": 22015,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Giridhar Eye Institute , ,Ponneth Temple Road Kadavanthra\nCochin, Kerala, 682020",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c61"
    },
    "Rank": 22021,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ERNAKULAM,GOVT MEDICAL COLLEGE, ERNAKULAM,\nKerala, 683503",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c62"
    },
    "Rank": 22031,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pandit Raghunath Murmu Medical College and Hospital , Orissa,AT-RANGAMATIA, PO- SHAMAKHUNTA, PS-BARIPADA SADAR, BARIPADA, MAYURBHANJ, Odisha, 757107",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c63"
    },
    "Rank": 22034,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Andhra Hospital (Vijayawada), ,CVR Complex, Governorpet, Vijayawada-520002, Krishna District, Andhra Pradesh, 520002",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c64"
    },
    "Rank": 22035,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "People Tree Hospital, ,No.02, Tumkur Rd, Next to Yeshwanthpur Metro Stop, Goraguntepalya, Yeswanthpur, Bengaluru, Karnataka, Karnataka, 560022",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c65"
    },
    "Rank": 22039,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,RAJBHAVAN ROAD SOMAJIGUDA, Telangana, 500082",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c66"
    },
    "Rank": 22041,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Life line Super Specialty Hospital, ,14th Mile, Melood P.O Adoor , Pathanamthitta Dist, Kerala, 691554",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c67"
    },
    "Rank": 22042,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c68"
    },
    "Rank": 22044,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c69"
    },
    "Rank": 22049,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Spirit Hospital, ,Mahakali Caves Road Andheri East Mumbai, Maharashtra, 400093",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c6a"
    },
    "Rank": 22056,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (Hospital Administration)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c6b"
    },
    "Rank": 22057,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. L H Hiranandani Hospital, ,HILL SIDE AVENUE, HIRANANDANI GARDENS, POWAI-400076, Maharashtra, 400076",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c6c"
    },
    "Rank": 22061,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c6d"
    },
    "Rank": 22065,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c6e"
    },
    "Rank": 22069,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c6f"
    },
    "Rank": 22071,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c70"
    },
    "Rank": 22075,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pimpri Chichwad Municipal Corporation Postgraduate Institute Y.C.M.Hospital Pimpri,Pune 18,Dean Office, Y.C.M.Hospital, Sant Tukaramnagar Pimpri, Pune, Maharashtra, 411018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c71"
    },
    "Rank": 22076,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shaheed Hasan Khan Mewati Government Medical College , ,Shaheed Hasan Khan Mewati Government Medical College Nalhar Nuh, Haryana, 122107",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c72"
    },
    "Rank": 22077,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Durgabai Deshmukh Hospital, ,OSMANIA UNIVERSITY ROAD, VIDYANAGAR, HYDERABAD, Telangana, 500044",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c73"
    },
    "Rank": 22079,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Naruvi Hospitals ,\nTamil Nadu,Naruvi Hospitals, A Unit of SANCO Foundation, T.S.No.72 1B2, Collector Office Road, Chennai- Bengalu, Tamil Nadu, 632004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c74"
    },
    "Rank": 22080,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c75"
    },
    "Rank": 22082,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagwan Mahaveer Jain Hospital, ,MILLERS ROAD, VASANTHNAGAR, BANGALORE, Karnataka, 560052",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c76"
    },
    "Rank": 22087,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SP FORT HOSPITAL, Kerala\n,FORT, ANAKOTTIL LANE, THIRUVANANTHAPURAM, Kerala, 695023",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c77"
    },
    "Rank": 22088,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c78"
    },
    "Rank": 22097,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c79"
    },
    "Rank": 22103,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c7a"
    },
    "Rank": 22106,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c7b"
    },
    "Rank": 22109,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c7c"
    },
    "Rank": 22113,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c7d"
    },
    "Rank": 22114,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sheth Vadilal Sarabhai General Hospital and Sheth Chinai Prasuti Hospital, Gujarat,SHETH VADILAL SARABHAI GENERAL HOSPITAL AND SHETH CHINAI PRASUTI HOSPITAL ELLISBRIDGE AHMEDABAD, Gujarat, 380006",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c7e"
    },
    "Rank": 22116,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c7f"
    },
    "Rank": 22118,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c80"
    },
    "Rank": 22121,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c81"
    },
    "Rank": 22123,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c82"
    },
    "Rank": 22127,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c83"
    },
    "Rank": 22130,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c84"
    },
    "Rank": 22133,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c85"
    },
    "Rank": 22136,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c86"
    },
    "Rank": 22138,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Institute of Nuclear Medicine & Allied Sciences,Institute of Nuclear Medicine & Allied Sciences, Delhi (NCT), 110054",
    "Course": "DIP IN RADIATION MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c87"
    },
    "Rank": 22143,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VILLUPURAM MEDICAL COLLEGE, VILLUPURAM,GOVERNMENT VILLUPURAM MEDICAL COLLEGE VILLUPURAM  TRICHY TRUNK ROAD MUNDIYAMPAKKAM  VILLUPURAM DISTR, Tamil Nadu, 605601",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c88"
    },
    "Rank": 22144,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c89"
    },
    "Rank": 22146,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c8a"
    },
    "Rank": 22147,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College, ,Office of the Dean, First Floor, Civil Hospital Campus, Sector- 12, Gandhinagar, Gujarat, 382016",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c8b"
    },
    "Rank": 22156,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Parsik Hill Road, ,APOLLO HOSPITAL PARSIK HILL ROAD ROAD NO 13 SECTOR 23 CBD BELAPUR NAVI MUMBAI, Maharashtra, 400614",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c8c"
    },
    "Rank": 22160,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c8d"
    },
    "Rank": 22162,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c8e"
    },
    "Rank": 22165,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,APOLLO HOSPITALS BANGALORE A UNIT OF IMPERIAL HOSPITALS AND RESEARCH CENTRE LIMITED 154/11 Opp IIM B, Karnataka, 560076",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c8f"
    },
    "Rank": 22166,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Moolchand Khairatiram Hospital and Ayurvedic Research Institute,,,Lajpat Nagar-III, New Delhi-110024, Delhi (NCT), 110024",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c90"
    },
    "Rank": 22167,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sree Uthradom Tirunal Hospital,,Pattom, Trivandrum, Kerala, 695004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c91"
    },
    "Rank": 22174,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c92"
    },
    "Rank": 22176,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "HEALTH CITY HOSPITAL , Assam\n,UDAY NAGAR KOINADHARA KHANAPARA GUWAHATI KAMRUP M, Assam, 781022",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c93"
    },
    "Rank": 22178,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District General Hospital, ,O/o. Medical Superintendent, Government District General Hospital, Contonment, Vizianagaram, Andhra Pradesh,\n535003",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c94"
    },
    "Rank": 22179,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c95"
    },
    "Rank": 22183,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Hospital, ,ALEXANDER ROAD, BESIDE HARIHARA KALABHAVAN, NEAR CLOCK TOWER, SECUNDERBAD, Telangana, 500003",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c96"
    },
    "Rank": 22184,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c97"
    },
    "Rank": 22187,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c98"
    },
    "Rank": 22188,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c99"
    },
    "Rank": 22193,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c9a"
    },
    "Rank": 22197,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SNR District Hospital,,SNR DISTRICT HOSPITAL, KOLAR KARNATAKA-563101,\nKarnataka, 563101",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c9b"
    },
    "Rank": 22202,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c9c"
    },
    "Rank": 22205,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c9d"
    },
    "Rank": 22206,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruban Memorial Hospital, Bihar,19 Patliputra Colony Near Patliputra Golambar Patna, Bihar, 800013",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c9e"
    },
    "Rank": 22209,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Vinoba Bhave Civil Hospital,,S R Delkar Marg, Saily Road, Silvassa, Dadra And Nagar Haveli, 396230",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05c9f"
    },
    "Rank": 22218,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ca0"
    },
    "Rank": 22221,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ca1"
    },
    "Rank": 22226,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chandra Mother and Eye Care Pvt Ltd,\nUttar Pradesh,Chandra Tower TC-13 V-III Vibhuti Khand gomti Nagar Lucknow, Uttar Pradesh, 226010",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ca2"
    },
    "Rank": 22230,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Anugraha Eye Hospital, ,NAVBHAG MAIN ROAD BEHIND CENTRAL BUS STAND NEAR SECAB COLLEGE VIJAYAPUR 586101, Karnataka, 586101",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ca3"
    },
    "Rank": 22234,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MAR SLEEVA MEDICITY PALAI, Kerala,Kezhuvankulam P.O., Cherpunkal, Kottayam, Kerala, 686584",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ca4"
    },
    "Rank": 22236,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Poonam Palaspagar - Academic Coordinator\nFortis Hospital, Bannerghatta Road, Bangalore, Karnataka 5, Karnataka, 560076",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ca5"
    },
    "Rank": 22240,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ca6"
    },
    "Rank": 22243,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kalpana Chawla Govt Medical College, ,Model Town, Karnal, Haryana, 132001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ca7"
    },
    "Rank": 22244,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ca8"
    },
    "Rank": 22248,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Daga Memorial Women Government Hospital Nagpur, ,Ganjakhet gandhibagh nagpur, Maharashtra, 440008",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ca9"
    },
    "Rank": 22256,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05caa"
    },
    "Rank": 22257,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cab"
    },
    "Rank": 22259,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cac"
    },
    "Rank": 22265,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medica Superspecialty Hospital, ,127 Mukundapur, E.M. Byapss, Kolkata, West Bengal, 700099",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cad"
    },
    "Rank": 22267,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees' State Insurance Corporation Medical College & Hospital, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD GULBARGA, Karnataka, 585106",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cae"
    },
    "Rank": 22270,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05caf"
    },
    "Rank": 22273,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cb0"
    },
    "Rank": 22283,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sahai Hospital and Research Centre, ,a-73 ,Bhabha Marg, Moti Doongri, Tilak Nagar, Jaipur 302004, Rajasthan, 302004",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cb1"
    },
    "Rank": 22284,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cb2"
    },
    "Rank": 22290,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cb3"
    },
    "Rank": 22294,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cb4"
    },
    "Rank": 22295,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cb5"
    },
    "Rank": 22296,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cb6"
    },
    "Rank": 22305,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shaheed Hasan Khan Mewati Government Medical College , ,Shaheed Hasan Khan Mewati Government Medical College Nalhar Nuh, Haryana, 122107",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cb7"
    },
    "Rank": 22310,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cb8"
    },
    "Rank": 22311,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cb9"
    },
    "Rank": 22316,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta The Medicity, ,Medanta The Medicity Sector 38 Gurgoan, Haryana, 122001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cba"
    },
    "Rank": 22320,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cbb"
    },
    "Rank": 22323,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Periyar Hospital, Tamil Nadu\n,GH road mayiladuthurai, Tamil Nadu, 609001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cbc"
    },
    "Rank": 22324,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cbd"
    },
    "Rank": 22325,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cbe"
    },
    "Rank": 22332,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cbf"
    },
    "Rank": 22335,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Kiran Institute of Ophthalmology, ,Srikiran Institute of Ophthalmology, D.No, 6-227, Penumarthy Road, Thimmapuram Village, Kakinada Rur, Andhra Pradesh, 533005",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cc0"
    },
    "Rank": 22336,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, ,1,2, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cc1"
    },
    "Rank": 22337,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cc2"
    },
    "Rank": 22338,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur and Associated Group of Hospitals Shri Haridev Joshi Hospital,,Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur, Rajasthan, 314001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cc3"
    },
    "Rank": 22343,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cc4"
    },
    "Rank": 22355,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cc5"
    },
    "Rank": 22359,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cc6"
    },
    "Rank": 22362,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cc7"
    },
    "Rank": 22364,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cc8"
    },
    "Rank": 22368,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, ,1,2, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cc9"
    },
    "Rank": 22369,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cca"
    },
    "Rank": 22370,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ccb"
    },
    "Rank": 22377,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES,CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, Chhattisgarh, 495001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ccc"
    },
    "Rank": 22378,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Balrampur Hospital, ,Gola Ganj, lucknow, Uttar Pradesh, 226018",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ccd"
    },
    "Rank": 22379,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Guru Gobind Singh Govt. Hospital, Delhi,F-Block, Raghubir Nagar, New Delhi, Delhi (NCT), 110027",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cce"
    },
    "Rank": 22380,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "AUTONOMOUS STATE MEDICAL COLLEGE, ,RAJARSHI DASHRATH AUTONOMOUS STATE MEDICAL COLLEGE VILL- GANJA, POST- DABHA SEMAR, DISTRICT- AYODHYA, Uttar Pradesh, 224133",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ccf"
    },
    "Rank": 22383,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agarsen Hospital, ,., Delhi (NCT), 110026",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cd0"
    },
    "Rank": 22386,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cd1"
    },
    "Rank": 22389,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Batra Hospital and Medical Research Centre, ,1, Tughlakabad institutional Area, Mehrauli Badarpur Road, New Delhi, Delhi (NCT), 110062",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cd2"
    },
    "Rank": 22390,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Down Town Hospital, G S Road, ,G S Road, Dispur, Guwahati-781006, Assam, 781006",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cd3"
    },
    "Rank": 22391,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Mission Hospital, West Bengal\n,The Mission Hospital, 219 P, Immon Kalyan Sarani, Sector-2C, Bidhannagar, Durgapur, Dist- Paschim Ba, West Bengal, 713212",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cd4"
    },
    "Rank": 22392,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cd5"
    },
    "Rank": 22396,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sahyadri Narayana Multispecialty Hospital, Harakere,,NT ROAD, HARAKERE , SHIMOGA, Karnataka, 577202",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cd6"
    },
    "Rank": 22399,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Area Hospital,\nAndhra Pradesh,NEAR OLD BUSTAND AREA HOSPITAL GUDUR, Andhra Pradesh, 524101",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cd7"
    },
    "Rank": 22403,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cd8"
    },
    "Rank": 22405,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cd9"
    },
    "Rank": 22407,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cda"
    },
    "Rank": 22408,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shalby Hospital, ,8TH Floor, shalby  hopsital , opp. karnwati club, sg highway ahmedabad, Gujarat, 380015",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cdb"
    },
    "Rank": 22411,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K S HOSPITAL, Karnataka,9-13-1270, M B DIWATAR NAGAR, HOSPET ROAD,\nKOPPAL, Karnataka, 583231",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cdc"
    },
    "Rank": 22414,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Gleneagles Hospital, ,58 Canal Circular Road Kolkata 700054, West Bengal, 700054",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cdd"
    },
    "Rank": 22417,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cde"
    },
    "Rank": 22420,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cdf"
    },
    "Rank": 22423,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ce0"
    },
    "Rank": 22424,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shree Jagannath Hospital and Research Centre, Jharkhand,BEHIND MACHHALI GHAR, NEAR RAJBHAWAN, BOOTY ROAD, THROUGH RADIUM ROAD, RANCHI-834001,\nJharkhand, 834001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ce1"
    },
    "Rank": 22425,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vishnuprasad Nandrai Desai Municipal General Hospital, Maharashtra,11 TH GOLIBAR ROAD TPS,SANTACRUZ EAST, Maharashtra, 400055",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ce2"
    },
    "Rank": 22427,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ce3"
    },
    "Rank": 22428,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Oriana Hospital,\nUttar Pradesh,6,7,8, Ravindrapuri Varanasi, Uttar Pradesh, 221005",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ce4"
    },
    "Rank": 22430,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ce5"
    },
    "Rank": 22437,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sakra World Hospital, (A Unit of Takshasila Hospitals Operating Private Limited)\n,LOVEKESH\\DOT\\PHASU\\AT\\SAKRAWORLDHOSPITAL\\DOT\\COM, Karnataka, 560103",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ce6"
    },
    "Rank": 22438,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S.N.M. Hospital , Ladakh,MEDICAL SUPERINTENDENT SNM HOSPITAL LEH UT LADAKH., Ladakh, 194101",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ce7"
    },
    "Rank": 22444,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ce8"
    },
    "Rank": 22452,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mata Chanan Devi Hospital,, ,C-1 JANAKPURI, Delhi (NCT), 110058",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ce9"
    },
    "Rank": 22458,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cea"
    },
    "Rank": 22462,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "JOSHI HOSPITAL,\nPunjab,JOSHI HOSPITAL KAPURTHALA CHOWK JALANDHAR PUNJAB 144001,\nPunjab, 144001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ceb"
    },
    "Rank": 22463,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cec"
    },
    "Rank": 22464,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Super Facility Hospital, Distt.-Azamgarh, Uttar Pradesh,Chakrapanpur, Kanaila, Azamgarh, Uttar Pradesh, 276128",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ced"
    },
    "Rank": 22470,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cee"
    },
    "Rank": 22472,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Superspecialty Hospital,,Junction of 2nd Hoogly Bridge & Andul Road, 120/1 Andul Road, West Bengal, 711103",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cef"
    },
    "Rank": 22475,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Specialty Hospital, ,NO. 320, PADMA COMPLEX, MOUNT ROAD, CHENNAI, Tamil Nadu, 600035",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cf0"
    },
    "Rank": 22476,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cf1"
    },
    "Rank": 22477,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cf2"
    },
    "Rank": 22480,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agrasen Medical College,,NH- 9, VPO - Agroha, District Hisar, Haryana, 125047",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cf3"
    },
    "Rank": 22484,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cf4"
    },
    "Rank": 22486,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital,,B-22, Sector 62, Noida, Uttar Pradesh, Uttar Pradesh, 201301",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cf5"
    },
    "Rank": 22487,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta The Medicity, ,Medanta The Medicity Sector 38 Gurgoan, Haryana, 122001",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cf6"
    },
    "Rank": 22488,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cf7"
    },
    "Rank": 22491,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cf8"
    },
    "Rank": 22492,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cf9"
    },
    "Rank": 22497,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruby Hall Clinic, ,GRANT MEDICAL FOUNDATION,\n40 SASSOON ROAD, PUNE 411001, Maharashtra, 411001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cfa"
    },
    "Rank": 22498,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cfb"
    },
    "Rank": 22499,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B L Kapur Memorial Hospital,Pusa Road New Delhi, Delhi (NCT), 110005",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cfc"
    },
    "Rank": 22500,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cfd"
    },
    "Rank": 22503,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cfe"
    },
    "Rank": 22504,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05cff"
    },
    "Rank": 22507,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d00"
    },
    "Rank": 22526,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "National Heart Institute, ,49-50, Community Centre, East of Kailash, Delhi (NCT), 110065",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d01"
    },
    "Rank": 22527,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d02"
    },
    "Rank": 22530,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S. K. S. Hospital, ,NO.23,SKS Hospital Road, Alagapuram,Salem, Tamil Nadu, 636004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d03"
    },
    "Rank": 22532,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "South Eastern Railways Central Hospital, ,CENTRAL HOSPITAL SOUTH EASTERN RAILWAY\n11 GARDEN REACH ROAD\nKOLKATA, West Bengal, 700043",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d04"
    },
    "Rank": 22536,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d05"
    },
    "Rank": 22537,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVERNMENT MEDICAL COLLEGE,KANYAKUMARI GOVERNMENT MEDICAL COLLEGE, Tamil Nadu, 629201",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d06"
    },
    "Rank": 22539,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KMC Specialty Hospital, ,No-6, Royal Road, Cantonment, Trichy-620001, Tamil Nadu, 620001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d07"
    },
    "Rank": 22542,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Kamakshi Memorial Hospital, (Dr. Kamakshi Institute of Medical Sciences and Research) ,No.1,Radial Road , Pallikaranai, Chennai, Tamil Nadu, 600100",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d08"
    },
    "Rank": 22549,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d09"
    },
    "Rank": 22550,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d0a"
    },
    "Rank": 22553,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital, B.H.Road, Tumakuru-572101, Karnataka, 572101",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d0b"
    },
    "Rank": 22558,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d0c"
    },
    "Rank": 22560,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d0d"
    },
    "Rank": 22563,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Polakulath Narayanan Renai Medicity Multi Super Specialty Hospital,,RENAIMEDICITY PALARIVATTOM KOCHI KERALA, Kerala, 682025",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d0e"
    },
    "Rank": 22564,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster CMI Hospital, ,43/2 New Airport Road, NH-7, Kodigehalli, Sahakara Nagar, Hebbal, Bengaluru, Karnataka, 560092",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d0f"
    },
    "Rank": 22568,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d10"
    },
    "Rank": 22570,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d11"
    },
    "Rank": 22576,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d12"
    },
    "Rank": 22580,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d13"
    },
    "Rank": 22583,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d14"
    },
    "Rank": 22588,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shree Narayana Hospital, ,Near Ganj Mandi, Behind Sector 5, Devendra Nagar, Pandri, Raipur, Chhattisgarh, 492001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d15"
    },
    "Rank": 22589,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d16"
    },
    "Rank": 22592,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d17"
    },
    "Rank": 22597,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d18"
    },
    "Rank": 22598,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Health City, ,Apollo Hospitals, Health City, Chinagadhili, Visakhapatnam, Andhra Pradesh, 530040",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d19"
    },
    "Rank": 22602,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d1a"
    },
    "Rank": 22603,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata Shrimati Devendra Kumari Singhdeo Government Medical College, Ambikapur,Kanyaparisar  Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d1b"
    },
    "Rank": 22605,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d1c"
    },
    "Rank": 22613,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d1d"
    },
    "Rank": 22616,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Gleneagles Hospital, ,58 Canal Circular Road Kolkata 700054, West Bengal, 700054",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d1e"
    },
    "Rank": 22629,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shija Hospitals and Research Institute, ,SHIJA HOSPITALS AND RESEARCH INSTITUTE, LANGOL, LAMPHELPAT, IMPHAL WEST, MANIPUR 795004, Manipur, 795004",
    "Course": "(NBEMS) Plastic and Reconstructive Surgery (Direct 6 Years Course)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d1f"
    },
    "Rank": 22631,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "C.S.I. Kalyani General Hospital,, ,15, DR RADHAKRISHNAN SALAI, MYLAPORE, CHENNAI, Tamil Nadu, 600004",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d20"
    },
    "Rank": 22638,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government General Hospital, Principal Medical Officer General hospital,\n,Rajiv Gandhi Government General Hospital, near bijli ghar circle, ALWAR, Rajasthan, 301001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d21"
    },
    "Rank": 22642,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d22"
    },
    "Rank": 22650,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d23"
    },
    "Rank": 22653,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Sathya Sai Institute of Higher Medical Sciences, ,PRASANTHIGRAM , PUTTAPARTHI\nANANTAPUR, Andhra Pradesh, 515134",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d24"
    },
    "Rank": 22657,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d25"
    },
    "Rank": 22659,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d26"
    },
    "Rank": 22662,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Sathya Sai Instt. of Higher Medical Sciences, ,EPIP AREA, WHITEFIELD, BANGALORE, Karnataka, 560066",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d27"
    },
    "Rank": 22669,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S. K. S. Hospital, ,NO.23,SKS Hospital Road, Alagapuram,Salem, Tamil Nadu, 636004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d28"
    },
    "Rank": 22670,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d29"
    },
    "Rank": 22671,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Ramesh Cardiac and Multi Speciality Hospital,,Collector office road, Nagarampalem, Guntur , AP, Andhra Pradesh, 522004",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d2a"
    },
    "Rank": 22673,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d2b"
    },
    "Rank": 22683,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. L H Hiranandani Hospital, ,HILL SIDE AVENUE, HIRANANDANI GARDENS, POWAI-400076, Maharashtra, 400076",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d2c"
    },
    "Rank": 22687,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Guru Gobind Singh Govt. Hospital, Delhi,F-Block, Raghubir Nagar, New Delhi, Delhi (NCT), 110027",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d2d"
    },
    "Rank": 22691,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d2e"
    },
    "Rank": 22695,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d2f"
    },
    "Rank": 22697,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaiprakash Hospital and Research Centre Pvt. Ltd, Odisha,,JAIPRAKASH HOSPITAL AND RESEARCH CENTER PVT. LTD., DANDIAPALI, ROURKELA, Odisha, 769004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d30"
    },
    "Rank": 22700,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d31"
    },
    "Rank": 22701,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ERNAKULAM,GOVT MEDICAL COLLEGE, ERNAKULAM,\nKerala, 683503",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d32"
    },
    "Rank": 22707,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d33"
    },
    "Rank": 22708,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d34"
    },
    "Rank": 22711,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d35"
    },
    "Rank": 22712,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "C.S.I. Kalyani General Hospital,, ,15, DR RADHAKRISHNAN SALAI, MYLAPORE, CHENNAI, Tamil Nadu, 600004",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d36"
    },
    "Rank": 22714,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d37"
    },
    "Rank": 22716,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Comtrust Eye Hospital, ,Mini Bypass Road, Puthiyara, Kozhikode., Kerala, 673004",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d38"
    },
    "Rank": 22720,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d39"
    },
    "Rank": 22724,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital, ,NO 1 RAMAMOORTHY ROAD VIRUDHUNAGAR, Tamil Nadu, 626001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d3a"
    },
    "Rank": 22731,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "APOLLO SPECTRA HOSPITALS ,H. No. 1-7-70 to 73, Mashallah Building, Paradise Circle, S. D Road, Secunderabad, Telangana, 500003",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d3b"
    },
    "Rank": 22741,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d3c"
    },
    "Rank": 22744,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESI Hospital, ,IG ESI HOSPITAL JHILMIL DELHI - 95, Delhi (NCT), 110095",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d3d"
    },
    "Rank": 22748,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Autonomous state medical College,\nUttar Pradesh,Village- Siraon, Marehara road, , Etah, Pincode- 207001, Uttar Pradesh, 207001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d3e"
    },
    "Rank": 22751,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,4th T Block, Beside Rajiv Gandhi University of Health Sciences Jayanagar,, Karnataka, 560041",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d3f"
    },
    "Rank": 22754,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d40"
    },
    "Rank": 22761,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital International, ,Plot 1A, GIDC Estate, Bhat Gandhinagar, Gujarat, 382428",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d41"
    },
    "Rank": 22763,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Meenakshi Mission Hospital and Research Centre, ,Lake Area, Melur Road, Madurai, Tamil Nadu, 625107",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d42"
    },
    "Rank": 22765,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rabindranath Tagore International Institute of Cardiac Sciences, ,PREMISES NO. 1489 124 MUKUNDAPUR  E M BYPASS  KOLKATA-700099, West Bengal, 700099",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d43"
    },
    "Rank": 22770,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) Palliative Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d44"
    },
    "Rank": 22771,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Kothari`s Eye Hospital,,Patel Circle, Udaipur, Rajasthan, 313001",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d45"
    },
    "Rank": 22772,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Balrampur Hospital, ,Gola Ganj, lucknow, Uttar Pradesh, 226018",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d46"
    },
    "Rank": 22773,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d47"
    },
    "Rank": 22775,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,RAJBHAVAN ROAD SOMAJIGUDA, Telangana, 500082",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d48"
    },
    "Rank": 22780,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d49"
    },
    "Rank": 22782,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Imambara District Hospital,,AKHANBAZAR ROAD, CHINSURA, HOOGHLY, West Bengal, 712101",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d4a"
    },
    "Rank": 22784,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vishnuprasad Nandrai Desai Municipal General Hospital, Maharashtra,11 TH GOLIBAR ROAD TPS,SANTACRUZ EAST, Maharashtra, 400055",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d4b"
    },
    "Rank": 22786,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d4c"
    },
    "Rank": 22788,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d4d"
    },
    "Rank": 22790,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d4e"
    },
    "Rank": 22793,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d4f"
    },
    "Rank": 22801,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d50"
    },
    "Rank": 22802,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Sathya Sai Institute of Higher Medical Sciences, ,PRASANTHIGRAM , PUTTAPARTHI\nANANTAPUR, Andhra Pradesh, 515134",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d51"
    },
    "Rank": 22804,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, Maharashtra,Morshi Road, Irwin Square Amravati, Maharashtra, 444601",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d52"
    },
    "Rank": 22807,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d53"
    },
    "Rank": 22808,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d54"
    },
    "Rank": 22810,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d55"
    },
    "Rank": 22812,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d56"
    },
    "Rank": 22816,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d57"
    },
    "Rank": 22817,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Sathya Sai Instt. of Higher Medical Sciences, ,EPIP AREA, WHITEFIELD, BANGALORE, Karnataka, 560066",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d58"
    },
    "Rank": 22818,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B. Nanavati Hospital, Vivekanand Rd,Swami Vivekananda Rd, near LIC, LIC Colony, Suresh Colony, Vile Parle West, Mumbai, Maharashtra 4000, Maharashtra, 400056",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d59"
    },
    "Rank": 22822,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital, B.H.Road, Tumakuru-572101, Karnataka, 572101",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d5a"
    },
    "Rank": 22824,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Poonam Palaspagar - Academic Coordinator\nFortis Hospital, Bannerghatta Road, Bangalore, Karnataka 5, Karnataka, 560076",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d5b"
    },
    "Rank": 22825,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Hurkisondas Nurrotumdas Hospital and Research Centre, ,Prarthana Samaj, Raja Ram Mohan Roy road,\nGirgaon, MUMBAI, Maharashtra, 400004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d5c"
    },
    "Rank": 22827,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d5d"
    },
    "Rank": 22828,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d5e"
    },
    "Rank": 22832,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Sector-62, Phase VIII, Mohali- 160062, Punjab, 160062",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d5f"
    },
    "Rank": 22833,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "J.L.N. Main Hospital and Research Centre, ,Bhilai Steel Plant Sector-9, Bhilai, C.G., Chhattisgarh, 490009",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d60"
    },
    "Rank": 22835,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d61"
    },
    "Rank": 22836,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d62"
    },
    "Rank": 22839,
    "Allotted Quota": "All India",
    "Allotted Institute": "All  India Institute of  Hygiene and Public Health,110, C. R  Avenue, Kolkata, West Bengal, 700073",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d63"
    },
    "Rank": 22843,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sakra World Hospital, (A Unit of Takshasila Hospitals Operating Private Limited)\n,LOVEKESH\\DOT\\PHASU\\AT\\SAKRAWORLDHOSPITAL\\DOT\\COM, Karnataka, 560103",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d64"
    },
    "Rank": 22844,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Associated Hospital Government Medical College, ,Kanth Bagh Baramulla, Jammu And Kashmir, 193103",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d65"
    },
    "Rank": 22845,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d66"
    },
    "Rank": 22849,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jothi Eye Care Centre, Pondicherry,jothieyecare@gmail.com, Puducherry, 605001",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d67"
    },
    "Rank": 22850,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d68"
    },
    "Rank": 22851,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d69"
    },
    "Rank": 22856,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bokaro General Hospital, ,SECTOR - 4 BOKARO STEEL CITY\nDIST - BOKARO, Jharkhand, 827004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d6a"
    },
    "Rank": 22860,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d6b"
    },
    "Rank": 22862,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d6c"
    },
    "Rank": 22868,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kalyani Institute of Medical Sciences,Alisaha, Joteram, Burdwan-713104, West Bengal, 713104",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d6d"
    },
    "Rank": 22871,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d6e"
    },
    "Rank": 22873,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d6f"
    },
    "Rank": 22878,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ispat General Hospital,,Ispat General Hospital Rourkela Steel Plant SAIL Rourkela 769005, Odisha, 769005",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d70"
    },
    "Rank": 22880,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d71"
    },
    "Rank": 22881,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Autonomous State Medical college Society and S.N.M District Hospital, ,DAULATTAPUR JALESAR ROAD NARKHI FIROZABAD, Uttar Pradesh, 283203",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d72"
    },
    "Rank": 22886,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shaheed Hasan Khan Mewati Government Medical College , ,Shaheed Hasan Khan Mewati Government Medical College Nalhar Nuh, Haryana, 122107",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d73"
    },
    "Rank": 22887,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "DIPLOMA IN ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d74"
    },
    "Rank": 22891,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d75"
    },
    "Rank": 22896,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d76"
    },
    "Rank": 22906,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d77"
    },
    "Rank": 22907,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mehta Multispecialty Hospital, (Formerly Known as Dr. Mehta`s Hospital) ,No.2, McNichols Road, 3 rd Lane, Chetpet, Chennai, Tamil Nadu, 600031",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d78"
    },
    "Rank": 22911,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Care Hospital,,Care Hospitals Road No1 Banjara Hills Hyderabad Telangana, Telangana, 500034",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d79"
    },
    "Rank": 22913,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d7a"
    },
    "Rank": 22915,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d7b"
    },
    "Rank": 22917,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d7c"
    },
    "Rank": 22920,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d7d"
    },
    "Rank": 22926,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vijaya Hospital, ,New No 434, Old No 180, NSK Salai, Vadapalani, Chennai - 600026, Tamil Nadu, 600026",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d7e"
    },
    "Rank": 22933,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d7f"
    },
    "Rank": 22935,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Doon Medical College, Dehradun,Govt. Doon Medical College Dehrakhas, Patel Nagar Dehradun 248001, Uttarakhand, 248001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d80"
    },
    "Rank": 22936,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d81"
    },
    "Rank": 22938,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Udai Omni Hospital, ,#5-9-92/A/1, Telangana, 500001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d82"
    },
    "Rank": 22943,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d83"
    },
    "Rank": 22947,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, (Formerly Columbia Asia Hospital),Survey No. 10P and 12P, Whitefield Main Rd, Varthur Kodi, Ramagondanahalli, Bengaluru, Karnataka, Karnataka, 560066",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d84"
    },
    "Rank": 22949,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d85"
    },
    "Rank": 22953,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d86"
    },
    "Rank": 22959,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College,Theni ,Government Medical College,Theni, Tamil Nadu, 625512",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d87"
    },
    "Rank": 22962,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d88"
    },
    "Rank": 22963,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kalyani Institute of Medical Sciences,Alisaha, Joteram, Burdwan-713104, West Bengal, 713104",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d89"
    },
    "Rank": 22971,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Naruvi Hospitals ,\nTamil Nadu,Naruvi Hospitals, A Unit of SANCO Foundation, T.S.No.72 1B2, Collector Office Road, Chennai- Bengalu, Tamil Nadu, 632004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d8a"
    },
    "Rank": 22972,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d8b"
    },
    "Rank": 22983,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d8c"
    },
    "Rank": 22984,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Zoi Hospital, (A unit of Panchvaid Healthcare Services Pvt. Ltd) #,Zoi Hospital, Beside HSBC Bank, Raj Bhavan Road, 500082, Telangana, 500082",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d8d"
    },
    "Rank": 22989,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Artemis Health Institute, ,Sector 51, Gurugram, Haryana -122001, Haryana, 122001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d8e"
    },
    "Rank": 22991,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Regency Hospital,,A-2, Sarvodaya Nagar, Kanpur, Uttar Pradesh, 208005",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d8f"
    },
    "Rank": 22992,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d90"
    },
    "Rank": 22994,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "DIP.IN GYNAE. and OBST.",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d91"
    },
    "Rank": 23000,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo KH Hospital, Tamil Nadu,Apollo KH Hospital, Ranipet - Vellore Bypass Road, Melvisharam, Ranipet District, Tamil Nadu., Tamil Nadu, 632509",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d92"
    },
    "Rank": 23001,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Gleneagles Hospital, ,58 Canal Circular Road Kolkata 700054, West Bengal, 700054",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d93"
    },
    "Rank": 23006,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d94"
    },
    "Rank": 23007,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dharan Hospital ,\nTamil Nadu,14 SEELANAICKENPATTY BYPASS SALEM 636201, Tamil Nadu, 636201",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d95"
    },
    "Rank": 23008,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deen Dayal Upadhyay Hospital, ,DDU HOSPITAL, SHAHEED MANGAL PANDEY MARG, NANAK PURA, HARI NAGAR, NEW DELHI, DELHI-110064, Delhi (NCT), 110064",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d96"
    },
    "Rank": 23010,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Swami Vivekanand National Institute of Rehabilitation Training and Research, ,Olatpur, PO Bairoi, Cuttack, Odisha, 754010",
    "Course": "(NBEMS) PHYSICAL\nMED. and REHABILITATION",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d97"
    },
    "Rank": 23011,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ADK Jain Eye Hospital, Uttar Pradesh,Pathshala Road\nKhekra\nBaghpat, Uttar Pradesh, 250101",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d98"
    },
    "Rank": 23013,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "HCG Cancer Centre,\nHoly cross road, Maharashtra,Holy Cross Road IC Colony Off Borival-Dahisar link road Borivali West Mumbai Maharashtra, Maharashtra, 400092",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d99"
    },
    "Rank": 23014,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruby Hall Clinic, ,GRANT MEDICAL FOUNDATION,\n40 SASSOON ROAD, PUNE 411001, Maharashtra, 411001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d9a"
    },
    "Rank": 23015,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d9b"
    },
    "Rank": 23016,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d9c"
    },
    "Rank": 23017,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d9d"
    },
    "Rank": 23020,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d9e"
    },
    "Rank": 23022,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Bangur Hospital, Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan , Rajasthan-306401,Government Medical College and Bangur Hospital Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan Ra, Rajasthan, 306401",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05d9f"
    },
    "Rank": 23023,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences Ltd, ,Dargamitta, Nellore, Andhra Pradesh, 524003",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05da0"
    },
    "Rank": 23025,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Civil Hospital, ,NIT, Opp. MCF Faridabad Haryana 121001, Haryana, 121001",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05da1"
    },
    "Rank": 23029,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05da2"
    },
    "Rank": 23030,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05da3"
    },
    "Rank": 23031,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05da4"
    },
    "Rank": 23032,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Public Health Centre,,174, LAKE VIEW ROAD WEST MAMBALAM\nCHENNAI - 600033, Tamil Nadu, 600033",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05da5"
    },
    "Rank": 23033,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster CMI Hospital, ,43/2 New Airport Road, NH-7, Kodigehalli, Sahakara Nagar, Hebbal, Bengaluru, Karnataka, 560092",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05da6"
    },
    "Rank": 23039,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05da7"
    },
    "Rank": 23041,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Diamond Harbour Government Medical College And Hospital, ,Harindanga, New Town, Word No- 4, P.O and P.S- Diamond Harbour, Dist- South 24 Parganas, West Bengal, West Bengal, 743331",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05da8"
    },
    "Rank": 23042,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Jeyasekharan Hospital and Nursing Home, ,Dr.Jeyasekharan Hospital and Nursing Home,\nK.P.Road, Nagercoil, Kanyakumari District, Tamil Nadu, 629003",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05da9"
    },
    "Rank": 23045,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05daa"
    },
    "Rank": 23046,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dab"
    },
    "Rank": 23050,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dac"
    },
    "Rank": 23052,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences, ,academics@kimshospitals.com, Telangana, 500003",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dad"
    },
    "Rank": 23054,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dae"
    },
    "Rank": 23055,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagwan Mahaveer Jain Hospital, ,MILLERS ROAD, VASANTHNAGAR, BANGALORE, Karnataka, 560052",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05daf"
    },
    "Rank": 23056,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05db0"
    },
    "Rank": 23059,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Park Hospital, Haryana,PARK HOSPITAL Q BLOCK SOUTH CITY II SOHNA ROAD SECTOR 47 GURGAON 122002, Haryana, 122002",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05db1"
    },
    "Rank": 23060,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05db2"
    },
    "Rank": 23062,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "G Kuppuswamy Naidu Memorial Hospital, ,Post Box No.6327, Nethaji Road, Pappanaickenpalayam, Coimbatore, Tamil Nadu, 641037",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05db3"
    },
    "Rank": 23064,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05db4"
    },
    "Rank": 23065,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sadhu Vaswani Missions ,7-9 Lane No 1 Koregaon Park Pune 411001, Maharashtra, 411001",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05db5"
    },
    "Rank": 23067,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05db6"
    },
    "Rank": 23068,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K C General Hospital, ,No. 89, 5th Cross, Malleshwaram, Bangalore, Karnataka, 560003",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05db7"
    },
    "Rank": 23081,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sadhu Vaswani Missions ,7-9 Lane No 1 Koregaon Park Pune 411001, Maharashtra, 411001",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05db8"
    },
    "Rank": 23083,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05db9"
    },
    "Rank": 23086,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dba"
    },
    "Rank": 23087,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dbb"
    },
    "Rank": 23088,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Baba Saheb Ambedkar Hospital, ,SEC 6 ROHINI DELHI, Delhi (NCT), 110085",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dbc"
    },
    "Rank": 23089,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Batra Hospital and Medical Research Centre, ,1, Tughlakabad institutional Area, Mehrauli Badarpur Road, New Delhi, Delhi (NCT), 110062",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dbd"
    },
    "Rank": 23091,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dbe"
    },
    "Rank": 23094,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Civil Hospital,Civil Hospital Barnala, Ram Bag Road, Barnala, Punjab, 148101",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dbf"
    },
    "Rank": 23096,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Meenakshi Mission Hospital and Research Centre, ,Lake Area, Melur Road, Madurai, Tamil Nadu, 625107",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dc0"
    },
    "Rank": 23099,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dc1"
    },
    "Rank": 23101,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "C.S.I. Kalyani General Hospital,, ,15, DR RADHAKRISHNAN SALAI, MYLAPORE, CHENNAI, Tamil Nadu, 600004",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dc2"
    },
    "Rank": 23102,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital for Women, Akola ,,District Hospital for Women  Near Durga Chowk Akola 444001, Maharashtra, 444001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dc3"
    },
    "Rank": 23103,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dc4"
    },
    "Rank": 23112,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Memorial Research Institute, ,Fortis memorial research institute, Sector 44, Opposite Huda City Centre Metro station, Gurgaon, Haryana, 122002",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dc5"
    },
    "Rank": 23115,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagwan Mahaveer Jain Hospital, ,MILLERS ROAD, VASANTHNAGAR, BANGALORE, Karnataka, 560052",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dc6"
    },
    "Rank": 23116,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dc7"
    },
    "Rank": 23117,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dc8"
    },
    "Rank": 23120,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Guru Gobind Singh Govt. Hospital, Delhi,F-Block, Raghubir Nagar, New Delhi, Delhi (NCT), 110027",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dc9"
    },
    "Rank": 23124,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dca"
    },
    "Rank": 23125,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dcb"
    },
    "Rank": 23133,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dcc"
    },
    "Rank": 23139,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dcd"
    },
    "Rank": 23148,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dce"
    },
    "Rank": 23155,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INSTITUTE OF MEDICAL SCIENCES,RAICHUR INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 584102",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dcf"
    },
    "Rank": 23159,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dd0"
    },
    "Rank": 23160,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dd1"
    },
    "Rank": 23161,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharashtra Post Graduate Institute of Medical Education and Research, Nashik,Maharashtra Post Graduation Institute Of Medical Education and Research Civil Hospital campus Trimba, Maharashtra, 422002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dd2"
    },
    "Rank": 23163,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dd3"
    },
    "Rank": 23166,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dd4"
    },
    "Rank": 23169,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dd5"
    },
    "Rank": 23171,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dd6"
    },
    "Rank": 23188,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dd7"
    },
    "Rank": 23190,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "DIPLOMA IN ORTHOPAEDICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dd8"
    },
    "Rank": 23193,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dd9"
    },
    "Rank": 23196,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dda"
    },
    "Rank": 23201,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Womens Center by Motherhood,\n,NO 146B Mettupalayam Road Coimbatore, Tamil Nadu, 641043",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ddb"
    },
    "Rank": 23202,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Santokbha Durlabhji Memorial Hospital Cum Medical Research Institute,\n,dnb.sdmh@gmail.com, Rajasthan, 302015",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ddc"
    },
    "Rank": 23216,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Hospital For Women and Children, Telangana,Saraswathi Nagar Colony, Mansoorabad Village, LB Nagar, Telangana, 500070",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ddd"
    },
    "Rank": 23220,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dde"
    },
    "Rank": 23221,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "National Heart Institute, ,49-50, Community Centre, East of Kailash, Delhi (NCT), 110065",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ddf"
    },
    "Rank": 23222,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital,,DNB office, 3rd Floor Opp Library  Apollo Hospitals, Jubilee Hills, Hyderabad, Telangana, 500033",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05de0"
    },
    "Rank": 23225,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rich Hospital, Andhra Pradesh,POGATHOTA,NELLORE,ANDHRAPRADESH, Andhra Pradesh, 524001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05de1"
    },
    "Rank": 23233,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05de2"
    },
    "Rank": 23245,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) Hospital Administration",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05de3"
    },
    "Rank": 23249,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sterling Hospital, ,Sterling Hospital Road, Memnagar, Ahmedabad, Gujarat, 380052",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05de4"
    },
    "Rank": 23256,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05de5"
    },
    "Rank": 23263,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bokaro General Hospital, ,SECTOR - 4 BOKARO STEEL CITY\nDIST - BOKARO, Jharkhand, 827004",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05de6"
    },
    "Rank": 23265,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05de7"
    },
    "Rank": 23267,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KIMS Hospital,,#1-112 / 86, Survey No 5 / EE, beside Union Bank, near RTA Office, Kondapur, Hyderabad,Telangana, Telangana, 500084",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05de8"
    },
    "Rank": 23275,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aadhar Health Institute, (A UNIT OF VLCOM HEALTHCARE P LTD.) ,Aadhar Health Institute, A UNIT OF VLCOM HEALTHCARE P LTD. Tosham Road, Hisar, Haryana- 125001, Haryana, 125001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05de9"
    },
    "Rank": 23278,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Cosmos Hospital,\nUttar Pradesh,PREM NAGAR POST KAZIPURA KANTH ROAD MORADABAD, Uttar\nPradesh, 244001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dea"
    },
    "Rank": 23282,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05deb"
    },
    "Rank": 23291,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dec"
    },
    "Rank": 23293,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Hospital, ,ALEXANDER ROAD, BESIDE HARIHARA KALABHAVAN, NEAR CLOCK TOWER, SECUNDERBAD, Telangana, 500003",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ded"
    },
    "Rank": 23306,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "PRS Hospital,,PRS HOSPITAL PVT LTD KILLIPALAM THIRUVANATHAPURAM\nKERALA. <academics@prshospital.com>, Kerala, 695002",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dee"
    },
    "Rank": 23307,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05def"
    },
    "Rank": 23316,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shanti Mukund Hospital,, ,2, INSTITUTIONAL AREA, VIKAS MARG EXTENSION, KARKARDOOMA, Delhi (NCT), 110092",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05df0"
    },
    "Rank": 23317,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05df1"
    },
    "Rank": 23320,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Bundelkhand Medical College Sagar M.P.,Govt. Bundelkhand Medical College Sagar M.P., Madhya Pradesh, 470001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05df2"
    },
    "Rank": 23322,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05df3"
    },
    "Rank": 23323,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Sathya Sai Sarla Memorial Hospital, Karnataka,Sathya Sai Grama Muddenahalli\nChikkaballapura, Karnataka, 562101",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05df4"
    },
    "Rank": 23324,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05df5"
    },
    "Rank": 23325,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05df6"
    },
    "Rank": 23329,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05df7"
    },
    "Rank": 23330,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "PRASHANTH HOSPITALS ,\nTamil Nadu,No 36  36 A Velachery Main road Velachery\nChennai 600042, Tamil Nadu, 600042",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05df8"
    },
    "Rank": 23334,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05df9"
    },
    "Rank": 23337,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dharamshila Narayana Superspeciality Hospital, ,Dharamshila Marg, Vasundhara Enclave, Dharamshila Narayana Superspeciality Hospital, Delhi 110096, Delhi (NCT), 110096",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dfa"
    },
    "Rank": 23338,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dfb"
    },
    "Rank": 23339,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Columbia Asia Referral Hospital, ,No 26 4 Brigade Gateway, Malleshwaram West, Beside Metro, Bengaluru- 560055, Karnataka, 560055",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dfc"
    },
    "Rank": 23340,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Advanced Medical Research Institute (AMRI) Hospital,,AMRI Hospitals, Bhubaneswar Plot no-1, Besides Satya Sai Enclave, Khandagiri, Bhubaneswar, Odisha, 751030",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dfd"
    },
    "Rank": 23344,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dfe"
    },
    "Rank": 23345,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gvernment Medical College,Haldwani,Government Medical College, Haldwani, Uttarakhand, 263139",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05dff"
    },
    "Rank": 23346,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Family Hospital, ,St.Andrews Road, Bandra West , Mumbai - 50, Maharashtra, 400050",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e00"
    },
    "Rank": 23354,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jehangir Hospital, ,32 Sasoon Road Pune, Maharashtra, 411001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e01"
    },
    "Rank": 23355,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e02"
    },
    "Rank": 23361,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e03"
    },
    "Rank": 23366,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e04"
    },
    "Rank": 23367,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e05"
    },
    "Rank": 23369,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dharan Hospital ,\nTamil Nadu,14 SEELANAICKENPATTY BYPASS SALEM 636201, Tamil Nadu, 636201",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e06"
    },
    "Rank": 23387,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e07"
    },
    "Rank": 23388,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Amandeep Medicity, Mall Road, Amritsar, Punjab,Mall Rd, near Novelty Chownk, Maharaja Ranjit Singh Nagar, Joshi Colony, Amritsar, Punjab 143001, Punjab, 143001",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e08"
    },
    "Rank": 23390,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e09"
    },
    "Rank": 23401,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Super Facility Hospital, Distt.-Azamgarh, Uttar Pradesh,Chakrapanpur, Kanaila, Azamgarh, Uttar Pradesh, 276128",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e0a"
    },
    "Rank": 23402,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e0b"
    },
    "Rank": 23404,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Capital Hospital, Orissa,director.chb@gmail.com, Odisha, 751001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e0c"
    },
    "Rank": 23405,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Starcare Hospital Kozhikode Private Limited, Starcare Hospital,\n,STARCARE HOSPITAL, NELLIKKODE POST, THONDAYAD, KOZHIKODE, Kerala, 673017",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e0d"
    },
    "Rank": 23408,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,Nalgonda X Roads, Malakpet, Hyderabad, Telangana, 500036",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e0e"
    },
    "Rank": 23412,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College Kannauj , Uttar Pradesh,Kannaujmedicalcollege@gmail.com, Uttar Pradesh, 209732",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e0f"
    },
    "Rank": 23415,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kollam,Government Medical College Kollam,Parippally, Kerala, 691574",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e10"
    },
    "Rank": 23430,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e11"
    },
    "Rank": 23435,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e12"
    },
    "Rank": 23437,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College and  PGIMSR,ESIC Medical College and  PGIMSR, KK Nagar, Chennai - 600 078, Tamil Nadu, 600078",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e13"
    },
    "Rank": 23438,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e14"
    },
    "Rank": 23440,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e15"
    },
    "Rank": 23442,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K C General Hospital, ,No. 89, 5th Cross, Malleshwaram, Bangalore, Karnataka, 560003",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e16"
    },
    "Rank": 23445,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e17"
    },
    "Rank": 23447,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dhayal Hospital and Research Centre, Rajasthan\n,Dhayal Hospital and Research Centre, Nh52,Near Math Mandir,\nReengus,\nSikar,Rajasthan-332404, Rajasthan, 332404",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e18"
    },
    "Rank": 23451,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahavir Cancer Sansthan and Research Centre, ,Phulwarisharif, Patna, Bihar, 801505",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e19"
    },
    "Rank": 23454,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e1a"
    },
    "Rank": 23462,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,DISTRICT HOSPITAL NEAR SANGAM CIRCLE DR RAJ KUMAR ROAD BALLARI-583101 KARNATAKA, Karnataka, 583101",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e1b"
    },
    "Rank": 23465,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. R N Cooper Municipal General Hospital, (Associated with H B T (Hinduhridayasamrat Balasaheb Thackeray) medical College) ,HBTMC AND DR.R.N.COOPER HOSPITAL\nU 15 BHAKTI VEDANTA ROAD JVPD SCHEME VILE PARLE WEST MUMBAI,\nMaharashtra, 400056",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e1c"
    },
    "Rank": 23466,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,SECTOR-6, PANCHKULA, HARYANA-134108, Haryana, 134108",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e1d"
    },
    "Rank": 23472,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e1e"
    },
    "Rank": 23474,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Kamakshi Memorial Hospital, (Dr. Kamakshi Institute of Medical Sciences and Research) ,No.1,Radial Road , Pallikaranai, Chennai, Tamil Nadu, 600100",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e1f"
    },
    "Rank": 23475,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e20"
    },
    "Rank": 23480,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e21"
    },
    "Rank": 23501,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e22"
    },
    "Rank": 23502,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "J.L.N. Main Hospital and Research Centre, ,Bhilai Steel Plant Sector-9, Bhilai, C.G., Chhattisgarh, 490009",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e23"
    },
    "Rank": 23506,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shija Hospitals and Research Institute, ,SHIJA HOSPITALS AND RESEARCH INSTITUTE, LANGOL, LAMPHELPAT, IMPHAL WEST, MANIPUR 795004, Manipur, 795004",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e24"
    },
    "Rank": 23512,
    "Allotted Quota": "All India",
    "Allotted Institute": "Institute of Nuclear Medicine & Allied Sciences,Institute of Nuclear Medicine & Allied Sciences, Delhi (NCT), 110054",
    "Course": "DIP IN RADIATION MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e25"
    },
    "Rank": 23516,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, (A unit of Balaji Medical and Diagnostic Research Centre)\n,Max Super Specialty Hospital -108A IP Extension Patparganj New Delhi, Delhi (NCT), 110092",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e26"
    },
    "Rank": 23520,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e27"
    },
    "Rank": 23528,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SUM ULTIMATE MEDICARE, Orissa,PLOT NO- C6 K-8 KALINGA NAGAR GHATIKIA\nBHUBANESWAR ODISHA, Odisha, 751003",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e28"
    },
    "Rank": 23530,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster CMI Hospital, ,43/2 New Airport Road, NH-7, Kodigehalli, Sahakara Nagar, Hebbal, Bengaluru, Karnataka, 560092",
    "Course": "(NBEMS) Palliative Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e29"
    },
    "Rank": 23533,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e2a"
    },
    "Rank": 23534,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "APOLLO SPECTRA HOSPITALS ,H. No. 1-7-70 to 73, Mashallah Building, Paradise Circle, S. D Road, Secunderabad, Telangana, 500003",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e2b"
    },
    "Rank": 23535,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e2c"
    },
    "Rank": 23539,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e2d"
    },
    "Rank": 23545,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Adarsha Hospital, ,Near KSRTC Bus Stand, Udupi Taluk and District, Karnataka, Karnataka, 576101",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e2e"
    },
    "Rank": 23546,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e2f"
    },
    "Rank": 23547,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e30"
    },
    "Rank": 23550,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e31"
    },
    "Rank": 23552,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bharat Cancer Hospital and Research Institute,,Surat-Bardoli Road , Saroli , Surat, Gujarat, 395010",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e32"
    },
    "Rank": 23553,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e33"
    },
    "Rank": 23554,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e34"
    },
    "Rank": 23555,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pimpri Chichwad Municipal Corporation Postgraduate Institute Y.C.M.Hospital Pimpri,Pune 18,Dean Office, Y.C.M.Hospital, Sant Tukaramnagar Pimpri, Pune, Maharashtra, 411018",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e35"
    },
    "Rank": 23556,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e36"
    },
    "Rank": 23557,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital,,GMERS Medical College and Hospital Campus, Dharpur, Patan-Unjha State Highway, Patan (North Gujarat,, Gujarat, 384265",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e37"
    },
    "Rank": 23560,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e38"
    },
    "Rank": 23573,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhabha Atomic Research Centre and Hospital, ,Anushaktinagar, Mumbai, Maharashtra, 400094",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e39"
    },
    "Rank": 23576,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Batra Hospital and Medical Research Centre, ,1, Tughlakabad institutional Area, Mehrauli Badarpur Road, New Delhi, Delhi (NCT), 110062",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e3a"
    },
    "Rank": 23579,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e3b"
    },
    "Rank": 23580,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Wockhardt Hospital, ,1877, Dr. Anand Rao Nair Marg, Mumbai Central, Maharashtra, 400011",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e3c"
    },
    "Rank": 23584,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e3d"
    },
    "Rank": 23585,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e3e"
    },
    "Rank": 23588,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e3f"
    },
    "Rank": 23592,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e40"
    },
    "Rank": 23593,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e41"
    },
    "Rank": 23595,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. GERIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e42"
    },
    "Rank": 23596,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Syed Ammal Trust Hospital, Tamil Nadu,DR E M ABDULLAH CAMPUS 127 SWAMY VIVEKANANDAR SALAI\nRAMANATHAPURAM, Tamil Nadu, 623501",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e43"
    },
    "Rank": 23601,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e44"
    },
    "Rank": 23602,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dharan Hospital ,\nTamil Nadu,14 SEELANAICKENPATTY BYPASS SALEM 636201, Tamil Nadu, 636201",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e45"
    },
    "Rank": 23604,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tamil Nadu Government Multi Superspeciality Hospital, Tamil Nadu\n,Omandurar Government Estate Anna Salai, Tamil Nadu, 600002",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e46"
    },
    "Rank": 23605,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e47"
    },
    "Rank": 23606,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e48"
    },
    "Rank": 23607,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "N S Memorial Institute of Medical Sciences ,N S Memorial Institute of Medical Sciences Palathara, Thattamala, Kollam, Kerala, 691020",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e49"
    },
    "Rank": 23611,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e4a"
    },
    "Rank": 23616,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e4b"
    },
    "Rank": 23618,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Radhakrishnan Govt. Medical College , ,Dr. Radhakrishnan Govt. Medical College Hamirpur HP, Himachal Pradesh, 177001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e4c"
    },
    "Rank": 23620,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE, PUDUCHERRY,INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE,\nPUDUCHERRY, Puducherry, 605009",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e4d"
    },
    "Rank": 23626,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e4e"
    },
    "Rank": 23628,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College,Tumuki, Bihaguri, Tezpur, Sonitpur, Assam, Assam, 784010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e4f"
    },
    "Rank": 23632,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e50"
    },
    "Rank": 23638,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e51"
    },
    "Rank": 23642,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KIMS Hospital,,#1-112 / 86, Survey No 5 / EE, beside Union Bank, near RTA Office, Kondapur, Hyderabad,Telangana, Telangana, 500084",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e52"
    },
    "Rank": 23644,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Gleneagles Hospital, ,58 Canal Circular Road Kolkata 700054, West Bengal, 700054",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e53"
    },
    "Rank": 23645,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Continental Hospital, ,Continental Hospitals Pvt Ltd., Plot No.3, Road No.2, IT & Financial District, Nanakramguda, Gachibo, Telangana, 500032",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e54"
    },
    "Rank": 23646,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.R.A. Medical College,\n,Saddarpur Akabarpur Tanda Road Post Surapur\nDistrict Ambedkarnagar, Uttar Pradesh, 224227",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e55"
    },
    "Rank": 23651,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhabha Atomic Research Centre and Hospital, ,Anushaktinagar, Mumbai, Maharashtra, 400094",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e56"
    },
    "Rank": 23653,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e57"
    },
    "Rank": 23659,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e58"
    },
    "Rank": 23661,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e59"
    },
    "Rank": 23668,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e5a"
    },
    "Rank": 23669,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e5b"
    },
    "Rank": 23673,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES ADILABAD -504001 TELANGANA STATE, Telangana, 504001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e5c"
    },
    "Rank": 23675,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e5d"
    },
    "Rank": 23677,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e5e"
    },
    "Rank": 23678,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e5f"
    },
    "Rank": 23683,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e60"
    },
    "Rank": 23684,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B. Nanavati Hospital, Vivekanand Rd,Swami Vivekananda Rd, near LIC, LIC Colony, Suresh Colony, Vile Parle West, Mumbai, Maharashtra 4000, Maharashtra, 400056",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e61"
    },
    "Rank": 23688,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Christian Institute of Health Sciences and Research, ,4th Mile, Central Jail Road, Dimapur, Nagaland, 797115",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e62"
    },
    "Rank": 23692,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Narayani Hospital and Res. Centre, ,Sripuram Thirumalaikodi Vellore, Tamil Nadu, 632055",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e63"
    },
    "Rank": 23693,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Rangarajan Memorial Hospital, (Sundaram Medical Foundation) ,Sundaram Medical Foundation, Shanthi Colony, 4th Avenue, Anna Nagar, Chennai, Tamil Nadu, 600040",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e64"
    },
    "Rank": 23696,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Syed Ammal Trust Hospital, Tamil Nadu,DR E M ABDULLAH CAMPUS 127 SWAMY VIVEKANANDAR SALAI\nRAMANATHAPURAM, Tamil Nadu, 623501",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e65"
    },
    "Rank": 23698,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rabindranath Tagore International Institute of Cardiac Sciences, ,PREMISES NO. 1489 124 MUKUNDAPUR  E M BYPASS  KOLKATA-700099, West Bengal, 700099",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e66"
    },
    "Rank": 23699,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Thiruvannamalai Medical College, Thiruvannamalai, Tamil Nadu,DISTRICT COLLECTORATE  MASTER PLAN COMPLEX, VENGIKKAL THIRUVANNAMALAI,\nTamil Nadu, 600604",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e67"
    },
    "Rank": 23702,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital,,FC 50, Max Wali Rd, C and D Block, Shalimar Place Site, Shalimar Bagh, New Delhi, Delhi 110088, Delhi (NCT), 110088",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e68"
    },
    "Rank": 23706,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e69"
    },
    "Rank": 23708,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e6a"
    },
    "Rank": 23710,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e6b"
    },
    "Rank": 23717,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. GERIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e6c"
    },
    "Rank": 23720,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollomedics Superspeciality Hospital,KBC 31, Sector B, LDA Colony, Kanpur Road, Lucknow, Uttar Pradesh, 226012",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e6d"
    },
    "Rank": 23725,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Down Town Hospital, G S Road, ,G S Road, Dispur, Guwahati-781006, Assam, 781006",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e6e"
    },
    "Rank": 23730,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e6f"
    },
    "Rank": 23732,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "N S Memorial Institute of Medical Sciences ,N S Memorial Institute of Medical Sciences Palathara, Thattamala, Kollam, Kerala, 691020",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e70"
    },
    "Rank": 23739,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e71"
    },
    "Rank": 23740,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e72"
    },
    "Rank": 23750,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute of Medical Sciences Srikakulam,Government Medical College Srikakulam\nBalaga Srikakulam Town Srikakulam District, Andhra Pradesh, 532001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e73"
    },
    "Rank": 23751,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College & Attached Hospital Bharatpur,GOVERNMENT MEDICAL COLLEGE BHARATPUR VILLAGE RAMPURA NH 21, Rajasthan, 321001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e74"
    },
    "Rank": 23752,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Giridhar Eye Institute , ,Ponneth Temple Road Kadavanthra\nCochin, Kerala, 682020",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e75"
    },
    "Rank": 23753,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e76"
    },
    "Rank": 23755,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESIC Model Hospital and Occupational Disease Centre,,ESIC MODEL HOSPITAL AND ODC NANDA NAGAR INDORE, Madhya Pradesh, 452011",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e77"
    },
    "Rank": 23758,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e78"
    },
    "Rank": 23762,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SNR District Hospital,,SNR DISTRICT HOSPITAL, KOLAR KARNATAKA-563101,\nKarnataka, 563101",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e79"
    },
    "Rank": 23764,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "South Eastern Railways Central Hospital, ,CENTRAL HOSPITAL SOUTH EASTERN RAILWAY\n11 GARDEN REACH ROAD\nKOLKATA, West Bengal, 700043",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e7a"
    },
    "Rank": 23765,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e7b"
    },
    "Rank": 23767,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e7c"
    },
    "Rank": 23771,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e7d"
    },
    "Rank": 23783,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e7e"
    },
    "Rank": 23784,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medicare Multispeciality Hospital,medicarepusad@gmail.com, Maharashtra, 445204",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e7f"
    },
    "Rank": 23789,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District General Hospital, ,Government District General Hospital, Noonepalle, Nandyal - 518502, Andhra Pradesh, 518502",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e80"
    },
    "Rank": 23790,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Narayani Hospital and Res. Centre, ,Sripuram Thirumalaikodi Vellore, Tamil Nadu, 632055",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e81"
    },
    "Rank": 23795,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Santokbha Durlabhji Memorial Hospital Cum Medical Research Institute,\n,dnb.sdmh@gmail.com, Rajasthan, 302015",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e82"
    },
    "Rank": 23801,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e83"
    },
    "Rank": 23802,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. Medical College and Hospital,,,Government Medical College Hospital Chandigarh, Chandigarh, 160030",
    "Course": "(NBEMS) Hospital Administration",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e84"
    },
    "Rank": 23803,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e85"
    },
    "Rank": 23810,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "New Amrutha medical and Research centre,,12-10-239-1-1,near Chitragar kalyan mantap,Siyatalab,Raichur-Karnataka\nKarnataka 584101, Karnataka, 584101",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e86"
    },
    "Rank": 23811,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Bundelkhand Medical College Sagar M.P.,Govt. Bundelkhand Medical College Sagar M.P., Madhya Pradesh, 470001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e87"
    },
    "Rank": 23815,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e88"
    },
    "Rank": 23817,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e89"
    },
    "Rank": 23818,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e8a"
    },
    "Rank": 23822,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Civil Hospital, ,CIVIL HOSPITAL, SUBHASH ROAD ROHTAK, Haryana, 124001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e8b"
    },
    "Rank": 23823,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Autonomous State Medical College,\nUttar Pradesh,Autonomous State Medical College, Gaura Danda, Sitapur Road, Hardoi, Uttar Pradesh, 241001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e8c"
    },
    "Rank": 23824,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College,\n,Gunera Wazidpur, Ujhani Road, Badaun, Uttar Pradesh, 243601",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e8d"
    },
    "Rank": 23825,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e8e"
    },
    "Rank": 23828,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e8f"
    },
    "Rank": 23836,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e90"
    },
    "Rank": 23841,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SunShine Hospital, ,1-7-201 TO 205\nPG Road, Paradise, Secunderabad, Telangana, 500003",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e91"
    },
    "Rank": 23842,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e92"
    },
    "Rank": 23844,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e93"
    },
    "Rank": 23849,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e94"
    },
    "Rank": 23850,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e95"
    },
    "Rank": 23851,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e96"
    },
    "Rank": 23853,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e97"
    },
    "Rank": 23854,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e98"
    },
    "Rank": 23855,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e99"
    },
    "Rank": 23858,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e9a"
    },
    "Rank": 23861,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaipur Golden Hospital, ,2 INSTITUTIONAL AREA, SECTOR-3, ROHINI, Delhi (NCT), 110085",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e9b"
    },
    "Rank": 23862,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e9c"
    },
    "Rank": 23868,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Hurkisondas Nurrotumdas Hospital and Research Centre, ,Prarthana Samaj, Raja Ram Mohan Roy road,\nGirgaon, MUMBAI, Maharashtra, 400004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e9d"
    },
    "Rank": 23869,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e9e"
    },
    "Rank": 23872,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Mulund - Goregaon Link Rd, Nahur West, Industrial Area, Bhandup West, Mumbai, Maharashtra 400078, Maharashtra, 400078",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05e9f"
    },
    "Rank": 23889,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaypee Hospital,,sector 128, Noida, Uttar Pradesh, 201304",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ea0"
    },
    "Rank": 23896,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,4th T Block, Beside Rajiv Gandhi University of Health Sciences Jayanagar,, Karnataka, 560041",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ea1"
    },
    "Rank": 23898,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ea2"
    },
    "Rank": 23899,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ea3"
    },
    "Rank": 23900,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Alchemist Hospital, Haryana,Alchemist Hospital Sector 21, Panchkula, Haryana, 134112",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ea4"
    },
    "Rank": 23904,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital,,FC 50, Max Wali Rd, C and D Block, Shalimar Place Site, Shalimar Bagh, New Delhi, Delhi 110088, Delhi (NCT), 110088",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ea5"
    },
    "Rank": 23907,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Anand Charitable Trusts Anand Hospital, Maharashtra,Anand Hospital, Phulewadi Road, Vaijapur Dist. Aurangabad, Maharashtra, 423701",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ea6"
    },
    "Rank": 23908,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, THOOTHUKUDI,GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, 3rd\nMile,Kamaraj Nagar,Thoothukudi., Tamil Nadu, 628008",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ea7"
    },
    "Rank": 23909,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ea8"
    },
    "Rank": 23912,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ea9"
    },
    "Rank": 23913,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Womens Center by Motherhood,\n,NO 146B Mettupalayam Road Coimbatore, Tamil Nadu, 641043",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05eaa"
    },
    "Rank": 23917,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05eab"
    },
    "Rank": 23922,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05eac"
    },
    "Rank": 23923,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BGS Global Hospital, ,BGS Global Hospital, Uttarahalli Road, Kengeri Bangalore, Karnataka, 560060",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ead"
    },
    "Rank": 23925,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B L Kapur Memorial Hospital,Pusa Road New Delhi, Delhi (NCT), 110005",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05eae"
    },
    "Rank": 23926,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05eaf"
    },
    "Rank": 23929,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MALABAR INSTITUTE OF MEDICAL SCIENCES Ltd ,Chala East, Chala, Kannur, Kerala, 670621",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05eb0"
    },
    "Rank": 23930,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05eb1"
    },
    "Rank": 23936,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medica Superspecialty Hospital, ,127 Mukundapur, E.M. Byapss, Kolkata, West Bengal, 700099",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05eb2"
    },
    "Rank": 23941,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05eb3"
    },
    "Rank": 23943,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05eb4"
    },
    "Rank": 23946,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05eb5"
    },
    "Rank": 23949,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Advanced Medical Research Institute (AMRI) Hospital,,AMRI Hospitals, Bhubaneswar Plot no-1, Besides Satya Sai Enclave, Khandagiri, Bhubaneswar, Odisha, 751030",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05eb6"
    },
    "Rank": 23950,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05eb7"
    },
    "Rank": 23952,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05eb8"
    },
    "Rank": 23957,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05eb9"
    },
    "Rank": 23958,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "YATHARTH SUPER SPECIALTY HOSPITALS,\nUttar Pradesh,Sector omega 01-, Greater noida, Uttar Pradesh, 201308",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05eba"
    },
    "Rank": 23960,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ebb"
    },
    "Rank": 23965,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "J.L.N. Main Hospital and Research Centre, ,Bhilai Steel Plant Sector-9, Bhilai, C.G., Chhattisgarh, 490009",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ebc"
    },
    "Rank": 23966,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MAA E.N.T HOSPITALS, MAA HOSPITALS PVT. LTD),MAA E.N.T HOSPITALS(MAA HOSPITALS PVT. LTD.)\nH.NO:8-2-293\\82\\A\\1266, ROAD NO.36, BESIDE ICICI BANK,, Telangana, 500033",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ebd"
    },
    "Rank": 23969,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ebe"
    },
    "Rank": 23970,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Kamakshi Memorial Hospital, (Dr. Kamakshi Institute of Medical Sciences and Research) ,No.1,Radial Road , Pallikaranai, Chennai, Tamil Nadu, 600100",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ebf"
    },
    "Rank": 23972,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ec0"
    },
    "Rank": 23976,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Life line Super Specialty Hospital, ,14th Mile, Melood P.O Adoor , Pathanamthitta Dist, Kerala, 691554",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ec1"
    },
    "Rank": 23977,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MALANKARA MEDICAL MISSION HOSPITAL,THRISSUR, Kerala,MOSC MEDICAL MISSION HOSPITAL, KUNNAMKULAM, THRISSUR, Kerala, 680503",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ec2"
    },
    "Rank": 23979,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ec3"
    },
    "Rank": 23982,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ec4"
    },
    "Rank": 23989,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ec5"
    },
    "Rank": 23990,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ec6"
    },
    "Rank": 23994,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Suhel Dev Medical College and Mahrishi Balark Hospital, Uttar Pradesh,MSD AS Medical College KDC Road Bahraich, Uttar Pradesh, 271801",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ec7"
    },
    "Rank": 23997,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rich Hospital, Andhra Pradesh,POGATHOTA,NELLORE,ANDHRAPRADESH, Andhra Pradesh, 524001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ec8"
    },
    "Rank": 23998,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ec9"
    },
    "Rank": 24001,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sadhu Vaswani Missions ,7-9 Lane No 1 Koregaon Park Pune 411001, Maharashtra, 411001",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05eca"
    },
    "Rank": 24002,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Charak Hospital and Research Center, Uttar Pradesh,Hardoi Road, Dubagga, Lucknow, Uttar Pradesh, 226003",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ecb"
    },
    "Rank": 24004,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, (A unit of Balaji Medical and Diagnostic Research Centre)\n,Max Super Specialty Hospital -108A IP Extension Patparganj New Delhi, Delhi (NCT), 110092",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ecc"
    },
    "Rank": 24005,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.R.A. Medical College,\n,Saddarpur Akabarpur Tanda Road Post Surapur\nDistrict Ambedkarnagar, Uttar Pradesh, 224227",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ecd"
    },
    "Rank": 24008,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "South Eastern Railways Central Hospital, ,CENTRAL HOSPITAL SOUTH EASTERN RAILWAY\n11 GARDEN REACH ROAD\nKOLKATA, West Bengal, 700043",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ece"
    },
    "Rank": 24010,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ecf"
    },
    "Rank": 24012,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ed0"
    },
    "Rank": 24013,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, Dist.Belgaum, Karnataka,GENERAL HOSPITAL AND MCH FALLS ROAD GOKAK DIST BELGAUM KARNATAKA, Karnataka, 591307",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ed1"
    },
    "Rank": 24016,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital,,Malsi, Mussoorie Diversion Road, Uttarakhand, 248001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ed2"
    },
    "Rank": 24017,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mothers Care Children Hospital and Research Centre, ,SAGOLBAND MOIRANG LEIRAK, IMPHAL WEST, Manipur, 795001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ed3"
    },
    "Rank": 24018,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Central Hospital, ,CENTRAL HOSPITAL, NORTH WESTERN RAILWAY, GANPATI NAGAR, HASANPURA ROAD, JAIPUR, Rajasthan, 302006",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ed4"
    },
    "Rank": 24025,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ed5"
    },
    "Rank": 24028,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ed6"
    },
    "Rank": 24033,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agarsen Hospital, ,., Delhi (NCT), 110026",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ed7"
    },
    "Rank": 24035,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ed8"
    },
    "Rank": 24038,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Global Hospital and Health City, (A unit of Ravindernath GE Medical Associate), Tamil Nadu,439, Cheran Nagar, Sholinganallur - Medavakkam  Road, Perumbakkam, Chennai, Tamil Nadu, 600100",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ed9"
    },
    "Rank": 24041,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05eda"
    },
    "Rank": 24050,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S.K.Hospital, ,SUPERINTENDENT RNO 36  GOVT SK HOSPITAL  SILVER JUBLI ROAD  SIKAR  RAJ, Rajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05edb"
    },
    "Rank": 24051,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Caritas Hospital, ,Thellakom P.O, Kerala, 686630",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05edc"
    },
    "Rank": 24052,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saket Hospital,10, Meera Marg, Agarwal Farm, Masarover, Jaipur, Rajasthan, 302020",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05edd"
    },
    "Rank": 24053,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ede"
    },
    "Rank": 24054,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medicare Multispeciality Hospital,medicarepusad@gmail.com, Maharashtra, 445204",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05edf"
    },
    "Rank": 24059,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agarsen Hospital, ,., Delhi (NCT), 110026",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ee0"
    },
    "Rank": 24060,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ee1"
    },
    "Rank": 24062,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medicover Hospitals, (Formerly MaxCure Hospital) ,Medicover Hospitals , Opposite Cyber gate way , Ibis hotel Lane , hitech city\nMadhapur Hyderabad, Telangana, 500081",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ee2"
    },
    "Rank": 24070,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ee3"
    },
    "Rank": 24072,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ee4"
    },
    "Rank": 24074,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ee5"
    },
    "Rank": 24075,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jehangir Hospital, ,32 Sasoon Road Pune, Maharashtra, 411001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ee6"
    },
    "Rank": 24080,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ee7"
    },
    "Rank": 24081,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "PRASHANTH HOSPITALS ,\nTamil Nadu,No 36  36 A Velachery Main road Velachery\nChennai 600042, Tamil Nadu, 600042",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ee8"
    },
    "Rank": 24083,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ee9"
    },
    "Rank": 24084,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bombay Hospital and Institute of Medical Sciences, ,12, New Marine Lines, Mumbai, Maharashtra, 400020",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05eea"
    },
    "Rank": 24087,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "North District Hospital -,Near Govt ITI College, Peddem, Mapusa, Bardez Goa., Goa, 403507",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05eeb"
    },
    "Rank": 24088,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaypee Hospital,,sector 128, Noida, Uttar Pradesh, 201304",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05eec"
    },
    "Rank": 24089,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05eed"
    },
    "Rank": 24090,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster RV Hospital, Karnataka,CA 37, 24th Main Rd, ITI Layout, 1st Phase, J. P. Nagar, Bengaluru, Karnataka 560078, Karnataka, 560078",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05eee"
    },
    "Rank": 24092,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05eef"
    },
    "Rank": 24098,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ef0"
    },
    "Rank": 24100,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ef1"
    },
    "Rank": 24101,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ef2"
    },
    "Rank": 24103,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ef3"
    },
    "Rank": 24104,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "PG Diploma in Psychological Medicine / Psychiatric Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ef4"
    },
    "Rank": 24109,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagwan Mahavir Cancer Hospital and Research Centre, ,Managed by K G Kothari Memorial Trust\nJawahar Lal Nehru Marg, Jaipur 302 017, Rajasthan, 302017",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ef5"
    },
    "Rank": 24110,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ef6"
    },
    "Rank": 24113,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Star Hospital,,Star Hospitals, 8-2-596/5, Road. No. 10,\nBanjara Hills, Hyderabad, Telangana, 500034",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ef7"
    },
    "Rank": 24116,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ef8"
    },
    "Rank": 24117,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ef9"
    },
    "Rank": 24118,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05efa"
    },
    "Rank": 24119,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05efb"
    },
    "Rank": 24120,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shaheed Hasan Khan Mewati Government Medical College , ,Shaheed Hasan Khan Mewati Government Medical College Nalhar Nuh, Haryana, 122107",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05efc"
    },
    "Rank": 24121,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05efd"
    },
    "Rank": 24122,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05efe"
    },
    "Rank": 24125,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sparsh Super Specialty Hospital, ,SPARSH SUPER SPECIALITY HOSPITAL, TUMKUR ROAD, YESHWANTHPURA, BANGALORE, Karnataka, 560022",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05eff"
    },
    "Rank": 24132,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asansol District Hospital, ,S. B. GORAI ROAD, P.O.- ASANSOL, DIST.- PASCHIM BARDHAMAN, West Bengal, 713301",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f00"
    },
    "Rank": 24134,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Santokbha Durlabhji Memorial Hospital Cum Medical Research Institute,\n,dnb.sdmh@gmail.com, Rajasthan, 302015",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f01"
    },
    "Rank": 24135,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahanadi Netra Chitsalaya,At Pandapali PO & Via Biramaharajpur Dist Subarnapur, Odisha, 767018",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f02"
    },
    "Rank": 24140,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f03"
    },
    "Rank": 24141,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f04"
    },
    "Rank": 24148,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Bangur Hospital, Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan , Rajasthan-306401,Government Medical College and Bangur Hospital Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan Ra, Rajasthan, 306401",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f05"
    },
    "Rank": 24150,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f06"
    },
    "Rank": 24151,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f07"
    },
    "Rank": 24155,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nizam`s Institute of Medical Sciences, ,NIZAMS INSTITUTE OF MEDICAL SCIENCES PANJAGUTTA HYDERABAD, Telangana, 500082",
    "Course": "(NBEMS) IMMUNO- HAEMATOLOGY AND BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f08"
    },
    "Rank": 24159,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Theresa`s Hospital,,7-1-645/A ERRAGADA SANATHNAGAAR HYDERABAD,\nTelangana, 500018",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f09"
    },
    "Rank": 24161,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f0a"
    },
    "Rank": 24167,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f0b"
    },
    "Rank": 24173,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f0c"
    },
    "Rank": 24176,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Little Stars Childrens Hospital, Hyderabad, Telangana,Plot no-30, Nagarjuna hills, Hyderabad, Telangana, 500082",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f0d"
    },
    "Rank": 24178,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Hurkisondas Nurrotumdas Hospital and Research Centre, ,Prarthana Samaj, Raja Ram Mohan Roy road,\nGirgaon, MUMBAI, Maharashtra, 400004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f0e"
    },
    "Rank": 24183,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f0f"
    },
    "Rank": 24187,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f10"
    },
    "Rank": 24190,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f11"
    },
    "Rank": 24195,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f12"
    },
    "Rank": 24196,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f13"
    },
    "Rank": 24198,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "NIMS Medicity, Trivandrum,\nKerala,Aralumoodu, Neyyattinkara, Trivandrum 695123, Kerala, 695123",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f14"
    },
    "Rank": 24199,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f15"
    },
    "Rank": 24200,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Hurkisondas Nurrotumdas Hospital and Research Centre, ,Prarthana Samaj, Raja Ram Mohan Roy road,\nGirgaon, MUMBAI, Maharashtra, 400004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f16"
    },
    "Rank": 24202,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f17"
    },
    "Rank": 24204,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Surya Mother and Child Superspeciality Hospital, ,Surya Mother and Child Super Speciality Hospital     Sr. No 8 Devi Yash , Near Octroi Post Wakad, Pu, Maharashtra, 411057",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f18"
    },
    "Rank": 24206,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f19"
    },
    "Rank": 24207,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f1a"
    },
    "Rank": 24208,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruby Hall Clinic, ,GRANT MEDICAL FOUNDATION,\n40 SASSOON ROAD, PUNE 411001, Maharashtra, 411001",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f1b"
    },
    "Rank": 24210,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District General Hospital, ,Government District General Hospital, Noonepalle, Nandyal - 518502, Andhra Pradesh, 518502",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f1c"
    },
    "Rank": 24212,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f1d"
    },
    "Rank": 24220,
    "Allotted Quota": "All India",
    "Allotted Institute": "Post Graduate Institute of Medical Education & Research and Capital Hospital ( PGIMER & CH), Odisha,Post Graduate Institute of Medical Education & Research and Capital Hospital, Bhubaneswar,Udyan Marg, Odisha, 751001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f1e"
    },
    "Rank": 24221,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE - KANNUR,GOVERNMENT MEDICAL COLLEGE\n- KANNUR, Kerala, 670503",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f1f"
    },
    "Rank": 24223,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f20"
    },
    "Rank": 24224,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f21"
    },
    "Rank": 24228,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,Government Medical College, Near RTC Bus stand, Gollaguda, Nalgonda Telangana - 508001, Telangana, 508001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f22"
    },
    "Rank": 24229,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Escorts Hospital, ,214, Jawahar Lal Nehru Marg, Malviya Nagar, Jaipur, Rajasthan, 302017",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f23"
    },
    "Rank": 24231,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Motors Hospital, ,KHARANGAJHAR, TELCO COLONY JAMSHEDPUR, Jharkhand, 831003",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f24"
    },
    "Rank": 24232,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f25"
    },
    "Rank": 24235,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f26"
    },
    "Rank": 24243,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f27"
    },
    "Rank": 24245,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,LOTUS TOWER, G S ROAD, GUWAHATI, Assam, 781005",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f28"
    },
    "Rank": 24252,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f29"
    },
    "Rank": 24270,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f2a"
    },
    "Rank": 24272,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f2b"
    },
    "Rank": 24273,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f2c"
    },
    "Rank": 24276,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f2d"
    },
    "Rank": 24281,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Kamakshi Memorial Hospital, (Dr. Kamakshi Institute of Medical Sciences and Research) ,No.1,Radial Road , Pallikaranai, Chennai, Tamil Nadu, 600100",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f2e"
    },
    "Rank": 24284,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f2f"
    },
    "Rank": 24300,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vijaya Hospital, ,New No 434, Old No 180, NSK Salai, Vadapalani, Chennai - 600026, Tamil Nadu, 600026",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f30"
    },
    "Rank": 24304,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f31"
    },
    "Rank": 24308,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaipur Golden Hospital, ,2 INSTITUTIONAL AREA, SECTOR-3, ROHINI, Delhi (NCT), 110085",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f32"
    },
    "Rank": 24312,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "National Institute of Pathology (Indian Council of Medical Research), ,ICMR-National Institute of Pathology, Sri Ramachari Bhawan, Safdarjung Hospital Campus, Ansari Nagar, Delhi (NCT), 110029",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f33"
    },
    "Rank": 24313,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f34"
    },
    "Rank": 24315,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Health City, ,Apollo Hospitals, Health City, Chinagadhili, Visakhapatnam, Andhra Pradesh, 530040",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f35"
    },
    "Rank": 24316,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saifee Hospital, ,postbox no 3771, 15-17 Maharshi Karve Marg, Charni road, Mumbai, Maharashtra, 400004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f36"
    },
    "Rank": 24321,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f37"
    },
    "Rank": 24326,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Trustwell Hospitals Pvt. Ltd, ,NO 5 Chandrika Tower JC Road\nBangalore, Karnataka, 560002",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f38"
    },
    "Rank": 24333,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvarur Govt.Medical College,Thiruvarur Govt.Medical College, Tamil Nadu, 610004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f39"
    },
    "Rank": 24335,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Poona Hospital and Research Centre,,27,Sadashiv Peth, Pune -411030, Maharashtra, 411030",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f3a"
    },
    "Rank": 24338,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f3b"
    },
    "Rank": 24346,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f3c"
    },
    "Rank": 24351,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SunShine Hospital, ,1-7-201 TO 205\nPG Road, Paradise, Secunderabad, Telangana, 500003",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f3d"
    },
    "Rank": 24354,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f3e"
    },
    "Rank": 24362,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f3f"
    },
    "Rank": 24364,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jupiter Hospital, ,Eastern Express Highway, Thane West, Maharashtra, 400601",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f40"
    },
    "Rank": 24369,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f41"
    },
    "Rank": 24371,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital,,GOVERNMENT HEADQUARTERS HOSPITAL AND DNB POST GRADUATE TEACHING INSTITUTE,EVN ROAD, ERODE, Tamil Nadu, 638009",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f42"
    },
    "Rank": 24372,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District General Hospital, ,Government District General Hospital, Noonepalle, Nandyal - 518502, Andhra Pradesh, 518502",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f43"
    },
    "Rank": 24382,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Sector-62, Phase VIII, Mohali- 160062, Punjab, 160062",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f44"
    },
    "Rank": 24383,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f45"
    },
    "Rank": 24386,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f46"
    },
    "Rank": 24388,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f47"
    },
    "Rank": 24392,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f48"
    },
    "Rank": 24393,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f49"
    },
    "Rank": 24394,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f4a"
    },
    "Rank": 24402,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f4b"
    },
    "Rank": 24406,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f4c"
    },
    "Rank": 24409,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f4d"
    },
    "Rank": 24415,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f4e"
    },
    "Rank": 24416,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agarsen Hospital, ,., Delhi (NCT), 110026",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f4f"
    },
    "Rank": 24421,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f50"
    },
    "Rank": 24422,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Metro Heart Institute with Multispecialty, ,SECTOR 16A, Faridabad, Haryana, Haryana, 121002",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f51"
    },
    "Rank": 24424,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, Shalimar Bagh,A- Block, Shalimar Bagh, Delhi (NCT), 110088",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f52"
    },
    "Rank": 24429,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Preethi Hospital, ,50, Melur Main Road, Uthangudi, Madurai - 625107, Tamil Nadu, 625107",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f53"
    },
    "Rank": 24432,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Choithram Netralaya,Choithram Netralaya Plot No 54-56 Shree Ram Talawali Dhar road, Indore PIN 453001, Madhya Pradesh, Madhya Pradesh, 453001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f54"
    },
    "Rank": 24434,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kailash Cancer Hospital and Research Centre,Kailash Cancer Hospital and Research Centre, Muni Seva Ashram, At and Post-Goraj, Ta-Waghodia, Dist-, Gujarat, 391761",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f55"
    },
    "Rank": 24440,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f56"
    },
    "Rank": 24443,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital,,B-22, Sector 62, Noida, Uttar Pradesh, Uttar Pradesh, 201301",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f57"
    },
    "Rank": 24445,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "G Kuppuswamy Naidu Memorial Hospital, ,Post Box No.6327, Nethaji Road, Pappanaickenpalayam, Coimbatore, Tamil Nadu, 641037",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f58"
    },
    "Rank": 24447,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f59"
    },
    "Rank": 24449,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f5a"
    },
    "Rank": 24450,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f5b"
    },
    "Rank": 24452,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sterling Hospital, ,Sterling Hospital Road, Memnagar, Ahmedabad, Gujarat, 380052",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f5c"
    },
    "Rank": 24455,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f5d"
    },
    "Rank": 24458,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f5e"
    },
    "Rank": 24460,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,NO. 21, OFF GREAMS LANE, GREAMS ROAD, CHENNAI, Tamil Nadu, 600006",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f5f"
    },
    "Rank": 24461,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sparsh Super Specialty Hospital, ,SPARSH SUPER SPECIALITY HOSPITAL, TUMKUR ROAD, YESHWANTHPURA, BANGALORE, Karnataka, 560022",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f60"
    },
    "Rank": 24462,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f61"
    },
    "Rank": 24464,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f62"
    },
    "Rank": 24466,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KMC Specialty Hospital, ,No-6, Royal Road, Cantonment, Trichy-620001, Tamil Nadu, 620001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f63"
    },
    "Rank": 24469,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f64"
    },
    "Rank": 24480,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f65"
    },
    "Rank": 24483,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Hospital, ,Government Medical College and Associated Hospital, Kheora, Rajouri, Jammu and Kashmir-185131, Jammu And Kashmir, 185131",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f66"
    },
    "Rank": 24485,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "PG Diploma in Psychological Medicine / Psychiatric Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f67"
    },
    "Rank": 24491,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rangadore Memorial Hospital,,1ST CROSS, SHANKARAPURAM, BANGALORE, Karnataka, 560004",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f68"
    },
    "Rank": 24494,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f69"
    },
    "Rank": 24496,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Columbia Asia Referral Hospital, ,No 26 4 Brigade Gateway, Malleshwaram West, Beside Metro, Bengaluru- 560055, Karnataka, 560055",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f6a"
    },
    "Rank": 24499,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f6b"
    },
    "Rank": 24503,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Poona Hospital and Research Centre,,27,Sadashiv Peth, Pune -411030, Maharashtra, 411030",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f6c"
    },
    "Rank": 24504,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mothers Care Children Hospital and Research Centre, ,SAGOLBAND MOIRANG LEIRAK, IMPHAL WEST, Manipur, 795001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f6d"
    },
    "Rank": 24506,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, (A unit of Balaji Medical and Diagnostic Research Centre)\n,Max Super Specialty Hospital -108A IP Extension Patparganj New Delhi, Delhi (NCT), 110092",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f6e"
    },
    "Rank": 24518,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lord Mahavir Civil Hospital,,Lord Mahavir Civil Hospital, MCH Wing Fieldganj Road Ludhiana, Punjab, 141008",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f6f"
    },
    "Rank": 24521,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Childrens Hospital and BirthRight, Telangana,Hno 3 7 222 223 sy no 51 to 54 opposit kharkana police station kaskhana main road kakaguda hyderabad, Telangana, 500009",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f70"
    },
    "Rank": 24522,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Central Hospital, ,SOUTH EAST CENTRAL RAILWAY, OPPOSITE GOLF GROUND,\nBILASPUR, Chhattisgarh, 495004",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f71"
    },
    "Rank": 24536,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kamalnayan Bajaj Hospital (Marathwada Medical and Research Institutes), ,Kamalnayan Bajaj Hospital, Gut no.  43, Satara Parisar, Beed bypass road, Auranagabad., Maharashtra, 431010",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f72"
    },
    "Rank": 24537,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f73"
    },
    "Rank": 24541,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K B Bhabha Municipal General Hospital, ,R K PATKAR ROAD ,BANDRA WEST\n,MUMBAI, Maharashtra, 400050",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f74"
    },
    "Rank": 24542,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f75"
    },
    "Rank": 24544,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindu Rao Hospital, ,SUBZI MANDI, MALKA GANJ, DELHI-110007, Delhi (NCT), 110007",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f76"
    },
    "Rank": 24545,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f77"
    },
    "Rank": 24547,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Multispecialty Hospital, ,Narayana Multispecialty Hospital, Jaipur Sec-28,Kumbha Marg, Pratap Nagar Sanganer 302033",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f78"
    },
    "Rank": 24549,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Citizen Specialty Hospital,,D.No.-1-100-1-CCH, Nallagandla, Serilingampally, Hyderabad, Ranga Reddy District, Telangana, 500019",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f79"
    },
    "Rank": 24561,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.G. Hospital and PG Medical Institute ,,No:5, Arts College Road, Coimbatore - 641018, Tamil Nadu, 641018",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f7a"
    },
    "Rank": 24565,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kanumuri Hospital, Andhra Pradesh,D-NO 12 , SANAKKAYALA FACTORY ROAD , KOTHAPET , GUNTUR, Andhra Pradesh, 522001",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f7b"
    },
    "Rank": 24566,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f7c"
    },
    "Rank": 24568,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Seven Hills Hospital, ,11-4-4/A, Rockdale Layout, Visakhapatnam, Andhra Pradesh, 530002",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f7d"
    },
    "Rank": 24571,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chandan Hospital, ,Vijayant Khand, Gomti Nagar, Lucknow, Uttar Pradesh, 226010",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f7e"
    },
    "Rank": 24575,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f7f"
    },
    "Rank": 24579,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Multispecialty Hospital, ,Narayana Multispecialty Hospital, Jaipur Sec-28,Kumbha Marg, Pratap Nagar Sanganer 302033",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f80"
    },
    "Rank": 24582,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Venkatapadma Hospital,,Venkatapadma hospital, near 3 lamp junction, Door No 15-1-45, Vizianagaram, Andhra Pradesh, 535002",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f81"
    },
    "Rank": 24583,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f82"
    },
    "Rank": 24585,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shanmuga Hospital and Salem Cancer Institute, ,24/51 SARADA COLLEGE ROAD\n,SHANMUGA HOSPITAL, SALEM TAMILNADU 636007, Tamil Nadu, 636007",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f83"
    },
    "Rank": 24587,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College,Theni ,Government Medical College,Theni, Tamil Nadu, 625512",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f84"
    },
    "Rank": 24590,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f85"
    },
    "Rank": 24598,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Little Stars Childrens Hospital, Hyderabad, Telangana,Plot no-30, Nagarjuna hills, Hyderabad, Telangana, 500082",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f86"
    },
    "Rank": 24604,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f87"
    },
    "Rank": 24610,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f88"
    },
    "Rank": 24612,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f89"
    },
    "Rank": 24614,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f8a"
    },
    "Rank": 24617,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Sathya Sai General Hospital, ,MAINROAD, PRASANTHI NILAYAM, PUTTAPARTHI, DISTRRICT ANATAPURAM, Andhra Pradesh, 515134",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f8b"
    },
    "Rank": 24622,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f8c"
    },
    "Rank": 24628,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bharath Hospital, Kerala,AZAD LANE, KOTTAYAM, KERALA 686001, Kerala, 686001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f8d"
    },
    "Rank": 24629,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ahalia Foundation Eye Hospital, ,Ahalia Campus Kozhippara Post Palakkad Kerala 678557, Kerala, 678557",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f8e"
    },
    "Rank": 24631,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f8f"
    },
    "Rank": 24634,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Coochbehar Govt Medical College and Hospital, West Bengal,Vivekananda Street, Pilkhana, Cooch Behar, WB-736101, West Bengal, 736101",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f90"
    },
    "Rank": 24638,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f91"
    },
    "Rank": 24641,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sagar Hospital, Karnataka,No. 44/54, 30th Cross, Tilak Nagar, Jayanagar Extn., Bangalore, Karnataka-560041",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f92"
    },
    "Rank": 24647,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kamineni Hospital ,Boggulkunta King Koti Hyderabad, Telangana, 500001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f93"
    },
    "Rank": 24648,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medicover Hospitals, (Formerly MaxCure Hospital) ,Medicover Hospitals , Opposite Cyber gate way , Ibis hotel Lane , hitech city\nMadhapur Hyderabad, Telangana, 500081",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f94"
    },
    "Rank": 24649,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f95"
    },
    "Rank": 24653,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f96"
    },
    "Rank": 24661,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f97"
    },
    "Rank": 24668,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f98"
    },
    "Rank": 24670,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f99"
    },
    "Rank": 24672,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f9a"
    },
    "Rank": 24676,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Giridhar Eye Institute , ,Ponneth Temple Road Kadavanthra\nCochin, Kerala, 682020",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f9b"
    },
    "Rank": 24679,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f9c"
    },
    "Rank": 24680,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nazareth Hospital, Laitumukhrah, ,Laitumkhrah Shillong, East Khasi Hills district, Meghalaya, 793003",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f9d"
    },
    "Rank": 24684,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital, B.H.Road, Tumakuru-572101, Karnataka, 572101",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f9e"
    },
    "Rank": 24692,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05f9f"
    },
    "Rank": 24695,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fa0"
    },
    "Rank": 24696,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fa1"
    },
    "Rank": 24697,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jupiter Hospital, ,Eastern Express Highway, Thane West, Maharashtra, 400601",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fa2"
    },
    "Rank": 24698,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fa3"
    },
    "Rank": 24700,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chittaranjan National Cancer Institute, 37- ,37, S P Mukherjee Road, Kolkata-700026- 1st campus, 299 DJ Block A.A.1, Newtown Kolkata- 700156-2nd, West Bengal, 700026",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fa4"
    },
    "Rank": 24701,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fa5"
    },
    "Rank": 24705,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Endoworld Hospital Private Limited, Maharashtra,723, In front of Airport, Chikalthana, Aurangabad, Maharashtra, Maharashtra, 431006",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fa6"
    },
    "Rank": 24710,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saket Hospital,10, Meera Marg, Agarwal Farm, Masarover, Jaipur, Rajasthan, 302020",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fa7"
    },
    "Rank": 24714,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Sathya Sai Sarla Memorial Hospital, Karnataka,Sathya Sai Grama Muddenahalli\nChikkaballapura, Karnataka, 562101",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fa8"
    },
    "Rank": 24717,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fa9"
    },
    "Rank": 24720,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05faa"
    },
    "Rank": 24723,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Preethi Hospital, ,50, Melur Main Road, Uthangudi, Madurai - 625107, Tamil Nadu, 625107",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fab"
    },
    "Rank": 24732,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fac"
    },
    "Rank": 24734,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fad"
    },
    "Rank": 24739,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Venkatapadma Hospital,,Venkatapadma hospital, near 3 lamp junction, Door No 15-1-45, Vizianagaram, Andhra Pradesh, 535002",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fae"
    },
    "Rank": 24741,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05faf"
    },
    "Rank": 24743,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fb0"
    },
    "Rank": 24746,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fb1"
    },
    "Rank": 24748,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fb2"
    },
    "Rank": 24750,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fb3"
    },
    "Rank": 24751,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "AUTONOMOUS STATE MEDICAL COLLEGE, ,RAJARSHI DASHRATH AUTONOMOUS STATE MEDICAL COLLEGE VILL- GANJA, POST- DABHA SEMAR, DISTRICT- AYODHYA, Uttar Pradesh, 224133",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fb4"
    },
    "Rank": 24756,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fb5"
    },
    "Rank": 24759,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fb6"
    },
    "Rank": 24766,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.E.M. Hospital, ,489, SARDAR MOODLIAR ROAD, RASTA PETH, PUNE 411011,\nMaharashtra, 411011",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fb7"
    },
    "Rank": 24768,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College & Attached Hospital Bharatpur,GOVERNMENT MEDICAL COLLEGE BHARATPUR VILLAGE RAMPURA NH 21, Rajasthan, 321001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fb8"
    },
    "Rank": 24774,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Central Super Speciality Hospital,\n,mdcsshecr@gmail.com, Bihar, 800001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fb9"
    },
    "Rank": 24776,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pandurang Ramarao Dongaonkar District Hospital, Chhattisgarh,Pandurang Ramarao Dongaonkar\nDistrict Hospital Durg G E Road District Durg Chhattisgarh 491001, Chhattisgarh, 491001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fba"
    },
    "Rank": 24778,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fbb"
    },
    "Rank": 24785,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital,,B-22, Sector 62, Noida, Uttar Pradesh, Uttar Pradesh, 201301",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fbc"
    },
    "Rank": 24786,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaiprakash Hospital and Research Centre Pvt. Ltd, Odisha,,JAIPRAKASH HOSPITAL AND RESEARCH CENTER PVT. LTD., DANDIAPALI, ROURKELA, Odisha, 769004",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fbd"
    },
    "Rank": 24790,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fbe"
    },
    "Rank": 24791,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Advanced Medical Research Institute (AMRI) Hospital,,AMRI Hospitals, Bhubaneswar Plot no-1, Besides Satya Sai Enclave, Khandagiri, Bhubaneswar, Odisha, 751030",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fbf"
    },
    "Rank": 24796,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fc0"
    },
    "Rank": 24798,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PALLIATIVE MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fc1"
    },
    "Rank": 24801,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tomo Riba Institute of Health and Medical Sciences,,TRIHMS. Old Assembly Block. Near Rajiv Gandhi Stadium. Naharlagun-791110. Arunachal Pradesh, Arunachal Pradesh, 791110",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fc2"
    },
    "Rank": 24802,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rampurhat Govt. Medical College and Hospital,\n,RAMPURHAT GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, P.O. RAMPURHAT, P.S. RAMPURHAT, DIST. BIRBHUM, WB, West Bengal, 731224",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fc3"
    },
    "Rank": 24805,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fc4"
    },
    "Rank": 24807,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sparsh Super Specialty Hospital, ,SPARSH SUPER SPECIALITY HOSPITAL, TUMKUR ROAD, YESHWANTHPURA, BANGALORE, Karnataka, 560022",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fc5"
    },
    "Rank": 24818,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Noble Hospital, ,Magarpatta City Road, Hadpsar, Pune, Maharashtra, 411028",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fc6"
    },
    "Rank": 24819,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaipur Golden Hospital, ,2 INSTITUTIONAL AREA, SECTOR-3, ROHINI, Delhi (NCT), 110085",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fc7"
    },
    "Rank": 24825,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Maharashtra,Barshi Road Beed, Maharashtra, 431122",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fc8"
    },
    "Rank": 24831,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fc9"
    },
    "Rank": 24832,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fca"
    },
    "Rank": 24836,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fcb"
    },
    "Rank": 24837,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fcc"
    },
    "Rank": 24838,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fcd"
    },
    "Rank": 24839,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Employee State Insurance Corporation Model Hospital,,Bharat Nagar, Ludhiana, Punjab, 141001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fce"
    },
    "Rank": 24845,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fcf"
    },
    "Rank": 24849,
    "Allotted Quota": "All India",
    "Allotted Institute": "All  India Institute of  Hygiene and Public Health,110, C. R  Avenue, Kolkata, West Bengal, 700073",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fd0"
    },
    "Rank": 24853,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fd1"
    },
    "Rank": 24860,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fd2"
    },
    "Rank": 24864,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fd3"
    },
    "Rank": 24867,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fd4"
    },
    "Rank": 24868,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fd5"
    },
    "Rank": 24873,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jupiter Lifeline Hospital,,Jupiter Lifeline Hospital, Sr No 142/12, And 143/1/4/5, Near Prathamesh Park, Baner, Pune, Maharasht, Maharashtra, 411045",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fd6"
    },
    "Rank": 24876,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fd7"
    },
    "Rank": 24891,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fd8"
    },
    "Rank": 24893,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fd9"
    },
    "Rank": 24896,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fda"
    },
    "Rank": 24897,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Allopathic Medical College, Uttar Pradesh,RANI DURGAVATI MEDICAL COLLEGE NARAINI ROAD BANDA, Uttar Pradesh, 210001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fdb"
    },
    "Rank": 24902,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.R.A. Medical College,\n,Saddarpur Akabarpur Tanda Road Post Surapur\nDistrict Ambedkarnagar, Uttar Pradesh, 224227",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fdc"
    },
    "Rank": 24903,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fdd"
    },
    "Rank": 24907,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fde"
    },
    "Rank": 24909,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Shiv Prasad Gupta Divisional District Hospital, ,Kabir Chaura, Lohatiya, Varanasi, Uttar Pradesh, 221001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fdf"
    },
    "Rank": 24910,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asian Institute of Medical Sciences,,Asian Institute of Medical Sciences, Badhkal Flyover Road,Sector-21A, Faridabad, Haryana-121001, Haryana, 121001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fe0"
    },
    "Rank": 24911,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fe1"
    },
    "Rank": 24913,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fe2"
    },
    "Rank": 24914,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Ramesh Cardiac and Multi Speciality Hospital,,Collector office road, Nagarampalem, Guntur , AP, Andhra Pradesh, 522004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fe3"
    },
    "Rank": 24915,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE,ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, Bihar, 823001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fe4"
    },
    "Rank": 24918,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fe5"
    },
    "Rank": 24919,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fe6"
    },
    "Rank": 24921,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Advanced Medicare and Research Institute (AMRI),,p-4&5, CIT scheme, Block-A, Gariahat Road, Kolkata-700029, West Bengal, West Bengal, 700029",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fe7"
    },
    "Rank": 24922,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fe8"
    },
    "Rank": 24925,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fe9"
    },
    "Rank": 24927,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fea"
    },
    "Rank": 24928,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital for Women, Akola ,,District Hospital for Women  Near Durga Chowk Akola 444001, Maharashtra, 444001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05feb"
    },
    "Rank": 24930,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S.K.Hospital, ,SUPERINTENDENT RNO 36  GOVT SK HOSPITAL  SILVER JUBLI ROAD  SIKAR  RAJ, Rajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fec"
    },
    "Rank": 24932,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ashoka Medicover Hospital, ,Ashoka Medicover Hospitals, Indira Nagar, Opposite to WNS, Wadala Nashik, Maharashtra, 422009",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fed"
    },
    "Rank": 24933,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fee"
    },
    "Rank": 24935,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jagjivan Ram Railway Hospital,,Maratha Mandir Marg, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fef"
    },
    "Rank": 24938,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ff0"
    },
    "Rank": 24939,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ff1"
    },
    "Rank": 24954,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ff2"
    },
    "Rank": 24955,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ff3"
    },
    "Rank": 24964,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kidwai Memorial Institute of Oncolgy,Kidwai Memorial Institute of Oncolgy, Karnataka, 560029",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ff4"
    },
    "Rank": 24968,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Star Women and Children Hospital ,\nTelangana,H No : 4-101 ,Saraswati nagar ,Teegalagutta pally Railway station road , karimnagar., Telangana, 505001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ff5"
    },
    "Rank": 24969,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rabindranath Tagore International Institute of Cardiac Sciences, ,PREMISES NO. 1489 124 MUKUNDAPUR  E M BYPASS  KOLKATA-700099, West Bengal, 700099",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ff6"
    },
    "Rank": 24972,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ff7"
    },
    "Rank": 24977,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lalitha Super Specialty Hospital, ,KOTHAPET GUNTUR, Andhra Pradesh, 522001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ff8"
    },
    "Rank": 24979,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sahyadri Super Specialty Hospital,,Plot No.30C, Erandawane, Karve Road, Pune, Maharashtra, 411004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ff9"
    },
    "Rank": 24980,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sahyadri Narayana Multispecialty Hospital, Harakere,,NT ROAD, HARAKERE , SHIMOGA, Karnataka, 577202",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ffa"
    },
    "Rank": 24984,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ffb"
    },
    "Rank": 24988,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chamarajanagar Institute of Medical Sciences, Karnataka,CHAMARAJANAGAR INSTITUTE OF MEDICAL SCIENCES, SY. NO 124, YEDAPURA VILLAGE KASABA HOBLI, CHAMARAJANA, Karnataka, 571313",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ffc"
    },
    "Rank": 24990,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ffd"
    },
    "Rank": 24992,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05ffe"
    },
    "Rank": 24997,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vivekananda Polyclinic and Institute of Medical Sciences, ,Vivekananda Polyclinic and Institute of Medical Sciences, Vivekananda Puram, Lucknow, Uttar Pradesh, 226007",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a05fff"
    },
    "Rank": 24999,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BharatRatna Dr. Babasaheb Ambedkar Municipal General Hospital, Kandivali (West), Maharashtra,Parekh Nagar, SV Road, Opp. Kandivali Police station, Kandivali West, Mumbai, Maharashtra, 400067",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06000"
    },
    "Rank": 25000,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sanjay Gandhi Postgarduate Institute of Medical Sciences,Sanjay Gandhi Postgarduate Institute of Medical Sciences, Uttar Pradesh, 226014",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06001"
    },
    "Rank": 25001,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06002"
    },
    "Rank": 25002,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06003"
    },
    "Rank": 25003,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06004"
    },
    "Rank": 25006,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06005"
    },
    "Rank": 25009,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaiprakash Hospital and Research Centre Pvt. Ltd, Odisha,,JAIPRAKASH HOSPITAL AND RESEARCH CENTER PVT. LTD., DANDIAPALI, ROURKELA, Odisha, 769004",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06006"
    },
    "Rank": 25010,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Hospital,,Government Hospital Gandhi Nagar Jammu, Jammu And Kashmir, 180004",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06007"
    },
    "Rank": 25013,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06008"
    },
    "Rank": 25015,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ivy Health and Life Sciences,,Ivy Hospital Mohali, Sector 71 SAS Nagar, Punjab, 160071",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06009"
    },
    "Rank": 25016,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0600a"
    },
    "Rank": 25018,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0600b"
    },
    "Rank": 25020,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0600c"
    },
    "Rank": 25022,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Amar Hospital, ,#8, Bank Colony, Income Tax Office Road, Patiala, Punjab, 147001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0600d"
    },
    "Rank": 25023,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MENTAL HEALTH AND HOSPITAL, AGRA,INSTITUTE OF MENTAL HEALTH AND HOSPITAL, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0600e"
    },
    "Rank": 25027,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharashtra Post Graduate Institute of Medical Education and Research, Nashik,Maharashtra Post Graduation Institute Of Medical Education and Research Civil Hospital campus Trimba, Maharashtra, 422002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0600f"
    },
    "Rank": 25031,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06010"
    },
    "Rank": 25032,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL CANCER CENTRE,REGIONAL CANCER CENTRE, Kerala, 695011",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06011"
    },
    "Rank": 25038,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06012"
    },
    "Rank": 25040,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06013"
    },
    "Rank": 25042,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06014"
    },
    "Rank": 25045,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "APOLLO SPECTRA HOSPITALS ,H. No. 1-7-70 to 73, Mashallah Building, Paradise Circle, S. D Road, Secunderabad, Telangana, 500003",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06015"
    },
    "Rank": 25047,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06016"
    },
    "Rank": 25050,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, (A unit of Balaji Medical and Diagnostic Research Centre)\n,Max Super Specialty Hospital -108A IP Extension Patparganj New Delhi, Delhi (NCT), 110092",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06017"
    },
    "Rank": 25051,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medica Superspecialty Hospital, ,127 Mukundapur, E.M. Byapss, Kolkata, West Bengal, 700099",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06018"
    },
    "Rank": 25056,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06019"
    },
    "Rank": 25057,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaipur Golden Hospital, ,2 INSTITUTIONAL AREA, SECTOR-3, ROHINI, Delhi (NCT), 110085",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0601a"
    },
    "Rank": 25058,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sahyadri Narayana Multispecialty Hospital, Harakere,,NT ROAD, HARAKERE , SHIMOGA, Karnataka, 577202",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0601b"
    },
    "Rank": 25062,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0601c"
    },
    "Rank": 25064,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sparsh Super Specialty Hospital, ,SPARSH SUPER SPECIALITY HOSPITAL, TUMKUR ROAD, YESHWANTHPURA, BANGALORE, Karnataka, 560022",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0601d"
    },
    "Rank": 25069,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0601e"
    },
    "Rank": 25071,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shree Krishna Hospital associated with Paramukhswami Medical College, Gujarat,Gokalnagar, Karamsad, Dist. Anand, Gujarat, 388325",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0601f"
    },
    "Rank": 25078,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06020"
    },
    "Rank": 25079,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, Shalimar Bagh,A- Block, Shalimar Bagh, Delhi (NCT), 110088",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06021"
    },
    "Rank": 25084,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06022"
    },
    "Rank": 25085,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06023"
    },
    "Rank": 25087,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06024"
    },
    "Rank": 25088,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhandari Hospital and Research Centre, ,138 A VASUNDHRA COLONY GOPAL PURA BYPASS TONK ROAD JAIPUR, Rajasthan, 302018",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06025"
    },
    "Rank": 25093,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaipur Golden Hospital, ,2 INSTITUTIONAL AREA, SECTOR-3, ROHINI, Delhi (NCT), 110085",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06026"
    },
    "Rank": 25101,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06027"
    },
    "Rank": 25107,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Bangalore Hospital,,#202, R.V. Road, Bangalore, Karnataka, 560004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06028"
    },
    "Rank": 25108,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital Whitefield,,ITPL Main Rd, KIADB Export Promotion Industrial Area, Krishnarajapura, Bengaluru, Karnataka, 560066",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06029"
    },
    "Rank": 25109,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, Shalimar Bagh,A- Block, Shalimar Bagh, Delhi (NCT), 110088",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0602a"
    },
    "Rank": 25113,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster CMI Hospital, ,43/2 New Airport Road, NH-7, Kodigehalli, Sahakara Nagar, Hebbal, Bengaluru, Karnataka, 560092",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0602b"
    },
    "Rank": 25114,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Civil Hospital,Civil Hospital Barnala, Ram Bag Road, Barnala, Punjab, 148101",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0602c"
    },
    "Rank": 25115,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Cauvery Heart and Multispeciality Hospital, ,BANNUR ROAD, NEAR TERESIAN CIRCLE,MYSORE, Karnataka, 570029",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0602d"
    },
    "Rank": 25117,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bombay Hospital,Ring Road Indore, Madhya Pradesh, 452010",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0602e"
    },
    "Rank": 25123,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agrasen Medical College,,NH- 9, VPO - Agroha, District Hisar, Haryana, 125047",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0602f"
    },
    "Rank": 25131,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rangadore Memorial Hospital,,1ST CROSS, SHANKARAPURAM, BANGALORE, Karnataka, 560004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06030"
    },
    "Rank": 25133,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindustan Aeronautics Hospital, ,SURANJANDAS ROAD ,VIMANAPURA POST BANGALORE -560017, Karnataka, 560017",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06031"
    },
    "Rank": 25138,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollomedics Superspeciality Hospital,KBC 31, Sector B, LDA Colony, Kanpur Road, Lucknow, Uttar Pradesh, 226012",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06032"
    },
    "Rank": 25139,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06033"
    },
    "Rank": 25143,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bansal Hospital,,C- Sector Shahpura, Bhopal, Madhya Pradesh, 462016",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06034"
    },
    "Rank": 25144,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pimpri Chichwad Municipal Corporation Postgraduate Institute Y.C.M.Hospital Pimpri,Pune 18,Dean Office, Y.C.M.Hospital, Sant Tukaramnagar Pimpri, Pune, Maharashtra, 411018",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06035"
    },
    "Rank": 25148,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Sector-62, Phase VIII, Mohali- 160062, Punjab, 160062",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06036"
    },
    "Rank": 25152,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital,,DNB office, 3rd Floor Opp Library  Apollo Hospitals, Jubilee Hills, Hyderabad, Telangana, 500033",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06037"
    },
    "Rank": 25160,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, ,1,2, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06038"
    },
    "Rank": 25161,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESI Hospital, ,IG ESI HOSPITAL JHILMIL DELHI - 95, Delhi (NCT), 110095",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06039"
    },
    "Rank": 25163,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0603a"
    },
    "Rank": 25164,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS Medical College Valsad,Nanakwada Halar Road,Valsad ,Gujarat, Gujarat, 396001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0603b"
    },
    "Rank": 25166,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0603c"
    },
    "Rank": 25171,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0603d"
    },
    "Rank": 25183,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Datia,Near 29 Batallion, National Highway 44, Dist Datia, Madhya Pradesh Pin - 475661, Madhya Pradesh, 475661",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0603e"
    },
    "Rank": 25184,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0603f"
    },
    "Rank": 25185,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06040"
    },
    "Rank": 25187,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "College of Medicine and JNM Hospital,,Kalyani, Silpanchal, West Bengal, 741235",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06041"
    },
    "Rank": 25190,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute of Medical Sciences Srikakulam,Government Medical College Srikakulam\nBalaga Srikakulam Town Srikakulam District, Andhra Pradesh, 532001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06042"
    },
    "Rank": 25198,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Andhra Pradesh\n,DISTRICT HOSPITAL MADANAPALLE, Andhra Pradesh, 517325",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06043"
    },
    "Rank": 25203,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM Health Care Private Limited, Tamil Nadu,New No 72, Old No 54, Nelson Manickam Road, Aminjikarai, Chennai – 600029 Tamilnadu State, Tamil Nadu, 600029",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06044"
    },
    "Rank": 25210,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bansal Hospital,,C- Sector Shahpura, Bhopal, Madhya Pradesh, 462016",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06045"
    },
    "Rank": 25212,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindu Rao Hospital, ,SUBZI MANDI, MALKA GANJ, DELHI-110007, Delhi (NCT), 110007",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06046"
    },
    "Rank": 25216,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06047"
    },
    "Rank": 25220,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06048"
    },
    "Rank": 25221,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06049"
    },
    "Rank": 25222,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0604a"
    },
    "Rank": 25225,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Noble Hospital, ,Magarpatta City Road, Hadpsar, Pune, Maharashtra, 411028",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0604b"
    },
    "Rank": 25226,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0604c"
    },
    "Rank": 25229,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Choithram Hospital and Research Centre,Manika Bagh Road Indore, Madhya Pradesh, 452014",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0604d"
    },
    "Rank": 25230,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0604e"
    },
    "Rank": 25232,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0604f"
    },
    "Rank": 25237,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06050"
    },
    "Rank": 25242,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Shankara Cancer Hospital and Research centre, ,1st Cross, Shankarapuram, Basavanagudi, Bengaluru, Karnataka, 560004",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06051"
    },
    "Rank": 25244,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Karnataka,Kodagu Institute of Medical Sciences Madikeri Kodagu, Karnataka, 571201",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06052"
    },
    "Rank": 25247,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Balaji Action Medical Institute,,FC34 A4 PASCHIM VIHAR NEW DELHI, Delhi (NCT), 110063",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06053"
    },
    "Rank": 25248,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Vinoba Bhave Civil Hospital,,S R Delkar Marg, Saily Road, Silvassa, Dadra And Nagar Haveli, 396230",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06054"
    },
    "Rank": 25249,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06055"
    },
    "Rank": 25252,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06056"
    },
    "Rank": 25254,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06057"
    },
    "Rank": 25255,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sagar Hospital, Karnataka,No. 44/54, 30th Cross, Tilak Nagar, Jayanagar Extn., Bangalore, Karnataka-560041",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06058"
    },
    "Rank": 25258,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06059"
    },
    "Rank": 25273,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Metro Heart Institute with Multispecialty, ,SECTOR 16A, Faridabad, Haryana, Haryana, 121002",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0605a"
    },
    "Rank": 25277,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jupiter Hospital, ,Eastern Express Highway, Thane West, Maharashtra, 400601",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0605b"
    },
    "Rank": 25281,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0605c"
    },
    "Rank": 25286,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aditya Birla Memorial Hospital, Maharashtra,Aditya Birla Hospital Marg, P.O.- Chinchwad, Pune, Maharashtra, 411033",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0605d"
    },
    "Rank": 25295,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhima Bhoi Medical College and Hospital, Balangir,At-Laltikra Po-Rajendra College, Balangir, Odisha, 767002",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0605e"
    },
    "Rank": 25306,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0605f"
    },
    "Rank": 25307,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Ramesh Cardiac and Multi Speciality Hospital,,Collector office road, Nagarampalem, Guntur , AP, Andhra Pradesh, 522004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06060"
    },
    "Rank": 25309,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Rangarajan Memorial Hospital, (Sundaram Medical Foundation) ,Sundaram Medical Foundation, Shanthi Colony, 4th Avenue, Anna Nagar, Chennai, Tamil Nadu, 600040",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06061"
    },
    "Rank": 25311,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06062"
    },
    "Rank": 25313,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences Ltd, ,Dargamitta, Nellore, Andhra Pradesh, 524003",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06063"
    },
    "Rank": 25318,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06064"
    },
    "Rank": 25320,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tricolour Hospital, ,Dr. Vikram Sarabhai Road, near Genda Circle, Vadodara, Gujarat, 390007",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06065"
    },
    "Rank": 25322,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06066"
    },
    "Rank": 25325,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06067"
    },
    "Rank": 25328,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "HealthCare Global Specialty Hospital, (Formerly Bangalore Institute of Oncology) ,HCG Towers, No. 8, P Kalinga Rao Road, Sampangi Ram Nagar, Bangalore, Karnataka, 560027",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06068"
    },
    "Rank": 25330,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06069"
    },
    "Rank": 25334,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0606a"
    },
    "Rank": 25338,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0606b"
    },
    "Rank": 25340,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahatma Gandhi Cancer Hospital and Research Institute,,Plot No.1\\7, MVP COLONY, VISAKHAPATNAM, Andhra Pradesh, 530017",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0606c"
    },
    "Rank": 25342,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0606d"
    },
    "Rank": 25344,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0606e"
    },
    "Rank": 25350,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaiprakash Hospital and Research Centre Pvt. Ltd, Odisha,,JAIPRAKASH HOSPITAL AND RESEARCH CENTER PVT. LTD., DANDIAPALI, ROURKELA, Odisha, 769004",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0606f"
    },
    "Rank": 25352,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jabalpur Hospital and Research Centre, Jabalpur,\nMadhya Pradesh,Russel Chowk Napier Town Jabalpur, Madhya Pradesh, 482002",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06070"
    },
    "Rank": 25355,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lakshmi Madhavan Hospital, Tirunelveli, Tamil Nadu,4A, South Byepass Road, Near BSNL, Vannarpettai, Tamil Nadu, 627003",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06071"
    },
    "Rank": 25357,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Area Hospital, ,Thirupathi Road Near Saibaba Temple, Area Hospital, Nagari Chittoor Dist, Andhra Pradesh, 517590",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06072"
    },
    "Rank": 25358,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06073"
    },
    "Rank": 25362,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06074"
    },
    "Rank": 25367,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SDH KUPWARA, Jammu and Kashmir,IQBAL MARKET KUPWARA, Jammu And Kashmir, 193222",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06075"
    },
    "Rank": 25368,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06076"
    },
    "Rank": 25370,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06077"
    },
    "Rank": 25379,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06078"
    },
    "Rank": 25382,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Escorts Hospital, ,214, Jawahar Lal Nehru Marg, Malviya Nagar, Jaipur, Rajasthan, 302017",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06079"
    },
    "Rank": 25387,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaypee Hospital,,sector 128, Noida, Uttar Pradesh, 201304",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0607a"
    },
    "Rank": 25391,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "DISTRICT HOSPITAL ,\nChhattisgarh,Room No. 122 - Government District Hospital  Near Mandi Gate Pandari, Raipur, Chhattisgarh, 492001, , Chhattisgarh, 492001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0607b"
    },
    "Rank": 25392,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District hospital chitradurga, B D road beside DHO office Chitradurga, Karnataka, 577501",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0607c"
    },
    "Rank": 25395,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,RAJBHAVAN ROAD SOMAJIGUDA, Telangana, 500082",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0607d"
    },
    "Rank": 25398,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0607e"
    },
    "Rank": 25399,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0607f"
    },
    "Rank": 25403,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06080"
    },
    "Rank": 25404,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "APOLLO SPECTRA HOSPITALS ,H. No. 1-7-70 to 73, Mashallah Building, Paradise Circle, S. D Road, Secunderabad, Telangana, 500003",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06081"
    },
    "Rank": 25406,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06082"
    },
    "Rank": 25411,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06083"
    },
    "Rank": 25412,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06084"
    },
    "Rank": 25416,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Noble Hospital, ,Magarpatta City Road, Hadpsar, Pune, Maharashtra, 411028",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06085"
    },
    "Rank": 25418,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06086"
    },
    "Rank": 25419,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. Medical College, ,Govt. Medical College, B-5 Medical Enclave, Maheshpura Bakshi Nagar, Jammu, Jammu And Kashmir, 180001",
    "Course": "(NBEMS) Hospital Administration",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06087"
    },
    "Rank": 25420,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06088"
    },
    "Rank": 25425,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06089"
    },
    "Rank": 25429,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0608a"
    },
    "Rank": 25432,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0608b"
    },
    "Rank": 25445,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Central Hospital, ,CENTRAL HOSPITAL, NORTH WESTERN RAILWAY, GANPATI NAGAR, HASANPURA ROAD, JAIPUR, Rajasthan, 302006",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0608c"
    },
    "Rank": 25453,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kamla Nehru Memorial Hospital, 1, ,1, HASHIMPUR ROAD, PRAYAGRAJ, Uttar Pradesh, 211002",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0608d"
    },
    "Rank": 25456,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0608e"
    },
    "Rank": 25460,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,Plotno-251,Sainik school Road,Unit-15 Apollo Hospitals , Bhubaneswar, Odisha, 751005",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0608f"
    },
    "Rank": 25463,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Star Women and Children Hospital ,\nTelangana,H No : 4-101 ,Saraswati nagar ,Teegalagutta pally Railway station road , karimnagar., Telangana, 505001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06090"
    },
    "Rank": 25468,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06091"
    },
    "Rank": 25472,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06092"
    },
    "Rank": 25476,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,SECTOR-6, PANCHKULA, HARYANA-134108, Haryana, 134108",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06093"
    },
    "Rank": 25477,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06094"
    },
    "Rank": 25478,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhattar Hospital (Balgopal Children Hospital and Research Institute), ,BYRON BAZAR, OPP ASHIRWAD BHAVAN, RAIPUR, Chhattisgarh, 492001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06095"
    },
    "Rank": 25485,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06096"
    },
    "Rank": 25486,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Bangur Hospital, Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan , Rajasthan-306401,Government Medical College and Bangur Hospital Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan Ra, Rajasthan, 306401",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06097"
    },
    "Rank": 25490,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dharwad,dsdharwad at gmail dot com, Karnataka, 580001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06098"
    },
    "Rank": 25491,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Ramesh Cardiac and Multi Speciality Hospital,,Collector office road, Nagarampalem, Guntur , AP, Andhra Pradesh, 522004",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06099"
    },
    "Rank": 25494,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0609a"
    },
    "Rank": 25496,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0609b"
    },
    "Rank": 25497,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0609c"
    },
    "Rank": 25498,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhabha Atomic Research Centre and Hospital, ,Anushaktinagar, Mumbai, Maharashtra, 400094",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0609d"
    },
    "Rank": 25501,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo KH Hospital, Tamil Nadu,Apollo KH Hospital, Ranipet - Vellore Bypass Road, Melvisharam, Ranipet District, Tamil Nadu., Tamil Nadu, 632509",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0609e"
    },
    "Rank": 25502,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0609f"
    },
    "Rank": 25508,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060a0"
    },
    "Rank": 25509,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Felix Hospital,\n,NH-01 SECTOR 137 NOIDA EXPRESSWAY UP, Uttar Pradesh, 201305",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060a1"
    },
    "Rank": 25511,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "FAKIR MOHAN MEDICAL COLLEGE AND HOSPITAL ,\nOrissa,AT-Remuna Post-Remuna Dist-Balasore, Odisha, 756019",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060a2"
    },
    "Rank": 25513,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District General Hospital, ,O/o. Medical Superintendent, Government District General Hospital, Contonment, Vizianagaram, Andhra Pradesh,\n535003",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060a3"
    },
    "Rank": 25515,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ananthapuri Hospital and Research Institute, ,Chacka, NH Bypass, Thiruvananthapuram 695024, Kerala, 695024",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060a4"
    },
    "Rank": 25516,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Endoworld Hospital Private Limited, Maharashtra,723, In front of Airport, Chikalthana, Aurangabad, Maharashtra, Maharashtra, 431006",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060a5"
    },
    "Rank": 25518,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government Women and Children Hospital, (Formerly Indira Gandhi.\nGeneral Hospital) ,100 feet road, Ellaipillaichavady, Puducherry, 605005",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060a6"
    },
    "Rank": 25520,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Giridhar Eye Institute , ,Ponneth Temple Road Kadavanthra\nCochin, Kerala, 682020",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060a7"
    },
    "Rank": 25521,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kainos Super ,Kainos Super Speciality Hospital, Kheri Sadh Bypass, Delhi Road, Rohtak, Haryana, 124021",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060a8"
    },
    "Rank": 25523,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060a9"
    },
    "Rank": 25533,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,LOTUS TOWER, G S ROAD, GUWAHATI, Assam, 781005",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060aa"
    },
    "Rank": 25534,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060ab"
    },
    "Rank": 25539,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sahyadri Super Specialty Hospital,,Plot No.30C, Erandawane, Karve Road, Pune, Maharashtra, 411004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060ac"
    },
    "Rank": 25541,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060ad"
    },
    "Rank": 25543,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060ae"
    },
    "Rank": 25553,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060af"
    },
    "Rank": 25557,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060b0"
    },
    "Rank": 25559,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060b1"
    },
    "Rank": 25566,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "DISTRICT HOSPITAL ,\nChhattisgarh,Room No. 122 - Government District Hospital  Near Mandi Gate Pandari, Raipur, Chhattisgarh, 492001, , Chhattisgarh, 492001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060b2"
    },
    "Rank": 25568,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060b3"
    },
    "Rank": 25570,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Escorts Hospital,,Fortis escorts hospital ,neelam bata road, faridabad, Haryana, 121002",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060b4"
    },
    "Rank": 25571,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060b5"
    },
    "Rank": 25572,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060b6"
    },
    "Rank": 25573,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060b7"
    },
    "Rank": 25574,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Cure Well Hospital, Warangal, Telangana,15-1-48, SRINIVASA COLONY,\nOPP-MGM HOSPITAL WARANGAL\nTELANGANA 506007, Telangana, 506007",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060b8"
    },
    "Rank": 25575,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060b9"
    },
    "Rank": 25578,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060ba"
    },
    "Rank": 25582,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060bb"
    },
    "Rank": 25584,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060bc"
    },
    "Rank": 25601,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060bd"
    },
    "Rank": 25603,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Cauvery Heart and Multispeciality Hospital, ,BANNUR ROAD, NEAR TERESIAN CIRCLE,MYSORE, Karnataka, 570029",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060be"
    },
    "Rank": 25607,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Baba Saheb Ambedkar Hospital, ,SEC 6 ROHINI DELHI, Delhi (NCT), 110085",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060bf"
    },
    "Rank": 25618,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Charak Hospital and Research Center, Uttar Pradesh,Hardoi Road, Dubagga, Lucknow, Uttar Pradesh, 226003",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060c0"
    },
    "Rank": 25619,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,10 Biplabi Haren Ghosh Sarani Howrah, West Bengal, 711101",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060c1"
    },
    "Rank": 25622,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060c2"
    },
    "Rank": 25625,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060c3"
    },
    "Rank": 25627,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ananthapuri Hospital and Research Institute, ,Chacka, NH Bypass, Thiruvananthapuram 695024, Kerala, 695024",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060c4"
    },
    "Rank": 25632,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060c5"
    },
    "Rank": 25636,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060c6"
    },
    "Rank": 25649,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "VCSG Govt Institute of Medical Science and Research and HNB Base Teaching Hospital\n, ,Srikot Ganganali Srinagar Garhwal Uttarakhand, Uttarakhand, 246174",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060c7"
    },
    "Rank": 25655,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060c8"
    },
    "Rank": 25656,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rabindranath Tagore International Institute of Cardiac Sciences, ,PREMISES NO. 1489 124 MUKUNDAPUR  E M BYPASS  KOLKATA-700099, West Bengal, 700099",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060c9"
    },
    "Rank": 25659,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. GERIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060ca"
    },
    "Rank": 25665,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Anand Charitable Trusts Anand Hospital, Maharashtra,Anand Hospital, Phulewadi Road, Vaijapur Dist. Aurangabad, Maharashtra, 423701",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060cb"
    },
    "Rank": 25666,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060cc"
    },
    "Rank": 25669,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, (Formerly- Pushpanjali Crosslay Hospital) ,W-3, Sector-1, Vaishali, Ghaziabad, Uttar Pradesh, 201012",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060cd"
    },
    "Rank": 25670,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sagar Hospital, Karnataka,No. 44/54, 30th Cross, Tilak Nagar, Jayanagar Extn., Bangalore, Karnataka-560041",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060ce"
    },
    "Rank": 25672,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI MEDICAL COLLEGE KALWA THANE,RAJIV GANDHI MEDICAL COLLEGE,THANE BELAPUR ROAD, KALWA, THANE, Maharashtra, 400605",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060cf"
    },
    "Rank": 25674,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060d0"
    },
    "Rank": 25675,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT DHARMAPURI MEDICAL COLLEGE, DHARMAPURI, TAMIL NADU,GOVERNMENT DHARMAPURI MEDICAL COLLEGE, NETHAJI BYE PASS ROAD, DHARMAPURI, TAMIL NADU, Tamil Nadu, 636701",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060d1"
    },
    "Rank": 25677,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "O P Jindal Institute Of Cancer and Cardiac Research, Haryana,Model Town, Hisar, Haryana, Haryana, 125005",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060d2"
    },
    "Rank": 25678,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060d3"
    },
    "Rank": 25681,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Bangur Hospital, Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan , Rajasthan-306401,Government Medical College and Bangur Hospital Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan Ra, Rajasthan, 306401",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060d4"
    },
    "Rank": 25684,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060d5"
    },
    "Rank": 25685,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bombay Hospital,Ring Road Indore, Madhya Pradesh, 452010",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060d6"
    },
    "Rank": 25689,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060d7"
    },
    "Rank": 25694,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan , Rajasthan-311001,Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan, Rajasthan, 311001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060d8"
    },
    "Rank": 25702,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060d9"
    },
    "Rank": 25704,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.G. Hospital and PG Medical Institute ,,No:5, Arts College Road, Coimbatore - 641018, Tamil Nadu, 641018",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060da"
    },
    "Rank": 25705,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sparsh Hospital, Karnataka,Ideal Homes HBCS Layout, 8, 4th Cross Rd, Javarandoddi, Rajarajeshwari Nagar, Bengaluru, Karnataka, 560098",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060db"
    },
    "Rank": 25709,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060dc"
    },
    "Rank": 25713,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Seven Hills Hospital, ,11-4-4/A, Rockdale Layout, Visakhapatnam, Andhra Pradesh, 530002",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060dd"
    },
    "Rank": 25717,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ispat General Hospital,,Ispat General Hospital Rourkela Steel Plant SAIL Rourkela 769005, Odisha, 769005",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060de"
    },
    "Rank": 25720,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060df"
    },
    "Rank": 25722,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital Faridkot,\nCivil Hospital Faridkot, Punjab,Railway Road Opposite to DC Office, Punjab, 151203",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060e0"
    },
    "Rank": 25724,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.B.Borooah Cancer Institute,Dr.B.Borooah Cancer Institute, Assam, 781016",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060e1"
    },
    "Rank": 25725,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chandan Hospital, ,Vijayant Khand, Gomti Nagar, Lucknow, Uttar Pradesh, 226010",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060e2"
    },
    "Rank": 25728,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Escorts Hospital,,Fortis escorts hospital ,neelam bata road, faridabad, Haryana, 121002",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060e3"
    },
    "Rank": 25729,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060e4"
    },
    "Rank": 25732,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rabindranath Tagore International Institute of Cardiac Sciences, ,PREMISES NO. 1489 124 MUKUNDAPUR  E M BYPASS  KOLKATA-700099, West Bengal, 700099",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060e5"
    },
    "Rank": 25734,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060e6"
    },
    "Rank": 25735,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060e7"
    },
    "Rank": 25737,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kauvery Hospital, ,No.199, Luz Church road, Mylapore,Chennai-600004, Tamil Nadu, 600004",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060e8"
    },
    "Rank": 25745,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060e9"
    },
    "Rank": 25746,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060ea"
    },
    "Rank": 25747,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060eb"
    },
    "Rank": 25750,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Village Lingiadih,,Apollo Hospitals Bilaspur Seepat Road  Lingyadih Village  Bilaspur, Chhattisgarh, 495006",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060ec"
    },
    "Rank": 25751,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital Bhandara,\nMaharashtra,Santaji Nagar, District Hospital, Bhandara, Maharashtra, 441904",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060ed"
    },
    "Rank": 25752,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, (Formerly- Pushpanjali Crosslay Hospital) ,W-3, Sector-1, Vaishali, Ghaziabad, Uttar Pradesh, 201012",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060ee"
    },
    "Rank": 25753,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060ef"
    },
    "Rank": 25754,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060f0"
    },
    "Rank": 25756,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Shankara Cancer Hospital and Research centre, ,1st Cross, Shankarapuram, Basavanagudi, Bengaluru, Karnataka, 560004",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060f1"
    },
    "Rank": 25759,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060f2"
    },
    "Rank": 25764,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Quality Care India Limited (Care Hospital), (Formerly Visakha Hospital and Diagnostics (Care Hospital)) ,10-5-11\\5 A S Raja Complex\nRamnagar Waltair Main Road Visakhapatnam, Andhra Pradesh, 530002",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060f3"
    },
    "Rank": 25765,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060f4"
    },
    "Rank": 25766,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060f5"
    },
    "Rank": 25772,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060f6"
    },
    "Rank": 25778,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "YATHARTH SUPER SPECIALTY HOSPITALS,\nUttar Pradesh,Sector omega 01-, Greater noida, Uttar Pradesh, 201308",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060f7"
    },
    "Rank": 25799,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "B.R. Singh Hospital and Centre for Medical Education and Research,,B.R.Singh Hospital Eastern Railway, Sealdah, Kolkata, West Bengal, 700014",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060f8"
    },
    "Rank": 25807,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kanumuri Hospital, Andhra Pradesh,D-NO 12 , SANAKKAYALA FACTORY ROAD , KOTHAPET , GUNTUR, Andhra Pradesh, 522001",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060f9"
    },
    "Rank": 25808,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vishnuprasad Nandrai Desai Municipal General Hospital, Maharashtra,11 TH GOLIBAR ROAD TPS,SANTACRUZ EAST, Maharashtra, 400055",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060fa"
    },
    "Rank": 25809,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Queen`s NRI Hospital, (A Unit of M/s Chalasani Hospitals Pvt. Ltd.) ,Gurudwara Lane, Seethammadhra,\nVisakhapatnam, Andhra Pradesh, 530013",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060fb"
    },
    "Rank": 25810,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,DISTRICT HOSPITAL NEAR SANGAM CIRCLE DR RAJ KUMAR ROAD BALLARI-583101 KARNATAKA, Karnataka, 583101",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060fc"
    },
    "Rank": 25812,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Rangarajan Memorial Hospital, (Sundaram Medical Foundation) ,Sundaram Medical Foundation, Shanthi Colony, 4th Avenue, Anna Nagar, Chennai, Tamil Nadu, 600040",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060fd"
    },
    "Rank": 25814,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060fe"
    },
    "Rank": 25818,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a060ff"
    },
    "Rank": 25819,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, Andhra Pradesh,near varadhi, Tadepalli, Guntur -522501, Andhra Pradesh, 522501",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06100"
    },
    "Rank": 25823,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06101"
    },
    "Rank": 25824,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Andhra Pradesh,District Hospital Machilipatnam House No  30-21-12-M Block No 30 Revenue ward no 30 Locality  Machil, Andhra Pradesh, 521001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06102"
    },
    "Rank": 25827,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR GOVERNMENT MEDICAL COLLEGE,NELLORE,Opp AC Subbareddy Stadium\nDargamitta Nellore, Andhra Pradesh, 524004",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06103"
    },
    "Rank": 25829,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Regency Hospital,,A-2, Sarvodaya Nagar, Kanpur, Uttar Pradesh, 208005",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06104"
    },
    "Rank": 25836,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "IQRAA HOSPITAL , Kerala\n,KOTTAKKUNNU SULTHAN BATHERY WAYANAD 673592, Kerala, 673592",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06105"
    },
    "Rank": 25837,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhavsinhji and Maharani Rupaliba General Hospital, ,Near S. T. Bus Stand, Opp.\nBhutnath Temple, Hospital Road, Porbandar, Gujarat, 360575",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06106"
    },
    "Rank": 25838,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06107"
    },
    "Rank": 25844,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06108"
    },
    "Rank": 25845,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yatharth Superspeciality Hospitals,\nUttar Pradesh,HO-01, Sector-01, Greater Noida West, Uttar Pradesh, 201306",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06109"
    },
    "Rank": 25846,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahavir Cancer Sansthan and Research Centre, ,Phulwarisharif, Patna, Bihar, 801505",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0610a"
    },
    "Rank": 25847,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ispat General Hospital,,Ispat General Hospital Rourkela Steel Plant SAIL Rourkela 769005, Odisha, 769005",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0610b"
    },
    "Rank": 25850,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "JLNM Hospital, ,JLNM Hospital Rainawari Srinagar, Jammu And Kashmir, 190003",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0610c"
    },
    "Rank": 25851,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0610d"
    },
    "Rank": 25856,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0610e"
    },
    "Rank": 25857,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KIMS Saveera Hospital,1-1348 OPPOSITE SAKSHI OFFICE RUDRAMPET ANANTAPURAM 515004, Andhra Pradesh, 515004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0610f"
    },
    "Rank": 25858,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS Medical College Valsad,Nanakwada Halar Road,Valsad ,Gujarat, Gujarat, 396001",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06110"
    },
    "Rank": 25861,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06111"
    },
    "Rank": 25864,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06112"
    },
    "Rank": 25869,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Cancer Institute (WIA),\nNo:38, Tamil Nadu,Regional Centre for Cancer Research and Treatment, Cancer Institute (WIA), No.38 Sardar Patel Road,C, Tamil Nadu, 600036",
    "Course": "(NBEMS) Palliative Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06113"
    },
    "Rank": 25871,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06114"
    },
    "Rank": 25873,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. Medical College (Maharani Hospital), Jagdalpur, Chhattisgarh,Late Baliram Kashyap memorial Govt. Medical College, Dimrapal, Jagdalpur Dist-Bastar, Chhattisgarh, 494001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06115"
    },
    "Rank": 25875,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sagar Hospital - ,Shavige Malleshwara Hills, Kumaraswamy Layout, Bangalore, Karnataka, 560078",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06116"
    },
    "Rank": 25878,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Venkateshwar Hospital, ,Sector- 18 A, Dwarka, New Delhi, Delhi (NCT), 110075",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06117"
    },
    "Rank": 25879,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06118"
    },
    "Rank": 25880,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Queen`s NRI Hospital, (A Unit of M/s Chalasani Hospitals Pvt. Ltd.) ,Gurudwara Lane, Seethammadhra,\nVisakhapatnam, Andhra Pradesh, 530013",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06119"
    },
    "Rank": 25881,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0611a"
    },
    "Rank": 25887,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Cancer Institute (WIA),\nNo:38, Tamil Nadu,Regional Centre for Cancer Research and Treatment, Cancer Institute (WIA), No.38 Sardar Patel Road,C, Tamil Nadu, 600036",
    "Course": "(NBEMS) Palliative Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0611b"
    },
    "Rank": 25892,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0611c"
    },
    "Rank": 25898,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital,,B-22, Sector 62, Noida, Uttar Pradesh, Uttar Pradesh, 201301",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0611d"
    },
    "Rank": 25910,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta The Medicity, ,Medanta The Medicity Sector 38 Gurgoan, Haryana, 122001",
    "Course": "(NBEMS) MICROBIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0611e"
    },
    "Rank": 25913,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0611f"
    },
    "Rank": 25922,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollomedics Superspeciality Hospital,KBC 31, Sector B, LDA Colony, Kanpur Road, Lucknow, Uttar Pradesh, 226012",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06120"
    },
    "Rank": 25927,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Distric Hopital Gwalior, Madhya Pradesh,Mall Road Morar Gwalior, Madhya Pradesh, 474006",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06121"
    },
    "Rank": 25933,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital , Jammu and Kashmir\n,Court road pulwama, Jammu And Kashmir, 192301",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06122"
    },
    "Rank": 25935,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06123"
    },
    "Rank": 25937,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06124"
    },
    "Rank": 25939,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahavir Cancer Sansthan and Research Centre, ,Phulwarisharif, Patna, Bihar, 801505",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06125"
    },
    "Rank": 25945,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06126"
    },
    "Rank": 25947,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SBLS Civil Hospital, ,NEAR JYOTI CHOWK JAIL ROAD JALANDHAR, Punjab, 144001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06127"
    },
    "Rank": 25948,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06128"
    },
    "Rank": 25949,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06129"
    },
    "Rank": 25951,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Sathya Sai General Hospital, ,MAINROAD, PRASANTHI NILAYAM, PUTTAPARTHI, DISTRRICT ANATAPURAM, Andhra Pradesh, 515134",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0612a"
    },
    "Rank": 25954,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0612b"
    },
    "Rank": 25956,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aditya Birla Memorial Hospital, Maharashtra,Aditya Birla Hospital Marg, P.O.- Chinchwad, Pune, Maharashtra, 411033",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0612c"
    },
    "Rank": 25958,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0612d"
    },
    "Rank": 25959,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KIMS Saveera Hospital,1-1348 OPPOSITE SAKSHI OFFICE RUDRAMPET ANANTAPURAM 515004, Andhra Pradesh, 515004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0612e"
    },
    "Rank": 25960,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0612f"
    },
    "Rank": 25968,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06130"
    },
    "Rank": 25969,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhandari Hospital and Research Centre, ,138 A VASUNDHRA COLONY GOPAL PURA BYPASS TONK ROAD JAIPUR, Rajasthan, 302018",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06131"
    },
    "Rank": 25974,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06132"
    },
    "Rank": 25979,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06133"
    },
    "Rank": 25981,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bombay Hospital,Ring Road Indore, Madhya Pradesh, 452010",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06134"
    },
    "Rank": 25985,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06135"
    },
    "Rank": 25986,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "RAJA PRATAP BAHADUR HOSPITAL ALLIED WITH DR SONELAL PATEL AUTONOMOUS STATE MEDICAL COLLEGE,\nUttar Pradesh,PURE KESHAV RAI GAIGHAT ROAD, PRATAPGARH, Uttar Pradesh, 230001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06136"
    },
    "Rank": 25991,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yatharth Superspeciality Hospitals,\nUttar Pradesh,HO-01, Sector-01, Greater Noida West, Uttar Pradesh, 201306",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06137"
    },
    "Rank": 25993,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Surya Mother and Child Care Jaipur Pvt Ltd.,\n,B-7, Sawai Ram SIngh Road, Opp SMS Hospital, Jaipur, Rajasthan, 302001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06138"
    },
    "Rank": 26001,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06139"
    },
    "Rank": 26007,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0613a"
    },
    "Rank": 26009,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medicare Multispeciality Hospital,medicarepusad@gmail.com, Maharashtra, 445204",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0613b"
    },
    "Rank": 26015,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tirumala Hospital, ,PSR COMPLEX, NEAR RTC COMPLEX, VIZIANAGARAM, Andhra Pradesh, 535003",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0613c"
    },
    "Rank": 26018,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0613d"
    },
    "Rank": 26020,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0613e"
    },
    "Rank": 26029,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0613f"
    },
    "Rank": 26036,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06140"
    },
    "Rank": 26039,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06141"
    },
    "Rank": 26040,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, (Formerly- Pushpanjali Crosslay Hospital) ,W-3, Sector-1, Vaishali, Ghaziabad, Uttar Pradesh, 201012",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06142"
    },
    "Rank": 26044,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06143"
    },
    "Rank": 26051,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chinmaya Mission Hospital,,Chinmaya Mission Hospital, 80 Feet CMH Road, Indiranagar, Bangalore, Karnataka, 560038",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06144"
    },
    "Rank": 26053,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Human care Medical Charitable Trust,Human Care Medical Charitable Trust. HCMCTManipal Hospital, Sector 6, DWARKA, NEW DELHI, Delhi (NCT), 110075",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06145"
    },
    "Rank": 26055,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Balrampur Hospital, ,Gola Ganj, lucknow, Uttar Pradesh, 226018",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06146"
    },
    "Rank": 26058,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06147"
    },
    "Rank": 26060,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06148"
    },
    "Rank": 26062,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BASEL MISSION CSI HOSPITAL, Karnataka\n,Near Ambabavani Circle, PB Road, Gadag-Betageri, Gadag, Karnataka, Karnataka, 582102",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06149"
    },
    "Rank": 26064,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0614a"
    },
    "Rank": 26065,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0614b"
    },
    "Rank": 26068,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0614c"
    },
    "Rank": 26071,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Ramesh Cardiac and Multi Speciality Hospital,,Collector office road, Nagarampalem, Guntur , AP, Andhra Pradesh, 522004",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0614d"
    },
    "Rank": 26072,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sub Divisional Hospital,\n,ADMINISTRATIVE MEDICAL OFFICER SUB DIVISIONAL HOSPITAL SAGARA J C ROAD SAGARA SHIVAMOGGA DIST PIN 57, Karnataka, 577401",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0614e"
    },
    "Rank": 26075,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0614f"
    },
    "Rank": 26078,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06150"
    },
    "Rank": 26080,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06151"
    },
    "Rank": 26081,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06152"
    },
    "Rank": 26082,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06153"
    },
    "Rank": 26090,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District General Hospital, ,O/o. Medical Superintendent, Government District General Hospital, Contonment, Vizianagaram, Andhra Pradesh,\n535003",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06154"
    },
    "Rank": 26091,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (TROPICAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06155"
    },
    "Rank": 26092,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Central Hospital, ,SOUTH EAST CENTRAL RAILWAY, OPPOSITE GOLF GROUND,\nBILASPUR, Chhattisgarh, 495004",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06156"
    },
    "Rank": 26095,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dharwad,dsdharwad at gmail dot com, Karnataka, 580001",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06157"
    },
    "Rank": 26096,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rangadore Memorial Hospital,,1ST CROSS, SHANKARAPURAM, BANGALORE, Karnataka, 560004",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06158"
    },
    "Rank": 26099,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06159"
    },
    "Rank": 26100,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0615a"
    },
    "Rank": 26104,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0615b"
    },
    "Rank": 26124,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0615c"
    },
    "Rank": 26130,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Suhel Dev Medical College and Mahrishi Balark Hospital, Uttar Pradesh,MSD AS Medical College KDC Road Bahraich, Uttar Pradesh, 271801",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0615d"
    },
    "Rank": 26131,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tirumala Hospital, ,PSR COMPLEX, NEAR RTC COMPLEX, VIZIANAGARAM, Andhra Pradesh, 535003",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0615e"
    },
    "Rank": 26143,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0615f"
    },
    "Rank": 26145,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06160"
    },
    "Rank": 26154,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Santosh Hospital, ,No.6/1, Promenade Road, Bangalore.560 005, Karnataka, 560005",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06161"
    },
    "Rank": 26158,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06162"
    },
    "Rank": 26161,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Punjab Institute of Medical Scineces ,Punjab Institute of Medical Sciences Garha Road Jalandhar, Punjab, 144006",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06163"
    },
    "Rank": 26169,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESIC MEDICAL COLLEGE AND HOSPITAL, Bihar,ESIC Medical College and Hospital,Ara Danapur Road, Sikandarpur, Bihta, Patna, Bihar, 801103",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06164"
    },
    "Rank": 26185,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06165"
    },
    "Rank": 26194,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06166"
    },
    "Rank": 26196,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06167"
    },
    "Rank": 26201,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06168"
    },
    "Rank": 26210,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06169"
    },
    "Rank": 26211,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaipur Golden Hospital, ,2 INSTITUTIONAL AREA, SECTOR-3, ROHINI, Delhi (NCT), 110085",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0616a"
    },
    "Rank": 26215,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0616b"
    },
    "Rank": 26216,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0616c"
    },
    "Rank": 26219,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Josephs General Hospital,D NO 27-1-12, OPP AC COLLEGE, NEAR FLY OVER NAGARAMPALEM, GUNTUR, Andhra Pradesh, 522004",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0616d"
    },
    "Rank": 26220,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0616e"
    },
    "Rank": 26224,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Rangarajan Memorial Hospital, (Sundaram Medical Foundation) ,Sundaram Medical Foundation, Shanthi Colony, 4th Avenue, Anna Nagar, Chennai, Tamil Nadu, 600040",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0616f"
    },
    "Rank": 26225,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR GOVERNMENT MEDICAL COLLEGE,NELLORE,Opp AC Subbareddy Stadium\nDargamitta Nellore, Andhra Pradesh, 524004",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06170"
    },
    "Rank": 26227,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06171"
    },
    "Rank": 26228,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06172"
    },
    "Rank": 26229,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06173"
    },
    "Rank": 26231,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06174"
    },
    "Rank": 26238,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06175"
    },
    "Rank": 26241,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Area Hospital, Andhra Pradesh,Area Hospital Amalapuram, East Godavari District, Andhra Pradesh, 533201",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06176"
    },
    "Rank": 26242,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESI Hospital, ,IG ESI HOSPITAL JHILMIL DELHI - 95, Delhi (NCT), 110095",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06177"
    },
    "Rank": 26243,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital, Near KEB, PB Road, Haveri, Karnataka, 581110",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06178"
    },
    "Rank": 26244,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06179"
    },
    "Rank": 26247,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Josephs General Hospital,D NO 27-1-12, OPP AC COLLEGE, NEAR FLY OVER NAGARAMPALEM, GUNTUR, Andhra Pradesh, 522004",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0617a"
    },
    "Rank": 26248,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, West Bengal,730, Em By pass Road, Anandapur, Kolkata, West Bengal, 700107",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0617b"
    },
    "Rank": 26253,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0617c"
    },
    "Rank": 26254,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0617d"
    },
    "Rank": 26255,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital Whitefield,,ITPL Main Rd, KIADB Export Promotion Industrial Area, Krishnarajapura, Bengaluru, Karnataka, 560066",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0617e"
    },
    "Rank": 26261,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0617f"
    },
    "Rank": 26262,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06180"
    },
    "Rank": 26264,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06181"
    },
    "Rank": 26265,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06182"
    },
    "Rank": 26266,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajkiya Medical College, Jalaun,kalpi Road orai Jalaun, Uttar Pradesh, 285001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06183"
    },
    "Rank": 26267,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06184"
    },
    "Rank": 26271,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ramkrishna Care Hospital, ,AUROBINDO ENCLAVE, PACHPEDI NAKA,DAHAMTARI ROAD, RAIPUR, Chhattisgarh, 492001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06185"
    },
    "Rank": 26277,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06186"
    },
    "Rank": 26285,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06187"
    },
    "Rank": 26293,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06188"
    },
    "Rank": 26297,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06189"
    },
    "Rank": 26307,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Human care Medical Charitable Trust,Human Care Medical Charitable Trust. HCMCTManipal Hospital, Sector 6, DWARKA, NEW DELHI, Delhi (NCT), 110075",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0618a"
    },
    "Rank": 26314,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0618b"
    },
    "Rank": 26315,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0618c"
    },
    "Rank": 26316,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Childrens Hospital,,, Tamil Nadu, 600015",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0618d"
    },
    "Rank": 26320,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Bhausaheb Hire Government Medical College,Sri Bhausaheb Hire Government Medical College,Dhule, Maharashtra, 424311",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0618e"
    },
    "Rank": 26322,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Diamond Harbour Government Medical College And Hospital, ,Harindanga, New Town, Word No- 4, P.O and P.S- Diamond Harbour, Dist- South 24 Parganas, West Bengal, West Bengal, 743331",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0618f"
    },
    "Rank": 26326,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06190"
    },
    "Rank": 26327,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM Health Care Private Limited, Tamil Nadu,New No 72, Old No 54, Nelson Manickam Road, Aminjikarai, Chennai – 600029 Tamilnadu State, Tamil Nadu, 600029",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06191"
    },
    "Rank": 26331,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Multispeciality Hospital, West Bengal,Narayana Multispeciality Hospital 78, Jessore Road ( South),\nKolkata, West Bengal, West Bengal, 700127",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06192"
    },
    "Rank": 26335,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06193"
    },
    "Rank": 26336,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "J.L.N. Main Hospital and Research Centre, ,Bhilai Steel Plant Sector-9, Bhilai, C.G., Chhattisgarh, 490009",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06194"
    },
    "Rank": 26337,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06195"
    },
    "Rank": 26344,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Baba Saheb Ambedkar Hospital, ,SEC 6 ROHINI DELHI, Delhi (NCT), 110085",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06196"
    },
    "Rank": 26346,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06197"
    },
    "Rank": 26348,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06198"
    },
    "Rank": 26351,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur and Associated Group of Hospitals Shri Haridev Joshi Hospital,,Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur, Rajasthan, 314001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06199"
    },
    "Rank": 26353,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. R N Cooper Municipal General Hospital, (Associated with H B T (Hinduhridayasamrat Balasaheb Thackeray) medical College) ,HBTMC AND DR.R.N.COOPER HOSPITAL\nU 15 BHAKTI VEDANTA ROAD JVPD SCHEME VILE PARLE WEST MUMBAI,\nMaharashtra, 400056",
    "Course": "(NBEMS) DERMATOLOGY and VENEREOLOGY and LEPROSY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0619a"
    },
    "Rank": 26357,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College, Kolhapur,R.K. Nagar Road, Shenda Park, Kolhapur, Maharashtra, Maharashtra, 416012",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0619b"
    },
    "Rank": 26358,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0619c"
    },
    "Rank": 26363,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster CMI Hospital, ,43/2 New Airport Road, NH-7, Kodigehalli, Sahakara Nagar, Hebbal, Bengaluru, Karnataka, 560092",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0619d"
    },
    "Rank": 26364,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0619e"
    },
    "Rank": 26367,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0619f"
    },
    "Rank": 26371,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061a0"
    },
    "Rank": 26376,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Womens Center by Motherhood,\n,NO 146B Mettupalayam Road Coimbatore, Tamil Nadu, 641043",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061a1"
    },
    "Rank": 26379,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061a2"
    },
    "Rank": 26382,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061a3"
    },
    "Rank": 26390,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MMI Narayana Multispecialty Hospital,,Dhamtari Road, Lalpur, Raipur, Chhattisgarh, 492001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061a4"
    },
    "Rank": 26391,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Veerangana Awanti Bai Women Hospital, ,sic.vabwhlko@gmail.com, Uttar Pradesh, 226018",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061a5"
    },
    "Rank": 26393,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagwan Mahavir Medica Superspecialty Hospital, ,INFRONT OF P.H.E.D. COLONY, BARIATU ROAD, NEAR BOOTY MORE,\nRANCHI, Jharkhand, 834009",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061a6"
    },
    "Rank": 26395,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rural Development Trust Hospital, ,Kadiri Road,Bathalapalli, Anantapur District Andhra Pradesh, Andhra Pradesh, 515661",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061a7"
    },
    "Rank": 26396,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, West Bengal,730, Em By pass Road, Anandapur, Kolkata, West Bengal, 700107",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061a8"
    },
    "Rank": 26397,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rangadore Memorial Hospital,,1ST CROSS, SHANKARAPURAM, BANGALORE, Karnataka, 560004",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061a9"
    },
    "Rank": 26399,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061aa"
    },
    "Rank": 26402,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nazareth Hospital, Laitumukhrah, ,Laitumkhrah Shillong, East Khasi Hills district, Meghalaya, 793003",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061ab"
    },
    "Rank": 26403,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061ac"
    },
    "Rank": 26406,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061ad"
    },
    "Rank": 26408,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061ae"
    },
    "Rank": 26412,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061af"
    },
    "Rank": 26417,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061b0"
    },
    "Rank": 26423,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061b1"
    },
    "Rank": 26427,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061b2"
    },
    "Rank": 26432,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061b3"
    },
    "Rank": 26433,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061b4"
    },
    "Rank": 26434,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061b5"
    },
    "Rank": 26437,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061b6"
    },
    "Rank": 26439,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061b7"
    },
    "Rank": 26445,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL CANCER CENTRE,REGIONAL CANCER CENTRE, Kerala, 695011",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061b8"
    },
    "Rank": 26446,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061b9"
    },
    "Rank": 26450,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061ba"
    },
    "Rank": 26454,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061bb"
    },
    "Rank": 26457,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rural Development Trust Hospital, ,Kadiri Road,Bathalapalli, Anantapur District Andhra Pradesh, Andhra Pradesh, 515661",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061bc"
    },
    "Rank": 26461,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MALABAR INSTITUTE OF MEDICAL SCIENCES Ltd ,Chala East, Chala, Kannur, Kerala, 670621",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061bd"
    },
    "Rank": 26464,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rabindranath Tagore International Institute of Cardiac Sciences, ,PREMISES NO. 1489 124 MUKUNDAPUR  E M BYPASS  KOLKATA-700099, West Bengal, 700099",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061be"
    },
    "Rank": 26469,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061bf"
    },
    "Rank": 26479,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061c0"
    },
    "Rank": 26480,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061c1"
    },
    "Rank": 26481,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061c2"
    },
    "Rank": 26483,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kanumuri Hospital, Andhra Pradesh,D-NO 12 , SANAKKAYALA FACTORY ROAD , KOTHAPET , GUNTUR, Andhra Pradesh, 522001",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061c3"
    },
    "Rank": 26484,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061c4"
    },
    "Rank": 26487,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061c5"
    },
    "Rank": 26492,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061c6"
    },
    "Rank": 26493,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061c7"
    },
    "Rank": 26497,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061c8"
    },
    "Rank": 26498,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chamarajanagar Institute of Medical Sciences, Karnataka,CHAMARAJANAGAR INSTITUTE OF MEDICAL SCIENCES, SY. NO 124, YEDAPURA VILLAGE KASABA HOBLI, CHAMARAJANA, Karnataka, 571313",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061c9"
    },
    "Rank": 26510,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061ca"
    },
    "Rank": 26511,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Choithram Hospital and Research Centre,Manika Bagh Road Indore, Madhya Pradesh, 452014",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061cb"
    },
    "Rank": 26512,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061cc"
    },
    "Rank": 26513,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061cd"
    },
    "Rank": 26514,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Amar Hospital, ,#8, Bank Colony, Income Tax Office Road, Patiala, Punjab, 147001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061ce"
    },
    "Rank": 26519,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "HealthCare Global Specialty Hospital, (Formerly Bangalore Institute of Oncology) ,HCG Towers, No. 8, P Kalinga Rao Road, Sampangi Ram Nagar, Bangalore, Karnataka, 560027",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061cf"
    },
    "Rank": 26521,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061d0"
    },
    "Rank": 26524,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061d1"
    },
    "Rank": 26526,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061d2"
    },
    "Rank": 26527,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061d3"
    },
    "Rank": 26533,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Motors Hospital, ,KHARANGAJHAR, TELCO COLONY JAMSHEDPUR, Jharkhand, 831003",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061d4"
    },
    "Rank": 26538,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061d5"
    },
    "Rank": 26539,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061d6"
    },
    "Rank": 26544,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061d7"
    },
    "Rank": 26545,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061d8"
    },
    "Rank": 26553,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061d9"
    },
    "Rank": 26562,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061da"
    },
    "Rank": 26565,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061db"
    },
    "Rank": 26567,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061dc"
    },
    "Rank": 26569,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061dd"
    },
    "Rank": 26576,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061de"
    },
    "Rank": 26579,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061df"
    },
    "Rank": 26585,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Christian Institute of Health Sciences and Research, ,4th Mile, Central Jail Road, Dimapur, Nagaland, 797115",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061e0"
    },
    "Rank": 26586,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061e1"
    },
    "Rank": 26590,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Choithram Hospital and Research Centre,Manika Bagh Road Indore, Madhya Pradesh, 452014",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061e2"
    },
    "Rank": 26598,
    "Allotted Quota": "All India",
    "Allotted Institute": "The National Institute of Health and Family Welfare,The National Institute of Health and Family Welfare, Delhi (NCT), 110067",
    "Course": "M.D. (COMMUNITY HEALTH  and ADMN.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061e3"
    },
    "Rank": 26604,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jawahar Lal Nehru Cancer Hospital and Res. Centre, Madhya Pradesh,Idgah Hills, Bhopal, Madhya Pradesh, 462001",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061e4"
    },
    "Rank": 26605,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Hospital,,JANGLAT MANDI ANANTNAG, JAMMU AND KASHMIR 192101, Jammu And Kashmir, 192101",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061e5"
    },
    "Rank": 26609,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College,\n,Gunera Wazidpur, Ujhani Road, Badaun, Uttar Pradesh, 243601",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061e6"
    },
    "Rank": 26611,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apex Hospital,,N7 2A 5D BLW Hydel Road Varanasi, Uttar Pradesh, 221004",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061e7"
    },
    "Rank": 26612,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur and Associated Group of Hospitals Shri Haridev Joshi Hospital,,Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur, Rajasthan, 314001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061e8"
    },
    "Rank": 26613,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Johns Medical College Hospital, Karnataka,Sarjapur Road, Koramangala, Bangalore, Karnataka, Karnataka, 560034",
    "Course": "(NBEMS) Palliative Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061e9"
    },
    "Rank": 26616,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sarji Hospital, ,RMR RAOD PARK EXTENSION sHIVAMOGGA-577201, Karnataka, 577201",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061ea"
    },
    "Rank": 26623,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061eb"
    },
    "Rank": 26624,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Raiganj Government Medical college and Hospital, West Bengal,DR B C ROY SARANI. RAIGANJ, UTTAR DINAJPUR,PIN 733130, West Bengal, 733130",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061ec"
    },
    "Rank": 26625,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "New Amrutha medical and Research centre,,12-10-239-1-1,near Chitragar kalyan mantap,Siyatalab,Raichur-Karnataka\nKarnataka 584101, Karnataka, 584101",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061ed"
    },
    "Rank": 26628,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061ee"
    },
    "Rank": 26644,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Marwari Hospitals, Assam,Sati Jaymati Road, Athgoan, Guwahati, Assam, 781008",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061ef"
    },
    "Rank": 26645,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Phase- 6 Sas Nagar, Punjab,SMO, DISTRICT HOSPITAL, PHASE 6, SAS NAGAR, PUNJAB, Punjab, 160055",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061f0"
    },
    "Rank": 26656,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Hospital,,JANGLAT MANDI ANANTNAG, JAMMU AND KASHMIR 192101, Jammu And Kashmir, 192101",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061f1"
    },
    "Rank": 26657,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061f2"
    },
    "Rank": 26662,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Down Town Hospital, G S Road, ,G S Road, Dispur, Guwahati-781006, Assam, 781006",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061f3"
    },
    "Rank": 26664,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jabalpur Hospital and Research Centre, Jabalpur,\nMadhya Pradesh,Russel Chowk Napier Town Jabalpur, Madhya Pradesh, 482002",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061f4"
    },
    "Rank": 26676,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061f5"
    },
    "Rank": 26677,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindusthan Hospital,Tamil Nadu,522/3, 523/3, Navaindia road, Udaiyampalayam, Coimbatore 641028, Tamil Nadu, 641028",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061f6"
    },
    "Rank": 26681,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061f7"
    },
    "Rank": 26683,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061f8"
    },
    "Rank": 26687,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061f9"
    },
    "Rank": 26690,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Lal Bahadur Shastri Government Medical College and Hospital , ,VPO- Bhangrotu, MC- Ner Chowk, District- Mandi, Himachal Pradesh, 175021",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061fa"
    },
    "Rank": 26692,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061fb"
    },
    "Rank": 26693,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061fc"
    },
    "Rank": 26695,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061fd"
    },
    "Rank": 26697,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (Hospital Administration)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061fe"
    },
    "Rank": 26703,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a061ff"
    },
    "Rank": 26709,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Mundian Kalan, Chandigarh Road, Ludhiana-141015, Punjab, 141015",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06200"
    },
    "Rank": 26710,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06201"
    },
    "Rank": 26711,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06202"
    },
    "Rank": 26718,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Healthworld Hospital, ,Healthworld Hospitals [A Unit of Parashmani Medical Centre Pvt.Ltd.], C-49,Commercial Area, Opp. ESI, West Bengal, 713216",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06203"
    },
    "Rank": 26725,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06204"
    },
    "Rank": 26726,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahatma Gandhi Cancer Hospital and Research Institute,,Plot No.1\\7, MVP COLONY, VISAKHAPATNAM, Andhra Pradesh, 530017",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06205"
    },
    "Rank": 26727,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sahyadri Super Specialty Hospital,,Plot No.30C, Erandawane, Karve Road, Pune, Maharashtra, 411004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06206"
    },
    "Rank": 26728,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06207"
    },
    "Rank": 26729,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06208"
    },
    "Rank": 26735,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ramkrishna Care Hospital, ,AUROBINDO ENCLAVE, PACHPEDI NAKA,DAHAMTARI ROAD, RAIPUR, Chhattisgarh, 492001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06209"
    },
    "Rank": 26740,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0620a"
    },
    "Rank": 26742,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Datia,Near 29 Batallion, National Highway 44, Dist Datia, Madhya Pradesh Pin - 475661, Madhya Pradesh, 475661",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0620b"
    },
    "Rank": 26743,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0620c"
    },
    "Rank": 26751,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0620d"
    },
    "Rank": 26753,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shree Markendey Solapur Sahakari Rugnalya and Research Centre, ,Final Plot No.19, Pachha Peth,Solapur, Maharashtra, 413005",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0620e"
    },
    "Rank": 26757,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0620f"
    },
    "Rank": 26767,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06210"
    },
    "Rank": 26770,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chandan Hospital, ,Vijayant Khand, Gomti Nagar, Lucknow, Uttar Pradesh, 226010",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06211"
    },
    "Rank": 26774,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06212"
    },
    "Rank": 26777,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06213"
    },
    "Rank": 26779,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lokopriya Gopinath Bordoloi Regional Institute of Mental Health,LGBRIMH, Tezpur, District Sonitpur,, Assam, 784001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06214"
    },
    "Rank": 26787,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06215"
    },
    "Rank": 26788,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06216"
    },
    "Rank": 26791,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06217"
    },
    "Rank": 26795,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06218"
    },
    "Rank": 26803,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Capital Hospital, Orissa,director.chb@gmail.com, Odisha, 751001",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06219"
    },
    "Rank": 26805,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0621a"
    },
    "Rank": 26810,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0621b"
    },
    "Rank": 26813,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindu Rao Hospital, ,SUBZI MANDI, MALKA GANJ, DELHI-110007, Delhi (NCT), 110007",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0621c"
    },
    "Rank": 26814,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0621d"
    },
    "Rank": 26818,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0621e"
    },
    "Rank": 26819,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karnataka,MG Road, Karnataka, 581301",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0621f"
    },
    "Rank": 26825,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06220"
    },
    "Rank": 26828,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kanyakumari Medical Mission, Tamil Nadu,CSI Hospital Neyyoor Kanyakumari Dist, Tamil Nadu, 629802",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06221"
    },
    "Rank": 26833,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06222"
    },
    "Rank": 26840,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, THOOTHUKUDI,GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, 3rd\nMile,Kamaraj Nagar,Thoothukudi., Tamil Nadu, 628008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06223"
    },
    "Rank": 26842,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaiprakash Hospital and Research Centre Pvt. Ltd, Odisha,,JAIPRAKASH HOSPITAL AND RESEARCH CENTER PVT. LTD., DANDIAPALI, ROURKELA, Odisha, 769004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06224"
    },
    "Rank": 26847,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06225"
    },
    "Rank": 26851,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06226"
    },
    "Rank": 26852,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06227"
    },
    "Rank": 26853,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06228"
    },
    "Rank": 26857,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahavir Jaipuriya Rajasthan Hospital, Rajasthan\n,Opposite Jaipuriya Hospital, Hospital Road, Milap Nagar, JLN Marg, Jaipur, Rajasthan, 302018",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06229"
    },
    "Rank": 26858,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0622a"
    },
    "Rank": 26861,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0622b"
    },
    "Rank": 26865,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0622c"
    },
    "Rank": 26867,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0622d"
    },
    "Rank": 26869,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0622e"
    },
    "Rank": 26873,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sadhu Vaswani Missions ,7-9 Lane No 1 Koregaon Park Pune 411001, Maharashtra, 411001",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0622f"
    },
    "Rank": 26874,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sheth Vadilal Sarabhai General Hospital and Sheth Chinai Prasuti Hospital, Gujarat,SHETH VADILAL SARABHAI GENERAL HOSPITAL AND SHETH CHINAI PRASUTI HOSPITAL ELLISBRIDGE AHMEDABAD, Gujarat, 380006",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06230"
    },
    "Rank": 26892,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Delhi Hospital And Nursing Home, Haryana,METRO PILLAR NO. 812 NEAR SHYAMJI COMPLEX BAHADURGARH DIST JHAJJAR-124507, Haryana, 124507",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06231"
    },
    "Rank": 26898,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06232"
    },
    "Rank": 26899,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Anand Rishiji Hospital and Medical Research Centre, ,124, Anandrishiji Marg, Ahmednagar, Maharashtra, 414001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06233"
    },
    "Rank": 26911,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "New Amrutha medical and Research centre,,12-10-239-1-1,near Chitragar kalyan mantap,Siyatalab,Raichur-Karnataka\nKarnataka 584101, Karnataka, 584101",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06234"
    },
    "Rank": 26915,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06235"
    },
    "Rank": 26916,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sadar Hospital,\n,Sadar Hospital, Belisarai, Motihari, East Champaran, Bihar, Under Government of Bihar, Bihar, 845401",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06236"
    },
    "Rank": 26917,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06237"
    },
    "Rank": 26919,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06238"
    },
    "Rank": 26922,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06239"
    },
    "Rank": 26924,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Anand Rishiji Hospital and Medical Research Centre, ,124, Anandrishiji Marg, Ahmednagar, Maharashtra, 414001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0623a"
    },
    "Rank": 26925,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0623b"
    },
    "Rank": 26926,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gvernment Medical College,Haldwani,Government Medical College, Haldwani, Uttarakhand, 263139",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0623c"
    },
    "Rank": 26927,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0623d"
    },
    "Rank": 26930,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, (Formerly- Pushpanjali Crosslay Hospital) ,W-3, Sector-1, Vaishali, Ghaziabad, Uttar Pradesh, 201012",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0623e"
    },
    "Rank": 26931,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0623f"
    },
    "Rank": 26935,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06240"
    },
    "Rank": 26938,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06241"
    },
    "Rank": 26943,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ispat General Hospital,,Ispat General Hospital Rourkela Steel Plant SAIL Rourkela 769005, Odisha, 769005",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06242"
    },
    "Rank": 26945,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06243"
    },
    "Rank": 26950,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06244"
    },
    "Rank": 26951,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06245"
    },
    "Rank": 26959,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE, PUDUCHERRY,INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE,\nPUDUCHERRY, Puducherry, 605009",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06246"
    },
    "Rank": 26960,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Alexis Multispecialty Hospital,232, Mankapur Square, Koradi road, NAGPUR, Maharashtra, 440030",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06247"
    },
    "Rank": 26973,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Institute of Medical Sciences (SVIMS), A State University estd. by Govt. of A.P.,Sri Venkateswara Institute of Medical Sciences, SVIMS, Alipiri Road, Tirupati, Andhra Pradesh, 517507",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06248"
    },
    "Rank": 26976,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Civil Hospital, Punjab,chhsp1234@gmail.com, Punjab, 146001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06249"
    },
    "Rank": 26983,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0624a"
    },
    "Rank": 26990,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0624b"
    },
    "Rank": 26993,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagwan Mahavir Medica Superspecialty Hospital, ,INFRONT OF P.H.E.D. COLONY, BARIATU ROAD, NEAR BOOTY MORE,\nRANCHI, Jharkhand, 834009",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0624c"
    },
    "Rank": 26997,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM Eye Institute,5TH MILE, VIDHAN SABHA ROAD, RAIPUR, Chhattisgarh, 493111",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0624d"
    },
    "Rank": 27000,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rabindranath Tagore International Institute of Cardiac Sciences, ,PREMISES NO. 1489 124 MUKUNDAPUR  E M BYPASS  KOLKATA-700099, West Bengal, 700099",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0624e"
    },
    "Rank": 27010,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0624f"
    },
    "Rank": 27011,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN,HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN, Karnataka, 573201",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06250"
    },
    "Rank": 27013,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06251"
    },
    "Rank": 27015,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06252"
    },
    "Rank": 27016,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06253"
    },
    "Rank": 27017,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government General Hospital, ,GOVERNMENT GENERAL HOSPITAL, ALANPUR LINK ROAD SAWAI MADHOPUR 322001, Rajasthan, 322001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06254"
    },
    "Rank": 27024,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06255"
    },
    "Rank": 27025,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06256"
    },
    "Rank": 27026,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06257"
    },
    "Rank": 27027,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06258"
    },
    "Rank": 27028,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06259"
    },
    "Rank": 27035,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Village Lingiadih,,Apollo Hospitals Bilaspur Seepat Road  Lingyadih Village  Bilaspur, Chhattisgarh, 495006",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0625a"
    },
    "Rank": 27039,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0625b"
    },
    "Rank": 27041,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vallabhbhai Patel Chest Institute, Delhi,Vallabhbhai Patel Chest Institute, Delhi, Delhi (NCT), 110007",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0625c"
    },
    "Rank": 27042,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (Hospital Administration)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0625d"
    },
    "Rank": 27045,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0625e"
    },
    "Rank": 27052,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Calcutta Medical Research Institute, ,The Calcutta Medical Research Institute 7 by 2 Diamond Harbour Road Kolkata 700027, West Bengal, 700027",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0625f"
    },
    "Rank": 27054,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sarvodaya Hospital and Research Centre, ,YMCA road Sector 08\nFaridabad, Haryana, 121006",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06260"
    },
    "Rank": 27055,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06261"
    },
    "Rank": 27057,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, (Formerly- Pushpanjali Crosslay Hospital) ,W-3, Sector-1, Vaishali, Ghaziabad, Uttar Pradesh, 201012",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06262"
    },
    "Rank": 27061,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06263"
    },
    "Rank": 27069,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06264"
    },
    "Rank": 27071,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06265"
    },
    "Rank": 27073,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06266"
    },
    "Rank": 27074,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06267"
    },
    "Rank": 27079,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Central Hospital, ,CENTRAL HOSPITAL NF RAILWAY\nMALIGAON GUWAHATI, Assam, 781011",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06268"
    },
    "Rank": 27087,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06269"
    },
    "Rank": 27091,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0626a"
    },
    "Rank": 27093,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mohandai Oswal Hospital, ,Mohandai Oswal Hospital, Sherpur Byepass, G T Road, Ludhiana-141009, Punjab, 141009",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0626b"
    },
    "Rank": 27094,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0626c"
    },
    "Rank": 27095,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0626d"
    },
    "Rank": 27097,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES,CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, Chhattisgarh, 495001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0626e"
    },
    "Rank": 27098,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0626f"
    },
    "Rank": 27100,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06270"
    },
    "Rank": 27104,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sub Divisional Hospital,\n,ADMINISTRATIVE MEDICAL OFFICER SUB DIVISIONAL HOSPITAL SAGARA J C ROAD SAGARA SHIVAMOGGA DIST PIN 57, Karnataka, 577401",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06271"
    },
    "Rank": 27106,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06272"
    },
    "Rank": 27108,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06273"
    },
    "Rank": 27110,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apex Hospital,,N7 2A 5D BLW Hydel Road Varanasi, Uttar Pradesh, 221004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06274"
    },
    "Rank": 27112,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06275"
    },
    "Rank": 27117,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagirathi Neotia Woman and Child Care Centre, West Bengal,BHAGIRATHI NEOTIA WOMAN AND CHILD CARE CENTRE, NEW TOWN PREMISES NO.-27-0327, STREET NO.-327, DG BLO, West Bengal, 700156",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06276"
    },
    "Rank": 27122,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sumitra Hospital,\nUttar Pradesh,A-119A SECTOR-35 NOIDA, Uttar Pradesh, 201301",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06277"
    },
    "Rank": 27125,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "South Western Railway,Room No 22 Central Hospital South Western Railway Gadag, road Hubballi, Karnataka, 580020",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06278"
    },
    "Rank": 27126,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kailash Hospital and Heart Institute,\nUttar Pradesh,H-33 SECTOR  27 NOIDA, Uttar Pradesh, 201301",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06279"
    },
    "Rank": 27136,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0627a"
    },
    "Rank": 27138,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pushpanjali Hospital and Research Centre, Uttar Pradesh,Pushpanjali Hospital and Research Centre Pvt Ltd Pushpanjali Palace, Delhi Gate, Agra, Uttar Pradesh, 282002",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0627b"
    },
    "Rank": 27139,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deen Dayal Upadhyay Hospital, ,DDU HOSPITAL, SHAHEED MANGAL PANDEY MARG, NANAK PURA, HARI NAGAR, NEW DELHI, DELHI-110064, Delhi (NCT), 110064",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0627c"
    },
    "Rank": 27141,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0627d"
    },
    "Rank": 27145,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "DISTRICT HOSPITAL ,\nChhattisgarh,Room No. 122 - Government District Hospital  Near Mandi Gate Pandari, Raipur, Chhattisgarh, 492001, , Chhattisgarh, 492001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0627e"
    },
    "Rank": 27151,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vivekananda Polyclinic and Institute of Medical Sciences, ,Vivekananda Polyclinic and Institute of Medical Sciences, Vivekananda Puram, Lucknow, Uttar Pradesh, 226007",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0627f"
    },
    "Rank": 27153,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06280"
    },
    "Rank": 27156,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Central Hospital, ,SOUTH EAST CENTRAL RAILWAY, OPPOSITE GOLF GROUND,\nBILASPUR, Chhattisgarh, 495004",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06281"
    },
    "Rank": 27157,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06282"
    },
    "Rank": 27161,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Khan Bahadur Bhabha Hospital, Maharashtra,Khan Bahadur Bhabha Hospital Belgrami road kurla west, Maharashtra, 400070",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06283"
    },
    "Rank": 27163,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06284"
    },
    "Rank": 27165,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06285"
    },
    "Rank": 27168,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jai Prakash District Government Hospital, ,Jai Prakash District Goverment Hospital, 1250, Link Road No.2 Tulsi Nagar, Shivaji Nagar, Bhopal,, Madhya Pradesh, 462001",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06286"
    },
    "Rank": 27171,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06287"
    },
    "Rank": 27173,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06288"
    },
    "Rank": 27175,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVERNMENT MEDICAL COLLEGE,KANYAKUMARI GOVERNMENT MEDICAL COLLEGE, Tamil Nadu, 629201",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06289"
    },
    "Rank": 27178,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0628a"
    },
    "Rank": 27180,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0628b"
    },
    "Rank": 27181,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0628c"
    },
    "Rank": 27187,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Charak Hospital and Research Center, Uttar Pradesh,Hardoi Road, Dubagga, Lucknow, Uttar Pradesh, 226003",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0628d"
    },
    "Rank": 27189,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Paras Hospital,,C1 Sushant lok sector 43 gurugram, Haryana, 122002",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0628e"
    },
    "Rank": 27190,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0628f"
    },
    "Rank": 27194,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06290"
    },
    "Rank": 27200,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06291"
    },
    "Rank": 27203,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sarvodaya Hospital and Research Centre, ,YMCA road Sector 08\nFaridabad, Haryana, 121006",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06292"
    },
    "Rank": 27209,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mehta Multispecialty Hospital, (Formerly Known as Dr. Mehta`s Hospital) ,No.2, McNichols Road, 3 rd Lane, Chetpet, Chennai, Tamil Nadu, 600031",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06293"
    },
    "Rank": 27210,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06294"
    },
    "Rank": 27213,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bansal Hospital,,C- Sector Shahpura, Bhopal, Madhya Pradesh, 462016",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06295"
    },
    "Rank": 27216,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06296"
    },
    "Rank": 27217,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital\nNext to Sainik School second gate Athani Road, Vijayapura Karnataka 586103, Karnataka, 586103",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06297"
    },
    "Rank": 27219,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "B.R. Singh Hospital and Centre for Medical Education and Research,,B.R.Singh Hospital Eastern Railway, Sealdah, Kolkata, West Bengal, 700014",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06298"
    },
    "Rank": 27220,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "HealthCare Global Specialty Hospital, (Formerly Bangalore Institute of Oncology) ,HCG Towers, No. 8, P Kalinga Rao Road, Sampangi Ram Nagar, Bangalore, Karnataka, 560027",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06299"
    },
    "Rank": 27221,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0629a"
    },
    "Rank": 27224,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0629b"
    },
    "Rank": 27227,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Gondia,Nehru Chowk, KTS General Hospital Campus, Govt. Medical College, Gondia. 441601. Maharashtra., Maharashtra, 441601",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0629c"
    },
    "Rank": 27232,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0629d"
    },
    "Rank": 27235,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College,Fakhruddin Ali Ahmed Medical College, Assam, 781301",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0629e"
    },
    "Rank": 27240,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "South Western Railway,Room No 22 Central Hospital South Western Railway Gadag, road Hubballi, Karnataka, 580020",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0629f"
    },
    "Rank": 27241,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kailash hospital neuro institute,\nUttar Pradesh,KAILASH HOSPITAL & NEURO INSTITUTE NH-1  SECTOR 71 NOIDA UP, Uttar Pradesh, 201309",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062a0"
    },
    "Rank": 27244,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062a1"
    },
    "Rank": 27245,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062a2"
    },
    "Rank": 27251,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062a3"
    },
    "Rank": 27252,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062a4"
    },
    "Rank": 27253,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062a5"
    },
    "Rank": 27258,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062a6"
    },
    "Rank": 27265,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062a7"
    },
    "Rank": 27271,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062a8"
    },
    "Rank": 27276,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "IQRAA HOSPITAL , Kerala\n,KOTTAKKUNNU SULTHAN BATHERY WAYANAD 673592, Kerala, 673592",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062a9"
    },
    "Rank": 27278,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062aa"
    },
    "Rank": 27279,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062ab"
    },
    "Rank": 27282,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062ac"
    },
    "Rank": 27283,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Eternal Heart Care Centre and Research Institute, ,3A, Jagatpura Road, Near Jawahar Circle, Malviya Nagar, Jaipur, Rajasthan, 302017",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062ad"
    },
    "Rank": 27284,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062ae"
    },
    "Rank": 27285,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "N S Memorial Institute of Medical Sciences ,N S Memorial Institute of Medical Sciences Palathara, Thattamala, Kollam, Kerala, 691020",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062af"
    },
    "Rank": 27286,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062b0"
    },
    "Rank": 27288,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062b1"
    },
    "Rank": 27291,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "New Amrutha medical and Research centre,,12-10-239-1-1,near Chitragar kalyan mantap,Siyatalab,Raichur-Karnataka\nKarnataka 584101, Karnataka, 584101",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062b2"
    },
    "Rank": 27293,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062b3"
    },
    "Rank": 27294,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062b4"
    },
    "Rank": 27296,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062b5"
    },
    "Rank": 27297,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062b6"
    },
    "Rank": 27299,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Omega Hospital,,MLA Colony, Road No. 12, Banjara Hills, Hyderabad, Telangana, 500034",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062b7"
    },
    "Rank": 27303,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Thangam Hospital, Namakkal, Tamil Nadu,No. 54, Dr Sankaran Road, Namakkal, Tamil Nadu, 637001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062b8"
    },
    "Rank": 27309,
    "Allotted Quota": "All India",
    "Allotted Institute": "Murshidabad Medical College and Hospital,Station Road, Berhampore, Murshidabad, West Bengal, 742101",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062b9"
    },
    "Rank": 27314,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE - KANNUR,GOVERNMENT MEDICAL COLLEGE\n- KANNUR, Kerala, 670503",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062ba"
    },
    "Rank": 27319,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062bb"
    },
    "Rank": 27331,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062bc"
    },
    "Rank": 27332,
    "Allotted Quota": "All India",
    "Allotted Institute": "Murshidabad Medical College and Hospital,Station Road, Berhampore, Murshidabad, West Bengal, 742101",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062bd"
    },
    "Rank": 27333,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062be"
    },
    "Rank": 27338,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062bf"
    },
    "Rank": 27340,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062c0"
    },
    "Rank": 27341,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062c1"
    },
    "Rank": 27348,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062c2"
    },
    "Rank": 27349,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062c3"
    },
    "Rank": 27357,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062c4"
    },
    "Rank": 27358,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Paras Hospital,,C1 Sushant lok sector 43 gurugram, Haryana, 122002",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062c5"
    },
    "Rank": 27359,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B.R. Ambedkar State Institute of Medical Sciences, Punjab,aimsestablishment@gmail.com, Punjab, 160055",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062c6"
    },
    "Rank": 27362,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, Village Lingiadih,,Apollo Hospitals Bilaspur Seepat Road  Lingyadih Village  Bilaspur, Chhattisgarh, 495006",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062c7"
    },
    "Rank": 27379,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062c8"
    },
    "Rank": 27385,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chandan Hospital, ,Vijayant Khand, Gomti Nagar, Lucknow, Uttar Pradesh, 226010",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062c9"
    },
    "Rank": 27390,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruby General Hospital, ,576 Anandapur, Kasba Golpark, E. M. Bypass, Kolkata 700107, West Bengal, 700107",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062ca"
    },
    "Rank": 27391,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Venkateshwar Hospital, ,Sector- 18 A, Dwarka, New Delhi, Delhi (NCT), 110075",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062cb"
    },
    "Rank": 27394,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062cc"
    },
    "Rank": 27399,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062cd"
    },
    "Rank": 27402,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Cauvery Heart and Multispeciality Hospital, ,BANNUR ROAD, NEAR TERESIAN CIRCLE,MYSORE, Karnataka, 570029",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062ce"
    },
    "Rank": 27405,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062cf"
    },
    "Rank": 27408,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062d0"
    },
    "Rank": 27409,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College & Attached Hospital Bharatpur,GOVERNMENT MEDICAL COLLEGE BHARATPUR VILLAGE RAMPURA NH 21, Rajasthan, 321001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062d1"
    },
    "Rank": 27411,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Delhi Hospital And Nursing Home, Haryana,METRO PILLAR NO. 812 NEAR SHYAMJI COMPLEX BAHADURGARH DIST JHAJJAR-124507, Haryana, 124507",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062d2"
    },
    "Rank": 27414,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062d3"
    },
    "Rank": 27415,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062d4"
    },
    "Rank": 27418,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indo-American Cancer Institute and Research Centre, ,Road No.14, Banjara Hills, Hyderabad, Telangana, 500034",
    "Course": "(NBEMS) Palliative Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062d5"
    },
    "Rank": 27420,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bansal Hospital,,C- Sector Shahpura, Bhopal, Madhya Pradesh, 462016",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062d6"
    },
    "Rank": 27421,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "DISTRICT HOSPITAL ,\nChhattisgarh,Room No. 122 - Government District Hospital  Near Mandi Gate Pandari, Raipur, Chhattisgarh, 492001, , Chhattisgarh, 492001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062d7"
    },
    "Rank": 27424,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Babasaheb Ambedkar Central Railway Hospital, ,Bharatratna Dr. Babasaheb Ambedkar Memorial Hospital, Central Railway, Dr. Babasaheb Ambedkar Road,, Maharashtra, 400027",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062d8"
    },
    "Rank": 27432,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Taluk General Hospital, Haveri District, Karnataka,TALUK GENERAL HOSPITAL BYADAGI, Karnataka, 581106",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062d9"
    },
    "Rank": 27435,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062da"
    },
    "Rank": 27440,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062db"
    },
    "Rank": 27447,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhaktivedanta Hospital and Research Institute, ,Bhaktivedanta Hospital and Research Institute,Srishti Complex, Bhaktivedanta Swami Marg, Mira Road E, Maharashtra, 401107",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062dc"
    },
    "Rank": 27450,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College Kannauj , Uttar Pradesh,Kannaujmedicalcollege@gmail.com, Uttar Pradesh, 209732",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062dd"
    },
    "Rank": 27452,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KMC Specialty Hospital, ,No-6, Royal Road, Cantonment, Trichy-620001, Tamil Nadu, 620001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062de"
    },
    "Rank": 27472,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062df"
    },
    "Rank": 27474,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062e0"
    },
    "Rank": 27475,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062e1"
    },
    "Rank": 27476,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062e2"
    },
    "Rank": 27485,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur and Associated Group of Hospitals Shri Haridev Joshi Hospital,,Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur, Rajasthan, 314001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062e3"
    },
    "Rank": 27489,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062e4"
    },
    "Rank": 27493,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062e5"
    },
    "Rank": 27499,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062e6"
    },
    "Rank": 27501,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062e7"
    },
    "Rank": 27503,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apex Hospital,,N7 2A 5D BLW Hydel Road Varanasi, Uttar Pradesh, 221004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062e8"
    },
    "Rank": 27507,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Peerless Hospital and B K Roy Research Centre, ,360, Panchasayar, Kolkata - 700 -094, West Bengal, 700094",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062e9"
    },
    "Rank": 27510,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062ea"
    },
    "Rank": 27511,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital,\n,MAIN ROAD SURAJPUR, Chhattisgarh, 497229",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062eb"
    },
    "Rank": 27516,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chandan Hospital, ,Vijayant Khand, Gomti Nagar, Lucknow, Uttar Pradesh, 226010",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062ec"
    },
    "Rank": 27517,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062ed"
    },
    "Rank": 27525,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lord Mahavir Civil Hospital,,Lord Mahavir Civil Hospital, MCH Wing Fieldganj Road Ludhiana, Punjab, 141008",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062ee"
    },
    "Rank": 27528,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jabalpur Hospital and Research Centre, Jabalpur,\nMadhya Pradesh,Russel Chowk Napier Town Jabalpur, Madhya Pradesh, 482002",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062ef"
    },
    "Rank": 27529,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062f0"
    },
    "Rank": 27531,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Phase- 6 Sas Nagar, Punjab,SMO, DISTRICT HOSPITAL, PHASE 6, SAS NAGAR, PUNJAB, Punjab, 160055",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062f1"
    },
    "Rank": 27535,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062f2"
    },
    "Rank": 27536,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062f3"
    },
    "Rank": 27537,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahavir Jaipuriya Rajasthan Hospital, Rajasthan\n,Opposite Jaipuriya Hospital, Hospital Road, Milap Nagar, JLN Marg, Jaipur, Rajasthan, 302018",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062f4"
    },
    "Rank": 27541,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. R. D. B. P. Jaipuria Hospital, (Attached with RUHS College of Medical Sciences),,GOVTRDBPJAIPURIAHOSPITALJAIPUR@GMAIL.COM, Rajasthan, 302018",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062f5"
    },
    "Rank": 27543,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Calcutta Medical Research Institute, ,The Calcutta Medical Research Institute 7 by 2 Diamond Harbour Road Kolkata 700027, West Bengal, 700027",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062f6"
    },
    "Rank": 27545,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062f7"
    },
    "Rank": 27547,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062f8"
    },
    "Rank": 27549,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062f9"
    },
    "Rank": 27550,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062fa"
    },
    "Rank": 27552,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062fb"
    },
    "Rank": 27557,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062fc"
    },
    "Rank": 27577,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062fd"
    },
    "Rank": 27581,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062fe"
    },
    "Rank": 27585,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster CMI Hospital, ,43/2 New Airport Road, NH-7, Kodigehalli, Sahakara Nagar, Hebbal, Bengaluru, Karnataka, 560092",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a062ff"
    },
    "Rank": 27588,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chamarajanagar Institute of Medical Sciences, Karnataka,CHAMARAJANAGAR INSTITUTE OF MEDICAL SCIENCES, SY. NO 124, YEDAPURA VILLAGE KASABA HOBLI, CHAMARAJANA, Karnataka, 571313",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06300"
    },
    "Rank": 27589,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Childrens Hospital and BirthRight, Telangana,Hno 3 7 222 223 sy no 51 to 54 opposit kharkana police station kaskhana main road kakaguda hyderabad, Telangana, 500009",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06301"
    },
    "Rank": 27593,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06302"
    },
    "Rank": 27597,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Specialty Hospitals, ,IIIM NEHRU NAGAR GHAZIABAD, Uttar Pradesh, 201001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06303"
    },
    "Rank": 27602,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Kamakshi Memorial Hospital, (Dr. Kamakshi Institute of Medical Sciences and Research) ,No.1,Radial Road , Pallikaranai, Chennai, Tamil Nadu, 600100",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06304"
    },
    "Rank": 27603,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06305"
    },
    "Rank": 27605,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06306"
    },
    "Rank": 27611,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Regency Hospital,,A-2, Sarvodaya Nagar, Kanpur, Uttar Pradesh, 208005",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06307"
    },
    "Rank": 27627,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06308"
    },
    "Rank": 27637,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Diamond Harbour Government Medical College And Hospital, ,Harindanga, New Town, Word No- 4, P.O and P.S- Diamond Harbour, Dist- South 24 Parganas, West Bengal, West Bengal, 743331",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06309"
    },
    "Rank": 27642,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0630a"
    },
    "Rank": 27644,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0630b"
    },
    "Rank": 27648,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rukmani Birla Hospital , (A unit of The Calcutta Medical Research Institute) ,Gopalpura Bypass Road, Near Triveni Flyover, Jaipur, Rajasthan, 302018",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0630c"
    },
    "Rank": 27650,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0630d"
    },
    "Rank": 27654,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,10 Biplabi Haren Ghosh Sarani Howrah, West Bengal, 711101",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0630e"
    },
    "Rank": 27659,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0630f"
    },
    "Rank": 27667,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06310"
    },
    "Rank": 27668,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM Govt District Hospital, ,MGM Govt District Hospital,Hanumangarh town, Rajasthan, 335513",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06311"
    },
    "Rank": 27673,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mohandai Oswal Hospital, ,Mohandai Oswal Hospital, Sherpur Byepass, G T Road, Ludhiana-141009, Punjab, 141009",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06312"
    },
    "Rank": 27674,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "YATHARTH SUPER SPECIALTY HOSPITALS,\nUttar Pradesh,Sector omega 01-, Greater noida, Uttar Pradesh, 201308",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06313"
    },
    "Rank": 27693,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06314"
    },
    "Rank": 27697,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR GOVERNMENT MEDICAL COLLEGE,NELLORE,Opp AC Subbareddy Stadium\nDargamitta Nellore, Andhra Pradesh, 524004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06315"
    },
    "Rank": 27699,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharashtra Post Graduate Institute of Medical Education and Research, Nashik,Maharashtra Post Graduation Institute Of Medical Education and Research Civil Hospital campus Trimba, Maharashtra, 422002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06316"
    },
    "Rank": 27700,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06317"
    },
    "Rank": 27702,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Childrens Hospital and BirthRight, Telangana,Hno 3 7 222 223 sy no 51 to 54 opposit kharkana police station kaskhana main road kakaguda hyderabad, Telangana, 500009",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06318"
    },
    "Rank": 27704,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Josephs General Hospital,D NO 27-1-12, OPP AC COLLEGE, NEAR FLY OVER NAGARAMPALEM, GUNTUR, Andhra Pradesh, 522004",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06319"
    },
    "Rank": 27710,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0631a"
    },
    "Rank": 27719,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0631b"
    },
    "Rank": 27723,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0631c"
    },
    "Rank": 27734,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "C.S.I. Holdsworth Memorial Hospital, ,P.B.No. 38, Sawday Road, Mandi Mohalla, Mysuru, Karnataka, 570001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0631d"
    },
    "Rank": 27736,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jagannath Gupta Institute of Medical Sciences and Hospital, ,BUITA, K P MONDAL ROAD, BUDGE BUDGE, SOUTH 24 PARGANAS, KOLKATA, West Bengal, 700137",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0631e"
    },
    "Rank": 27741,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0631f"
    },
    "Rank": 27743,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06320"
    },
    "Rank": 27744,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06321"
    },
    "Rank": 27746,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ursala Horsman Memorial Hospital, ,Bada Chauraha, Civil Lines,Kanpur Nagar, Uttar Pradesh, 208001",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06322"
    },
    "Rank": 27751,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06323"
    },
    "Rank": 27759,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06324"
    },
    "Rank": 27765,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06325"
    },
    "Rank": 27770,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06326"
    },
    "Rank": 27771,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rural Development Trust Hospital, ,Kadiri Road,Bathalapalli, Anantapur District Andhra Pradesh, Andhra Pradesh, 515661",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06327"
    },
    "Rank": 27773,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06328"
    },
    "Rank": 27781,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Shiv Prasad Gupta Divisional District Hospital, ,Kabir Chaura, Lohatiya, Varanasi, Uttar Pradesh, 221001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06329"
    },
    "Rank": 27782,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SETH V. C. GANDHI and M. A. VORA MUNICIPAL GENERAL HOSPITAL (RAJAWADI HOSPITAL),\nMaharashtra,hospital.rajawadi@gmail.com, Maharashtra, 400077",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0632a"
    },
    "Rank": 27784,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan , Rajasthan-311001,Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan, Rajasthan, 311001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0632b"
    },
    "Rank": 27790,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0632c"
    },
    "Rank": 27794,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS MEDICAL COLLEGE, NEAR GUJARAT HIGH COURT, S.G HIGH WAY, SOLA, AHMEDABAD, Gujarat, 380060",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0632d"
    },
    "Rank": 27802,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Mundian Kalan, Chandigarh Road, Ludhiana-141015, Punjab, 141015",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0632e"
    },
    "Rank": 27807,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Northern Railway Central Hospital,, ,Basant Lane New Delhi, Delhi (NCT), 110055",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0632f"
    },
    "Rank": 27812,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Southern Railway Head Quarter Hospital, ,Ayanavaram, Tamil Nadu, 600023",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06330"
    },
    "Rank": 27814,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. BDK hospital, govt. ,OPPOSITE ROADWAYS BUS STAND MAAN NAGAR JHUNJHUNU RAJASTHAN, Rajasthan, 333001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06331"
    },
    "Rank": 27815,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR GOVERNMENT MEDICAL COLLEGE,NELLORE,Opp AC Subbareddy Stadium\nDargamitta Nellore, Andhra Pradesh, 524004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06332"
    },
    "Rank": 27816,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06333"
    },
    "Rank": 27820,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "QRG Medicare, ,Plot No. 1, Sector-16, Faridabad, Haryana, Haryana, 121002",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06334"
    },
    "Rank": 27824,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06335"
    },
    "Rank": 27825,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06336"
    },
    "Rank": 27827,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Metro Hospital and Cancer Research Centre,,KUCHAINI PARISAR, DAMOH NAKA, JABALPUR, Madhya Pradesh, 482002",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06337"
    },
    "Rank": 27835,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government General Hospital, ,GOVERNMENT GENERAL HOSPITAL, ALANPUR LINK ROAD SAWAI MADHOPUR 322001, Rajasthan, 322001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06338"
    },
    "Rank": 27840,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06339"
    },
    "Rank": 27849,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0633a"
    },
    "Rank": 27852,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Medical College and Chhatrapati Shivaji Maharaj Hospital, ,RAJIV GANDHI MEDICAL COLLEGE,THANE BELAPUR ROAD, KALWA, THANE, Maharashtra, 400605",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0633b"
    },
    "Rank": 27853,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0633c"
    },
    "Rank": 27856,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0633d"
    },
    "Rank": 27857,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tamil Nadu Government Multi Superspeciality Hospital, Tamil Nadu\n,Omandurar Government Estate Anna Salai, Tamil Nadu, 600002",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0633e"
    },
    "Rank": 27870,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Sector-62, Phase VIII, Mohali- 160062, Punjab, 160062",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0633f"
    },
    "Rank": 27871,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "DEEP NURSING HOME AND CHILDREN HOSPITAL, Punjab,481, Model Town Road,\nPritam Nagar, Model Town, Ludhiana, Punjab 141002, Punjab, 141002",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06340"
    },
    "Rank": 27877,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Surya Mother and Child Care Jaipur Pvt Ltd.,\n,B-7, Sawai Ram SIngh Road, Opp SMS Hospital, Jaipur, Rajasthan, 302001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06341"
    },
    "Rank": 27883,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06342"
    },
    "Rank": 27888,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06343"
    },
    "Rank": 27889,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06344"
    },
    "Rank": 27890,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06345"
    },
    "Rank": 27899,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Josephs General Hospital,D NO 27-1-12, OPP AC COLLEGE, NEAR FLY OVER NAGARAMPALEM, GUNTUR, Andhra Pradesh, 522004",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06346"
    },
    "Rank": 27900,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06347"
    },
    "Rank": 27902,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06348"
    },
    "Rank": 27907,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06349"
    },
    "Rank": 27908,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0634a"
    },
    "Rank": 27911,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, ,RAJBHAVAN ROAD SOMAJIGUDA, Telangana, 500082",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0634b"
    },
    "Rank": 27916,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0634c"
    },
    "Rank": 27918,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apex Hospital,\nUttar Pradesh,Delhi Road, opposite hotel park square, Moradabad, Uttar Pradesh, 244001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0634d"
    },
    "Rank": 27924,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0634e"
    },
    "Rank": 27926,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0634f"
    },
    "Rank": 27928,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06350"
    },
    "Rank": 27930,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MAGNUS HOSPITAL,\nRajasthan,24 ROOP NAGAR BHUWANA UDAIPUR, Rajasthan, 313001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06351"
    },
    "Rank": 27931,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Coochbehar Govt Medical College and Hospital, West Bengal,Vivekananda Street, Pilkhana, Cooch Behar, WB-736101, West Bengal, 736101",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06352"
    },
    "Rank": 27934,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06353"
    },
    "Rank": 27936,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06354"
    },
    "Rank": 27949,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "HCG Manavata Cancer Centre, A Unit of HCG Manavata Oncology LLP,,Near Mylan Circle, Mumbai Naka, Nashik-422002, Maharashtra, 422002",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06355"
    },
    "Rank": 27953,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Petals Newborn and Children Hospital, Chhattisgarh,BHEEMSEN BHAWAN MARG, SAMTA COLONY, RAIPUR  CHHATTISGARH, Chhattisgarh, 492001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06356"
    },
    "Rank": 27954,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06357"
    },
    "Rank": 27955,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bokaro General Hospital, ,SECTOR - 4 BOKARO STEEL CITY\nDIST - BOKARO, Jharkhand, 827004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06358"
    },
    "Rank": 27958,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06359"
    },
    "Rank": 27965,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital,,Malsi, Mussoorie Diversion Road, Uttarakhand, 248001",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0635a"
    },
    "Rank": 27967,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM Health Care Private Limited, Tamil Nadu,New No 72, Old No 54, Nelson Manickam Road, Aminjikarai, Chennai – 600029 Tamilnadu State, Tamil Nadu, 600029",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0635b"
    },
    "Rank": 27970,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Multispeciality Hospital, West Bengal,Narayana Multispeciality Hospital 78, Jessore Road ( South),\nKolkata, West Bengal, West Bengal, 700127",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0635c"
    },
    "Rank": 27976,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0635d"
    },
    "Rank": 27990,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0635e"
    },
    "Rank": 27993,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Northern Railway Central Hospital,, ,Basant Lane New Delhi, Delhi (NCT), 110055",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0635f"
    },
    "Rank": 27999,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tej Bahadur Sapru Hospital, ,Stanley Road opp Erny memorial school Beli Goan Prayagraj up pin- 211002, Uttar Pradesh, 211002",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06360"
    },
    "Rank": 28000,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Mission Hospital, West Bengal\n,The Mission Hospital, 219 P, Immon Kalyan Sarani, Sector-2C, Bidhannagar, Durgapur, Dist- Paschim Ba, West Bengal, 713212",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06361"
    },
    "Rank": 28002,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06362"
    },
    "Rank": 28003,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata Shrimati Devendra Kumari Singhdeo Government Medical College, Ambikapur,Kanyaparisar  Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06363"
    },
    "Rank": 28007,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rukmani Birla Hospital , (A unit of The Calcutta Medical Research Institute) ,Gopalpura Bypass Road, Near Triveni Flyover, Jaipur, Rajasthan, 302018",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06364"
    },
    "Rank": 28008,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Specialty Hospitals, ,IIIM NEHRU NAGAR GHAZIABAD, Uttar Pradesh, 201001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06365"
    },
    "Rank": 28009,
    "Allotted Quota": "All India",
    "Allotted Institute": "HINDU RAO HOSPITAL DELHI,HINDU RAO HOSPITAL DELHI, Delhi (NCT), 110007",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06366"
    },
    "Rank": 28010,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06367"
    },
    "Rank": 28014,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06368"
    },
    "Rank": 28021,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06369"
    },
    "Rank": 28026,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0636a"
    },
    "Rank": 28027,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lilavati Hospital and Research Centre, ,Lilavati Hospital And Research Centre A-791, Bandra Reclamation, Bandra - West, Mumbai, Maharashtra, 400050",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0636b"
    },
    "Rank": 28029,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0636c"
    },
    "Rank": 28031,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0636d"
    },
    "Rank": 28032,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Northern Railway Central Hospital,, ,Basant Lane New Delhi, Delhi (NCT), 110055",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0636e"
    },
    "Rank": 28034,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0636f"
    },
    "Rank": 28036,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06370"
    },
    "Rank": 28039,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kanyakumari Medical Mission, Tamil Nadu,CSI Hospital Neyyoor Kanyakumari Dist, Tamil Nadu, 629802",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06371"
    },
    "Rank": 28042,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospital, ,Sector-62, Phase VIII, Mohali- 160062, Punjab, 160062",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06372"
    },
    "Rank": 28044,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06373"
    },
    "Rank": 28046,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "C.S.I. Holdsworth Memorial Hospital, ,P.B.No. 38, Sawday Road, Mandi Mohalla, Mysuru, Karnataka, 570001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06374"
    },
    "Rank": 28047,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06375"
    },
    "Rank": 28054,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06376"
    },
    "Rank": 28057,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06377"
    },
    "Rank": 28058,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06378"
    },
    "Rank": 28064,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06379"
    },
    "Rank": 28066,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0637a"
    },
    "Rank": 28068,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. Medical College (Maharani Hospital), Jagdalpur, Chhattisgarh,Late Baliram Kashyap memorial Govt. Medical College, Dimrapal, Jagdalpur Dist-Bastar, Chhattisgarh, 494001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0637b"
    },
    "Rank": 28072,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "VCSG Govt Institute of Medical Science and Research and HNB Base Teaching Hospital\n, ,Srikot Ganganali Srinagar Garhwal Uttarakhand, Uttarakhand, 246174",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0637c"
    },
    "Rank": 28074,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0637d"
    },
    "Rank": 28081,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0637e"
    },
    "Rank": 28082,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0637f"
    },
    "Rank": 28083,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Regency Hospital,,A-2, Sarvodaya Nagar, Kanpur, Uttar Pradesh, 208005",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06380"
    },
    "Rank": 28092,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Josephs General Hospital,D NO 27-1-12, OPP AC COLLEGE, NEAR FLY OVER NAGARAMPALEM, GUNTUR, Andhra Pradesh, 522004",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06381"
    },
    "Rank": 28095,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06382"
    },
    "Rank": 28096,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06383"
    },
    "Rank": 28098,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Allopathic Medical College, Uttar Pradesh,RANI DURGAVATI MEDICAL COLLEGE NARAINI ROAD BANDA, Uttar Pradesh, 210001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06384"
    },
    "Rank": 28102,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital, B.H.Road, Tumakuru-572101, Karnataka, 572101",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06385"
    },
    "Rank": 28103,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06386"
    },
    "Rank": 28106,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital,\n,GT ROAD NEAR BUS STAND PANIPAT, Haryana, 132103",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06387"
    },
    "Rank": 28118,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06388"
    },
    "Rank": 28132,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06389"
    },
    "Rank": 28138,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dharan Hospital ,\nTamil Nadu,14 SEELANAICKENPATTY BYPASS SALEM 636201, Tamil Nadu, 636201",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0638a"
    },
    "Rank": 28139,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pandurang Ramarao Dongaonkar District Hospital, Chhattisgarh,Pandurang Ramarao Dongaonkar\nDistrict Hospital Durg G E Road District Durg Chhattisgarh 491001, Chhattisgarh, 491001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0638b"
    },
    "Rank": 28144,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, Uttar Pradesh,H-1, 24,26,27, KAUSHAMBI, NEAR DABUR CHOWK, GHAZIABAD, Uttar Pradesh, 201010",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0638c"
    },
    "Rank": 28146,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0638d"
    },
    "Rank": 28152,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SBLS Civil Hospital, ,NEAR JYOTI CHOWK JAIL ROAD JALANDHAR, Punjab, 144001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0638e"
    },
    "Rank": 28153,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0638f"
    },
    "Rank": 28158,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government General Hospital, ,GOVERNMENT GENERAL HOSPITAL, ALANPUR LINK ROAD SAWAI MADHOPUR 322001, Rajasthan, 322001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06390"
    },
    "Rank": 28160,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06391"
    },
    "Rank": 28161,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aditya Birla Memorial Hospital, Maharashtra,Aditya Birla Hospital Marg, P.O.- Chinchwad, Pune, Maharashtra, 411033",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06392"
    },
    "Rank": 28164,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06393"
    },
    "Rank": 28168,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06394"
    },
    "Rank": 28169,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06395"
    },
    "Rank": 28170,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Ophthalmology,RIO Prev Known as Nehru Institute of Ophthalmology and Research Centre Civil Lines Sitapur, Uttar Pradesh, 261001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06396"
    },
    "Rank": 28171,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06397"
    },
    "Rank": 28173,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Goverment District Hospital Bundi, ,IN FRONT OF COLLECTORATE BUNDI, Rajasthan, 323001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06398"
    },
    "Rank": 28174,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06399"
    },
    "Rank": 28181,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Associated Hospital Government Medical College, ,Kanth Bagh Baramulla, Jammu And Kashmir, 193103",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0639a"
    },
    "Rank": 28182,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Calcutta Medical Research Institute, ,The Calcutta Medical Research Institute 7 by 2 Diamond Harbour Road Kolkata 700027, West Bengal, 700027",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0639b"
    },
    "Rank": 28184,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0639c"
    },
    "Rank": 28185,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0639d"
    },
    "Rank": 28189,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0639e"
    },
    "Rank": 28191,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0639f"
    },
    "Rank": 28194,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063a0"
    },
    "Rank": 28197,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063a1"
    },
    "Rank": 28202,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063a2"
    },
    "Rank": 28207,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Bangur Hospital, Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan , Rajasthan-306401,Government Medical College and Bangur Hospital Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan Ra, Rajasthan, 306401",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063a3"
    },
    "Rank": 28211,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063a4"
    },
    "Rank": 28222,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063a5"
    },
    "Rank": 28225,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Durgapur Steel Plant Hospital, ,J M Sengupta Road, B Zone,  Durgapur, Dist- Paschim Bardhaman, West Bengal, 713205",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063a6"
    },
    "Rank": 28230,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Down Town Hospital, G S Road, ,G S Road, Dispur, Guwahati-781006, Assam, 781006",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063a7"
    },
    "Rank": 28231,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063a8"
    },
    "Rank": 28233,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063a9"
    },
    "Rank": 28239,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Vinoba Bhave Civil Hospital,,S R Delkar Marg, Saily Road, Silvassa, Dadra And Nagar Haveli, 396230",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063aa"
    },
    "Rank": 28241,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063ab"
    },
    "Rank": 28248,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chandan Hospital, ,Vijayant Khand, Gomti Nagar, Lucknow, Uttar Pradesh, 226010",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063ac"
    },
    "Rank": 28254,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063ad"
    },
    "Rank": 28258,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063ae"
    },
    "Rank": 28271,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deben Mahato (Sadar) Hospital,,Main Road Near SP office PO Purulia Main PS Purulia Town Dist Purulia West Bengal, West Bengal, 723101",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063af"
    },
    "Rank": 28273,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063b0"
    },
    "Rank": 28275,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063b1"
    },
    "Rank": 28278,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063b2"
    },
    "Rank": 28280,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063b3"
    },
    "Rank": 28281,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESIC MEDICAL COLLEGE AND HOSPITAL, Bihar,ESIC Medical College and Hospital,Ara Danapur Road, Sikandarpur, Bihta, Patna, Bihar, 801103",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063b4"
    },
    "Rank": 28289,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063b5"
    },
    "Rank": 28299,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government DB General Medical Hosptal,Churu, Nayabass, Churu",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063b6"
    },
    "Rank": 28306,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063b7"
    },
    "Rank": 28308,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indira Gandhi Govt. General Hospital and PG Institute, No. 1, ,No.1, Victor Simonel street, Puducherry -1., Puducherry, 605001",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063b8"
    },
    "Rank": 28309,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital , ,GOVT HEAD QUARTERS HOSPITAL, THURAIYUR MAIN ROAD,PERAMBALUR, TAMILNADU, Tamil Nadu, 621212",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063b9"
    },
    "Rank": 28320,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "PRIYA HOSPITAL,\nUttar Pradesh,D-25, HIG, World Bank Barra, Bypass Road, Kanpur Nagar, Uttar Pradesh, 208027",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063ba"
    },
    "Rank": 28329,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "PRIYA HOSPITAL,\nUttar Pradesh,D-25, HIG, World Bank Barra, Bypass Road, Kanpur Nagar, Uttar Pradesh, 208027",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063bb"
    },
    "Rank": 28340,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Durgabai Deshmukh Hospital, ,OSMANIA UNIVERSITY ROAD, VIDYANAGAR, HYDERABAD, Telangana, 500044",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063bc"
    },
    "Rank": 28342,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063bd"
    },
    "Rank": 28343,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Heritage Hospital Pvt. Ltd.,\nUttar Pradesh,Madhav Market Lanka Near BHU Gate Varanasi, Uttar Pradesh, 221005",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063be"
    },
    "Rank": 28348,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063bf"
    },
    "Rank": 28349,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mohandai Oswal Hospital, ,Mohandai Oswal Hospital, Sherpur Byepass, G T Road, Ludhiana-141009, Punjab, 141009",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063c0"
    },
    "Rank": 28351,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063c1"
    },
    "Rank": 28356,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Siddipet,SURVEY NO 54  GOVERNMENT MEDICAL COLLEGE  ENSANPALLY  SIDDIPET   502114, Telangana, 502114",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063c2"
    },
    "Rank": 28361,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063c3"
    },
    "Rank": 28365,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vallabhbhai Patel Chest Institute, Delhi,Vallabhbhai Patel Chest Institute, Delhi, Delhi (NCT), 110007",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063c4"
    },
    "Rank": 28366,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medivision Eye and Health Care Centre, ,10-3-304/1 to 4, Indira Sadan,Humayun Nagar, Near NMDC, Hyderabad., Telangana, 500028",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063c5"
    },
    "Rank": 28375,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.G. Hospital and PG Medical Institute ,,No:5, Arts College Road, Coimbatore - 641018, Tamil Nadu, 641018",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063c6"
    },
    "Rank": 28377,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063c7"
    },
    "Rank": 28378,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063c8"
    },
    "Rank": 28382,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063c9"
    },
    "Rank": 28383,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063ca"
    },
    "Rank": 28389,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063cb"
    },
    "Rank": 28396,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta Abdur Razzaque Ansari Memorial Weavers Hospital, ,Medanta Abdur Razzaque Ansari Memorial Weavers Hospital NH 33 Irba ranchi, Jharkhand, 835219",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063cc"
    },
    "Rank": 28407,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063cd"
    },
    "Rank": 28413,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063ce"
    },
    "Rank": 28418,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S.K.Hospital, ,SUPERINTENDENT RNO 36  GOVT SK HOSPITAL  SILVER JUBLI ROAD  SIKAR  RAJ, Rajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063cf"
    },
    "Rank": 28422,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063d0"
    },
    "Rank": 28423,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Institute of Medical Sciences (SVIMS), A State University estd. by Govt. of A.P.,Sri Venkateswara Institute of Medical Sciences, SVIMS, Alipiri Road, Tirupati, Andhra Pradesh, 517507",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063d1"
    },
    "Rank": 28426,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063d2"
    },
    "Rank": 28431,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063d3"
    },
    "Rank": 28432,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063d4"
    },
    "Rank": 28433,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063d5"
    },
    "Rank": 28434,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063d6"
    },
    "Rank": 28436,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shree Mata Vaishno Devi Narayana Superspeciality Hospital, Jammu and Kashmir,Kakraya Katra Reasi District, Jammu And Kashmir, 182320",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063d7"
    },
    "Rank": 28439,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Mission Hospital, West Bengal\n,The Mission Hospital, 219 P, Immon Kalyan Sarani, Sector-2C, Bidhannagar, Durgapur, Dist- Paschim Ba, West Bengal, 713212",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063d8"
    },
    "Rank": 28443,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bharath Hospital, Kerala,AZAD LANE, KOTTAYAM, KERALA 686001, Kerala, 686001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063d9"
    },
    "Rank": 28444,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063da"
    },
    "Rank": 28454,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Divisional Railway Hospital, West Bengal,Chief Medical Superintendent  Divisional Railway Hospital  Asansol Budha Road Budha Paschim Bardhama, West Bengal, 713301",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063db"
    },
    "Rank": 28461,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "RENOVA SOUMYA CANCER CENTRE ,\nTelangana,Plot. No 3 7 218 219 Behind DC Green Karkhana Secunderabad 500015,\nTelangana, 500015",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063dc"
    },
    "Rank": 28463,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063dd"
    },
    "Rank": 28472,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063de"
    },
    "Rank": 28477,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Amandeep Hospital, Punjab,Dalhousie Road, Mamoon, Pathankot, Punjab, 145001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063df"
    },
    "Rank": 28480,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, A Unit of Hometrail Buildtech Pvt Ltd. ,Near Civil Hospital, Phase-6, Mohali, Punjab-160055, Punjab, 160055",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063e0"
    },
    "Rank": 28486,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Medical Center, ,14 MAJOR ARTERIAL ROAD EW NEWTOWN RAJARHAT KOLKATA 700160, West Bengal, 700160",
    "Course": "(NBEMS) IMMUNO- HAEMATOLOGY AND BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063e1"
    },
    "Rank": 28493,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Valluvanad Hospital Complex Limited,KANNIYAMPURAM POST OTTAPALAM\nPALAKKAD KERALA, Kerala, 679104",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063e2"
    },
    "Rank": 28494,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saroj Gupta Cancer Centre Welfare Home and Research Institute,,Mahatma Gandhi Road, Thakurpukur, Kolkata, West Bengal, 700063",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063e3"
    },
    "Rank": 28498,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rani Hospital, ,INFRONT OF GOVERNOR HOUSE BOOTY ROAD RANCHI, Jharkhand, 834001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063e4"
    },
    "Rank": 28500,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hartej Hospital (Hartej Nursing Home), Amritsar,\nPunjab,A-290-292,RANJIT AVENUE,AMRITSAR,PUNJAB, Punjab, 143001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063e5"
    },
    "Rank": 28503,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster CMI Hospital, ,43/2 New Airport Road, NH-7, Kodigehalli, Sahakara Nagar, Hebbal, Bengaluru, Karnataka, 560092",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063e6"
    },
    "Rank": 28516,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063e7"
    },
    "Rank": 28521,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063e8"
    },
    "Rank": 28522,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063e9"
    },
    "Rank": 28529,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063ea"
    },
    "Rank": 28530,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Durgabai Deshmukh Hospital, ,OSMANIA UNIVERSITY ROAD, VIDYANAGAR, HYDERABAD, Telangana, 500044",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063eb"
    },
    "Rank": 28540,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063ec"
    },
    "Rank": 28543,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. District Saadat Hospital,\n,Near Patel Circle Tonk, Rajasthan, 304001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063ed"
    },
    "Rank": 28544,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063ee"
    },
    "Rank": 28546,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063ef"
    },
    "Rank": 28548,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063f0"
    },
    "Rank": 28550,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Emergency Medical Care (EMC) Hospital,\n,Back Side DC Residence Lane, Green Avenue, Amritsar, Punjab, 143001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063f1"
    },
    "Rank": 28552,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences, ,academics@kimshospitals.com, Telangana, 500003",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063f2"
    },
    "Rank": 28555,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Lal Bahadur Shastri Government Medical College and Hospital , ,VPO- Bhangrotu, MC- Ner Chowk, District- Mandi, Himachal Pradesh, 175021",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063f3"
    },
    "Rank": 28556,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063f4"
    },
    "Rank": 28557,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063f5"
    },
    "Rank": 28558,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deben Mahato (Sadar) Hospital,,Main Road Near SP office PO Purulia Main PS Purulia Town Dist Purulia West Bengal, West Bengal, 723101",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063f6"
    },
    "Rank": 28562,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063f7"
    },
    "Rank": 28563,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063f8"
    },
    "Rank": 28565,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063f9"
    },
    "Rank": 28566,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Arambagh Sub Divisional/ Super Speciality Hospital, ,Arambagh, Hooghly , Pin - 712601, West Bengal, 712601",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063fa"
    },
    "Rank": 28567,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Bangur Hospital, Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan , Rajasthan-306401,Government Medical College and Bangur Hospital Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan Ra, Rajasthan, 306401",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063fb"
    },
    "Rank": 28569,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063fc"
    },
    "Rank": 28570,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063fd"
    },
    "Rank": 28571,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063fe"
    },
    "Rank": 28572,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Petals Newborn and Children Hospital, Chhattisgarh,BHEEMSEN BHAWAN MARG, SAMTA COLONY, RAIPUR  CHHATTISGARH, Chhattisgarh, 492001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a063ff"
    },
    "Rank": 28593,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. GERIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06400"
    },
    "Rank": 28594,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Syed Ammal Trust Hospital, Tamil Nadu,DR E M ABDULLAH CAMPUS 127 SWAMY VIVEKANANDAR SALAI\nRAMANATHAPURAM, Tamil Nadu, 623501",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06401"
    },
    "Rank": 28599,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06402"
    },
    "Rank": 28601,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Hospital,377, MAIN ROAD, SATTUR, VIRUDHUNAGAR, Tamil Nadu, 626203",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06403"
    },
    "Rank": 28609,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06404"
    },
    "Rank": 28615,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06405"
    },
    "Rank": 28616,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06406"
    },
    "Rank": 28617,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chittaranjan National Cancer Institute, 37- ,37, S P Mukherjee Road, Kolkata-700026- 1st campus, 299 DJ Block A.A.1, Newtown Kolkata- 700156-2nd, West Bengal, 700026",
    "Course": "M.D.Laboratory Medicine Course",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06407"
    },
    "Rank": 28620,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06408"
    },
    "Rank": 28625,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06409"
    },
    "Rank": 28631,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Malabar Institute of Medical Sciences, ,Govindapuram P.O., Mini Bypass Road, Kozhikode, Kerala, 673016",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0640a"
    },
    "Rank": 28633,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0640b"
    },
    "Rank": 28634,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,DISTRICT HOSPITAL NEAR SANGAM CIRCLE DR RAJ KUMAR ROAD BALLARI-583101 KARNATAKA, Karnataka, 583101",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0640c"
    },
    "Rank": 28638,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0640d"
    },
    "Rank": 28640,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Regency Hospital,,A-2, Sarvodaya Nagar, Kanpur, Uttar Pradesh, 208005",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0640e"
    },
    "Rank": 28641,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0640f"
    },
    "Rank": 28654,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Surya Mother and Child Care Jaipur Pvt Ltd.,\n,B-7, Sawai Ram SIngh Road, Opp SMS Hospital, Jaipur, Rajasthan, 302001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06410"
    },
    "Rank": 28655,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06411"
    },
    "Rank": 28663,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06412"
    },
    "Rank": 28664,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir C V Raman General Hospital, ,80 FEET ROAD INDIRANAGARA BANGALORE, Karnataka, 560038",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06413"
    },
    "Rank": 28665,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06414"
    },
    "Rank": 28667,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chandan Hospital, ,Vijayant Khand, Gomti Nagar, Lucknow, Uttar Pradesh, 226010",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06415"
    },
    "Rank": 28671,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06416"
    },
    "Rank": 28672,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SBLS Civil Hospital, ,NEAR JYOTI CHOWK JAIL ROAD JALANDHAR, Punjab, 144001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06417"
    },
    "Rank": 28673,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Government Medical College, Vidisha,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA MP, Madhya Pradesh, 464001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06418"
    },
    "Rank": 28674,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06419"
    },
    "Rank": 28681,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0641a"
    },
    "Rank": 28694,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Popular Medicare,POPULAR MEDICARE LTD N 10 60 A 2 BLW ROAD KAKARAMATTA VARANASI, Uttar Pradesh, 221106",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0641b"
    },
    "Rank": 28697,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "NEOTIA GETWEL HEALTHCARE CENTRE,\n,Uttorayon, Behind City Centre, Matigara, Siliguri, West Bengal, 734010",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0641c"
    },
    "Rank": 28702,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lord Mahavir Civil Hospital,,Lord Mahavir Civil Hospital, MCH Wing Fieldganj Road Ludhiana, Punjab, 141008",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0641d"
    },
    "Rank": 28715,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0641e"
    },
    "Rank": 28718,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asansol District Hospital, ,S. B. GORAI ROAD, P.O.- ASANSOL, DIST.- PASCHIM BARDHAMAN, West Bengal, 713301",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0641f"
    },
    "Rank": 28721,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06420"
    },
    "Rank": 28725,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06421"
    },
    "Rank": 28726,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruby General Hospital, ,576 Anandapur, Kasba Golpark, E. M. Bypass, Kolkata 700107, West Bengal, 700107",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06422"
    },
    "Rank": 28727,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "DIPLOMA IN OTO- RHINO-LARYNGOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06423"
    },
    "Rank": 28730,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital ,ALIPURDUAR DISTRICT HOSPITAL, ALIPURDUAR, HOSPITAL ROAD, WARD NO-XIII, ALIPURDUAR , PIN-736121, WEST BENGAL, 736121",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06424"
    },
    "Rank": 28745,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, A Unit of Hometrail Buildtech Pvt Ltd. ,Near Civil Hospital, Phase-6, Mohali, Punjab-160055, Punjab, 160055",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06425"
    },
    "Rank": 28748,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06426"
    },
    "Rank": 28753,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06427"
    },
    "Rank": 28754,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mohandai Oswal Hospital, ,Mohandai Oswal Hospital, Sherpur Byepass, G T Road, Ludhiana-141009, Punjab, 141009",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06428"
    },
    "Rank": 28755,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06429"
    },
    "Rank": 28762,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Omega Hospital,,MLA Colony, Road No. 12, Banjara Hills, Hyderabad, Telangana, 500034",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0642a"
    },
    "Rank": 28763,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0642b"
    },
    "Rank": 28764,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Delhi Hospital And Nursing Home, Haryana,METRO PILLAR NO. 812 NEAR SHYAMJI COMPLEX BAHADURGARH DIST JHAJJAR-124507, Haryana, 124507",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0642c"
    },
    "Rank": 28769,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0642d"
    },
    "Rank": 28770,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0642e"
    },
    "Rank": 28786,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0642f"
    },
    "Rank": 28793,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chaithanya Eye Hospital and Research Institute, Kerala,Manjadi, Thiruvalla, Kerala 689105, Kerala, 689105",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06430"
    },
    "Rank": 28797,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06431"
    },
    "Rank": 28798,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06432"
    },
    "Rank": 28800,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06433"
    },
    "Rank": 28804,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MAXCARE Hospital, ,6-1-236, Near Kuda Office Line Sai Nagar, Opposite Ashoka Hotel, Hanamkonda, Telangana 506001, Telangana, 506001",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06434"
    },
    "Rank": 28807,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Manipal Hospital, ,#98, Rustom Bagh, HAL Airport Road, Bengaluru, Karnataka, 560017",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06435"
    },
    "Rank": 28809,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06436"
    },
    "Rank": 28820,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "N S Memorial Institute of Medical Sciences ,N S Memorial Institute of Medical Sciences Palathara, Thattamala, Kollam, Kerala, 691020",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06437"
    },
    "Rank": 28824,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06438"
    },
    "Rank": 28828,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kothari Medical and Research Institute, ,NH-15, Jaisalmer road, Bikaner, Rajasthan, 334004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06439"
    },
    "Rank": 28829,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhabha Atomic Research Centre and Hospital, ,Anushaktinagar, Mumbai, Maharashtra, 400094",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0643a"
    },
    "Rank": 28834,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Mission Hospital, West Bengal\n,The Mission Hospital, 219 P, Immon Kalyan Sarani, Sector-2C, Bidhannagar, Durgapur, Dist- Paschim Ba, West Bengal, 713212",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0643b"
    },
    "Rank": 28838,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0643c"
    },
    "Rank": 28846,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Life line Super Specialty Hospital, ,14th Mile, Melood P.O Adoor , Pathanamthitta Dist, Kerala, 691554",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0643d"
    },
    "Rank": 28848,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government Women and Children Hospital, (Formerly Indira Gandhi.\nGeneral Hospital) ,100 feet road, Ellaipillaichavady, Puducherry, 605005",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0643e"
    },
    "Rank": 28851,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0643f"
    },
    "Rank": 28856,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Civil Hospital, ,Medical Superintendent Office, Civil Hospital, Dawrpui, Aizawl, Mizoram, 796001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06440"
    },
    "Rank": 28858,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06441"
    },
    "Rank": 28860,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06442"
    },
    "Rank": 28866,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06443"
    },
    "Rank": 28875,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "HINDU RAO HOSPITAL DELHI,HINDU RAO HOSPITAL DELHI, Delhi (NCT), 110007",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06444"
    },
    "Rank": 28888,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06445"
    },
    "Rank": 28889,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06446"
    },
    "Rank": 28893,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "O P Jindal Institute Of Cancer and Cardiac Research, Haryana,Model Town, Hisar, Haryana, Haryana, 125005",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06447"
    },
    "Rank": 28894,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Princess Durru Shehvar Children`s and General Hospital, ,22 3 660A PURANI HAVELI HYDERABAD, Telangana, 500002",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06448"
    },
    "Rank": 28896,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06449"
    },
    "Rank": 28901,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster CMI Hospital, ,43/2 New Airport Road, NH-7, Kodigehalli, Sahakara Nagar, Hebbal, Bengaluru, Karnataka, 560092",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0644a"
    },
    "Rank": 28906,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Hospital,,JANGLAT MANDI ANANTNAG, JAMMU AND KASHMIR 192101, Jammu And Kashmir, 192101",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0644b"
    },
    "Rank": 28915,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0644c"
    },
    "Rank": 28916,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0644d"
    },
    "Rank": 28924,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0644e"
    },
    "Rank": 28936,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0644f"
    },
    "Rank": 28937,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indus International Hospital, ,CHANDIGARH-AMBALA ROAD NH-22, DERABASSI, Punjab, 140507",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06450"
    },
    "Rank": 28938,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06451"
    },
    "Rank": 28945,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06452"
    },
    "Rank": 28954,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06453"
    },
    "Rank": 28961,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06454"
    },
    "Rank": 28962,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06455"
    },
    "Rank": 28963,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06456"
    },
    "Rank": 28965,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06457"
    },
    "Rank": 28971,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06458"
    },
    "Rank": 28972,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06459"
    },
    "Rank": 28974,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagwan Mahavir Cancer Hospital and Research Centre, ,Managed by K G Kothari Memorial Trust\nJawahar Lal Nehru Marg, Jaipur 302 017, Rajasthan, 302017",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0645a"
    },
    "Rank": 28977,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0645b"
    },
    "Rank": 28978,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0645c"
    },
    "Rank": 28982,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shija Hospitals and Research Institute, ,SHIJA HOSPITALS AND RESEARCH INSTITUTE, LANGOL, LAMPHELPAT, IMPHAL WEST, MANIPUR 795004, Manipur, 795004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0645d"
    },
    "Rank": 28984,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gangasheel Advanced Medical Research Institute, Uttar Pradesh,C-17, Deen Dayal Puram, Bareilly - 243122, Uttar Pradesh, 243122",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0645e"
    },
    "Rank": 28987,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government General Hospital, ,GOVERNMENT GENERAL HOSPITAL, ALANPUR LINK ROAD SAWAI MADHOPUR 322001, Rajasthan, 322001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0645f"
    },
    "Rank": 28989,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06460"
    },
    "Rank": 29000,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr.Chandrakanth Malabar Superspeciality Eye Hospital, Kerala,Kannur Rd, West Nadakkave, Vandipetta, Bilathikkulam, Kozhikode, Kerala 673011, Kerala, 673011",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06461"
    },
    "Rank": 29003,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06462"
    },
    "Rank": 29004,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06463"
    },
    "Rank": 29005,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Charak Hospital and Research Center, Uttar Pradesh,Hardoi Road, Dubagga, Lucknow, Uttar Pradesh, 226003",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06464"
    },
    "Rank": 29008,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Civil Hospital, ,Maharaja Agrasen District Civil Hospital Hisar, Haryana, 125001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06465"
    },
    "Rank": 29009,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06466"
    },
    "Rank": 29011,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chettinad Super Speciality Hospital, Tamil Nadu,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06467"
    },
    "Rank": 29012,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06468"
    },
    "Rank": 29016,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06469"
    },
    "Rank": 29024,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sanjay Gandhi Memorial Hospital, , ,Sanjay Gandhi Memorial Hospital\nS-Block, Near Bus Terminal, Mangolpuri, North West, Delhi - 110083, Delhi (NCT), 110083",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0646a"
    },
    "Rank": 29029,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital,\n,paddoc road, near majewadi gate, junagadh, Gujarat, 362001",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0646b"
    },
    "Rank": 29030,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MAGNUS HOSPITAL,\nRajasthan,24 ROOP NAGAR BHUWANA UDAIPUR, Rajasthan, 313001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0646c"
    },
    "Rank": 29032,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0646d"
    },
    "Rank": 29033,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0646e"
    },
    "Rank": 29035,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0646f"
    },
    "Rank": 29047,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kidwai Memorial Institute of Oncolgy,Kidwai Memorial Institute of Oncolgy, Karnataka, 560029",
    "Course": "DIP. IN MEDICAL RADIO- THERAPY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06470"
    },
    "Rank": 29062,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ivy Health and Life Sciences Pvt. Ltd. ,\n(Ivy Hospital Amritsar), Punjab,Ivy Hospital Amritsar, Airport Road, Village Heir, Amritsar, Punjab, Punjab, 143001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06471"
    },
    "Rank": 29063,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06472"
    },
    "Rank": 29066,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06473"
    },
    "Rank": 29067,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College,Fakhruddin Ali Ahmed Medical College, Assam, 781301",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06474"
    },
    "Rank": 29069,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shrikrishna Medical College & Hospital, Muzaffarpur,Shrikrishna Medical College & Hospital, Muzaffarpur, Bihar, 842004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06475"
    },
    "Rank": 29070,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06476"
    },
    "Rank": 29072,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06477"
    },
    "Rank": 29074,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06478"
    },
    "Rank": 29077,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06479"
    },
    "Rank": 29079,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0647a"
    },
    "Rank": 29085,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0647b"
    },
    "Rank": 29097,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0647c"
    },
    "Rank": 29098,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0647d"
    },
    "Rank": 29099,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0647e"
    },
    "Rank": 29100,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0647f"
    },
    "Rank": 29101,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ivy Health and Life Sciences,,Ivy Hospital Mohali, Sector 71 SAS Nagar, Punjab, 160071",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06480"
    },
    "Rank": 29107,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06481"
    },
    "Rank": 29109,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06482"
    },
    "Rank": 29110,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06483"
    },
    "Rank": 29112,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06484"
    },
    "Rank": 29113,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06485"
    },
    "Rank": 29114,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06486"
    },
    "Rank": 29117,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06487"
    },
    "Rank": 29118,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Life line Super Specialty Hospital, ,14th Mile, Melood P.O Adoor , Pathanamthitta Dist, Kerala, 691554",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06488"
    },
    "Rank": 29126,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06489"
    },
    "Rank": 29127,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0648a"
    },
    "Rank": 29142,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medical Trust Hospital, ,M.G. Road Pallimukku\nCochin, Kerala, 682016",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0648b"
    },
    "Rank": 29144,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Midland Healthcare and Research Centre, Uttar Pradesh,B-55 Mandir Marg, Mahanagar,Lucknow., Uttar Pradesh, 226006",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0648c"
    },
    "Rank": 29146,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Capital Hospital, Orissa,director.chb@gmail.com, Odisha, 751001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0648d"
    },
    "Rank": 29152,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "DURGAPUR SUB DIVISIONAL HOSPITAL ,\n,DR.ZAKIR HUSSIAN AVENUE, BIDHANNAGAR, DURGAPUR, West Bengal, 713206",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0648e"
    },
    "Rank": 29156,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0648f"
    },
    "Rank": 29160,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06490"
    },
    "Rank": 29163,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Civil Hospital Palwal, Palwal , Haryana,Civil Hospital Palwal, Haryana, 121102",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06491"
    },
    "Rank": 29173,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06492"
    },
    "Rank": 29174,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06493"
    },
    "Rank": 29178,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Sagar, Madhya Pradesh,shivaji ward tilli road sagar, Madhya Pradesh, 470002",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06494"
    },
    "Rank": 29189,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06495"
    },
    "Rank": 29195,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06496"
    },
    "Rank": 29199,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06497"
    },
    "Rank": 29205,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06498"
    },
    "Rank": 29207,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06499"
    },
    "Rank": 29212,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Suri Sadar Hospital, ,Laldighipara Suri Birbhum, West Bengal, 731101",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0649a"
    },
    "Rank": 29224,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC MEDICAL COLLEGE, JOKA,ESI-PGIMSR & ESIC MEDICAL COLLEGE, JOKA, KOLKATA, WEST BENGAL, West Bengal, 700104",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0649b"
    },
    "Rank": 29225,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0649c"
    },
    "Rank": 29233,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Bundelkhand Medical College Sagar M.P.,Govt. Bundelkhand Medical College Sagar M.P., Madhya Pradesh, 470001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0649d"
    },
    "Rank": 29238,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0649e"
    },
    "Rank": 29239,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Satguru Partap Singh Hospital, ,Sherpur Chowk, GT Road, Ludhiana, Punjab-141003, Punjab, 141003",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0649f"
    },
    "Rank": 29252,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064a0"
    },
    "Rank": 29256,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064a1"
    },
    "Rank": 29257,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064a2"
    },
    "Rank": 29280,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064a3"
    },
    "Rank": 29288,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruby General Hospital, ,576 Anandapur, Kasba Golpark, E. M. Bypass, Kolkata 700107, West Bengal, 700107",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064a4"
    },
    "Rank": 29298,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064a5"
    },
    "Rank": 29299,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS MEDICAL COLLEGE, NEAR GUJARAT HIGH COURT, S.G HIGH WAY, SOLA, AHMEDABAD, Gujarat, 380060",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064a6"
    },
    "Rank": 29310,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Satguru Partap Singh Hospital, ,Sherpur Chowk, GT Road, Ludhiana, Punjab-141003, Punjab, 141003",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064a7"
    },
    "Rank": 29314,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064a8"
    },
    "Rank": 29316,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bombay Hospital,Ring Road Indore, Madhya Pradesh, 452010",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064a9"
    },
    "Rank": 29323,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064aa"
    },
    "Rank": 29324,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MAXCARE Hospital, ,6-1-236, Near Kuda Office Line Sai Nagar, Opposite Ashoka Hotel, Hanamkonda, Telangana 506001, Telangana, 506001",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064ab"
    },
    "Rank": 29328,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064ac"
    },
    "Rank": 29330,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lakshmi Madhavan Hospital, Tirunelveli, Tamil Nadu,4A, South Byepass Road, Near BSNL, Vannarpettai, Tamil Nadu, 627003",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064ad"
    },
    "Rank": 29333,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064ae"
    },
    "Rank": 29334,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064af"
    },
    "Rank": 29336,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064b0"
    },
    "Rank": 29338,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064b1"
    },
    "Rank": 29344,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saket Hospital,10, Meera Marg, Agarwal Farm, Masarover, Jaipur, Rajasthan, 302020",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064b2"
    },
    "Rank": 29347,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064b3"
    },
    "Rank": 29358,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM Health Care Private Limited, Tamil Nadu,New No 72, Old No 54, Nelson Manickam Road, Aminjikarai, Chennai – 600029 Tamilnadu State, Tamil Nadu, 600029",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064b4"
    },
    "Rank": 29365,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064b5"
    },
    "Rank": 29369,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064b6"
    },
    "Rank": 29370,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064b7"
    },
    "Rank": 29371,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064b8"
    },
    "Rank": 29372,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indus International Hospital, ,CHANDIGARH-AMBALA ROAD NH-22, DERABASSI, Punjab, 140507",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064b9"
    },
    "Rank": 29374,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SunShine Hospital, ,1-7-201 TO 205\nPG Road, Paradise, Secunderabad, Telangana, 500003",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064ba"
    },
    "Rank": 29375,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shree Mata Vaishno Devi Narayana Superspeciality Hospital, Jammu and Kashmir,Kakraya Katra Reasi District, Jammu And Kashmir, 182320",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064bb"
    },
    "Rank": 29381,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gaba Hospital, Haryana,Bye Pass Road, Yamuna Nagar Haryana 135001, Haryana, 135001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064bc"
    },
    "Rank": 29382,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Satguru Partap Singh Hospital, ,Sherpur Chowk, GT Road, Ludhiana, Punjab-141003, Punjab, 141003",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064bd"
    },
    "Rank": 29384,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064be"
    },
    "Rank": 29386,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064bf"
    },
    "Rank": 29393,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064c0"
    },
    "Rank": 29396,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064c1"
    },
    "Rank": 29399,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064c2"
    },
    "Rank": 29407,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064c3"
    },
    "Rank": 29409,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064c4"
    },
    "Rank": 29411,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064c5"
    },
    "Rank": 29413,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064c6"
    },
    "Rank": 29414,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SBLS Civil Hospital, ,NEAR JYOTI CHOWK JAIL ROAD JALANDHAR, Punjab, 144001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064c7"
    },
    "Rank": 29420,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortune Hospital, Kanpur Uttar Pradesh ,\nUttar Pradesh,117-Q 40A-41 Sharda Nagar, Kanpur near Gurdev Crossing, Uttar Pradesh, 208025",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064c8"
    },
    "Rank": 29421,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064c9"
    },
    "Rank": 29423,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K C General Hospital, ,No. 89, 5th Cross, Malleshwaram, Bangalore, Karnataka, 560003",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064ca"
    },
    "Rank": 29426,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM Health Care Private Limited, Tamil Nadu,New No 72, Old No 54, Nelson Manickam Road, Aminjikarai, Chennai – 600029 Tamilnadu State, Tamil Nadu, 600029",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064cb"
    },
    "Rank": 29433,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chalmeda Anand Rao Institute of Medical Sciences (CAIMS) Cancer Hospital and Research Institute, ,Chalmeda Anand Rao Institute of Medical Sciences CAIMS Cancer Hospital and Research Institute bommak, Telangana, 505001",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064cc"
    },
    "Rank": 29440,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064cd"
    },
    "Rank": 29442,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064ce"
    },
    "Rank": 29447,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064cf"
    },
    "Rank": 29450,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064d0"
    },
    "Rank": 29452,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "DIP.IN GYNAE. and OBST.",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064d1"
    },
    "Rank": 29456,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064d2"
    },
    "Rank": 29463,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064d3"
    },
    "Rank": 29464,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064d4"
    },
    "Rank": 29466,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064d5"
    },
    "Rank": 29472,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064d6"
    },
    "Rank": 29474,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Vinoba Bhave Civil Hospital,,S R Delkar Marg, Saily Road, Silvassa, Dadra And Nagar Haveli, 396230",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064d7"
    },
    "Rank": 29475,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064d8"
    },
    "Rank": 29479,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064d9"
    },
    "Rank": 29482,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064da"
    },
    "Rank": 29484,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur and Associated Group of Hospitals Shri Haridev Joshi Hospital,,Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur, Rajasthan, 314001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064db"
    },
    "Rank": 29486,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "C.S.I. Kalyani General Hospital,, ,15, DR RADHAKRISHNAN SALAI, MYLAPORE, CHENNAI, Tamil Nadu, 600004",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064dc"
    },
    "Rank": 29488,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Associated Hospital Government Medical College, ,Kanth Bagh Baramulla, Jammu And Kashmir, 193103",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064dd"
    },
    "Rank": 29492,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064de"
    },
    "Rank": 29506,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Life line Super Specialty Hospital, ,14th Mile, Melood P.O Adoor , Pathanamthitta Dist, Kerala, 691554",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064df"
    },
    "Rank": 29513,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064e0"
    },
    "Rank": 29516,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064e1"
    },
    "Rank": 29517,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064e2"
    },
    "Rank": 29519,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064e3"
    },
    "Rank": 29522,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064e4"
    },
    "Rank": 29523,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064e5"
    },
    "Rank": 29531,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Healthworld Hospital, ,Healthworld Hospitals [A Unit of Parashmani Medical Centre Pvt.Ltd.], C-49,Commercial Area, Opp. ESI, West Bengal, 713216",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064e6"
    },
    "Rank": 29538,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064e7"
    },
    "Rank": 29539,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064e8"
    },
    "Rank": 29540,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees' State Insurance Corporation Medical College & Hospital, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD GULBARGA, Karnataka, 585106",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064e9"
    },
    "Rank": 29542,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dharwad,dsdharwad at gmail dot com, Karnataka, 580001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064ea"
    },
    "Rank": 29543,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064eb"
    },
    "Rank": 29546,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064ec"
    },
    "Rank": 29547,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kamla Nehru Memorial Hospital, 1, ,1, HASHIMPUR ROAD, PRAYAGRAJ, Uttar Pradesh, 211002",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064ed"
    },
    "Rank": 29551,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064ee"
    },
    "Rank": 29554,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064ef"
    },
    "Rank": 29559,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "FIMS HOSPITAL(FRANK INSTITUTE OF MEDICAL SCIENCES), Haryana,Sonipat-\nBahalgarh Road, Sonipat-NCR, Sonipat, Haryana, 131001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064f0"
    },
    "Rank": 29562,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "DIP.IN GYNAE. and OBST.",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064f1"
    },
    "Rank": 29563,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064f2"
    },
    "Rank": 29567,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. BDK hospital, govt. ,OPPOSITE ROADWAYS BUS STAND MAAN NAGAR JHUNJHUNU RAJASTHAN, Rajasthan, 333001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064f3"
    },
    "Rank": 29569,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. BDK hospital, govt. ,OPPOSITE ROADWAYS BUS STAND MAAN NAGAR JHUNJHUNU RAJASTHAN, Rajasthan, 333001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064f4"
    },
    "Rank": 29575,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064f5"
    },
    "Rank": 29577,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064f6"
    },
    "Rank": 29582,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sumitra Hospital,\nUttar Pradesh,A-119A SECTOR-35 NOIDA, Uttar Pradesh, 201301",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064f7"
    },
    "Rank": 29591,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan , Rajasthan-311001,Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan, Rajasthan, 311001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064f8"
    },
    "Rank": 29592,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruby Eye Hospital, Berhampur, Orissa,SUSHRUTA NAGAR, GOVINDA BIHAR,\nMG ROAD, BERHAMPUR, Odisha, 760001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064f9"
    },
    "Rank": 29594,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064fa"
    },
    "Rank": 29598,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aditya Birla Memorial Hospital, Maharashtra,Aditya Birla Hospital Marg, P.O.- Chinchwad, Pune, Maharashtra, 411033",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064fb"
    },
    "Rank": 29601,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mohandai Oswal Hospital, ,Mohandai Oswal Hospital, Sherpur Byepass, G T Road, Ludhiana-141009, Punjab, 141009",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064fc"
    },
    "Rank": 29602,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064fd"
    },
    "Rank": 29605,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta Abdur Razzaque Ansari Memorial Weavers Hospital, ,Medanta Abdur Razzaque Ansari Memorial Weavers Hospital NH 33 Irba ranchi, Jharkhand, 835219",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064fe"
    },
    "Rank": 29613,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a064ff"
    },
    "Rank": 29615,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06500"
    },
    "Rank": 29617,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06501"
    },
    "Rank": 29618,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06502"
    },
    "Rank": 29626,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06503"
    },
    "Rank": 29628,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06504"
    },
    "Rank": 29630,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S.K.Hospital, ,SUPERINTENDENT RNO 36  GOVT SK HOSPITAL  SILVER JUBLI ROAD  SIKAR  RAJ, Rajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06505"
    },
    "Rank": 29638,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Lal Bahadur Shastri Government Medical College and Hospital , ,VPO- Bhangrotu, MC- Ner Chowk, District- Mandi, Himachal Pradesh, 175021",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06506"
    },
    "Rank": 29644,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Josephs General Hospital,D NO 27-1-12, OPP AC COLLEGE, NEAR FLY OVER NAGARAMPALEM, GUNTUR, Andhra Pradesh, 522004",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06507"
    },
    "Rank": 29650,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tagore Hospital and Heart Care Center Pvt. Ltd., ,BANDA BAHADUR NAGAR, MAHAVIR MARG, JALANDHAR CITY 144001, PUNJAB, INDIA, Punjab, 144001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06508"
    },
    "Rank": 29651,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06509"
    },
    "Rank": 29663,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0650a"
    },
    "Rank": 29668,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sanjay Gandhi Postgarduate Institute of Medical Sciences,Sanjay Gandhi Postgarduate Institute of Medical Sciences, Uttar Pradesh, 226014",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0650b"
    },
    "Rank": 29670,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aditya Birla Memorial Hospital, Maharashtra,Aditya Birla Hospital Marg, P.O.- Chinchwad, Pune, Maharashtra, 411033",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0650c"
    },
    "Rank": 29675,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0650d"
    },
    "Rank": 29679,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Woodlands Multispeciality Hospital Limited,\n,8-5 ALIPORE ROAD KOLKATA, West Bengal, 700027",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0650e"
    },
    "Rank": 29680,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dharwad,dsdharwad at gmail dot com, Karnataka, 580001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0650f"
    },
    "Rank": 29682,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06510"
    },
    "Rank": 29683,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster CMI Hospital, ,43/2 New Airport Road, NH-7, Kodigehalli, Sahakara Nagar, Hebbal, Bengaluru, Karnataka, 560092",
    "Course": "(NBEMS) Palliative Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06511"
    },
    "Rank": 29696,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06512"
    },
    "Rank": 29699,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06513"
    },
    "Rank": 29702,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06514"
    },
    "Rank": 29706,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nizam`s Institute of Medical Sciences, ,NIZAMS INSTITUTE OF MEDICAL SCIENCES PANJAGUTTA HYDERABAD, Telangana, 500082",
    "Course": "(NBEMS) IMMUNO- HAEMATOLOGY AND BLOOD TRANSFUSION",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06515"
    },
    "Rank": 29708,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Woodlands Multispeciality Hospital Limited,\n,8-5 ALIPORE ROAD KOLKATA, West Bengal, 700027",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06516"
    },
    "Rank": 29709,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "DIPLOMA IN OTO- RHINO-LARYNGOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06517"
    },
    "Rank": 29710,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital Faridkot,\nCivil Hospital Faridkot, Punjab,Railway Road Opposite to DC Office, Punjab, 151203",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06518"
    },
    "Rank": 29718,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06519"
    },
    "Rank": 29721,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hayat Hospital, ,kahilipara Road, Odalbakra, Lalganesh,Guwahati, Assam, 781034",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0651a"
    },
    "Rank": 29722,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF HUMAN  BEHAVIOUR AND ALLIED SCIENCES,Institute of Human\nBehaviour and Allied Sciences,  Dilshad Garden, New Delhi, Delhi 110095, India, Delhi (NCT), 110095",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0651b"
    },
    "Rank": 29730,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0651c"
    },
    "Rank": 29739,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jabalpur Hospital and Research Centre, Jabalpur,\nMadhya Pradesh,Russel Chowk Napier Town Jabalpur, Madhya Pradesh, 482002",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0651d"
    },
    "Rank": 29740,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. GERIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0651e"
    },
    "Rank": 29741,
    "Allotted Quota": "All India",
    "Allotted Institute": "The National Institute of Health and Family Welfare,The National Institute of Health and Family Welfare, Delhi (NCT), 110067",
    "Course": "M.D. (COMMUNITY HEALTH  and ADMN.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0651f"
    },
    "Rank": 29743,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06520"
    },
    "Rank": 29744,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shija Hospitals and Research Institute, ,SHIJA HOSPITALS AND RESEARCH INSTITUTE, LANGOL, LAMPHELPAT, IMPHAL WEST, MANIPUR 795004, Manipur, 795004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06521"
    },
    "Rank": 29745,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Sathya Sai Sarla Memorial Hospital, Karnataka,Sathya Sai Grama Muddenahalli\nChikkaballapura, Karnataka, 562101",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06522"
    },
    "Rank": 29761,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06523"
    },
    "Rank": 29762,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Andhra Pradesh,Yerraguntla By pass Road, Proddatur, YSR Kadapa District, Andhra Pradesh, 516360",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06524"
    },
    "Rank": 29771,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06525"
    },
    "Rank": 29774,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06526"
    },
    "Rank": 29776,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Porwal Hospital, ,bakliwalravi@gmail.com, Rajasthan, 311001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06527"
    },
    "Rank": 29781,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dholpur,Old City Road Dholpur, Rajasthan, 328001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06528"
    },
    "Rank": 29784,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06529"
    },
    "Rank": 29787,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagwan Mahavir Cancer Hospital and Research Centre, ,Managed by K G Kothari Memorial Trust\nJawahar Lal Nehru Marg, Jaipur 302 017, Rajasthan, 302017",
    "Course": "(NBEMS) Palliative Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0652a"
    },
    "Rank": 29791,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt Seth Govind Das Victoria District Hospital, Madhya Pradesh,Govt Seth Govind Das Victoria District Hospital Jabalpur, Madhya Pradesh, 482002",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0652b"
    },
    "Rank": 29797,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Krishna Medical College and Hospital, ,Uma Nagar Muzaffarpur Bihar 842004, Bihar, 842004",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0652c"
    },
    "Rank": 29806,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0652d"
    },
    "Rank": 29813,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nice Hospital For Women, Newborn and Children, ,10-2-247 and 248, Shanti Nagar, Masab Tank, Hyderabad, Telangana 500057, India, Telangana, 500057",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0652e"
    },
    "Rank": 29815,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vadamalayan Hospital,,Registered Office - 15, Jawahar Road, Chokkikulam, Madurai - 625002.\n9A, Vallabhai Road, Chokkikula, Tamil Nadu, 625002",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0652f"
    },
    "Rank": 29817,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Syed Ammal Trust Hospital, Tamil Nadu,DR E M ABDULLAH CAMPUS 127 SWAMY VIVEKANANDAR SALAI\nRAMANATHAPURAM, Tamil Nadu, 623501",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06530"
    },
    "Rank": 29822,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06531"
    },
    "Rank": 29829,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "N S Memorial Institute of Medical Sciences ,N S Memorial Institute of Medical Sciences Palathara, Thattamala, Kollam, Kerala, 691020",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06532"
    },
    "Rank": 29830,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pandit Raghunath Murmu Medical College and Hospital , Orissa,AT-RANGAMATIA, PO- SHAMAKHUNTA, PS-BARIPADA SADAR, BARIPADA, MAYURBHANJ, Odisha, 757107",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06533"
    },
    "Rank": 29837,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06534"
    },
    "Rank": 29838,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06535"
    },
    "Rank": 29840,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Shishu Bhawan Hospital,,EIDGAH ROAD MADHYA NAGARI BILASPUR, Chhattisgarh, 495001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06536"
    },
    "Rank": 29841,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "DIPLOMA IN ORTHOPAEDICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06537"
    },
    "Rank": 29847,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06538"
    },
    "Rank": 29848,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Agarwals Eye Hospital, ,10, South Bypass Road, Vannarpettai,\nTirunelveli, Tamil Nadu, 627003",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06539"
    },
    "Rank": 29856,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,Government Medical College, Near RTC Bus stand, Gollaguda, Nalgonda Telangana - 508001, Telangana, 508001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0653a"
    },
    "Rank": 29857,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "HEALTH CITY HOSPITAL , Assam\n,UDAY NAGAR KOINADHARA KHANAPARA GUWAHATI KAMRUP M, Assam, 781022",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0653b"
    },
    "Rank": 29858,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital , ,shahid Rajmal Meena Govt. Dis. Hospital Mandi Road Baran, Rajasthan, 325205",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0653c"
    },
    "Rank": 29859,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0653d"
    },
    "Rank": 29860,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0653e"
    },
    "Rank": 29861,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0653f"
    },
    "Rank": 29862,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Mission Hospital, West Bengal\n,The Mission Hospital, 219 P, Immon Kalyan Sarani, Sector-2C, Bidhannagar, Durgapur, Dist- Paschim Ba, West Bengal, 713212",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06540"
    },
    "Rank": 29864,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06541"
    },
    "Rank": 29866,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06542"
    },
    "Rank": 29867,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06543"
    },
    "Rank": 29872,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06544"
    },
    "Rank": 29873,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06545"
    },
    "Rank": 29881,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06546"
    },
    "Rank": 29888,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06547"
    },
    "Rank": 29899,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06548"
    },
    "Rank": 29900,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06549"
    },
    "Rank": 29910,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0654a"
    },
    "Rank": 29913,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0654b"
    },
    "Rank": 29916,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0654c"
    },
    "Rank": 29920,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0654d"
    },
    "Rank": 29923,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0654e"
    },
    "Rank": 29927,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0654f"
    },
    "Rank": 29933,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06550"
    },
    "Rank": 29934,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital,Nawanshahr, Punjab,VPO  Barnala Kalan, Chandigarh Road, SBS Nagar, Punjab, 144514",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06551"
    },
    "Rank": 29935,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06552"
    },
    "Rank": 29937,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06553"
    },
    "Rank": 29940,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06554"
    },
    "Rank": 29944,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruban Memorial Hospital, Bihar,19 Patliputra Colony Near Patliputra Golambar Patna, Bihar, 800013",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06555"
    },
    "Rank": 29947,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06556"
    },
    "Rank": 29956,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06557"
    },
    "Rank": 29957,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SNR District Hospital,,SNR DISTRICT HOSPITAL, KOLAR KARNATAKA-563101,\nKarnataka, 563101",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06558"
    },
    "Rank": 29979,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kidwai Memorial Institute of Oncolgy,Kidwai Memorial Institute of Oncolgy, Karnataka, 560029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06559"
    },
    "Rank": 29980,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0655a"
    },
    "Rank": 29981,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tagore Hospital and Heart Care Center Pvt. Ltd., ,BANDA BAHADUR NAGAR, MAHAVIR MARG, JALANDHAR CITY 144001, PUNJAB, INDIA, Punjab, 144001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0655b"
    },
    "Rank": 29992,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0655c"
    },
    "Rank": 29994,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0655d"
    },
    "Rank": 29998,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kauvery Hospital,9-50, Trichy Main Road, Seelanaickenpatti, Salem, Tamil Nadu, 636201",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0655e"
    },
    "Rank": 30003,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0655f"
    },
    "Rank": 30005,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aditya Birla Memorial Hospital, Maharashtra,Aditya Birla Hospital Marg, P.O.- Chinchwad, Pune, Maharashtra, 411033",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06560"
    },
    "Rank": 30010,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital ,\nJalore Hospital ,GENERAL HOSPITAL JALORE, HOSPITAL JALORE, OPP. NAGAR PARISHAD, JALORE, RAJASTHAN, Rajasthan, 343001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06561"
    },
    "Rank": 30013,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06562"
    },
    "Rank": 30017,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06563"
    },
    "Rank": 30018,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06564"
    },
    "Rank": 30021,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,4th T Block, Beside Rajiv Gandhi University of Health Sciences Jayanagar,, Karnataka, 560041",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06565"
    },
    "Rank": 30031,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06566"
    },
    "Rank": 30033,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06567"
    },
    "Rank": 30038,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Area Hospital Sriikalahasthi, ,Near RTC Bustand Ayyalanadu Cheruvu Area Hospital, srikalahasthi Chitotorm, Andhra Pradesh, 517644",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06568"
    },
    "Rank": 30042,
    "Allotted Quota": "All India",
    "Allotted Institute": "All  India Institute of  Hygiene and Public Health,110, C. R  Avenue, Kolkata, West Bengal, 700073",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06569"
    },
    "Rank": 30044,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital,\n,DISTRICT SUREGEON DISTRICT HOSPITAL SETOR-11 NAVANAGAR BAGALKOTE\nKARNATAKA, Karnataka, 587103",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0656a"
    },
    "Rank": 30047,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0656b"
    },
    "Rank": 30048,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0656c"
    },
    "Rank": 30057,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0656d"
    },
    "Rank": 30066,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruban Memorial Hospital, Bihar,19 Patliputra Colony Near Patliputra Golambar Patna, Bihar, 800013",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0656e"
    },
    "Rank": 30067,
    "Allotted Quota": "All India",
    "Allotted Institute": "All  India Institute of  Hygiene and Public Health,110, C. R  Avenue, Kolkata, West Bengal, 700073",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0656f"
    },
    "Rank": 30076,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Stephen`s Hospital, ,Tis Hazari, Delhi (NCT), 110054",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06570"
    },
    "Rank": 30078,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Public Health Centre,,174, LAKE VIEW ROAD WEST MAMBALAM\nCHENNAI - 600033, Tamil Nadu, 600033",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06571"
    },
    "Rank": 30082,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "HEALTH CITY HOSPITAL , Assam\n,UDAY NAGAR KOINADHARA KHANAPARA GUWAHATI KAMRUP M, Assam, 781022",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06572"
    },
    "Rank": 30084,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,DISTRICT HOSPITAL NEAR SANGAM CIRCLE DR RAJ KUMAR ROAD BALLARI-583101 KARNATAKA, Karnataka, 583101",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06573"
    },
    "Rank": 30093,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06574"
    },
    "Rank": 30097,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06575"
    },
    "Rank": 30098,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06576"
    },
    "Rank": 30099,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06577"
    },
    "Rank": 30110,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06578"
    },
    "Rank": 30123,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06579"
    },
    "Rank": 30136,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0657a"
    },
    "Rank": 30142,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0657b"
    },
    "Rank": 30148,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Central Super Speciality Hospital,\n,mdcsshecr@gmail.com, Bihar, 800001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0657c"
    },
    "Rank": 30150,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Cure Well Hospital, Warangal, Telangana,15-1-48, SRINIVASA COLONY,\nOPP-MGM HOSPITAL WARANGAL\nTELANGANA 506007, Telangana, 506007",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0657d"
    },
    "Rank": 30156,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0657e"
    },
    "Rank": 30157,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0657f"
    },
    "Rank": 30161,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06580"
    },
    "Rank": 30166,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06581"
    },
    "Rank": 30173,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06582"
    },
    "Rank": 30175,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital, B.H.Road, Tumakuru-572101, Karnataka, 572101",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06583"
    },
    "Rank": 30177,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06584"
    },
    "Rank": 30178,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06585"
    },
    "Rank": 30181,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06586"
    },
    "Rank": 30184,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06587"
    },
    "Rank": 30210,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MNJ Institute of Oncology and Cancer ,MNJ Institute of Oncology and Regional Cancer Center, Red Hills, Hyderabad, Telangana, Telangana, 500004",
    "Course": "(NBEMS) Palliative Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06588"
    },
    "Rank": 30211,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06589"
    },
    "Rank": 30212,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0658a"
    },
    "Rank": 30213,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0658b"
    },
    "Rank": 30216,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0658c"
    },
    "Rank": 30217,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0658d"
    },
    "Rank": 30218,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chettinad Super Speciality Hospital, Tamil Nadu,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0658e"
    },
    "Rank": 30223,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0658f"
    },
    "Rank": 30230,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06590"
    },
    "Rank": 30232,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BARJORA SUPER SPECIALTY HOSPITAL, West Bengal,Post Office - Barjora Police Station - Barjora\nDistrict - Bankura\nPin - 722202, West Bengal, 722202",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06591"
    },
    "Rank": 30235,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. BDK hospital, govt. ,OPPOSITE ROADWAYS BUS STAND MAAN NAGAR JHUNJHUNU RAJASTHAN, Rajasthan, 333001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06592"
    },
    "Rank": 30257,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06593"
    },
    "Rank": 30261,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06594"
    },
    "Rank": 30264,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06595"
    },
    "Rank": 30266,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Durgapur Steel Plant Hospital, ,J M Sengupta Road, B Zone,  Durgapur, Dist- Paschim Bardhaman, West Bengal, 713205",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06596"
    },
    "Rank": 30268,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06597"
    },
    "Rank": 30273,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06598"
    },
    "Rank": 30280,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Swami Dayanand Hospital, ,Dilshad Garden, Shahdara, Delhi, Delhi (NCT), 110095",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06599"
    },
    "Rank": 30285,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES ADILABAD -504001 TELANGANA STATE, Telangana, 504001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0659a"
    },
    "Rank": 30292,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Star Women and Children Hospital ,\nTelangana,H No : 4-101 ,Saraswati nagar ,Teegalagutta pally Railway station road , karimnagar., Telangana, 505001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0659b"
    },
    "Rank": 30295,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0659c"
    },
    "Rank": 30297,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM Health Care Private Limited, Tamil Nadu,New No 72, Old No 54, Nelson Manickam Road, Aminjikarai, Chennai – 600029 Tamilnadu State, Tamil Nadu, 600029",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0659d"
    },
    "Rank": 30298,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan , Rajasthan-311001,Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan, Rajasthan, 311001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0659e"
    },
    "Rank": 30299,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE, PUDUCHERRY,INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE,\nPUDUCHERRY, Puducherry, 605009",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0659f"
    },
    "Rank": 30306,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065a0"
    },
    "Rank": 30307,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Medical Center, ,14 MAJOR ARTERIAL ROAD EW NEWTOWN RAJARHAT KOLKATA 700160, West Bengal, 700160",
    "Course": "(NBEMS) IMMUNO- HAEMATOLOGY AND BLOOD TRANSFUSION",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065a1"
    },
    "Rank": 30314,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065a2"
    },
    "Rank": 30326,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065a3"
    },
    "Rank": 30332,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065a4"
    },
    "Rank": 30333,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065a5"
    },
    "Rank": 30334,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. R. D. B. P. Jaipuria Hospital, (Attached with RUHS College of Medical Sciences),,GOVTRDBPJAIPURIAHOSPITALJAIPUR@GMAIL.COM, Rajasthan, 302018",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065a6"
    },
    "Rank": 30335,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065a7"
    },
    "Rank": 30337,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065a8"
    },
    "Rank": 30338,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Medical College for Women, Khanpur Kalan Sonepat,BPS Govt. Medical College for Women, Khanpur Kalan Sonepat, Haryana, 131305",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065a9"
    },
    "Rank": 30344,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065aa"
    },
    "Rank": 30345,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065ab"
    },
    "Rank": 30347,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Regency Hospital,,A-2, Sarvodaya Nagar, Kanpur, Uttar Pradesh, 208005",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065ac"
    },
    "Rank": 30364,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065ad"
    },
    "Rank": 30375,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065ae"
    },
    "Rank": 30379,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065af"
    },
    "Rank": 30398,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065b0"
    },
    "Rank": 30404,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government General Hospital, Principal Medical Officer General hospital,\n,Rajiv Gandhi Government General Hospital, near bijli ghar circle, ALWAR, Rajasthan, 301001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065b1"
    },
    "Rank": 30406,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Bhausaheb Hire Government Medical College,Sri Bhausaheb Hire Government Medical College,Dhule, Maharashtra, 424311",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065b2"
    },
    "Rank": 30411,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065b3"
    },
    "Rank": 30424,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. Medical College (Maharani Hospital), Jagdalpur, Chhattisgarh,Late Baliram Kashyap memorial Govt. Medical College, Dimrapal, Jagdalpur Dist-Bastar, Chhattisgarh, 494001",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065b4"
    },
    "Rank": 30427,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM Health Care Private Limited, Tamil Nadu,New No 72, Old No 54, Nelson Manickam Road, Aminjikarai, Chennai – 600029 Tamilnadu State, Tamil Nadu, 600029",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065b5"
    },
    "Rank": 30429,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065b6"
    },
    "Rank": 30434,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mohandai Oswal Hospital, ,Mohandai Oswal Hospital, Sherpur Byepass, G T Road, Ludhiana-141009, Punjab, 141009",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065b7"
    },
    "Rank": 30444,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nice Hospital For Women, Newborn and Children, ,10-2-247 and 248, Shanti Nagar, Masab Tank, Hyderabad, Telangana 500057, India, Telangana, 500057",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065b8"
    },
    "Rank": 30446,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Ramesh Cardiac and Multi Speciality Hospital,,Collector office road, Nagarampalem, Guntur , AP, Andhra Pradesh, 522004",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065b9"
    },
    "Rank": 30448,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College,Theni ,Government Medical College,Theni, Tamil Nadu, 625512",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065ba"
    },
    "Rank": 30450,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "JYOTHI HOSPITAL, Telangana\n,19-1350, JYOTHI HOSPITAL, NEAR ANJANEYA SWAMI TEMPLE, MIRYALAGUDA, NALGONDA DISTRICT, TELANGANA, Telangana, 508207",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065bb"
    },
    "Rank": 30467,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Coochbehar Govt Medical College and Hospital, West Bengal,Vivekananda Street, Pilkhana, Cooch Behar, WB-736101, West Bengal, 736101",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065bc"
    },
    "Rank": 30470,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065bd"
    },
    "Rank": 30471,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065be"
    },
    "Rank": 30478,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065bf"
    },
    "Rank": 30480,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065c0"
    },
    "Rank": 30489,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asarfi Hospital, Jharkhand,Baramuri, B Polytechnic, Dhanbad, Jharkhand, 828130",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065c1"
    },
    "Rank": 30490,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Capitol Hospital,,jalandhar pathankot road NH 44 Near Reru Chowk Jalandhar, Punjab, 144012",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065c2"
    },
    "Rank": 30506,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065c3"
    },
    "Rank": 30508,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065c4"
    },
    "Rank": 30513,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065c5"
    },
    "Rank": 30518,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065c6"
    },
    "Rank": 30525,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065c7"
    },
    "Rank": 30526,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065c8"
    },
    "Rank": 30551,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "N S Memorial Institute of Medical Sciences ,N S Memorial Institute of Medical Sciences Palathara, Thattamala, Kollam, Kerala, 691020",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065c9"
    },
    "Rank": 30552,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065ca"
    },
    "Rank": 30554,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Calcutta Medical Research Institute, ,The Calcutta Medical Research Institute 7 by 2 Diamond Harbour Road Kolkata 700027, West Bengal, 700027",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065cb"
    },
    "Rank": 30562,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065cc"
    },
    "Rank": 30564,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Al-Aafia Civil Hospital , Delhi Alwar Road, Haryana,Near Traffic Police Station Delhi Alwar Road VPO Mandikhera Dstt Nuh, Haryana, 122108",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065cd"
    },
    "Rank": 30570,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Ramesh Cardiac and Multi Speciality Hospital,,Collector office road, Nagarampalem, Guntur , AP, Andhra Pradesh, 522004",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065ce"
    },
    "Rank": 30588,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065cf"
    },
    "Rank": 30589,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065d0"
    },
    "Rank": 30593,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065d1"
    },
    "Rank": 30595,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065d2"
    },
    "Rank": 30604,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MNJ Institute of Oncology and Cancer ,MNJ Institute of Oncology and Regional Cancer Center, Red Hills, Hyderabad, Telangana, Telangana, 500004",
    "Course": "(NBEMS) Palliative Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065d3"
    },
    "Rank": 30614,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B L Kapur Memorial Hospital,Pusa Road New Delhi, Delhi (NCT), 110005",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065d4"
    },
    "Rank": 30617,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065d5"
    },
    "Rank": 30623,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065d6"
    },
    "Rank": 30624,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kauvery Hospital,9-50, Trichy Main Road, Seelanaickenpatti, Salem, Tamil Nadu, 636201",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065d7"
    },
    "Rank": 30625,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065d8"
    },
    "Rank": 30626,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Johns Medical College Hospital, Karnataka,Sarjapur Road, Koramangala, Bangalore, Karnataka, Karnataka, 560034",
    "Course": "(NBEMS) Palliative Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065d9"
    },
    "Rank": 30629,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (Hospital Administration)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065da"
    },
    "Rank": 30632,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065db"
    },
    "Rank": 30637,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065dc"
    },
    "Rank": 30638,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortune Hospital, Kanpur Uttar Pradesh ,\nUttar Pradesh,117-Q 40A-41 Sharda Nagar, Kanpur near Gurdev Crossing, Uttar Pradesh, 208025",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065dd"
    },
    "Rank": 30640,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065de"
    },
    "Rank": 30643,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065df"
    },
    "Rank": 30649,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065e0"
    },
    "Rank": 30652,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "C.S.I. Kalyani General Hospital,, ,15, DR RADHAKRISHNAN SALAI, MYLAPORE, CHENNAI, Tamil Nadu, 600004",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065e1"
    },
    "Rank": 30655,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deenanath Mangeshkar Hospital and Research Centre.,,8 13 2 Erandawane near Mhatre Bridge, Maharashtra, 411004",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065e2"
    },
    "Rank": 30657,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065e3"
    },
    "Rank": 30658,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KMC Hospital and Research Centre, ,KMC Hospital and Research Centre 187, Baghpat Road, Meerut, Uttar Pradesh, 250002",
    "Course": "(NBEMS) RADIATION ONCOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065e4"
    },
    "Rank": 30660,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065e5"
    },
    "Rank": 30667,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065e6"
    },
    "Rank": 30671,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065e7"
    },
    "Rank": 30673,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065e8"
    },
    "Rank": 30675,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indira Gandhi Govt. General Hospital and PG Institute, No. 1, ,No.1, Victor Simonel street, Puducherry -1., Puducherry, 605001",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065e9"
    },
    "Rank": 30677,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065ea"
    },
    "Rank": 30686,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital ,ALIPURDUAR DISTRICT HOSPITAL, ALIPURDUAR, HOSPITAL ROAD, WARD NO-XIII, ALIPURDUAR , PIN-736121, WEST BENGAL, 736121",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065eb"
    },
    "Rank": 30691,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine & Sagore Dutta Hospital,College of Medicine & Sagore Dutta Hospital, West Bengal, 700058",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065ec"
    },
    "Rank": 30701,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065ed"
    },
    "Rank": 30719,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065ee"
    },
    "Rank": 30721,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Amardeep Eye Care , Kollam, Kerala,12-694, 2nd MILESTONE, KOLLAM, Kerala, 691004",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065ef"
    },
    "Rank": 30741,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GURUKRIPA HOSPITALS ,JYOTI NAGAR PIPRALI ROAD SIKAR RAJASTHAN,\nRajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065f0"
    },
    "Rank": 30743,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Raj Eye Hospital,CHHATRA SANGH CHAURAHA, CANT ROAD, GORAKHPUR, Uttar\nPradesh, 273001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065f1"
    },
    "Rank": 30744,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Goverment District Hospital Bundi, ,IN FRONT OF COLLECTORATE BUNDI, Rajasthan, 323001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065f2"
    },
    "Rank": 30746,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Charak Hospital and Research Center, Uttar Pradesh,Hardoi Road, Dubagga, Lucknow, Uttar Pradesh, 226003",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065f3"
    },
    "Rank": 30753,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065f4"
    },
    "Rank": 30765,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065f5"
    },
    "Rank": 30767,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065f6"
    },
    "Rank": 30771,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. L H Hiranandani Hospital, ,HILL SIDE AVENUE, HIRANANDANI GARDENS, POWAI-400076, Maharashtra, 400076",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065f7"
    },
    "Rank": 30782,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government DB General Medical Hosptal,Churu, Nayabass, Churu",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065f8"
    },
    "Rank": 30798,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Hospital For Women and Children, Telangana,Saraswathi Nagar Colony, Mansoorabad Village, LB Nagar, Telangana, 500070",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065f9"
    },
    "Rank": 30801,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital , ,shahid Rajmal Meena Govt. Dis. Hospital Mandi Road Baran, Rajasthan, 325205",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065fa"
    },
    "Rank": 30803,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065fb"
    },
    "Rank": 30805,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065fc"
    },
    "Rank": 30808,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SANKARA NETRA CHIKITSALAYA, Andhra Pradesh,SANKARA NETRA CHIKITSALAYA, 59-14-1/2, NIRMALA CONVENT CIRCLE, BHARATHI SQUARE BUILDING, VIJAYAWADA, Andhra Pradesh, 520008",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065fd"
    },
    "Rank": 30816,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065fe"
    },
    "Rank": 30820,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.G. Hospital and PG Medical Institute ,,No:5, Arts College Road, Coimbatore - 641018, Tamil Nadu, 641018",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a065ff"
    },
    "Rank": 30828,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government General Hospital, ,GOVERNMENT GENERAL HOSPITAL, ALANPUR LINK ROAD SAWAI MADHOPUR 322001, Rajasthan, 322001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06600"
    },
    "Rank": 30829,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06601"
    },
    "Rank": 30839,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "DURGAPUR SUB DIVISIONAL HOSPITAL ,\n,DR.ZAKIR HUSSIAN AVENUE, BIDHANNAGAR, DURGAPUR, West Bengal, 713206",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06602"
    },
    "Rank": 30840,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06603"
    },
    "Rank": 30847,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06604"
    },
    "Rank": 30853,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06605"
    },
    "Rank": 30859,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06606"
    },
    "Rank": 30862,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06607"
    },
    "Rank": 30867,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06608"
    },
    "Rank": 30869,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital, ,Nellikuppam Main Road, Cuddalore, Tamil Nadu, 607001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06609"
    },
    "Rank": 30879,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0660a"
    },
    "Rank": 30883,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Associated Hospital Government Medical College, ,Kanth Bagh Baramulla, Jammu And Kashmir, 193103",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0660b"
    },
    "Rank": 30887,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (Hospital Administration)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0660c"
    },
    "Rank": 30888,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Post Graduate Institute of Child Health (PGICH), SEC-30, Noida,Post Graduate  Instituite of Child Health sec-30 Noida, Gautam Buddh Nagar, Uttar Pradesh, 201303",
    "Course": "(NBEMS) IMMUNO- HAEMATOLOGY AND BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0660d"
    },
    "Rank": 30891,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "R.K. Govt District Hospital, ,Near Housing Board, Bhilwara Py Pass Road, Dhoinda , Rajsamand, Rajasthan, 313324",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0660e"
    },
    "Rank": 30892,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0660f"
    },
    "Rank": 30897,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06610"
    },
    "Rank": 30903,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Cauvery Heart and Multispeciality Hospital, ,BANNUR ROAD, NEAR TERESIAN CIRCLE,MYSORE, Karnataka, 570029",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06611"
    },
    "Rank": 30913,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06612"
    },
    "Rank": 30917,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shaheed Bhai Mani Singh ,opposite Police Line, Mansa Road Bathinda, Distt. Bathinda.\n151001, Punjab, 151001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06613"
    },
    "Rank": 30929,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06614"
    },
    "Rank": 30930,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06615"
    },
    "Rank": 30937,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06616"
    },
    "Rank": 30957,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06617"
    },
    "Rank": 30959,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06618"
    },
    "Rank": 30961,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "FIMS HOSPITAL(FRANK INSTITUTE OF MEDICAL SCIENCES), Haryana,Sonipat-\nBahalgarh Road, Sonipat-NCR, Sonipat, Haryana, 131001",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06619"
    },
    "Rank": 30962,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0661a"
    },
    "Rank": 30967,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0661b"
    },
    "Rank": 30969,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0661c"
    },
    "Rank": 30974,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0661d"
    },
    "Rank": 30976,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0661e"
    },
    "Rank": 30980,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0661f"
    },
    "Rank": 30983,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN,HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN, Karnataka, 573201",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06620"
    },
    "Rank": 30987,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District General Hospital, ,O/o. Medical Superintendent, Government District General Hospital, Contonment, Vizianagaram, Andhra Pradesh,\n535003",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06621"
    },
    "Rank": 30990,
    "Allotted Quota": "All India",
    "Allotted Institute": "All  India Institute of  Hygiene and Public Health,110, C. R  Avenue, Kolkata, West Bengal, 700073",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06622"
    },
    "Rank": 31001,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute Of Medical Sciences, Kalaburagi,VEERESH NAGAR SEDAM ROAD KALABURAGI, Karnataka, 585105",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06623"
    },
    "Rank": 31011,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Imambara District Hospital,,AKHANBAZAR ROAD, CHINSURA, HOOGHLY, West Bengal, 712101",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06624"
    },
    "Rank": 31015,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06625"
    },
    "Rank": 31016,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sambhu Nath Pandit Hospital, West Bengal,11 , Lala Lajpat Rai Sarani , Kolkata, West Bengal, 700020",
    "Course": "(NBEMS) PHYSICAL\nMED. and REHABILITATION",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06626"
    },
    "Rank": 31023,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "DIPLOMA IN OTO- RHINO-LARYNGOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06627"
    },
    "Rank": 31027,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06628"
    },
    "Rank": 31032,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06629"
    },
    "Rank": 31035,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0662a"
    },
    "Rank": 31042,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Laxmi Narasimha Hospital, Telangana,H NO 2 2 316 BESIDE PUSHPANJALI FUNCTION HALL NAIM NAGAR HANAMKONDA, Telangana, 506001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0662b"
    },
    "Rank": 31043,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Satguru Partap Singh Hospital, ,Sherpur Chowk, GT Road, Ludhiana, Punjab-141003, Punjab, 141003",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0662c"
    },
    "Rank": 31047,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0662d"
    },
    "Rank": 31049,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0662e"
    },
    "Rank": 31050,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0662f"
    },
    "Rank": 31053,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Institute of Medical Sciences (SVIMS), A State University estd. by Govt. of A.P.,Sri Venkateswara Institute of Medical Sciences, SVIMS, Alipiri Road, Tirupati, Andhra Pradesh, 517507",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06630"
    },
    "Rank": 31058,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sanjay Gandhi Postgarduate Institute of Medical Sciences,Sanjay Gandhi Postgarduate Institute of Medical Sciences, Uttar Pradesh, 226014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06631"
    },
    "Rank": 31065,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06632"
    },
    "Rank": 31069,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06633"
    },
    "Rank": 31071,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06634"
    },
    "Rank": 31074,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta The Medicity, ,Medanta The Medicity Sector 38 Gurgoan, Haryana, 122001",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06635"
    },
    "Rank": 31077,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06636"
    },
    "Rank": 31088,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, ,1,2, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) Palliative Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06637"
    },
    "Rank": 31089,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06638"
    },
    "Rank": 31090,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06639"
    },
    "Rank": 31093,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0663a"
    },
    "Rank": 31095,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0663b"
    },
    "Rank": 31110,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0663c"
    },
    "Rank": 31123,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0663d"
    },
    "Rank": 31126,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0663e"
    },
    "Rank": 31129,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0663f"
    },
    "Rank": 31135,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr.Chandrakanth Malabar Superspeciality Eye Hospital, Kerala,Kannur Rd, West Nadakkave, Vandipetta, Bilathikkulam, Kozhikode, Kerala 673011, Kerala, 673011",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06640"
    },
    "Rank": 31141,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shaheed Hasan Khan Mewati Government Medical College , ,Shaheed Hasan Khan Mewati Government Medical College Nalhar Nuh, Haryana, 122107",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06641"
    },
    "Rank": 31163,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bombay Hospital and Institute of Medical Sciences, ,12, New Marine Lines, Mumbai, Maharashtra, 400020",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06642"
    },
    "Rank": 31176,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06643"
    },
    "Rank": 31182,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06644"
    },
    "Rank": 31184,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06645"
    },
    "Rank": 31190,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06646"
    },
    "Rank": 31193,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06647"
    },
    "Rank": 31195,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06648"
    },
    "Rank": 31204,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06649"
    },
    "Rank": 31210,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC MEDICAL COLLEGE, JOKA,ESI-PGIMSR & ESIC MEDICAL COLLEGE, JOKA, KOLKATA, WEST BENGAL, West Bengal, 700104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0664a"
    },
    "Rank": 31214,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "PRS Hospital,,PRS HOSPITAL PVT LTD KILLIPALAM THIRUVANATHAPURAM\nKERALA. <academics@prshospital.com>, Kerala, 695002",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0664b"
    },
    "Rank": 31226,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0664c"
    },
    "Rank": 31228,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0664d"
    },
    "Rank": 31234,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0664e"
    },
    "Rank": 31239,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0664f"
    },
    "Rank": 31240,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06650"
    },
    "Rank": 31241,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06651"
    },
    "Rank": 31243,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL CANCER CENTRE,REGIONAL CANCER CENTRE, Kerala, 695011",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06652"
    },
    "Rank": 31244,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06653"
    },
    "Rank": 31246,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06654"
    },
    "Rank": 31248,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ahalia Diabetes Hospital , Kerala,Ahalia Campus Kozhippara Post, Kerala, 678557",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06655"
    },
    "Rank": 31249,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Childrens Hospital, (A unit of Rainbow Childrens Medicare Pvt. Ltd.) (Formerly Rainbow Institute of Medical Sciences ,Opp. NTR University of Health Sciences, Currency Nagar. Vijayawada - 520008, Andhra Pradesh, 520008",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06656"
    },
    "Rank": 31258,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06657"
    },
    "Rank": 31259,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Family Hospital, ,St.Andrews Road, Bandra West , Mumbai - 50, Maharashtra, 400050",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06658"
    },
    "Rank": 31263,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06659"
    },
    "Rank": 31265,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maitri Hospital, Rajasthan,2-A, Commerce College Rd, Sector - A, Talwandi, Kota, Rajasthan, 324005",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0665a"
    },
    "Rank": 31275,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Bhausaheb Hire Government Medical College,Sri Bhausaheb Hire Government Medical College,Dhule, Maharashtra, 424311",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0665b"
    },
    "Rank": 31279,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster CMI Hospital, ,43/2 New Airport Road, NH-7, Kodigehalli, Sahakara Nagar, Hebbal, Bengaluru, Karnataka, 560092",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0665c"
    },
    "Rank": 31280,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0665d"
    },
    "Rank": 31283,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0665e"
    },
    "Rank": 31285,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0665f"
    },
    "Rank": 31287,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06660"
    },
    "Rank": 31288,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "FIMS HOSPITAL(FRANK INSTITUTE OF MEDICAL SCIENCES), Haryana,Sonipat-\nBahalgarh Road, Sonipat-NCR, Sonipat, Haryana, 131001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06661"
    },
    "Rank": 31290,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "N S Memorial Institute of Medical Sciences ,N S Memorial Institute of Medical Sciences Palathara, Thattamala, Kollam, Kerala, 691020",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06662"
    },
    "Rank": 31293,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06663"
    },
    "Rank": 31296,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06664"
    },
    "Rank": 31298,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06665"
    },
    "Rank": 31302,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06666"
    },
    "Rank": 31311,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Laxmi Narasimha Hospital, Telangana,H NO 2 2 316 BESIDE PUSHPANJALI FUNCTION HALL NAIM NAGAR HANAMKONDA, Telangana, 506001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06667"
    },
    "Rank": 31316,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06668"
    },
    "Rank": 31318,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital , ,GMERS MEDICAL COLLEGE , Hadiyol-Gadhoda Road, Near-Government Polytechnic, Hiimmatnagar, Gujarat, 383001",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06669"
    },
    "Rank": 31324,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0666a"
    },
    "Rank": 31329,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0666b"
    },
    "Rank": 31334,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0666c"
    },
    "Rank": 31336,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0666d"
    },
    "Rank": 31339,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0666e"
    },
    "Rank": 31344,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0666f"
    },
    "Rank": 31346,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06670"
    },
    "Rank": 31349,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06671"
    },
    "Rank": 31359,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06672"
    },
    "Rank": 31360,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Valluvanad Hospital Complex Limited,KANNIYAMPURAM POST OTTAPALAM\nPALAKKAD KERALA, Kerala, 679104",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06673"
    },
    "Rank": 31361,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06674"
    },
    "Rank": 31362,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagirathi Neotia Woman and Child Care Centre, West Bengal,BHAGIRATHI NEOTIA WOMAN AND CHILD CARE CENTRE, NEW TOWN PREMISES NO.-27-0327, STREET NO.-327, DG BLO, West Bengal, 700156",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06675"
    },
    "Rank": 31371,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06676"
    },
    "Rank": 31376,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gangasheel Advanced Medical Research Institute, Uttar Pradesh,C-17, Deen Dayal Puram, Bareilly - 243122, Uttar Pradesh, 243122",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06677"
    },
    "Rank": 31380,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06678"
    },
    "Rank": 31384,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06679"
    },
    "Rank": 31385,
    "Allotted Quota": "All India",
    "Allotted Institute": "BHOPAL MEMORIAL HOSPITAL AND RESEARCH CENTRE, BHOPAL,Raisen By pass\nRoad, Bhopal- 462038 (M.P.), Madhya Pradesh, 462038",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0667a"
    },
    "Rank": 31388,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KALNA SD and SS HOSPITAL, West Bengal,S.T.K.K Road, P.O and P.S - Kalna, Dist - Purba Bardhaman, West Bengal, 713409",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0667b"
    },
    "Rank": 31394,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0667c"
    },
    "Rank": 31396,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dholpur,Old City Road Dholpur, Rajasthan, 328001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0667d"
    },
    "Rank": 31397,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. GERIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0667e"
    },
    "Rank": 31398,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0667f"
    },
    "Rank": 31406,
    "Allotted Quota": "All India",
    "Allotted Institute": "REGIONAL CANCER CENTRE,REGIONAL CANCER CENTRE, Kerala, 695011",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06680"
    },
    "Rank": 31408,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Muthoot Health Care, ,Muthoot Healthcare, College Road, Kozhencherry, Pathanamthitta District, Kerala, 689641",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06681"
    },
    "Rank": 31414,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. L H Hiranandani Hospital, ,HILL SIDE AVENUE, HIRANANDANI GARDENS, POWAI-400076, Maharashtra, 400076",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06682"
    },
    "Rank": 31416,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06683"
    },
    "Rank": 31428,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mohandai Oswal Hospital, ,Mohandai Oswal Hospital, Sherpur Byepass, G T Road, Ludhiana-141009, Punjab, 141009",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06684"
    },
    "Rank": 31429,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06685"
    },
    "Rank": 31430,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Southern Railway Head Quarter Hospital, ,Ayanavaram, Tamil Nadu, 600023",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06686"
    },
    "Rank": 31443,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06687"
    },
    "Rank": 31448,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06688"
    },
    "Rank": 31449,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06689"
    },
    "Rank": 31450,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0668a"
    },
    "Rank": 31455,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kalpana Chawla Govt Medical College, ,Model Town, Karnal, Haryana, 132001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0668b"
    },
    "Rank": 31456,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0668c"
    },
    "Rank": 31457,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0668d"
    },
    "Rank": 31459,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0668e"
    },
    "Rank": 31466,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mydhili Hospital, ,ICS Road Gudur SPSR Nellore District, Andhra Pradesh, 524101",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0668f"
    },
    "Rank": 31467,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rainbow Childrens Hospital, (A unit of Rainbow Childrens Medicare Pvt. Ltd.) (Formerly Rainbow Institute of Medical Sciences ,Opp. NTR University of Health Sciences, Currency Nagar. Vijayawada - 520008, Andhra Pradesh, 520008",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06690"
    },
    "Rank": 31471,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06691"
    },
    "Rank": 31472,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06692"
    },
    "Rank": 31476,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06693"
    },
    "Rank": 31480,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06694"
    },
    "Rank": 31488,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06695"
    },
    "Rank": 31491,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Hospital, ,principal.mc.barmer@rajasthan.gov.in, Rajasthan, 344001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06696"
    },
    "Rank": 31492,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Popular Medicare,POPULAR MEDICARE LTD N 10 60 A 2 BLW ROAD KAKARAMATTA VARANASI, Uttar Pradesh, 221106",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06697"
    },
    "Rank": 31495,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06698"
    },
    "Rank": 31498,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06699"
    },
    "Rank": 31503,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0669a"
    },
    "Rank": 31507,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asarfi Hospital, Jharkhand,Baramuri, B Polytechnic, Dhanbad, Jharkhand, 828130",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0669b"
    },
    "Rank": 31508,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Star Women and Children Hospital ,\nTelangana,H No : 4-101 ,Saraswati nagar ,Teegalagutta pally Railway station road , karimnagar., Telangana, 505001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0669c"
    },
    "Rank": 31512,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajagiri Hospital,Rajagiri Hospital, Chunagamvely, Aluva 683112, Kerala, 683112",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0669d"
    },
    "Rank": 31518,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI MEDICAL COLLEGE KALWA THANE,RAJIV GANDHI MEDICAL COLLEGE,THANE BELAPUR ROAD, KALWA, THANE, Maharashtra, 400605",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0669e"
    },
    "Rank": 31519,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "FIMS HOSPITAL(FRANK INSTITUTE OF MEDICAL SCIENCES), Haryana,Sonipat-\nBahalgarh Road, Sonipat-NCR, Sonipat, Haryana, 131001",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0669f"
    },
    "Rank": 31523,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066a0"
    },
    "Rank": 31527,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital,\n,paddoc road, near majewadi gate, junagadh, Gujarat, 362001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066a1"
    },
    "Rank": 31529,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066a2"
    },
    "Rank": 31530,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066a3"
    },
    "Rank": 31531,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066a4"
    },
    "Rank": 31575,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Karnataka,Kodagu Institute of Medical Sciences Madikeri Kodagu, Karnataka, 571201",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066a5"
    },
    "Rank": 31576,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College,Tumuki, Bihaguri, Tezpur, Sonitpur, Assam, Assam, 784010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066a6"
    },
    "Rank": 31579,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066a7"
    },
    "Rank": 31581,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066a8"
    },
    "Rank": 31583,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "N S Memorial Institute of Medical Sciences ,N S Memorial Institute of Medical Sciences Palathara, Thattamala, Kollam, Kerala, 691020",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066a9"
    },
    "Rank": 31591,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaheed Hasan Khan Mewati Government Medical College,Shaheed Hasan Khan Mewati Government Medical College, Haryana, 122107",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066aa"
    },
    "Rank": 31597,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066ab"
    },
    "Rank": 31605,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agrasen Medical College,,NH- 9, VPO - Agroha, District Hisar, Haryana, 125047",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066ac"
    },
    "Rank": 31607,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066ad"
    },
    "Rank": 31610,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066ae"
    },
    "Rank": 31611,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066af"
    },
    "Rank": 31618,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066b0"
    },
    "Rank": 31624,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Imambara District Hospital,,AKHANBAZAR ROAD, CHINSURA, HOOGHLY, West Bengal, 712101",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066b1"
    },
    "Rank": 31634,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KALNA SD and SS HOSPITAL, West Bengal,S.T.K.K Road, P.O and P.S - Kalna, Dist - Purba Bardhaman, West Bengal, 713409",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066b2"
    },
    "Rank": 31637,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066b3"
    },
    "Rank": 31640,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066b4"
    },
    "Rank": 31641,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dhayal Hospital and Research Centre, Rajasthan\n,Dhayal Hospital and Research Centre, Nh52,Near Math Mandir,\nReengus,\nSikar,Rajasthan-332404, Rajasthan, 332404",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066b5"
    },
    "Rank": 31642,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066b6"
    },
    "Rank": 31643,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066b7"
    },
    "Rank": 31645,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066b8"
    },
    "Rank": 31652,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066b9"
    },
    "Rank": 31655,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066ba"
    },
    "Rank": 31659,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gangasheel Advanced Medical Research Institute, Uttar Pradesh,C-17, Deen Dayal Puram, Bareilly - 243122, Uttar Pradesh, 243122",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066bb"
    },
    "Rank": 31662,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gvernment Medical College,Haldwani,Government Medical College, Haldwani, Uttarakhand, 263139",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066bc"
    },
    "Rank": 31663,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066bd"
    },
    "Rank": 31667,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Government Medical College, Vidisha,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA MP, Madhya Pradesh, 464001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066be"
    },
    "Rank": 31668,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066bf"
    },
    "Rank": 31674,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066c0"
    },
    "Rank": 31683,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066c1"
    },
    "Rank": 31688,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066c2"
    },
    "Rank": 31691,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan , Rajasthan-311001,Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan, Rajasthan, 311001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066c3"
    },
    "Rank": 31697,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066c4"
    },
    "Rank": 31701,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066c5"
    },
    "Rank": 31702,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066c6"
    },
    "Rank": 31707,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066c7"
    },
    "Rank": 31713,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Divisional Railway Hospital,,Near Railway Gate Valdhuni Kalyan west dist Thane, Maharashtra, 421301",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066c8"
    },
    "Rank": 31719,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kushabhau Thakre District Hospital, Madhya Pradesh,Govindgarh Road Bichhiya Rewa, Madhya Pradesh, 486001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066c9"
    },
    "Rank": 31721,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Hurkisondas Nurrotumdas Hospital and Research Centre, ,Prarthana Samaj, Raja Ram Mohan Roy road,\nGirgaon, MUMBAI, Maharashtra, 400004",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066ca"
    },
    "Rank": 31723,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Siddh Multi Specialty Hospital,SIDDH MULTISPECIALTY HOSPITAL  HARTHALA INDUSTRIAL ESTATE KANTH ROAD MORADABAD, Uttar Pradesh, 244001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066cb"
    },
    "Rank": 31728,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "NIMS Medicity, Trivandrum,\nKerala,Aralumoodu, Neyyattinkara, Trivandrum 695123, Kerala, 695123",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066cc"
    },
    "Rank": 31732,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066cd"
    },
    "Rank": 31735,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066ce"
    },
    "Rank": 31737,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066cf"
    },
    "Rank": 31739,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066d0"
    },
    "Rank": 31745,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sanghamitra Hospitals Private Limited, Andhra Pradesh,D.No -5-82-1, Ambedkar Colony Extension, Near South Bypass junction, Beside Swathi Pipes.  Ongole, Andhra Pradesh, 523002",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066d1"
    },
    "Rank": 31749,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SDH KUPWARA, Jammu and Kashmir,IQBAL MARKET KUPWARA, Jammu And Kashmir, 193222",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066d2"
    },
    "Rank": 31759,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066d3"
    },
    "Rank": 31760,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066d4"
    },
    "Rank": 31777,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066d5"
    },
    "Rank": 31778,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rani Hospital, ,INFRONT OF GOVERNOR HOUSE BOOTY ROAD RANCHI, Jharkhand, 834001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066d6"
    },
    "Rank": 31779,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066d7"
    },
    "Rank": 31783,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066d8"
    },
    "Rank": 31785,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K C General Hospital, ,No. 89, 5th Cross, Malleshwaram, Bangalore, Karnataka, 560003",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066d9"
    },
    "Rank": 31788,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bombay Hospital and Institute of Medical Sciences, ,12, New Marine Lines, Mumbai, Maharashtra, 400020",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066da"
    },
    "Rank": 31789,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066db"
    },
    "Rank": 31795,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066dc"
    },
    "Rank": 31799,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMR Varalakshmi Care Hospital, Andhra Pradesh,GMR Varalakshmi CARE Hospital GMR Nagar ,\nRajam,\nVizianagaram District, Andhra Pradesh, 532127",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066dd"
    },
    "Rank": 31804,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066de"
    },
    "Rank": 31807,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066df"
    },
    "Rank": 31808,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pimpri Chichwad Municipal Corporation Postgraduate Institute Y.C.M.Hospital Pimpri,Pune 18,Dean Office, Y.C.M.Hospital, Sant Tukaramnagar Pimpri, Pune, Maharashtra, 411018",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066e0"
    },
    "Rank": 31816,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sarji Hospital, ,RMR RAOD PARK EXTENSION sHIVAMOGGA-577201, Karnataka, 577201",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066e1"
    },
    "Rank": 31822,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. GERIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066e2"
    },
    "Rank": 31836,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College & Attached Hospital Bharatpur,GOVERNMENT MEDICAL COLLEGE BHARATPUR VILLAGE RAMPURA NH 21, Rajasthan, 321001",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066e3"
    },
    "Rank": 31837,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ranaghat Sub Division Hospital, West Bengal,Jagpur Road, West Bengal, 741255",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066e4"
    },
    "Rank": 31843,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066e5"
    },
    "Rank": 31847,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066e6"
    },
    "Rank": 31855,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066e7"
    },
    "Rank": 31856,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066e8"
    },
    "Rank": 31858,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chaithanya Eye Hospital and Research Institute, Kerala,Manjadi, Thiruvalla, Kerala 689105, Kerala, 689105",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066e9"
    },
    "Rank": 31861,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066ea"
    },
    "Rank": 31862,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066eb"
    },
    "Rank": 31863,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066ec"
    },
    "Rank": 31864,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute Of Medical Sciences, Kalaburagi,VEERESH NAGAR SEDAM ROAD KALABURAGI, Karnataka, 585105",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066ed"
    },
    "Rank": 31871,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College, ,Office of the Dean, First Floor, Civil Hospital Campus, Sector- 12, Gandhinagar, Gujarat, 382016",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066ee"
    },
    "Rank": 31877,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066ef"
    },
    "Rank": 31878,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jalgaon,Jilha Peth, Government Hospital Campus, Jalgaon, Maharashtra, 425001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066f0"
    },
    "Rank": 31880,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMR Varalakshmi Care Hospital, Andhra Pradesh,GMR Varalakshmi CARE Hospital GMR Nagar ,\nRajam,\nVizianagaram District, Andhra Pradesh, 532127",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066f1"
    },
    "Rank": 31881,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI MEDICAL COLLEGE KALWA THANE,RAJIV GANDHI MEDICAL COLLEGE,THANE BELAPUR ROAD, KALWA, THANE, Maharashtra, 400605",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066f2"
    },
    "Rank": 31884,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066f3"
    },
    "Rank": 31886,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066f4"
    },
    "Rank": 31891,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066f5"
    },
    "Rank": 31892,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066f6"
    },
    "Rank": 31895,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "JYOTHI HOSPITAL, Telangana\n,19-1350, JYOTHI HOSPITAL, NEAR ANJANEYA SWAMI TEMPLE, MIRYALAGUDA, NALGONDA DISTRICT, TELANGANA, Telangana, 508207",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066f7"
    },
    "Rank": 31897,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066f8"
    },
    "Rank": 31907,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066f9"
    },
    "Rank": 31913,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lord Mahavir Civil Hospital,,Lord Mahavir Civil Hospital, MCH Wing Fieldganj Road Ludhiana, Punjab, 141008",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066fa"
    },
    "Rank": 31918,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066fb"
    },
    "Rank": 31928,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066fc"
    },
    "Rank": 31940,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066fd"
    },
    "Rank": 31943,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066fe"
    },
    "Rank": 31949,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a066ff"
    },
    "Rank": 31953,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06700"
    },
    "Rank": 31960,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06701"
    },
    "Rank": 31963,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06702"
    },
    "Rank": 31978,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06703"
    },
    "Rank": 31984,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06704"
    },
    "Rank": 31988,
    "Allotted Quota": "All India",
    "Allotted Institute": "Thiruvarur Govt.Medical College,Thiruvarur Govt.Medical College, Tamil Nadu, 610004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06705"
    },
    "Rank": 31994,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06706"
    },
    "Rank": 31996,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rabindranath Tagore International Institute of Cardiac Sciences, ,PREMISES NO. 1489 124 MUKUNDAPUR  E M BYPASS  KOLKATA-700099, West Bengal, 700099",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06707"
    },
    "Rank": 31997,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06708"
    },
    "Rank": 32005,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Popular Medicare,POPULAR MEDICARE LTD N 10 60 A 2 BLW ROAD KAKARAMATTA VARANASI, Uttar Pradesh, 221106",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06709"
    },
    "Rank": 32015,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0670a"
    },
    "Rank": 32016,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lala Harbhagwan Das Memorial and Dr Prem Hospital, ,BISHAN SWAROOP COLONY, OPP. BUS STAND, Haryana, 132103",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0670b"
    },
    "Rank": 32017,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BHAGIRATHI NEOTIA WOMAN AND CHILD CARE CENTRE, West Bengal,BHAGIRATHI NEOTIA WOMAN AND CHILD CARE CENTRE, RAWDON\nSTREET                                        , West Bengal, 700017",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0670c"
    },
    "Rank": 32022,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0670d"
    },
    "Rank": 32031,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0670e"
    },
    "Rank": 32048,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0670f"
    },
    "Rank": 32049,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06710"
    },
    "Rank": 32051,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GURUKRIPA HOSPITALS ,JYOTI NAGAR PIPRALI ROAD SIKAR RAJASTHAN,\nRajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06711"
    },
    "Rank": 32054,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "RAJ JINDAL HOSPITAL AND RESEARCH CENTRE PVT LTD, Rajasthan,S.P.M. NAGAR BEHIND REGIONAL OFFICE RSPCB BHARATPUR,\nRajasthan, 321001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06712"
    },
    "Rank": 32075,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06713"
    },
    "Rank": 32080,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06714"
    },
    "Rank": 32081,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06715"
    },
    "Rank": 32093,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06716"
    },
    "Rank": 32094,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06717"
    },
    "Rank": 32096,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital,,GOVERNMENT HEADQUARTERS HOSPITAL AND DNB POST GRADUATE TEACHING INSTITUTE,EVN ROAD, ERODE, Tamil Nadu, 638009",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06718"
    },
    "Rank": 32099,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06719"
    },
    "Rank": 32102,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Doon Medical College, Dehradun,Govt. Doon Medical College Dehrakhas, Patel Nagar Dehradun 248001, Uttarakhand, 248001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0671a"
    },
    "Rank": 32108,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0671b"
    },
    "Rank": 32109,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0671c"
    },
    "Rank": 32111,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0671d"
    },
    "Rank": 32112,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government General Hospital, ,GOVERNMENT GENERAL HOSPITAL, ALANPUR LINK ROAD SAWAI MADHOPUR 322001, Rajasthan, 322001",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0671e"
    },
    "Rank": 32127,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0671f"
    },
    "Rank": 32129,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06720"
    },
    "Rank": 32130,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06721"
    },
    "Rank": 32137,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06722"
    },
    "Rank": 32142,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06723"
    },
    "Rank": 32143,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06724"
    },
    "Rank": 32144,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06725"
    },
    "Rank": 32147,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06726"
    },
    "Rank": 32148,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06727"
    },
    "Rank": 32155,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06728"
    },
    "Rank": 32156,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06729"
    },
    "Rank": 32161,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "RAJ JINDAL HOSPITAL AND RESEARCH CENTRE PVT LTD, Rajasthan,S.P.M. NAGAR BEHIND REGIONAL OFFICE RSPCB BHARATPUR,\nRajasthan, 321001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0672a"
    },
    "Rank": 32167,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0672b"
    },
    "Rank": 32172,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0672c"
    },
    "Rank": 32178,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital Shikaripura,mohshikaripur, Karnataka, 577427",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0672d"
    },
    "Rank": 32179,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "IGM Hospital , ,IGM Chowmuhani, Agartala, Tripura West, Tripura, 799001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0672e"
    },
    "Rank": 32182,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Laxmi Narasimha Hospital, Telangana,H NO 2 2 316 BESIDE PUSHPANJALI FUNCTION HALL NAIM NAGAR HANAMKONDA, Telangana, 506001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0672f"
    },
    "Rank": 32183,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District General Hospital, ,Government District General Hospital, Noonepalle, Nandyal - 518502, Andhra Pradesh, 518502",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06730"
    },
    "Rank": 32184,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bangalore Baptist Hospital, ,Bangalore Baptist Hospital, Bellary Road, Hebbal, Bangalore - 560024, Karnataka, 560024",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06731"
    },
    "Rank": 32189,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Adarsha Hospital, ,Near KSRTC Bus Stand, Udupi Taluk and District, Karnataka, Karnataka, 576101",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06732"
    },
    "Rank": 32190,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, Dist.Belgaum, Karnataka,GENERAL HOSPITAL AND MCH FALLS ROAD GOKAK DIST BELGAUM KARNATAKA, Karnataka, 591307",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06733"
    },
    "Rank": 32191,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Max Super Specialty Hospital, ,1,2, Press Enclave Road, Saket, New Delhi, Delhi (NCT), 110017",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06734"
    },
    "Rank": 32192,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06735"
    },
    "Rank": 32193,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Hospital, ,principal.mc.barmer@rajasthan.gov.in, Rajasthan, 344001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06736"
    },
    "Rank": 32195,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06737"
    },
    "Rank": 32201,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06738"
    },
    "Rank": 32205,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06739"
    },
    "Rank": 32207,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0673a"
    },
    "Rank": 32208,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rani Hospital, ,INFRONT OF GOVERNOR HOUSE BOOTY ROAD RANCHI, Jharkhand, 834001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0673b"
    },
    "Rank": 32210,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0673c"
    },
    "Rank": 32217,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0673d"
    },
    "Rank": 32226,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, THOOTHUKUDI,GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, 3rd\nMile,Kamaraj Nagar,Thoothukudi., Tamil Nadu, 628008",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0673e"
    },
    "Rank": 32229,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0673f"
    },
    "Rank": 32231,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06740"
    },
    "Rank": 32235,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06741"
    },
    "Rank": 32236,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06742"
    },
    "Rank": 32253,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06743"
    },
    "Rank": 32254,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06744"
    },
    "Rank": 32262,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Popular Medicare,POPULAR MEDICARE LTD N 10 60 A 2 BLW ROAD KAKARAMATTA VARANASI, Uttar Pradesh, 221106",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06745"
    },
    "Rank": 32267,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PALLIATIVE MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06746"
    },
    "Rank": 32269,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06747"
    },
    "Rank": 32271,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06748"
    },
    "Rank": 32273,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06749"
    },
    "Rank": 32274,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0674a"
    },
    "Rank": 32275,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0674b"
    },
    "Rank": 32284,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0674c"
    },
    "Rank": 32306,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0674d"
    },
    "Rank": 32308,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0674e"
    },
    "Rank": 32312,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Bangur Hospital, Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan , Rajasthan-306401,Government Medical College and Bangur Hospital Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan Ra, Rajasthan, 306401",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0674f"
    },
    "Rank": 32313,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital , ,Dist. Hospital Pratapgarh, Banswara Road, Pratapgarh, Rajasthan, 312605",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06750"
    },
    "Rank": 32322,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VILLUPURAM MEDICAL COLLEGE, VILLUPURAM,GOVERNMENT VILLUPURAM MEDICAL COLLEGE VILLUPURAM  TRICHY TRUNK ROAD MUNDIYAMPAKKAM  VILLUPURAM DISTR, Tamil Nadu, 605601",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06751"
    },
    "Rank": 32324,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06752"
    },
    "Rank": 32325,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Walsh S.D.H., West Bengal,22A, T.C Goswami Street, Serampore, Hooghly, West Bengal, 712201",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06753"
    },
    "Rank": 32339,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Regency Hospital,,A-2, Sarvodaya Nagar, Kanpur, Uttar Pradesh, 208005",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06754"
    },
    "Rank": 32342,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06755"
    },
    "Rank": 32343,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06756"
    },
    "Rank": 32351,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr.Chandrakanth Malabar Superspeciality Eye Hospital, Kerala,Kannur Rd, West Nadakkave, Vandipetta, Bilathikkulam, Kozhikode, Kerala 673011, Kerala, 673011",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06757"
    },
    "Rank": 32353,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06758"
    },
    "Rank": 32358,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06759"
    },
    "Rank": 32360,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0675a"
    },
    "Rank": 32363,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "PRS Hospital,,PRS HOSPITAL PVT LTD KILLIPALAM THIRUVANATHAPURAM\nKERALA. <academics@prshospital.com>, Kerala, 695002",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0675b"
    },
    "Rank": 32375,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0675c"
    },
    "Rank": 32380,
    "Allotted Quota": "All India",
    "Allotted Institute": "All  India Institute of  Hygiene and Public Health,110, C. R  Avenue, Kolkata, West Bengal, 700073",
    "Course": "M.P.H. (EPIDEMIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0675d"
    },
    "Rank": 32383,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0675e"
    },
    "Rank": 32387,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Laxmi Narasimha Hospital, Telangana,H NO 2 2 316 BESIDE PUSHPANJALI FUNCTION HALL NAIM NAGAR HANAMKONDA, Telangana, 506001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0675f"
    },
    "Rank": 32388,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital\nNext to Sainik School second gate Athani Road, Vijayapura Karnataka 586103, Karnataka, 586103",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06760"
    },
    "Rank": 32396,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06761"
    },
    "Rank": 32397,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06762"
    },
    "Rank": 32398,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Babasaheb Ambedkar Central Railway Hospital, ,Bharatratna Dr. Babasaheb Ambedkar Memorial Hospital, Central Railway, Dr. Babasaheb Ambedkar Road,, Maharashtra, 400027",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06763"
    },
    "Rank": 32407,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Sathya Sai Sarla Memorial Hospital, Karnataka,Sathya Sai Grama Muddenahalli\nChikkaballapura, Karnataka, 562101",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06764"
    },
    "Rank": 32417,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06765"
    },
    "Rank": 32422,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06766"
    },
    "Rank": 32424,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bombay Hospital and Institute of Medical Sciences, ,12, New Marine Lines, Mumbai, Maharashtra, 400020",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06767"
    },
    "Rank": 32426,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06768"
    },
    "Rank": 32446,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital , Chhattisgarh,District Hospital, Near collector office, Lalpur Road, Kawardha, Distt Kabirdham CG 491995, Chhattisgarh, 491995",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06769"
    },
    "Rank": 32460,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0676a"
    },
    "Rank": 32463,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0676b"
    },
    "Rank": 32465,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES,CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, Chhattisgarh, 495001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0676c"
    },
    "Rank": 32466,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,SECTOR-6, PANCHKULA, HARYANA-134108, Haryana, 134108",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0676d"
    },
    "Rank": 32471,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0676e"
    },
    "Rank": 32473,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vadamalayan Hospital,,Registered Office - 15, Jawahar Road, Chokkikulam, Madurai - 625002.\n9A, Vallabhai Road, Chokkikula, Tamil Nadu, 625002",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0676f"
    },
    "Rank": 32477,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06770"
    },
    "Rank": 32479,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06771"
    },
    "Rank": 32490,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sub Division Hospital, ,Opp Town Police Station Gangavathi Dist Koppal, Karnataka, 583227",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06772"
    },
    "Rank": 32492,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06773"
    },
    "Rank": 32504,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06774"
    },
    "Rank": 32511,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06775"
    },
    "Rank": 32526,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06776"
    },
    "Rank": 32532,
    "Allotted Quota": "All India",
    "Allotted Institute": "All  India Institute of  Hygiene and Public Health,110, C. R  Avenue, Kolkata, West Bengal, 700073",
    "Course": "M.P.H. (EPIDEMIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06777"
    },
    "Rank": 32533,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06778"
    },
    "Rank": 32534,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06779"
    },
    "Rank": 32537,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0677a"
    },
    "Rank": 32549,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0677b"
    },
    "Rank": 32556,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hospital for Mental Health, ,Near Jeevan Bharti School,Karelibaug,Vadodara, Gujarat, 390018",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0677c"
    },
    "Rank": 32561,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0677d"
    },
    "Rank": 32566,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0677e"
    },
    "Rank": 32567,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0677f"
    },
    "Rank": 32571,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06780"
    },
    "Rank": 32583,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vadamalayan Hospital,,Registered Office - 15, Jawahar Road, Chokkikulam, Madurai - 625002.\n9A, Vallabhai Road, Chokkikula, Tamil Nadu, 625002",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06781"
    },
    "Rank": 32584,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital ,  DARJEELING, West Bengal,20, BAZAAR CART ROAD, DARJEELING, West Bengal, 734101",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06782"
    },
    "Rank": 32585,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BHAGIRATHI NEOTIA WOMAN AND CHILD CARE CENTRE, West Bengal,BHAGIRATHI NEOTIA WOMAN AND CHILD CARE CENTRE, RAWDON\nSTREET                                        , West Bengal, 700017",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06783"
    },
    "Rank": 32586,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindustan Aeronautics Hospital, ,SURANJANDAS ROAD ,VIMANAPURA POST BANGALORE -560017, Karnataka, 560017",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06784"
    },
    "Rank": 32588,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. District Civil Hospital, ,DIS GOVT HOSPITAL, SRI GANGANAGAR, Rajasthan, 335001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06785"
    },
    "Rank": 32594,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06786"
    },
    "Rank": 32597,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06787"
    },
    "Rank": 32601,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06788"
    },
    "Rank": 32605,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06789"
    },
    "Rank": 32612,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District General Hospital, ,Government District General Hospital, Noonepalle, Nandyal - 518502, Andhra Pradesh, 518502",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0678a"
    },
    "Rank": 32616,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sanghamitra Hospitals Private Limited, Andhra Pradesh,D.No -5-82-1, Ambedkar Colony Extension, Near South Bypass junction, Beside Swathi Pipes.  Ongole, Andhra Pradesh, 523002",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0678b"
    },
    "Rank": 32625,
    "Allotted Quota": "All India",
    "Allotted Institute": "Koppal Institute of Medical Sciences Koppal, Karnataka,Gangavati Road Kiddidal Gate koppal, Karnataka, 583231",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0678c"
    },
    "Rank": 32627,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maitri Hospital, Rajasthan,2-A, Commerce College Rd, Sector - A, Talwandi, Kota, Rajasthan, 324005",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0678d"
    },
    "Rank": 32629,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government DB General Medical Hosptal,Churu, Nayabass, Churu",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0678e"
    },
    "Rank": 32630,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0678f"
    },
    "Rank": 32635,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College,Tumuki, Bihaguri, Tezpur, Sonitpur, Assam, Assam, 784010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06790"
    },
    "Rank": 32643,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06791"
    },
    "Rank": 32646,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Taluka General Hospital , ,Chief Medical Officer Taluka General Hospital Athani, Tq Athani, Dist Belagavi\nKarnataka\n591304, Karnataka, 591304",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06792"
    },
    "Rank": 32650,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06793"
    },
    "Rank": 32653,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06794"
    },
    "Rank": 32656,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06795"
    },
    "Rank": 32662,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Doon Medical College, Dehradun,Govt. Doon Medical College Dehrakhas, Patel Nagar Dehradun 248001, Uttarakhand, 248001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06796"
    },
    "Rank": 32667,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06797"
    },
    "Rank": 32671,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06798"
    },
    "Rank": 32672,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06799"
    },
    "Rank": 32673,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Pali Rajasthan.,NH-62,Sumerpur Road,Village Ramasiya,Hemawas,Pali, Rajasthan, Rajasthan, 306401",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0679a"
    },
    "Rank": 32679,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0679b"
    },
    "Rank": 32685,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indira Gandhi Govt. General Hospital and PG Institute, No. 1, ,No.1, Victor Simonel street, Puducherry -1., Puducherry, 605001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0679c"
    },
    "Rank": 32689,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jangipur S. D. Hospital, West Bengal,Lalgola Maharaja Road PO and PS Raghunathganj\nMurshidabad, West Bengal, 742225",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0679d"
    },
    "Rank": 32690,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0679e"
    },
    "Rank": 32697,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0679f"
    },
    "Rank": 32703,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indo-American Cancer Institute and Research Centre, ,Road No.14, Banjara Hills, Hyderabad, Telangana, 500034",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067a0"
    },
    "Rank": 32705,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. GERIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067a1"
    },
    "Rank": 32710,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Doon Medical College, Dehradun,Govt. Doon Medical College Dehrakhas, Patel Nagar Dehradun 248001, Uttarakhand, 248001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067a2"
    },
    "Rank": 32718,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government DB General Medical Hosptal,Churu, Nayabass, Churu",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067a3"
    },
    "Rank": 32721,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kharagpur Sub-Divisional Hospital , ,At-Chotatengra, P.O.- Kharagpur, P.S.- Kharagpur Town, Pin- 721301, West Bengal, 721301",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067a4"
    },
    "Rank": 32728,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067a5"
    },
    "Rank": 32733,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government General Hospital, ,GOVERNMENT GENERAL HOSPITAL, ALANPUR LINK ROAD SAWAI MADHOPUR 322001, Rajasthan, 322001",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067a6"
    },
    "Rank": 32742,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067a7"
    },
    "Rank": 32746,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067a8"
    },
    "Rank": 32753,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. District Saadat Hospital,\n,Near Patel Circle Tonk, Rajasthan, 304001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067a9"
    },
    "Rank": 32757,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067aa"
    },
    "Rank": 32784,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital ,  DARJEELING, West Bengal,20, BAZAAR CART ROAD, DARJEELING, West Bengal, 734101",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067ab"
    },
    "Rank": 32788,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagwan Mahavir Cancer Hospital and Research Centre, ,Managed by K G Kothari Memorial Trust\nJawahar Lal Nehru Marg, Jaipur 302 017, Rajasthan, 302017",
    "Course": "(NBEMS) Palliative Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067ac"
    },
    "Rank": 32790,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "HealthCare Global Specialty Hospital, (Formerly Bangalore Institute of Oncology) ,HCG Towers, No. 8, P Kalinga Rao Road, Sampangi Ram Nagar, Bangalore, Karnataka, 560027",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067ad"
    },
    "Rank": 32791,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College,Fakhruddin Ali Ahmed Medical College, Assam, 781301",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067ae"
    },
    "Rank": 32803,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067af"
    },
    "Rank": 32806,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lord Mahavir Civil Hospital,,Lord Mahavir Civil Hospital, MCH Wing Fieldganj Road Ludhiana, Punjab, 141008",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067b0"
    },
    "Rank": 32807,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067b1"
    },
    "Rank": 32819,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067b2"
    },
    "Rank": 32828,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067b3"
    },
    "Rank": 32829,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Multispeciality Hospital , West Bengal,Andul road, Podrah Chunavati Howrah, West Bengal, 711109",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067b4"
    },
    "Rank": 32833,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067b5"
    },
    "Rank": 32838,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067b6"
    },
    "Rank": 32839,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067b7"
    },
    "Rank": 32840,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chandannagar Sub Division Hospital, ,CHANDANNAGAR SD HOSPITAL, West Bengal, 712136",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067b8"
    },
    "Rank": 32842,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt Amrit Kaur Hospital, ,PRINCIPAL MEDICAL OFFICER GOVT. AMRIT KAUR HOSPITAL BEAWAR, Rajasthan, 305901",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067b9"
    },
    "Rank": 32843,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067ba"
    },
    "Rank": 32845,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067bb"
    },
    "Rank": 32846,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067bc"
    },
    "Rank": 32853,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067bd"
    },
    "Rank": 32870,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067be"
    },
    "Rank": 32873,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067bf"
    },
    "Rank": 32874,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM Health Care Private Limited, Tamil Nadu,New No 72, Old No 54, Nelson Manickam Road, Aminjikarai, Chennai – 600029 Tamilnadu State, Tamil Nadu, 600029",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067c0"
    },
    "Rank": 32882,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KMC Specialty Hospital, ,No-6, Royal Road, Cantonment, Trichy-620001, Tamil Nadu, 620001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067c1"
    },
    "Rank": 32890,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067c2"
    },
    "Rank": 32899,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Multispeciality Hospital , West Bengal,Andul road, Podrah Chunavati Howrah, West Bengal, 711109",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067c3"
    },
    "Rank": 32903,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067c4"
    },
    "Rank": 32904,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM Health Care Private Limited, Tamil Nadu,New No 72, Old No 54, Nelson Manickam Road, Aminjikarai, Chennai – 600029 Tamilnadu State, Tamil Nadu, 600029",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067c5"
    },
    "Rank": 32911,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Goverment District Hospital Bundi, ,IN FRONT OF COLLECTORATE BUNDI, Rajasthan, 323001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067c6"
    },
    "Rank": 32912,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "DIPLOMA IN CLINICAL PATHOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067c7"
    },
    "Rank": 32913,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067c8"
    },
    "Rank": 32915,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Abhi Eye Hospital, Tamil Nadu\n,ABHI EYE HOSPITAL,JOTHIMILL STREET,\nJOTHI NAGAR,ATTUR-T.K,SALEM-D.T, Tamil Nadu, 636102",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067c9"
    },
    "Rank": 32927,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067ca"
    },
    "Rank": 32931,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067cb"
    },
    "Rank": 32934,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,GOVT. HOSPITAL, PLACE ROAD ,SIROHI, Rajasthan, 307001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067cc"
    },
    "Rank": 32936,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067cd"
    },
    "Rank": 32938,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067ce"
    },
    "Rank": 32940,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067cf"
    },
    "Rank": 32946,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067d0"
    },
    "Rank": 32949,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067d1"
    },
    "Rank": 32954,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067d2"
    },
    "Rank": 32961,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067d3"
    },
    "Rank": 32962,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067d4"
    },
    "Rank": 32963,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rani Hospital, ,INFRONT OF GOVERNOR HOUSE BOOTY ROAD RANCHI, Jharkhand, 834001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067d5"
    },
    "Rank": 32967,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067d6"
    },
    "Rank": 32969,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067d7"
    },
    "Rank": 32977,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhabha Atomic Research Centre and Hospital, ,Anushaktinagar, Mumbai, Maharashtra, 400094",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067d8"
    },
    "Rank": 32978,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067d9"
    },
    "Rank": 32983,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067da"
    },
    "Rank": 32992,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067db"
    },
    "Rank": 32993,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067dc"
    },
    "Rank": 32999,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067dd"
    },
    "Rank": 33000,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067de"
    },
    "Rank": 33004,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067df"
    },
    "Rank": 33005,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067e0"
    },
    "Rank": 33014,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,OLD SABJI MANDI HINDUAN GATE KARAULI, Rajasthan, 322241",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067e1"
    },
    "Rank": 33016,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ahalia Diabetes Hospital , Kerala,Ahalia Campus Kozhippara Post, Kerala, 678557",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067e2"
    },
    "Rank": 33020,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "IGM Hospital , ,IGM Chowmuhani, Agartala, Tripura West, Tripura, 799001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067e3"
    },
    "Rank": 33026,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "JLN Government Hospital,,NH 89 BIKANER ROAD, NAGAUR, Rajasthan, 341001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067e4"
    },
    "Rank": 33030,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gabberia State General Hospital,\n,gabberiasgh@gmail.com, West Bengal, 711322",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067e5"
    },
    "Rank": 33034,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067e6"
    },
    "Rank": 33037,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067e7"
    },
    "Rank": 33043,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Choithram Netralaya,Choithram Netralaya Plot No 54-56 Shree Ram Talawali Dhar road, Indore PIN 453001, Madhya Pradesh, Madhya Pradesh, 453001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067e8"
    },
    "Rank": 33049,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. BDK hospital, govt. ,OPPOSITE ROADWAYS BUS STAND MAAN NAGAR JHUNJHUNU RAJASTHAN, Rajasthan, 333001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067e9"
    },
    "Rank": 33050,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhavsinhji and Maharani Rupaliba General Hospital, ,Near S. T. Bus Stand, Opp.\nBhutnath Temple, Hospital Road, Porbandar, Gujarat, 360575",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067ea"
    },
    "Rank": 33056,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067eb"
    },
    "Rank": 33057,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067ec"
    },
    "Rank": 33061,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067ed"
    },
    "Rank": 33069,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067ee"
    },
    "Rank": 33074,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Regency Hospital,,A-2, Sarvodaya Nagar, Kanpur, Uttar Pradesh, 208005",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067ef"
    },
    "Rank": 33075,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067f0"
    },
    "Rank": 33076,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067f1"
    },
    "Rank": 33081,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K C General Hospital, ,No. 89, 5th Cross, Malleshwaram, Bangalore, Karnataka, 560003",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067f2"
    },
    "Rank": 33093,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,10 Biplabi Haren Ghosh Sarani Howrah, West Bengal, 711101",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067f3"
    },
    "Rank": 33097,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Taluka General Hospital, Karnataka,Taluka General Hospital Ramdurga, Belgaum Road, Ramdurga, Karnataka, 591123",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067f4"
    },
    "Rank": 33099,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067f5"
    },
    "Rank": 33102,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067f6"
    },
    "Rank": 33103,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067f7"
    },
    "Rank": 33106,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Thiruvannamalai Medical College, Thiruvannamalai, Tamil Nadu,DISTRICT COLLECTORATE  MASTER PLAN COMPLEX, VENGIKKAL THIRUVANNAMALAI,\nTamil Nadu, 600604",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067f8"
    },
    "Rank": 33107,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "DIPLOMA IN CHILD HEALTH/ PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067f9"
    },
    "Rank": 33110,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067fa"
    },
    "Rank": 33116,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067fb"
    },
    "Rank": 33118,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Bangur Hospital, Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan , Rajasthan-306401,Government Medical College and Bangur Hospital Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan Ra, Rajasthan, 306401",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067fc"
    },
    "Rank": 33122,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067fd"
    },
    "Rank": 33125,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067fe"
    },
    "Rank": 33136,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a067ff"
    },
    "Rank": 33143,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, Gujarat,Salaya Char Rasta, Near Reliance Circle,\nHarsadPur,Ta-Jamkhambhaliya,\nDist-DevbhumiDwarka-361305, Gujarat, 361305",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06800"
    },
    "Rank": 33161,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06801"
    },
    "Rank": 33170,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Kamakshi Memorial Hospital, (Dr. Kamakshi Institute of Medical Sciences and Research) ,No.1,Radial Road , Pallikaranai, Chennai, Tamil Nadu, 600100",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06802"
    },
    "Rank": 33179,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Durgapur Steel Plant Hospital, ,J M Sengupta Road, B Zone,  Durgapur, Dist- Paschim Bardhaman, West Bengal, 713205",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06803"
    },
    "Rank": 33186,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06804"
    },
    "Rank": 33190,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06805"
    },
    "Rank": 33195,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ahalia Diabetes Hospital , Kerala,Ahalia Campus Kozhippara Post, Kerala, 678557",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06806"
    },
    "Rank": 33205,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,APOLLO HOSPITALS BANGALORE A UNIT OF IMPERIAL HOSPITALS AND RESEARCH CENTRE LIMITED 154/11 Opp IIM B, Karnataka, 560076",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06807"
    },
    "Rank": 33210,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06808"
    },
    "Rank": 33211,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06809"
    },
    "Rank": 33213,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0680a"
    },
    "Rank": 33215,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BALURGHAT DH and SSH, West Bengal,PO BT PARK, PS BALURGHAT, DIST DAKSHIN DINAJUPUR, West Bengal, 733103",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0680b"
    },
    "Rank": 33223,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0680c"
    },
    "Rank": 33234,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0680d"
    },
    "Rank": 33237,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0680e"
    },
    "Rank": 33240,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0680f"
    },
    "Rank": 33247,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06810"
    },
    "Rank": 33253,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06811"
    },
    "Rank": 33258,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06812"
    },
    "Rank": 33259,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06813"
    },
    "Rank": 33278,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES,CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, Chhattisgarh, 495001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06814"
    },
    "Rank": 33281,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06815"
    },
    "Rank": 33284,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "JLN Government Hospital,,NH 89 BIKANER ROAD, NAGAUR, Rajasthan, 341001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06816"
    },
    "Rank": 33285,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, Karnataka\n,General Hospital Chintamani M G Road.\nCHINTAMANI\nChikkaballapur district, Karnataka, 563125",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06817"
    },
    "Rank": 33288,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06818"
    },
    "Rank": 33289,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06819"
    },
    "Rank": 33295,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0681a"
    },
    "Rank": 33297,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0681b"
    },
    "Rank": 33298,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0681c"
    },
    "Rank": 33304,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Family Hospital,,Okhla Road, New Delhi, Delhi (NCT), 110025",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0681d"
    },
    "Rank": 33307,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0681e"
    },
    "Rank": 33308,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0681f"
    },
    "Rank": 33311,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06820"
    },
    "Rank": 33316,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "NEOTIA GETWEL HEALTHCARE CENTRE,\n,Uttorayon, Behind City Centre, Matigara, Siliguri, West Bengal, 734010",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06821"
    },
    "Rank": 33319,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06822"
    },
    "Rank": 33321,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06823"
    },
    "Rank": 33322,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06824"
    },
    "Rank": 33324,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06825"
    },
    "Rank": 33330,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Choithram Netralaya,Choithram Netralaya Plot No 54-56 Shree Ram Talawali Dhar road, Indore PIN 453001, Madhya Pradesh, Madhya Pradesh, 453001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06826"
    },
    "Rank": 33333,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06827"
    },
    "Rank": 33336,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06828"
    },
    "Rank": 33337,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06829"
    },
    "Rank": 33340,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0682a"
    },
    "Rank": 33342,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Down Town Hospital, G S Road, ,G S Road, Dispur, Guwahati-781006, Assam, 781006",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0682b"
    },
    "Rank": 33344,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0682c"
    },
    "Rank": 33345,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0682d"
    },
    "Rank": 33346,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0682e"
    },
    "Rank": 33360,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0682f"
    },
    "Rank": 33362,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06830"
    },
    "Rank": 33372,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06831"
    },
    "Rank": 33376,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College and  PGIMSR,ESIC Medical College and  PGIMSR, KK Nagar, Chennai - 600 078, Tamil Nadu, 600078",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06832"
    },
    "Rank": 33377,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Marwari Hospitals, Assam,Sati Jaymati Road, Athgoan, Guwahati, Assam, 781008",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06833"
    },
    "Rank": 33378,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06834"
    },
    "Rank": 33380,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tomo Riba Institute of Health and Medical Sciences,,TRIHMS. Old Assembly Block. Near Rajiv Gandhi Stadium. Naharlagun-791110. Arunachal Pradesh, Arunachal Pradesh, 791110",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06835"
    },
    "Rank": 33393,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Woodland Hospital, Meghalaya,Dhankheti P.O. Laitumkhrah East Khasi Hills District Shillong, Meghalaya, 793003",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06836"
    },
    "Rank": 33399,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mohandai Oswal Hospital, ,Mohandai Oswal Hospital, Sherpur Byepass, G T Road, Ludhiana-141009, Punjab, 141009",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06837"
    },
    "Rank": 33404,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "South Central Railways Hospital, ,SOUTH CENTRAL RAILWAYS CENTRAL HOSPITAL, OFFICE OF THE MEDICAL DIRECTOR, 1ST FLOOR\nLALLAGUDA, METT, Telangana, 500017",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06838"
    },
    "Rank": 33405,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06839"
    },
    "Rank": 33406,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0683a"
    },
    "Rank": 33408,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0683b"
    },
    "Rank": 33409,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0683c"
    },
    "Rank": 33410,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0683d"
    },
    "Rank": 33411,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government General Hospital, Principal Medical Officer General hospital,\n,Rajiv Gandhi Government General Hospital, near bijli ghar circle, ALWAR, Rajasthan, 301001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0683e"
    },
    "Rank": 33412,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0683f"
    },
    "Rank": 33416,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Civil Hospital, Gurugram ,\nHaryana,Civil Hospital Sector-10, Gurugram, Haryana, Haryana, 122001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06840"
    },
    "Rank": 33418,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "JLN Government Hospital,,NH 89 BIKANER ROAD, NAGAUR, Rajasthan, 341001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06841"
    },
    "Rank": 33423,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital, ,Nellikuppam Main Road, Cuddalore, Tamil Nadu, 607001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06842"
    },
    "Rank": 33428,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06843"
    },
    "Rank": 33430,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government Women and Children Hospital, (Formerly Indira Gandhi.\nGeneral Hospital) ,100 feet road, Ellaipillaichavady, Puducherry, 605005",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06844"
    },
    "Rank": 33432,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06845"
    },
    "Rank": 33439,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06846"
    },
    "Rank": 33440,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Delhi Hospital And Nursing Home, Haryana,METRO PILLAR NO. 812 NEAR SHYAMJI COMPLEX BAHADURGARH DIST JHAJJAR-124507, Haryana, 124507",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06847"
    },
    "Rank": 33446,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Vasantrao Naik Govt Medical College Yavatmal,Shri Vasantrao Naik Govt Medical College Yavatmal, Maharashtra, 445001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06848"
    },
    "Rank": 33447,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06849"
    },
    "Rank": 33451,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0684a"
    },
    "Rank": 33458,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ispat General Hospital,,Ispat General Hospital Rourkela Steel Plant SAIL Rourkela 769005, Odisha, 769005",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0684b"
    },
    "Rank": 33463,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0684c"
    },
    "Rank": 33464,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0684d"
    },
    "Rank": 33473,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0684e"
    },
    "Rank": 33477,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Multispeciality Hospital , West Bengal,Andul road, Podrah Chunavati Howrah, West Bengal, 711109",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0684f"
    },
    "Rank": 33481,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tomo Riba Institute of Health and Medical Sciences,,TRIHMS. Old Assembly Block. Near Rajiv Gandhi Stadium. Naharlagun-791110. Arunachal Pradesh, Arunachal Pradesh, 791110",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06850"
    },
    "Rank": 33483,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06851"
    },
    "Rank": 33488,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06852"
    },
    "Rank": 33489,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dharamshila Narayana Superspeciality Hospital, ,Dharamshila Marg, Vasundhara Enclave, Dharamshila Narayana Superspeciality Hospital, Delhi 110096, Delhi (NCT), 110096",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06853"
    },
    "Rank": 33490,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saifee Hospital, ,postbox no 3771, 15-17 Maharshi Karve Marg, Charni road, Mumbai, Maharashtra, 400004",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06854"
    },
    "Rank": 33508,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06855"
    },
    "Rank": 33515,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Islampur SDH/ ISSH, Islampur, West Bengal,Islampur Super Specialty Hospital, Sukantapally, Islampur, Uttar Dinajpur, West Bengal, 733202",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06856"
    },
    "Rank": 33516,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (Hospital Administration)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06857"
    },
    "Rank": 33518,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06858"
    },
    "Rank": 33520,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute Of Medical Sciences, Kalaburagi,VEERESH NAGAR SEDAM ROAD KALABURAGI, Karnataka, 585105",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06859"
    },
    "Rank": 33521,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0685a"
    },
    "Rank": 33522,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0685b"
    },
    "Rank": 33527,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0685c"
    },
    "Rank": 33538,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Kamakshi Memorial Hospital, (Dr. Kamakshi Institute of Medical Sciences and Research) ,No.1,Radial Road , Pallikaranai, Chennai, Tamil Nadu, 600100",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0685d"
    },
    "Rank": 33542,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0685e"
    },
    "Rank": 33554,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Balaji Action Medical Institute,,FC34 A4 PASCHIM VIHAR NEW DELHI, Delhi (NCT), 110063",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0685f"
    },
    "Rank": 33562,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "N S Memorial Institute of Medical Sciences ,N S Memorial Institute of Medical Sciences Palathara, Thattamala, Kollam, Kerala, 691020",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06860"
    },
    "Rank": 33569,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06861"
    },
    "Rank": 33583,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SBLS Civil Hospital, ,NEAR JYOTI CHOWK JAIL ROAD JALANDHAR, Punjab, 144001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06862"
    },
    "Rank": 33594,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Pali Rajasthan.,NH-62,Sumerpur Road,Village Ramasiya,Hemawas,Pali, Rajasthan, Rajasthan, 306401",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06863"
    },
    "Rank": 33599,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06864"
    },
    "Rank": 33602,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tomo Riba Institute of Health and Medical Sciences,,TRIHMS. Old Assembly Block. Near Rajiv Gandhi Stadium. Naharlagun-791110. Arunachal Pradesh, Arunachal Pradesh, 791110",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06865"
    },
    "Rank": 33606,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06866"
    },
    "Rank": 33611,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06867"
    },
    "Rank": 33614,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06868"
    },
    "Rank": 33623,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06869"
    },
    "Rank": 33636,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0686a"
    },
    "Rank": 33638,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0686b"
    },
    "Rank": 33645,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. District Civil Hospital, ,DIS GOVT HOSPITAL, SRI GANGANAGAR, Rajasthan, 335001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0686c"
    },
    "Rank": 33646,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Alexis Multispecialty Hospital,232, Mankapur Square, Koradi road, NAGPUR, Maharashtra, 440030",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0686d"
    },
    "Rank": 33648,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0686e"
    },
    "Rank": 33649,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0686f"
    },
    "Rank": 33652,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. District Hospital, Rajasthan,Ajmer Road Kekri District Ajmer, Rajasthan, 305404",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06870"
    },
    "Rank": 33654,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06871"
    },
    "Rank": 33659,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lilavati Hospital and Research Centre, ,Lilavati Hospital And Research Centre A-791, Bandra Reclamation, Bandra - West, Mumbai, Maharashtra, 400050",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06872"
    },
    "Rank": 33663,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahathma Eye Hospital,\n,No. 6, Seshapuram, Tennur, Tiruchirappalli - 620017, Tamilnadu, Tamil Nadu, 620017",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06873"
    },
    "Rank": 33667,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine & Sagore Dutta Hospital,College of Medicine & Sagore Dutta Hospital, West Bengal, 700058",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06874"
    },
    "Rank": 33670,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM Govt District Hospital, ,MGM Govt District Hospital,Hanumangarh town, Rajasthan, 335513",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06875"
    },
    "Rank": 33688,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06876"
    },
    "Rank": 33694,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06877"
    },
    "Rank": 33700,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mallige Medical Centre, ,No 31 32 Crescent Road Bangalore 560001, Karnataka, 560001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06878"
    },
    "Rank": 33706,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rabindranath Tagore International Institute of Cardiac Sciences, ,PREMISES NO. 1489 124 MUKUNDAPUR  E M BYPASS  KOLKATA-700099, West Bengal, 700099",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06879"
    },
    "Rank": 33708,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0687a"
    },
    "Rank": 33720,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0687b"
    },
    "Rank": 33729,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ahalia Diabetes Hospital , Kerala,Ahalia Campus Kozhippara Post, Kerala, 678557",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0687c"
    },
    "Rank": 33734,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0687d"
    },
    "Rank": 33739,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0687e"
    },
    "Rank": 33741,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. Medical College and hospital, ,Govt Medical College  Akola  Collector Office Road Akola Maharshtra, Maharashtra, 444001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0687f"
    },
    "Rank": 33746,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Panihati State General Hospital, West Bengal,218F Barasat Road, Sodepur. north 24 pgs, West Bengal, 700110",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06880"
    },
    "Rank": 33748,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06881"
    },
    "Rank": 33757,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES ADILABAD -504001 TELANGANA STATE, Telangana, 504001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06882"
    },
    "Rank": 33767,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kharagpur Sub-Divisional Hospital , ,At-Chotatengra, P.O.- Kharagpur, P.S.- Kharagpur Town, Pin- 721301, West Bengal, 721301",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06883"
    },
    "Rank": 33781,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06884"
    },
    "Rank": 33790,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06885"
    },
    "Rank": 33793,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06886"
    },
    "Rank": 33797,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06887"
    },
    "Rank": 33800,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06888"
    },
    "Rank": 33808,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. Mahatma Gandhi District Hospital, ,COLLETOR ATE CHORAHA NEAR MOHAN COLONY BANSWARA, Rajasthan, 327001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06889"
    },
    "Rank": 33816,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ahalia Diabetes Hospital , Kerala,Ahalia Campus Kozhippara Post, Kerala, 678557",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0688a"
    },
    "Rank": 33819,
    "Allotted Quota": "All India",
    "Allotted Institute": "Post Graduate Institute of Medical Education & Research and Capital Hospital ( PGIMER & CH), Odisha,Post Graduate Institute of Medical Education & Research and Capital Hospital, Bhubaneswar,Udyan Marg, Odisha, 751001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0688b"
    },
    "Rank": 33821,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0688c"
    },
    "Rank": 33832,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0688d"
    },
    "Rank": 33836,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0688e"
    },
    "Rank": 33839,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Khan Bahadur Bhabha Hospital, Maharashtra,Khan Bahadur Bhabha Hospital Belgrami road kurla west, Maharashtra, 400070",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0688f"
    },
    "Rank": 33840,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sadar Hospital,\n,Sadar Hospital, Belisarai, Motihari, East Champaran, Bihar, Under Government of Bihar, Bihar, 845401",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06890"
    },
    "Rank": 33841,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06891"
    },
    "Rank": 33845,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06892"
    },
    "Rank": 33853,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06893"
    },
    "Rank": 33856,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06894"
    },
    "Rank": 33857,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06895"
    },
    "Rank": 33858,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06896"
    },
    "Rank": 33859,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital,\n,paddoc road, near majewadi gate, junagadh, Gujarat, 362001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06897"
    },
    "Rank": 33869,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District General Hospital, ,O/o. Medical Superintendent, Government District General Hospital, Contonment, Vizianagaram, Andhra Pradesh,\n535003",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06898"
    },
    "Rank": 33873,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06899"
    },
    "Rank": 33887,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,OFFICE OF SUPERINTENDENT 9TH FLOOR SSH PO JALPAIGURI,\nWest Bengal, 735101",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0689a"
    },
    "Rank": 33895,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0689b"
    },
    "Rank": 33899,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College,Churu,Civil Line, Shekhawat Colony, Churu-331001, Rajasthan, 331001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0689c"
    },
    "Rank": 33906,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0689d"
    },
    "Rank": 33913,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0689e"
    },
    "Rank": 33917,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yatharth Superspeciality Hospitals,\nUttar Pradesh,HO-01, Sector-01, Greater Noida West, Uttar Pradesh, 201306",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0689f"
    },
    "Rank": 33924,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068a0"
    },
    "Rank": 33934,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "JBMM District Hospital , ,JBMM DISTRICT HOSPITAL RAM BAGH PHATAK NANDAN CINEMA\nCHOWK AMRITSAR AMRITSAR AMRITSAR IN\n143001, Punjab, 143001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068a1"
    },
    "Rank": 33936,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068a2"
    },
    "Rank": 33939,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital ,ALIPURDUAR DISTRICT HOSPITAL, ALIPURDUAR, HOSPITAL ROAD, WARD NO-XIII, ALIPURDUAR , PIN-736121, WEST BENGAL, 736121",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068a3"
    },
    "Rank": 33943,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068a4"
    },
    "Rank": 33946,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068a5"
    },
    "Rank": 33957,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "VCSG Govt Institute of Medical Science and Research and HNB Base Teaching Hospital\n, ,Srikot Ganganali Srinagar Garhwal Uttarakhand, Uttarakhand, 246174",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068a6"
    },
    "Rank": 33960,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Institute of Medical Sciences (SVIMS), A State University estd. by Govt. of A.P.,Sri Venkateswara Institute of Medical Sciences, SVIMS, Alipiri Road, Tirupati, Andhra Pradesh, 517507",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068a7"
    },
    "Rank": 33961,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068a8"
    },
    "Rank": 33975,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Medical College for Women, Khanpur Kalan Sonepat,BPS Govt. Medical College for Women, Khanpur Kalan Sonepat, Haryana, 131305",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068a9"
    },
    "Rank": 33992,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068aa"
    },
    "Rank": 33993,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068ab"
    },
    "Rank": 33997,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chandannagar Sub Division Hospital, ,CHANDANNAGAR SD HOSPITAL, West Bengal, 712136",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068ac"
    },
    "Rank": 34004,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068ad"
    },
    "Rank": 34007,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068ae"
    },
    "Rank": 34014,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068af"
    },
    "Rank": 34020,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "National Institute of Pathology (Indian Council of Medical Research), ,ICMR-National Institute of Pathology, Sri Ramachari Bhawan, Safdarjung Hospital Campus, Ansari Nagar, Delhi (NCT), 110029",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068b0"
    },
    "Rank": 34025,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068b1"
    },
    "Rank": 34028,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital,\n,paddoc road, near majewadi gate, junagadh, Gujarat, 362001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068b2"
    },
    "Rank": 34043,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr.Chandrakanth Malabar Superspeciality Eye Hospital, Kerala,Kannur Rd, West Nadakkave, Vandipetta, Bilathikkulam, Kozhikode, Kerala 673011, Kerala, 673011",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068b3"
    },
    "Rank": 34046,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Domkal Sub Divisional and Super Speciality Hospital, West Bengal,Vill - Bablabona,  P.O\n- P.T. Rasulpur  , P.S - Domkal , Dist - Murshidabad, West Bengal, 742303",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068b4"
    },
    "Rank": 34049,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "JLNM Hospital, ,JLNM Hospital Rainawari Srinagar, Jammu And Kashmir, 190003",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068b5"
    },
    "Rank": 34052,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068b6"
    },
    "Rank": 34057,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dharamshila Narayana Superspeciality Hospital, ,Dharamshila Marg, Vasundhara Enclave, Dharamshila Narayana Superspeciality Hospital, Delhi 110096, Delhi (NCT), 110096",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068b7"
    },
    "Rank": 34059,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gvernment Medical College,Haldwani,Government Medical College, Haldwani, Uttarakhand, 263139",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068b8"
    },
    "Rank": 34060,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kauvery Hospital Medical Services Pvt Ltd,\n,vijay@kauveryhealthcare.com,\nNo 35,Shanthi nagar, Opposite to CSI church,Hosur 635109,Tamil Nadu,",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068b9"
    },
    "Rank": 34062,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Siliguri District Hospital,,Kachari Road, Siliguri, Dist- Darjeeling, West Bengal, West Bengal, 734001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068ba"
    },
    "Rank": 34068,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068bb"
    },
    "Rank": 34069,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE, PUDUCHERRY,INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE,\nPUDUCHERRY, Puducherry, 605009",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068bc"
    },
    "Rank": 34073,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Daga Memorial Women Government Hospital Nagpur, ,Ganjakhet gandhibagh nagpur, Maharashtra, 440008",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068bd"
    },
    "Rank": 34075,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ahalia Diabetes Hospital , Kerala,Ahalia Campus Kozhippara Post, Kerala, 678557",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068be"
    },
    "Rank": 34077,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068bf"
    },
    "Rank": 34078,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068c0"
    },
    "Rank": 34080,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nizam`s Institute of Medical Sciences, ,NIZAMS INSTITUTE OF MEDICAL SCIENCES PANJAGUTTA HYDERABAD, Telangana, 500082",
    "Course": "(NBEMS) IMMUNO- HAEMATOLOGY AND BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068c1"
    },
    "Rank": 34081,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lala Harbhagwan Das Memorial and Dr Prem Hospital, ,BISHAN SWAROOP COLONY, OPP. BUS STAND, Haryana, 132103",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068c2"
    },
    "Rank": 34089,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068c3"
    },
    "Rank": 34099,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068c4"
    },
    "Rank": 34101,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068c5"
    },
    "Rank": 34107,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "V Y Institute of Medical Science, ,Near Kamal Vihar, Dhamtari Road, Raipur, Chhattisgarh, 492001",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068c6"
    },
    "Rank": 34124,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068c7"
    },
    "Rank": 34125,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,SECTOR-6, PANCHKULA, HARYANA-134108, Haryana, 134108",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068c8"
    },
    "Rank": 34127,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gvernment Medical College,Haldwani,Government Medical College, Haldwani, Uttarakhand, 263139",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068c9"
    },
    "Rank": 34135,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068ca"
    },
    "Rank": 34146,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068cb"
    },
    "Rank": 34151,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068cc"
    },
    "Rank": 34152,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068cd"
    },
    "Rank": 34156,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068ce"
    },
    "Rank": 34160,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068cf"
    },
    "Rank": 34164,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068d0"
    },
    "Rank": 34171,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068d1"
    },
    "Rank": 34173,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068d2"
    },
    "Rank": 34174,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068d3"
    },
    "Rank": 34178,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Multispeciality Hospital , West Bengal,Andul road, Podrah Chunavati Howrah, West Bengal, 711109",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068d4"
    },
    "Rank": 34179,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068d5"
    },
    "Rank": 34181,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,DISTRICT HOSPITAL NEAR SANGAM CIRCLE DR RAJ KUMAR ROAD BALLARI-583101 KARNATAKA, Karnataka, 583101",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068d6"
    },
    "Rank": 34182,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068d7"
    },
    "Rank": 34184,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068d8"
    },
    "Rank": 34185,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068d9"
    },
    "Rank": 34192,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068da"
    },
    "Rank": 34194,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068db"
    },
    "Rank": 34205,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068dc"
    },
    "Rank": 34206,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068dd"
    },
    "Rank": 34211,
    "Allotted Quota": "All India",
    "Allotted Institute": "NSC Government Medical College, Khandwa (MP),LUV KUSH NAGAR,MUNDI NAGAR, KHANDWA, Madhya Pradesh, 450001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068de"
    },
    "Rank": 34217,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Christian Institute of Health Sciences and Research, ,4th Mile, Central Jail Road, Dimapur, Nagaland, 797115",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068df"
    },
    "Rank": 34228,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital, ,RAILWAY FEEDER ROAD , KARAIKUDI, Tamil Nadu, 630001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068e0"
    },
    "Rank": 34230,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068e1"
    },
    "Rank": 34233,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068e2"
    },
    "Rank": 34235,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068e3"
    },
    "Rank": 34251,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068e4"
    },
    "Rank": 34254,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068e5"
    },
    "Rank": 34258,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068e6"
    },
    "Rank": 34265,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068e7"
    },
    "Rank": 34270,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SDH Nathdwara, ,shree goverdhan chikitsalay nathdwara, Rajasthan, 313301",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068e8"
    },
    "Rank": 34276,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Motors Hospital, ,KHARANGAJHAR, TELCO COLONY JAMSHEDPUR, Jharkhand, 831003",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068e9"
    },
    "Rank": 34283,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068ea"
    },
    "Rank": 34285,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068eb"
    },
    "Rank": 34291,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068ec"
    },
    "Rank": 34293,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068ed"
    },
    "Rank": 34299,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) MICROBIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068ee"
    },
    "Rank": 34301,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068ef"
    },
    "Rank": 34303,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sanjay Gandhi Postgarduate Institute of Medical Sciences,Sanjay Gandhi Postgarduate Institute of Medical Sciences, Uttar Pradesh, 226014",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068f0"
    },
    "Rank": 34307,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068f1"
    },
    "Rank": 34308,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068f2"
    },
    "Rank": 34310,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068f3"
    },
    "Rank": 34320,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068f4"
    },
    "Rank": 34327,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068f5"
    },
    "Rank": 34336,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESIC Model Hospital, ,A-3, TULSI MARG, SECTOR-24, NOIDA, Uttar Pradesh, 201301",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068f6"
    },
    "Rank": 34340,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jalgaon,Jilha Peth, Government Hospital Campus, Jalgaon, Maharashtra, 425001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068f7"
    },
    "Rank": 34344,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068f8"
    },
    "Rank": 34346,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rampurhat Govt. Medical College and Hospital,\n,RAMPURHAT GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, P.O. RAMPURHAT, P.S. RAMPURHAT, DIST. BIRBHUM, WB, West Bengal, 731224",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068f9"
    },
    "Rank": 34347,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068fa"
    },
    "Rank": 34357,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068fb"
    },
    "Rank": 34366,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "NAIHATI STATE GENERAL HOSPITAL , West Bengal,NAIHATI NORTH 24\nPARGANAS, West Bengal, 743165",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068fc"
    },
    "Rank": 34368,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068fd"
    },
    "Rank": 34374,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068fe"
    },
    "Rank": 34379,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sheth Vadilal Sarabhai General Hospital and Sheth Chinai Prasuti Hospital, Gujarat,SHETH VADILAL SARABHAI GENERAL HOSPITAL AND SHETH CHINAI PRASUTI HOSPITAL ELLISBRIDGE AHMEDABAD, Gujarat, 380006",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a068ff"
    },
    "Rank": 34382,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06900"
    },
    "Rank": 34388,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital, ,Nellikuppam Main Road, Cuddalore, Tamil Nadu, 607001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06901"
    },
    "Rank": 34404,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lalbagh Sub Division Hospital, West Bengal,Golapbagh, PO-Murshidabad, PS- Murshidabad, Dist.-Murshidabad, West Bengal, 742149",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06902"
    },
    "Rank": 34407,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06903"
    },
    "Rank": 34410,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06904"
    },
    "Rank": 34413,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital\nNext to Sainik School second gate Athani Road, Vijayapura Karnataka 586103, Karnataka, 586103",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06905"
    },
    "Rank": 34414,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Diamond Harbour Government Medical College And Hospital, ,Harindanga, New Town, Word No- 4, P.O and P.S- Diamond Harbour, Dist- South 24 Parganas, West Bengal, West Bengal, 743331",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06906"
    },
    "Rank": 34415,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06907"
    },
    "Rank": 34416,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06908"
    },
    "Rank": 34421,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06909"
    },
    "Rank": 34422,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0690a"
    },
    "Rank": 34427,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Kamakshi Memorial Hospital, (Dr. Kamakshi Institute of Medical Sciences and Research) ,No.1,Radial Road , Pallikaranai, Chennai, Tamil Nadu, 600100",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0690b"
    },
    "Rank": 34429,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0690c"
    },
    "Rank": 34436,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0690d"
    },
    "Rank": 34440,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS COLLEGE OF MEDICAL SCIENCES,RUHS COLLEGE OF MEDICAL\nSCIENCES, Rajasthan, 302033",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0690e"
    },
    "Rank": 34443,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0690f"
    },
    "Rank": 34453,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06910"
    },
    "Rank": 34454,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06911"
    },
    "Rank": 34459,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Life line Super Specialty Hospital, ,14th Mile, Melood P.O Adoor , Pathanamthitta Dist, Kerala, 691554",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06912"
    },
    "Rank": 34462,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College,Tumuki, Bihaguri, Tezpur, Sonitpur, Assam, Assam, 784010",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06913"
    },
    "Rank": 34464,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06914"
    },
    "Rank": 34466,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kauvery Hospital Medical Services Pvt Ltd,\n,vijay@kauveryhealthcare.com,\nNo 35,Shanthi nagar, Opposite to CSI church,Hosur 635109,Tamil Nadu,",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06915"
    },
    "Rank": 34471,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06916"
    },
    "Rank": 34477,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06917"
    },
    "Rank": 34478,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06918"
    },
    "Rank": 34482,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06919"
    },
    "Rank": 34483,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sparsh Super Specialty Hospital, ,SPARSH SUPER SPECIALITY HOSPITAL, TUMKUR ROAD, YESHWANTHPURA, BANGALORE, Karnataka, 560022",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0691a"
    },
    "Rank": 34485,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0691b"
    },
    "Rank": 34487,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0691c"
    },
    "Rank": 34490,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0691d"
    },
    "Rank": 34503,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0691e"
    },
    "Rank": 34506,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0691f"
    },
    "Rank": 34507,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Baby Memorial Hospital, ,Indira Gandhi Road Kozhikode, Kerala, 673004",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06920"
    },
    "Rank": 34510,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06921"
    },
    "Rank": 34515,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06922"
    },
    "Rank": 34516,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06923"
    },
    "Rank": 34517,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharashtra Post Graduate Institute of Medical Education and Research, Nashik,Maharashtra Post Graduation Institute Of Medical Education and Research Civil Hospital campus Trimba, Maharashtra, 422002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06924"
    },
    "Rank": 34520,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06925"
    },
    "Rank": 34538,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06926"
    },
    "Rank": 34541,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajan Eye Care Hospital,,No.5, Vidyodaya second cross street,T.Nagar, Chennai- 600017\nTamil Nadu,\nIndia, Tamil Nadu, 600017",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06927"
    },
    "Rank": 34544,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Jamshedpur,Mahatma Gandhi Memorial Medical College, Jamshedpur, Jharkhand, 831020",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06928"
    },
    "Rank": 34545,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESIC MEDICAL COLLEGE AND HOSPITAL, Bihar,ESIC Medical College and Hospital,Ara Danapur Road, Sikandarpur, Bihta, Patna, Bihar, 801103",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06929"
    },
    "Rank": 34549,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dholpur,Old City Road Dholpur, Rajasthan, 328001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0692a"
    },
    "Rank": 34553,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Woodland Hospital, Meghalaya,Dhankheti P.O. Laitumkhrah East Khasi Hills District Shillong, Meghalaya, 793003",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0692b"
    },
    "Rank": 34557,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0692c"
    },
    "Rank": 34558,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Siddipet,SURVEY NO 54  GOVERNMENT MEDICAL COLLEGE  ENSANPALLY  SIDDIPET   502114, Telangana, 502114",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0692d"
    },
    "Rank": 34564,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0692e"
    },
    "Rank": 34571,
    "Allotted Quota": "All India",
    "Allotted Institute": "Calcutta School Of Tropical Medicine,Calcutta School Of Tropical Medicine, West Bengal, 700073",
    "Course": "M.D. (TROPICAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0692f"
    },
    "Rank": 34572,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indraprastha Apollo Hospital,,Sarita Vihar, New Delhi 110 076, Delhi (NCT), 110076",
    "Course": "(NBEMS) IMMUNO- HAEMATOLOGY AND BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06930"
    },
    "Rank": 34575,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06931"
    },
    "Rank": 34576,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06932"
    },
    "Rank": 34579,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06933"
    },
    "Rank": 34586,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06934"
    },
    "Rank": 34589,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06935"
    },
    "Rank": 34594,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06936"
    },
    "Rank": 34598,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Life line Super Specialty Hospital, ,14th Mile, Melood P.O Adoor , Pathanamthitta Dist, Kerala, 691554",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06937"
    },
    "Rank": 34613,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Siddipet,SURVEY NO 54  GOVERNMENT MEDICAL COLLEGE  ENSANPALLY  SIDDIPET   502114, Telangana, 502114",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06938"
    },
    "Rank": 34615,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06939"
    },
    "Rank": 34622,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0693a"
    },
    "Rank": 34635,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Associated Hospital Government Medical College, ,Kanth Bagh Baramulla, Jammu And Kashmir, 193103",
    "Course": "(NBEMS) COMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0693b"
    },
    "Rank": 34637,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0693c"
    },
    "Rank": 34640,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0693d"
    },
    "Rank": 34644,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital,,DNB office, 3rd Floor Opp Library  Apollo Hospitals, Jubilee Hills, Hyderabad, Telangana, 500033",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0693e"
    },
    "Rank": 34645,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0693f"
    },
    "Rank": 34648,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06940"
    },
    "Rank": 34651,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Autonomous State Medical College and Allied Pandit Ram Prasad Bismil Memorial Hospital,\n,ASMC, JIGNERA, kanth road, shahjahanpur, Uttar Pradesh, 242001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06941"
    },
    "Rank": 34653,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06942"
    },
    "Rank": 34656,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06943"
    },
    "Rank": 34658,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06944"
    },
    "Rank": 34676,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chinmaya Mission Hospital,,Chinmaya Mission Hospital, 80 Feet CMH Road, Indiranagar, Bangalore, Karnataka, 560038",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06945"
    },
    "Rank": 34683,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06946"
    },
    "Rank": 34687,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Family Hospital,,Okhla Road, New Delhi, Delhi (NCT), 110025",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06947"
    },
    "Rank": 34692,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06948"
    },
    "Rank": 34706,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06949"
    },
    "Rank": 34726,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0694a"
    },
    "Rank": 34727,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0694b"
    },
    "Rank": 34729,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0694c"
    },
    "Rank": 34734,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0694d"
    },
    "Rank": 34741,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0694e"
    },
    "Rank": 34764,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0694f"
    },
    "Rank": 34765,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government DB General Medical Hosptal,Churu, Nayabass, Churu",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06950"
    },
    "Rank": 34767,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital,,DNB office, 3rd Floor Opp Library  Apollo Hospitals, Jubilee Hills, Hyderabad, Telangana, 500033",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06951"
    },
    "Rank": 34768,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KMC Specialty Hospital, ,No-6, Royal Road, Cantonment, Trichy-620001, Tamil Nadu, 620001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06952"
    },
    "Rank": 34774,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Government Medical College, Vidisha,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA MP, Madhya Pradesh, 464001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06953"
    },
    "Rank": 34777,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kharagpur Sub-Divisional Hospital , ,At-Chotatengra, P.O.- Kharagpur, P.S.- Kharagpur Town, Pin- 721301, West Bengal, 721301",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06954"
    },
    "Rank": 34778,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06955"
    },
    "Rank": 34780,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06956"
    },
    "Rank": 34781,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06957"
    },
    "Rank": 34782,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06958"
    },
    "Rank": 34798,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06959"
    },
    "Rank": 34802,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0695a"
    },
    "Rank": 34811,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0695b"
    },
    "Rank": 34812,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0695c"
    },
    "Rank": 34813,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "FAKIR MOHAN MEDICAL COLLEGE AND HOSPITAL ,\nOrissa,AT-Remuna Post-Remuna Dist-Balasore, Odisha, 756019",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0695d"
    },
    "Rank": 34816,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0695e"
    },
    "Rank": 34820,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0695f"
    },
    "Rank": 34821,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06960"
    },
    "Rank": 34824,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06961"
    },
    "Rank": 34826,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06962"
    },
    "Rank": 34842,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06963"
    },
    "Rank": 34847,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06964"
    },
    "Rank": 34854,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Gondia,Nehru Chowk, KTS General Hospital Campus, Govt. Medical College, Gondia. 441601. Maharashtra., Maharashtra, 441601",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06965"
    },
    "Rank": 34866,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "College of Medicine and JNM Hospital,,Kalyani, Silpanchal, West Bengal, 741235",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06966"
    },
    "Rank": 34868,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06967"
    },
    "Rank": 34871,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06968"
    },
    "Rank": 34899,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06969"
    },
    "Rank": 34909,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "APL and ANR Area, Andhra Pradesh,Area Hospital, Gudiwada\nOPP Head Post office Road, Fire station road, Gudiwada, Andhra Pradesh, 521301",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0696a"
    },
    "Rank": 34910,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0696b"
    },
    "Rank": 34915,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES,CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, Chhattisgarh, 495001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0696c"
    },
    "Rank": 34916,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0696d"
    },
    "Rank": 34925,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Hurkisondas Nurrotumdas Hospital and Research Centre, ,Prarthana Samaj, Raja Ram Mohan Roy road,\nGirgaon, MUMBAI, Maharashtra, 400004",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0696e"
    },
    "Rank": 34929,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0696f"
    },
    "Rank": 34940,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06970"
    },
    "Rank": 34943,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sree Balaram Seva Mandir S.G. Hospital, ,225\\5,MS Mukherjee Road,PO-Sopan,PS Khardaha,Kol-700116, West Bengal, 700116",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06971"
    },
    "Rank": 34950,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06972"
    },
    "Rank": 34955,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06973"
    },
    "Rank": 34959,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital, ,Kharvad medan Sipor road Vadnagar, Gujarat, 384355",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06974"
    },
    "Rank": 34961,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06975"
    },
    "Rank": 34965,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06976"
    },
    "Rank": 34967,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06977"
    },
    "Rank": 34969,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06978"
    },
    "Rank": 34988,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K.G. Hospital and PG Medical Institute ,,No:5, Arts College Road, Coimbatore - 641018, Tamil Nadu, 641018",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06979"
    },
    "Rank": 34992,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0697a"
    },
    "Rank": 34994,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "NH Brahamananda Narayana ,Near Pardih Chowk, Tamolia, NH 33, Jamshedpur, Saraikela-Kharsawan, Jharkhand 831012, Jharkhand, 831012",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0697b"
    },
    "Rank": 34995,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0697c"
    },
    "Rank": 34997,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0697d"
    },
    "Rank": 34998,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kabra Eye Hospital, Rajasthan,C-59,60 JAMNA NAGAR, SODALA, JAIPUR, Rajasthan, 302006",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0697e"
    },
    "Rank": 35011,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Christian Institute of Health Sciences and Research, ,4th Mile, Central Jail Road, Dimapur, Nagaland, 797115",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0697f"
    },
    "Rank": 35012,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06980"
    },
    "Rank": 35018,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SDH KUPWARA, Jammu and Kashmir,IQBAL MARKET KUPWARA, Jammu And Kashmir, 193222",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06981"
    },
    "Rank": 35021,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "IGM Hospital , ,IGM Chowmuhani, Agartala, Tripura West, Tripura, 799001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06982"
    },
    "Rank": 35022,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06983"
    },
    "Rank": 35031,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06984"
    },
    "Rank": 35043,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06985"
    },
    "Rank": 35046,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06986"
    },
    "Rank": 35053,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06987"
    },
    "Rank": 35061,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06988"
    },
    "Rank": 35063,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,HBT Medical College and Dr R N Cooper Municipal General Hospital, Bhaktivedanta Swami Rd, JVPD Schem, Maharashtra, 400056",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06989"
    },
    "Rank": 35068,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0698a"
    },
    "Rank": 35071,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0698b"
    },
    "Rank": 35078,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0698c"
    },
    "Rank": 35079,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0698d"
    },
    "Rank": 35082,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0698e"
    },
    "Rank": 35084,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0698f"
    },
    "Rank": 35088,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06990"
    },
    "Rank": 35110,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06991"
    },
    "Rank": 35116,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Erode Medical Centre, Tamil Nadu,374-2,3,5, PERUNDURAI ROAD, OPPOSITE TO RELIANCE MALL, ERODE, Tamil Nadu, 638011",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06992"
    },
    "Rank": 35118,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital , ,GMERS MEDICAL COLLEGE , Hadiyol-Gadhoda Road, Near-Government Polytechnic, Hiimmatnagar, Gujarat, 383001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06993"
    },
    "Rank": 35130,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06994"
    },
    "Rank": 35141,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06995"
    },
    "Rank": 35146,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "IGM Hospital , ,IGM Chowmuhani, Agartala, Tripura West, Tripura, 799001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06996"
    },
    "Rank": 35147,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06997"
    },
    "Rank": 35148,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06998"
    },
    "Rank": 35152,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES ADILABAD -504001 TELANGANA STATE, Telangana, 504001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06999"
    },
    "Rank": 35169,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0699a"
    },
    "Rank": 35170,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0699b"
    },
    "Rank": 35176,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0699c"
    },
    "Rank": 35183,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0699d"
    },
    "Rank": 35197,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0699e"
    },
    "Rank": 35200,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0699f"
    },
    "Rank": 35201,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069a0"
    },
    "Rank": 35205,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069a1"
    },
    "Rank": 35208,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069a2"
    },
    "Rank": 35215,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069a3"
    },
    "Rank": 35223,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069a4"
    },
    "Rank": 35224,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mohandai Oswal Hospital, ,Mohandai Oswal Hospital, Sherpur Byepass, G T Road, Ludhiana-141009, Punjab, 141009",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069a5"
    },
    "Rank": 35225,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Kamakshi Memorial Hospital, (Dr. Kamakshi Institute of Medical Sciences and Research) ,No.1,Radial Road , Pallikaranai, Chennai, Tamil Nadu, 600100",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069a6"
    },
    "Rank": 35226,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Jamshedpur,Mahatma Gandhi Memorial Medical College, Jamshedpur, Jharkhand, 831020",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069a7"
    },
    "Rank": 35231,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069a8"
    },
    "Rank": 35232,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069a9"
    },
    "Rank": 35236,
    "Allotted Quota": "All India",
    "Allotted Institute": "GADAG INSTITUTE OF MEDICAL SCIENCES GADAG,Gadag Institute of Medical Sciences Mallasamudra Gadag-582103, Karnataka, 582103",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069aa"
    },
    "Rank": 35241,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Midland Healthcare and Research Centre, Uttar Pradesh,B-55 Mandir Marg, Mahanagar,Lucknow., Uttar Pradesh, 226006",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069ab"
    },
    "Rank": 35248,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069ac"
    },
    "Rank": 35251,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069ad"
    },
    "Rank": 35268,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Bangur Hospital, Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan , Rajasthan-306401,Government Medical College and Bangur Hospital Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan Ra, Rajasthan, 306401",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069ae"
    },
    "Rank": 35278,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069af"
    },
    "Rank": 35286,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Area Hospital, Andhra Pradesh,Area Hospital Amalapuram, East Godavari District, Andhra Pradesh, 533201",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069b0"
    },
    "Rank": 35288,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069b1"
    },
    "Rank": 35294,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069b2"
    },
    "Rank": 35301,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069b3"
    },
    "Rank": 35307,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069b4"
    },
    "Rank": 35316,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Erode Medical Centre, Tamil Nadu,374-2,3,5, PERUNDURAI ROAD, OPPOSITE TO RELIANCE MALL, ERODE, Tamil Nadu, 638011",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069b5"
    },
    "Rank": 35329,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE - KANNUR,GOVERNMENT MEDICAL COLLEGE\n- KANNUR, Kerala, 670503",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069b6"
    },
    "Rank": 35333,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069b7"
    },
    "Rank": 35335,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069b8"
    },
    "Rank": 35336,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute of Medical Sciences Srikakulam,Government Medical College Srikakulam\nBalaga Srikakulam Town Srikakulam District, Andhra Pradesh, 532001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069b9"
    },
    "Rank": 35339,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069ba"
    },
    "Rank": 35345,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069bb"
    },
    "Rank": 35347,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069bc"
    },
    "Rank": 35353,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital, ,NO 1 RAMAMOORTHY ROAD VIRUDHUNAGAR, Tamil Nadu, 626001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069bd"
    },
    "Rank": 35357,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069be"
    },
    "Rank": 35360,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospitals Limited Fortis Escorts Hospital, Punjab,Majitha Verka Bypass Road Amritsar, Punjab, 143004",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069bf"
    },
    "Rank": 35367,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069c0"
    },
    "Rank": 35380,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "V Y Institute of Medical Science, ,Near Kamal Vihar, Dhamtari Road, Raipur, Chhattisgarh, 492001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069c1"
    },
    "Rank": 35387,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069c2"
    },
    "Rank": 35397,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Panjabrao Alias Bhausaheb Deshmukh Memorial Medical College, ,Shivaji Nagar Near Panchawati Square Amravati, Maharashtra, 444603",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069c3"
    },
    "Rank": 35403,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069c4"
    },
    "Rank": 35408,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069c5"
    },
    "Rank": 35410,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069c6"
    },
    "Rank": 35423,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069c7"
    },
    "Rank": 35425,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069c8"
    },
    "Rank": 35430,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069c9"
    },
    "Rank": 35438,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069ca"
    },
    "Rank": 35439,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069cb"
    },
    "Rank": 35462,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069cc"
    },
    "Rank": 35469,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "INSTITUTE OF HUMAN  BEHAVIOUR AND ALLIED SCIENCES,Institute of Human\nBehaviour and Allied Sciences,  Dilshad Garden, New Delhi, Delhi 110095, India, Delhi (NCT), 110095",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069cd"
    },
    "Rank": 35470,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chinmaya Mission Hospital,,Chinmaya Mission Hospital, 80 Feet CMH Road, Indiranagar, Bangalore, Karnataka, 560038",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069ce"
    },
    "Rank": 35471,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069cf"
    },
    "Rank": 35485,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESI Hospital, ,IG ESI HOSPITAL JHILMIL DELHI - 95, Delhi (NCT), 110095",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069d0"
    },
    "Rank": 35488,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Amardeep Eye Care , Kollam, Kerala,12-694, 2nd MILESTONE, KOLLAM, Kerala, 691004",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069d1"
    },
    "Rank": 35490,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069d2"
    },
    "Rank": 35491,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "JLNM Hospital, ,JLNM Hospital Rainawari Srinagar, Jammu And Kashmir, 190003",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069d3"
    },
    "Rank": 35497,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069d4"
    },
    "Rank": 35499,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhima Bhoi Medical College and Hospital, Balangir,At-Laltikra Po-Rajendra College, Balangir, Odisha, 767002",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069d5"
    },
    "Rank": 35510,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Allopathic Medical College, Uttar Pradesh,RANI DURGAVATI MEDICAL COLLEGE NARAINI ROAD BANDA, Uttar Pradesh, 210001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069d6"
    },
    "Rank": 35511,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Super Facility Hospital, Distt.-Azamgarh, Uttar Pradesh,Chakrapanpur, Kanaila, Azamgarh, Uttar Pradesh, 276128",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069d7"
    },
    "Rank": 35517,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Purba Medinipur District Hospital, ,PO - TAMLUK, PS - TAMLUK, DIST - PURBA MEDINIPUR, West Bengal, 721636",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069d8"
    },
    "Rank": 35518,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069d9"
    },
    "Rank": 35529,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (Hospital Administration)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069da"
    },
    "Rank": 35531,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069db"
    },
    "Rank": 35538,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Hospital, ,principal.mc.barmer@rajasthan.gov.in, Rajasthan, 344001",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069dc"
    },
    "Rank": 35541,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital, ,Nellikuppam Main Road, Cuddalore, Tamil Nadu, 607001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069dd"
    },
    "Rank": 35546,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069de"
    },
    "Rank": 35548,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "North District Hospital -,Near Govt ITI College, Peddem, Mapusa, Bardez Goa., Goa, 403507",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069df"
    },
    "Rank": 35550,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indo-American Cancer Institute and Research Centre, ,Road No.14, Banjara Hills, Hyderabad, Telangana, 500034",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069e0"
    },
    "Rank": 35564,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shrikrishna Medical College & Hospital, Muzaffarpur,Shrikrishna Medical College & Hospital, Muzaffarpur, Bihar, 842004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069e1"
    },
    "Rank": 35568,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069e2"
    },
    "Rank": 35569,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069e3"
    },
    "Rank": 35573,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069e4"
    },
    "Rank": 35574,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Holy Family Hospital,,Okhla Road, New Delhi, Delhi (NCT), 110025",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069e5"
    },
    "Rank": 35576,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM Health Care Private Limited, Tamil Nadu,New No 72, Old No 54, Nelson Manickam Road, Aminjikarai, Chennai – 600029 Tamilnadu State, Tamil Nadu, 600029",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069e6"
    },
    "Rank": 35577,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069e7"
    },
    "Rank": 35583,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jalgaon,Jilha Peth, Government Hospital Campus, Jalgaon, Maharashtra, 425001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069e8"
    },
    "Rank": 35587,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069e9"
    },
    "Rank": 35595,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069ea"
    },
    "Rank": 35597,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069eb"
    },
    "Rank": 35598,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Venkateshwar Hospital, ,Sector- 18 A, Dwarka, New Delhi, Delhi (NCT), 110075",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069ec"
    },
    "Rank": 35599,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Naga Hospital Authority, Nagaland-0,NH-29, Hospital Colony, Kohima, Nagaland 797001, Nagaland, 797001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069ed"
    },
    "Rank": 35606,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069ee"
    },
    "Rank": 35609,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069ef"
    },
    "Rank": 35614,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069f0"
    },
    "Rank": 35618,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069f1"
    },
    "Rank": 35641,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069f2"
    },
    "Rank": 35651,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government General Hospital, Principal Medical Officer General hospital,\n,Rajiv Gandhi Government General Hospital, near bijli ghar circle, ALWAR, Rajasthan, 301001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069f3"
    },
    "Rank": 35657,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069f4"
    },
    "Rank": 35665,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College,Fakhruddin Ali Ahmed Medical College, Assam, 781301",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069f5"
    },
    "Rank": 35678,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069f6"
    },
    "Rank": 35683,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069f7"
    },
    "Rank": 35686,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069f8"
    },
    "Rank": 35697,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069f9"
    },
    "Rank": 35698,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069fa"
    },
    "Rank": 35702,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069fb"
    },
    "Rank": 35703,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chettinad Super Speciality Hospital, Tamil Nadu,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069fc"
    },
    "Rank": 35706,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069fd"
    },
    "Rank": 35711,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069fe"
    },
    "Rank": 35721,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a069ff"
    },
    "Rank": 35722,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a00"
    },
    "Rank": 35725,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a01"
    },
    "Rank": 35726,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a02"
    },
    "Rank": 35727,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a03"
    },
    "Rank": 35742,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jagjivan Ram Railway Hospital,,Maratha Mandir Marg, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a04"
    },
    "Rank": 35744,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gunam Super Speciality Hospital,No 35, Tank Street, Opp To Govt Hospital, Denkanikottai Road, Hosur, Krishnagiri Dist., Tamil Nadu, 635109",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a05"
    },
    "Rank": 35752,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a06"
    },
    "Rank": 35753,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a07"
    },
    "Rank": 35763,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indira Gandhi Govt. General Hospital and PG Institute, No. 1, ,No.1, Victor Simonel street, Puducherry -1., Puducherry, 605001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a08"
    },
    "Rank": 35771,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Agrasen Medical College,,NH- 9, VPO - Agroha, District Hisar, Haryana, 125047",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a09"
    },
    "Rank": 35773,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a0a"
    },
    "Rank": 35775,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a0b"
    },
    "Rank": 35782,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Synergy Institute of Medical Sciences, ,Ballupur Canal Road, Ballupur Chowk, Dehradun, Uttarakhand, 248001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a0c"
    },
    "Rank": 35793,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a0d"
    },
    "Rank": 35795,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a0e"
    },
    "Rank": 35806,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a0f"
    },
    "Rank": 35810,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital , ,GMERS MEDICAL COLLEGE , Hadiyol-Gadhoda Road, Near-Government Polytechnic, Hiimmatnagar, Gujarat, 383001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a10"
    },
    "Rank": 35815,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a11"
    },
    "Rank": 35824,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a12"
    },
    "Rank": 35827,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Government Medical College, Vidisha,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA MP, Madhya Pradesh, 464001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a13"
    },
    "Rank": 35830,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a14"
    },
    "Rank": 35840,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a15"
    },
    "Rank": 35841,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a16"
    },
    "Rank": 35842,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Datia,Near 29 Batallion, National Highway 44, Dist Datia, Madhya Pradesh Pin - 475661, Madhya Pradesh, 475661",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a17"
    },
    "Rank": 35844,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a18"
    },
    "Rank": 35853,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a19"
    },
    "Rank": 35854,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a1a"
    },
    "Rank": 35855,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a1b"
    },
    "Rank": 35856,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a1c"
    },
    "Rank": 35858,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Arambagh Sub Divisional/ Super Speciality Hospital, ,Arambagh, Hooghly , Pin - 712601, West Bengal, 712601",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a1d"
    },
    "Rank": 35873,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Autonomous State Medical College and Allied Pandit Ram Prasad Bismil Memorial Hospital,\n,ASMC, JIGNERA, kanth road, shahjahanpur, Uttar Pradesh, 242001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a1e"
    },
    "Rank": 35877,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a1f"
    },
    "Rank": 35887,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a20"
    },
    "Rank": 35888,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a21"
    },
    "Rank": 35890,
    "Allotted Quota": "All India",
    "Allotted Institute": "All  India Institute of  Hygiene and Public Health,110, C. R  Avenue, Kolkata, West Bengal, 700073",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a22"
    },
    "Rank": 35893,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruby Hall Clinic, ,GRANT MEDICAL FOUNDATION,\n40 SASSOON ROAD, PUNE 411001, Maharashtra, 411001",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a23"
    },
    "Rank": 35905,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Hospital,,JANGLAT MANDI ANANTNAG, JAMMU AND KASHMIR 192101, Jammu And Kashmir, 192101",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a24"
    },
    "Rank": 35921,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM Health Care Private Limited, Tamil Nadu,New No 72, Old No 54, Nelson Manickam Road, Aminjikarai, Chennai – 600029 Tamilnadu State, Tamil Nadu, 600029",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a25"
    },
    "Rank": 35922,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gvernment Medical College,Haldwani,Government Medical College, Haldwani, Uttarakhand, 263139",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a26"
    },
    "Rank": 35928,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a27"
    },
    "Rank": 35940,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Associated Hospital Government Medical College, ,Kanth Bagh Baramulla, Jammu And Kashmir, 193103",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a28"
    },
    "Rank": 35947,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a29"
    },
    "Rank": 35952,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a2a"
    },
    "Rank": 35954,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a2b"
    },
    "Rank": 35956,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Choithram Netralaya,Choithram Netralaya Plot No 54-56 Shree Ram Talawali Dhar road, Indore PIN 453001, Madhya Pradesh, Madhya Pradesh, 453001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a2c"
    },
    "Rank": 35957,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sadhu Vaswani Missions ,7-9 Lane No 1 Koregaon Park Pune 411001, Maharashtra, 411001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a2d"
    },
    "Rank": 35962,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a2e"
    },
    "Rank": 35964,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a2f"
    },
    "Rank": 35967,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a30"
    },
    "Rank": 35975,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a31"
    },
    "Rank": 35978,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a32"
    },
    "Rank": 35980,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a33"
    },
    "Rank": 35995,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a34"
    },
    "Rank": 35997,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sankara Nethralaya, (A Unit of Medical Research Foundation) ,147\\1 MUKUNDAPUR E M BYPASS KOLKATA 700099, West Bengal, 700099",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a35"
    },
    "Rank": 36000,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a36"
    },
    "Rank": 36004,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,Opposite to Shivajinagar Bus stop, Shivajinagar, Bengaluru., Karnataka, 560001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a37"
    },
    "Rank": 36005,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a38"
    },
    "Rank": 36011,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a39"
    },
    "Rank": 36017,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District General Hospital,,Central Jail Road, Rajamahendravaram, East Godavari District, Andhra Pradesh, 533105",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a3a"
    },
    "Rank": 36022,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a3b"
    },
    "Rank": 36023,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a3c"
    },
    "Rank": 36026,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a3d"
    },
    "Rank": 36029,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kamineni Hospital ,Boggulkunta King Koti Hyderabad, Telangana, 500001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a3e"
    },
    "Rank": 36031,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a3f"
    },
    "Rank": 36035,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a40"
    },
    "Rank": 36048,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a41"
    },
    "Rank": 36050,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District General Hospital,,Central Jail Road, Rajamahendravaram, East Godavari District, Andhra Pradesh, 533105",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a42"
    },
    "Rank": 36052,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a43"
    },
    "Rank": 36053,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a44"
    },
    "Rank": 36057,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kidwai Memorial Institute of Oncolgy,Kidwai Memorial Institute of Oncolgy, Karnataka, 560029",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a45"
    },
    "Rank": 36059,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a46"
    },
    "Rank": 36064,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "J.L.N. Main Hospital and Research Centre, ,Bhilai Steel Plant Sector-9, Bhilai, C.G., Chhattisgarh, 490009",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a47"
    },
    "Rank": 36067,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a48"
    },
    "Rank": 36069,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saheed Laxman Nayak Medical College and Hospital,Orissa,SAHEED LAXMAN NAYAK MEDICAL COLLEGE AND HOSPITAL KORAPUT, JANIGUDA ODISHA KORAPUT 764020, Odisha, 764020",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a49"
    },
    "Rank": 36070,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences, ,academics@kimshospitals.com, Telangana, 500003",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a4a"
    },
    "Rank": 36074,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INSTITUTE OF MEDICAL SCIENCES,RAICHUR INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 584102",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a4b"
    },
    "Rank": 36077,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pimpri Chichwad Municipal Corporation Postgraduate Institute Y.C.M.Hospital Pimpri,Pune 18,Dean Office, Y.C.M.Hospital, Sant Tukaramnagar Pimpri, Pune, Maharashtra, 411018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a4c"
    },
    "Rank": 36080,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Prasad Netralaya Superspeciality EYe Hospital, Karnataka\n,A J ALSE ROAD\nUDUPI, Karnataka, 576101",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a4d"
    },
    "Rank": 36081,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a4e"
    },
    "Rank": 36084,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a4f"
    },
    "Rank": 36088,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Central Hospital, ,Central Hospital, P.O- Jagjiwan Nagar, Dist- Dhanbad, Pin-826003, Jharkhand, 826003",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a50"
    },
    "Rank": 36102,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sanghamitra Hospitals Private Limited, Andhra Pradesh,D.No -5-82-1, Ambedkar Colony Extension, Near South Bypass junction, Beside Swathi Pipes.  Ongole, Andhra Pradesh, 523002",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a51"
    },
    "Rank": 36106,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a52"
    },
    "Rank": 36122,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a53"
    },
    "Rank": 36127,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Jamshedpur,Mahatma Gandhi Memorial Medical College, Jamshedpur, Jharkhand, 831020",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a54"
    },
    "Rank": 36129,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a55"
    },
    "Rank": 36131,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a56"
    },
    "Rank": 36135,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a57"
    },
    "Rank": 36136,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a58"
    },
    "Rank": 36140,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a59"
    },
    "Rank": 36147,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a5a"
    },
    "Rank": 36156,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a5b"
    },
    "Rank": 36162,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a5c"
    },
    "Rank": 36165,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a5d"
    },
    "Rank": 36166,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a5e"
    },
    "Rank": 36168,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.S. ( Traumatology and Surgery )",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a5f"
    },
    "Rank": 36169,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kabra Eye Hospital, Rajasthan,C-59,60 JAMNA NAGAR, SODALA, JAIPUR, Rajasthan, 302006",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a60"
    },
    "Rank": 36171,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Zen Multispeciality Hospital, Maharashtra,425, 10th Road, Chembur, Mumbai, Maharashtra, 400071",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a61"
    },
    "Rank": 36193,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a62"
    },
    "Rank": 36197,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Super Speciality Hospital, Delhi,Rajiv Gandhi Super Speciality Hospital,Tahirpur, Delhi, Delhi (NCT), 110093",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a63"
    },
    "Rank": 36198,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a64"
    },
    "Rank": 36200,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a65"
    },
    "Rank": 36206,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a66"
    },
    "Rank": 36207,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a67"
    },
    "Rank": 36221,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a68"
    },
    "Rank": 36229,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hiranandani Hospital, Maharashtra\n,Hiranandani Hospital-A Fortis Network Hospital, Plot No 28, Sector 10A, Mini Seashore Road, Vashi, N, Maharashtra, 400703",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a69"
    },
    "Rank": 36231,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a6a"
    },
    "Rank": 36232,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a6b"
    },
    "Rank": 36251,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Amandeep Hospital, Punjab,Dalhousie Road, Mamoon, Pathankot, Punjab, 145001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a6c"
    },
    "Rank": 36259,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a6d"
    },
    "Rank": 36263,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Gleneagles Hospital, ,58 Canal Circular Road Kolkata 700054, West Bengal, 700054",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a6e"
    },
    "Rank": 36267,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chettinad Super Speciality Hospital, Tamil Nadu,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a6f"
    },
    "Rank": 36268,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a70"
    },
    "Rank": 36269,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a71"
    },
    "Rank": 36274,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a72"
    },
    "Rank": 36276,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a73"
    },
    "Rank": 36279,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "The Bangalore Hospital,,#202, R.V. Road, Bangalore, Karnataka, 560004",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a74"
    },
    "Rank": 36283,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a75"
    },
    "Rank": 36290,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a76"
    },
    "Rank": 36299,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a77"
    },
    "Rank": 36302,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Associated Hospital Government Medical College, ,Kanth Bagh Baramulla, Jammu And Kashmir, 193103",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a78"
    },
    "Rank": 36303,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a79"
    },
    "Rank": 36305,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a7a"
    },
    "Rank": 36306,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a7b"
    },
    "Rank": 36316,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a7c"
    },
    "Rank": 36320,
    "Allotted Quota": "All India",
    "Allotted Institute": "Institute of Nuclear Medicine & Allied Sciences,Institute of Nuclear Medicine & Allied Sciences, Delhi (NCT), 110054",
    "Course": "DIP IN RADIATION MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a7d"
    },
    "Rank": 36328,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruby Hall Clinic, ,GRANT MEDICAL FOUNDATION,\n40 SASSOON ROAD, PUNE 411001, Maharashtra, 411001",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a7e"
    },
    "Rank": 36330,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a7f"
    },
    "Rank": 36334,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a80"
    },
    "Rank": 36337,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "DISTRICT HOSPITAL , Madhya Pradesh-456001,csurgeonujjmp@nic.in, Madhya Pradesh, 456001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a81"
    },
    "Rank": 36338,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a82"
    },
    "Rank": 36339,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a83"
    },
    "Rank": 36344,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a84"
    },
    "Rank": 36350,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a85"
    },
    "Rank": 36355,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a86"
    },
    "Rank": 36358,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a87"
    },
    "Rank": 36364,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a88"
    },
    "Rank": 36368,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District General Hospital, ,O/o. Medical Superintendent, Government District General Hospital, Contonment, Vizianagaram, Andhra Pradesh,\n535003",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a89"
    },
    "Rank": 36369,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a8a"
    },
    "Rank": 36371,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Thangam Hospital, Namakkal, Tamil Nadu,No. 54, Dr Sankaran Road, Namakkal, Tamil Nadu, 637001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a8b"
    },
    "Rank": 36383,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES,CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, Chhattisgarh, 495001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a8c"
    },
    "Rank": 36385,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Government Medical College, Vidisha,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA MP, Madhya Pradesh, 464001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a8d"
    },
    "Rank": 36390,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a8e"
    },
    "Rank": 36401,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a8f"
    },
    "Rank": 36406,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a90"
    },
    "Rank": 36415,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a91"
    },
    "Rank": 36416,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sadhu Vaswani Missions ,7-9 Lane No 1 Koregaon Park Pune 411001, Maharashtra, 411001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a92"
    },
    "Rank": 36419,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a93"
    },
    "Rank": 36420,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a94"
    },
    "Rank": 36423,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a95"
    },
    "Rank": 36430,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a96"
    },
    "Rank": 36432,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government Women and Children Hospital, (Formerly Indira Gandhi.\nGeneral Hospital) ,100 feet road, Ellaipillaichavady, Puducherry, 605005",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a97"
    },
    "Rank": 36439,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a98"
    },
    "Rank": 36453,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a99"
    },
    "Rank": 36455,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a9a"
    },
    "Rank": 36470,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a9b"
    },
    "Rank": 36477,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a9c"
    },
    "Rank": 36483,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a9d"
    },
    "Rank": 36485,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "South Eastern Railways Central Hospital, ,CENTRAL HOSPITAL SOUTH EASTERN RAILWAY\n11 GARDEN REACH ROAD\nKOLKATA, West Bengal, 700043",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a9e"
    },
    "Rank": 36486,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06a9f"
    },
    "Rank": 36489,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06aa0"
    },
    "Rank": 36490,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06aa1"
    },
    "Rank": 36491,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Artemis Health Institute, ,Sector 51, Gurugram, Haryana -122001, Haryana, 122001",
    "Course": "(NBEMS) IMMUNO- HAEMATOLOGY AND BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06aa2"
    },
    "Rank": 36498,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06aa3"
    },
    "Rank": 36508,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SNR District Hospital,,SNR DISTRICT HOSPITAL, KOLAR KARNATAKA-563101,\nKarnataka, 563101",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06aa4"
    },
    "Rank": 36517,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06aa5"
    },
    "Rank": 36518,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06aa6"
    },
    "Rank": 36522,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06aa7"
    },
    "Rank": 36527,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Autonomous State Medical college Society and S.N.M District Hospital, ,DAULATTAPUR JALESAR ROAD NARKHI FIROZABAD, Uttar Pradesh, 283203",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06aa8"
    },
    "Rank": 36528,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06aa9"
    },
    "Rank": 36529,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rangadore Memorial Hospital,,1ST CROSS, SHANKARAPURAM, BANGALORE, Karnataka, 560004",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06aaa"
    },
    "Rank": 36541,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06aab"
    },
    "Rank": 36548,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06aac"
    },
    "Rank": 36550,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Anugraha Eye Hospital, ,NAVBHAG MAIN ROAD BEHIND CENTRAL BUS STAND NEAR SECAB COLLEGE VIJAYAPUR 586101, Karnataka, 586101",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06aad"
    },
    "Rank": 36556,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06aae"
    },
    "Rank": 36559,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06aaf"
    },
    "Rank": 36562,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ab0"
    },
    "Rank": 36570,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashvedh Healthcare Services, Andhra Pradesh,Gaigolupadu Junction Kakinada, Andhra Pradesh, 533005",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ab1"
    },
    "Rank": 36574,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ab2"
    },
    "Rank": 36579,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ab3"
    },
    "Rank": 36598,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,SECTOR-6, PANCHKULA, HARYANA-134108, Haryana, 134108",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ab4"
    },
    "Rank": 36617,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ab5"
    },
    "Rank": 36620,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ab6"
    },
    "Rank": 36622,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ab7"
    },
    "Rank": 36635,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ab8"
    },
    "Rank": 36637,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ab9"
    },
    "Rank": 36642,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06aba"
    },
    "Rank": 36647,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS Medical College Valsad,Nanakwada Halar Road,Valsad ,Gujarat, Gujarat, 396001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06abb"
    },
    "Rank": 36664,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06abc"
    },
    "Rank": 36668,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06abd"
    },
    "Rank": 36671,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Associated Hospital Government Medical College, ,Kanth Bagh Baramulla, Jammu And Kashmir, 193103",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06abe"
    },
    "Rank": 36673,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06abf"
    },
    "Rank": 36675,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ac0"
    },
    "Rank": 36678,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ac1"
    },
    "Rank": 36681,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ac2"
    },
    "Rank": 36684,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ac3"
    },
    "Rank": 36689,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saijyothi Eye Institute, Telangana,Plot No. 185, Road No. 1 West Marredpally, Secunderabad, Telangana, 500026",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ac4"
    },
    "Rank": 36691,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ac5"
    },
    "Rank": 36694,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ac6"
    },
    "Rank": 36695,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ac7"
    },
    "Rank": 36703,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ac8"
    },
    "Rank": 36704,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ac9"
    },
    "Rank": 36712,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06aca"
    },
    "Rank": 36713,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,5 D L Roy Road, Krishnanagar, Nadia, West Bengal, 741101",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06acb"
    },
    "Rank": 36719,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06acc"
    },
    "Rank": 36726,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06acd"
    },
    "Rank": 36730,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ace"
    },
    "Rank": 36733,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06acf"
    },
    "Rank": 36746,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ad0"
    },
    "Rank": 36749,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gunam Super Speciality Hospital,No 35, Tank Street, Opp To Govt Hospital, Denkanikottai Road, Hosur, Krishnagiri Dist., Tamil Nadu, 635109",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ad1"
    },
    "Rank": 36751,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ad2"
    },
    "Rank": 36767,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ad3"
    },
    "Rank": 36782,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medica Superspecialty Hospital, ,127 Mukundapur, E.M. Byapss, Kolkata, West Bengal, 700099",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ad4"
    },
    "Rank": 36784,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ad5"
    },
    "Rank": 36786,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ad6"
    },
    "Rank": 36793,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ad7"
    },
    "Rank": 36803,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ad8"
    },
    "Rank": 36813,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ad9"
    },
    "Rank": 36815,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bombay Hospital,Ring Road Indore, Madhya Pradesh, 452010",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ada"
    },
    "Rank": 36823,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Head Quarters Hospital,,District Hospital, Sangareddy Telangana State, Telangana, 502001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06adb"
    },
    "Rank": 36824,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KMC Specialty Hospital, ,No-6, Royal Road, Cantonment, Trichy-620001, Tamil Nadu, 620001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06adc"
    },
    "Rank": 36827,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Eastern Indira Gandhi Regional Institute of Health & Medical Sciences, Shillong,North Eastern Indira Gandhi Regional Institute of Health & Medical Sciences, Shillong, Meghalaya, 793018",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06add"
    },
    "Rank": 36837,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees' State Insurance Corporation Medical College & Hospital, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD GULBARGA, Karnataka, 585106",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ade"
    },
    "Rank": 36838,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06adf"
    },
    "Rank": 36855,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ae0"
    },
    "Rank": 36857,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ae1"
    },
    "Rank": 36873,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kauvery Hospital Medical Services Pvt Ltd,\n,vijay@kauveryhealthcare.com,\nNo 35,Shanthi nagar, Opposite to CSI church,Hosur 635109,Tamil Nadu,",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ae2"
    },
    "Rank": 36891,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ae3"
    },
    "Rank": 36892,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ae4"
    },
    "Rank": 36895,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ae5"
    },
    "Rank": 36897,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ae6"
    },
    "Rank": 36898,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ae7"
    },
    "Rank": 36901,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Autonomous State Medical College and Allied Pandit Ram Prasad Bismil Memorial Hospital,\n,ASMC, JIGNERA, kanth road, shahjahanpur, Uttar Pradesh, 242001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ae8"
    },
    "Rank": 36904,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,HBT Medical College and Dr R N Cooper Municipal General Hospital, Bhaktivedanta Swami Rd, JVPD Schem, Maharashtra, 400056",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ae9"
    },
    "Rank": 36906,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06aea"
    },
    "Rank": 36907,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06aeb"
    },
    "Rank": 36910,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06aec"
    },
    "Rank": 36911,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "College of Medicine and JNM Hospital,,Kalyani, Silpanchal, West Bengal, 741235",
    "Course": "(NBEMS) BIOCHEMISTRY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06aed"
    },
    "Rank": 36917,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06aee"
    },
    "Rank": 36924,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06aef"
    },
    "Rank": 36933,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06af0"
    },
    "Rank": 36948,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajan Eye Care Hospital,,No.5, Vidyodaya second cross street,T.Nagar, Chennai- 600017\nTamil Nadu,\nIndia, Tamil Nadu, 600017",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06af1"
    },
    "Rank": 36949,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06af2"
    },
    "Rank": 36952,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06af3"
    },
    "Rank": 36960,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sankara Nethralaya, (A Unit of Medical Research Foundation) ,147\\1 MUKUNDAPUR E M BYPASS KOLKATA 700099, West Bengal, 700099",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06af4"
    },
    "Rank": 36970,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Panjabrao Alias Bhausaheb Deshmukh Memorial Medical College, ,Shivaji Nagar Near Panchawati Square Amravati, Maharashtra, 444603",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06af5"
    },
    "Rank": 36972,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06af6"
    },
    "Rank": 36976,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06af7"
    },
    "Rank": 36982,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06af8"
    },
    "Rank": 36985,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06af9"
    },
    "Rank": 36992,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan , Rajasthan-311001,Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan, Rajasthan, 311001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06afa"
    },
    "Rank": 36993,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Charak Hospital and Research Center, Uttar Pradesh,Hardoi Road, Dubagga, Lucknow, Uttar Pradesh, 226003",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06afb"
    },
    "Rank": 36995,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Autonomous State Medical college Society and S.N.M District Hospital, ,DAULATTAPUR JALESAR ROAD NARKHI FIROZABAD, Uttar Pradesh, 283203",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06afc"
    },
    "Rank": 36997,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06afd"
    },
    "Rank": 36999,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Chandra Sekara Hospital, Tamil Nadu,M-1, M-2, H-23, Avalapalli Housing Board, Bagalur Road, Hosur - 635109, Krishnagiri Dist, Tamil Nadu, Tamil Nadu, 635109",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06afe"
    },
    "Rank": 37000,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gunam Super Speciality Hospital,No 35, Tank Street, Opp To Govt Hospital, Denkanikottai Road, Hosur, Krishnagiri Dist., Tamil Nadu, 635109",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06aff"
    },
    "Rank": 37001,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Artemis Health Institute, ,Sector 51, Gurugram, Haryana -122001, Haryana, 122001",
    "Course": "(NBEMS) IMMUNO- HAEMATOLOGY AND BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b00"
    },
    "Rank": 37002,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b01"
    },
    "Rank": 37003,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Syed Ammal Trust Hospital, Tamil Nadu,DR E M ABDULLAH CAMPUS 127 SWAMY VIVEKANANDAR SALAI\nRAMANATHAPURAM, Tamil Nadu, 623501",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b02"
    },
    "Rank": 37010,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,OFFICE OF SUPERINTENDENT 9TH FLOOR SSH PO JALPAIGURI,\nWest Bengal, 735101",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b03"
    },
    "Rank": 37011,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b04"
    },
    "Rank": 37018,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b05"
    },
    "Rank": 37023,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b06"
    },
    "Rank": 37028,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b07"
    },
    "Rank": 37034,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Doon Medical College, Dehradun,Govt. Doon Medical College Dehrakhas, Patel Nagar Dehradun 248001, Uttarakhand, 248001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b08"
    },
    "Rank": 37042,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b09"
    },
    "Rank": 37044,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b0a"
    },
    "Rank": 37047,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b0b"
    },
    "Rank": 37048,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b0c"
    },
    "Rank": 37049,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b0d"
    },
    "Rank": 37050,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SETH V. C. GANDHI and M. A. VORA MUNICIPAL GENERAL HOSPITAL (RAJAWADI HOSPITAL),\nMaharashtra,hospital.rajawadi@gmail.com, Maharashtra, 400077",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b0e"
    },
    "Rank": 37057,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b0f"
    },
    "Rank": 37066,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vijay Marie Hospital and Educational Society, ,10-1-673 1 Veernagar Khairatabad, Telangana, 500004",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b10"
    },
    "Rank": 37074,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b11"
    },
    "Rank": 37077,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Amandeep Hospital, Punjab,Dalhousie Road, Mamoon, Pathankot, Punjab, 145001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b12"
    },
    "Rank": 37082,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b13"
    },
    "Rank": 37090,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b14"
    },
    "Rank": 37104,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "APL and ANR Area, Andhra Pradesh,Area Hospital, Gudiwada\nOPP Head Post office Road, Fire station road, Gudiwada, Andhra Pradesh, 521301",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b15"
    },
    "Rank": 37121,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b16"
    },
    "Rank": 37135,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b17"
    },
    "Rank": 37139,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shrikrishna Medical College & Hospital, Muzaffarpur,Shrikrishna Medical College & Hospital, Muzaffarpur, Bihar, 842004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b18"
    },
    "Rank": 37144,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b19"
    },
    "Rank": 37146,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B L Kapur Memorial Hospital,Pusa Road New Delhi, Delhi (NCT), 110005",
    "Course": "(NBEMS) IMMUNO- HAEMATOLOGY AND BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b1a"
    },
    "Rank": 37166,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Balaji Action Medical Institute,,FC34 A4 PASCHIM VIHAR NEW DELHI, Delhi (NCT), 110063",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b1b"
    },
    "Rank": 37176,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b1c"
    },
    "Rank": 37181,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Civil Hospital, ,Maharaja Agrasen District Civil Hospital Hisar, Haryana, 125001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b1d"
    },
    "Rank": 37191,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b1e"
    },
    "Rank": 37197,
    "Allotted Quota": "All India",
    "Allotted Institute": "Acharya Harihar Post Graduate Institute of Cancer, Cuttack,Cuttack Odisha, Odisha, 753007",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b1f"
    },
    "Rank": 37201,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b20"
    },
    "Rank": 37207,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. R N Cooper Municipal General Hospital, (Associated with H B T (Hinduhridayasamrat Balasaheb Thackeray) medical College) ,HBTMC AND DR.R.N.COOPER HOSPITAL\nU 15 BHAKTI VEDANTA ROAD JVPD SCHEME VILE PARLE WEST MUMBAI,\nMaharashtra, 400056",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b21"
    },
    "Rank": 37208,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b22"
    },
    "Rank": 37212,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Vinoba Bhave Civil Hospital,,S R Delkar Marg, Saily Road, Silvassa, Dadra And Nagar Haveli, 396230",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b23"
    },
    "Rank": 37216,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b24"
    },
    "Rank": 37218,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b25"
    },
    "Rank": 37219,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b26"
    },
    "Rank": 37229,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b27"
    },
    "Rank": 37233,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster Prime Hospital,\n(A Unit of Sri Sainatha Multi Specialty Hospital), Telangana,plot no 2 and 4, satyam theatre road, behind mythrivanam building, ameerpet, hyderabad,telangana, Telangana, 500038",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b28"
    },
    "Rank": 37249,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b29"
    },
    "Rank": 37258,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b2a"
    },
    "Rank": 37261,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b2b"
    },
    "Rank": 37274,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Second Sight Southend Eye Centre Pvt. Ltd., West Bengal,Baruipur Amtola Road. Dhopagachi, Kundarali, PS.Baruipur, Westbengal, Pin - 743610, West Bengal, 743610",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b2c"
    },
    "Rank": 37280,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b2d"
    },
    "Rank": 37284,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rabindranath Tagore International Institute of Cardiac Sciences, ,PREMISES NO. 1489 124 MUKUNDAPUR  E M BYPASS  KOLKATA-700099, West Bengal, 700099",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b2e"
    },
    "Rank": 37288,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deben Mahato (Sadar) Hospital,,Main Road Near SP office PO Purulia Main PS Purulia Town Dist Purulia West Bengal, West Bengal, 723101",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b2f"
    },
    "Rank": 37296,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b30"
    },
    "Rank": 37297,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b31"
    },
    "Rank": 37305,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b32"
    },
    "Rank": 37307,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Arambagh Sub Divisional/ Super Speciality Hospital, ,Arambagh, Hooghly , Pin - 712601, West Bengal, 712601",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b33"
    },
    "Rank": 37310,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District General Hospital, ,O/o. Medical Superintendent, Government District General Hospital, Contonment, Vizianagaram, Andhra Pradesh,\n535003",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b34"
    },
    "Rank": 37313,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b35"
    },
    "Rank": 37322,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b36"
    },
    "Rank": 37323,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b37"
    },
    "Rank": 37327,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b38"
    },
    "Rank": 37330,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b39"
    },
    "Rank": 37333,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. B L Kapur Memorial Hospital,Pusa Road New Delhi, Delhi (NCT), 110005",
    "Course": "(NBEMS) IMMUNO- HAEMATOLOGY AND BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b3a"
    },
    "Rank": 37371,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b3b"
    },
    "Rank": 37385,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b3c"
    },
    "Rank": 37389,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b3d"
    },
    "Rank": 37395,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b3e"
    },
    "Rank": 37397,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SANKARA NETRA CHIKITSALAYA, Andhra Pradesh,SANKARA NETRA CHIKITSALAYA, 59-14-1/2, NIRMALA CONVENT CIRCLE, BHARATHI SQUARE BUILDING, VIJAYAWADA, Andhra Pradesh, 520008",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b3f"
    },
    "Rank": 37400,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "TIBRA EYE HOSPITAL AND RETINA CENTRE,\nRajasthan,W-28, SANTOSHI MATA MANDIR KE PAS, RANI SATI ROAD, SIKAR,\nRajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b40"
    },
    "Rank": 37405,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vijaya Hospital, ,New No 434, Old No 180, NSK Salai, Vadapalani, Chennai - 600026, Tamil Nadu, 600026",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b41"
    },
    "Rank": 37417,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b42"
    },
    "Rank": 37422,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bombay Hospital,Ring Road Indore, Madhya Pradesh, 452010",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b43"
    },
    "Rank": 37425,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMR Varalakshmi Care Hospital, Andhra Pradesh,GMR Varalakshmi CARE Hospital GMR Nagar ,\nRajam,\nVizianagaram District, Andhra Pradesh, 532127",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b44"
    },
    "Rank": 37436,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b45"
    },
    "Rank": 37457,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b46"
    },
    "Rank": 37463,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b47"
    },
    "Rank": 37464,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b48"
    },
    "Rank": 37468,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b49"
    },
    "Rank": 37471,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b4a"
    },
    "Rank": 37473,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b4b"
    },
    "Rank": 37474,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b4c"
    },
    "Rank": 37476,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b4d"
    },
    "Rank": 37487,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b4e"
    },
    "Rank": 37490,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b4f"
    },
    "Rank": 37491,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b50"
    },
    "Rank": 37492,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b51"
    },
    "Rank": 37496,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b52"
    },
    "Rank": 37508,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b53"
    },
    "Rank": 37520,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Chandra Sekara Hospital, Tamil Nadu,M-1, M-2, H-23, Avalapalli Housing Board, Bagalur Road, Hosur - 635109, Krishnagiri Dist, Tamil Nadu, Tamil Nadu, 635109",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b54"
    },
    "Rank": 37526,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b55"
    },
    "Rank": 37527,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b56"
    },
    "Rank": 37529,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b57"
    },
    "Rank": 37544,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "JBMM District Hospital , ,JBMM DISTRICT HOSPITAL RAM BAGH PHATAK NANDAN CINEMA\nCHOWK AMRITSAR AMRITSAR AMRITSAR IN\n143001, Punjab, 143001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b58"
    },
    "Rank": 37550,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b59"
    },
    "Rank": 37556,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b5a"
    },
    "Rank": 37558,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM Health Care Private Limited, Tamil Nadu,New No 72, Old No 54, Nelson Manickam Road, Aminjikarai, Chennai – 600029 Tamilnadu State, Tamil Nadu, 600029",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b5b"
    },
    "Rank": 37560,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b5c"
    },
    "Rank": 37562,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b5d"
    },
    "Rank": 37571,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Santokbha Durlabhji Memorial Hospital Cum Medical Research Institute,\n,dnb.sdmh@gmail.com, Rajasthan, 302015",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b5e"
    },
    "Rank": 37581,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Eastern Indira Gandhi Regional Institute of Health & Medical Sciences, Shillong,North Eastern Indira Gandhi Regional Institute of Health & Medical Sciences, Shillong, Meghalaya, 793018",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b5f"
    },
    "Rank": 37586,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indraprastha Apollo Hospital,,Sarita Vihar, New Delhi 110 076, Delhi (NCT), 110076",
    "Course": "(NBEMS) IMMUNO- HAEMATOLOGY AND BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b60"
    },
    "Rank": 37598,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Khan Bahadur Bhabha Hospital, Maharashtra,Khan Bahadur Bhabha Hospital Belgrami road kurla west, Maharashtra, 400070",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b61"
    },
    "Rank": 37606,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b62"
    },
    "Rank": 37611,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b63"
    },
    "Rank": 37616,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b64"
    },
    "Rank": 37617,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b65"
    },
    "Rank": 37619,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K C General Hospital, ,No. 89, 5th Cross, Malleshwaram, Bangalore, Karnataka, 560003",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b66"
    },
    "Rank": 37623,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b67"
    },
    "Rank": 37628,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Amandeep Medicity, Mall Road, Amritsar, Punjab,Mall Rd, near Novelty Chownk, Maharaja Ranjit Singh Nagar, Joshi Colony, Amritsar, Punjab 143001, Punjab, 143001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b68"
    },
    "Rank": 37640,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b69"
    },
    "Rank": 37643,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b6a"
    },
    "Rank": 37644,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b6b"
    },
    "Rank": 37658,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "DISTRICT HOSPITAL ,\nChhattisgarh,Room No. 122 - Government District Hospital  Near Mandi Gate Pandari, Raipur, Chhattisgarh, 492001, , Chhattisgarh, 492001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b6c"
    },
    "Rank": 37662,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b6d"
    },
    "Rank": 37667,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b6e"
    },
    "Rank": 37668,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b6f"
    },
    "Rank": 37678,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b70"
    },
    "Rank": 37694,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Head Quarters Hospital,,District Hospital, Sangareddy Telangana State, Telangana, 502001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b71"
    },
    "Rank": 37697,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital , ,GMERS MEDICAL COLLEGE , Hadiyol-Gadhoda Road, Near-Government Polytechnic, Hiimmatnagar, Gujarat, 383001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b72"
    },
    "Rank": 37698,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b73"
    },
    "Rank": 37700,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b74"
    },
    "Rank": 37706,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b75"
    },
    "Rank": 37713,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jehangir Hospital, ,32 Sasoon Road Pune, Maharashtra, 411001",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b76"
    },
    "Rank": 37717,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College,Fakhruddin Ali Ahmed Medical College, Assam, 781301",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b77"
    },
    "Rank": 37718,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Santokbha Durlabhji Memorial Hospital Cum Medical Research Institute,\n,dnb.sdmh@gmail.com, Rajasthan, 302015",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b78"
    },
    "Rank": 37720,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Trustwell Hospitals Pvt. Ltd, ,NO 5 Chandrika Tower JC Road\nBangalore, Karnataka, 560002",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b79"
    },
    "Rank": 37722,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b7a"
    },
    "Rank": 37734,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b7b"
    },
    "Rank": 37743,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b7c"
    },
    "Rank": 37744,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Anand Rishiji Hospital and Medical Research Centre, ,124, Anandrishiji Marg, Ahmednagar, Maharashtra, 414001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b7d"
    },
    "Rank": 37753,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b7e"
    },
    "Rank": 37756,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b7f"
    },
    "Rank": 37764,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. District Civil Hospital, ,DIS GOVT HOSPITAL, SRI GANGANAGAR, Rajasthan, 335001",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b80"
    },
    "Rank": 37766,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b81"
    },
    "Rank": 37767,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b82"
    },
    "Rank": 37768,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b83"
    },
    "Rank": 37784,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K B Bhabha Municipal General Hospital, ,R K PATKAR ROAD ,BANDRA WEST\n,MUMBAI, Maharashtra, 400050",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b84"
    },
    "Rank": 37786,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b85"
    },
    "Rank": 37791,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Susrut Eye Foundation, West Bengal,HB 36 A 1 Salt Lake City Sector 3 Kolkata 700106, West Bengal, 700106",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b86"
    },
    "Rank": 37792,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b87"
    },
    "Rank": 37793,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b88"
    },
    "Rank": 37798,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dhir Hospital,\n,DHIR HOSPITAL MEHAM GATE BHIWANI HARYANA 127021, Haryana, 127021",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b89"
    },
    "Rank": 37803,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b8a"
    },
    "Rank": 37810,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b8b"
    },
    "Rank": 37819,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Life line Super Specialty Hospital, ,14th Mile, Melood P.O Adoor , Pathanamthitta Dist, Kerala, 691554",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b8c"
    },
    "Rank": 37831,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b8d"
    },
    "Rank": 37848,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b8e"
    },
    "Rank": 37851,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b8f"
    },
    "Rank": 37853,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaikh-Ul-Hind Maulana Mahmood Hasan Medical College, Saharanpur,Pilkhani Ambala Road, Uttar Pradesh, 247232",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b90"
    },
    "Rank": 37862,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College, ,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "(NBEMS) IMMUNO- HAEMATOLOGY AND BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b91"
    },
    "Rank": 37871,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b92"
    },
    "Rank": 37885,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b93"
    },
    "Rank": 37891,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Suhel Dev Medical College and Mahrishi Balark Hospital, Uttar Pradesh,MSD AS Medical College KDC Road Bahraich, Uttar Pradesh, 271801",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b94"
    },
    "Rank": 37904,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b95"
    },
    "Rank": 37905,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b96"
    },
    "Rank": 37919,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b97"
    },
    "Rank": 37925,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b98"
    },
    "Rank": 37926,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "DIP.IN GYNAE. and OBST.",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b99"
    },
    "Rank": 37933,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b9a"
    },
    "Rank": 37939,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b9b"
    },
    "Rank": 37956,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital,,NO.5 RAILWAY ROAD, KANCHIPURAM., Tamil Nadu, 631501",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b9c"
    },
    "Rank": 37957,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b9d"
    },
    "Rank": 37963,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindu Rao Hospital, ,SUBZI MANDI, MALKA GANJ, DELHI-110007, Delhi (NCT), 110007",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b9e"
    },
    "Rank": 37972,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06b9f"
    },
    "Rank": 37975,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ba0"
    },
    "Rank": 37986,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ba1"
    },
    "Rank": 37993,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vijaya Hospital, ,New No 434, Old No 180, NSK Salai, Vadapalani, Chennai - 600026, Tamil Nadu, 600026",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ba2"
    },
    "Rank": 37994,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ba3"
    },
    "Rank": 37999,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tata Main Hospital, ,C Road, Bistupur, Jamshedpur, Jharkhand, 831001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ba4"
    },
    "Rank": 38019,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Choudhury Eye Hospital And Research Centre, Assam,Shyamaprasad Road, Silchar-1, Assam, 788001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ba5"
    },
    "Rank": 38026,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ba6"
    },
    "Rank": 38031,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Cottage Hospital , Gujarat,Cottage hospital station road Upleta, Gujarat, 360490",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ba7"
    },
    "Rank": 38035,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital, Near KEB, PB Road, Haveri, Karnataka, 581110",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ba8"
    },
    "Rank": 38041,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine and JNM Hospital, WBUHS,Block A, Post Office Kalyani, Near Silpanchal Station,\nBlock A2, District Nadia, West Bengal, West Bengal, 741235",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ba9"
    },
    "Rank": 38049,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aster Prime Hospital,\n(A Unit of Sri Sainatha Multi Specialty Hospital), Telangana,plot no 2 and 4, satyam theatre road, behind mythrivanam building, ameerpet, hyderabad,telangana, Telangana, 500038",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06baa"
    },
    "Rank": 38051,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bab"
    },
    "Rank": 38066,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bac"
    },
    "Rank": 38068,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ramkrishna Care Hospital, ,AUROBINDO ENCLAVE, PACHPEDI NAKA,DAHAMTARI ROAD, RAIPUR, Chhattisgarh, 492001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bad"
    },
    "Rank": 38071,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bae"
    },
    "Rank": 38077,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06baf"
    },
    "Rank": 38078,
    "Allotted Quota": "All India",
    "Allotted Institute": "GADAG INSTITUTE OF MEDICAL SCIENCES GADAG,Gadag Institute of Medical Sciences Mallasamudra Gadag-582103, Karnataka, 582103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bb0"
    },
    "Rank": 38079,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bb1"
    },
    "Rank": 38080,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bb2"
    },
    "Rank": 38083,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bb3"
    },
    "Rank": 38092,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (Hospital Administration)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bb4"
    },
    "Rank": 38093,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bb5"
    },
    "Rank": 38095,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bb6"
    },
    "Rank": 38096,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bb7"
    },
    "Rank": 38104,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Employee State Insurance Corporation Model Hospital,,Bharat Nagar, Ludhiana, Punjab, 141001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bb8"
    },
    "Rank": 38117,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Balaji Action Medical Institute,,FC34 A4 PASCHIM VIHAR NEW DELHI, Delhi (NCT), 110063",
    "Course": "(NBEMS) IMMUNO- HAEMATOLOGY AND BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bb9"
    },
    "Rank": 38120,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bba"
    },
    "Rank": 38126,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S. G. L. Super Specialty Charitable Hospital, ,GARHA ROAD, JALANDHAR, Punjab, 144022",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bbb"
    },
    "Rank": 38132,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bbc"
    },
    "Rank": 38137,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bbd"
    },
    "Rank": 38143,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bbe"
    },
    "Rank": 38162,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,Plotno-251,Sainik school Road,Unit-15 Apollo Hospitals , Bhubaneswar, Odisha, 751005",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bbf"
    },
    "Rank": 38168,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital, ,Kharvad medan Sipor road Vadnagar, Gujarat, 384355",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bc0"
    },
    "Rank": 38173,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deen Dayal Upadhyay Hospital, ,DDU HOSPITAL, SHAHEED MANGAL PANDEY MARG, NANAK PURA, HARI NAGAR, NEW DELHI, DELHI-110064, Delhi (NCT), 110064",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bc1"
    },
    "Rank": 38175,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bc2"
    },
    "Rank": 38184,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bc3"
    },
    "Rank": 38193,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sadhu Vaswani Missions ,7-9 Lane No 1 Koregaon Park Pune 411001, Maharashtra, 411001",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bc4"
    },
    "Rank": 38195,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Super Facility Hospital, Distt.-Azamgarh, Uttar Pradesh,Chakrapanpur, Kanaila, Azamgarh, Uttar Pradesh, 276128",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bc5"
    },
    "Rank": 38200,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bc6"
    },
    "Rank": 38212,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bc7"
    },
    "Rank": 38213,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bc8"
    },
    "Rank": 38232,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bc9"
    },
    "Rank": 38235,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bca"
    },
    "Rank": 38237,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhima Bhoi Medical College and Hospital, Balangir,At-Laltikra Po-Rajendra College, Balangir, Odisha, 767002",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bcb"
    },
    "Rank": 38239,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI MEDICAL COLLEGE KALWA THANE,RAJIV GANDHI MEDICAL COLLEGE,THANE BELAPUR ROAD, KALWA, THANE, Maharashtra, 400605",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bcc"
    },
    "Rank": 38245,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Hospital, ,principal.mc.barmer@rajasthan.gov.in, Rajasthan, 344001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bcd"
    },
    "Rank": 38255,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shaheed Hasan Khan Mewati Government Medical College , ,Shaheed Hasan Khan Mewati Government Medical College Nalhar Nuh, Haryana, 122107",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bce"
    },
    "Rank": 38258,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bcf"
    },
    "Rank": 38260,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bd0"
    },
    "Rank": 38264,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bd1"
    },
    "Rank": 38267,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bd2"
    },
    "Rank": 38270,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. Sports Medicine",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bd3"
    },
    "Rank": 38275,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bd4"
    },
    "Rank": 38289,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Muthoot Health Care, ,Muthoot Healthcare, College Road, Kozhencherry, Pathanamthitta District, Kerala, 689641",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bd5"
    },
    "Rank": 38292,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.R.A. Medical College,\n,Saddarpur Akabarpur Tanda Road Post Surapur\nDistrict Ambedkarnagar, Uttar Pradesh, 224227",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bd6"
    },
    "Rank": 38293,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bd7"
    },
    "Rank": 38298,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bd8"
    },
    "Rank": 38302,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bd9"
    },
    "Rank": 38315,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dharwad,dsdharwad at gmail dot com, Karnataka, 580001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bda"
    },
    "Rank": 38322,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sahyadri Narayana Multispecialty Hospital, Harakere,,NT ROAD, HARAKERE , SHIMOGA, Karnataka, 577202",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bdb"
    },
    "Rank": 38329,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bdc"
    },
    "Rank": 38336,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bdd"
    },
    "Rank": 38337,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asian Institute of Medical Sciences,,p -72,Milapnagar,MIDC,Dombivli E, Maharashtra, 421203",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bde"
    },
    "Rank": 38338,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pandurang Ramarao Dongaonkar District Hospital, Chhattisgarh,Pandurang Ramarao Dongaonkar\nDistrict Hospital Durg G E Road District Durg Chhattisgarh 491001, Chhattisgarh, 491001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bdf"
    },
    "Rank": 38343,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES ADILABAD -504001 TELANGANA STATE, Telangana, 504001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06be0"
    },
    "Rank": 38352,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06be1"
    },
    "Rank": 38353,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06be2"
    },
    "Rank": 38362,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06be3"
    },
    "Rank": 38363,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06be4"
    },
    "Rank": 38368,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "DIPLOMA IN ORTHOPAEDICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06be5"
    },
    "Rank": 38370,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06be6"
    },
    "Rank": 38372,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06be7"
    },
    "Rank": 38377,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06be8"
    },
    "Rank": 38381,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06be9"
    },
    "Rank": 38387,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S. G. L. Super Specialty Charitable Hospital, ,GARHA ROAD, JALANDHAR, Punjab, 144022",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bea"
    },
    "Rank": 38393,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06beb"
    },
    "Rank": 38394,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bec"
    },
    "Rank": 38398,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bed"
    },
    "Rank": 38400,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bee"
    },
    "Rank": 38411,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bef"
    },
    "Rank": 38424,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollomedics Superspeciality Hospital,KBC 31, Sector B, LDA Colony, Kanpur Road, Lucknow, Uttar Pradesh, 226012",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bf0"
    },
    "Rank": 38430,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bf1"
    },
    "Rank": 38432,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bf2"
    },
    "Rank": 38434,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,4th T Block, Beside Rajiv Gandhi University of Health Sciences Jayanagar,, Karnataka, 560041",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bf3"
    },
    "Rank": 38440,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bf4"
    },
    "Rank": 38444,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bf5"
    },
    "Rank": 38446,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bf6"
    },
    "Rank": 38451,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bf7"
    },
    "Rank": 38455,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bf8"
    },
    "Rank": 38484,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bf9"
    },
    "Rank": 38485,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bfa"
    },
    "Rank": 38498,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bfb"
    },
    "Rank": 38505,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bfc"
    },
    "Rank": 38515,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESIC Model Hospital, ,A-3, TULSI MARG, SECTOR-24, NOIDA, Uttar Pradesh, 201301",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bfd"
    },
    "Rank": 38518,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bfe"
    },
    "Rank": 38527,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06bff"
    },
    "Rank": 38531,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c00"
    },
    "Rank": 38535,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c01"
    },
    "Rank": 38537,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c02"
    },
    "Rank": 38550,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c03"
    },
    "Rank": 38553,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ozone Multispeciality hospital and Critical care center, Maharashtra,Holycross convent Road, Kedia Plots, Akola, Maharashtra, 444005",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c04"
    },
    "Rank": 38555,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c05"
    },
    "Rank": 38562,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c06"
    },
    "Rank": 38563,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c07"
    },
    "Rank": 38570,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c08"
    },
    "Rank": 38579,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESIC Model Hospital, ,ms-bapunagar.gj@esic.nic.in, Gujarat, 380024",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c09"
    },
    "Rank": 38584,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c0a"
    },
    "Rank": 38585,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c0b"
    },
    "Rank": 38592,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Felix Hospital,\n,NH-01 SECTOR 137 NOIDA EXPRESSWAY UP, Uttar Pradesh, 201305",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c0c"
    },
    "Rank": 38601,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c0d"
    },
    "Rank": 38603,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c0e"
    },
    "Rank": 38605,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c0f"
    },
    "Rank": 38610,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Venkateshwar Hospital, ,Sector- 18 A, Dwarka, New Delhi, Delhi (NCT), 110075",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c10"
    },
    "Rank": 38614,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c11"
    },
    "Rank": 38622,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "DIP.IN GYNAE. and OBST.",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c12"
    },
    "Rank": 38630,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c13"
    },
    "Rank": 38636,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c14"
    },
    "Rank": 38641,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c15"
    },
    "Rank": 38642,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Zen Multispeciality Hospital, Maharashtra,425, 10th Road, Chembur, Mumbai, Maharashtra, 400071",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c16"
    },
    "Rank": 38660,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hiranandani Hospital, Maharashtra\n,Hiranandani Hospital-A Fortis Network Hospital, Plot No 28, Sector 10A, Mini Seashore Road, Vashi, N, Maharashtra, 400703",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c17"
    },
    "Rank": 38663,
    "Allotted Quota": "All India",
    "Allotted Institute": "GADAG INSTITUTE OF MEDICAL SCIENCES GADAG,Gadag Institute of Medical Sciences Mallasamudra Gadag-582103, Karnataka, 582103",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c18"
    },
    "Rank": 38666,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c19"
    },
    "Rank": 38670,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c1a"
    },
    "Rank": 38673,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lord Mahavir Civil Hospital,,Lord Mahavir Civil Hospital, MCH Wing Fieldganj Road Ludhiana, Punjab, 141008",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c1b"
    },
    "Rank": 38676,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c1c"
    },
    "Rank": 38678,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c1d"
    },
    "Rank": 38682,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c1e"
    },
    "Rank": 38693,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c1f"
    },
    "Rank": 38695,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c20"
    },
    "Rank": 38704,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c21"
    },
    "Rank": 38713,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c22"
    },
    "Rank": 38721,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Hurkisondas Nurrotumdas Hospital and Research Centre, ,Prarthana Samaj, Raja Ram Mohan Roy road,\nGirgaon, MUMBAI, Maharashtra, 400004",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c23"
    },
    "Rank": 38738,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c24"
    },
    "Rank": 38746,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asansol District Hospital, ,S. B. GORAI ROAD, P.O.- ASANSOL, DIST.- PASCHIM BARDHAMAN, West Bengal, 713301",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c25"
    },
    "Rank": 38760,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c26"
    },
    "Rank": 38763,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c27"
    },
    "Rank": 38771,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c28"
    },
    "Rank": 38772,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortis Hospitals Limited Fortis Escorts Hospital, Punjab,Majitha Verka Bypass Road Amritsar, Punjab, 143004",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c29"
    },
    "Rank": 38783,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Prasad Netralaya Superspeciality EYe Hospital, Karnataka\n,A J ALSE ROAD\nUDUPI, Karnataka, 576101",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c2a"
    },
    "Rank": 38809,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rani Durgavati Medical College, Banda ,Rani Durgavati Medical College Naraini Road Banda, Uttar Pradesh, 210001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c2b"
    },
    "Rank": 38813,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c2c"
    },
    "Rank": 38818,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c2d"
    },
    "Rank": 38821,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Paras HMRI Hospital, ,Paras HMRI Hospital\nNH 30 Bailey Road Raja Bazaar Patna 800014 Bihar, Bihar, 800014",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c2e"
    },
    "Rank": 38830,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c2f"
    },
    "Rank": 38835,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c30"
    },
    "Rank": 38853,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jagjivan Ram Railway Hospital,,Maratha Mandir Marg, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c31"
    },
    "Rank": 38854,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bansal Hospital,,C- Sector Shahpura, Bhopal, Madhya Pradesh, 462016",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c32"
    },
    "Rank": 38862,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c33"
    },
    "Rank": 38866,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c34"
    },
    "Rank": 38868,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c35"
    },
    "Rank": 38875,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c36"
    },
    "Rank": 38876,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c37"
    },
    "Rank": 38877,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c38"
    },
    "Rank": 38881,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c39"
    },
    "Rank": 38896,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c3a"
    },
    "Rank": 38909,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhavsinhji and Maharani Rupaliba General Hospital, ,Near S. T. Bus Stand, Opp.\nBhutnath Temple, Hospital Road, Porbandar, Gujarat, 360575",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c3b"
    },
    "Rank": 38914,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c3c"
    },
    "Rank": 38917,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c3d"
    },
    "Rank": 38928,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c3e"
    },
    "Rank": 38950,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jaypee Hospital,,sector 128, Noida, Uttar Pradesh, 201304",
    "Course": "(NBEMS) IMMUNO- HAEMATOLOGY AND BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c3f"
    },
    "Rank": 38954,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c40"
    },
    "Rank": 38961,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Suri Sadar Hospital, ,Laldighipara Suri Birbhum, West Bengal, 731101",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c41"
    },
    "Rank": 38968,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c42"
    },
    "Rank": 38975,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c43"
    },
    "Rank": 38985,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Hospital, Omandurar,GOVERNMENT MEDICAL COLLEGE, OMANDURAR GOVERNMENT ESTATE, WALAJAH ROAD, NEAR KALAIVANAR ARANGAM, CHEN, Tamil Nadu, 600002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c44"
    },
    "Rank": 38987,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c45"
    },
    "Rank": 38992,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c46"
    },
    "Rank": 39004,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c47"
    },
    "Rank": 39005,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c48"
    },
    "Rank": 39008,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c49"
    },
    "Rank": 39011,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Guru Gobind Singh Govt. Hospital, Delhi,F-Block, Raghubir Nagar, New Delhi, Delhi (NCT), 110027",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c4a"
    },
    "Rank": 39014,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KAUVERY HOSPITAL, Karnataka,no.92, 1 A B, Konappana Agrahara, Electronic City, Bangalore, Karnataka, 560100",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c4b"
    },
    "Rank": 39022,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c4c"
    },
    "Rank": 39040,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c4d"
    },
    "Rank": 39049,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c4e"
    },
    "Rank": 39054,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c4f"
    },
    "Rank": 39056,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Multispecialty Hospital, ,Narayana Multispecialty Hospital, Jaipur Sec-28,Kumbha Marg, Pratap Nagar Sanganer 302033",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c50"
    },
    "Rank": 39058,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c51"
    },
    "Rank": 39062,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c52"
    },
    "Rank": 39070,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c53"
    },
    "Rank": 39077,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c54"
    },
    "Rank": 39089,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sahyadri Narayana Multispecialty Hospital, Harakere,,NT ROAD, HARAKERE , SHIMOGA, Karnataka, 577202",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c55"
    },
    "Rank": 39105,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c56"
    },
    "Rank": 39109,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c57"
    },
    "Rank": 39110,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c58"
    },
    "Rank": 39123,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c59"
    },
    "Rank": 39137,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c5a"
    },
    "Rank": 39138,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c5b"
    },
    "Rank": 39159,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,Opposite to Shivajinagar Bus stop, Shivajinagar, Bengaluru., Karnataka, 560001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c5c"
    },
    "Rank": 39166,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c5d"
    },
    "Rank": 39176,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vishnuprasad Nandrai Desai Municipal General Hospital, Maharashtra,11 TH GOLIBAR ROAD TPS,SANTACRUZ EAST, Maharashtra, 400055",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c5e"
    },
    "Rank": 39185,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c5f"
    },
    "Rank": 39195,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c60"
    },
    "Rank": 39200,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c61"
    },
    "Rank": 39201,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c62"
    },
    "Rank": 39218,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c63"
    },
    "Rank": 39224,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c64"
    },
    "Rank": 39226,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c65"
    },
    "Rank": 39227,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c66"
    },
    "Rank": 39244,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajkiya Medical College, Jalaun,kalpi Road orai Jalaun, Uttar Pradesh, 285001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c67"
    },
    "Rank": 39247,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c68"
    },
    "Rank": 39251,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c69"
    },
    "Rank": 39253,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asian Institute of Medical Sciences,,p -72,Milapnagar,MIDC,Dombivli E, Maharashtra, 421203",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c6a"
    },
    "Rank": 39261,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c6b"
    },
    "Rank": 39262,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c6c"
    },
    "Rank": 39264,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c6d"
    },
    "Rank": 39272,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c6e"
    },
    "Rank": 39273,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c6f"
    },
    "Rank": 39276,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c70"
    },
    "Rank": 39277,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lal Bahadur Shastri Hosptial, ,LBSH MAYUR VIHAR PHASE 2 KHICHRIPUR 110091, Delhi (NCT), 110091",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c71"
    },
    "Rank": 39280,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c72"
    },
    "Rank": 39287,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c73"
    },
    "Rank": 39289,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c74"
    },
    "Rank": 39290,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan , Rajasthan-311001,Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan, Rajasthan, 311001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c75"
    },
    "Rank": 39294,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c76"
    },
    "Rank": 39295,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c77"
    },
    "Rank": 39310,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c78"
    },
    "Rank": 39313,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Central Hospital, ,SOUTH EAST CENTRAL RAILWAY, OPPOSITE GOLF GROUND,\nBILASPUR, Chhattisgarh, 495004",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c79"
    },
    "Rank": 39321,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c7a"
    },
    "Rank": 39333,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c7b"
    },
    "Rank": 39337,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vivekananda Polyclinic and Institute of Medical Sciences, ,Vivekananda Polyclinic and Institute of Medical Sciences, Vivekananda Puram, Lucknow, Uttar Pradesh, 226007",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c7c"
    },
    "Rank": 39340,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KAUVERY HOSPITAL, Karnataka,no.92, 1 A B, Konappana Agrahara, Electronic City, Bangalore, Karnataka, 560100",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c7d"
    },
    "Rank": 39349,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS COLLEGE OF MEDICAL SCIENCES,RUHS COLLEGE OF MEDICAL\nSCIENCES, Rajasthan, 302033",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c7e"
    },
    "Rank": 39351,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ashwani Sahakari Rugnalaya,\n,Survey No 7107/1 Tank plot No 180,North Sadar Bazar Solapur, Maharashtra, 413003",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c7f"
    },
    "Rank": 39359,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c80"
    },
    "Rank": 39364,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c81"
    },
    "Rank": 39365,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Delhi Hospital And Nursing Home, Haryana,METRO PILLAR NO. 812 NEAR SHYAMJI COMPLEX BAHADURGARH DIST JHAJJAR-124507, Haryana, 124507",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c82"
    },
    "Rank": 39371,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c83"
    },
    "Rank": 39373,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c84"
    },
    "Rank": 39378,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c85"
    },
    "Rank": 39379,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c86"
    },
    "Rank": 39385,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c87"
    },
    "Rank": 39387,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c88"
    },
    "Rank": 39396,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharishi Vashistha Autonomous State Medical College and Opec Hospital,\n,Rampur Tahsil Sadar Basti Basti, Uttar Pradesh, 272124",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c89"
    },
    "Rank": 39408,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Gondia,Nehru Chowk, KTS General Hospital Campus, Govt. Medical College, Gondia. 441601. Maharashtra., Maharashtra, 441601",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c8a"
    },
    "Rank": 39419,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta The Medicity, ,Medanta The Medicity Sector 38 Gurgoan, Haryana, 122001",
    "Course": "(NBEMS) IMMUNO- HAEMATOLOGY AND BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c8b"
    },
    "Rank": 39422,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c8c"
    },
    "Rank": 39426,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c8d"
    },
    "Rank": 39431,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Susrut Eye Foundation, West Bengal,HB 36 A 1 Salt Lake City Sector 3 Kolkata 700106, West Bengal, 700106",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c8e"
    },
    "Rank": 39444,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c8f"
    },
    "Rank": 39445,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Main Hospital , Telangana,MAIN HOSPITAL, THE SINGARENI COLLERIES COMPANY LIMITED, KOTHAGUDEM, BHADRADRI KOTHAGUDEM DISTRICT, T, Telangana, 507101",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c90"
    },
    "Rank": 39453,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c91"
    },
    "Rank": 39454,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c92"
    },
    "Rank": 39456,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c93"
    },
    "Rank": 39459,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c94"
    },
    "Rank": 39463,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c95"
    },
    "Rank": 39468,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Baba Saheb Ambedkar Hospital, ,SEC 6 ROHINI DELHI, Delhi (NCT), 110085",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c96"
    },
    "Rank": 39473,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c97"
    },
    "Rank": 39480,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GENERAL HOSPITAL MORBI,GENERAL HOSPITAL MORBI, GANDHI CHOKE NEAR TOWN HALL MORBI, Gujarat, 363641",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c98"
    },
    "Rank": 39491,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "NAIHATI STATE GENERAL HOSPITAL , West Bengal,NAIHATI NORTH 24\nPARGANAS, West Bengal, 743165",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c99"
    },
    "Rank": 39497,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata Shrimati Devendra Kumari Singhdeo Government Medical College, Ambikapur,Kanyaparisar  Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c9a"
    },
    "Rank": 39507,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c9b"
    },
    "Rank": 39510,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c9c"
    },
    "Rank": 39515,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shaheed Hasan Khan Mewati Government Medical College , ,Shaheed Hasan Khan Mewati Government Medical College Nalhar Nuh, Haryana, 122107",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c9d"
    },
    "Rank": 39518,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c9e"
    },
    "Rank": 39527,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06c9f"
    },
    "Rank": 39528,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ca0"
    },
    "Rank": 39535,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ca1"
    },
    "Rank": 39537,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ca2"
    },
    "Rank": 39538,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chamarajanagar Institute of Medical Sciences, Karnataka,CHAMARAJANAGAR INSTITUTE OF MEDICAL SCIENCES, SY. NO 124, YEDAPURA VILLAGE KASABA HOBLI, CHAMARAJANA, Karnataka, 571313",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ca3"
    },
    "Rank": 39539,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ca4"
    },
    "Rank": 39542,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ca5"
    },
    "Rank": 39547,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bombay Hospital,Ring Road Indore, Madhya Pradesh, 452010",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ca6"
    },
    "Rank": 39554,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ca7"
    },
    "Rank": 39561,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ca8"
    },
    "Rank": 39578,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vivekananda Polyclinic and Institute of Medical Sciences, ,Vivekananda Polyclinic and Institute of Medical Sciences, Vivekananda Puram, Lucknow, Uttar Pradesh, 226007",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ca9"
    },
    "Rank": 39581,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06caa"
    },
    "Rank": 39590,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cab"
    },
    "Rank": 39593,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cac"
    },
    "Rank": 39597,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sadhu Vaswani Missions ,7-9 Lane No 1 Koregaon Park Pune 411001, Maharashtra, 411001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cad"
    },
    "Rank": 39603,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Daga Memorial Women Government Hospital Nagpur, ,Ganjakhet gandhibagh nagpur, Maharashtra, 440008",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cae"
    },
    "Rank": 39607,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06caf"
    },
    "Rank": 39609,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cb0"
    },
    "Rank": 39614,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cb1"
    },
    "Rank": 39618,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cb2"
    },
    "Rank": 39626,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan , Rajasthan-311001,Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan, Rajasthan, 311001",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cb3"
    },
    "Rank": 39627,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cb4"
    },
    "Rank": 39629,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cb5"
    },
    "Rank": 39633,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bombay Hospital,Ring Road Indore, Madhya Pradesh, 452010",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cb6"
    },
    "Rank": 39643,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cb7"
    },
    "Rank": 39649,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Anand Rishiji Hospital and Medical Research Centre, ,124, Anandrishiji Marg, Ahmednagar, Maharashtra, 414001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cb8"
    },
    "Rank": 39656,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cb9"
    },
    "Rank": 39657,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cba"
    },
    "Rank": 39660,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. GERIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cbb"
    },
    "Rank": 39685,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cbc"
    },
    "Rank": 39705,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cbd"
    },
    "Rank": 39706,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cbe"
    },
    "Rank": 39707,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan , Rajasthan-311001,Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan, Rajasthan, 311001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cbf"
    },
    "Rank": 39708,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cc0"
    },
    "Rank": 39709,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Balrampur Hospital, ,Gola Ganj, lucknow, Uttar Pradesh, 226018",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cc1"
    },
    "Rank": 39713,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cc2"
    },
    "Rank": 39716,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gunam Super Speciality Hospital,No 35, Tank Street, Opp To Govt Hospital, Denkanikottai Road, Hosur, Krishnagiri Dist., Tamil Nadu, 635109",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cc3"
    },
    "Rank": 39717,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cc4"
    },
    "Rank": 39731,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bansal Hospital,,C- Sector Shahpura, Bhopal, Madhya Pradesh, 462016",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cc5"
    },
    "Rank": 39734,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cc6"
    },
    "Rank": 39735,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cc7"
    },
    "Rank": 39737,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Government Medical College, Vidisha,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA MP, Madhya Pradesh, 464001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cc8"
    },
    "Rank": 39738,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "VCSG Govt Institute of Medical Science and Research and HNB Base Teaching Hospital\n, ,Srikot Ganganali Srinagar Garhwal Uttarakhand, Uttarakhand, 246174",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cc9"
    },
    "Rank": 39747,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Felix Hospital,\n,NH-01 SECTOR 137 NOIDA EXPRESSWAY UP, Uttar Pradesh, 201305",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cca"
    },
    "Rank": 39750,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ccb"
    },
    "Rank": 39751,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital,,GMERS Medical College and Hospital Campus, Dharpur, Patan-Unjha State Highway, Patan (North Gujarat,, Gujarat, 384265",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ccc"
    },
    "Rank": 39755,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ccd"
    },
    "Rank": 39757,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cce"
    },
    "Rank": 39763,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ccf"
    },
    "Rank": 39769,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cd0"
    },
    "Rank": 39777,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cd1"
    },
    "Rank": 39779,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cd2"
    },
    "Rank": 39783,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bansal Hospital,,C- Sector Shahpura, Bhopal, Madhya Pradesh, 462016",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cd3"
    },
    "Rank": 39785,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Allopathic Medical College, Uttar Pradesh,RANI DURGAVATI MEDICAL COLLEGE NARAINI ROAD BANDA, Uttar Pradesh, 210001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cd4"
    },
    "Rank": 39787,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "INSTITUTE OF HUMAN  BEHAVIOUR AND ALLIED SCIENCES,Institute of Human\nBehaviour and Allied Sciences,  Dilshad Garden, New Delhi, Delhi 110095, India, Delhi (NCT), 110095",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cd5"
    },
    "Rank": 39793,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cd6"
    },
    "Rank": 39801,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cd7"
    },
    "Rank": 39806,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaikh-Ul-Hind Maulana Mahmood Hasan Medical College, Saharanpur,Pilkhani Ambala Road, Uttar Pradesh, 247232",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cd8"
    },
    "Rank": 39811,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cd9"
    },
    "Rank": 39815,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cda"
    },
    "Rank": 39819,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cdb"
    },
    "Rank": 39827,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cdc"
    },
    "Rank": 39832,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Super Facility Hospital, Distt.-Azamgarh, Uttar Pradesh,Chakrapanpur, Kanaila, Azamgarh, Uttar Pradesh, 276128",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cdd"
    },
    "Rank": 39834,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cde"
    },
    "Rank": 39839,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cdf"
    },
    "Rank": 39841,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,HBT Medical College and Dr R N Cooper Municipal General Hospital, Bhaktivedanta Swami Rd, JVPD Schem, Maharashtra, 400056",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ce0"
    },
    "Rank": 39844,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ce1"
    },
    "Rank": 39851,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dharwad,dsdharwad at gmail dot com, Karnataka, 580001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ce2"
    },
    "Rank": 39853,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ce3"
    },
    "Rank": 39858,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ce4"
    },
    "Rank": 39874,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ce5"
    },
    "Rank": 39879,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ce6"
    },
    "Rank": 39880,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Princess Durru Shehvar Children`s and General Hospital, ,22 3 660A PURANI HAVELI HYDERABAD, Telangana, 500002",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ce7"
    },
    "Rank": 39883,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ce8"
    },
    "Rank": 39889,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital, Near KEB, PB Road, Haveri, Karnataka, 581110",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ce9"
    },
    "Rank": 39896,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cea"
    },
    "Rank": 39897,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Thorat Netra Hospital, Maharashtra,Near LRT commerce college Civil lines Akola, Maharashtra, 444001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ceb"
    },
    "Rank": 39900,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cec"
    },
    "Rank": 39901,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ced"
    },
    "Rank": 39909,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cee"
    },
    "Rank": 39910,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cef"
    },
    "Rank": 39916,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SETH V. C. GANDHI and M. A. VORA MUNICIPAL GENERAL HOSPITAL (RAJAWADI HOSPITAL),\nMaharashtra,hospital.rajawadi@gmail.com, Maharashtra, 400077",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cf0"
    },
    "Rank": 39926,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cf1"
    },
    "Rank": 39929,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bombay Hospital,Ring Road Indore, Madhya Pradesh, 452010",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cf2"
    },
    "Rank": 39934,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cf3"
    },
    "Rank": 39943,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College, ,Office of the Dean, First Floor, Civil Hospital Campus, Sector- 12, Gandhinagar, Gujarat, 382016",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cf4"
    },
    "Rank": 39945,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Hurkisondas Nurrotumdas Hospital and Research Centre, ,Prarthana Samaj, Raja Ram Mohan Roy road,\nGirgaon, MUMBAI, Maharashtra, 400004",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cf5"
    },
    "Rank": 39946,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cf6"
    },
    "Rank": 39965,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital, Near KEB, PB Road, Haveri, Karnataka, 581110",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cf7"
    },
    "Rank": 39967,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cf8"
    },
    "Rank": 39975,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cf9"
    },
    "Rank": 39978,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SRI SANKARA NETRA CHIKITSALAYA, Andhra Pradesh,SRI SANKARA NETRA CHIKITSALAYA, 23A-6-6 ,NEAR VENKATESWARASWAMY TEMPLE,RR PETA,ELURU,WESTGODAVARI DI, Andhra Pradesh, 534002",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cfa"
    },
    "Rank": 39980,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pawana Hospital,Pawana Hospital, Gat No-167, Somatane Phata, Tal- Maval. Pune, Maharashtra, 410506",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cfb"
    },
    "Rank": 39981,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Civil Hospital, ,Medical Superintendent Office, Civil Hospital, Dawrpui, Aizawl, Mizoram, 796001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cfc"
    },
    "Rank": 39984,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saijyothi Eye Institute, Telangana,Plot No. 185, Road No. 1 West Marredpally, Secunderabad, Telangana, 500026",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cfd"
    },
    "Rank": 39987,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cfe"
    },
    "Rank": 39989,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06cff"
    },
    "Rank": 39990,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d00"
    },
    "Rank": 40016,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d01"
    },
    "Rank": 40021,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SLG Hospitals,Sree Lakshmi Gayatri Hospitals Pvt Ltd, SLG Circle Nizampet Road, Bachupally, Medchal Malkajgiri Dis, Telangana, 500090",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d02"
    },
    "Rank": 40026,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SARASWATI MISSION HOSPITAL, Haryana,Ambala Road, Pehowa District Kurukshetra, Haryana, 136128",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d03"
    },
    "Rank": 40029,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d04"
    },
    "Rank": 40030,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Trustwell Hospitals Pvt. Ltd, ,NO 5 Chandrika Tower JC Road\nBangalore, Karnataka, 560002",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d05"
    },
    "Rank": 40034,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI MEDICAL COLLEGE KALWA THANE,RAJIV GANDHI MEDICAL COLLEGE,THANE BELAPUR ROAD, KALWA, THANE, Maharashtra, 400605",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d06"
    },
    "Rank": 40037,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d07"
    },
    "Rank": 40046,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d08"
    },
    "Rank": 40059,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d09"
    },
    "Rank": 40061,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ispat General Hospital,,Ispat General Hospital Rourkela Steel Plant SAIL Rourkela 769005, Odisha, 769005",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d0a"
    },
    "Rank": 40079,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d0b"
    },
    "Rank": 40081,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d0c"
    },
    "Rank": 40087,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d0d"
    },
    "Rank": 40088,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d0e"
    },
    "Rank": 40089,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bombay Hospital,Ring Road Indore, Madhya Pradesh, 452010",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d0f"
    },
    "Rank": 40092,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d10"
    },
    "Rank": 40103,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d11"
    },
    "Rank": 40104,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d12"
    },
    "Rank": 40116,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d13"
    },
    "Rank": 40120,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d14"
    },
    "Rank": 40122,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d15"
    },
    "Rank": 40145,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d16"
    },
    "Rank": 40153,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College Kannauj , Uttar Pradesh,Kannaujmedicalcollege@gmail.com, Uttar Pradesh, 209732",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d17"
    },
    "Rank": 40155,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d18"
    },
    "Rank": 40172,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d19"
    },
    "Rank": 40191,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d1a"
    },
    "Rank": 40195,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d1b"
    },
    "Rank": 40196,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d1c"
    },
    "Rank": 40198,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d1d"
    },
    "Rank": 40203,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d1e"
    },
    "Rank": 40206,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d1f"
    },
    "Rank": 40214,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d20"
    },
    "Rank": 40218,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d21"
    },
    "Rank": 40229,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute of Medical Sciences Srikakulam,Government Medical College Srikakulam\nBalaga Srikakulam Town Srikakulam District, Andhra Pradesh, 532001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d22"
    },
    "Rank": 40234,
    "Allotted Quota": "All India",
    "Allotted Institute": "TATA MEMORIAL CENTRE, MUMBAI,OFFICE OF THE DIRECTOR ACADEMICS, 13TH FLOOR, HOMI BHABHA BLOCK, TATA MEMORIAL HOSPITAL, PAREL, MUMB,\nMaharashtra, 400012",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d23"
    },
    "Rank": 40237,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d24"
    },
    "Rank": 40241,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d25"
    },
    "Rank": 40250,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d26"
    },
    "Rank": 40254,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d27"
    },
    "Rank": 40258,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VELLORE MEDICAL COLLEGE,GOVERNMENT VELLORE MEDICAL COLLEGE, Tamil Nadu, 632011",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d28"
    },
    "Rank": 40259,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d29"
    },
    "Rank": 40262,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindustan Aeronautics Hospital, ,SURANJANDAS ROAD ,VIMANAPURA POST BANGALORE -560017, Karnataka, 560017",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d2a"
    },
    "Rank": 40266,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d2b"
    },
    "Rank": 40267,
    "Allotted Quota": "All India",
    "Allotted Institute": "Institute of Child Health, Kolkata,Institute of Child Health, Kolkata, West Bengal, 700017",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d2c"
    },
    "Rank": 40276,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d2d"
    },
    "Rank": 40285,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE,ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, Bihar, 823001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d2e"
    },
    "Rank": 40299,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d2f"
    },
    "Rank": 40301,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d30"
    },
    "Rank": 40306,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d31"
    },
    "Rank": 40318,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Trustwell Hospitals Pvt. Ltd, ,NO 5 Chandrika Tower JC Road\nBangalore, Karnataka, 560002",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d32"
    },
    "Rank": 40321,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d33"
    },
    "Rank": 40322,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d34"
    },
    "Rank": 40338,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d35"
    },
    "Rank": 40339,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d36"
    },
    "Rank": 40361,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Head Quarters Hospital, Khammam District, Telangana,District Head Quarters Hospital, Khammam District, Telangana, Telangana, 507002",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d37"
    },
    "Rank": 40369,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d38"
    },
    "Rank": 40370,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d39"
    },
    "Rank": 40372,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d3a"
    },
    "Rank": 40379,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d3b"
    },
    "Rank": 40380,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d3c"
    },
    "Rank": 40385,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d3d"
    },
    "Rank": 40389,
    "Allotted Quota": "All India",
    "Allotted Institute": "VARDHMAN INSTITUTE OF MEDICAL SCIENCES, NALANDA,BMIMS CAMPUS, PAWAPURI, NALANDA, Bihar, 803115",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d3e"
    },
    "Rank": 40390,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d3f"
    },
    "Rank": 40392,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Post Graduate Institute of Child Health (PGICH), SEC-30, Noida,Post Graduate  Instituite of Child Health sec-30 Noida, Gautam Buddh Nagar, Uttar Pradesh, 201303",
    "Course": "(NBEMS) IMMUNO- HAEMATOLOGY AND BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d40"
    },
    "Rank": 40395,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagwan Mahavir Cancer Hospital and Research Centre, ,Managed by K G Kothari Memorial Trust\nJawahar Lal Nehru Marg, Jaipur 302 017, Rajasthan, 302017",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d41"
    },
    "Rank": 40398,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d42"
    },
    "Rank": 40401,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d43"
    },
    "Rank": 40410,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Choudhury Eye Hospital And Research Centre, Assam,Shyamaprasad Road, Silchar-1, Assam, 788001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d44"
    },
    "Rank": 40414,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Arambagh Sub Divisional/ Super Speciality Hospital, ,Arambagh, Hooghly , Pin - 712601, West Bengal, 712601",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d45"
    },
    "Rank": 40416,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Mental Health Centre,,Govt Mental Health Centre, Near District TB Centre, Kuthiravattom P O,\nKozhikode, Kerala, Kerala, 673016",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d46"
    },
    "Rank": 40421,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d47"
    },
    "Rank": 40427,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lokopriya Gopinath Bordoloi Regional Institute of Mental Health,LGBRIMH, Tezpur, District Sonitpur,, Assam, 784001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d48"
    },
    "Rank": 40429,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d49"
    },
    "Rank": 40438,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d4a"
    },
    "Rank": 40440,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d4b"
    },
    "Rank": 40441,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d4c"
    },
    "Rank": 40442,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medica Superspecialty Hospital, ,127 Mukundapur, E.M. Byapss, Kolkata, West Bengal, 700099",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d4d"
    },
    "Rank": 40443,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d4e"
    },
    "Rank": 40444,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jagjivan Ram Railway Hospital,,Maratha Mandir Marg, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d4f"
    },
    "Rank": 40448,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d50"
    },
    "Rank": 40452,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences Ltd, ,Dargamitta, Nellore, Andhra Pradesh, 524003",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d51"
    },
    "Rank": 40453,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Civil Hospital, ,Civil Hospital Bahardurgarh, Delhi-Rohtak Road, District- Jhajjar, Haryana, 124507",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d52"
    },
    "Rank": 40454,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d53"
    },
    "Rank": 40466,
    "Allotted Quota": "All India",
    "Allotted Institute": "The National Institute of Health and Family Welfare,The National Institute of Health and Family Welfare, Delhi (NCT), 110067",
    "Course": "M.D. (COMMUNITY HEALTH  and ADMN.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d54"
    },
    "Rank": 40480,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d55"
    },
    "Rank": 40491,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,DISTRICT HOSPITAL NEAR SANGAM CIRCLE DR RAJ KUMAR ROAD BALLARI-583101 KARNATAKA, Karnataka, 583101",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d56"
    },
    "Rank": 40499,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d57"
    },
    "Rank": 40504,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d58"
    },
    "Rank": 40508,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d59"
    },
    "Rank": 40511,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d5a"
    },
    "Rank": 40514,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d5b"
    },
    "Rank": 40515,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bombay Hospital,Ring Road Indore, Madhya Pradesh, 452010",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d5c"
    },
    "Rank": 40520,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d5d"
    },
    "Rank": 40521,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d5e"
    },
    "Rank": 40524,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vidyasagar State General Hospital,,4 BRAHMA SAMAJ ROAD, BEHALA, KOLKATA - 700034, West Bengal, 700034",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d5f"
    },
    "Rank": 40525,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d60"
    },
    "Rank": 40528,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d61"
    },
    "Rank": 40529,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d62"
    },
    "Rank": 40530,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d63"
    },
    "Rank": 40536,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d64"
    },
    "Rank": 40543,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d65"
    },
    "Rank": 40557,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d66"
    },
    "Rank": 40564,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagwan Mahavir Cancer Hospital and Research Centre, ,Managed by K G Kothari Memorial Trust\nJawahar Lal Nehru Marg, Jaipur 302 017, Rajasthan, 302017",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d67"
    },
    "Rank": 40569,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BASEL MISSION CSI HOSPITAL, Karnataka\n,Near Ambabavani Circle, PB Road, Gadag-Betageri, Gadag, Karnataka, Karnataka, 582102",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d68"
    },
    "Rank": 40580,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saket Hospital,10, Meera Marg, Agarwal Farm, Masarover, Jaipur, Rajasthan, 302020",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d69"
    },
    "Rank": 40592,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d6a"
    },
    "Rank": 40599,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College,Theni ,Government Medical College,Theni, Tamil Nadu, 625512",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d6b"
    },
    "Rank": 40600,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d6c"
    },
    "Rank": 40613,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital , ,District Hospital Kumundhan Mohalla Near Police Station Ramanagara\nKarnataka-562159, Karnataka, 562159",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d6d"
    },
    "Rank": 40616,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital, B.H.Road, Tumakuru-572101, Karnataka, 572101",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d6e"
    },
    "Rank": 40617,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d6f"
    },
    "Rank": 40625,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d70"
    },
    "Rank": 40629,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SBLS Civil Hospital, ,NEAR JYOTI CHOWK JAIL ROAD JALANDHAR, Punjab, 144001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d71"
    },
    "Rank": 40635,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d72"
    },
    "Rank": 40637,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d73"
    },
    "Rank": 40656,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. Medical College, ,Govt. Medical College, B-5 Medical Enclave, Maheshpura Bakshi Nagar, Jammu, Jammu And Kashmir, 180001",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d74"
    },
    "Rank": 40659,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d75"
    },
    "Rank": 40672,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d76"
    },
    "Rank": 40679,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d77"
    },
    "Rank": 40686,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SRI SANKARA NETRA CHIKITSALAYA, Andhra Pradesh,SRI SANKARA NETRA CHIKITSALAYA, 23A-6-6 ,NEAR VENKATESWARASWAMY TEMPLE,RR PETA,ELURU,WESTGODAVARI DI, Andhra Pradesh, 534002",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d78"
    },
    "Rank": 40688,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d79"
    },
    "Rank": 40692,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d7a"
    },
    "Rank": 40694,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,Veer Chandra Singh Garhwali Govt. Institute of Medical Science and Research, Srinagar Garhwal, Uttarakhand, 246174",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d7b"
    },
    "Rank": 40697,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d7c"
    },
    "Rank": 40700,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d7d"
    },
    "Rank": 40712,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K S HOSPITAL, Karnataka,9-13-1270, M B DIWATAR NAGAR, HOSPET ROAD,\nKOPPAL, Karnataka, 583231",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d7e"
    },
    "Rank": 40713,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d7f"
    },
    "Rank": 40720,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d80"
    },
    "Rank": 40728,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashvedh Healthcare Services, Andhra Pradesh,Gaigolupadu Junction Kakinada, Andhra Pradesh, 533005",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d81"
    },
    "Rank": 40741,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d82"
    },
    "Rank": 40744,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d83"
    },
    "Rank": 40750,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d84"
    },
    "Rank": 40763,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d85"
    },
    "Rank": 40764,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d86"
    },
    "Rank": 40766,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Specialty Hospitals, ,IIIM NEHRU NAGAR GHAZIABAD, Uttar Pradesh, 201001",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d87"
    },
    "Rank": 40769,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pimpri Chichwad Municipal Corporation Postgraduate Institute Y.C.M.Hospital Pimpri,Pune 18,Dean Office, Y.C.M.Hospital, Sant Tukaramnagar Pimpri, Pune, Maharashtra, 411018",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d88"
    },
    "Rank": 40770,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d89"
    },
    "Rank": 40772,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Second Sight Southend Eye Centre Pvt. Ltd., West Bengal,Baruipur Amtola Road. Dhopagachi, Kundarali, PS.Baruipur, Westbengal, Pin - 743610, West Bengal, 743610",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d8a"
    },
    "Rank": 40784,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d8b"
    },
    "Rank": 40800,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d8c"
    },
    "Rank": 40807,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d8d"
    },
    "Rank": 40812,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d8e"
    },
    "Rank": 40815,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d8f"
    },
    "Rank": 40819,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d90"
    },
    "Rank": 40824,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VILLUPURAM MEDICAL COLLEGE, VILLUPURAM,GOVERNMENT VILLUPURAM MEDICAL COLLEGE VILLUPURAM  TRICHY TRUNK ROAD MUNDIYAMPAKKAM  VILLUPURAM DISTR, Tamil Nadu, 605601",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d91"
    },
    "Rank": 40825,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d92"
    },
    "Rank": 40827,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (FAMILY MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d93"
    },
    "Rank": 40834,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d94"
    },
    "Rank": 40837,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d95"
    },
    "Rank": 40847,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d96"
    },
    "Rank": 40856,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d97"
    },
    "Rank": 40861,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d98"
    },
    "Rank": 40869,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d99"
    },
    "Rank": 40872,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pandurang Ramarao Dongaonkar District Hospital, Chhattisgarh,Pandurang Ramarao Dongaonkar\nDistrict Hospital Durg G E Road District Durg Chhattisgarh 491001, Chhattisgarh, 491001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d9a"
    },
    "Rank": 40873,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d9b"
    },
    "Rank": 40879,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan , Rajasthan-311001,Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan, Rajasthan, 311001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d9c"
    },
    "Rank": 40889,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d9d"
    },
    "Rank": 40892,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d9e"
    },
    "Rank": 40905,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06d9f"
    },
    "Rank": 40907,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06da0"
    },
    "Rank": 40913,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06da1"
    },
    "Rank": 40932,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06da2"
    },
    "Rank": 40936,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Government Medical College, Vidisha,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA MP, Madhya Pradesh, 464001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06da3"
    },
    "Rank": 40939,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06da4"
    },
    "Rank": 40945,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06da5"
    },
    "Rank": 40951,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06da6"
    },
    "Rank": 40952,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06da7"
    },
    "Rank": 40954,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubnagar,Government Medical College, Yedira, Mahabubnagar, Telangana state, Telangana, 509002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06da8"
    },
    "Rank": 40956,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06da9"
    },
    "Rank": 40957,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06daa"
    },
    "Rank": 40969,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dab"
    },
    "Rank": 40970,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dac"
    },
    "Rank": 40972,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dad"
    },
    "Rank": 40983,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dae"
    },
    "Rank": 40987,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06daf"
    },
    "Rank": 40990,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S.K.Hospital, ,SUPERINTENDENT RNO 36  GOVT SK HOSPITAL  SILVER JUBLI ROAD  SIKAR  RAJ, Rajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06db0"
    },
    "Rank": 40991,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Super Facility Hospital, Distt.-Azamgarh, Uttar Pradesh,Chakrapanpur, Kanaila, Azamgarh, Uttar Pradesh, 276128",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06db1"
    },
    "Rank": 40993,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Civil Hospital Shillong, Meghalaya,Civil Hospital Shillong Laban Shillong East Khasi Hills Meghalaya 793001, Meghalaya, 793001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06db2"
    },
    "Rank": 40997,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06db3"
    },
    "Rank": 41007,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Taluka General Hospital, ,CHIEF MEDICAL OFFICER TALUKA GENERAL HOSPITAL HANAMAGERI STREET SAUNDATTI TQ SAUNDATTI DIST BELAGAVI, Karnataka, 591126",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06db4"
    },
    "Rank": 41034,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Associated Hospital Government Medical College, ,Kanth Bagh Baramulla, Jammu And Kashmir, 193103",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06db5"
    },
    "Rank": 41039,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06db6"
    },
    "Rank": 41046,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Multispecialty Hospital, ,Narayana Multispecialty Hospital, Jaipur Sec-28,Kumbha Marg, Pratap Nagar Sanganer 302033",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06db7"
    },
    "Rank": 41047,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06db8"
    },
    "Rank": 41049,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06db9"
    },
    "Rank": 41054,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SLG Hospitals,Sree Lakshmi Gayatri Hospitals Pvt Ltd, SLG Circle Nizampet Road, Bachupally, Medchal Malkajgiri Dis, Telangana, 500090",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dba"
    },
    "Rank": 41061,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dbb"
    },
    "Rank": 41065,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dbc"
    },
    "Rank": 41078,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dbd"
    },
    "Rank": 41079,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dbe"
    },
    "Rank": 41087,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dbf"
    },
    "Rank": 41089,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SETH V. C. GANDHI and M. A. VORA MUNICIPAL GENERAL HOSPITAL (RAJAWADI HOSPITAL),\nMaharashtra,hospital.rajawadi@gmail.com, Maharashtra, 400077",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dc0"
    },
    "Rank": 41094,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dc1"
    },
    "Rank": 41097,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dc2"
    },
    "Rank": 41099,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dc3"
    },
    "Rank": 41109,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dc4"
    },
    "Rank": 41113,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dc5"
    },
    "Rank": 41115,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dc6"
    },
    "Rank": 41124,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Baba Saheb Ambedkar Hospital, ,SEC 6 ROHINI DELHI, Delhi (NCT), 110085",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dc7"
    },
    "Rank": 41126,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dc8"
    },
    "Rank": 41129,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dc9"
    },
    "Rank": 41141,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dca"
    },
    "Rank": 41150,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dcb"
    },
    "Rank": 41156,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College & Attached Hospital Bharatpur,GOVERNMENT MEDICAL COLLEGE BHARATPUR VILLAGE RAMPURA NH 21, Rajasthan, 321001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dcc"
    },
    "Rank": 41165,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dcd"
    },
    "Rank": 41166,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dce"
    },
    "Rank": 41176,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dcf"
    },
    "Rank": 41187,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dd0"
    },
    "Rank": 41189,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dd1"
    },
    "Rank": 41195,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dd2"
    },
    "Rank": 41196,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dd3"
    },
    "Rank": 41200,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dd4"
    },
    "Rank": 41201,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Punjab Institute of Medical Scineces ,Punjab Institute of Medical Sciences Garha Road Jalandhar, Punjab, 144006",
    "Course": "(NBEMS) IMMUNO- HAEMATOLOGY AND BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dd5"
    },
    "Rank": 41206,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dd6"
    },
    "Rank": 41209,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dd7"
    },
    "Rank": 41223,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dd8"
    },
    "Rank": 41226,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dd9"
    },
    "Rank": 41229,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dda"
    },
    "Rank": 41231,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ddb"
    },
    "Rank": 41235,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ddc"
    },
    "Rank": 41238,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ddd"
    },
    "Rank": 41246,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dde"
    },
    "Rank": 41265,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ddf"
    },
    "Rank": 41272,
    "Allotted Quota": "All India",
    "Allotted Institute": "Murshidabad Medical College and Hospital,Station Road, Berhampore, Murshidabad, West Bengal, 742101",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06de0"
    },
    "Rank": 41273,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06de1"
    },
    "Rank": 41275,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06de2"
    },
    "Rank": 41297,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Head Quarters Hospital,,District Hospital, Sangareddy Telangana State, Telangana, 502001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06de3"
    },
    "Rank": 41300,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06de4"
    },
    "Rank": 41306,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District General Hospital, ,O/o. Medical Superintendent, Government District General Hospital, Contonment, Vizianagaram, Andhra Pradesh,\n535003",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06de5"
    },
    "Rank": 41318,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06de6"
    },
    "Rank": 41319,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06de7"
    },
    "Rank": 41326,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06de8"
    },
    "Rank": 41334,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06de9"
    },
    "Rank": 41342,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Daga Memorial Women Government Hospital Nagpur, ,Ganjakhet gandhibagh nagpur, Maharashtra, 440008",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dea"
    },
    "Rank": 41363,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06deb"
    },
    "Rank": 41368,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dec"
    },
    "Rank": 41371,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ded"
    },
    "Rank": 41375,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Maharashtra,Barshi Road Beed, Maharashtra, 431122",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dee"
    },
    "Rank": 41395,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06def"
    },
    "Rank": 41399,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06df0"
    },
    "Rank": 41404,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06df1"
    },
    "Rank": 41406,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06df2"
    },
    "Rank": 41413,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06df3"
    },
    "Rank": 41415,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06df4"
    },
    "Rank": 41420,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06df5"
    },
    "Rank": 41443,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06df6"
    },
    "Rank": 41455,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06df7"
    },
    "Rank": 41458,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06df8"
    },
    "Rank": 41463,
    "Allotted Quota": "All India",
    "Allotted Institute": "All  India Institute of  Hygiene and Public Health,110, C. R  Avenue, Kolkata, West Bengal, 700073",
    "Course": "M.P.H. (EPIDEMIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06df9"
    },
    "Rank": 41470,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dfa"
    },
    "Rank": 41485,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Government Medical College, Vidisha,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA MP, Madhya Pradesh, 464001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dfb"
    },
    "Rank": 41488,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dfc"
    },
    "Rank": 41492,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine & Sagore Dutta Hospital,College of Medicine & Sagore Dutta Hospital, West Bengal, 700058",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dfd"
    },
    "Rank": 41498,
    "Allotted Quota": "All India",
    "Allotted Institute": "Calcutta School Of Tropical Medicine,Calcutta School Of Tropical Medicine, West Bengal, 700073",
    "Course": "DIPLOMA IN CLINICAL PATHOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dfe"
    },
    "Rank": 41502,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06dff"
    },
    "Rank": 41504,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e00"
    },
    "Rank": 41508,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESIC Medical College Hospital and Super Specialty Hospital, ,SR Nagar, Sanathnagar, Telangana, 500038",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e01"
    },
    "Rank": 41510,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e02"
    },
    "Rank": 41511,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MMI Narayana Multispecialty Hospital,,Dhamtari Road, Lalpur, Raipur, Chhattisgarh, 492001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e03"
    },
    "Rank": 41513,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e04"
    },
    "Rank": 41515,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e05"
    },
    "Rank": 41516,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "J.L.N. Main Hospital and Research Centre, ,Bhilai Steel Plant Sector-9, Bhilai, C.G., Chhattisgarh, 490009",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e06"
    },
    "Rank": 41527,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Ram Manohar Lohia Institute of Medical Sciences, Vibhuti khand, ,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Vibhuti Khand, Gomti Nagar, Lucknow, Uttar Pradesh, 226010",
    "Course": "(NBEMS) FORENSIC MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e07"
    },
    "Rank": 41528,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Princess Durru Shehvar Children`s and General Hospital, ,22 3 660A PURANI HAVELI HYDERABAD, Telangana, 500002",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e08"
    },
    "Rank": 41532,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e09"
    },
    "Rank": 41539,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e0a"
    },
    "Rank": 41542,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e0b"
    },
    "Rank": 41548,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e0c"
    },
    "Rank": 41554,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e0d"
    },
    "Rank": 41564,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e0e"
    },
    "Rank": 41567,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e0f"
    },
    "Rank": 41568,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e10"
    },
    "Rank": 41571,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e11"
    },
    "Rank": 41573,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e12"
    },
    "Rank": 41583,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e13"
    },
    "Rank": 41589,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e14"
    },
    "Rank": 41590,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government DB General Medical Hosptal,Churu, Nayabass, Churu",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e15"
    },
    "Rank": 41592,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e16"
    },
    "Rank": 41594,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Allopathic Medical College, Uttar Pradesh,RANI DURGAVATI MEDICAL COLLEGE NARAINI ROAD BANDA, Uttar Pradesh, 210001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e17"
    },
    "Rank": 41601,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e18"
    },
    "Rank": 41602,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District General Hospital, Ramachandraraopeta, Eluru, West Godavari District, Andhra Prad, Andhra Pradesh, 534006,Government District General Hospital, Ramachandraraopeta, Eluru, West Godavari District, Andhra Prad, Andhra Pradesh, 534006",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e19"
    },
    "Rank": 41604,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e1a"
    },
    "Rank": 41615,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e1b"
    },
    "Rank": 41626,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e1c"
    },
    "Rank": 41632,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e1d"
    },
    "Rank": 41635,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e1e"
    },
    "Rank": 41647,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e1f"
    },
    "Rank": 41672,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e20"
    },
    "Rank": 41677,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e21"
    },
    "Rank": 41685,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e22"
    },
    "Rank": 41695,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Kamaraj Hospital, ,SP KOIL STREET,CHIDAMBARM,CUDDALORE DISTRICT, Tamil Nadu, 608001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e23"
    },
    "Rank": 41699,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e24"
    },
    "Rank": 41706,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e25"
    },
    "Rank": 41719,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e26"
    },
    "Rank": 41738,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hayat Hospital, ,kahilipara Road, Odalbakra, Lalganesh,Guwahati, Assam, 781034",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e27"
    },
    "Rank": 41740,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e28"
    },
    "Rank": 41749,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e29"
    },
    "Rank": 41750,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e2a"
    },
    "Rank": 41753,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e2b"
    },
    "Rank": 41762,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e2c"
    },
    "Rank": 41765,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e2d"
    },
    "Rank": 41766,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e2e"
    },
    "Rank": 41767,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e2f"
    },
    "Rank": 41770,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e30"
    },
    "Rank": 41774,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e31"
    },
    "Rank": 41780,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e32"
    },
    "Rank": 41790,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "NH Brahamananda Narayana ,Near Pardih Chowk, Tamolia, NH 33, Jamshedpur, Saraikela-Kharsawan, Jharkhand 831012, Jharkhand, 831012",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e33"
    },
    "Rank": 41793,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e34"
    },
    "Rank": 41794,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e35"
    },
    "Rank": 41801,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e36"
    },
    "Rank": 41802,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e37"
    },
    "Rank": 41805,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e38"
    },
    "Rank": 41809,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e39"
    },
    "Rank": 41812,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e3a"
    },
    "Rank": 41834,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jabalpur Hospital and Research Centre, Jabalpur,\nMadhya Pradesh,Russel Chowk Napier Town Jabalpur, Madhya Pradesh, 482002",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e3b"
    },
    "Rank": 41836,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,DISTRICT HOSPITAL NEAR SANGAM CIRCLE DR RAJ KUMAR ROAD BALLARI-583101 KARNATAKA, Karnataka, 583101",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e3c"
    },
    "Rank": 41841,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e3d"
    },
    "Rank": 41846,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital,\n,DISTRICT SUREGEON DISTRICT HOSPITAL SETOR-11 NAVANAGAR BAGALKOTE\nKARNATAKA, Karnataka, 587103",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e3e"
    },
    "Rank": 41850,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e3f"
    },
    "Rank": 41864,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e40"
    },
    "Rank": 41868,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e41"
    },
    "Rank": 41870,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e42"
    },
    "Rank": 41873,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e43"
    },
    "Rank": 41875,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e44"
    },
    "Rank": 41880,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e45"
    },
    "Rank": 41883,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Jayachamarajendra General Hospital, Hassan.Dist. Karnataka,Sri Jayachamarajendra General Hospital, ARSIKERE,Hassan.Dist. Karnataka, Karnataka, 573103",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e46"
    },
    "Rank": 41886,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BharatRatna Dr. Babasaheb Ambedkar Municipal General Hospital, Kandivali (West), Maharashtra,Parekh Nagar, SV Road, Opp. Kandivali Police station, Kandivali West, Mumbai, Maharashtra, 400067",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e47"
    },
    "Rank": 41898,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital Bhandara,\nMaharashtra,Santaji Nagar, District Hospital, Bhandara, Maharashtra, 441904",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e48"
    },
    "Rank": 41900,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e49"
    },
    "Rank": 41903,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e4a"
    },
    "Rank": 41913,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e4b"
    },
    "Rank": 41926,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e4c"
    },
    "Rank": 41927,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e4d"
    },
    "Rank": 41932,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "J.L.N. Main Hospital and Research Centre, ,Bhilai Steel Plant Sector-9, Bhilai, C.G., Chhattisgarh, 490009",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e4e"
    },
    "Rank": 41933,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e4f"
    },
    "Rank": 41942,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e50"
    },
    "Rank": 41956,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e51"
    },
    "Rank": 41970,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e52"
    },
    "Rank": 41977,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e53"
    },
    "Rank": 41978,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bansal Hospital,,C- Sector Shahpura, Bhopal, Madhya Pradesh, 462016",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e54"
    },
    "Rank": 41979,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e55"
    },
    "Rank": 41980,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e56"
    },
    "Rank": 41984,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e57"
    },
    "Rank": 42013,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ozone Multispeciality hospital and Critical care center, Maharashtra,Holycross convent Road, Kedia Plots, Akola, Maharashtra, 444005",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e58"
    },
    "Rank": 42015,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Medical College for Women, Khanpur Kalan Sonepat,BPS Govt. Medical College for Women, Khanpur Kalan Sonepat, Haryana, 131305",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e59"
    },
    "Rank": 42016,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e5a"
    },
    "Rank": 42018,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e5b"
    },
    "Rank": 42022,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lalitha Super Specialty Hospital, ,KOTHAPET GUNTUR, Andhra Pradesh, 522001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e5c"
    },
    "Rank": 42023,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e5d"
    },
    "Rank": 42025,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e5e"
    },
    "Rank": 42032,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e5f"
    },
    "Rank": 42053,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e60"
    },
    "Rank": 42058,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e61"
    },
    "Rank": 42062,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e62"
    },
    "Rank": 42063,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e63"
    },
    "Rank": 42068,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e64"
    },
    "Rank": 42072,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e65"
    },
    "Rank": 42074,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e66"
    },
    "Rank": 42081,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e67"
    },
    "Rank": 42086,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhima Bhoi Medical College and Hospital, Balangir,At-Laltikra Po-Rajendra College, Balangir, Odisha, 767002",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e68"
    },
    "Rank": 42092,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e69"
    },
    "Rank": 42099,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e6a"
    },
    "Rank": 42101,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e6b"
    },
    "Rank": 42102,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital,\n,DISTRICT SUREGEON DISTRICT HOSPITAL SETOR-11 NAVANAGAR BAGALKOTE\nKARNATAKA, Karnataka, 587103",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e6c"
    },
    "Rank": 42107,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e6d"
    },
    "Rank": 42109,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Maharashtra,Barshi Road Beed, Maharashtra, 431122",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e6e"
    },
    "Rank": 42120,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital, Near KEB, PB Road, Haveri, Karnataka, 581110",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e6f"
    },
    "Rank": 42133,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e70"
    },
    "Rank": 42134,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e71"
    },
    "Rank": 42141,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Specialty Hospitals, ,IIIM NEHRU NAGAR GHAZIABAD, Uttar Pradesh, 201001",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e72"
    },
    "Rank": 42155,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e73"
    },
    "Rank": 42164,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e74"
    },
    "Rank": 42168,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e75"
    },
    "Rank": 42173,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Institute of Medical Sciences, ,Greater Noida, Kasna, Uttar Pradesh, 201310",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e76"
    },
    "Rank": 42181,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital, B.H.Road, Tumakuru-572101, Karnataka, 572101",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e77"
    },
    "Rank": 42184,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e78"
    },
    "Rank": 42185,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e79"
    },
    "Rank": 42200,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Capital Hospital, Orissa,director.chb@gmail.com, Odisha, 751001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e7a"
    },
    "Rank": 42203,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e7b"
    },
    "Rank": 42214,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College Kannauj , Uttar Pradesh,Kannaujmedicalcollege@gmail.com, Uttar Pradesh, 209732",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e7c"
    },
    "Rank": 42220,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e7d"
    },
    "Rank": 42222,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gopala Gowda Shanthaveri Memorial Hospital, Karnataka,T Narasipura Road, Nazarbad Mysuru, Karnataka, 570010",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e7e"
    },
    "Rank": 42223,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e7f"
    },
    "Rank": 42228,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District hospital chitradurga, B D road beside DHO office Chitradurga, Karnataka, 577501",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e80"
    },
    "Rank": 42233,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e81"
    },
    "Rank": 42234,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e82"
    },
    "Rank": 42242,
    "Allotted Quota": "All India",
    "Allotted Institute": "The National Institute of Health and Family Welfare,The National Institute of Health and Family Welfare, Delhi (NCT), 110067",
    "Course": "DIPLOMA IN HEALTH ADMINISTRATION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e83"
    },
    "Rank": 42245,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e84"
    },
    "Rank": 42254,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e85"
    },
    "Rank": 42268,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kalpana Chawla Govt Medical College, ,Model Town, Karnal, Haryana, 132001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e86"
    },
    "Rank": 42270,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e87"
    },
    "Rank": 42287,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e88"
    },
    "Rank": 42314,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta Hospital,\nUttar Pradesh,Medanta hospital, Sec A pocket 1, Amar Shaheed Path Sushant Golf City Lucknow, Uttar Pradesh, 226030",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e89"
    },
    "Rank": 42316,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e8a"
    },
    "Rank": 42325,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e8b"
    },
    "Rank": 42332,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Ramesh Cardiac and Multi Speciality Hospital,,Collector office road, Nagarampalem, Guntur , AP, Andhra Pradesh, 522004",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e8c"
    },
    "Rank": 42335,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "G B Pant Institute of Post Graduate Medical Education and Research,G B Pant Institute of Post Graduate Medical Education and Research, Delhi (NCT), 110007",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e8d"
    },
    "Rank": 42338,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e8e"
    },
    "Rank": 42341,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e8f"
    },
    "Rank": 42346,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Daga Memorial Women Government Hospital Nagpur, ,Ganjakhet gandhibagh nagpur, Maharashtra, 440008",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e90"
    },
    "Rank": 42352,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e91"
    },
    "Rank": 42356,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Area Hospital,,Area Hospital  Near YSR Statue Victoriapet Adoni, Andhra Pradesh, 518301",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e92"
    },
    "Rank": 42357,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Civil Hospital GURDASPUR, Punjab,smodhgsp@gmail.com, Punjab, 143521",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e93"
    },
    "Rank": 42362,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e94"
    },
    "Rank": 42371,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHAS NATIONAL INSTITUTE OF SPORTS, PATIALA,NETAJI SUBHAS NATIONAL INSTITUTE OF SPORTS, OLD MOTIBAGH, PATIALA, Punjab, 147001",
    "Course": "DIP. IN SPORTS MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e95"
    },
    "Rank": 42379,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e96"
    },
    "Rank": 42381,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e97"
    },
    "Rank": 42386,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e98"
    },
    "Rank": 42388,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e99"
    },
    "Rank": 42390,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e9a"
    },
    "Rank": 42395,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ojas Super Speciality Hospital, Panchkula,\nHaryana,H1, NH 7, Sector 26, Panchkula Extension, Panchkula, Haryana, Haryana, 134116",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e9b"
    },
    "Rank": 42398,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e9c"
    },
    "Rank": 42399,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e9d"
    },
    "Rank": 42400,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e9e"
    },
    "Rank": 42408,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06e9f"
    },
    "Rank": 42416,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital, B.H.Road, Tumakuru-572101, Karnataka, 572101",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ea0"
    },
    "Rank": 42442,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ea1"
    },
    "Rank": 42463,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ea2"
    },
    "Rank": 42469,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ea3"
    },
    "Rank": 42470,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Diamond Harbour Government Medical College And Hospital, ,Harindanga, New Town, Word No- 4, P.O and P.S- Diamond Harbour, Dist- South 24 Parganas, West Bengal, West Bengal, 743331",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ea4"
    },
    "Rank": 42496,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ea5"
    },
    "Rank": 42498,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ea6"
    },
    "Rank": 42504,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagwan Mahavir Cancer Hospital and Research Centre, ,Managed by K G Kothari Memorial Trust\nJawahar Lal Nehru Marg, Jaipur 302 017, Rajasthan, 302017",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ea7"
    },
    "Rank": 42508,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ea8"
    },
    "Rank": 42530,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ea9"
    },
    "Rank": 42531,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06eaa"
    },
    "Rank": 42533,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06eab"
    },
    "Rank": 42534,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06eac"
    },
    "Rank": 42544,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagwan Mahavir Cancer Hospital and Research Centre, ,Managed by K G Kothari Memorial Trust\nJawahar Lal Nehru Marg, Jaipur 302 017, Rajasthan, 302017",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ead"
    },
    "Rank": 42559,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06eae"
    },
    "Rank": 42564,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital, ,New Road, Kovilpatti, Thoothukudi District, Tamil Nadu, 628501",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06eaf"
    },
    "Rank": 42569,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06eb0"
    },
    "Rank": 42573,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Vinoba Bhave Civil Hospital,,S R Delkar Marg, Saily Road, Silvassa, Dadra And Nagar Haveli, 396230",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06eb1"
    },
    "Rank": 42576,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06eb2"
    },
    "Rank": 42578,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06eb3"
    },
    "Rank": 42585,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06eb4"
    },
    "Rank": 42591,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06eb5"
    },
    "Rank": 42604,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vijay Marie Hospital and Educational Society, ,10-1-673 1 Veernagar Khairatabad, Telangana, 500004",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06eb6"
    },
    "Rank": 42611,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06eb7"
    },
    "Rank": 42612,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06eb8"
    },
    "Rank": 42618,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06eb9"
    },
    "Rank": 42620,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06eba"
    },
    "Rank": 42626,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ebb"
    },
    "Rank": 42628,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "V Y Institute of Medical Science, ,Near Kamal Vihar, Dhamtari Road, Raipur, Chhattisgarh, 492001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ebc"
    },
    "Rank": 42633,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ebd"
    },
    "Rank": 42634,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ebe"
    },
    "Rank": 42639,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ebf"
    },
    "Rank": 42654,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ec0"
    },
    "Rank": 42655,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ec1"
    },
    "Rank": 42660,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ec2"
    },
    "Rank": 42661,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ec3"
    },
    "Rank": 42662,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ec4"
    },
    "Rank": 42663,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ec5"
    },
    "Rank": 42664,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. GERIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ec6"
    },
    "Rank": 42665,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ec7"
    },
    "Rank": 42666,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ec8"
    },
    "Rank": 42669,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ec9"
    },
    "Rank": 42671,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06eca"
    },
    "Rank": 42672,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,10 Biplabi Haren Ghosh Sarani Howrah, West Bengal, 711101",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ecb"
    },
    "Rank": 42673,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital,\n,General Hospital Kanakapura near KSRTC garage Melekote Kanakpura taluk Ramanagara district, Karnataka, 562117",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ecc"
    },
    "Rank": 42677,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College & Attached Hospital Bharatpur,GOVERNMENT MEDICAL COLLEGE BHARATPUR VILLAGE RAMPURA NH 21, Rajasthan, 321001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ecd"
    },
    "Rank": 42680,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ece"
    },
    "Rank": 42691,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yashoda Super Speciality Hospital, Uttar Pradesh,H-1, 24,26,27, KAUSHAMBI, NEAR DABUR CHOWK, GHAZIABAD, Uttar Pradesh, 201010",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ecf"
    },
    "Rank": 42693,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ed0"
    },
    "Rank": 42696,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ed1"
    },
    "Rank": 42698,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jabalpur Hospital and Research Centre, Jabalpur,\nMadhya Pradesh,Russel Chowk Napier Town Jabalpur, Madhya Pradesh, 482002",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ed2"
    },
    "Rank": 42710,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ed3"
    },
    "Rank": 42713,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ed4"
    },
    "Rank": 42730,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ed5"
    },
    "Rank": 42740,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ed6"
    },
    "Rank": 42762,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ed7"
    },
    "Rank": 42764,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ed8"
    },
    "Rank": 42765,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ed9"
    },
    "Rank": 42769,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "JBMM District Hospital , ,JBMM DISTRICT HOSPITAL RAM BAGH PHATAK NANDAN CINEMA\nCHOWK AMRITSAR AMRITSAR AMRITSAR IN\n143001, Punjab, 143001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06eda"
    },
    "Rank": 42775,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06edb"
    },
    "Rank": 42791,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.R.A. Medical College,\n,Saddarpur Akabarpur Tanda Road Post Surapur\nDistrict Ambedkarnagar, Uttar Pradesh, 224227",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06edc"
    },
    "Rank": 42795,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06edd"
    },
    "Rank": 42799,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ede"
    },
    "Rank": 42802,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06edf"
    },
    "Rank": 42809,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ee0"
    },
    "Rank": 42813,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,SECTOR-6, PANCHKULA, HARYANA-134108, Haryana, 134108",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ee1"
    },
    "Rank": 42820,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ee2"
    },
    "Rank": 42825,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ee3"
    },
    "Rank": 42829,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ee4"
    },
    "Rank": 42838,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ee5"
    },
    "Rank": 42844,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ee6"
    },
    "Rank": 42845,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ee7"
    },
    "Rank": 42848,
    "Allotted Quota": "All India",
    "Allotted Institute": "BHOPAL MEMORIAL HOSPITAL AND RESEARCH CENTRE, BHOPAL,Raisen By pass\nRoad, Bhopal- 462038 (M.P.), Madhya Pradesh, 462038",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ee8"
    },
    "Rank": 42852,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital\nNext to Sainik School second gate Athani Road, Vijayapura Karnataka 586103, Karnataka, 586103",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ee9"
    },
    "Rank": 42855,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06eea"
    },
    "Rank": 42861,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES,CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, Chhattisgarh, 495001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06eeb"
    },
    "Rank": 42870,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06eec"
    },
    "Rank": 42874,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06eed"
    },
    "Rank": 42876,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06eee"
    },
    "Rank": 42882,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College,Theni ,Government Medical College,Theni, Tamil Nadu, 625512",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06eef"
    },
    "Rank": 42886,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ef0"
    },
    "Rank": 42895,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ef1"
    },
    "Rank": 42899,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ef2"
    },
    "Rank": 42900,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ef3"
    },
    "Rank": 42903,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital,,GMERS Medical College and Hospital Campus, Dharpur, Patan-Unjha State Highway, Patan (North Gujarat,, Gujarat, 384265",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ef4"
    },
    "Rank": 42911,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ef5"
    },
    "Rank": 42919,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "DISTRICT HOSPITAL , Madhya Pradesh-456001,csurgeonujjmp@nic.in, Madhya Pradesh, 456001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ef6"
    },
    "Rank": 42940,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government General Hospital, ,GOVERNMENT GENERAL HOSPITAL, ALANPUR LINK ROAD SAWAI MADHOPUR 322001, Rajasthan, 322001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ef7"
    },
    "Rank": 42942,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital\nNext to Sainik School second gate Athani Road, Vijayapura Karnataka 586103, Karnataka, 586103",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ef8"
    },
    "Rank": 42945,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ef9"
    },
    "Rank": 42955,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06efa"
    },
    "Rank": 42972,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06efb"
    },
    "Rank": 42973,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06efc"
    },
    "Rank": 43000,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06efd"
    },
    "Rank": 43006,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06efe"
    },
    "Rank": 43007,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06eff"
    },
    "Rank": 43008,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f00"
    },
    "Rank": 43028,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Vinoba Bhave Civil Hospital,,S R Delkar Marg, Saily Road, Silvassa, Dadra And Nagar Haveli, 396230",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f01"
    },
    "Rank": 43044,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir C V Raman General Hospital, ,80 FEET ROAD INDIRANAGARA BANGALORE, Karnataka, 560038",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f02"
    },
    "Rank": 43052,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f03"
    },
    "Rank": 43055,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f04"
    },
    "Rank": 43058,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES,CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, Chhattisgarh, 495001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f05"
    },
    "Rank": 43069,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f06"
    },
    "Rank": 43093,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f07"
    },
    "Rank": 43103,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f08"
    },
    "Rank": 43112,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "APL and ANR Area, Andhra Pradesh,Area Hospital, Gudiwada\nOPP Head Post office Road, Fire station road, Gudiwada, Andhra Pradesh, 521301",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f09"
    },
    "Rank": 43116,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f0a"
    },
    "Rank": 43126,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Central Hospital, Gandhinagar, ,CENTRAL HOSPITAL, GANDHINAGAR, GANDHINAGAR COLONY,\nKANKE ROAD,\nRANCHI, Jharkhand, 834008",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f0b"
    },
    "Rank": 43133,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f0c"
    },
    "Rank": 43137,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rabindranath Tagore International Institute of Cardiac Sciences, ,PREMISES NO. 1489 124 MUKUNDAPUR  E M BYPASS  KOLKATA-700099, West Bengal, 700099",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f0d"
    },
    "Rank": 43141,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f0e"
    },
    "Rank": 43146,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f0f"
    },
    "Rank": 43147,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f10"
    },
    "Rank": 43149,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f11"
    },
    "Rank": 43150,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f12"
    },
    "Rank": 43152,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f13"
    },
    "Rank": 43169,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Civil Hospital, Gurugram ,\nHaryana,Civil Hospital Sector-10, Gurugram, Haryana, Haryana, 122001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f14"
    },
    "Rank": 43178,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f15"
    },
    "Rank": 43183,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f16"
    },
    "Rank": 43189,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f17"
    },
    "Rank": 43190,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.S. ( Traumatology and Surgery )",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f18"
    },
    "Rank": 43192,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Charak Hospital and Research Center, Uttar Pradesh,Hardoi Road, Dubagga, Lucknow, Uttar Pradesh, 226003",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f19"
    },
    "Rank": 43195,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f1a"
    },
    "Rank": 43198,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f1b"
    },
    "Rank": 43200,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f1c"
    },
    "Rank": 43203,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Taluk General Hospital,\n,Taluk General Hospital\nKadur Nh 206 Kadur , Chikmagalur District Karnataka 577548, Karnataka, 577548",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f1d"
    },
    "Rank": 43204,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f1e"
    },
    "Rank": 43213,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f1f"
    },
    "Rank": 43225,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f20"
    },
    "Rank": 43227,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f21"
    },
    "Rank": 43230,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f22"
    },
    "Rank": 43236,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f23"
    },
    "Rank": 43237,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital,,GMERS Medical College and Hospital Campus, Dharpur, Patan-Unjha State Highway, Patan (North Gujarat,, Gujarat, 384265",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f24"
    },
    "Rank": 43270,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Autonomous State Medical College and Allied Pandit Ram Prasad Bismil Memorial Hospital,\n,ASMC, JIGNERA, kanth road, shahjahanpur, Uttar Pradesh, 242001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f25"
    },
    "Rank": 43271,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f26"
    },
    "Rank": 43274,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f27"
    },
    "Rank": 43275,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f28"
    },
    "Rank": 43276,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f29"
    },
    "Rank": 43279,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f2a"
    },
    "Rank": 43280,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f2b"
    },
    "Rank": 43281,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "DIP. IN FORENSIC MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f2c"
    },
    "Rank": 43285,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f2d"
    },
    "Rank": 43290,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f2e"
    },
    "Rank": 43307,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f2f"
    },
    "Rank": 43314,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f30"
    },
    "Rank": 43321,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saket Hospital,10, Meera Marg, Agarwal Farm, Masarover, Jaipur, Rajasthan, 302020",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f31"
    },
    "Rank": 43323,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Durgapur Steel Plant Hospital, ,J M Sengupta Road, B Zone,  Durgapur, Dist- Paschim Bardhaman, West Bengal, 713205",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f32"
    },
    "Rank": 43324,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f33"
    },
    "Rank": 43329,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f34"
    },
    "Rank": 43334,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f35"
    },
    "Rank": 43339,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f36"
    },
    "Rank": 43348,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Multispecialty Hospital, ,Narayana Multispecialty Hospital, Jaipur Sec-28,Kumbha Marg, Pratap Nagar Sanganer 302033",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f37"
    },
    "Rank": 43351,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f38"
    },
    "Rank": 43354,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Associated Hospital Government Medical College, ,Kanth Bagh Baramulla, Jammu And Kashmir, 193103",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f39"
    },
    "Rank": 43355,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f3a"
    },
    "Rank": 43360,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lala Harbhagwan Das Memorial and Dr Prem Hospital, ,BISHAN SWAROOP COLONY, OPP. BUS STAND, Haryana, 132103",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f3b"
    },
    "Rank": 43363,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f3c"
    },
    "Rank": 43369,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f3d"
    },
    "Rank": 43371,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f3e"
    },
    "Rank": 43379,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f3f"
    },
    "Rank": 43380,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f40"
    },
    "Rank": 43382,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f41"
    },
    "Rank": 43391,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f42"
    },
    "Rank": 43393,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f43"
    },
    "Rank": 43400,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f44"
    },
    "Rank": 43401,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f45"
    },
    "Rank": 43410,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BOLPUR SUB DIVISION HOSPITAL , ,bolpursdhospital@yahoo.com, West Bengal, 731204",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f46"
    },
    "Rank": 43415,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f47"
    },
    "Rank": 43423,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f48"
    },
    "Rank": 43432,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f49"
    },
    "Rank": 43439,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sahara India Medical Institute Limited,\nUttar Pradesh,1 Viraj Khand Gomti Nagar, Uttar Pradesh, 226010",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f4a"
    },
    "Rank": 43440,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f4b"
    },
    "Rank": 43441,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f4c"
    },
    "Rank": 43452,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Autonomous State Medical college Society and S.N.M District Hospital, ,DAULATTAPUR JALESAR ROAD NARKHI FIROZABAD, Uttar Pradesh, 283203",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f4d"
    },
    "Rank": 43470,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Felix Hospital,\n,NH-01 SECTOR 137 NOIDA EXPRESSWAY UP, Uttar Pradesh, 201305",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f4e"
    },
    "Rank": 43474,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f4f"
    },
    "Rank": 43476,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f50"
    },
    "Rank": 43484,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yatharth Superspeciality Hospitals,\nUttar Pradesh,HO-01, Sector-01, Greater Noida West, Uttar Pradesh, 201306",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f51"
    },
    "Rank": 43491,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f52"
    },
    "Rank": 43500,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jan Swasthya Sahyog,,,Village and PO Ganiyari - 495112, Dist. Bilaspur,\nChhattisgarh, Chhattisgarh, 495112",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f53"
    },
    "Rank": 43512,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f54"
    },
    "Rank": 43513,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f55"
    },
    "Rank": 43520,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sher-I-Kashmir Institute of Medical Sciences, Jammu and Kashmir,SKIMS SOURA SRINAGAR JAMMU & KASHMIR, Jammu And Kashmir, 190011",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f56"
    },
    "Rank": 43535,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f57"
    },
    "Rank": 43536,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital Bhandara,\nMaharashtra,Santaji Nagar, District Hospital, Bhandara, Maharashtra, 441904",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f58"
    },
    "Rank": 43539,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f59"
    },
    "Rank": 43543,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f5a"
    },
    "Rank": 43550,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f5b"
    },
    "Rank": 43551,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f5c"
    },
    "Rank": 43553,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f5d"
    },
    "Rank": 43555,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f5e"
    },
    "Rank": 43556,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f5f"
    },
    "Rank": 43561,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f60"
    },
    "Rank": 43569,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rural Development Trust Hospital, ,Kadiri Road,Bathalapalli, Anantapur District Andhra Pradesh, Andhra Pradesh, 515661",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f61"
    },
    "Rank": 43573,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f62"
    },
    "Rank": 43577,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. Medical College (Maharani Hospital), Jagdalpur, Chhattisgarh,Late Baliram Kashyap memorial Govt. Medical College, Dimrapal, Jagdalpur Dist-Bastar, Chhattisgarh, 494001",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f63"
    },
    "Rank": 43586,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f64"
    },
    "Rank": 43587,
    "Allotted Quota": "All India",
    "Allotted Institute": "All  India Institute of  Hygiene and Public Health,110, C. R  Avenue, Kolkata, West Bengal, 700073",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f65"
    },
    "Rank": 43589,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. Sports Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f66"
    },
    "Rank": 43595,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f67"
    },
    "Rank": 43596,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f68"
    },
    "Rank": 43601,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f69"
    },
    "Rank": 43603,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f6a"
    },
    "Rank": 43606,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f6b"
    },
    "Rank": 43608,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f6c"
    },
    "Rank": 43610,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f6d"
    },
    "Rank": 43612,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f6e"
    },
    "Rank": 43618,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f6f"
    },
    "Rank": 43623,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur and Associated Group of Hospitals Shri Haridev Joshi Hospital,,Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur, Rajasthan, 314001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f70"
    },
    "Rank": 43633,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f71"
    },
    "Rank": 43641,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f72"
    },
    "Rank": 43642,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f73"
    },
    "Rank": 43645,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f74"
    },
    "Rank": 43646,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f75"
    },
    "Rank": 43648,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f76"
    },
    "Rank": 43649,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College Kannauj , Uttar Pradesh,Kannaujmedicalcollege@gmail.com, Uttar Pradesh, 209732",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f77"
    },
    "Rank": 43660,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Bundelkhand Medical College Sagar M.P.,Govt. Bundelkhand Medical College Sagar M.P., Madhya Pradesh, 470001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f78"
    },
    "Rank": 43661,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital,\n,General Hospital Kanakapura near KSRTC garage Melekote Kanakpura taluk Ramanagara district, Karnataka, 562117",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f79"
    },
    "Rank": 43664,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES ADILABAD -504001 TELANGANA STATE, Telangana, 504001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f7a"
    },
    "Rank": 43665,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f7b"
    },
    "Rank": 43667,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f7c"
    },
    "Rank": 43670,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f7d"
    },
    "Rank": 43709,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f7e"
    },
    "Rank": 43721,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f7f"
    },
    "Rank": 43727,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f80"
    },
    "Rank": 43733,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College & Attached Hospital Bharatpur,GOVERNMENT MEDICAL COLLEGE BHARATPUR VILLAGE RAMPURA NH 21, Rajasthan, 321001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f81"
    },
    "Rank": 43737,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f82"
    },
    "Rank": 43743,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f83"
    },
    "Rank": 43745,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f84"
    },
    "Rank": 43762,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f85"
    },
    "Rank": 43764,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f86"
    },
    "Rank": 43766,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f87"
    },
    "Rank": 43769,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Regency Hospital,,A-2, Sarvodaya Nagar, Kanpur, Uttar Pradesh, 208005",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f88"
    },
    "Rank": 43772,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f89"
    },
    "Rank": 43776,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f8a"
    },
    "Rank": 43777,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f8b"
    },
    "Rank": 43787,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f8c"
    },
    "Rank": 43801,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f8d"
    },
    "Rank": 43807,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Super Facility Hospital, Distt.-Azamgarh, Uttar Pradesh,Chakrapanpur, Kanaila, Azamgarh, Uttar Pradesh, 276128",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f8e"
    },
    "Rank": 43809,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gangasheel Advanced Medical Research Institute, Uttar Pradesh,C-17, Deen Dayal Puram, Bareilly - 243122, Uttar Pradesh, 243122",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f8f"
    },
    "Rank": 43813,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f90"
    },
    "Rank": 43820,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f91"
    },
    "Rank": 43833,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f92"
    },
    "Rank": 43835,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f93"
    },
    "Rank": 43841,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f94"
    },
    "Rank": 43844,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. R N Cooper Municipal General Hospital, (Associated with H B T (Hinduhridayasamrat Balasaheb Thackeray) medical College) ,HBTMC AND DR.R.N.COOPER HOSPITAL\nU 15 BHAKTI VEDANTA ROAD JVPD SCHEME VILE PARLE WEST MUMBAI,\nMaharashtra, 400056",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f95"
    },
    "Rank": 43856,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f96"
    },
    "Rank": 43863,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "DIPLOMA IN PUBLIC HEALTH",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f97"
    },
    "Rank": 43878,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f98"
    },
    "Rank": 43883,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Medical College for Women, Khanpur Kalan Sonepat,BPS Govt. Medical College for Women, Khanpur Kalan Sonepat, Haryana, 131305",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f99"
    },
    "Rank": 43895,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f9a"
    },
    "Rank": 43898,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f9b"
    },
    "Rank": 43899,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhandari Hospital and Research Centre, ,138 A VASUNDHRA COLONY GOPAL PURA BYPASS TONK ROAD JAIPUR, Rajasthan, 302018",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f9c"
    },
    "Rank": 43910,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Ramesh Cardiac and Multi Speciality Hospital,,Collector office road, Nagarampalem, Guntur , AP, Andhra Pradesh, 522004",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f9d"
    },
    "Rank": 43923,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f9e"
    },
    "Rank": 43927,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06f9f"
    },
    "Rank": 43932,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fa0"
    },
    "Rank": 43933,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fa1"
    },
    "Rank": 43937,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fa2"
    },
    "Rank": 43938,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fa3"
    },
    "Rank": 43950,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fa4"
    },
    "Rank": 43952,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fa5"
    },
    "Rank": 43954,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhandari Hospital and Research Centre, ,138 A VASUNDHRA COLONY GOPAL PURA BYPASS TONK ROAD JAIPUR, Rajasthan, 302018",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fa6"
    },
    "Rank": 43956,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fa7"
    },
    "Rank": 43959,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fa8"
    },
    "Rank": 43977,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Vinoba Bhave Civil Hospital,,S R Delkar Marg, Saily Road, Silvassa, Dadra And Nagar Haveli, 396230",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fa9"
    },
    "Rank": 44001,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06faa"
    },
    "Rank": 44004,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fab"
    },
    "Rank": 44006,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fac"
    },
    "Rank": 44018,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fad"
    },
    "Rank": 44021,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College & Attached Hospital Bharatpur,GOVERNMENT MEDICAL COLLEGE BHARATPUR VILLAGE RAMPURA NH 21, Rajasthan, 321001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fae"
    },
    "Rank": 44030,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt Amrit Kaur Hospital, ,PRINCIPAL MEDICAL OFFICER GOVT. AMRIT KAUR HOSPITAL BEAWAR, Rajasthan, 305901",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06faf"
    },
    "Rank": 44032,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fb0"
    },
    "Rank": 44033,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fb1"
    },
    "Rank": 44036,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fb2"
    },
    "Rank": 44039,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fb3"
    },
    "Rank": 44051,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fb4"
    },
    "Rank": 44065,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fb5"
    },
    "Rank": 44072,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fb6"
    },
    "Rank": 44077,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fb7"
    },
    "Rank": 44084,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Karnataka,Kodagu Institute of Medical Sciences Madikeri Kodagu, Karnataka, 571201",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fb8"
    },
    "Rank": 44087,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fb9"
    },
    "Rank": 44096,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fba"
    },
    "Rank": 44099,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fbb"
    },
    "Rank": 44103,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fbc"
    },
    "Rank": 44105,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fbd"
    },
    "Rank": 44107,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fbe"
    },
    "Rank": 44113,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fbf"
    },
    "Rank": 44114,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fc0"
    },
    "Rank": 44117,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fc1"
    },
    "Rank": 44154,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Civil Hospital Palwal, Palwal , Haryana,Civil Hospital Palwal, Haryana, 121102",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fc2"
    },
    "Rank": 44157,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Felix Hospital,\n,NH-01 SECTOR 137 NOIDA EXPRESSWAY UP, Uttar Pradesh, 201305",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fc3"
    },
    "Rank": 44167,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fc4"
    },
    "Rank": 44172,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fc5"
    },
    "Rank": 44175,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Civil Hospital, ,CIVIL HOSPITAL, SUBHASH ROAD ROHTAK, Haryana, 124001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fc6"
    },
    "Rank": 44191,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fc7"
    },
    "Rank": 44216,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fc8"
    },
    "Rank": 44219,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fc9"
    },
    "Rank": 44221,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fca"
    },
    "Rank": 44226,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BOLPUR SUB DIVISION HOSPITAL , ,bolpursdhospital@yahoo.com, West Bengal, 731204",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fcb"
    },
    "Rank": 44227,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fcc"
    },
    "Rank": 44233,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fcd"
    },
    "Rank": 44239,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chirayu Hospital, ,HATHOJ, KALWAR ROAD, JAIPUR, Rajasthan, 302012",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fce"
    },
    "Rank": 44246,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fcf"
    },
    "Rank": 44255,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fd0"
    },
    "Rank": 44260,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fd1"
    },
    "Rank": 44261,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fd2"
    },
    "Rank": 44266,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fd3"
    },
    "Rank": 44270,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fd4"
    },
    "Rank": 44289,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fd5"
    },
    "Rank": 44305,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagyoday Tirth Chikitsalay, Sagar,\nMadhya Pradesh,khurai road ,Sagar M.P., Madhya Pradesh, 470002",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fd6"
    },
    "Rank": 44306,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fd7"
    },
    "Rank": 44308,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fd8"
    },
    "Rank": 44310,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (FAMILY MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fd9"
    },
    "Rank": 44319,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fda"
    },
    "Rank": 44323,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fdb"
    },
    "Rank": 44333,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fdc"
    },
    "Rank": 44342,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Bhausaheb Hire Government Medical College,Sri Bhausaheb Hire Government Medical College,Dhule, Maharashtra, 424311",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fdd"
    },
    "Rank": 44351,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fde"
    },
    "Rank": 44357,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fdf"
    },
    "Rank": 44362,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fe0"
    },
    "Rank": 44372,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fe1"
    },
    "Rank": 44376,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fe2"
    },
    "Rank": 44381,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fe3"
    },
    "Rank": 44388,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital Shikaripura,mohshikaripur, Karnataka, 577427",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fe4"
    },
    "Rank": 44398,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fe5"
    },
    "Rank": 44402,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fe6"
    },
    "Rank": 44421,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fe7"
    },
    "Rank": 44428,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fe8"
    },
    "Rank": 44433,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fe9"
    },
    "Rank": 44457,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fea"
    },
    "Rank": 44483,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06feb"
    },
    "Rank": 44484,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fec"
    },
    "Rank": 44488,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fed"
    },
    "Rank": 44491,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fee"
    },
    "Rank": 44495,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chirayu Hospital, ,HATHOJ, KALWAR ROAD, JAIPUR, Rajasthan, 302012",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fef"
    },
    "Rank": 44497,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Vinoba Bhave Civil Hospital,,S R Delkar Marg, Saily Road, Silvassa, Dadra And Nagar Haveli, 396230",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ff0"
    },
    "Rank": 44500,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ff1"
    },
    "Rank": 44514,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Daga Memorial Women Government Hospital Nagpur, ,Ganjakhet gandhibagh nagpur, Maharashtra, 440008",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ff2"
    },
    "Rank": 44519,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ff3"
    },
    "Rank": 44520,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ff4"
    },
    "Rank": 44535,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chamarajanagar Institute of Medical Sciences, Karnataka,CHAMARAJANAGAR INSTITUTE OF MEDICAL SCIENCES, SY. NO 124, YEDAPURA VILLAGE KASABA HOBLI, CHAMARAJANA, Karnataka, 571313",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ff5"
    },
    "Rank": 44545,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government General Hospital, ,GOVERNMENT GENERAL HOSPITAL, ALANPUR LINK ROAD SAWAI MADHOPUR 322001, Rajasthan, 322001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ff6"
    },
    "Rank": 44551,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.R.A. Medical College,\n,Saddarpur Akabarpur Tanda Road Post Surapur\nDistrict Ambedkarnagar, Uttar Pradesh, 224227",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ff7"
    },
    "Rank": 44558,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ff8"
    },
    "Rank": 44562,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES ADILABAD -504001 TELANGANA STATE, Telangana, 504001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ff9"
    },
    "Rank": 44564,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ffa"
    },
    "Rank": 44579,
    "Allotted Quota": "All India",
    "Allotted Institute": "SJP Medical College, Bharatpur,SJP MEDICAL COLLEGE BHARATPUR VILLAGE RAMPURA NH 21, Rajasthan, 321001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ffb"
    },
    "Rank": 44583,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ffc"
    },
    "Rank": 44593,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ffd"
    },
    "Rank": 44603,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital , ,Dist. Hospital Pratapgarh, Banswara Road, Pratapgarh, Rajasthan, 312605",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06ffe"
    },
    "Rank": 44609,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a06fff"
    },
    "Rank": 44617,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07000"
    },
    "Rank": 44626,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Regency Hospital,,A-2, Sarvodaya Nagar, Kanpur, Uttar Pradesh, 208005",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07001"
    },
    "Rank": 44627,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07002"
    },
    "Rank": 44656,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07003"
    },
    "Rank": 44667,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07004"
    },
    "Rank": 44672,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07005"
    },
    "Rank": 44680,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Choudhury Eye Hospital And Research Centre, Assam,Shyamaprasad Road, Silchar-1, Assam, 788001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07006"
    },
    "Rank": 44681,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07007"
    },
    "Rank": 44682,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Multispecialty Hospital, ,Narayana Multispecialty Hospital, Jaipur Sec-28,Kumbha Marg, Pratap Nagar Sanganer 302033",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07008"
    },
    "Rank": 44690,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07009"
    },
    "Rank": 44692,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0700a"
    },
    "Rank": 44693,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0700b"
    },
    "Rank": 44697,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Institute of Medical Sciences (SVIMS), A State University estd. by Govt. of A.P.,Sri Venkateswara Institute of Medical Sciences, SVIMS, Alipiri Road, Tirupati, Andhra Pradesh, 517507",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0700c"
    },
    "Rank": 44698,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0700d"
    },
    "Rank": 44703,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sahara India Medical Institute Limited,\nUttar Pradesh,1 Viraj Khand Gomti Nagar, Uttar Pradesh, 226010",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0700e"
    },
    "Rank": 44713,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S.K.Hospital, ,SUPERINTENDENT RNO 36  GOVT SK HOSPITAL  SILVER JUBLI ROAD  SIKAR  RAJ, Rajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0700f"
    },
    "Rank": 44717,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07010"
    },
    "Rank": 44728,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yatharth Superspeciality Hospitals,\nUttar Pradesh,HO-01, Sector-01, Greater Noida West, Uttar Pradesh, 201306",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07011"
    },
    "Rank": 44731,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07012"
    },
    "Rank": 44734,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07013"
    },
    "Rank": 44736,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sanjay Gandhi Postgarduate Institute of Medical Sciences,Sanjay Gandhi Postgarduate Institute of Medical Sciences, Uttar Pradesh, 226014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07014"
    },
    "Rank": 44743,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07015"
    },
    "Rank": 44749,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07016"
    },
    "Rank": 44762,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Balrampur Hospital, ,Gola Ganj, lucknow, Uttar Pradesh, 226018",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07017"
    },
    "Rank": 44767,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur and Associated Group of Hospitals Shri Haridev Joshi Hospital,,Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur, Rajasthan, 314001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07018"
    },
    "Rank": 44773,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07019"
    },
    "Rank": 44777,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Allopathic Medical College, Uttar Pradesh,RANI DURGAVATI MEDICAL COLLEGE NARAINI ROAD BANDA, Uttar Pradesh, 210001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0701a"
    },
    "Rank": 44779,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0701b"
    },
    "Rank": 44785,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0701c"
    },
    "Rank": 44788,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0701d"
    },
    "Rank": 44792,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0701e"
    },
    "Rank": 44795,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0701f"
    },
    "Rank": 44796,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07020"
    },
    "Rank": 44809,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MAXCARE Hospital, ,6-1-236, Near Kuda Office Line Sai Nagar, Opposite Ashoka Hotel, Hanamkonda, Telangana 506001, Telangana, 506001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07021"
    },
    "Rank": 44812,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nizam`s Institute of Medical Sciences, ,NIZAMS INSTITUTE OF MEDICAL SCIENCES PANJAGUTTA HYDERABAD, Telangana, 500082",
    "Course": "(NBEMS) IMMUNO- HAEMATOLOGY AND BLOOD TRANSFUSION",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07022"
    },
    "Rank": 44813,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (Hospital Administration)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07023"
    },
    "Rank": 44814,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07024"
    },
    "Rank": 44816,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07025"
    },
    "Rank": 44819,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07026"
    },
    "Rank": 44831,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07027"
    },
    "Rank": 44832,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07028"
    },
    "Rank": 44846,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07029"
    },
    "Rank": 44851,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0702a"
    },
    "Rank": 44854,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Choudhury Eye Hospital And Research Centre, Assam,Shyamaprasad Road, Silchar-1, Assam, 788001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0702b"
    },
    "Rank": 44856,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0702c"
    },
    "Rank": 44860,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0702d"
    },
    "Rank": 44866,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0702e"
    },
    "Rank": 44881,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0702f"
    },
    "Rank": 44883,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07030"
    },
    "Rank": 44887,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07031"
    },
    "Rank": 44888,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saheed Laxman Nayak Medical College and Hospital,Orissa,SAHEED LAXMAN NAYAK MEDICAL COLLEGE AND HOSPITAL KORAPUT, JANIGUDA ODISHA KORAPUT 764020, Odisha, 764020",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07032"
    },
    "Rank": 44889,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07033"
    },
    "Rank": 44893,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07034"
    },
    "Rank": 44895,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalpana Chawla Govt Medical College, Karnal, Haryana,Kalpana Chawla Government Medical College Model Town Karnal 132001 Haryana, Haryana, 132001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07035"
    },
    "Rank": 44908,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences Ltd, ,Dargamitta, Nellore, Andhra Pradesh, 524003",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07036"
    },
    "Rank": 44924,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07037"
    },
    "Rank": 44933,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Christian Institute of Health Sciences and Research, ,4th Mile, Central Jail Road, Dimapur, Nagaland, 797115",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07038"
    },
    "Rank": 44934,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07039"
    },
    "Rank": 44961,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruban Memorial Hospital, Bihar,19 Patliputra Colony Near Patliputra Golambar Patna, Bihar, 800013",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0703a"
    },
    "Rank": 44963,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0703b"
    },
    "Rank": 44971,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0703c"
    },
    "Rank": 44987,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chamarajanagar Institute of Medical Sciences, Karnataka,CHAMARAJANAGAR INSTITUTE OF MEDICAL SCIENCES, SY. NO 124, YEDAPURA VILLAGE KASABA HOBLI, CHAMARAJANA, Karnataka, 571313",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0703d"
    },
    "Rank": 44989,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pimpri Chichwad Municipal Corporation Postgraduate Institute Y.C.M.Hospital Pimpri,Pune 18,Dean Office, Y.C.M.Hospital, Sant Tukaramnagar Pimpri, Pune, Maharashtra, 411018",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0703e"
    },
    "Rank": 44994,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S.K.Hospital, ,SUPERINTENDENT RNO 36  GOVT SK HOSPITAL  SILVER JUBLI ROAD  SIKAR  RAJ, Rajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0703f"
    },
    "Rank": 45002,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07040"
    },
    "Rank": 45015,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07041"
    },
    "Rank": 45035,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sumitra Hospital,\nUttar Pradesh,A-119A SECTOR-35 NOIDA, Uttar Pradesh, 201301",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07042"
    },
    "Rank": 45047,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College,Fakhruddin Ali Ahmed Medical College, Assam, 781301",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07043"
    },
    "Rank": 45049,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Eastern Indira Gandhi Regional Institute of Health & Medical Sciences, Shillong,North Eastern Indira Gandhi Regional Institute of Health & Medical Sciences, Shillong, Meghalaya, 793018",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07044"
    },
    "Rank": 45050,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07045"
    },
    "Rank": 45055,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07046"
    },
    "Rank": 45064,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07047"
    },
    "Rank": 45065,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07048"
    },
    "Rank": 45071,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagyoday Tirth Chikitsalay, Sagar,\nMadhya Pradesh,khurai road ,Sagar M.P., Madhya Pradesh, 470002",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07049"
    },
    "Rank": 45072,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Gondia,Nehru Chowk, KTS General Hospital Campus, Govt. Medical College, Gondia. 441601. Maharashtra., Maharashtra, 441601",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0704a"
    },
    "Rank": 45073,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur and Associated Group of Hospitals Shri Haridev Joshi Hospital,,Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur, Rajasthan, 314001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0704b"
    },
    "Rank": 45075,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0704c"
    },
    "Rank": 45081,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0704d"
    },
    "Rank": 45090,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0704e"
    },
    "Rank": 45112,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government General Hospital, Principal Medical Officer General hospital,\n,Rajiv Gandhi Government General Hospital, near bijli ghar circle, ALWAR, Rajasthan, 301001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0704f"
    },
    "Rank": 45130,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07050"
    },
    "Rank": 45134,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07051"
    },
    "Rank": 45150,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07052"
    },
    "Rank": 45161,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Capital Hospital, Orissa,director.chb@gmail.com, Odisha, 751001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07053"
    },
    "Rank": 45173,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07054"
    },
    "Rank": 45205,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07055"
    },
    "Rank": 45208,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07056"
    },
    "Rank": 45213,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07057"
    },
    "Rank": 45223,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07058"
    },
    "Rank": 45232,
    "Allotted Quota": "All India",
    "Allotted Institute": "Murshidabad Medical College and Hospital,Station Road, Berhampore, Murshidabad, West Bengal, 742101",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07059"
    },
    "Rank": 45242,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0705a"
    },
    "Rank": 45251,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0705b"
    },
    "Rank": 45266,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0705c"
    },
    "Rank": 45272,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0705d"
    },
    "Rank": 45278,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0705e"
    },
    "Rank": 45281,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0705f"
    },
    "Rank": 45284,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07060"
    },
    "Rank": 45289,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07061"
    },
    "Rank": 45294,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07062"
    },
    "Rank": 45301,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Autonomous State Medical College and Allied Pandit Ram Prasad Bismil Memorial Hospital,\n,ASMC, JIGNERA, kanth road, shahjahanpur, Uttar Pradesh, 242001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07063"
    },
    "Rank": 45310,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07064"
    },
    "Rank": 45316,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07065"
    },
    "Rank": 45329,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07066"
    },
    "Rank": 45331,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07067"
    },
    "Rank": 45362,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07068"
    },
    "Rank": 45363,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,SECTOR-6, PANCHKULA, HARYANA-134108, Haryana, 134108",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07069"
    },
    "Rank": 45364,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0706a"
    },
    "Rank": 45365,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,HBT Medical College and Dr R N Cooper Municipal General Hospital, Bhaktivedanta Swami Rd, JVPD Schem, Maharashtra, 400056",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0706b"
    },
    "Rank": 45380,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0706c"
    },
    "Rank": 45384,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0706d"
    },
    "Rank": 45385,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0706e"
    },
    "Rank": 45389,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0706f"
    },
    "Rank": 45396,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan , Rajasthan-311001,Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan, Rajasthan, 311001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07070"
    },
    "Rank": 45399,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,HBT Medical College and Dr R N Cooper Municipal General Hospital, Bhaktivedanta Swami Rd, JVPD Schem, Maharashtra, 400056",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07071"
    },
    "Rank": 45410,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07072"
    },
    "Rank": 45423,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07073"
    },
    "Rank": 45426,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07074"
    },
    "Rank": 45429,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (Hospital Administration)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07075"
    },
    "Rank": 45434,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Government Medical College, Vidisha,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA MP, Madhya Pradesh, 464001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07076"
    },
    "Rank": 45437,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07077"
    },
    "Rank": 45438,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "North 24 Parganas District Hospital, ,BANAMALIPUR BARASAT WEST BENGAL, West Bengal, 700124",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07078"
    },
    "Rank": 45439,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sambhu Nath Pandit Hospital, West Bengal,11 , Lala Lajpat Rai Sarani , Kolkata, West Bengal, 700020",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07079"
    },
    "Rank": 45445,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0707a"
    },
    "Rank": 45460,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0707b"
    },
    "Rank": 45464,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GENERAL HOSPITAL MORBI,GENERAL HOSPITAL MORBI, GANDHI CHOKE NEAR TOWN HALL MORBI, Gujarat, 363641",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0707c"
    },
    "Rank": 45465,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0707d"
    },
    "Rank": 45476,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pt. Jawaharlal Nehru Govt. Medical College, ,Akahnchandi Palace Near Luxmi Narayan Temple Tehsil and Dist Chamba, Himachal Pradesh, 176310",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0707e"
    },
    "Rank": 45480,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Hospital, ,principal.mc.barmer@rajasthan.gov.in, Rajasthan, 344001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0707f"
    },
    "Rank": 45488,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07080"
    },
    "Rank": 45489,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Yatharth Superspeciality Hospitals,\nUttar Pradesh,HO-01, Sector-01, Greater Noida West, Uttar Pradesh, 201306",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07081"
    },
    "Rank": 45518,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07082"
    },
    "Rank": 45525,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07083"
    },
    "Rank": 45530,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shija Hospitals and Research Institute, ,SHIJA HOSPITALS AND RESEARCH INSTITUTE, LANGOL, LAMPHELPAT, IMPHAL WEST, MANIPUR 795004, Manipur, 795004",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07084"
    },
    "Rank": 45538,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MAXCARE Hospital, ,6-1-236, Near Kuda Office Line Sai Nagar, Opposite Ashoka Hotel, Hanamkonda, Telangana 506001, Telangana, 506001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07085"
    },
    "Rank": 45542,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07086"
    },
    "Rank": 45543,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07087"
    },
    "Rank": 45551,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07088"
    },
    "Rank": 45555,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07089"
    },
    "Rank": 45557,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0708a"
    },
    "Rank": 45562,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVERNMENT MEDICAL COLLEGE,KANYAKUMARI GOVERNMENT MEDICAL COLLEGE, Tamil Nadu, 629201",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0708b"
    },
    "Rank": 45563,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SNR District Hospital,,SNR DISTRICT HOSPITAL, KOLAR KARNATAKA-563101,\nKarnataka, 563101",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0708c"
    },
    "Rank": 45575,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0708d"
    },
    "Rank": 45577,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Capital Hospital, Orissa,director.chb@gmail.com, Odisha, 751001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0708e"
    },
    "Rank": 45579,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. District Civil Hospital, ,DIS GOVT HOSPITAL, SRI GANGANAGAR, Rajasthan, 335001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0708f"
    },
    "Rank": 45594,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rural Development Trust Hospital, ,Kadiri Road,Bathalapalli, Anantapur District Andhra Pradesh, Andhra Pradesh, 515661",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07090"
    },
    "Rank": 45596,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07091"
    },
    "Rank": 45598,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07092"
    },
    "Rank": 45605,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07093"
    },
    "Rank": 45608,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07094"
    },
    "Rank": 45609,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07095"
    },
    "Rank": 45624,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07096"
    },
    "Rank": 45633,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07097"
    },
    "Rank": 45640,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07098"
    },
    "Rank": 45664,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07099"
    },
    "Rank": 45667,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0709a"
    },
    "Rank": 45669,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0709b"
    },
    "Rank": 45679,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0709c"
    },
    "Rank": 45695,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0709d"
    },
    "Rank": 45699,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Hospital, ,principal.mc.barmer@rajasthan.gov.in, Rajasthan, 344001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0709e"
    },
    "Rank": 45748,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0709f"
    },
    "Rank": 45761,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070a0"
    },
    "Rank": 45769,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070a1"
    },
    "Rank": 45776,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070a2"
    },
    "Rank": 45779,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lala Harbhagwan Das Memorial and Dr Prem Hospital, ,BISHAN SWAROOP COLONY, OPP. BUS STAND, Haryana, 132103",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070a3"
    },
    "Rank": 45788,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070a4"
    },
    "Rank": 45793,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070a5"
    },
    "Rank": 45800,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Vasantrao Naik Govt Medical College Yavatmal,Shri Vasantrao Naik Govt Medical College Yavatmal, Maharashtra, 445001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070a6"
    },
    "Rank": 45825,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute Of Medical Sciences, Kalaburagi,VEERESH NAGAR SEDAM ROAD KALABURAGI, Karnataka, 585105",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070a7"
    },
    "Rank": 45826,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070a8"
    },
    "Rank": 45836,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070a9"
    },
    "Rank": 45841,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070aa"
    },
    "Rank": 45842,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070ab"
    },
    "Rank": 45846,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubnagar,Government Medical College, Yedira, Mahabubnagar, Telangana state, Telangana, 509002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070ac"
    },
    "Rank": 45858,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070ad"
    },
    "Rank": 45859,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070ae"
    },
    "Rank": 45868,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070af"
    },
    "Rank": 45875,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Bhausaheb Hire Government Medical College,Sri Bhausaheb Hire Government Medical College,Dhule, Maharashtra, 424311",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070b0"
    },
    "Rank": 45881,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AZAMGARH,Chakrapan Pur, Azamgarh (U.P.),\nUttar Pradesh, 276128",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070b1"
    },
    "Rank": 45882,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070b2"
    },
    "Rank": 45887,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070b3"
    },
    "Rank": 45891,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070b4"
    },
    "Rank": 45894,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070b5"
    },
    "Rank": 45914,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070b6"
    },
    "Rank": 45926,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMR Varalakshmi Care Hospital, Andhra Pradesh,GMR Varalakshmi CARE Hospital GMR Nagar ,\nRajam,\nVizianagaram District, Andhra Pradesh, 532127",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070b7"
    },
    "Rank": 45928,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital\nNext to Sainik School second gate Athani Road, Vijayapura Karnataka 586103, Karnataka, 586103",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070b8"
    },
    "Rank": 45932,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070b9"
    },
    "Rank": 45939,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,Opposite to Shivajinagar Bus stop, Shivajinagar, Bengaluru., Karnataka, 560001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070ba"
    },
    "Rank": 45941,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070bb"
    },
    "Rank": 45947,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE, PUDUCHERRY,INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE,\nPUDUCHERRY, Puducherry, 605009",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070bc"
    },
    "Rank": 45951,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE - KANNUR,GOVERNMENT MEDICAL COLLEGE\n- KANNUR, Kerala, 670503",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070bd"
    },
    "Rank": 45967,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070be"
    },
    "Rank": 45975,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Allopathic Medical College, Uttar Pradesh,RANI DURGAVATI MEDICAL COLLEGE NARAINI ROAD BANDA, Uttar Pradesh, 210001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070bf"
    },
    "Rank": 45993,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070c0"
    },
    "Rank": 45996,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070c1"
    },
    "Rank": 46004,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "FIMS HOSPITAL(FRANK INSTITUTE OF MEDICAL SCIENCES), Haryana,Sonipat-\nBahalgarh Road, Sonipat-NCR, Sonipat, Haryana, 131001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070c2"
    },
    "Rank": 46010,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dholpur,Old City Road Dholpur, Rajasthan, 328001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070c3"
    },
    "Rank": 46014,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070c4"
    },
    "Rank": 46016,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District hospital chitradurga, B D road beside DHO office Chitradurga, Karnataka, 577501",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070c5"
    },
    "Rank": 46019,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070c6"
    },
    "Rank": 46021,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE - KANNUR,GOVERNMENT MEDICAL COLLEGE\n- KANNUR, Kerala, 670503",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070c7"
    },
    "Rank": 46026,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070c8"
    },
    "Rank": 46028,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Suhel Dev Medical College and Mahrishi Balark Hospital, Uttar Pradesh,MSD AS Medical College KDC Road Bahraich, Uttar Pradesh, 271801",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070c9"
    },
    "Rank": 46031,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Maharashtra,Barshi Road Beed, Maharashtra, 431122",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070ca"
    },
    "Rank": 46034,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ispat General Hospital,,Ispat General Hospital Rourkela Steel Plant SAIL Rourkela 769005, Odisha, 769005",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070cb"
    },
    "Rank": 46040,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, Karnataka,General Hospital Tiptur Tumkur District\nKarnataka\nPin 572201, Karnataka, 572201",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070cc"
    },
    "Rank": 46041,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070cd"
    },
    "Rank": 46054,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070ce"
    },
    "Rank": 46074,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ispat General Hospital,,Ispat General Hospital Rourkela Steel Plant SAIL Rourkela 769005, Odisha, 769005",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070cf"
    },
    "Rank": 46079,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070d0"
    },
    "Rank": 46089,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070d1"
    },
    "Rank": 46096,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070d2"
    },
    "Rank": 46115,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,10 Biplabi Haren Ghosh Sarani Howrah, West Bengal, 711101",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070d3"
    },
    "Rank": 46116,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070d4"
    },
    "Rank": 46119,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070d5"
    },
    "Rank": 46121,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070d6"
    },
    "Rank": 46125,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deben Mahato (Sadar) Hospital,,Main Road Near SP office PO Purulia Main PS Purulia Town Dist Purulia West Bengal, West Bengal, 723101",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070d7"
    },
    "Rank": 46126,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070d8"
    },
    "Rank": 46146,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Midland Healthcare and Research Centre, Uttar Pradesh,B-55 Mandir Marg, Mahanagar,Lucknow., Uttar Pradesh, 226006",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070d9"
    },
    "Rank": 46153,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070da"
    },
    "Rank": 46154,
    "Allotted Quota": "All India",
    "Allotted Institute": "NSC Government Medical College, Khandwa (MP),LUV KUSH NAGAR,MUNDI NAGAR, KHANDWA, Madhya Pradesh, 450001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070db"
    },
    "Rank": 46155,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070dc"
    },
    "Rank": 46156,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070dd"
    },
    "Rank": 46158,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070de"
    },
    "Rank": 46160,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070df"
    },
    "Rank": 46166,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070e0"
    },
    "Rank": 46176,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070e1"
    },
    "Rank": 46179,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070e2"
    },
    "Rank": 46203,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070e3"
    },
    "Rank": 46212,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shaheed Hasan Khan Mewati Government Medical College , ,Shaheed Hasan Khan Mewati Government Medical College Nalhar Nuh, Haryana, 122107",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070e4"
    },
    "Rank": 46220,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070e5"
    },
    "Rank": 46225,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070e6"
    },
    "Rank": 46232,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070e7"
    },
    "Rank": 46238,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070e8"
    },
    "Rank": 46240,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070e9"
    },
    "Rank": 46247,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sheth Vadilal Sarabhai General Hospital and Sheth Chinai Prasuti Hospital, Gujarat,SHETH VADILAL SARABHAI GENERAL HOSPITAL AND SHETH CHINAI PRASUTI HOSPITAL ELLISBRIDGE AHMEDABAD, Gujarat, 380006",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070ea"
    },
    "Rank": 46253,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070eb"
    },
    "Rank": 46264,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College,Churu,Civil Line, Shekhawat Colony, Churu-331001, Rajasthan, 331001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070ec"
    },
    "Rank": 46270,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070ed"
    },
    "Rank": 46278,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070ee"
    },
    "Rank": 46286,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070ef"
    },
    "Rank": 46310,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Fortune Hospital, Kanpur Uttar Pradesh ,\nUttar Pradesh,117-Q 40A-41 Sharda Nagar, Kanpur near Gurdev Crossing, Uttar Pradesh, 208025",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070f0"
    },
    "Rank": 46313,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dholpur,Old City Road Dholpur, Rajasthan, 328001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070f1"
    },
    "Rank": 46315,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070f2"
    },
    "Rank": 46316,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070f3"
    },
    "Rank": 46332,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070f4"
    },
    "Rank": 46358,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070f5"
    },
    "Rank": 46360,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070f6"
    },
    "Rank": 46363,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070f7"
    },
    "Rank": 46367,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070f8"
    },
    "Rank": 46383,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070f9"
    },
    "Rank": 46391,
    "Allotted Quota": "All India",
    "Allotted Institute": "KANYAKUMARI GOVERNMENT MEDICAL COLLEGE,KANYAKUMARI GOVERNMENT MEDICAL COLLEGE, Tamil Nadu, 629201",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070fa"
    },
    "Rank": 46403,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070fb"
    },
    "Rank": 46407,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital Shikaripura,mohshikaripur, Karnataka, 577427",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070fc"
    },
    "Rank": 46413,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070fd"
    },
    "Rank": 46418,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070fe"
    },
    "Rank": 46431,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Autonomous State Medical college Society and S.N.M District Hospital, ,DAULATTAPUR JALESAR ROAD NARKHI FIROZABAD, Uttar Pradesh, 283203",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a070ff"
    },
    "Rank": 46447,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07100"
    },
    "Rank": 46451,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07101"
    },
    "Rank": 46456,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kashyap Memorial eye hospital Pvt. Ltd., Jharkhand,PURULIA ROAD, RANCHI, Jharkhand, 834001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07102"
    },
    "Rank": 46457,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07103"
    },
    "Rank": 46459,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07104"
    },
    "Rank": 46463,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07105"
    },
    "Rank": 46466,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07106"
    },
    "Rank": 46471,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "NEOTIA GETWEL HEALTHCARE CENTRE,\n,Uttorayon, Behind City Centre, Matigara, Siliguri, West Bengal, 734010",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07107"
    },
    "Rank": 46494,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Midland Healthcare and Research Centre, Uttar Pradesh,B-55 Mandir Marg, Mahanagar,Lucknow., Uttar Pradesh, 226006",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07108"
    },
    "Rank": 46503,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhavsinhji and Maharani Rupaliba General Hospital, ,Near S. T. Bus Stand, Opp.\nBhutnath Temple, Hospital Road, Porbandar, Gujarat, 360575",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07109"
    },
    "Rank": 46504,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government General Hospital, Principal Medical Officer General hospital,\n,Rajiv Gandhi Government General Hospital, near bijli ghar circle, ALWAR, Rajasthan, 301001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0710a"
    },
    "Rank": 46506,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0710b"
    },
    "Rank": 46512,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0710c"
    },
    "Rank": 46519,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0710d"
    },
    "Rank": 46531,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0710e"
    },
    "Rank": 46532,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0710f"
    },
    "Rank": 46544,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07110"
    },
    "Rank": 46558,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07111"
    },
    "Rank": 46574,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07112"
    },
    "Rank": 46582,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital,\n,paddoc road, near majewadi gate, junagadh, Gujarat, 362001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07113"
    },
    "Rank": 46584,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07114"
    },
    "Rank": 46593,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07115"
    },
    "Rank": 46597,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07116"
    },
    "Rank": 46598,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07117"
    },
    "Rank": 46618,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sorake Chandrashekar Hospital (S.C.S.), Mangalore, Karnataka,SCS Hospital, Upper Bendoor, Mangalore., Karnataka, 575002",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07118"
    },
    "Rank": 46620,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07119"
    },
    "Rank": 46621,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0711a"
    },
    "Rank": 46624,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0711b"
    },
    "Rank": 46631,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0711c"
    },
    "Rank": 46651,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0711d"
    },
    "Rank": 46654,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0711e"
    },
    "Rank": 46656,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0711f"
    },
    "Rank": 46664,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07120"
    },
    "Rank": 46669,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07121"
    },
    "Rank": 46678,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07122"
    },
    "Rank": 46683,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07123"
    },
    "Rank": 46688,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07124"
    },
    "Rank": 46698,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07125"
    },
    "Rank": 46699,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "DISTRICT HOSPITAL ,\nChhattisgarh,Room No. 122 - Government District Hospital  Near Mandi Gate Pandari, Raipur, Chhattisgarh, 492001, , Chhattisgarh, 492001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07126"
    },
    "Rank": 46717,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (FAMILY MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07127"
    },
    "Rank": 46726,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Venugram Hospital, Belagavi, Karnataka,CTS 785, 2nd Cross, Hindu Nagar, Near 3rd Railway Gate, Belagavi, Karnataka, 590006",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07128"
    },
    "Rank": 46736,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karnataka,MG Road, Karnataka, 581301",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07129"
    },
    "Rank": 46740,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0712a"
    },
    "Rank": 46748,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0712b"
    },
    "Rank": 46755,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Central Hospital, ,SOUTH EAST CENTRAL RAILWAY, OPPOSITE GOLF GROUND,\nBILASPUR, Chhattisgarh, 495004",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0712c"
    },
    "Rank": 46766,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deben Mahato (Sadar) Hospital,,Main Road Near SP office PO Purulia Main PS Purulia Town Dist Purulia West Bengal, West Bengal, 723101",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0712d"
    },
    "Rank": 46772,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE,ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, Bihar, 823001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0712e"
    },
    "Rank": 46776,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0712f"
    },
    "Rank": 46781,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gheesibai Memorial Mittal Hospital and Research Centre,,Gheesi Bai Memorial Mittal Hospital and Research Centre, Pushkar Road, Ajmer, Rajasthan, 305004",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07130"
    },
    "Rank": 46786,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District General Hospital, ,O/o. Medical Superintendent, Government District General Hospital, Contonment, Vizianagaram, Andhra Pradesh,\n535003",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07131"
    },
    "Rank": 46809,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07132"
    },
    "Rank": 46810,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Associated Hospital Government Medical College, ,Kanth Bagh Baramulla, Jammu And Kashmir, 193103",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07133"
    },
    "Rank": 46812,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07134"
    },
    "Rank": 46822,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dana Shivam Heart and Superspeciality Hospital, Rajasthan\n,PLOT NO2 BLOCKB SECTOR2 OPPOSITE CENTRAL SPINE BUILDING VIDHYADHAR NAGAR, Rajasthan, 302039",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07135"
    },
    "Rank": 46829,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Christian Institute of Health Sciences and Research, ,4th Mile, Central Jail Road, Dimapur, Nagaland, 797115",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07136"
    },
    "Rank": 46834,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07137"
    },
    "Rank": 46848,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07138"
    },
    "Rank": 46853,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07139"
    },
    "Rank": 46860,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0713a"
    },
    "Rank": 46882,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0713b"
    },
    "Rank": 46890,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Autonomous State Medical college Society and S.N.M District Hospital, ,DAULATTAPUR JALESAR ROAD NARKHI FIROZABAD, Uttar Pradesh, 283203",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0713c"
    },
    "Rank": 46893,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0713d"
    },
    "Rank": 46895,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0713e"
    },
    "Rank": 46896,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0713f"
    },
    "Rank": 46900,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07140"
    },
    "Rank": 46903,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07141"
    },
    "Rank": 46925,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE, PUDUCHERRY,INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE,\nPUDUCHERRY, Puducherry, 605009",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07142"
    },
    "Rank": 46927,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. BDK hospital, govt. ,OPPOSITE ROADWAYS BUS STAND MAAN NAGAR JHUNJHUNU RAJASTHAN, Rajasthan, 333001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07143"
    },
    "Rank": 46932,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07144"
    },
    "Rank": 46938,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, Karnataka,General Hospital Doddaballapur, Near TB Circle, Chikkaballapur Main Road, Doddaballapur Taluk, Benga, Karnataka, 561203",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07145"
    },
    "Rank": 46946,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07146"
    },
    "Rank": 46968,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07147"
    },
    "Rank": 47007,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07148"
    },
    "Rank": 47010,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07149"
    },
    "Rank": 47016,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "DISTRICT HOSPITAL , Madhya Pradesh,Laxmiganj Guna M.P., Madhya Pradesh, 473001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0714a"
    },
    "Rank": 47019,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0714b"
    },
    "Rank": 47027,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indus International Hospital, ,CHANDIGARH-AMBALA ROAD NH-22, DERABASSI, Punjab, 140507",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0714c"
    },
    "Rank": 47036,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0714d"
    },
    "Rank": 47051,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0714e"
    },
    "Rank": 47062,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0714f"
    },
    "Rank": 47066,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharishi Vashistha Autonomous State Medical College and Opec Hospital,\n,Rampur Tahsil Sadar Basti Basti, Uttar Pradesh, 272124",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07150"
    },
    "Rank": 47067,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07151"
    },
    "Rank": 47069,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Hospital,,Government Hospital Gandhi Nagar Jammu, Jammu And Kashmir, 180004",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07152"
    },
    "Rank": 47082,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07153"
    },
    "Rank": 47110,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rural Development Trust Hospital, ,Kadiri Road,Bathalapalli, Anantapur District Andhra Pradesh, Andhra Pradesh, 515661",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07154"
    },
    "Rank": 47123,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubnagar,Government Medical College, Yedira, Mahabubnagar, Telangana state, Telangana, 509002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07155"
    },
    "Rank": 47133,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,General Hospital Mehsana, Opp S T Bus Station, Raj Mahel Road, Gujarat, 384001",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07156"
    },
    "Rank": 47135,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07157"
    },
    "Rank": 47140,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07158"
    },
    "Rank": 47151,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government General Hospital, ,GOVERNMENT GENERAL HOSPITAL, ALANPUR LINK ROAD SAWAI MADHOPUR 322001, Rajasthan, 322001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07159"
    },
    "Rank": 47152,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. BDK hospital, govt. ,OPPOSITE ROADWAYS BUS STAND MAAN NAGAR JHUNJHUNU RAJASTHAN, Rajasthan, 333001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0715a"
    },
    "Rank": 47153,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0715b"
    },
    "Rank": 47155,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0715c"
    },
    "Rank": 47166,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. GERIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0715d"
    },
    "Rank": 47177,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0715e"
    },
    "Rank": 47186,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0715f"
    },
    "Rank": 47210,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07160"
    },
    "Rank": 47218,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07161"
    },
    "Rank": 47235,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata Shrimati Devendra Kumari Singhdeo Government Medical College, Ambikapur,Kanyaparisar  Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07162"
    },
    "Rank": 47237,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07163"
    },
    "Rank": 47240,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07164"
    },
    "Rank": 47241,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deen Dayal Upadhyay Hospital, ,DDU HOSPITAL, SHAHEED MANGAL PANDEY MARG, NANAK PURA, HARI NAGAR, NEW DELHI, DELHI-110064, Delhi (NCT), 110064",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07165"
    },
    "Rank": 47243,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07166"
    },
    "Rank": 47247,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07167"
    },
    "Rank": 47259,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dana Shivam Heart and Superspeciality Hospital, Rajasthan\n,PLOT NO2 BLOCKB SECTOR2 OPPOSITE CENTRAL SPINE BUILDING VIDHYADHAR NAGAR, Rajasthan, 302039",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07168"
    },
    "Rank": 47269,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07169"
    },
    "Rank": 47273,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rangadore Memorial Hospital,,1ST CROSS, SHANKARAPURAM, BANGALORE, Karnataka, 560004",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0716a"
    },
    "Rank": 47287,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0716b"
    },
    "Rank": 47288,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0716c"
    },
    "Rank": 47289,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0716d"
    },
    "Rank": 47290,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0716e"
    },
    "Rank": 47305,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0716f"
    },
    "Rank": 47309,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. Sports Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07170"
    },
    "Rank": 47343,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rural Development Trust Hospital, ,Kadiri Road,Bathalapalli, Anantapur District Andhra Pradesh, Andhra Pradesh, 515661",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07171"
    },
    "Rank": 47344,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07172"
    },
    "Rank": 47349,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07173"
    },
    "Rank": 47354,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07174"
    },
    "Rank": 47355,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. BDK hospital, govt. ,OPPOSITE ROADWAYS BUS STAND MAAN NAGAR JHUNJHUNU RAJASTHAN, Rajasthan, 333001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07175"
    },
    "Rank": 47360,
    "Allotted Quota": "All India",
    "Allotted Institute": "Murshidabad Medical College and Hospital,Station Road, Berhampore, Murshidabad, West Bengal, 742101",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07176"
    },
    "Rank": 47368,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07177"
    },
    "Rank": 47370,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07178"
    },
    "Rank": 47378,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07179"
    },
    "Rank": 47386,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0717a"
    },
    "Rank": 47391,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0717b"
    },
    "Rank": 47395,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0717c"
    },
    "Rank": 47396,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0717d"
    },
    "Rank": 47398,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pandit Madan Mohan Malaviya Shatabdi Hospital, Maharashtra,Pandit  Madan Mohan Malaviya  Shatabdi Hospital\nWaman Tukaram Patil Marg Govandi Mumbai 400088, Maharashtra, 400088",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0717e"
    },
    "Rank": 47404,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0717f"
    },
    "Rank": 47416,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07180"
    },
    "Rank": 47424,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaheed Hasan Khan Mewati Government Medical College,Shaheed Hasan Khan Mewati Government Medical College, Haryana, 122107",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07181"
    },
    "Rank": 47427,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07182"
    },
    "Rank": 47434,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07183"
    },
    "Rank": 47442,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07184"
    },
    "Rank": 47443,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,Opposite to Shivajinagar Bus stop, Shivajinagar, Bengaluru., Karnataka, 560001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07185"
    },
    "Rank": 47457,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07186"
    },
    "Rank": 47460,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07187"
    },
    "Rank": 47466,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07188"
    },
    "Rank": 47477,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine & Sagore Dutta Hospital,College of Medicine & Sagore Dutta Hospital, West Bengal, 700058",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07189"
    },
    "Rank": 47484,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gangasheel Advanced Medical Research Institute, Uttar Pradesh,C-17, Deen Dayal Puram, Bareilly - 243122, Uttar Pradesh, 243122",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0718a"
    },
    "Rank": 47490,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0718b"
    },
    "Rank": 47524,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Diamond Harbour Government Medical College And Hospital, ,Harindanga, New Town, Word No- 4, P.O and P.S- Diamond Harbour, Dist- South 24 Parganas, West Bengal, West Bengal, 743331",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0718c"
    },
    "Rank": 47532,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0718d"
    },
    "Rank": 47538,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0718e"
    },
    "Rank": 47539,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government General Hospital, Principal Medical Officer General hospital,\n,Rajiv Gandhi Government General Hospital, near bijli ghar circle, ALWAR, Rajasthan, 301001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0718f"
    },
    "Rank": 47548,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07190"
    },
    "Rank": 47558,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07191"
    },
    "Rank": 47564,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07192"
    },
    "Rank": 47589,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07193"
    },
    "Rank": 47594,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Gondia,Nehru Chowk, KTS General Hospital Campus, Govt. Medical College, Gondia. 441601. Maharashtra., Maharashtra, 441601",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07194"
    },
    "Rank": 47599,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07195"
    },
    "Rank": 47606,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07196"
    },
    "Rank": 47608,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07197"
    },
    "Rank": 47610,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07198"
    },
    "Rank": 47612,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07199"
    },
    "Rank": 47617,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0719a"
    },
    "Rank": 47619,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0719b"
    },
    "Rank": 47651,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sorake Chandrashekar Hospital (S.C.S.), Mangalore, Karnataka,SCS Hospital, Upper Bendoor, Mangalore., Karnataka, 575002",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0719c"
    },
    "Rank": 47658,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0719d"
    },
    "Rank": 47661,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0719e"
    },
    "Rank": 47667,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0719f"
    },
    "Rank": 47670,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071a0"
    },
    "Rank": 47689,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071a1"
    },
    "Rank": 47690,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071a2"
    },
    "Rank": 47695,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071a3"
    },
    "Rank": 47697,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071a4"
    },
    "Rank": 47715,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071a5"
    },
    "Rank": 47734,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Siddh Multi Specialty Hospital,SIDDH MULTISPECIALTY HOSPITAL  HARTHALA INDUSTRIAL ESTATE KANTH ROAD MORADABAD, Uttar Pradesh, 244001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071a6"
    },
    "Rank": 47739,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071a7"
    },
    "Rank": 47740,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071a8"
    },
    "Rank": 47741,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071a9"
    },
    "Rank": 47748,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071aa"
    },
    "Rank": 47753,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071ab"
    },
    "Rank": 47765,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071ac"
    },
    "Rank": 47773,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Bangur Hospital, Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan , Rajasthan-306401,Government Medical College and Bangur Hospital Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan Ra, Rajasthan, 306401",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071ad"
    },
    "Rank": 47800,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saheed Laxman Nayak Medical College and Hospital,Orissa,SAHEED LAXMAN NAYAK MEDICAL COLLEGE AND HOSPITAL KORAPUT, JANIGUDA ODISHA KORAPUT 764020, Odisha, 764020",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071ae"
    },
    "Rank": 47804,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071af"
    },
    "Rank": 47807,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071b0"
    },
    "Rank": 47810,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071b1"
    },
    "Rank": 47815,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071b2"
    },
    "Rank": 47818,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071b3"
    },
    "Rank": 47822,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute of Medical Sciences Srikakulam,Government Medical College Srikakulam\nBalaga Srikakulam Town Srikakulam District, Andhra Pradesh, 532001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071b4"
    },
    "Rank": 47824,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071b5"
    },
    "Rank": 47840,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "FIMS HOSPITAL(FRANK INSTITUTE OF MEDICAL SCIENCES), Haryana,Sonipat-\nBahalgarh Road, Sonipat-NCR, Sonipat, Haryana, 131001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071b6"
    },
    "Rank": 47842,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071b7"
    },
    "Rank": 47850,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071b8"
    },
    "Rank": 47852,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071b9"
    },
    "Rank": 47854,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071ba"
    },
    "Rank": 47857,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir C V Raman General Hospital, ,80 FEET ROAD INDIRANAGARA BANGALORE, Karnataka, 560038",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071bb"
    },
    "Rank": 47883,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071bc"
    },
    "Rank": 47884,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071bd"
    },
    "Rank": 47886,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Chhattisgarh\n,DISTRICT HOSPITAL KANKER, NEAR POST OFFICE, KANKER, Chhattisgarh, 494334",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071be"
    },
    "Rank": 47888,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,GOVT. HOSPITAL, PLACE ROAD ,SIROHI, Rajasthan, 307001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071bf"
    },
    "Rank": 47899,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071c0"
    },
    "Rank": 47914,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071c1"
    },
    "Rank": 47916,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE, PUDUCHERRY,INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE,\nPUDUCHERRY, Puducherry, 605009",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071c2"
    },
    "Rank": 47923,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071c3"
    },
    "Rank": 47936,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "IGM Hospital , ,IGM Chowmuhani, Agartala, Tripura West, Tripura, 799001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071c4"
    },
    "Rank": 47954,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071c5"
    },
    "Rank": 47963,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071c6"
    },
    "Rank": 47964,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071c7"
    },
    "Rank": 47965,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071c8"
    },
    "Rank": 47967,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "BOLPUR SUB DIVISION HOSPITAL , ,bolpursdhospital@yahoo.com, West Bengal, 731204",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071c9"
    },
    "Rank": 47968,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071ca"
    },
    "Rank": 47969,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071cb"
    },
    "Rank": 47971,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071cc"
    },
    "Rank": 47984,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071cd"
    },
    "Rank": 47985,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071ce"
    },
    "Rank": 47986,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071cf"
    },
    "Rank": 47991,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071d0"
    },
    "Rank": 48016,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital, ,Kharvad medan Sipor road Vadnagar, Gujarat, 384355",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071d1"
    },
    "Rank": 48027,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071d2"
    },
    "Rank": 48041,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071d3"
    },
    "Rank": 48046,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071d4"
    },
    "Rank": 48052,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071d5"
    },
    "Rank": 48067,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071d6"
    },
    "Rank": 48069,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071d7"
    },
    "Rank": 48083,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "V Y Institute of Medical Science, ,Near Kamal Vihar, Dhamtari Road, Raipur, Chhattisgarh, 492001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071d8"
    },
    "Rank": 48087,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071d9"
    },
    "Rank": 48091,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071da"
    },
    "Rank": 48094,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sanghamitra Hospitals Private Limited, Andhra Pradesh,D.No -5-82-1, Ambedkar Colony Extension, Near South Bypass junction, Beside Swathi Pipes.  Ongole, Andhra Pradesh, 523002",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071db"
    },
    "Rank": 48098,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071dc"
    },
    "Rank": 48106,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071dd"
    },
    "Rank": 48113,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071de"
    },
    "Rank": 48127,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071df"
    },
    "Rank": 48130,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government General Hospital, Principal Medical Officer General hospital,\n,Rajiv Gandhi Government General Hospital, near bijli ghar circle, ALWAR, Rajasthan, 301001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071e0"
    },
    "Rank": 48145,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071e1"
    },
    "Rank": 48146,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071e2"
    },
    "Rank": 48154,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE, PUDUCHERRY,INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE,\nPUDUCHERRY, Puducherry, 605009",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071e3"
    },
    "Rank": 48158,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital,,General Hospital\nArakalagud Road, Holenarasipura Taluk, Hassan District, Karnataka, 573211",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071e4"
    },
    "Rank": 48160,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071e5"
    },
    "Rank": 48170,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071e6"
    },
    "Rank": 48174,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071e7"
    },
    "Rank": 48179,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071e8"
    },
    "Rank": 48200,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital,\n,DISTRICT HOSPITAL KULGAM NEAR FRUIT MANDI, Jammu And Kashmir, 192231",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071e9"
    },
    "Rank": 48205,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Renee Hospital, Karimnagar, Telangana,H. No 3-7-1 and 3-7-02, Indira Nagar, Karimnagar-505001, Telangana, Telangana, 505001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071ea"
    },
    "Rank": 48219,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071eb"
    },
    "Rank": 48220,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. BDK hospital, govt. ,OPPOSITE ROADWAYS BUS STAND MAAN NAGAR JHUNJHUNU RAJASTHAN, Rajasthan, 333001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071ec"
    },
    "Rank": 48230,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagyoday Tirth Chikitsalay, Sagar,\nMadhya Pradesh,khurai road ,Sagar M.P., Madhya Pradesh, 470002",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071ed"
    },
    "Rank": 48231,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071ee"
    },
    "Rank": 48232,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government DB General Medical Hosptal,Churu, Nayabass, Churu",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071ef"
    },
    "Rank": 48233,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071f0"
    },
    "Rank": 48237,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071f1"
    },
    "Rank": 48259,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071f2"
    },
    "Rank": 48261,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071f3"
    },
    "Rank": 48280,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071f4"
    },
    "Rank": 48281,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071f5"
    },
    "Rank": 48288,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071f6"
    },
    "Rank": 48298,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071f7"
    },
    "Rank": 48299,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071f8"
    },
    "Rank": 48304,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071f9"
    },
    "Rank": 48310,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071fa"
    },
    "Rank": 48326,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071fb"
    },
    "Rank": 48336,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071fc"
    },
    "Rank": 48338,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071fd"
    },
    "Rank": 48353,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESIC Model Hospital, ,A-3, TULSI MARG, SECTOR-24, NOIDA, Uttar Pradesh, 201301",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071fe"
    },
    "Rank": 48361,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a071ff"
    },
    "Rank": 48365,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07200"
    },
    "Rank": 48372,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07201"
    },
    "Rank": 48380,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07202"
    },
    "Rank": 48388,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07203"
    },
    "Rank": 48400,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07204"
    },
    "Rank": 48429,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pushpanjali Hospital and Research Centre, Uttar Pradesh,Pushpanjali Hospital and Research Centre Pvt Ltd Pushpanjali Palace, Delhi Gate, Agra, Uttar Pradesh, 282002",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07205"
    },
    "Rank": 48447,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07206"
    },
    "Rank": 48450,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07207"
    },
    "Rank": 48455,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07208"
    },
    "Rank": 48459,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07209"
    },
    "Rank": 48464,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0720a"
    },
    "Rank": 48466,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0720b"
    },
    "Rank": 48467,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0720c"
    },
    "Rank": 48469,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0720d"
    },
    "Rank": 48485,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0720e"
    },
    "Rank": 48496,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0720f"
    },
    "Rank": 48497,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07210"
    },
    "Rank": 48521,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07211"
    },
    "Rank": 48522,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S.K.Hospital, ,SUPERINTENDENT RNO 36  GOVT SK HOSPITAL  SILVER JUBLI ROAD  SIKAR  RAJ, Rajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07212"
    },
    "Rank": 48525,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07213"
    },
    "Rank": 48526,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07214"
    },
    "Rank": 48530,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07215"
    },
    "Rank": 48544,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07216"
    },
    "Rank": 48545,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07217"
    },
    "Rank": 48547,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07218"
    },
    "Rank": 48548,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07219"
    },
    "Rank": 48549,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0721a"
    },
    "Rank": 48550,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0721b"
    },
    "Rank": 48568,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tirumala Hospital, ,PSR COMPLEX, NEAR RTC COMPLEX, VIZIANAGARAM, Andhra Pradesh, 535003",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0721c"
    },
    "Rank": 48570,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0721d"
    },
    "Rank": 48572,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0721e"
    },
    "Rank": 48575,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0721f"
    },
    "Rank": 48583,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07220"
    },
    "Rank": 48593,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07221"
    },
    "Rank": 48599,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07222"
    },
    "Rank": 48613,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital ,ALIPURDUAR DISTRICT HOSPITAL, ALIPURDUAR, HOSPITAL ROAD, WARD NO-XIII, ALIPURDUAR , PIN-736121, WEST BENGAL, 736121",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07223"
    },
    "Rank": 48634,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07224"
    },
    "Rank": 48637,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Diamond Harbour Government Medical College And Hospital, ,Harindanga, New Town, Word No- 4, P.O and P.S- Diamond Harbour, Dist- South 24 Parganas, West Bengal, West Bengal, 743331",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07225"
    },
    "Rank": 48643,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07226"
    },
    "Rank": 48645,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07227"
    },
    "Rank": 48650,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Renee Hospital, Karimnagar, Telangana,H. No 3-7-1 and 3-7-02, Indira Nagar, Karimnagar-505001, Telangana, Telangana, 505001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07228"
    },
    "Rank": 48656,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nalgonda,Office of the Principal, Government Medical College Nalgonda, Near RTC Bus Stand, Gollaguda,\nNalgon, Telangana, 508001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07229"
    },
    "Rank": 48687,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0722a"
    },
    "Rank": 48693,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0722b"
    },
    "Rank": 48704,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0722c"
    },
    "Rank": 48713,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,4th T Block, Beside Rajiv Gandhi University of Health Sciences Jayanagar,, Karnataka, 560041",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0722d"
    },
    "Rank": 48717,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0722e"
    },
    "Rank": 48732,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0722f"
    },
    "Rank": 48737,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07230"
    },
    "Rank": 48742,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07231"
    },
    "Rank": 48746,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rampurhat Govt. Medical College and Hospital,\n,RAMPURHAT GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, P.O. RAMPURHAT, P.S. RAMPURHAT, DIST. BIRBHUM, WB, West Bengal, 731224",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07232"
    },
    "Rank": 48764,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07233"
    },
    "Rank": 48774,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07234"
    },
    "Rank": 48777,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07235"
    },
    "Rank": 48779,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07236"
    },
    "Rank": 48782,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07237"
    },
    "Rank": 48786,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07238"
    },
    "Rank": 48808,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Associated Hospital Government Medical College, ,Kanth Bagh Baramulla, Jammu And Kashmir, 193103",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07239"
    },
    "Rank": 48825,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0723a"
    },
    "Rank": 48831,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "FAKIR MOHAN MEDICAL COLLEGE AND HOSPITAL ,\nOrissa,AT-Remuna Post-Remuna Dist-Balasore, Odisha, 756019",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0723b"
    },
    "Rank": 48859,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Swagat Super Speciality Surgical Institute, Assam,swagathospital@gmail.com, Assam, 781011",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0723c"
    },
    "Rank": 48860,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital ,ALIPURDUAR DISTRICT HOSPITAL, ALIPURDUAR, HOSPITAL ROAD, WARD NO-XIII, ALIPURDUAR , PIN-736121, WEST BENGAL, 736121",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0723d"
    },
    "Rank": 48873,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0723e"
    },
    "Rank": 48883,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0723f"
    },
    "Rank": 48891,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07240"
    },
    "Rank": 48894,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07241"
    },
    "Rank": 48898,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07242"
    },
    "Rank": 48910,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07243"
    },
    "Rank": 48914,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07244"
    },
    "Rank": 48916,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07245"
    },
    "Rank": 48917,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07246"
    },
    "Rank": 48922,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07247"
    },
    "Rank": 48923,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07248"
    },
    "Rank": 48927,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07249"
    },
    "Rank": 48930,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government DB General Medical Hosptal,Churu, Nayabass, Churu",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0724a"
    },
    "Rank": 48935,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0724b"
    },
    "Rank": 48936,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0724c"
    },
    "Rank": 48938,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0724d"
    },
    "Rank": 48950,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0724e"
    },
    "Rank": 48953,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0724f"
    },
    "Rank": 48955,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07250"
    },
    "Rank": 48959,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07251"
    },
    "Rank": 48975,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07252"
    },
    "Rank": 48989,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruby Eye Hospital, Berhampur, Orissa,SUSHRUTA NAGAR, GOVINDA BIHAR,\nMG ROAD, BERHAMPUR, Odisha, 760001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07253"
    },
    "Rank": 48991,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07254"
    },
    "Rank": 49004,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07255"
    },
    "Rank": 49005,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. District Civil Hospital, ,DIS GOVT HOSPITAL, SRI GANGANAGAR, Rajasthan, 335001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07256"
    },
    "Rank": 49006,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07257"
    },
    "Rank": 49019,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07258"
    },
    "Rank": 49026,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07259"
    },
    "Rank": 49028,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College & Attached Hospital Bharatpur,GOVERNMENT MEDICAL COLLEGE BHARATPUR VILLAGE RAMPURA NH 21, Rajasthan, 321001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0725a"
    },
    "Rank": 49029,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0725b"
    },
    "Rank": 49031,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute Of Medical Sciences, Kalaburagi,VEERESH NAGAR SEDAM ROAD KALABURAGI, Karnataka, 585105",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0725c"
    },
    "Rank": 49045,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0725d"
    },
    "Rank": 49050,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jangipur S. D. Hospital, West Bengal,Lalgola Maharaja Road PO and PS Raghunathganj\nMurshidabad, West Bengal, 742225",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0725e"
    },
    "Rank": 49055,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pushpanjali Hospital and Research Centre, Uttar Pradesh,Pushpanjali Hospital and Research Centre Pvt Ltd Pushpanjali Palace, Delhi Gate, Agra, Uttar Pradesh, 282002",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0725f"
    },
    "Rank": 49083,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07260"
    },
    "Rank": 49089,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Woodlands Multispeciality Hospital Limited,\n,8-5 ALIPORE ROAD KOLKATA, West Bengal, 700027",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07261"
    },
    "Rank": 49090,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gopala Gowda Shanthaveri Memorial Hospital, Karnataka,T Narasipura Road, Nazarbad Mysuru, Karnataka, 570010",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07262"
    },
    "Rank": 49103,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Vasantrao Naik Govt Medical College Yavatmal,Shri Vasantrao Naik Govt Medical College Yavatmal, Maharashtra, 445001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07263"
    },
    "Rank": 49112,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07264"
    },
    "Rank": 49119,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07265"
    },
    "Rank": 49123,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07266"
    },
    "Rank": 49124,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07267"
    },
    "Rank": 49129,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,SECTOR-6, PANCHKULA, HARYANA-134108, Haryana, 134108",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07268"
    },
    "Rank": 49133,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07269"
    },
    "Rank": 49152,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dholpur,Old City Road Dholpur, Rajasthan, 328001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0726a"
    },
    "Rank": 49157,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0726b"
    },
    "Rank": 49158,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indus International Hospital, ,CHANDIGARH-AMBALA ROAD NH-22, DERABASSI, Punjab, 140507",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0726c"
    },
    "Rank": 49164,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, Karnataka,General Hospital Tiptur Tumkur District\nKarnataka\nPin 572201, Karnataka, 572201",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0726d"
    },
    "Rank": 49165,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0726e"
    },
    "Rank": 49172,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Hospital,,JANGLAT MANDI ANANTNAG, JAMMU AND KASHMIR 192101, Jammu And Kashmir, 192101",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0726f"
    },
    "Rank": 49195,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Saheed Laxman Nayak Medical College and Hospital,Orissa,SAHEED LAXMAN NAYAK MEDICAL COLLEGE AND HOSPITAL KORAPUT, JANIGUDA ODISHA KORAPUT 764020, Odisha, 764020",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07270"
    },
    "Rank": 49200,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07271"
    },
    "Rank": 49207,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07272"
    },
    "Rank": 49230,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07273"
    },
    "Rank": 49253,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rampurhat Govt. Medical College and Hospital,\n,RAMPURHAT GOVERNMENT MEDICAL COLLEGE AND HOSPITAL, P.O. RAMPURHAT, P.S. RAMPURHAT, DIST. BIRBHUM, WB, West Bengal, 731224",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07274"
    },
    "Rank": 49257,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07275"
    },
    "Rank": 49262,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07276"
    },
    "Rank": 49263,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07277"
    },
    "Rank": 49267,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07278"
    },
    "Rank": 49268,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07279"
    },
    "Rank": 49281,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0727a"
    },
    "Rank": 49282,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0727b"
    },
    "Rank": 49285,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0727c"
    },
    "Rank": 49292,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0727d"
    },
    "Rank": 49298,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0727e"
    },
    "Rank": 49304,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0727f"
    },
    "Rank": 49311,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07280"
    },
    "Rank": 49323,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lord Mahavir Civil Hospital,,Lord Mahavir Civil Hospital, MCH Wing Fieldganj Road Ludhiana, Punjab, 141008",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07281"
    },
    "Rank": 49333,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pushpanjali Hospital and Research Centre, Uttar Pradesh,Pushpanjali Hospital and Research Centre Pvt Ltd Pushpanjali Palace, Delhi Gate, Agra, Uttar Pradesh, 282002",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07282"
    },
    "Rank": 49335,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07283"
    },
    "Rank": 49337,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharishi Vashistha Autonomous State Medical College and Opec Hospital,\n,Rampur Tahsil Sadar Basti Basti, Uttar Pradesh, 272124",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07284"
    },
    "Rank": 49345,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government DB General Medical Hosptal,Churu, Nayabass, Churu",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07285"
    },
    "Rank": 49347,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07286"
    },
    "Rank": 49351,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07287"
    },
    "Rank": 49393,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Siddh Multi Specialty Hospital,SIDDH MULTISPECIALTY HOSPITAL  HARTHALA INDUSTRIAL ESTATE KANTH ROAD MORADABAD, Uttar Pradesh, 244001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07288"
    },
    "Rank": 49400,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07289"
    },
    "Rank": 49401,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan , Rajasthan-311001,Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan, Rajasthan, 311001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0728a"
    },
    "Rank": 49406,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0728b"
    },
    "Rank": 49408,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan , Rajasthan-311001,Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan, Rajasthan, 311001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0728c"
    },
    "Rank": 49411,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0728d"
    },
    "Rank": 49433,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0728e"
    },
    "Rank": 49437,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0728f"
    },
    "Rank": 49443,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07290"
    },
    "Rank": 49461,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shrikrishna Medical College & Hospital, Muzaffarpur,Shrikrishna Medical College & Hospital, Muzaffarpur, Bihar, 842004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07291"
    },
    "Rank": 49466,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07292"
    },
    "Rank": 49470,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital , ,shahid Rajmal Meena Govt. Dis. Hospital Mandi Road Baran, Rajasthan, 325205",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07293"
    },
    "Rank": 49484,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07294"
    },
    "Rank": 49497,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. BDK hospital, govt. ,OPPOSITE ROADWAYS BUS STAND MAAN NAGAR JHUNJHUNU RAJASTHAN, Rajasthan, 333001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07295"
    },
    "Rank": 49507,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07296"
    },
    "Rank": 49528,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07297"
    },
    "Rank": 49531,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07298"
    },
    "Rank": 49534,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07299"
    },
    "Rank": 49542,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Government Medical College, Vidisha,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA MP, Madhya Pradesh, 464001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0729a"
    },
    "Rank": 49554,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0729b"
    },
    "Rank": 49555,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0729c"
    },
    "Rank": 49560,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESIC MEDICAL COLLEGE AND HOSPITAL, Bihar,ESIC Medical College and Hospital,Ara Danapur Road, Sikandarpur, Bihta, Patna, Bihar, 801103",
    "Course": "(NBEMS) BIOCHEMISTRY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0729d"
    },
    "Rank": 49565,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0729e"
    },
    "Rank": 49566,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0729f"
    },
    "Rank": 49567,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072a0"
    },
    "Rank": 49583,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "PRIYA HOSPITAL,\nUttar Pradesh,D-25, HIG, World Bank Barra, Bypass Road, Kanpur Nagar, Uttar Pradesh, 208027",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072a1"
    },
    "Rank": 49592,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072a2"
    },
    "Rank": 49599,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072a3"
    },
    "Rank": 49609,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072a4"
    },
    "Rank": 49613,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072a5"
    },
    "Rank": 49621,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072a6"
    },
    "Rank": 49629,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072a7"
    },
    "Rank": 49644,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072a8"
    },
    "Rank": 49652,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072a9"
    },
    "Rank": 49657,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072aa"
    },
    "Rank": 49660,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072ab"
    },
    "Rank": 49674,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072ac"
    },
    "Rank": 49677,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072ad"
    },
    "Rank": 49679,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government General Hospital, Principal Medical Officer General hospital,\n,Rajiv Gandhi Government General Hospital, near bijli ghar circle, ALWAR, Rajasthan, 301001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072ae"
    },
    "Rank": 49683,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072af"
    },
    "Rank": 49692,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072b0"
    },
    "Rank": 49701,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072b1"
    },
    "Rank": 49716,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS Medical College Valsad,Nanakwada Halar Road,Valsad ,Gujarat, Gujarat, 396001",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072b2"
    },
    "Rank": 49718,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072b3"
    },
    "Rank": 49732,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072b4"
    },
    "Rank": 49736,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Diamond Harbour Government Medical College And Hospital, ,Harindanga, New Town, Word No- 4, P.O and P.S- Diamond Harbour, Dist- South 24 Parganas, West Bengal, West Bengal, 743331",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072b5"
    },
    "Rank": 49737,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,OLD SABJI MANDI HINDUAN GATE KARAULI, Rajasthan, 322241",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072b6"
    },
    "Rank": 49747,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072b7"
    },
    "Rank": 49758,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072b8"
    },
    "Rank": 49764,
    "Allotted Quota": "All India",
    "Allotted Institute": "Calcutta School Of Tropical Medicine,Calcutta School Of Tropical Medicine, West Bengal, 700073",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072b9"
    },
    "Rank": 49774,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072ba"
    },
    "Rank": 49779,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072bb"
    },
    "Rank": 49780,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072bc"
    },
    "Rank": 49787,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072bd"
    },
    "Rank": 49788,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072be"
    },
    "Rank": 49790,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Venugram Hospital, Belagavi, Karnataka,CTS 785, 2nd Cross, Hindu Nagar, Near 3rd Railway Gate, Belagavi, Karnataka, 590006",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072bf"
    },
    "Rank": 49812,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072c0"
    },
    "Rank": 49816,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072c1"
    },
    "Rank": 49823,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072c2"
    },
    "Rank": 49839,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ojas Super Speciality Hospital, Panchkula,\nHaryana,H1, NH 7, Sector 26, Panchkula Extension, Panchkula, Haryana, Haryana, 134116",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072c3"
    },
    "Rank": 49849,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072c4"
    },
    "Rank": 49858,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ruban Memorial Hospital, Bihar,19 Patliputra Colony Near Patliputra Golambar Patna, Bihar, 800013",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072c5"
    },
    "Rank": 49865,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College, ,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "(NBEMS) FORENSIC MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072c6"
    },
    "Rank": 49867,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "JLN Government Hospital,,NH 89 BIKANER ROAD, NAGAUR, Rajasthan, 341001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072c7"
    },
    "Rank": 49878,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072c8"
    },
    "Rank": 49881,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Super Facility Hospital, Distt.-Azamgarh, Uttar Pradesh,Chakrapanpur, Kanaila, Azamgarh, Uttar Pradesh, 276128",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072c9"
    },
    "Rank": 49882,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072ca"
    },
    "Rank": 49884,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072cb"
    },
    "Rank": 49885,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "DIPLOMA IN BACTERIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072cc"
    },
    "Rank": 49890,
    "Allotted Quota": "All India",
    "Allotted Institute": "GADAG INSTITUTE OF MEDICAL SCIENCES GADAG,Gadag Institute of Medical Sciences Mallasamudra Gadag-582103, Karnataka, 582103",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072cd"
    },
    "Rank": 49900,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072ce"
    },
    "Rank": 49901,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Karnataka,Kodagu Institute of Medical Sciences Madikeri Kodagu, Karnataka, 571201",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072cf"
    },
    "Rank": 49909,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S.K.Hospital, ,SUPERINTENDENT RNO 36  GOVT SK HOSPITAL  SILVER JUBLI ROAD  SIKAR  RAJ, Rajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072d0"
    },
    "Rank": 49913,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District hospital chitradurga, B D road beside DHO office Chitradurga, Karnataka, 577501",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072d1"
    },
    "Rank": 49917,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072d2"
    },
    "Rank": 49918,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Eastern Indira Gandhi Regional Institute of Health & Medical Sciences, Shillong,North Eastern Indira Gandhi Regional Institute of Health & Medical Sciences, Shillong, Meghalaya, 793018",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072d3"
    },
    "Rank": 49919,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Paras HMRI Hospital, ,Paras HMRI Hospital\nNH 30 Bailey Road Raja Bazaar Patna 800014 Bihar, Bihar, 800014",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072d4"
    },
    "Rank": 49922,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072d5"
    },
    "Rank": 49936,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "FIMS HOSPITAL(FRANK INSTITUTE OF MEDICAL SCIENCES), Haryana,Sonipat-\nBahalgarh Road, Sonipat-NCR, Sonipat, Haryana, 131001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072d6"
    },
    "Rank": 49947,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072d7"
    },
    "Rank": 49948,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Imambara District Hospital,,AKHANBAZAR ROAD, CHINSURA, HOOGHLY, West Bengal, 712101",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072d8"
    },
    "Rank": 49967,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072d9"
    },
    "Rank": 49972,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072da"
    },
    "Rank": 49977,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072db"
    },
    "Rank": 49981,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072dc"
    },
    "Rank": 49983,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072dd"
    },
    "Rank": 49984,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072de"
    },
    "Rank": 49985,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,HBT Medical College and Dr R N Cooper Municipal General Hospital, Bhaktivedanta Swami Rd, JVPD Schem, Maharashtra, 400056",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072df"
    },
    "Rank": 49989,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072e0"
    },
    "Rank": 49993,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072e1"
    },
    "Rank": 50001,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072e2"
    },
    "Rank": 50003,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072e3"
    },
    "Rank": 50004,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072e4"
    },
    "Rank": 50011,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Allopathic Medical College, Uttar Pradesh,RANI DURGAVATI MEDICAL COLLEGE NARAINI ROAD BANDA, Uttar Pradesh, 210001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072e5"
    },
    "Rank": 50012,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072e6"
    },
    "Rank": 50018,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072e7"
    },
    "Rank": 50026,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rani Durgavati Medical College, Banda ,Rani Durgavati Medical College Naraini Road Banda, Uttar Pradesh, 210001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072e8"
    },
    "Rank": 50039,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072e9"
    },
    "Rank": 50047,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072ea"
    },
    "Rank": 50058,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital,\n,paddoc road, near majewadi gate, junagadh, Gujarat, 362001",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072eb"
    },
    "Rank": 50065,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt Amrit Kaur Hospital, ,PRINCIPAL MEDICAL OFFICER GOVT. AMRIT KAUR HOSPITAL BEAWAR, Rajasthan, 305901",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072ec"
    },
    "Rank": 50076,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.R.A. Medical College,\n,Saddarpur Akabarpur Tanda Road Post Surapur\nDistrict Ambedkarnagar, Uttar Pradesh, 224227",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072ed"
    },
    "Rank": 50078,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072ee"
    },
    "Rank": 50080,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Medical College for Women, Khanpur Kalan Sonepat,BPS Govt. Medical College for Women, Khanpur Kalan Sonepat, Haryana, 131305",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072ef"
    },
    "Rank": 50086,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072f0"
    },
    "Rank": 50091,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE,ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, Bihar, 823001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072f1"
    },
    "Rank": 50094,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072f2"
    },
    "Rank": 50099,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072f3"
    },
    "Rank": 50118,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Doon Medical College, Dehradun,Govt. Doon Medical College Dehrakhas, Patel Nagar Dehradun 248001, Uttarakhand, 248001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072f4"
    },
    "Rank": 50134,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072f5"
    },
    "Rank": 50138,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072f6"
    },
    "Rank": 50140,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072f7"
    },
    "Rank": 50143,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072f8"
    },
    "Rank": 50151,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072f9"
    },
    "Rank": 50156,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072fa"
    },
    "Rank": 50173,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital,\n,DISTRICT HOSPITAL KULGAM NEAR FRUIT MANDI, Jammu And Kashmir, 192231",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072fb"
    },
    "Rank": 50176,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lalbagh Sub Division Hospital, West Bengal,Golapbagh, PO-Murshidabad, PS- Murshidabad, Dist.-Murshidabad, West Bengal, 742149",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072fc"
    },
    "Rank": 50179,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072fd"
    },
    "Rank": 50190,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072fe"
    },
    "Rank": 50197,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a072ff"
    },
    "Rank": 50202,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07300"
    },
    "Rank": 50207,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07301"
    },
    "Rank": 50225,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07302"
    },
    "Rank": 50236,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07303"
    },
    "Rank": 50249,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07304"
    },
    "Rank": 50250,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07305"
    },
    "Rank": 50253,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Venugram Hospital, Belagavi, Karnataka,CTS 785, 2nd Cross, Hindu Nagar, Near 3rd Railway Gate, Belagavi, Karnataka, 590006",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07306"
    },
    "Rank": 50257,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07307"
    },
    "Rank": 50260,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07308"
    },
    "Rank": 50265,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07309"
    },
    "Rank": 50266,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K S HOSPITAL, Karnataka,9-13-1270, M B DIWATAR NAGAR, HOSPET ROAD,\nKOPPAL, Karnataka, 583231",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0730a"
    },
    "Rank": 50268,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0730b"
    },
    "Rank": 50271,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0730c"
    },
    "Rank": 50315,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S.K.Hospital, ,SUPERINTENDENT RNO 36  GOVT SK HOSPITAL  SILVER JUBLI ROAD  SIKAR  RAJ, Rajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0730d"
    },
    "Rank": 50317,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Venugram Hospital, Belagavi, Karnataka,CTS 785, 2nd Cross, Hindu Nagar, Near 3rd Railway Gate, Belagavi, Karnataka, 590006",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0730e"
    },
    "Rank": 50324,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Synergy Institute of Medical Sciences, ,Ballupur Canal Road, Ballupur Chowk, Dehradun, Uttarakhand, 248001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0730f"
    },
    "Rank": 50328,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07310"
    },
    "Rank": 50342,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07311"
    },
    "Rank": 50348,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07312"
    },
    "Rank": 50350,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07313"
    },
    "Rank": 50351,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07314"
    },
    "Rank": 50353,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07315"
    },
    "Rank": 50360,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07316"
    },
    "Rank": 50370,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "DISTRICT HOSPITAL ,\nChhattisgarh,Room No. 122 - Government District Hospital  Near Mandi Gate Pandari, Raipur, Chhattisgarh, 492001, , Chhattisgarh, 492001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07317"
    },
    "Rank": 50372,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vishnuprasad Nandrai Desai Municipal General Hospital, Maharashtra,11 TH GOLIBAR ROAD TPS,SANTACRUZ EAST, Maharashtra, 400055",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07318"
    },
    "Rank": 50389,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07319"
    },
    "Rank": 50391,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0731a"
    },
    "Rank": 50395,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "DIPLOMA IN CLINICAL PATHOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0731b"
    },
    "Rank": 50398,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0731c"
    },
    "Rank": 50407,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Hospital,,JANGLAT MANDI ANANTNAG, JAMMU AND KASHMIR 192101, Jammu And Kashmir, 192101",
    "Course": "(NBEMS) COMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0731d"
    },
    "Rank": 50408,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0731e"
    },
    "Rank": 50418,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0731f"
    },
    "Rank": 50427,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07320"
    },
    "Rank": 50432,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07321"
    },
    "Rank": 50440,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07322"
    },
    "Rank": 50444,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07323"
    },
    "Rank": 50445,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INSTITUTE OF MEDICAL SCIENCES,RAICHUR INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 584102",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07324"
    },
    "Rank": 50446,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07325"
    },
    "Rank": 50447,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07326"
    },
    "Rank": 50454,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07327"
    },
    "Rank": 50476,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07328"
    },
    "Rank": 50480,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07329"
    },
    "Rank": 50483,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sahyadri Narayana Multispecialty Hospital, Harakere,,NT ROAD, HARAKERE , SHIMOGA, Karnataka, 577202",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0732a"
    },
    "Rank": 50486,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0732b"
    },
    "Rank": 50493,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0732c"
    },
    "Rank": 50507,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0732d"
    },
    "Rank": 50525,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0732e"
    },
    "Rank": 50537,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0732f"
    },
    "Rank": 50542,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07330"
    },
    "Rank": 50544,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07331"
    },
    "Rank": 50546,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07332"
    },
    "Rank": 50550,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07333"
    },
    "Rank": 50554,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07334"
    },
    "Rank": 50566,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Venugram Hospital, Belagavi, Karnataka,CTS 785, 2nd Cross, Hindu Nagar, Near 3rd Railway Gate, Belagavi, Karnataka, 590006",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07335"
    },
    "Rank": 50570,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "IGM Hospital , ,IGM Chowmuhani, Agartala, Tripura West, Tripura, 799001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07336"
    },
    "Rank": 50578,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07337"
    },
    "Rank": 50592,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan , Rajasthan-311001,Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan, Rajasthan, 311001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07338"
    },
    "Rank": 50602,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07339"
    },
    "Rank": 50604,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0733a"
    },
    "Rank": 50633,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government General Hospital, Principal Medical Officer General hospital,\n,Rajiv Gandhi Government General Hospital, near bijli ghar circle, ALWAR, Rajasthan, 301001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0733b"
    },
    "Rank": 50641,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0733c"
    },
    "Rank": 50645,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0733d"
    },
    "Rank": 50649,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0733e"
    },
    "Rank": 50655,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0733f"
    },
    "Rank": 50663,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07340"
    },
    "Rank": 50678,
    "Allotted Quota": "All India",
    "Allotted Institute": "G B Pant Institute of Post Graduate Medical Education and Research,G B Pant Institute of Post Graduate Medical Education and Research, Delhi (NCT), 110007",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07341"
    },
    "Rank": 50681,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Datia,Near 29 Batallion, National Highway 44, Dist Datia, Madhya Pradesh Pin - 475661, Madhya Pradesh, 475661",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07342"
    },
    "Rank": 50682,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07343"
    },
    "Rank": 50683,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital,,General Hospital\nArakalagud Road, Holenarasipura Taluk, Hassan District, Karnataka, 573211",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07344"
    },
    "Rank": 50691,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07345"
    },
    "Rank": 50697,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07346"
    },
    "Rank": 50710,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sahyadri Narayana Multispecialty Hospital, Harakere,,NT ROAD, HARAKERE , SHIMOGA, Karnataka, 577202",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07347"
    },
    "Rank": 50711,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07348"
    },
    "Rank": 50723,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07349"
    },
    "Rank": 50729,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "DIP. IN FORENSIC MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0734a"
    },
    "Rank": 50736,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Second Sight Southend Eye Centre Pvt. Ltd., West Bengal,Baruipur Amtola Road. Dhopagachi, Kundarali, PS.Baruipur, Westbengal, Pin - 743610, West Bengal, 743610",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0734b"
    },
    "Rank": 50738,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SSG government district hospital, ,Udaipur road, Senthi, Chittorgarh, Rajasthan, Rajasthan, 312001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0734c"
    },
    "Rank": 50750,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "DIPLOMA IN CLINICAL PATHOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0734d"
    },
    "Rank": 50752,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. BDK hospital, govt. ,OPPOSITE ROADWAYS BUS STAND MAAN NAGAR JHUNJHUNU RAJASTHAN, Rajasthan, 333001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0734e"
    },
    "Rank": 50753,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "DURGAPUR SUB DIVISIONAL HOSPITAL ,\n,DR.ZAKIR HUSSIAN AVENUE, BIDHANNAGAR, DURGAPUR, West Bengal, 713206",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0734f"
    },
    "Rank": 50760,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07350"
    },
    "Rank": 50761,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07351"
    },
    "Rank": 50774,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07352"
    },
    "Rank": 50775,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07353"
    },
    "Rank": 50777,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07354"
    },
    "Rank": 50778,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07355"
    },
    "Rank": 50780,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07356"
    },
    "Rank": 50781,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07357"
    },
    "Rank": 50791,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07358"
    },
    "Rank": 50792,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07359"
    },
    "Rank": 50803,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0735a"
    },
    "Rank": 50812,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Popular Medicare,POPULAR MEDICARE LTD N 10 60 A 2 BLW ROAD KAKARAMATTA VARANASI, Uttar Pradesh, 221106",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0735b"
    },
    "Rank": 50819,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0735c"
    },
    "Rank": 50820,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0735d"
    },
    "Rank": 50860,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0735e"
    },
    "Rank": 50862,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dharwad,dsdharwad at gmail dot com, Karnataka, 580001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0735f"
    },
    "Rank": 50873,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07360"
    },
    "Rank": 50876,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07361"
    },
    "Rank": 50889,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07362"
    },
    "Rank": 50898,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07363"
    },
    "Rank": 50900,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07364"
    },
    "Rank": 50904,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07365"
    },
    "Rank": 50906,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07366"
    },
    "Rank": 50907,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07367"
    },
    "Rank": 50926,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07368"
    },
    "Rank": 50941,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07369"
    },
    "Rank": 50950,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0736a"
    },
    "Rank": 50959,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0736b"
    },
    "Rank": 50971,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,OLD SABJI MANDI HINDUAN GATE KARAULI, Rajasthan, 322241",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0736c"
    },
    "Rank": 50977,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0736d"
    },
    "Rank": 50986,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0736e"
    },
    "Rank": 50995,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0736f"
    },
    "Rank": 51002,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07370"
    },
    "Rank": 51016,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07371"
    },
    "Rank": 51017,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "FAKIR MOHAN MEDICAL COLLEGE AND HOSPITAL ,\nOrissa,AT-Remuna Post-Remuna Dist-Balasore, Odisha, 756019",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07372"
    },
    "Rank": 51041,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "JLNM Hospital, ,JLNM Hospital Rainawari Srinagar, Jammu And Kashmir, 190003",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07373"
    },
    "Rank": 51043,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S.K.Hospital, ,SUPERINTENDENT RNO 36  GOVT SK HOSPITAL  SILVER JUBLI ROAD  SIKAR  RAJ, Rajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07374"
    },
    "Rank": 51049,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Swagat Super Speciality Surgical Institute, Assam,swagathospital@gmail.com, Assam, 781011",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07375"
    },
    "Rank": 51050,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Swami Dayanand Hospital, ,Dilshad Garden, Shahdara, Delhi, Delhi (NCT), 110095",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07376"
    },
    "Rank": 51057,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07377"
    },
    "Rank": 51063,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07378"
    },
    "Rank": 51071,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07379"
    },
    "Rank": 51127,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0737a"
    },
    "Rank": 51150,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lord Mahavir Civil Hospital,,Lord Mahavir Civil Hospital, MCH Wing Fieldganj Road Ludhiana, Punjab, 141008",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0737b"
    },
    "Rank": 51151,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government General Hospital, ,GOVERNMENT GENERAL HOSPITAL, ALANPUR LINK ROAD SAWAI MADHOPUR 322001, Rajasthan, 322001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0737c"
    },
    "Rank": 51152,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "NH Brahamananda Narayana ,Near Pardih Chowk, Tamolia, NH 33, Jamshedpur, Saraikela-Kharsawan, Jharkhand 831012, Jharkhand, 831012",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0737d"
    },
    "Rank": 51158,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Habra State General Hospital,Habra SGH, North 24 Pgs, Habra, Pin - 743263, West Bengal, 743263",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0737e"
    },
    "Rank": 51166,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital ,  DARJEELING, West Bengal,20, BAZAAR CART ROAD, DARJEELING, West Bengal, 734101",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0737f"
    },
    "Rank": 51167,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07380"
    },
    "Rank": 51168,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07381"
    },
    "Rank": 51177,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07382"
    },
    "Rank": 51184,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Johal Multispeciality Hospital,Hoshiarpur Road Rama Mandi Jalandhar Punjab 144005, Punjab, 144005",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07383"
    },
    "Rank": 51185,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07384"
    },
    "Rank": 51188,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07385"
    },
    "Rank": 51210,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07386"
    },
    "Rank": 51211,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07387"
    },
    "Rank": 51219,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.R.A. Medical College,\n,Saddarpur Akabarpur Tanda Road Post Surapur\nDistrict Ambedkarnagar, Uttar Pradesh, 224227",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07388"
    },
    "Rank": 51223,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07389"
    },
    "Rank": 51228,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Gondia,Nehru Chowk, KTS General Hospital Campus, Govt. Medical College, Gondia. 441601. Maharashtra., Maharashtra, 441601",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0738a"
    },
    "Rank": 51232,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "J.L.N. Main Hospital and Research Centre, ,Bhilai Steel Plant Sector-9, Bhilai, C.G., Chhattisgarh, 490009",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0738b"
    },
    "Rank": 51233,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0738c"
    },
    "Rank": 51234,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0738d"
    },
    "Rank": 51245,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0738e"
    },
    "Rank": 51249,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "DIP. IN DERM. VENEREOLOGY and LEPROSY/DERMATOLO GY /VENEREOLOGY and DERMATOLOGY/LEPRO SY/VENEREAL DISEASE and LEPROSY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0738f"
    },
    "Rank": 51259,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07390"
    },
    "Rank": 51260,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kashyap Memorial eye hospital Pvt. Ltd., Jharkhand,PURULIA ROAD, RANCHI, Jharkhand, 834001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07391"
    },
    "Rank": 51266,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gopala Gowda Shanthaveri Memorial Hospital, Karnataka,T Narasipura Road, Nazarbad Mysuru, Karnataka, 570010",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07392"
    },
    "Rank": 51268,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Suhel Dev Medical College and Mahrishi Balark Hospital, Uttar Pradesh,MSD AS Medical College KDC Road Bahraich, Uttar Pradesh, 271801",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07393"
    },
    "Rank": 51274,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07394"
    },
    "Rank": 51281,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerudi Hospital and Research Centre, Karnataka,opp axis bank extension area bagalkot, Karnataka, 587101",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07395"
    },
    "Rank": 51282,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Egra Sub Divisional Hospital and SSH ,Egra, PO- Egra, Dist- Purba Medinipur, PIN- 721429, West Bengal, 721429",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07396"
    },
    "Rank": 51284,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaikh-Ul-Hind Maulana Mahmood Hasan Medical College, Saharanpur,Pilkhani Ambala Road, Uttar Pradesh, 247232",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07397"
    },
    "Rank": 51293,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07398"
    },
    "Rank": 51303,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07399"
    },
    "Rank": 51307,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0739a"
    },
    "Rank": 51314,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0739b"
    },
    "Rank": 51317,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0739c"
    },
    "Rank": 51327,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0739d"
    },
    "Rank": 51328,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagyoday Tirth Chikitsalay, Sagar,\nMadhya Pradesh,khurai road ,Sagar M.P., Madhya Pradesh, 470002",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0739e"
    },
    "Rank": 51330,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0739f"
    },
    "Rank": 51338,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073a0"
    },
    "Rank": 51341,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073a1"
    },
    "Rank": 51352,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073a2"
    },
    "Rank": 51356,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073a3"
    },
    "Rank": 51368,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073a4"
    },
    "Rank": 51396,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073a5"
    },
    "Rank": 51397,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073a6"
    },
    "Rank": 51404,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073a7"
    },
    "Rank": 51407,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073a8"
    },
    "Rank": 51411,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073a9"
    },
    "Rank": 51420,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073aa"
    },
    "Rank": 51423,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073ab"
    },
    "Rank": 51429,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073ac"
    },
    "Rank": 51430,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gopala Gowda Shanthaveri Memorial Hospital, Karnataka,T Narasipura Road, Nazarbad Mysuru, Karnataka, 570010",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073ad"
    },
    "Rank": 51434,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073ae"
    },
    "Rank": 51455,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073af"
    },
    "Rank": 51459,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhima Bhoi Medical College and Hospital, Balangir,At-Laltikra Po-Rajendra College, Balangir, Odisha, 767002",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073b0"
    },
    "Rank": 51470,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073b1"
    },
    "Rank": 51487,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073b2"
    },
    "Rank": 51492,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073b3"
    },
    "Rank": 51497,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073b4"
    },
    "Rank": 51500,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073b5"
    },
    "Rank": 51508,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073b6"
    },
    "Rank": 51523,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sacred Heart Hospital, ,Sacred Heart Hospital Maqsudan\nJalandhar Punjab, Punjab, 144008",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073b7"
    },
    "Rank": 51528,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073b8"
    },
    "Rank": 51530,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073b9"
    },
    "Rank": 51533,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073ba"
    },
    "Rank": 51559,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073bb"
    },
    "Rank": 51561,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073bc"
    },
    "Rank": 51567,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073bd"
    },
    "Rank": 51573,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073be"
    },
    "Rank": 51581,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerudi Hospital and Research Centre, Karnataka,opp axis bank extension area bagalkot, Karnataka, 587101",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073bf"
    },
    "Rank": 51582,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073c0"
    },
    "Rank": 51583,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073c1"
    },
    "Rank": 51584,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaheed Hasan Khan Mewati Government Medical College,Shaheed Hasan Khan Mewati Government Medical College, Haryana, 122107",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073c2"
    },
    "Rank": 51586,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073c3"
    },
    "Rank": 51602,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Associated Hospital Government Medical College, ,Kanth Bagh Baramulla, Jammu And Kashmir, 193103",
    "Course": "(NBEMS) COMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073c4"
    },
    "Rank": 51604,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073c5"
    },
    "Rank": 51610,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073c6"
    },
    "Rank": 51613,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073c7"
    },
    "Rank": 51614,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073c8"
    },
    "Rank": 51617,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073c9"
    },
    "Rank": 51630,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073ca"
    },
    "Rank": 51668,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerudi Hospital and Research Centre, Karnataka,opp axis bank extension area bagalkot, Karnataka, 587101",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073cb"
    },
    "Rank": 51674,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MMI Narayana Multispecialty Hospital,,Dhamtari Road, Lalpur, Raipur, Chhattisgarh, 492001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073cc"
    },
    "Rank": 51683,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073cd"
    },
    "Rank": 51689,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Diamond Harbour Government Medical College And Hospital, ,Harindanga, New Town, Word No- 4, P.O and P.S- Diamond Harbour, Dist- South 24 Parganas, West Bengal, West Bengal, 743331",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073ce"
    },
    "Rank": 51708,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073cf"
    },
    "Rank": 51710,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073d0"
    },
    "Rank": 51720,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaikh-Ul-Hind Maulana Mahmood Hasan Medical College, Saharanpur,Pilkhani Ambala Road, Uttar Pradesh, 247232",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073d1"
    },
    "Rank": 51726,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073d2"
    },
    "Rank": 51731,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS Medical College Valsad,Nanakwada Halar Road,Valsad ,Gujarat, Gujarat, 396001",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073d3"
    },
    "Rank": 51770,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073d4"
    },
    "Rank": 51773,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Jammu and Kashmir\n,DUDERHAMA GANDERBAL, Jammu And Kashmir, 191201",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073d5"
    },
    "Rank": 51777,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Central Hospital, ,SOUTH EAST CENTRAL RAILWAY, OPPOSITE GOLF GROUND,\nBILASPUR, Chhattisgarh, 495004",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073d6"
    },
    "Rank": 51806,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073d7"
    },
    "Rank": 51810,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073d8"
    },
    "Rank": 51818,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Bangur Hospital, Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan , Rajasthan-306401,Government Medical College and Bangur Hospital Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan Ra, Rajasthan, 306401",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073d9"
    },
    "Rank": 51820,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073da"
    },
    "Rank": 51833,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Johal Multispeciality Hospital,Hoshiarpur Road Rama Mandi Jalandhar Punjab 144005, Punjab, 144005",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073db"
    },
    "Rank": 51834,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Habra State General Hospital,Habra SGH, North 24 Pgs, Habra, Pin - 743263, West Bengal, 743263",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073dc"
    },
    "Rank": 51841,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073dd"
    },
    "Rank": 51847,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073de"
    },
    "Rank": 51852,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "AUTONOMOUS STATE MEDICAL COLLEGE, ,RAJARSHI DASHRATH AUTONOMOUS STATE MEDICAL COLLEGE VILL- GANJA, POST- DABHA SEMAR, DISTRICT- AYODHYA, Uttar Pradesh, 224133",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073df"
    },
    "Rank": 51856,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pandit Raghunath Murmu Medical College and Hospital , Orissa,AT-RANGAMATIA, PO- SHAMAKHUNTA, PS-BARIPADA SADAR, BARIPADA, MAYURBHANJ, Odisha, 757107",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073e0"
    },
    "Rank": 51857,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Bundelkhand Medical College Sagar M.P.,Govt. Bundelkhand Medical College Sagar M.P., Madhya Pradesh, 470001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073e1"
    },
    "Rank": 51865,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073e2"
    },
    "Rank": 51870,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE - KANNUR,GOVERNMENT MEDICAL COLLEGE\n- KANNUR, Kerala, 670503",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073e3"
    },
    "Rank": 51874,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073e4"
    },
    "Rank": 51887,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073e5"
    },
    "Rank": 51916,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073e6"
    },
    "Rank": 51917,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073e7"
    },
    "Rank": 51925,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073e8"
    },
    "Rank": 51929,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,Veer Chandra Singh Garhwali Govt. Institute of Medical Science and Research, Srinagar Garhwal, Uttarakhand, 246174",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073e9"
    },
    "Rank": 51934,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073ea"
    },
    "Rank": 51940,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073eb"
    },
    "Rank": 51950,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Hospital Administration)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073ec"
    },
    "Rank": 51957,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073ed"
    },
    "Rank": 51959,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073ee"
    },
    "Rank": 51960,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073ef"
    },
    "Rank": 51962,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073f0"
    },
    "Rank": 51979,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073f1"
    },
    "Rank": 51990,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073f2"
    },
    "Rank": 51991,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kashyap Memorial eye hospital Pvt. Ltd., Jharkhand,PURULIA ROAD, RANCHI, Jharkhand, 834001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073f3"
    },
    "Rank": 51998,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. District Civil Hospital, ,DIS GOVT HOSPITAL, SRI GANGANAGAR, Rajasthan, 335001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073f4"
    },
    "Rank": 52002,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073f5"
    },
    "Rank": 52003,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073f6"
    },
    "Rank": 52009,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073f7"
    },
    "Rank": 52019,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073f8"
    },
    "Rank": 52025,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073f9"
    },
    "Rank": 52030,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kerudi Hospital and Research Centre, Karnataka,opp axis bank extension area bagalkot, Karnataka, 587101",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073fa"
    },
    "Rank": 52039,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073fb"
    },
    "Rank": 52041,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073fc"
    },
    "Rank": 52054,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073fd"
    },
    "Rank": 52055,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Medical College for Women, Khanpur Kalan Sonepat,BPS Govt. Medical College for Women, Khanpur Kalan Sonepat, Haryana, 131305",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073fe"
    },
    "Rank": 52058,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a073ff"
    },
    "Rank": 52069,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07400"
    },
    "Rank": 52078,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07401"
    },
    "Rank": 52095,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN,HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN, Karnataka, 573201",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07402"
    },
    "Rank": 52126,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07403"
    },
    "Rank": 52129,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Datia,Near 29 Batallion, National Highway 44, Dist Datia, Madhya Pradesh Pin - 475661, Madhya Pradesh, 475661",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07404"
    },
    "Rank": 52142,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07405"
    },
    "Rank": 52143,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07406"
    },
    "Rank": 52144,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,SECTOR-6, PANCHKULA, HARYANA-134108, Haryana, 134108",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07407"
    },
    "Rank": 52164,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07408"
    },
    "Rank": 52171,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07409"
    },
    "Rank": 52176,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0740a"
    },
    "Rank": 52177,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0740b"
    },
    "Rank": 52178,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0740c"
    },
    "Rank": 52201,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0740d"
    },
    "Rank": 52214,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharani Hospital, Jagdalpur, Chhattisgarh,NEAR CHANDNI CHOWK JAGDALPUR, Chhattisgarh, 494001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0740e"
    },
    "Rank": 52219,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0740f"
    },
    "Rank": 52220,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Siddh Multi Specialty Hospital,SIDDH MULTISPECIALTY HOSPITAL  HARTHALA INDUSTRIAL ESTATE KANTH ROAD MORADABAD, Uttar Pradesh, 244001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07410"
    },
    "Rank": 52238,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07411"
    },
    "Rank": 52252,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07412"
    },
    "Rank": 52253,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07413"
    },
    "Rank": 52260,
    "Allotted Quota": "All India",
    "Allotted Institute": "GADAG INSTITUTE OF MEDICAL SCIENCES GADAG,Gadag Institute of Medical Sciences Mallasamudra Gadag-582103, Karnataka, 582103",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07414"
    },
    "Rank": 52274,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Purba Medinipur District Hospital, ,PO - TAMLUK, PS - TAMLUK, DIST - PURBA MEDINIPUR, West Bengal, 721636",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07415"
    },
    "Rank": 52291,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07416"
    },
    "Rank": 52298,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07417"
    },
    "Rank": 52300,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07418"
    },
    "Rank": 52304,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07419"
    },
    "Rank": 52331,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Siddh Multi Specialty Hospital,SIDDH MULTISPECIALTY HOSPITAL  HARTHALA INDUSTRIAL ESTATE KANTH ROAD MORADABAD, Uttar Pradesh, 244001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0741a"
    },
    "Rank": 52341,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0741b"
    },
    "Rank": 52356,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Hospital,,JANGLAT MANDI ANANTNAG, JAMMU AND KASHMIR 192101, Jammu And Kashmir, 192101",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0741c"
    },
    "Rank": 52358,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0741d"
    },
    "Rank": 52363,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0741e"
    },
    "Rank": 52369,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0741f"
    },
    "Rank": 52370,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07420"
    },
    "Rank": 52372,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07421"
    },
    "Rank": 52374,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07422"
    },
    "Rank": 52381,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nirmala Multi Speciality Hospital, Karnataka,academicsnirmala@gmail.com, Karnataka, 570028",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07423"
    },
    "Rank": 52394,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "North 24 Parganas District Hospital, ,BANAMALIPUR BARASAT WEST BENGAL, West Bengal, 700124",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07424"
    },
    "Rank": 52395,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kashyap Memorial eye hospital Pvt. Ltd., Jharkhand,PURULIA ROAD, RANCHI, Jharkhand, 834001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07425"
    },
    "Rank": 52396,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "JBMM District Hospital , ,JBMM DISTRICT HOSPITAL RAM BAGH PHATAK NANDAN CINEMA\nCHOWK AMRITSAR AMRITSAR AMRITSAR IN\n143001, Punjab, 143001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07426"
    },
    "Rank": 52399,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07427"
    },
    "Rank": 52404,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07428"
    },
    "Rank": 52422,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07429"
    },
    "Rank": 52436,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0742a"
    },
    "Rank": 52455,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0742b"
    },
    "Rank": 52464,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0742c"
    },
    "Rank": 52468,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0742d"
    },
    "Rank": 52492,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0742e"
    },
    "Rank": 52494,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0742f"
    },
    "Rank": 52497,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07430"
    },
    "Rank": 52503,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07431"
    },
    "Rank": 52506,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07432"
    },
    "Rank": 52508,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07433"
    },
    "Rank": 52510,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07434"
    },
    "Rank": 52535,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07435"
    },
    "Rank": 52550,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07436"
    },
    "Rank": 52555,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07437"
    },
    "Rank": 52556,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07438"
    },
    "Rank": 52569,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07439"
    },
    "Rank": 52580,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0743a"
    },
    "Rank": 52585,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0743b"
    },
    "Rank": 52589,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0743c"
    },
    "Rank": 52591,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0743d"
    },
    "Rank": 52604,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nanjappa Hospital, Karnataka,#660, Kuvempu Road, Shimoga, Karnataka, 577201",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0743e"
    },
    "Rank": 52606,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT VELLORE MEDICAL COLLEGE,GOVERNMENT VELLORE MEDICAL COLLEGE, Tamil Nadu, 632011",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0743f"
    },
    "Rank": 52608,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07440"
    },
    "Rank": 52622,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07441"
    },
    "Rank": 52631,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07442"
    },
    "Rank": 52633,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07443"
    },
    "Rank": 52640,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07444"
    },
    "Rank": 52642,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07445"
    },
    "Rank": 52650,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07446"
    },
    "Rank": 52651,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07447"
    },
    "Rank": 52656,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07448"
    },
    "Rank": 52661,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07449"
    },
    "Rank": 52675,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0744a"
    },
    "Rank": 52677,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0744b"
    },
    "Rank": 52686,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0744c"
    },
    "Rank": 52695,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0744d"
    },
    "Rank": 52696,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nirmala Multi Speciality Hospital, Karnataka,academicsnirmala@gmail.com, Karnataka, 570028",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0744e"
    },
    "Rank": 52708,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0744f"
    },
    "Rank": 52713,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07450"
    },
    "Rank": 52719,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhabha Atomic Research Centre and Hospital, ,Anushaktinagar, Mumbai, Maharashtra, 400094",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07451"
    },
    "Rank": 52720,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,Veer Chandra Singh Garhwali Govt. Institute of Medical Science and Research, Srinagar Garhwal, Uttarakhand, 246174",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07452"
    },
    "Rank": 52722,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07453"
    },
    "Rank": 52735,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kalpana Chawla Govt Medical College, ,Model Town, Karnal, Haryana, 132001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07454"
    },
    "Rank": 52738,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07455"
    },
    "Rank": 52749,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07456"
    },
    "Rank": 52757,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07457"
    },
    "Rank": 52773,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07458"
    },
    "Rank": 52779,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07459"
    },
    "Rank": 52789,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0745a"
    },
    "Rank": 52799,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Civil Hospital, ,Maharaja Agrasen District Civil Hospital Hisar, Haryana, 125001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0745b"
    },
    "Rank": 52811,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0745c"
    },
    "Rank": 52817,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0745d"
    },
    "Rank": 52824,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0745e"
    },
    "Rank": 52831,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0745f"
    },
    "Rank": 52840,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (PALLIATIVE MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07460"
    },
    "Rank": 52846,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07461"
    },
    "Rank": 52861,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07462"
    },
    "Rank": 52862,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.S. ( Traumatology and Surgery )",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07463"
    },
    "Rank": 52868,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07464"
    },
    "Rank": 52886,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07465"
    },
    "Rank": 52890,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07466"
    },
    "Rank": 52896,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07467"
    },
    "Rank": 52898,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07468"
    },
    "Rank": 52937,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Civil Hospital, ,Maharaja Agrasen District Civil Hospital Hisar, Haryana, 125001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07469"
    },
    "Rank": 52938,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0746a"
    },
    "Rank": 52942,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0746b"
    },
    "Rank": 52944,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0746c"
    },
    "Rank": 52948,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. GERIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0746d"
    },
    "Rank": 52953,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0746e"
    },
    "Rank": 52966,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0746f"
    },
    "Rank": 52982,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07470"
    },
    "Rank": 52998,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07471"
    },
    "Rank": 53009,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07472"
    },
    "Rank": 53010,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07473"
    },
    "Rank": 53014,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Allopathic Medical College, Uttar Pradesh,RANI DURGAVATI MEDICAL COLLEGE NARAINI ROAD BANDA, Uttar Pradesh, 210001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07474"
    },
    "Rank": 53023,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Datia,Near 29 Batallion, National Highway 44, Dist Datia, Madhya Pradesh Pin - 475661, Madhya Pradesh, 475661",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07475"
    },
    "Rank": 53037,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESIC Model Hospital, ,ms-bapunagar.gj@esic.nic.in, Gujarat, 380024",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07476"
    },
    "Rank": 53042,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Doon Medical College, Dehradun,Govt. Doon Medical College Dehrakhas, Patel Nagar Dehradun 248001, Uttarakhand, 248001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07477"
    },
    "Rank": 53061,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07478"
    },
    "Rank": 53067,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07479"
    },
    "Rank": 53097,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0747a"
    },
    "Rank": 53101,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0747b"
    },
    "Rank": 53107,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0747c"
    },
    "Rank": 53121,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0747d"
    },
    "Rank": 53130,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GURUKRIPA HOSPITALS ,JYOTI NAGAR PIPRALI ROAD SIKAR RAJASTHAN,\nRajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0747e"
    },
    "Rank": 53134,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S.K.Hospital, ,SUPERINTENDENT RNO 36  GOVT SK HOSPITAL  SILVER JUBLI ROAD  SIKAR  RAJ, Rajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0747f"
    },
    "Rank": 53135,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kidwai Memorial Institute of Oncolgy,Kidwai Memorial Institute of Oncolgy, Karnataka, 560029",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07480"
    },
    "Rank": 53143,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07481"
    },
    "Rank": 53160,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07482"
    },
    "Rank": 53166,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07483"
    },
    "Rank": 53169,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07484"
    },
    "Rank": 53171,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07485"
    },
    "Rank": 53178,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07486"
    },
    "Rank": 53192,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07487"
    },
    "Rank": 53208,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07488"
    },
    "Rank": 53221,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07489"
    },
    "Rank": 53236,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0748a"
    },
    "Rank": 53251,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0748b"
    },
    "Rank": 53252,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0748c"
    },
    "Rank": 53255,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM Govt District Hospital, ,MGM Govt District Hospital,Hanumangarh town, Rajasthan, 335513",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0748d"
    },
    "Rank": 53282,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0748e"
    },
    "Rank": 53284,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0748f"
    },
    "Rank": 53308,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "VCSG Govt Institute of Medical Science and Research and HNB Base Teaching Hospital\n, ,Srikot Ganganali Srinagar Garhwal Uttarakhand, Uttarakhand, 246174",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07490"
    },
    "Rank": 53309,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07491"
    },
    "Rank": 53313,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SSG government district hospital, ,Udaipur road, Senthi, Chittorgarh, Rajasthan, Rajasthan, 312001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07492"
    },
    "Rank": 53321,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Bangur Hospital, Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan , Rajasthan-306401,Government Medical College and Bangur Hospital Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan Ra, Rajasthan, 306401",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07493"
    },
    "Rank": 53322,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Habra State General Hospital,Habra SGH, North 24 Pgs, Habra, Pin - 743263, West Bengal, 743263",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07494"
    },
    "Rank": 53328,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07495"
    },
    "Rank": 53334,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07496"
    },
    "Rank": 53335,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07497"
    },
    "Rank": 53345,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07498"
    },
    "Rank": 53347,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07499"
    },
    "Rank": 53350,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0749a"
    },
    "Rank": 53371,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0749b"
    },
    "Rank": 53372,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT DHARMAPURI MEDICAL COLLEGE, DHARMAPURI, TAMIL NADU,GOVERNMENT DHARMAPURI MEDICAL COLLEGE, NETHAJI BYE PASS ROAD, DHARMAPURI, TAMIL NADU, Tamil Nadu, 636701",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0749c"
    },
    "Rank": 53373,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0749d"
    },
    "Rank": 53378,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0749e"
    },
    "Rank": 53396,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0749f"
    },
    "Rank": 53397,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074a0"
    },
    "Rank": 53418,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074a1"
    },
    "Rank": 53438,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074a2"
    },
    "Rank": 53439,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074a3"
    },
    "Rank": 53460,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074a4"
    },
    "Rank": 53465,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S.K.Hospital, ,SUPERINTENDENT RNO 36  GOVT SK HOSPITAL  SILVER JUBLI ROAD  SIKAR  RAJ, Rajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074a5"
    },
    "Rank": 53472,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074a6"
    },
    "Rank": 53482,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074a7"
    },
    "Rank": 53483,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074a8"
    },
    "Rank": 53493,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College,Tumuki, Bihaguri, Tezpur, Sonitpur, Assam, Assam, 784010",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074a9"
    },
    "Rank": 53495,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074aa"
    },
    "Rank": 53509,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074ab"
    },
    "Rank": 53512,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074ac"
    },
    "Rank": 53520,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074ad"
    },
    "Rank": 53528,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074ae"
    },
    "Rank": 53530,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074af"
    },
    "Rank": 53546,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Venugram Hospital, Belagavi, Karnataka,CTS 785, 2nd Cross, Hindu Nagar, Near 3rd Railway Gate, Belagavi, Karnataka, 590006",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074b0"
    },
    "Rank": 53554,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074b1"
    },
    "Rank": 53556,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074b2"
    },
    "Rank": 53569,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074b3"
    },
    "Rank": 53578,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074b4"
    },
    "Rank": 53579,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074b5"
    },
    "Rank": 53583,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pandurang Ramarao Dongaonkar District Hospital, Chhattisgarh,Pandurang Ramarao Dongaonkar\nDistrict Hospital Durg G E Road District Durg Chhattisgarh 491001, Chhattisgarh, 491001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074b6"
    },
    "Rank": 53587,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074b7"
    },
    "Rank": 53591,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074b8"
    },
    "Rank": 53597,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074b9"
    },
    "Rank": 53611,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "St. Stephen`s Hospital, ,Tis Hazari, Delhi (NCT), 110054",
    "Course": "(NBEMS) COMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074ba"
    },
    "Rank": 53625,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "DIPLOMA IN PUBLIC HEALTH",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074bb"
    },
    "Rank": 53634,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lalbagh Sub Division Hospital, West Bengal,Golapbagh, PO-Murshidabad, PS- Murshidabad, Dist.-Murshidabad, West Bengal, 742149",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074bc"
    },
    "Rank": 53636,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074bd"
    },
    "Rank": 53637,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074be"
    },
    "Rank": 53651,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074bf"
    },
    "Rank": 53653,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S.K.Hospital, ,SUPERINTENDENT RNO 36  GOVT SK HOSPITAL  SILVER JUBLI ROAD  SIKAR  RAJ, Rajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074c0"
    },
    "Rank": 53656,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074c1"
    },
    "Rank": 53674,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubnagar,Government Medical College, Yedira, Mahabubnagar, Telangana state, Telangana, 509002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074c2"
    },
    "Rank": 53685,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074c3"
    },
    "Rank": 53689,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074c4"
    },
    "Rank": 53692,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074c5"
    },
    "Rank": 53697,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Woodlands Multispeciality Hospital Limited,\n,8-5 ALIPORE ROAD KOLKATA, West Bengal, 700027",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074c6"
    },
    "Rank": 53721,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nanjappa Hospital, Karnataka,#660, Kuvempu Road, Shimoga, Karnataka, 577201",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074c7"
    },
    "Rank": 53736,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074c8"
    },
    "Rank": 53771,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074c9"
    },
    "Rank": 53772,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074ca"
    },
    "Rank": 53782,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074cb"
    },
    "Rank": 53785,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Vinoba Bhave Civil Hospital,,S R Delkar Marg, Saily Road, Silvassa, Dadra And Nagar Haveli, 396230",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074cc"
    },
    "Rank": 53806,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074cd"
    },
    "Rank": 53813,
    "Allotted Quota": "All India",
    "Allotted Institute": "SJP Medical College, Bharatpur,SJP MEDICAL COLLEGE BHARATPUR VILLAGE RAMPURA NH 21, Rajasthan, 321001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074ce"
    },
    "Rank": 53834,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074cf"
    },
    "Rank": 53842,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074d0"
    },
    "Rank": 53851,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute Of Medical Sciences, Kalaburagi,VEERESH NAGAR SEDAM ROAD KALABURAGI, Karnataka, 585105",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074d1"
    },
    "Rank": 53862,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074d2"
    },
    "Rank": 53874,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074d3"
    },
    "Rank": 53892,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074d4"
    },
    "Rank": 53904,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074d5"
    },
    "Rank": 53912,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS Medical College Valsad,Nanakwada Halar Road,Valsad ,Gujarat, Gujarat, 396001",
    "Course": "(NBEMS) MICROBIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074d6"
    },
    "Rank": 53918,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074d7"
    },
    "Rank": 53924,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nanjappa Hospital, Karnataka,#660, Kuvempu Road, Shimoga, Karnataka, 577201",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074d8"
    },
    "Rank": 53926,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074d9"
    },
    "Rank": 53930,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074da"
    },
    "Rank": 53943,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,Opposite to Shivajinagar Bus stop, Shivajinagar, Bengaluru., Karnataka, 560001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074db"
    },
    "Rank": 53945,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nalgonda,Office of the Principal, Government Medical College Nalgonda, Near RTC Bus Stand, Gollaguda,\nNalgon, Telangana, 508001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074dc"
    },
    "Rank": 53952,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074dd"
    },
    "Rank": 53956,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074de"
    },
    "Rank": 53989,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. BDK hospital, govt. ,OPPOSITE ROADWAYS BUS STAND MAAN NAGAR JHUNJHUNU RAJASTHAN, Rajasthan, 333001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074df"
    },
    "Rank": 53990,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. Sports Medicine",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074e0"
    },
    "Rank": 53992,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Nanjappa Hospital, Karnataka,#660, Kuvempu Road, Shimoga, Karnataka, 577201",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074e1"
    },
    "Rank": 54006,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074e2"
    },
    "Rank": 54036,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074e3"
    },
    "Rank": 54046,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074e4"
    },
    "Rank": 54048,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "B.R. Singh Hospital and Centre for Medical Education and Research,,B.R.Singh Hospital Eastern Railway, Sealdah, Kolkata, West Bengal, 700014",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074e5"
    },
    "Rank": 54054,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074e6"
    },
    "Rank": 54064,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074e7"
    },
    "Rank": 54075,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074e8"
    },
    "Rank": 54094,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Hospital, ,Government Medical College and Associated Hospital, Kheora, Rajouri, Jammu and Kashmir-185131, Jammu And Kashmir, 185131",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074e9"
    },
    "Rank": 54095,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GURUKRIPA HOSPITALS ,JYOTI NAGAR PIPRALI ROAD SIKAR RAJASTHAN,\nRajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074ea"
    },
    "Rank": 54097,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074eb"
    },
    "Rank": 54099,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074ec"
    },
    "Rank": 54100,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074ed"
    },
    "Rank": 54103,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074ee"
    },
    "Rank": 54104,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074ef"
    },
    "Rank": 54115,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074f0"
    },
    "Rank": 54121,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074f1"
    },
    "Rank": 54122,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074f2"
    },
    "Rank": 54129,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074f3"
    },
    "Rank": 54136,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074f4"
    },
    "Rank": 54154,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074f5"
    },
    "Rank": 54156,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074f6"
    },
    "Rank": 54170,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074f7"
    },
    "Rank": 54209,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074f8"
    },
    "Rank": 54218,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Medical College for Women, Khanpur Kalan Sonepat,BPS Govt. Medical College for Women, Khanpur Kalan Sonepat, Haryana, 131305",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074f9"
    },
    "Rank": 54234,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074fa"
    },
    "Rank": 54240,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074fb"
    },
    "Rank": 54241,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Siddipet,SURVEY NO 54  GOVERNMENT MEDICAL COLLEGE  ENSANPALLY  SIDDIPET   502114, Telangana, 502114",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074fc"
    },
    "Rank": 54264,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INSTITUTE OF MEDICAL SCIENCES,RAICHUR INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 584102",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074fd"
    },
    "Rank": 54265,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074fe"
    },
    "Rank": 54277,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, THOOTHUKUDI,GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, 3rd\nMile,Kamaraj Nagar,Thoothukudi., Tamil Nadu, 628008",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a074ff"
    },
    "Rank": 54285,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07500"
    },
    "Rank": 54299,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "TIBRA EYE HOSPITAL AND RETINA CENTRE,\nRajasthan,W-28, SANTOSHI MATA MANDIR KE PAS, RANI SATI ROAD, SIKAR,\nRajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07501"
    },
    "Rank": 54310,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07502"
    },
    "Rank": 54313,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07503"
    },
    "Rank": 54315,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07504"
    },
    "Rank": 54319,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07505"
    },
    "Rank": 54342,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "PG Diploma in Psychological Medicine / Psychiatric Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07506"
    },
    "Rank": 54356,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07507"
    },
    "Rank": 54358,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07508"
    },
    "Rank": 54360,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karnataka,MG Road, Karnataka, 581301",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07509"
    },
    "Rank": 54361,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0750a"
    },
    "Rank": 54365,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Katwa Sub Divisional Hospital, West Bengal,Kachari Road, Katwa, Purba Bardhaman, West Bengal, 713130",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0750b"
    },
    "Rank": 54378,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0750c"
    },
    "Rank": 54379,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0750d"
    },
    "Rank": 54381,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0750e"
    },
    "Rank": 54386,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0750f"
    },
    "Rank": 54391,
    "Allotted Quota": "All India",
    "Allotted Institute": "K.A.P.Viswanatham Government Medical College, Tiruchirapalli,PERIYAMILAGUPARAI, TIRUCHIRAPPALLI,, Tamil Nadu, 620001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07510"
    },
    "Rank": 54406,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College, Kolhapur,R.K. Nagar Road, Shenda Park, Kolhapur, Maharashtra, Maharashtra, 416012",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07511"
    },
    "Rank": 54423,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07512"
    },
    "Rank": 54445,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07513"
    },
    "Rank": 54454,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07514"
    },
    "Rank": 54464,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07515"
    },
    "Rank": 54472,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees' State Insurance Corporation Medical College & Hospital, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD GULBARGA, Karnataka, 585106",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07516"
    },
    "Rank": 54479,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07517"
    },
    "Rank": 54481,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07518"
    },
    "Rank": 54486,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07519"
    },
    "Rank": 54546,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "JLN Government Hospital,,NH 89 BIKANER ROAD, NAGAUR, Rajasthan, 341001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0751a"
    },
    "Rank": 54562,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0751b"
    },
    "Rank": 54564,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0751c"
    },
    "Rank": 54565,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "KHATRA SUB DIVISINAL HOSPITAL SUB DIVISIONAL HOSPITAL , West Bengal,KHATRA S D HOSPITAL BANKURA, West Bengal, 722140",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0751d"
    },
    "Rank": 54584,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Suri Sadar Hospital, ,Laldighipara Suri Birbhum, West Bengal, 731101",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0751e"
    },
    "Rank": 54590,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhima Bhoi Medical College and Hospital, Balangir,At-Laltikra Po-Rajendra College, Balangir, Odisha, 767002",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0751f"
    },
    "Rank": 54600,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur and Associated Group of Hospitals Shri Haridev Joshi Hospital,,Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur, Rajasthan, 314001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07520"
    },
    "Rank": 54607,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ursala Horsman Memorial Hospital, ,Bada Chauraha, Civil Lines,Kanpur Nagar, Uttar Pradesh, 208001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07521"
    },
    "Rank": 54614,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07522"
    },
    "Rank": 54633,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07523"
    },
    "Rank": 54665,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07524"
    },
    "Rank": 54666,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S.K.Hospital, ,SUPERINTENDENT RNO 36  GOVT SK HOSPITAL  SILVER JUBLI ROAD  SIKAR  RAJ, Rajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07525"
    },
    "Rank": 54669,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07526"
    },
    "Rank": 54676,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07527"
    },
    "Rank": 54678,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07528"
    },
    "Rank": 54686,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07529"
    },
    "Rank": 54687,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Second Sight Southend Eye Centre Pvt. Ltd., West Bengal,Baruipur Amtola Road. Dhopagachi, Kundarali, PS.Baruipur, Westbengal, Pin - 743610, West Bengal, 743610",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0752a"
    },
    "Rank": 54688,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0752b"
    },
    "Rank": 54725,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0752c"
    },
    "Rank": 54730,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0752d"
    },
    "Rank": 54733,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0752e"
    },
    "Rank": 54739,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0752f"
    },
    "Rank": 54744,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07530"
    },
    "Rank": 54745,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07531"
    },
    "Rank": 54747,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07532"
    },
    "Rank": 54748,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sheth Vadilal Sarabhai General Hospital and Sheth Chinai Prasuti Hospital, Gujarat,SHETH VADILAL SARABHAI GENERAL HOSPITAL AND SHETH CHINAI PRASUTI HOSPITAL ELLISBRIDGE AHMEDABAD, Gujarat, 380006",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07533"
    },
    "Rank": 54763,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "NEOTIA GETWEL HEALTHCARE CENTRE,\n,Uttorayon, Behind City Centre, Matigara, Siliguri, West Bengal, 734010",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07534"
    },
    "Rank": 54789,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07535"
    },
    "Rank": 54793,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07536"
    },
    "Rank": 54794,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07537"
    },
    "Rank": 54795,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07538"
    },
    "Rank": 54801,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07539"
    },
    "Rank": 54812,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0753a"
    },
    "Rank": 54818,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES ADILABAD -504001 TELANGANA STATE, Telangana, 504001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0753b"
    },
    "Rank": 54819,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital , ,Dist. Hospital Pratapgarh, Banswara Road, Pratapgarh, Rajasthan, 312605",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0753c"
    },
    "Rank": 54828,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0753d"
    },
    "Rank": 54829,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "PRIYA HOSPITAL,\nUttar Pradesh,D-25, HIG, World Bank Barra, Bypass Road, Kanpur Nagar, Uttar Pradesh, 208027",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0753e"
    },
    "Rank": 54830,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Ganga Ram Hospital, ,Rajinder Nagar, New Delhi, Delhi (NCT), 110060",
    "Course": "(NBEMS) MICROBIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0753f"
    },
    "Rank": 54834,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07540"
    },
    "Rank": 54847,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07541"
    },
    "Rank": 54860,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "NEOTIA GETWEL HEALTHCARE CENTRE,\n,Uttorayon, Behind City Centre, Matigara, Siliguri, West Bengal, 734010",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07542"
    },
    "Rank": 54864,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07543"
    },
    "Rank": 54869,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07544"
    },
    "Rank": 54881,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07545"
    },
    "Rank": 54882,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE AND ESIC HOSPITAL, COIMBATORE,KAMARAJAR ROAD, VARATHARAJAPURAM, SINGANALLUR, COIMBATORE, Tamil Nadu, 641015",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07546"
    },
    "Rank": 54899,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07547"
    },
    "Rank": 54903,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07548"
    },
    "Rank": 54912,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07549"
    },
    "Rank": 54922,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0754a"
    },
    "Rank": 54930,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0754b"
    },
    "Rank": 54967,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0754c"
    },
    "Rank": 54970,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur and Associated Group of Hospitals Shri Haridev Joshi Hospital,,Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur, Rajasthan, 314001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0754d"
    },
    "Rank": 54971,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. Medical College and Hospital,,,Government Medical College Hospital Chandigarh, Chandigarh, 160030",
    "Course": "(NBEMS) Hospital Administration",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0754e"
    },
    "Rank": 54981,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0754f"
    },
    "Rank": 54990,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07550"
    },
    "Rank": 54993,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Siliguri District Hospital,,Kachari Road, Siliguri, Dist- Darjeeling, West Bengal, West Bengal, 734001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07551"
    },
    "Rank": 54995,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "NEOTIA GETWEL HEALTHCARE CENTRE,\n,Uttorayon, Behind City Centre, Matigara, Siliguri, West Bengal, 734010",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07552"
    },
    "Rank": 54997,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07553"
    },
    "Rank": 55010,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07554"
    },
    "Rank": 55013,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "RAJ JINDAL HOSPITAL AND RESEARCH CENTRE PVT LTD, Rajasthan,S.P.M. NAGAR BEHIND REGIONAL OFFICE RSPCB BHARATPUR,\nRajasthan, 321001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07555"
    },
    "Rank": 55028,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI MEDICAL COLLEGE KALWA THANE,RAJIV GANDHI MEDICAL COLLEGE,THANE BELAPUR ROAD, KALWA, THANE, Maharashtra, 400605",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07556"
    },
    "Rank": 55033,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07557"
    },
    "Rank": 55057,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07558"
    },
    "Rank": 55084,
    "Allotted Quota": "All India",
    "Allotted Institute": "SJP Medical College, Bharatpur,SJP MEDICAL COLLEGE BHARATPUR VILLAGE RAMPURA NH 21, Rajasthan, 321001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07559"
    },
    "Rank": 55113,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0755a"
    },
    "Rank": 55114,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "North 24 Parganas District Hospital, ,BANAMALIPUR BARASAT WEST BENGAL, West Bengal, 700124",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0755b"
    },
    "Rank": 55118,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S.K.Hospital, ,SUPERINTENDENT RNO 36  GOVT SK HOSPITAL  SILVER JUBLI ROAD  SIKAR  RAJ, Rajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0755c"
    },
    "Rank": 55130,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "RAJ JINDAL HOSPITAL AND RESEARCH CENTRE PVT LTD, Rajasthan,S.P.M. NAGAR BEHIND REGIONAL OFFICE RSPCB BHARATPUR,\nRajasthan, 321001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0755d"
    },
    "Rank": 55134,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0755e"
    },
    "Rank": 55139,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0755f"
    },
    "Rank": 55140,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07560"
    },
    "Rank": 55149,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07561"
    },
    "Rank": 55165,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07562"
    },
    "Rank": 55168,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07563"
    },
    "Rank": 55169,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07564"
    },
    "Rank": 55180,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07565"
    },
    "Rank": 55189,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07566"
    },
    "Rank": 55199,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07567"
    },
    "Rank": 55203,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital,\n,DISTRICT SUREGEON DISTRICT HOSPITAL SETOR-11 NAVANAGAR BAGALKOTE\nKARNATAKA, Karnataka, 587103",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07568"
    },
    "Rank": 55223,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07569"
    },
    "Rank": 55228,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0756a"
    },
    "Rank": 55233,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0756b"
    },
    "Rank": 55240,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0756c"
    },
    "Rank": 55242,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0756d"
    },
    "Rank": 55260,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0756e"
    },
    "Rank": 55268,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0756f"
    },
    "Rank": 55278,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07570"
    },
    "Rank": 55309,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District General Hospital, ,Government District General Hospital, Noonepalle, Nandyal - 518502, Andhra Pradesh, 518502",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07571"
    },
    "Rank": 55311,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07572"
    },
    "Rank": 55312,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Gheesibai Memorial Mittal Hospital and Research Centre,,Gheesi Bai Memorial Mittal Hospital and Research Centre, Pushkar Road, Ajmer, Rajasthan, 305004",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07573"
    },
    "Rank": 55313,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dholpur,Old City Road Dholpur, Rajasthan, 328001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07574"
    },
    "Rank": 55317,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07575"
    },
    "Rank": 55338,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government General Hospital, Principal Medical Officer General hospital,\n,Rajiv Gandhi Government General Hospital, near bijli ghar circle, ALWAR, Rajasthan, 301001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07576"
    },
    "Rank": 55354,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07577"
    },
    "Rank": 55358,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07578"
    },
    "Rank": 55362,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07579"
    },
    "Rank": 55364,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Baramati,Plot No P-107 Opposite Women Hospital Baramati MIDC Area Baramati Tehsil Baramati Dist Pune, Maharashtra, 413133",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0757a"
    },
    "Rank": 55369,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0757b"
    },
    "Rank": 55378,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0757c"
    },
    "Rank": 55384,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0757d"
    },
    "Rank": 55410,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0757e"
    },
    "Rank": 55418,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0757f"
    },
    "Rank": 55426,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07580"
    },
    "Rank": 55427,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07581"
    },
    "Rank": 55440,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07582"
    },
    "Rank": 55443,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07583"
    },
    "Rank": 55448,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07584"
    },
    "Rank": 55457,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07585"
    },
    "Rank": 55459,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chamarajanagar Institute of Medical Sciences, Karnataka,CHAMARAJANAGAR INSTITUTE OF MEDICAL SCIENCES, SY. NO 124, YEDAPURA VILLAGE KASABA HOBLI, CHAMARAJANA, Karnataka, 571313",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07586"
    },
    "Rank": 55471,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Uttarpara State General Hospital,,RPM ROAD,UTTARPARA,HOOGHLY-712258, West Bengal, 712258",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07587"
    },
    "Rank": 55472,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07588"
    },
    "Rank": 55476,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07589"
    },
    "Rank": 55477,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0758a"
    },
    "Rank": 55478,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SSG government district hospital, ,Udaipur road, Senthi, Chittorgarh, Rajasthan, Rajasthan, 312001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0758b"
    },
    "Rank": 55485,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0758c"
    },
    "Rank": 55494,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0758d"
    },
    "Rank": 55498,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0758e"
    },
    "Rank": 55501,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0758f"
    },
    "Rank": 55506,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07590"
    },
    "Rank": 55533,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sub Division Hospital, Karnataka,MM HILLS ROAD, KOLLEGAL, CHAMARAJANAGAR DISTRICT, Karnataka, 571440",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07591"
    },
    "Rank": 55542,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07592"
    },
    "Rank": 55556,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07593"
    },
    "Rank": 55565,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07594"
    },
    "Rank": 55566,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Woodland Hospital, Meghalaya,Dhankheti P.O. Laitumkhrah East Khasi Hills District Shillong, Meghalaya, 793003",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07595"
    },
    "Rank": 55573,
    "Allotted Quota": "All India",
    "Allotted Institute": "Calcutta School Of Tropical Medicine,Calcutta School Of Tropical Medicine, West Bengal, 700073",
    "Course": "M.D. (TROPICAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07596"
    },
    "Rank": 55580,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07597"
    },
    "Rank": 55583,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07598"
    },
    "Rank": 55584,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SNR District Hospital,,SNR DISTRICT HOSPITAL, KOLAR KARNATAKA-563101,\nKarnataka, 563101",
    "Course": "(NBEMS) PAEDIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a07599"
    },
    "Rank": 55590,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE, PUDUCHERRY,INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE,\nPUDUCHERRY, Puducherry, 605009",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0759a"
    },
    "Rank": 55599,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0759b"
    },
    "Rank": 55600,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0759c"
    },
    "Rank": 55606,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0759d"
    },
    "Rank": 55629,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0759e"
    },
    "Rank": 55632,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata Shrimati Devendra Kumari Singhdeo Government Medical College, Ambikapur,Kanyaparisar  Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a0759f"
    },
    "Rank": 55636,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "FIMS HOSPITAL(FRANK INSTITUTE OF MEDICAL SCIENCES), Haryana,Sonipat-\nBahalgarh Road, Sonipat-NCR, Sonipat, Haryana, 131001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075a0"
    },
    "Rank": 55646,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075a1"
    },
    "Rank": 55654,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pushpanjali Hospital and Research Centre, Uttar Pradesh,Pushpanjali Hospital and Research Centre Pvt Ltd Pushpanjali Palace, Delhi Gate, Agra, Uttar Pradesh, 282002",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075a2"
    },
    "Rank": 55690,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075a3"
    },
    "Rank": 55699,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kainos Super ,Kainos Super Speciality Hospital, Kheri Sadh Bypass, Delhi Road, Rohtak, Haryana, 124021",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075a4"
    },
    "Rank": 55704,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata Shrimati Devendra Kumari Singhdeo Government Medical College, Ambikapur,Kanyaparisar  Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075a5"
    },
    "Rank": 55710,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075a6"
    },
    "Rank": 55720,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075a7"
    },
    "Rank": 55726,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075a8"
    },
    "Rank": 55734,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075a9"
    },
    "Rank": 55756,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075aa"
    },
    "Rank": 55777,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075ab"
    },
    "Rank": 55779,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075ac"
    },
    "Rank": 55799,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Kasturba Hospital, Daryaganj,Delhi,Daryaganj,Delhi",
    "Course": "DIP.IN GYNAE. and OBST.",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075ad"
    },
    "Rank": 55808,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075ae"
    },
    "Rank": 55809,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075af"
    },
    "Rank": 55815,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075b0"
    },
    "Rank": 55821,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aralaguppe Mallegowda District Hospital,,AZAD PARK CHIKKAMAGALURU, Karnataka, 577101",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075b1"
    },
    "Rank": 55822,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. District Civil Hospital, ,DIS GOVT HOSPITAL, SRI GANGANAGAR, Rajasthan, 335001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075b2"
    },
    "Rank": 55834,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075b3"
    },
    "Rank": 55845,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075b4"
    },
    "Rank": 55856,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075b5"
    },
    "Rank": 55866,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075b6"
    },
    "Rank": 55877,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,Veer Chandra Singh Garhwali Govt. Institute of Medical Science and Research, Srinagar Garhwal, Uttarakhand, 246174",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075b7"
    },
    "Rank": 55894,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Civil Hospital,\n,JAGADHARI ROAD, CIVIL HOSPITAL , AMBALA CANTT, Haryana, 133001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075b8"
    },
    "Rank": 55897,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075b9"
    },
    "Rank": 55911,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075ba"
    },
    "Rank": 55915,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075bb"
    },
    "Rank": 55917,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075bc"
    },
    "Rank": 55921,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075bd"
    },
    "Rank": 55926,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Medical College and Chhatrapati Shivaji Maharaj Hospital, ,RAJIV GANDHI MEDICAL COLLEGE,THANE BELAPUR ROAD, KALWA, THANE, Maharashtra, 400605",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075be"
    },
    "Rank": 55940,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075bf"
    },
    "Rank": 55944,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075c0"
    },
    "Rank": 55946,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075c1"
    },
    "Rank": 55955,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075c2"
    },
    "Rank": 55967,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075c3"
    },
    "Rank": 55969,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075c4"
    },
    "Rank": 55971,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Woodland Hospital, Meghalaya,Dhankheti P.O. Laitumkhrah East Khasi Hills District Shillong, Meghalaya, 793003",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075c5"
    },
    "Rank": 55975,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karnataka,MG Road, Karnataka, 581301",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075c6"
    },
    "Rank": 55983,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075c7"
    },
    "Rank": 55992,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075c8"
    },
    "Rank": 55995,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075c9"
    },
    "Rank": 55998,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075ca"
    },
    "Rank": 56022,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075cb"
    },
    "Rank": 56029,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,SECTOR-6, PANCHKULA, HARYANA-134108, Haryana, 134108",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075cc"
    },
    "Rank": 56035,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075cd"
    },
    "Rank": 56037,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075ce"
    },
    "Rank": 56039,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075cf"
    },
    "Rank": 56042,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Bangur Hospital, Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan , Rajasthan-306401,Government Medical College and Bangur Hospital Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan Ra, Rajasthan, 306401",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075d0"
    },
    "Rank": 56066,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075d1"
    },
    "Rank": 56067,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075d2"
    },
    "Rank": 56072,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075d3"
    },
    "Rank": 56076,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075d4"
    },
    "Rank": 56094,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Suryapet Telangana,Amaravadi Nagar Thallagadda Suryapet, Telangana, 508213",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075d5"
    },
    "Rank": 56100,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SDH KUPWARA, Jammu and Kashmir,IQBAL MARKET KUPWARA, Jammu And Kashmir, 193222",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075d6"
    },
    "Rank": 56103,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Government Medical College, Vidisha,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA MP, Madhya Pradesh, 464001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075d7"
    },
    "Rank": 56104,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jalgaon,Jilha Peth, Government Hospital Campus, Jalgaon, Maharashtra, 425001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075d8"
    },
    "Rank": 56116,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075d9"
    },
    "Rank": 56122,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043912f54ba6d9a075da"
    },
    "Rank": 56148,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075db"
    },
    "Rank": 56150,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075dc"
    },
    "Rank": 56164,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075dd"
    },
    "Rank": 56167,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075de"
    },
    "Rank": 56177,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "National Institute of Tuberculosis and Respiratory Diseases, (Formerly Known as L.R.S. Instt. of T.B. and Allied Scs),,National Institute of Tuberculosis and Respiratory Diseases (Formerly Known as L.R.S. Instt. of T.B., Delhi (NCT), 110030",
    "Course": "(NBEMS) MICROBIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075df"
    },
    "Rank": 56184,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jan Swasthya Sahyog,,,Village and PO Ganiyari - 495112, Dist. Bilaspur,\nChhattisgarh, Chhattisgarh, 495112",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075e0"
    },
    "Rank": 56188,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tomo Riba Institute of Health and Medical Sciences,,TRIHMS. Old Assembly Block. Near Rajiv Gandhi Stadium. Naharlagun-791110. Arunachal Pradesh, Arunachal Pradesh, 791110",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075e1"
    },
    "Rank": 56201,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Wenlock District Hospital, ,HAMPANAKATTA MANGALORE, Karnataka, 575001",
    "Course": "(NBEMS) IMMUNO- HAEMATOLOGY AND BLOOD TRANSFUSION",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075e2"
    },
    "Rank": 56217,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075e3"
    },
    "Rank": 56222,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075e4"
    },
    "Rank": 56225,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075e5"
    },
    "Rank": 56277,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075e6"
    },
    "Rank": 56280,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075e7"
    },
    "Rank": 56282,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075e8"
    },
    "Rank": 56295,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075e9"
    },
    "Rank": 56298,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075ea"
    },
    "Rank": 56329,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075eb"
    },
    "Rank": 56336,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075ec"
    },
    "Rank": 56342,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075ed"
    },
    "Rank": 56364,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075ee"
    },
    "Rank": 56380,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075ef"
    },
    "Rank": 56383,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075f0"
    },
    "Rank": 56389,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075f1"
    },
    "Rank": 56403,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075f2"
    },
    "Rank": 56405,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075f3"
    },
    "Rank": 56409,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075f4"
    },
    "Rank": 56417,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government General Hospital, Principal Medical Officer General hospital,\n,Rajiv Gandhi Government General Hospital, near bijli ghar circle, ALWAR, Rajasthan, 301001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075f5"
    },
    "Rank": 56418,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075f6"
    },
    "Rank": 56421,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075f7"
    },
    "Rank": 56422,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075f8"
    },
    "Rank": 56440,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Marwari Hospitals, Assam,Sati Jaymati Road, Athgoan, Guwahati, Assam, 781008",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075f9"
    },
    "Rank": 56451,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075fa"
    },
    "Rank": 56454,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075fb"
    },
    "Rank": 56458,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075fc"
    },
    "Rank": 56470,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "APL and ANR Area, Andhra Pradesh,Area Hospital, Gudiwada\nOPP Head Post office Road, Fire station road, Gudiwada, Andhra Pradesh, 521301",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075fd"
    },
    "Rank": 56475,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075fe"
    },
    "Rank": 56477,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a075ff"
    },
    "Rank": 56481,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07600"
    },
    "Rank": 56500,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07601"
    },
    "Rank": 56515,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07602"
    },
    "Rank": 56525,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Siddipet,SURVEY NO 54  GOVERNMENT MEDICAL COLLEGE  ENSANPALLY  SIDDIPET   502114, Telangana, 502114",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07603"
    },
    "Rank": 56529,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07604"
    },
    "Rank": 56535,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07605"
    },
    "Rank": 56570,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Institute of Nuclear Medicine & Allied Sciences,Institute of Nuclear Medicine & Allied Sciences, Delhi (NCT), 110054",
    "Course": "DIP IN RADIATION MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07606"
    },
    "Rank": 56592,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital, ,Kharvad medan Sipor road Vadnagar, Gujarat, 384355",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07607"
    },
    "Rank": 56596,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District General Hospital, ,Government District General Hospital, Noonepalle, Nandyal - 518502, Andhra Pradesh, 518502",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07608"
    },
    "Rank": 56611,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07609"
    },
    "Rank": 56636,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0760a"
    },
    "Rank": 56637,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta Hospital,\nUttar Pradesh,Medanta hospital, Sec A pocket 1, Amar Shaheed Path Sushant Golf City Lucknow, Uttar Pradesh, 226030",
    "Course": "(NBEMS) MICROBIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0760b"
    },
    "Rank": 56646,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0760c"
    },
    "Rank": 56649,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0760d"
    },
    "Rank": 56695,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0760e"
    },
    "Rank": 56699,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0760f"
    },
    "Rank": 56702,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07610"
    },
    "Rank": 56704,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07611"
    },
    "Rank": 56714,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07612"
    },
    "Rank": 56723,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07613"
    },
    "Rank": 56727,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07614"
    },
    "Rank": 56751,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07615"
    },
    "Rank": 56754,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07616"
    },
    "Rank": 56781,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07617"
    },
    "Rank": 56805,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Breach Candy Hospital, ,60-A, Bhulabhai Desai Road, Mumbai- 400 026., Maharashtra, 400026",
    "Course": "(NBEMS) MICROBIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07618"
    },
    "Rank": 56831,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "DIPLOMA IN OTO- RHINO-LARYNGOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07619"
    },
    "Rank": 56846,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0761a"
    },
    "Rank": 56853,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Bangur Hospital, Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan , Rajasthan-306401,Government Medical College and Bangur Hospital Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan Ra, Rajasthan, 306401",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0761b"
    },
    "Rank": 56868,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0761c"
    },
    "Rank": 56876,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0761d"
    },
    "Rank": 56891,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0761e"
    },
    "Rank": 56892,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0761f"
    },
    "Rank": 56894,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07620"
    },
    "Rank": 56903,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07621"
    },
    "Rank": 56909,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07622"
    },
    "Rank": 56915,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07623"
    },
    "Rank": 56920,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lala Harbhagwan Das Memorial and Dr Prem Hospital, ,BISHAN SWAROOP COLONY, OPP. BUS STAND, Haryana, 132103",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07624"
    },
    "Rank": 56930,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07625"
    },
    "Rank": 56932,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07626"
    },
    "Rank": 56946,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07627"
    },
    "Rank": 56983,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,OLD SABJI MANDI HINDUAN GATE KARAULI, Rajasthan, 322241",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07628"
    },
    "Rank": 56985,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07629"
    },
    "Rank": 56993,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0762a"
    },
    "Rank": 56997,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0762b"
    },
    "Rank": 57007,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0762c"
    },
    "Rank": 57020,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0762d"
    },
    "Rank": 57036,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "JLNM Hospital, ,JLNM Hospital Rainawari Srinagar, Jammu And Kashmir, 190003",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0762e"
    },
    "Rank": 57042,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0762f"
    },
    "Rank": 57047,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07630"
    },
    "Rank": 57052,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07631"
    },
    "Rank": 57056,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07632"
    },
    "Rank": 57057,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,DISTRICT HOSPITAL NEAR SANGAM CIRCLE DR RAJ KUMAR ROAD BALLARI-583101 KARNATAKA, Karnataka, 583101",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07633"
    },
    "Rank": 57077,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07634"
    },
    "Rank": 57079,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07635"
    },
    "Rank": 57080,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07636"
    },
    "Rank": 57081,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital , ,GMERS MEDICAL COLLEGE , Hadiyol-Gadhoda Road, Near-Government Polytechnic, Hiimmatnagar, Gujarat, 383001",
    "Course": "(NBEMS)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07637"
    },
    "Rank": 57085,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07638"
    },
    "Rank": 57106,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07639"
    },
    "Rank": 57122,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Cottage Hospital , Gujarat,Cottage hospital station road Upleta, Gujarat, 360490",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0763a"
    },
    "Rank": 57127,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0763b"
    },
    "Rank": 57129,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0763c"
    },
    "Rank": 57139,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0763d"
    },
    "Rank": 57198,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0763e"
    },
    "Rank": 57204,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0763f"
    },
    "Rank": 57230,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07640"
    },
    "Rank": 57257,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07641"
    },
    "Rank": 57271,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07642"
    },
    "Rank": 57280,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07643"
    },
    "Rank": 57331,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07644"
    },
    "Rank": 57336,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07645"
    },
    "Rank": 57354,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07646"
    },
    "Rank": 57363,
    "Allotted Quota": "All India",
    "Allotted Institute": "Employees' State Insurance Corporation Medical College & Hospital, Gulbarga,ESIC MEDICAL COLLEGE SEDAM ROAD GULBARGA, Karnataka, 585106",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07647"
    },
    "Rank": 57378,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07648"
    },
    "Rank": 57398,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07649"
    },
    "Rank": 57412,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0764a"
    },
    "Rank": 57414,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0764b"
    },
    "Rank": 57424,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0764c"
    },
    "Rank": 57426,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0764d"
    },
    "Rank": 57430,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0764e"
    },
    "Rank": 57462,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital , ,GMERS MEDICAL COLLEGE , Hadiyol-Gadhoda Road, Near-Government Polytechnic, Hiimmatnagar, Gujarat, 383001",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0764f"
    },
    "Rank": 57466,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07650"
    },
    "Rank": 57470,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07651"
    },
    "Rank": 57475,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07652"
    },
    "Rank": 57497,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Suhel Dev Medical College and Mahrishi Balark Hospital, Uttar Pradesh,MSD AS Medical College KDC Road Bahraich, Uttar Pradesh, 271801",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07653"
    },
    "Rank": 57512,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07654"
    },
    "Rank": 57514,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07655"
    },
    "Rank": 57539,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07656"
    },
    "Rank": 57542,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Medanta The Medicity, ,Medanta The Medicity Sector 38 Gurgoan, Haryana, 122001",
    "Course": "(NBEMS) BIOCHEMISTRY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07657"
    },
    "Rank": 57544,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07658"
    },
    "Rank": 57562,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07659"
    },
    "Rank": 57574,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0765a"
    },
    "Rank": 57581,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0765b"
    },
    "Rank": 57592,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0765c"
    },
    "Rank": 57611,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0765d"
    },
    "Rank": 57623,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0765e"
    },
    "Rank": 57634,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0765f"
    },
    "Rank": 57675,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07660"
    },
    "Rank": 57685,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07661"
    },
    "Rank": 57687,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Vasantrao Naik Govt Medical College Yavatmal,Shri Vasantrao Naik Govt Medical College Yavatmal, Maharashtra, 445001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07662"
    },
    "Rank": 57698,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07663"
    },
    "Rank": 57709,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07664"
    },
    "Rank": 57710,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07665"
    },
    "Rank": 57743,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07666"
    },
    "Rank": 57756,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07667"
    },
    "Rank": 57777,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07668"
    },
    "Rank": 57781,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07669"
    },
    "Rank": 57785,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0766a"
    },
    "Rank": 57823,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0766b"
    },
    "Rank": 57826,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dholpur,Old City Road Dholpur, Rajasthan, 328001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0766c"
    },
    "Rank": 57838,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0766d"
    },
    "Rank": 57845,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0766e"
    },
    "Rank": 57864,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0766f"
    },
    "Rank": 57867,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07670"
    },
    "Rank": 57868,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07671"
    },
    "Rank": 57870,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07672"
    },
    "Rank": 57885,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07673"
    },
    "Rank": 57886,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07674"
    },
    "Rank": 57893,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07675"
    },
    "Rank": 57913,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District Hospital,,POOLBAGH ROAD,NEAR FRUIT MARKET,ANAKAPALLI,VISAKHAPATNAM,ANDHRAPRADESH., Andhra Pradesh, 531001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07676"
    },
    "Rank": 57917,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07677"
    },
    "Rank": 57918,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07678"
    },
    "Rank": 57923,
    "Allotted Quota": "All India",
    "Allotted Institute": "Calcutta School Of Tropical Medicine,Calcutta School Of Tropical Medicine, West Bengal, 700073",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07679"
    },
    "Rank": 57928,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0767a"
    },
    "Rank": 57929,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0767b"
    },
    "Rank": 57945,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0767c"
    },
    "Rank": 57950,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,OLD SABJI MANDI HINDUAN GATE KARAULI, Rajasthan, 322241",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0767d"
    },
    "Rank": 57983,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0767e"
    },
    "Rank": 58017,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0767f"
    },
    "Rank": 58035,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. GERIATRICS",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07680"
    },
    "Rank": 58047,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07681"
    },
    "Rank": 58060,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07682"
    },
    "Rank": 58064,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07683"
    },
    "Rank": 58068,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. Medical College (Maharani Hospital), Jagdalpur, Chhattisgarh,Late Baliram Kashyap memorial Govt. Medical College, Dimrapal, Jagdalpur Dist-Bastar, Chhattisgarh, 494001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07684"
    },
    "Rank": 58087,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07685"
    },
    "Rank": 58091,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07686"
    },
    "Rank": 58096,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07687"
    },
    "Rank": 58100,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07688"
    },
    "Rank": 58106,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07689"
    },
    "Rank": 58109,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0768a"
    },
    "Rank": 58131,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Marwari Hospitals, Assam,Sati Jaymati Road, Athgoan, Guwahati, Assam, 781008",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0768b"
    },
    "Rank": 58154,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0768c"
    },
    "Rank": 58155,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0768d"
    },
    "Rank": 58183,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0768e"
    },
    "Rank": 58187,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Hospital, ,principal.mc.barmer@rajasthan.gov.in, Rajasthan, 344001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0768f"
    },
    "Rank": 58213,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AZAMGARH,Chakrapan Pur, Azamgarh (U.P.),\nUttar Pradesh, 276128",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07690"
    },
    "Rank": 58223,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07691"
    },
    "Rank": 58244,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital ,\nJalore Hospital ,GENERAL HOSPITAL JALORE, HOSPITAL JALORE, OPP. NAGAR PARISHAD, JALORE, RAJASTHAN, Rajasthan, 343001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07692"
    },
    "Rank": 58246,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07693"
    },
    "Rank": 58250,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,Opposite to Shivajinagar Bus stop, Shivajinagar, Bengaluru., Karnataka, 560001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07694"
    },
    "Rank": 58259,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07695"
    },
    "Rank": 58267,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07696"
    },
    "Rank": 58293,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07697"
    },
    "Rank": 58304,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07698"
    },
    "Rank": 58319,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07699"
    },
    "Rank": 58322,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government General Hospital, Principal Medical Officer General hospital,\n,Rajiv Gandhi Government General Hospital, near bijli ghar circle, ALWAR, Rajasthan, 301001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0769a"
    },
    "Rank": 58323,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0769b"
    },
    "Rank": 58324,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0769c"
    },
    "Rank": 58327,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0769d"
    },
    "Rank": 58335,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0769e"
    },
    "Rank": 58350,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0769f"
    },
    "Rank": 58360,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076a0"
    },
    "Rank": 58368,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076a1"
    },
    "Rank": 58373,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076a2"
    },
    "Rank": 58380,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076a3"
    },
    "Rank": 58384,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajan Babu Institute of Pulmonary Medicine and Tuberculosis, ,Rajan Babu Institute for Pulmonary Medicine and Tuberculosis, GTB Nagar, Kingsway Camp, Delhi-110009, Delhi (NCT), 110009",
    "Course": "(NBEMS) Respiratory Medicine",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076a4"
    },
    "Rank": 58386,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076a5"
    },
    "Rank": 58403,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076a6"
    },
    "Rank": 58415,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076a7"
    },
    "Rank": 58416,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital, ,GMERS Medical College and General hospital,Old TB Campus, gotri road, Vadodara, Gujarat, 390021",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076a8"
    },
    "Rank": 58419,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076a9"
    },
    "Rank": 58427,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076aa"
    },
    "Rank": 58431,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076ab"
    },
    "Rank": 58447,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076ac"
    },
    "Rank": 58454,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076ad"
    },
    "Rank": 58473,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076ae"
    },
    "Rank": 58474,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076af"
    },
    "Rank": 58483,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076b0"
    },
    "Rank": 58485,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076b1"
    },
    "Rank": 58494,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076b2"
    },
    "Rank": 58496,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076b3"
    },
    "Rank": 58497,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076b4"
    },
    "Rank": 58501,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES ADILABAD -504001 TELANGANA STATE, Telangana, 504001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076b5"
    },
    "Rank": 58515,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollo Hospital, ,Plotno-251,Sainik school Road,Unit-15 Apollo Hospitals , Bhubaneswar, Odisha, 751005",
    "Course": "(NBEMS) MICROBIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076b6"
    },
    "Rank": 58530,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076b7"
    },
    "Rank": 58538,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "DISTRICT HOSPITAL ,\nChhattisgarh,Room No. 122 - Government District Hospital  Near Mandi Gate Pandari, Raipur, Chhattisgarh, 492001, , Chhattisgarh, 492001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076b8"
    },
    "Rank": 58545,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076b9"
    },
    "Rank": 58549,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076ba"
    },
    "Rank": 58555,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076bb"
    },
    "Rank": 58572,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076bc"
    },
    "Rank": 58575,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Ophthalmology,RIO Prev Known as Nehru Institute of Ophthalmology and Research Centre Civil Lines Sitapur, Uttar Pradesh, 261001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076bd"
    },
    "Rank": 58583,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076be"
    },
    "Rank": 58598,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076bf"
    },
    "Rank": 58625,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076c0"
    },
    "Rank": 58644,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076c1"
    },
    "Rank": 58657,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076c2"
    },
    "Rank": 58680,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076c3"
    },
    "Rank": 58690,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076c4"
    },
    "Rank": 58693,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076c5"
    },
    "Rank": 58698,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INSTITUTE OF MEDICAL SCIENCES,RAICHUR INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 584102",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076c6"
    },
    "Rank": 58707,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076c7"
    },
    "Rank": 58714,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076c8"
    },
    "Rank": 58720,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076c9"
    },
    "Rank": 58739,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076ca"
    },
    "Rank": 58742,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076cb"
    },
    "Rank": 58751,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "INFECTIOUS DISEASES AND BELIAGHATA GENERAL HOSPITAL, West Bengal,57 BELIAGHATA MAIN ROAD,\nKOLKATA, West Bengal, 700010",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076cc"
    },
    "Rank": 58755,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076cd"
    },
    "Rank": 58761,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Kainos Super ,Kainos Super Speciality Hospital, Kheri Sadh Bypass, Delhi Road, Rohtak, Haryana, 124021",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076ce"
    },
    "Rank": 58787,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076cf"
    },
    "Rank": 58788,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076d0"
    },
    "Rank": 58803,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076d1"
    },
    "Rank": 58814,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076d2"
    },
    "Rank": 58826,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076d3"
    },
    "Rank": 58830,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076d4"
    },
    "Rank": 58839,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076d5"
    },
    "Rank": 58864,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076d6"
    },
    "Rank": 58876,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076d7"
    },
    "Rank": 58880,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076d8"
    },
    "Rank": 58883,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076d9"
    },
    "Rank": 58894,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076da"
    },
    "Rank": 58908,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076db"
    },
    "Rank": 58910,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076dc"
    },
    "Rank": 58916,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076dd"
    },
    "Rank": 58924,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076de"
    },
    "Rank": 58938,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076df"
    },
    "Rank": 58973,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076e0"
    },
    "Rank": 58987,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076e1"
    },
    "Rank": 58998,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076e2"
    },
    "Rank": 58999,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076e3"
    },
    "Rank": 59001,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076e4"
    },
    "Rank": 59006,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076e5"
    },
    "Rank": 59023,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076e6"
    },
    "Rank": 59027,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PALLIATIVE MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076e7"
    },
    "Rank": 59038,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "G B Pant Institute of Post Graduate Medical Education and Research,G B Pant Institute of Post Graduate Medical Education and Research, Delhi (NCT), 110007",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076e8"
    },
    "Rank": 59047,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076e9"
    },
    "Rank": 59066,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Imambara District Hospital,,AKHANBAZAR ROAD, CHINSURA, HOOGHLY, West Bengal, 712101",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076ea"
    },
    "Rank": 59071,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076eb"
    },
    "Rank": 59101,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076ec"
    },
    "Rank": 59113,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076ed"
    },
    "Rank": 59117,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "FAKIR MOHAN MEDICAL COLLEGE AND HOSPITAL ,\nOrissa,AT-Remuna Post-Remuna Dist-Balasore, Odisha, 756019",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076ee"
    },
    "Rank": 59131,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076ef"
    },
    "Rank": 59134,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076f0"
    },
    "Rank": 59136,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine & Sagore Dutta Hospital,College of Medicine & Sagore Dutta Hospital, West Bengal, 700058",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076f1"
    },
    "Rank": 59137,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K C General Hospital, ,No. 89, 5th Cross, Malleshwaram, Bangalore, Karnataka, 560003",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076f2"
    },
    "Rank": 59161,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076f3"
    },
    "Rank": 59163,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076f4"
    },
    "Rank": 59168,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIZAMS INSTITUTE OF MEDICAL SCIENCES,NIZAMS INSTITUTE OF MEDICAL SCIENCES  PANJAGUTTA  HYDERABAD, Telangana, 500082",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076f5"
    },
    "Rank": 59182,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076f6"
    },
    "Rank": 59196,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076f7"
    },
    "Rank": 59209,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076f8"
    },
    "Rank": 59210,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076f9"
    },
    "Rank": 59214,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076fa"
    },
    "Rank": 59217,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076fb"
    },
    "Rank": 59218,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pandit Raghunath Murmu Medical College and Hospital , Orissa,AT-RANGAMATIA, PO- SHAMAKHUNTA, PS-BARIPADA SADAR, BARIPADA, MAYURBHANJ, Odisha, 757107",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076fc"
    },
    "Rank": 59221,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076fd"
    },
    "Rank": 59222,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076fe"
    },
    "Rank": 59229,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a076ff"
    },
    "Rank": 59231,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. Sports Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07700"
    },
    "Rank": 59244,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07701"
    },
    "Rank": 59249,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07702"
    },
    "Rank": 59271,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Balrampur Hospital, ,Gola Ganj, lucknow, Uttar Pradesh, 226018",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07703"
    },
    "Rank": 59290,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07704"
    },
    "Rank": 59296,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07705"
    },
    "Rank": 59324,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07706"
    },
    "Rank": 59325,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S.K.Hospital, ,SUPERINTENDENT RNO 36  GOVT SK HOSPITAL  SILVER JUBLI ROAD  SIKAR  RAJ, Rajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07707"
    },
    "Rank": 59333,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07708"
    },
    "Rank": 59345,
    "Allotted Quota": "All India",
    "Allotted Institute": "Murshidabad Medical College and Hospital,Station Road, Berhampore, Murshidabad, West Bengal, 742101",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07709"
    },
    "Rank": 59355,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0770a"
    },
    "Rank": 59357,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Goverment District Hospital Bundi, ,IN FRONT OF COLLECTORATE BUNDI, Rajasthan, 323001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0770b"
    },
    "Rank": 59374,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0770c"
    },
    "Rank": 59387,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0770d"
    },
    "Rank": 59391,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0770e"
    },
    "Rank": 59398,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0770f"
    },
    "Rank": 59443,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07710"
    },
    "Rank": 59444,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07711"
    },
    "Rank": 59448,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07712"
    },
    "Rank": 59457,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government DB General Medical Hosptal,Churu, Nayabass, Churu",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07713"
    },
    "Rank": 59487,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07714"
    },
    "Rank": 59492,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07715"
    },
    "Rank": 59499,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07716"
    },
    "Rank": 59502,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07717"
    },
    "Rank": 59506,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07718"
    },
    "Rank": 59510,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07719"
    },
    "Rank": 59512,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0771a"
    },
    "Rank": 59513,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0771b"
    },
    "Rank": 59518,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0771c"
    },
    "Rank": 59526,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0771d"
    },
    "Rank": 59532,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESI Hospital, ,IG ESI HOSPITAL JHILMIL DELHI - 95, Delhi (NCT), 110095",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0771e"
    },
    "Rank": 59548,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0771f"
    },
    "Rank": 59551,
    "Allotted Quota": "All India",
    "Allotted Institute": "Late Baliram Kashyap Memorial Govt Medical Dimrapal,Late Baliram Kashyap Memorial Govt Medical Dimrapal, Chhattisgarh, 494001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07720"
    },
    "Rank": 59560,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (FAMILY MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07721"
    },
    "Rank": 59571,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07722"
    },
    "Rank": 59600,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07723"
    },
    "Rank": 59601,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07724"
    },
    "Rank": 59616,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07725"
    },
    "Rank": 59619,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07726"
    },
    "Rank": 59637,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07727"
    },
    "Rank": 59642,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07728"
    },
    "Rank": 59643,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07729"
    },
    "Rank": 59644,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute of Medical Sciences Srikakulam,Government Medical College Srikakulam\nBalaga Srikakulam Town Srikakulam District, Andhra Pradesh, 532001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0772a"
    },
    "Rank": 59672,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0772b"
    },
    "Rank": 59682,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Popular Medicare,POPULAR MEDICARE LTD N 10 60 A 2 BLW ROAD KAKARAMATTA VARANASI, Uttar Pradesh, 221106",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0772c"
    },
    "Rank": 59688,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0772d"
    },
    "Rank": 59691,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PALLIATIVE MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0772e"
    },
    "Rank": 59701,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Multispeciality Hospital , West Bengal,Andul road, Podrah Chunavati Howrah, West Bengal, 711109",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0772f"
    },
    "Rank": 59718,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07730"
    },
    "Rank": 59734,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07731"
    },
    "Rank": 59768,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dholpur,Old City Road Dholpur, Rajasthan, 328001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07732"
    },
    "Rank": 59782,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Hospital, ,principal.mc.barmer@rajasthan.gov.in, Rajasthan, 344001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07733"
    },
    "Rank": 59791,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07734"
    },
    "Rank": 59796,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07735"
    },
    "Rank": 59807,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Central Hospital, Gandhinagar, ,CENTRAL HOSPITAL, GANDHINAGAR, GANDHINAGAR COLONY,\nKANKE ROAD,\nRANCHI, Jharkhand, 834008",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07736"
    },
    "Rank": 59816,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07737"
    },
    "Rank": 59846,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07738"
    },
    "Rank": 59848,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07739"
    },
    "Rank": 59850,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0773a"
    },
    "Rank": 59862,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jangipur S. D. Hospital, West Bengal,Lalgola Maharaja Road PO and PS Raghunathganj\nMurshidabad, West Bengal, 742225",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0773b"
    },
    "Rank": 59881,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0773c"
    },
    "Rank": 59893,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0773d"
    },
    "Rank": 59902,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0773e"
    },
    "Rank": 59903,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0773f"
    },
    "Rank": 59904,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07740"
    },
    "Rank": 59905,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07741"
    },
    "Rank": 59909,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07742"
    },
    "Rank": 59917,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07743"
    },
    "Rank": 59937,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07744"
    },
    "Rank": 59954,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07745"
    },
    "Rank": 59979,
    "Allotted Quota": "All India",
    "Allotted Institute": "LATE SHRI LAKHI RAM AGRAWAL MEMORIAL GOVT.MEDICAL COLLEGE,LATE SHRI LAKHI RAM AGRAWAL MEMORIAL GOVT.MEDICAL COLLEGE, Chhattisgarh, 496001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07746"
    },
    "Rank": 59982,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07747"
    },
    "Rank": 59984,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07748"
    },
    "Rank": 59992,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07749"
    },
    "Rank": 60006,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0774a"
    },
    "Rank": 60011,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0774b"
    },
    "Rank": 60024,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0774c"
    },
    "Rank": 60036,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0774d"
    },
    "Rank": 60041,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0774e"
    },
    "Rank": 60058,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College & Attached Hospital Bharatpur,GOVERNMENT MEDICAL COLLEGE BHARATPUR VILLAGE RAMPURA NH 21, Rajasthan, 321001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0774f"
    },
    "Rank": 60084,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07750"
    },
    "Rank": 60095,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07751"
    },
    "Rank": 60098,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07752"
    },
    "Rank": 60123,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07753"
    },
    "Rank": 60131,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07754"
    },
    "Rank": 60133,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07755"
    },
    "Rank": 60155,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sher-I-Kashmir Institute of Medical Sciences, Jammu and Kashmir,SKIMS SOURA SRINAGAR JAMMU & KASHMIR, Jammu And Kashmir, 190011",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07756"
    },
    "Rank": 60161,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07757"
    },
    "Rank": 60162,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07758"
    },
    "Rank": 60184,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07759"
    },
    "Rank": 60196,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0775a"
    },
    "Rank": 60219,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0775b"
    },
    "Rank": 60234,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0775c"
    },
    "Rank": 60245,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI MEDICAL COLLEGE KALWA THANE,RAJIV GANDHI MEDICAL COLLEGE,THANE BELAPUR ROAD, KALWA, THANE, Maharashtra, 400605",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0775d"
    },
    "Rank": 60272,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0775e"
    },
    "Rank": 60295,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0775f"
    },
    "Rank": 60317,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07760"
    },
    "Rank": 60319,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07761"
    },
    "Rank": 60336,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07762"
    },
    "Rank": 60341,
    "Allotted Quota": "All India",
    "Allotted Institute": "All  India Institute of  Hygiene and Public Health,110, C. R  Avenue, Kolkata, West Bengal, 700073",
    "Course": "M.P.H. (EPIDEMIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07763"
    },
    "Rank": 60343,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "FAKIR MOHAN MEDICAL COLLEGE AND HOSPITAL ,\nOrissa,AT-Remuna Post-Remuna Dist-Balasore, Odisha, 756019",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07764"
    },
    "Rank": 60352,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07765"
    },
    "Rank": 60354,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07766"
    },
    "Rank": 60379,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07767"
    },
    "Rank": 60390,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07768"
    },
    "Rank": 60391,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07769"
    },
    "Rank": 60393,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0776a"
    },
    "Rank": 60399,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Sagar, Madhya Pradesh,shivaji ward tilli road sagar, Madhya Pradesh, 470002",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0776b"
    },
    "Rank": 60409,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0776c"
    },
    "Rank": 60415,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0776d"
    },
    "Rank": 60421,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karnataka,MG Road, Karnataka, 581301",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0776e"
    },
    "Rank": 60424,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0776f"
    },
    "Rank": 60431,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07770"
    },
    "Rank": 60439,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07771"
    },
    "Rank": 60443,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07772"
    },
    "Rank": 60450,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07773"
    },
    "Rank": 60451,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07774"
    },
    "Rank": 60459,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07775"
    },
    "Rank": 60461,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital,\n,paddoc road, near majewadi gate, junagadh, Gujarat, 362001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07776"
    },
    "Rank": 60477,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07777"
    },
    "Rank": 60496,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07778"
    },
    "Rank": 60498,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07779"
    },
    "Rank": 60501,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0777a"
    },
    "Rank": 60502,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0777b"
    },
    "Rank": 60513,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0777c"
    },
    "Rank": 60536,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0777d"
    },
    "Rank": 60541,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0777e"
    },
    "Rank": 60549,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0777f"
    },
    "Rank": 60554,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07780"
    },
    "Rank": 60561,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07781"
    },
    "Rank": 60573,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07782"
    },
    "Rank": 60583,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07783"
    },
    "Rank": 60584,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07784"
    },
    "Rank": 60642,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07785"
    },
    "Rank": 60648,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07786"
    },
    "Rank": 60650,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07787"
    },
    "Rank": 60652,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07788"
    },
    "Rank": 60668,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K C General Hospital, ,No. 89, 5th Cross, Malleshwaram, Bangalore, Karnataka, 560003",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07789"
    },
    "Rank": 60676,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0778a"
    },
    "Rank": 60679,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SETH V. C. GANDHI and M. A. VORA MUNICIPAL GENERAL HOSPITAL (RAJAWADI HOSPITAL),\nMaharashtra,hospital.rajawadi@gmail.com, Maharashtra, 400077",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0778b"
    },
    "Rank": 60684,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0778c"
    },
    "Rank": 60687,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0778d"
    },
    "Rank": 60689,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College,Fakhruddin Ali Ahmed Medical College, Assam, 781301",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0778e"
    },
    "Rank": 60690,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0778f"
    },
    "Rank": 60694,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07790"
    },
    "Rank": 60709,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07791"
    },
    "Rank": 60714,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07792"
    },
    "Rank": 60733,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07793"
    },
    "Rank": 60739,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07794"
    },
    "Rank": 60751,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07795"
    },
    "Rank": 60754,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Apollomedics Superspeciality Hospital,KBC 31, Sector B, LDA Colony, Kanpur Road, Lucknow, Uttar Pradesh, 226012",
    "Course": "(NBEMS) MICROBIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07796"
    },
    "Rank": 60762,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AZAMGARH,Chakrapan Pur, Azamgarh (U.P.),\nUttar Pradesh, 276128",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07797"
    },
    "Rank": 60793,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07798"
    },
    "Rank": 60794,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07799"
    },
    "Rank": 60796,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0779a"
    },
    "Rank": 60798,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur and Associated Group of Hospitals Shri Haridev Joshi Hospital,,Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur, Rajasthan, 314001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0779b"
    },
    "Rank": 60799,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0779c"
    },
    "Rank": 60800,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0779d"
    },
    "Rank": 60803,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0779e"
    },
    "Rank": 60833,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0779f"
    },
    "Rank": 60858,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077a0"
    },
    "Rank": 60860,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077a1"
    },
    "Rank": 60871,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077a2"
    },
    "Rank": 60883,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Civil Hospital Shillong, Meghalaya,Civil Hospital Shillong Laban Shillong East Khasi Hills Meghalaya 793001, Meghalaya, 793001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077a3"
    },
    "Rank": 60884,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077a4"
    },
    "Rank": 60887,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077a5"
    },
    "Rank": 60892,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Medical College for Women, Khanpur Kalan Sonepat,BPS Govt. Medical College for Women, Khanpur Kalan Sonepat, Haryana, 131305",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077a6"
    },
    "Rank": 60895,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077a7"
    },
    "Rank": 60912,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Eastern Indira Gandhi Regional Institute of Health & Medical Sciences, Shillong,North Eastern Indira Gandhi Regional Institute of Health & Medical Sciences, Shillong, Meghalaya, 793018",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077a8"
    },
    "Rank": 60915,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077a9"
    },
    "Rank": 60924,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077aa"
    },
    "Rank": 60927,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077ab"
    },
    "Rank": 60943,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,OLD SABJI MANDI HINDUAN GATE KARAULI, Rajasthan, 322241",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077ac"
    },
    "Rank": 60945,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077ad"
    },
    "Rank": 60947,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Hospital,,JANGLAT MANDI ANANTNAG, JAMMU AND KASHMIR 192101, Jammu And Kashmir, 192101",
    "Course": "(NBEMS) COMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077ae"
    },
    "Rank": 60953,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077af"
    },
    "Rank": 60962,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077b0"
    },
    "Rank": 60970,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077b1"
    },
    "Rank": 61001,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077b2"
    },
    "Rank": 61022,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077b3"
    },
    "Rank": 61036,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077b4"
    },
    "Rank": 61049,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077b5"
    },
    "Rank": 61078,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077b6"
    },
    "Rank": 61092,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077b7"
    },
    "Rank": 61095,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077b8"
    },
    "Rank": 61111,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077b9"
    },
    "Rank": 61126,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "IGM Hospital , ,IGM Chowmuhani, Agartala, Tripura West, Tripura, 799001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077ba"
    },
    "Rank": 61132,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077bb"
    },
    "Rank": 61134,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077bc"
    },
    "Rank": 61136,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077bd"
    },
    "Rank": 61144,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077be"
    },
    "Rank": 61148,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077bf"
    },
    "Rank": 61149,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077c0"
    },
    "Rank": 61152,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077c1"
    },
    "Rank": 61153,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077c2"
    },
    "Rank": 61155,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077c3"
    },
    "Rank": 61156,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077c4"
    },
    "Rank": 61159,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077c5"
    },
    "Rank": 61185,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077c6"
    },
    "Rank": 61187,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077c7"
    },
    "Rank": 61191,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Government Medical College, Vidisha,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA MP, Madhya Pradesh, 464001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077c8"
    },
    "Rank": 61201,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077c9"
    },
    "Rank": 61204,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,Opposite to Shivajinagar Bus stop, Shivajinagar, Bengaluru., Karnataka, 560001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077ca"
    },
    "Rank": 61207,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077cb"
    },
    "Rank": 61208,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077cc"
    },
    "Rank": 61219,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Popular Medicare,POPULAR MEDICARE LTD N 10 60 A 2 BLW ROAD KAKARAMATTA VARANASI, Uttar Pradesh, 221106",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077cd"
    },
    "Rank": 61235,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077ce"
    },
    "Rank": 61238,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,HBT Medical College and Dr R N Cooper Municipal General Hospital, Bhaktivedanta Swami Rd, JVPD Schem, Maharashtra, 400056",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077cf"
    },
    "Rank": 61252,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Popular Medicare,POPULAR MEDICARE LTD N 10 60 A 2 BLW ROAD KAKARAMATTA VARANASI, Uttar Pradesh, 221106",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077d0"
    },
    "Rank": 61263,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077d1"
    },
    "Rank": 61276,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077d2"
    },
    "Rank": 61280,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077d3"
    },
    "Rank": 61304,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077d4"
    },
    "Rank": 61305,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "DIPLOMA IN OTO- RHINO-LARYNGOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077d5"
    },
    "Rank": 61306,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESI Hospital, ,IG ESI HOSPITAL JHILMIL DELHI - 95, Delhi (NCT), 110095",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077d6"
    },
    "Rank": 61310,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077d7"
    },
    "Rank": 61329,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077d8"
    },
    "Rank": 61332,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077d9"
    },
    "Rank": 61338,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt Seth Govind Das Victoria District Hospital, Madhya Pradesh,Govt Seth Govind Das Victoria District Hospital Jabalpur, Madhya Pradesh, 482002",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077da"
    },
    "Rank": 61346,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077db"
    },
    "Rank": 61347,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077dc"
    },
    "Rank": 61372,
    "Allotted Quota": "All India",
    "Allotted Institute": "STANLEY MEDICAL COLLEGE,STANLEY MEDICAL COLLEGE NO.1, OLD JAIL ROAD,\nCHENNAI-600001, Tamil Nadu, 600001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077dd"
    },
    "Rank": 61376,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077de"
    },
    "Rank": 61381,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077df"
    },
    "Rank": 61382,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077e0"
    },
    "Rank": 61385,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077e1"
    },
    "Rank": 61388,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077e2"
    },
    "Rank": 61393,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077e3"
    },
    "Rank": 61396,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077e4"
    },
    "Rank": 61401,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077e5"
    },
    "Rank": 61402,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077e6"
    },
    "Rank": 61405,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077e7"
    },
    "Rank": 61422,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077e8"
    },
    "Rank": 61431,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077e9"
    },
    "Rank": 61441,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077ea"
    },
    "Rank": 61451,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077eb"
    },
    "Rank": 61461,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Associated Hospital Government Medical College, ,Government Medical College Chack Sajjan Kathua, Jammu And Kashmir, 184101",
    "Course": "(NBEMS) PHYSIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077ec"
    },
    "Rank": 61476,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077ed"
    },
    "Rank": 61486,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077ee"
    },
    "Rank": 61499,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077ef"
    },
    "Rank": 61502,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077f0"
    },
    "Rank": 61503,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077f1"
    },
    "Rank": 61515,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077f2"
    },
    "Rank": 61522,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077f3"
    },
    "Rank": 61525,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077f4"
    },
    "Rank": 61529,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077f5"
    },
    "Rank": 61533,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077f6"
    },
    "Rank": 61537,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077f7"
    },
    "Rank": 61539,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College,Fakhruddin Ali Ahmed Medical College, Assam, 781301",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077f8"
    },
    "Rank": 61547,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077f9"
    },
    "Rank": 61552,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077fa"
    },
    "Rank": 61554,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077fb"
    },
    "Rank": 61571,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077fc"
    },
    "Rank": 61573,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077fd"
    },
    "Rank": 61581,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077fe"
    },
    "Rank": 61582,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a077ff"
    },
    "Rank": 61586,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07800"
    },
    "Rank": 61599,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07801"
    },
    "Rank": 61604,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07802"
    },
    "Rank": 61605,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07803"
    },
    "Rank": 61607,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07804"
    },
    "Rank": 61611,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07805"
    },
    "Rank": 61612,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07806"
    },
    "Rank": 61642,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07807"
    },
    "Rank": 61643,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07808"
    },
    "Rank": 61653,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07809"
    },
    "Rank": 61659,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0780a"
    },
    "Rank": 61686,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0780b"
    },
    "Rank": 61699,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine & Sagore Dutta Hospital,College of Medicine & Sagore Dutta Hospital, West Bengal, 700058",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0780c"
    },
    "Rank": 61710,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Goverment District Hospital Bundi, ,IN FRONT OF COLLECTORATE BUNDI, Rajasthan, 323001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0780d"
    },
    "Rank": 61720,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0780e"
    },
    "Rank": 61742,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0780f"
    },
    "Rank": 61770,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07810"
    },
    "Rank": 61772,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07811"
    },
    "Rank": 61798,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Bundelkhand Medical College Sagar M.P.,Govt. Bundelkhand Medical College Sagar M.P., Madhya Pradesh, 470001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07812"
    },
    "Rank": 61841,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Narayana Multispeciality Hospital , West Bengal,Andul road, Podrah Chunavati Howrah, West Bengal, 711109",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07813"
    },
    "Rank": 61846,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07814"
    },
    "Rank": 61858,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Civil Hospital,\n,JAGADHARI ROAD, CIVIL HOSPITAL , AMBALA CANTT, Haryana, 133001",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07815"
    },
    "Rank": 61906,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur and Associated Group of Hospitals Shri Haridev Joshi Hospital,,Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur, Rajasthan, 314001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07816"
    },
    "Rank": 61907,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07817"
    },
    "Rank": 61930,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07818"
    },
    "Rank": 61962,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07819"
    },
    "Rank": 61995,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,OLD SABJI MANDI HINDUAN GATE KARAULI, Rajasthan, 322241",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0781a"
    },
    "Rank": 62010,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0781b"
    },
    "Rank": 62016,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0781c"
    },
    "Rank": 62028,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0781d"
    },
    "Rank": 62043,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0781e"
    },
    "Rank": 62046,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0781f"
    },
    "Rank": 62060,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07820"
    },
    "Rank": 62066,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07821"
    },
    "Rank": 62068,
    "Allotted Quota": "All India",
    "Allotted Institute": "NSC Government Medical College, Khandwa (MP),LUV KUSH NAGAR,MUNDI NAGAR, KHANDWA, Madhya Pradesh, 450001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07822"
    },
    "Rank": 62082,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07823"
    },
    "Rank": 62086,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07824"
    },
    "Rank": 62094,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07825"
    },
    "Rank": 62099,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07826"
    },
    "Rank": 62101,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,Opposite to Shivajinagar Bus stop, Shivajinagar, Bengaluru., Karnataka, 560001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07827"
    },
    "Rank": 62104,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07828"
    },
    "Rank": 62105,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07829"
    },
    "Rank": 62107,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0782a"
    },
    "Rank": 62131,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Bangur Hospital, Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan , Rajasthan-306401,Government Medical College and Bangur Hospital Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan Ra, Rajasthan, 306401",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0782b"
    },
    "Rank": 62145,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0782c"
    },
    "Rank": 62148,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0782d"
    },
    "Rank": 62158,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0782e"
    },
    "Rank": 62163,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0782f"
    },
    "Rank": 62164,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07830"
    },
    "Rank": 62167,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07831"
    },
    "Rank": 62190,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07832"
    },
    "Rank": 62191,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur and Associated Group of Hospitals Shri Haridev Joshi Hospital,,Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur, Rajasthan, 314001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07833"
    },
    "Rank": 62194,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07834"
    },
    "Rank": 62214,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07835"
    },
    "Rank": 62222,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07836"
    },
    "Rank": 62230,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Baramati,Plot No P-107 Opposite Women Hospital Baramati MIDC Area Baramati Tehsil Baramati Dist Pune, Maharashtra, 413133",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07837"
    },
    "Rank": 62242,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07838"
    },
    "Rank": 62267,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute Of Medical Sciences, Kalaburagi,VEERESH NAGAR SEDAM ROAD KALABURAGI, Karnataka, 585105",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07839"
    },
    "Rank": 62271,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "B.R. Singh Hospital and Centre for Medical Education and Research,,B.R.Singh Hospital Eastern Railway, Sealdah, Kolkata, West Bengal, 700014",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0783a"
    },
    "Rank": 62279,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0783b"
    },
    "Rank": 62281,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0783c"
    },
    "Rank": 62299,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0783d"
    },
    "Rank": 62316,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0783e"
    },
    "Rank": 62320,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0783f"
    },
    "Rank": 62321,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07840"
    },
    "Rank": 62332,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital, ,Kharvad medan Sipor road Vadnagar, Gujarat, 384355",
    "Course": "(NBEMS) MICROBIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07841"
    },
    "Rank": 62339,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07842"
    },
    "Rank": 62340,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07843"
    },
    "Rank": 62343,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07844"
    },
    "Rank": 62352,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07845"
    },
    "Rank": 62378,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07846"
    },
    "Rank": 62403,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07847"
    },
    "Rank": 62404,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07848"
    },
    "Rank": 62408,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI MEDICAL COLLEGE KALWA THANE,RAJIV GANDHI MEDICAL COLLEGE,THANE BELAPUR ROAD, KALWA, THANE, Maharashtra, 400605",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07849"
    },
    "Rank": 62418,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences, ,academics@kimshospitals.com, Telangana, 500003",
    "Course": "(NBEMS) MICROBIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0784a"
    },
    "Rank": 62425,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Hospital,,JANGLAT MANDI ANANTNAG, JAMMU AND KASHMIR 192101, Jammu And Kashmir, 192101",
    "Course": "(NBEMS) COMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0784b"
    },
    "Rank": 62440,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0784c"
    },
    "Rank": 62447,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata Shrimati Devendra Kumari Singhdeo Government Medical College, Ambikapur,Kanyaparisar  Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0784d"
    },
    "Rank": 62449,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0784e"
    },
    "Rank": 62461,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0784f"
    },
    "Rank": 62469,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07850"
    },
    "Rank": 62480,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07851"
    },
    "Rank": 62486,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07852"
    },
    "Rank": 62500,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07853"
    },
    "Rank": 62513,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07854"
    },
    "Rank": 62520,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07855"
    },
    "Rank": 62537,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07856"
    },
    "Rank": 62543,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07857"
    },
    "Rank": 62546,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07858"
    },
    "Rank": 62551,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07859"
    },
    "Rank": 62574,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0785a"
    },
    "Rank": 62588,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0785b"
    },
    "Rank": 62593,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0785c"
    },
    "Rank": 62597,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0785d"
    },
    "Rank": 62612,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0785e"
    },
    "Rank": 62633,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0785f"
    },
    "Rank": 62635,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07860"
    },
    "Rank": 62657,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine and JNM Hospital, WBUHS,Block A, Post Office Kalyani, Near Silpanchal Station,\nBlock A2, District Nadia, West Bengal, West Bengal, 741235",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07861"
    },
    "Rank": 62693,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07862"
    },
    "Rank": 62694,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07863"
    },
    "Rank": 62699,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07864"
    },
    "Rank": 62701,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07865"
    },
    "Rank": 62711,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07866"
    },
    "Rank": 62721,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07867"
    },
    "Rank": 62722,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sumitra Hospital,\nUttar Pradesh,A-119A SECTOR-35 NOIDA, Uttar Pradesh, 201301",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07868"
    },
    "Rank": 62723,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07869"
    },
    "Rank": 62738,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0786a"
    },
    "Rank": 62754,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0786b"
    },
    "Rank": 62757,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0786c"
    },
    "Rank": 62785,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "IGM Hospital , ,IGM Chowmuhani, Agartala, Tripura West, Tripura, 799001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0786d"
    },
    "Rank": 62790,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District hospital chitradurga, B D road beside DHO office Chitradurga, Karnataka, 577501",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0786e"
    },
    "Rank": 62798,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0786f"
    },
    "Rank": 62804,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pandit Raghunath Murmu Medical College and Hospital , Orissa,AT-RANGAMATIA, PO- SHAMAKHUNTA, PS-BARIPADA SADAR, BARIPADA, MAYURBHANJ, Odisha, 757107",
    "Course": "(NBEMS-DIPLOMA) RADIO-DIAGNOSIS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07870"
    },
    "Rank": 62808,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07871"
    },
    "Rank": 62824,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07872"
    },
    "Rank": 62842,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07873"
    },
    "Rank": 62890,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Medical College for Women, Khanpur Kalan Sonepat,BPS Govt. Medical College for Women, Khanpur Kalan Sonepat, Haryana, 131305",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07874"
    },
    "Rank": 62906,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07875"
    },
    "Rank": 62907,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07876"
    },
    "Rank": 62915,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07877"
    },
    "Rank": 62922,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "MGM Govt District Hospital, ,MGM Govt District Hospital,Hanumangarh town, Rajasthan, 335513",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07878"
    },
    "Rank": 62923,
    "Allotted Quota": "All India",
    "Allotted Institute": "SJP Medical College, Bharatpur,SJP MEDICAL COLLEGE BHARATPUR VILLAGE RAMPURA NH 21, Rajasthan, 321001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07879"
    },
    "Rank": 62938,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0787a"
    },
    "Rank": 62945,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0787b"
    },
    "Rank": 62981,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0787c"
    },
    "Rank": 62991,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0787d"
    },
    "Rank": 63008,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0787e"
    },
    "Rank": 63013,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0787f"
    },
    "Rank": 63027,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07880"
    },
    "Rank": 63035,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07881"
    },
    "Rank": 63038,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07882"
    },
    "Rank": 63039,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07883"
    },
    "Rank": 63059,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07884"
    },
    "Rank": 63077,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07885"
    },
    "Rank": 63122,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07886"
    },
    "Rank": 63126,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chittaranjan National Cancer Institute, 37- ,37, S P Mukherjee Road, Kolkata-700026- 1st campus, 299 DJ Block A.A.1, Newtown Kolkata- 700156-2nd, West Bengal, 700026",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07887"
    },
    "Rank": 63193,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Diamond Harbour Government Medical College And Hospital, ,Harindanga, New Town, Word No- 4, P.O and P.S- Diamond Harbour, Dist- South 24 Parganas, West Bengal, West Bengal, 743331",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07888"
    },
    "Rank": 63227,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07889"
    },
    "Rank": 63245,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0788a"
    },
    "Rank": 63253,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sher-I-Kashmir Institute of Medical Sciences, ,Principal SKIMS Medical College Hospital Bemina Srinagar, Jammu And Kashmir, 190018",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0788b"
    },
    "Rank": 63254,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI,ESI-POST GRADUATE INSTITUTE OF MEDICAL SCIENCES  AND RESEARCH, BASAIDARAPUR, NEW DELHI, Delhi (NCT), 110015",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0788c"
    },
    "Rank": 63259,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "South Eastern Railways Central Hospital, ,CENTRAL HOSPITAL SOUTH EASTERN RAILWAY\n11 GARDEN REACH ROAD\nKOLKATA, West Bengal, 700043",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0788d"
    },
    "Rank": 63276,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0788e"
    },
    "Rank": 63285,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0788f"
    },
    "Rank": 63307,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07890"
    },
    "Rank": 63308,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07891"
    },
    "Rank": 63315,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07892"
    },
    "Rank": 63319,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07893"
    },
    "Rank": 63336,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,DISTRICT HOSPITAL NEAR SANGAM CIRCLE DR RAJ KUMAR ROAD BALLARI-583101 KARNATAKA, Karnataka, 583101",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07894"
    },
    "Rank": 63337,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07895"
    },
    "Rank": 63340,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07896"
    },
    "Rank": 63344,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur and Associated Group of Hospitals Shri Haridev Joshi Hospital,,Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur, Rajasthan, 314001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07897"
    },
    "Rank": 63350,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07898"
    },
    "Rank": 63370,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07899"
    },
    "Rank": 63371,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0789a"
    },
    "Rank": 63382,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Bangur Hospital, Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan , Rajasthan-306401,Government Medical College and Bangur Hospital Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan Ra, Rajasthan, 306401",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0789b"
    },
    "Rank": 63392,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0789c"
    },
    "Rank": 63395,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0789d"
    },
    "Rank": 63397,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0789e"
    },
    "Rank": 63401,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0789f"
    },
    "Rank": 63407,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078a0"
    },
    "Rank": 63409,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078a1"
    },
    "Rank": 63410,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078a2"
    },
    "Rank": 63412,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078a3"
    },
    "Rank": 63416,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078a4"
    },
    "Rank": 63424,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078a5"
    },
    "Rank": 63425,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078a6"
    },
    "Rank": 63455,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078a7"
    },
    "Rank": 63481,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078a8"
    },
    "Rank": 63492,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078a9"
    },
    "Rank": 63495,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (PALLIATIVE MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078aa"
    },
    "Rank": 63502,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute Of Medical Sciences, Kalaburagi,VEERESH NAGAR SEDAM ROAD KALABURAGI, Karnataka, 585105",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078ab"
    },
    "Rank": 63503,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078ac"
    },
    "Rank": 63522,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078ad"
    },
    "Rank": 63551,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan , Rajasthan-311001,Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan, Rajasthan, 311001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078ae"
    },
    "Rank": 63561,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078af"
    },
    "Rank": 63562,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Siddipet,SURVEY NO 54  GOVERNMENT MEDICAL COLLEGE  ENSANPALLY  SIDDIPET   502114, Telangana, 502114",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078b0"
    },
    "Rank": 63569,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078b1"
    },
    "Rank": 63589,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubnagar,Government Medical College, Yedira, Mahabubnagar, Telangana state, Telangana, 509002",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078b2"
    },
    "Rank": 63611,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078b3"
    },
    "Rank": 63616,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078b4"
    },
    "Rank": 63623,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078b5"
    },
    "Rank": 63624,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078b6"
    },
    "Rank": 63640,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078b7"
    },
    "Rank": 63663,
    "Allotted Quota": "All India",
    "Allotted Institute": "Murshidabad Medical College and Hospital,Station Road, Berhampore, Murshidabad, West Bengal, 742101",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078b8"
    },
    "Rank": 63670,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078b9"
    },
    "Rank": 63691,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078ba"
    },
    "Rank": 63697,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078bb"
    },
    "Rank": 63699,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078bc"
    },
    "Rank": 63723,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078bd"
    },
    "Rank": 63751,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078be"
    },
    "Rank": 63753,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078bf"
    },
    "Rank": 63756,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078c0"
    },
    "Rank": 63763,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078c1"
    },
    "Rank": 63770,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078c2"
    },
    "Rank": 63773,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078c3"
    },
    "Rank": 63776,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078c4"
    },
    "Rank": 63777,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078c5"
    },
    "Rank": 63778,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Lal Bahadur Shastri Hosptial, ,LBSH MAYUR VIHAR PHASE 2 KHICHRIPUR 110091, Delhi (NCT), 110091",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078c6"
    },
    "Rank": 63788,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital, Near KEB, PB Road, Haveri, Karnataka, 581110",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078c7"
    },
    "Rank": 63795,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078c8"
    },
    "Rank": 63818,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078c9"
    },
    "Rank": 63819,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078ca"
    },
    "Rank": 63820,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078cb"
    },
    "Rank": 63823,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078cc"
    },
    "Rank": 63824,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078cd"
    },
    "Rank": 63830,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078ce"
    },
    "Rank": 63835,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Jamshedpur,Mahatma Gandhi Memorial Medical College, Jamshedpur, Jharkhand, 831020",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078cf"
    },
    "Rank": 63849,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078d0"
    },
    "Rank": 63868,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078d1"
    },
    "Rank": 63870,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078d2"
    },
    "Rank": 63877,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078d3"
    },
    "Rank": 63926,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078d4"
    },
    "Rank": 63927,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078d5"
    },
    "Rank": 63934,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078d6"
    },
    "Rank": 63947,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078d7"
    },
    "Rank": 63952,
    "Allotted Quota": "All India",
    "Allotted Institute": "Murshidabad Medical College and Hospital,Station Road, Berhampore, Murshidabad, West Bengal, 742101",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078d8"
    },
    "Rank": 63970,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078d9"
    },
    "Rank": 63981,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078da"
    },
    "Rank": 63982,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078db"
    },
    "Rank": 64007,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chittaranjan National Cancer Institute, 37- ,37, S P Mukherjee Road, Kolkata-700026- 1st campus, 299 DJ Block A.A.1, Newtown Kolkata- 700156-2nd, West Bengal, 700026",
    "Course": "M.D.Laboratory Medicine Course",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078dc"
    },
    "Rank": 64022,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078dd"
    },
    "Rank": 64024,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078de"
    },
    "Rank": 64045,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078df"
    },
    "Rank": 64074,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078e0"
    },
    "Rank": 64088,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078e1"
    },
    "Rank": 64092,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078e2"
    },
    "Rank": 64128,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Doon Medical College, Dehradun,Govt. Doon Medical College Dehrakhas, Patel Nagar Dehradun 248001, Uttarakhand, 248001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078e3"
    },
    "Rank": 64130,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SNR District Hospital,,SNR DISTRICT HOSPITAL, KOLAR KARNATAKA-563101,\nKarnataka, 563101",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078e4"
    },
    "Rank": 64137,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078e5"
    },
    "Rank": 64147,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078e6"
    },
    "Rank": 64151,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Associated Hospital Government Medical College, ,Kanth Bagh Baramulla, Jammu And Kashmir, 193103",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078e7"
    },
    "Rank": 64156,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078e8"
    },
    "Rank": 64172,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078e9"
    },
    "Rank": 64179,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078ea"
    },
    "Rank": 64192,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital KGF, Karnataka,GENERAL HOSPITAL, ROBERTSONPET, KGF TALUK, KOLAR DISTRICT., Karnataka, 563122",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078eb"
    },
    "Rank": 64193,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078ec"
    },
    "Rank": 64202,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078ed"
    },
    "Rank": 64208,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Siddipet,SURVEY NO 54  GOVERNMENT MEDICAL COLLEGE  ENSANPALLY  SIDDIPET   502114, Telangana, 502114",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078ee"
    },
    "Rank": 64220,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078ef"
    },
    "Rank": 64225,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078f0"
    },
    "Rank": 64249,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078f1"
    },
    "Rank": 64261,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078f2"
    },
    "Rank": 64276,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078f3"
    },
    "Rank": 64283,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078f4"
    },
    "Rank": 64297,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. Sports Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078f5"
    },
    "Rank": 64299,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078f6"
    },
    "Rank": 64304,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078f7"
    },
    "Rank": 64309,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078f8"
    },
    "Rank": 64345,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078f9"
    },
    "Rank": 64348,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078fa"
    },
    "Rank": 64371,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078fb"
    },
    "Rank": 64376,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078fc"
    },
    "Rank": 64377,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078fd"
    },
    "Rank": 64407,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078fe"
    },
    "Rank": 64409,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a078ff"
    },
    "Rank": 64418,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07900"
    },
    "Rank": 64429,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07901"
    },
    "Rank": 64441,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Suri Sadar Hospital, ,Laldighipara Suri Birbhum, West Bengal, 731101",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07902"
    },
    "Rank": 64446,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07903"
    },
    "Rank": 64448,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. GERIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07904"
    },
    "Rank": 64450,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07905"
    },
    "Rank": 64458,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhagat Phool Singh Govt. Medical College for Women,BHAGAT PHOOL SINGH GOVT. MEDICAL COLLEGE FOR WOMEN KHANPUR KALAN SONEPAT, Haryana, 131305",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07906"
    },
    "Rank": 64464,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07907"
    },
    "Rank": 64472,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07908"
    },
    "Rank": 64516,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07909"
    },
    "Rank": 64537,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF HUMAN  BEHAVIOUR AND ALLIED SCIENCES,Institute of Human\nBehaviour and Allied Sciences,  Dilshad Garden, New Delhi, Delhi 110095, India, Delhi (NCT), 110095",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0790a"
    },
    "Rank": 64539,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0790b"
    },
    "Rank": 64541,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Hospital, Manjeri,Government Medical College, Manjeri, Malappuram, Kerala, Kerala, 676121",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0790c"
    },
    "Rank": 64557,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0790d"
    },
    "Rank": 64576,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0790e"
    },
    "Rank": 64585,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0790f"
    },
    "Rank": 64587,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07910"
    },
    "Rank": 64628,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07911"
    },
    "Rank": 64635,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07912"
    },
    "Rank": 64668,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07913"
    },
    "Rank": 64687,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07914"
    },
    "Rank": 64699,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07915"
    },
    "Rank": 64700,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government Women and Children Hospital, (Formerly Indira Gandhi.\nGeneral Hospital) ,100 feet road, Ellaipillaichavady, Puducherry, 605005",
    "Course": "(NBEMS) Obstetrics and Gynaecology",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07916"
    },
    "Rank": 64708,
    "Allotted Quota": "All India",
    "Allotted Institute": "Calcutta School Of Tropical Medicine,Calcutta School Of Tropical Medicine, West Bengal, 700073",
    "Course": "DIPLOMA IN CLINICAL PATHOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07917"
    },
    "Rank": 64725,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07918"
    },
    "Rank": 64726,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07919"
    },
    "Rank": 64727,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0791a"
    },
    "Rank": 64734,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0791b"
    },
    "Rank": 64735,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0791c"
    },
    "Rank": 64736,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0791d"
    },
    "Rank": 64737,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0791e"
    },
    "Rank": 64753,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0791f"
    },
    "Rank": 64754,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07920"
    },
    "Rank": 64762,
    "Allotted Quota": "All India",
    "Allotted Institute": "VARDHMAN INSTITUTE OF MEDICAL SCIENCES, NALANDA,BMIMS CAMPUS, PAWAPURI, NALANDA, Bihar, 803115",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07921"
    },
    "Rank": 64803,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS COLLEGE OF MEDICAL SCIENCES,RUHS COLLEGE OF MEDICAL\nSCIENCES, Rajasthan, 302033",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07922"
    },
    "Rank": 64813,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07923"
    },
    "Rank": 64814,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07924"
    },
    "Rank": 64848,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07925"
    },
    "Rank": 64862,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07926"
    },
    "Rank": 64888,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaheed Hasan Khan Mewati Government Medical College,Shaheed Hasan Khan Mewati Government Medical College, Haryana, 122107",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07927"
    },
    "Rank": 64895,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07928"
    },
    "Rank": 64900,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07929"
    },
    "Rank": 64907,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0792a"
    },
    "Rank": 64916,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0792b"
    },
    "Rank": 64917,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0792c"
    },
    "Rank": 64930,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0792d"
    },
    "Rank": 64947,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0792e"
    },
    "Rank": 64954,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0792f"
    },
    "Rank": 64973,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07930"
    },
    "Rank": 64974,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07931"
    },
    "Rank": 64977,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07932"
    },
    "Rank": 65005,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Marwari Hospitals, Assam,Sati Jaymati Road, Athgoan, Guwahati, Assam, 781008",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07933"
    },
    "Rank": 65007,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07934"
    },
    "Rank": 65014,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07935"
    },
    "Rank": 65018,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SDH KUPWARA, Jammu and Kashmir,IQBAL MARKET KUPWARA, Jammu And Kashmir, 193222",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07936"
    },
    "Rank": 65027,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College,Fakhruddin Ali Ahmed Medical College, Assam, 781301",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07937"
    },
    "Rank": 65072,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07938"
    },
    "Rank": 65083,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College and  PGIMSR,ESIC Medical College and  PGIMSR, KK Nagar, Chennai - 600 078, Tamil Nadu, 600078",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07939"
    },
    "Rank": 65105,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0793a"
    },
    "Rank": 65116,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0793b"
    },
    "Rank": 65140,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0793c"
    },
    "Rank": 65150,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0793d"
    },
    "Rank": 65160,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0793e"
    },
    "Rank": 65170,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0793f"
    },
    "Rank": 65202,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07940"
    },
    "Rank": 65204,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07941"
    },
    "Rank": 65211,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government General Hospital, Principal Medical Officer General hospital,\n,Rajiv Gandhi Government General Hospital, near bijli ghar circle, ALWAR, Rajasthan, 301001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07942"
    },
    "Rank": 65215,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07943"
    },
    "Rank": 65260,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07944"
    },
    "Rank": 65278,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07945"
    },
    "Rank": 65287,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sub District Hospital Sopore, Jammu and Kashmir\n,Sir Syed Colony Kupwara Road Sopore-193201,\nDistrict Barmulla ,Jammu and Kashmir, Jammu And Kashmir, 193201",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07946"
    },
    "Rank": 65304,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07947"
    },
    "Rank": 65317,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07948"
    },
    "Rank": 65330,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07949"
    },
    "Rank": 65338,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0794a"
    },
    "Rank": 65373,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0794b"
    },
    "Rank": 65384,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0794c"
    },
    "Rank": 65402,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kidwai Memorial Institute of Oncolgy,Kidwai Memorial Institute of Oncolgy, Karnataka, 560029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0794d"
    },
    "Rank": 65410,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0794e"
    },
    "Rank": 65414,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0794f"
    },
    "Rank": 65420,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07950"
    },
    "Rank": 65427,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Taluk General Hospital,\n,Taluk General Hospital\nKadur Nh 206 Kadur , Chikmagalur District Karnataka 577548, Karnataka, 577548",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07951"
    },
    "Rank": 65467,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital , ,shahid Rajmal Meena Govt. Dis. Hospital Mandi Road Baran, Rajasthan, 325205",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07952"
    },
    "Rank": 65479,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07953"
    },
    "Rank": 65500,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07954"
    },
    "Rank": 65504,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07955"
    },
    "Rank": 65520,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07956"
    },
    "Rank": 65539,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Marwari Hospitals, Assam,Sati Jaymati Road, Athgoan, Guwahati, Assam, 781008",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07957"
    },
    "Rank": 65540,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07958"
    },
    "Rank": 65551,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07959"
    },
    "Rank": 65565,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Goverment District Hospital Bundi, ,IN FRONT OF COLLECTORATE BUNDI, Rajasthan, 323001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0795a"
    },
    "Rank": 65590,
    "Allotted Quota": "All India",
    "Allotted Institute": "GADAG INSTITUTE OF MEDICAL SCIENCES GADAG,Gadag Institute of Medical Sciences Mallasamudra Gadag-582103, Karnataka, 582103",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0795b"
    },
    "Rank": 65594,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0795c"
    },
    "Rank": 65599,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0795d"
    },
    "Rank": 65607,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0795e"
    },
    "Rank": 65624,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0795f"
    },
    "Rank": 65626,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07960"
    },
    "Rank": 65630,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07961"
    },
    "Rank": 65649,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07962"
    },
    "Rank": 65662,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07963"
    },
    "Rank": 65710,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07964"
    },
    "Rank": 65713,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07965"
    },
    "Rank": 65717,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07966"
    },
    "Rank": 65762,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Krishna Medical College and Hospital, ,Uma Nagar Muzaffarpur Bihar 842004, Bihar, 842004",
    "Course": "(NBEMS) FORENSIC MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07967"
    },
    "Rank": 65783,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07968"
    },
    "Rank": 65794,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Kilpauk Medical College,Government Kilpauk Medical College Poonthamalli main roadl, Kilpauk, Chennai 10, Tamil Nadu, 600010",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07969"
    },
    "Rank": 65802,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0796a"
    },
    "Rank": 65813,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0796b"
    },
    "Rank": 65814,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0796c"
    },
    "Rank": 65825,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0796d"
    },
    "Rank": 65839,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0796e"
    },
    "Rank": 65846,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur and Associated Group of Hospitals Shri Haridev Joshi Hospital,,Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur, Rajasthan, 314001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0796f"
    },
    "Rank": 65863,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaikh-Ul-Hind Maulana Mahmood Hasan Medical College, Saharanpur,Pilkhani Ambala Road, Uttar Pradesh, 247232",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07970"
    },
    "Rank": 65865,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07971"
    },
    "Rank": 65890,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07972"
    },
    "Rank": 65891,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government General Hospital, Principal Medical Officer General hospital,\n,Rajiv Gandhi Government General Hospital, near bijli ghar circle, ALWAR, Rajasthan, 301001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07973"
    },
    "Rank": 65894,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07974"
    },
    "Rank": 65906,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07975"
    },
    "Rank": 65927,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07976"
    },
    "Rank": 65934,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07977"
    },
    "Rank": 65941,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07978"
    },
    "Rank": 65944,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07979"
    },
    "Rank": 65971,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0797a"
    },
    "Rank": 65989,
    "Allotted Quota": "All India",
    "Allotted Institute": "Murshidabad Medical College and Hospital,Station Road, Berhampore, Murshidabad, West Bengal, 742101",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0797b"
    },
    "Rank": 66012,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0797c"
    },
    "Rank": 66014,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0797d"
    },
    "Rank": 66015,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0797e"
    },
    "Rank": 66017,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0797f"
    },
    "Rank": 66030,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07980"
    },
    "Rank": 66032,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07981"
    },
    "Rank": 66038,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07982"
    },
    "Rank": 66043,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07983"
    },
    "Rank": 66046,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI MEDICAL COLLEGE KALWA THANE,RAJIV GANDHI MEDICAL COLLEGE,THANE BELAPUR ROAD, KALWA, THANE, Maharashtra, 400605",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07984"
    },
    "Rank": 66079,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07985"
    },
    "Rank": 66083,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "ST PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07986"
    },
    "Rank": 66090,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07987"
    },
    "Rank": 66111,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "VCSG Govt Institute of Medical Science and Research and HNB Base Teaching Hospital\n, ,Srikot Ganganali Srinagar Garhwal Uttarakhand, Uttarakhand, 246174",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07988"
    },
    "Rank": 66123,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07989"
    },
    "Rank": 66138,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital , Jammu and Kashmir\n,Court road pulwama, Jammu And Kashmir, 192301",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0798a"
    },
    "Rank": 66143,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0798b"
    },
    "Rank": 66152,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dholpur,Old City Road Dholpur, Rajasthan, 328001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0798c"
    },
    "Rank": 66154,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0798d"
    },
    "Rank": 66161,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0798e"
    },
    "Rank": 66195,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0798f"
    },
    "Rank": 66196,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Esic Hospital , Uttar Pradesh,ESIC Hospital, Pandeypur, Varanasi, Uttar Pradesh, 221002",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07990"
    },
    "Rank": 66198,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07991"
    },
    "Rank": 66210,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN,HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN, Karnataka, 573201",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07992"
    },
    "Rank": 66213,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,HBT Medical College and Dr R N Cooper Municipal General Hospital, Bhaktivedanta Swami Rd, JVPD Schem, Maharashtra, 400056",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07993"
    },
    "Rank": 66223,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur and Associated Group of Hospitals Shri Haridev Joshi Hospital,,Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur, Rajasthan, 314001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07994"
    },
    "Rank": 66310,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07995"
    },
    "Rank": 66315,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07996"
    },
    "Rank": 66316,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07997"
    },
    "Rank": 66332,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07998"
    },
    "Rank": 66339,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07999"
    },
    "Rank": 66340,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0799a"
    },
    "Rank": 66370,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Allopathic Medical College, Uttar Pradesh,RANI DURGAVATI MEDICAL COLLEGE NARAINI ROAD BANDA, Uttar Pradesh, 210001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0799b"
    },
    "Rank": 66373,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0799c"
    },
    "Rank": 66385,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0799d"
    },
    "Rank": 66417,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0799e"
    },
    "Rank": 66418,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Siddipet,SURVEY NO 54  GOVERNMENT MEDICAL COLLEGE  ENSANPALLY  SIDDIPET   502114, Telangana, 502114",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0799f"
    },
    "Rank": 66423,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079a0"
    },
    "Rank": 66433,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079a1"
    },
    "Rank": 66442,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079a2"
    },
    "Rank": 66462,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079a3"
    },
    "Rank": 66470,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079a4"
    },
    "Rank": 66493,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. GERIATRICS",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079a5"
    },
    "Rank": 66501,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079a6"
    },
    "Rank": 66502,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079a7"
    },
    "Rank": 66518,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079a8"
    },
    "Rank": 66519,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079a9"
    },
    "Rank": 66528,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079aa"
    },
    "Rank": 66533,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES,CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, Chhattisgarh, 495001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079ab"
    },
    "Rank": 66541,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079ac"
    },
    "Rank": 66544,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079ad"
    },
    "Rank": 66560,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079ae"
    },
    "Rank": 66570,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079af"
    },
    "Rank": 66628,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079b0"
    },
    "Rank": 66631,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079b1"
    },
    "Rank": 66645,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079b2"
    },
    "Rank": 66652,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079b3"
    },
    "Rank": 66666,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079b4"
    },
    "Rank": 66685,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079b5"
    },
    "Rank": 66698,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "INSTITUTE OF HUMAN  BEHAVIOUR AND ALLIED SCIENCES,Institute of Human\nBehaviour and Allied Sciences,  Dilshad Garden, New Delhi, Delhi 110095, India, Delhi (NCT), 110095",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079b6"
    },
    "Rank": 66713,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079b7"
    },
    "Rank": 66739,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079b8"
    },
    "Rank": 66742,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079b9"
    },
    "Rank": 66755,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079ba"
    },
    "Rank": 66756,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079bb"
    },
    "Rank": 66787,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, THOOTHUKUDI,GOVERNMENT THOOTHUKUDI MEDICAL COLLEGE, 3rd\nMile,Kamaraj Nagar,Thoothukudi., Tamil Nadu, 628008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079bc"
    },
    "Rank": 66791,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079bd"
    },
    "Rank": 66803,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079be"
    },
    "Rank": 66850,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079bf"
    },
    "Rank": 66860,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079c0"
    },
    "Rank": 66870,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079c1"
    },
    "Rank": 66898,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chamarajanagar Institute of Medical Sciences, Karnataka,CHAMARAJANAGAR INSTITUTE OF MEDICAL SCIENCES, SY. NO 124, YEDAPURA VILLAGE KASABA HOBLI, CHAMARAJANA, Karnataka, 571313",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079c2"
    },
    "Rank": 66920,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079c3"
    },
    "Rank": 66921,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079c4"
    },
    "Rank": 66933,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079c5"
    },
    "Rank": 66977,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079c6"
    },
    "Rank": 66978,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kollam,Government Medical College Kollam,Parippally, Kerala, 691574",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079c7"
    },
    "Rank": 67004,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079c8"
    },
    "Rank": 67029,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079c9"
    },
    "Rank": 67030,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079ca"
    },
    "Rank": 67031,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079cb"
    },
    "Rank": 67036,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079cc"
    },
    "Rank": 67046,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079cd"
    },
    "Rank": 67054,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079ce"
    },
    "Rank": 67062,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079cf"
    },
    "Rank": 67071,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079d0"
    },
    "Rank": 67074,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalpana Chawla Govt Medical College, Karnal, Haryana,Kalpana Chawla Government Medical College Model Town Karnal 132001 Haryana, Haryana, 132001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079d1"
    },
    "Rank": 67099,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079d2"
    },
    "Rank": 67110,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079d3"
    },
    "Rank": 67111,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079d4"
    },
    "Rank": 67113,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079d5"
    },
    "Rank": 67122,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079d6"
    },
    "Rank": 67127,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079d7"
    },
    "Rank": 67130,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079d8"
    },
    "Rank": 67131,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079d9"
    },
    "Rank": 67133,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079da"
    },
    "Rank": 67156,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079db"
    },
    "Rank": 67158,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079dc"
    },
    "Rank": 67172,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079dd"
    },
    "Rank": 67176,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Bhausaheb Hire Government Medical College,Sri Bhausaheb Hire Government Medical College,Dhule, Maharashtra, 424311",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079de"
    },
    "Rank": 67178,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079df"
    },
    "Rank": 67182,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079e0"
    },
    "Rank": 67192,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079e1"
    },
    "Rank": 67200,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079e2"
    },
    "Rank": 67209,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaheed Hasan Khan Mewati Government Medical College,Shaheed Hasan Khan Mewati Government Medical College, Haryana, 122107",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079e3"
    },
    "Rank": 67210,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,Veer Chandra Singh Garhwali Govt. Institute of Medical Science and Research, Srinagar Garhwal, Uttarakhand, 246174",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079e4"
    },
    "Rank": 67248,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079e5"
    },
    "Rank": 67261,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "DIP.IN GYNAE. and OBST.",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079e6"
    },
    "Rank": 67275,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Suri Sadar Hospital, ,Laldighipara Suri Birbhum, West Bengal, 731101",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079e7"
    },
    "Rank": 67294,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079e8"
    },
    "Rank": 67314,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079e9"
    },
    "Rank": 67321,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079ea"
    },
    "Rank": 67322,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079eb"
    },
    "Rank": 67332,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079ec"
    },
    "Rank": 67345,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079ed"
    },
    "Rank": 67364,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079ee"
    },
    "Rank": 67390,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079ef"
    },
    "Rank": 67406,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gvernment Medical College,Haldwani,Government Medical College, Haldwani, Uttarakhand, 263139",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079f0"
    },
    "Rank": 67419,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079f1"
    },
    "Rank": 67444,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Allopathic Medical College, Uttar Pradesh,RANI DURGAVATI MEDICAL COLLEGE NARAINI ROAD BANDA, Uttar Pradesh, 210001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079f2"
    },
    "Rank": 67475,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079f3"
    },
    "Rank": 67482,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079f4"
    },
    "Rank": 67490,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Kollam,Government Medical College Kollam,Parippally, Kerala, 691574",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079f5"
    },
    "Rank": 67491,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079f6"
    },
    "Rank": 67527,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079f7"
    },
    "Rank": 67529,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079f8"
    },
    "Rank": 67554,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079f9"
    },
    "Rank": 67556,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079fa"
    },
    "Rank": 67572,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079fb"
    },
    "Rank": 67587,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079fc"
    },
    "Rank": 67623,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079fd"
    },
    "Rank": 67625,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079fe"
    },
    "Rank": 67628,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a079ff"
    },
    "Rank": 67644,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a00"
    },
    "Rank": 67649,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a01"
    },
    "Rank": 67653,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a02"
    },
    "Rank": 67660,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a03"
    },
    "Rank": 67661,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government General Hospital, Principal Medical Officer General hospital,\n,Rajiv Gandhi Government General Hospital, near bijli ghar circle, ALWAR, Rajasthan, 301001",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a04"
    },
    "Rank": 67664,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a05"
    },
    "Rank": 67672,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a06"
    },
    "Rank": 67674,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a07"
    },
    "Rank": 67680,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT DHARMAPURI MEDICAL COLLEGE, DHARMAPURI, TAMIL NADU,GOVERNMENT DHARMAPURI MEDICAL COLLEGE, NETHAJI BYE PASS ROAD, DHARMAPURI, TAMIL NADU, Tamil Nadu, 636701",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a08"
    },
    "Rank": 67696,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur and Associated Group of Hospitals Shri Haridev Joshi Hospital,,Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur, Rajasthan, 314001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a09"
    },
    "Rank": 67714,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a0a"
    },
    "Rank": 67715,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a0b"
    },
    "Rank": 67730,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a0c"
    },
    "Rank": 67736,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a0d"
    },
    "Rank": 67764,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (FAMILY MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a0e"
    },
    "Rank": 67772,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Karnataka,Kodagu Institute of Medical Sciences Madikeri Kodagu, Karnataka, 571201",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a0f"
    },
    "Rank": 67777,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a10"
    },
    "Rank": 67782,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a11"
    },
    "Rank": 67826,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a12"
    },
    "Rank": 67828,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a13"
    },
    "Rank": 67841,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute Of Medical Sciences, Kalaburagi,VEERESH NAGAR SEDAM ROAD KALABURAGI, Karnataka, 585105",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a14"
    },
    "Rank": 67853,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a15"
    },
    "Rank": 67858,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Goverment District Hospital Bundi, ,IN FRONT OF COLLECTORATE BUNDI, Rajasthan, 323001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a16"
    },
    "Rank": 67902,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asansol District Hospital, ,S. B. GORAI ROAD, P.O.- ASANSOL, DIST.- PASCHIM BARDHAMAN, West Bengal, 713301",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a17"
    },
    "Rank": 67904,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a18"
    },
    "Rank": 67928,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a19"
    },
    "Rank": 67940,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a1a"
    },
    "Rank": 67944,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a1b"
    },
    "Rank": 67951,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a1c"
    },
    "Rank": 67978,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a1d"
    },
    "Rank": 67979,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a1e"
    },
    "Rank": 68008,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a1f"
    },
    "Rank": 68009,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karnataka,MG Road, Karnataka, 581301",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a20"
    },
    "Rank": 68022,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a21"
    },
    "Rank": 68024,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES,CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, Chhattisgarh, 495001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a22"
    },
    "Rank": 68027,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a23"
    },
    "Rank": 68042,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a24"
    },
    "Rank": 68047,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a25"
    },
    "Rank": 68053,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a26"
    },
    "Rank": 68065,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a27"
    },
    "Rank": 68071,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a28"
    },
    "Rank": 68072,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a29"
    },
    "Rank": 68073,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a2a"
    },
    "Rank": 68079,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a2b"
    },
    "Rank": 68080,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a2c"
    },
    "Rank": 68096,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a2d"
    },
    "Rank": 68097,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a2e"
    },
    "Rank": 68153,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a2f"
    },
    "Rank": 68169,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a30"
    },
    "Rank": 68194,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,Veer Chandra Singh Garhwali Govt. Institute of Medical Science and Research, Srinagar Garhwal, Uttarakhand, 246174",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a31"
    },
    "Rank": 68203,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a32"
    },
    "Rank": 68207,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a33"
    },
    "Rank": 68295,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a34"
    },
    "Rank": 68327,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a35"
    },
    "Rank": 68329,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a36"
    },
    "Rank": 68377,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a37"
    },
    "Rank": 68390,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES ADILABAD -504001 TELANGANA STATE, Telangana, 504001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a38"
    },
    "Rank": 68394,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a39"
    },
    "Rank": 68396,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a3a"
    },
    "Rank": 68440,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a3b"
    },
    "Rank": 68477,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a3c"
    },
    "Rank": 68514,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a3d"
    },
    "Rank": 68554,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a3e"
    },
    "Rank": 68556,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a3f"
    },
    "Rank": 68557,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. Sports Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a40"
    },
    "Rank": 68559,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a41"
    },
    "Rank": 68562,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a42"
    },
    "Rank": 68584,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a43"
    },
    "Rank": 68601,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INSTITUTE OF MEDICAL SCIENCES,RAICHUR INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 584102",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a44"
    },
    "Rank": 68645,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a45"
    },
    "Rank": 68662,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a46"
    },
    "Rank": 68675,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a47"
    },
    "Rank": 68687,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a48"
    },
    "Rank": 68694,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a49"
    },
    "Rank": 68708,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a4a"
    },
    "Rank": 68709,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a4b"
    },
    "Rank": 68717,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "DIP.IN IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a4c"
    },
    "Rank": 68743,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a4d"
    },
    "Rank": 68746,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a4e"
    },
    "Rank": 68750,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a4f"
    },
    "Rank": 68757,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a50"
    },
    "Rank": 68758,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a51"
    },
    "Rank": 68760,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a52"
    },
    "Rank": 68763,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a53"
    },
    "Rank": 68765,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a54"
    },
    "Rank": 68773,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a55"
    },
    "Rank": 68778,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a56"
    },
    "Rank": 68794,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a57"
    },
    "Rank": 68802,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a58"
    },
    "Rank": 68803,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a59"
    },
    "Rank": 68808,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a5a"
    },
    "Rank": 68817,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a5b"
    },
    "Rank": 68827,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a5c"
    },
    "Rank": 68831,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a5d"
    },
    "Rank": 68843,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a5e"
    },
    "Rank": 68848,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a5f"
    },
    "Rank": 68849,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a60"
    },
    "Rank": 68856,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a61"
    },
    "Rank": 68867,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a62"
    },
    "Rank": 68935,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a63"
    },
    "Rank": 68952,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a64"
    },
    "Rank": 68970,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a65"
    },
    "Rank": 69017,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a66"
    },
    "Rank": 69019,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a67"
    },
    "Rank": 69022,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a68"
    },
    "Rank": 69024,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a69"
    },
    "Rank": 69116,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a6a"
    },
    "Rank": 69123,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a6b"
    },
    "Rank": 69124,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur and Associated Group of Hospitals Shri Haridev Joshi Hospital,,Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur, Rajasthan, 314001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a6c"
    },
    "Rank": 69125,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a6d"
    },
    "Rank": 69126,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a6e"
    },
    "Rank": 69129,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a6f"
    },
    "Rank": 69136,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a70"
    },
    "Rank": 69139,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a71"
    },
    "Rank": 69167,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Baba Saheb Ambedkar Hospital, ,SEC 6 ROHINI DELHI, Delhi (NCT), 110085",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a72"
    },
    "Rank": 69190,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bokaro General Hospital, ,SECTOR - 4 BOKARO STEEL CITY\nDIST - BOKARO, Jharkhand, 827004",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a73"
    },
    "Rank": 69193,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a74"
    },
    "Rank": 69202,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a75"
    },
    "Rank": 69225,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a76"
    },
    "Rank": 69245,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a77"
    },
    "Rank": 69252,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jalgaon,Jilha Peth, Government Hospital Campus, Jalgaon, Maharashtra, 425001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a78"
    },
    "Rank": 69272,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a79"
    },
    "Rank": 69278,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a7a"
    },
    "Rank": 69291,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a7b"
    },
    "Rank": 69299,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a7c"
    },
    "Rank": 69305,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a7d"
    },
    "Rank": 69306,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a7e"
    },
    "Rank": 69318,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a7f"
    },
    "Rank": 69325,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a80"
    },
    "Rank": 69364,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a81"
    },
    "Rank": 69390,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a82"
    },
    "Rank": 69418,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Hospital,,JANGLAT MANDI ANANTNAG, JAMMU AND KASHMIR 192101, Jammu And Kashmir, 192101",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a83"
    },
    "Rank": 69450,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a84"
    },
    "Rank": 69460,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital, B.H.Road, Tumakuru-572101, Karnataka, 572101",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a85"
    },
    "Rank": 69462,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "DIP. IN PHY. MEDICINE\nand REHAB.",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a86"
    },
    "Rank": 69472,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a87"
    },
    "Rank": 69473,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a88"
    },
    "Rank": 69476,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a89"
    },
    "Rank": 69494,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a8a"
    },
    "Rank": 69507,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a8b"
    },
    "Rank": 69514,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a8c"
    },
    "Rank": 69535,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a8d"
    },
    "Rank": 69543,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a8e"
    },
    "Rank": 69547,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a8f"
    },
    "Rank": 69555,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "J.L.N. Main Hospital and Research Centre, ,Bhilai Steel Plant Sector-9, Bhilai, C.G., Chhattisgarh, 490009",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a90"
    },
    "Rank": 69563,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a91"
    },
    "Rank": 69565,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a92"
    },
    "Rank": 69566,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a93"
    },
    "Rank": 69579,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a94"
    },
    "Rank": 69599,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a95"
    },
    "Rank": 69600,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a96"
    },
    "Rank": 69602,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a97"
    },
    "Rank": 69604,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a98"
    },
    "Rank": 69654,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a99"
    },
    "Rank": 69664,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a9a"
    },
    "Rank": 69673,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a9b"
    },
    "Rank": 69677,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a9c"
    },
    "Rank": 69696,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a9d"
    },
    "Rank": 69715,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a9e"
    },
    "Rank": 69717,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07a9f"
    },
    "Rank": 69719,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07aa0"
    },
    "Rank": 69721,
    "Allotted Quota": "All India",
    "Allotted Institute": "G B Pant Institute of Post Graduate Medical Education and Research,G B Pant Institute of Post Graduate Medical Education and Research, Delhi (NCT), 110007",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07aa1"
    },
    "Rank": 69723,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07aa2"
    },
    "Rank": 69744,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07aa3"
    },
    "Rank": 69753,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07aa4"
    },
    "Rank": 69776,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Siddipet,SURVEY NO 54  GOVERNMENT MEDICAL COLLEGE  ENSANPALLY  SIDDIPET   502114, Telangana, 502114",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07aa5"
    },
    "Rank": 69799,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07aa6"
    },
    "Rank": 69805,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07aa7"
    },
    "Rank": 69814,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07aa8"
    },
    "Rank": 69839,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07aa9"
    },
    "Rank": 69842,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07aaa"
    },
    "Rank": 69906,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07aab"
    },
    "Rank": 69927,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Siddipet,SURVEY NO 54  GOVERNMENT MEDICAL COLLEGE  ENSANPALLY  SIDDIPET   502114, Telangana, 502114",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07aac"
    },
    "Rank": 69937,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07aad"
    },
    "Rank": 69951,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07aae"
    },
    "Rank": 69955,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07aaf"
    },
    "Rank": 69972,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ab0"
    },
    "Rank": 69975,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ab1"
    },
    "Rank": 69982,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ab2"
    },
    "Rank": 70018,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ab3"
    },
    "Rank": 70025,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ab4"
    },
    "Rank": 70028,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ab5"
    },
    "Rank": 70037,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ab6"
    },
    "Rank": 70040,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ab7"
    },
    "Rank": 70061,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Bangur Hospital, Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan , Rajasthan-306401,Government Medical College and Bangur Hospital Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan Ra, Rajasthan, 306401",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ab8"
    },
    "Rank": 70069,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ab9"
    },
    "Rank": 70073,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07aba"
    },
    "Rank": 70085,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sanjay Gandhi Postgarduate Institute of Medical Sciences,Sanjay Gandhi Postgarduate Institute of Medical Sciences, Uttar Pradesh, 226014",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07abb"
    },
    "Rank": 70092,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07abc"
    },
    "Rank": 70099,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07abd"
    },
    "Rank": 70108,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07abe"
    },
    "Rank": 70115,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07abf"
    },
    "Rank": 70120,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ac0"
    },
    "Rank": 70148,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ac1"
    },
    "Rank": 70158,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ac2"
    },
    "Rank": 70185,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ac3"
    },
    "Rank": 70196,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ac4"
    },
    "Rank": 70199,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ac5"
    },
    "Rank": 70225,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ac6"
    },
    "Rank": 70237,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ac7"
    },
    "Rank": 70239,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ac8"
    },
    "Rank": 70247,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ac9"
    },
    "Rank": 70252,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07aca"
    },
    "Rank": 70268,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07acb"
    },
    "Rank": 70270,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07acc"
    },
    "Rank": 70302,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07acd"
    },
    "Rank": 70314,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ace"
    },
    "Rank": 70315,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07acf"
    },
    "Rank": 70321,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ad0"
    },
    "Rank": 70326,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ad1"
    },
    "Rank": 70337,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ad2"
    },
    "Rank": 70357,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhabha Atomic Research Centre and Hospital, ,Anushaktinagar, Mumbai, Maharashtra, 400094",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ad3"
    },
    "Rank": 70367,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. Sports Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ad4"
    },
    "Rank": 70370,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,HBT Medical College and Dr R N Cooper Municipal General Hospital, Bhaktivedanta Swami Rd, JVPD Schem, Maharashtra, 400056",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ad5"
    },
    "Rank": 70373,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ad6"
    },
    "Rank": 70380,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ad7"
    },
    "Rank": 70395,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ad8"
    },
    "Rank": 70451,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ad9"
    },
    "Rank": 70458,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ada"
    },
    "Rank": 70462,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07adb"
    },
    "Rank": 70475,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07adc"
    },
    "Rank": 70499,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07add"
    },
    "Rank": 70518,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ade"
    },
    "Rank": 70525,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07adf"
    },
    "Rank": 70531,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalpana Chawla Govt Medical College, Karnal, Haryana,Kalpana Chawla Government Medical College Model Town Karnal 132001 Haryana, Haryana, 132001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ae0"
    },
    "Rank": 70545,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ae1"
    },
    "Rank": 70556,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ae2"
    },
    "Rank": 70569,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District Hospital, Shopian Kashmir ,Jammu and Kashmir,OFFICE OF THE MEDICAL SUPERINTENDENT DISTRICT HOSPITAL SHOPIAN, Jammu And Kashmir, 192303",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ae3"
    },
    "Rank": 70584,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ae4"
    },
    "Rank": 70586,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ae5"
    },
    "Rank": 70599,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ae6"
    },
    "Rank": 70600,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ae7"
    },
    "Rank": 70604,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ae8"
    },
    "Rank": 70611,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "JLN Government Hospital,,NH 89 BIKANER ROAD, NAGAUR, Rajasthan, 341001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ae9"
    },
    "Rank": 70620,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07aea"
    },
    "Rank": 70643,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07aeb"
    },
    "Rank": 70648,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07aec"
    },
    "Rank": 70662,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07aed"
    },
    "Rank": 70669,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07aee"
    },
    "Rank": 70670,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07aef"
    },
    "Rank": 70695,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,Opposite to Shivajinagar Bus stop, Shivajinagar, Bengaluru., Karnataka, 560001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07af0"
    },
    "Rank": 70698,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Bhima Bhoi Medical College and Hospital, Balangir,At-Laltikra Po-Rajendra College, Balangir, Odisha, 767002",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07af1"
    },
    "Rank": 70710,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07af2"
    },
    "Rank": 70737,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07af3"
    },
    "Rank": 70751,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07af4"
    },
    "Rank": 70753,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07af5"
    },
    "Rank": 70765,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07af6"
    },
    "Rank": 70773,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07af7"
    },
    "Rank": 70793,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07af8"
    },
    "Rank": 70822,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaheed Hasan Khan Mewati Government Medical College,Shaheed Hasan Khan Mewati Government Medical College, Haryana, 122107",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07af9"
    },
    "Rank": 70828,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07afa"
    },
    "Rank": 70836,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07afb"
    },
    "Rank": 70841,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07afc"
    },
    "Rank": 70852,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INSTITUTE OF MEDICAL SCIENCES,RAICHUR INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 584102",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07afd"
    },
    "Rank": 70855,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kidwai Memorial Institute of Oncolgy,Kidwai Memorial Institute of Oncolgy, Karnataka, 560029",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07afe"
    },
    "Rank": 70860,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07aff"
    },
    "Rank": 70864,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN,HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN, Karnataka, 573201",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b00"
    },
    "Rank": 70865,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b01"
    },
    "Rank": 70888,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Hospital,,JANGLAT MANDI ANANTNAG, JAMMU AND KASHMIR 192101, Jammu And Kashmir, 192101",
    "Course": "(NBEMS) PSYCHIATRY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b02"
    },
    "Rank": 70896,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b03"
    },
    "Rank": 70900,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b04"
    },
    "Rank": 70907,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b05"
    },
    "Rank": 70929,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b06"
    },
    "Rank": 70932,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b07"
    },
    "Rank": 70936,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jagjivan Ram Railway Hospital,,Maratha Mandir Marg, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b08"
    },
    "Rank": 70940,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b09"
    },
    "Rank": 70942,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b0a"
    },
    "Rank": 70944,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b0b"
    },
    "Rank": 70955,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Taluk General Hospital, Karnataka,Taluk General Hospital Bhadravathi cmobdvtatgmail.com, Karnataka, 577301",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b0c"
    },
    "Rank": 70996,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b0d"
    },
    "Rank": 71003,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b0e"
    },
    "Rank": 71011,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b0f"
    },
    "Rank": 71014,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b10"
    },
    "Rank": 71024,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b11"
    },
    "Rank": 71033,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b12"
    },
    "Rank": 71046,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaheed Hasan Khan Mewati Government Medical College,Shaheed Hasan Khan Mewati Government Medical College, Haryana, 122107",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b13"
    },
    "Rank": 71049,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b14"
    },
    "Rank": 71078,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b15"
    },
    "Rank": 71099,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b16"
    },
    "Rank": 71133,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b17"
    },
    "Rank": 71154,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b18"
    },
    "Rank": 71164,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHIMOGA INSTITUTE OF MEDICAL SCIENCES,SHIMOGA INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 577201",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b19"
    },
    "Rank": 71170,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b1a"
    },
    "Rank": 71174,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b1b"
    },
    "Rank": 71183,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b1c"
    },
    "Rank": 71210,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b1d"
    },
    "Rank": 71215,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b1e"
    },
    "Rank": 71218,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESIC MEDICAL COLLEGE AND HOSPITAL, Bihar,ESIC Medical College and Hospital,Ara Danapur Road, Sikandarpur, Bihta, Patna, Bihar, 801103",
    "Course": "(NBEMS) PHARMACOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b1f"
    },
    "Rank": 71219,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b20"
    },
    "Rank": 71237,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b21"
    },
    "Rank": 71241,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b22"
    },
    "Rank": 71245,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital , ,shahid Rajmal Meena Govt. Dis. Hospital Mandi Road Baran, Rajasthan, 325205",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b23"
    },
    "Rank": 71267,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Sagar, Madhya Pradesh,shivaji ward tilli road sagar, Madhya Pradesh, 470002",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b24"
    },
    "Rank": 71288,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Datia,Near 29 Batallion, National Highway 44, Dist Datia, Madhya Pradesh Pin - 475661, Madhya Pradesh, 475661",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b25"
    },
    "Rank": 71292,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b26"
    },
    "Rank": 71293,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b27"
    },
    "Rank": 71295,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b28"
    },
    "Rank": 71296,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b29"
    },
    "Rank": 71314,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b2a"
    },
    "Rank": 71329,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b2b"
    },
    "Rank": 71336,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b2c"
    },
    "Rank": 71338,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b2d"
    },
    "Rank": 71347,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b2e"
    },
    "Rank": 71372,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b2f"
    },
    "Rank": 71393,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b30"
    },
    "Rank": 71396,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b31"
    },
    "Rank": 71403,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b32"
    },
    "Rank": 71427,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b33"
    },
    "Rank": 71453,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b34"
    },
    "Rank": 71455,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b35"
    },
    "Rank": 71503,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b36"
    },
    "Rank": 71522,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b37"
    },
    "Rank": 71532,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jamnabai General Hospital, ,Mandvi-Panigate Road, Mandvi, Opp. SBI Main Branch, Vadodara., Gujarat, 390017",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b38"
    },
    "Rank": 71544,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b39"
    },
    "Rank": 71578,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b3a"
    },
    "Rank": 71580,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b3b"
    },
    "Rank": 71589,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b3c"
    },
    "Rank": 71608,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan , Rajasthan-311001,Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan, Rajasthan, 311001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b3d"
    },
    "Rank": 71613,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b3e"
    },
    "Rank": 71639,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b3f"
    },
    "Rank": 71648,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b40"
    },
    "Rank": 71700,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b41"
    },
    "Rank": 71704,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b42"
    },
    "Rank": 71718,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b43"
    },
    "Rank": 71728,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pandit Raghunath Murmu Medical College and Hospital , Orissa,AT-RANGAMATIA, PO- SHAMAKHUNTA, PS-BARIPADA SADAR, BARIPADA, MAYURBHANJ, Odisha, 757107",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b44"
    },
    "Rank": 71744,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b45"
    },
    "Rank": 71752,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b46"
    },
    "Rank": 71757,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b47"
    },
    "Rank": 71758,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b48"
    },
    "Rank": 71759,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b49"
    },
    "Rank": 71767,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b4a"
    },
    "Rank": 71769,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b4b"
    },
    "Rank": 71775,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b4c"
    },
    "Rank": 71782,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Karnataka,Kodagu Institute of Medical Sciences Madikeri Kodagu, Karnataka, 571201",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b4d"
    },
    "Rank": 71802,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b4e"
    },
    "Rank": 71818,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b4f"
    },
    "Rank": 71848,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b50"
    },
    "Rank": 71852,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES ADILABAD -504001 TELANGANA STATE, Telangana, 504001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b51"
    },
    "Rank": 71856,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b52"
    },
    "Rank": 71859,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b53"
    },
    "Rank": 71869,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b54"
    },
    "Rank": 71870,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b55"
    },
    "Rank": 71877,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b56"
    },
    "Rank": 71897,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b57"
    },
    "Rank": 71942,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b58"
    },
    "Rank": 71958,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b59"
    },
    "Rank": 71968,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b5a"
    },
    "Rank": 71997,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b5b"
    },
    "Rank": 72001,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b5c"
    },
    "Rank": 72024,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b5d"
    },
    "Rank": 72060,
    "Allotted Quota": "All India",
    "Allotted Institute": "BHOPAL MEMORIAL HOSPITAL AND RESEARCH CENTRE, BHOPAL,Raisen By pass\nRoad, Bhopal- 462038 (M.P.), Madhya Pradesh, 462038",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b5e"
    },
    "Rank": 72064,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b5f"
    },
    "Rank": 72065,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b60"
    },
    "Rank": 72068,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b61"
    },
    "Rank": 72075,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b62"
    },
    "Rank": 72079,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b63"
    },
    "Rank": 72095,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Swami Dayanand Hospital, ,Dilshad Garden, Shahdara, Delhi, Delhi (NCT), 110095",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b64"
    },
    "Rank": 72101,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b65"
    },
    "Rank": 72111,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Hurkisondas Nurrotumdas Hospital and Research Centre, ,Prarthana Samaj, Raja Ram Mohan Roy road,\nGirgaon, MUMBAI, Maharashtra, 400004",
    "Course": "(NBEMS) MICROBIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b66"
    },
    "Rank": 72128,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b67"
    },
    "Rank": 72133,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b68"
    },
    "Rank": 72136,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b69"
    },
    "Rank": 72149,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b6a"
    },
    "Rank": 72222,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b6b"
    },
    "Rank": 72228,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b6c"
    },
    "Rank": 72242,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b6d"
    },
    "Rank": 72257,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b6e"
    },
    "Rank": 72280,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b6f"
    },
    "Rank": 72285,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b70"
    },
    "Rank": 72317,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b71"
    },
    "Rank": 72323,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Vallabhbhai Patel Chest Institute, Delhi,Vallabhbhai Patel Chest Institute, Delhi, Delhi (NCT), 110007",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b72"
    },
    "Rank": 72324,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b73"
    },
    "Rank": 72326,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b74"
    },
    "Rank": 72344,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b75"
    },
    "Rank": 72346,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b76"
    },
    "Rank": 72368,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b77"
    },
    "Rank": 72386,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b78"
    },
    "Rank": 72390,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital for Women, Akola ,,District Hospital for Women  Near Durga Chowk Akola 444001, Maharashtra, 444001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b79"
    },
    "Rank": 72393,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b7a"
    },
    "Rank": 72407,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b7b"
    },
    "Rank": 72415,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b7c"
    },
    "Rank": 72436,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b7d"
    },
    "Rank": 72444,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b7e"
    },
    "Rank": 72446,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b7f"
    },
    "Rank": 72457,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b80"
    },
    "Rank": 72463,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "The National Institute of Health and Family Welfare,The National Institute of Health and Family Welfare, Delhi (NCT), 110067",
    "Course": "M.D. (COMMUNITY HEALTH  and ADMN.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b81"
    },
    "Rank": 72467,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b82"
    },
    "Rank": 72470,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b83"
    },
    "Rank": 72484,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b84"
    },
    "Rank": 72490,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b85"
    },
    "Rank": 72533,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b86"
    },
    "Rank": 72538,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b87"
    },
    "Rank": 72540,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b88"
    },
    "Rank": 72552,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b89"
    },
    "Rank": 72570,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b8a"
    },
    "Rank": 72589,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b8b"
    },
    "Rank": 72594,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b8c"
    },
    "Rank": 72614,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karnataka,MG Road, Karnataka, 581301",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b8d"
    },
    "Rank": 72630,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b8e"
    },
    "Rank": 72636,
    "Allotted Quota": "All India",
    "Allotted Institute": "BIDAR INSTITUTE OF MEDICAL SCIENCES, BIDAR,BIDAR INSTITUTE OF MEDICAL SCIENCES, BIDAR, Karnataka, 585401",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b8f"
    },
    "Rank": 72641,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b90"
    },
    "Rank": 72649,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b91"
    },
    "Rank": 72651,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b92"
    },
    "Rank": 72656,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b93"
    },
    "Rank": 72678,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b94"
    },
    "Rank": 72681,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b95"
    },
    "Rank": 72701,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b96"
    },
    "Rank": 72703,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b97"
    },
    "Rank": 72725,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b98"
    },
    "Rank": 72735,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b99"
    },
    "Rank": 72742,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b9a"
    },
    "Rank": 72750,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b9b"
    },
    "Rank": 72758,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sher-I-Kashmir Institute of Medical Sciences, ,Principal SKIMS Medical College Hospital Bemina Srinagar, Jammu And Kashmir, 190018",
    "Course": "(NBEMS) PHYSIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b9c"
    },
    "Rank": 72823,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b9d"
    },
    "Rank": 72827,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE,ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, Bihar, 823001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b9e"
    },
    "Rank": 72830,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07b9f"
    },
    "Rank": 72845,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ba0"
    },
    "Rank": 72847,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ba1"
    },
    "Rank": 72882,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ba2"
    },
    "Rank": 72904,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ba3"
    },
    "Rank": 72930,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ba4"
    },
    "Rank": 72951,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Bundelkhand Medical College Sagar M.P.,Govt. Bundelkhand Medical College Sagar M.P., Madhya Pradesh, 470001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ba5"
    },
    "Rank": 72989,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ba6"
    },
    "Rank": 73007,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ba7"
    },
    "Rank": 73012,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ba8"
    },
    "Rank": 73014,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ba9"
    },
    "Rank": 73028,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07baa"
    },
    "Rank": 73059,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESIC MEDICAL COLLEGE AND HOSPITAL, Bihar,ESIC Medical College and Hospital,Ara Danapur Road, Sikandarpur, Bihta, Patna, Bihar, 801103",
    "Course": "(NBEMS) PHYSIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bab"
    },
    "Rank": 73074,
    "Allotted Quota": "All India",
    "Allotted Institute": "BIDAR INSTITUTE OF MEDICAL SCIENCES, BIDAR,BIDAR INSTITUTE OF MEDICAL SCIENCES, BIDAR, Karnataka, 585401",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bac"
    },
    "Rank": 73086,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bad"
    },
    "Rank": 73088,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bae"
    },
    "Rank": 73110,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07baf"
    },
    "Rank": 73111,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bb0"
    },
    "Rank": 73122,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bb1"
    },
    "Rank": 73125,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bb2"
    },
    "Rank": 73143,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bb3"
    },
    "Rank": 73146,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bb4"
    },
    "Rank": 73153,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bb5"
    },
    "Rank": 73165,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bb6"
    },
    "Rank": 73177,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bb7"
    },
    "Rank": 73195,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bb8"
    },
    "Rank": 73201,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bb9"
    },
    "Rank": 73204,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bba"
    },
    "Rank": 73208,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bbb"
    },
    "Rank": 73225,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bbc"
    },
    "Rank": 73235,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bbd"
    },
    "Rank": 73247,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bbe"
    },
    "Rank": 73253,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bbf"
    },
    "Rank": 73263,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shrikrishna Medical College & Hospital, Muzaffarpur,Shrikrishna Medical College & Hospital, Muzaffarpur, Bihar, 842004",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bc0"
    },
    "Rank": 73281,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Indira Gandhi Govt. General Hospital and PG Institute, No. 1, ,No.1, Victor Simonel street, Puducherry -1., Puducherry, 605001",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bc1"
    },
    "Rank": 73291,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bc2"
    },
    "Rank": 73296,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bc3"
    },
    "Rank": 73310,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bc4"
    },
    "Rank": 73321,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bc5"
    },
    "Rank": 73337,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bc6"
    },
    "Rank": 73348,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bc7"
    },
    "Rank": 73350,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bc8"
    },
    "Rank": 73386,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS COLLEGE OF MEDICAL SCIENCES,RUHS COLLEGE OF MEDICAL\nSCIENCES, Rajasthan, 302033",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bc9"
    },
    "Rank": 73387,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bca"
    },
    "Rank": 73391,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Bundelkhand Medical College Sagar M.P.,Govt. Bundelkhand Medical College Sagar M.P., Madhya Pradesh, 470001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bcb"
    },
    "Rank": 73403,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bcc"
    },
    "Rank": 73426,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bcd"
    },
    "Rank": 73431,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bce"
    },
    "Rank": 73436,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bcf"
    },
    "Rank": 73439,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Northern Railway Central Hospital,, ,Basant Lane New Delhi, Delhi (NCT), 110055",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bd0"
    },
    "Rank": 73462,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bd1"
    },
    "Rank": 73464,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bd2"
    },
    "Rank": 73465,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bd3"
    },
    "Rank": 73468,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bd4"
    },
    "Rank": 73471,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bd5"
    },
    "Rank": 73483,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.R.A. Medical College,\n,Saddarpur Akabarpur Tanda Road Post Surapur\nDistrict Ambedkarnagar, Uttar Pradesh, 224227",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bd6"
    },
    "Rank": 73497,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bd7"
    },
    "Rank": 73505,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bd8"
    },
    "Rank": 73512,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bd9"
    },
    "Rank": 73518,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bda"
    },
    "Rank": 73530,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bdb"
    },
    "Rank": 73551,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Area Hospital, ,Thirupathi Road Near Saibaba Temple, Area Hospital, Nagari Chittoor Dist, Andhra Pradesh, 517590",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bdc"
    },
    "Rank": 73553,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bdd"
    },
    "Rank": 73567,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bde"
    },
    "Rank": 73573,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bdf"
    },
    "Rank": 73576,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07be0"
    },
    "Rank": 73591,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vallabhbhai Patel Chest Institute, Delhi,Vallabhbhai Patel Chest Institute, Delhi, Delhi (NCT), 110007",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07be1"
    },
    "Rank": 73601,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07be2"
    },
    "Rank": 73605,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07be3"
    },
    "Rank": 73612,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07be4"
    },
    "Rank": 73628,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07be5"
    },
    "Rank": 73629,
    "Allotted Quota": "All India",
    "Allotted Institute": "Late Baliram Kashyap Memorial Govt Medical Dimrapal,Late Baliram Kashyap Memorial Govt Medical Dimrapal, Chhattisgarh, 494001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07be6"
    },
    "Rank": 73654,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07be7"
    },
    "Rank": 73667,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07be8"
    },
    "Rank": 73669,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07be9"
    },
    "Rank": 73700,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bea"
    },
    "Rank": 73705,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07beb"
    },
    "Rank": 73728,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bec"
    },
    "Rank": 73771,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bed"
    },
    "Rank": 73772,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bee"
    },
    "Rank": 73806,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bef"
    },
    "Rank": 73810,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bf0"
    },
    "Rank": 73859,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bf1"
    },
    "Rank": 73868,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bf2"
    },
    "Rank": 73895,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bf3"
    },
    "Rank": 73911,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bf4"
    },
    "Rank": 73918,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bf5"
    },
    "Rank": 73923,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bf6"
    },
    "Rank": 73973,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bf7"
    },
    "Rank": 74009,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bf8"
    },
    "Rank": 74052,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bf9"
    },
    "Rank": 74101,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bfa"
    },
    "Rank": 74111,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bfb"
    },
    "Rank": 74120,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bfc"
    },
    "Rank": 74133,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bfd"
    },
    "Rank": 74150,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bfe"
    },
    "Rank": 74178,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07bff"
    },
    "Rank": 74183,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c00"
    },
    "Rank": 74214,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c01"
    },
    "Rank": 74267,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c02"
    },
    "Rank": 74277,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c03"
    },
    "Rank": 74321,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c04"
    },
    "Rank": 74335,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c05"
    },
    "Rank": 74363,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Government Medical College, Vidisha,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA MP, Madhya Pradesh, 464001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c06"
    },
    "Rank": 74371,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S.K.Hospital, ,SUPERINTENDENT RNO 36  GOVT SK HOSPITAL  SILVER JUBLI ROAD  SIKAR  RAJ, Rajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c07"
    },
    "Rank": 74372,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c08"
    },
    "Rank": 74388,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. BDK hospital, govt. ,OPPOSITE ROADWAYS BUS STAND MAAN NAGAR JHUNJHUNU RAJASTHAN, Rajasthan, 333001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c09"
    },
    "Rank": 74435,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c0a"
    },
    "Rank": 74441,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c0b"
    },
    "Rank": 74448,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c0c"
    },
    "Rank": 74465,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital, ,Nellikuppam Main Road, Cuddalore, Tamil Nadu, 607001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c0d"
    },
    "Rank": 74473,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c0e"
    },
    "Rank": 74481,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c0f"
    },
    "Rank": 74492,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c10"
    },
    "Rank": 74503,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Gondia,Nehru Chowk, KTS General Hospital Campus, Govt. Medical College, Gondia. 441601. Maharashtra., Maharashtra, 441601",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c11"
    },
    "Rank": 74510,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c12"
    },
    "Rank": 74561,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c13"
    },
    "Rank": 74567,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c14"
    },
    "Rank": 74573,
    "Allotted Quota": "All India",
    "Allotted Institute": "Late Baliram Kashyap Memorial Govt Medical Dimrapal,Late Baliram Kashyap Memorial Govt Medical Dimrapal, Chhattisgarh, 494001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c15"
    },
    "Rank": 74587,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c16"
    },
    "Rank": 74622,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c17"
    },
    "Rank": 74636,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c18"
    },
    "Rank": 74669,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sir Hurkisondas Nurrotumdas Hospital and Research Centre, ,Prarthana Samaj, Raja Ram Mohan Roy road,\nGirgaon, MUMBAI, Maharashtra, 400004",
    "Course": "(NBEMS) MICROBIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c19"
    },
    "Rank": 74684,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c1a"
    },
    "Rank": 74737,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c1b"
    },
    "Rank": 74743,
    "Allotted Quota": "All India",
    "Allotted Institute": "NSC Government Medical College, Khandwa (MP),LUV KUSH NAGAR,MUNDI NAGAR, KHANDWA, Madhya Pradesh, 450001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c1c"
    },
    "Rank": 74798,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c1d"
    },
    "Rank": 74805,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. Sports Medicine",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c1e"
    },
    "Rank": 74818,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c1f"
    },
    "Rank": 74819,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ranaghat Sub Division Hospital, West Bengal,Jagpur Road, West Bengal, 741255",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c20"
    },
    "Rank": 74823,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S.K.Hospital, ,SUPERINTENDENT RNO 36  GOVT SK HOSPITAL  SILVER JUBLI ROAD  SIKAR  RAJ, Rajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c21"
    },
    "Rank": 74828,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c22"
    },
    "Rank": 74832,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c23"
    },
    "Rank": 74834,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Ramkaran Joshi Government District Hospital, ,LALSOT ROAD, DAUSA, Rajasthan, 303303",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c24"
    },
    "Rank": 74855,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c25"
    },
    "Rank": 74857,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c26"
    },
    "Rank": 74872,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESIC MEDICAL COLLEGE AND HOSPITAL, Bihar,ESIC Medical College and Hospital,Ara Danapur Road, Sikandarpur, Bihta, Patna, Bihar, 801103",
    "Course": "(NBEMS) BIOCHEMISTRY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c27"
    },
    "Rank": 74876,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c28"
    },
    "Rank": 74889,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c29"
    },
    "Rank": 74909,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c2a"
    },
    "Rank": 74944,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c2b"
    },
    "Rank": 74946,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c2c"
    },
    "Rank": 74954,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c2d"
    },
    "Rank": 74963,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c2e"
    },
    "Rank": 74969,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c2f"
    },
    "Rank": 74972,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c30"
    },
    "Rank": 74986,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c31"
    },
    "Rank": 74990,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c32"
    },
    "Rank": 74998,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c33"
    },
    "Rank": 75015,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c34"
    },
    "Rank": 75060,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c35"
    },
    "Rank": 75065,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c36"
    },
    "Rank": 75067,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Siliguri District Hospital,,Kachari Road, Siliguri, Dist- Darjeeling, West Bengal, West Bengal, 734001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c37"
    },
    "Rank": 75079,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karnataka,MG Road, Karnataka, 581301",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c38"
    },
    "Rank": 75100,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c39"
    },
    "Rank": 75124,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c3a"
    },
    "Rank": 75162,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c3b"
    },
    "Rank": 75233,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Khan Bahadur Bhabha Hospital, Maharashtra,Khan Bahadur Bhabha Hospital Belgrami road kurla west, Maharashtra, 400070",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c3c"
    },
    "Rank": 75253,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c3d"
    },
    "Rank": 75255,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c3e"
    },
    "Rank": 75256,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c3f"
    },
    "Rank": 75260,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c40"
    },
    "Rank": 75262,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c41"
    },
    "Rank": 75263,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c42"
    },
    "Rank": 75277,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c43"
    },
    "Rank": 75280,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c44"
    },
    "Rank": 75288,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c45"
    },
    "Rank": 75314,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c46"
    },
    "Rank": 75345,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c47"
    },
    "Rank": 75349,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c48"
    },
    "Rank": 75356,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c49"
    },
    "Rank": 75360,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c4a"
    },
    "Rank": 75383,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c4b"
    },
    "Rank": 75403,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c4c"
    },
    "Rank": 75412,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c4d"
    },
    "Rank": 75422,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c4e"
    },
    "Rank": 75439,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c4f"
    },
    "Rank": 75447,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c50"
    },
    "Rank": 75454,
    "Allotted Quota": "All India",
    "Allotted Institute": "Fakhruddin Ali Ahmed Medical College,Fakhruddin Ali Ahmed Medical College, Assam, 781301",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c51"
    },
    "Rank": 75455,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES ADILABAD -504001 TELANGANA STATE, Telangana, 504001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c52"
    },
    "Rank": 75463,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c53"
    },
    "Rank": 75465,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c54"
    },
    "Rank": 75469,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c55"
    },
    "Rank": 75470,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c56"
    },
    "Rank": 75473,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. Sports Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c57"
    },
    "Rank": 75474,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital, B.H.Road, Tumakuru-572101, Karnataka, 572101",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c58"
    },
    "Rank": 75475,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College,Tumuki, Bihaguri, Tezpur, Sonitpur, Assam, Assam, 784010",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c59"
    },
    "Rank": 75476,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c5a"
    },
    "Rank": 75521,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c5b"
    },
    "Rank": 75547,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Bhausaheb Hire Government Medical College,Sri Bhausaheb Hire Government Medical College,Dhule, Maharashtra, 424311",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c5c"
    },
    "Rank": 75558,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c5d"
    },
    "Rank": 75562,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c5e"
    },
    "Rank": 75625,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bettiah,Government Medical College Bettiah West Champaran Bihar, Bihar, 845438",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c5f"
    },
    "Rank": 75631,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c60"
    },
    "Rank": 75643,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c61"
    },
    "Rank": 75674,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SBLS Civil Hospital, ,NEAR JYOTI CHOWK JAIL ROAD JALANDHAR, Punjab, 144001",
    "Course": "(NBEMS) GENERAL SURGERY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c62"
    },
    "Rank": 75681,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nalgonda,Office of the Principal, Government Medical College Nalgonda, Near RTC Bus Stand, Gollaguda,\nNalgon, Telangana, 508001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c63"
    },
    "Rank": 75695,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c64"
    },
    "Rank": 75752,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Krishna Institute of Medical Sciences, ,academics@kimshospitals.com, Telangana, 500003",
    "Course": "(NBEMS) MICROBIOLOGY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c65"
    },
    "Rank": 75791,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c66"
    },
    "Rank": 75794,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, Karnataka\n,General Hospital Chintamani M G Road.\nCHINTAMANI\nChikkaballapur district, Karnataka, 563125",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c67"
    },
    "Rank": 75807,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c68"
    },
    "Rank": 75816,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c69"
    },
    "Rank": 75833,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c6a"
    },
    "Rank": 75938,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c6b"
    },
    "Rank": 75942,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE,ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, Bihar, 823001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c6c"
    },
    "Rank": 75945,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c6d"
    },
    "Rank": 75956,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c6e"
    },
    "Rank": 75963,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c6f"
    },
    "Rank": 75968,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c70"
    },
    "Rank": 75988,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c71"
    },
    "Rank": 75995,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c72"
    },
    "Rank": 75998,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c73"
    },
    "Rank": 76007,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c74"
    },
    "Rank": 76008,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tomo Riba Institute of Health and Medical Sciences,,TRIHMS. Old Assembly Block. Near Rajiv Gandhi Stadium. Naharlagun-791110. Arunachal Pradesh, Arunachal Pradesh, 791110",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c75"
    },
    "Rank": 76013,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c76"
    },
    "Rank": 76036,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c77"
    },
    "Rank": 76043,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c78"
    },
    "Rank": 76076,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c79"
    },
    "Rank": 76089,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c7a"
    },
    "Rank": 76125,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Orthopaedics Hospital, ,222, Church Road, Howrah, West Bengal, 711101",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c7b"
    },
    "Rank": 76133,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c7c"
    },
    "Rank": 76154,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c7d"
    },
    "Rank": 76158,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c7e"
    },
    "Rank": 76171,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c7f"
    },
    "Rank": 76175,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c80"
    },
    "Rank": 76178,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c81"
    },
    "Rank": 76188,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c82"
    },
    "Rank": 76191,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sheth Vadilal Sarabhai General Hospital and Sheth Chinai Prasuti Hospital, Gujarat,SHETH VADILAL SARABHAI GENERAL HOSPITAL AND SHETH CHINAI PRASUTI HOSPITAL ELLISBRIDGE AHMEDABAD, Gujarat, 380006",
    "Course": "(NBEMS) ORTHOPAEDICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c83"
    },
    "Rank": 76193,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c84"
    },
    "Rank": 76205,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c85"
    },
    "Rank": 76244,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c86"
    },
    "Rank": 76261,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c87"
    },
    "Rank": 76287,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c88"
    },
    "Rank": 76290,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c89"
    },
    "Rank": 76298,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c8a"
    },
    "Rank": 76300,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c8b"
    },
    "Rank": 76310,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c8c"
    },
    "Rank": 76314,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c8d"
    },
    "Rank": 76321,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c8e"
    },
    "Rank": 76329,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c8f"
    },
    "Rank": 76335,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c90"
    },
    "Rank": 76340,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c91"
    },
    "Rank": 76356,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c92"
    },
    "Rank": 76417,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c93"
    },
    "Rank": 76418,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College,Tumuki, Bihaguri, Tezpur, Sonitpur, Assam, Assam, 784010",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c94"
    },
    "Rank": 76428,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c95"
    },
    "Rank": 76454,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c96"
    },
    "Rank": 76462,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c97"
    },
    "Rank": 76466,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c98"
    },
    "Rank": 76483,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c99"
    },
    "Rank": 76521,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c9a"
    },
    "Rank": 76529,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c9b"
    },
    "Rank": 76532,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c9c"
    },
    "Rank": 76539,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c9d"
    },
    "Rank": 76540,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c9e"
    },
    "Rank": 76553,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07c9f"
    },
    "Rank": 76558,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ca0"
    },
    "Rank": 76562,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ca1"
    },
    "Rank": 76594,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ca2"
    },
    "Rank": 76597,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ca3"
    },
    "Rank": 76615,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ca4"
    },
    "Rank": 76636,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ca5"
    },
    "Rank": 76658,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ca6"
    },
    "Rank": 76661,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ca7"
    },
    "Rank": 76670,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ca8"
    },
    "Rank": 76696,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ca9"
    },
    "Rank": 76701,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07caa"
    },
    "Rank": 76716,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cab"
    },
    "Rank": 76718,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cac"
    },
    "Rank": 76726,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cad"
    },
    "Rank": 76732,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cae"
    },
    "Rank": 76770,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07caf"
    },
    "Rank": 76777,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cb0"
    },
    "Rank": 76781,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cb1"
    },
    "Rank": 76799,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cb2"
    },
    "Rank": 76807,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cb3"
    },
    "Rank": 76819,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cb4"
    },
    "Rank": 76846,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cb5"
    },
    "Rank": 76861,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cb6"
    },
    "Rank": 76881,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cb7"
    },
    "Rank": 76893,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cb8"
    },
    "Rank": 76906,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cb9"
    },
    "Rank": 76922,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cba"
    },
    "Rank": 76943,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cbb"
    },
    "Rank": 76947,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Super Facility Hospital, Distt.-Azamgarh, Uttar Pradesh,Chakrapanpur, Kanaila, Azamgarh, Uttar Pradesh, 276128",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cbc"
    },
    "Rank": 76957,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cbd"
    },
    "Rank": 76958,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,DISTRICT HOSPITAL NEAR SANGAM CIRCLE DR RAJ KUMAR ROAD BALLARI-583101 KARNATAKA, Karnataka, 583101",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cbe"
    },
    "Rank": 76970,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cbf"
    },
    "Rank": 76978,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS COLLEGE OF MEDICAL SCIENCES,RUHS COLLEGE OF MEDICAL\nSCIENCES, Rajasthan, 302033",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cc0"
    },
    "Rank": 77038,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cc1"
    },
    "Rank": 77049,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cc2"
    },
    "Rank": 77062,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Asansol District Hospital, ,S. B. GORAI ROAD, P.O.- ASANSOL, DIST.- PASCHIM BARDHAMAN, West Bengal, 713301",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cc3"
    },
    "Rank": 77063,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cc4"
    },
    "Rank": 77076,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cc5"
    },
    "Rank": 77083,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cc6"
    },
    "Rank": 77091,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karnataka,MG Road, Karnataka, 581301",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cc7"
    },
    "Rank": 77104,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cc8"
    },
    "Rank": 77154,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cc9"
    },
    "Rank": 77167,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cca"
    },
    "Rank": 77195,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ccb"
    },
    "Rank": 77198,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ccc"
    },
    "Rank": 77208,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ccd"
    },
    "Rank": 77212,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cce"
    },
    "Rank": 77215,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ccf"
    },
    "Rank": 77218,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cd0"
    },
    "Rank": 77256,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cd1"
    },
    "Rank": 77272,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cd2"
    },
    "Rank": 77290,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cd3"
    },
    "Rank": 77308,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cd4"
    },
    "Rank": 77312,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "DIPLOMA IN OTO- RHINO-LARYNGOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cd5"
    },
    "Rank": 77315,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cd6"
    },
    "Rank": 77321,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cd7"
    },
    "Rank": 77322,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cd8"
    },
    "Rank": 77324,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cd9"
    },
    "Rank": 77326,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cda"
    },
    "Rank": 77345,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cdb"
    },
    "Rank": 77374,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cdc"
    },
    "Rank": 77384,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cdd"
    },
    "Rank": 77386,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cde"
    },
    "Rank": 77460,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cdf"
    },
    "Rank": 77468,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ce0"
    },
    "Rank": 77481,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ce1"
    },
    "Rank": 77541,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ce2"
    },
    "Rank": 77543,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaikh-Ul-Hind Maulana Mahmood Hasan Medical College, Saharanpur,Pilkhani Ambala Road, Uttar Pradesh, 247232",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ce3"
    },
    "Rank": 77569,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ce4"
    },
    "Rank": 77584,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ce5"
    },
    "Rank": 77634,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ce6"
    },
    "Rank": 77664,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ce7"
    },
    "Rank": 77671,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ce8"
    },
    "Rank": 77674,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ce9"
    },
    "Rank": 77684,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cea"
    },
    "Rank": 77693,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ceb"
    },
    "Rank": 77718,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bettiah,Government Medical College Bettiah West Champaran Bihar, Bihar, 845438",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cec"
    },
    "Rank": 77722,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ced"
    },
    "Rank": 77728,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Siddipet,SURVEY NO 54  GOVERNMENT MEDICAL COLLEGE  ENSANPALLY  SIDDIPET   502114, Telangana, 502114",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cee"
    },
    "Rank": 77729,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cef"
    },
    "Rank": 77737,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cf0"
    },
    "Rank": 77740,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cf1"
    },
    "Rank": 77749,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Capital Hospital, Orissa,director.chb@gmail.com, Odisha, 751001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cf2"
    },
    "Rank": 77751,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cf3"
    },
    "Rank": 77843,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Tomo Riba Institute of Health and Medical Sciences,,TRIHMS. Old Assembly Block. Near Rajiv Gandhi Stadium. Naharlagun-791110. Arunachal Pradesh, Arunachal Pradesh, 791110",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cf4"
    },
    "Rank": 77853,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cf5"
    },
    "Rank": 77883,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Gondia,Nehru Chowk, KTS General Hospital Campus, Govt. Medical College, Gondia. 441601. Maharashtra., Maharashtra, 441601",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cf6"
    },
    "Rank": 77889,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District Hospital\nNext to Sainik School second gate Athani Road, Vijayapura Karnataka 586103, Karnataka, 586103",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cf7"
    },
    "Rank": 77892,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cf8"
    },
    "Rank": 77899,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cf9"
    },
    "Rank": 77919,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cfa"
    },
    "Rank": 77920,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN,HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN, Karnataka, 573201",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cfb"
    },
    "Rank": 77922,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cfc"
    },
    "Rank": 77923,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cfd"
    },
    "Rank": 77924,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cfe"
    },
    "Rank": 77933,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07cff"
    },
    "Rank": 77978,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d00"
    },
    "Rank": 77982,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d01"
    },
    "Rank": 77984,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d02"
    },
    "Rank": 78010,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d03"
    },
    "Rank": 78011,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d04"
    },
    "Rank": 78020,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d05"
    },
    "Rank": 78047,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d06"
    },
    "Rank": 78054,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,HBT Medical College and Dr R N Cooper Municipal General Hospital, Bhaktivedanta Swami Rd, JVPD Schem, Maharashtra, 400056",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d07"
    },
    "Rank": 78055,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d08"
    },
    "Rank": 78092,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d09"
    },
    "Rank": 78098,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Dada Dev Matri Avum Shishu Chikitsalaya, ,Dabri, New Delhi-110045, Delhi (NCT), 110045",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d0a"
    },
    "Rank": 78133,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. Sports Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d0b"
    },
    "Rank": 78139,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d0c"
    },
    "Rank": 78144,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d0d"
    },
    "Rank": 78161,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Aralaguppe Mallegowda District Hospital,,AZAD PARK CHIKKAMAGALURU, Karnataka, 577101",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d0e"
    },
    "Rank": 78162,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Doon Medical College, Dehradun,Govt. Doon Medical College Dehrakhas, Patel Nagar Dehradun 248001, Uttarakhand, 248001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d0f"
    },
    "Rank": 78168,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d10"
    },
    "Rank": 78170,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d11"
    },
    "Rank": 78178,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d12"
    },
    "Rank": 78204,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d13"
    },
    "Rank": 78208,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d14"
    },
    "Rank": 78238,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d15"
    },
    "Rank": 78267,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d16"
    },
    "Rank": 78268,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d17"
    },
    "Rank": 78299,
    "Allotted Quota": "All India",
    "Allotted Institute": "All  India Institute of  Hygiene and Public Health,110, C. R  Avenue, Kolkata, West Bengal, 700073",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d18"
    },
    "Rank": 78324,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d19"
    },
    "Rank": 78329,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d1a"
    },
    "Rank": 78354,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d1b"
    },
    "Rank": 78360,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "J.L.N. Main Hospital and Research Centre, ,Bhilai Steel Plant Sector-9, Bhilai, C.G., Chhattisgarh, 490009",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d1c"
    },
    "Rank": 78371,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d1d"
    },
    "Rank": 78415,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d1e"
    },
    "Rank": 78422,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d1f"
    },
    "Rank": 78436,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d20"
    },
    "Rank": 78457,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d21"
    },
    "Rank": 78519,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d22"
    },
    "Rank": 78556,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d23"
    },
    "Rank": 78600,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "M.M. Institute of Medical Sciences and Research, Ambala,M.M. Institute of Medical Sciences and Research, Ambala, Haryana, 133207",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d24"
    },
    "Rank": 78602,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d25"
    },
    "Rank": 78603,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d26"
    },
    "Rank": 78609,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d27"
    },
    "Rank": 78622,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d28"
    },
    "Rank": 78635,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Central Super Speciality Hospital,\n,mdcsshecr@gmail.com, Bihar, 800001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d29"
    },
    "Rank": 78637,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d2a"
    },
    "Rank": 78648,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d2b"
    },
    "Rank": 78652,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d2c"
    },
    "Rank": 78679,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d2d"
    },
    "Rank": 78683,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d2e"
    },
    "Rank": 78706,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d2f"
    },
    "Rank": 78707,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d30"
    },
    "Rank": 78715,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d31"
    },
    "Rank": 78723,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d32"
    },
    "Rank": 78730,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d33"
    },
    "Rank": 78735,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d34"
    },
    "Rank": 78752,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d35"
    },
    "Rank": 78755,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d36"
    },
    "Rank": 78811,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d37"
    },
    "Rank": 78819,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d38"
    },
    "Rank": 78858,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d39"
    },
    "Rank": 78878,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kozhikode,Government Medical College Kozhikode principalmcc@gmail.com, Kerala, 673008",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d3a"
    },
    "Rank": 78881,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d3b"
    },
    "Rank": 78930,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d3c"
    },
    "Rank": 78958,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d3d"
    },
    "Rank": 78971,
    "Allotted Quota": "All India",
    "Allotted Institute": "GMC Bharat Ratna Late Shri Atal Bihari Vajpayee Memorial Medical College, Chhattisgarh ,BHARAT RATNA LATE SHRI ATAL BIHARI VAJPAYEE MEMORIAL MEDICAL COLLEGE,\nPENDRI, RAJNANDGAON, CHHATTIS, Chhattisgarh, 491441",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d3e"
    },
    "Rank": 78984,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Super Facility Hospital, Distt.-Azamgarh, Uttar Pradesh,Chakrapanpur, Kanaila, Azamgarh, Uttar Pradesh, 276128",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d3f"
    },
    "Rank": 78987,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d40"
    },
    "Rank": 78997,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital, ,Kharvad medan Sipor road Vadnagar, Gujarat, 384355",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d41"
    },
    "Rank": 79001,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d42"
    },
    "Rank": 79050,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d43"
    },
    "Rank": 79057,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d44"
    },
    "Rank": 79061,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d45"
    },
    "Rank": 79095,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d46"
    },
    "Rank": 79096,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Hospital,,Government Hospital Gandhi Nagar Jammu, Jammu And Kashmir, 180004",
    "Course": "(NBEMS) ANAESTHESIOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d47"
    },
    "Rank": 79100,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d48"
    },
    "Rank": 79113,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College, Kolhapur,R.K. Nagar Road, Shenda Park, Kolhapur, Maharashtra, Maharashtra, 416012",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d49"
    },
    "Rank": 79127,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d4a"
    },
    "Rank": 79130,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d4b"
    },
    "Rank": 79133,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d4c"
    },
    "Rank": 79138,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d4d"
    },
    "Rank": 79146,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d4e"
    },
    "Rank": 79149,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d4f"
    },
    "Rank": 79153,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d50"
    },
    "Rank": 79172,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d51"
    },
    "Rank": 79183,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dharwad,dsdharwad at gmail dot com, Karnataka, 580001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d52"
    },
    "Rank": 79191,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d53"
    },
    "Rank": 79196,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d54"
    },
    "Rank": 79201,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nalgonda,Office of the Principal, Government Medical College Nalgonda, Near RTC Bus Stand, Gollaguda,\nNalgon, Telangana, 508001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d55"
    },
    "Rank": 79207,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District General Hospital,,Central Jail Road, Rajamahendravaram, East Godavari District, Andhra Pradesh, 533105",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d56"
    },
    "Rank": 79228,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES,CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, Chhattisgarh, 495001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d57"
    },
    "Rank": 79230,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalpana Chawla Govt Medical College, Karnal, Haryana,Kalpana Chawla Government Medical College Model Town Karnal 132001 Haryana, Haryana, 132001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d58"
    },
    "Rank": 79241,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d59"
    },
    "Rank": 79265,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d5a"
    },
    "Rank": 79266,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d5b"
    },
    "Rank": 79294,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d5c"
    },
    "Rank": 79311,
    "Allotted Quota": "All India",
    "Allotted Institute": "NSC Government Medical College, Khandwa (MP),LUV KUSH NAGAR,MUNDI NAGAR, KHANDWA, Madhya Pradesh, 450001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d5d"
    },
    "Rank": 79315,
    "Allotted Quota": "All India",
    "Allotted Institute": "Post Graduate Institute of Child Health, Noida.,Post Graduate Institute of Child Health,SECTOR-30, NOIDA, GAUTAM Buddha Nagar, Uttar Pradesh, 201303",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d5e"
    },
    "Rank": 79316,
    "Allotted Quota": "All India",
    "Allotted Institute": "MALDA MEDICAL COLLEGE,MALDA MEDICAL COLLEGE, West Bengal, 732101",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d5f"
    },
    "Rank": 79328,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d60"
    },
    "Rank": 79362,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d61"
    },
    "Rank": 79393,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d62"
    },
    "Rank": 79404,
    "Allotted Quota": "All India",
    "Allotted Institute": "VARDHMAN INSTITUTE OF MEDICAL SCIENCES, NALANDA,BMIMS CAMPUS, PAWAPURI, NALANDA, Bihar, 803115",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d63"
    },
    "Rank": 79414,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d64"
    },
    "Rank": 79416,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d65"
    },
    "Rank": 79475,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur and Associated Group of Hospitals Shri Haridev Joshi Hospital,,Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur, Rajasthan, 314001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d66"
    },
    "Rank": 79488,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d67"
    },
    "Rank": 79568,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d68"
    },
    "Rank": 79570,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute of Medical Sciences Srikakulam,Government Medical College Srikakulam\nBalaga Srikakulam Town Srikakulam District, Andhra Pradesh, 532001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d69"
    },
    "Rank": 79581,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Autonomous State Medical College and Allied Pandit Ram Prasad Bismil Memorial Hospital,\n,ASMC, JIGNERA, kanth road, shahjahanpur, Uttar Pradesh, 242001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d6a"
    },
    "Rank": 79583,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d6b"
    },
    "Rank": 79625,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d6c"
    },
    "Rank": 79636,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d6d"
    },
    "Rank": 79669,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d6e"
    },
    "Rank": 79698,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d6f"
    },
    "Rank": 79716,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d70"
    },
    "Rank": 79758,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d71"
    },
    "Rank": 79784,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d72"
    },
    "Rank": 79816,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d73"
    },
    "Rank": 79824,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d74"
    },
    "Rank": 79829,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d75"
    },
    "Rank": 79834,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d76"
    },
    "Rank": 79838,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d77"
    },
    "Rank": 79843,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,OLD SABJI MANDI HINDUAN GATE KARAULI, Rajasthan, 322241",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d78"
    },
    "Rank": 79849,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d79"
    },
    "Rank": 79860,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d7a"
    },
    "Rank": 79900,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d7b"
    },
    "Rank": 79907,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d7c"
    },
    "Rank": 79929,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d7d"
    },
    "Rank": 79947,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d7e"
    },
    "Rank": 79983,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d7f"
    },
    "Rank": 80057,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d80"
    },
    "Rank": 80083,
    "Allotted Quota": "All India",
    "Allotted Institute": "LATE SHRI LAKHI RAM AGRAWAL MEMORIAL GOVT.MEDICAL COLLEGE,LATE SHRI LAKHI RAM AGRAWAL MEMORIAL GOVT.MEDICAL COLLEGE, Chhattisgarh, 496001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d81"
    },
    "Rank": 80098,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "FAKIR MOHAN MEDICAL COLLEGE AND HOSPITAL ,\nOrissa,AT-Remuna Post-Remuna Dist-Balasore, Odisha, 756019",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d82"
    },
    "Rank": 80103,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d83"
    },
    "Rank": 80120,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d84"
    },
    "Rank": 80124,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d85"
    },
    "Rank": 80125,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d86"
    },
    "Rank": 80128,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d87"
    },
    "Rank": 80140,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d88"
    },
    "Rank": 80165,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d89"
    },
    "Rank": 80167,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d8a"
    },
    "Rank": 80184,
    "Allotted Quota": "All India",
    "Allotted Institute": "GADAG INSTITUTE OF MEDICAL SCIENCES GADAG,Gadag Institute of Medical Sciences Mallasamudra Gadag-582103, Karnataka, 582103",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d8b"
    },
    "Rank": 80214,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d8c"
    },
    "Rank": 80236,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d8d"
    },
    "Rank": 80244,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d8e"
    },
    "Rank": 80246,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d8f"
    },
    "Rank": 80261,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d90"
    },
    "Rank": 80268,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d91"
    },
    "Rank": 80278,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d92"
    },
    "Rank": 80280,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d93"
    },
    "Rank": 80302,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d94"
    },
    "Rank": 80305,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d95"
    },
    "Rank": 80314,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. Sports Medicine",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d96"
    },
    "Rank": 80320,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI MEDICAL COLLEGE KALWA THANE,RAJIV GANDHI MEDICAL COLLEGE,THANE BELAPUR ROAD, KALWA, THANE, Maharashtra, 400605",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d97"
    },
    "Rank": 80329,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d98"
    },
    "Rank": 80330,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Vasantrao Naik Govt Medical College Yavatmal,Shri Vasantrao Naik Govt Medical College Yavatmal, Maharashtra, 445001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d99"
    },
    "Rank": 80343,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d9a"
    },
    "Rank": 80351,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d9b"
    },
    "Rank": 80354,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d9c"
    },
    "Rank": 80369,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d9d"
    },
    "Rank": 80379,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d9e"
    },
    "Rank": 80387,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07d9f"
    },
    "Rank": 80405,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Coochbehar Govt Medical College and Hospital, West Bengal,Vivekananda Street, Pilkhana, Cooch Behar, WB-736101, West Bengal, 736101",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07da0"
    },
    "Rank": 80419,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Bundelkhand Medical College Sagar M.P.,Govt. Bundelkhand Medical College Sagar M.P., Madhya Pradesh, 470001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07da1"
    },
    "Rank": 80431,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07da2"
    },
    "Rank": 80442,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07da3"
    },
    "Rank": 80461,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07da4"
    },
    "Rank": 80472,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07da5"
    },
    "Rank": 80510,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07da6"
    },
    "Rank": 80576,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07da7"
    },
    "Rank": 80581,
    "Allotted Quota": "All India",
    "Allotted Institute": "NSC Government Medical College, Khandwa (MP),LUV KUSH NAGAR,MUNDI NAGAR, KHANDWA, Madhya Pradesh, 450001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07da8"
    },
    "Rank": 80585,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07da9"
    },
    "Rank": 80598,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07daa"
    },
    "Rank": 80618,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dab"
    },
    "Rank": 80647,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "ESIC Medical College Hospital and Super Specialty Hospital, ,SR Nagar, Sanathnagar, Telangana, 500038",
    "Course": "(NBEMS) FAMILY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dac"
    },
    "Rank": 80653,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dad"
    },
    "Rank": 80684,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dae"
    },
    "Rank": 80698,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07daf"
    },
    "Rank": 80702,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07db0"
    },
    "Rank": 80704,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07db1"
    },
    "Rank": 80716,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07db2"
    },
    "Rank": 80732,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubnagar,Government Medical College, Yedira, Mahabubnagar, Telangana state, Telangana, 509002",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07db3"
    },
    "Rank": 80735,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07db4"
    },
    "Rank": 80740,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07db5"
    },
    "Rank": 80745,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07db6"
    },
    "Rank": 80776,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07db7"
    },
    "Rank": 80781,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sri Jayachamarajendra General Hospital, Hassan.Dist. Karnataka,Sri Jayachamarajendra General Hospital, ARSIKERE,Hassan.Dist. Karnataka, Karnataka, 573103",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07db8"
    },
    "Rank": 80782,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07db9"
    },
    "Rank": 80800,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dba"
    },
    "Rank": 80806,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital ,  DARJEELING, West Bengal,20, BAZAAR CART ROAD, DARJEELING, West Bengal, 734101",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dbb"
    },
    "Rank": 80816,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dbc"
    },
    "Rank": 80821,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dbd"
    },
    "Rank": 80833,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute Of Medical Sciences, Kalaburagi,VEERESH NAGAR SEDAM ROAD KALABURAGI, Karnataka, 585105",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dbe"
    },
    "Rank": 80847,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dbf"
    },
    "Rank": 80856,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dc0"
    },
    "Rank": 80863,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dc1"
    },
    "Rank": 80873,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dc2"
    },
    "Rank": 80888,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dc3"
    },
    "Rank": 80910,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dc4"
    },
    "Rank": 80912,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dc5"
    },
    "Rank": 80923,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dc6"
    },
    "Rank": 80959,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dc7"
    },
    "Rank": 80987,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dc8"
    },
    "Rank": 81004,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dc9"
    },
    "Rank": 81063,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dca"
    },
    "Rank": 81077,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dcb"
    },
    "Rank": 81106,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Bhausaheb Hire Government Medical College,Sri Bhausaheb Hire Government Medical College,Dhule, Maharashtra, 424311",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dcc"
    },
    "Rank": 81112,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dcd"
    },
    "Rank": 81139,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dce"
    },
    "Rank": 81154,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dcf"
    },
    "Rank": 81155,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine & Sagore Dutta Hospital,College of Medicine & Sagore Dutta Hospital, West Bengal, 700058",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dd0"
    },
    "Rank": 81161,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dd1"
    },
    "Rank": 81172,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dd2"
    },
    "Rank": 81194,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dd3"
    },
    "Rank": 81207,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dd4"
    },
    "Rank": 81214,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dd5"
    },
    "Rank": 81215,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dd6"
    },
    "Rank": 81217,
    "Allotted Quota": "All India",
    "Allotted Institute": "Guru Gobind Singh Medical College,Guru Gobind Singh Medical College, Punjab, 151203",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dd7"
    },
    "Rank": 81224,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dd8"
    },
    "Rank": 81226,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dd9"
    },
    "Rank": 81244,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dda"
    },
    "Rank": 81248,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ddb"
    },
    "Rank": 81258,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ddc"
    },
    "Rank": 81270,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ddd"
    },
    "Rank": 81275,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dde"
    },
    "Rank": 81283,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ddf"
    },
    "Rank": 81294,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07de0"
    },
    "Rank": 81301,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07de1"
    },
    "Rank": 81303,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07de2"
    },
    "Rank": 81308,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07de3"
    },
    "Rank": 81318,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07de4"
    },
    "Rank": 81321,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07de5"
    },
    "Rank": 81333,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Jammu and Kashmir\n,DUDERHAMA GANDERBAL, Jammu And Kashmir, 191201",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07de6"
    },
    "Rank": 81340,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College & Hospital, Faridabad,NH3 NIT FARIDABAD, Haryana, 121001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07de7"
    },
    "Rank": 81347,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07de8"
    },
    "Rank": 81435,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07de9"
    },
    "Rank": 81448,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dea"
    },
    "Rank": 81453,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sher-I-Kashmir Institute of Medical Sciences, ,Principal SKIMS Medical College Hospital Bemina Srinagar, Jammu And Kashmir, 190018",
    "Course": "(NBEMS) PHYSIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07deb"
    },
    "Rank": 81458,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sheth Vadilal Sarabhai General Hospital and Sheth Chinai Prasuti Hospital, Gujarat,SHETH VADILAL SARABHAI GENERAL HOSPITAL AND SHETH CHINAI PRASUTI HOSPITAL ELLISBRIDGE AHMEDABAD, Gujarat, 380006",
    "Course": "(NBEMS) MICROBIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dec"
    },
    "Rank": 81467,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ded"
    },
    "Rank": 81468,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "DIPLOMA IN PUBLIC HEALTH",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dee"
    },
    "Rank": 81483,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07def"
    },
    "Rank": 81484,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07df0"
    },
    "Rank": 81485,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07df1"
    },
    "Rank": 81508,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07df2"
    },
    "Rank": 81515,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07df3"
    },
    "Rank": 81542,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07df4"
    },
    "Rank": 81549,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07df5"
    },
    "Rank": 81575,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07df6"
    },
    "Rank": 81599,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute Of Medical Sciences, Kalaburagi,VEERESH NAGAR SEDAM ROAD KALABURAGI, Karnataka, 585105",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07df7"
    },
    "Rank": 81602,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07df8"
    },
    "Rank": 81609,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07df9"
    },
    "Rank": 81647,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dfa"
    },
    "Rank": 81670,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dfb"
    },
    "Rank": 81693,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dfc"
    },
    "Rank": 81696,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dfd"
    },
    "Rank": 81724,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dfe"
    },
    "Rank": 81737,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07dff"
    },
    "Rank": 81746,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e00"
    },
    "Rank": 81758,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e01"
    },
    "Rank": 81764,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Bundelkhand Medical College Sagar M.P.,Govt. Bundelkhand Medical College Sagar M.P., Madhya Pradesh, 470001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e02"
    },
    "Rank": 81797,
    "Allotted Quota": "All India",
    "Allotted Institute": "ACSR GOVERNMENT MEDICAL COLLEGE,NELLORE,Opp AC Subbareddy Stadium\nDargamitta Nellore, Andhra Pradesh, 524004",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e03"
    },
    "Rank": 81807,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e04"
    },
    "Rank": 81832,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e05"
    },
    "Rank": 81839,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e06"
    },
    "Rank": 81853,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e07"
    },
    "Rank": 81869,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e08"
    },
    "Rank": 81903,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e09"
    },
    "Rank": 81905,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e0a"
    },
    "Rank": 81956,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e0b"
    },
    "Rank": 82000,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e0c"
    },
    "Rank": 82003,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e0d"
    },
    "Rank": 82012,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e0e"
    },
    "Rank": 82024,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e0f"
    },
    "Rank": 82031,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e10"
    },
    "Rank": 82033,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajmata Shrimati Devendra Kumari Singhdeo Government Medical College, Ambikapur,Kanyaparisar  Road Gangapur Ambikapur District Surguja, Chhattisgarh, 497001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e11"
    },
    "Rank": 82036,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e12"
    },
    "Rank": 82038,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e13"
    },
    "Rank": 82066,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Vishnuprasad Nandrai Desai Municipal General Hospital, Maharashtra,11 TH GOLIBAR ROAD TPS,SANTACRUZ EAST, Maharashtra, 400055",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e14"
    },
    "Rank": 82084,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e15"
    },
    "Rank": 82100,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e16"
    },
    "Rank": 82104,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e17"
    },
    "Rank": 82136,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e18"
    },
    "Rank": 82148,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e19"
    },
    "Rank": 82154,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e1a"
    },
    "Rank": 82155,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College,Tumuki, Bihaguri, Tezpur, Sonitpur, Assam, Assam, 784010",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e1b"
    },
    "Rank": 82157,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e1c"
    },
    "Rank": 82177,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e1d"
    },
    "Rank": 82183,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e1e"
    },
    "Rank": 82194,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e1f"
    },
    "Rank": 82197,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e20"
    },
    "Rank": 82209,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e21"
    },
    "Rank": 82222,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e22"
    },
    "Rank": 82252,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e23"
    },
    "Rank": 82279,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e24"
    },
    "Rank": 82332,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e25"
    },
    "Rank": 82386,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAICHUR INSTITUTE OF MEDICAL SCIENCES,RAICHUR INSTITUTE OF MEDICAL\nSCIENCES, Karnataka, 584102",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e26"
    },
    "Rank": 82412,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e27"
    },
    "Rank": 82417,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e28"
    },
    "Rank": 82441,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e29"
    },
    "Rank": 82472,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e2a"
    },
    "Rank": 82473,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e2b"
    },
    "Rank": 82494,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e2c"
    },
    "Rank": 82527,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e2d"
    },
    "Rank": 82538,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e2e"
    },
    "Rank": 82575,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e2f"
    },
    "Rank": 82594,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AZAMGARH,Chakrapan Pur, Azamgarh (U.P.),\nUttar Pradesh, 276128",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e30"
    },
    "Rank": 82631,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e31"
    },
    "Rank": 82652,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e32"
    },
    "Rank": 82656,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e33"
    },
    "Rank": 82666,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e34"
    },
    "Rank": 82703,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Medical College for Women, Khanpur Kalan Sonepat,BPS Govt. Medical College for Women, Khanpur Kalan Sonepat, Haryana, 131305",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e35"
    },
    "Rank": 82704,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e36"
    },
    "Rank": 82713,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e37"
    },
    "Rank": 82716,
    "Allotted Quota": "All India",
    "Allotted Institute": "GANDHI MEDICAL COLLEGE,GANDHI MEDICAL COLLEGE, Telangana, 500003",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e38"
    },
    "Rank": 82749,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e39"
    },
    "Rank": 82754,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e3a"
    },
    "Rank": 82777,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e3b"
    },
    "Rank": 82779,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pimpri Chichwad Municipal Corporation Postgraduate Institute Y.C.M.Hospital Pimpri,Pune 18,Dean Office, Y.C.M.Hospital, Sant Tukaramnagar Pimpri, Pune, Maharashtra, 411018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e3c"
    },
    "Rank": 82801,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e3d"
    },
    "Rank": 82823,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e3e"
    },
    "Rank": 82844,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Gondia,Nehru Chowk, KTS General Hospital Campus, Govt. Medical College, Gondia. 441601. Maharashtra., Maharashtra, 441601",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e3f"
    },
    "Rank": 82858,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e40"
    },
    "Rank": 82864,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e41"
    },
    "Rank": 82895,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e42"
    },
    "Rank": 82914,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e43"
    },
    "Rank": 82917,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karnataka,MG Road, Karnataka, 581301",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e44"
    },
    "Rank": 82919,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e45"
    },
    "Rank": 82929,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e46"
    },
    "Rank": 82934,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e47"
    },
    "Rank": 82949,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e48"
    },
    "Rank": 83001,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e49"
    },
    "Rank": 83011,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e4a"
    },
    "Rank": 83027,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e4b"
    },
    "Rank": 83035,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e4c"
    },
    "Rank": 83048,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e4d"
    },
    "Rank": 83063,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e4e"
    },
    "Rank": 83066,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e4f"
    },
    "Rank": 83091,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e50"
    },
    "Rank": 83099,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e51"
    },
    "Rank": 83126,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e52"
    },
    "Rank": 83148,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e53"
    },
    "Rank": 83153,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e54"
    },
    "Rank": 83186,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e55"
    },
    "Rank": 83190,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e56"
    },
    "Rank": 83198,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaheed Hasan Khan Mewati Government Medical College,Shaheed Hasan Khan Mewati Government Medical College, Haryana, 122107",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e57"
    },
    "Rank": 83201,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital,\n,paddoc road, near majewadi gate, junagadh, Gujarat, 362001",
    "Course": "(NBEMS) MICROBIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e58"
    },
    "Rank": 83208,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e59"
    },
    "Rank": 83236,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e5a"
    },
    "Rank": 83240,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e5b"
    },
    "Rank": 83244,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e5c"
    },
    "Rank": 83293,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e5d"
    },
    "Rank": 83321,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e5e"
    },
    "Rank": 83341,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "The National Institute of Health and Family Welfare,The National Institute of Health and Family Welfare, Delhi (NCT), 110067",
    "Course": "M.D. (COMMUNITY HEALTH  and ADMN.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e5f"
    },
    "Rank": 83348,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e60"
    },
    "Rank": 83349,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute Of Medical Sciences, Kalaburagi,VEERESH NAGAR SEDAM ROAD KALABURAGI, Karnataka, 585105",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e61"
    },
    "Rank": 83383,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e62"
    },
    "Rank": 83425,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e63"
    },
    "Rank": 83444,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e64"
    },
    "Rank": 83464,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e65"
    },
    "Rank": 83467,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e66"
    },
    "Rank": 83473,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e67"
    },
    "Rank": 83512,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e68"
    },
    "Rank": 83513,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e69"
    },
    "Rank": 83520,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU,CHENGALPATTU MEDICAL COLLEGE, CHENGALPATTU, Tamil Nadu, 603001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e6a"
    },
    "Rank": 83524,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e6b"
    },
    "Rank": 83540,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e6c"
    },
    "Rank": 83564,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e6d"
    },
    "Rank": 83575,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e6e"
    },
    "Rank": 83584,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e6f"
    },
    "Rank": 83595,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e70"
    },
    "Rank": 83602,
    "Allotted Quota": "All India",
    "Allotted Institute": "S.V. Medical College, Tirupathi ,S.V. Medical College, Tirupathi, Andhra Pradesh, 517507",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e71"
    },
    "Rank": 83611,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e72"
    },
    "Rank": 83626,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e73"
    },
    "Rank": 83628,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e74"
    },
    "Rank": 83630,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e75"
    },
    "Rank": 83646,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e76"
    },
    "Rank": 83670,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalpana Chawla Govt Medical College, Karnal, Haryana,Kalpana Chawla Government Medical College Model Town Karnal 132001 Haryana, Haryana, 132001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e77"
    },
    "Rank": 83702,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e78"
    },
    "Rank": 83716,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e79"
    },
    "Rank": 83741,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e7a"
    },
    "Rank": 83742,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e7b"
    },
    "Rank": 83758,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e7c"
    },
    "Rank": 83778,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e7d"
    },
    "Rank": 83780,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e7e"
    },
    "Rank": 83796,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e7f"
    },
    "Rank": 83825,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e80"
    },
    "Rank": 83869,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e81"
    },
    "Rank": 83901,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e82"
    },
    "Rank": 83905,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e83"
    },
    "Rank": 83963,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e84"
    },
    "Rank": 84024,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e85"
    },
    "Rank": 84032,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Associated Hospital Government Medical College, ,Government Medical College Chack Sajjan Kathua, Jammu And Kashmir, 184101",
    "Course": "(NBEMS) PHARMACOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e86"
    },
    "Rank": 84049,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaheed Hasan Khan Mewati Government Medical College,Shaheed Hasan Khan Mewati Government Medical College, Haryana, 122107",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e87"
    },
    "Rank": 84129,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Medical College for Women, Khanpur Kalan Sonepat,BPS Govt. Medical College for Women, Khanpur Kalan Sonepat, Haryana, 131305",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e88"
    },
    "Rank": 84134,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e89"
    },
    "Rank": 84139,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Siddipet,SURVEY NO 54  GOVERNMENT MEDICAL COLLEGE  ENSANPALLY  SIDDIPET   502114, Telangana, 502114",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e8a"
    },
    "Rank": 84150,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e8b"
    },
    "Rank": 84156,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e8c"
    },
    "Rank": 84163,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e8d"
    },
    "Rank": 84184,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e8e"
    },
    "Rank": 84193,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e8f"
    },
    "Rank": 84211,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e90"
    },
    "Rank": 84237,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e91"
    },
    "Rank": 84293,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e92"
    },
    "Rank": 84297,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e93"
    },
    "Rank": 84338,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e94"
    },
    "Rank": 84347,
    "Allotted Quota": "All India",
    "Allotted Institute": "Calcutta School Of Tropical Medicine,Calcutta School Of Tropical Medicine, West Bengal, 700073",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e95"
    },
    "Rank": 84363,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e96"
    },
    "Rank": 84379,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e97"
    },
    "Rank": 84382,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e98"
    },
    "Rank": 84384,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e99"
    },
    "Rank": 84407,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e9a"
    },
    "Rank": 84426,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jawaharlal Institute of Medical Sciences, Manipur,Jawaharlal Nehru Institute of Medical Sciences, Porompat, Imphal -East, Manipur-795005, Manipur, 795005",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e9b"
    },
    "Rank": 84432,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e9c"
    },
    "Rank": 84437,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e9d"
    },
    "Rank": 84449,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e9e"
    },
    "Rank": 84453,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07e9f"
    },
    "Rank": 84484,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ea0"
    },
    "Rank": 84558,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ea1"
    },
    "Rank": 84579,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ea2"
    },
    "Rank": 84593,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ea3"
    },
    "Rank": 84596,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ea4"
    },
    "Rank": 84640,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ea5"
    },
    "Rank": 84658,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ea6"
    },
    "Rank": 84704,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ea7"
    },
    "Rank": 84722,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ea8"
    },
    "Rank": 84748,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ea9"
    },
    "Rank": 84750,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07eaa"
    },
    "Rank": 84775,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07eab"
    },
    "Rank": 84781,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07eac"
    },
    "Rank": 84802,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ead"
    },
    "Rank": 84812,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07eae"
    },
    "Rank": 84833,
    "Allotted Quota": "All India",
    "Allotted Institute": "BIDAR INSTITUTE OF MEDICAL SCIENCES, BIDAR,BIDAR INSTITUTE OF MEDICAL SCIENCES, BIDAR, Karnataka, 585401",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07eaf"
    },
    "Rank": 84838,
    "Allotted Quota": "All India",
    "Allotted Institute": "Chamarajanagar Institute of Medical Sciences, Karnataka,CHAMARAJANAGAR INSTITUTE OF MEDICAL SCIENCES, SY. NO 124, YEDAPURA VILLAGE KASABA HOBLI, CHAMARAJANA, Karnataka, 571313",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07eb0"
    },
    "Rank": 84840,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07eb1"
    },
    "Rank": 84845,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07eb2"
    },
    "Rank": 84866,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07eb3"
    },
    "Rank": 84878,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07eb4"
    },
    "Rank": 84896,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07eb5"
    },
    "Rank": 84915,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07eb6"
    },
    "Rank": 84970,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH,GOVT. MEDICAL COLLEGE AND HOSPITAL, CHANDIGARH, Chandigarh, 160030",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07eb7"
    },
    "Rank": 84985,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07eb8"
    },
    "Rank": 85034,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07eb9"
    },
    "Rank": 85059,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07eba"
    },
    "Rank": 85081,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government General Hospital, ,GOVERNMENT GENERAL HOSPITAL, ALANPUR LINK ROAD SAWAI MADHOPUR 322001, Rajasthan, 322001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ebb"
    },
    "Rank": 85088,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. Medical College, ,Govt. Medical College, B-5 Medical Enclave, Maheshpura Bakshi Nagar, Jammu, Jammu And Kashmir, 180001",
    "Course": "(NBEMS) BIOCHEMISTRY",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ebc"
    },
    "Rank": 85089,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ebd"
    },
    "Rank": 85092,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Autonomous State Medical college Society and S.N.M District Hospital, ,DAULATTAPUR JALESAR ROAD NARKHI FIROZABAD, Uttar Pradesh, 283203",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ebe"
    },
    "Rank": 85100,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ebf"
    },
    "Rank": 85107,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ec0"
    },
    "Rank": 85113,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ec1"
    },
    "Rank": 85114,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ec2"
    },
    "Rank": 85127,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ec3"
    },
    "Rank": 85128,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES, KADAPA),GOVERNMENT MEDICAL COLLEGE KADAPA (FORMERLY RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,\nKADAPA), Andhra Pradesh, 516002",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ec4"
    },
    "Rank": 85129,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ec5"
    },
    "Rank": 85148,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ec6"
    },
    "Rank": 85154,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ec7"
    },
    "Rank": 85158,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ec8"
    },
    "Rank": 85163,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ec9"
    },
    "Rank": 85184,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07eca"
    },
    "Rank": 85198,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ecb"
    },
    "Rank": 85205,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ecc"
    },
    "Rank": 85238,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ecd"
    },
    "Rank": 85241,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ece"
    },
    "Rank": 85264,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Cuddalore District,Govt. Medical College and Hospital, Cuddalore District Erstwhile Rajah Muthiah Medical College, Tamil Nadu, 608002",
    "Course": "DIPLOMA IN ORTHOPAEDICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ecf"
    },
    "Rank": 85268,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ed0"
    },
    "Rank": 85274,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ed1"
    },
    "Rank": 85275,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ed2"
    },
    "Rank": 85285,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ed3"
    },
    "Rank": 85293,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ed4"
    },
    "Rank": 85308,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ed5"
    },
    "Rank": 85309,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ed6"
    },
    "Rank": 85318,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ed7"
    },
    "Rank": 85330,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ed8"
    },
    "Rank": 85345,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ed9"
    },
    "Rank": 85346,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07eda"
    },
    "Rank": 85355,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07edb"
    },
    "Rank": 85376,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07edc"
    },
    "Rank": 85381,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07edd"
    },
    "Rank": 85383,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ede"
    },
    "Rank": 85386,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07edf"
    },
    "Rank": 85404,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. District Civil Hospital, ,DIS GOVT HOSPITAL, SRI GANGANAGAR, Rajasthan, 335001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ee0"
    },
    "Rank": 85406,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ee1"
    },
    "Rank": 85440,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ee2"
    },
    "Rank": 85460,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ee3"
    },
    "Rank": 85471,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ee4"
    },
    "Rank": 85479,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ee5"
    },
    "Rank": 85490,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Government Medical College, Vidisha,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA MP, Madhya Pradesh, 464001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ee6"
    },
    "Rank": 85495,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ee7"
    },
    "Rank": 85552,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ee8"
    },
    "Rank": 85581,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ee9"
    },
    "Rank": 85589,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Civil Hospital GURDASPUR, Punjab,smodhgsp@gmail.com, Punjab, 143521",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07eea"
    },
    "Rank": 85590,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07eeb"
    },
    "Rank": 85600,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07eec"
    },
    "Rank": 85608,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07eed"
    },
    "Rank": 85609,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Doon Medical College, Dehradun,Govt. Doon Medical College Dehrakhas, Patel Nagar Dehradun 248001, Uttarakhand, 248001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07eee"
    },
    "Rank": 85623,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07eef"
    },
    "Rank": 85650,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ef0"
    },
    "Rank": 85653,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ef1"
    },
    "Rank": 85661,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ef2"
    },
    "Rank": 85678,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Associated Hospital Government Medical College, ,Government Medical College Chack Sajjan Kathua, Jammu And Kashmir, 184101",
    "Course": "(NBEMS) PHARMACOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ef3"
    },
    "Rank": 85693,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Vasantrao Naik Govt Medical College Yavatmal,Shri Vasantrao Naik Govt Medical College Yavatmal, Maharashtra, 445001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ef4"
    },
    "Rank": 85694,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ef5"
    },
    "Rank": 85751,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ef6"
    },
    "Rank": 85812,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ef7"
    },
    "Rank": 85841,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ef8"
    },
    "Rank": 85852,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ef9"
    },
    "Rank": 85856,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karnataka,MG Road, Karnataka, 581301",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07efa"
    },
    "Rank": 85859,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07efb"
    },
    "Rank": 85861,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07efc"
    },
    "Rank": 85889,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07efd"
    },
    "Rank": 85892,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07efe"
    },
    "Rank": 85904,
    "Allotted Quota": "All India",
    "Allotted Institute": "Midnapore Medical College and Hospital,Midnapore Medical College and Hospital, West Bengal, 721101",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07eff"
    },
    "Rank": 85954,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f00"
    },
    "Rank": 85962,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f01"
    },
    "Rank": 86015,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f02"
    },
    "Rank": 86023,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f03"
    },
    "Rank": 86026,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f04"
    },
    "Rank": 86029,
    "Allotted Quota": "All India",
    "Allotted Institute": "M. P. SHAH GOVERNMENT MEDICAL COLLEGE,M. P. SHAH GOVERNMENT MEDICAL COLLEGE, Gujarat, 361008",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f05"
    },
    "Rank": 86037,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dholpur,Old City Road Dholpur, Rajasthan, 328001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f06"
    },
    "Rank": 86053,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f07"
    },
    "Rank": 86059,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f08"
    },
    "Rank": 86062,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f09"
    },
    "Rank": 86076,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f0a"
    },
    "Rank": 86092,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f0b"
    },
    "Rank": 86104,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f0c"
    },
    "Rank": 86106,
    "Allotted Quota": "All India",
    "Allotted Institute": "Murshidabad Medical College and Hospital,Station Road, Berhampore, Murshidabad, West Bengal, 742101",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f0d"
    },
    "Rank": 86163,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f0e"
    },
    "Rank": 86165,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,Opposite to Shivajinagar Bus stop, Shivajinagar, Bengaluru., Karnataka, 560001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f0f"
    },
    "Rank": 86192,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f10"
    },
    "Rank": 86229,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f11"
    },
    "Rank": 86235,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f12"
    },
    "Rank": 86271,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f13"
    },
    "Rank": 86298,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f14"
    },
    "Rank": 86322,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f15"
    },
    "Rank": 86338,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. Mahatma Gandhi District Hospital, ,COLLETOR ATE CHORAHA NEAR MOHAN COLONY BANSWARA, Rajasthan, 327001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f16"
    },
    "Rank": 86380,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f17"
    },
    "Rank": 86401,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f18"
    },
    "Rank": 86412,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f19"
    },
    "Rank": 86419,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f1a"
    },
    "Rank": 86543,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f1b"
    },
    "Rank": 86548,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f1c"
    },
    "Rank": 86572,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f1d"
    },
    "Rank": 86580,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f1e"
    },
    "Rank": 86607,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f1f"
    },
    "Rank": 86647,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f20"
    },
    "Rank": 86661,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital, ,GMERS Medical College and General hospital,Old TB Campus, gotri road, Vadodara, Gujarat, 390021",
    "Course": "(NBEMS) MICROBIOLOGY",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f21"
    },
    "Rank": 86685,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f22"
    },
    "Rank": 86686,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f23"
    },
    "Rank": 86689,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f24"
    },
    "Rank": 86732,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f25"
    },
    "Rank": 86843,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f26"
    },
    "Rank": 86852,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f27"
    },
    "Rank": 86888,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f28"
    },
    "Rank": 86907,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f29"
    },
    "Rank": 86937,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f2a"
    },
    "Rank": 86948,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f2b"
    },
    "Rank": 86974,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f2c"
    },
    "Rank": 86996,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f2d"
    },
    "Rank": 87009,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f2e"
    },
    "Rank": 87018,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f2f"
    },
    "Rank": 87038,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f30"
    },
    "Rank": 87047,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f31"
    },
    "Rank": 87056,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f32"
    },
    "Rank": 87058,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f33"
    },
    "Rank": 87069,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f34"
    },
    "Rank": 87084,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f35"
    },
    "Rank": 87087,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f36"
    },
    "Rank": 87100,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f37"
    },
    "Rank": 87102,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f38"
    },
    "Rank": 87103,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f39"
    },
    "Rank": 87106,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f3a"
    },
    "Rank": 87117,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f3b"
    },
    "Rank": 87137,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f3c"
    },
    "Rank": 87174,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr. Rajendra Prasad Govt. Medical College, ,Dr.Rajendra Prasad Govt.Medical College Kangra at Tanda., Himachal Pradesh, 176002",
    "Course": "(NBEMS) PHYSIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f3d"
    },
    "Rank": 87204,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f3e"
    },
    "Rank": 87209,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f3f"
    },
    "Rank": 87221,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f40"
    },
    "Rank": 87228,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f41"
    },
    "Rank": 87263,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f42"
    },
    "Rank": 87272,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f43"
    },
    "Rank": 87289,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f44"
    },
    "Rank": 87354,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f45"
    },
    "Rank": 87365,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine & Sagore Dutta Hospital,College of Medicine & Sagore Dutta Hospital, West Bengal, 700058",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f46"
    },
    "Rank": 87389,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f47"
    },
    "Rank": 87391,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f48"
    },
    "Rank": 87398,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Medical College for Women, Khanpur Kalan Sonepat,BPS Govt. Medical College for Women, Khanpur Kalan Sonepat, Haryana, 131305",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f49"
    },
    "Rank": 87414,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f4a"
    },
    "Rank": 87418,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital, ,Kharvad medan Sipor road Vadnagar, Gujarat, 384355",
    "Course": "(NBEMS) MICROBIOLOGY",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f4b"
    },
    "Rank": 87457,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f4c"
    },
    "Rank": 87490,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f4d"
    },
    "Rank": 87523,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f4e"
    },
    "Rank": 87564,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f4f"
    },
    "Rank": 87572,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f50"
    },
    "Rank": 87582,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. District Civil Hospital, ,DIS GOVT HOSPITAL, SRI GANGANAGAR, Rajasthan, 335001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f51"
    },
    "Rank": 87604,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f52"
    },
    "Rank": 87610,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f53"
    },
    "Rank": 87611,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f54"
    },
    "Rank": 87627,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f55"
    },
    "Rank": 87655,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f56"
    },
    "Rank": 87678,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f57"
    },
    "Rank": 87686,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f58"
    },
    "Rank": 87694,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f59"
    },
    "Rank": 87705,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Suryapet Telangana,Amaravadi Nagar Thallagadda Suryapet, Telangana, 508213",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f5a"
    },
    "Rank": 87728,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Dharwad,dsdharwad at gmail dot com, Karnataka, 580001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f5b"
    },
    "Rank": 87798,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Dr.B.N.Bose Sub Divisional Hospital , ,BTRoad Talpukur\nBarrackpore 24Parganas N\n700123, West Bengal, 700123",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f5c"
    },
    "Rank": 87802,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f5d"
    },
    "Rank": 87807,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f5e"
    },
    "Rank": 87811,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f5f"
    },
    "Rank": 87815,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS COLLEGE OF MEDICAL SCIENCES,RUHS COLLEGE OF MEDICAL\nSCIENCES, Rajasthan, 302033",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f60"
    },
    "Rank": 87816,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f61"
    },
    "Rank": 87819,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (Emergency and Critical Care)/M.D.\n(Emergency Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f62"
    },
    "Rank": 87838,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shrikrishna Medical College & Hospital, Muzaffarpur,Shrikrishna Medical College & Hospital, Muzaffarpur, Bihar, 842004",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f63"
    },
    "Rank": 87843,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f64"
    },
    "Rank": 87844,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sher-i-Kashmir Institute of Medical Sciences, Srinagar,J&K ,SKIMS, SOURA, SRINAGAR-190011, Jammu And Kashmir, 190011",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f65"
    },
    "Rank": 87849,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f66"
    },
    "Rank": 87865,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f67"
    },
    "Rank": 87868,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f68"
    },
    "Rank": 87887,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f69"
    },
    "Rank": 87890,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f6a"
    },
    "Rank": 87939,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f6b"
    },
    "Rank": 87978,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f6c"
    },
    "Rank": 87982,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f6d"
    },
    "Rank": 87995,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f6e"
    },
    "Rank": 88001,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f6f"
    },
    "Rank": 88004,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f70"
    },
    "Rank": 88016,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Latur,Government Medical College, Old Railway station Near Marwadi Rajasthani High-School Latur, Maharashtra, 413512",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f71"
    },
    "Rank": 88025,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f72"
    },
    "Rank": 88036,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f73"
    },
    "Rank": 88040,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f74"
    },
    "Rank": 88050,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f75"
    },
    "Rank": 88097,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f76"
    },
    "Rank": 88141,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f77"
    },
    "Rank": 88149,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f78"
    },
    "Rank": 88159,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f79"
    },
    "Rank": 88178,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "JBMM District Hospital , ,JBMM DISTRICT HOSPITAL RAM BAGH PHATAK NANDAN CINEMA\nCHOWK AMRITSAR AMRITSAR AMRITSAR IN\n143001, Punjab, 143001",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f7a"
    },
    "Rank": 88247,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f7b"
    },
    "Rank": 88249,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f7c"
    },
    "Rank": 88274,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f7d"
    },
    "Rank": 88275,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f7e"
    },
    "Rank": 88281,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f7f"
    },
    "Rank": 88284,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f80"
    },
    "Rank": 88294,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tirunelveli Medical College,TIRUNELVELI MEDICAL COLLEGE, HIGHGROUND, TIRUNELVELI-11, Tamil Nadu, 627011",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f81"
    },
    "Rank": 88297,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f82"
    },
    "Rank": 88302,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f83"
    },
    "Rank": 88348,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Karnataka,Kodagu Institute of Medical Sciences Madikeri Kodagu, Karnataka, 571201",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f84"
    },
    "Rank": 88354,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Siddipet,SURVEY NO 54  GOVERNMENT MEDICAL COLLEGE  ENSANPALLY  SIDDIPET   502114, Telangana, 502114",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f85"
    },
    "Rank": 88381,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f86"
    },
    "Rank": 88392,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f87"
    },
    "Rank": 88414,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f88"
    },
    "Rank": 88418,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f89"
    },
    "Rank": 88432,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f8a"
    },
    "Rank": 88433,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Gondia,Nehru Chowk, KTS General Hospital Campus, Govt. Medical College, Gondia. 441601. Maharashtra., Maharashtra, 441601",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f8b"
    },
    "Rank": 88436,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f8c"
    },
    "Rank": 88482,
    "Allotted Quota": "All India",
    "Allotted Institute": "Calcutta School Of Tropical Medicine,Calcutta School Of Tropical Medicine, West Bengal, 700073",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f8d"
    },
    "Rank": 88497,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f8e"
    },
    "Rank": 88528,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f8f"
    },
    "Rank": 88532,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f90"
    },
    "Rank": 88580,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f91"
    },
    "Rank": 88632,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f92"
    },
    "Rank": 88635,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f93"
    },
    "Rank": 88658,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f94"
    },
    "Rank": 88662,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f95"
    },
    "Rank": 88702,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f96"
    },
    "Rank": 88709,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f97"
    },
    "Rank": 88721,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f98"
    },
    "Rank": 88723,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f99"
    },
    "Rank": 88747,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f9a"
    },
    "Rank": 88785,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f9b"
    },
    "Rank": 88794,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f9c"
    },
    "Rank": 88813,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f9d"
    },
    "Rank": 88861,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f9e"
    },
    "Rank": 88871,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07f9f"
    },
    "Rank": 88897,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fa0"
    },
    "Rank": 88899,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fa1"
    },
    "Rank": 88921,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fa2"
    },
    "Rank": 88967,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fa3"
    },
    "Rank": 88973,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fa4"
    },
    "Rank": 88983,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fa5"
    },
    "Rank": 88984,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fa6"
    },
    "Rank": 89014,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fa7"
    },
    "Rank": 89030,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fa8"
    },
    "Rank": 89054,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fa9"
    },
    "Rank": 89061,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07faa"
    },
    "Rank": 89072,
    "Allotted Quota": "All India",
    "Allotted Institute": "Late Baliram Kashyap Memorial Govt Medical Dimrapal,Late Baliram Kashyap Memorial Govt Medical Dimrapal, Chhattisgarh, 494001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fab"
    },
    "Rank": 89098,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fac"
    },
    "Rank": 89108,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fad"
    },
    "Rank": 89125,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fae"
    },
    "Rank": 89141,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07faf"
    },
    "Rank": 89147,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fb0"
    },
    "Rank": 89149,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (Hospital Administration)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fb1"
    },
    "Rank": 89184,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fb2"
    },
    "Rank": 89204,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fb3"
    },
    "Rank": 89238,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fb4"
    },
    "Rank": 89241,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan , Rajasthan-311001,Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan, Rajasthan, 311001",
    "Course": "(NBEMS-DIPLOMA)\nTuberculosis and CHEST DISEASES",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fb5"
    },
    "Rank": 89247,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubnagar,Government Medical College, Yedira, Mahabubnagar, Telangana state, Telangana, 509002",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fb6"
    },
    "Rank": 89250,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gvernment Medical College,Haldwani,Government Medical College, Haldwani, Uttarakhand, 263139",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fb7"
    },
    "Rank": 89257,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Wenlock District Hospital, ,HAMPANAKATTA MANGALORE, Karnataka, 575001",
    "Course": "(NBEMS) IMMUNO- HAEMATOLOGY AND BLOOD TRANSFUSION",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fb8"
    },
    "Rank": 89280,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fb9"
    },
    "Rank": 89311,
    "Allotted Quota": "All India",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fba"
    },
    "Rank": 89315,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Datia,Near 29 Batallion, National Highway 44, Dist Datia, Madhya Pradesh Pin - 475661, Madhya Pradesh, 475661",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fbb"
    },
    "Rank": 89355,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fbc"
    },
    "Rank": 89436,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fbd"
    },
    "Rank": 89438,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fbe"
    },
    "Rank": 89464,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fbf"
    },
    "Rank": 89483,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fc0"
    },
    "Rank": 89493,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fc1"
    },
    "Rank": 89540,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fc2"
    },
    "Rank": 89541,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fc3"
    },
    "Rank": 89556,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fc4"
    },
    "Rank": 89559,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fc5"
    },
    "Rank": 89588,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fc6"
    },
    "Rank": 89611,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fc7"
    },
    "Rank": 89661,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fc8"
    },
    "Rank": 89663,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fc9"
    },
    "Rank": 89673,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fca"
    },
    "Rank": 89696,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fcb"
    },
    "Rank": 89715,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fcc"
    },
    "Rank": 89743,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fcd"
    },
    "Rank": 89750,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fce"
    },
    "Rank": 89772,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fcf"
    },
    "Rank": 89792,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fd0"
    },
    "Rank": 89834,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nalgonda,Office of the Principal, Government Medical College Nalgonda, Near RTC Bus Stand, Gollaguda,\nNalgon, Telangana, 508001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fd1"
    },
    "Rank": 89865,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fd2"
    },
    "Rank": 89868,
    "Allotted Quota": "All India",
    "Allotted Institute": "Tezpur Medical College,Tumuki, Bihaguri, Tezpur, Sonitpur, Assam, Assam, 784010",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fd3"
    },
    "Rank": 89870,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "DIP. IN FORENSIC MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fd4"
    },
    "Rank": 89871,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fd5"
    },
    "Rank": 89890,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fd6"
    },
    "Rank": 89943,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Gondia,Nehru Chowk, KTS General Hospital Campus, Govt. Medical College, Gondia. 441601. Maharashtra., Maharashtra, 441601",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fd7"
    },
    "Rank": 90002,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fd8"
    },
    "Rank": 90010,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fd9"
    },
    "Rank": 90013,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fda"
    },
    "Rank": 90018,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fdb"
    },
    "Rank": 90019,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fdc"
    },
    "Rank": 90044,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,Veer Chandra Singh Garhwali Govt. Institute of Medical Science and Research, Srinagar Garhwal, Uttarakhand, 246174",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fdd"
    },
    "Rank": 90099,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fde"
    },
    "Rank": 90169,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fdf"
    },
    "Rank": 90203,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fe0"
    },
    "Rank": 90214,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bangalore Medical College and Research Institute,Bangalore Medical College and Research Institute, Karnataka, 560002",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fe1"
    },
    "Rank": 90225,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fe2"
    },
    "Rank": 90286,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fe3"
    },
    "Rank": 90294,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fe4"
    },
    "Rank": 90319,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fe5"
    },
    "Rank": 90333,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fe6"
    },
    "Rank": 90340,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fe7"
    },
    "Rank": 90348,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fe8"
    },
    "Rank": 90405,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fe9"
    },
    "Rank": 90407,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fea"
    },
    "Rank": 90442,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07feb"
    },
    "Rank": 90479,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fec"
    },
    "Rank": 90509,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital, ,GMERS Medical College Valsad,Nanakwada Halar Road,Valsad ,Gujarat, Gujarat, 396001",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fed"
    },
    "Rank": 90568,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fee"
    },
    "Rank": 90592,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fef"
    },
    "Rank": 90596,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ff0"
    },
    "Rank": 90613,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ff1"
    },
    "Rank": 90620,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ff2"
    },
    "Rank": 90622,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ff3"
    },
    "Rank": 90624,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ff4"
    },
    "Rank": 90692,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ff5"
    },
    "Rank": 90724,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ff6"
    },
    "Rank": 90736,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ff7"
    },
    "Rank": 90764,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ff8"
    },
    "Rank": 90787,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ff9"
    },
    "Rank": 90800,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ffa"
    },
    "Rank": 90811,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ffb"
    },
    "Rank": 90864,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ffc"
    },
    "Rank": 90865,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ffd"
    },
    "Rank": 90869,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07ffe"
    },
    "Rank": 90870,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a07fff"
    },
    "Rank": 90935,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08000"
    },
    "Rank": 90949,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08001"
    },
    "Rank": 90967,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08002"
    },
    "Rank": 90979,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08003"
    },
    "Rank": 90982,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08004"
    },
    "Rank": 91007,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Katwa Sub Divisional Hospital, West Bengal,Kachari Road, Katwa, Purba Bardhaman, West Bengal, 713130",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08005"
    },
    "Rank": 91029,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08006"
    },
    "Rank": 91032,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08007"
    },
    "Rank": 91043,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08008"
    },
    "Rank": 91047,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08009"
    },
    "Rank": 91051,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0800a"
    },
    "Rank": 91054,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0800b"
    },
    "Rank": 91090,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0800c"
    },
    "Rank": 91095,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0800d"
    },
    "Rank": 91097,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0800e"
    },
    "Rank": 91123,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0800f"
    },
    "Rank": 91133,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08010"
    },
    "Rank": 91175,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Daga Memorial Women Government Hospital Nagpur, ,Ganjakhet gandhibagh nagpur, Maharashtra, 440008",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08011"
    },
    "Rank": 91202,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08012"
    },
    "Rank": 91234,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08013"
    },
    "Rank": 91244,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08014"
    },
    "Rank": 91248,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08015"
    },
    "Rank": 91259,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08016"
    },
    "Rank": 91262,
    "Allotted Quota": "All India",
    "Allotted Institute": "NSC Government Medical College, Khandwa (MP),LUV KUSH NAGAR,MUNDI NAGAR, KHANDWA, Madhya Pradesh, 450001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08017"
    },
    "Rank": 91283,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08018"
    },
    "Rank": 91290,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08019"
    },
    "Rank": 91294,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0801a"
    },
    "Rank": 91337,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0801b"
    },
    "Rank": 91367,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jalgaon,Jilha Peth, Government Hospital Campus, Jalgaon, Maharashtra, 425001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0801c"
    },
    "Rank": 91387,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0801d"
    },
    "Rank": 91392,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0801e"
    },
    "Rank": 91423,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0801f"
    },
    "Rank": 91424,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08020"
    },
    "Rank": 91433,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08021"
    },
    "Rank": 91439,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08022"
    },
    "Rank": 91449,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS COLLEGE OF MEDICAL SCIENCES,RUHS COLLEGE OF MEDICAL\nSCIENCES, Rajasthan, 302033",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08023"
    },
    "Rank": 91456,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08024"
    },
    "Rank": 91462,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08025"
    },
    "Rank": 91475,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08026"
    },
    "Rank": 91510,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08027"
    },
    "Rank": 91522,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08028"
    },
    "Rank": 91523,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08029"
    },
    "Rank": 91539,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0802a"
    },
    "Rank": 91562,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0802b"
    },
    "Rank": 91616,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0802c"
    },
    "Rank": 91634,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "G B Pant Institute of Post Graduate Medical Education and Research,G B Pant Institute of Post Graduate Medical Education and Research, Delhi (NCT), 110007",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0802d"
    },
    "Rank": 91636,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0802e"
    },
    "Rank": 91754,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0802f"
    },
    "Rank": 91799,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08030"
    },
    "Rank": 91817,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08031"
    },
    "Rank": 91826,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08032"
    },
    "Rank": 91838,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Pandurang Ramarao Dongaonkar District Hospital, Chhattisgarh,Pandurang Ramarao Dongaonkar\nDistrict Hospital Durg G E Road District Durg Chhattisgarh 491001, Chhattisgarh, 491001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08033"
    },
    "Rank": 91842,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SETH V. C. GANDHI and M. A. VORA MUNICIPAL GENERAL HOSPITAL (RAJAWADI HOSPITAL),\nMaharashtra,hospital.rajawadi@gmail.com, Maharashtra, 400077",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08034"
    },
    "Rank": 91847,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08035"
    },
    "Rank": 91865,
    "Allotted Quota": "All India",
    "Allotted Institute": "Silchar Medical College, Silchar,Silchar Medical College, Silchar, Assam, 788014",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08036"
    },
    "Rank": 91869,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08037"
    },
    "Rank": 91890,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08038"
    },
    "Rank": 91894,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08039"
    },
    "Rank": 91896,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0803a"
    },
    "Rank": 91902,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0803b"
    },
    "Rank": 91910,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0803c"
    },
    "Rank": 91911,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0803d"
    },
    "Rank": 91946,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0803e"
    },
    "Rank": 91967,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine and JNM Hospital, WBUHS,Block A, Post Office Kalyani, Near Silpanchal Station,\nBlock A2, District Nadia, West Bengal, West Bengal, 741235",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0803f"
    },
    "Rank": 92003,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08040"
    },
    "Rank": 92013,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08041"
    },
    "Rank": 92071,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08042"
    },
    "Rank": 92080,
    "Allotted Quota": "All India",
    "Allotted Institute": "Bankura Sammilani Medical College,Bankura Sammilani Medical College, West Bengal, 722102",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08043"
    },
    "Rank": 92105,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE,ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, Bihar, 823001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08044"
    },
    "Rank": 92136,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08045"
    },
    "Rank": 92141,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08046"
    },
    "Rank": 92159,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08047"
    },
    "Rank": 92176,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI MEDICAL COLLEGE KALWA THANE,RAJIV GANDHI MEDICAL COLLEGE,THANE BELAPUR ROAD, KALWA, THANE, Maharashtra, 400605",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08048"
    },
    "Rank": 92182,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08049"
    },
    "Rank": 92185,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0804a"
    },
    "Rank": 92213,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0804b"
    },
    "Rank": 92244,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0804c"
    },
    "Rank": 92255,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0804d"
    },
    "Rank": 92302,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0804e"
    },
    "Rank": 92309,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0804f"
    },
    "Rank": 92326,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08050"
    },
    "Rank": 92335,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bettiah,Government Medical College Bettiah West Champaran Bihar, Bihar, 845438",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08051"
    },
    "Rank": 92344,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08052"
    },
    "Rank": 92349,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08053"
    },
    "Rank": 92350,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08054"
    },
    "Rank": 92353,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Rajiv Gandhi Government General Hospital, Principal Medical Officer General hospital,\n,Rajiv Gandhi Government General Hospital, near bijli ghar circle, ALWAR, Rajasthan, 301001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08055"
    },
    "Rank": 92358,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08056"
    },
    "Rank": 92379,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08057"
    },
    "Rank": 92391,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08058"
    },
    "Rank": 92411,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08059"
    },
    "Rank": 92421,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0805a"
    },
    "Rank": 92431,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0805b"
    },
    "Rank": 92439,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.Ch. (Neuro Surgery)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0805c"
    },
    "Rank": 92479,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0805d"
    },
    "Rank": 92497,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur and Associated Group of Hospitals Shri Haridev Joshi Hospital,,Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur, Rajasthan, 314001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0805e"
    },
    "Rank": 92510,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gandhi Medical College, Bhopal,Gandhi Medical College, Bhopal, Madhya Pradesh, 462001",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0805f"
    },
    "Rank": 92524,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Bettiah,Government Medical College Bettiah West Champaran Bihar, Bihar, 845438",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08060"
    },
    "Rank": 92552,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08061"
    },
    "Rank": 92585,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08062"
    },
    "Rank": 92594,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08063"
    },
    "Rank": 92601,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08064"
    },
    "Rank": 92625,
    "Allotted Quota": "All India",
    "Allotted Institute": "SJP Medical College, Bharatpur,SJP MEDICAL COLLEGE BHARATPUR VILLAGE RAMPURA NH 21, Rajasthan, 321001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08065"
    },
    "Rank": 92626,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08066"
    },
    "Rank": 92665,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Ratlam,Gram Banjali, Sailana Road, NH927A, Madhya Pradesh 457001, India, Madhya Pradesh, 457001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08067"
    },
    "Rank": 92682,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08068"
    },
    "Rank": 92685,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08069"
    },
    "Rank": 92724,
    "Allotted Quota": "All India",
    "Allotted Institute": "MANDYA INSTITUTE OF MEDICAL SCIENCES,MANDYA INSTITUTE OF MEDICAL SCIENCES\nMANDYA, Karnataka, 571401",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0806a"
    },
    "Rank": 92736,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0806b"
    },
    "Rank": 92806,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0806c"
    },
    "Rank": 92851,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0806d"
    },
    "Rank": 92855,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "EWS PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0806e"
    },
    "Rank": 92864,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Chittaranjan Seva Sadan College of Obstetrics, ,37, S P MUKHERJEE ROAD KOLKATA, West Bengal, 700026",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0806f"
    },
    "Rank": 92886,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08070"
    },
    "Rank": 92908,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute Of Medical Sciences, Kalaburagi,VEERESH NAGAR SEDAM ROAD KALABURAGI, Karnataka, 585105",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08071"
    },
    "Rank": 92919,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08072"
    },
    "Rank": 92974,
    "Allotted Quota": "All India",
    "Allotted Institute": "THANJAVUR MEDICAL COLLEGE, THANJAVUR,THANJAVUR MEDICAL COLLEGE, THANJAVUR, Tamil Nadu, 613004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08073"
    },
    "Rank": 93006,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08074"
    },
    "Rank": 93025,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08075"
    },
    "Rank": 93084,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08076"
    },
    "Rank": 93094,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08077"
    },
    "Rank": 93126,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08078"
    },
    "Rank": 93181,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08079"
    },
    "Rank": 93187,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Doon Medical College, Dehradun,Govt. Doon Medical College Dehrakhas, Patel Nagar Dehradun 248001, Uttarakhand, 248001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0807a"
    },
    "Rank": 93192,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0807b"
    },
    "Rank": 93198,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0807c"
    },
    "Rank": 93227,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0807d"
    },
    "Rank": 93230,
    "Allotted Quota": "All India",
    "Allotted Institute": "B. J. MEDICAL COLLEGE, Ahmedabad,B.J. Medical College, New Civil Hospital\nAsarwa,Ahmedabad-380016,Gujarat,India, Gujarat, 380016",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0807e"
    },
    "Rank": 93240,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0807f"
    },
    "Rank": 93262,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08080"
    },
    "Rank": 93340,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE KOTTAYAM,GOVT MEDICAL COLLEGE, GANDHINAGAR P O, KOTTAYAM\nKERALA 686008, Kerala, 686008",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08081"
    },
    "Rank": 93343,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08082"
    },
    "Rank": 93346,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08083"
    },
    "Rank": 93347,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08084"
    },
    "Rank": 93360,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08085"
    },
    "Rank": 93362,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08086"
    },
    "Rank": 93385,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08087"
    },
    "Rank": 93416,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute Of Medical Sciences, Kalaburagi,VEERESH NAGAR SEDAM ROAD KALABURAGI, Karnataka, 585105",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08088"
    },
    "Rank": 93446,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08089"
    },
    "Rank": 93452,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0808a"
    },
    "Rank": 93455,
    "Allotted Quota": "All India",
    "Allotted Institute": "Central Institute of Psychiatry, Ranchi,Central Institute of Psychiatry, Ranchi, Jharkhand, 834006",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0808b"
    },
    "Rank": 93457,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0808c"
    },
    "Rank": 93519,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0808d"
    },
    "Rank": 93539,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0808e"
    },
    "Rank": 93573,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0808f"
    },
    "Rank": 93593,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08090"
    },
    "Rank": 93606,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08091"
    },
    "Rank": 93610,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08092"
    },
    "Rank": 93617,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08093"
    },
    "Rank": 93654,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08094"
    },
    "Rank": 93694,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08095"
    },
    "Rank": 93717,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08096"
    },
    "Rank": 93796,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08097"
    },
    "Rank": 93832,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Deben Mahato (Sadar) Hospital,,Main Road Near SP office PO Purulia Main PS Purulia Town Dist Purulia West Bengal, West Bengal, 723101",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08098"
    },
    "Rank": 93895,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08099"
    },
    "Rank": 93899,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0809a"
    },
    "Rank": 93904,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0809b"
    },
    "Rank": 93906,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Walsh S.D.H., West Bengal,22A, T.C Goswami Street, Serampore, Hooghly, West Bengal, 712201",
    "Course": "(NBEMS-DIPLOMA)\nObstetrics and Gynaecology",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0809c"
    },
    "Rank": 93923,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0809d"
    },
    "Rank": 93927,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0809e"
    },
    "Rank": 93945,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0809f"
    },
    "Rank": 93965,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080a0"
    },
    "Rank": 93972,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE, ANANTAPURAMU,GOVT MEDICAL COLLEGE,\nANANTAPURAMU, Andhra Pradesh, 515001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080a1"
    },
    "Rank": 93976,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080a2"
    },
    "Rank": 93977,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080a3"
    },
    "Rank": 94034,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080a4"
    },
    "Rank": 94036,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080a5"
    },
    "Rank": 94039,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government T D Medical College, Vandanm , Alappuzha,Government T D Medical College, Vandanam , Alappuzha, Kerala, 688005",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080a6"
    },
    "Rank": 94043,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Vasantrao Naik Govt Medical College Yavatmal,Shri Vasantrao Naik Govt Medical College Yavatmal, Maharashtra, 445001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080a7"
    },
    "Rank": 94067,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080a8"
    },
    "Rank": 94078,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080a9"
    },
    "Rank": 94090,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080aa"
    },
    "Rank": 94149,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080ab"
    },
    "Rank": 94160,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080ac"
    },
    "Rank": 94166,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, Karnataka,General Hospital Tiptur Tumkur District\nKarnataka\nPin 572201, Karnataka, 572201",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080ad"
    },
    "Rank": 94168,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Allopathic Medical College, Uttar Pradesh,RANI DURGAVATI MEDICAL COLLEGE NARAINI ROAD BANDA, Uttar Pradesh, 210001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080ae"
    },
    "Rank": 94172,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital , Jammu and Kashmir\n,Court road pulwama, Jammu And Kashmir, 192301",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080af"
    },
    "Rank": 94173,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080b0"
    },
    "Rank": 94223,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE,ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, Bihar, 823001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080b1"
    },
    "Rank": 94257,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080b2"
    },
    "Rank": 94270,
    "Allotted Quota": "All India",
    "Allotted Institute": "TOPIWALA NATIONAL MEDICAL COLLEGE,TOPIWALA NATIONAL MEDICAL COLLEGE\nB Y L Nair Ch Hospital, Dr. A.L. Nair Rd, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080b3"
    },
    "Rank": 94315,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE,ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, Bihar, 823001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080b4"
    },
    "Rank": 94353,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080b5"
    },
    "Rank": 94360,
    "Allotted Quota": "All India",
    "Allotted Institute": "CALCUTTA NATIONAL MEDICAL COLLEGE,CALCUTTA NATIONAL MEDICAL COLLEGE\n32, Gorachand Road, Kolkata 700014, West Bengal, 700014",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080b6"
    },
    "Rank": 94377,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080b7"
    },
    "Rank": 94388,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080b8"
    },
    "Rank": 94397,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080b9"
    },
    "Rank": 94512,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080ba"
    },
    "Rank": 94515,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080bb"
    },
    "Rank": 94517,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080bc"
    },
    "Rank": 94565,
    "Allotted Quota": "All India",
    "Allotted Institute": "ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE,ANUGRAH NARAYAN MAGADH MEDICAL COLLEGE, Bihar, 823001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080bd"
    },
    "Rank": 94568,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080be"
    },
    "Rank": 94579,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080bf"
    },
    "Rank": 94583,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080c0"
    },
    "Rank": 94584,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ispat General Hospital,,Ispat General Hospital Rourkela Steel Plant SAIL Rourkela 769005, Odisha, 769005",
    "Course": "(NBEMS) OPHTHALMOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080c1"
    },
    "Rank": 94591,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080c2"
    },
    "Rank": 94605,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government General Hospital, ,GOVERNMENT GENERAL HOSPITAL, ALANPUR LINK ROAD SAWAI MADHOPUR 322001, Rajasthan, 322001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080c3"
    },
    "Rank": 94643,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080c4"
    },
    "Rank": 94723,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080c5"
    },
    "Rank": 94731,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Karnataka,Kodagu Institute of Medical Sciences Madikeri Kodagu, Karnataka, 571201",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080c6"
    },
    "Rank": 94737,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080c7"
    },
    "Rank": 94738,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080c8"
    },
    "Rank": 94752,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080c9"
    },
    "Rank": 94753,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080ca"
    },
    "Rank": 94788,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080cb"
    },
    "Rank": 94795,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080cc"
    },
    "Rank": 94799,
    "Allotted Quota": "All India",
    "Allotted Institute": "Agartala Government Medical College,Agartala Government Medical College, Tripura, 799006",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080cd"
    },
    "Rank": 94806,
    "Allotted Quota": "All India",
    "Allotted Institute": "LATE SHRI LAKHI RAM AGRAWAL MEMORIAL GOVT.MEDICAL COLLEGE,LATE SHRI LAKHI RAM AGRAWAL MEMORIAL GOVT.MEDICAL COLLEGE, Chhattisgarh, 496001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080ce"
    },
    "Rank": 94836,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Venkateswara Institute of Medical Sciences (SVIMS), A State University estd. by Govt. of A.P.,Sri Venkateswara Institute of Medical Sciences, SVIMS, Alipiri Road, Tirupati, Andhra Pradesh, 517507",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080cf"
    },
    "Rank": 94839,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "DIPLOMA IN ANAESTHESIOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080d0"
    },
    "Rank": 94905,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shri Atal Bihari Vajpayee Medical College & Research Institute, Bengaluru,Opposite to Shivajinagar Bus stop, Shivajinagar, Bengaluru., Karnataka, 560001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080d1"
    },
    "Rank": 94928,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080d2"
    },
    "Rank": 94942,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rani Durgavati Medical College, Banda ,Rani Durgavati Medical College Naraini Road Banda, Uttar Pradesh, 210001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080d3"
    },
    "Rank": 94950,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080d4"
    },
    "Rank": 94965,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080d5"
    },
    "Rank": 94993,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080d6"
    },
    "Rank": 95009,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Sagar, Madhya Pradesh,shivaji ward tilli road sagar, Madhya Pradesh, 470002",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080d7"
    },
    "Rank": 95039,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080d8"
    },
    "Rank": 95056,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080d9"
    },
    "Rank": 95066,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080da"
    },
    "Rank": 95099,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080db"
    },
    "Rank": 95102,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080dc"
    },
    "Rank": 95115,
    "Allotted Quota": "All India",
    "Allotted Institute": "SHYAM SHAH MEDICAL COLLEGE,deanmcrewa@rediffmail.com, Madhya Pradesh, 486001",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080dd"
    },
    "Rank": 95135,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080de"
    },
    "Rank": 95166,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080df"
    },
    "Rank": 95172,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080e0"
    },
    "Rank": 95179,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine and JNM Hospital, WBUHS,Block A, Post Office Kalyani, Near Silpanchal Station,\nBlock A2, District Nadia, West Bengal, West Bengal, 741235",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080e1"
    },
    "Rank": 95183,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080e2"
    },
    "Rank": 95200,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080e3"
    },
    "Rank": 95202,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080e4"
    },
    "Rank": 95238,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080e5"
    },
    "Rank": 95250,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080e6"
    },
    "Rank": 95254,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kalpana Chawla Govt Medical College, Karnal, Haryana,Kalpana Chawla Government Medical College Model Town Karnal 132001 Haryana, Haryana, 132001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080e7"
    },
    "Rank": 95273,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080e8"
    },
    "Rank": 95281,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080e9"
    },
    "Rank": 95353,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080ea"
    },
    "Rank": 95392,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080eb"
    },
    "Rank": 95410,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080ec"
    },
    "Rank": 95425,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080ed"
    },
    "Rank": 95453,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080ee"
    },
    "Rank": 95481,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080ef"
    },
    "Rank": 95514,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080f0"
    },
    "Rank": 95528,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080f1"
    },
    "Rank": 95552,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080f2"
    },
    "Rank": 95564,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI MEDICAL COLLEGE KALWA THANE,RAJIV GANDHI MEDICAL COLLEGE,THANE BELAPUR ROAD, KALWA, THANE, Maharashtra, 400605",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080f3"
    },
    "Rank": 95574,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080f4"
    },
    "Rank": 95579,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080f5"
    },
    "Rank": 95581,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080f6"
    },
    "Rank": 95583,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080f7"
    },
    "Rank": 95589,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080f8"
    },
    "Rank": 95656,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080f9"
    },
    "Rank": 95681,
    "Allotted Quota": "All India",
    "Allotted Institute": "JHALAWAR MEDICAL COLLEGE,JHALAWAR MEDICAL COLLEGE, JHALAWAR,\nRajasthan, 326001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080fa"
    },
    "Rank": 95696,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080fb"
    },
    "Rank": 95699,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080fc"
    },
    "Rank": 95756,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080fd"
    },
    "Rank": 95822,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080fe"
    },
    "Rank": 95825,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a080ff"
    },
    "Rank": 95838,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08100"
    },
    "Rank": 95846,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08101"
    },
    "Rank": 95852,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "IGM Hospital , ,IGM Chowmuhani, Agartala, Tripura West, Tripura, 799001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08102"
    },
    "Rank": 95856,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaheed Hasan Khan Mewati Government Medical College,Shaheed Hasan Khan Mewati Government Medical College, Haryana, 122107",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08103"
    },
    "Rank": 95859,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08104"
    },
    "Rank": 95860,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08105"
    },
    "Rank": 95865,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08106"
    },
    "Rank": 95880,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08107"
    },
    "Rank": 95889,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08108"
    },
    "Rank": 95926,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08109"
    },
    "Rank": 95928,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0810a"
    },
    "Rank": 95941,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM,GOVT. MEDICAL COLLEGE, THIRUVANANTHAPURAM, Kerala, 695011",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0810b"
    },
    "Rank": 95971,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0810c"
    },
    "Rank": 95974,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Vinoba Bhave Civil Hospital,,S R Delkar Marg, Saily Road, Silvassa, Dadra And Nagar Haveli, 396230",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0810d"
    },
    "Rank": 95997,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0810e"
    },
    "Rank": 95999,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0810f"
    },
    "Rank": 96070,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08110"
    },
    "Rank": 96071,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08111"
    },
    "Rank": 96076,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08112"
    },
    "Rank": 96106,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08113"
    },
    "Rank": 96113,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08114"
    },
    "Rank": 96155,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08115"
    },
    "Rank": 96221,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Southern Railway Head Quarter Hospital, ,Ayanavaram, Tamil Nadu, 600023",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08116"
    },
    "Rank": 96226,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08117"
    },
    "Rank": 96229,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08118"
    },
    "Rank": 96253,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08119"
    },
    "Rank": 96257,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Gondia,Nehru Chowk, KTS General Hospital Campus, Govt. Medical College, Gondia. 441601. Maharashtra., Maharashtra, 441601",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0811a"
    },
    "Rank": 96290,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0811b"
    },
    "Rank": 96309,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0811c"
    },
    "Rank": 96320,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0811d"
    },
    "Rank": 96380,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0811e"
    },
    "Rank": 96388,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0811f"
    },
    "Rank": 96417,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08120"
    },
    "Rank": 96471,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08121"
    },
    "Rank": 96496,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08122"
    },
    "Rank": 96508,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08123"
    },
    "Rank": 96534,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08124"
    },
    "Rank": 96538,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08125"
    },
    "Rank": 96547,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Bhausaheb Hire Government Medical College,Sri Bhausaheb Hire Government Medical College,Dhule, Maharashtra, 424311",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08126"
    },
    "Rank": 96568,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08127"
    },
    "Rank": 96616,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, ,District hospital chitradurga, B D road beside DHO office Chitradurga, Karnataka, 577501",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08128"
    },
    "Rank": 96666,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08129"
    },
    "Rank": 96667,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0812a"
    },
    "Rank": 96668,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0812b"
    },
    "Rank": 96671,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0812c"
    },
    "Rank": 96693,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Swami Dayanand Hospital, ,Dilshad Garden, Shahdara, Delhi, Delhi (NCT), 110095",
    "Course": "(NBEMS) GENERAL MEDICINE",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0812d"
    },
    "Rank": 96711,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College Akola,Govt. Medical College Akola, Maharashtra, 444001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0812e"
    },
    "Rank": 96715,
    "Allotted Quota": "All India",
    "Allotted Institute": "Osmania Medical Collge,Osmania Medical Collge, Telangana, 500095",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0812f"
    },
    "Rank": 96782,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08130"
    },
    "Rank": 96809,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Baramati,Plot No P-107 Opposite Women Hospital Baramati MIDC Area Baramati Tehsil Baramati Dist Pune, Maharashtra, 413133",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08131"
    },
    "Rank": 96825,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08132"
    },
    "Rank": 96826,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08133"
    },
    "Rank": 96841,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karnataka,MG Road, Karnataka, 581301",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08134"
    },
    "Rank": 96886,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08135"
    },
    "Rank": 96962,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08136"
    },
    "Rank": 96995,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08137"
    },
    "Rank": 97022,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08138"
    },
    "Rank": 97023,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaheed Hasan Khan Mewati Government Medical College,Shaheed Hasan Khan Mewati Government Medical College, Haryana, 122107",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08139"
    },
    "Rank": 97048,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0813a"
    },
    "Rank": 97050,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0813b"
    },
    "Rank": 97064,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0813c"
    },
    "Rank": 97104,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0813d"
    },
    "Rank": 97118,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0813e"
    },
    "Rank": 97148,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0813f"
    },
    "Rank": 97165,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Head Quarters Hospital,,GOVERNMENT HEADQUARTERS HOSPITAL AND DNB POST GRADUATE TEACHING INSTITUTE,EVN ROAD, ERODE, Tamil Nadu, 638009",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08140"
    },
    "Rank": 97170,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08141"
    },
    "Rank": 97197,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08142"
    },
    "Rank": 97202,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08143"
    },
    "Rank": 97206,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08144"
    },
    "Rank": 97213,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08145"
    },
    "Rank": 97225,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08146"
    },
    "Rank": 97244,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08147"
    },
    "Rank": 97245,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08148"
    },
    "Rank": 97260,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08149"
    },
    "Rank": 97282,
    "Allotted Quota": "Muslim Minority Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0814a"
    },
    "Rank": 97300,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0814b"
    },
    "Rank": 97302,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0814c"
    },
    "Rank": 97307,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0814d"
    },
    "Rank": 97467,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0814e"
    },
    "Rank": 97495,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0814f"
    },
    "Rank": 97524,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08150"
    },
    "Rank": 97549,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08151"
    },
    "Rank": 97550,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Doon Medical College, Dehradun,Govt. Doon Medical College Dehrakhas, Patel Nagar Dehradun 248001, Uttarakhand, 248001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08152"
    },
    "Rank": 97586,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08153"
    },
    "Rank": 97594,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08154"
    },
    "Rank": 97643,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08155"
    },
    "Rank": 97732,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Datia,Near 29 Batallion, National Highway 44, Dist Datia, Madhya Pradesh Pin - 475661, Madhya Pradesh, 475661",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08156"
    },
    "Rank": 97744,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08157"
    },
    "Rank": 97784,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS COLLEGE OF MEDICAL SCIENCES,RUHS COLLEGE OF MEDICAL\nSCIENCES, Rajasthan, 302033",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08158"
    },
    "Rank": 97790,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karnataka Institute of Medical Sciences, Hubballi,Karnataka Institute of Medical Sciences, Hubballi, Karnataka, 580021",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08159"
    },
    "Rank": 97799,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0815a"
    },
    "Rank": 97933,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0815b"
    },
    "Rank": 97934,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0815c"
    },
    "Rank": 97967,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "GITAM Institute of Medical Sciences & Research, Visakhapatnam,GITAM Deemed to be University\nRushikonda,  Visakhapatnam - 530045 Andhra Pradesh, Andhra Pradesh, 530045",
    "Course": "M.D. (Tuberculosis and Respiratory\ndiseases)/Pulmonary Medicine /M.D. (Respiratory Medicine)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0815d"
    },
    "Rank": 97990,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0815e"
    },
    "Rank": 98038,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0815f"
    },
    "Rank": 98054,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08160"
    },
    "Rank": 98075,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08161"
    },
    "Rank": 98086,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08162"
    },
    "Rank": 98096,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08163"
    },
    "Rank": 98178,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08164"
    },
    "Rank": 98183,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Gondia,Nehru Chowk, KTS General Hospital Campus, Govt. Medical College, Gondia. 441601. Maharashtra., Maharashtra, 441601",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08165"
    },
    "Rank": 98198,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08166"
    },
    "Rank": 98221,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08167"
    },
    "Rank": 98309,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08168"
    },
    "Rank": 98332,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08169"
    },
    "Rank": 98335,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0816a"
    },
    "Rank": 98342,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0816b"
    },
    "Rank": 98359,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "K S Hegde Medical Academy, Mangaluru,K S Hegde Medical Academy, Nithyanandanagar, Deralakatte, Mangaluru, Karnataka, 575018",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0816c"
    },
    "Rank": 98381,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0816d"
    },
    "Rank": 98423,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0816e"
    },
    "Rank": 98429,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute Of Medical Sciences, Kalaburagi,VEERESH NAGAR SEDAM ROAD KALABURAGI, Karnataka, 585105",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0816f"
    },
    "Rank": 98449,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08170"
    },
    "Rank": 98467,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08171"
    },
    "Rank": 98470,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Doon Medical College, Dehradun,Govt. Doon Medical College Dehrakhas, Patel Nagar Dehradun 248001, Uttarakhand, 248001",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08172"
    },
    "Rank": 98493,
    "Allotted Quota": "Jain Minority Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08173"
    },
    "Rank": 98499,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08174"
    },
    "Rank": 98509,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08175"
    },
    "Rank": 98510,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08176"
    },
    "Rank": 98557,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08177"
    },
    "Rank": 98572,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08178"
    },
    "Rank": 98581,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08179"
    },
    "Rank": 98595,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0817a"
    },
    "Rank": 98629,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0817b"
    },
    "Rank": 98657,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0817c"
    },
    "Rank": 98676,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0817d"
    },
    "Rank": 98694,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Dindayal Upadhyay Medical College, RAJKOT,Pandit Dindayal Upadhyay Medical College, RAJKOT, Gujarat, 360001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0817e"
    },
    "Rank": 98727,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0817f"
    },
    "Rank": 98762,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08180"
    },
    "Rank": 98767,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08181"
    },
    "Rank": 98847,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08182"
    },
    "Rank": 98853,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08183"
    },
    "Rank": 98887,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "SSG government district hospital, ,Udaipur road, Senthi, Chittorgarh, Rajasthan, Rajasthan, 312001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08184"
    },
    "Rank": 98924,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08185"
    },
    "Rank": 98953,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08186"
    },
    "Rank": 98981,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08187"
    },
    "Rank": 98988,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08188"
    },
    "Rank": 99008,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08189"
    },
    "Rank": 99017,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0818a"
    },
    "Rank": 99021,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0818b"
    },
    "Rank": 99024,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0818c"
    },
    "Rank": 99027,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SBKS Medical College and Research Centre, Vadodara,SBKS Medical Institute and Research Centre, Sumandeep Vidyapeeth, Piparia, Waghodia Rd, Vadodara, Gujarat, 391760",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0818d"
    },
    "Rank": 99030,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0818e"
    },
    "Rank": 99041,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0818f"
    },
    "Rank": 99044,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajiv Gandhi Institute of Medical Sciences Srikakulam,Government Medical College Srikakulam\nBalaga Srikakulam Town Srikakulam District, Andhra Pradesh, 532001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08190"
    },
    "Rank": 99052,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Bangur Hospital, Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan , Rajasthan-306401,Government Medical College and Bangur Hospital Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan Ra, Rajasthan, 306401",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08191"
    },
    "Rank": 99083,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08192"
    },
    "Rank": 99096,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08193"
    },
    "Rank": 99146,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08194"
    },
    "Rank": 99162,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08195"
    },
    "Rank": 99255,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08196"
    },
    "Rank": 99303,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DR. D. Y. PATIL MEDICAL COLLEGE, PUNE,Dr. D. Y. Patil Medical College,  Hospital and Research Centre, Sant Tukaram Nagar, Pimpri, Pune., Maharashtra, 411018",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08197"
    },
    "Rank": 99314,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08198"
    },
    "Rank": 99317,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08199"
    },
    "Rank": 99325,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0819a"
    },
    "Rank": 99360,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0819b"
    },
    "Rank": 99376,
    "Allotted Quota": "Aligarh Muslim University",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY STATE UTTAR,JAWAHARLAL NEHRU MEDICAL COLLEGE, ALIGARH MUSLIM UNIVERSITY\nSTATE UTTAR, Uttar Pradesh, 202002",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0819c"
    },
    "Rank": 99417,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0819d"
    },
    "Rank": 99462,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0819e"
    },
    "Rank": 99463,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0819f"
    },
    "Rank": 99476,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081a0"
    },
    "Rank": 99477,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081a1"
    },
    "Rank": 99529,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081a2"
    },
    "Rank": 99555,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081a3"
    },
    "Rank": 99564,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College, Kolhapur,R.K. Nagar Road, Shenda Park, Kolhapur, Maharashtra, Maharashtra, 416012",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081a4"
    },
    "Rank": 99649,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081a5"
    },
    "Rank": 99657,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081a6"
    },
    "Rank": 99673,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081a7"
    },
    "Rank": 99700,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081a8"
    },
    "Rank": 99723,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081a9"
    },
    "Rank": 99735,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081aa"
    },
    "Rank": 99801,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081ab"
    },
    "Rank": 99826,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081ac"
    },
    "Rank": 99874,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081ad"
    },
    "Rank": 99875,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081ae"
    },
    "Rank": 99878,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081af"
    },
    "Rank": 99919,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081b0"
    },
    "Rank": 99933,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081b1"
    },
    "Rank": 99983,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081b2"
    },
    "Rank": 100021,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081b3"
    },
    "Rank": 100024,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Kamaraj Hospital, ,SP KOIL STREET,CHIDAMBARM,CUDDALORE DISTRICT, Tamil Nadu, 608001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081b4"
    },
    "Rank": 100033,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081b5"
    },
    "Rank": 100053,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "EWS"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081b6"
    },
    "Rank": 100077,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081b7"
    },
    "Rank": 100079,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081b8"
    },
    "Rank": 100108,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081b9"
    },
    "Rank": 100112,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081ba"
    },
    "Rank": 100123,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081bb"
    },
    "Rank": 100124,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sri Bhausaheb Hire Government Medical College,Sri Bhausaheb Hire Government Medical College,Dhule, Maharashtra, 424311",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081bc"
    },
    "Rank": 100148,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081bd"
    },
    "Rank": 100157,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081be"
    },
    "Rank": 100173,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081bf"
    },
    "Rank": 100183,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,Veer Chandra Singh Garhwali Govt. Institute of Medical Science and Research, Srinagar Garhwal, Uttarakhand, 246174",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081c0"
    },
    "Rank": 100217,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rangaraya Medical College,Rangaraya Medical College Beside SP Office , Pithapuram Road, Kakinada, Andhra Pradesh, 533003",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081c1"
    },
    "Rank": 100237,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081c2"
    },
    "Rank": 100273,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081c3"
    },
    "Rank": 100284,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Suryapet Telangana,Amaravadi Nagar Thallagadda Suryapet, Telangana, 508213",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081c4"
    },
    "Rank": 100287,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College, ,Office of the Dean, First Floor, Civil Hospital Campus, Sector- 12, Gandhinagar, Gujarat, 382016",
    "Course": "(NBEMS) DERMATOLOGY and VENEREOLOGY and LEPROSY",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081c5"
    },
    "Rank": 100295,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081c6"
    },
    "Rank": 100323,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081c7"
    },
    "Rank": 100374,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081c8"
    },
    "Rank": 100385,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081c9"
    },
    "Rank": 100389,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081ca"
    },
    "Rank": 100412,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081cb"
    },
    "Rank": 100413,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081cc"
    },
    "Rank": 100415,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081cd"
    },
    "Rank": 100420,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "DIPLOMA IN OPHTHALMOLOGY/DOM S",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081ce"
    },
    "Rank": 100452,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081cf"
    },
    "Rank": 100478,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081d0"
    },
    "Rank": 100498,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081d1"
    },
    "Rank": 100543,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "KALINGA INSTITUTE OF MEDICAL SCIENCES,KALINGA INSTITUTE OF MEDICAL SCIENCES, Odisha, 751024",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081d2"
    },
    "Rank": 100550,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur and Associated Group of Hospitals Shri Haridev Joshi Hospital,,Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur, Rajasthan, 314001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081d3"
    },
    "Rank": 100556,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081d4"
    },
    "Rank": 100616,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081d5"
    },
    "Rank": 100677,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081d6"
    },
    "Rank": 100680,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "General"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081d7"
    },
    "Rank": 100689,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081d8"
    },
    "Rank": 100733,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081d9"
    },
    "Rank": 100797,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081da"
    },
    "Rank": 100929,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081db"
    },
    "Rank": 100975,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak,Pandit Bhagwat Dayal Sharma Post Graduate Institute of Medical Sciences, Rohtak, Haryana, 124001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081dc"
    },
    "Rank": 101002,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081dd"
    },
    "Rank": 101097,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Miraj,Government Medical College, Miraj, Maharashtra, 416410",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081de"
    },
    "Rank": 101131,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081df"
    },
    "Rank": 101155,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Hospital,,JANGLAT MANDI ANANTNAG, JAMMU AND KASHMIR 192101, Jammu And Kashmir, 192101",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081e0"
    },
    "Rank": 101159,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081e1"
    },
    "Rank": 101161,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081e2"
    },
    "Rank": 101189,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081e3"
    },
    "Rank": 101202,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nalgonda,Office of the Principal, Government Medical College Nalgonda, Near RTC Bus Stand, Gollaguda,\nNalgon, Telangana, 508001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081e4"
    },
    "Rank": 101282,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081e5"
    },
    "Rank": 101318,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN,HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN, Karnataka, 573201",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081e6"
    },
    "Rank": 101338,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081e7"
    },
    "Rank": 101343,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine and JNM Hospital, WBUHS,Block A, Post Office Kalyani, Near Silpanchal Station,\nBlock A2, District Nadia, West Bengal, West Bengal, 741235",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081e8"
    },
    "Rank": 101455,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081e9"
    },
    "Rank": 101456,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081ea"
    },
    "Rank": 101482,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081eb"
    },
    "Rank": 101514,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081ec"
    },
    "Rank": 101665,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan , Rajasthan-311001,Mahatma Gandhi Hospital, Bhilwara Swami Ram Charan Marg, Bhopal Ganj, Bhilwara, Rajasthan Rajasthan, Rajasthan, 311001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081ed"
    },
    "Rank": 101762,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081ee"
    },
    "Rank": 101924,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.S. (OPHTHALMOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081ef"
    },
    "Rank": 101982,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081f0"
    },
    "Rank": 101984,
    "Allotted Quota": "All India",
    "Allotted Institute": "Assam Medical College,Assam Medical College, Assam, 786002",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081f1"
    },
    "Rank": 102146,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT. MEDICAL COLLEGE, AMRITSAR,GOVT. MEDICAL COLLEGE,CIRCULAR ROAD, AMRITSAR, Punjab, 143001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081f2"
    },
    "Rank": 102153,
    "Allotted Quota": "All India",
    "Allotted Institute": "Veer Chandra Singh Garhwali Govt. Institute of Medical Science & Research,Veer Chandra Singh Garhwali Govt. Institute of Medical Science and Research, Srinagar Garhwal, Uttarakhand, 246174",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081f3"
    },
    "Rank": 102156,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081f4"
    },
    "Rank": 102166,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081f5"
    },
    "Rank": 102203,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081f6"
    },
    "Rank": 102312,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081f7"
    },
    "Rank": 102346,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081f8"
    },
    "Rank": 102361,
    "Allotted Quota": "All India",
    "Allotted Institute": "Burdwan Medical College, West Bengal,Burdwan Medical College, West Bengal, West Bengal, 713104",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081f9"
    },
    "Rank": 102384,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081fa"
    },
    "Rank": 102439,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081fb"
    },
    "Rank": 102440,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081fc"
    },
    "Rank": 102479,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "SC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081fd"
    },
    "Rank": 102658,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Bangur Hospital, Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan , Rajasthan-306401,Government Medical College and Bangur Hospital Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan Ra, Rajasthan, 306401",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081fe"
    },
    "Rank": 102666,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. BDK hospital, govt. ,OPPOSITE ROADWAYS BUS STAND MAAN NAGAR JHUNJHUNU RAJASTHAN, Rajasthan, 333001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a081ff"
    },
    "Rank": 102687,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08200"
    },
    "Rank": 102723,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karnataka,MG Road, Karnataka, 581301",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08201"
    },
    "Rank": 102849,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08202"
    },
    "Rank": 102863,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Institute of Medical Sciences, Patna,Indira Gandhi Institute of Medical Sciences, Patna, Bihar, 800014",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08203"
    },
    "Rank": 102926,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08204"
    },
    "Rank": 102977,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Rajendra Prasad Government Medical College, Tanda,Dr. Rajendra Prasad Government Medical College, Tanda, Himachal Pradesh, 176002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08205"
    },
    "Rank": 102979,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08206"
    },
    "Rank": 103015,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08207"
    },
    "Rank": 103028,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08208"
    },
    "Rank": 103062,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08209"
    },
    "Rank": 103063,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0820a"
    },
    "Rank": 103073,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0820b"
    },
    "Rank": 103084,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0820c"
    },
    "Rank": 103133,
    "Allotted Quota": "All India",
    "Allotted Institute": "Grant Medical College, Maharashtra,Grant Medical College, Maharashtra, Maharashtra, 400008",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0820d"
    },
    "Rank": 103182,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0820e"
    },
    "Rank": 103192,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0820f"
    },
    "Rank": 103267,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08210"
    },
    "Rank": 103318,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08211"
    },
    "Rank": 103360,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08212"
    },
    "Rank": 103392,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08213"
    },
    "Rank": 103438,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08214"
    },
    "Rank": 103454,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Hindu Rao Hospital, ,SUBZI MANDI, MALKA GANJ, DELHI-110007, Delhi (NCT), 110007",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08215"
    },
    "Rank": 103464,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08216"
    },
    "Rank": 103489,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine & Sagore Dutta Hospital,College of Medicine & Sagore Dutta Hospital, West Bengal, 700058",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08217"
    },
    "Rank": 103492,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08218"
    },
    "Rank": 103504,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Baramati,Plot No P-107 Opposite Women Hospital Baramati MIDC Area Baramati Tehsil Baramati Dist Pune, Maharashtra, 413133",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08219"
    },
    "Rank": 103516,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0821a"
    },
    "Rank": 103519,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kakatiya Medical College,Kakatiya Medical College, Telangana, 506007",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0821b"
    },
    "Rank": 103536,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0821c"
    },
    "Rank": 103602,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0821d"
    },
    "Rank": 103616,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "District Hospital, Andhra Pradesh\n,DISTRICT HOSPITAL MADANAPALLE, Andhra Pradesh, 517325",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0821e"
    },
    "Rank": 103660,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ganesh Shankar Vidyarthi Memorial Medical College,Ganesh Shankar Vidyarthi Memorial Medical College, Uttar Pradesh, 208002",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "EWS PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0821f"
    },
    "Rank": 103670,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gauhati Medical College, Guwahati,Gauhati Medical College, Guwahati, Assam, 781032",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08220"
    },
    "Rank": 103725,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08221"
    },
    "Rank": 103924,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08222"
    },
    "Rank": 103978,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08223"
    },
    "Rank": 103988,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08224"
    },
    "Rank": 104067,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Hamdard Institute of Medical Sciences and Research,HIMSR, Jamia Hamdard, Guru Ravidas Marg, Hamdard Nagar, New Delhi, Delhi (NCT), 110062",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08225"
    },
    "Rank": 104069,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08226"
    },
    "Rank": 104084,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08227"
    },
    "Rank": 104090,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE, PUDUCHERRY,INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE,\nPUDUCHERRY, Puducherry, 605009",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08228"
    },
    "Rank": 104091,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth Deemed to be University Medical College,Pune,Bharati Vidyapeeth Deemed to be University Medical College, Dhankawadi, Pune Satara Road, Pune, Maharashtra, 411043",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08229"
    },
    "Rank": 104102,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0822a"
    },
    "Rank": 104192,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0822b"
    },
    "Rank": 104237,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0822c"
    },
    "Rank": 104269,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0822d"
    },
    "Rank": 104273,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0822e"
    },
    "Rank": 104293,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0822f"
    },
    "Rank": 104356,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "General Hospital, ,OLD SABJI MANDI HINDUAN GATE KARAULI, Rajasthan, 322241",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08230"
    },
    "Rank": 104377,
    "Allotted Quota": "All India",
    "Allotted Institute": "BPS Govt. Medical College for Women, Khanpur Kalan Sonepat,BPS Govt. Medical College for Women, Khanpur Kalan Sonepat, Haryana, 131305",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08231"
    },
    "Rank": 104403,
    "Allotted Quota": "All India",
    "Allotted Institute": "SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI,SWAMI RAMANAND TEERTH RURAL GOVERNMENT MEDICAL COLLEGE AMBAJOGAI, Maharashtra, 431517",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08232"
    },
    "Rank": 104421,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08233"
    },
    "Rank": 104456,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08234"
    },
    "Rank": 104533,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Siddipet,SURVEY NO 54  GOVERNMENT MEDICAL COLLEGE  ENSANPALLY  SIDDIPET   502114, Telangana, 502114",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08235"
    },
    "Rank": 104534,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08236"
    },
    "Rank": 104559,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08237"
    },
    "Rank": 104572,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08238"
    },
    "Rank": 104585,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08239"
    },
    "Rank": 104602,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0823a"
    },
    "Rank": 104607,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0823b"
    },
    "Rank": 104731,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Ranaghat Sub Division Hospital, West Bengal,Jagpur Road, West Bengal, 741255",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0823c"
    },
    "Rank": 104789,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0823d"
    },
    "Rank": 104795,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Gondia,Nehru Chowk, KTS General Hospital Campus, Govt. Medical College, Gondia. 441601. Maharashtra., Maharashtra, 441601",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0823e"
    },
    "Rank": 104825,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0823f"
    },
    "Rank": 104835,
    "Allotted Quota": "All India",
    "Allotted Institute": "VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA,VSS INSTITUTE OF MEDICAL SCIENCES AND RESEARCH,BURLA, Odisha, 768017",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08240"
    },
    "Rank": 104842,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08241"
    },
    "Rank": 104904,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow,Dr Ram Manohar Lohia Institute of Medical Sciences, Lucknow, Uttar Pradesh, 226010",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08242"
    },
    "Rank": 105022,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08243"
    },
    "Rank": 105154,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine & Sagore Dutta Hospital,College of Medicine & Sagore Dutta Hospital, West Bengal, 700058",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08244"
    },
    "Rank": 105207,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08245"
    },
    "Rank": 105254,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08246"
    },
    "Rank": 105271,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08247"
    },
    "Rank": 105275,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08248"
    },
    "Rank": 105299,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Jammu,Govt. Medical College, Bakshi Nagar, Jamu, Jammu And Kashmir, 180001",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08249"
    },
    "Rank": 105335,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Malabar Cancer Centre, ,Moozhikkara PO, Thalassery, Kannur, Kerala, 670103",
    "Course": "(NBEMS) PATHOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0824a"
    },
    "Rank": 105340,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0824b"
    },
    "Rank": 105356,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0824c"
    },
    "Rank": 105452,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0824d"
    },
    "Rank": 105494,
    "Allotted Quota": "All India",
    "Allotted Institute": "R G KAR MEDICAL COLLEGE, West Bengal,R G KAR MEDICAL COLLEGE,1 KHUDIRAM BOSE SARANI,KOLKATA, West Bengal, West Bengal, 700004",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0824e"
    },
    "Rank": 105588,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Naga Hospital Authority, Nagaland-0,NH-29, Hospital Colony, Kohima, Nagaland 797001, Nagaland, 797001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0824f"
    },
    "Rank": 105590,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Sadar Hospital,\n,Sadar Hospital, Belisarai, Motihari, East Champaran, Bihar, Under Government of Bihar, Bihar, 845401",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08250"
    },
    "Rank": 105592,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and General Hospital, ,GMERS Medical College and General hospital,Old TB Campus, gotri road, Vadodara, Gujarat, 390021",
    "Course": "(NBEMS) MICROBIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08251"
    },
    "Rank": 105596,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08252"
    },
    "Rank": 105598,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08253"
    },
    "Rank": 105659,
    "Allotted Quota": "All India",
    "Allotted Institute": "LOKMANYA TILAK MEDICAL COLLEGE MUMBAI,LOKMANYA TILAK MEDICAL\nCOLLEGE MUMBAI, Maharashtra, 400022",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08254"
    },
    "Rank": 105685,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08255"
    },
    "Rank": 105687,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08256"
    },
    "Rank": 105749,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08257"
    },
    "Rank": 105752,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08258"
    },
    "Rank": 105771,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES ADILABAD -504001 TELANGANA STATE, Telangana, 504001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08259"
    },
    "Rank": 105819,
    "Allotted Quota": "All India",
    "Allotted Institute": "Seth Gordhandas Sunderdas Medical College, MUMBAI,Seth Gordhandas Sunderdas Medical College, Acharya Donde Marg Parel MUMBAI, Maharashtra, 400012",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "General PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0825a"
    },
    "Rank": 105825,
    "Allotted Quota": "All India",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0825b"
    },
    "Rank": 105982,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0825c"
    },
    "Rank": 105992,
    "Allotted Quota": "All India",
    "Allotted Institute": "COIMBATORE MEDICAL COLLEGE,COIMBATORE MEDICAL COLLEGE, Tamil Nadu, 641014",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0825d"
    },
    "Rank": 105999,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "VINAYAKA MISSIONS MEDICAL COLLEGE AND HOSPITAL, KARAIKAL,KEEZHAKASAKUDIMEDU,\nKOTTUCHERRY (POST),\nKARAIKAL, UT of PUDUCHERRY, Puducherry, 609609",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0825e"
    },
    "Rank": 106031,
    "Allotted Quota": "All India",
    "Allotted Institute": "The National Institute of Health and Family Welfare,The National Institute of Health and Family Welfare, Delhi (NCT), 110067",
    "Course": "DIPLOMA IN HEALTH ADMINISTRATION",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0825f"
    },
    "Rank": 106048,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08260"
    },
    "Rank": 106078,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karnataka,MG Road, Karnataka, 581301",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08261"
    },
    "Rank": 106131,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Jalgaon,Jilha Peth, Government Hospital Campus, Jalgaon, Maharashtra, 425001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08262"
    },
    "Rank": 106132,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08263"
    },
    "Rank": 106182,
    "Allotted Quota": "All India",
    "Allotted Institute": "Jorhat Medical College & Hospital,Jorhat Medical College & Hospital, Assam, 785001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08264"
    },
    "Rank": 106184,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08265"
    },
    "Rank": 106188,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08266"
    },
    "Rank": 106228,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "K C General Hospital, ,No. 89, 5th Cross, Malleshwaram, Bangalore, Karnataka, 560003",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08267"
    },
    "Rank": 106234,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08268"
    },
    "Rank": 106242,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08269"
    },
    "Rank": 106365,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0826a"
    },
    "Rank": 106367,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0826b"
    },
    "Rank": 106451,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0826c"
    },
    "Rank": 106529,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0826d"
    },
    "Rank": 106538,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0826e"
    },
    "Rank": 106556,
    "Allotted Quota": "All India",
    "Allotted Institute": "RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES,RAJIV GANDHI INSTITUTE OF MEDICAL SCIENCES ADILABAD -504001 TELANGANA STATE, Telangana, 504001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0826f"
    },
    "Rank": 106573,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08270"
    },
    "Rank": 106690,
    "Allotted Quota": "All India",
    "Allotted Institute": "HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN,HASSAN INSTITUTE OF MEDICAL SCIENCES, HASSAN, Karnataka, 573201",
    "Course": "M.D. (PSYCHIATRY)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08271"
    },
    "Rank": 106738,
    "Allotted Quota": "All India",
    "Allotted Institute": "KURNOOL MEDICAL COLLEGE,KURNOOL MEDICAL COLLEGE, Andhra Pradesh, 518002",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08272"
    },
    "Rank": 106779,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08273"
    },
    "Rank": 106780,
    "Allotted Quota": "All India",
    "Allotted Institute": "Hinduhridayasamrat Balasaheb Thackeray Medical College and Dr. R. N. Cooper Municipal General Hospital, Maharashtra,HBT Medical College and Dr R N Cooper Municipal General Hospital, Bhaktivedanta Swami Rd, JVPD Schem, Maharashtra, 400056",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08274"
    },
    "Rank": 106785,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08275"
    },
    "Rank": 106801,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08276"
    },
    "Rank": 106803,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08277"
    },
    "Rank": 106811,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur and Associated Group of Hospitals Shri Haridev Joshi Hospital,,Government Medical College and Associated Group of Hospitals, Government Medical College, Dungarpur, Rajasthan, 314001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08278"
    },
    "Rank": 106928,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08279"
    },
    "Rank": 106932,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADRAS MEDICAL COLLEGE,EVR PERIYAR SALAI, CHENNAI-03, Tamil Nadu, 600003",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0827a"
    },
    "Rank": 107035,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0827b"
    },
    "Rank": 107071,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0827c"
    },
    "Rank": 107072,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0827d"
    },
    "Rank": 107113,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0827e"
    },
    "Rank": 107115,
    "Allotted Quota": "All India",
    "Allotted Institute": "North Bengal Medical College,North Bengal Medical College, West Bengal, 734012",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0827f"
    },
    "Rank": 107160,
    "Allotted Quota": "All India",
    "Allotted Institute": "EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE AND PGIMSR,EMPLOYEES STATE INSURANCE CORPORATION MEDICAL COLLEGE\nAND PGIMSR, Karnataka, 560010",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08280"
    },
    "Rank": 107216,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "AMRITA SCHOOL OF MEDICINE,Kochi,AMRITA SCHOOL OF MEDICINE,Kochi,\nKerala, 682041",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08281"
    },
    "Rank": 107379,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Mohan Kumaramangalam Medical College, Salem,Government Mohan Kumaramangalam Medical College, Majeera Kollappatti, Steel Plant Road, Salem., Tamil Nadu, 636030",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "SC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08282"
    },
    "Rank": 107470,
    "Allotted Quota": "All India",
    "Allotted Institute": "MAHARANI LAXMI BAI MEDICAL COLLEGE JHANSI,MAHARANI LAXMI BAI MEDICAL\nCOLLEGE JHANSI, Uttar Pradesh, 284128",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08283"
    },
    "Rank": 107526,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08284"
    },
    "Rank": 107534,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08285"
    },
    "Rank": 107535,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08286"
    },
    "Rank": 107549,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08287"
    },
    "Rank": 107672,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08288"
    },
    "Rank": 107702,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08289"
    },
    "Rank": 107772,
    "Allotted Quota": "All India",
    "Allotted Institute": "Shaikh-Ul-Hind Maulana Mahmood Hasan Medical College, Saharanpur,Pilkhani Ambala Road, Uttar Pradesh, 247232",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0828a"
    },
    "Rank": 107796,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "North 24 Parganas District Hospital, ,BANAMALIPUR BARASAT WEST BENGAL, West Bengal, 700124",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0828b"
    },
    "Rank": 107879,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0828c"
    },
    "Rank": 107893,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0828d"
    },
    "Rank": 107923,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maulana Azad Medical College,Maulana Azad Medical College, Delhi (NCT), 110002",
    "Course": "M.D. (Radiotherapy/ Radiation Oncology)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0828e"
    },
    "Rank": 107931,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0828f"
    },
    "Rank": 107939,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR,INDIRA GANDHI GOVT.MEDICAL COLLEGE NAGPUR, Maharashtra, 440018",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08290"
    },
    "Rank": 107993,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08291"
    },
    "Rank": 108070,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubnagar,Government Medical College, Yedira, Mahabubnagar, Telangana state, Telangana, 509002",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08292"
    },
    "Rank": 108141,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08293"
    },
    "Rank": 108244,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08294"
    },
    "Rank": 108316,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08295"
    },
    "Rank": 108319,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08296"
    },
    "Rank": 108354,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08297"
    },
    "Rank": 108401,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08298"
    },
    "Rank": 108405,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08299"
    },
    "Rank": 108460,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0829a"
    },
    "Rank": 108502,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubnagar,Government Medical College, Yedira, Mahabubnagar, Telangana state, Telangana, 509002",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0829b"
    },
    "Rank": 108519,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "S.K.Hospital, ,SUPERINTENDENT RNO 36  GOVT SK HOSPITAL  SILVER JUBLI ROAD  SIKAR  RAJ, Rajasthan, 332001",
    "Course": "(NBEMS-DIPLOMA) PAEDIATRICS",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0829c"
    },
    "Rank": 108597,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0829d"
    },
    "Rank": 108886,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0829e"
    },
    "Rank": 109150,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0829f"
    },
    "Rank": 109200,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082a0"
    },
    "Rank": 109223,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082a1"
    },
    "Rank": 109243,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082a2"
    },
    "Rank": 109251,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government District General Hospital, Ramachandraraopeta, Eluru, West Godavari District, Andhra Prad, Andhra Pradesh, 534006,Government District General Hospital, Ramachandraraopeta, Eluru, West Godavari District, Andhra Prad, Andhra Pradesh, 534006",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082a3"
    },
    "Rank": 109283,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082a4"
    },
    "Rank": 109329,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "The National Institute of Health and Family Welfare,The National Institute of Health and Family Welfare, Delhi (NCT), 110067",
    "Course": "M.D. (COMMUNITY HEALTH  and ADMN.)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082a5"
    },
    "Rank": 109360,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082a6"
    },
    "Rank": 109364,
    "Allotted Quota": "IP University Quota",
    "Allotted Institute": "PGIMER, DR. RML Hospital,ABVIMS, DR. RML Hospital, Baba Kharak Singh Marg, Cannaught Place, Delhi (NCT), 110001",
    "Course": "M.D. (PHYSICAL MED. and REHABILITATION)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082a7"
    },
    "Rank": 109415,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "National Institute of Tuberculosis and Respiratory Diseases, (Formerly Known as L.R.S. Instt. of T.B. and Allied Scs),,National Institute of Tuberculosis and Respiratory Diseases (Formerly Known as L.R.S. Instt. of T.B., Delhi (NCT), 110030",
    "Course": "(NBEMS) MICROBIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082a8"
    },
    "Rank": 109428,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Jagjivan Ram Railway Hospital,,Maratha Mandir Marg, Mumbai Central, Mumbai, Maharashtra, 400008",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082a9"
    },
    "Rank": 109552,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082aa"
    },
    "Rank": 109624,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082ab"
    },
    "Rank": 109687,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082ac"
    },
    "Rank": 109689,
    "Allotted Quota": "All India",
    "Allotted Institute": "NALANDA MEDICAL COLLEGE,PATNA,NALANDA MEDICAL COLLEGE,PATNA, OLD BYPASS KANKARBAGH, Bihar, 800026",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082ad"
    },
    "Rank": 109696,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082ae"
    },
    "Rank": 109741,
    "Allotted Quota": "All India",
    "Allotted Institute": "INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE, PUDUCHERRY,INDIRA GANDHI MEDICAL COLLEGE AND RESEARCH INSTITUTE,\nPUDUCHERRY, Puducherry, 605009",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082af"
    },
    "Rank": 109756,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082b0"
    },
    "Rank": 109849,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082b1"
    },
    "Rank": 109898,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Baroda,Govt. Medical College, Baroda, Gujarat, 390001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082b2"
    },
    "Rank": 109903,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sardar Patel Medical College, Bikaner,Sardar Patel Medical College, Bikaner, Rajasthan, 334003",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082b3"
    },
    "Rank": 109940,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082b4"
    },
    "Rank": 109984,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082b5"
    },
    "Rank": 109985,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082b6"
    },
    "Rank": 110062,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082b7"
    },
    "Rank": 110072,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082b8"
    },
    "Rank": 110119,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082b9"
    },
    "Rank": 110146,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082ba"
    },
    "Rank": 110244,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082bb"
    },
    "Rank": 110335,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082bc"
    },
    "Rank": 110351,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082bd"
    },
    "Rank": 110360,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082be"
    },
    "Rank": 110378,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082bf"
    },
    "Rank": 110454,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082c0"
    },
    "Rank": 110575,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Thiruvannamalai Medical College, Thiruvannamalai, Tamil Nadu,DISTRICT COLLECTORATE  MASTER PLAN COMPLEX, VENGIKKAL THIRUVANNAMALAI,\nTamil Nadu, 600604",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082c1"
    },
    "Rank": 110590,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government medical college patiala.,Government medical college patiala., Punjab, 147001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082c2"
    },
    "Rank": 110665,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082c3"
    },
    "Rank": 110822,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082c4"
    },
    "Rank": 110836,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Associated Hospital,,JANGLAT MANDI ANANTNAG, JAMMU AND KASHMIR 192101, Jammu And Kashmir, 192101",
    "Course": "(NBEMS) PHARMACOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082c5"
    },
    "Rank": 110877,
    "Allotted Quota": "All India",
    "Allotted Institute": "BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI,BELAGAVI INSTITUTE OF MEDICAL SCIENCES, BELAGAVI, Karnataka, 590001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082c6"
    },
    "Rank": 110928,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082c7"
    },
    "Rank": 110957,
    "Allotted Quota": "All India",
    "Allotted Institute": "Uttar Pradesh University of Medical Sciences, Saifai, Etawah,Uttar Pradesh University of Medical Sciences, Saifai, Etawah, Uttar Pradesh, 206130",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082c8"
    },
    "Rank": 110965,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute Of Medical Sciences, Kalaburagi,VEERESH NAGAR SEDAM ROAD KALABURAGI, Karnataka, 585105",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082c9"
    },
    "Rank": 110968,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082ca"
    },
    "Rank": 111068,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082cb"
    },
    "Rank": 111136,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082cc"
    },
    "Rank": 111174,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVT MEDICAL COLLEGE,THRISSUR,GOVT MEDICAL COLLEGE,THRISSUR,\nKerala, 680596",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "SC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082cd"
    },
    "Rank": 111200,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082ce"
    },
    "Rank": 111329,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082cf"
    },
    "Rank": 111394,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nagpur,Government Medical College, Nagpur, Maharashtra, 440003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082d0"
    },
    "Rank": 111409,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082d1"
    },
    "Rank": 111415,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082d2"
    },
    "Rank": 111437,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082d3"
    },
    "Rank": 111445,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082d4"
    },
    "Rank": 111453,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Srinagar,Administrative Block Government Medical College Karan Nagar Srinagar, Jammu And Kashmir, 190010",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082d5"
    },
    "Rank": 111499,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute Of Medical Sciences, Kalaburagi,VEERESH NAGAR SEDAM ROAD KALABURAGI, Karnataka, 585105",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082d6"
    },
    "Rank": 111526,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082d7"
    },
    "Rank": 111617,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082d8"
    },
    "Rank": 111677,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOA MEDICAL COLLEGE,Bambolim, Goa, Goa, 403202",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082d9"
    },
    "Rank": 111732,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR,JAWAHARLAL NEHRU MEDICAL COLLEGE, BHAGALPUR, Bihar, 812001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082da"
    },
    "Rank": 111740,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082db"
    },
    "Rank": 111744,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082dc"
    },
    "Rank": 111758,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082dd"
    },
    "Rank": 111773,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082de"
    },
    "Rank": 111784,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. IN TRANSFUSION MEDICINE/ IMMUNO- HAEMATOLOGY and BLOOD TRANSFUSION",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082df"
    },
    "Rank": 111798,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082e0"
    },
    "Rank": 111843,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Government Medical College, Vidisha,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA MP, Madhya Pradesh, 464001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082e1"
    },
    "Rank": 111926,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE, AZAMGARH,Chakrapan Pur, Azamgarh (U.P.),\nUttar Pradesh, 276128",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082e2"
    },
    "Rank": 111959,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082e3"
    },
    "Rank": 111962,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082e4"
    },
    "Rank": 112013,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082e5"
    },
    "Rank": 112069,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "ST PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082e6"
    },
    "Rank": 112201,
    "Allotted Quota": "All India",
    "Allotted Institute": "DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, NANDED,DR. SHANKARRAO CHAVAN GOVERNMENT MEDICAL COLLEGE, VISHNUPURI,\nNANDED, Maharashtra, 431606",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082e7"
    },
    "Rank": 112232,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rims Medical College, Ongole, ,GOVERNMENT MEDICAL COLLEGE ONGOLE, Andhra Pradesh, 523001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082e8"
    },
    "Rank": 112246,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. BDK hospital, govt. ,OPPOSITE ROADWAYS BUS STAND MAAN NAGAR JHUNJHUNU RAJASTHAN, Rajasthan, 333001",
    "Course": "(NBEMS-DIPLOMA) ANAESTHESIOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082e9"
    },
    "Rank": 112252,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082ea"
    },
    "Rank": 112289,
    "Allotted Quota": "All India",
    "Allotted Institute": "NIL RATAN SIRCAR MEDICAL COLLEGE,NIL RATAN SIRCAR MEDICAL COLLEGE, 138 AJC BOSE ROAD, KOLKATA-700014., West Bengal, 700014",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082eb"
    },
    "Rank": 112340,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Belagavi,Jawaharlal Nehru Medical College, Belagavi, Karnataka, 590010",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082ec"
    },
    "Rank": 112405,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082ed"
    },
    "Rank": 112429,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082ee"
    },
    "Rank": 112486,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "FAKIR MOHAN MEDICAL COLLEGE AND HOSPITAL ,\nOrissa,AT-Remuna Post-Remuna Dist-Balasore, Odisha, 756019",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082ef"
    },
    "Rank": 112547,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "D. Y. Patil Medical College, Kolhapur,869  E  D. Y. PatilVidyanagar Kolhapur 416 006, Maharashtra, 416006",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082f0"
    },
    "Rank": 112558,
    "Allotted Quota": "All India",
    "Allotted Institute": "Ravindra Nath Tagore Medical College, Udaipur,Ravindra Nath Tagore Medical College, Udaipur, Rajasthan, 313001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082f1"
    },
    "Rank": 112661,
    "Allotted Quota": "All India",
    "Allotted Institute": "Gulbarga Institute Of Medical Sciences, Kalaburagi,VEERESH NAGAR SEDAM ROAD KALABURAGI, Karnataka, 585105",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082f2"
    },
    "Rank": 112690,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "JSS Medical College, Mysuru,JSS Medical Institutions Campus Sri Shivarathreeshwara Nagar Bannimantap Mysuru, Karnataka, 570015",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082f3"
    },
    "Rank": 112776,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES,CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, Chhattisgarh, 495001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082f4"
    },
    "Rank": 113017,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajarshee Chhatrapati Shahu Maharaj Government Medical College, Kolhapur,R.K. Nagar Road, Shenda Park, Kolhapur, Maharashtra, Maharashtra, 416012",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082f5"
    },
    "Rank": 113033,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082f6"
    },
    "Rank": 113170,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082f7"
    },
    "Rank": 113251,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082f8"
    },
    "Rank": 113260,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082f9"
    },
    "Rank": 113294,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082fa"
    },
    "Rank": 113538,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082fb"
    },
    "Rank": 113549,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Institute of Medical Sciences, Wardha,Mahatma Gandhi Institute of Medical Sciences, Sevagram, Wardha, Maharashtra, 442102",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082fc"
    },
    "Rank": 113593,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082fd"
    },
    "Rank": 113605,
    "Allotted Quota": "All India",
    "Allotted Institute": "NSC Government Medical College, Khandwa (MP),LUV KUSH NAGAR,MUNDI NAGAR, KHANDWA, Madhya Pradesh, 450001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082fe"
    },
    "Rank": 113608,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a082ff"
    },
    "Rank": 113747,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College Kota,Government Medical College Kota, Rajasthan, 324005",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08300"
    },
    "Rank": 113805,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08301"
    },
    "Rank": 113922,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Aurangabad,Government Medical College, Aurangabad, Maharashtra, 431001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08302"
    },
    "Rank": 114094,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08303"
    },
    "Rank": 114107,
    "Allotted Quota": "Delhi University Quota",
    "Allotted Institute": "Lady Hardinge Medical College,Lady Hardinge Medical College, Delhi (NCT), 110001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08304"
    },
    "Rank": 114123,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08305"
    },
    "Rank": 114126,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Nizamabad, Telangana State,Government Medical College, Nizamabad, Telangana State, Telangana, 503001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08306"
    },
    "Rank": 114173,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sarojini Naidu Medical College, AGRA,Sarojini Naidu Medical College, AGRA, Uttar Pradesh, 282002",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08307"
    },
    "Rank": 114218,
    "Allotted Quota": "All India",
    "Allotted Institute": "MEDICAL COLLEGE, KOLKATA,MEDICAL COLLEGE, KOLKATA, West Bengal, 700073",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08308"
    },
    "Rank": 114288,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08309"
    },
    "Rank": 114325,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0830a"
    },
    "Rank": 114375,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.S. (E.N.T.)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0830b"
    },
    "Rank": 114420,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Multi Specialty Hospital, ,Director Health and Family Welfare Govt. Multi Speciality Hospital Sector 16 Chandigarh, Chandigarh, 160016",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0830c"
    },
    "Rank": 114549,
    "Allotted Quota": "All India",
    "Allotted Institute": "KING GEORGES MEDICAL UNIVERSITY,KING GEORGES MEDICAL UNIVERSITY,\nShahmeena Road, Chowk, Lucknow-226003, Uttar Pradesh, 226003",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0830d"
    },
    "Rank": 114589,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0830e"
    },
    "Rank": 114607,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0830f"
    },
    "Rank": 114616,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Siddipet,SURVEY NO 54  GOVERNMENT MEDICAL COLLEGE  ENSANPALLY  SIDDIPET   502114, Telangana, 502114",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08310"
    },
    "Rank": 114626,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Government Medical College and Bangur Hospital, Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan , Rajasthan-306401,Government Medical College and Bangur Hospital Nh62 Sumerpur Road Village Ramasiya Pali Rajasthan Ra, Rajasthan, 306401",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08311"
    },
    "Rank": 114743,
    "Allotted Quota": "All India",
    "Allotted Institute": "JAWAHAR LAL NEHRU MEDICAL COLLEGE, AJMER,NEAR PATEL STADIUM,\nAJMER, Rajasthan, 305001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08312"
    },
    "Rank": 114776,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08313"
    },
    "Rank": 114843,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08314"
    },
    "Rank": 114847,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Surat,Government Medical College, Majuragate, Surat., Gujarat, 395001",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08315"
    },
    "Rank": 114955,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08316"
    },
    "Rank": 115010,
    "Allotted Quota": "All India",
    "Allotted Institute": "VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI,VIJANAGARA INSTITUTE OF MEDICAL SCIENCES BALLARI, Karnataka, 583104",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08317"
    },
    "Rank": 115018,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Institute of Medical Sciences and SUM Hospital, Bhubaneswar,Institute of Medical Sciences and SUM Hospital K 8 Kalinga Nagar PO Mahalaxmi Vihar Bhubaneswar, Odisha, 751029",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08318"
    },
    "Rank": 115030,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Associated Hospital Government Medical College, ,Government Medical College Chack Sajjan Kathua, Jammu And Kashmir, 184101",
    "Course": "(NBEMS) Anatomy",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08319"
    },
    "Rank": 115037,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0831a"
    },
    "Rank": 115166,
    "Allotted Quota": "All India",
    "Allotted Institute": "PATNA MEDICAL COLLEGE, PATNA,PATNA MEDICAL COLLEGE, PATNA, Bihar, 800004",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0831b"
    },
    "Rank": 115173,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0831c"
    },
    "Rank": 115192,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0831d"
    },
    "Rank": 115203,
    "Allotted Quota": "All India",
    "Allotted Institute": "Lala Lajpat Rai Memorial Medical College, MEERUT,Lala Lajpat Rai Memorial Medical College, MEERUT, Uttar Pradesh, 250004",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0831e"
    },
    "Rank": 115211,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0831f"
    },
    "Rank": 115408,
    "Allotted Quota": "All India",
    "Allotted Institute": "Vardhman Mahavir Medical College, , New Delhi,Vardhman Mahavir Medical College, , New Delhi, Delhi (NCT), 110029",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "ST PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08320"
    },
    "Rank": 115433,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08321"
    },
    "Rank": 115498,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08322"
    },
    "Rank": 115507,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08323"
    },
    "Rank": 115573,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08324"
    },
    "Rank": 115630,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08325"
    },
    "Rank": 115671,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Central Super Speciality Hospital,\n,mdcsshecr@gmail.com, Bihar, 800001",
    "Course": "(NBEMS-DIPLOMA) FAMILY MEDICINE",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08326"
    },
    "Rank": 115722,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Pravara Rural Medical College, Loni,Dr. Balasaheb Vikhe Patil Rural Medical College (Formerly Called As Rural Medical College, Loni), Maharashtra, 413736",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08327"
    },
    "Rank": 115741,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08328"
    },
    "Rank": 115842,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "GMERS Medical College and Hospital,\n,paddoc road, near majewadi gate, junagadh, Gujarat, 362001",
    "Course": "(NBEMS) MICROBIOLOGY",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08329"
    },
    "Rank": 115849,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Navi Mumbai,MGM Medical College, Sector1, Plot 1-2, Kamothe,                                Navi Mumbai-, Maharashtra, 410209",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0832a"
    },
    "Rank": 115912,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "MGM Medical College, Aurangabad,MGM Medical College, Aurangabad, Maharashtra, 431003",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0832b"
    },
    "Rank": 116067,
    "Allotted Quota": "All India",
    "Allotted Institute": "Mahatma Gandhi Memorial Medical College, Indore,Mahatma Gandhi Memorial Medical College, Indore, Madhya Pradesh, 452001",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0832c"
    },
    "Rank": 116143,
    "Allotted Quota": "All India",
    "Allotted Institute": "Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR,Pt. Jawahar Lal Nehru Memorial Medical College, RAIPUR, Chhattisgarh, 492001",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0832d"
    },
    "Rank": 116206,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0832e"
    },
    "Rank": 116295,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0832f"
    },
    "Rank": 116306,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08330"
    },
    "Rank": 116363,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08331"
    },
    "Rank": 116489,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08332"
    },
    "Rank": 116530,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Shri Shiv Prasad Gupta Divisional District Hospital, ,Kabir Chaura, Lohatiya, Varanasi, Uttar Pradesh, 221001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08333"
    },
    "Rank": 116645,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08334"
    },
    "Rank": 116700,
    "Allotted Quota": "All India",
    "Allotted Institute": "University College of Medical Sciences,University College of Medical Sciences, Delhi (NCT), 110095",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08335"
    },
    "Rank": 116702,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08336"
    },
    "Rank": 116745,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08337"
    },
    "Rank": 116951,
    "Allotted Quota": "Banaras Hindu University",
    "Allotted Institute": "INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY,INSTITUTE OF MEDICAL SCIENCES, BANARAS HINDU UNIVERSITY, Uttar Pradesh, 221005",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08338"
    },
    "Rank": 116983,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SRI DEVARAJ URS MEDICAL COLLEGE, TAMAKA,Tamaka, Kolar, Karnataka, 563103",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08339"
    },
    "Rank": 117079,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr.V.M.Govt.Medical College,Solapur, Maharasthtra,In front of district court civil chowk Dr.V.M.Govt.Medical College,Solapur, Maharasthtra, Maharashtra, 413003",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0833a"
    },
    "Rank": 117094,
    "Allotted Quota": "All India",
    "Allotted Institute": "NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, JABALPUR ,NETAJI SUBHASH CHANDRA BOSE MEDICAL COLLEGE, NAGPUR ROAD JABALPUR,\nMadhya Pradesh, 482003",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0833b"
    },
    "Rank": 117115,
    "Allotted Quota": "All India",
    "Allotted Institute": "Andhra Medical College,Andhra Medical College, Andhra Pradesh, 530002",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0833c"
    },
    "Rank": 117147,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0833d"
    },
    "Rank": 117161,
    "Allotted Quota": "All India",
    "Allotted Institute": "Rajendra Institute of Medical Sciences, Ranchi,Rajendra Institute of Medical Sciences, Ranchi, Jharkhand, 834009",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0833e"
    },
    "Rank": 117201,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Ramachandra Medical College and Research Institute,Sri Ramachandra Medical College and Research Institute, Tamil Nadu, 600116",
    "Course": "M.D. (FORENSIC MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0833f"
    },
    "Rank": 117321,
    "Allotted Quota": "All India",
    "Allotted Institute": "Indira Gandhi Medical College Shimla,Indira Gandhi Medical College Shimla, Himachal Pradesh, 171001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08340"
    },
    "Rank": 117360,
    "Allotted Quota": "All India",
    "Allotted Institute": "College of Medicine and JNM Hospital, WBUHS,Block A, Post Office Kalyani, Near Silpanchal Station,\nBlock A2, District Nadia, West Bengal, West Bengal, 741235",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08341"
    },
    "Rank": 117691,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08342"
    },
    "Rank": 117726,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08343"
    },
    "Rank": 117792,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08344"
    },
    "Rank": 117819,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08345"
    },
    "Rank": 117835,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08346"
    },
    "Rank": 117873,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "M.R.A. Medical College,\n,Saddarpur Akabarpur Tanda Road Post Surapur\nDistrict Ambedkarnagar, Uttar Pradesh, 224227",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08347"
    },
    "Rank": 117882,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI,DR D Y PATIL MEDICAL COLLEGE, NAVI MUMBAI, Maharashtra, 400706",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08348"
    },
    "Rank": 117919,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08349"
    },
    "Rank": 117959,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0834a"
    },
    "Rank": 117961,
    "Allotted Quota": "All India",
    "Allotted Institute": "Kodagu Institute of Medical Sciences, Karnataka,Kodagu Institute of Medical Sciences Madikeri Kodagu, Karnataka, 571201",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0834b"
    },
    "Rank": 117973,
    "Allotted Quota": "All India",
    "Allotted Institute": "GOVERNMENT MEDICAL COLLEGE CHANDRAPUR, MAHARASHTRA,TB Hospital\nCampus, Ram Nagar, Chandrapur - 442401, Maharashtra, 442401",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0834c"
    },
    "Rank": 118096,
    "Allotted Quota": "All India",
    "Allotted Institute": "Government Medical College, Mahabubnagar,Government Medical College, Yedira, Mahabubnagar, Telangana state, Telangana, 509002",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0834d"
    },
    "Rank": 118138,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SHRI. B. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SHRI.\nB. M. PATIL MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE, SOLAPUR ROAD, VIJAYAPURA, Karnataka, 586103",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0834e"
    },
    "Rank": 118196,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Bundelkhand Medical College Sagar M.P.,Govt. Bundelkhand Medical College Sagar M.P., Madhya Pradesh, 470001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0834f"
    },
    "Rank": 118230,
    "Allotted Quota": "All India",
    "Allotted Institute": "Govt. Medical College, Bhavnagar,Near. S.T. Bus Stand,Jail Road, Bhavnagar-364001, Gujarat, 364001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08350"
    },
    "Rank": 118272,
    "Allotted Quota": "All India",
    "Allotted Institute": "B.J.Government Medical College, Pune,B.J.Government Medical College, Pune, Maharashtra, 411001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08351"
    },
    "Rank": 118313,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-,Jawaharlal Nehru Medical College, Sawangi (Meghe), Wardha-442107, Maharashtra, 442107",
    "Course": "M.D. (PAEDIATRICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08352"
    },
    "Rank": 118486,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08353"
    },
    "Rank": 118514,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08354"
    },
    "Rank": 118540,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08355"
    },
    "Rank": 118600,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Saveetha Medical College Nd Hospital,Saveetha Medical College and Hospital, Tamil Nadu, 602105",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08356"
    },
    "Rank": 118666,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "AARUPADAI VEEDU MEDICAL COLLEGE AND HOSPITAL,PONDY - CUDDALORE MAIN ROAD,\nKIRUMAMPAKKAM, BAHOUR COMMUNE PANCHAYAT,\nPUDUCHERRY, Puducherry, 607402",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08357"
    },
    "Rank": 118684,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08358"
    },
    "Rank": 118716,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Yenepoya Medical College,Yenepoya Medical College, Karnataka, 575018",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08359"
    },
    "Rank": 118719,
    "Allotted Quota": "All India",
    "Allotted Institute": "Baba Raghav Das Medical College,Baba Raghav Das Medical College, Gorakhpur, Uttar Pradesh, 273013",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0835a"
    },
    "Rank": 118730,
    "Allotted Quota": "All India",
    "Allotted Institute": "Atal Bihari Vajpayee Government Medical College, Vidisha,NH 86 INFRONT OF KHEL PARISAR SANCHI ROAD VIDISHA MP, Madhya Pradesh, 464001",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0835b"
    },
    "Rank": 118761,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0835c"
    },
    "Rank": 118805,
    "Allotted Quota": "All India",
    "Allotted Institute": "MADURAI MEDICAL COLLEGE,MADURAI MEDICAL COLLEGE, Tamil Nadu, 625020",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0835d"
    },
    "Rank": 118840,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0835e"
    },
    "Rank": 118876,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0835f"
    },
    "Rank": 118884,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08360"
    },
    "Rank": 118974,
    "Allotted Quota": "All India",
    "Allotted Institute": "CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES,CHHATTISGARH INSTITUTE OF MEDICAL SCIENCES, Chhattisgarh, 495001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08361"
    },
    "Rank": 118979,
    "Allotted Quota": "All India",
    "Allotted Institute": "Siddhartha Medical College, Vijayawada,Siddhartha Medical College, Vijayawada, Andhra Pradesh, 520008",
    "Course": "M.D. (MICROBIOLOGY)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08362"
    },
    "Rank": 118993,
    "Allotted Quota": "All India",
    "Allotted Institute": "DARBHANGA MEDICAL COLLEGE,Sheetal Prasad Marg, Laheriasarai, Darbhanga, Bihar, Bihar, 846003",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08363"
    },
    "Rank": 119011,
    "Allotted Quota": "All India",
    "Allotted Institute": "Regional Institute of Medical Sciences, Imphal,Regional Institute of Medical Sciences, Imphal, Lamphelpat, Manipur, 795004",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08364"
    },
    "Rank": 119036,
    "Allotted Quota": "All India",
    "Allotted Institute": "Dr. Sampurnanand Medical College (SNMC), JODHPUR,Dr. Sampurnanand Medical College (SNMC), JODHPUR, Rajasthan, 342001",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08365"
    },
    "Rank": 119040,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Mahatma Gandhi Medical College and Research Institute, Pondicherry,SBV Pondicherry Campus, Pillaiyarkuppam, Pondicherry, Puducherry, 607402",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08366"
    },
    "Rank": 119055,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.S. (GENERAL SURGERY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08367"
    },
    "Rank": 119084,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SREE BALAJI MEDICAL COLLEGE AND HOSPITAL, CHENNAI,NO,7 WORKS ROAD,CHROMEPET,CHENNAI, Tamil Nadu, 600044",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08368"
    },
    "Rank": 119160,
    "Allotted Quota": "All India",
    "Allotted Institute": "Karwar Institute of Medical Sciences, Karnataka,MG Road, Karnataka, 581301",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08369"
    },
    "Rank": 119172,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Sri Lakshmi narayana institute of medical sciences,puducherry,Sri Lakshmi narayana institute of medical sciences,puducherry, Puducherry, 605502",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0836a"
    },
    "Rank": 119200,
    "Allotted Quota": "All India",
    "Allotted Institute": "GUNTUR MEDICAL COLLEGE,GUNTUR MEDICAL COLLEGE, Andhra Pradesh, 522004",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0836b"
    },
    "Rank": 119209,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR,SRI SIDDHARTHA MEDICAL COLLEGE, TUMKUR, Karnataka, 572107",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0836c"
    },
    "Rank": 119292,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0836d"
    },
    "Rank": 119335,
    "Allotted Quota": "All India",
    "Allotted Institute": "Moti Lal Nehru Medical College,Moti Lal Nehru Medical College, Uttar Pradesh, 211001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0836e"
    },
    "Rank": 119386,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli,Bharati Vidyapeeth (Deemed to be University) Medical College and Hospital, Sangli, Maharashtra, 416416",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0836f"
    },
    "Rank": 119398,
    "Allotted Quota": "All India",
    "Allotted Institute": "VARDHMAN INSTITUTE OF MEDICAL SCIENCES, NALANDA,BMIMS CAMPUS, PAWAPURI, NALANDA, Bihar, 803115",
    "Course": "M.D. (BIOCHEMISTRY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08370"
    },
    "Rank": 119407,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08371"
    },
    "Rank": 119620,
    "Allotted Quota": "All India",
    "Allotted Institute": "IPGME&R and SSKM Hospital Kolkata,244, Acharya J. C. Bose Road, Kolkata-700020, West Bengal, 700020",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08372"
    },
    "Rank": 119627,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal,Kasturba Medical College Manipal, Manipal Academy Of Higher Education, Manipal, Karnataka, 576104",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08373"
    },
    "Rank": 119637,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08374"
    },
    "Rank": 119643,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Vinayaka Missions Kirupananda Variyar  Medical College and Hospital, Salem,Sankari Main Road, NH-47, Seeragapadi, Salem, Tamil Nadu, 636308",
    "Course": "M.D. (PREVENTIVE and SOCIAL MEDICINE)/\nCOMMUNITY MEDICINE",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08375"
    },
    "Rank": 119644,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08376"
    },
    "Rank": 119798,
    "Allotted Quota": "All India",
    "Allotted Institute": "Srirama Chandra Bhanja Medical College, CUTTACK,Srirama Chandra Bhanja Medical College, CUTTACK, Odisha, 753007",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08377"
    },
    "Rank": 119799,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,  SRM INSTITUTE OF SCIENCE AND TECHNOLOGY,SRM MEDICAL COLLEGE HOSPITAL AND RESEARCH CENTRE,SRM IST, KATTANKULATHUR , CHENGALPATTU DIST, Tamil Nadu, 603203",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08378"
    },
    "Rank": 119805,
    "Allotted Quota": "All India",
    "Allotted Institute": "ESIC Medical College, Hyderbad,Sanathnagar, Hyderabad, Telangana, 500038",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08379"
    },
    "Rank": 119813,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "A.C.S. Medical College and Hospital,A.C.S. Medical College and Hospital, Tamil Nadu, 600077",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0837a"
    },
    "Rank": 119850,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Govt. District Civil Hospital, ,DIS GOVT HOSPITAL, SRI GANGANAGAR, Rajasthan, 335001",
    "Course": "(NBEMS-DIPLOMA) OPHTHALMOLOGY",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0837b"
    },
    "Rank": 119905,
    "Allotted Quota": "DNB Quota",
    "Allotted Institute": "Maharaja Suhel Dev Medical College and Mahrishi Balark Hospital, Uttar Pradesh,MSD AS Medical College KDC Road Bahraich, Uttar Pradesh, 271801",
    "Course": "(NBEMS-DIPLOMA)\nOtorhinolaryngology (E.N.T.)",
    "Candidate Category": "ST"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0837c"
    },
    "Rank": 120007,
    "Allotted Quota": "All India",
    "Allotted Institute": "GAJRA RAJA MEDICAL COLLEGE GWALIOR,GAJRA RAJA MEDICAL COLLEGE\nGWALIOR, Madhya Pradesh, 474009",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0837d"
    },
    "Rank": 120064,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD,KRISHNA INSTITUTE OF MEDICAL SCIENCES, KARAD, Maharashtra, 415110",
    "Course": "M.D. (Obst. and Gynae)/MS (Obstetrics\nand Gynaecology)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0837e"
    },
    "Rank": 120072,
    "Allotted Quota": "All India",
    "Allotted Institute": "GADAG INSTITUTE OF MEDICAL SCIENCES GADAG,Gadag Institute of Medical Sciences Mallasamudra Gadag-582103, Karnataka, 582103",
    "Course": "M.D. (GENERAL MEDICINE)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a0837f"
    },
    "Rank": 120169,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Shri Sathya Sai Medical College and Research Institute,Kancheepuram,Shri Sathya Sai Nagar, SBV Chennai Campus, Ammapettai, Chengalpattu District, Tamil Nadu, 603108",
    "Course": "M.D. (PATHOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08380"
    },
    "Rank": 120180,
    "Allotted Quota": "All India",
    "Allotted Institute": "MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE,MYSORE MEDICAL COLLEGE AND RESEARCH INSTITUTE, Karnataka, 570001",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08381"
    },
    "Rank": 120185,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Rajarajeswari Medical College and Hospital,No. 202, Kambipura, Mysore Road, Bengaluru, Karnataka, 560074",
    "Course": "M.D. (RADIO- DIAGNOSIS)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08382"
    },
    "Rank": 120267,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Santosh Medical College and Hospital, Ghaziabad, NCR Delhi,Santosh Medical College and Hospital, Ghaziabad, Delhi NCR, Uttar Pradesh, 201009",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "OBC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08383"
    },
    "Rank": 120283,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Meenakshi Medical College Hospital and Research Institute, Kanchipuram,Meenakshi Medical College Hospital and Research Institute, Kanchipuram, Tamil Nadu, 631552",
    "Course": "M.D. (ANAESTHESIOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08384"
    },
    "Rank": 120361,
    "Allotted Quota": "All India",
    "Allotted Institute": "RUHS COLLEGE OF MEDICAL SCIENCES,RUHS COLLEGE OF MEDICAL\nSCIENCES, Rajasthan, 302033",
    "Course": "MD/MS (Anatomy)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08385"
    },
    "Rank": 120362,
    "Allotted Quota": "Non-Resident Indian",
    "Allotted Institute": "Chettinad Hospital and Research Institute,Rajiv Gandhi Salai, Kelambakkam, Chengalpattu District, Tamil Nadu, 603103",
    "Course": "M.D. (DERM.,VENE. and LEPROSY)/\n(DERMATOLOGY)/(SKIN and VENEREAL DISEASES)/(VENEREOL OGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08386"
    },
    "Rank": 120363,
    "Allotted Quota": "All India",
    "Allotted Institute": "Sawai Man Singh Medical College, JAIPUR,Sawai Man Singh Medical College, JLN Marg, Jaipur-302004, Rajasthan, 302004",
    "Course": "M.S. (ORTHOPAEDICS)",
    "Candidate Category": "OBC PwD"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08387"
    },
    "Rank": 120456,
    "Allotted Quota": "Management/Paid Seats Quota",
    "Allotted Institute": "Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore,Kasturba Medical College Mangalore, Manipal Academy of Higher Education, Mangalore, Karnataka, 575001",
    "Course": "M.D. (PHARMACOLOGY)",
    "Candidate Category": "SC"
  },
  {
    "_id": {
      "$oid": "6733043a12f54ba6d9a08388"
    },
    "Rank": 120470,
    "Allotted Quota": "All India",
    "Allotted Institute": "Maharaja Krushna Chandra Gajapati Medical College , Brahmapur,Maharaja Krushna Chandra Gajapati Medical College , Brahmapur, Odisha, 760004",
    "Course": "M.D. (PHYSIOLOGY)",
    "Candidate Category": "OBC"
  }]