import React, { useState } from 'react';
import EngineeringBranch from './EngineeringBranch';
import ManagementBranch from './ManagementBranch';
import Medical from './Medical';
import Law from './Law';
import Pharmacy from './Pharmacy';
import ArtsCommerce from './ArtsCommerce';
import Architecture from './Architecture';
import CollegePredictors from './CollegePredictors';
import More from './More';

const MegaDropdown = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(null); // Track which dropdown is open
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [isMenuVisible, setMenuVisible] = useState(false); // For mobile view

  const handleDropdownToggle = (menu) => {
    setDropdownOpen(menu === isDropdownOpen ? null : menu);
  };

  const handleMouseEnter = (submenu) => {
    setActiveSubmenu(submenu);
  };

  const handleMouseLeave = () => {
    setActiveSubmenu(null);
  };

  const toggleMenuVisibility = () => {
    setMenuVisible(!isMenuVisible);
    if (!isMenuVisible) {
      setDropdownOpen(null); // Close all dropdowns when menu is opened on mobile
    }
  };

  return (
    <div className="relative">
      {/* Hamburger Icon */}
      <button 
        className="block lg:hidden p-2 text-white"
        onClick={toggleMenuVisibility}
      >
        <span className="sr-only">Open menu</span>
        {/* Hamburger icon */}
        <svg 
          className="w-8 h-8" 
          fill="none" 
          stroke="currentColor" 
          viewBox="0 0 24 24" 
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
        </svg>
      </button>

      {/* Dropdown Menu */}
      <div 
        className={`lg:flex lg:space-x-3 flex-col lg:flex-row ${isMenuVisible ? 'block' : 'hidden'} lg:block  lg:bg-transparent transition-all duration-300 ease-in-out`}
      >
       
        <div>
          <ManagementBranch
            isDropdownOpen={isDropdownOpen}
            handleDropdownToggle={handleDropdownToggle}
            activeSubmenu={activeSubmenu}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
          />
        </div>
        <div>
          <Medical
            isDropdownOpen={isDropdownOpen}
            handleDropdownToggle={handleDropdownToggle}
            activeSubmenu={activeSubmenu}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
          />
        </div>
        <div>
          <Law
            isDropdownOpen={isDropdownOpen}
            handleDropdownToggle={handleDropdownToggle}
            activeSubmenu={activeSubmenu}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
          />
        </div>
        <div>
          <EngineeringBranch
            isDropdownOpen={isDropdownOpen}
            handleDropdownToggle={handleDropdownToggle}
            activeSubmenu={activeSubmenu}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
          />
        </div>
        <div>
          <Pharmacy
            isDropdownOpen={isDropdownOpen}
            handleDropdownToggle={handleDropdownToggle}
            activeSubmenu={activeSubmenu}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
          />
        </div>
        <div>
          <ArtsCommerce
            isDropdownOpen={isDropdownOpen}
            handleDropdownToggle={handleDropdownToggle}
            activeSubmenu={activeSubmenu}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
          />
        </div>
        <div>
          <Architecture
            isDropdownOpen={isDropdownOpen}
            handleDropdownToggle={handleDropdownToggle}
            activeSubmenu={activeSubmenu}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
          />
        </div>
        <div>
          <CollegePredictors
            isDropdownOpen={isDropdownOpen}
            handleDropdownToggle={handleDropdownToggle}
            activeSubmenu={activeSubmenu}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
          />
        </div>
        <div>
          <More
            isDropdownOpen={isDropdownOpen}
            handleDropdownToggle={handleDropdownToggle}
            activeSubmenu={activeSubmenu}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
          />
        </div>
      </div>
    </div>
  );
};

export default MegaDropdown;
