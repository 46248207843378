export const collegeData = [
  {
    name: "IIM Lucknow - Indian Institute of Management",
    location: "Lucknow",
    rank: "#7 NIRF",
    logo: "iim-lucknow.jpg",
    coursesOffered: 4,
    rating: 4.4,
    fees: "₹7.6 L - 12.6 L",
    coursesLink: "url1",
  },
  {
    name: "IIT Madras - Indian Institute of Technology",
    location: "Chennai",
    rank: "#1 NIRF",
    logo: "iit-madras.jpg",
    coursesOffered: 6,
    rating: 4.8,
    fees: "₹10.5 L - 18.6 L",
    coursesLink: "url2",
  },
  {
    name: "IIM Lucknow - Indian Institute of Management",
    location: "Lucknow",
    rank: "#7 NIRF",
    logo: "iim-lucknow.jpg",
    coursesOffered: 4,
    rating: 4.4,
    fees: "₹7.6 L - 12.6 L",
    coursesLink: "url1",
  },
  {
    name: "IIT Madras - Indian Institute of Technology",
    location: "Chennai",
    rank: "#1 NIRF",
    logo: "iit-madras.jpg",
    coursesOffered: 6,
    rating: 4.8,
    fees: "₹10.5 L - 18.6 L",
    coursesLink: "url2",
  },
  {
    name: "IIM Lucknow - Indian Institute of Management",
    location: "Lucknow",
    rank: "#7 NIRF",
    logo: "iim-lucknow.jpg",
    coursesOffered: 4,
    rating: 4.4,
    fees: "₹7.6 L - 12.6 L",
    coursesLink: "url1",
  },
  {
    name: "IIT Madras - Indian Institute of Technology",
    location: "Chennai",
    rank: "#1 NIRF",
    logo: "iit-madras.jpg",
    coursesOffered: 6,
    rating: 4.8,
    fees: "₹10.5 L - 18.6 L",
    coursesLink: "url2",
  },
  {
    name: "IIM Lucknow - Indian Institute of Management",
    location: "Lucknow",
    rank: "#7 NIRF",
    logo: "iim-lucknow.jpg",
    coursesOffered: 4,
    rating: 4.4,
    fees: "₹7.6 L - 12.6 L",
    coursesLink: "url1",
  },
  {
    name: "IIT Madras - Indian Institute of Technology",
    location: "Chennai",
    rank: "#1 NIRF",
    logo: "iit-madras.jpg",
    coursesOffered: 6,
    rating: 4.8,
    fees: "₹10.5 L - 18.6 L",
    coursesLink: "url2",
  },
];
