import React, { useState, useEffect } from 'react';
import { FaUser, FaSearch, FaWhatsapp } from 'react-icons/fa'; // Import person icon
import { logo } from "../../images/index";
import { Link } from 'react-router-dom';
import { auth } from '../firebase'; // Import the auth instance from your firebase config
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
const MobileHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState({
    engineering: false,
    medical: false,
    management: false,
    law: false,
    pharmacy: false, // New dropdown
    artsCommerce: false, // New dropdown
    architecture: false, // New dropdown
    collegepredictor: false,
  });
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);
  const handleNavigate = () => {
    setIsVisible(false);
    navigate('/search'); // Ensure this route is set to render SearchPage
  };
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const [user, setUser] = useState(null); // State to hold the authenticated user

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const toggleDropdown = (key) => {
    setIsDropdownOpen((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };
  const handleOptionSelect = () => {
    setIsDropdownOpen({
      engineering: false,
      medical: false,
      management: false,
      law: false,
      pharmacy: false,
      artsCommerce: false,
      architecture: false,
      collegepredictor: false,
    });
  };

  const toggleUserDropdown = () => {
    setIsUserDropdownOpen(!isUserDropdownOpen);
  };

  // Check for user authentication status
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe(); // Cleanup subscription
  }, []);

  // Sign out function
  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setUser(null); // Update user state to null after sign out
    } catch (error) {
      console.error("Error signing out: ", error.message);
    }
  };

  return (
    <header className="w-full bg-[#68a4a5] shadow-md p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-xl font-bold">
          <a href="/">
            <img
              src={logo}
              style={{ width: "140px", height: "39px" }}
              alt="Logo"
            />
          </a>
        </div>

        {/* Hamburger Icon for Mobile */}
        <div className="md:hidden flex items-center space-x-4">
          <button onClick={toggleMenu} className="text-white focus:outline-none">
            {isOpen ? (
              <svg
                className="w-6 h-6"
                fill="white"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            ) : (
              <svg
                className="w-6 h-6"
                fill="white"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            )}
          </button>
          <div className="relative">
            <button className="text-white focus:outline-none">
              <a href="https://api.whatsapp.com/send?phone=919711630487">
                <FaWhatsapp className="w-6 h-6" />
              </a>
            </button>
          </div>
          <div className="relative">
            <button
              onClick={toggleUserDropdown}
              className="text-white focus:outline-none"
            >
              <FaUser className="w-6 h-6" />
            </button>
            {/* User Dropdown */}
            {isUserDropdownOpen && (
              <div className="absolute right-0 mt-2 bg-white shadow-lg rounded-lg py-2 w-32 z-50">
                {user ? (
                  <>
                    <Link
                      to="/profile" // Link to user profile page
                      className="block px-4 py-2 text-gray-700 hover:bg-[#68a4a5] hover:text-white transition-all duration-200"
                      onClick={() => setIsUserDropdownOpen(false)}
                    >
                      Profile
                    </Link>
                    <button
                      onClick={() => {
                        handleSignOut();
                        setIsUserDropdownOpen(false);
                      }}
                      className="block px-4 py-2 text-gray-700 hover:bg-[#68a4a5] hover:text-white transition-all duration-200 w-full text-left"
                    >
                      Sign Out
                    </button>
                  </>
                ) : (
                  <>
                    <Link
                      to="/login"
                      className="block px-4 py-2 text-gray-700 hover:bg-[#68a4a5] hover:text-white transition-all duration-200"
                      onClick={() => setIsUserDropdownOpen(false)}
                    >
                      Login
                    </Link>
                    <Link
                      to="/signup"
                      className="block px-4 py-2 text-gray-700 hover:bg-[#68a4a5] hover:text-white transition-all duration-200"
                      onClick={() => setIsUserDropdownOpen(false)}
                    >
                      Signup
                    </Link>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
            {/* Mobile Dropdown Menu */}
            {isOpen && (
        <div className="md:hidden">
          <nav className="flex flex-col space-y-2 mt-2">
            {/* Engineering Dropdown */}
            <div className="relative">
              <button
                onClick={() => toggleDropdown("engineering")}
                className="flex justify-between items-center w-full text-white hover:text-white transition-all duration-200 p-2 rounded-lg hover:bg-[#1c4c4c]"
              >
                Engineering
                {isDropdownOpen.engineering ? (
                  <span className="ml-2">&#x25B2;</span> // Up arrow for open
                ) : (
                  <span className="ml-2">&#x25BC;</span> // Down arrow for closed
                )}
              </button>
              {isDropdownOpen.engineering && (
                <div className="mt-2 pl-4 space-y-2">
                  <ul className="space-y-2">
                    <li className="text-xs">
                      <Link
                        to="/TopEngineering"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Engineering Colleges in India
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/TopPrivate"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Private Engineering Colleges in India
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/TopIIT"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top IITs in India
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/TopNIT"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top NITs in India
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/TopKarnataka"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Engineering Colleges in Karnataka
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/TopHyderabad"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Engineering Colleges in Hyderabad
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/TopPune"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Engineering Colleges in Pune
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            {/* Medical Dropdown */}
            <div className="relative">
              <button
                onClick={() => toggleDropdown("medical")}
                className="flex justify-between items-center w-full text-white hover:text-white transition-all duration-200 p-2 rounded-lg hover:bg-[#1c4c4c]"
              >
                Medical
                {isDropdownOpen.medical ? (
                  <span className="ml-2">&#x25B2;</span>
                ) : (
                  <span className="ml-2">&#x25BC;</span>
                )}
              </button>
              {isDropdownOpen.medical && (
                <div className="mt-2 pl-4 space-y-2">
                  <ul className="space-y-2">
                    <li className="text-xs">
                      <Link
                        to="/TopMedicalIndia"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Medical Colleges in India
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/TopGovernmentMedical"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Government Medical Colleges
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/TopPrivateMedical"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Private Medical Colleges
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/TopBangaloreMedical"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Medical Colleges in Bangalore
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/TopMumbaiMedical"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Medical Colleges in Mumbai
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            {/* Management Dropdown */}
            <div className="relative">
              <button
                onClick={() => toggleDropdown("management")}
                className="flex justify-between items-center w-full text-white hover:text-white transition-all duration-200 p-2 rounded-lg hover:bg-[#1c4c4c]"
              >
                Management
                {isDropdownOpen.management ? (
                  <span className="ml-2">&#x25B2;</span>
                ) : (
                  <span className="ml-2">&#x25BC;</span>
                )}
              </button>
              {isDropdownOpen.management && (
                <div className="mt-2 pl-4 space-y-2">
                  <ul className="space-y-2">
                    <li className="text-xs">
                      <Link
                        to="/TopManagementCollegesinIndia"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Management Colleges in India
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/TopIIMsinIndia"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top IIMs in India
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/TopManagementCollegesinBangalore"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Management Colleges in Bnaglore
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/TopMBACollegesinPune"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Management Colleges in Pune
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/TopPrivateManagementColleges"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Private Management Colleges
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            {/* Law Dropdown */}
            <div className="relative">
              <button
                onClick={() => toggleDropdown("law")}
                className="flex justify-between items-center w-full text-white hover:text-white transition-all duration-200 p-2 rounded-lg hover:bg-[#1c4c4c]"
              >
                Law
                {isDropdownOpen.law ? (
                  <span className="ml-2">&#x25B2;</span>
                ) : (
                  <span className="ml-2">&#x25BC;</span>
                )}
              </button>
              {isDropdownOpen.law && (
                <div className="mt-2 pl-4 space-y-2">
                  <ul className="space-y-2">
                    <li className="text-xs">
                      <Link
                        to="/TopLawCollegesinIndia"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Law Colleges in India
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/TopNationalLawUniversities"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top National Law Universities
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/TopPrivateLawColleges"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Private Law Colleges
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/TopLawCollegesinDelhi"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Law Colleges in Delhi
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/TopLawCollegesinMumbai"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Law Colleges in Mumbai
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            {/* Pharmacy Dropdown */}
            <div className="relative">
              <button
                onClick={() => toggleDropdown("pharmacy")}
                className="flex justify-between items-center w-full text-white hover:text-white transition-all duration-200 p-2 rounded-lg hover:bg-[#1c4c4c]"
              >
                Pharmacy
                {isDropdownOpen.pharmacy ? (
                  <span className="ml-2">&#x25B2;</span>
                ) : (
                  <span className="ml-2">&#x25BC;</span>
                )}
              </button>
              {isDropdownOpen.pharmacy && (
                <div className="mt-2 pl-4 space-y-2">
                  <ul className="space-y-2">
                    <li className="text-xs">
                      <Link
                        to="/TopPharmacyCollegesinIndia"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Pharmacy Colleges in India
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/TopPrivatePharmacyColleges"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Private Pharmacy Colleges
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/TopPharmacyCollegesinDelhi"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Pharmacy Colleges in Delhi
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/TopPharmacyCollegesinMumbai"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Pharmacy Colleges in Mumbai
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/TopPharmacyCollegesinBanglore"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Pharmacy Colleges in Bangalore
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            {/* Arts & Commerce Dropdown */}
            <div className="relative">
              <button
                onClick={() => toggleDropdown("artsCommerce")}
                className="flex justify-between items-center w-full text-white hover:text-white transition-all duration-200 p-2 rounded-lg hover:bg-[#1c4c4c]"
              >
                Arts & Commerce
                {isDropdownOpen.artsCommerce ? (
                  <span className="ml-2">&#x25B2;</span>
                ) : (
                  <span className="ml-2">&#x25BC;</span>
                )}
              </button>
              {isDropdownOpen.artsCommerce && (
                <div className="mt-2 pl-4 space-y-2">
                  <ul className="space-y-2">
                    <li className="text-xs">
                      <Link
                        to="/TopArtsCollegesinIndia"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Arts Colleges in India
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/TopCommerceCollegesinIndia"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Commerce Colleges in India
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/TopPrivateArtsColleges"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Private Arts Colleges
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/TopArtsCollegesinDelhi"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Arts Colleges in Delhi
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/TopCommerceCollegesinMumbai"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Commerce Colleges in Mumbai
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            {/* Architecture Dropdown */}
            <div className="relative">
              <button
                onClick={() => toggleDropdown("architecture")}
                className="flex justify-between items-center w-full text-white hover:text-white transition-all duration-200 p-2 rounded-lg hover:bg-[#1c4c4c]"
              >
                Architecture
                {isDropdownOpen.architecture ? (
                  <span className="ml-2">&#x25B2;</span>
                ) : (
                  <span className="ml-2">&#x25BC;</span>
                )}
              </button>
              {isDropdownOpen.architecture && (
                <div className="mt-2 pl-4 space-y-2">
                  <ul className="space-y-2">
                    <li className="text-xs">
                      <Link
                        to="/TopArchitectureCollegesinindia"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Architecture Colleges in India
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/TopPrivateArchitectureColleges"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Private Architecture Colleges
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/TopArchitectureCollegesinDelhi"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Architecture Colleges in Delhi
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="TopArchitectureCollegesinMumbai"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Architecture Colleges in Mumbai
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="TopArchitectureCollegesinBangalore"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Top Architecture Colleges in Bangalore
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            {/* {Colege Predictor} */}

            <div className="relative">
              <button
                onClick={() => toggleDropdown("collegepredictor")}
                className="flex justify-between items-center w-full text-white hover:text-white transition-all duration-200 p-2 rounded-lg hover:bg-[#1c4c4c]"
              >
                College Predictor
                {isDropdownOpen.collegepredictor ? (
                  <span className="ml-2">&#x25B2;</span>
                ) : (
                  <span className="ml-2">&#x25BC;</span>
                )}
              </button>
              {isDropdownOpen.collegepredictor && (
                <div className="mt-2 pl-4 space-y-2">
                  <ul className="space-y-4">
                    <li className="text-xs">
                      {" "}
                      <Link
                        to="/NeetUGAllIndiaPredictor"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        Neet UG All India
                      </Link>
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/NEETPGPredictor"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        NEET PG All India
                      </Link>{" "}
                    </li>
                    <li className="text-xs">
                      <Link
                        to="/UPPg/Predictor"
                        onClick={handleOptionSelect}
                        className="block text-white hover:text-white transition-all duration-200 py-1 px-2 rounded-lg hover:bg-[#1c4c4c]"
                      >
                        UP PG Predictor
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </nav>
        </div>
      )}
    {isVisible && (
        <div
          onClick={handleNavigate}
          className="relative w-full h-30 bg-cover bg-center bg-no-repeat bg-[#68a4a5] p-4 cursor-pointer"
        >
          <div className="absolute inset-0 bg-[#68a4a5]"></div>
          <div className="relative z-10 flex items-center w-full max-w-lg mx-auto">
            
            <input
              type="text"
              className="flex-grow p-2 rounded-l-md border-none focus:ring-2 focus:ring-[#1c4c4c] w-full h-10"
              placeholder="Search colleges..."
              readOnly
            />
            <div className="bg-[#1c4c4c] p-2 rounded-r-md text-white h-10 flex items-center justify-center">
              <FaSearch />
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default MobileHeader;
