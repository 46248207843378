// import React, { useState } from 'react';
// import Sidebar from './sidebar';

// const Admissions = () => {
//   const [selectedForm, setSelectedForm] = useState('btech');

//   const renderForm = () => {
//     switch (selectedForm) {
//       case 'btech':
//         return <BtechForm />;
//       case 'integrated':
//         return <IntegratedForm />;
//       case 'mtech':
//         return <MtechForm />;
//       case 'ma':
//         return <MaForm />;
//       default:
//         return null;
//     }
//   };

//   return (
//     <div className="bg-gray-100 min-h-screen p-6 flex">
//       <div className="w-full lg:w-2/3 pr-4">
//         <h1 className="text-2xl font-bold mb-6">IIT Madras Admission 2024</h1>

        {/* Tabs for selecting different admission forms */}
      //   <div className="flex space-x-4 mb-8">
      //     <button
      //       className={`py-2 px-4 ${selectedForm === 'btech' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
      //       onClick={() => setSelectedForm('btech')}
      //     >
      //       BTech Admission
      //     </button>
      //     <button
      //       className={`py-2 px-4 ${selectedForm === 'integrated' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
      //       onClick={() => setSelectedForm('integrated')}
      //     >
      //       Integrated Courses
      //     </button>
      //     <button
      //       className={`py-2 px-4 ${selectedForm === 'mtech' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
      //       onClick={() => setSelectedForm('mtech')}
      //     >
      //       MTech Admission
      //     </button>
      //     <button
      //       className={`py-2 px-4 ${selectedForm === 'ma' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
      //       onClick={() => setSelectedForm('ma')}
      //     >
      //       MA Admission
      //     </button>
      //   </div>

      //   {/* Render the selected form */}
      //   <div className="bg-white shadow-md p-6 rounded-md">
      //     {renderForm()}
      //   </div>
      // </div>

      {/* Sidebar Section */}
//       <Sidebar/>
//     </div>
//   );
// };

// B.Tech Form Component
// const BtechForm = () => (
//   <div>
//     <h2 className="text-xl font-bold mb-4">IIT Madras B.Tech Admission 2024</h2>
//     <form className="space-y-4">
//       <div>
//         <label className="block font-semibold mb-1">Basic Eligibility</label>
//         <input
//           type="text"
//           value="10+2 with 60% marks"
//           className="w-full p-2 border border-gray-300 rounded"
//           readOnly
//         />
//       </div>
//       <div>
//         <label className="block font-semibold mb-1">Number of Specializations/Courses</label>
//         <input
//           type="text"
//           value="10"
//           className="w-full p-2 border border-gray-300 rounded"
//           readOnly
//         />
//       </div>
//       <div>
//         <label className="block font-semibold mb-1">Fees</label>
//         <input
//           type="text"
//           value="INR 1.14 Lakhs (First Sem)"
//           className="w-full p-2 border border-gray-300 rounded"
//           readOnly
//         />
//       </div>
//       <div>
//         <label className="block font-semibold mb-1">Seats</label>
//         <input
//           type="text"
//           value="1,134"
//           className="w-full p-2 border border-gray-300 rounded"
//           readOnly
//         />
//       </div>
//       <div>
//         <label className="block font-semibold mb-1">Selection Process</label>
//         <input
//           type="text"
//           value="Admissions are based on the JEE Main"
//           className="w-full p-2 border border-gray-300 rounded"
//           readOnly
//         />
//       </div>
      
//     </form>
//   </div>
// );

// Integrated Courses Form Component
// const IntegratedForm = () => (
//   <div>
//     <h2 className="text-xl font-bold mb-4">IIT Madras Integrated Courses Admission 2024</h2>
//     <form className="space-y-4">
//       <div>
//         <label className="block font-semibold mb-1">Basic Eligibility</label>
//         <input
//           type="text"
//           value="10+2 with 75% marks"
//           className="w-full p-2 border border-gray-300 rounded"
//           readOnly
//         />
//       </div>
//       <div>
//         <label className="block font-semibold mb-1">Fees</label>
//         <input
//           type="text"
//           value="INR 2.09 Lakhs (First Sem)"
//           className="w-full p-2 border border-gray-300 rounded"
//           readOnly
//         />
//       </div>
//       <div>
//         <label className="block font-semibold mb-1">Application Fees</label>
//         <input
//           type="text"
//           value="Male: INR 1000; Female: INR 800; SC/ST/PwD: INR 500"
//           className="w-full p-2 border border-gray-300 rounded"
//           readOnly
//         />
//       </div>
//       <div>
//         <label className="block font-semibold mb-1">Selection Process</label>
//         <input
//           type="text"
//           value="Admissions are based on the JEE Main score"
//           className="w-full p-2 border border-gray-300 rounded"
//           readOnly
//         />
//       </div>
      
//     </form>
//   </div>
// );

// M.Tech Form Component
// const MtechForm = () => (
//   <div>
//     <h2 className="text-xl font-bold mb-4">IIT Madras M.Tech Admission 2024</h2>
//     <form className="space-y-4">
//       <div>
//         <label className="block font-semibold mb-1">Basic Eligibility</label>
//         <input
//           type="text"
//           value="Graduation with 60% marks"
//           className="w-full p-2 border border-gray-300 rounded"
//           readOnly
//         />
//       </div>
//       <div>
//         <label className="block font-semibold mb-1">Fees</label>
//         <input
//           type="text"
//           value="INR 36,200"
//           className="w-full p-2 border border-gray-300 rounded"
//           readOnly
//         />
//       </div>
//       <div>
//         <label className="block font-semibold mb-1">Selection Process</label>
//         <input
//           type="text"
//           value="GATE Score"
//           className="w-full p-2 border border-gray-300 rounded"
//           readOnly
//         />
//       </div>
      
//     </form>
//   </div>
// );

// MA Form Component
// const MaForm = () => (
//   <div>
//     <h2 className="text-xl font-bold mb-4">IIT Madras MA Admission 2024</h2>
//     <form className="space-y-4">
//       <div>
//         <label className="block font-semibold mb-1">Basic Eligibility</label>
//         <input
//           type="text"
//           value="Bachelor's Degree with 60% marks"
//           className="w-full p-2 border border-gray-300 rounded"
//           readOnly
//         />
//       </div>
//       <div>
//         <label className="block font-semibold mb-1">Fees</label>
//         <input
//           type="text"
//           value="INR 23,365"
//           className="w-full p-2 border border-gray-300 rounded"
//           readOnly
//         />
//       </div>
//       <div>
//         <label className="block font-semibold mb-1">Selection Process</label>
//         <input
//           type="text"
//           value="Based on the entrance exam"
//           className="w-full p-2 border border-gray-300 rounded"
//           readOnly
//         />
//       </div>
      
//     </form>
//   </div>
// );

// export default Admissions;
